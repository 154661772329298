import { ProductItem } from "../types";

export const mapProductsToCSV = (products: ProductItem[]) => {
  return products.map(data => {
    return {
      product_id: data.id,
      product_name: data.product_name,
      number_of_product_stock: data.product_stocks_aggregate.aggregate?.count,
      service_account: data.service_account.service_account_name,
      is_case: data.case_product_id ? "Yes" : "No",
      case_quantity: data.base_product_quantity,
      external_product_id: data.external_product_id,
    };
  });
};
