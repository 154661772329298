import { Formik } from "formik";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AsyncSelect from "react-select/async";
import { toast } from "react-toastify";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { addIAMMembers } from "../api/addIAMMembers";
import { getIAMUsers } from "../api/getIAMUsers";

export interface UserOption {
  label: string;
  value: string;
}

export interface AddMemberForm {
  users: UserOption[];
}

export interface UsersResponse {
  name: string;
  email: string;
  uuid: string;
  organisations_users: {
    organisation: {
      uuid: string;
    };
  }[];
}

export const fieldObjects = {
  users: {
    fieldName: "users",
    fieldLabel: "users",
    fieldPlaceholder: "Add Users",
    fieldBox: "Please select Users",
  },
};

export const initialFieldValues: AddMemberForm = {
  users: [],
};

const AddIAMOrganisationMembers = () => {
  const { orgUUID, displayName } = useParams<{ orgUUID: string; displayName: string }>();
  const [initialValues] = useState<AddMemberForm>(initialFieldValues);
  const history = useHistory();

  const [, getUsers] = useAPI(getIAMUsers, false, {
    pending: "Fetching users...",
    success: "users fetched successfully",
    error: "Error while trying to fetch users",
  });

  const [addMembersState, addMembers] = useAPI(addIAMMembers, false, {
    pending: "add users membership...",
    success: "users membership added successfully",
    error: "Error while trying to add membership",
  });

  const onSubmit = (values: AddMemberForm) => {
    addMembers({
      members: values.users.map(user => user.value),
      organisationId: orgUUID,
    })
      .catch(error => {
        console.error("error adding members:", error);
        toast.error("Error adding members");
      })
      .then(response => {
        console.log("members added successfully");
        toast.success("members added successfully");
        history.push(`/admin/iam/organisations/${orgUUID}/${displayName}/members`);
      });
  };

  const loadOptions = async (value: string) => {
    const response = await getUsers({
      offset: 0,
      limit: 50,
      filters: [{ key: "username", value: `_regex:${value}` }], // Assuming your API can filter users based on a query
    });
    const usersData = Array.isArray(response) ? response[0].data.data : response.data.data;
    const filteredOptions = usersData
      .filter((user: UsersResponse) =>
        user.organisations_users.every(
          organisationUser => organisationUser.organisation.uuid !== orgUUID
        )
      )
      .map((user: UsersResponse) => ({
        label: `${user.name} ${user.email}`,
        value: user.uuid,
      }));
    return filteredOptions;
  };

  const isLoading = addMembersState === API_REQUEST_STATE.PENDING;
  const error = addMembersState === API_REQUEST_STATE.ERROR;

  return (
    <Page title={displayName} isLoading={isLoading} error={error}>
      <Panel withWrapper title={"Add Members"}>
        <InnerPanelWrapper>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              validateForm,
              setTouched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <Title>Add Users</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.users.fieldName}>
                          {fieldObjects.users.fieldLabel}
                        </Label>
                        <AsyncSelect
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          loadOptions={loadOptions}
                          id={fieldObjects.users.fieldName}
                          isMulti
                          value={values.users}
                          onChange={e => {
                            console.log(e);
                            setFieldValue(fieldObjects.users.fieldName, e);
                          }}
                          placeholder={fieldObjects.users.fieldPlaceholder}
                        />
                        {errors.users && touched.users ? (
                          <ErrorMessage>{JSON.stringify(errors.users)}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <SecondaryButton
                    type="button"
                    appearance={"blueButton"}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Confirm
                  </SecondaryButton>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default AddIAMOrganisationMembers;
