import { CourierNameItem } from "../graphql/types";

export const mapCourierNamesToDrodownObject = (courierNames: CourierNameItem[]) => {
  return courierNames.map(courierName => {
    return {
      label: courierName.courier_name,
      value: courierName.id,
    };
  });
};
