import { FormikErrors, FormikTouched } from "formik";

import SimpleDateInput from "../../../../shared/components/forms/SimpleDateInput";
import { DataPoint, PrimaryLineDataWrapper } from "../../../../styles/SharedStyles";
import { fieldObjects } from "../formValues";
import { ClientPromisedDeliveryDate as ClientPromisedDeliveryDateType } from "../types";

export interface ClientPromisedDeliveryDateProps {
  values: ClientPromisedDeliveryDateType;
  errors: FormikErrors<ClientPromisedDeliveryDateType> | undefined;
  touched: FormikTouched<ClientPromisedDeliveryDateType> | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const ClientPromisedDeliveryDate = ({
  values,
  errors,
  touched,
  setFieldValue,
}: ClientPromisedDeliveryDateProps) => {
  return (
    <PrimaryLineDataWrapper>
      <DataPoint>
        <SimpleDateInput
          htmlFor={fieldObjects.client_promised_delivery_date.from_date.fieldName}
          name={fieldObjects.client_promised_delivery_date.from_date.fieldName}
          value={values.from_date}
          error={errors?.from_date}
          touched={touched?.from_date}
          label={fieldObjects.client_promised_delivery_date.from_date.fieldLabel}
          changeHandler={e => {
            setFieldValue(
              fieldObjects.client_promised_delivery_date.from_date.fieldName,
              e.target.value
            );
          }}
        />
      </DataPoint>
      <DataPoint>
        <SimpleDateInput
          htmlFor={fieldObjects.client_promised_delivery_date.to_date.fieldName}
          name={fieldObjects.client_promised_delivery_date.to_date.fieldName}
          value={values.to_date}
          error={errors?.to_date}
          touched={touched?.to_date}
          label={fieldObjects.client_promised_delivery_date.to_date.fieldLabel}
          changeHandler={e => {
            setFieldValue(
              fieldObjects.client_promised_delivery_date.to_date.fieldName,
              e.target.value
            );
          }}
        />
      </DataPoint>
    </PrimaryLineDataWrapper>
  );
};

export default ClientPromisedDeliveryDate;
