import { ApolloError } from "@apollo/client";

import { useGetStockByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapStockToCSV } from "../mappers/mapStockToCSV";

export interface HookResult {
  stock: any[];
  stockError?: ApolloError;
  stockLoading: boolean;
  getStockByWarehouseId: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number | null;
}

export const useStockByWarehouseId = (): HookResult => {
  const [getStockByWarehouseId, { data, error, loading }] = useGetStockByWarehouseIdLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stock: data && Array.isArray(data.product_stock) ? mapStockToCSV(data.product_stock) : [],
    stockError: error,
    stockLoading: loading,
    getStockByWarehouseId: async ({ warehouseId }: Options) => {
      getStockByWarehouseId({ variables: { warehouseId } });
    },
  };
};
