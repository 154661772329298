import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createPrePurchaseOrderAPI } from "../../api/createPrePurchaseOrderAPI";
import { CreatePrePurchaseOrderBody } from "../../api/types";
import {
  CREATE_PRE_PURCHASE_ORDER_FAILED,
  CREATE_PRE_PURCHASE_ORDER_LOADING,
  CREATE_PRE_PURCHASE_ORDER_RESET,
  CREATE_PRE_PURCHASE_ORDER_SUCCESS,
} from "../types";

export const createPrePurchaseOrderLoading = () => {
  return {
    type: CREATE_PRE_PURCHASE_ORDER_LOADING,
  };
};

export const createPrePurchaseOrderSuccess = () => {
  return {
    type: CREATE_PRE_PURCHASE_ORDER_SUCCESS,
  };
};

export const createPrePurchaseOrderFailed = () => {
  return {
    type: CREATE_PRE_PURCHASE_ORDER_FAILED,
  };
};

export const createPrePurchaseOrderReset = () => {
  return {
    type: CREATE_PRE_PURCHASE_ORDER_RESET,
  };
};

export const createPrePurchaseOrderAction = (
  clientOrderLineId: number,
  body: CreatePrePurchaseOrderBody
) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Pre-Purchase Order", { hideProgressBar: true });
    dispatch(createPrePurchaseOrderLoading());
    try {
      await createPrePurchaseOrderAPI(clientOrderLineId, body);
      toast.success("Created Pre-Purchase Order successfully");
      dispatch(createPrePurchaseOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating pre-purchase order.");
      dispatch(createPrePurchaseOrderFailed());
    }
  };
};
