import { CreateOrderForm } from "../types";

const CN_TAX_RATE = 1.13;

export const getCnVatRate = (values: CreateOrderForm) => {
  if (values.sourceful_entity_cn.client_cn_entity.label === "YES") {
    return CN_TAX_RATE; // 13% VAT for CN orders with client entity
  }

  if (values.sourceful_entity_cn.client_3rd_party_cn_entity.label === "YES") {
    if (values.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.label === "YES") {
      return CN_TAX_RATE; // 13% VAT for CN orders with 3rd party entity
    }
    return;
  }

  if (values.shipping_address.country_name.label === "CN") {
    return 1; // 0% VAT for CN orders going to CN without client entity
  }

  return;
};
