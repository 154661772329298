import { SetStateAction, useState } from "react";
import { Filters } from "react-table";

import { ButtonGroup, Heading, Icon, styled } from "@sourceful/shared-components";

import SecondaryButton from "../../shared/components/atoms/buttons/SecondaryButton";
import { UniqueColumnData } from "../Table";
import { CheckboxScroll } from "../checkboxScroll/CheckboxScroll";

const ModalContent = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexWrap: "wrap",
  maxHeight: "90vh",
});

const OptionWrapper = styled("div", {
  display: "flex",
  width: "100%",
  flexDirection: "row",
  marginTop: "30px",
  flexWrap: "wrap",
});

export interface FilterPopupProps<T extends object = { Header: string }> {
  UniqueColumnsData: UniqueColumnData[];
  setAllFilters: (updater: Filters<T> | ((filters: Filters<T>) => Filters<T>)) => void;
  setAreFiltersVisible: (value: SetStateAction<boolean>) => void;
}

export const FilterPopup = ({
  UniqueColumnsData,
  setAllFilters,
  setAreFiltersVisible,
}: FilterPopupProps) => {
  const [allOptions, setAllOptions] = useState<any>({});

  const filterRows = () => {
    const arrOfOptions = Object.entries(allOptions);

    const random = arrOfOptions.map((optionsAndId: any) => {
      const checkedOptionsOnly = optionsAndId[1].filter((val: any) => val.checked === true);
      const checkedOptionsStrings = checkedOptionsOnly.map((option: any) => option.value);
      return {
        id: optionsAndId[0],
        value: checkedOptionsStrings,
      };
    });

    setAllFilters(random);
  };

  return (
    <ModalContent>
      <div>
        <Heading level={5}>Filter by</Heading>
        <OptionWrapper>
          {UniqueColumnsData.map((col, i) => {
            const options = col.data.map(value => {
              return {
                value: value,
                checked: false,
              };
            });
            return (
              <CheckboxScroll
                key={`popup_${i}`}
                hasSearchBar
                searchPlaceholder={`Search ${col.column} `}
                heading={col.column}
                accessorType={col.accessorType}
                options={options}
                setAllOptions={setAllOptions}
              />
            );
          })}
        </OptionWrapper>

        <ButtonGroup css={{ display: "flex", justifyContent: "flex-end", marginBlock: "30px" }}>
          <SecondaryButton
            appearance="whiteButton"
            onClick={() => {
              setAllOptions({});
              filterRows();
              setAreFiltersVisible(false);
            }}
          >
            <Icon name={"navigation-refresh"}></Icon>
          </SecondaryButton>
          <SecondaryButton
            appearance="blueButton"
            onClick={() => {
              filterRows();
              setAreFiltersVisible(false);
            }}
          >
            Confirm
          </SecondaryButton>
        </ButtonGroup>
      </div>
    </ModalContent>
  );
};
