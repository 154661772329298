import { FormikErrors, FormikTouched } from "formik";

import { IconText } from "@sourceful/shared-components";

import SecondaryButton from "../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import {
  ButtonWrapper,
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../styles/SharedStyles";
import { isFeatureFlagEnabled } from "../../../../util/featureFlags";
import { countryCodeOptions, fieldObjects } from "../formValues";
import { ShippingAddress } from "../types";
import { filterCNCountryCodeOptions } from "../util/FilterCNOptions";

export interface ShippingDetailsProps {
  values: ShippingAddress;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<ShippingAddress> | undefined;
  touched: FormikTouched<ShippingAddress> | undefined;
  copyBillingIntoShipping: () => void;
}

const ShippingDetails = ({
  values,
  errors,
  touched,
  setFieldValue,
  copyBillingIntoShipping,
}: ShippingDetailsProps) => {
  const getCustomDeliveryInstructionsLabel = () => {
    if (!values.custom_delivery_instruction || values.custom_delivery_instruction.length === 0) {
      return fieldObjects.shipping_address.custom_delivery_instruction.fieldLabel;
    }
    return `${fieldObjects.shipping_address.custom_delivery_instruction.fieldLabel} (${values.custom_delivery_instruction.length}/450 characters)`;
  };

  const filteredCountries = filterCNCountryCodeOptions(countryCodeOptions);

  return (
    <PrimaryLineWrapper>
      <Title>Contact Information - Shipping Details</Title>
      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
        <SecondaryButton
          type="button"
          appearance={"whiteButtonBlueText"}
          onClick={() => {
            copyBillingIntoShipping();
          }}
        >
          <IconText text={"Copy Billing Address"} primaryIcon={"alert-add-outline"} />
        </SecondaryButton>
      </ButtonWrapper>
      <PrimaryLineDataWrapper>
        <DataPoint halfWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.first_name.fieldName}
            name={fieldObjects.shipping_address.first_name.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.first_name.fieldPlaceholder}
            value={values.first_name}
            error={errors?.first_name}
            touched={touched?.first_name}
            label={fieldObjects.shipping_address.first_name.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.first_name.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.second_name.fieldName}
            name={fieldObjects.shipping_address.second_name.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.second_name.fieldPlaceholder}
            value={values.second_name}
            error={errors?.second_name}
            touched={touched?.second_name}
            label={fieldObjects.shipping_address.second_name.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.second_name.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.company_name.fieldName}
            name={fieldObjects.shipping_address.company_name.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.company_name.fieldPlaceholder}
            value={values.company_name}
            error={errors?.company_name}
            touched={touched?.company_name}
            label={fieldObjects.shipping_address.company_name.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.company_name.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.address_line_1.fieldName}
            name={fieldObjects.shipping_address.address_line_1.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.address_line_1.fieldPlaceholder}
            value={values.address_line_1}
            error={errors?.address_line_1}
            touched={touched?.address_line_1}
            label={fieldObjects.shipping_address.address_line_1.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.address_line_1.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            htmlFor={fieldObjects.shipping_address.address_line_2.fieldName}
            name={fieldObjects.shipping_address.address_line_2.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.address_line_2.fieldPlaceholder}
            value={values.address_line_2}
            error={errors?.address_line_2}
            touched={touched?.address_line_2}
            label={fieldObjects.shipping_address.address_line_2.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.address_line_2.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            htmlFor={fieldObjects.shipping_address.address_line_3.fieldName}
            name={fieldObjects.shipping_address.address_line_3.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.address_line_3.fieldPlaceholder}
            value={values.address_line_3}
            error={errors?.address_line_3}
            touched={touched?.address_line_3}
            label={fieldObjects.shipping_address.address_line_3.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.address_line_3.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.city.fieldName}
            name={fieldObjects.shipping_address.city.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.city.fieldPlaceholder}
            value={values.city}
            error={errors?.city}
            touched={touched?.city}
            label={fieldObjects.shipping_address.city.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.city.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.region_or_county_or_state.fieldName}
            name={fieldObjects.shipping_address.region_or_county_or_state.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.region_or_county_or_state.fieldPlaceholder}
            tooltipMessage="For US, use the 2 Digit State Code e.g. CA for California"
            value={values.region_or_county_or_state}
            error={errors?.region_or_county_or_state}
            touched={touched?.region_or_county_or_state}
            label={fieldObjects.shipping_address.region_or_county_or_state.fieldLabel}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.shipping_address.region_or_county_or_state.fieldName,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.shipping_address.postcode.fieldName}
            name={fieldObjects.shipping_address.postcode.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.postcode.fieldPlaceholder}
            value={values.postcode}
            error={errors?.postcode}
            touched={touched?.postcode}
            label={fieldObjects.shipping_address.postcode.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.shipping_address.postcode.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.shipping_address.country_name.fieldName}
            name={fieldObjects.shipping_address.country_name.fieldName}
            placeholder={fieldObjects.shipping_address.country_name.fieldPlaceholder}
            options={
              isFeatureFlagEnabled("REACT_APP_FEATURE_FLAG_CN_SHIPPING_ADDRESS")
                ? countryCodeOptions
                : filteredCountries
            }
            value={values.country_name}
            error={errors?.country_name?.label}
            touched={touched?.country_name?.label}
            label={fieldObjects.shipping_address.country_name.fieldLabel}
            changeHandler={e =>
              setFieldValue(fieldObjects.shipping_address.country_name.fieldName, e)
            }
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            htmlFor={fieldObjects.shipping_address.custom_delivery_instruction.fieldName}
            name={fieldObjects.shipping_address.custom_delivery_instruction.fieldName}
            type="text"
            placeholder={fieldObjects.shipping_address.custom_delivery_instruction.fieldPlaceholder}
            value={values.custom_delivery_instruction}
            error={errors?.custom_delivery_instruction}
            touched={touched?.custom_delivery_instruction}
            label={getCustomDeliveryInstructionsLabel()}
            tooltipMessage="Add any special instructions e.g. Pallet unloading"
            changeHandler={e => {
              setFieldValue(
                fieldObjects.shipping_address.custom_delivery_instruction.fieldName,
                e.target.value
              );
            }}
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
};

export default ShippingDetails;
