import moment from "moment";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { STOCK_CHECK_STATUS } from "../../shared/models";
import { useStockCheckDesc } from "../graphql/hooks/useStockCheckDesc";
import { StockCheckItem } from "../graphql/types";

export default function ViewStockChecks() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const isAuthorised = WMS_EDIT_ROLES.some(role => roles.includes(role));

  const rightItems: PanelProps["rightItems"] = isAuthorised
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/operations/stock-check/create-basic-stock-check");
          },
          type: "button",
          title: "Create Basic Check",
          iconName: "alert-add-outline",
        },
        {
          id: 2,
          handleClick: () => {
            history.push("/operations/stock-check/create-weekly-stock-check");
          },
          type: "button",
          title: "Create Weekly Check",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const { stockChecks, stockChecksLoading, stockChecksError } = useStockCheckDesc();

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.2,
        accessor: (stock_check: StockCheckItem) => stock_check.id,
      },
      {
        Header: "Status",
        widthPercent: 0.2,
        accessor: (stock_check: StockCheckItem) => (
          <StatusBadge
            type="wms"
            statusName={stock_check.stock_check_status.stock_check_status_name}
          />
        ),
      },
      {
        Header: "Stock Check Type",
        widthPercent: 0.2,
        accessor: (stock_check: StockCheckItem) =>
          stock_check.stock_check_type.stock_check_type_name,
      },

      {
        Header: "Due Date",
        widthPercent: 0.2,
        accessor: (stock_check: StockCheckItem) => {
          return stock_check.due_date ? moment(stock_check.due_date).format("YYYY-MM-DD") : "N/A";
        },
      },
      {
        Header: "Fulfil",
        widthPercent: 0.2,
        accessor: (listItem: StockCheckItem) => listItem.id,
        Cell: ({ _, row }: CellProps<StockCheckItem>) => {
          if (isAuthorised && row.original.stock_check_status.id !== STOCK_CHECK_STATUS.COMPLETE) {
            return (
              <Link to={`/operations/stock-check/fulfil-stock-check/${row.original.id}`}>
                <SecondaryButton appearance="blueButton" isWithinTable>
                  Fulfil
                </SecondaryButton>
              </Link>
            );
          }
          return null;
        },
      },
      {
        Header: "Review",
        widthPercent: 0.2,
        accessor: (listItem: StockCheckItem) => listItem.id,
        Cell: ({ _, row }: CellProps<StockCheckItem>) => (
          <Link to={`/operations/stock-check/view-stock-check/${row.original.id}`}>
            <SecondaryButton appearance="blueButton" isWithinTable>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    [isAuthorised]
  );

  return (
    <Page
      error={stockChecksError}
      isLoading={stockChecksLoading}
      title={"Operations - Stock Check"}
    >
      <Panel
        withWrapper
        title={"Stock Check List"}
        text={"Use the table below to view Sourceful's Stock Checks"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        toolTipMessage={"Each Stock Check is a review of stock in a warehouse"}
      >
        <Table
          data={stockChecks}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
}
