import { ApolloError } from "@apollo/client";

import { useGetRejectionReasonsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { RejectionReasonItem } from "../types";

export interface HookResult {
  rejectionReasons?: RejectionReasonItem[];
  rejectionReasonsError?: ApolloError;
  rejectionReasonsLoading: boolean;
}

export const useRejectionReasons = (): HookResult => {
  const { data, error, loading } = useGetRejectionReasonsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    rejectionReasons: data?.purchase_order_supplier_rejection_reason,
    rejectionReasonsError: error,
    rejectionReasonsLoading: loading,
  };
};
