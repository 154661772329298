import { ApolloError } from "@apollo/client";

import { useGetStockCheckStockConditionsQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DropdownItem } from "../../../shared/graphql/types";
import { mapStockConditionsToDropdownObject } from "../../mappers";

export interface HookResult {
  stockConditions?: DropdownItem[];
  stockConditionsError?: ApolloError;
  stockConditionsLoading: boolean;
}

export const useStockConditions = (): HookResult => {
  const { data, error, loading } = useGetStockCheckStockConditionsQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockConditions:
      data && Array.isArray(data.stock_condition)
        ? mapStockConditionsToDropdownObject(data.stock_condition)
        : [],
    stockConditionsError: error,
    stockConditionsLoading: loading,
  };
};
