import { ApolloError } from "@apollo/client";

import { useGetProductStockByParamsQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStock: ProductStockItem[];
  productStockError?: ApolloError;
  productStockLoading: boolean;
}

interface Options {
  warehouseId: number;
  baseProductId: number;
  organisationId: number;
}

export const useProductStock = ({
  warehouseId,
  baseProductId,
  organisationId,
}: Options): HookResult => {
  const { data, error, loading } = useGetProductStockByParamsQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      warehouseId,
      baseProductId,
      organisationId,
    },
  });

  return {
    productStock: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    productStockError: error,
    productStockLoading: loading,
  };
};
