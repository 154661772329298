import { ApolloError } from "@apollo/client";

import { useGetCustomReportOrganisationNamesQuery } from "../../../../../../generated/graphql_wms";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  organisationNames: DropdownItem[];
  organisationNamesError?: ApolloError;
  organisationNamesLoading: boolean;
}

export const useOrganisationNames = (): HookResult => {
  const { data, error, loading } = useGetCustomReportOrganisationNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    organisationNames:
      data && Array.isArray(data.organisation)
        ? data.organisation.map(organisation => {
            return {
              value: organisation.id,
              label: organisation.organisation_name,
            };
          })
        : [],
    organisationNamesError: error,
    organisationNamesLoading: loading,
  };
};
