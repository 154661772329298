import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { UpdateProductBody } from "../../api/types";
import { updateProductAPI } from "../../api/updateProduct";
import {
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_LOADING,
  UPDATE_PRODUCT_RESET,
  UPDATE_PRODUCT_SUCCESS,
} from "../types";

export const updateProductLoading = () => {
  return {
    type: UPDATE_PRODUCT_LOADING,
  };
};

export const updateProductSuccess = () => {
  return {
    type: UPDATE_PRODUCT_SUCCESS,
  };
};

export const updateProductFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: UPDATE_PRODUCT_FAILED,
    errors: errors,
  };
};

export const updateProductReset = () => {
  return {
    type: UPDATE_PRODUCT_RESET,
  };
};

export const updateProductAction = (productId: number, body: UpdateProductBody) => {
  return async (dispatch: Dispatch) => {
    toast("Updating product", { hideProgressBar: true });
    dispatch(updateProductLoading());
    try {
      await updateProductAPI(productId, body);
      toast.success("Updated product successfully.");
      dispatch(updateProductSuccess());
    } catch (err) {
      toast.error("Something went wrong with updating product.");
      dispatch(updateProductFailed({ errors: (err as any).response?.data?.errors }));
    }
  };
};
