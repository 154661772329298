import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, Heading, Modal, PrimaryButton, styled } from "@sourceful/shared-components";

import { ModalContainer } from "../../../../../components/ModalContainer/ModalContainer";
import Timeline, { TimelineSection } from "../../../../../components/Timeline/Timeline";
import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import AddressBox from "../../../../../shared/components/organisms/AddressBox";
import { Page } from "../../../../../shared/components/templates/Page";
import { WMSButtonGroup } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { mapAddressToDropdownItem } from "../../../shared/graphql/mappers/mapAddressToDropdownItem";
import { ShipmentUpdateItem } from "../../../shared/graphql/types/ShipmentUpdateItem";
import { getLastShipmentUpdate } from "../../../shared/helpers/getLastShipmentUpdate";
import { getShipmentExpectedDate } from "../../../shared/helpers/getShipmentExpectedDate";
import { SHIPMENT_STATUS, SHIPMENT_UPDATE_TYPE } from "../../../shared/models";
import { createShipmentDeliveredUpdateAPI } from "../../shared/api/createShipmentDeliveredUpdate";
import { createShipmentInTransitUpdateAPI } from "../../shared/api/createShipmentInTransitUpdate";
import { getClientOrderDetailsAPI } from "../../shared/api/getClientOrderDetails";
import {
  CreateShipmentDeliveredUpdateBody,
  CreateShipmentInTransitUpdateBody,
  UpdateShipmentDeliveryDateBody,
} from "../../shared/api/types";
import { updateShipmentDeliveryDateAPI } from "../../shared/api/updateShipmentDeliveryDate";
import {
  DeliveredUpdateInterface,
  DeliveryDateUpdateInterface,
  InTransitUpdateInterface,
  initialDeliveredFieldValues,
  initialDeliveryDateFieldValues,
  initialInTransitFieldValues,
} from "../../shared/components";
import DeliveryUpdateForm from "../../shared/components/DeliveryUpdateForm";
import InTransitUpdateForm from "../../shared/components/InTransitUpdateForm";
import { useInternalAddresses } from "../../shared/graphql/hooks/useInternalAddresses";
import {
  AlignedLabel,
  ClientOrderContainer,
  ShipmentContainer,
  TimelineContainer,
} from "../components";
import SplitShipmentForm from "../components/SplitShipmentForm";
import { useClientOrderShipments } from "../graphql/hooks/useClientOrderShipments";
import { splitShipmentAction, splitShipmentReset } from "../redux/actions/splitShipmentAction";
import { SplitShipmentState } from "../redux/reducers/splitShipmentReducer";

const PoLine = styled("div", {
  position: "relative",
  bottom: "150px",
});
interface Props {
  splitShipmentState: SplitShipmentState;
  splitShipment: (shipmentId: number) => void;
  resetSplitShipment: () => void;
}

const ViewClientOrderShipments = (props: Props) => {
  const { splitShipmentState, splitShipment, resetSplitShipment } = props;
  const [poShipmentsOpen, setPoShipmentsOpen] = useState<string>();
  const [createDeliveredUpdateState, createDeliveredUpdate] = useAPI(
    createShipmentDeliveredUpdateAPI
  );
  const [createInTransitUpdateState, createInTransitUpdate] = useAPI(
    createShipmentInTransitUpdateAPI
  );
  const [updateShipmentDeliveryDateState, updateShipmentDeliveryDate] = useAPI(
    updateShipmentDeliveryDateAPI
  );

  const [getClientOrderDetailsState, getClientOrderDetails] = useAPI(getClientOrderDetailsAPI);

  const { clientOrderId } = useParams<{ clientOrderId: string }>();
  const { clientOrder, clientOrderError, clientOrderLoading, refetchClientOrder } =
    useClientOrderShipments(Number(clientOrderId));

  const { internalAddresses, internalAddressesError, internalAddressesLoading } =
    useInternalAddresses();

  const modalRef = createRef<HTMLButtonElement>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shipmentStatus, setShipmentStatus] = useState<SHIPMENT_STATUS | null>(null);
  const [shipmentIdToUpdate, setShipmentIdToUpdate] = useState<number | null>(null);
  const [shipmentAddress, setShipmentAddress] = useState<DropdownItem>();
  const [clientOrderDetails, setClientOrderDetails] = useState<any>();
  const [shipmentDate, setShipmentDate] = useState<string>();
  const [isSplittingShipment, setIsSplittingShipment] = useState<boolean>(false);

  const loading = clientOrderLoading || internalAddressesLoading;
  const error = clientOrderError || internalAddressesError;

  const mappedFinalAddress = clientOrder ? mapAddressToDropdownItem([clientOrder.address]) : [];
  console.log("shipmentStatus", shipmentStatus);

  const handleInTransitUpdate = (values: InTransitUpdateInterface) => {
    setIsModalOpen(false);

    const inTransitUpdate: CreateShipmentInTransitUpdateBody = {
      fromAddressId: values.fromDestination!.value,
      toAddressId: values.toDestination!.value,
      estimatedDeliveryDate: values.expectedDeliveryDate!,
      notes: values.notes,
      trackingLink: values.trackingLink,
    };

    createInTransitUpdate({
      body: inTransitUpdate,
      shipmentId: values.shipmentIds[0] as number,
    });
  };

  const handleDeliveredUpdate = (values: DeliveredUpdateInterface) => {
    setIsModalOpen(false);

    const deliveredUpdate: CreateShipmentDeliveredUpdateBody = {
      actualDeliveryDate: values.actualDeliveryDate!,
    };

    createDeliveredUpdate({
      body: deliveredUpdate,
      shipmentId: values.shipmentIds[0] as number,
    });
  };

  const handleDeliveryDateUpdate = (values: DeliveryDateUpdateInterface) => {
    setIsModalOpen(false);

    const deliveryDateUpdate: UpdateShipmentDeliveryDateBody = {
      updatedDeliveryDate: values.expectedDeliveryDate!,
      notes: values.notes,
    };

    updateShipmentDeliveryDate({
      body: deliveryDateUpdate,
      shipmentId: values.shipmentIds[0] as number,
    });
  };

  const handleSplitShipment = (shipmentId: number) => {
    setIsModalOpen(false);
    splitShipment(shipmentId);
  };

  const resetValues = () => {
    setIsModalOpen(false);
    setShipmentStatus(null);
    setShipmentIdToUpdate(null);
    setShipmentDate("");
    setIsSplittingShipment(false);
  };

  useEffect(() => {
    const fetchClientOrderDetails = async () => {
      const response = await getClientOrderDetails({
        clientOrderId: Number(clientOrderId),
      });
      const details = Array.isArray(response)
        ? response[0].data.clientOrderDetails
        : response.data.clientOrderDetails;
      setClientOrderDetails(details);
    };

    fetchClientOrderDetails();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (getClientOrderDetailsState === API_REQUEST_STATE.ERROR) {
      console.error("error getting client order details:", error);
    }
    // eslint-disable-next-line
  }, [getClientOrderDetailsState]);

  useEffect(() => {
    if (updateShipmentDeliveryDateState === API_REQUEST_STATE.SUCCESS) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShipmentDeliveryDateState]);

  useEffect(() => {
    if (createInTransitUpdateState === API_REQUEST_STATE.SUCCESS) {
      refetchClientOrder();
      resetValues();
    }
    if (createDeliveredUpdateState === API_REQUEST_STATE.ERROR) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInTransitUpdateState]);

  useEffect(() => {
    if (createDeliveredUpdateState === API_REQUEST_STATE.SUCCESS) {
      refetchClientOrder();
      resetValues();
    }
    if (createDeliveredUpdateState === API_REQUEST_STATE.ERROR) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDeliveredUpdateState]);

  useEffect(() => {
    if (updateShipmentDeliveryDateState === API_REQUEST_STATE.SUCCESS) {
      refetchClientOrder();
      resetValues();
    }
    if (updateShipmentDeliveryDateState === API_REQUEST_STATE.ERROR) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShipmentDeliveryDateState]);

  useEffect(() => {
    if (splitShipmentState.success) {
      resetSplitShipment();
      refetchClientOrder();
      resetValues();
    }
    if (splitShipmentState.error) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [splitShipmentState.success, splitShipmentState.error]);

  const getShipmentUpdateDeliveryDate = (update: ShipmentUpdateItem) => {
    if (
      (update.shipment_update_type.id !== SHIPMENT_UPDATE_TYPE.DELIVERED_FINAL &&
        update.shipment_update_type.id !== SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE) ||
      !update.expected_date
    )
      return;

    return `- ${moment(update.expected_date).format("DD-MM-YYYY")}`;
  };

  const updateHasNotes = (update: ShipmentUpdateItem): boolean => {
    return !!(update.notes && update.notes.length > 0);
  };

  const updateHasTrackingLink = (update: ShipmentUpdateItem): boolean => {
    return !!(update.tracking_link && update.tracking_link.length > 0);
  };

  const handleOpenPoShipments = (poId: string) => {
    if (poShipmentsOpen === poId) {
      setPoShipmentsOpen(undefined);
    } else {
      setPoShipmentsOpen(poId);

      setTimeout(() => {
        document.getElementById(poId)?.scrollIntoView({ behavior: "smooth" });
      });
    }
  };

  return (
    <Page
      title={`Client Order - ${clientOrder?.external_client_order_id}`}
      isLoading={loading}
      error={error}
    >
      <ClientOrderContainer>
        <Panel
          key="client-order-overview"
          withWrapper
          title="Client Order Overview"
          allignTitle="left"
        >
          <ShipmentContainer>
            {clientOrder?.address && (
              <AddressBox address={clientOrder.address} title="Final Address" />
            )}
          </ShipmentContainer>
        </Panel>

        <Heading level={5} css={{ marginTop: "20px" }}>
          Shipments
        </Heading>

        {!clientOrder?.shipments || clientOrder.shipments.length === 0 ? (
          <p>There are no shipments</p>
        ) : (
          clientOrderDetails &&
          clientOrderDetails.purchaseOrders.map((purchaseOrder: any) => {
            const shipmentsByPo = clientOrder?.shipments?.filter(
              shipment =>
                shipment.purchase_order?.purchase_order_uuid ===
                purchaseOrder.purchaseOrderDocumentUid
            );

            if (!shipmentsByPo || shipmentsByPo.length === 0) return null;

            return (
              <Panel
                key={`po-${shipmentsByPo![0].purchase_order?.purchase_order_uuid}`}
                withWrapper
                title={`${shipmentsByPo![0].purchase_order?.purchase_order_uuid}`}
                allignTitle="left"
              >
                <ShipmentContainer>
                  <PoLine id={shipmentsByPo![0].purchase_order?.purchase_order_uuid} />

                  <>
                    <AlignedLabel>Quantity:</AlignedLabel>
                    {purchaseOrder.purchaseOrderLines[0].quantity}
                  </>
                  <br />
                  <>
                    <AlignedLabel>Product Name:</AlignedLabel>
                    {purchaseOrder.purchaseOrderLines[0].productName}
                  </>

                  <br />

                  <PrimaryButton
                    borderRadius={"square"}
                    css={{ marginTop: "20px" }}
                    onClick={() => {
                      shipmentsByPo![0].purchase_order?.purchase_order_uuid &&
                        handleOpenPoShipments(
                          shipmentsByPo![0].purchase_order?.purchase_order_uuid
                        );
                    }}
                  >
                    {poShipmentsOpen === shipmentsByPo![0].purchase_order?.purchase_order_uuid
                      ? "Hide"
                      : "View"}{" "}
                    Shipments {!!shipmentsByPo && `(${shipmentsByPo.length})`}
                  </PrimaryButton>

                  {poShipmentsOpen === shipmentsByPo![0].purchase_order?.purchase_order_uuid &&
                    shipmentsByPo?.map(shipment => {
                      const lastShipmentUpdate = shipment.shipment_updates
                        ? getLastShipmentUpdate(shipment.shipment_updates)
                        : undefined;
                      const deliveredIntermediateUpdates = shipment.shipment_updates
                        ? shipment.shipment_updates.filter(
                            update =>
                              update.shipment_update_type.id ===
                              SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE
                          )
                        : [];
                      const lastDeliveredIntermediateUpdate = deliveredIntermediateUpdates
                        ? getLastShipmentUpdate(deliveredIntermediateUpdates)
                        : undefined;

                      return (
                        <Panel
                          key={shipment.id}
                          withWrapper
                          title={`Shipment`}
                          allignTitle="left"
                          iconName={"shopping-shipping"}
                        >
                          <ShipmentContainer>
                            {shipment.shipment_updates && (
                              <>
                                <AlignedLabel>Expected date:</AlignedLabel>
                                {getShipmentExpectedDate(shipment.shipment_updates)}
                              </>
                            )}
                            <div>
                              <AlignedLabel>Status:</AlignedLabel>
                              {shipment.shipment_status.shipment_status_name}
                            </div>

                            {shipment.purchase_order?.address && (
                              <AddressBox
                                address={shipment.purchase_order.address}
                                title="Initial Address"
                              />
                            )}

                            <TimelineContainer>
                              <Timeline>
                                {shipment.shipment_updates &&
                                  shipment.shipment_updates?.map(update => (
                                    <TimelineSection
                                      key={`update_${shipment.id}_${update.shipment_update_type.id}`}
                                    >
                                      <h3 style={{ margin: "0" }}>
                                        {update.shipment_update_type.shipment_update_type_name}{" "}
                                        {getShipmentUpdateDeliveryDate(update)}
                                      </h3>
                                      {update.shipment_update_type.update_message}
                                      {updateHasNotes(update) && (
                                        <>
                                          <h5 style={{ margin: "0" }}>Notes: </h5>
                                          <p style={{ margin: "0" }}>{update.notes}</p>
                                        </>
                                      )}
                                      {updateHasTrackingLink(update) && (
                                        <>
                                          <h5 style={{ margin: "0" }}>Tracking Link: </h5>
                                          <p style={{ margin: "0" }}>{update.tracking_link}</p>
                                        </>
                                      )}
                                    </TimelineSection>
                                  ))}
                              </Timeline>
                            </TimelineContainer>
                          </ShipmentContainer>

                          {(shipment.shipment_status.id === SHIPMENT_STATUS.DELIVERY_INTERMEDIATE ||
                            shipment.shipment_status.id === SHIPMENT_STATUS.IN_TRANSIT) && (
                            <ShipmentContainer>
                              <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                                <SecondaryButton
                                  appearance="whiteButtonBlueText"
                                  onClick={() => {
                                    setShipmentStatus(shipment.shipment_status.id);
                                    console.log(
                                      "lastDeliveredIntermediateUpdate",
                                      lastDeliveredIntermediateUpdate
                                    );
                                    if (lastDeliveredIntermediateUpdate) {
                                      setShipmentAddress(
                                        mapAddressToDropdownItem([
                                          lastDeliveredIntermediateUpdate.address!,
                                        ])[0]
                                      );
                                    }
                                    setShipmentIdToUpdate(shipment.id);
                                    setShipmentDate(
                                      moment(
                                        lastShipmentUpdate?.expected_date ?? Date.now()
                                      ).format("YYYY-MM-DD")
                                    );
                                    setIsModalOpen(true);
                                  }}
                                >
                                  Generate Updates
                                </SecondaryButton>
                                {shipment.shipment_status.id ===
                                  SHIPMENT_STATUS.DELIVERY_INTERMEDIATE && (
                                  <SecondaryButton
                                    appearance="whiteButtonBlueText"
                                    onClick={() => {
                                      setShipmentStatus(shipment.shipment_status.id);
                                      setShipmentIdToUpdate(shipment.id);
                                      setIsModalOpen(true);
                                      setIsSplittingShipment(true);
                                    }}
                                  >
                                    Split Shipment
                                  </SecondaryButton>
                                )}
                              </ButtonGroup>
                            </ShipmentContainer>
                          )}
                        </Panel>
                      );
                    })}
                </ShipmentContainer>
              </Panel>
            );
          })
        )}

        <Modal
          id={`shipmentUpdateModal`}
          isOpen={isModalOpen}
          handleClose={() => resetValues()}
          triggerRef={modalRef}
        >
          <ModalContainer>
            {shipmentStatus === SHIPMENT_STATUS.DELIVERY_INTERMEDIATE && !isSplittingShipment && (
              <InTransitUpdateForm
                addresses={internalAddresses.concat(mappedFinalAddress)}
                handleResponse={handleInTransitUpdate}
                formValues={{
                  ...initialInTransitFieldValues,
                  shipmentIds: shipmentIdToUpdate ? [shipmentIdToUpdate] : [],
                  fromDestination: shipmentAddress!,
                }}
                reset={resetValues}
              />
            )}
            {shipmentStatus === SHIPMENT_STATUS.IN_TRANSIT && (
              <DeliveryUpdateForm
                handleDeliveredResponse={handleDeliveredUpdate}
                handleDeliveryDateResponse={handleDeliveryDateUpdate}
                deliveredValues={{
                  ...initialDeliveredFieldValues,
                  shipmentIds: shipmentIdToUpdate ? [shipmentIdToUpdate] : [],
                  actualDeliveryDate: moment(Date.now()).format("YYYY-MM-DD"),
                }}
                deliveryDateValues={{
                  ...initialDeliveryDateFieldValues,
                  shipmentIds: shipmentIdToUpdate ? [shipmentIdToUpdate] : [],
                  expectedDeliveryDate: shipmentDate,
                }}
              />
            )}
            {isSplittingShipment && (
              <SplitShipmentForm
                handleSplitShipmentResponse={handleSplitShipment}
                reset={resetValues}
                shipmentId={shipmentIdToUpdate ?? 0}
              />
            )}
          </ModalContainer>
        </Modal>
      </ClientOrderContainer>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return { splitShipmentState: state.splitShipmentReducer };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    splitShipment: (shipmentId: number) => dispatch(splitShipmentAction(shipmentId)),
    resetSplitShipment: () => dispatch(splitShipmentReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewClientOrderShipments);
