import { SOURCING_API } from "../../../../../api/baseConfig";

export const deleteClientOrderAPI = async (clientOrderId: number) => {
  try {
    return await SOURCING_API.delete(`/client-orders/${clientOrderId}`);
  } catch (err) {
    console.log("Error in delete client order API call", err);
    throw err;
  }
};
