import { uniqBy } from "lodash";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import { prettifyProductName } from "../../../shared/mappers";
import { PendingDemandItem, Supplier, SupplierItem } from "../graphql/types";
import { ProductPendingDemand } from "../types";

export const mapAttachmentsToDTO = (attachments: UploadedFile[]) => {
  return attachments.map(attachment => ({
    originalFilename: attachment.original_filename,
    uuid: attachment.attachment_uuid,
    notes: "",
    metadata: JSON.stringify({}),
  }));
};

export const mapDemandToProducts = (pendingDemand: PendingDemandItem[]) => {
  const productPendingDemand: ProductPendingDemand[] = [];

  for (let row of pendingDemand!) {
    const indexOfProduct = productPendingDemand.findIndex(
      demand => demand.product.id === row.product?.id
    );
    if (indexOfProduct === -1) {
      const newProduct: ProductPendingDemand = {
        product: {
          id: row.product?.id!,
          product_name: row.product?.product_name!,
        },
        quantityToBePurchased: row.quantity_to_purchase!,
      };
      productPendingDemand.push(newProduct);
    } else {
      const additionalQuantity = row.quantity_to_purchase!;
      const copyOfPDperProduct = [...productPendingDemand];
      const currentQuantity = copyOfPDperProduct[indexOfProduct].quantityToBePurchased;
      const updatedQuantity = currentQuantity + additionalQuantity;
      productPendingDemand[indexOfProduct].quantityToBePurchased = updatedQuantity;
    }
  }

  return productPendingDemand;
};

export const getUniquePurchaseOrderLines = (purchaseOrderLines: any[]) => {
  return uniqBy(purchaseOrderLines, purchaseOrderLine => {
    return purchaseOrderLine.product?.value;
  });
};

export const getMaxLeadTimeDate = (leadTime: number, supplierDetails: SupplierItem | null) => {
  // Configuration to reduce pressure on Engine and
  // provide a gap between client date and supplier date
  const adjustment = supplierDetails
    ? supplierDetails.supplier_delivery_date_config || supplierDetails.global_delivery_date_config
    : 0;
  if (leadTime < adjustment) leadTime = 0;
  else leadTime -= adjustment;

  const todaysDate = new Date();
  if (leadTime === 0) {
    todaysDate.setDate(todaysDate.getDate() + 14);
  }
  if (leadTime !== 0) {
    todaysDate.setDate(todaysDate.getDate() + leadTime);
  }
  return todaysDate.toISOString().split("T")[0];
};

export const getDefaultEstimatedDate = () => {
  const defaultEstimatedDeliveryDate = new Date();
  defaultEstimatedDeliveryDate.setDate(defaultEstimatedDeliveryDate.getDate() + 14);
  return defaultEstimatedDeliveryDate.toISOString().split("T")[0];
};

export const mapDemandToClients = (pendingDemand: PendingDemandItem[]) => {
  const uniqueClients = uniqBy(pendingDemand, demand => {
    return demand.client_order_line?.client_order.organisation;
  });

  return uniqueClients.map(client => {
    return {
      label: client.client_order_line?.client_order.organisation.organisation_name!,
      value: client.client_order_line?.client_order.organisation.id!,
    };
  });
};

export const mapDemandToOrders = (pendingDemand: PendingDemandItem[]) => {
  const uniqueClientOrderLines = uniqBy(pendingDemand, demand => {
    return demand.client_order_line?.client_order?.id;
  });

  return uniqueClientOrderLines.map(client => {
    return {
      label: String(
        client.client_order_line?.client_order?.external_client_order_id ||
          client.client_order_line?.client_order?.id
      )!,
      value: client.client_order_line?.client_order.id!,
    };
  });
};

export const mapSupplier = (supplierDetails: Supplier[]) => {
  return supplierDetails.map(supplier => {
    return {
      supplier_delivery_date_config:
        supplier.supplier_quote.organisation.supplier_details[0].supplier_delivery_date_config,
      global_delivery_date_config:
        supplier.supplier_quote.organisation.supplier_details[0].global_delivery_date_config,
      delivery_type_id: supplier.supplier_quote.organisation.supplier_details[0].delivery_type_id,
      organisation_name: supplier.supplier_quote.organisation.organisation_name,
      id: supplier.supplier_quote.organisation.id,
    };
  });
};

export const mapSupplierOptions = (suppliers: SupplierItem[]) => {
  return suppliers.map(supplier => {
    return {
      label: supplier.organisation_name,
      value: supplier.id,
      deliveryTypeId: supplier.delivery_type_id,
    };
  });
};

export const mapProductDropdownItems = (productData: ProductPendingDemand[]) => {
  return productData.map(product => {
    return {
      label: prettifyProductName(product.product.product_name),
      value: product.product.id,
    };
  });
};
