import { ApolloError } from "@apollo/client";

import { useGetWarehouseAddressesQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { AddressItem } from "../types";

export interface HookResult {
  addresses?: AddressItem[];
  addressesLoading: boolean;
  addressesError?: ApolloError;
}

export const useWarehouseAddresses = (): HookResult => {
  const { data, loading, error } = useGetWarehouseAddressesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    addresses: data?.address,
    addressesLoading: loading,
    addressesError: error,
  };
};
