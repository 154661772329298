import * as Yup from "yup";

export const validationSchema = Yup.object({
  quote_valid_from: Yup.string().required("Date is required"),
  quote_valid_to: Yup.string().required("Date is required"),
  quote_lines: Yup.array()
    .of(
      Yup.object({
        rfq_line_id: Yup.number().required("RFQ ID is required"),
        minimum_order_quantity: Yup.string().required("Minimum Order Quanitity is required"),
        lead_time_max_days: Yup.string(),
      })
    )
    .min(1, "Please provide at least 1 Quote"),
});
