import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { Icon, styled } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

import { ToolTip } from "../../shared/components/atoms/labels/ToolTip/ToolTip";
import {
  CardIconTextWrapper,
  CardTitle,
  CardTopSectionWrapper,
  CardWrapper,
  Label,
  ScrollBar,
  ScrollItem,
  reactSelectDropdownStyling,
} from "../../styles/SharedStyles";

const LinkWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  marginLeft: "30px",
  color: "#005AE1",
  fontSize: "13px",
});

export const ToolTipWrapper = styled("div", {
  color: "#005AE1",
  paddingTop: "0px",
  marginLeft: "5px",
  marginTop: "0px",
});

export const EmptyListMessageWrapper = styled("div", {
  justifyContent: "center",
  marginTop: "60px",
  display: "flex",
  flexDirection: "row",
  color: "#8D8D8D",
  fontSize: "18px",
});

export const DropdownWrapper = styled("div", {
  fontSize: "15px",
  lineHeight: "18px",
  display: "flex",
  flexDirection: "row",
  width: "100%",
  justifyContent: "center",
  alignItems: "center",
});

export const IconTextWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const TextLine = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginLeft: "5px",
});

export const NotificationWrapper = styled("div", {
  paddingLeft: "6px",
  fontSize: "12px",
});

export interface VerticalCardProps {
  scrollBarContent: ScrollBarContentProps[];
  cardTitle: string;
  contentStyles?: any;
  notificationNumber?: string;
  sortBy?: boolean;
  linkText?: string;
  link?: string;
  tooltip?: string;
}

const defaultContentStyles = {
  width: "30%",
  maxWidth: "475px",
  height: "473px",
};

export interface ScrollBarContentProps {
  date?: string; //make this required
  orderId: number;
  iconName: IconName;
  organisationName?: string;
  link?: string;
}

export const VerticalCard = ({
  cardTitle,
  scrollBarContent,
  notificationNumber,
  sortBy,
  linkText,
  link,
  tooltip,
  contentStyles = defaultContentStyles,
}: VerticalCardProps) => {
  const [sortByValue, setSortByValue] = useState("Select");

  const sortByDate = function (a: ScrollBarContentProps, b: ScrollBarContentProps) {
    const Moment = require("moment");
    const date1 = new Moment(a.date).format("YYYYMMDD");
    const date2 = new Moment(b.date).format("YYYYMMDD");
    return date2 - date1;
  };
  const scrollBarContentSortedByDate = scrollBarContent.slice().sort(sortByDate);

  const sortByID = function (a: ScrollBarContentProps, b: ScrollBarContentProps) {
    return a.orderId > b.orderId ? 1 : -1;
  };
  const scrollBarContentSortedByID = scrollBarContent.slice().sort(sortByID);

  const ScrollBarDisplay = ({ item }: { item: ScrollBarContentProps }) => {
    return (
      <ScrollItem key={item.orderId}>
        <IconTextWrapper>
          <Icon name={item.iconName}></Icon>
          {item.date ? (
            <TextLine>
              {moment(item.date).format("DD/MM/YYYY")}
              {" - "}
              {item.organisationName || "Unspecified organisation"}
            </TextLine>
          ) : (
            <TextLine>{item.organisationName}</TextLine>
          )}
        </IconTextWrapper>
        <LinkWrapper>
          <Link to={`${link}${item.orderId}`}>{linkText}</Link>
        </LinkWrapper>
      </ScrollItem>
    );
  };

  return (
    <CardWrapper css={contentStyles}>
      <CardTopSectionWrapper>
        <CardTitle>
          {cardTitle}
          {notificationNumber && (
            <NotificationWrapper>{`(${notificationNumber})`}</NotificationWrapper>
          )}
          {tooltip && <ToolTip message={tooltip}></ToolTip>}
        </CardTitle>
        {scrollBarContent.length === 0 && (
          <EmptyListMessageWrapper>Hmmm... nothing to see here</EmptyListMessageWrapper>
        )}
        {sortBy && scrollBarContent.length !== 0 ? (
          <DropdownWrapper>
            <Label style={{ marginRight: "5px" }}>Sort by:</Label>
            <Select
              styles={reactSelectDropdownStyling}
              maxMenuHeight={220}
              isSearchable={true}
              options={[
                { label: "Date", value: "Date" },
                { label: "ID", value: "ID" },
              ]}
              id={"1"}
              value={{ label: sortByValue, value: sortByValue }}
              onChange={value => setSortByValue(value?.value || "")}
            />
          </DropdownWrapper>
        ) : (
          <></>
        )}
      </CardTopSectionWrapper>
      {sortByValue === "Select" && (
        <ScrollBar>
          {scrollBarContent.map(item => {
            const itemLink = `${link}${item.link !== undefined ? item.link : item.orderId || ""}`;

            return (
              <ScrollItem key={item.orderId}>
                <CardIconTextWrapper>
                  <Icon name={item.iconName}></Icon>
                  {item.date ? (
                    <TextLine>
                      {moment(item.date).format("DD/MM/YYYY")}
                      {" - "}
                      {item.organisationName || "Unspecified organisation"}
                    </TextLine>
                  ) : (
                    <TextLine>{item.organisationName}</TextLine>
                  )}
                </CardIconTextWrapper>
                <LinkWrapper>
                  <Link to={itemLink}>{linkText}</Link>
                </LinkWrapper>
              </ScrollItem>
            );
          })}
        </ScrollBar>
      )}
      {sortByValue === "ID" && (
        <ScrollBar>
          {scrollBarContentSortedByID.map(item => (
            <ScrollBarDisplay key={item.orderId} item={item} />
          ))}
        </ScrollBar>
      )}
      {sortByValue === "Date" && (
        <ScrollBar>
          {scrollBarContentSortedByDate.map(item => (
            <ScrollBarDisplay key={item.orderId} item={item} />
          ))}
        </ScrollBar>
      )}
    </CardWrapper>
  );
};
