import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { createStockTransferAPI } from "../../api/createStockTransfer";
import { CreateStockTransferBody } from "../../api/types";
import {
  CREATE_STOCK_TRANSFER_FAILED,
  CREATE_STOCK_TRANSFER_LOADING,
  CREATE_STOCK_TRANSFER_RESET,
  CREATE_STOCK_TRANSFER_SUCCESS,
} from "../types";

export const creatingStockTransfer = () => {
  return {
    type: CREATE_STOCK_TRANSFER_LOADING,
  };
};

export const createStockTransferSuccess = () => {
  return {
    type: CREATE_STOCK_TRANSFER_SUCCESS,
  };
};

export const createStockTransferReset = () => {
  return {
    type: CREATE_STOCK_TRANSFER_RESET,
  };
};

export const createStockTransferFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CREATE_STOCK_TRANSFER_FAILED,
    errors: errors,
  };
};

export const createStockTransferAction = (body: CreateStockTransferBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Stock Transfer", { hideProgressBar: true });
    dispatch(creatingStockTransfer());
    try {
      await createStockTransferAPI(body);
      toast.success("Stock Transfer created successfully.");
      dispatch(createStockTransferSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating stock transfer.");
      dispatch(createStockTransferFailed({ errors: (err as any).response.data.errors }));
    }
  };
};
