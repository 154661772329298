import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

import { Icon } from "@sourceful/shared-components";

import useFileUpload, { UploadedFile } from "../../attachments/fileUpload";
import FileUploader from "../../attachments/fileUploader";
import { IconWrapper, SecondaryLineDataWrapper } from "../../styles/SharedStyles";
import { DiplayAttachment } from "../../styles/SourcingStyles";
import AttachmentDownloader from "../AttachmentDownloader/AttachmentDownloader";

export interface PrefilledAttachment {
  originalFilename: string;
  attachmentUUID: string;
}

interface AttachmentProps {
  parameterName: string;
  indexAttachment: number;
  prefilledAttachment?: PrefilledAttachment;
  handleRemoveAttachmentLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const AttachmentUploader = ({
  parameterName,
  setFieldValue,
  indexAttachment,
  handleRemoveAttachmentLine,
  prefilledAttachment,
}: AttachmentProps) => {
  const [currentFile, setCurrentFile] = useState<UploadedFile | null>(
    prefilledAttachment !== undefined
      ? {
          original_filename: prefilledAttachment.originalFilename,
          attachment_uuid: prefilledAttachment.attachmentUUID,
        }
      : null
  );

  const { selectedFile, setSelectedFile, uploadFile } = useFileUpload<UploadedFile>({
    onUploadComplete: file => {
      setFieldValue(`${parameterName}[${indexAttachment}]`, file);
      setCurrentFile(file);
    },
  });

  const { addToast } = useToasts();

  const handleSubmit = () => {
    uploadFile();
  };

  useEffect(() => {
    if (selectedFile) {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFile]);

  const handleRemove = () => {
    handleRemoveAttachmentLine(indexAttachment);
    setCurrentFile(null);
  };

  return (
    <SecondaryLineDataWrapper>
      <DiplayAttachment>
        {currentFile ? (
          <AttachmentDownloader
            originalFilename={currentFile.original_filename}
            attachmentUUID={currentFile.attachment_uuid}
          />
        ) : (
          <>
            <FileUploader
              onFileSelectSuccess={file => {
                setSelectedFile(file);
              }}
              onFileSelectError={({ error }) => addToast(error, { appearance: "error" })}
            />
          </>
        )}
        <IconWrapper type="button" onClick={handleRemove}>
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DiplayAttachment>
    </SecondaryLineDataWrapper>
  );
};

export default AttachmentUploader;
