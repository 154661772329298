export interface BaseProductForm {
  product_name: string;
  weight_grams: string;
  length_mm: string;
  width_mm: string;
  height_mm: string;
  fsc_certified: boolean;
  grs_certified: boolean;
}

export const initialFieldValues: BaseProductForm = {
  product_name: "",
  weight_grams: "",
  length_mm: "",
  width_mm: "",
  height_mm: "",
  fsc_certified: false,
  grs_certified: false,
};

export const fieldNames = {
  product_name: "product_name",
  weight_grams: "weight_grams",
  length_mm: "length_mm",
  width_mm: "width_mm",
  height_mm: "height_mm",
  fsc_certified: "fsc_certified",
  grs_certified: "grs_certified",
};
