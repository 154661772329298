import { ApolloError } from "@apollo/client";

import { useGetProductStockByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStock?: ProductStockItem;
  productStockError?: ApolloError;
  productStockLoading: boolean;
}

export const useProductStock = (productStockId: number): HookResult => {
  const { data, error, loading } = useGetProductStockByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      productStockId,
    },
  });

  return {
    productStock: data && Array.isArray(data.product_stock) ? data.product_stock[0] : undefined,
    productStockError: error,
    productStockLoading: loading,
  };
};
