import getRuntimeEnv from "./getRuntimeEnv";

export const getFeatureFlagPcatEnabled = (): boolean => {
  const featureFlagPcatEnabled: string = process.env.REACT_APP_FEATURE_FLAG_PCAT
    ? process.env.REACT_APP_FEATURE_FLAG_PCAT
    : window.REACT_APP_FEATURE_FLAG_PCAT;

  return featureFlagPcatEnabled.toLowerCase() === "true";
};

export const isFeatureFlagEnabled = (featureFlag: string): boolean => {
  const featureFlagValue: string = getRuntimeEnv(featureFlag);

  return !!featureFlagValue && featureFlagValue.toLowerCase() === "true";
};

export interface WithFeatureFlagProps {
  featureFlag: string;
  children: JSX.Element;
}

export const WithFeatureFlag = ({ featureFlag, children }: WithFeatureFlagProps) => {
  return isFeatureFlagEnabled(featureFlag) ? children : null;
};
