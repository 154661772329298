import { useEffect, useMemo, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SM_ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { InlineCode } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { getIAMOrganisationMemberRoles } from "../api/getIAMOrganisationMemberRoles";

export interface IAMOrganisationMember {
  name?: string | null;
  description?: string | null;
}

const OrganisationMemberRoleNameCell = ({ row }: CellProps<IAMOrganisationMember>) => (
  <InlineCode>{row.original.name}</InlineCode>
);

const ViewIAMOrganisationMemberRoles = () => {
  const { orgUuid, displayName, userUuid, userName } =
    useParams<{ orgUuid: string; displayName: string; userUuid: string; userName: string }>();
  const [organisationMembers, setOrganisationMembers] = useState<IAMOrganisationMember[]>([]);

  const history = useHistory();
  const { roles } = useAuthorisationContext();

  const [getOrganisationMemberRolesState, getOrganisationMemberRoles] = useAPI(
    getIAMOrganisationMemberRoles,
    true
  );

  useEffect(() => {
    const fetchOrganisationMemberRoles = async () => {
      const response = await getOrganisationMemberRoles({
        orgUuid: orgUuid,
        userUuid: userUuid,
      });
      const organisationMembersdata = Array.isArray(response) ? response[0].data : response.data;
      setOrganisationMembers(organisationMembersdata);
    };

    fetchOrganisationMemberRoles().catch(error => {
      console.error("error getting organisation roles:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        widthPercent: 1,
        accessor: (organisationMember: IAMOrganisationMember) => organisationMember.name,
        Cell: OrganisationMemberRoleNameCell,
        filter: "multiRow",
      },
      {
        Header: "Description",
        widthPercent: 1,
        accessor: (organisationMember: IAMOrganisationMember) => organisationMember.description,
        filter: "multiRow",
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationMembers]
  );
  const items = SM_ADMIN_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push(
              `/admin/iam/organisations/${orgUuid}/${displayName}/member/${userUuid}/${userName}/roles/add`
            );
          },
          type: "button",
          title: "Assign Roles",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];

  return (
    <Page
      title={"IAM Organisations Member Roles"}
      isLoading={getOrganisationMemberRolesState === API_REQUEST_STATE.PENDING}
      error={getOrganisationMemberRolesState === API_REQUEST_STATE.ERROR}
    >
      <Panel
        withWrapper
        title={userName}
        text={`Assign the roles that ${userName} has while accessing your application in the ${displayName} organization`}
        allignTitle="left"
        rightItems={rightItems}
        iconName={"toggle-star-fill"}
      >
        <Table
          data={organisationMembers ?? []}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
};

export default ViewIAMOrganisationMemberRoles;
