import { ProductReferenceInterface } from "../types";

const CHAR_SEQ = "%-%";

export const mapProductReferencetoDTO = (
  productReferences: ProductReferenceInterface[],
  organisationId: number
) => {
  return productReferences.map(productReference => {
    return {
      reference_id: productReference.reference_id,
      product_id: productReference.product_id,
      supplier_organisation_id: organisationId,
      supplier_reference: [
        productReference.supplier_order_number,
        productReference.supplier_product_id,
      ].join(CHAR_SEQ),
    };
  });
};
