import { OutboundManifestLineBody } from "../api/types";
import { ManifestLineInterface } from "../formValues";
import {
  CourierServiceNameItem,
  LogisticsTypeNameItem,
  OrganisationNameItem,
  WarehouseNameItem,
} from "../graphql/types";

export const mapCourierServiceNamesToDropdownObject = (
  courierServiceNames: CourierServiceNameItem[]
) => {
  return courierServiceNames.map(courierServiceName => {
    return {
      label: courierServiceName.courier_service_name,
      value: courierServiceName.id,
    };
  });
};

export const mapOrganisationNamesToDropdownObject = (organisationNames: OrganisationNameItem[]) => {
  return organisationNames.map(organisationName => {
    return {
      label: organisationName.organisation_name,
      value: organisationName.id,
    };
  });
};

export const mapWarehouseNamesToDropdownObject = (warehouseNames: WarehouseNameItem[]) => {
  return warehouseNames.map(warehouseName => {
    return {
      label: warehouseName.warehouse_name,
      value: warehouseName.id,
    };
  });
};

export const mapLogisticsTypeNamesToDropdownObject = (
  logisticsTypeNames: LogisticsTypeNameItem[]
) => {
  return logisticsTypeNames.map(logisticsTypeName => {
    return {
      label: logisticsTypeName.logistics_type_name,
      value: logisticsTypeName.id,
    };
  });
};

export const mapManifestLinesToDTO = (manifestLines: ManifestLineInterface[]) => {
  return manifestLines.map(line => {
    const lineBody: OutboundManifestLineBody = {
      quantity: Number(line.quantity),
      external_order_line_id: -1,
      product_id: line.product?.value ?? 0,
    };
    return lineBody;
  });
};
