import { IAM_API } from "../../../../../api/baseConfig";

export interface GetIAMOrganisationsParams {
  offset: number;
  limit: number;
}

export const getIAMOrganisations = async (params: GetIAMOrganisationsParams) => {
  try {
    return await IAM_API.get("/v2/organisations", {
      params: {
        offset: params.offset,
        limit: params.limit,
      },
    });
  } catch (err) {
    console.error("Error in get organisations API call", err);
    throw err;
  }
};
