import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { cancelInboundManifestLineAPI } from "../../api/terminateInboundManifestLine/cancelInboundManifestLineAPI";
import { completeInboundManifestLineAPI } from "../../api/terminateInboundManifestLine/completeInboundManifestLineAPI";
import { rejectInboundManifestLineAPI } from "../../api/terminateInboundManifestLine/rejectInboundManifestLineAPI";
import {
  CHANGE_IBM_LINE_STATUS,
  TERMINATE_INBOUND_MANIFEST_LINE_FAILED,
  TERMINATE_INBOUND_MANIFEST_LINE_LOADING,
  TERMINATE_INBOUND_MANIFEST_LINE_RESET,
  TERMINATE_INBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export const terminatingInboundManifestLine = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_LINE_LOADING,
  };
};

export const terminateInboundManifestLineSuccess = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_LINE_SUCCESS,
  };
};

export const terminateInboundManifestLineFailed = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_LINE_FAILED,
  };
};

export const terminateInboundManifestLineReset = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_LINE_RESET,
  };
};

export interface TerminateInboundManifestLineActionBody {
  type: CHANGE_IBM_LINE_STATUS;
  inbound_manifest_line_id: number;
  inbound_manifest_id: number;
}

export const terminateInboundManifestLineAction = ({
  inbound_manifest_line_id,
  inbound_manifest_id,
  type,
}: TerminateInboundManifestLineActionBody) => {
  return async (dispatch: Dispatch) => {
    toast(`Terminating Inbound Manifest Line with type ${type}`, { hideProgressBar: true });
    dispatch(terminatingInboundManifestLine());
    try {
      if (type === CHANGE_IBM_LINE_STATUS.CANCEL) {
        await cancelInboundManifestLineAPI({ inbound_manifest_line_id, inbound_manifest_id });
      }
      if (type === CHANGE_IBM_LINE_STATUS.REJECT) {
        await rejectInboundManifestLineAPI({ inbound_manifest_line_id, inbound_manifest_id });
      }
      if (type === CHANGE_IBM_LINE_STATUS.MARK_AS_DONE) {
        await completeInboundManifestLineAPI({ inbound_manifest_line_id, inbound_manifest_id });
      }

      toast.success("Inbound Manifest Line terminated successfully.");
      dispatch(terminateInboundManifestLineSuccess());
    } catch (err) {
      toast.error("Something went wrong with terminating inbound manifest line.");
      dispatch(terminateInboundManifestLineFailed());
    }
  };
};
