import { Formik, FormikErrors } from "formik";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, CheckBox, IconText, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { RecipeRequestBody } from "../api/types";
import RecipeLineComponent from "../components/RecipeLineComponent";
import {
  RecipeForm,
  RecipeLine,
  emptyRecipeLine,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { useBaseProductNames } from "../graphql/hooks/useRecipeBaseProductNames";
import { useRecipeTypeNames } from "../graphql/hooks/useRecipeTypeNames";
import { mapRecipeLinesToDTO } from "../mappers";
import { recipeRequestAction, recipeRequestReset } from "../redux/actions/recipeRequestAction";
import { RecipeRequestReducer } from "../redux/reducers/recipeRequestReducer";
import { validationSchema } from "../validation";

export interface RecipeRequestProps {
  recipeRequestState: RecipeRequestReducer;
  recipeRequest: (body: RecipeRequestBody) => void;
  resetRecipeRequest: () => void;
}

export function RecipeRequest(props: RecipeRequestProps) {
  const [initialValues, setInitialValues] = useState<RecipeForm>(initialFieldValues);

  const { recipeRequestState, recipeRequest, resetRecipeRequest } = props;

  const onSubmit = (values: RecipeForm) => {
    const inputLines = mapRecipeLinesToDTO(values.inputLines);
    const outputLines = mapRecipeLinesToDTO(values.outputLines);

    const recipeRequestBody: RecipeRequestBody = {
      recipe_name: values.recipe_name,
      recipe_type_id: values.recipe_type?.value!,
      use_for_reporting: values.use_for_reporting,
      recipeInputs: [...inputLines],
      recipeOutputs: [...outputLines],
      is_draft: false,
    };

    const allRecipeLines = [...inputLines, ...outputLines];

    const uniqueLines = uniqBy(allRecipeLines, recipe_line => recipe_line.product_id);

    const hasDuplicateProductIds = uniqueLines.length !== allRecipeLines.length;

    if (hasDuplicateProductIds) {
      toast.error(`You have used a duplicate base product, please ensure there are no duplicates`);
      return;
    }

    recipeRequest(recipeRequestBody);
  };

  useEffect(() => {
    if (recipeRequestState.success) {
      resetRecipeRequest();
      setInitialValues({ ...initialFieldValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeRequestState.success, recipeRequestState.error]);

  const { baseProductNames, baseProductNamesLoading, baseProductNamesError } =
    useBaseProductNames();
  const { recipeTypeNames, recipeTypeNamesLoading, recipeTypeNamesError } = useRecipeTypeNames();

  const isLoading = baseProductNamesLoading || recipeTypeNamesLoading || recipeRequestState.loading;
  const error = baseProductNamesError || recipeTypeNamesError;

  return (
    <Page error={error} isLoading={isLoading} title={"Manifests - Recipe"}>
      <Panel
        withWrapper
        title={"Recipe Request Card"}
        subtitle={"A request to repackage using 'ingredient' items and resulting in 'dish' items"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <Label isRequired htmlFor={fieldNames.recipe_name}>
                      Recipe Name
                    </Label>
                    <InputField
                      id={fieldNames.recipe_name}
                      size={"medium"}
                      type={"text"}
                      value={values.recipe_name}
                      placeholder={"Input Recipe Name"}
                      handleChange={e => {
                        setFieldValue(fieldNames.recipe_name, e.target.value);
                      }}
                    />
                    {errors.recipe_name && touched.recipe_name ? (
                      <ErrorMessage>{errors.recipe_name}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                  <DataPoint>
                    <Label isRequired>Recipe Type</Label>
                    <Select
                      options={recipeTypeNames}
                      value={values.recipe_type}
                      onChange={e => {
                        setFieldValue(fieldNames.recipe_type, e);
                      }}
                      maxMenuHeight={220}
                      isSearchable={true}
                      id={fieldNames.recipe_type}
                      placeholder="Select Recipe type"
                      styles={reactSelectStyling}
                    />
                    {errors.recipe_type && touched.recipe_type ? (
                      <ErrorMessage>{errors.recipe_type}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                  <DataPoint>
                    <Label isRequired>Use For Bundle Stock Reporting</Label>
                    <CheckBox
                      handleChange={e => {
                        setFieldValue(fieldNames.use_for_reporting, e.target.checked);
                      }}
                      value={fieldNames.use_for_reporting}
                      id={fieldNames.use_for_reporting}
                      label={""}
                      isChecked={values.use_for_reporting}
                    />
                    {errors.use_for_reporting && touched.use_for_reporting ? (
                      <ErrorMessage>{errors.use_for_reporting}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                </TopPanelInputsWrapper>
                <PrimaryLineWrapper>
                  <Title>Recipe Inputs</Title>
                  {values.inputLines.map((line, inputIndex) => (
                    <RecipeLineComponent
                      key={inputIndex}
                      type="input"
                      baseProducts={baseProductNames}
                      errors={errors.inputLines as FormikErrors<RecipeLine[]>}
                      touched={touched.inputLines}
                      values={line}
                      index={inputIndex}
                      setFieldValue={setFieldValue}
                      handleRemoveRecipeLine={(inputLineIndex: number) => {
                        const fitleredRecipeLines = values.inputLines.filter(
                          (_, i) => i !== inputLineIndex
                        );
                        const newValues = { ...values };
                        newValues.inputLines = fitleredRecipeLines;
                        setInitialValues(newValues);
                      }}
                    />
                  ))}

                  <ButtonWrapper>
                    <SecondaryButton
                      className={WMSButtonGroup({ type: "smallMargin" })}
                      type="button"
                      appearance={"whiteButtonBlueText"}
                      onClick={() => {
                        setInitialValues({
                          ...values,
                          inputLines: [...values.inputLines, emptyRecipeLine],
                        });
                      }}
                    >
                      <IconText text={"Add Input Line"} primaryIcon={"alert-add-outline"} />
                    </SecondaryButton>
                  </ButtonWrapper>
                </PrimaryLineWrapper>
                {errors.inputLines && typeof errors.inputLines === "string" && (
                  <ErrorMessage> {errors.inputLines} </ErrorMessage>
                )}

                <PrimaryLineWrapper>
                  <Title>Recipe Outputs</Title>
                  {values.outputLines.map((line, outputIndex) => (
                    <RecipeLineComponent
                      key={outputIndex}
                      type="output"
                      baseProducts={baseProductNames}
                      errors={errors.outputLines as FormikErrors<RecipeLine[]>}
                      touched={touched.outputLines}
                      values={line}
                      index={outputIndex}
                      setFieldValue={setFieldValue}
                      handleRemoveRecipeLine={(outputLineIndex: number) => {
                        const fitleredRecipeLines = values.outputLines.filter(
                          (_, i) => i !== outputLineIndex
                        );
                        const newValues = { ...values };
                        newValues.outputLines = fitleredRecipeLines;
                        setInitialValues(newValues);
                      }}
                    />
                  ))}

                  <ButtonWrapper>
                    <SecondaryButton
                      className={WMSButtonGroup({ type: "smallMargin" })}
                      type="button"
                      appearance={"whiteButtonBlueText"}
                      onClick={() => {
                        setInitialValues({
                          ...values,
                          outputLines: [...values.outputLines, emptyRecipeLine],
                        });
                      }}
                    >
                      <IconText text={"Add Output Line"} primaryIcon={"alert-add-outline"} />
                    </SecondaryButton>
                  </ButtonWrapper>
                </PrimaryLineWrapper>

                {errors.outputLines && typeof errors.outputLines === "string" && (
                  <ErrorMessage> {errors.outputLines} </ErrorMessage>
                )}

                <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                  <SecondaryButton
                    type="button"
                    appearance={"whiteButton"}
                    onClick={() => {
                      setInitialValues({ ...initialFieldValues });
                    }}
                  >
                    Cancel
                  </SecondaryButton>

                  <SecondaryButton
                    type="button"
                    appearance={"blueButton"}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Confirm
                  </SecondaryButton>
                </ButtonGroup>
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    recipeRequestState: state.recipeRequestReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    recipeRequest: (body: RecipeRequestBody) => dispatch(recipeRequestAction(body)),
    resetRecipeRequest: () => dispatch(recipeRequestReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(RecipeRequest);
