import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { SHIPMENT_UPDATE_TYPE } from "../../../shared/models";

export const MOVING_UPDATES = [
  SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE,
  SHIPMENT_UPDATE_TYPE.UPDATED_ETA,
];

export const STATIC_UPDATES = [SHIPMENT_UPDATE_TYPE.IN_TRANSIT];

export interface BatchShipmentUpdateInterface {
  updateType: DropdownItem | null;
  currentAddress: DropdownItem | null;
  fromAddress: DropdownItem | null;
  toAddress: DropdownItem | null;
  clientOrganisations: DropdownItem[] | [];
  purchaseOrderNumbers: DropdownItem[] | [];
  clientOrderNumbers: DropdownItem[] | [];
}
