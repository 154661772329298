import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreateSupplierQuoteRequestBody } from "./types";

export const createSupplierQuoteRequestAPI = async (body: CreateSupplierQuoteRequestBody) => {
  try {
    return await SOURCING_API.post("/supplier-quote-requests", body);
  } catch (err) {
    console.log("Error in create supplier quote request API call", err);
    throw err;
  }
};
