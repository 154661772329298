import { BaseProductAccreditationItem } from "../types";

export const mapBaseProductsAccreditationsToCSV = (
  accreditations: BaseProductAccreditationItem[]
) => {
  return accreditations.map(data => {
    let fscClaimProductName: string = "-";
    if (data && data.product_name) {
      if (data.product_name.includes("FSC")) {
        fscClaimProductName = data?.product_name.slice(data.product_name.indexOf("FSC"));
      }

      return {
        Product: data.product_name,
        FSC_Certified: data.fsc_certified ? "Yes" : "No",
        GRS_Certified: data.grs_certified ? "Yes" : "No",
        FSC_Claim: fscClaimProductName,
      };
    } else return {};
  });
};
