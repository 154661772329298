import { WMS_API } from "../../../../../../api/baseConfig";
import { CreateStockCheckBody } from "./types";

export const createStockCheckAPI = async (createStockCheckAPIBody: CreateStockCheckBody) => {
  try {
    return await WMS_API.post("/stock-checks/", createStockCheckAPIBody);
  } catch (err) {
    console.log("Error in create stock check API call:", err);
    throw err;
  }
};
