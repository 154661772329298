import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { AddressItem } from "../types/AddressItem";

export const mapAddressToDropdownItem = (addresses: AddressItem[]): DropdownItem[] => {
  const existingIds: number[] = [];

  return addresses
    .map(address => {
      if (existingIds.includes(address.id)) {
        return undefined;
      }

      existingIds.push(address.id);

      const addressString = [
        address.address_line_1,
        address.address_line_2,
        address.address_line_3,
        address.city,
        address.country_code,
        address.postcode,
      ]
        .filter(Boolean)
        .join(", ");

      return {
        label: `${address.address_name}: ${addressString}`,
        value: address.id,
      };
    })
    .filter((item): item is DropdownItem => !!item);
};
