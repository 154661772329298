import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { markAsInTransitPurchaseOrderAPI } from "../../api/markAsInTransitPurchaseOrder";
import {
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_FAILED,
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_LOADING,
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_RESET,
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_SUCCESS,
} from "../types";

export const markAsInTransitPurchaseOrderLoading = () => {
  return {
    type: MARK_AS_IN_TRANSIT_PURCHASE_ORDER_LOADING,
  };
};

export const markAsInTransitPurchaseOrderSuccess = () => {
  return {
    type: MARK_AS_IN_TRANSIT_PURCHASE_ORDER_SUCCESS,
  };
};

export const markAsInTransitPurchaseOrderFailed = () => {
  return {
    type: MARK_AS_IN_TRANSIT_PURCHASE_ORDER_FAILED,
  };
};

export const markAsInTransitPurchaseOrderReset = () => {
  return {
    type: MARK_AS_IN_TRANSIT_PURCHASE_ORDER_RESET,
  };
};

export const markAsInTransitPurchaseOrderAction = (purchaseOrderId: number) => {
  return async (dispatch: Dispatch) => {
    toast("Marking as In Transit", { hideProgressBar: true });
    dispatch(markAsInTransitPurchaseOrderLoading());
    try {
      await markAsInTransitPurchaseOrderAPI(purchaseOrderId);
      toast.success("Order marked as 'In Transit' successfully");
      dispatch(markAsInTransitPurchaseOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with marking this order as in transit.");
      dispatch(markAsInTransitPurchaseOrderFailed());
    }
  };
};
