import moment from "moment";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  InnerPanelWrapper,
  Label,
  LinkText,
  RectangleStatusBadge,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useOrganisationById } from "../graphql/hooks/useOrganisationById";
import { InboundManifestItem, OutboundManifestItem, ProductStockItem } from "../graphql/types";

const ViewOrganisation = () => {
  const { organisation_id } = useParams<{ organisation_id: string }>();

  const { organisation, organisationLoading, organisationError } = useOrganisationById(
    Number(organisation_id)
  );

  const productStockTableColumns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.id,
      },
      {
        Header: "Location",
        widthPercent: 0.4,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.location_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: ProductStockItem) => listItem.location.location_name,
      },
      {
        Header: "Stock status",
        widthPercent: 0.4,
        accessor: (listItem: ProductStockItem) => (
          <RectangleStatusBadge
            status={listItem.stock_status.stock_status_name.replace(/\s/g, "") as any}
          >
            {listItem.stock_status.stock_status_name}
          </RectangleStatusBadge>
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Stock condition",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
      },

      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  const inboundManifestTableColumns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.05,
        accessor: (listItem: InboundManifestItem) => listItem.id,
      },
      {
        Header: "Status",
        widthPercent: 0.05,
        accessor: (listItem: InboundManifestItem) =>
          listItem.inbound_manifest_status.inbound_manifest_status_name,
        Cell: ({ _, row }: CellProps<InboundManifestItem>) => (
          <StatusBadge
            type="wms"
            statusName={row.original.inbound_manifest_status.inbound_manifest_status_name}
          />
        ),
      },
      {
        Header: "Expected Arrival Date",
        widthPercent: 0.2,
        accessor: (listItem: InboundManifestItem) => moment(listItem.expected_arrival).format("x"),
        Cell: ({ _, row }: CellProps<InboundManifestItem>) =>
          moment(row.original.expected_arrival).format("DD-MM-YYYY"),
      },
      {
        Header: "Warehouse",
        widthPercent: 0.2,
        Cell: ({ _, row }: CellProps<InboundManifestItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: InboundManifestItem) => listItem.warehouse.warehouse_name,
      },
      {
        Header: "Lines #",
        widthPercent: 0.1,
        accessor: (listItem: InboundManifestItem) =>
          listItem.inbound_manifest_lines_aggregate.aggregate?.count,
      },
      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<InboundManifestItem>) => (
          <Link to={`/manifests/inbound/review-inbound-manifest/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  const outboundManifestTableColumns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.05,
        accessor: (listItem: OutboundManifestItem) => listItem.id,
      },
      {
        Header: "Status",
        widthPercent: 0.05,
        accessor: (listItem: OutboundManifestItem) =>
          listItem.outbound_manifest_status.outbound_manifest_status_name,
        Cell: ({ _, row }: CellProps<OutboundManifestItem>) => (
          <StatusBadge
            type="wms"
            statusName={row.original.outbound_manifest_status.outbound_manifest_status_name}
          />
        ),
      },
      {
        Header: "Expected Dispatch Date",
        widthPercent: 0.2,
        accessor: (listItem: OutboundManifestItem) =>
          moment(listItem.expected_dispatch).format("x"),
        Cell: ({ _, row }: CellProps<OutboundManifestItem>) =>
          moment(row.original.expected_dispatch).format("DD-MM-YYYY"),
      },
      {
        Header: "Warehouse",
        widthPercent: 0.2,
        accessor: (listItem: OutboundManifestItem) => listItem.warehouse.warehouse_name,
        Cell: ({ _, row }: CellProps<OutboundManifestItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Lines #",
        widthPercent: 0.1,
        accessor: (listItem: OutboundManifestItem) =>
          listItem.outbound_manifest_lines_aggregate.aggregate?.count,
      },
      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<OutboundManifestItem>) => (
          <Link to={`/manifests/outbound/review-outbound-manifest/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  const organisationName = organisation?.organisation_name;

  return (
    <Page isLoading={organisationLoading} error={organisationError} title={"View Organisation"}>
      <Panel title={`${organisationName}`} subtitle={"View Organisation details below"} withWrapper>
        {
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DataPoint>
                <Label isGray>Organisation ID</Label>
                <Box>#{organisation?.id}</Box>
              </DataPoint>
              <DataPoint>
                <Label isGray>Organisation Name</Label>
                <Box>{organisationName}</Box>
              </DataPoint>
            </TopPanelInputsWrapper>
            <InnerPanelWrapper>
              <Panel allignTitle={"left"} title={`${organisationName}'s Product Stock`}>
                <Table
                  data={organisation?.product_stocks ?? []}
                  columns={productStockTableColumns}
                />
              </Panel>
            </InnerPanelWrapper>

            <InnerPanelWrapper>
              <Panel allignTitle={"left"} title={`${organisationName}'s Inbound Manifests`}>
                <Table
                  data={organisation?.inbound_manifests ?? []}
                  columns={inboundManifestTableColumns}
                />
              </Panel>
            </InnerPanelWrapper>

            <InnerPanelWrapper>
              <Panel allignTitle={"left"} title={`${organisationName}'s Outbound Manifests `}>
                <Table
                  data={organisation?.outbound_manifests ?? []}
                  columns={outboundManifestTableColumns}
                />
              </Panel>
            </InnerPanelWrapper>
          </InnerPanelWrapper>
        }
      </Panel>
    </Page>
  );
};

export default ViewOrganisation;
