import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import {
  STOCK_MANAGEMENT_COUNTRY_CODES,
  STOCK_MANAGEMENT_CURRENCIES,
  STOCK_MANAGEMENT_LOCALES,
  STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES,
} from "../../../../../shared/constants";
import { FormattedContact } from "../../updateStockManagementOrganisation/page/UpdateStockManagementOrgansiation";
import { orderFlowTypes } from "../graphql/mappers";
import { Address, Contact, Organisation } from "../graphql/types";
import { getInitialPrimaryContactValue } from "../helpers";

export interface DropdownItemStringValue {
  label: string;
  value: any;
}

export interface AddAddressToOrganisationInterface {
  isWarehouse: boolean;
  isInternal: boolean;
  addressName: string;
  addressDescription: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  postcode: string;
  countryCode: DropdownItem | null;
  region: string;
  deliveryTimelineMin: number;
  deliveryTimelineMax: number;
  deliveryTimelineUnits: string;
  deliveryTimelineBusinessDaysOnly: boolean;
  primaryContactId: DropdownItem | null;
}

export const initialAddAddressToOrganisationValues: AddAddressToOrganisationInterface = {
  isWarehouse: false,
  isInternal: false,
  addressName: "",
  addressDescription: "",
  addressLine1: "",
  addressLine2: "",
  addressLine3: "",
  city: "",
  postcode: "",
  countryCode: null,
  region: "",
  deliveryTimelineMin: 0,
  deliveryTimelineMax: 0,
  deliveryTimelineUnits: "days",
  deliveryTimelineBusinessDaysOnly: false,
  primaryContactId: null,
};

export const addAddressFieldNames = {
  isWarehouse: "isWarehouse",
  isInternal: "isInternal",
  addressName: "addressName",
  addressDescription: "addressDescription",
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  addressLine3: "addressLine3",
  city: "city",
  postcode: "postcode",
  countryCode: "countryCode",
  region: "region",
  deliveryTimelineMin: "deliveryTimelineMin",
  deliveryTimelineMax: "deliveryTimelineMax",
  deliveryTimelineUnits: "deliveryTimelineUnits",
  deliveryTimelineBusinessDaysOnly: "deliveryTimelineBusinessDaysOnly",
  primaryContactId: "primaryContactId",
};

export interface UpdateAddressDataInterface {
  isWarehouse: boolean;
  isInternal: boolean;
  addressName: string;
  addressDescription: string;
  addressLine1: string;
  addressLine2: string;
  addressLine3: string;
  city: string;
  postcode: string;
  countryCode: DropdownItem | null;
  region: string;
  deliveryTimelineMin: number;
  deliveryTimelineMax: number;
  deliveryTimelineUnits: string;
  deliveryTimelineBusinessDaysOnly: boolean;
  primaryContactId: DropdownItem | null;
}

export const initialUpdateAddressValues = (
  address: Address,
  filteredOrganisationContacts: FormattedContact[]
): UpdateAddressDataInterface => {
  const selectedPrimaryContact = getInitialPrimaryContactValue({
    organisationContacts: filteredOrganisationContacts,
    address,
  });

  return {
    isWarehouse: address.isWarehouse as boolean,
    isInternal: address.isInternal as boolean,
    addressName: address.name as string,
    addressDescription: address.addressDescription as string,
    addressLine1: address.addressLine1 as string,
    addressLine2: address.addressLine2 as string,
    addressLine3: address.addressLine3 as string,
    city: address.city as string,
    postcode: address.postcode as string,
    countryCode: {
      label: address.countryCode as string,
      value: STOCK_MANAGEMENT_COUNTRY_CODES.indexOf(address.countryCode as string),
    },
    region: address.region as string,
    deliveryTimelineMin: address.deliveryTimeline?.min as number,
    deliveryTimelineMax: address.deliveryTimeline?.max as number,
    deliveryTimelineUnits: address.deliveryTimeline?.units as string,
    deliveryTimelineBusinessDaysOnly: address.deliveryTimeline?.businessDaysOnly as boolean,
    primaryContactId: selectedPrimaryContact,
  };
};

export const updateAddressFieldNames = {
  isWarehouse: "isWarehouse",
  isInternal: "isInternal",
  addressName: "addressName",
  addressDescription: "addressDescription",
  addressLine1: "addressLine1",
  addressLine2: "addressLine2",
  addressLine3: "addressLine3",
  city: "city",
  postcode: "postcode",
  countryCode: "countryCode",
  region: "region",
  deliveryTimelineMin: "deliveryTimelineMin",
  deliveryTimelineMax: "deliveryTimelineMax",
  deliveryTimelineUnits: "deliveryTimelineUnits",
  deliveryTimelineBusinessDaysOnly: "deliveryTimelineBusinessDaysOnly",
  primaryContactId: "primaryContactId",
};

export interface AddContactToOrganisationInterface {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  emailAddress: string;
  preferredCommunicationMethodId: DropdownItemStringValue | null;
  addressId: DropdownItem | null;
}

export const initialAddContactToOrganisationValues: AddContactToOrganisationInterface = {
  firstName: "",
  lastName: "",
  jobTitle: "",
  phoneNumber: "",
  emailAddress: "",
  preferredCommunicationMethodId: null,
  addressId: null,
};

export const addContactFieldNames = {
  firstName: "firstName",
  lastName: "lastName",
  jobTitle: "jobTitle",
  phoneNumber: "phoneNumber",
  emailAddress: "emailAddress",
  preferredCommunicationMethodId: "preferredCommunicationMethodId",
  addressId: "addressId",
};

export interface UpdateContactDataInterface {
  firstName: string;
  lastName: string;
  jobTitle: string;
  phoneNumber: string;
  emailAddress: string;
  preferredCommunicationMethodId: DropdownItem | null;
  addressId: DropdownItemStringValue | null;
}

export const initialUpdateContactValues = (
  contact: Contact,
  addressName: string
): UpdateContactDataInterface => {
  const preferredCommunicationMethodId = contact.preferredCommunicationMethodId as number;
  const preferredCommunicationMethodLabel =
    STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES.find(
      type => type.value === preferredCommunicationMethodId
    )?.label ?? "Type not found";
  return {
    firstName: contact.firstName as string,
    lastName: contact.lastName as string,
    jobTitle: contact.contactJobTitle as string,
    phoneNumber: contact.contactPhoneNumber as string,
    emailAddress: contact.contactEmailAddress as string,
    preferredCommunicationMethodId: {
      label: preferredCommunicationMethodLabel,
      value: preferredCommunicationMethodId,
    },
    addressId: contact.addressId
      ? { label: addressName, value: contact.addressId as string }
      : null,
  };
};

export const updateContactFieldNames = {
  firstName: "firstName",
  lastName: "lastName",
  jobTitle: "jobTitle",
  phoneNumber: "phoneNumber",
  emailAddress: "emailAddress",
  isPrimary: "isPrimary",
  preferredCommunicationMethodId: "preferredCommunicationMethodId",
  addressId: "addressId",
};

export interface UpdateOrganisationConfig {
  paymentTerms: string;
  paymentDueDate: {
    type: DropdownItemStringValue | null;
    value: number;
  };
  xeroClientName: string;
  orderFlowTypeId: DropdownItem | null;
  paymentDisclaimer: string;
  deliveryInvoicedSeparately: boolean;
  computeDeliveryCostPerProduct: boolean;
  enableClientReferenceCollection: boolean;
  prepaidStock: boolean;
  displayProductUnitCost: boolean;
}

export interface UpdateOrganisationDataInterface {
  id: string | null;
  defaultCurrency: DropdownItem | null;
  config: UpdateOrganisationConfig;
  name: string;
  locale: DropdownItem | null;
}

export const initialUpdateOrganisationValues = (
  organisation: Organisation
): UpdateOrganisationDataInterface => {
  return {
    id: organisation?.id ?? null,
    defaultCurrency: {
      label: organisation.defaultCurrency as string,
      value: STOCK_MANAGEMENT_CURRENCIES.indexOf(organisation.defaultCurrency as string),
    },
    config: {
      paymentTerms: organisation.config?.paymentTerms as string,
      paymentDueDate: {
        type: {
          label:
            organisation.config?.paymentDueDate?.type === "days_after_invoice"
              ? "(x) days after invoice"
              : "Last day of month",
          value: organisation.config?.paymentDueDate?.type as string,
        },
        value: organisation.config?.paymentDueDate?.value ?? 0,
      },
      xeroClientName: organisation.config?.xeroClientName as string,
      orderFlowTypeId: {
        label: orderFlowTypes.find(
          orderFlowType => orderFlowType.value === organisation.config?.orderFlowTypeId
        )?.label as string,
        value: organisation.config?.orderFlowTypeId as number,
      },
      paymentDisclaimer: organisation.config?.paymentDisclaimer as string,
      deliveryInvoicedSeparately: organisation.config?.deliveryInvoicedSeparately as boolean,
      computeDeliveryCostPerProduct: organisation.config?.computeDeliveryCostPerProduct as boolean,
      enableClientReferenceCollection: organisation.config
        ?.enableClientReferenceCollection as boolean,
      prepaidStock: organisation.config?.prepaidStock as boolean,
      displayProductUnitCost: organisation.config?.displayProductUnitCost as boolean,
    },
    name: organisation.name as string,
    locale: {
      label: organisation.locale as string,
      value: STOCK_MANAGEMENT_LOCALES.findIndex(locale => locale === organisation.locale),
    },
  };
};

export const updateOrganisationFieldNames = {
  defaultCurrency: "defaultCurrency",
  organisationTypeId: "organisationTypeId",
  paymentTerms: "config.paymentTerms",
  paymentDueDateType: "config.paymentDueDate.type",
  paymentDueDateValue: "config.paymentDueDate.value",
  xeroClientName: "config.xeroClientName",
  orderFlowTypeId: "config.orderFlowTypeId",
  paymentDisclaimer: "config.paymentDisclaimer",
  deliveryInvoicedSeparately: "config.deliveryInvoicedSeparately",
  computeDeliveryCostPerProduct: "config.computeDeliveryCostPerProduct",
  enableClientReferenceCollection: "config.enableClientReferenceCollection",
  prepaidStock: "config.prepaidStock",
  displayProductUnitCost: "config.displayProductUnitCost",
  name: "name",
  locale: "locale",
};
