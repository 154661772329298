import { ApolloError } from "@apollo/client";

import { useInsertCourierServiceMutation } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { AddCourierServiceMutationOptions } from "../types";

export interface HookResult {
  addCourierService: (options: AddCourierServiceMutationOptions) => Promise<number | undefined>;
}

export interface Options {
  onError: (error: ApolloError) => void;
  onCompleted: () => void;
}

export const useAddCourierService = ({ onError, onCompleted }: Options): HookResult => {
  const [AddCourierService] = useInsertCourierServiceMutation({
    context: {
      clientName: GRAPHQL_WMS,
    },
    onError: error => onError(error),
    onCompleted: () => onCompleted(),
  });

  return {
    addCourierService: async ({
      courierId,
      courierServiceName,
    }: AddCourierServiceMutationOptions) => {
      const insertedCourierService = await AddCourierService({
        variables: {
          courierId,
          courierServiceName,
        },
      });

      return insertedCourierService.data?.insert_courier_service_one?.id;
    },
  };
};
