import { ApolloError } from "@apollo/client";

import { useGetCaseChangeAggregatedProductStockByParamsLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapAggregatedProductStock } from "../../mappers";
import { AggProductStockItem } from "../types";

export interface HookResult {
  aggregatedProductStock: AggProductStockItem[];
  aggregatedProductStockError?: ApolloError;
  aggregatedProductStockLoading: boolean;
  getAggregatedProductStock: (options: GetAggregatedProductStockOptions) => Promise<void>;
}

export interface GetAggregatedProductStockOptions {
  baseProductId: number | null;
  warehouseId: number | null;
  stockConditionId: number | null;
  organisationId: number | null;
}

export const useAggregatedProductStockByParams = (): HookResult => {
  const [getAggregatedProductStock, { data, error, loading }] =
    useGetCaseChangeAggregatedProductStockByParamsLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    aggregatedProductStock:
      data && Array.isArray(data.product_stock)
        ? mapAggregatedProductStock(data.product_stock)
        : [],
    aggregatedProductStockError: error,
    aggregatedProductStockLoading: loading,
    getAggregatedProductStock: async ({
      warehouseId,
      organisationId,
      baseProductId,
      stockConditionId,
    }: GetAggregatedProductStockOptions) => {
      getAggregatedProductStock({
        variables: { warehouseId, baseProductId, stockConditionId, organisationId },
      });
    },
  };
};
