import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup } from "@sourceful/shared-components";

import { ROLE, useAuthorisationContext } from "../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import {
  DataPoint,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { SourcingStatusBadge } from "../../../../../styles/SourcingStyles";
import { CreatePurchaseOrderApprovalBody, UserName } from "../api/types/index";
import { DIELINES_APPROVAL, EXEC_APPROVAL, FINANCE_APPROVAL, ORDER_APPROVAL } from "../copy";
import { ActionType, ApprovalTypeIds } from "../formValues";
import {
  createPurchaseOrderApprovalAction,
  createPurchaseOrderApprovalReset,
} from "../redux/actions/createPurchaseOrderApprovalAction";
import { getUsersByRoleAction } from "../redux/actions/getUserNamesByRoleAction";
import { ApprovalSectionProps, ApprovalsCopyInterface } from "../types";

const ApprovalSection = ({
  type,
  approval,
  disabled = false,
  refetchPurchaseOrder,
  createPurchaseOrderApprovalState,
  getUsersByRole,
  ...props
}: ApprovalSectionProps) => {
  const { roles } = useAuthorisationContext();
  const [usersWithRoles, setUsersWithRoles] = useState<string[]>([]);

  let approvalRole: string;
  let copyKeys: ApprovalsCopyInterface;
  const approvalTypeId: number = ApprovalTypeIds[type].valueOf();
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  switch (type) {
    case "dielines":
      approvalRole = ROLE.DIELINES_APPROVER;
      copyKeys = DIELINES_APPROVAL;
      break;
    case "order":
      approvalRole = ROLE.ORDER_APPROVER;
      copyKeys = ORDER_APPROVAL;
      break;
    case "finance":
      approvalRole = ROLE.FINANCE_APPROVER;
      copyKeys = FINANCE_APPROVAL;
      break;
    case "exec":
      approvalRole = ROLE.EXEC_APPROVER;
      copyKeys = EXEC_APPROVAL;
      break;
  }

  const judgement = ({ actionType }: { actionType: ActionType }) => {
    if (disabled) {
      toast.error("The order is not in the correct state for this approval to be updated.");
    }

    const incorrectDielinesPermissions = type === "dielines" && !roles.includes(approvalRole);
    const incorrectOrderPermissions = type === "order" && !roles.includes(approvalRole);
    const incorrectFinancePermissions = type === "finance" && !roles.includes(approvalRole);
    const incorrectExecPermissions = type === "exec" && !roles.includes(approvalRole);

    const incorrectPermissions =
      incorrectDielinesPermissions ||
      incorrectOrderPermissions ||
      incorrectFinancePermissions ||
      incorrectExecPermissions;

    if (!roles.includes(ROLE.ADMIN) && !roles.includes(ROLE.DEVELOPER) && incorrectPermissions) {
      toast.error("Incorrect approval permissions");
      return;
    }

    const purchaseOrderApproval = {
      isApproved: actionType === "approve",
      purchaseOrderApprovalTypeId: approvalTypeId,
    };

    props.createPurchaseOrderApproval(Number(purchaseOrderId), purchaseOrderApproval);
  };

  useEffect(() => {
    if (createPurchaseOrderApprovalState.success) {
      refetchPurchaseOrder();
      props.resetCreatePurchaseOrderApproval();
    }
    if (createPurchaseOrderApprovalState.success) {
      props.resetCreatePurchaseOrderApproval();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createPurchaseOrderApprovalState]);

  useEffect(() => {
    const getAllUsersWithRole = async () => {
      const allUsersWithRole: UserName[] = await getUsersByRole(approvalRole);
      const userNamesWithRole = allUsersWithRole.map(user => user.name);

      setUsersWithRoles(userNamesWithRole);
    };
    getAllUsersWithRole();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PrimaryLineWrapper>
      <Title>{copyKeys.approvalTitle}</Title>
      <PrimaryLineDataWrapper>
        <DataPoint halfWidth withUniqueMargin>
          <p>{copyKeys.approvalDescription}</p>
        </DataPoint>
        <DataPoint halfWidth withUniqueMargin>
          {approval.length !== 0 && (
            <>
              <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                <SourcingStatusBadge
                  type={
                    (approval[approval.length - 1].isApproved
                      ? "Approved"
                      : "InternallyRejected") as any
                  }
                >
                  {approval[approval.length - 1].isApproved ? "Approved" : "Rejected"}
                </SourcingStatusBadge>
              </ButtonGroup>

              <Label className={WMSButtonGroup({ type: "largeMargin" })}>
                {approval[approval.length - 1].isApproved
                  ? `Approved by: ${approval[approval.length - 1].userName}`
                  : `Rejected by: ${approval[approval.length - 1].userName}`}
                &nbsp;on {approval[approval.length - 1].createdAt}
              </Label>
            </>
          )}
          {approval.length === 0 && (
            <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
              <SecondaryButton
                type="button"
                appearance={"blueButton"}
                onClick={() => {
                  judgement({ actionType: ActionType.approve });
                }}
                disabled={
                  ![ROLE.ADMIN, ROLE.DEVELOPER, approvalRole].some(role => roles.includes(role)) ||
                  disabled
                }
              >
                Approve
              </SecondaryButton>

              <SecondaryButton
                appearance={"whiteButton"}
                type="button"
                onClick={() => {
                  judgement({ actionType: ActionType.reject });
                }}
                disabled={
                  ![ROLE.ADMIN, ROLE.DEVELOPER, approvalRole].some(role => roles.includes(role)) ||
                  disabled
                }
              >
                Reject
              </SecondaryButton>
            </ButtonGroup>
          )}
        </DataPoint>
        <DataPoint withUniqueMargin fullWidth>
          <p> Permissions: {usersWithRoles.join(", ")}</p>
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createPurchaseOrderApprovalState: state.createPurchaseOrderApprovalReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createPurchaseOrderApproval: (purchaseOrderId: number, body: CreatePurchaseOrderApprovalBody) =>
      dispatch(createPurchaseOrderApprovalAction(purchaseOrderId, body)),
    resetCreatePurchaseOrderApproval: () => dispatch(createPurchaseOrderApprovalReset()),
    getUsersByRole: (role: string) => dispatch(getUsersByRoleAction(role)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApprovalSection);
