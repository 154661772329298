import { ApolloError } from "@apollo/client";

import { useGetCaseChangeToCaseProductByBaseProductParamsLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapProductNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  existingToCaseProduct: DropdownItem[] | null;
  existingToCaseProductError?: ApolloError;
  existingToCaseProductLoading: boolean;
  getToCaseExistingProduct: (options: Options) => Promise<void>;
}

interface Options {
  baseProductId: number | null;
  baseProductQuantity: number | null;
}

export const useToCaseProductByBaseProductParams = (): HookResult => {
  const [getToCaseExistingProduct, { data, error, loading }] =
    useGetCaseChangeToCaseProductByBaseProductParamsLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    existingToCaseProduct:
      data && Array.isArray(data.product) ? mapProductNamesToDropdownObject(data.product) : null,
    existingToCaseProductError: error,
    existingToCaseProductLoading: loading,
    getToCaseExistingProduct: async ({ baseProductId, baseProductQuantity }: Options) => {
      getToCaseExistingProduct({
        variables: { baseProductId, baseProductQuantity },
      });
    },
  };
};
