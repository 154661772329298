import {
  Box,
  DataPoint,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { ProductStockItem } from "../../shared/graphql/types";

interface StockCheckLineOriginalStockProps {
  productStock: ProductStockItem;
}

const StockCheckLineOriginalStock = ({ productStock }: StockCheckLineOriginalStockProps) => {
  return (
    <SecondaryLineWrapper type="withIndex">
      <SecondaryLineDataWrapper>
        <DataPoint>
          <Label isGray={true}>Product Stock ID</Label>
          <Box>{productStock.id}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Product</Label>
          <Box>{productStock.product.product_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Organisation</Label>
          <Box>{productStock.organisation?.organisation_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}> Quantity</Label>
          <Box>{productStock.quantity}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}> Condition</Label>
          <Box>{productStock.stock_condition?.stock_condition_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}> Status</Label>
          <Box>{productStock.stock_status?.stock_status_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}> Location</Label>
          <Box>{productStock.location?.location_name}</Box>
        </DataPoint>
      </SecondaryLineDataWrapper>
    </SecondaryLineWrapper>
  );
};

export default StockCheckLineOriginalStock;
