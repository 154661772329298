import { useTranslation } from "react-i18next";

import { styled } from "@sourceful/shared-components";
import { BasicValue, SizeValue } from "@sourceful/shared-types";

import { renderSizeValue } from "../../supplierQuoteRequests/createSupplierQuoteRequest/helpers/renderSizeValue";
import { titleCase } from "../../supplierQuoteRequests/createSupplierQuoteRequest/helpers/titleCase";

interface AttributesSummaryProps {
  physicalAttributes: { [attribute: string]: string | SizeValue };
  contentAttributes: { [attribute: string]: BasicValue | SizeValue };
}

const KeyContainer = styled("div", {
  width: "250px",
  minWidth: "250px",
  fontWeight: "bold",

  variants: {
    size: {
      small: {
        width: "150px",
        minWidth: "150px",
      },
    },
  },
});

const ValueContainer = styled("div", {
  maxWidth: "235px",
});

const AttributeContainer = styled("div", {
  marginLeft: "10px",
  borderTop: "1px solid rgb(196, 196, 196)",
  borderLeft: "1px solid rgb(196, 196, 196)",
  borderRight: "1px solid rgb(196, 196, 196)",
  padding: "10px",

  "&:last-of-type": {
    borderBottom: "1px solid rgb(196, 196, 196)",
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
  },

  "&:first-of-type": {
    borderTopRightRadius: "10px",
    borderTopLeftRadius: "10px",
  },
});

const SummaryContainer = styled("div", {
  display: "flex",
  gap: "50px",
});

const AttributesSummary = ({ contentAttributes, physicalAttributes }: AttributesSummaryProps) => {
  const { t } = useTranslation();
  const emptyValueString = t("quote_empty_attribute_value");

  return (
    <SummaryContainer>
      <div>
        {Object.entries(contentAttributes).map(([key, value]) => {
          return (
            <AttributeContainer key={key}>
              {value.hasOwnProperty("value") ? (
                <div style={{ display: "flex" }}>
                  <KeyContainer size="small">{titleCase(key)}:</KeyContainer>
                  {(value as BasicValue).value || emptyValueString}
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <KeyContainer size="small">{titleCase(key)}:</KeyContainer>
                  {renderSizeValue(value as SizeValue, emptyValueString)}
                </div>
              )}
            </AttributeContainer>
          );
        })}
      </div>
      <div>
        {Object.entries(physicalAttributes).map(([key, value]) => {
          return (
            <AttributeContainer key={key}>
              {typeof value === "string" ? (
                <div style={{ display: "flex" }}>
                  <KeyContainer>{titleCase(key)}:</KeyContainer>
                  <ValueContainer>{value ? value.toString() : emptyValueString}</ValueContainer>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <KeyContainer>{titleCase(key)}:</KeyContainer>
                  <ValueContainer>{renderSizeValue(value, emptyValueString)}</ValueContainer>
                </div>
              )}
            </AttributeContainer>
          );
        })}
      </div>
    </SummaryContainer>
  );
};

export default AttributesSummary;
