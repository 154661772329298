import { WMS_API } from "../../../../../api/baseConfig";
import { UpdateProductBody } from "./types";

export const updateProductAPI = async (productId: number, body: UpdateProductBody) => {
  try {
    return await WMS_API.patch(`/products/${productId}`, body);
  } catch (err) {
    console.log("Error in update product API call", err);
    throw err;
  }
};
