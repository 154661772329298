import { Auth0Provider, useAuth0 } from "@auth0/auth0-react";
import LogRocket from "logrocket";
import React, { Suspense, useEffect } from "react";
import "react-confirm-alert/src/react-confirm-alert.css";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Redirect, Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { ToastProvider } from "react-toast-notifications";
import { ToastContainer } from "react-toastify";
import { PersistGate } from "redux-persist/integration/react";
import { QueryParamProvider } from "use-query-params";

import "@sourceful/reset.css";
import "@sourceful/reset.css/forms.css";
import "@sourceful/reset.css/typography.css";
import { Heading, IconSprite, globalCss } from "@sourceful/shared-components";

import {
  GCS_API_CLIENT,
  IAM_API_CLIENT,
  LOGISTICS_API_CLIENT,
  ORDER_SERVICE_CLIENT,
  PRODUCT_CATALOGUE_API_CLIENT,
  PRODUCT_PRICING_API_CLIENT,
  SOURCING_API_CLIENT,
  STOCK_MANAGEMENT_API_CLIENT,
  WMS_API_CLIENT,
} from "./api/baseConfig";
import PrivateRoute from "./components/PrivateRoute";
import LoadingScreen from "./components/loadingScreen/LoadingScreen";
import "./i18n";
import AddIAMOrganisationMembers from "./modules/admin/IAMOrganisations/addIAMOrganisationMembers/page/AddIAMOrganisationMembers";
import AssignMemberRoles from "./modules/admin/IAMOrganisations/assignMemberRoles/page/AssignMemberRoles";
import CreateIAMOrganisation from "./modules/admin/IAMOrganisations/createIAMOrganisation/page/createIAMOrganisation";
import CreateUser from "./modules/admin/IAMOrganisations/createUser/page/CreateUser";
import ViewIAMOrganisationMembers from "./modules/admin/IAMOrganisations/viewIAMOrganisation/page/ViewIAMOrganisationMembers";
import ViewIAMOrganisationMemberRoles from "./modules/admin/IAMOrganisations/viewIAMOrganisationMemberRoles/page/ViewIAMOrganisationMemberRoles";
import ViewIAMOrganisations from "./modules/admin/IAMOrganisations/viewIAMOrganisations/page/ViewIAMOrganisations";
import ViewIAMUsers from "./modules/admin/IAMOrganisations/viewIAMUsers/page/ViewIAMUsers";
import ViewAdminDashboard from "./modules/admin/dashboards/viewAdminDashboard/page/ViewAdminDashboard";
import CreateOrganisation from "./modules/admin/organisations/createOrganisation/page/CreateOrganisation";
import UpdateStockManagementOrganisation from "./modules/admin/organisations/updateStockManagementOrganisation/page/UpdateStockManagementOrgansiation";
import ViewStockManagementOrganisations from "./modules/admin/organisations/viewStockManagementOrganisations/page/ViewStockManagementOrganisations";
import UpdateSku from "./modules/admin/skus/updateSku/page/UpdateSku";
import ViewSkus from "./modules/admin/skus/viewSkus/page/ViewSkus";
import ViewLogisticsDashboard from "./modules/logistics/dashboards/viewLogisticsDashboard/page/ViewLogisticsDashboard";
import UpdateShipmentsInBatch from "./modules/logistics/shipments/updateShipmentsInBatch/page/UpdateShipmentsInBatch";
import ViewClientOrderShipments from "./modules/logistics/shipments/viewClientOrderShipments/page/ViewClientOrderShipments";
import ViewShipments from "./modules/logistics/shipments/viewShipments/page/ViewShipments";
import CreateOrder from "./modules/orders/createOrder/page/CreateOrder";
import ViewAttributesTypes from "./modules/pcat/viewAttributesTypes/page/ViewAttributesTypes";
import ViewBaseProductOverview from "./modules/pcat/viewBaseProductVersions/page/viewBaseProductVersions";
import ViewPCatBaseProducts from "./modules/pcat/viewBaseProducts/page/ViewBaseProducts";
import HelpHome from "./modules/shared/help/HelpHome";
import ViewHomepage from "./modules/shared/homepage/page/ViewHomepage";
import CreateQuotationCostType from "./modules/sourcing/admin/quotationCostTypes/createQuotationCostType/page/CreateQuotationCostType";
import UpdateQuotationCostType from "./modules/sourcing/admin/quotationCostTypes/updateQuotationCostType/page/UpdateQuotationCostType";
import ViewAdminPanel from "./modules/sourcing/admin/viewAdminPanel/page/viewAdminPanel";
import UpdateClientOrder from "./modules/sourcing/clientOrders/updateClientOrder/page/UpdateClientOrder";
import ViewClientOrders from "./modules/sourcing/clientOrders/viewClientOrders/page/ViewClientOrders";
import ViewPendingDemand from "./modules/sourcing/clientOrders/viewPendingDemand/page/ViewPendingDemand";
import ViewSourcingDashboard from "./modules/sourcing/dashboards/viewSourcingDashboard/page/ViewSourcingDashboard";
import SourcingUserManual from "./modules/sourcing/help/page/ViewUserManual";
import CreatePurchaseOrder from "./modules/sourcing/purchaseOrders/createPurchaseOrder/page/CreatePurchaseOrder";
import SupplierResponseStatusConfirmation from "./modules/sourcing/purchaseOrders/createPurchaseOrderSupplierResponse/components/StatusConfirmation";
import CreatePurchaseOrderSupplierResponse from "./modules/sourcing/purchaseOrders/createPurchaseOrderSupplierResponse/page/CreatePurchaseOrderSupplierResponse";
import UpdatePurchaseOrder from "./modules/sourcing/purchaseOrders/updatePurchaseOrders/page/UpdatePurchaseOrder";
import ViewPurchaseOrders from "./modules/sourcing/purchaseOrders/viewPurchaseOrders/page/ViewPurchaseOrders";
import CreateQCInspection from "./modules/sourcing/qualityControlInspections/createQCInspection/page/CreateQCInspection";
import ViewQCInspections from "./modules/sourcing/qualityControlInspections/viewQCInspections/page/ViewQCInspections";
import SourcingCreateDefinedReport from "./modules/sourcing/reports/createDefinedReport/page/CreateDefinedReport";
import CreateRequestForQuotation from "./modules/sourcing/supplierQuoteRequests/createSupplierQuoteRequest/page/CreateRequestForQuotation";
import ViewSupplierQuoteRequest from "./modules/sourcing/supplierQuoteRequests/viewSupplierQuoteRequest/page/ViewSupplierQuoteRequest";
import ViewRequestsForQuotations from "./modules/sourcing/supplierQuoteRequests/viewSupplierQuoteRequests/page/ViewRequestsForQuotations";
import CreateQuotation from "./modules/sourcing/supplierQuotes/createQuotes/createInternalQuotes/page/createInternalQuotes";
import CreateQuotationResponse from "./modules/sourcing/supplierQuotes/createQuotes/createInternalResponse/page/createInternalResponse";
import SupplierResponse from "./modules/sourcing/supplierQuotes/createQuotes/createSupplierResponse/page/createSupplierResponse";
import UpdateQuotation from "./modules/sourcing/supplierQuotes/updateQuotes/page/UpdateInternalQuote";
import ViewSupplierQuotes from "./modules/sourcing/supplierQuotes/viewSupplierQuotes/page/ViewSupplierQuotes";
import CreateLocaleHoliday from "./modules/sourcing/suppliers/createLocaleHoliday/page/CreateLocaleHoliday";
import CreateSupplier from "./modules/sourcing/suppliers/createSupplier/page/CreateSupplier";
import ViewDeliveryDates from "./modules/sourcing/suppliers/viewDeliveryDates/page/ViewDeliveryDates";
import ViewSupplier from "./modules/sourcing/suppliers/viewSupplier/page/ViewSupplier";
import ViewSuppliers from "./modules/sourcing/suppliers/viewSuppliers/page/ViewSuppliers";
import ViewSuppliersDashboard from "./modules/sourcing/suppliers/viewSuppliersDashboard/page/ViewSuppliersDashboard";
import AddAddress from "./modules/wms/addresses/addAddress/page/AddAddress";
import ViewAddresses from "./modules/wms/addresses/viewAddresses/page/ViewAddresses";
import ViewWMSAdminPanel from "./modules/wms/admin/viewWMSAdminPanel/page/viewWMSAdminPanel";
import CreateCaseChange from "./modules/wms/caseChange/createChange/page/CreateCaseChange";
import AddCourier from "./modules/wms/couriers/addCourier/page/AddCourier";
import AddCourierService from "./modules/wms/couriers/addCourierService/page/AddCourierService";
import ViewCourierServices from "./modules/wms/couriers/viewCourierServices/page/ViewCourierServices";
import ViewHomeDashboard from "./modules/wms/dashboards/viewHomeDashboard/page/ViewHomeDashboard";
import ViewInventoryDashboard from "./modules/wms/dashboards/viewInventoryDashboard/page/ViewInventoryDashboard";
import ViewManifestDashboard from "./modules/wms/dashboards/viewManifestDashboard/page/ViewManifestDashboard";
import ViewOperationsDashboard from "./modules/wms/dashboards/viewOperationsDashboard/page/ViewOperationsDashboard";
import DeletFileCopies from "./modules/wms/fileCopies/deleteFileCopies/page/DeleteFileCopies";
import ViewFileCopies from "./modules/wms/fileCopies/viewFileCopies/page/ViewFileCopies";
import WMSSetupGuide from "./modules/wms/help/ViewSetupGuide";
import WMSUserManual from "./modules/wms/help/ViewUserManual";
import CreateInboundManifest from "./modules/wms/inboundManifests/createInboundManifest/page/CreateInboundManifest";
import FulfilInboundManifest from "./modules/wms/inboundManifests/fulfilInboundManifest/page/FulfilInboundManifest";
import ViewInboundManifest from "./modules/wms/inboundManifests/viewInboundManifest/page/ViewInboundManifest";
import ViewInboundManifests from "./modules/wms/inboundManifests/viewInboundManifests/page/ViewInboundManifests";
import AddLocation from "./modules/wms/locations/addLocation/page/AddLocation";
import ViewLocation from "./modules/wms/locations/viewLocation/page/ViewLocation";
import ViewLocations from "./modules/wms/locations/viewLocations/page/ViewLocations";
import AddOrganisation from "./modules/wms/organisations/addOrganisation/page/AddOrganisation";
import ViewOrganisation from "./modules/wms/organisations/viewOrganisation/page/ViewOrganisation";
import ViewOrganisations from "./modules/wms/organisations/viewOrganisations/page/ViewOrganisations";
import CreateOutboundManifest from "./modules/wms/outboundManifests/createOutboundManifest/page/CreateOutboundManifest";
import FulfilOutboundManifest from "./modules/wms/outboundManifests/fulfilOutboundManifest/page/FulfilOutboundManifest";
import ViewOutboundManifest from "./modules/wms/outboundManifests/viewOutboundManifest/page/ViewOutboundManifest";
import ViewOutboundManifests from "./modules/wms/outboundManifests/viewOutboundManifests/page/ViewOutboundManifests";
import ViewProductStock from "./modules/wms/productStocks/viewProductStock/page/ViewProductStock";
import ViewProductStocks from "./modules/wms/productStocks/viewProductStocks/page/ViewProductStocks";
import AddBaseProduct from "./modules/wms/products/addBaseProduct/page/AddBaseProduct";
import AddProduct from "./modules/wms/products/addProduct/page/AddProduct";
import ViewBaseProductAndStock from "./modules/wms/products/viewBaseProductAndStock/page/ViewBaseProductAndStock";
import ViewBaseProducts from "./modules/wms/products/viewBaseProducts/page/ViewBaseProducts";
import ViewCaseSizesByBaseProduct from "./modules/wms/products/viewCaseSizesByBaseProduct/page/ViewCaseSizesByBaseProduct";
import ViewProducts from "./modules/wms/products/viewProducts/page/ViewProducts";
import AddRecipe from "./modules/wms/recipes/addRecipe/page/AddRecipe";
import ViewRecipe from "./modules/wms/recipes/viewRecipe/page/ViewRecipe";
import ViewRecipes from "./modules/wms/recipes/viewRecipes/page/ViewRecipes";
import CreateCustomReport from "./modules/wms/reports/createCustomReport/page/CreateCustomReport";
import CreateDefinedReport from "./modules/wms/reports/createDefinedReport/page/CreateDefinedReport";
import CreateStockAllocation from "./modules/wms/stockAllocation/createStockAllocation/page/CreateStockAllocation";
import ViewStockAllocations from "./modules/wms/stockAllocation/viewStockAllocations/page/ViewStockAllocations";
import CreateAdHocStockCheck from "./modules/wms/stockChecks/createAdhocStockCheck/page/CreateAdhocStockCheck";
import CreateBasicStockCheck from "./modules/wms/stockChecks/createBasicStockCheck/page/CreateBasicStockCheck";
import CreateWeeklyStockCheck from "./modules/wms/stockChecks/createWeeklyStockCheck/page/CreateWeeklyStockCheck";
import FulfilStockCheck from "./modules/wms/stockChecks/fulfilStockCheck/page/FulfilStockCheck";
import ViewStockCheck from "./modules/wms/stockChecks/viewStockCheck/page/ViewStockCheck";
import ViewStockChecks from "./modules/wms/stockChecks/viewStockChecks/page/ViewStockChecks";
import CreateStockTransfer from "./modules/wms/stockTransfer/createStockTransfer/page/CreateStockTransfer";
import ViewStockTransfer from "./modules/wms/stockTransfer/viewStockTransfer/page/ViewStockTransfer";
import ViewStockTransfers from "./modules/wms/stockTransfer/viewStockTransfers/page/ViewStockTransfers";
import AddWarehouse from "./modules/wms/warehouses/addWarehouse/page/AddWarehouse";
import ViewWarehouse from "./modules/wms/warehouses/viewWarehouse/page/ViewWarehouse";
import ViewWarehouses from "./modules/wms/warehouses/viewWarehouses/page/ViewWarehouses";
import AddWorkOrder from "./modules/wms/workOrders/addWorkOrder/page/AddWorkOrder";
import FulfilWorkOrder from "./modules/wms/workOrders/fulfilWorkOrder/page/FulfilWorkOrder";
import ViewWorkOrder from "./modules/wms/workOrders/viewWorkOrder/page/ViewWorkOrder";
import ViewWorkOrders from "./modules/wms/workOrders/viewWorkOrders/page/ViewWorkOrders";
import ApolloWrapper from "./providers/ApolloWrapper";
import AuthorisationProvider from "./providers/AuthorisationProvider";
import store, { persistor } from "./redux/store";
import Layout from "./shared/components/templates/Layout";
import {
  SERVICE_ADMIN,
  SERVICE_DEFAULT,
  SERVICE_LOGISTICS,
  SERVICE_ORDERS_ACCOUNTS,
  SERVICE_PCAT,
  SERVICE_SOURCING,
  SERVICE_WMS,
} from "./shared/constants";
import getRuntimeEnv from "./util/getRuntimeEnv";
import { setupMonitoring } from "./util/monitoring";

if (process.env.NODE_ENV !== "production") {
  const axe = require("@axe-core/react");
  axe(React, ReactDOM, 1000);
}

const globalStyles = globalCss({
  body: { background: "rgba(0, 90, 225, 0.01)" },
});

const routes = [
  { path: "/", exact: true, main: () => <ViewHomepage />, service: SERVICE_DEFAULT },
  {
    path: "/dashboard",
    exact: true,
    main: () => <ViewHomeDashboard />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests",
    exact: true,
    main: () => <ViewManifestDashboard />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/inbound",
    exact: true,
    main: () => <ViewInboundManifests />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/outbound",
    exact: true,
    main: () => <ViewOutboundManifests />,
    service: SERVICE_WMS,
  },
  {
    path: "/organisation-management/add-organisation",
    exact: true,
    main: () => <AddOrganisation />,
    service: SERVICE_WMS,
  },
  {
    path: "/courier-management/add-courier",
    exact: true,
    main: () => <AddCourier />,
    service: SERVICE_WMS,
  },
  {
    path: "/courier-management/add-courier-service",
    exact: true,
    main: () => <AddCourierService />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/courier-services",
    exact: true,
    main: () => <ViewCourierServices />,
    service: SERVICE_WMS,
  },
  { path: "/manifests/recipe", exact: true, main: () => <ViewRecipes />, service: SERVICE_WMS },
  {
    path: "/manifests/recipe/recipe-request",
    exact: true,
    main: () => <AddRecipe />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/recipe/view-recipe/:recipe_id",
    exact: true,
    main: () => <ViewRecipe />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/work-orders",
    exact: true,
    main: () => <ViewWorkOrders />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/work-orders/create",
    exact: true,
    main: () => <AddWorkOrder />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/work-orders/fulfil/:work_order_id",
    exact: true,
    main: () => <FulfilWorkOrder />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/work-orders/view/:work_order_id",
    exact: true,
    main: () => <ViewWorkOrder />,
  },
  {
    path: "/operations",
    exact: true,
    main: () => <ViewOperationsDashboard />,
    service: SERVICE_WMS,
  },
  {
    path: "/operations/case-change",
    exact: true,
    main: () => <CreateCaseChange />,
    service: SERVICE_WMS,
  },
  {
    path: "/operations/stock-allocation",
    exact: true,
    main: () => <ViewStockAllocations />,
    service: SERVICE_WMS,
  },

  {
    path: "/operations/stock-allocation/create-stock-allocation",
    exact: true,
    main: () => <CreateStockAllocation />,
    service: SERVICE_WMS,
  },
  /*{
    path: "/operations/perpetual-inventory",
    exact: true,
    main: () => <ViewAndUpdatePerpetualInventory />,
    service: SERVICE_WMS,
  },*/
  {
    path: "/operations/stock-check",
    exact: true,
    main: () => <ViewStockChecks />,
    service: SERVICE_WMS,
  },
  {
    path: "/operations/stock-check-adhoc",
    exact: true,
    main: () => <CreateAdHocStockCheck />,
    service: SERVICE_WMS,
  },
  {
    path: "/operations/stock-check/create-basic-stock-check",
    exact: true,
    main: () => <CreateBasicStockCheck></CreateBasicStockCheck>,
  },
  {
    path: "/operations/stock-check/create-weekly-stock-check",
    exact: true,
    main: () => <CreateWeeklyStockCheck></CreateWeeklyStockCheck>,
  },
  {
    path: "/operations/stock-check/view-stock-check/:stock_check_id",
    exact: true,
    main: () => <ViewStockCheck></ViewStockCheck>,
  },
  {
    path: "/operations/stock-check/fulfil-stock-check/:stock_check_id",
    exact: true,
    main: () => <FulfilStockCheck></FulfilStockCheck>,
  },
  {
    path: "/operations/stock-transfer",
    exact: true,
    main: () => <ViewStockTransfers />,
  },
  {
    path: "/operations/stock-transfer/create-stock-transfer",
    exact: true,
    main: () => <CreateStockTransfer />,
  },
  {
    path: "/operations/stock-transfer/review-stock-transfer/:stock_transdfer_id",
    exact: true,
    main: () => <ViewStockTransfer />,
  },
  { path: "/inventory", exact: true, main: () => <ViewInventoryDashboard />, service: SERVICE_WMS },
  { path: "/inventory/products", exact: true, main: () => <ViewProducts />, service: SERVICE_WMS },
  {
    path: "/inventory/base-products",
    exact: true,
    main: () => <ViewBaseProducts />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/base-products/add-base-product",
    exact: true,
    main: () => <AddBaseProduct />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/base-products/view-case-sizes/:base_product_id",
    exact: true,
    main: () => <ViewCaseSizesByBaseProduct />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/products/add-product",
    exact: true,
    main: () => <AddProduct />,
  },
  {
    path: "/inventory/view-product-stock/:product_stock_id",
    exact: true,
    main: () => <ViewProductStock />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/stock-levels",
    exact: true,
    main: () => <ViewProductStocks />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/file-copies",
    exact: true,
    main: () => <ViewFileCopies />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/file-copies/delete",
    exact: true,
    main: () => <DeletFileCopies />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/reports",
    exact: true,
    main: () => <CreateDefinedReport />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/create-reports",
    exact: true,
    main: () => <CreateCustomReport />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management",
    exact: true,
    main: () => <ViewWarehouses />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/location",
    exact: true,
    main: () => <ViewLocations />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/location/view-location/:location_id",
    exact: true,
    main: () => <ViewLocation />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/view-warehouse/:warehouse_id",
    exact: true,
    main: () => <ViewWarehouse />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/address-book",
    exact: true,
    main: () => <ViewAddresses />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/add-location",
    exact: true,
    main: () => <AddLocation />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/add-warehouse",
    exact: true,
    main: () => <AddWarehouse />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/inbound/create-inbound-manifest",
    exact: true,
    main: () => <CreateInboundManifest />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/inbound/fulfil-inbound-manifest/:inbound_manifest_id",
    exact: true,
    main: () => <FulfilInboundManifest />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/outbound/create-outbound-manifest",
    exact: true,
    main: () => <CreateOutboundManifest />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/outbound/fulfil-outbound-manifest/:outbound_manifest_id",
    exact: true,
    main: () => <FulfilOutboundManifest />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/inbound/review-inbound-manifest/:inbound_manifest_id",
    exact: true,
    main: () => <ViewInboundManifest />,
    service: SERVICE_WMS,
  },
  {
    path: "/manifests/outbound/review-outbound-manifest/:outbound_manifest_id",
    exact: true,
    main: () => <ViewOutboundManifest />,
    service: SERVICE_WMS,
  },
  {
    path: "/warehouse-management/add-address",
    exact: true,
    main: () => <AddAddress />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/organisations",
    exact: true,
    main: () => <ViewOrganisations />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/organisations/view-organisation/:organisation_id",
    exact: true,
    main: () => <ViewOrganisation />,
    service: SERVICE_WMS,
  },
  {
    path: "/inventory/products/view-product/:product_id/:base_product_id",
    exact: true,
    main: () => <ViewBaseProductAndStock />,
    service: SERVICE_WMS,
  },
  { path: "/wms-admin", exact: true, main: () => <ViewWMSAdminPanel />, service: SERVICE_WMS },
  // ------------ SOURCING -----------------
  {
    path: "/sourcing",
    exact: true,
    main: () => <ViewSourcingDashboard />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations",
    exact: true,
    main: () => <ViewSupplierQuotes />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations/quotation/create-quotation-response",
    exact: true,
    main: () => <CreateQuotationResponse />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations/quotation/create-quotation",
    exact: true,
    main: () => <CreateQuotation />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations/quotation/update-quotation/:quote_id/:quote_line_id",
    exact: true,
    main: () => <UpdateQuotation />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations/request-for-quotation",
    exact: true,
    main: () => <ViewRequestsForQuotations />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations/request-for-quotation/create-request-for-quotation",
    exact: true,
    main: () => <CreateRequestForQuotation />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/quotations/request-for-quotation/view-request-for-quotation/:rfq_id",
    exact: true,
    main: () => <ViewSupplierQuoteRequest />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/client-orders",
    exact: true,
    main: () => <ViewClientOrders />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/client-orders/pending-demand",
    exact: true,
    main: () => <ViewPendingDemand />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/client-orders/update-client-order/:clientOrderId/:clientOrderLineId?",
    exact: true,
    main: () => <UpdateClientOrder />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/purchase-orders",
    exact: true,
    main: () => <ViewPurchaseOrders />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/purchase-orders/create-purchase-order/:purchaseOrderId?",
    exact: true,
    main: () => <CreatePurchaseOrder />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/purchase-orders/update-purchase-order/:purchaseOrderId",
    exact: true,
    main: () => <UpdatePurchaseOrder />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/qc-orders/",
    exact: true,
    main: () => <ViewQCInspections />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/qc-orders/create-qc",
    exact: true,
    main: () => <CreateQCInspection />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/sourcing/admin",
    exact: true,
    main: () => <ViewAdminPanel />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/sourcing/admin/create-quotation-cost-type",
    exact: true,
    main: () => <CreateQuotationCostType />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/suppliers",
    exact: true,
    main: () => <ViewSuppliers />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/suppliers/suppliers-dashboard",
    exact: true,
    main: () => <ViewSuppliersDashboard />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/suppliers/create-locale-holiday",
    exact: true,
    main: () => <CreateLocaleHoliday />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/suppliers/create-supplier",
    exact: true,
    main: () => <CreateSupplier />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/suppliers/view-delivery-dates",
    exact: true,
    main: () => <ViewDeliveryDates />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/suppliers/view/:xero_contact_id",
    exact: true,
    main: () => <ViewSupplier />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/sourcing/admin/update-quotation-cost-type/:quotationCostTypeId",
    exact: true,
    main: () => <UpdateQuotationCostType />,
    service: SERVICE_SOURCING,
  },
  {
    path: "/help",
    exact: true,
    main: () => <HelpHome />,
  },
  {
    path: "/help/wms-setup-guide",
    exact: true,
    main: () => <WMSSetupGuide />,
  },
  {
    path: "/help/wms-user-manual",
    exact: true,
    main: () => <WMSUserManual />,
  },
  {
    path: "/help/sourcing-user-manual",
    exact: true,
    main: () => <SourcingUserManual />,
  },
  {
    path: "/reports/",
    exact: true,
    main: () => <SourcingCreateDefinedReport />,
    service: SERVICE_SOURCING,
  },
  // ------------ LOGISTICS -----------------
  {
    path: "/logistics/dashboard",
    exact: true,
    main: () => <ViewLogisticsDashboard />,
    service: SERVICE_LOGISTICS,
  },
  {
    path: "/shipments",
    exact: true,
    main: () => <ViewShipments />,
    service: SERVICE_LOGISTICS,
  },
  {
    path: "/shipments/client-order/:clientOrderId",
    exact: true,
    main: () => <ViewClientOrderShipments />,
    service: SERVICE_LOGISTICS,
  },
  {
    path: "/shipments/batch-update",
    exact: true,
    main: () => <UpdateShipmentsInBatch />,
    service: SERVICE_LOGISTICS,
  },
  // ------------ ORDERS -----------------
  {
    path: "/orders/dashboard",
    exact: true,
    main: () => <CreateOrder />,
    service: SERVICE_ORDERS_ACCOUNTS,
  },
  // ------------ ADMIN -----------------
  {
    path: "/admin",
    exact: true,
    main: () => <ViewAdminDashboard />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/users",
    exact: true,
    main: () => <ViewIAMUsers />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/user/create",
    exact: true,
    main: () => <CreateUser />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/organisations",
    exact: true,
    main: () => <ViewIAMOrganisations />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/organisations/create",
    exact: true,
    main: () => <CreateIAMOrganisation />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/organisations/:orgUUID/:displayName/members",
    exact: true,
    main: () => <ViewIAMOrganisationMembers />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/organisations/:orgUuid/:displayName/member/:userUuid/:userName",
    exact: true,
    main: () => <ViewIAMOrganisationMemberRoles />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/organisations/:orgUuid/:displayName/member/:userUuid/:userName/roles/add",
    exact: true,
    main: () => <AssignMemberRoles />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/iam/organisations/:orgUUID/:displayName/members/add",
    exact: true,
    main: () => <AddIAMOrganisationMembers />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/organisations",
    exact: true,
    main: () => <ViewStockManagementOrganisations />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/organisations/create-organisation",
    exact: true,
    main: () => <CreateOrganisation />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/organisations/:orgId",
    exact: true,
    main: () => <UpdateStockManagementOrganisation />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/skus",
    exact: true,
    main: () => <ViewSkus />,
    service: SERVICE_ADMIN,
  },
  {
    path: "/admin/skus/update-sku/:skuId",
    exact: true,
    main: () => <UpdateSku />,
    service: SERVICE_ADMIN,
  },
  // ------------ PRODUCT CATALOGUE -----------------

  {
    path: "/product-catalogue",
    exact: true,
    main: () => <ViewPCatBaseProducts />,
    service: SERVICE_PCAT,
  },
  {
    path: "/product-catalogue/baseproduct/:productId",
    exact: true,
    main: () => <ViewBaseProductOverview />,
    service: SERVICE_PCAT,
  },
  {
    path: "/product-catalogue/attribute-templates",
    exact: true,
    main: () => <ViewAttributesTypes />,
    service: SERVICE_PCAT,
  },
];

const publicRoutes = [
  {
    path: "/access/request-for-quotation/supplier-response",
    exact: true,
    main: () => <SupplierResponse></SupplierResponse>,
  },
  {
    path: "/access/purchase-orders/supplier-response",
    exact: true,
    main: () => <CreatePurchaseOrderSupplierResponse></CreatePurchaseOrderSupplierResponse>,
  },
  {
    path: "/access/purchase-orders/supplier-response/status",
    exact: true,
    main: () => <SupplierResponseStatusConfirmation></SupplierResponseStatusConfirmation>,
  },
];

const wmsHttpClient = WMS_API_CLIENT;
const orderServiceClient = ORDER_SERVICE_CLIENT;
const sourcingHttpClient = SOURCING_API_CLIENT;
const gcseHttpClient = GCS_API_CLIENT;
const logisticsHttpClient = LOGISTICS_API_CLIENT;
const productCatalogueHttpClient = PRODUCT_CATALOGUE_API_CLIENT;
const productPricingHttpClient = PRODUCT_PRICING_API_CLIENT;
const iamHttpClient = IAM_API_CLIENT;
const stockManagementClient = STOCK_MANAGEMENT_API_CLIENT;

setupMonitoring();

function App() {
  return (
    <ToastProvider>
      <Provider store={store}>
        <PersistGate loading={<LoadingScreen />} persistor={persistor}>
          <Router>
            <Auth0Provider
              domain={getRuntimeEnv("REACT_APP_AUTH0_DOMAIN")}
              clientId={getRuntimeEnv("REACT_APP_AUTH0_CLIENT_ID")}
              audience={getRuntimeEnv("REACT_APP_AUTH0_AUDIENCE")}
              redirectUri={window.location.origin}
              // Currently assuming Sourceful is the only org available
              organization={getRuntimeEnv("REACT_APP_SOURCEFUL_ORGANISATION")}
            >
              <AuthorisationProvider>
                <QueryParamProvider ReactRouterRoute={Route}>
                  <ApolloWrapper>
                    <Suspense fallback={() => <div />}>
                      <ToastContainer autoClose={3000} />
                      <IconSprite />
                      <CoreApp />
                    </Suspense>
                  </ApolloWrapper>
                </QueryParamProvider>
              </AuthorisationProvider>
            </Auth0Provider>
          </Router>
        </PersistGate>
      </Provider>
    </ToastProvider>
  );
}

const CoreApp = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();

  useEffect(() => {
    wmsHttpClient.setTokenGenerator(getAccessTokenSilently);
    sourcingHttpClient.setTokenGenerator(getAccessTokenSilently);
    gcseHttpClient.setTokenGenerator(getAccessTokenSilently);
    logisticsHttpClient.setTokenGenerator(getAccessTokenSilently);
    productCatalogueHttpClient.setTokenGenerator(getAccessTokenSilently);
    productPricingHttpClient.setTokenGenerator(getAccessTokenSilently);
    orderServiceClient.setTokenGenerator(getAccessTokenSilently);
    iamHttpClient.setTokenGenerator(getAccessTokenSilently);
    stockManagementClient.setTokenGenerator(getAccessTokenSilently);
  }, [getAccessTokenSilently]);

  globalStyles();

  useEffect(() => {
    if (isAuthenticated)
      LogRocket.identify(user?.sub ?? "", {
        name: user?.name ?? "",
        email: user?.email ?? "",
      });
  }, [user, isAuthenticated]);

  return (
    <Router>
      <Switch>
        {routes.map((route, index) => {
          return (
            <PrivateRoute
              key={index}
              path={route.path}
              exact={route.exact}
              service={route.service}
              component={() => {
                return (
                  <Layout>
                    <route.main />
                  </Layout>
                );
              }}
            />
          );
        })}

        <PrivateRoute
          path="/not-found"
          exact
          component={() => {
            return (
              <Layout>
                <Heading level={1}>Page not found</Heading>
              </Layout>
            );
          }}
        />

        {publicRoutes.map((route, index) => {
          return (
            <Route
              key={index}
              path={route.path}
              exact={route.exact}
              component={() => {
                return (
                  <Layout publicPage={true}>
                    <route.main />
                  </Layout>
                );
              }}
            />
          );
        })}

        <PrivateRoute path="/" exact component={() => <Layout />} />

        <Redirect to="/not-found" />
      </Switch>
    </Router>
  );
};

export default App;
