import { QAInspectionItem } from "../graphql/types";

export const mapQAInspectionToTableRow = (qaInspections: QAInspectionItem[]) => {
  return qaInspections.map(qa => {
    return {
      qa_id: qa.id,
      purchase_order_line_id: qa.purchase_order_line.id,
      purchase_order_uid: qa.purchase_order_line.purchase_order.purchase_order_document_uid || "",
      purchase_order_id: qa.purchase_order_line.purchase_order_id,
      purchase_order_status_id: qa.purchase_order_line.purchase_order.purchase_order_status_id,
      manufacturer_name: qa.purchase_order_line.purchase_order.organisation.organisation_name,
      client_name:
        qa.purchase_order_line.purchase_order_line_allocations.length > 1
          ? "Multiple"
          : qa.purchase_order_line.purchase_order_line_allocations.length === 0
          ? "Unknown"
          : qa.purchase_order_line.purchase_order_line_allocations[0].client_order.organisation
              .organisation_name || "Unknown",
      qa_status: qa.quality_assurance_status.quality_assurance_status_name,
      locale:
        qa.purchase_order_line.purchase_order.supplier_organisation?.supplier_details[0].locale ||
        "",
    };
  });
};
