import * as Yup from "yup";

export const validationSchema = Yup.object({
  supplier: Yup.object()
    .shape({
      value: Yup.number().required("Supplier is required"),
    })
    .typeError("Supplier is required"),
  currency: Yup.object()
    .shape({
      value: Yup.number().required("Currency is required"),
    })
    .typeError("Currency is required"),
  product_lines: Yup.array().of(
    Yup.object({
      product: Yup.object()
        .shape({
          value: Yup.number().required("Product is required"),
        })
        .typeError("Product is required"),
      quantity: Yup.array()
        .of(Yup.string().required("Quantity is required"))
        .min(1, "Quantity is required")
        .required("Quantity is required"),
      notes: Yup.string(),
    })
  ),
});
