import moment from "moment";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useFileCopies } from "../graphql/hooks/useFileCopies";
import { FileCopyItem } from "../graphql/types";

export default function ViewFileCopies() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { fileCopies, fileCopiesLoading, fileCopiesError } = useFileCopies();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/inventory/file-copies/delete");
          },
          type: "button",
          title: "Delete File Copies",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        widthPercent: 0.4,
        Cell: ({ _, row }: CellProps<FileCopyItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: FileCopyItem) => listItem.product.product_name,
      },
      {
        Header: "Quantity",
        widthPercent: 0.2,
        accessor: (listItem: FileCopyItem) => listItem.quantity,
      },
      {
        Header: "Location",
        widthPercent: 0.4,
        accessor: (listItem: FileCopyItem) => listItem.location.location_name,
        Cell: ({ _, row }: CellProps<FileCopyItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.location_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Created At",
        widthPercent: 0.2,
        accessor: (listItem: FileCopyItem) => moment(listItem.last_updated).format("x"),
        Cell: ({ _, row }: CellProps<FileCopyItem>) =>
          moment(row.original.last_updated).format("DD-MM-YYYY"),
      },

      {
        Header: "Condition",
        widthPercent: 0.2,
        accessor: (listItem: FileCopyItem) => listItem.stock_condition.stock_condition_name,
      },
      {
        Header: "Status",
        widthPercent: 0.1,
        accessor: (listItem: FileCopyItem) => listItem.stock_status.stock_status_name,
        Cell: ({ _, row }: CellProps<FileCopyItem>) => (
          <StatusBadge type="wms" statusName={row.original.stock_status.stock_status_name} />
        ),
      },
    ],
    []
  );

  return (
    <Page error={fileCopiesError} isLoading={fileCopiesLoading} title={"Inventory - File Copies"}>
      <Panel
        withWrapper
        title={"File Copies"}
        text={"Use the table below to view Sourceful's File Copies"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={fileCopies} columns={columns} />
      </Panel>
    </Page>
  );
}
