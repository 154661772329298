/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { ButtonGroup } from "@sourceful/shared-components";

import { Report, ReportsCard } from "../../../../../components/ReportsCard/ReportsCard";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { StringListDropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { ReportForm, fieldNames, reportOptions } from "../formValues";
import { useAllReportSupplierCountryCodes } from "../graphql/hooks/useAllReportSupplierCountryCodes";
import { useOrderExport } from "../graphql/hooks/useOrderExport";
import { REPORT_TYPE } from "../types";

export function SourcingCreateDefinedReport() {
  const [selectedToDate, setSelectedToDate] = useState(
    moment().add(1, "day").format("YYYY-MM-DD").toString()
  );
  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().subtract(12, "day").format("YYYY-MM-DD").toString()
  );
  const [reportData, setReportData] = useState<Report | null>(null);
  const [selectedReport, setSelectedReport] = useState<number | null>(null);
  const [supplierCountryCodeNeeded, setSupplierCountryCodeNeeded] = useState<boolean>(false);
  const [dateRangeNeeded, setDateRangeNeeded] = useState<boolean>(false);
  const [selectedSupplierCountryCode, setSelectedSupplierCountryCode] = useState<
    StringListDropdownItem | undefined
  >(undefined);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<ReportForm>();

  const { supplierCountryCodes, supplierCountryCodesLoading, supplierCountryCodesError } =
    useAllReportSupplierCountryCodes();

  const { orderExport, orderExportLoading, orderExportError, getOrderExport } = useOrderExport();

  const onSubmit = (values: ReportForm) => {
    if (values?.report?.value === REPORT_TYPE.ORDER_EXPORT) {
      getOrderExport({
        supplier_country_code: selectedSupplierCountryCode?.value!,
        fromDate: selectedFromDate,
        toDate: selectedToDate,
      });
    }
  };

  const isError = orderExportError ?? supplierCountryCodesError;
  const isLoading = orderExportLoading ?? supplierCountryCodesLoading;

  const allFields = watch();

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.ORDER_EXPORT) {
      const reportPoSupplierComments: Report = {
        fileTitle: `order_export_report_in_${selectedSupplierCountryCode?.label}_${selectedFromDate}_to_${selectedToDate}_generated`,
        displayTitle: `Download Order Export Data in ${selectedSupplierCountryCode?.label}, ${selectedFromDate} to ${selectedToDate}`,
        data: orderExport,
      };
      setReportData(reportPoSupplierComments);
    }
  }, [orderExportLoading]);

  return (
    <Page isLoading={isLoading} error={isError} title={"Inventory - Reports"}>
      <Panel withWrapper title={"Common Reports"} subtitle={"Create Common Reports"}>
        <InnerPanelWrapper>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <TopPanelInputsWrapper>
              <DataPoint>
                <Label htmlFor={fieldNames.report} isGray={reportData ? true : false} isRequired>
                  Reports
                </Label>
                {!reportData ? (
                  <Controller
                    name={"report"}
                    control={control}
                    rules={{ required: "Report is required" }}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          onChange={e => {
                            field.onChange(e);
                            setSelectedReport(e?.value || null);
                            if (e?.value === REPORT_TYPE.ORDER_EXPORT)
                              setSupplierCountryCodeNeeded(true);
                            else setSupplierCountryCodeNeeded(false);

                            if (e?.value === REPORT_TYPE.ORDER_EXPORT) setDateRangeNeeded(true);
                            else setDateRangeNeeded(false);
                          }}
                          options={reportOptions}
                          maxMenuHeight={220}
                          isSearchable={true}
                          placeholder="Select Report"
                          styles={reactSelectStyling}
                        />
                      );
                    }}
                  />
                ) : (
                  <Box>{allFields.report?.label}</Box>
                )}
                {errors.report && (
                  <ErrorMessage>{errors.report && (errors.report as any).message}</ErrorMessage>
                )}
              </DataPoint>
              {supplierCountryCodeNeeded && (
                <DataPoint>
                  <Label
                    htmlFor={fieldNames.supplierCountryCode}
                    isGray={reportData ? true : false}
                    isRequired
                  >
                    Supplier Country Code
                  </Label>
                  {!reportData ? (
                    <Controller
                      name={"supplierCountryCode"}
                      control={control}
                      rules={{ required: "Supplier Country Code is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={supplierCountryCodes}
                          onChange={e => {
                            field.onChange(e);
                            setSelectedSupplierCountryCode(e!);
                          }}
                          maxMenuHeight={220}
                          isSearchable={true}
                          placeholder="Select Report"
                          styles={reactSelectStyling}
                        />
                      )}
                    />
                  ) : (
                    <Box>{allFields.supplierCountryCode?.label}</Box>
                  )}
                  {errors?.supplierCountryCode && (
                    <ErrorMessage>{(errors.supplierCountryCode as any).message}</ErrorMessage>
                  )}
                </DataPoint>
              )}

              {dateRangeNeeded && (
                <>
                  <DataPoint>
                    <Label
                      htmlFor={fieldNames.fromDate}
                      isGray={reportData ? true : false}
                      isRequired
                    >
                      From Date
                    </Label>
                    {!reportData ? (
                      <Controller
                        name={"fromDate"}
                        control={control}
                        render={({ field }) => {
                          if (!field.value) {
                            field.value = moment().subtract(12, "day").format("YYYY-MM-DD");
                          }

                          return (
                            <DateInput
                              value={
                                field.value ?? moment().subtract(12, "day").format("YYYY-MM-DD")
                              }
                              id={fieldNames.fromDate}
                              type="date"
                              onChange={e => {
                                field.onChange(e);
                                setSelectedFromDate(
                                  moment(e.target.valueAsDate).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          );
                        }}
                      />
                    ) : (
                      <Box>{moment(selectedFromDate).format("YYYY-MM-DD")}</Box>
                    )}
                  </DataPoint>
                  <DataPoint>
                    <Label
                      htmlFor={fieldNames.toDate}
                      isGray={reportData ? true : false}
                      isRequired
                    >
                      To Date
                    </Label>
                    {!reportData ? (
                      <Controller
                        name={"toDate"}
                        control={control}
                        render={({ field }) => {
                          if (!field.value) {
                            field.value = moment().format("YYYY-MM-DD");
                          }

                          return (
                            <DateInput
                              value={field.value ?? moment().format("YYYY-MM-DD")}
                              id={fieldNames.fromDate}
                              type="date"
                              onChange={e => {
                                field.onChange(e);
                                setSelectedToDate(
                                  moment(e.target.valueAsDate).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          );
                        }}
                      />
                    ) : (
                      <Box>{moment(selectedToDate).format("YYYY-MM-DD")}</Box>
                    )}
                  </DataPoint>
                </>
              )}
            </TopPanelInputsWrapper>

            {reportData && (
              <>
                <PrimaryLineWrapper>
                  <Title>Generated Report</Title>
                  <ReportsCard report={reportData} />
                </PrimaryLineWrapper>
              </>
            )}

            <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
              <SecondaryButton
                appearance={"whiteButton"}
                type="reset"
                onClick={() => {
                  reset();
                  setSupplierCountryCodeNeeded(false);
                  setDateRangeNeeded(false);
                  setReportData(null);
                  setSelectedReport(null);
                }}
              >
                Cancel
              </SecondaryButton>
              {!reportData && (
                <SecondaryButton appearance={"blueButton"} type="submit">
                  Confirm
                </SecondaryButton>
              )}
            </ButtonGroup>
          </StyledForm>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

export default SourcingCreateDefinedReport;
