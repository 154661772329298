export interface AdhocStockCheckForm {
  warehouse: {
    value: number;
    label: string;
  } | null;
  stock_check_lines: StockCheckLine[];
  due_date: string;
  notes: string;
}

export interface StockCheckLine {
  product_stock: {
    value: number;
    label: string;
  } | null;
  expected_quantity: string;
  expected_stock_condition_id: string;
}

export const fieldNames = {
  warehouse: "warehouse",
  notes: "notes",
  due_date: "due_date",
};

export const emptyStockCheckLine: StockCheckLine = {
  product_stock: null,
  expected_quantity: "",
  expected_stock_condition_id: "",
};

export const initialFieldValues: AdhocStockCheckForm = {
  stock_check_lines: [],
  warehouse: null,
  due_date: "",
  notes: "",
};
