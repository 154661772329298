import React, { createRef, useState } from "react";

import { Modal, SecondaryButton, styled } from "@sourceful/shared-components";

import { AttributeTemplate } from "../api/types";
import AttributeType from "./AttributeType";

interface AttributeRowProps {
  id: number;
  attribute_type: string;
  templates: AttributeTemplate[];
  refetchTemplates: () => void;
}

const AttributeTypeButton = styled(SecondaryButton, {
  width: "100%",
  marginBottom: "10px",
});

const AttributeTypeRow = ({
  id,
  attribute_type,
  templates,
  refetchTemplates,
}: AttributeRowProps) => {
  const modalRef = createRef<HTMLButtonElement>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  return (
    <>
      <AttributeTypeButton onClick={() => setIsModalOpen(true)}>
        {attribute_type}
      </AttributeTypeButton>
      <Modal
        id={`attributeTypeModal-${id}`}
        isOpen={isModalOpen}
        handleClose={() => setIsModalOpen(false)}
        triggerRef={modalRef}
      >
        <AttributeType
          id={id}
          attribute_type={attribute_type}
          templates={templates}
          refetchTemplates={refetchTemplates}
        />
      </Modal>
    </>
  );
};

export default AttributeTypeRow;
