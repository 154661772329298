import {
  CREATE_PRE_PURCHASE_ORDER_FAILED,
  CREATE_PRE_PURCHASE_ORDER_LOADING,
  CREATE_PRE_PURCHASE_ORDER_RESET,
  CREATE_PRE_PURCHASE_ORDER_SUCCESS,
} from "../types";

export interface CreatePrePurchaseOrderReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreatePrePurchaseOrderReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createPrePurchaseOrderReducer(
  state: CreatePrePurchaseOrderReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_PRE_PURCHASE_ORDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PRE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_PRE_PURCHASE_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_PRE_PURCHASE_ORDER_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
