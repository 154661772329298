import { ApolloError } from "@apollo/client";

import { useGetRecipeTypeNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapRecipeTypeNamesToDropdownItem } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  recipeTypeNames: DropdownItem[];
  recipeTypeNamesError?: ApolloError;
  recipeTypeNamesLoading: boolean;
}

export const useRecipeTypeNames = (): HookResult => {
  const { data, error, loading } = useGetRecipeTypeNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    recipeTypeNames:
      data && Array.isArray(data.recipe_type)
        ? mapRecipeTypeNamesToDropdownItem(data.recipe_type)
        : [],
    recipeTypeNamesError: error,
    recipeTypeNamesLoading: loading,
  };
};
