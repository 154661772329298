import { gql } from "@apollo/client";
import * as Apollo from "@apollo/client";
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  bigint: any;
  date: any;
  jsonb: any;
  numeric: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: "address";
  address_description?: Maybe<Scalars["String"]>;
  address_line_1: Scalars["String"];
  address_line_2: Scalars["String"];
  address_line_3: Scalars["String"];
  address_name: Scalars["String"];
  city: Scalars["String"];
  /** An object relationship */
  country: Country;
  country_code: Scalars["String"];
  created_at: Scalars["timestamptz"];
  external_address_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  name: Scalars["String"];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars["Int"];
  phone_number?: Maybe<Scalars["String"]>;
  postcode: Scalars["String"];
  /** An array relationship */
  warehouses: Array<Warehouse>;
  /** An aggregate relationship */
  warehouses_aggregate: Warehouse_Aggregate;
};

/** columns and relationships of "address" */
export type AddressWarehousesArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** columns and relationships of "address" */
export type AddressWarehouses_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: "address_aggregate";
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

export type Address_Aggregate_Bool_Exp = {
  count?: Maybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: "address_aggregate_fields";
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};

/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  avg?: Maybe<Address_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Address_Max_Order_By>;
  min?: Maybe<Address_Min_Order_By>;
  stddev?: Maybe<Address_Stddev_Order_By>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Order_By>;
  sum?: Maybe<Address_Sum_Order_By>;
  var_pop?: Maybe<Address_Var_Pop_Order_By>;
  var_samp?: Maybe<Address_Var_Samp_Order_By>;
  variance?: Maybe<Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: "address_avg_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "address" */
export type Address_Avg_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: Maybe<Array<Address_Bool_Exp>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Address_Bool_Exp>>;
  address_description?: Maybe<String_Comparison_Exp>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  address_line_3?: Maybe<String_Comparison_Exp>;
  address_name?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  country?: Maybe<Country_Bool_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_address_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  phone_number?: Maybe<String_Comparison_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  warehouses?: Maybe<Warehouse_Bool_Exp>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = "address_pkey",
}

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  external_address_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  address_name?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_address_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["Int"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
  warehouses?: Maybe<Warehouse_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: "address_max_fields";
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  address_name?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  address_name?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: "address_min_fields";
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  address_name?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  address_name?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: "address_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  address_name?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country?: Maybe<Country_Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  phone_number?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AddressDescription = "address_description",
  /** column name */
  AddressLine_1 = "address_line_1",
  /** column name */
  AddressLine_2 = "address_line_2",
  /** column name */
  AddressLine_3 = "address_line_3",
  /** column name */
  AddressName = "address_name",
  /** column name */
  City = "city",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalAddressId = "external_address_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Postcode = "postcode",
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  address_name?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_address_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: "address_stddev_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "address" */
export type Address_Stddev_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: "address_stddev_pop_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "address" */
export type Address_Stddev_Pop_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: "address_stddev_samp_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "address" */
export type Address_Stddev_Samp_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  address_description?: Maybe<Scalars["String"]>;
  address_line_1?: Maybe<Scalars["String"]>;
  address_line_2?: Maybe<Scalars["String"]>;
  address_line_3?: Maybe<Scalars["String"]>;
  address_name?: Maybe<Scalars["String"]>;
  city?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  phone_number?: Maybe<Scalars["String"]>;
  postcode?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: "address_sum_fields";
  external_address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "address" */
export type Address_Sum_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AddressDescription = "address_description",
  /** column name */
  AddressLine_1 = "address_line_1",
  /** column name */
  AddressLine_2 = "address_line_2",
  /** column name */
  AddressLine_3 = "address_line_3",
  /** column name */
  AddressName = "address_name",
  /** column name */
  City = "city",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalAddressId = "external_address_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Name = "name",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  PhoneNumber = "phone_number",
  /** column name */
  Postcode = "postcode",
}

export type Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: "address_var_pop_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "address" */
export type Address_Var_Pop_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: "address_var_samp_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "address" */
export type Address_Var_Samp_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: "address_variance_fields";
  external_address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "address" */
export type Address_Variance_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** columns and relationships of "attachment" */
export type Attachment = {
  __typename?: "attachment";
  attachment_type_id: Scalars["Int"];
  attachment_uuid: Scalars["uuid"];
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  folder: Folder;
  folder_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "attachment" */
export type Attachment_Aggregate = {
  __typename?: "attachment_aggregate";
  aggregate?: Maybe<Attachment_Aggregate_Fields>;
  nodes: Array<Attachment>;
};

export type Attachment_Aggregate_Bool_Exp = {
  count?: Maybe<Attachment_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Attachment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Attachment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "attachment" */
export type Attachment_Aggregate_Fields = {
  __typename?: "attachment_aggregate_fields";
  avg?: Maybe<Attachment_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Attachment_Max_Fields>;
  min?: Maybe<Attachment_Min_Fields>;
  stddev?: Maybe<Attachment_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Sum_Fields>;
  var_pop?: Maybe<Attachment_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Variance_Fields>;
};

/** aggregate fields of "attachment" */
export type Attachment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Attachment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "attachment" */
export type Attachment_Aggregate_Order_By = {
  avg?: Maybe<Attachment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Attachment_Max_Order_By>;
  min?: Maybe<Attachment_Min_Order_By>;
  stddev?: Maybe<Attachment_Stddev_Order_By>;
  stddev_pop?: Maybe<Attachment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Attachment_Stddev_Samp_Order_By>;
  sum?: Maybe<Attachment_Sum_Order_By>;
  var_pop?: Maybe<Attachment_Var_Pop_Order_By>;
  var_samp?: Maybe<Attachment_Var_Samp_Order_By>;
  variance?: Maybe<Attachment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "attachment" */
export type Attachment_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Attachment_On_Conflict>;
};

/** aggregate avg on columns */
export type Attachment_Avg_Fields = {
  __typename?: "attachment_avg_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "attachment" */
export type Attachment_Avg_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment". All fields are combined with a logical 'AND'. */
export type Attachment_Bool_Exp = {
  _and?: Maybe<Array<Attachment_Bool_Exp>>;
  _not?: Maybe<Attachment_Bool_Exp>;
  _or?: Maybe<Array<Attachment_Bool_Exp>>;
  attachment_type_id?: Maybe<Int_Comparison_Exp>;
  attachment_uuid?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  folder?: Maybe<Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "attachment" */
export enum Attachment_Constraint {
  /** unique or primary key constraint on columns "id" */
  AttachmentPkey = "attachment_pkey",
}

/** input type for incrementing numeric columns in table "attachment" */
export type Attachment_Inc_Input = {
  attachment_type_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "attachment" */
export type Attachment_Insert_Input = {
  attachment_type_id?: Maybe<Scalars["Int"]>;
  attachment_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  folder?: Maybe<Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Attachment_Max_Fields = {
  __typename?: "attachment_max_fields";
  attachment_type_id?: Maybe<Scalars["Int"]>;
  attachment_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "attachment" */
export type Attachment_Max_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  attachment_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Attachment_Min_Fields = {
  __typename?: "attachment_min_fields";
  attachment_type_id?: Maybe<Scalars["Int"]>;
  attachment_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "attachment" */
export type Attachment_Min_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  attachment_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "attachment" */
export type Attachment_Mutation_Response = {
  __typename?: "attachment_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment>;
};

/** on_conflict condition type for table "attachment" */
export type Attachment_On_Conflict = {
  constraint: Attachment_Constraint;
  update_columns?: Array<Attachment_Update_Column>;
  where?: Maybe<Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment". */
export type Attachment_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  attachment_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  folder?: Maybe<Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: attachment */
export type Attachment_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "attachment" */
export enum Attachment_Select_Column {
  /** column name */
  AttachmentTypeId = "attachment_type_id",
  /** column name */
  AttachmentUuid = "attachment_uuid",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "attachment" */
export type Attachment_Set_Input = {
  attachment_type_id?: Maybe<Scalars["Int"]>;
  attachment_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Attachment_Stddev_Fields = {
  __typename?: "attachment_stddev_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "attachment" */
export type Attachment_Stddev_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Stddev_Pop_Fields = {
  __typename?: "attachment_stddev_pop_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "attachment" */
export type Attachment_Stddev_Pop_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Stddev_Samp_Fields = {
  __typename?: "attachment_stddev_samp_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "attachment" */
export type Attachment_Stddev_Samp_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "attachment" */
export type Attachment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Stream_Cursor_Value_Input = {
  attachment_type_id?: Maybe<Scalars["Int"]>;
  attachment_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Attachment_Sum_Fields = {
  __typename?: "attachment_sum_fields";
  attachment_type_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "attachment" */
export type Attachment_Sum_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "attachment_type" */
export type Attachment_Type = {
  __typename?: "attachment_type";
  attachment_type_name: Scalars["String"];
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** aggregated selection of "attachment_type" */
export type Attachment_Type_Aggregate = {
  __typename?: "attachment_type_aggregate";
  aggregate?: Maybe<Attachment_Type_Aggregate_Fields>;
  nodes: Array<Attachment_Type>;
};

/** aggregate fields of "attachment_type" */
export type Attachment_Type_Aggregate_Fields = {
  __typename?: "attachment_type_aggregate_fields";
  avg?: Maybe<Attachment_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Attachment_Type_Max_Fields>;
  min?: Maybe<Attachment_Type_Min_Fields>;
  stddev?: Maybe<Attachment_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Type_Sum_Fields>;
  var_pop?: Maybe<Attachment_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Type_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Type_Variance_Fields>;
};

/** aggregate fields of "attachment_type" */
export type Attachment_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Attachment_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Attachment_Type_Avg_Fields = {
  __typename?: "attachment_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "attachment_type". All fields are combined with a logical 'AND'. */
export type Attachment_Type_Bool_Exp = {
  _and?: Maybe<Array<Attachment_Type_Bool_Exp>>;
  _not?: Maybe<Attachment_Type_Bool_Exp>;
  _or?: Maybe<Array<Attachment_Type_Bool_Exp>>;
  attachment_type_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "attachment_type" */
export enum Attachment_Type_Constraint {
  /** unique or primary key constraint on columns "attachment_type_name" */
  AttachmentTypeAttachmentTypeNameKey = "attachment_type_attachment_type_name_key",
  /** unique or primary key constraint on columns "id" */
  AttachmentTypePkey = "attachment_type_pkey",
}

/** input type for incrementing numeric columns in table "attachment_type" */
export type Attachment_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "attachment_type" */
export type Attachment_Type_Insert_Input = {
  attachment_type_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Attachment_Type_Max_Fields = {
  __typename?: "attachment_type_max_fields";
  attachment_type_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Attachment_Type_Min_Fields = {
  __typename?: "attachment_type_min_fields";
  attachment_type_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "attachment_type" */
export type Attachment_Type_Mutation_Response = {
  __typename?: "attachment_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment_Type>;
};

/** on_conflict condition type for table "attachment_type" */
export type Attachment_Type_On_Conflict = {
  constraint: Attachment_Type_Constraint;
  update_columns?: Array<Attachment_Type_Update_Column>;
  where?: Maybe<Attachment_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment_type". */
export type Attachment_Type_Order_By = {
  attachment_type_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: attachment_type */
export type Attachment_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "attachment_type" */
export enum Attachment_Type_Select_Column {
  /** column name */
  AttachmentTypeName = "attachment_type_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "attachment_type" */
export type Attachment_Type_Set_Input = {
  attachment_type_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Attachment_Type_Stddev_Fields = {
  __typename?: "attachment_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Type_Stddev_Pop_Fields = {
  __typename?: "attachment_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Type_Stddev_Samp_Fields = {
  __typename?: "attachment_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "attachment_type" */
export type Attachment_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachment_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Type_Stream_Cursor_Value_Input = {
  attachment_type_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Attachment_Type_Sum_Fields = {
  __typename?: "attachment_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "attachment_type" */
export enum Attachment_Type_Update_Column {
  /** column name */
  AttachmentTypeName = "attachment_type_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

export type Attachment_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Attachment_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Attachment_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachment_Type_Var_Pop_Fields = {
  __typename?: "attachment_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Attachment_Type_Var_Samp_Fields = {
  __typename?: "attachment_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Attachment_Type_Variance_Fields = {
  __typename?: "attachment_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** update columns of table "attachment" */
export enum Attachment_Update_Column {
  /** column name */
  AttachmentTypeId = "attachment_type_id",
  /** column name */
  AttachmentUuid = "attachment_uuid",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  LastUpdated = "last_updated",
}

export type Attachment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Attachment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Attachment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachment_Var_Pop_Fields = {
  __typename?: "attachment_var_pop_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "attachment" */
export type Attachment_Var_Pop_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attachment_Var_Samp_Fields = {
  __typename?: "attachment_var_samp_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "attachment" */
export type Attachment_Var_Samp_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Attachment_Variance_Fields = {
  __typename?: "attachment_variance_fields";
  attachment_type_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "attachment" */
export type Attachment_Variance_Order_By = {
  attachment_type_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "base_product" */
export type Base_Product = {
  __typename?: "base_product";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  /** fetch data from the table: "service_account" */
  service_account: Array<Service_Account>;
  /** fetch aggregated fields from the table: "service_account" */
  service_account_aggregate: Service_Account_Aggregate;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "base_product" */
export type Base_ProductService_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

/** columns and relationships of "base_product" */
export type Base_ProductService_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

/** aggregated selection of "base_product" */
export type Base_Product_Aggregate = {
  __typename?: "base_product_aggregate";
  aggregate?: Maybe<Base_Product_Aggregate_Fields>;
  nodes: Array<Base_Product>;
};

/** aggregate fields of "base_product" */
export type Base_Product_Aggregate_Fields = {
  __typename?: "base_product_aggregate_fields";
  avg?: Maybe<Base_Product_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Base_Product_Max_Fields>;
  min?: Maybe<Base_Product_Min_Fields>;
  stddev?: Maybe<Base_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Base_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Base_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Base_Product_Sum_Fields>;
  var_pop?: Maybe<Base_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Base_Product_Var_Samp_Fields>;
  variance?: Maybe<Base_Product_Variance_Fields>;
};

/** aggregate fields of "base_product" */
export type Base_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Base_Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Base_Product_Avg_Fields = {
  __typename?: "base_product_avg_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "base_product". All fields are combined with a logical 'AND'. */
export type Base_Product_Bool_Exp = {
  _and?: Maybe<Array<Base_Product_Bool_Exp>>;
  _not?: Maybe<Base_Product_Bool_Exp>;
  _or?: Maybe<Array<Base_Product_Bool_Exp>>;
  base_product_id?: Maybe<Int_Comparison_Exp>;
  base_product_quantity?: Maybe<Int_Comparison_Exp>;
  case_product_id?: Maybe<Int_Comparison_Exp>;
  case_product_quantity?: Maybe<Int_Comparison_Exp>;
  external_product_id?: Maybe<String_Comparison_Exp>;
  fsc_certified?: Maybe<Boolean_Comparison_Exp>;
  grs_certified?: Maybe<Boolean_Comparison_Exp>;
  height_mm?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  length_mm?: Maybe<Int_Comparison_Exp>;
  product_name?: Maybe<String_Comparison_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_aggregate?: Maybe<Service_Account_Aggregate_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  weight_grams?: Maybe<Int_Comparison_Exp>;
  width_mm?: Maybe<Int_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "base_product" */
export type Base_Product_Inc_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "base_product" */
export type Base_Product_Insert_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account?: Maybe<Service_Account_Arr_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Base_Product_Max_Fields = {
  __typename?: "base_product_max_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Base_Product_Min_Fields = {
  __typename?: "base_product_min_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** response of any mutation on the table "base_product" */
export type Base_Product_Mutation_Response = {
  __typename?: "base_product_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Base_Product>;
};

/** Ordering options when selecting data from "base_product". */
export type Base_Product_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  fsc_certified?: Maybe<Order_By>;
  grs_certified?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  service_account_aggregate?: Maybe<Service_Account_Aggregate_Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** select columns of table "base_product" */
export enum Base_Product_Select_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  BaseProductQuantity = "base_product_quantity",
  /** column name */
  CaseProductId = "case_product_id",
  /** column name */
  CaseProductQuantity = "case_product_quantity",
  /** column name */
  ExternalProductId = "external_product_id",
  /** column name */
  FscCertified = "fsc_certified",
  /** column name */
  GrsCertified = "grs_certified",
  /** column name */
  HeightMm = "height_mm",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LengthMm = "length_mm",
  /** column name */
  ProductName = "product_name",
  /** column name */
  ServiceAccountId = "service_account_id",
  /** column name */
  WeightGrams = "weight_grams",
  /** column name */
  WidthMm = "width_mm",
}

/** input type for updating data in table "base_product" */
export type Base_Product_Set_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Base_Product_Stddev_Fields = {
  __typename?: "base_product_stddev_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Base_Product_Stddev_Pop_Fields = {
  __typename?: "base_product_stddev_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Base_Product_Stddev_Samp_Fields = {
  __typename?: "base_product_stddev_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "base_product" */
export type Base_Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Base_Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Base_Product_Stream_Cursor_Value_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Base_Product_Sum_Fields = {
  __typename?: "base_product_sum_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

export type Base_Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Base_Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Base_Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Base_Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Base_Product_Var_Pop_Fields = {
  __typename?: "base_product_var_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Base_Product_Var_Samp_Fields = {
  __typename?: "base_product_var_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Base_Product_Variance_Fields = {
  __typename?: "base_product_variance_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: Maybe<Scalars["bigint"]>;
  _gt?: Maybe<Scalars["bigint"]>;
  _gte?: Maybe<Scalars["bigint"]>;
  _in?: Maybe<Array<Scalars["bigint"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["bigint"]>;
  _lte?: Maybe<Scalars["bigint"]>;
  _neq?: Maybe<Scalars["bigint"]>;
  _nin?: Maybe<Array<Scalars["bigint"]>>;
};

/** columns and relationships of "case_change" */
export type Case_Change = {
  __typename?: "case_change";
  /** An array relationship */
  case_change_input_lines: Array<Case_Change_Input_Line>;
  /** An aggregate relationship */
  case_change_input_lines_aggregate: Case_Change_Input_Line_Aggregate;
  /** An array relationship */
  case_change_output_lines: Array<Case_Change_Output_Line>;
  /** An aggregate relationship */
  case_change_output_lines_aggregate: Case_Change_Output_Line_Aggregate;
  /** An object relationship */
  case_change_status: Case_Change_Status;
  case_change_status_id: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  due_date: Scalars["timestamptz"];
  from_product_id: Scalars["Int"];
  from_quantity: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars["Int"];
  /** An object relationship */
  product: Product;
  /** An object relationship */
  productByToProductId: Product;
  reference: Scalars["String"];
  to_cancelled_quantity: Scalars["Int"];
  to_completed_quantity: Scalars["Int"];
  to_outstanding_quantity: Scalars["Int"];
  to_product_id: Scalars["Int"];
  to_quantity: Scalars["Int"];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars["Int"];
};

/** columns and relationships of "case_change" */
export type Case_ChangeCase_Change_Input_LinesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

/** columns and relationships of "case_change" */
export type Case_ChangeCase_Change_Input_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

/** columns and relationships of "case_change" */
export type Case_ChangeCase_Change_Output_LinesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

/** columns and relationships of "case_change" */
export type Case_ChangeCase_Change_Output_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

/** aggregated selection of "case_change" */
export type Case_Change_Aggregate = {
  __typename?: "case_change_aggregate";
  aggregate?: Maybe<Case_Change_Aggregate_Fields>;
  nodes: Array<Case_Change>;
};

export type Case_Change_Aggregate_Bool_Exp = {
  count?: Maybe<Case_Change_Aggregate_Bool_Exp_Count>;
};

export type Case_Change_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Case_Change_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Case_Change_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_change" */
export type Case_Change_Aggregate_Fields = {
  __typename?: "case_change_aggregate_fields";
  avg?: Maybe<Case_Change_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Case_Change_Max_Fields>;
  min?: Maybe<Case_Change_Min_Fields>;
  stddev?: Maybe<Case_Change_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Change_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Change_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Change_Sum_Fields>;
  var_pop?: Maybe<Case_Change_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Change_Var_Samp_Fields>;
  variance?: Maybe<Case_Change_Variance_Fields>;
};

/** aggregate fields of "case_change" */
export type Case_Change_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Change_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_change" */
export type Case_Change_Aggregate_Order_By = {
  avg?: Maybe<Case_Change_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Change_Max_Order_By>;
  min?: Maybe<Case_Change_Min_Order_By>;
  stddev?: Maybe<Case_Change_Stddev_Order_By>;
  stddev_pop?: Maybe<Case_Change_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Case_Change_Stddev_Samp_Order_By>;
  sum?: Maybe<Case_Change_Sum_Order_By>;
  var_pop?: Maybe<Case_Change_Var_Pop_Order_By>;
  var_samp?: Maybe<Case_Change_Var_Samp_Order_By>;
  variance?: Maybe<Case_Change_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "case_change" */
export type Case_Change_Arr_Rel_Insert_Input = {
  data: Array<Case_Change_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Case_Change_On_Conflict>;
};

/** aggregate avg on columns */
export type Case_Change_Avg_Fields = {
  __typename?: "case_change_avg_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "case_change" */
export type Case_Change_Avg_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "case_change". All fields are combined with a logical 'AND'. */
export type Case_Change_Bool_Exp = {
  _and?: Maybe<Array<Case_Change_Bool_Exp>>;
  _not?: Maybe<Case_Change_Bool_Exp>;
  _or?: Maybe<Array<Case_Change_Bool_Exp>>;
  case_change_input_lines?: Maybe<Case_Change_Input_Line_Bool_Exp>;
  case_change_input_lines_aggregate?: Maybe<Case_Change_Input_Line_Aggregate_Bool_Exp>;
  case_change_output_lines?: Maybe<Case_Change_Output_Line_Bool_Exp>;
  case_change_output_lines_aggregate?: Maybe<Case_Change_Output_Line_Aggregate_Bool_Exp>;
  case_change_status?: Maybe<Case_Change_Status_Bool_Exp>;
  case_change_status_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_date?: Maybe<Timestamptz_Comparison_Exp>;
  from_product_id?: Maybe<Int_Comparison_Exp>;
  from_quantity?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  productByToProductId?: Maybe<Product_Bool_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  to_cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  to_completed_quantity?: Maybe<Int_Comparison_Exp>;
  to_outstanding_quantity?: Maybe<Int_Comparison_Exp>;
  to_product_id?: Maybe<Int_Comparison_Exp>;
  to_quantity?: Maybe<Int_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_change" */
export enum Case_Change_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseChangePkey = "case_change_pkey",
  /** unique or primary key constraint on columns "reference" */
  CaseChangeReferenceKey = "case_change_reference_key",
}

/** input type for incrementing numeric columns in table "case_change" */
export type Case_Change_Inc_Input = {
  case_change_status_id?: Maybe<Scalars["Int"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "case_change_input_line" */
export type Case_Change_Input_Line = {
  __typename?: "case_change_input_line";
  /** An object relationship */
  case_change: Case_Change;
  case_change_id: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  from_quantity: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product_stock: Product_Stock;
  source_product_stock_id: Scalars["Int"];
};

/** aggregated selection of "case_change_input_line" */
export type Case_Change_Input_Line_Aggregate = {
  __typename?: "case_change_input_line_aggregate";
  aggregate?: Maybe<Case_Change_Input_Line_Aggregate_Fields>;
  nodes: Array<Case_Change_Input_Line>;
};

export type Case_Change_Input_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Case_Change_Input_Line_Aggregate_Bool_Exp_Count>;
};

export type Case_Change_Input_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Case_Change_Input_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_change_input_line" */
export type Case_Change_Input_Line_Aggregate_Fields = {
  __typename?: "case_change_input_line_aggregate_fields";
  avg?: Maybe<Case_Change_Input_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Case_Change_Input_Line_Max_Fields>;
  min?: Maybe<Case_Change_Input_Line_Min_Fields>;
  stddev?: Maybe<Case_Change_Input_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Change_Input_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Change_Input_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Change_Input_Line_Sum_Fields>;
  var_pop?: Maybe<Case_Change_Input_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Change_Input_Line_Var_Samp_Fields>;
  variance?: Maybe<Case_Change_Input_Line_Variance_Fields>;
};

/** aggregate fields of "case_change_input_line" */
export type Case_Change_Input_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_change_input_line" */
export type Case_Change_Input_Line_Aggregate_Order_By = {
  avg?: Maybe<Case_Change_Input_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Change_Input_Line_Max_Order_By>;
  min?: Maybe<Case_Change_Input_Line_Min_Order_By>;
  stddev?: Maybe<Case_Change_Input_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Case_Change_Input_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Case_Change_Input_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Case_Change_Input_Line_Sum_Order_By>;
  var_pop?: Maybe<Case_Change_Input_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Case_Change_Input_Line_Var_Samp_Order_By>;
  variance?: Maybe<Case_Change_Input_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "case_change_input_line" */
export type Case_Change_Input_Line_Arr_Rel_Insert_Input = {
  data: Array<Case_Change_Input_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Case_Change_Input_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Case_Change_Input_Line_Avg_Fields = {
  __typename?: "case_change_input_line_avg_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Avg_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "case_change_input_line". All fields are combined with a logical 'AND'. */
export type Case_Change_Input_Line_Bool_Exp = {
  _and?: Maybe<Array<Case_Change_Input_Line_Bool_Exp>>;
  _not?: Maybe<Case_Change_Input_Line_Bool_Exp>;
  _or?: Maybe<Array<Case_Change_Input_Line_Bool_Exp>>;
  case_change?: Maybe<Case_Change_Bool_Exp>;
  case_change_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_quantity?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_change_input_line" */
export enum Case_Change_Input_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseChangeInputLinePkey = "case_change_input_line_pkey",
}

/** input type for incrementing numeric columns in table "case_change_input_line" */
export type Case_Change_Input_Line_Inc_Input = {
  case_change_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "case_change_input_line" */
export type Case_Change_Input_Line_Insert_Input = {
  case_change?: Maybe<Case_Change_Obj_Rel_Insert_Input>;
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Case_Change_Input_Line_Max_Fields = {
  __typename?: "case_change_input_line_max_fields";
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Max_Order_By = {
  case_change_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Change_Input_Line_Min_Fields = {
  __typename?: "case_change_input_line_min_fields";
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Min_Order_By = {
  case_change_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_change_input_line" */
export type Case_Change_Input_Line_Mutation_Response = {
  __typename?: "case_change_input_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Change_Input_Line>;
};

/** on_conflict condition type for table "case_change_input_line" */
export type Case_Change_Input_Line_On_Conflict = {
  constraint: Case_Change_Input_Line_Constraint;
  update_columns?: Array<Case_Change_Input_Line_Update_Column>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "case_change_input_line". */
export type Case_Change_Input_Line_Order_By = {
  case_change?: Maybe<Case_Change_Order_By>;
  case_change_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** primary key columns input for table: case_change_input_line */
export type Case_Change_Input_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "case_change_input_line" */
export enum Case_Change_Input_Line_Select_Column {
  /** column name */
  CaseChangeId = "case_change_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromQuantity = "from_quantity",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
}

/** input type for updating data in table "case_change_input_line" */
export type Case_Change_Input_Line_Set_Input = {
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Case_Change_Input_Line_Stddev_Fields = {
  __typename?: "case_change_input_line_stddev_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Stddev_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Case_Change_Input_Line_Stddev_Pop_Fields = {
  __typename?: "case_change_input_line_stddev_pop_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Stddev_Pop_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Case_Change_Input_Line_Stddev_Samp_Fields = {
  __typename?: "case_change_input_line_stddev_samp_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Stddev_Samp_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "case_change_input_line" */
export type Case_Change_Input_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Change_Input_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Change_Input_Line_Stream_Cursor_Value_Input = {
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Case_Change_Input_Line_Sum_Fields = {
  __typename?: "case_change_input_line_sum_fields";
  case_change_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Sum_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** update columns of table "case_change_input_line" */
export enum Case_Change_Input_Line_Update_Column {
  /** column name */
  CaseChangeId = "case_change_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromQuantity = "from_quantity",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
}

export type Case_Change_Input_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Case_Change_Input_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Case_Change_Input_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Change_Input_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Case_Change_Input_Line_Var_Pop_Fields = {
  __typename?: "case_change_input_line_var_pop_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Var_Pop_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Case_Change_Input_Line_Var_Samp_Fields = {
  __typename?: "case_change_input_line_var_samp_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Var_Samp_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Case_Change_Input_Line_Variance_Fields = {
  __typename?: "case_change_input_line_variance_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "case_change_input_line" */
export type Case_Change_Input_Line_Variance_Order_By = {
  case_change_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** input type for inserting data into table "case_change" */
export type Case_Change_Insert_Input = {
  case_change_input_lines?: Maybe<Case_Change_Input_Line_Arr_Rel_Insert_Input>;
  case_change_output_lines?: Maybe<Case_Change_Output_Line_Arr_Rel_Insert_Input>;
  case_change_status?: Maybe<Case_Change_Status_Obj_Rel_Insert_Input>;
  case_change_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  productByToProductId?: Maybe<Product_Obj_Rel_Insert_Input>;
  reference?: Maybe<Scalars["String"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Case_Change_Max_Fields = {
  __typename?: "case_change_max_fields";
  case_change_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "case_change" */
export type Case_Change_Max_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Change_Min_Fields = {
  __typename?: "case_change_min_fields";
  case_change_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "case_change" */
export type Case_Change_Min_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_change" */
export type Case_Change_Mutation_Response = {
  __typename?: "case_change_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Change>;
};

/** input type for inserting object relation for remote table "case_change" */
export type Case_Change_Obj_Rel_Insert_Input = {
  data: Case_Change_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Case_Change_On_Conflict>;
};

/** on_conflict condition type for table "case_change" */
export type Case_Change_On_Conflict = {
  constraint: Case_Change_Constraint;
  update_columns?: Array<Case_Change_Update_Column>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** Ordering options when selecting data from "case_change". */
export type Case_Change_Order_By = {
  case_change_input_lines_aggregate?: Maybe<Case_Change_Input_Line_Aggregate_Order_By>;
  case_change_output_lines_aggregate?: Maybe<Case_Change_Output_Line_Aggregate_Order_By>;
  case_change_status?: Maybe<Case_Change_Status_Order_By>;
  case_change_status_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  productByToProductId?: Maybe<Product_Order_By>;
  reference?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "case_change_output_line" */
export type Case_Change_Output_Line = {
  __typename?: "case_change_output_line";
  /** An object relationship */
  case_change: Case_Change;
  case_change_id: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Location;
  location_id: Scalars["Int"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  to_quantity: Scalars["Int"];
};

/** columns and relationships of "case_change_output_line" */
export type Case_Change_Output_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "case_change_output_line" */
export type Case_Change_Output_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "case_change_output_line" */
export type Case_Change_Output_Line_Aggregate = {
  __typename?: "case_change_output_line_aggregate";
  aggregate?: Maybe<Case_Change_Output_Line_Aggregate_Fields>;
  nodes: Array<Case_Change_Output_Line>;
};

export type Case_Change_Output_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Case_Change_Output_Line_Aggregate_Bool_Exp_Count>;
};

export type Case_Change_Output_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Case_Change_Output_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "case_change_output_line" */
export type Case_Change_Output_Line_Aggregate_Fields = {
  __typename?: "case_change_output_line_aggregate_fields";
  avg?: Maybe<Case_Change_Output_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Case_Change_Output_Line_Max_Fields>;
  min?: Maybe<Case_Change_Output_Line_Min_Fields>;
  stddev?: Maybe<Case_Change_Output_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Change_Output_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Change_Output_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Change_Output_Line_Sum_Fields>;
  var_pop?: Maybe<Case_Change_Output_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Change_Output_Line_Var_Samp_Fields>;
  variance?: Maybe<Case_Change_Output_Line_Variance_Fields>;
};

/** aggregate fields of "case_change_output_line" */
export type Case_Change_Output_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "case_change_output_line" */
export type Case_Change_Output_Line_Aggregate_Order_By = {
  avg?: Maybe<Case_Change_Output_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Case_Change_Output_Line_Max_Order_By>;
  min?: Maybe<Case_Change_Output_Line_Min_Order_By>;
  stddev?: Maybe<Case_Change_Output_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Case_Change_Output_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Case_Change_Output_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Case_Change_Output_Line_Sum_Order_By>;
  var_pop?: Maybe<Case_Change_Output_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Case_Change_Output_Line_Var_Samp_Order_By>;
  variance?: Maybe<Case_Change_Output_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "case_change_output_line" */
export type Case_Change_Output_Line_Arr_Rel_Insert_Input = {
  data: Array<Case_Change_Output_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Case_Change_Output_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Case_Change_Output_Line_Avg_Fields = {
  __typename?: "case_change_output_line_avg_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Avg_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "case_change_output_line". All fields are combined with a logical 'AND'. */
export type Case_Change_Output_Line_Bool_Exp = {
  _and?: Maybe<Array<Case_Change_Output_Line_Bool_Exp>>;
  _not?: Maybe<Case_Change_Output_Line_Bool_Exp>;
  _or?: Maybe<Array<Case_Change_Output_Line_Bool_Exp>>;
  case_change?: Maybe<Case_Change_Bool_Exp>;
  case_change_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  to_quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_change_output_line" */
export enum Case_Change_Output_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  CaseChangeOutputLinePkey = "case_change_output_line_pkey",
}

/** input type for incrementing numeric columns in table "case_change_output_line" */
export type Case_Change_Output_Line_Inc_Input = {
  case_change_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "case_change_output_line" */
export type Case_Change_Output_Line_Insert_Input = {
  case_change?: Maybe<Case_Change_Obj_Rel_Insert_Input>;
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Case_Change_Output_Line_Max_Fields = {
  __typename?: "case_change_output_line_max_fields";
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Max_Order_By = {
  case_change_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Case_Change_Output_Line_Min_Fields = {
  __typename?: "case_change_output_line_min_fields";
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Min_Order_By = {
  case_change_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "case_change_output_line" */
export type Case_Change_Output_Line_Mutation_Response = {
  __typename?: "case_change_output_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Change_Output_Line>;
};

/** input type for inserting object relation for remote table "case_change_output_line" */
export type Case_Change_Output_Line_Obj_Rel_Insert_Input = {
  data: Case_Change_Output_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Case_Change_Output_Line_On_Conflict>;
};

/** on_conflict condition type for table "case_change_output_line" */
export type Case_Change_Output_Line_On_Conflict = {
  constraint: Case_Change_Output_Line_Constraint;
  update_columns?: Array<Case_Change_Output_Line_Update_Column>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "case_change_output_line". */
export type Case_Change_Output_Line_Order_By = {
  case_change?: Maybe<Case_Change_Order_By>;
  case_change_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: case_change_output_line */
export type Case_Change_Output_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "case_change_output_line" */
export enum Case_Change_Output_Line_Select_Column {
  /** column name */
  CaseChangeId = "case_change_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ToQuantity = "to_quantity",
}

/** input type for updating data in table "case_change_output_line" */
export type Case_Change_Output_Line_Set_Input = {
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Case_Change_Output_Line_Stddev_Fields = {
  __typename?: "case_change_output_line_stddev_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Stddev_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Case_Change_Output_Line_Stddev_Pop_Fields = {
  __typename?: "case_change_output_line_stddev_pop_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Stddev_Pop_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Case_Change_Output_Line_Stddev_Samp_Fields = {
  __typename?: "case_change_output_line_stddev_samp_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Stddev_Samp_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "case_change_output_line" */
export type Case_Change_Output_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Change_Output_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Change_Output_Line_Stream_Cursor_Value_Input = {
  case_change_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Case_Change_Output_Line_Sum_Fields = {
  __typename?: "case_change_output_line_sum_fields";
  case_change_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Sum_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** update columns of table "case_change_output_line" */
export enum Case_Change_Output_Line_Update_Column {
  /** column name */
  CaseChangeId = "case_change_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ToQuantity = "to_quantity",
}

export type Case_Change_Output_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Case_Change_Output_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Case_Change_Output_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Change_Output_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Case_Change_Output_Line_Var_Pop_Fields = {
  __typename?: "case_change_output_line_var_pop_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Var_Pop_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Case_Change_Output_Line_Var_Samp_Fields = {
  __typename?: "case_change_output_line_var_samp_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Var_Samp_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Case_Change_Output_Line_Variance_Fields = {
  __typename?: "case_change_output_line_variance_fields";
  case_change_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "case_change_output_line" */
export type Case_Change_Output_Line_Variance_Order_By = {
  case_change_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: case_change */
export type Case_Change_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "case_change" */
export enum Case_Change_Select_Column {
  /** column name */
  CaseChangeStatusId = "case_change_status_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  FromProductId = "from_product_id",
  /** column name */
  FromQuantity = "from_quantity",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ToCancelledQuantity = "to_cancelled_quantity",
  /** column name */
  ToCompletedQuantity = "to_completed_quantity",
  /** column name */
  ToOutstandingQuantity = "to_outstanding_quantity",
  /** column name */
  ToProductId = "to_product_id",
  /** column name */
  ToQuantity = "to_quantity",
  /** column name */
  WarehouseId = "warehouse_id",
}

/** input type for updating data in table "case_change" */
export type Case_Change_Set_Input = {
  case_change_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "case_change_status" */
export type Case_Change_Status = {
  __typename?: "case_change_status";
  case_change_status_name: Scalars["String"];
  /** An array relationship */
  case_changes: Array<Case_Change>;
  /** An aggregate relationship */
  case_changes_aggregate: Case_Change_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "case_change_status" */
export type Case_Change_StatusCase_ChangesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "case_change_status" */
export type Case_Change_StatusCase_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** aggregated selection of "case_change_status" */
export type Case_Change_Status_Aggregate = {
  __typename?: "case_change_status_aggregate";
  aggregate?: Maybe<Case_Change_Status_Aggregate_Fields>;
  nodes: Array<Case_Change_Status>;
};

/** aggregate fields of "case_change_status" */
export type Case_Change_Status_Aggregate_Fields = {
  __typename?: "case_change_status_aggregate_fields";
  avg?: Maybe<Case_Change_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Case_Change_Status_Max_Fields>;
  min?: Maybe<Case_Change_Status_Min_Fields>;
  stddev?: Maybe<Case_Change_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Case_Change_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Case_Change_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Case_Change_Status_Sum_Fields>;
  var_pop?: Maybe<Case_Change_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Case_Change_Status_Var_Samp_Fields>;
  variance?: Maybe<Case_Change_Status_Variance_Fields>;
};

/** aggregate fields of "case_change_status" */
export type Case_Change_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Case_Change_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Case_Change_Status_Avg_Fields = {
  __typename?: "case_change_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "case_change_status". All fields are combined with a logical 'AND'. */
export type Case_Change_Status_Bool_Exp = {
  _and?: Maybe<Array<Case_Change_Status_Bool_Exp>>;
  _not?: Maybe<Case_Change_Status_Bool_Exp>;
  _or?: Maybe<Array<Case_Change_Status_Bool_Exp>>;
  case_change_status_name?: Maybe<String_Comparison_Exp>;
  case_changes?: Maybe<Case_Change_Bool_Exp>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "case_change_status" */
export enum Case_Change_Status_Constraint {
  /** unique or primary key constraint on columns "case_change_status_name" */
  CaseChangeStatusCaseChangeStatusNameKey = "case_change_status_case_change_status_name_key",
  /** unique or primary key constraint on columns "id" */
  CaseChangeStatusPkey = "case_change_status_pkey",
}

/** input type for incrementing numeric columns in table "case_change_status" */
export type Case_Change_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "case_change_status" */
export type Case_Change_Status_Insert_Input = {
  case_change_status_name?: Maybe<Scalars["String"]>;
  case_changes?: Maybe<Case_Change_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Case_Change_Status_Max_Fields = {
  __typename?: "case_change_status_max_fields";
  case_change_status_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Case_Change_Status_Min_Fields = {
  __typename?: "case_change_status_min_fields";
  case_change_status_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "case_change_status" */
export type Case_Change_Status_Mutation_Response = {
  __typename?: "case_change_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Case_Change_Status>;
};

/** input type for inserting object relation for remote table "case_change_status" */
export type Case_Change_Status_Obj_Rel_Insert_Input = {
  data: Case_Change_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Case_Change_Status_On_Conflict>;
};

/** on_conflict condition type for table "case_change_status" */
export type Case_Change_Status_On_Conflict = {
  constraint: Case_Change_Status_Constraint;
  update_columns?: Array<Case_Change_Status_Update_Column>;
  where?: Maybe<Case_Change_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "case_change_status". */
export type Case_Change_Status_Order_By = {
  case_change_status_name?: Maybe<Order_By>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: case_change_status */
export type Case_Change_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "case_change_status" */
export enum Case_Change_Status_Select_Column {
  /** column name */
  CaseChangeStatusName = "case_change_status_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "case_change_status" */
export type Case_Change_Status_Set_Input = {
  case_change_status_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Case_Change_Status_Stddev_Fields = {
  __typename?: "case_change_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Case_Change_Status_Stddev_Pop_Fields = {
  __typename?: "case_change_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Case_Change_Status_Stddev_Samp_Fields = {
  __typename?: "case_change_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "case_change_status" */
export type Case_Change_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Change_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Change_Status_Stream_Cursor_Value_Input = {
  case_change_status_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Case_Change_Status_Sum_Fields = {
  __typename?: "case_change_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "case_change_status" */
export enum Case_Change_Status_Update_Column {
  /** column name */
  CaseChangeStatusName = "case_change_status_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

export type Case_Change_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Case_Change_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Case_Change_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Change_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Case_Change_Status_Var_Pop_Fields = {
  __typename?: "case_change_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Case_Change_Status_Var_Samp_Fields = {
  __typename?: "case_change_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Case_Change_Status_Variance_Fields = {
  __typename?: "case_change_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Case_Change_Stddev_Fields = {
  __typename?: "case_change_stddev_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "case_change" */
export type Case_Change_Stddev_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Case_Change_Stddev_Pop_Fields = {
  __typename?: "case_change_stddev_pop_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "case_change" */
export type Case_Change_Stddev_Pop_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Case_Change_Stddev_Samp_Fields = {
  __typename?: "case_change_stddev_samp_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "case_change" */
export type Case_Change_Stddev_Samp_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "case_change" */
export type Case_Change_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Case_Change_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Case_Change_Stream_Cursor_Value_Input = {
  case_change_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Case_Change_Sum_Fields = {
  __typename?: "case_change_sum_fields";
  case_change_status_id?: Maybe<Scalars["Int"]>;
  from_product_id?: Maybe<Scalars["Int"]>;
  from_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  to_cancelled_quantity?: Maybe<Scalars["Int"]>;
  to_completed_quantity?: Maybe<Scalars["Int"]>;
  to_outstanding_quantity?: Maybe<Scalars["Int"]>;
  to_product_id?: Maybe<Scalars["Int"]>;
  to_quantity?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "case_change" */
export type Case_Change_Sum_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "case_change" */
export enum Case_Change_Update_Column {
  /** column name */
  CaseChangeStatusId = "case_change_status_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  FromProductId = "from_product_id",
  /** column name */
  FromQuantity = "from_quantity",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ToCancelledQuantity = "to_cancelled_quantity",
  /** column name */
  ToCompletedQuantity = "to_completed_quantity",
  /** column name */
  ToOutstandingQuantity = "to_outstanding_quantity",
  /** column name */
  ToProductId = "to_product_id",
  /** column name */
  ToQuantity = "to_quantity",
  /** column name */
  WarehouseId = "warehouse_id",
}

export type Case_Change_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Case_Change_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Case_Change_Set_Input>;
  /** filter the rows which have to be updated */
  where: Case_Change_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Case_Change_Var_Pop_Fields = {
  __typename?: "case_change_var_pop_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "case_change" */
export type Case_Change_Var_Pop_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Case_Change_Var_Samp_Fields = {
  __typename?: "case_change_var_samp_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "case_change" */
export type Case_Change_Var_Samp_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Case_Change_Variance_Fields = {
  __typename?: "case_change_variance_fields";
  case_change_status_id?: Maybe<Scalars["Float"]>;
  from_product_id?: Maybe<Scalars["Float"]>;
  from_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  to_cancelled_quantity?: Maybe<Scalars["Float"]>;
  to_completed_quantity?: Maybe<Scalars["Float"]>;
  to_outstanding_quantity?: Maybe<Scalars["Float"]>;
  to_product_id?: Maybe<Scalars["Float"]>;
  to_quantity?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "case_change" */
export type Case_Change_Variance_Order_By = {
  case_change_status_id?: Maybe<Order_By>;
  from_product_id?: Maybe<Order_By>;
  from_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  to_cancelled_quantity?: Maybe<Order_By>;
  to_completed_quantity?: Maybe<Order_By>;
  to_outstanding_quantity?: Maybe<Order_By>;
  to_product_id?: Maybe<Order_By>;
  to_quantity?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: "country";
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  continent: Scalars["String"];
  country_code: Scalars["String"];
  country_name: Scalars["String"];
  created_at: Scalars["timestamptz"];
  internet_tld: Scalars["String"];
  last_updated: Scalars["timestamptz"];
  official_name: Scalars["String"];
  region: Scalars["String"];
  sovereignty: Scalars["String"];
};

/** columns and relationships of "country" */
export type CountryAddressesArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

/** columns and relationships of "country" */
export type CountryAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: "country_aggregate";
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: "country_aggregate_fields";
  count: Scalars["Int"];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Country_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: Maybe<Array<Country_Bool_Exp>>;
  _not?: Maybe<Country_Bool_Exp>;
  _or?: Maybe<Array<Country_Bool_Exp>>;
  addresses?: Maybe<Address_Bool_Exp>;
  addresses_aggregate?: Maybe<Address_Aggregate_Bool_Exp>;
  continent?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  country_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  internet_tld?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  official_name?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  sovereignty?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "country_code" */
  CountryPkey = "country_pkey",
}

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  addresses?: Maybe<Address_Arr_Rel_Insert_Input>;
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: "country_max_fields";
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: "country_min_fields";
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: "country_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: Maybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  continent?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  internet_tld?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  official_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  sovereignty?: Maybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  country_code: Scalars["String"];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  Continent = "continent",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  CountryName = "country_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  InternetTld = "internet_tld",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OfficialName = "official_name",
  /** column name */
  Region = "region",
  /** column name */
  Sovereignty = "sovereignty",
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  continent?: Maybe<Scalars["String"]>;
  country_code?: Maybe<Scalars["String"]>;
  country_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internet_tld?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  official_name?: Maybe<Scalars["String"]>;
  region?: Maybe<Scalars["String"]>;
  sovereignty?: Maybe<Scalars["String"]>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  Continent = "continent",
  /** column name */
  CountryCode = "country_code",
  /** column name */
  CountryName = "country_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  InternetTld = "internet_tld",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OfficialName = "official_name",
  /** column name */
  Region = "region",
  /** column name */
  Sovereignty = "sovereignty",
}

export type Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** columns and relationships of "courier" */
export type Courier = {
  __typename?: "courier";
  /** An object relationship */
  courier_location?: Maybe<Courier_Location>;
  courier_name: Scalars["String"];
  /** An array relationship */
  courier_services: Array<Courier_Service>;
  /** An aggregate relationship */
  courier_services_aggregate: Courier_Service_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "courier" */
export type CourierCourier_ServicesArgs = {
  distinct_on?: Maybe<Array<Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Service_Order_By>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

/** columns and relationships of "courier" */
export type CourierCourier_Services_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Service_Order_By>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

/** aggregated selection of "courier" */
export type Courier_Aggregate = {
  __typename?: "courier_aggregate";
  aggregate?: Maybe<Courier_Aggregate_Fields>;
  nodes: Array<Courier>;
};

/** aggregate fields of "courier" */
export type Courier_Aggregate_Fields = {
  __typename?: "courier_aggregate_fields";
  avg?: Maybe<Courier_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Courier_Max_Fields>;
  min?: Maybe<Courier_Min_Fields>;
  stddev?: Maybe<Courier_Stddev_Fields>;
  stddev_pop?: Maybe<Courier_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Courier_Stddev_Samp_Fields>;
  sum?: Maybe<Courier_Sum_Fields>;
  var_pop?: Maybe<Courier_Var_Pop_Fields>;
  var_samp?: Maybe<Courier_Var_Samp_Fields>;
  variance?: Maybe<Courier_Variance_Fields>;
};

/** aggregate fields of "courier" */
export type Courier_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Courier_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Courier_Avg_Fields = {
  __typename?: "courier_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "courier". All fields are combined with a logical 'AND'. */
export type Courier_Bool_Exp = {
  _and?: Maybe<Array<Courier_Bool_Exp>>;
  _not?: Maybe<Courier_Bool_Exp>;
  _or?: Maybe<Array<Courier_Bool_Exp>>;
  courier_location?: Maybe<Courier_Location_Bool_Exp>;
  courier_name?: Maybe<String_Comparison_Exp>;
  courier_services?: Maybe<Courier_Service_Bool_Exp>;
  courier_services_aggregate?: Maybe<Courier_Service_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "courier" */
export enum Courier_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourierPkey = "courier_pkey",
}

/** input type for inserting data into table "courier" */
export type Courier_Insert_Input = {
  courier_location?: Maybe<Courier_Location_Obj_Rel_Insert_Input>;
  courier_name?: Maybe<Scalars["String"]>;
  courier_services?: Maybe<Courier_Service_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "courier_location" */
export type Courier_Location = {
  __typename?: "courier_location";
  /** An object relationship */
  courier: Courier;
  courier_id: Scalars["Int"];
  /** An object relationship */
  courier_location: Courier_Location;
  /** An array relationship */
  courier_locations: Array<Courier_Location>;
  /** An aggregate relationship */
  courier_locations_aggregate: Courier_Location_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  inbound: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  location_id: Scalars["Int"];
  outbound: Scalars["Boolean"];
};

/** columns and relationships of "courier_location" */
export type Courier_LocationCourier_LocationsArgs = {
  distinct_on?: Maybe<Array<Courier_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Location_Order_By>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

/** columns and relationships of "courier_location" */
export type Courier_LocationCourier_Locations_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Location_Order_By>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

/** aggregated selection of "courier_location" */
export type Courier_Location_Aggregate = {
  __typename?: "courier_location_aggregate";
  aggregate?: Maybe<Courier_Location_Aggregate_Fields>;
  nodes: Array<Courier_Location>;
};

export type Courier_Location_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Courier_Location_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Courier_Location_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Courier_Location_Aggregate_Bool_Exp_Count>;
};

export type Courier_Location_Aggregate_Bool_Exp_Bool_And = {
  arguments: Courier_Location_Select_Column_Courier_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Courier_Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Courier_Location_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Courier_Location_Select_Column_Courier_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Courier_Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Courier_Location_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Courier_Location_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Courier_Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courier_location" */
export type Courier_Location_Aggregate_Fields = {
  __typename?: "courier_location_aggregate_fields";
  avg?: Maybe<Courier_Location_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Courier_Location_Max_Fields>;
  min?: Maybe<Courier_Location_Min_Fields>;
  stddev?: Maybe<Courier_Location_Stddev_Fields>;
  stddev_pop?: Maybe<Courier_Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Courier_Location_Stddev_Samp_Fields>;
  sum?: Maybe<Courier_Location_Sum_Fields>;
  var_pop?: Maybe<Courier_Location_Var_Pop_Fields>;
  var_samp?: Maybe<Courier_Location_Var_Samp_Fields>;
  variance?: Maybe<Courier_Location_Variance_Fields>;
};

/** aggregate fields of "courier_location" */
export type Courier_Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Courier_Location_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "courier_location" */
export type Courier_Location_Aggregate_Order_By = {
  avg?: Maybe<Courier_Location_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Courier_Location_Max_Order_By>;
  min?: Maybe<Courier_Location_Min_Order_By>;
  stddev?: Maybe<Courier_Location_Stddev_Order_By>;
  stddev_pop?: Maybe<Courier_Location_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Courier_Location_Stddev_Samp_Order_By>;
  sum?: Maybe<Courier_Location_Sum_Order_By>;
  var_pop?: Maybe<Courier_Location_Var_Pop_Order_By>;
  var_samp?: Maybe<Courier_Location_Var_Samp_Order_By>;
  variance?: Maybe<Courier_Location_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "courier_location" */
export type Courier_Location_Arr_Rel_Insert_Input = {
  data: Array<Courier_Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Courier_Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Courier_Location_Avg_Fields = {
  __typename?: "courier_location_avg_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "courier_location" */
export type Courier_Location_Avg_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "courier_location". All fields are combined with a logical 'AND'. */
export type Courier_Location_Bool_Exp = {
  _and?: Maybe<Array<Courier_Location_Bool_Exp>>;
  _not?: Maybe<Courier_Location_Bool_Exp>;
  _or?: Maybe<Array<Courier_Location_Bool_Exp>>;
  courier?: Maybe<Courier_Bool_Exp>;
  courier_id?: Maybe<Int_Comparison_Exp>;
  courier_location?: Maybe<Courier_Location_Bool_Exp>;
  courier_locations?: Maybe<Courier_Location_Bool_Exp>;
  courier_locations_aggregate?: Maybe<Courier_Location_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  outbound?: Maybe<Boolean_Comparison_Exp>;
};

/** unique or primary key constraints on table "courier_location" */
export enum Courier_Location_Constraint {
  /** unique or primary key constraint on columns "courier_id" */
  CourierLocationCourierIdKey = "courier_location_courier_id_key",
  /** unique or primary key constraint on columns "id" */
  CourierLocationPkey = "courier_location_pkey",
}

/** input type for incrementing numeric columns in table "courier_location" */
export type Courier_Location_Inc_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "courier_location" */
export type Courier_Location_Insert_Input = {
  courier?: Maybe<Courier_Obj_Rel_Insert_Input>;
  courier_id?: Maybe<Scalars["Int"]>;
  courier_location?: Maybe<Courier_Location_Obj_Rel_Insert_Input>;
  courier_locations?: Maybe<Courier_Location_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  inbound?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  outbound?: Maybe<Scalars["Boolean"]>;
};

/** aggregate max on columns */
export type Courier_Location_Max_Fields = {
  __typename?: "courier_location_max_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "courier_location" */
export type Courier_Location_Max_Order_By = {
  courier_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Courier_Location_Min_Fields = {
  __typename?: "courier_location_min_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "courier_location" */
export type Courier_Location_Min_Order_By = {
  courier_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "courier_location" */
export type Courier_Location_Mutation_Response = {
  __typename?: "courier_location_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Courier_Location>;
};

/** input type for inserting object relation for remote table "courier_location" */
export type Courier_Location_Obj_Rel_Insert_Input = {
  data: Courier_Location_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Courier_Location_On_Conflict>;
};

/** on_conflict condition type for table "courier_location" */
export type Courier_Location_On_Conflict = {
  constraint: Courier_Location_Constraint;
  update_columns?: Array<Courier_Location_Update_Column>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

/** Ordering options when selecting data from "courier_location". */
export type Courier_Location_Order_By = {
  courier?: Maybe<Courier_Order_By>;
  courier_id?: Maybe<Order_By>;
  courier_location?: Maybe<Courier_Location_Order_By>;
  courier_locations_aggregate?: Maybe<Courier_Location_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  outbound?: Maybe<Order_By>;
};

/** primary key columns input for table: courier_location */
export type Courier_Location_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "courier_location" */
export enum Courier_Location_Select_Column {
  /** column name */
  CourierId = "courier_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  Inbound = "inbound",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Outbound = "outbound",
}

/** select "courier_location_aggregate_bool_exp_bool_and_arguments_columns" columns of table "courier_location" */
export enum Courier_Location_Select_Column_Courier_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Inbound = "inbound",
  /** column name */
  Outbound = "outbound",
}

/** select "courier_location_aggregate_bool_exp_bool_or_arguments_columns" columns of table "courier_location" */
export enum Courier_Location_Select_Column_Courier_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Inbound = "inbound",
  /** column name */
  Outbound = "outbound",
}

/** input type for updating data in table "courier_location" */
export type Courier_Location_Set_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  inbound?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  outbound?: Maybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type Courier_Location_Stddev_Fields = {
  __typename?: "courier_location_stddev_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "courier_location" */
export type Courier_Location_Stddev_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Courier_Location_Stddev_Pop_Fields = {
  __typename?: "courier_location_stddev_pop_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "courier_location" */
export type Courier_Location_Stddev_Pop_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Courier_Location_Stddev_Samp_Fields = {
  __typename?: "courier_location_stddev_samp_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "courier_location" */
export type Courier_Location_Stddev_Samp_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "courier_location" */
export type Courier_Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Courier_Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courier_Location_Stream_Cursor_Value_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  outbound?: Maybe<Scalars["Boolean"]>;
};

/** aggregate sum on columns */
export type Courier_Location_Sum_Fields = {
  __typename?: "courier_location_sum_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "courier_location" */
export type Courier_Location_Sum_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** update columns of table "courier_location" */
export enum Courier_Location_Update_Column {
  /** column name */
  CourierId = "courier_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Inbound = "inbound",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Outbound = "outbound",
}

export type Courier_Location_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Courier_Location_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Courier_Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Courier_Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Courier_Location_Var_Pop_Fields = {
  __typename?: "courier_location_var_pop_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "courier_location" */
export type Courier_Location_Var_Pop_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Courier_Location_Var_Samp_Fields = {
  __typename?: "courier_location_var_samp_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "courier_location" */
export type Courier_Location_Var_Samp_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Courier_Location_Variance_Fields = {
  __typename?: "courier_location_variance_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "courier_location" */
export type Courier_Location_Variance_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Courier_Max_Fields = {
  __typename?: "courier_max_fields";
  courier_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Courier_Min_Fields = {
  __typename?: "courier_min_fields";
  courier_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "courier" */
export type Courier_Mutation_Response = {
  __typename?: "courier_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Courier>;
};

/** input type for inserting object relation for remote table "courier" */
export type Courier_Obj_Rel_Insert_Input = {
  data: Courier_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Courier_On_Conflict>;
};

/** on_conflict condition type for table "courier" */
export type Courier_On_Conflict = {
  constraint: Courier_Constraint;
  update_columns?: Array<Courier_Update_Column>;
  where?: Maybe<Courier_Bool_Exp>;
};

/** Ordering options when selecting data from "courier". */
export type Courier_Order_By = {
  courier_location?: Maybe<Courier_Location_Order_By>;
  courier_name?: Maybe<Order_By>;
  courier_services_aggregate?: Maybe<Courier_Service_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: courier */
export type Courier_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "courier" */
export enum Courier_Select_Column {
  /** column name */
  CourierName = "courier_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** columns and relationships of "courier_service" */
export type Courier_Service = {
  __typename?: "courier_service";
  /** An object relationship */
  courier: Courier;
  courier_id: Scalars["Int"];
  courier_service_name: Scalars["String"];
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
};

/** columns and relationships of "courier_service" */
export type Courier_ServiceInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "courier_service" */
export type Courier_ServiceInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "courier_service" */
export type Courier_ServiceOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "courier_service" */
export type Courier_ServiceOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "courier_service" */
export type Courier_Service_Aggregate = {
  __typename?: "courier_service_aggregate";
  aggregate?: Maybe<Courier_Service_Aggregate_Fields>;
  nodes: Array<Courier_Service>;
};

export type Courier_Service_Aggregate_Bool_Exp = {
  count?: Maybe<Courier_Service_Aggregate_Bool_Exp_Count>;
};

export type Courier_Service_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Courier_Service_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Courier_Service_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "courier_service" */
export type Courier_Service_Aggregate_Fields = {
  __typename?: "courier_service_aggregate_fields";
  avg?: Maybe<Courier_Service_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Courier_Service_Max_Fields>;
  min?: Maybe<Courier_Service_Min_Fields>;
  stddev?: Maybe<Courier_Service_Stddev_Fields>;
  stddev_pop?: Maybe<Courier_Service_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Courier_Service_Stddev_Samp_Fields>;
  sum?: Maybe<Courier_Service_Sum_Fields>;
  var_pop?: Maybe<Courier_Service_Var_Pop_Fields>;
  var_samp?: Maybe<Courier_Service_Var_Samp_Fields>;
  variance?: Maybe<Courier_Service_Variance_Fields>;
};

/** aggregate fields of "courier_service" */
export type Courier_Service_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Courier_Service_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "courier_service" */
export type Courier_Service_Aggregate_Order_By = {
  avg?: Maybe<Courier_Service_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Courier_Service_Max_Order_By>;
  min?: Maybe<Courier_Service_Min_Order_By>;
  stddev?: Maybe<Courier_Service_Stddev_Order_By>;
  stddev_pop?: Maybe<Courier_Service_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Courier_Service_Stddev_Samp_Order_By>;
  sum?: Maybe<Courier_Service_Sum_Order_By>;
  var_pop?: Maybe<Courier_Service_Var_Pop_Order_By>;
  var_samp?: Maybe<Courier_Service_Var_Samp_Order_By>;
  variance?: Maybe<Courier_Service_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "courier_service" */
export type Courier_Service_Arr_Rel_Insert_Input = {
  data: Array<Courier_Service_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Courier_Service_On_Conflict>;
};

/** aggregate avg on columns */
export type Courier_Service_Avg_Fields = {
  __typename?: "courier_service_avg_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "courier_service" */
export type Courier_Service_Avg_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "courier_service". All fields are combined with a logical 'AND'. */
export type Courier_Service_Bool_Exp = {
  _and?: Maybe<Array<Courier_Service_Bool_Exp>>;
  _not?: Maybe<Courier_Service_Bool_Exp>;
  _or?: Maybe<Array<Courier_Service_Bool_Exp>>;
  courier?: Maybe<Courier_Bool_Exp>;
  courier_id?: Maybe<Int_Comparison_Exp>;
  courier_service_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "courier_service" */
export enum Courier_Service_Constraint {
  /** unique or primary key constraint on columns "id" */
  CourierServicePkey = "courier_service_pkey",
}

/** input type for incrementing numeric columns in table "courier_service" */
export type Courier_Service_Inc_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "courier_service" */
export type Courier_Service_Insert_Input = {
  courier?: Maybe<Courier_Obj_Rel_Insert_Input>;
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Courier_Service_Max_Fields = {
  __typename?: "courier_service_max_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "courier_service" */
export type Courier_Service_Max_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_service_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Courier_Service_Min_Fields = {
  __typename?: "courier_service_min_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "courier_service" */
export type Courier_Service_Min_Order_By = {
  courier_id?: Maybe<Order_By>;
  courier_service_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** response of any mutation on the table "courier_service" */
export type Courier_Service_Mutation_Response = {
  __typename?: "courier_service_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Courier_Service>;
};

/** input type for inserting object relation for remote table "courier_service" */
export type Courier_Service_Obj_Rel_Insert_Input = {
  data: Courier_Service_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Courier_Service_On_Conflict>;
};

/** on_conflict condition type for table "courier_service" */
export type Courier_Service_On_Conflict = {
  constraint: Courier_Service_Constraint;
  update_columns?: Array<Courier_Service_Update_Column>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

/** Ordering options when selecting data from "courier_service". */
export type Courier_Service_Order_By = {
  courier?: Maybe<Courier_Order_By>;
  courier_id?: Maybe<Order_By>;
  courier_service_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
};

/** primary key columns input for table: courier_service */
export type Courier_Service_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "courier_service" */
export enum Courier_Service_Select_Column {
  /** column name */
  CourierId = "courier_id",
  /** column name */
  CourierServiceName = "courier_service_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "courier_service" */
export type Courier_Service_Set_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Courier_Service_Stddev_Fields = {
  __typename?: "courier_service_stddev_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "courier_service" */
export type Courier_Service_Stddev_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Courier_Service_Stddev_Pop_Fields = {
  __typename?: "courier_service_stddev_pop_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "courier_service" */
export type Courier_Service_Stddev_Pop_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Courier_Service_Stddev_Samp_Fields = {
  __typename?: "courier_service_stddev_samp_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "courier_service" */
export type Courier_Service_Stddev_Samp_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "courier_service" */
export type Courier_Service_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Courier_Service_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courier_Service_Stream_Cursor_Value_Input = {
  courier_id?: Maybe<Scalars["Int"]>;
  courier_service_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Courier_Service_Sum_Fields = {
  __typename?: "courier_service_sum_fields";
  courier_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "courier_service" */
export type Courier_Service_Sum_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "courier_service" */
export enum Courier_Service_Update_Column {
  /** column name */
  CourierId = "courier_id",
  /** column name */
  CourierServiceName = "courier_service_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
}

export type Courier_Service_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Courier_Service_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Courier_Service_Set_Input>;
  /** filter the rows which have to be updated */
  where: Courier_Service_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Courier_Service_Var_Pop_Fields = {
  __typename?: "courier_service_var_pop_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "courier_service" */
export type Courier_Service_Var_Pop_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Courier_Service_Var_Samp_Fields = {
  __typename?: "courier_service_var_samp_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "courier_service" */
export type Courier_Service_Var_Samp_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Courier_Service_Variance_Fields = {
  __typename?: "courier_service_variance_fields";
  courier_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "courier_service" */
export type Courier_Service_Variance_Order_By = {
  courier_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** input type for updating data in table "courier" */
export type Courier_Set_Input = {
  courier_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Courier_Stddev_Fields = {
  __typename?: "courier_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Courier_Stddev_Pop_Fields = {
  __typename?: "courier_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Courier_Stddev_Samp_Fields = {
  __typename?: "courier_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "courier" */
export type Courier_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Courier_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Courier_Stream_Cursor_Value_Input = {
  courier_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Courier_Sum_Fields = {
  __typename?: "courier_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "courier" */
export enum Courier_Update_Column {
  /** column name */
  CourierName = "courier_name",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
}

export type Courier_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Courier_Set_Input>;
  /** filter the rows which have to be updated */
  where: Courier_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Courier_Var_Pop_Fields = {
  __typename?: "courier_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Courier_Var_Samp_Fields = {
  __typename?: "courier_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Courier_Variance_Fields = {
  __typename?: "courier_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** columns and relationships of "databasechangelog" */
export type Databasechangelog = {
  __typename?: "databasechangelog";
  author: Scalars["String"];
  comments?: Maybe<Scalars["String"]>;
  contexts?: Maybe<Scalars["String"]>;
  dateexecuted: Scalars["timestamp"];
  deployment_id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exectype: Scalars["String"];
  filename: Scalars["String"];
  id: Scalars["String"];
  labels?: Maybe<Scalars["String"]>;
  liquibase?: Maybe<Scalars["String"]>;
  md5sum?: Maybe<Scalars["String"]>;
  orderexecuted: Scalars["Int"];
  tag?: Maybe<Scalars["String"]>;
};

/** aggregated selection of "databasechangelog" */
export type Databasechangelog_Aggregate = {
  __typename?: "databasechangelog_aggregate";
  aggregate?: Maybe<Databasechangelog_Aggregate_Fields>;
  nodes: Array<Databasechangelog>;
};

/** aggregate fields of "databasechangelog" */
export type Databasechangelog_Aggregate_Fields = {
  __typename?: "databasechangelog_aggregate_fields";
  avg?: Maybe<Databasechangelog_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Databasechangelog_Max_Fields>;
  min?: Maybe<Databasechangelog_Min_Fields>;
  stddev?: Maybe<Databasechangelog_Stddev_Fields>;
  stddev_pop?: Maybe<Databasechangelog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Databasechangelog_Stddev_Samp_Fields>;
  sum?: Maybe<Databasechangelog_Sum_Fields>;
  var_pop?: Maybe<Databasechangelog_Var_Pop_Fields>;
  var_samp?: Maybe<Databasechangelog_Var_Samp_Fields>;
  variance?: Maybe<Databasechangelog_Variance_Fields>;
};

/** aggregate fields of "databasechangelog" */
export type Databasechangelog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Databasechangelog_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Databasechangelog_Avg_Fields = {
  __typename?: "databasechangelog_avg_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "databasechangelog". All fields are combined with a logical 'AND'. */
export type Databasechangelog_Bool_Exp = {
  _and?: Maybe<Array<Databasechangelog_Bool_Exp>>;
  _not?: Maybe<Databasechangelog_Bool_Exp>;
  _or?: Maybe<Array<Databasechangelog_Bool_Exp>>;
  author?: Maybe<String_Comparison_Exp>;
  comments?: Maybe<String_Comparison_Exp>;
  contexts?: Maybe<String_Comparison_Exp>;
  dateexecuted?: Maybe<Timestamp_Comparison_Exp>;
  deployment_id?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  exectype?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  labels?: Maybe<String_Comparison_Exp>;
  liquibase?: Maybe<String_Comparison_Exp>;
  md5sum?: Maybe<String_Comparison_Exp>;
  orderexecuted?: Maybe<Int_Comparison_Exp>;
  tag?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "databasechangelog" */
export type Databasechangelog_Inc_Input = {
  orderexecuted?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "databasechangelog" */
export type Databasechangelog_Insert_Input = {
  author?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  contexts?: Maybe<Scalars["String"]>;
  dateexecuted?: Maybe<Scalars["timestamp"]>;
  deployment_id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exectype?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  labels?: Maybe<Scalars["String"]>;
  liquibase?: Maybe<Scalars["String"]>;
  md5sum?: Maybe<Scalars["String"]>;
  orderexecuted?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Databasechangelog_Max_Fields = {
  __typename?: "databasechangelog_max_fields";
  author?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  contexts?: Maybe<Scalars["String"]>;
  dateexecuted?: Maybe<Scalars["timestamp"]>;
  deployment_id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exectype?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  labels?: Maybe<Scalars["String"]>;
  liquibase?: Maybe<Scalars["String"]>;
  md5sum?: Maybe<Scalars["String"]>;
  orderexecuted?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Databasechangelog_Min_Fields = {
  __typename?: "databasechangelog_min_fields";
  author?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  contexts?: Maybe<Scalars["String"]>;
  dateexecuted?: Maybe<Scalars["timestamp"]>;
  deployment_id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exectype?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  labels?: Maybe<Scalars["String"]>;
  liquibase?: Maybe<Scalars["String"]>;
  md5sum?: Maybe<Scalars["String"]>;
  orderexecuted?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "databasechangelog" */
export type Databasechangelog_Mutation_Response = {
  __typename?: "databasechangelog_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Databasechangelog>;
};

/** Ordering options when selecting data from "databasechangelog". */
export type Databasechangelog_Order_By = {
  author?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  contexts?: Maybe<Order_By>;
  dateexecuted?: Maybe<Order_By>;
  deployment_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  exectype?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  labels?: Maybe<Order_By>;
  liquibase?: Maybe<Order_By>;
  md5sum?: Maybe<Order_By>;
  orderexecuted?: Maybe<Order_By>;
  tag?: Maybe<Order_By>;
};

/** select columns of table "databasechangelog" */
export enum Databasechangelog_Select_Column {
  /** column name */
  Author = "author",
  /** column name */
  Comments = "comments",
  /** column name */
  Contexts = "contexts",
  /** column name */
  Dateexecuted = "dateexecuted",
  /** column name */
  DeploymentId = "deployment_id",
  /** column name */
  Description = "description",
  /** column name */
  Exectype = "exectype",
  /** column name */
  Filename = "filename",
  /** column name */
  Id = "id",
  /** column name */
  Labels = "labels",
  /** column name */
  Liquibase = "liquibase",
  /** column name */
  Md5sum = "md5sum",
  /** column name */
  Orderexecuted = "orderexecuted",
  /** column name */
  Tag = "tag",
}

/** input type for updating data in table "databasechangelog" */
export type Databasechangelog_Set_Input = {
  author?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  contexts?: Maybe<Scalars["String"]>;
  dateexecuted?: Maybe<Scalars["timestamp"]>;
  deployment_id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exectype?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  labels?: Maybe<Scalars["String"]>;
  liquibase?: Maybe<Scalars["String"]>;
  md5sum?: Maybe<Scalars["String"]>;
  orderexecuted?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Databasechangelog_Stddev_Fields = {
  __typename?: "databasechangelog_stddev_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Databasechangelog_Stddev_Pop_Fields = {
  __typename?: "databasechangelog_stddev_pop_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Databasechangelog_Stddev_Samp_Fields = {
  __typename?: "databasechangelog_stddev_samp_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "databasechangelog" */
export type Databasechangelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Databasechangelog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Databasechangelog_Stream_Cursor_Value_Input = {
  author?: Maybe<Scalars["String"]>;
  comments?: Maybe<Scalars["String"]>;
  contexts?: Maybe<Scalars["String"]>;
  dateexecuted?: Maybe<Scalars["timestamp"]>;
  deployment_id?: Maybe<Scalars["String"]>;
  description?: Maybe<Scalars["String"]>;
  exectype?: Maybe<Scalars["String"]>;
  filename?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["String"]>;
  labels?: Maybe<Scalars["String"]>;
  liquibase?: Maybe<Scalars["String"]>;
  md5sum?: Maybe<Scalars["String"]>;
  orderexecuted?: Maybe<Scalars["Int"]>;
  tag?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Databasechangelog_Sum_Fields = {
  __typename?: "databasechangelog_sum_fields";
  orderexecuted?: Maybe<Scalars["Int"]>;
};

export type Databasechangelog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Databasechangelog_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Databasechangelog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Databasechangelog_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Databasechangelog_Var_Pop_Fields = {
  __typename?: "databasechangelog_var_pop_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Databasechangelog_Var_Samp_Fields = {
  __typename?: "databasechangelog_var_samp_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Databasechangelog_Variance_Fields = {
  __typename?: "databasechangelog_variance_fields";
  orderexecuted?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "databasechangeloglock" */
export type Databasechangeloglock = {
  __typename?: "databasechangeloglock";
  id: Scalars["Int"];
  locked: Scalars["Boolean"];
  lockedby?: Maybe<Scalars["String"]>;
  lockgranted?: Maybe<Scalars["timestamp"]>;
};

/** aggregated selection of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate = {
  __typename?: "databasechangeloglock_aggregate";
  aggregate?: Maybe<Databasechangeloglock_Aggregate_Fields>;
  nodes: Array<Databasechangeloglock>;
};

/** aggregate fields of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate_Fields = {
  __typename?: "databasechangeloglock_aggregate_fields";
  avg?: Maybe<Databasechangeloglock_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Databasechangeloglock_Max_Fields>;
  min?: Maybe<Databasechangeloglock_Min_Fields>;
  stddev?: Maybe<Databasechangeloglock_Stddev_Fields>;
  stddev_pop?: Maybe<Databasechangeloglock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Databasechangeloglock_Stddev_Samp_Fields>;
  sum?: Maybe<Databasechangeloglock_Sum_Fields>;
  var_pop?: Maybe<Databasechangeloglock_Var_Pop_Fields>;
  var_samp?: Maybe<Databasechangeloglock_Var_Samp_Fields>;
  variance?: Maybe<Databasechangeloglock_Variance_Fields>;
};

/** aggregate fields of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Databasechangeloglock_Avg_Fields = {
  __typename?: "databasechangeloglock_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "databasechangeloglock". All fields are combined with a logical 'AND'. */
export type Databasechangeloglock_Bool_Exp = {
  _and?: Maybe<Array<Databasechangeloglock_Bool_Exp>>;
  _not?: Maybe<Databasechangeloglock_Bool_Exp>;
  _or?: Maybe<Array<Databasechangeloglock_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  locked?: Maybe<Boolean_Comparison_Exp>;
  lockedby?: Maybe<String_Comparison_Exp>;
  lockgranted?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "databasechangeloglock" */
export enum Databasechangeloglock_Constraint {
  /** unique or primary key constraint on columns "id" */
  DatabasechangeloglockPkey = "databasechangeloglock_pkey",
}

/** input type for incrementing numeric columns in table "databasechangeloglock" */
export type Databasechangeloglock_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "databasechangeloglock" */
export type Databasechangeloglock_Insert_Input = {
  id?: Maybe<Scalars["Int"]>;
  locked?: Maybe<Scalars["Boolean"]>;
  lockedby?: Maybe<Scalars["String"]>;
  lockgranted?: Maybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type Databasechangeloglock_Max_Fields = {
  __typename?: "databasechangeloglock_max_fields";
  id?: Maybe<Scalars["Int"]>;
  lockedby?: Maybe<Scalars["String"]>;
  lockgranted?: Maybe<Scalars["timestamp"]>;
};

/** aggregate min on columns */
export type Databasechangeloglock_Min_Fields = {
  __typename?: "databasechangeloglock_min_fields";
  id?: Maybe<Scalars["Int"]>;
  lockedby?: Maybe<Scalars["String"]>;
  lockgranted?: Maybe<Scalars["timestamp"]>;
};

/** response of any mutation on the table "databasechangeloglock" */
export type Databasechangeloglock_Mutation_Response = {
  __typename?: "databasechangeloglock_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Databasechangeloglock>;
};

/** on_conflict condition type for table "databasechangeloglock" */
export type Databasechangeloglock_On_Conflict = {
  constraint: Databasechangeloglock_Constraint;
  update_columns?: Array<Databasechangeloglock_Update_Column>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

/** Ordering options when selecting data from "databasechangeloglock". */
export type Databasechangeloglock_Order_By = {
  id?: Maybe<Order_By>;
  locked?: Maybe<Order_By>;
  lockedby?: Maybe<Order_By>;
  lockgranted?: Maybe<Order_By>;
};

/** primary key columns input for table: databasechangeloglock */
export type Databasechangeloglock_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "databasechangeloglock" */
export enum Databasechangeloglock_Select_Column {
  /** column name */
  Id = "id",
  /** column name */
  Locked = "locked",
  /** column name */
  Lockedby = "lockedby",
  /** column name */
  Lockgranted = "lockgranted",
}

/** input type for updating data in table "databasechangeloglock" */
export type Databasechangeloglock_Set_Input = {
  id?: Maybe<Scalars["Int"]>;
  locked?: Maybe<Scalars["Boolean"]>;
  lockedby?: Maybe<Scalars["String"]>;
  lockgranted?: Maybe<Scalars["timestamp"]>;
};

/** aggregate stddev on columns */
export type Databasechangeloglock_Stddev_Fields = {
  __typename?: "databasechangeloglock_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Databasechangeloglock_Stddev_Pop_Fields = {
  __typename?: "databasechangeloglock_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Databasechangeloglock_Stddev_Samp_Fields = {
  __typename?: "databasechangeloglock_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "databasechangeloglock" */
export type Databasechangeloglock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Databasechangeloglock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Databasechangeloglock_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars["Int"]>;
  locked?: Maybe<Scalars["Boolean"]>;
  lockedby?: Maybe<Scalars["String"]>;
  lockgranted?: Maybe<Scalars["timestamp"]>;
};

/** aggregate sum on columns */
export type Databasechangeloglock_Sum_Fields = {
  __typename?: "databasechangeloglock_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "databasechangeloglock" */
export enum Databasechangeloglock_Update_Column {
  /** column name */
  Id = "id",
  /** column name */
  Locked = "locked",
  /** column name */
  Lockedby = "lockedby",
  /** column name */
  Lockgranted = "lockgranted",
}

export type Databasechangeloglock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Databasechangeloglock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Databasechangeloglock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Databasechangeloglock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Databasechangeloglock_Var_Pop_Fields = {
  __typename?: "databasechangeloglock_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Databasechangeloglock_Var_Samp_Fields = {
  __typename?: "databasechangeloglock_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Databasechangeloglock_Variance_Fields = {
  __typename?: "databasechangeloglock_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: Maybe<Scalars["date"]>;
  _gt?: Maybe<Scalars["date"]>;
  _gte?: Maybe<Scalars["date"]>;
  _in?: Maybe<Array<Scalars["date"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["date"]>;
  _lte?: Maybe<Scalars["date"]>;
  _neq?: Maybe<Scalars["date"]>;
  _nin?: Maybe<Array<Scalars["date"]>>;
};

/** columns and relationships of "file_copy_deletion" */
export type File_Copy_Deletion = {
  __typename?: "file_copy_deletion";
  created_at: Scalars["timestamptz"];
  deleted_quantity: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Location;
  location_id: Scalars["Int"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  /** An object relationship */
  product_stock: Product_Stock;
  source_product_stock_id: Scalars["Int"];
  /** An object relationship */
  stock_condition: Stock_Condition;
  stock_condition_id: Scalars["Int"];
};

/** aggregated selection of "file_copy_deletion" */
export type File_Copy_Deletion_Aggregate = {
  __typename?: "file_copy_deletion_aggregate";
  aggregate?: Maybe<File_Copy_Deletion_Aggregate_Fields>;
  nodes: Array<File_Copy_Deletion>;
};

export type File_Copy_Deletion_Aggregate_Bool_Exp = {
  count?: Maybe<File_Copy_Deletion_Aggregate_Bool_Exp_Count>;
};

export type File_Copy_Deletion_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<File_Copy_Deletion_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "file_copy_deletion" */
export type File_Copy_Deletion_Aggregate_Fields = {
  __typename?: "file_copy_deletion_aggregate_fields";
  avg?: Maybe<File_Copy_Deletion_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<File_Copy_Deletion_Max_Fields>;
  min?: Maybe<File_Copy_Deletion_Min_Fields>;
  stddev?: Maybe<File_Copy_Deletion_Stddev_Fields>;
  stddev_pop?: Maybe<File_Copy_Deletion_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<File_Copy_Deletion_Stddev_Samp_Fields>;
  sum?: Maybe<File_Copy_Deletion_Sum_Fields>;
  var_pop?: Maybe<File_Copy_Deletion_Var_Pop_Fields>;
  var_samp?: Maybe<File_Copy_Deletion_Var_Samp_Fields>;
  variance?: Maybe<File_Copy_Deletion_Variance_Fields>;
};

/** aggregate fields of "file_copy_deletion" */
export type File_Copy_Deletion_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "file_copy_deletion" */
export type File_Copy_Deletion_Aggregate_Order_By = {
  avg?: Maybe<File_Copy_Deletion_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<File_Copy_Deletion_Max_Order_By>;
  min?: Maybe<File_Copy_Deletion_Min_Order_By>;
  stddev?: Maybe<File_Copy_Deletion_Stddev_Order_By>;
  stddev_pop?: Maybe<File_Copy_Deletion_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<File_Copy_Deletion_Stddev_Samp_Order_By>;
  sum?: Maybe<File_Copy_Deletion_Sum_Order_By>;
  var_pop?: Maybe<File_Copy_Deletion_Var_Pop_Order_By>;
  var_samp?: Maybe<File_Copy_Deletion_Var_Samp_Order_By>;
  variance?: Maybe<File_Copy_Deletion_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "file_copy_deletion" */
export type File_Copy_Deletion_Arr_Rel_Insert_Input = {
  data: Array<File_Copy_Deletion_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<File_Copy_Deletion_On_Conflict>;
};

/** aggregate avg on columns */
export type File_Copy_Deletion_Avg_Fields = {
  __typename?: "file_copy_deletion_avg_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Avg_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "file_copy_deletion". All fields are combined with a logical 'AND'. */
export type File_Copy_Deletion_Bool_Exp = {
  _and?: Maybe<Array<File_Copy_Deletion_Bool_Exp>>;
  _not?: Maybe<File_Copy_Deletion_Bool_Exp>;
  _or?: Maybe<Array<File_Copy_Deletion_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_quantity?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
  stock_condition_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "file_copy_deletion" */
export enum File_Copy_Deletion_Constraint {
  /** unique or primary key constraint on columns "id" */
  FileCopyDeletionPkey = "file_copy_deletion_pkey",
}

/** input type for incrementing numeric columns in table "file_copy_deletion" */
export type File_Copy_Deletion_Inc_Input = {
  deleted_quantity?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "file_copy_deletion" */
export type File_Copy_Deletion_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type File_Copy_Deletion_Max_Fields = {
  __typename?: "file_copy_deletion_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type File_Copy_Deletion_Min_Fields = {
  __typename?: "file_copy_deletion_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "file_copy_deletion" */
export type File_Copy_Deletion_Mutation_Response = {
  __typename?: "file_copy_deletion_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<File_Copy_Deletion>;
};

/** on_conflict condition type for table "file_copy_deletion" */
export type File_Copy_Deletion_On_Conflict = {
  constraint: File_Copy_Deletion_Constraint;
  update_columns?: Array<File_Copy_Deletion_Update_Column>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** Ordering options when selecting data from "file_copy_deletion". */
export type File_Copy_Deletion_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** primary key columns input for table: file_copy_deletion */
export type File_Copy_Deletion_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "file_copy_deletion" */
export enum File_Copy_Deletion_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedQuantity = "deleted_quantity",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  StockConditionId = "stock_condition_id",
}

/** input type for updating data in table "file_copy_deletion" */
export type File_Copy_Deletion_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type File_Copy_Deletion_Stddev_Fields = {
  __typename?: "file_copy_deletion_stddev_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Stddev_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type File_Copy_Deletion_Stddev_Pop_Fields = {
  __typename?: "file_copy_deletion_stddev_pop_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Stddev_Pop_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type File_Copy_Deletion_Stddev_Samp_Fields = {
  __typename?: "file_copy_deletion_stddev_samp_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Stddev_Samp_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "file_copy_deletion" */
export type File_Copy_Deletion_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: File_Copy_Deletion_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type File_Copy_Deletion_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type File_Copy_Deletion_Sum_Fields = {
  __typename?: "file_copy_deletion_sum_fields";
  deleted_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Sum_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** update columns of table "file_copy_deletion" */
export enum File_Copy_Deletion_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedQuantity = "deleted_quantity",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  StockConditionId = "stock_condition_id",
}

export type File_Copy_Deletion_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<File_Copy_Deletion_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<File_Copy_Deletion_Set_Input>;
  /** filter the rows which have to be updated */
  where: File_Copy_Deletion_Bool_Exp;
};

/** aggregate var_pop on columns */
export type File_Copy_Deletion_Var_Pop_Fields = {
  __typename?: "file_copy_deletion_var_pop_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Var_Pop_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type File_Copy_Deletion_Var_Samp_Fields = {
  __typename?: "file_copy_deletion_var_samp_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Var_Samp_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type File_Copy_Deletion_Variance_Fields = {
  __typename?: "file_copy_deletion_variance_fields";
  deleted_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "file_copy_deletion" */
export type File_Copy_Deletion_Variance_Order_By = {
  deleted_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
};

/** columns and relationships of "folder" */
export type Folder = {
  __typename?: "folder";
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
};

/** columns and relationships of "folder" */
export type FolderAttachmentsArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

/** columns and relationships of "folder" */
export type FolderAttachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

/** columns and relationships of "folder" */
export type FolderOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "folder" */
export type FolderOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "folder" */
export type Folder_Aggregate = {
  __typename?: "folder_aggregate";
  aggregate?: Maybe<Folder_Aggregate_Fields>;
  nodes: Array<Folder>;
};

/** aggregate fields of "folder" */
export type Folder_Aggregate_Fields = {
  __typename?: "folder_aggregate_fields";
  avg?: Maybe<Folder_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Folder_Max_Fields>;
  min?: Maybe<Folder_Min_Fields>;
  stddev?: Maybe<Folder_Stddev_Fields>;
  stddev_pop?: Maybe<Folder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Folder_Stddev_Samp_Fields>;
  sum?: Maybe<Folder_Sum_Fields>;
  var_pop?: Maybe<Folder_Var_Pop_Fields>;
  var_samp?: Maybe<Folder_Var_Samp_Fields>;
  variance?: Maybe<Folder_Variance_Fields>;
};

/** aggregate fields of "folder" */
export type Folder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Folder_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Folder_Avg_Fields = {
  __typename?: "folder_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "folder". All fields are combined with a logical 'AND'. */
export type Folder_Bool_Exp = {
  _and?: Maybe<Array<Folder_Bool_Exp>>;
  _not?: Maybe<Folder_Bool_Exp>;
  _or?: Maybe<Array<Folder_Bool_Exp>>;
  attachments?: Maybe<Attachment_Bool_Exp>;
  attachments_aggregate?: Maybe<Attachment_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "folder" */
export enum Folder_Constraint {
  /** unique or primary key constraint on columns "id" */
  FolderPkey = "folder_pkey",
}

/** input type for inserting data into table "folder" */
export type Folder_Insert_Input = {
  attachments?: Maybe<Attachment_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Folder_Max_Fields = {
  __typename?: "folder_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Folder_Min_Fields = {
  __typename?: "folder_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "folder" */
export type Folder_Mutation_Response = {
  __typename?: "folder_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Folder>;
};

/** input type for inserting object relation for remote table "folder" */
export type Folder_Obj_Rel_Insert_Input = {
  data: Folder_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Folder_On_Conflict>;
};

/** on_conflict condition type for table "folder" */
export type Folder_On_Conflict = {
  constraint: Folder_Constraint;
  update_columns?: Array<Folder_Update_Column>;
  where?: Maybe<Folder_Bool_Exp>;
};

/** Ordering options when selecting data from "folder". */
export type Folder_Order_By = {
  attachments_aggregate?: Maybe<Attachment_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
};

/** primary key columns input for table: folder */
export type Folder_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "folder" */
export enum Folder_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "folder" */
export type Folder_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Folder_Stddev_Fields = {
  __typename?: "folder_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Folder_Stddev_Pop_Fields = {
  __typename?: "folder_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Folder_Stddev_Samp_Fields = {
  __typename?: "folder_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "folder" */
export type Folder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Folder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Folder_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Folder_Sum_Fields = {
  __typename?: "folder_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "folder" */
export enum Folder_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
}

export type Folder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Folder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Folder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Folder_Var_Pop_Fields = {
  __typename?: "folder_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Folder_Var_Samp_Fields = {
  __typename?: "folder_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Folder_Variance_Fields = {
  __typename?: "folder_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "inbound_manifest" */
export type Inbound_Manifest = {
  __typename?: "inbound_manifest";
  /** An object relationship */
  courier_service?: Maybe<Courier_Service>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at: Scalars["timestamptz"];
  expected_arrival: Scalars["timestamptz"];
  external_order_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  /** An object relationship */
  inbound_manifest_status: Inbound_Manifest_Status;
  inbound_manifest_status_id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  logistics_type: Logistics_Type;
  logistics_type_id: Scalars["Int"];
  notes: Scalars["String"];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars["Int"];
  reference: Scalars["String"];
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars["Int"];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars["Int"];
};

/** columns and relationships of "inbound_manifest" */
export type Inbound_ManifestInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "inbound_manifest" */
export type Inbound_ManifestInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest" */
export type Inbound_Manifest_Aggregate = {
  __typename?: "inbound_manifest_aggregate";
  aggregate?: Maybe<Inbound_Manifest_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest>;
};

export type Inbound_Manifest_Aggregate_Bool_Exp = {
  count?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp_Count>;
};

export type Inbound_Manifest_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inbound_manifest" */
export type Inbound_Manifest_Aggregate_Fields = {
  __typename?: "inbound_manifest_aggregate_fields";
  avg?: Maybe<Inbound_Manifest_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Inbound_Manifest_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Variance_Fields>;
};

/** aggregate fields of "inbound_manifest" */
export type Inbound_Manifest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inbound_manifest" */
export type Inbound_Manifest_Aggregate_Order_By = {
  avg?: Maybe<Inbound_Manifest_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inbound_Manifest_Max_Order_By>;
  min?: Maybe<Inbound_Manifest_Min_Order_By>;
  stddev?: Maybe<Inbound_Manifest_Stddev_Order_By>;
  stddev_pop?: Maybe<Inbound_Manifest_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inbound_Manifest_Stddev_Samp_Order_By>;
  sum?: Maybe<Inbound_Manifest_Sum_Order_By>;
  var_pop?: Maybe<Inbound_Manifest_Var_Pop_Order_By>;
  var_samp?: Maybe<Inbound_Manifest_Var_Samp_Order_By>;
  variance?: Maybe<Inbound_Manifest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inbound_manifest" */
export type Inbound_Manifest_Arr_Rel_Insert_Input = {
  data: Array<Inbound_Manifest_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Avg_Fields = {
  __typename?: "inbound_manifest_avg_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Avg_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inbound_manifest". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Bool_Exp>>;
  courier_service?: Maybe<Courier_Service_Bool_Exp>;
  courier_service_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_arrival?: Maybe<Timestamptz_Comparison_Exp>;
  external_order_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
  inbound_manifest_status_id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  logistics_type?: Maybe<Logistics_Type_Bool_Exp>;
  logistics_type_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest" */
export enum Inbound_Manifest_Constraint {
  /** unique or primary key constraint on columns "id" */
  InboundManifestPkey = "inbound_manifest_pkey",
  /** unique or primary key constraint on columns "reference" */
  InboundManifestReferenceKey = "inbound_manifest_reference_key",
}

/** columns and relationships of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line = {
  __typename?: "inbound_manifest_fulfilment_line";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An object relationship */
  inbound_manifest_line: Inbound_Manifest_Line;
  inbound_manifest_line_id: Scalars["Int"];
  is_file_copy: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  notes: Scalars["String"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  quantity: Scalars["Int"];
};

/** columns and relationships of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate = {
  __typename?: "inbound_manifest_fulfilment_line_aggregate";
  aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Fulfilment_Line>;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count>;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And = {
  arguments: Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_aggregate_fields";
  avg?: Maybe<Inbound_Manifest_Fulfilment_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Inbound_Manifest_Fulfilment_Line_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Fulfilment_Line_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Fulfilment_Line_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Fulfilment_Line_Variance_Fields>;
};

/** aggregate fields of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate_Order_By = {
  avg?: Maybe<Inbound_Manifest_Fulfilment_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inbound_Manifest_Fulfilment_Line_Max_Order_By>;
  min?: Maybe<Inbound_Manifest_Fulfilment_Line_Min_Order_By>;
  stddev?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inbound_Manifest_Fulfilment_Line_Sum_Order_By>;
  var_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inbound_Manifest_Fulfilment_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input = {
  data: Array<Inbound_Manifest_Fulfilment_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Fulfilment_Line_Avg_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_fulfilment_line". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Fulfilment_Line_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_line_id?: Maybe<Int_Comparison_Exp>;
  is_file_copy?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  InboundManifestFulfilmentLinePkey = "inbound_manifest_fulfilment_line_pkey",
}

/** input type for incrementing numeric columns in table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Inc_Input = {
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Obj_Rel_Insert_Input>;
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  is_file_copy?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Fulfilment_Line_Max_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Fulfilment_Line_Min_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Mutation_Response = {
  __typename?: "inbound_manifest_fulfilment_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Fulfilment_Line>;
};

/** input type for inserting object relation for remote table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Fulfilment_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_On_Conflict = {
  constraint: Inbound_Manifest_Fulfilment_Line_Constraint;
  update_columns?: Array<Inbound_Manifest_Fulfilment_Line_Update_Column>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_fulfilment_line". */
export type Inbound_Manifest_Fulfilment_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  is_file_copy?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_fulfilment_line */
export type Inbound_Manifest_Fulfilment_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestLineId = "inbound_manifest_line_id",
  /** column name */
  IsFileCopy = "is_file_copy",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Notes = "notes",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
}

/** select "inbound_manifest_fulfilment_line_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFileCopy = "is_file_copy",
}

/** select "inbound_manifest_fulfilment_line_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFileCopy = "is_file_copy",
}

/** input type for updating data in table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  is_file_copy?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  is_file_copy?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  notes?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Fulfilment_Line_Sum_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  InboundManifestLineId = "inbound_manifest_line_id",
  /** column name */
  IsFileCopy = "is_file_copy",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Notes = "notes",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
}

export type Inbound_Manifest_Fulfilment_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Fulfilment_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Fulfilment_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Fulfilment_Line_Var_Pop_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Fulfilment_Line_Var_Samp_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Fulfilment_Line_Variance_Fields = {
  __typename?: "inbound_manifest_fulfilment_line_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "inbound_manifest" */
export type Inbound_Manifest_Inc_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inbound_manifest" */
export type Inbound_Manifest_Insert_Input = {
  courier_service?: Maybe<Courier_Service_Obj_Rel_Insert_Input>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_arrival?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Obj_Rel_Insert_Input>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type?: Maybe<Logistics_Type_Obj_Rel_Insert_Input>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "inbound_manifest_line" */
export type Inbound_Manifest_Line = {
  __typename?: "inbound_manifest_line";
  cancelled_quantity: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  /** An object relationship */
  inbound_manifest: Inbound_Manifest;
  /** An array relationship */
  inbound_manifest_fulfilment_lines: Array<Inbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  inbound_manifest_fulfilment_lines_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  inbound_manifest_id: Scalars["Int"];
  /** An object relationship */
  inbound_manifest_line_status: Inbound_Manifest_Line_Status;
  inbound_manifest_line_status_id: Scalars["Int"];
  is_fragile: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  missing_quantity: Scalars["Int"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  quantity: Scalars["Int"];
  received_quantity: Scalars["Int"];
  rejected_quantity: Scalars["Int"];
};

/** columns and relationships of "inbound_manifest_line" */
export type Inbound_Manifest_LineInbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "inbound_manifest_line" */
export type Inbound_Manifest_LineInbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate = {
  __typename?: "inbound_manifest_line_aggregate";
  aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Line>;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp_Count>;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And = {
  arguments: Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate_Fields = {
  __typename?: "inbound_manifest_line_aggregate_fields";
  avg?: Maybe<Inbound_Manifest_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Inbound_Manifest_Line_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Line_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Line_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Line_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Line_Variance_Fields>;
};

/** aggregate fields of "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate_Order_By = {
  avg?: Maybe<Inbound_Manifest_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inbound_Manifest_Line_Max_Order_By>;
  min?: Maybe<Inbound_Manifest_Line_Min_Order_By>;
  stddev?: Maybe<Inbound_Manifest_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inbound_Manifest_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inbound_Manifest_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inbound_Manifest_Line_Sum_Order_By>;
  var_pop?: Maybe<Inbound_Manifest_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inbound_Manifest_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inbound_Manifest_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Arr_Rel_Insert_Input = {
  data: Array<Inbound_Manifest_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Line_Avg_Fields = {
  __typename?: "inbound_manifest_line_avg_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_line". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Line_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Line_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Line_Bool_Exp>>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_order_line_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifest_fulfilment_lines?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  inbound_manifest_fulfilment_lines_aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  inbound_manifest_id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_line_status?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
  inbound_manifest_line_status_id?: Maybe<Int_Comparison_Exp>;
  is_fragile?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  missing_quantity?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  received_quantity?: Maybe<Int_Comparison_Exp>;
  rejected_quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  InboundManifestLinePkey = "inbound_manifest_line_pkey",
}

/** input type for incrementing numeric columns in table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Insert_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest?: Maybe<Inbound_Manifest_Obj_Rel_Insert_Input>;
  inbound_manifest_fulfilment_lines?: Maybe<Inbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status?: Maybe<Inbound_Manifest_Line_Status_Obj_Rel_Insert_Input>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  is_fragile?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Line_Max_Fields = {
  __typename?: "inbound_manifest_line_max_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Line_Min_Fields = {
  __typename?: "inbound_manifest_line_min_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Mutation_Response = {
  __typename?: "inbound_manifest_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Line>;
};

/** input type for inserting object relation for remote table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_line" */
export type Inbound_Manifest_Line_On_Conflict = {
  constraint: Inbound_Manifest_Line_Constraint;
  update_columns?: Array<Inbound_Manifest_Line_Update_Column>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_line". */
export type Inbound_Manifest_Line_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest?: Maybe<Inbound_Manifest_Order_By>;
  inbound_manifest_fulfilment_lines_aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status?: Maybe<Inbound_Manifest_Line_Status_Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  is_fragile?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_line */
export type Inbound_Manifest_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Select_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalOrderLineId = "external_order_line_id",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestId = "inbound_manifest_id",
  /** column name */
  InboundManifestLineStatusId = "inbound_manifest_line_status_id",
  /** column name */
  IsFragile = "is_fragile",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MissingQuantity = "missing_quantity",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReceivedQuantity = "received_quantity",
  /** column name */
  RejectedQuantity = "rejected_quantity",
}

/** select "inbound_manifest_line_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFragile = "is_fragile",
}

/** select "inbound_manifest_line_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFragile = "is_fragile",
}

/** input type for updating data in table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Set_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  is_fragile?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status = {
  __typename?: "inbound_manifest_line_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  inbound_manifest_line_status_name: Scalars["String"];
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_StatusInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_StatusInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Aggregate = {
  __typename?: "inbound_manifest_line_status_aggregate";
  aggregate?: Maybe<Inbound_Manifest_Line_Status_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Line_Status>;
};

/** aggregate fields of "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Aggregate_Fields = {
  __typename?: "inbound_manifest_line_status_aggregate_fields";
  avg?: Maybe<Inbound_Manifest_Line_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Inbound_Manifest_Line_Status_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Line_Status_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Line_Status_Variance_Fields>;
};

/** aggregate fields of "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Line_Status_Avg_Fields = {
  __typename?: "inbound_manifest_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_line_status". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Line_Status_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Line_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_line_status_name?: Maybe<String_Comparison_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_line_status" */
export enum Inbound_Manifest_Line_Status_Constraint {
  /** unique or primary key constraint on columns "inbound_manifest_line_status_name" */
  InboundManifestLineStatusInboundManifestLineStatusNKey = "inbound_manifest_line_status_inbound_manifest_line_status_n_key",
  /** unique or primary key constraint on columns "id" */
  InboundManifestLineStatusPkey = "inbound_manifest_line_status_pkey",
}

/** input type for incrementing numeric columns in table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Line_Status_Max_Fields = {
  __typename?: "inbound_manifest_line_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Line_Status_Min_Fields = {
  __typename?: "inbound_manifest_line_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Mutation_Response = {
  __typename?: "inbound_manifest_line_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Line_Status>;
};

/** input type for inserting object relation for remote table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Line_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Line_Status_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_On_Conflict = {
  constraint: Inbound_Manifest_Line_Status_Constraint;
  update_columns?: Array<Inbound_Manifest_Line_Status_Update_Column>;
  where?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_line_status". */
export type Inbound_Manifest_Line_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line_status_name?: Maybe<Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_line_status */
export type Inbound_Manifest_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inbound_manifest_line_status" */
export enum Inbound_Manifest_Line_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestLineStatusName = "inbound_manifest_line_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Line_Status_Stddev_Fields = {
  __typename?: "inbound_manifest_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Line_Status_Stddev_Pop_Fields = {
  __typename?: "inbound_manifest_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Line_Status_Stddev_Samp_Fields = {
  __typename?: "inbound_manifest_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "inbound_manifest_line_status" */
export type Inbound_Manifest_Line_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Line_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Line_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Line_Status_Sum_Fields = {
  __typename?: "inbound_manifest_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "inbound_manifest_line_status" */
export enum Inbound_Manifest_Line_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestLineStatusName = "inbound_manifest_line_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

export type Inbound_Manifest_Line_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Line_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Line_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Line_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Line_Status_Var_Pop_Fields = {
  __typename?: "inbound_manifest_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Line_Status_Var_Samp_Fields = {
  __typename?: "inbound_manifest_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Line_Status_Variance_Fields = {
  __typename?: "inbound_manifest_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Line_Stddev_Fields = {
  __typename?: "inbound_manifest_line_stddev_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Line_Stddev_Pop_Fields = {
  __typename?: "inbound_manifest_line_stddev_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Line_Stddev_Samp_Fields = {
  __typename?: "inbound_manifest_line_stddev_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Line_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  is_fragile?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Line_Sum_Fields = {
  __typename?: "inbound_manifest_line_sum_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  missing_quantity?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  received_quantity?: Maybe<Scalars["Int"]>;
  rejected_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** update columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Update_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalOrderLineId = "external_order_line_id",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestId = "inbound_manifest_id",
  /** column name */
  InboundManifestLineStatusId = "inbound_manifest_line_status_id",
  /** column name */
  IsFragile = "is_fragile",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  MissingQuantity = "missing_quantity",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReceivedQuantity = "received_quantity",
  /** column name */
  RejectedQuantity = "rejected_quantity",
}

export type Inbound_Manifest_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Line_Var_Pop_Fields = {
  __typename?: "inbound_manifest_line_var_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Line_Var_Samp_Fields = {
  __typename?: "inbound_manifest_line_var_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Line_Variance_Fields = {
  __typename?: "inbound_manifest_line_variance_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_id?: Maybe<Scalars["Float"]>;
  inbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  missing_quantity?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  received_quantity?: Maybe<Scalars["Float"]>;
  rejected_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_line_status_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Max_Fields = {
  __typename?: "inbound_manifest_max_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_arrival?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Max_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Min_Fields = {
  __typename?: "inbound_manifest_min_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_arrival?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Min_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inbound_manifest" */
export type Inbound_Manifest_Mutation_Response = {
  __typename?: "inbound_manifest_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest>;
};

/** input type for inserting object relation for remote table "inbound_manifest" */
export type Inbound_Manifest_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest" */
export type Inbound_Manifest_On_Conflict = {
  constraint: Inbound_Manifest_Constraint;
  update_columns?: Array<Inbound_Manifest_Update_Column>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest". */
export type Inbound_Manifest_Order_By = {
  courier_service?: Maybe<Courier_Service_Order_By>;
  courier_service_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type?: Maybe<Logistics_Type_Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest */
export type Inbound_Manifest_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inbound_manifest" */
export enum Inbound_Manifest_Select_Column {
  /** column name */
  CourierServiceId = "courier_service_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpectedArrival = "expected_arrival",
  /** column name */
  ExternalOrderId = "external_order_id",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestStatusId = "inbound_manifest_status_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LogisticsTypeId = "logistics_type_id",
  /** column name */
  Notes = "notes",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ServiceAccountId = "service_account_id",
  /** column name */
  WarehouseId = "warehouse_id",
}

/** input type for updating data in table "inbound_manifest" */
export type Inbound_Manifest_Set_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_arrival?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_Status = {
  __typename?: "inbound_manifest_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  inbound_manifest_status_name: Scalars["String"];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_StatusInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_StatusInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_status" */
export type Inbound_Manifest_Status_Aggregate = {
  __typename?: "inbound_manifest_status_aggregate";
  aggregate?: Maybe<Inbound_Manifest_Status_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Status>;
};

/** aggregate fields of "inbound_manifest_status" */
export type Inbound_Manifest_Status_Aggregate_Fields = {
  __typename?: "inbound_manifest_status_aggregate_fields";
  avg?: Maybe<Inbound_Manifest_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Inbound_Manifest_Status_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Status_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Status_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Status_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Status_Variance_Fields>;
};

/** aggregate fields of "inbound_manifest_status" */
export type Inbound_Manifest_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Status_Avg_Fields = {
  __typename?: "inbound_manifest_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_status". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Status_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Status_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_status_name?: Maybe<String_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_status" */
export enum Inbound_Manifest_Status_Constraint {
  /** unique or primary key constraint on columns "inbound_manifest_status_name" */
  InboundManifestStatusInboundManifestStatusNameKey = "inbound_manifest_status_inbound_manifest_status_name_key",
  /** unique or primary key constraint on columns "id" */
  InboundManifestStatusPkey = "inbound_manifest_status_pkey",
}

/** input type for incrementing numeric columns in table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_name?: Maybe<Scalars["String"]>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Status_Max_Fields = {
  __typename?: "inbound_manifest_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Status_Min_Fields = {
  __typename?: "inbound_manifest_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Mutation_Response = {
  __typename?: "inbound_manifest_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Status>;
};

/** input type for inserting object relation for remote table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Status_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_status" */
export type Inbound_Manifest_Status_On_Conflict = {
  constraint: Inbound_Manifest_Status_Constraint;
  update_columns?: Array<Inbound_Manifest_Status_Update_Column>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_status". */
export type Inbound_Manifest_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_name?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_status */
export type Inbound_Manifest_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "inbound_manifest_status" */
export enum Inbound_Manifest_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestStatusName = "inbound_manifest_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Status_Stddev_Fields = {
  __typename?: "inbound_manifest_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Status_Stddev_Pop_Fields = {
  __typename?: "inbound_manifest_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Status_Stddev_Samp_Fields = {
  __typename?: "inbound_manifest_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Status_Sum_Fields = {
  __typename?: "inbound_manifest_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "inbound_manifest_status" */
export enum Inbound_Manifest_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InboundManifestStatusName = "inbound_manifest_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

export type Inbound_Manifest_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Status_Var_Pop_Fields = {
  __typename?: "inbound_manifest_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Status_Var_Samp_Fields = {
  __typename?: "inbound_manifest_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Status_Variance_Fields = {
  __typename?: "inbound_manifest_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Stddev_Fields = {
  __typename?: "inbound_manifest_stddev_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Stddev_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Stddev_Pop_Fields = {
  __typename?: "inbound_manifest_stddev_pop_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Stddev_Pop_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Stddev_Samp_Fields = {
  __typename?: "inbound_manifest_stddev_samp_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Stddev_Samp_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "inbound_manifest" */
export type Inbound_Manifest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Stream_Cursor_Value_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_arrival?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Sum_Fields = {
  __typename?: "inbound_manifest_sum_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Sum_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "inbound_manifest" */
export enum Inbound_Manifest_Update_Column {
  /** column name */
  CourierServiceId = "courier_service_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpectedArrival = "expected_arrival",
  /** column name */
  ExternalOrderId = "external_order_id",
  /** column name */
  InboundManifestStatusId = "inbound_manifest_status_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LogisticsTypeId = "logistics_type_id",
  /** column name */
  Notes = "notes",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Reference = "reference",
  /** column name */
  ServiceAccountId = "service_account_id",
  /** column name */
  WarehouseId = "warehouse_id",
}

export type Inbound_Manifest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Var_Pop_Fields = {
  __typename?: "inbound_manifest_var_pop_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Var_Pop_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Var_Samp_Fields = {
  __typename?: "inbound_manifest_var_samp_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Var_Samp_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Variance_Fields = {
  __typename?: "inbound_manifest_variance_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  inbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Variance_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "internal_transfer" */
export type Internal_Transfer = {
  __typename?: "internal_transfer";
  created_at: Scalars["timestamptz"];
  /** An object relationship */
  from_location?: Maybe<Location>;
  from_location_id: Scalars["Int"];
  id: Scalars["Int"];
  /** An array relationship */
  internal_transfer_lines: Array<Internal_Transfer_Line>;
  /** An aggregate relationship */
  internal_transfer_lines_aggregate: Internal_Transfer_Line_Aggregate;
  /** An object relationship */
  internal_transfer_status: Internal_Transfer_Status;
  internal_transfer_status_id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  reference: Scalars["String"];
  /** An object relationship */
  to_location: Location;
  to_location_id: Scalars["Int"];
  transfer_date: Scalars["timestamptz"];
};

/** columns and relationships of "internal_transfer" */
export type Internal_TransferInternal_Transfer_LinesArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** columns and relationships of "internal_transfer" */
export type Internal_TransferInternal_Transfer_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** aggregated selection of "internal_transfer" */
export type Internal_Transfer_Aggregate = {
  __typename?: "internal_transfer_aggregate";
  aggregate?: Maybe<Internal_Transfer_Aggregate_Fields>;
  nodes: Array<Internal_Transfer>;
};

export type Internal_Transfer_Aggregate_Bool_Exp = {
  count?: Maybe<Internal_Transfer_Aggregate_Bool_Exp_Count>;
};

export type Internal_Transfer_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Internal_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Internal_Transfer_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "internal_transfer" */
export type Internal_Transfer_Aggregate_Fields = {
  __typename?: "internal_transfer_aggregate_fields";
  avg?: Maybe<Internal_Transfer_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Internal_Transfer_Max_Fields>;
  min?: Maybe<Internal_Transfer_Min_Fields>;
  stddev?: Maybe<Internal_Transfer_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Transfer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Transfer_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Transfer_Sum_Fields>;
  var_pop?: Maybe<Internal_Transfer_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Transfer_Var_Samp_Fields>;
  variance?: Maybe<Internal_Transfer_Variance_Fields>;
};

/** aggregate fields of "internal_transfer" */
export type Internal_Transfer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Internal_Transfer_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "internal_transfer" */
export type Internal_Transfer_Aggregate_Order_By = {
  avg?: Maybe<Internal_Transfer_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Internal_Transfer_Max_Order_By>;
  min?: Maybe<Internal_Transfer_Min_Order_By>;
  stddev?: Maybe<Internal_Transfer_Stddev_Order_By>;
  stddev_pop?: Maybe<Internal_Transfer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Internal_Transfer_Stddev_Samp_Order_By>;
  sum?: Maybe<Internal_Transfer_Sum_Order_By>;
  var_pop?: Maybe<Internal_Transfer_Var_Pop_Order_By>;
  var_samp?: Maybe<Internal_Transfer_Var_Samp_Order_By>;
  variance?: Maybe<Internal_Transfer_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "internal_transfer" */
export type Internal_Transfer_Arr_Rel_Insert_Input = {
  data: Array<Internal_Transfer_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Internal_Transfer_On_Conflict>;
};

/** aggregate avg on columns */
export type Internal_Transfer_Avg_Fields = {
  __typename?: "internal_transfer_avg_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "internal_transfer" */
export type Internal_Transfer_Avg_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "internal_transfer". All fields are combined with a logical 'AND'. */
export type Internal_Transfer_Bool_Exp = {
  _and?: Maybe<Array<Internal_Transfer_Bool_Exp>>;
  _not?: Maybe<Internal_Transfer_Bool_Exp>;
  _or?: Maybe<Array<Internal_Transfer_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  from_location?: Maybe<Location_Bool_Exp>;
  from_location_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  internal_transfer_lines?: Maybe<Internal_Transfer_Line_Bool_Exp>;
  internal_transfer_lines_aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Bool_Exp>;
  internal_transfer_status?: Maybe<Internal_Transfer_Status_Bool_Exp>;
  internal_transfer_status_id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  to_location?: Maybe<Location_Bool_Exp>;
  to_location_id?: Maybe<Int_Comparison_Exp>;
  transfer_date?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "internal_transfer" */
export enum Internal_Transfer_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalTransferPkey = "internal_transfer_pkey",
  /** unique or primary key constraint on columns "reference" */
  InternalTransferReferenceKey = "internal_transfer_reference_key",
}

/** input type for incrementing numeric columns in table "internal_transfer" */
export type Internal_Transfer_Inc_Input = {
  from_location_id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  to_location_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "internal_transfer" */
export type Internal_Transfer_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  from_location_id?: Maybe<Scalars["Int"]>;
  internal_transfer_lines?: Maybe<Internal_Transfer_Line_Arr_Rel_Insert_Input>;
  internal_transfer_status?: Maybe<Internal_Transfer_Status_Obj_Rel_Insert_Input>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  reference?: Maybe<Scalars["String"]>;
  to_location?: Maybe<Location_Obj_Rel_Insert_Input>;
  to_location_id?: Maybe<Scalars["Int"]>;
  transfer_date?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "internal_transfer_line" */
export type Internal_Transfer_Line = {
  __typename?: "internal_transfer_line";
  cancelled_quantity: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An object relationship */
  internal_transfer: Internal_Transfer;
  internal_transfer_id: Scalars["Int"];
  /** An object relationship */
  internal_transfer_line_status: Internal_Transfer_Line_Status;
  internal_transfer_line_status_id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product_stock: Product_Stock;
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  quantity: Scalars["Int"];
  reserved_quantity: Scalars["Int"];
  source_product_stock_id: Scalars["Int"];
  transferred_quantity: Scalars["Int"];
};

/** columns and relationships of "internal_transfer_line" */
export type Internal_Transfer_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "internal_transfer_line" */
export type Internal_Transfer_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "internal_transfer_line" */
export type Internal_Transfer_Line_Aggregate = {
  __typename?: "internal_transfer_line_aggregate";
  aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Fields>;
  nodes: Array<Internal_Transfer_Line>;
};

export type Internal_Transfer_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Internal_Transfer_Line_Aggregate_Bool_Exp_Count>;
};

export type Internal_Transfer_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Internal_Transfer_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "internal_transfer_line" */
export type Internal_Transfer_Line_Aggregate_Fields = {
  __typename?: "internal_transfer_line_aggregate_fields";
  avg?: Maybe<Internal_Transfer_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Internal_Transfer_Line_Max_Fields>;
  min?: Maybe<Internal_Transfer_Line_Min_Fields>;
  stddev?: Maybe<Internal_Transfer_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Transfer_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Transfer_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Transfer_Line_Sum_Fields>;
  var_pop?: Maybe<Internal_Transfer_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Transfer_Line_Var_Samp_Fields>;
  variance?: Maybe<Internal_Transfer_Line_Variance_Fields>;
};

/** aggregate fields of "internal_transfer_line" */
export type Internal_Transfer_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "internal_transfer_line" */
export type Internal_Transfer_Line_Aggregate_Order_By = {
  avg?: Maybe<Internal_Transfer_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Internal_Transfer_Line_Max_Order_By>;
  min?: Maybe<Internal_Transfer_Line_Min_Order_By>;
  stddev?: Maybe<Internal_Transfer_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Internal_Transfer_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Internal_Transfer_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Internal_Transfer_Line_Sum_Order_By>;
  var_pop?: Maybe<Internal_Transfer_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Internal_Transfer_Line_Var_Samp_Order_By>;
  variance?: Maybe<Internal_Transfer_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "internal_transfer_line" */
export type Internal_Transfer_Line_Arr_Rel_Insert_Input = {
  data: Array<Internal_Transfer_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Internal_Transfer_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Internal_Transfer_Line_Avg_Fields = {
  __typename?: "internal_transfer_line_avg_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "internal_transfer_line". All fields are combined with a logical 'AND'. */
export type Internal_Transfer_Line_Bool_Exp = {
  _and?: Maybe<Array<Internal_Transfer_Line_Bool_Exp>>;
  _not?: Maybe<Internal_Transfer_Line_Bool_Exp>;
  _or?: Maybe<Array<Internal_Transfer_Line_Bool_Exp>>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  internal_transfer?: Maybe<Internal_Transfer_Bool_Exp>;
  internal_transfer_id?: Maybe<Int_Comparison_Exp>;
  internal_transfer_line_status?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
  internal_transfer_line_status_id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  reserved_quantity?: Maybe<Int_Comparison_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  transferred_quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "internal_transfer_line" */
export enum Internal_Transfer_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  InternalTransferLinePkey = "internal_transfer_line_pkey",
}

/** input type for incrementing numeric columns in table "internal_transfer_line" */
export type Internal_Transfer_Line_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "internal_transfer_line" */
export type Internal_Transfer_Line_Insert_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internal_transfer?: Maybe<Internal_Transfer_Obj_Rel_Insert_Input>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status?: Maybe<Internal_Transfer_Line_Status_Obj_Rel_Insert_Input>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Internal_Transfer_Line_Max_Fields = {
  __typename?: "internal_transfer_line_max_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Internal_Transfer_Line_Min_Fields = {
  __typename?: "internal_transfer_line_min_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "internal_transfer_line" */
export type Internal_Transfer_Line_Mutation_Response = {
  __typename?: "internal_transfer_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Transfer_Line>;
};

/** input type for inserting object relation for remote table "internal_transfer_line" */
export type Internal_Transfer_Line_Obj_Rel_Insert_Input = {
  data: Internal_Transfer_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Internal_Transfer_Line_On_Conflict>;
};

/** on_conflict condition type for table "internal_transfer_line" */
export type Internal_Transfer_Line_On_Conflict = {
  constraint: Internal_Transfer_Line_Constraint;
  update_columns?: Array<Internal_Transfer_Line_Update_Column>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "internal_transfer_line". */
export type Internal_Transfer_Line_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer?: Maybe<Internal_Transfer_Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status?: Maybe<Internal_Transfer_Line_Status_Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: internal_transfer_line */
export type Internal_Transfer_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "internal_transfer_line" */
export enum Internal_Transfer_Line_Select_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalTransferId = "internal_transfer_id",
  /** column name */
  InternalTransferLineStatusId = "internal_transfer_line_status_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReservedQuantity = "reserved_quantity",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  TransferredQuantity = "transferred_quantity",
}

/** input type for updating data in table "internal_transfer_line" */
export type Internal_Transfer_Line_Set_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status = {
  __typename?: "internal_transfer_line_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  internal_transfer_line_status_name: Scalars["String"];
  /** An array relationship */
  internal_transfer_lines: Array<Internal_Transfer_Line>;
  /** An aggregate relationship */
  internal_transfer_lines_aggregate: Internal_Transfer_Line_Aggregate;
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "internal_transfer_line_status" */
export type Internal_Transfer_Line_StatusInternal_Transfer_LinesArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** columns and relationships of "internal_transfer_line_status" */
export type Internal_Transfer_Line_StatusInternal_Transfer_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** aggregated selection of "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Aggregate = {
  __typename?: "internal_transfer_line_status_aggregate";
  aggregate?: Maybe<Internal_Transfer_Line_Status_Aggregate_Fields>;
  nodes: Array<Internal_Transfer_Line_Status>;
};

/** aggregate fields of "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Aggregate_Fields = {
  __typename?: "internal_transfer_line_status_aggregate_fields";
  avg?: Maybe<Internal_Transfer_Line_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Internal_Transfer_Line_Status_Max_Fields>;
  min?: Maybe<Internal_Transfer_Line_Status_Min_Fields>;
  stddev?: Maybe<Internal_Transfer_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Transfer_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Transfer_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Transfer_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Internal_Transfer_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Transfer_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Internal_Transfer_Line_Status_Variance_Fields>;
};

/** aggregate fields of "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Internal_Transfer_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Internal_Transfer_Line_Status_Avg_Fields = {
  __typename?: "internal_transfer_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "internal_transfer_line_status". All fields are combined with a logical 'AND'. */
export type Internal_Transfer_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Internal_Transfer_Line_Status_Bool_Exp>>;
  _not?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Internal_Transfer_Line_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  internal_transfer_line_status_name?: Maybe<String_Comparison_Exp>;
  internal_transfer_lines?: Maybe<Internal_Transfer_Line_Bool_Exp>;
  internal_transfer_lines_aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "internal_transfer_line_status" */
export enum Internal_Transfer_Line_Status_Constraint {
  /** unique or primary key constraint on columns "internal_transfer_line_status_name" */
  InternalTransferLineStatusInternalTransferLineStatusKey = "internal_transfer_line_status_internal_transfer_line_status_key",
  /** unique or primary key constraint on columns "id" */
  InternalTransferLineStatusPkey = "internal_transfer_line_status_pkey",
}

/** input type for incrementing numeric columns in table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_name?: Maybe<Scalars["String"]>;
  internal_transfer_lines?: Maybe<Internal_Transfer_Line_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Internal_Transfer_Line_Status_Max_Fields = {
  __typename?: "internal_transfer_line_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Internal_Transfer_Line_Status_Min_Fields = {
  __typename?: "internal_transfer_line_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Mutation_Response = {
  __typename?: "internal_transfer_line_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Transfer_Line_Status>;
};

/** input type for inserting object relation for remote table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Obj_Rel_Insert_Input = {
  data: Internal_Transfer_Line_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Internal_Transfer_Line_Status_On_Conflict>;
};

/** on_conflict condition type for table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_On_Conflict = {
  constraint: Internal_Transfer_Line_Status_Constraint;
  update_columns?: Array<Internal_Transfer_Line_Status_Update_Column>;
  where?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "internal_transfer_line_status". */
export type Internal_Transfer_Line_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_line_status_name?: Maybe<Order_By>;
  internal_transfer_lines_aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: internal_transfer_line_status */
export type Internal_Transfer_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "internal_transfer_line_status" */
export enum Internal_Transfer_Line_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalTransferLineStatusName = "internal_transfer_line_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Internal_Transfer_Line_Status_Stddev_Fields = {
  __typename?: "internal_transfer_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Internal_Transfer_Line_Status_Stddev_Pop_Fields = {
  __typename?: "internal_transfer_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Internal_Transfer_Line_Status_Stddev_Samp_Fields = {
  __typename?: "internal_transfer_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "internal_transfer_line_status" */
export type Internal_Transfer_Line_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Transfer_Line_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Transfer_Line_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Internal_Transfer_Line_Status_Sum_Fields = {
  __typename?: "internal_transfer_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "internal_transfer_line_status" */
export enum Internal_Transfer_Line_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalTransferLineStatusName = "internal_transfer_line_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

export type Internal_Transfer_Line_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Internal_Transfer_Line_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Internal_Transfer_Line_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Transfer_Line_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Transfer_Line_Status_Var_Pop_Fields = {
  __typename?: "internal_transfer_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Internal_Transfer_Line_Status_Var_Samp_Fields = {
  __typename?: "internal_transfer_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Internal_Transfer_Line_Status_Variance_Fields = {
  __typename?: "internal_transfer_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Internal_Transfer_Line_Stddev_Fields = {
  __typename?: "internal_transfer_line_stddev_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Internal_Transfer_Line_Stddev_Pop_Fields = {
  __typename?: "internal_transfer_line_stddev_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Internal_Transfer_Line_Stddev_Samp_Fields = {
  __typename?: "internal_transfer_line_stddev_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "internal_transfer_line" */
export type Internal_Transfer_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Transfer_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Transfer_Line_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Internal_Transfer_Line_Sum_Fields = {
  __typename?: "internal_transfer_line_sum_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_id?: Maybe<Scalars["Int"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  reserved_quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  transferred_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** update columns of table "internal_transfer_line" */
export enum Internal_Transfer_Line_Update_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  InternalTransferId = "internal_transfer_id",
  /** column name */
  InternalTransferLineStatusId = "internal_transfer_line_status_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReservedQuantity = "reserved_quantity",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  TransferredQuantity = "transferred_quantity",
}

export type Internal_Transfer_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Internal_Transfer_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Internal_Transfer_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Transfer_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Transfer_Line_Var_Pop_Fields = {
  __typename?: "internal_transfer_line_var_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Internal_Transfer_Line_Var_Samp_Fields = {
  __typename?: "internal_transfer_line_var_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Internal_Transfer_Line_Variance_Fields = {
  __typename?: "internal_transfer_line_variance_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_id?: Maybe<Scalars["Float"]>;
  internal_transfer_line_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  reserved_quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  transferred_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "internal_transfer_line" */
export type Internal_Transfer_Line_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_id?: Maybe<Order_By>;
  internal_transfer_line_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reserved_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  transferred_quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Internal_Transfer_Max_Fields = {
  __typename?: "internal_transfer_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  reference?: Maybe<Scalars["String"]>;
  to_location_id?: Maybe<Scalars["Int"]>;
  transfer_date?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "internal_transfer" */
export type Internal_Transfer_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
  transfer_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Internal_Transfer_Min_Fields = {
  __typename?: "internal_transfer_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  reference?: Maybe<Scalars["String"]>;
  to_location_id?: Maybe<Scalars["Int"]>;
  transfer_date?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "internal_transfer" */
export type Internal_Transfer_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
  transfer_date?: Maybe<Order_By>;
};

/** response of any mutation on the table "internal_transfer" */
export type Internal_Transfer_Mutation_Response = {
  __typename?: "internal_transfer_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Transfer>;
};

/** input type for inserting object relation for remote table "internal_transfer" */
export type Internal_Transfer_Obj_Rel_Insert_Input = {
  data: Internal_Transfer_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Internal_Transfer_On_Conflict>;
};

/** on_conflict condition type for table "internal_transfer" */
export type Internal_Transfer_On_Conflict = {
  constraint: Internal_Transfer_Constraint;
  update_columns?: Array<Internal_Transfer_Update_Column>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

/** Ordering options when selecting data from "internal_transfer". */
export type Internal_Transfer_Order_By = {
  created_at?: Maybe<Order_By>;
  from_location?: Maybe<Location_Order_By>;
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_lines_aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Order_By>;
  internal_transfer_status?: Maybe<Internal_Transfer_Status_Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  to_location?: Maybe<Location_Order_By>;
  to_location_id?: Maybe<Order_By>;
  transfer_date?: Maybe<Order_By>;
};

/** primary key columns input for table: internal_transfer */
export type Internal_Transfer_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "internal_transfer" */
export enum Internal_Transfer_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromLocationId = "from_location_id",
  /** column name */
  Id = "id",
  /** column name */
  InternalTransferStatusId = "internal_transfer_status_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Reference = "reference",
  /** column name */
  ToLocationId = "to_location_id",
  /** column name */
  TransferDate = "transfer_date",
}

/** input type for updating data in table "internal_transfer" */
export type Internal_Transfer_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_location_id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  reference?: Maybe<Scalars["String"]>;
  to_location_id?: Maybe<Scalars["Int"]>;
  transfer_date?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "internal_transfer_status" */
export type Internal_Transfer_Status = {
  __typename?: "internal_transfer_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  internal_transfer_status_name: Scalars["String"];
  /** An array relationship */
  internal_transfers: Array<Internal_Transfer>;
  /** An aggregate relationship */
  internal_transfers_aggregate: Internal_Transfer_Aggregate;
  last_updated: Scalars["timestamptz"];
};

/** columns and relationships of "internal_transfer_status" */
export type Internal_Transfer_StatusInternal_TransfersArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

/** columns and relationships of "internal_transfer_status" */
export type Internal_Transfer_StatusInternal_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

/** aggregated selection of "internal_transfer_status" */
export type Internal_Transfer_Status_Aggregate = {
  __typename?: "internal_transfer_status_aggregate";
  aggregate?: Maybe<Internal_Transfer_Status_Aggregate_Fields>;
  nodes: Array<Internal_Transfer_Status>;
};

/** aggregate fields of "internal_transfer_status" */
export type Internal_Transfer_Status_Aggregate_Fields = {
  __typename?: "internal_transfer_status_aggregate_fields";
  avg?: Maybe<Internal_Transfer_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Internal_Transfer_Status_Max_Fields>;
  min?: Maybe<Internal_Transfer_Status_Min_Fields>;
  stddev?: Maybe<Internal_Transfer_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Internal_Transfer_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Internal_Transfer_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Internal_Transfer_Status_Sum_Fields>;
  var_pop?: Maybe<Internal_Transfer_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Internal_Transfer_Status_Var_Samp_Fields>;
  variance?: Maybe<Internal_Transfer_Status_Variance_Fields>;
};

/** aggregate fields of "internal_transfer_status" */
export type Internal_Transfer_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Internal_Transfer_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Internal_Transfer_Status_Avg_Fields = {
  __typename?: "internal_transfer_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "internal_transfer_status". All fields are combined with a logical 'AND'. */
export type Internal_Transfer_Status_Bool_Exp = {
  _and?: Maybe<Array<Internal_Transfer_Status_Bool_Exp>>;
  _not?: Maybe<Internal_Transfer_Status_Bool_Exp>;
  _or?: Maybe<Array<Internal_Transfer_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  internal_transfer_status_name?: Maybe<String_Comparison_Exp>;
  internal_transfers?: Maybe<Internal_Transfer_Bool_Exp>;
  internal_transfers_aggregate?: Maybe<Internal_Transfer_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "internal_transfer_status" */
export enum Internal_Transfer_Status_Constraint {
  /** unique or primary key constraint on columns "internal_transfer_status_name" */
  InternalTransferStatusInternalTransferStatusNameKey = "internal_transfer_status_internal_transfer_status_name_key",
  /** unique or primary key constraint on columns "id" */
  InternalTransferStatusPkey = "internal_transfer_status_pkey",
}

/** input type for incrementing numeric columns in table "internal_transfer_status" */
export type Internal_Transfer_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "internal_transfer_status" */
export type Internal_Transfer_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_name?: Maybe<Scalars["String"]>;
  internal_transfers?: Maybe<Internal_Transfer_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Internal_Transfer_Status_Max_Fields = {
  __typename?: "internal_transfer_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate min on columns */
export type Internal_Transfer_Status_Min_Fields = {
  __typename?: "internal_transfer_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** response of any mutation on the table "internal_transfer_status" */
export type Internal_Transfer_Status_Mutation_Response = {
  __typename?: "internal_transfer_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Internal_Transfer_Status>;
};

/** input type for inserting object relation for remote table "internal_transfer_status" */
export type Internal_Transfer_Status_Obj_Rel_Insert_Input = {
  data: Internal_Transfer_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Internal_Transfer_Status_On_Conflict>;
};

/** on_conflict condition type for table "internal_transfer_status" */
export type Internal_Transfer_Status_On_Conflict = {
  constraint: Internal_Transfer_Status_Constraint;
  update_columns?: Array<Internal_Transfer_Status_Update_Column>;
  where?: Maybe<Internal_Transfer_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "internal_transfer_status". */
export type Internal_Transfer_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_name?: Maybe<Order_By>;
  internal_transfers_aggregate?: Maybe<Internal_Transfer_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
};

/** primary key columns input for table: internal_transfer_status */
export type Internal_Transfer_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "internal_transfer_status" */
export enum Internal_Transfer_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalTransferStatusName = "internal_transfer_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

/** input type for updating data in table "internal_transfer_status" */
export type Internal_Transfer_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Internal_Transfer_Status_Stddev_Fields = {
  __typename?: "internal_transfer_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Internal_Transfer_Status_Stddev_Pop_Fields = {
  __typename?: "internal_transfer_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Internal_Transfer_Status_Stddev_Samp_Fields = {
  __typename?: "internal_transfer_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "internal_transfer_status" */
export type Internal_Transfer_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Transfer_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Transfer_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_name?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Internal_Transfer_Status_Sum_Fields = {
  __typename?: "internal_transfer_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "internal_transfer_status" */
export enum Internal_Transfer_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InternalTransferStatusName = "internal_transfer_status_name",
  /** column name */
  LastUpdated = "last_updated",
}

export type Internal_Transfer_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Internal_Transfer_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Internal_Transfer_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Transfer_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Transfer_Status_Var_Pop_Fields = {
  __typename?: "internal_transfer_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Internal_Transfer_Status_Var_Samp_Fields = {
  __typename?: "internal_transfer_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Internal_Transfer_Status_Variance_Fields = {
  __typename?: "internal_transfer_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Internal_Transfer_Stddev_Fields = {
  __typename?: "internal_transfer_stddev_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "internal_transfer" */
export type Internal_Transfer_Stddev_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Internal_Transfer_Stddev_Pop_Fields = {
  __typename?: "internal_transfer_stddev_pop_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "internal_transfer" */
export type Internal_Transfer_Stddev_Pop_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Internal_Transfer_Stddev_Samp_Fields = {
  __typename?: "internal_transfer_stddev_samp_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "internal_transfer" */
export type Internal_Transfer_Stddev_Samp_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "internal_transfer" */
export type Internal_Transfer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Internal_Transfer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Internal_Transfer_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  from_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  reference?: Maybe<Scalars["String"]>;
  to_location_id?: Maybe<Scalars["Int"]>;
  transfer_date?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Internal_Transfer_Sum_Fields = {
  __typename?: "internal_transfer_sum_fields";
  from_location_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  internal_transfer_status_id?: Maybe<Scalars["Int"]>;
  to_location_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "internal_transfer" */
export type Internal_Transfer_Sum_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** update columns of table "internal_transfer" */
export enum Internal_Transfer_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FromLocationId = "from_location_id",
  /** column name */
  InternalTransferStatusId = "internal_transfer_status_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Reference = "reference",
  /** column name */
  ToLocationId = "to_location_id",
  /** column name */
  TransferDate = "transfer_date",
}

export type Internal_Transfer_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Internal_Transfer_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Internal_Transfer_Set_Input>;
  /** filter the rows which have to be updated */
  where: Internal_Transfer_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Internal_Transfer_Var_Pop_Fields = {
  __typename?: "internal_transfer_var_pop_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "internal_transfer" */
export type Internal_Transfer_Var_Pop_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Internal_Transfer_Var_Samp_Fields = {
  __typename?: "internal_transfer_var_samp_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "internal_transfer" */
export type Internal_Transfer_Var_Samp_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Internal_Transfer_Variance_Fields = {
  __typename?: "internal_transfer_variance_fields";
  from_location_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  internal_transfer_status_id?: Maybe<Scalars["Float"]>;
  to_location_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "internal_transfer" */
export type Internal_Transfer_Variance_Order_By = {
  from_location_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  internal_transfer_status_id?: Maybe<Order_By>;
  to_location_id?: Maybe<Order_By>;
};

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
};

/** columns and relationships of "location" */
export type Location = {
  __typename?: "location";
  can_hold_stock: Scalars["Boolean"];
  /** An array relationship */
  case_change_output_lines: Array<Case_Change_Output_Line>;
  /** An aggregate relationship */
  case_change_output_lines_aggregate: Case_Change_Output_Line_Aggregate;
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  file_copy_deletions: Array<File_Copy_Deletion>;
  /** An aggregate relationship */
  file_copy_deletions_aggregate: File_Copy_Deletion_Aggregate;
  id: Scalars["Int"];
  /** An array relationship */
  internal_transfers: Array<Internal_Transfer>;
  /** An aggregate relationship */
  internal_transfers_aggregate: Internal_Transfer_Aggregate;
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location?: Maybe<Location>;
  location_attributes: Scalars["jsonb"];
  location_barcode_uuid: Scalars["uuid"];
  location_name: Scalars["String"];
  /** An object relationship */
  location_type: Location_Type;
  location_type_id: Scalars["Int"];
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate;
  parent_location_id?: Maybe<Scalars["Int"]>;
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An array relationship */
  stock_allocation_fulfilments: Array<Stock_Allocation_Fulfilment>;
  /** An aggregate relationship */
  stock_allocation_fulfilments_aggregate: Stock_Allocation_Fulfilment_Aggregate;
  /** An array relationship */
  stock_check_line_fulfilments: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stock_check_line_fulfilments_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  /** An array relationship */
  stock_checks: Array<Stock_Check>;
  /** An aggregate relationship */
  stock_checks_aggregate: Stock_Check_Aggregate;
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars["Int"];
};

/** columns and relationships of "location" */
export type LocationCase_Change_Output_LinesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationCase_Change_Output_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationFile_Copy_DeletionsArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationFile_Copy_Deletions_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationInternal_TransfersArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationInternal_Transfers_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationLocation_AttributesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "location" */
export type LocationLocationsArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationStock_Allocation_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationStock_Allocation_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationStock_Check_Line_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationStock_Check_Line_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationStock_ChecksArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** columns and relationships of "location" */
export type LocationStock_Checks_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** aggregated selection of "location" */
export type Location_Aggregate = {
  __typename?: "location_aggregate";
  aggregate?: Maybe<Location_Aggregate_Fields>;
  nodes: Array<Location>;
};

export type Location_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Location_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Location_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Location_Aggregate_Bool_Exp_Count>;
};

export type Location_Aggregate_Bool_Exp_Bool_And = {
  arguments: Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Location_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Location_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Location_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Location_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "location" */
export type Location_Aggregate_Fields = {
  __typename?: "location_aggregate_fields";
  avg?: Maybe<Location_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Location_Max_Fields>;
  min?: Maybe<Location_Min_Fields>;
  stddev?: Maybe<Location_Stddev_Fields>;
  stddev_pop?: Maybe<Location_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Location_Stddev_Samp_Fields>;
  sum?: Maybe<Location_Sum_Fields>;
  var_pop?: Maybe<Location_Var_Pop_Fields>;
  var_samp?: Maybe<Location_Var_Samp_Fields>;
  variance?: Maybe<Location_Variance_Fields>;
};

/** aggregate fields of "location" */
export type Location_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Location_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "location" */
export type Location_Aggregate_Order_By = {
  avg?: Maybe<Location_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Location_Max_Order_By>;
  min?: Maybe<Location_Min_Order_By>;
  stddev?: Maybe<Location_Stddev_Order_By>;
  stddev_pop?: Maybe<Location_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Location_Stddev_Samp_Order_By>;
  sum?: Maybe<Location_Sum_Order_By>;
  var_pop?: Maybe<Location_Var_Pop_Order_By>;
  var_samp?: Maybe<Location_Var_Samp_Order_By>;
  variance?: Maybe<Location_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Location_Append_Input = {
  location_attributes?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "location" */
export type Location_Arr_Rel_Insert_Input = {
  data: Array<Location_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Location_On_Conflict>;
};

/** aggregate avg on columns */
export type Location_Avg_Fields = {
  __typename?: "location_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "location" */
export type Location_Avg_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "location". All fields are combined with a logical 'AND'. */
export type Location_Bool_Exp = {
  _and?: Maybe<Array<Location_Bool_Exp>>;
  _not?: Maybe<Location_Bool_Exp>;
  _or?: Maybe<Array<Location_Bool_Exp>>;
  can_hold_stock?: Maybe<Boolean_Comparison_Exp>;
  case_change_output_lines?: Maybe<Case_Change_Output_Line_Bool_Exp>;
  case_change_output_lines_aggregate?: Maybe<Case_Change_Output_Line_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Bool_Exp>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  internal_transfers?: Maybe<Internal_Transfer_Bool_Exp>;
  internal_transfers_aggregate?: Maybe<Internal_Transfer_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_attributes?: Maybe<Jsonb_Comparison_Exp>;
  location_barcode_uuid?: Maybe<Uuid_Comparison_Exp>;
  location_name?: Maybe<String_Comparison_Exp>;
  location_type?: Maybe<Location_Type_Bool_Exp>;
  location_type_id?: Maybe<Int_Comparison_Exp>;
  locations?: Maybe<Location_Bool_Exp>;
  locations_aggregate?: Maybe<Location_Aggregate_Bool_Exp>;
  parent_location_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  stock_allocation_fulfilments?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
  stock_allocation_fulfilments_aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Bool_Exp>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
  stock_checks?: Maybe<Stock_Check_Bool_Exp>;
  stock_checks_aggregate?: Maybe<Stock_Check_Aggregate_Bool_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "location" */
export enum Location_Constraint {
  /** unique or primary key constraint on columns "id" */
  LocationPkey = "location_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Location_Delete_At_Path_Input = {
  location_attributes?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Location_Delete_Elem_Input = {
  location_attributes?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Location_Delete_Key_Input = {
  location_attributes?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "location" */
export type Location_Inc_Input = {
  location_type_id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "location" */
export type Location_Insert_Input = {
  can_hold_stock?: Maybe<Scalars["Boolean"]>;
  case_change_output_lines?: Maybe<Case_Change_Output_Line_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Arr_Rel_Insert_Input>;
  internal_transfers?: Maybe<Internal_Transfer_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_attributes?: Maybe<Scalars["jsonb"]>;
  location_barcode_uuid?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  location_type?: Maybe<Location_Type_Obj_Rel_Insert_Input>;
  location_type_id?: Maybe<Scalars["Int"]>;
  locations?: Maybe<Location_Arr_Rel_Insert_Input>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  stock_allocation_fulfilments?: Maybe<Stock_Allocation_Fulfilment_Arr_Rel_Insert_Input>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
  stock_checks?: Maybe<Stock_Check_Arr_Rel_Insert_Input>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Location_Max_Fields = {
  __typename?: "location_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_barcode_uuid?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  location_type_id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "location" */
export type Location_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_barcode_uuid?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Location_Min_Fields = {
  __typename?: "location_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_barcode_uuid?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  location_type_id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "location" */
export type Location_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_barcode_uuid?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "location" */
export type Location_Mutation_Response = {
  __typename?: "location_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Location>;
};

/** input type for inserting object relation for remote table "location" */
export type Location_Obj_Rel_Insert_Input = {
  data: Location_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Location_On_Conflict>;
};

/** on_conflict condition type for table "location" */
export type Location_On_Conflict = {
  constraint: Location_Constraint;
  update_columns?: Array<Location_Update_Column>;
  where?: Maybe<Location_Bool_Exp>;
};

/** Ordering options when selecting data from "location". */
export type Location_Order_By = {
  can_hold_stock?: Maybe<Order_By>;
  case_change_output_lines_aggregate?: Maybe<Case_Change_Output_Line_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  internal_transfers_aggregate?: Maybe<Internal_Transfer_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_attributes?: Maybe<Order_By>;
  location_barcode_uuid?: Maybe<Order_By>;
  location_name?: Maybe<Order_By>;
  location_type?: Maybe<Location_Type_Order_By>;
  location_type_id?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Location_Aggregate_Order_By>;
  parent_location_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  stock_allocation_fulfilments_aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Order_By>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
  stock_checks_aggregate?: Maybe<Stock_Check_Aggregate_Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** primary key columns input for table: location */
export type Location_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Location_Prepend_Input = {
  location_attributes?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "location" */
export enum Location_Select_Column {
  /** column name */
  CanHoldStock = "can_hold_stock",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationAttributes = "location_attributes",
  /** column name */
  LocationBarcodeUuid = "location_barcode_uuid",
  /** column name */
  LocationName = "location_name",
  /** column name */
  LocationTypeId = "location_type_id",
  /** column name */
  ParentLocationId = "parent_location_id",
  /** column name */
  WarehouseId = "warehouse_id",
}

/** select "location_aggregate_bool_exp_bool_and_arguments_columns" columns of table "location" */
export enum Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  CanHoldStock = "can_hold_stock",
}

/** select "location_aggregate_bool_exp_bool_or_arguments_columns" columns of table "location" */
export enum Location_Select_Column_Location_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  CanHoldStock = "can_hold_stock",
}

/** input type for updating data in table "location" */
export type Location_Set_Input = {
  can_hold_stock?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_attributes?: Maybe<Scalars["jsonb"]>;
  location_barcode_uuid?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  location_type_id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Location_Stddev_Fields = {
  __typename?: "location_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "location" */
export type Location_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Location_Stddev_Pop_Fields = {
  __typename?: "location_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "location" */
export type Location_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Location_Stddev_Samp_Fields = {
  __typename?: "location_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "location" */
export type Location_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "location" */
export type Location_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Stream_Cursor_Value_Input = {
  can_hold_stock?: Maybe<Scalars["Boolean"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_attributes?: Maybe<Scalars["jsonb"]>;
  location_barcode_uuid?: Maybe<Scalars["uuid"]>;
  location_name?: Maybe<Scalars["String"]>;
  location_type_id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Location_Sum_Fields = {
  __typename?: "location_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  location_type_id?: Maybe<Scalars["Int"]>;
  parent_location_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "location" */
export type Location_Sum_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "location_type" */
export type Location_Type = {
  __typename?: "location_type";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  location_type_name: Scalars["String"];
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate;
};

/** columns and relationships of "location_type" */
export type Location_TypeLocationsArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

/** columns and relationships of "location_type" */
export type Location_TypeLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

/** aggregated selection of "location_type" */
export type Location_Type_Aggregate = {
  __typename?: "location_type_aggregate";
  aggregate?: Maybe<Location_Type_Aggregate_Fields>;
  nodes: Array<Location_Type>;
};

/** aggregate fields of "location_type" */
export type Location_Type_Aggregate_Fields = {
  __typename?: "location_type_aggregate_fields";
  avg?: Maybe<Location_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Location_Type_Max_Fields>;
  min?: Maybe<Location_Type_Min_Fields>;
  stddev?: Maybe<Location_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Location_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Location_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Location_Type_Sum_Fields>;
  var_pop?: Maybe<Location_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Location_Type_Var_Samp_Fields>;
  variance?: Maybe<Location_Type_Variance_Fields>;
};

/** aggregate fields of "location_type" */
export type Location_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Location_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Location_Type_Avg_Fields = {
  __typename?: "location_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "location_type". All fields are combined with a logical 'AND'. */
export type Location_Type_Bool_Exp = {
  _and?: Maybe<Array<Location_Type_Bool_Exp>>;
  _not?: Maybe<Location_Type_Bool_Exp>;
  _or?: Maybe<Array<Location_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location_type_name?: Maybe<String_Comparison_Exp>;
  locations?: Maybe<Location_Bool_Exp>;
  locations_aggregate?: Maybe<Location_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "location_type" */
export enum Location_Type_Constraint {
  /** unique or primary key constraint on columns "location_type_name" */
  LocationTypeLocationTypeNameKey = "location_type_location_type_name_key",
  /** unique or primary key constraint on columns "id" */
  LocationTypePkey = "location_type_pkey",
}

/** input type for incrementing numeric columns in table "location_type" */
export type Location_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "location_type" */
export type Location_Type_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_type_name?: Maybe<Scalars["String"]>;
  locations?: Maybe<Location_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Location_Type_Max_Fields = {
  __typename?: "location_type_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Location_Type_Min_Fields = {
  __typename?: "location_type_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_type_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "location_type" */
export type Location_Type_Mutation_Response = {
  __typename?: "location_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Location_Type>;
};

/** input type for inserting object relation for remote table "location_type" */
export type Location_Type_Obj_Rel_Insert_Input = {
  data: Location_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Location_Type_On_Conflict>;
};

/** on_conflict condition type for table "location_type" */
export type Location_Type_On_Conflict = {
  constraint: Location_Type_Constraint;
  update_columns?: Array<Location_Type_Update_Column>;
  where?: Maybe<Location_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "location_type". */
export type Location_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_type_name?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Location_Aggregate_Order_By>;
};

/** primary key columns input for table: location_type */
export type Location_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "location_type" */
export enum Location_Type_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationTypeName = "location_type_name",
}

/** input type for updating data in table "location_type" */
export type Location_Type_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Location_Type_Stddev_Fields = {
  __typename?: "location_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Location_Type_Stddev_Pop_Fields = {
  __typename?: "location_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Location_Type_Stddev_Samp_Fields = {
  __typename?: "location_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "location_type" */
export type Location_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Location_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Location_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Location_Type_Sum_Fields = {
  __typename?: "location_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "location_type" */
export enum Location_Type_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationTypeName = "location_type_name",
}

export type Location_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Location_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Location_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Type_Var_Pop_Fields = {
  __typename?: "location_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Location_Type_Var_Samp_Fields = {
  __typename?: "location_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Location_Type_Variance_Fields = {
  __typename?: "location_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** update columns of table "location" */
export enum Location_Update_Column {
  /** column name */
  CanHoldStock = "can_hold_stock",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationAttributes = "location_attributes",
  /** column name */
  LocationBarcodeUuid = "location_barcode_uuid",
  /** column name */
  LocationName = "location_name",
  /** column name */
  LocationTypeId = "location_type_id",
  /** column name */
  ParentLocationId = "parent_location_id",
  /** column name */
  WarehouseId = "warehouse_id",
}

export type Location_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Location_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Location_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Location_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Location_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Location_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Location_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Location_Set_Input>;
  /** filter the rows which have to be updated */
  where: Location_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Location_Var_Pop_Fields = {
  __typename?: "location_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "location" */
export type Location_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Location_Var_Samp_Fields = {
  __typename?: "location_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "location" */
export type Location_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Location_Variance_Fields = {
  __typename?: "location_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  location_type_id?: Maybe<Scalars["Float"]>;
  parent_location_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "location" */
export type Location_Variance_Order_By = {
  id?: Maybe<Order_By>;
  location_type_id?: Maybe<Order_By>;
  parent_location_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "logistics_type" */
export type Logistics_Type = {
  __typename?: "logistics_type";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  last_updated: Scalars["timestamptz"];
  logistics_type_name: Scalars["String"];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
};

/** columns and relationships of "logistics_type" */
export type Logistics_TypeInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "logistics_type" */
export type Logistics_TypeInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "logistics_type" */
export type Logistics_TypeOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "logistics_type" */
export type Logistics_TypeOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "logistics_type" */
export type Logistics_Type_Aggregate = {
  __typename?: "logistics_type_aggregate";
  aggregate?: Maybe<Logistics_Type_Aggregate_Fields>;
  nodes: Array<Logistics_Type>;
};

/** aggregate fields of "logistics_type" */
export type Logistics_Type_Aggregate_Fields = {
  __typename?: "logistics_type_aggregate_fields";
  avg?: Maybe<Logistics_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Logistics_Type_Max_Fields>;
  min?: Maybe<Logistics_Type_Min_Fields>;
  stddev?: Maybe<Logistics_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Logistics_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Logistics_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Logistics_Type_Sum_Fields>;
  var_pop?: Maybe<Logistics_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Logistics_Type_Var_Samp_Fields>;
  variance?: Maybe<Logistics_Type_Variance_Fields>;
};

/** aggregate fields of "logistics_type" */
export type Logistics_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logistics_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Logistics_Type_Avg_Fields = {
  __typename?: "logistics_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "logistics_type". All fields are combined with a logical 'AND'. */
export type Logistics_Type_Bool_Exp = {
  _and?: Maybe<Array<Logistics_Type_Bool_Exp>>;
  _not?: Maybe<Logistics_Type_Bool_Exp>;
  _or?: Maybe<Array<Logistics_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  logistics_type_name?: Maybe<String_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "logistics_type" */
export enum Logistics_Type_Constraint {
  /** unique or primary key constraint on columns "logistics_type_name" */
  LogisticsTypeLogisticsTypeNameKey = "logistics_type_logistics_type_name_key",
  /** unique or primary key constraint on columns "id" */
  LogisticsTypePkey = "logistics_type_pkey",
}

/** input type for incrementing numeric columns in table "logistics_type" */
export type Logistics_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "logistics_type" */
export type Logistics_Type_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_name?: Maybe<Scalars["String"]>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Logistics_Type_Max_Fields = {
  __typename?: "logistics_type_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Logistics_Type_Min_Fields = {
  __typename?: "logistics_type_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "logistics_type" */
export type Logistics_Type_Mutation_Response = {
  __typename?: "logistics_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Logistics_Type>;
};

/** input type for inserting object relation for remote table "logistics_type" */
export type Logistics_Type_Obj_Rel_Insert_Input = {
  data: Logistics_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Logistics_Type_On_Conflict>;
};

/** on_conflict condition type for table "logistics_type" */
export type Logistics_Type_On_Conflict = {
  constraint: Logistics_Type_Constraint;
  update_columns?: Array<Logistics_Type_Update_Column>;
  where?: Maybe<Logistics_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "logistics_type". */
export type Logistics_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type_name?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
};

/** primary key columns input for table: logistics_type */
export type Logistics_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "logistics_type" */
export enum Logistics_Type_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LogisticsTypeName = "logistics_type_name",
}

/** input type for updating data in table "logistics_type" */
export type Logistics_Type_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Logistics_Type_Stddev_Fields = {
  __typename?: "logistics_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Logistics_Type_Stddev_Pop_Fields = {
  __typename?: "logistics_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Logistics_Type_Stddev_Samp_Fields = {
  __typename?: "logistics_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "logistics_type" */
export type Logistics_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Logistics_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Logistics_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Logistics_Type_Sum_Fields = {
  __typename?: "logistics_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "logistics_type" */
export enum Logistics_Type_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LogisticsTypeName = "logistics_type_name",
}

export type Logistics_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Logistics_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Logistics_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Logistics_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Logistics_Type_Var_Pop_Fields = {
  __typename?: "logistics_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Logistics_Type_Var_Samp_Fields = {
  __typename?: "logistics_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Logistics_Type_Variance_Fields = {
  __typename?: "logistics_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "attachment" */
  delete_attachment?: Maybe<Attachment_Mutation_Response>;
  /** delete single row from the table: "attachment" */
  delete_attachment_by_pk?: Maybe<Attachment>;
  /** delete data from the table: "attachment_type" */
  delete_attachment_type?: Maybe<Attachment_Type_Mutation_Response>;
  /** delete single row from the table: "attachment_type" */
  delete_attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** delete data from the table: "base_product" */
  delete_base_product?: Maybe<Base_Product_Mutation_Response>;
  /** delete data from the table: "case_change" */
  delete_case_change?: Maybe<Case_Change_Mutation_Response>;
  /** delete single row from the table: "case_change" */
  delete_case_change_by_pk?: Maybe<Case_Change>;
  /** delete data from the table: "case_change_input_line" */
  delete_case_change_input_line?: Maybe<Case_Change_Input_Line_Mutation_Response>;
  /** delete single row from the table: "case_change_input_line" */
  delete_case_change_input_line_by_pk?: Maybe<Case_Change_Input_Line>;
  /** delete data from the table: "case_change_output_line" */
  delete_case_change_output_line?: Maybe<Case_Change_Output_Line_Mutation_Response>;
  /** delete single row from the table: "case_change_output_line" */
  delete_case_change_output_line_by_pk?: Maybe<Case_Change_Output_Line>;
  /** delete data from the table: "case_change_status" */
  delete_case_change_status?: Maybe<Case_Change_Status_Mutation_Response>;
  /** delete single row from the table: "case_change_status" */
  delete_case_change_status_by_pk?: Maybe<Case_Change_Status>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "courier" */
  delete_courier?: Maybe<Courier_Mutation_Response>;
  /** delete single row from the table: "courier" */
  delete_courier_by_pk?: Maybe<Courier>;
  /** delete data from the table: "courier_location" */
  delete_courier_location?: Maybe<Courier_Location_Mutation_Response>;
  /** delete single row from the table: "courier_location" */
  delete_courier_location_by_pk?: Maybe<Courier_Location>;
  /** delete data from the table: "courier_service" */
  delete_courier_service?: Maybe<Courier_Service_Mutation_Response>;
  /** delete single row from the table: "courier_service" */
  delete_courier_service_by_pk?: Maybe<Courier_Service>;
  /** delete data from the table: "databasechangelog" */
  delete_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** delete data from the table: "databasechangeloglock" */
  delete_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** delete single row from the table: "databasechangeloglock" */
  delete_databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** delete data from the table: "file_copy_deletion" */
  delete_file_copy_deletion?: Maybe<File_Copy_Deletion_Mutation_Response>;
  /** delete single row from the table: "file_copy_deletion" */
  delete_file_copy_deletion_by_pk?: Maybe<File_Copy_Deletion>;
  /** delete data from the table: "folder" */
  delete_folder?: Maybe<Folder_Mutation_Response>;
  /** delete single row from the table: "folder" */
  delete_folder_by_pk?: Maybe<Folder>;
  /** delete data from the table: "inbound_manifest" */
  delete_inbound_manifest?: Maybe<Inbound_Manifest_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest" */
  delete_inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** delete data from the table: "inbound_manifest_fulfilment_line" */
  delete_inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_fulfilment_line" */
  delete_inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** delete data from the table: "inbound_manifest_line" */
  delete_inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_line" */
  delete_inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** delete data from the table: "inbound_manifest_line_status" */
  delete_inbound_manifest_line_status?: Maybe<Inbound_Manifest_Line_Status_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_line_status" */
  delete_inbound_manifest_line_status_by_pk?: Maybe<Inbound_Manifest_Line_Status>;
  /** delete data from the table: "inbound_manifest_status" */
  delete_inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_status" */
  delete_inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** delete data from the table: "internal_transfer" */
  delete_internal_transfer?: Maybe<Internal_Transfer_Mutation_Response>;
  /** delete single row from the table: "internal_transfer" */
  delete_internal_transfer_by_pk?: Maybe<Internal_Transfer>;
  /** delete data from the table: "internal_transfer_line" */
  delete_internal_transfer_line?: Maybe<Internal_Transfer_Line_Mutation_Response>;
  /** delete single row from the table: "internal_transfer_line" */
  delete_internal_transfer_line_by_pk?: Maybe<Internal_Transfer_Line>;
  /** delete data from the table: "internal_transfer_line_status" */
  delete_internal_transfer_line_status?: Maybe<Internal_Transfer_Line_Status_Mutation_Response>;
  /** delete single row from the table: "internal_transfer_line_status" */
  delete_internal_transfer_line_status_by_pk?: Maybe<Internal_Transfer_Line_Status>;
  /** delete data from the table: "internal_transfer_status" */
  delete_internal_transfer_status?: Maybe<Internal_Transfer_Status_Mutation_Response>;
  /** delete single row from the table: "internal_transfer_status" */
  delete_internal_transfer_status_by_pk?: Maybe<Internal_Transfer_Status>;
  /** delete data from the table: "location" */
  delete_location?: Maybe<Location_Mutation_Response>;
  /** delete single row from the table: "location" */
  delete_location_by_pk?: Maybe<Location>;
  /** delete data from the table: "location_type" */
  delete_location_type?: Maybe<Location_Type_Mutation_Response>;
  /** delete single row from the table: "location_type" */
  delete_location_type_by_pk?: Maybe<Location_Type>;
  /** delete data from the table: "logistics_type" */
  delete_logistics_type?: Maybe<Logistics_Type_Mutation_Response>;
  /** delete single row from the table: "logistics_type" */
  delete_logistics_type_by_pk?: Maybe<Logistics_Type>;
  /** delete data from the table: "organisation" */
  delete_organisation?: Maybe<Organisation_Mutation_Response>;
  /** delete single row from the table: "organisation" */
  delete_organisation_by_pk?: Maybe<Organisation>;
  /** delete data from the table: "outbound_manifest" */
  delete_outbound_manifest?: Maybe<Outbound_Manifest_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest" */
  delete_outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** delete data from the table: "outbound_manifest_fulfilment" */
  delete_outbound_manifest_fulfilment?: Maybe<Outbound_Manifest_Fulfilment_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_fulfilment" */
  delete_outbound_manifest_fulfilment_by_pk?: Maybe<Outbound_Manifest_Fulfilment>;
  /** delete data from the table: "outbound_manifest_fulfilment_line" */
  delete_outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_fulfilment_line" */
  delete_outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** delete data from the table: "outbound_manifest_fulfilment_line_status" */
  delete_outbound_manifest_fulfilment_line_status?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_fulfilment_line_status" */
  delete_outbound_manifest_fulfilment_line_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line_Status>;
  /** delete data from the table: "outbound_manifest_fulfilment_status" */
  delete_outbound_manifest_fulfilment_status?: Maybe<Outbound_Manifest_Fulfilment_Status_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_fulfilment_status" */
  delete_outbound_manifest_fulfilment_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Status>;
  /** delete data from the table: "outbound_manifest_line" */
  delete_outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_line" */
  delete_outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** delete data from the table: "outbound_manifest_line_status" */
  delete_outbound_manifest_line_status?: Maybe<Outbound_Manifest_Line_Status_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_line_status" */
  delete_outbound_manifest_line_status_by_pk?: Maybe<Outbound_Manifest_Line_Status>;
  /** delete data from the table: "outbound_manifest_return" */
  delete_outbound_manifest_return?: Maybe<Outbound_Manifest_Return_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_return" */
  delete_outbound_manifest_return_by_pk?: Maybe<Outbound_Manifest_Return>;
  /** delete data from the table: "outbound_manifest_return_line" */
  delete_outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_return_line" */
  delete_outbound_manifest_return_line_by_pk?: Maybe<Outbound_Manifest_Return_Line>;
  /** delete data from the table: "outbound_manifest_status" */
  delete_outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_status" */
  delete_outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_stock" */
  delete_product_stock?: Maybe<Product_Stock_Mutation_Response>;
  /** delete single row from the table: "product_stock" */
  delete_product_stock_by_pk?: Maybe<Product_Stock>;
  /** delete data from the table: "recipe" */
  delete_recipe?: Maybe<Recipe_Mutation_Response>;
  /** delete single row from the table: "recipe" */
  delete_recipe_by_pk?: Maybe<Recipe>;
  /** delete data from the table: "recipe_input" */
  delete_recipe_input?: Maybe<Recipe_Input_Mutation_Response>;
  /** delete single row from the table: "recipe_input" */
  delete_recipe_input_by_pk?: Maybe<Recipe_Input>;
  /** delete data from the table: "recipe_output" */
  delete_recipe_output?: Maybe<Recipe_Output_Mutation_Response>;
  /** delete single row from the table: "recipe_output" */
  delete_recipe_output_by_pk?: Maybe<Recipe_Output>;
  /** delete data from the table: "recipe_type" */
  delete_recipe_type?: Maybe<Recipe_Type_Mutation_Response>;
  /** delete single row from the table: "recipe_type" */
  delete_recipe_type_by_pk?: Maybe<Recipe_Type>;
  /** delete data from the table: "service_account" */
  delete_service_account?: Maybe<Service_Account_Mutation_Response>;
  /** delete single row from the table: "service_account" */
  delete_service_account_by_pk?: Maybe<Service_Account>;
  /** delete data from the table: "stock_allocation" */
  delete_stock_allocation?: Maybe<Stock_Allocation_Mutation_Response>;
  /** delete single row from the table: "stock_allocation" */
  delete_stock_allocation_by_pk?: Maybe<Stock_Allocation>;
  /** delete data from the table: "stock_allocation_fulfilment" */
  delete_stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment_Mutation_Response>;
  /** delete single row from the table: "stock_allocation_fulfilment" */
  delete_stock_allocation_fulfilment_by_pk?: Maybe<Stock_Allocation_Fulfilment>;
  /** delete data from the table: "stock_check" */
  delete_stock_check?: Maybe<Stock_Check_Mutation_Response>;
  /** delete single row from the table: "stock_check" */
  delete_stock_check_by_pk?: Maybe<Stock_Check>;
  /** delete data from the table: "stock_check_line" */
  delete_stock_check_line?: Maybe<Stock_Check_Line_Mutation_Response>;
  /** delete single row from the table: "stock_check_line" */
  delete_stock_check_line_by_pk?: Maybe<Stock_Check_Line>;
  /** delete data from the table: "stock_check_line_fulfilment" */
  delete_stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment_Mutation_Response>;
  /** delete single row from the table: "stock_check_line_fulfilment" */
  delete_stock_check_line_fulfilment_by_pk?: Maybe<Stock_Check_Line_Fulfilment>;
  /** delete data from the table: "stock_check_line_fulfilment_status" */
  delete_stock_check_line_fulfilment_status?: Maybe<Stock_Check_Line_Fulfilment_Status_Mutation_Response>;
  /** delete single row from the table: "stock_check_line_fulfilment_status" */
  delete_stock_check_line_fulfilment_status_by_pk?: Maybe<Stock_Check_Line_Fulfilment_Status>;
  /** delete data from the table: "stock_check_line_status" */
  delete_stock_check_line_status?: Maybe<Stock_Check_Line_Status_Mutation_Response>;
  /** delete single row from the table: "stock_check_line_status" */
  delete_stock_check_line_status_by_pk?: Maybe<Stock_Check_Line_Status>;
  /** delete data from the table: "stock_check_status" */
  delete_stock_check_status?: Maybe<Stock_Check_Status_Mutation_Response>;
  /** delete single row from the table: "stock_check_status" */
  delete_stock_check_status_by_pk?: Maybe<Stock_Check_Status>;
  /** delete data from the table: "stock_check_type" */
  delete_stock_check_type?: Maybe<Stock_Check_Type_Mutation_Response>;
  /** delete single row from the table: "stock_check_type" */
  delete_stock_check_type_by_pk?: Maybe<Stock_Check_Type>;
  /** delete data from the table: "stock_condition" */
  delete_stock_condition?: Maybe<Stock_Condition_Mutation_Response>;
  /** delete single row from the table: "stock_condition" */
  delete_stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** delete data from the table: "stock_status" */
  delete_stock_status?: Maybe<Stock_Status_Mutation_Response>;
  /** delete single row from the table: "stock_status" */
  delete_stock_status_by_pk?: Maybe<Stock_Status>;
  /** delete data from the table: "warehouse" */
  delete_warehouse?: Maybe<Warehouse_Mutation_Response>;
  /** delete single row from the table: "warehouse" */
  delete_warehouse_by_pk?: Maybe<Warehouse>;
  /** delete data from the table: "work_order" */
  delete_work_order?: Maybe<Work_Order_Mutation_Response>;
  /** delete single row from the table: "work_order" */
  delete_work_order_by_pk?: Maybe<Work_Order>;
  /** delete data from the table: "work_order_input_line" */
  delete_work_order_input_line?: Maybe<Work_Order_Input_Line_Mutation_Response>;
  /** delete single row from the table: "work_order_input_line" */
  delete_work_order_input_line_by_pk?: Maybe<Work_Order_Input_Line>;
  /** delete data from the table: "work_order_output_line" */
  delete_work_order_output_line?: Maybe<Work_Order_Output_Line_Mutation_Response>;
  /** delete single row from the table: "work_order_output_line" */
  delete_work_order_output_line_by_pk?: Maybe<Work_Order_Output_Line>;
  /** delete data from the table: "work_order_status" */
  delete_work_order_status?: Maybe<Work_Order_Status_Mutation_Response>;
  /** delete single row from the table: "work_order_status" */
  delete_work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "attachment" */
  insert_attachment?: Maybe<Attachment_Mutation_Response>;
  /** insert a single row into the table: "attachment" */
  insert_attachment_one?: Maybe<Attachment>;
  /** insert data into the table: "attachment_type" */
  insert_attachment_type?: Maybe<Attachment_Type_Mutation_Response>;
  /** insert a single row into the table: "attachment_type" */
  insert_attachment_type_one?: Maybe<Attachment_Type>;
  /** insert data into the table: "base_product" */
  insert_base_product?: Maybe<Base_Product_Mutation_Response>;
  /** insert a single row into the table: "base_product" */
  insert_base_product_one?: Maybe<Base_Product>;
  /** insert data into the table: "case_change" */
  insert_case_change?: Maybe<Case_Change_Mutation_Response>;
  /** insert data into the table: "case_change_input_line" */
  insert_case_change_input_line?: Maybe<Case_Change_Input_Line_Mutation_Response>;
  /** insert a single row into the table: "case_change_input_line" */
  insert_case_change_input_line_one?: Maybe<Case_Change_Input_Line>;
  /** insert a single row into the table: "case_change" */
  insert_case_change_one?: Maybe<Case_Change>;
  /** insert data into the table: "case_change_output_line" */
  insert_case_change_output_line?: Maybe<Case_Change_Output_Line_Mutation_Response>;
  /** insert a single row into the table: "case_change_output_line" */
  insert_case_change_output_line_one?: Maybe<Case_Change_Output_Line>;
  /** insert data into the table: "case_change_status" */
  insert_case_change_status?: Maybe<Case_Change_Status_Mutation_Response>;
  /** insert a single row into the table: "case_change_status" */
  insert_case_change_status_one?: Maybe<Case_Change_Status>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "courier" */
  insert_courier?: Maybe<Courier_Mutation_Response>;
  /** insert data into the table: "courier_location" */
  insert_courier_location?: Maybe<Courier_Location_Mutation_Response>;
  /** insert a single row into the table: "courier_location" */
  insert_courier_location_one?: Maybe<Courier_Location>;
  /** insert a single row into the table: "courier" */
  insert_courier_one?: Maybe<Courier>;
  /** insert data into the table: "courier_service" */
  insert_courier_service?: Maybe<Courier_Service_Mutation_Response>;
  /** insert a single row into the table: "courier_service" */
  insert_courier_service_one?: Maybe<Courier_Service>;
  /** insert data into the table: "databasechangelog" */
  insert_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** insert a single row into the table: "databasechangelog" */
  insert_databasechangelog_one?: Maybe<Databasechangelog>;
  /** insert data into the table: "databasechangeloglock" */
  insert_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** insert a single row into the table: "databasechangeloglock" */
  insert_databasechangeloglock_one?: Maybe<Databasechangeloglock>;
  /** insert data into the table: "file_copy_deletion" */
  insert_file_copy_deletion?: Maybe<File_Copy_Deletion_Mutation_Response>;
  /** insert a single row into the table: "file_copy_deletion" */
  insert_file_copy_deletion_one?: Maybe<File_Copy_Deletion>;
  /** insert data into the table: "folder" */
  insert_folder?: Maybe<Folder_Mutation_Response>;
  /** insert a single row into the table: "folder" */
  insert_folder_one?: Maybe<Folder>;
  /** insert data into the table: "inbound_manifest" */
  insert_inbound_manifest?: Maybe<Inbound_Manifest_Mutation_Response>;
  /** insert data into the table: "inbound_manifest_fulfilment_line" */
  insert_inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_fulfilment_line" */
  insert_inbound_manifest_fulfilment_line_one?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** insert data into the table: "inbound_manifest_line" */
  insert_inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_line" */
  insert_inbound_manifest_line_one?: Maybe<Inbound_Manifest_Line>;
  /** insert data into the table: "inbound_manifest_line_status" */
  insert_inbound_manifest_line_status?: Maybe<Inbound_Manifest_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_line_status" */
  insert_inbound_manifest_line_status_one?: Maybe<Inbound_Manifest_Line_Status>;
  /** insert a single row into the table: "inbound_manifest" */
  insert_inbound_manifest_one?: Maybe<Inbound_Manifest>;
  /** insert data into the table: "inbound_manifest_status" */
  insert_inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_status" */
  insert_inbound_manifest_status_one?: Maybe<Inbound_Manifest_Status>;
  /** insert data into the table: "internal_transfer" */
  insert_internal_transfer?: Maybe<Internal_Transfer_Mutation_Response>;
  /** insert data into the table: "internal_transfer_line" */
  insert_internal_transfer_line?: Maybe<Internal_Transfer_Line_Mutation_Response>;
  /** insert a single row into the table: "internal_transfer_line" */
  insert_internal_transfer_line_one?: Maybe<Internal_Transfer_Line>;
  /** insert data into the table: "internal_transfer_line_status" */
  insert_internal_transfer_line_status?: Maybe<Internal_Transfer_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "internal_transfer_line_status" */
  insert_internal_transfer_line_status_one?: Maybe<Internal_Transfer_Line_Status>;
  /** insert a single row into the table: "internal_transfer" */
  insert_internal_transfer_one?: Maybe<Internal_Transfer>;
  /** insert data into the table: "internal_transfer_status" */
  insert_internal_transfer_status?: Maybe<Internal_Transfer_Status_Mutation_Response>;
  /** insert a single row into the table: "internal_transfer_status" */
  insert_internal_transfer_status_one?: Maybe<Internal_Transfer_Status>;
  /** insert data into the table: "location" */
  insert_location?: Maybe<Location_Mutation_Response>;
  /** insert a single row into the table: "location" */
  insert_location_one?: Maybe<Location>;
  /** insert data into the table: "location_type" */
  insert_location_type?: Maybe<Location_Type_Mutation_Response>;
  /** insert a single row into the table: "location_type" */
  insert_location_type_one?: Maybe<Location_Type>;
  /** insert data into the table: "logistics_type" */
  insert_logistics_type?: Maybe<Logistics_Type_Mutation_Response>;
  /** insert a single row into the table: "logistics_type" */
  insert_logistics_type_one?: Maybe<Logistics_Type>;
  /** insert data into the table: "organisation" */
  insert_organisation?: Maybe<Organisation_Mutation_Response>;
  /** insert a single row into the table: "organisation" */
  insert_organisation_one?: Maybe<Organisation>;
  /** insert data into the table: "outbound_manifest" */
  insert_outbound_manifest?: Maybe<Outbound_Manifest_Mutation_Response>;
  /** insert data into the table: "outbound_manifest_fulfilment" */
  insert_outbound_manifest_fulfilment?: Maybe<Outbound_Manifest_Fulfilment_Mutation_Response>;
  /** insert data into the table: "outbound_manifest_fulfilment_line" */
  insert_outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_fulfilment_line" */
  insert_outbound_manifest_fulfilment_line_one?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** insert data into the table: "outbound_manifest_fulfilment_line_status" */
  insert_outbound_manifest_fulfilment_line_status?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_fulfilment_line_status" */
  insert_outbound_manifest_fulfilment_line_status_one?: Maybe<Outbound_Manifest_Fulfilment_Line_Status>;
  /** insert a single row into the table: "outbound_manifest_fulfilment" */
  insert_outbound_manifest_fulfilment_one?: Maybe<Outbound_Manifest_Fulfilment>;
  /** insert data into the table: "outbound_manifest_fulfilment_status" */
  insert_outbound_manifest_fulfilment_status?: Maybe<Outbound_Manifest_Fulfilment_Status_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_fulfilment_status" */
  insert_outbound_manifest_fulfilment_status_one?: Maybe<Outbound_Manifest_Fulfilment_Status>;
  /** insert data into the table: "outbound_manifest_line" */
  insert_outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_line" */
  insert_outbound_manifest_line_one?: Maybe<Outbound_Manifest_Line>;
  /** insert data into the table: "outbound_manifest_line_status" */
  insert_outbound_manifest_line_status?: Maybe<Outbound_Manifest_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_line_status" */
  insert_outbound_manifest_line_status_one?: Maybe<Outbound_Manifest_Line_Status>;
  /** insert a single row into the table: "outbound_manifest" */
  insert_outbound_manifest_one?: Maybe<Outbound_Manifest>;
  /** insert data into the table: "outbound_manifest_return" */
  insert_outbound_manifest_return?: Maybe<Outbound_Manifest_Return_Mutation_Response>;
  /** insert data into the table: "outbound_manifest_return_line" */
  insert_outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_return_line" */
  insert_outbound_manifest_return_line_one?: Maybe<Outbound_Manifest_Return_Line>;
  /** insert a single row into the table: "outbound_manifest_return" */
  insert_outbound_manifest_return_one?: Maybe<Outbound_Manifest_Return>;
  /** insert data into the table: "outbound_manifest_status" */
  insert_outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_status" */
  insert_outbound_manifest_status_one?: Maybe<Outbound_Manifest_Status>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_stock" */
  insert_product_stock?: Maybe<Product_Stock_Mutation_Response>;
  /** insert a single row into the table: "product_stock" */
  insert_product_stock_one?: Maybe<Product_Stock>;
  /** insert data into the table: "recipe" */
  insert_recipe?: Maybe<Recipe_Mutation_Response>;
  /** insert data into the table: "recipe_input" */
  insert_recipe_input?: Maybe<Recipe_Input_Mutation_Response>;
  /** insert a single row into the table: "recipe_input" */
  insert_recipe_input_one?: Maybe<Recipe_Input>;
  /** insert a single row into the table: "recipe" */
  insert_recipe_one?: Maybe<Recipe>;
  /** insert data into the table: "recipe_output" */
  insert_recipe_output?: Maybe<Recipe_Output_Mutation_Response>;
  /** insert a single row into the table: "recipe_output" */
  insert_recipe_output_one?: Maybe<Recipe_Output>;
  /** insert data into the table: "recipe_type" */
  insert_recipe_type?: Maybe<Recipe_Type_Mutation_Response>;
  /** insert a single row into the table: "recipe_type" */
  insert_recipe_type_one?: Maybe<Recipe_Type>;
  /** insert data into the table: "service_account" */
  insert_service_account?: Maybe<Service_Account_Mutation_Response>;
  /** insert a single row into the table: "service_account" */
  insert_service_account_one?: Maybe<Service_Account>;
  /** insert data into the table: "stock_allocation" */
  insert_stock_allocation?: Maybe<Stock_Allocation_Mutation_Response>;
  /** insert data into the table: "stock_allocation_fulfilment" */
  insert_stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment_Mutation_Response>;
  /** insert a single row into the table: "stock_allocation_fulfilment" */
  insert_stock_allocation_fulfilment_one?: Maybe<Stock_Allocation_Fulfilment>;
  /** insert a single row into the table: "stock_allocation" */
  insert_stock_allocation_one?: Maybe<Stock_Allocation>;
  /** insert data into the table: "stock_check" */
  insert_stock_check?: Maybe<Stock_Check_Mutation_Response>;
  /** insert data into the table: "stock_check_line" */
  insert_stock_check_line?: Maybe<Stock_Check_Line_Mutation_Response>;
  /** insert data into the table: "stock_check_line_fulfilment" */
  insert_stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment_Mutation_Response>;
  /** insert a single row into the table: "stock_check_line_fulfilment" */
  insert_stock_check_line_fulfilment_one?: Maybe<Stock_Check_Line_Fulfilment>;
  /** insert data into the table: "stock_check_line_fulfilment_status" */
  insert_stock_check_line_fulfilment_status?: Maybe<Stock_Check_Line_Fulfilment_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_check_line_fulfilment_status" */
  insert_stock_check_line_fulfilment_status_one?: Maybe<Stock_Check_Line_Fulfilment_Status>;
  /** insert a single row into the table: "stock_check_line" */
  insert_stock_check_line_one?: Maybe<Stock_Check_Line>;
  /** insert data into the table: "stock_check_line_status" */
  insert_stock_check_line_status?: Maybe<Stock_Check_Line_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_check_line_status" */
  insert_stock_check_line_status_one?: Maybe<Stock_Check_Line_Status>;
  /** insert a single row into the table: "stock_check" */
  insert_stock_check_one?: Maybe<Stock_Check>;
  /** insert data into the table: "stock_check_status" */
  insert_stock_check_status?: Maybe<Stock_Check_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_check_status" */
  insert_stock_check_status_one?: Maybe<Stock_Check_Status>;
  /** insert data into the table: "stock_check_type" */
  insert_stock_check_type?: Maybe<Stock_Check_Type_Mutation_Response>;
  /** insert a single row into the table: "stock_check_type" */
  insert_stock_check_type_one?: Maybe<Stock_Check_Type>;
  /** insert data into the table: "stock_condition" */
  insert_stock_condition?: Maybe<Stock_Condition_Mutation_Response>;
  /** insert a single row into the table: "stock_condition" */
  insert_stock_condition_one?: Maybe<Stock_Condition>;
  /** insert data into the table: "stock_status" */
  insert_stock_status?: Maybe<Stock_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_status" */
  insert_stock_status_one?: Maybe<Stock_Status>;
  /** insert data into the table: "warehouse" */
  insert_warehouse?: Maybe<Warehouse_Mutation_Response>;
  /** insert a single row into the table: "warehouse" */
  insert_warehouse_one?: Maybe<Warehouse>;
  /** insert data into the table: "work_order" */
  insert_work_order?: Maybe<Work_Order_Mutation_Response>;
  /** insert data into the table: "work_order_input_line" */
  insert_work_order_input_line?: Maybe<Work_Order_Input_Line_Mutation_Response>;
  /** insert a single row into the table: "work_order_input_line" */
  insert_work_order_input_line_one?: Maybe<Work_Order_Input_Line>;
  /** insert a single row into the table: "work_order" */
  insert_work_order_one?: Maybe<Work_Order>;
  /** insert data into the table: "work_order_output_line" */
  insert_work_order_output_line?: Maybe<Work_Order_Output_Line_Mutation_Response>;
  /** insert a single row into the table: "work_order_output_line" */
  insert_work_order_output_line_one?: Maybe<Work_Order_Output_Line>;
  /** insert data into the table: "work_order_status" */
  insert_work_order_status?: Maybe<Work_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "work_order_status" */
  insert_work_order_status_one?: Maybe<Work_Order_Status>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "attachment" */
  update_attachment?: Maybe<Attachment_Mutation_Response>;
  /** update single row of the table: "attachment" */
  update_attachment_by_pk?: Maybe<Attachment>;
  /** update multiples rows of table: "attachment" */
  update_attachment_many?: Maybe<Array<Maybe<Attachment_Mutation_Response>>>;
  /** update data of the table: "attachment_type" */
  update_attachment_type?: Maybe<Attachment_Type_Mutation_Response>;
  /** update single row of the table: "attachment_type" */
  update_attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** update multiples rows of table: "attachment_type" */
  update_attachment_type_many?: Maybe<Array<Maybe<Attachment_Type_Mutation_Response>>>;
  /** update data of the table: "base_product" */
  update_base_product?: Maybe<Base_Product_Mutation_Response>;
  /** update multiples rows of table: "base_product" */
  update_base_product_many?: Maybe<Array<Maybe<Base_Product_Mutation_Response>>>;
  /** update data of the table: "case_change" */
  update_case_change?: Maybe<Case_Change_Mutation_Response>;
  /** update single row of the table: "case_change" */
  update_case_change_by_pk?: Maybe<Case_Change>;
  /** update data of the table: "case_change_input_line" */
  update_case_change_input_line?: Maybe<Case_Change_Input_Line_Mutation_Response>;
  /** update single row of the table: "case_change_input_line" */
  update_case_change_input_line_by_pk?: Maybe<Case_Change_Input_Line>;
  /** update multiples rows of table: "case_change_input_line" */
  update_case_change_input_line_many?: Maybe<
    Array<Maybe<Case_Change_Input_Line_Mutation_Response>>
  >;
  /** update multiples rows of table: "case_change" */
  update_case_change_many?: Maybe<Array<Maybe<Case_Change_Mutation_Response>>>;
  /** update data of the table: "case_change_output_line" */
  update_case_change_output_line?: Maybe<Case_Change_Output_Line_Mutation_Response>;
  /** update single row of the table: "case_change_output_line" */
  update_case_change_output_line_by_pk?: Maybe<Case_Change_Output_Line>;
  /** update multiples rows of table: "case_change_output_line" */
  update_case_change_output_line_many?: Maybe<
    Array<Maybe<Case_Change_Output_Line_Mutation_Response>>
  >;
  /** update data of the table: "case_change_status" */
  update_case_change_status?: Maybe<Case_Change_Status_Mutation_Response>;
  /** update single row of the table: "case_change_status" */
  update_case_change_status_by_pk?: Maybe<Case_Change_Status>;
  /** update multiples rows of table: "case_change_status" */
  update_case_change_status_many?: Maybe<Array<Maybe<Case_Change_Status_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "courier" */
  update_courier?: Maybe<Courier_Mutation_Response>;
  /** update single row of the table: "courier" */
  update_courier_by_pk?: Maybe<Courier>;
  /** update data of the table: "courier_location" */
  update_courier_location?: Maybe<Courier_Location_Mutation_Response>;
  /** update single row of the table: "courier_location" */
  update_courier_location_by_pk?: Maybe<Courier_Location>;
  /** update multiples rows of table: "courier_location" */
  update_courier_location_many?: Maybe<Array<Maybe<Courier_Location_Mutation_Response>>>;
  /** update multiples rows of table: "courier" */
  update_courier_many?: Maybe<Array<Maybe<Courier_Mutation_Response>>>;
  /** update data of the table: "courier_service" */
  update_courier_service?: Maybe<Courier_Service_Mutation_Response>;
  /** update single row of the table: "courier_service" */
  update_courier_service_by_pk?: Maybe<Courier_Service>;
  /** update multiples rows of table: "courier_service" */
  update_courier_service_many?: Maybe<Array<Maybe<Courier_Service_Mutation_Response>>>;
  /** update data of the table: "databasechangelog" */
  update_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** update multiples rows of table: "databasechangelog" */
  update_databasechangelog_many?: Maybe<Array<Maybe<Databasechangelog_Mutation_Response>>>;
  /** update data of the table: "databasechangeloglock" */
  update_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** update single row of the table: "databasechangeloglock" */
  update_databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** update multiples rows of table: "databasechangeloglock" */
  update_databasechangeloglock_many?: Maybe<Array<Maybe<Databasechangeloglock_Mutation_Response>>>;
  /** update data of the table: "file_copy_deletion" */
  update_file_copy_deletion?: Maybe<File_Copy_Deletion_Mutation_Response>;
  /** update single row of the table: "file_copy_deletion" */
  update_file_copy_deletion_by_pk?: Maybe<File_Copy_Deletion>;
  /** update multiples rows of table: "file_copy_deletion" */
  update_file_copy_deletion_many?: Maybe<Array<Maybe<File_Copy_Deletion_Mutation_Response>>>;
  /** update data of the table: "folder" */
  update_folder?: Maybe<Folder_Mutation_Response>;
  /** update single row of the table: "folder" */
  update_folder_by_pk?: Maybe<Folder>;
  /** update multiples rows of table: "folder" */
  update_folder_many?: Maybe<Array<Maybe<Folder_Mutation_Response>>>;
  /** update data of the table: "inbound_manifest" */
  update_inbound_manifest?: Maybe<Inbound_Manifest_Mutation_Response>;
  /** update single row of the table: "inbound_manifest" */
  update_inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** update data of the table: "inbound_manifest_fulfilment_line" */
  update_inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_fulfilment_line" */
  update_inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** update multiples rows of table: "inbound_manifest_fulfilment_line" */
  update_inbound_manifest_fulfilment_line_many?: Maybe<
    Array<Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>>
  >;
  /** update data of the table: "inbound_manifest_line" */
  update_inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_line" */
  update_inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** update multiples rows of table: "inbound_manifest_line" */
  update_inbound_manifest_line_many?: Maybe<Array<Maybe<Inbound_Manifest_Line_Mutation_Response>>>;
  /** update data of the table: "inbound_manifest_line_status" */
  update_inbound_manifest_line_status?: Maybe<Inbound_Manifest_Line_Status_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_line_status" */
  update_inbound_manifest_line_status_by_pk?: Maybe<Inbound_Manifest_Line_Status>;
  /** update multiples rows of table: "inbound_manifest_line_status" */
  update_inbound_manifest_line_status_many?: Maybe<
    Array<Maybe<Inbound_Manifest_Line_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "inbound_manifest" */
  update_inbound_manifest_many?: Maybe<Array<Maybe<Inbound_Manifest_Mutation_Response>>>;
  /** update data of the table: "inbound_manifest_status" */
  update_inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_status" */
  update_inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** update multiples rows of table: "inbound_manifest_status" */
  update_inbound_manifest_status_many?: Maybe<
    Array<Maybe<Inbound_Manifest_Status_Mutation_Response>>
  >;
  /** update data of the table: "internal_transfer" */
  update_internal_transfer?: Maybe<Internal_Transfer_Mutation_Response>;
  /** update single row of the table: "internal_transfer" */
  update_internal_transfer_by_pk?: Maybe<Internal_Transfer>;
  /** update data of the table: "internal_transfer_line" */
  update_internal_transfer_line?: Maybe<Internal_Transfer_Line_Mutation_Response>;
  /** update single row of the table: "internal_transfer_line" */
  update_internal_transfer_line_by_pk?: Maybe<Internal_Transfer_Line>;
  /** update multiples rows of table: "internal_transfer_line" */
  update_internal_transfer_line_many?: Maybe<
    Array<Maybe<Internal_Transfer_Line_Mutation_Response>>
  >;
  /** update data of the table: "internal_transfer_line_status" */
  update_internal_transfer_line_status?: Maybe<Internal_Transfer_Line_Status_Mutation_Response>;
  /** update single row of the table: "internal_transfer_line_status" */
  update_internal_transfer_line_status_by_pk?: Maybe<Internal_Transfer_Line_Status>;
  /** update multiples rows of table: "internal_transfer_line_status" */
  update_internal_transfer_line_status_many?: Maybe<
    Array<Maybe<Internal_Transfer_Line_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "internal_transfer" */
  update_internal_transfer_many?: Maybe<Array<Maybe<Internal_Transfer_Mutation_Response>>>;
  /** update data of the table: "internal_transfer_status" */
  update_internal_transfer_status?: Maybe<Internal_Transfer_Status_Mutation_Response>;
  /** update single row of the table: "internal_transfer_status" */
  update_internal_transfer_status_by_pk?: Maybe<Internal_Transfer_Status>;
  /** update multiples rows of table: "internal_transfer_status" */
  update_internal_transfer_status_many?: Maybe<
    Array<Maybe<Internal_Transfer_Status_Mutation_Response>>
  >;
  /** update data of the table: "location" */
  update_location?: Maybe<Location_Mutation_Response>;
  /** update single row of the table: "location" */
  update_location_by_pk?: Maybe<Location>;
  /** update multiples rows of table: "location" */
  update_location_many?: Maybe<Array<Maybe<Location_Mutation_Response>>>;
  /** update data of the table: "location_type" */
  update_location_type?: Maybe<Location_Type_Mutation_Response>;
  /** update single row of the table: "location_type" */
  update_location_type_by_pk?: Maybe<Location_Type>;
  /** update multiples rows of table: "location_type" */
  update_location_type_many?: Maybe<Array<Maybe<Location_Type_Mutation_Response>>>;
  /** update data of the table: "logistics_type" */
  update_logistics_type?: Maybe<Logistics_Type_Mutation_Response>;
  /** update single row of the table: "logistics_type" */
  update_logistics_type_by_pk?: Maybe<Logistics_Type>;
  /** update multiples rows of table: "logistics_type" */
  update_logistics_type_many?: Maybe<Array<Maybe<Logistics_Type_Mutation_Response>>>;
  /** update data of the table: "organisation" */
  update_organisation?: Maybe<Organisation_Mutation_Response>;
  /** update single row of the table: "organisation" */
  update_organisation_by_pk?: Maybe<Organisation>;
  /** update multiples rows of table: "organisation" */
  update_organisation_many?: Maybe<Array<Maybe<Organisation_Mutation_Response>>>;
  /** update data of the table: "outbound_manifest" */
  update_outbound_manifest?: Maybe<Outbound_Manifest_Mutation_Response>;
  /** update single row of the table: "outbound_manifest" */
  update_outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** update data of the table: "outbound_manifest_fulfilment" */
  update_outbound_manifest_fulfilment?: Maybe<Outbound_Manifest_Fulfilment_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_fulfilment" */
  update_outbound_manifest_fulfilment_by_pk?: Maybe<Outbound_Manifest_Fulfilment>;
  /** update data of the table: "outbound_manifest_fulfilment_line" */
  update_outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_fulfilment_line" */
  update_outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** update multiples rows of table: "outbound_manifest_fulfilment_line" */
  update_outbound_manifest_fulfilment_line_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>>
  >;
  /** update data of the table: "outbound_manifest_fulfilment_line_status" */
  update_outbound_manifest_fulfilment_line_status?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_fulfilment_line_status" */
  update_outbound_manifest_fulfilment_line_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line_Status>;
  /** update multiples rows of table: "outbound_manifest_fulfilment_line_status" */
  update_outbound_manifest_fulfilment_line_status_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Fulfilment_Line_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "outbound_manifest_fulfilment" */
  update_outbound_manifest_fulfilment_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Fulfilment_Mutation_Response>>
  >;
  /** update data of the table: "outbound_manifest_fulfilment_status" */
  update_outbound_manifest_fulfilment_status?: Maybe<Outbound_Manifest_Fulfilment_Status_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_fulfilment_status" */
  update_outbound_manifest_fulfilment_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Status>;
  /** update multiples rows of table: "outbound_manifest_fulfilment_status" */
  update_outbound_manifest_fulfilment_status_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Fulfilment_Status_Mutation_Response>>
  >;
  /** update data of the table: "outbound_manifest_line" */
  update_outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_line" */
  update_outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** update multiples rows of table: "outbound_manifest_line" */
  update_outbound_manifest_line_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Line_Mutation_Response>>
  >;
  /** update data of the table: "outbound_manifest_line_status" */
  update_outbound_manifest_line_status?: Maybe<Outbound_Manifest_Line_Status_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_line_status" */
  update_outbound_manifest_line_status_by_pk?: Maybe<Outbound_Manifest_Line_Status>;
  /** update multiples rows of table: "outbound_manifest_line_status" */
  update_outbound_manifest_line_status_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Line_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "outbound_manifest" */
  update_outbound_manifest_many?: Maybe<Array<Maybe<Outbound_Manifest_Mutation_Response>>>;
  /** update data of the table: "outbound_manifest_return" */
  update_outbound_manifest_return?: Maybe<Outbound_Manifest_Return_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_return" */
  update_outbound_manifest_return_by_pk?: Maybe<Outbound_Manifest_Return>;
  /** update data of the table: "outbound_manifest_return_line" */
  update_outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_return_line" */
  update_outbound_manifest_return_line_by_pk?: Maybe<Outbound_Manifest_Return_Line>;
  /** update multiples rows of table: "outbound_manifest_return_line" */
  update_outbound_manifest_return_line_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Return_Line_Mutation_Response>>
  >;
  /** update multiples rows of table: "outbound_manifest_return" */
  update_outbound_manifest_return_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Return_Mutation_Response>>
  >;
  /** update data of the table: "outbound_manifest_status" */
  update_outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_status" */
  update_outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** update multiples rows of table: "outbound_manifest_status" */
  update_outbound_manifest_status_many?: Maybe<
    Array<Maybe<Outbound_Manifest_Status_Mutation_Response>>
  >;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "product_stock" */
  update_product_stock?: Maybe<Product_Stock_Mutation_Response>;
  /** update single row of the table: "product_stock" */
  update_product_stock_by_pk?: Maybe<Product_Stock>;
  /** update multiples rows of table: "product_stock" */
  update_product_stock_many?: Maybe<Array<Maybe<Product_Stock_Mutation_Response>>>;
  /** update data of the table: "recipe" */
  update_recipe?: Maybe<Recipe_Mutation_Response>;
  /** update single row of the table: "recipe" */
  update_recipe_by_pk?: Maybe<Recipe>;
  /** update data of the table: "recipe_input" */
  update_recipe_input?: Maybe<Recipe_Input_Mutation_Response>;
  /** update single row of the table: "recipe_input" */
  update_recipe_input_by_pk?: Maybe<Recipe_Input>;
  /** update multiples rows of table: "recipe_input" */
  update_recipe_input_many?: Maybe<Array<Maybe<Recipe_Input_Mutation_Response>>>;
  /** update multiples rows of table: "recipe" */
  update_recipe_many?: Maybe<Array<Maybe<Recipe_Mutation_Response>>>;
  /** update data of the table: "recipe_output" */
  update_recipe_output?: Maybe<Recipe_Output_Mutation_Response>;
  /** update single row of the table: "recipe_output" */
  update_recipe_output_by_pk?: Maybe<Recipe_Output>;
  /** update multiples rows of table: "recipe_output" */
  update_recipe_output_many?: Maybe<Array<Maybe<Recipe_Output_Mutation_Response>>>;
  /** update data of the table: "recipe_type" */
  update_recipe_type?: Maybe<Recipe_Type_Mutation_Response>;
  /** update single row of the table: "recipe_type" */
  update_recipe_type_by_pk?: Maybe<Recipe_Type>;
  /** update multiples rows of table: "recipe_type" */
  update_recipe_type_many?: Maybe<Array<Maybe<Recipe_Type_Mutation_Response>>>;
  /** update data of the table: "service_account" */
  update_service_account?: Maybe<Service_Account_Mutation_Response>;
  /** update single row of the table: "service_account" */
  update_service_account_by_pk?: Maybe<Service_Account>;
  /** update multiples rows of table: "service_account" */
  update_service_account_many?: Maybe<Array<Maybe<Service_Account_Mutation_Response>>>;
  /** update data of the table: "stock_allocation" */
  update_stock_allocation?: Maybe<Stock_Allocation_Mutation_Response>;
  /** update single row of the table: "stock_allocation" */
  update_stock_allocation_by_pk?: Maybe<Stock_Allocation>;
  /** update data of the table: "stock_allocation_fulfilment" */
  update_stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment_Mutation_Response>;
  /** update single row of the table: "stock_allocation_fulfilment" */
  update_stock_allocation_fulfilment_by_pk?: Maybe<Stock_Allocation_Fulfilment>;
  /** update multiples rows of table: "stock_allocation_fulfilment" */
  update_stock_allocation_fulfilment_many?: Maybe<
    Array<Maybe<Stock_Allocation_Fulfilment_Mutation_Response>>
  >;
  /** update multiples rows of table: "stock_allocation" */
  update_stock_allocation_many?: Maybe<Array<Maybe<Stock_Allocation_Mutation_Response>>>;
  /** update data of the table: "stock_check" */
  update_stock_check?: Maybe<Stock_Check_Mutation_Response>;
  /** update single row of the table: "stock_check" */
  update_stock_check_by_pk?: Maybe<Stock_Check>;
  /** update data of the table: "stock_check_line" */
  update_stock_check_line?: Maybe<Stock_Check_Line_Mutation_Response>;
  /** update single row of the table: "stock_check_line" */
  update_stock_check_line_by_pk?: Maybe<Stock_Check_Line>;
  /** update data of the table: "stock_check_line_fulfilment" */
  update_stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment_Mutation_Response>;
  /** update single row of the table: "stock_check_line_fulfilment" */
  update_stock_check_line_fulfilment_by_pk?: Maybe<Stock_Check_Line_Fulfilment>;
  /** update multiples rows of table: "stock_check_line_fulfilment" */
  update_stock_check_line_fulfilment_many?: Maybe<
    Array<Maybe<Stock_Check_Line_Fulfilment_Mutation_Response>>
  >;
  /** update data of the table: "stock_check_line_fulfilment_status" */
  update_stock_check_line_fulfilment_status?: Maybe<Stock_Check_Line_Fulfilment_Status_Mutation_Response>;
  /** update single row of the table: "stock_check_line_fulfilment_status" */
  update_stock_check_line_fulfilment_status_by_pk?: Maybe<Stock_Check_Line_Fulfilment_Status>;
  /** update multiples rows of table: "stock_check_line_fulfilment_status" */
  update_stock_check_line_fulfilment_status_many?: Maybe<
    Array<Maybe<Stock_Check_Line_Fulfilment_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "stock_check_line" */
  update_stock_check_line_many?: Maybe<Array<Maybe<Stock_Check_Line_Mutation_Response>>>;
  /** update data of the table: "stock_check_line_status" */
  update_stock_check_line_status?: Maybe<Stock_Check_Line_Status_Mutation_Response>;
  /** update single row of the table: "stock_check_line_status" */
  update_stock_check_line_status_by_pk?: Maybe<Stock_Check_Line_Status>;
  /** update multiples rows of table: "stock_check_line_status" */
  update_stock_check_line_status_many?: Maybe<
    Array<Maybe<Stock_Check_Line_Status_Mutation_Response>>
  >;
  /** update multiples rows of table: "stock_check" */
  update_stock_check_many?: Maybe<Array<Maybe<Stock_Check_Mutation_Response>>>;
  /** update data of the table: "stock_check_status" */
  update_stock_check_status?: Maybe<Stock_Check_Status_Mutation_Response>;
  /** update single row of the table: "stock_check_status" */
  update_stock_check_status_by_pk?: Maybe<Stock_Check_Status>;
  /** update multiples rows of table: "stock_check_status" */
  update_stock_check_status_many?: Maybe<Array<Maybe<Stock_Check_Status_Mutation_Response>>>;
  /** update data of the table: "stock_check_type" */
  update_stock_check_type?: Maybe<Stock_Check_Type_Mutation_Response>;
  /** update single row of the table: "stock_check_type" */
  update_stock_check_type_by_pk?: Maybe<Stock_Check_Type>;
  /** update multiples rows of table: "stock_check_type" */
  update_stock_check_type_many?: Maybe<Array<Maybe<Stock_Check_Type_Mutation_Response>>>;
  /** update data of the table: "stock_condition" */
  update_stock_condition?: Maybe<Stock_Condition_Mutation_Response>;
  /** update single row of the table: "stock_condition" */
  update_stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** update multiples rows of table: "stock_condition" */
  update_stock_condition_many?: Maybe<Array<Maybe<Stock_Condition_Mutation_Response>>>;
  /** update data of the table: "stock_status" */
  update_stock_status?: Maybe<Stock_Status_Mutation_Response>;
  /** update single row of the table: "stock_status" */
  update_stock_status_by_pk?: Maybe<Stock_Status>;
  /** update multiples rows of table: "stock_status" */
  update_stock_status_many?: Maybe<Array<Maybe<Stock_Status_Mutation_Response>>>;
  /** update data of the table: "warehouse" */
  update_warehouse?: Maybe<Warehouse_Mutation_Response>;
  /** update single row of the table: "warehouse" */
  update_warehouse_by_pk?: Maybe<Warehouse>;
  /** update multiples rows of table: "warehouse" */
  update_warehouse_many?: Maybe<Array<Maybe<Warehouse_Mutation_Response>>>;
  /** update data of the table: "work_order" */
  update_work_order?: Maybe<Work_Order_Mutation_Response>;
  /** update single row of the table: "work_order" */
  update_work_order_by_pk?: Maybe<Work_Order>;
  /** update data of the table: "work_order_input_line" */
  update_work_order_input_line?: Maybe<Work_Order_Input_Line_Mutation_Response>;
  /** update single row of the table: "work_order_input_line" */
  update_work_order_input_line_by_pk?: Maybe<Work_Order_Input_Line>;
  /** update multiples rows of table: "work_order_input_line" */
  update_work_order_input_line_many?: Maybe<Array<Maybe<Work_Order_Input_Line_Mutation_Response>>>;
  /** update multiples rows of table: "work_order" */
  update_work_order_many?: Maybe<Array<Maybe<Work_Order_Mutation_Response>>>;
  /** update data of the table: "work_order_output_line" */
  update_work_order_output_line?: Maybe<Work_Order_Output_Line_Mutation_Response>;
  /** update single row of the table: "work_order_output_line" */
  update_work_order_output_line_by_pk?: Maybe<Work_Order_Output_Line>;
  /** update multiples rows of table: "work_order_output_line" */
  update_work_order_output_line_many?: Maybe<
    Array<Maybe<Work_Order_Output_Line_Mutation_Response>>
  >;
  /** update data of the table: "work_order_status" */
  update_work_order_status?: Maybe<Work_Order_Status_Mutation_Response>;
  /** update single row of the table: "work_order_status" */
  update_work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** update multiples rows of table: "work_order_status" */
  update_work_order_status_many?: Maybe<Array<Maybe<Work_Order_Status_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_AttachmentArgs = {
  where: Attachment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Attachment_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Attachment_TypeArgs = {
  where: Attachment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Attachment_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Base_ProductArgs = {
  where: Base_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_ChangeArgs = {
  where: Case_Change_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_Input_LineArgs = {
  where: Case_Change_Input_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_Input_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_Output_LineArgs = {
  where: Case_Change_Output_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_Output_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_StatusArgs = {
  where: Case_Change_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Case_Change_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  country_code: Scalars["String"];
};

/** mutation root */
export type Mutation_RootDelete_CourierArgs = {
  where: Courier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Courier_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Courier_LocationArgs = {
  where: Courier_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Courier_Location_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Courier_ServiceArgs = {
  where: Courier_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Courier_Service_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_DatabasechangelogArgs = {
  where: Databasechangelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_DatabasechangeloglockArgs = {
  where: Databasechangeloglock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Databasechangeloglock_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_File_Copy_DeletionArgs = {
  where: File_Copy_Deletion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_File_Copy_Deletion_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_FolderArgs = {
  where: Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Folder_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inbound_ManifestArgs = {
  where: Inbound_Manifest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Fulfilment_LineArgs = {
  where: Inbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_LineArgs = {
  where: Inbound_Manifest_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Line_StatusArgs = {
  where: Inbound_Manifest_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_StatusArgs = {
  where: Inbound_Manifest_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Internal_TransferArgs = {
  where: Internal_Transfer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_LineArgs = {
  where: Internal_Transfer_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_Line_StatusArgs = {
  where: Internal_Transfer_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_StatusArgs = {
  where: Internal_Transfer_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Internal_Transfer_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_LocationArgs = {
  where: Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Location_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Location_TypeArgs = {
  where: Location_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Location_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Logistics_TypeArgs = {
  where: Logistics_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Logistics_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_OrganisationArgs = {
  where: Organisation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Organisation_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_ManifestArgs = {
  where: Outbound_Manifest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_FulfilmentArgs = {
  where: Outbound_Manifest_Fulfilment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_LineArgs = {
  where: Outbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_Line_StatusArgs = {
  where: Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_StatusArgs = {
  where: Outbound_Manifest_Fulfilment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_LineArgs = {
  where: Outbound_Manifest_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Line_StatusArgs = {
  where: Outbound_Manifest_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_ReturnArgs = {
  where: Outbound_Manifest_Return_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Return_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Return_LineArgs = {
  where: Outbound_Manifest_Return_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Return_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_StatusArgs = {
  where: Outbound_Manifest_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Product_StockArgs = {
  where: Product_Stock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Stock_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_RecipeArgs = {
  where: Recipe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recipe_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Recipe_InputArgs = {
  where: Recipe_Input_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recipe_Input_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Recipe_OutputArgs = {
  where: Recipe_Output_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recipe_Output_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Recipe_TypeArgs = {
  where: Recipe_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Recipe_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Service_AccountArgs = {
  where: Service_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Service_Account_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_AllocationArgs = {
  where: Stock_Allocation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Allocation_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Allocation_FulfilmentArgs = {
  where: Stock_Allocation_Fulfilment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Allocation_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_CheckArgs = {
  where: Stock_Check_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_LineArgs = {
  where: Stock_Check_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_FulfilmentArgs = {
  where: Stock_Check_Line_Fulfilment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_Fulfilment_StatusArgs = {
  where: Stock_Check_Line_Fulfilment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_Fulfilment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_StatusArgs = {
  where: Stock_Check_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_StatusArgs = {
  where: Stock_Check_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_TypeArgs = {
  where: Stock_Check_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Check_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_ConditionArgs = {
  where: Stock_Condition_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Condition_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Stock_StatusArgs = {
  where: Stock_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Stock_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_WarehouseArgs = {
  where: Warehouse_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Warehouse_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Work_OrderArgs = {
  where: Work_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_Input_LineArgs = {
  where: Work_Order_Input_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_Input_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_Output_LineArgs = {
  where: Work_Order_Output_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_Output_Line_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_StatusArgs = {
  where: Work_Order_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Work_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_AttachmentArgs = {
  objects: Array<Attachment_Insert_Input>;
  on_conflict?: Maybe<Attachment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Attachment_OneArgs = {
  object: Attachment_Insert_Input;
  on_conflict?: Maybe<Attachment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Attachment_TypeArgs = {
  objects: Array<Attachment_Type_Insert_Input>;
  on_conflict?: Maybe<Attachment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Attachment_Type_OneArgs = {
  object: Attachment_Type_Insert_Input;
  on_conflict?: Maybe<Attachment_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Base_ProductArgs = {
  objects: Array<Base_Product_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Base_Product_OneArgs = {
  object: Base_Product_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_Case_ChangeArgs = {
  objects: Array<Case_Change_Insert_Input>;
  on_conflict?: Maybe<Case_Change_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_Input_LineArgs = {
  objects: Array<Case_Change_Input_Line_Insert_Input>;
  on_conflict?: Maybe<Case_Change_Input_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_Input_Line_OneArgs = {
  object: Case_Change_Input_Line_Insert_Input;
  on_conflict?: Maybe<Case_Change_Input_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_OneArgs = {
  object: Case_Change_Insert_Input;
  on_conflict?: Maybe<Case_Change_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_Output_LineArgs = {
  objects: Array<Case_Change_Output_Line_Insert_Input>;
  on_conflict?: Maybe<Case_Change_Output_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_Output_Line_OneArgs = {
  object: Case_Change_Output_Line_Insert_Input;
  on_conflict?: Maybe<Case_Change_Output_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_StatusArgs = {
  objects: Array<Case_Change_Status_Insert_Input>;
  on_conflict?: Maybe<Case_Change_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Case_Change_Status_OneArgs = {
  object: Case_Change_Status_Insert_Input;
  on_conflict?: Maybe<Case_Change_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: Maybe<Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: Maybe<Country_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CourierArgs = {
  objects: Array<Courier_Insert_Input>;
  on_conflict?: Maybe<Courier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courier_LocationArgs = {
  objects: Array<Courier_Location_Insert_Input>;
  on_conflict?: Maybe<Courier_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courier_Location_OneArgs = {
  object: Courier_Location_Insert_Input;
  on_conflict?: Maybe<Courier_Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courier_OneArgs = {
  object: Courier_Insert_Input;
  on_conflict?: Maybe<Courier_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courier_ServiceArgs = {
  objects: Array<Courier_Service_Insert_Input>;
  on_conflict?: Maybe<Courier_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Courier_Service_OneArgs = {
  object: Courier_Service_Insert_Input;
  on_conflict?: Maybe<Courier_Service_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_DatabasechangelogArgs = {
  objects: Array<Databasechangelog_Insert_Input>;
};

/** mutation root */
export type Mutation_RootInsert_Databasechangelog_OneArgs = {
  object: Databasechangelog_Insert_Input;
};

/** mutation root */
export type Mutation_RootInsert_DatabasechangeloglockArgs = {
  objects: Array<Databasechangeloglock_Insert_Input>;
  on_conflict?: Maybe<Databasechangeloglock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Databasechangeloglock_OneArgs = {
  object: Databasechangeloglock_Insert_Input;
  on_conflict?: Maybe<Databasechangeloglock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_File_Copy_DeletionArgs = {
  objects: Array<File_Copy_Deletion_Insert_Input>;
  on_conflict?: Maybe<File_Copy_Deletion_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_File_Copy_Deletion_OneArgs = {
  object: File_Copy_Deletion_Insert_Input;
  on_conflict?: Maybe<File_Copy_Deletion_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FolderArgs = {
  objects: Array<Folder_Insert_Input>;
  on_conflict?: Maybe<Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Folder_OneArgs = {
  object: Folder_Insert_Input;
  on_conflict?: Maybe<Folder_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_ManifestArgs = {
  objects: Array<Inbound_Manifest_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Fulfilment_LineArgs = {
  objects: Array<Inbound_Manifest_Fulfilment_Line_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Fulfilment_Line_OneArgs = {
  object: Inbound_Manifest_Fulfilment_Line_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_LineArgs = {
  objects: Array<Inbound_Manifest_Line_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Line_OneArgs = {
  object: Inbound_Manifest_Line_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Line_StatusArgs = {
  objects: Array<Inbound_Manifest_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Line_Status_OneArgs = {
  object: Inbound_Manifest_Line_Status_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_OneArgs = {
  object: Inbound_Manifest_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_StatusArgs = {
  objects: Array<Inbound_Manifest_Status_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Status_OneArgs = {
  object: Inbound_Manifest_Status_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_TransferArgs = {
  objects: Array<Internal_Transfer_Insert_Input>;
  on_conflict?: Maybe<Internal_Transfer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_LineArgs = {
  objects: Array<Internal_Transfer_Line_Insert_Input>;
  on_conflict?: Maybe<Internal_Transfer_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_Line_OneArgs = {
  object: Internal_Transfer_Line_Insert_Input;
  on_conflict?: Maybe<Internal_Transfer_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_Line_StatusArgs = {
  objects: Array<Internal_Transfer_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Internal_Transfer_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_Line_Status_OneArgs = {
  object: Internal_Transfer_Line_Status_Insert_Input;
  on_conflict?: Maybe<Internal_Transfer_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_OneArgs = {
  object: Internal_Transfer_Insert_Input;
  on_conflict?: Maybe<Internal_Transfer_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_StatusArgs = {
  objects: Array<Internal_Transfer_Status_Insert_Input>;
  on_conflict?: Maybe<Internal_Transfer_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Internal_Transfer_Status_OneArgs = {
  object: Internal_Transfer_Status_Insert_Input;
  on_conflict?: Maybe<Internal_Transfer_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationArgs = {
  objects: Array<Location_Insert_Input>;
  on_conflict?: Maybe<Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Location_OneArgs = {
  object: Location_Insert_Input;
  on_conflict?: Maybe<Location_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Location_TypeArgs = {
  objects: Array<Location_Type_Insert_Input>;
  on_conflict?: Maybe<Location_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Location_Type_OneArgs = {
  object: Location_Type_Insert_Input;
  on_conflict?: Maybe<Location_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Logistics_TypeArgs = {
  objects: Array<Logistics_Type_Insert_Input>;
  on_conflict?: Maybe<Logistics_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Logistics_Type_OneArgs = {
  object: Logistics_Type_Insert_Input;
  on_conflict?: Maybe<Logistics_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_OrganisationArgs = {
  objects: Array<Organisation_Insert_Input>;
  on_conflict?: Maybe<Organisation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Organisation_OneArgs = {
  object: Organisation_Insert_Input;
  on_conflict?: Maybe<Organisation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_ManifestArgs = {
  objects: Array<Outbound_Manifest_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_FulfilmentArgs = {
  objects: Array<Outbound_Manifest_Fulfilment_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_LineArgs = {
  objects: Array<Outbound_Manifest_Fulfilment_Line_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_Line_OneArgs = {
  object: Outbound_Manifest_Fulfilment_Line_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_Line_StatusArgs = {
  objects: Array<Outbound_Manifest_Fulfilment_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_Line_Status_OneArgs = {
  object: Outbound_Manifest_Fulfilment_Line_Status_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_OneArgs = {
  object: Outbound_Manifest_Fulfilment_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_StatusArgs = {
  objects: Array<Outbound_Manifest_Fulfilment_Status_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_Status_OneArgs = {
  object: Outbound_Manifest_Fulfilment_Status_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_LineArgs = {
  objects: Array<Outbound_Manifest_Line_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Line_OneArgs = {
  object: Outbound_Manifest_Line_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Line_StatusArgs = {
  objects: Array<Outbound_Manifest_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Line_Status_OneArgs = {
  object: Outbound_Manifest_Line_Status_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_OneArgs = {
  object: Outbound_Manifest_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_ReturnArgs = {
  objects: Array<Outbound_Manifest_Return_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Return_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Return_LineArgs = {
  objects: Array<Outbound_Manifest_Return_Line_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Return_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Return_Line_OneArgs = {
  object: Outbound_Manifest_Return_Line_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Return_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Return_OneArgs = {
  object: Outbound_Manifest_Return_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Return_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_StatusArgs = {
  objects: Array<Outbound_Manifest_Status_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Status_OneArgs = {
  object: Outbound_Manifest_Status_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_StockArgs = {
  objects: Array<Product_Stock_Insert_Input>;
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Stock_OneArgs = {
  object: Product_Stock_Insert_Input;
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_RecipeArgs = {
  objects: Array<Recipe_Insert_Input>;
  on_conflict?: Maybe<Recipe_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_InputArgs = {
  objects: Array<Recipe_Input_Insert_Input>;
  on_conflict?: Maybe<Recipe_Input_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_Input_OneArgs = {
  object: Recipe_Input_Insert_Input;
  on_conflict?: Maybe<Recipe_Input_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_OneArgs = {
  object: Recipe_Insert_Input;
  on_conflict?: Maybe<Recipe_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_OutputArgs = {
  objects: Array<Recipe_Output_Insert_Input>;
  on_conflict?: Maybe<Recipe_Output_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_Output_OneArgs = {
  object: Recipe_Output_Insert_Input;
  on_conflict?: Maybe<Recipe_Output_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_TypeArgs = {
  objects: Array<Recipe_Type_Insert_Input>;
  on_conflict?: Maybe<Recipe_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Recipe_Type_OneArgs = {
  object: Recipe_Type_Insert_Input;
  on_conflict?: Maybe<Recipe_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Service_AccountArgs = {
  objects: Array<Service_Account_Insert_Input>;
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Service_Account_OneArgs = {
  object: Service_Account_Insert_Input;
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_AllocationArgs = {
  objects: Array<Stock_Allocation_Insert_Input>;
  on_conflict?: Maybe<Stock_Allocation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Allocation_FulfilmentArgs = {
  objects: Array<Stock_Allocation_Fulfilment_Insert_Input>;
  on_conflict?: Maybe<Stock_Allocation_Fulfilment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Allocation_Fulfilment_OneArgs = {
  object: Stock_Allocation_Fulfilment_Insert_Input;
  on_conflict?: Maybe<Stock_Allocation_Fulfilment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Allocation_OneArgs = {
  object: Stock_Allocation_Insert_Input;
  on_conflict?: Maybe<Stock_Allocation_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_CheckArgs = {
  objects: Array<Stock_Check_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_LineArgs = {
  objects: Array<Stock_Check_Line_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_FulfilmentArgs = {
  objects: Array<Stock_Check_Line_Fulfilment_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_Fulfilment_OneArgs = {
  object: Stock_Check_Line_Fulfilment_Insert_Input;
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_Fulfilment_StatusArgs = {
  objects: Array<Stock_Check_Line_Fulfilment_Status_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_Fulfilment_Status_OneArgs = {
  object: Stock_Check_Line_Fulfilment_Status_Insert_Input;
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_OneArgs = {
  object: Stock_Check_Line_Insert_Input;
  on_conflict?: Maybe<Stock_Check_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_StatusArgs = {
  objects: Array<Stock_Check_Line_Status_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Line_Status_OneArgs = {
  object: Stock_Check_Line_Status_Insert_Input;
  on_conflict?: Maybe<Stock_Check_Line_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_OneArgs = {
  object: Stock_Check_Insert_Input;
  on_conflict?: Maybe<Stock_Check_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_StatusArgs = {
  objects: Array<Stock_Check_Status_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Status_OneArgs = {
  object: Stock_Check_Status_Insert_Input;
  on_conflict?: Maybe<Stock_Check_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_TypeArgs = {
  objects: Array<Stock_Check_Type_Insert_Input>;
  on_conflict?: Maybe<Stock_Check_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Check_Type_OneArgs = {
  object: Stock_Check_Type_Insert_Input;
  on_conflict?: Maybe<Stock_Check_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_ConditionArgs = {
  objects: Array<Stock_Condition_Insert_Input>;
  on_conflict?: Maybe<Stock_Condition_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Condition_OneArgs = {
  object: Stock_Condition_Insert_Input;
  on_conflict?: Maybe<Stock_Condition_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_StatusArgs = {
  objects: Array<Stock_Status_Insert_Input>;
  on_conflict?: Maybe<Stock_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Stock_Status_OneArgs = {
  object: Stock_Status_Insert_Input;
  on_conflict?: Maybe<Stock_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_WarehouseArgs = {
  objects: Array<Warehouse_Insert_Input>;
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Warehouse_OneArgs = {
  object: Warehouse_Insert_Input;
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_OrderArgs = {
  objects: Array<Work_Order_Insert_Input>;
  on_conflict?: Maybe<Work_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_Input_LineArgs = {
  objects: Array<Work_Order_Input_Line_Insert_Input>;
  on_conflict?: Maybe<Work_Order_Input_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_Input_Line_OneArgs = {
  object: Work_Order_Input_Line_Insert_Input;
  on_conflict?: Maybe<Work_Order_Input_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_OneArgs = {
  object: Work_Order_Insert_Input;
  on_conflict?: Maybe<Work_Order_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_Output_LineArgs = {
  objects: Array<Work_Order_Output_Line_Insert_Input>;
  on_conflict?: Maybe<Work_Order_Output_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_Output_Line_OneArgs = {
  object: Work_Order_Output_Line_Insert_Input;
  on_conflict?: Maybe<Work_Order_Output_Line_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_StatusArgs = {
  objects: Array<Work_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Work_Order_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Work_Order_Status_OneArgs = {
  object: Work_Order_Status_Insert_Input;
  on_conflict?: Maybe<Work_Order_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_AttachmentArgs = {
  _inc?: Maybe<Attachment_Inc_Input>;
  _set?: Maybe<Attachment_Set_Input>;
  where: Attachment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Attachment_By_PkArgs = {
  _inc?: Maybe<Attachment_Inc_Input>;
  _set?: Maybe<Attachment_Set_Input>;
  pk_columns: Attachment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Attachment_ManyArgs = {
  updates: Array<Attachment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Attachment_TypeArgs = {
  _inc?: Maybe<Attachment_Type_Inc_Input>;
  _set?: Maybe<Attachment_Type_Set_Input>;
  where: Attachment_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Attachment_Type_By_PkArgs = {
  _inc?: Maybe<Attachment_Type_Inc_Input>;
  _set?: Maybe<Attachment_Type_Set_Input>;
  pk_columns: Attachment_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Attachment_Type_ManyArgs = {
  updates: Array<Attachment_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Base_ProductArgs = {
  _inc?: Maybe<Base_Product_Inc_Input>;
  _set?: Maybe<Base_Product_Set_Input>;
  where: Base_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Base_Product_ManyArgs = {
  updates: Array<Base_Product_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_ChangeArgs = {
  _inc?: Maybe<Case_Change_Inc_Input>;
  _set?: Maybe<Case_Change_Set_Input>;
  where: Case_Change_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_By_PkArgs = {
  _inc?: Maybe<Case_Change_Inc_Input>;
  _set?: Maybe<Case_Change_Set_Input>;
  pk_columns: Case_Change_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Input_LineArgs = {
  _inc?: Maybe<Case_Change_Input_Line_Inc_Input>;
  _set?: Maybe<Case_Change_Input_Line_Set_Input>;
  where: Case_Change_Input_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Input_Line_By_PkArgs = {
  _inc?: Maybe<Case_Change_Input_Line_Inc_Input>;
  _set?: Maybe<Case_Change_Input_Line_Set_Input>;
  pk_columns: Case_Change_Input_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Input_Line_ManyArgs = {
  updates: Array<Case_Change_Input_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_ManyArgs = {
  updates: Array<Case_Change_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Output_LineArgs = {
  _inc?: Maybe<Case_Change_Output_Line_Inc_Input>;
  _set?: Maybe<Case_Change_Output_Line_Set_Input>;
  where: Case_Change_Output_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Output_Line_By_PkArgs = {
  _inc?: Maybe<Case_Change_Output_Line_Inc_Input>;
  _set?: Maybe<Case_Change_Output_Line_Set_Input>;
  pk_columns: Case_Change_Output_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Output_Line_ManyArgs = {
  updates: Array<Case_Change_Output_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_StatusArgs = {
  _inc?: Maybe<Case_Change_Status_Inc_Input>;
  _set?: Maybe<Case_Change_Status_Set_Input>;
  where: Case_Change_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Status_By_PkArgs = {
  _inc?: Maybe<Case_Change_Status_Inc_Input>;
  _set?: Maybe<Case_Change_Status_Set_Input>;
  pk_columns: Case_Change_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Case_Change_Status_ManyArgs = {
  updates: Array<Case_Change_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _set?: Maybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _set?: Maybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CourierArgs = {
  _set?: Maybe<Courier_Set_Input>;
  where: Courier_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_By_PkArgs = {
  _set?: Maybe<Courier_Set_Input>;
  pk_columns: Courier_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_LocationArgs = {
  _inc?: Maybe<Courier_Location_Inc_Input>;
  _set?: Maybe<Courier_Location_Set_Input>;
  where: Courier_Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_Location_By_PkArgs = {
  _inc?: Maybe<Courier_Location_Inc_Input>;
  _set?: Maybe<Courier_Location_Set_Input>;
  pk_columns: Courier_Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_Location_ManyArgs = {
  updates: Array<Courier_Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_ManyArgs = {
  updates: Array<Courier_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_ServiceArgs = {
  _inc?: Maybe<Courier_Service_Inc_Input>;
  _set?: Maybe<Courier_Service_Set_Input>;
  where: Courier_Service_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_Service_By_PkArgs = {
  _inc?: Maybe<Courier_Service_Inc_Input>;
  _set?: Maybe<Courier_Service_Set_Input>;
  pk_columns: Courier_Service_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Courier_Service_ManyArgs = {
  updates: Array<Courier_Service_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DatabasechangelogArgs = {
  _inc?: Maybe<Databasechangelog_Inc_Input>;
  _set?: Maybe<Databasechangelog_Set_Input>;
  where: Databasechangelog_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Databasechangelog_ManyArgs = {
  updates: Array<Databasechangelog_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_DatabasechangeloglockArgs = {
  _inc?: Maybe<Databasechangeloglock_Inc_Input>;
  _set?: Maybe<Databasechangeloglock_Set_Input>;
  where: Databasechangeloglock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Databasechangeloglock_By_PkArgs = {
  _inc?: Maybe<Databasechangeloglock_Inc_Input>;
  _set?: Maybe<Databasechangeloglock_Set_Input>;
  pk_columns: Databasechangeloglock_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Databasechangeloglock_ManyArgs = {
  updates: Array<Databasechangeloglock_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_File_Copy_DeletionArgs = {
  _inc?: Maybe<File_Copy_Deletion_Inc_Input>;
  _set?: Maybe<File_Copy_Deletion_Set_Input>;
  where: File_Copy_Deletion_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_File_Copy_Deletion_By_PkArgs = {
  _inc?: Maybe<File_Copy_Deletion_Inc_Input>;
  _set?: Maybe<File_Copy_Deletion_Set_Input>;
  pk_columns: File_Copy_Deletion_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_File_Copy_Deletion_ManyArgs = {
  updates: Array<File_Copy_Deletion_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FolderArgs = {
  _set?: Maybe<Folder_Set_Input>;
  where: Folder_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Folder_By_PkArgs = {
  _set?: Maybe<Folder_Set_Input>;
  pk_columns: Folder_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Folder_ManyArgs = {
  updates: Array<Folder_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_ManifestArgs = {
  _inc?: Maybe<Inbound_Manifest_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Set_Input>;
  where: Inbound_Manifest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Set_Input>;
  pk_columns: Inbound_Manifest_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Fulfilment_LineArgs = {
  _inc?: Maybe<Inbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Fulfilment_Line_Set_Input>;
  where: Inbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Fulfilment_Line_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Fulfilment_Line_Set_Input>;
  pk_columns: Inbound_Manifest_Fulfilment_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Fulfilment_Line_ManyArgs = {
  updates: Array<Inbound_Manifest_Fulfilment_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_LineArgs = {
  _inc?: Maybe<Inbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Line_Set_Input>;
  where: Inbound_Manifest_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Line_Set_Input>;
  pk_columns: Inbound_Manifest_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_ManyArgs = {
  updates: Array<Inbound_Manifest_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_StatusArgs = {
  _inc?: Maybe<Inbound_Manifest_Line_Status_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Line_Status_Set_Input>;
  where: Inbound_Manifest_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_Status_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Line_Status_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Line_Status_Set_Input>;
  pk_columns: Inbound_Manifest_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_Status_ManyArgs = {
  updates: Array<Inbound_Manifest_Line_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_ManyArgs = {
  updates: Array<Inbound_Manifest_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_StatusArgs = {
  _inc?: Maybe<Inbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Status_Set_Input>;
  where: Inbound_Manifest_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Status_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Status_Set_Input>;
  pk_columns: Inbound_Manifest_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Status_ManyArgs = {
  updates: Array<Inbound_Manifest_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_TransferArgs = {
  _inc?: Maybe<Internal_Transfer_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Set_Input>;
  where: Internal_Transfer_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_By_PkArgs = {
  _inc?: Maybe<Internal_Transfer_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Set_Input>;
  pk_columns: Internal_Transfer_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_LineArgs = {
  _inc?: Maybe<Internal_Transfer_Line_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Line_Set_Input>;
  where: Internal_Transfer_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Line_By_PkArgs = {
  _inc?: Maybe<Internal_Transfer_Line_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Line_Set_Input>;
  pk_columns: Internal_Transfer_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Line_ManyArgs = {
  updates: Array<Internal_Transfer_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Line_StatusArgs = {
  _inc?: Maybe<Internal_Transfer_Line_Status_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Line_Status_Set_Input>;
  where: Internal_Transfer_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Line_Status_By_PkArgs = {
  _inc?: Maybe<Internal_Transfer_Line_Status_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Line_Status_Set_Input>;
  pk_columns: Internal_Transfer_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Line_Status_ManyArgs = {
  updates: Array<Internal_Transfer_Line_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_ManyArgs = {
  updates: Array<Internal_Transfer_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_StatusArgs = {
  _inc?: Maybe<Internal_Transfer_Status_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Status_Set_Input>;
  where: Internal_Transfer_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Status_By_PkArgs = {
  _inc?: Maybe<Internal_Transfer_Status_Inc_Input>;
  _set?: Maybe<Internal_Transfer_Status_Set_Input>;
  pk_columns: Internal_Transfer_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Internal_Transfer_Status_ManyArgs = {
  updates: Array<Internal_Transfer_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_LocationArgs = {
  _append?: Maybe<Location_Append_Input>;
  _delete_at_path?: Maybe<Location_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Location_Delete_Elem_Input>;
  _delete_key?: Maybe<Location_Delete_Key_Input>;
  _inc?: Maybe<Location_Inc_Input>;
  _prepend?: Maybe<Location_Prepend_Input>;
  _set?: Maybe<Location_Set_Input>;
  where: Location_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Location_By_PkArgs = {
  _append?: Maybe<Location_Append_Input>;
  _delete_at_path?: Maybe<Location_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Location_Delete_Elem_Input>;
  _delete_key?: Maybe<Location_Delete_Key_Input>;
  _inc?: Maybe<Location_Inc_Input>;
  _prepend?: Maybe<Location_Prepend_Input>;
  _set?: Maybe<Location_Set_Input>;
  pk_columns: Location_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Location_ManyArgs = {
  updates: Array<Location_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Location_TypeArgs = {
  _inc?: Maybe<Location_Type_Inc_Input>;
  _set?: Maybe<Location_Type_Set_Input>;
  where: Location_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Location_Type_By_PkArgs = {
  _inc?: Maybe<Location_Type_Inc_Input>;
  _set?: Maybe<Location_Type_Set_Input>;
  pk_columns: Location_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Location_Type_ManyArgs = {
  updates: Array<Location_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Logistics_TypeArgs = {
  _inc?: Maybe<Logistics_Type_Inc_Input>;
  _set?: Maybe<Logistics_Type_Set_Input>;
  where: Logistics_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Logistics_Type_By_PkArgs = {
  _inc?: Maybe<Logistics_Type_Inc_Input>;
  _set?: Maybe<Logistics_Type_Set_Input>;
  pk_columns: Logistics_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Logistics_Type_ManyArgs = {
  updates: Array<Logistics_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_OrganisationArgs = {
  _inc?: Maybe<Organisation_Inc_Input>;
  _set?: Maybe<Organisation_Set_Input>;
  where: Organisation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_By_PkArgs = {
  _inc?: Maybe<Organisation_Inc_Input>;
  _set?: Maybe<Organisation_Set_Input>;
  pk_columns: Organisation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Organisation_ManyArgs = {
  updates: Array<Organisation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_ManifestArgs = {
  _inc?: Maybe<Outbound_Manifest_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Set_Input>;
  where: Outbound_Manifest_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Set_Input>;
  pk_columns: Outbound_Manifest_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_FulfilmentArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Set_Input>;
  where: Outbound_Manifest_Fulfilment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Set_Input>;
  pk_columns: Outbound_Manifest_Fulfilment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_LineArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Set_Input>;
  where: Outbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Set_Input>;
  pk_columns: Outbound_Manifest_Fulfilment_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_ManyArgs = {
  updates: Array<Outbound_Manifest_Fulfilment_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_StatusArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Set_Input>;
  where: Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_Status_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Set_Input>;
  pk_columns: Outbound_Manifest_Fulfilment_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_Status_ManyArgs = {
  updates: Array<Outbound_Manifest_Fulfilment_Line_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_ManyArgs = {
  updates: Array<Outbound_Manifest_Fulfilment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_StatusArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Status_Set_Input>;
  where: Outbound_Manifest_Fulfilment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Status_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Status_Set_Input>;
  pk_columns: Outbound_Manifest_Fulfilment_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Status_ManyArgs = {
  updates: Array<Outbound_Manifest_Fulfilment_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_LineArgs = {
  _inc?: Maybe<Outbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Line_Set_Input>;
  where: Outbound_Manifest_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Line_Set_Input>;
  pk_columns: Outbound_Manifest_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_ManyArgs = {
  updates: Array<Outbound_Manifest_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_StatusArgs = {
  _inc?: Maybe<Outbound_Manifest_Line_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Line_Status_Set_Input>;
  where: Outbound_Manifest_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_Status_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Line_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Line_Status_Set_Input>;
  pk_columns: Outbound_Manifest_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_Status_ManyArgs = {
  updates: Array<Outbound_Manifest_Line_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_ManyArgs = {
  updates: Array<Outbound_Manifest_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_ReturnArgs = {
  _inc?: Maybe<Outbound_Manifest_Return_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Return_Set_Input>;
  where: Outbound_Manifest_Return_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Return_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Return_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Return_Set_Input>;
  pk_columns: Outbound_Manifest_Return_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Return_LineArgs = {
  _inc?: Maybe<Outbound_Manifest_Return_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Return_Line_Set_Input>;
  where: Outbound_Manifest_Return_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Return_Line_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Return_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Return_Line_Set_Input>;
  pk_columns: Outbound_Manifest_Return_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Return_Line_ManyArgs = {
  updates: Array<Outbound_Manifest_Return_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Return_ManyArgs = {
  updates: Array<Outbound_Manifest_Return_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_StatusArgs = {
  _inc?: Maybe<Outbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Status_Set_Input>;
  where: Outbound_Manifest_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Status_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Status_Set_Input>;
  pk_columns: Outbound_Manifest_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Status_ManyArgs = {
  updates: Array<Outbound_Manifest_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_StockArgs = {
  _inc?: Maybe<Product_Stock_Inc_Input>;
  _set?: Maybe<Product_Stock_Set_Input>;
  where: Product_Stock_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Stock_By_PkArgs = {
  _inc?: Maybe<Product_Stock_Inc_Input>;
  _set?: Maybe<Product_Stock_Set_Input>;
  pk_columns: Product_Stock_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Stock_ManyArgs = {
  updates: Array<Product_Stock_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_RecipeArgs = {
  _inc?: Maybe<Recipe_Inc_Input>;
  _set?: Maybe<Recipe_Set_Input>;
  where: Recipe_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_By_PkArgs = {
  _inc?: Maybe<Recipe_Inc_Input>;
  _set?: Maybe<Recipe_Set_Input>;
  pk_columns: Recipe_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_InputArgs = {
  _inc?: Maybe<Recipe_Input_Inc_Input>;
  _set?: Maybe<Recipe_Input_Set_Input>;
  where: Recipe_Input_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Input_By_PkArgs = {
  _inc?: Maybe<Recipe_Input_Inc_Input>;
  _set?: Maybe<Recipe_Input_Set_Input>;
  pk_columns: Recipe_Input_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Input_ManyArgs = {
  updates: Array<Recipe_Input_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_ManyArgs = {
  updates: Array<Recipe_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_OutputArgs = {
  _inc?: Maybe<Recipe_Output_Inc_Input>;
  _set?: Maybe<Recipe_Output_Set_Input>;
  where: Recipe_Output_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Output_By_PkArgs = {
  _inc?: Maybe<Recipe_Output_Inc_Input>;
  _set?: Maybe<Recipe_Output_Set_Input>;
  pk_columns: Recipe_Output_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Output_ManyArgs = {
  updates: Array<Recipe_Output_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_TypeArgs = {
  _inc?: Maybe<Recipe_Type_Inc_Input>;
  _set?: Maybe<Recipe_Type_Set_Input>;
  where: Recipe_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Type_By_PkArgs = {
  _inc?: Maybe<Recipe_Type_Inc_Input>;
  _set?: Maybe<Recipe_Type_Set_Input>;
  pk_columns: Recipe_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Recipe_Type_ManyArgs = {
  updates: Array<Recipe_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Service_AccountArgs = {
  _inc?: Maybe<Service_Account_Inc_Input>;
  _set?: Maybe<Service_Account_Set_Input>;
  where: Service_Account_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Service_Account_By_PkArgs = {
  _inc?: Maybe<Service_Account_Inc_Input>;
  _set?: Maybe<Service_Account_Set_Input>;
  pk_columns: Service_Account_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Service_Account_ManyArgs = {
  updates: Array<Service_Account_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_AllocationArgs = {
  _inc?: Maybe<Stock_Allocation_Inc_Input>;
  _set?: Maybe<Stock_Allocation_Set_Input>;
  where: Stock_Allocation_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Allocation_By_PkArgs = {
  _inc?: Maybe<Stock_Allocation_Inc_Input>;
  _set?: Maybe<Stock_Allocation_Set_Input>;
  pk_columns: Stock_Allocation_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Allocation_FulfilmentArgs = {
  _inc?: Maybe<Stock_Allocation_Fulfilment_Inc_Input>;
  _set?: Maybe<Stock_Allocation_Fulfilment_Set_Input>;
  where: Stock_Allocation_Fulfilment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Allocation_Fulfilment_By_PkArgs = {
  _inc?: Maybe<Stock_Allocation_Fulfilment_Inc_Input>;
  _set?: Maybe<Stock_Allocation_Fulfilment_Set_Input>;
  pk_columns: Stock_Allocation_Fulfilment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Allocation_Fulfilment_ManyArgs = {
  updates: Array<Stock_Allocation_Fulfilment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Allocation_ManyArgs = {
  updates: Array<Stock_Allocation_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_CheckArgs = {
  _inc?: Maybe<Stock_Check_Inc_Input>;
  _set?: Maybe<Stock_Check_Set_Input>;
  where: Stock_Check_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Inc_Input>;
  _set?: Maybe<Stock_Check_Set_Input>;
  pk_columns: Stock_Check_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_LineArgs = {
  _inc?: Maybe<Stock_Check_Line_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Set_Input>;
  where: Stock_Check_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Line_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Set_Input>;
  pk_columns: Stock_Check_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_FulfilmentArgs = {
  _inc?: Maybe<Stock_Check_Line_Fulfilment_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Fulfilment_Set_Input>;
  where: Stock_Check_Line_Fulfilment_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Fulfilment_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Line_Fulfilment_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Fulfilment_Set_Input>;
  pk_columns: Stock_Check_Line_Fulfilment_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Fulfilment_ManyArgs = {
  updates: Array<Stock_Check_Line_Fulfilment_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Fulfilment_StatusArgs = {
  _inc?: Maybe<Stock_Check_Line_Fulfilment_Status_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Fulfilment_Status_Set_Input>;
  where: Stock_Check_Line_Fulfilment_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Fulfilment_Status_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Line_Fulfilment_Status_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Fulfilment_Status_Set_Input>;
  pk_columns: Stock_Check_Line_Fulfilment_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Fulfilment_Status_ManyArgs = {
  updates: Array<Stock_Check_Line_Fulfilment_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_ManyArgs = {
  updates: Array<Stock_Check_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_StatusArgs = {
  _inc?: Maybe<Stock_Check_Line_Status_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Status_Set_Input>;
  where: Stock_Check_Line_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Status_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Line_Status_Inc_Input>;
  _set?: Maybe<Stock_Check_Line_Status_Set_Input>;
  pk_columns: Stock_Check_Line_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Line_Status_ManyArgs = {
  updates: Array<Stock_Check_Line_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_ManyArgs = {
  updates: Array<Stock_Check_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_StatusArgs = {
  _inc?: Maybe<Stock_Check_Status_Inc_Input>;
  _set?: Maybe<Stock_Check_Status_Set_Input>;
  where: Stock_Check_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Status_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Status_Inc_Input>;
  _set?: Maybe<Stock_Check_Status_Set_Input>;
  pk_columns: Stock_Check_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Status_ManyArgs = {
  updates: Array<Stock_Check_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_TypeArgs = {
  _inc?: Maybe<Stock_Check_Type_Inc_Input>;
  _set?: Maybe<Stock_Check_Type_Set_Input>;
  where: Stock_Check_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Type_By_PkArgs = {
  _inc?: Maybe<Stock_Check_Type_Inc_Input>;
  _set?: Maybe<Stock_Check_Type_Set_Input>;
  pk_columns: Stock_Check_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Check_Type_ManyArgs = {
  updates: Array<Stock_Check_Type_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_ConditionArgs = {
  _inc?: Maybe<Stock_Condition_Inc_Input>;
  _set?: Maybe<Stock_Condition_Set_Input>;
  where: Stock_Condition_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Condition_By_PkArgs = {
  _inc?: Maybe<Stock_Condition_Inc_Input>;
  _set?: Maybe<Stock_Condition_Set_Input>;
  pk_columns: Stock_Condition_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Condition_ManyArgs = {
  updates: Array<Stock_Condition_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_StatusArgs = {
  _inc?: Maybe<Stock_Status_Inc_Input>;
  _set?: Maybe<Stock_Status_Set_Input>;
  where: Stock_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Status_By_PkArgs = {
  _inc?: Maybe<Stock_Status_Inc_Input>;
  _set?: Maybe<Stock_Status_Set_Input>;
  pk_columns: Stock_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Stock_Status_ManyArgs = {
  updates: Array<Stock_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_WarehouseArgs = {
  _inc?: Maybe<Warehouse_Inc_Input>;
  _set?: Maybe<Warehouse_Set_Input>;
  where: Warehouse_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Warehouse_By_PkArgs = {
  _inc?: Maybe<Warehouse_Inc_Input>;
  _set?: Maybe<Warehouse_Set_Input>;
  pk_columns: Warehouse_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Warehouse_ManyArgs = {
  updates: Array<Warehouse_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Work_OrderArgs = {
  _inc?: Maybe<Work_Order_Inc_Input>;
  _set?: Maybe<Work_Order_Set_Input>;
  where: Work_Order_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_By_PkArgs = {
  _inc?: Maybe<Work_Order_Inc_Input>;
  _set?: Maybe<Work_Order_Set_Input>;
  pk_columns: Work_Order_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Input_LineArgs = {
  _inc?: Maybe<Work_Order_Input_Line_Inc_Input>;
  _set?: Maybe<Work_Order_Input_Line_Set_Input>;
  where: Work_Order_Input_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Input_Line_By_PkArgs = {
  _inc?: Maybe<Work_Order_Input_Line_Inc_Input>;
  _set?: Maybe<Work_Order_Input_Line_Set_Input>;
  pk_columns: Work_Order_Input_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Input_Line_ManyArgs = {
  updates: Array<Work_Order_Input_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_ManyArgs = {
  updates: Array<Work_Order_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Output_LineArgs = {
  _inc?: Maybe<Work_Order_Output_Line_Inc_Input>;
  _set?: Maybe<Work_Order_Output_Line_Set_Input>;
  where: Work_Order_Output_Line_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Output_Line_By_PkArgs = {
  _inc?: Maybe<Work_Order_Output_Line_Inc_Input>;
  _set?: Maybe<Work_Order_Output_Line_Set_Input>;
  pk_columns: Work_Order_Output_Line_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Output_Line_ManyArgs = {
  updates: Array<Work_Order_Output_Line_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_StatusArgs = {
  _inc?: Maybe<Work_Order_Status_Inc_Input>;
  _set?: Maybe<Work_Order_Status_Set_Input>;
  where: Work_Order_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Status_By_PkArgs = {
  _inc?: Maybe<Work_Order_Status_Inc_Input>;
  _set?: Maybe<Work_Order_Status_Set_Input>;
  pk_columns: Work_Order_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Work_Order_Status_ManyArgs = {
  updates: Array<Work_Order_Status_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
  _eq?: Maybe<Scalars["numeric"]>;
  _gt?: Maybe<Scalars["numeric"]>;
  _gte?: Maybe<Scalars["numeric"]>;
  _in?: Maybe<Array<Scalars["numeric"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["numeric"]>;
  _lte?: Maybe<Scalars["numeric"]>;
  _neq?: Maybe<Scalars["numeric"]>;
  _nin?: Maybe<Array<Scalars["numeric"]>>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "organisation" */
export type Organisation = {
  __typename?: "organisation";
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  /** An array relationship */
  case_changes: Array<Case_Change>;
  /** An aggregate relationship */
  case_changes_aggregate: Case_Change_Aggregate;
  created_at: Scalars["timestamptz"];
  external_organisation_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  is_deleted: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  organisation_name: Scalars["String"];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars["Int"];
  /** An array relationship */
  stockAllocationsByToOrganisationId: Array<Stock_Allocation>;
  /** An aggregate relationship */
  stockAllocationsByToOrganisationId_aggregate: Stock_Allocation_Aggregate;
  /** An array relationship */
  stock_allocations: Array<Stock_Allocation>;
  /** An aggregate relationship */
  stock_allocations_aggregate: Stock_Allocation_Aggregate;
  /** An array relationship */
  warehouses: Array<Warehouse>;
  /** An aggregate relationship */
  warehouses_aggregate: Warehouse_Aggregate;
  /** An array relationship */
  work_orders: Array<Work_Order>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Order_Aggregate;
};

/** columns and relationships of "organisation" */
export type OrganisationAddressesArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationCase_ChangesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationCase_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationStockAllocationsByToOrganisationIdArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationStockAllocationsByToOrganisationId_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationStock_AllocationsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationStock_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationWarehousesArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationWarehouses_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationWork_OrdersArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** columns and relationships of "organisation" */
export type OrganisationWork_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** aggregated selection of "organisation" */
export type Organisation_Aggregate = {
  __typename?: "organisation_aggregate";
  aggregate?: Maybe<Organisation_Aggregate_Fields>;
  nodes: Array<Organisation>;
};

export type Organisation_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Organisation_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Organisation_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Organisation_Aggregate_Bool_Exp_Count>;
};

export type Organisation_Aggregate_Bool_Exp_Bool_And = {
  arguments: Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Organisation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Organisation_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Organisation_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Organisation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Organisation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organisation" */
export type Organisation_Aggregate_Fields = {
  __typename?: "organisation_aggregate_fields";
  avg?: Maybe<Organisation_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Organisation_Max_Fields>;
  min?: Maybe<Organisation_Min_Fields>;
  stddev?: Maybe<Organisation_Stddev_Fields>;
  stddev_pop?: Maybe<Organisation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organisation_Stddev_Samp_Fields>;
  sum?: Maybe<Organisation_Sum_Fields>;
  var_pop?: Maybe<Organisation_Var_Pop_Fields>;
  var_samp?: Maybe<Organisation_Var_Samp_Fields>;
  variance?: Maybe<Organisation_Variance_Fields>;
};

/** aggregate fields of "organisation" */
export type Organisation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organisation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "organisation" */
export type Organisation_Aggregate_Order_By = {
  avg?: Maybe<Organisation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Organisation_Max_Order_By>;
  min?: Maybe<Organisation_Min_Order_By>;
  stddev?: Maybe<Organisation_Stddev_Order_By>;
  stddev_pop?: Maybe<Organisation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Organisation_Stddev_Samp_Order_By>;
  sum?: Maybe<Organisation_Sum_Order_By>;
  var_pop?: Maybe<Organisation_Var_Pop_Order_By>;
  var_samp?: Maybe<Organisation_Var_Samp_Order_By>;
  variance?: Maybe<Organisation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organisation" */
export type Organisation_Arr_Rel_Insert_Input = {
  data: Array<Organisation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Organisation_On_Conflict>;
};

/** aggregate avg on columns */
export type Organisation_Avg_Fields = {
  __typename?: "organisation_avg_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "organisation" */
export type Organisation_Avg_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organisation". All fields are combined with a logical 'AND'. */
export type Organisation_Bool_Exp = {
  _and?: Maybe<Array<Organisation_Bool_Exp>>;
  _not?: Maybe<Organisation_Bool_Exp>;
  _or?: Maybe<Array<Organisation_Bool_Exp>>;
  addresses?: Maybe<Address_Bool_Exp>;
  addresses_aggregate?: Maybe<Address_Aggregate_Bool_Exp>;
  case_changes?: Maybe<Case_Change_Bool_Exp>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_organisation_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  organisation_name?: Maybe<String_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  stockAllocationsByToOrganisationId?: Maybe<Stock_Allocation_Bool_Exp>;
  stockAllocationsByToOrganisationId_aggregate?: Maybe<Stock_Allocation_Aggregate_Bool_Exp>;
  stock_allocations?: Maybe<Stock_Allocation_Bool_Exp>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Bool_Exp>;
  warehouses?: Maybe<Warehouse_Bool_Exp>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Bool_Exp>;
  work_orders?: Maybe<Work_Order_Bool_Exp>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organisation" */
export enum Organisation_Constraint {
  /** unique or primary key constraint on columns "organisation_name" */
  AccountAccountNameKey = "account_account_name_key",
  /** unique or primary key constraint on columns "id" */
  AccountPkey = "account_pkey",
}

/** input type for incrementing numeric columns in table "organisation" */
export type Organisation_Inc_Input = {
  external_organisation_id?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "organisation" */
export type Organisation_Insert_Input = {
  addresses?: Maybe<Address_Arr_Rel_Insert_Input>;
  case_changes?: Maybe<Case_Change_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_organisation_id?: Maybe<Scalars["Int"]>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_name?: Maybe<Scalars["String"]>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars["Int"]>;
  stockAllocationsByToOrganisationId?: Maybe<Stock_Allocation_Arr_Rel_Insert_Input>;
  stock_allocations?: Maybe<Stock_Allocation_Arr_Rel_Insert_Input>;
  warehouses?: Maybe<Warehouse_Arr_Rel_Insert_Input>;
  work_orders?: Maybe<Work_Order_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organisation_Max_Fields = {
  __typename?: "organisation_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "organisation" */
export type Organisation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organisation_Min_Fields = {
  __typename?: "organisation_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "organisation" */
export type Organisation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "organisation" */
export type Organisation_Mutation_Response = {
  __typename?: "organisation_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation>;
};

/** input type for inserting object relation for remote table "organisation" */
export type Organisation_Obj_Rel_Insert_Input = {
  data: Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Organisation_On_Conflict>;
};

/** on_conflict condition type for table "organisation" */
export type Organisation_On_Conflict = {
  constraint: Organisation_Constraint;
  update_columns?: Array<Organisation_Update_Column>;
  where?: Maybe<Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation". */
export type Organisation_Order_By = {
  addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  is_deleted?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_name?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  stockAllocationsByToOrganisationId_aggregate?: Maybe<Stock_Allocation_Aggregate_Order_By>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Order_By>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Order_By>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Order_By>;
};

/** primary key columns input for table: organisation */
export type Organisation_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "organisation" */
export enum Organisation_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalOrganisationId = "external_organisation_id",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationName = "organisation_name",
  /** column name */
  ServiceAccountId = "service_account_id",
}

/** select "organisation_aggregate_bool_exp_bool_and_arguments_columns" columns of table "organisation" */
export enum Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = "is_deleted",
}

/** select "organisation_aggregate_bool_exp_bool_or_arguments_columns" columns of table "organisation" */
export enum Organisation_Select_Column_Organisation_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = "is_deleted",
}

/** input type for updating data in table "organisation" */
export type Organisation_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_organisation_id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Organisation_Stddev_Fields = {
  __typename?: "organisation_stddev_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "organisation" */
export type Organisation_Stddev_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organisation_Stddev_Pop_Fields = {
  __typename?: "organisation_stddev_pop_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "organisation" */
export type Organisation_Stddev_Pop_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organisation_Stddev_Samp_Fields = {
  __typename?: "organisation_stddev_samp_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "organisation" */
export type Organisation_Stddev_Samp_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "organisation" */
export type Organisation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Organisation_Sum_Fields = {
  __typename?: "organisation_sum_fields";
  external_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "organisation" */
export type Organisation_Sum_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** update columns of table "organisation" */
export enum Organisation_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalOrganisationId = "external_organisation_id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationName = "organisation_name",
  /** column name */
  ServiceAccountId = "service_account_id",
}

export type Organisation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Organisation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organisation_Var_Pop_Fields = {
  __typename?: "organisation_var_pop_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "organisation" */
export type Organisation_Var_Pop_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organisation_Var_Samp_Fields = {
  __typename?: "organisation_var_samp_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "organisation" */
export type Organisation_Var_Samp_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Organisation_Variance_Fields = {
  __typename?: "organisation_variance_fields";
  external_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "organisation" */
export type Organisation_Variance_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_Manifest = {
  __typename?: "outbound_manifest";
  /** An object relationship */
  courier_service: Courier_Service;
  courier_service_id: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  expected_dispatch: Scalars["timestamptz"];
  external_order_id: Scalars["Int"];
  /** An object relationship */
  folder?: Maybe<Folder>;
  folder_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  logistics_type: Logistics_Type;
  logistics_type_id: Scalars["Int"];
  notes: Scalars["String"];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars["Int"];
  /** An array relationship */
  outbound_manifest_fulfilments: Array<Outbound_Manifest_Fulfilment>;
  /** An aggregate relationship */
  outbound_manifest_fulfilments_aggregate: Outbound_Manifest_Fulfilment_Aggregate;
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
  /** An array relationship */
  outbound_manifest_returns: Array<Outbound_Manifest_Return>;
  /** An aggregate relationship */
  outbound_manifest_returns_aggregate: Outbound_Manifest_Return_Aggregate;
  /** An object relationship */
  outbound_manifest_status: Outbound_Manifest_Status;
  outbound_manifest_status_id: Scalars["Int"];
  reference: Scalars["String"];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars["Int"];
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_ReturnsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_Returns_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest" */
export type Outbound_Manifest_Aggregate = {
  __typename?: "outbound_manifest_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest>;
};

export type Outbound_Manifest_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest" */
export type Outbound_Manifest_Aggregate_Fields = {
  __typename?: "outbound_manifest_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest" */
export type Outbound_Manifest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "outbound_manifest" */
export type Outbound_Manifest_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest" */
export type Outbound_Manifest_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Avg_Fields = {
  __typename?: "outbound_manifest_avg_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Avg_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Bool_Exp>>;
  courier_service?: Maybe<Courier_Service_Bool_Exp>;
  courier_service_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_dispatch?: Maybe<Timestamptz_Comparison_Exp>;
  external_order_id?: Maybe<Int_Comparison_Exp>;
  folder?: Maybe<Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  logistics_type?: Maybe<Logistics_Type_Bool_Exp>;
  logistics_type_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_fulfilments?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
  outbound_manifest_fulfilments_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Aggregate_Bool_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
  outbound_manifest_returns?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
  outbound_manifest_returns_aggregate?: Maybe<Outbound_Manifest_Return_Aggregate_Bool_Exp>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
  outbound_manifest_status_id?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest" */
export enum Outbound_Manifest_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestPkey = "outbound_manifest_pkey",
  /** unique or primary key constraint on columns "reference" */
  OutboundManifestReferenceKey = "outbound_manifest_reference_key",
}

/** columns and relationships of "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment = {
  __typename?: "outbound_manifest_fulfilment";
  cancelled_date?: Maybe<Scalars["timestamptz"]>;
  created_at: Scalars["timestamptz"];
  dispatched_date?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  outbound_manifest: Outbound_Manifest;
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** An object relationship */
  outbound_manifest_fulfilment_status: Outbound_Manifest_Fulfilment_Status;
  outbound_manifest_fulfilment_status_id: Scalars["Int"];
  outbound_manifest_id: Scalars["Int"];
};

/** columns and relationships of "outbound_manifest_fulfilment" */
export type Outbound_Manifest_FulfilmentOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_fulfilment" */
export type Outbound_Manifest_FulfilmentOutbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Aggregate = {
  __typename?: "outbound_manifest_fulfilment_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Fulfilment_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Fulfilment>;
};

export type Outbound_Manifest_Fulfilment_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Fulfilment_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Fulfilment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Aggregate_Fields = {
  __typename?: "outbound_manifest_fulfilment_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Fulfilment_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Fulfilment_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Fulfilment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Fulfilment_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Fulfilment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Fulfilment_Avg_Fields = {
  __typename?: "outbound_manifest_fulfilment_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Avg_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_fulfilment". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Fulfilment_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Fulfilment_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Fulfilment_Bool_Exp>>;
  cancelled_date?: Maybe<Timestamptz_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dispatched_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  outbound_manifest_fulfilment_status?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
  outbound_manifest_fulfilment_status_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_fulfilment" */
export enum Outbound_Manifest_Fulfilment_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestFulfilmentPkey = "outbound_manifest_fulfilment_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Inc_Input = {
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Insert_Input = {
  cancelled_date?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_date?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest?: Maybe<Outbound_Manifest_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_fulfilment_status?: Maybe<Outbound_Manifest_Fulfilment_Status_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line = {
  __typename?: "outbound_manifest_fulfilment_line";
  created_at: Scalars["timestamptz"];
  fulfil_from_product_stock_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  outbound_manifest_fulfilment: Outbound_Manifest_Fulfilment;
  outbound_manifest_fulfilment_id: Scalars["Int"];
  /** An object relationship */
  outbound_manifest_fulfilment_line_status: Outbound_Manifest_Fulfilment_Line_Status;
  outbound_manifest_fulfilment_line_status_id: Scalars["Int"];
  /** An object relationship */
  outbound_manifest_line: Outbound_Manifest_Line;
  outbound_manifest_line_id: Scalars["Int"];
  /** An array relationship */
  outbound_manifest_return_lines: Array<Outbound_Manifest_Return_Line>;
  /** An aggregate relationship */
  outbound_manifest_return_lines_aggregate: Outbound_Manifest_Return_Line_Aggregate;
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  /** An object relationship */
  product_stock: Product_Stock;
  quantity: Scalars["Int"];
  returned_quantity: Scalars["Int"];
};

/** columns and relationships of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_LineOutbound_Manifest_Return_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_LineOutbound_Manifest_Return_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate = {
  __typename?: "outbound_manifest_fulfilment_line_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Fulfilment_Line>;
};

export type Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Fulfilment_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Fulfilment_Line_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Line_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Line_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Line_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Fulfilment_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Fulfilment_Line_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Line_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Line_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Fulfilment_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Fulfilment_Line_Avg_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_avg_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Avg_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_fulfilment_line". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Fulfilment_Line_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  fulfil_from_product_stock_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest_fulfilment?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
  outbound_manifest_fulfilment_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_fulfilment_line_status?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_line_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_return_lines?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
  outbound_manifest_return_lines_aggregate?: Maybe<Outbound_Manifest_Return_Line_Aggregate_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  returned_quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_fulfilment_line" */
export enum Outbound_Manifest_Fulfilment_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestFulfilmentLinePkey = "outbound_manifest_fulfilment_line_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Inc_Input = {
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment?: Maybe<Outbound_Manifest_Fulfilment_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Obj_Rel_Insert_Input>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_lines?: Maybe<Outbound_Manifest_Return_Line_Arr_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Fulfilment_Line_Max_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Fulfilment_Line_Min_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Mutation_Response = {
  __typename?: "outbound_manifest_fulfilment_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Fulfilment_Line>;
};

/** input type for inserting object relation for remote table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Fulfilment_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_On_Conflict = {
  constraint: Outbound_Manifest_Fulfilment_Line_Constraint;
  update_columns?: Array<Outbound_Manifest_Fulfilment_Line_Update_Column>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_fulfilment_line". */
export type Outbound_Manifest_Fulfilment_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment?: Maybe<Outbound_Manifest_Fulfilment_Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  outbound_manifest_return_lines_aggregate?: Maybe<Outbound_Manifest_Return_Line_Aggregate_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_fulfilment_line */
export type Outbound_Manifest_Fulfilment_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_fulfilment_line" */
export enum Outbound_Manifest_Fulfilment_Line_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FulfilFromProductStockId = "fulfil_from_product_stock_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentId = "outbound_manifest_fulfilment_id",
  /** column name */
  OutboundManifestFulfilmentLineStatusId = "outbound_manifest_fulfilment_line_status_id",
  /** column name */
  OutboundManifestLineId = "outbound_manifest_line_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReturnedQuantity = "returned_quantity",
}

/** input type for updating data in table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status = {
  __typename?: "outbound_manifest_fulfilment_line_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  outbound_manifest_fulfilment_line_status_name: Scalars["String"];
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
};

/** columns and relationships of "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_StatusOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_StatusOutbound_Manifest_Fulfilment_Lines_AggregateArgs =
  {
    distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
    limit?: Maybe<Scalars["Int"]>;
    offset?: Maybe<Scalars["Int"]>;
    order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
    where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  };

/** aggregated selection of "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Aggregate = {
  __typename?: "outbound_manifest_fulfilment_line_status_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Fulfilment_Line_Status>;
};

/** aggregate fields of "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Aggregate_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Avg_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_fulfilment_line_status". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<String_Comparison_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_fulfilment_line_status" */
export enum Outbound_Manifest_Fulfilment_Line_Status_Constraint {
  /** unique or primary key constraint on columns "outbound_manifest_fulfilment_line_status_name" */
  OutboundManifestFulfilmentOutboundManifestFulfilmentKey1 = "outbound_manifest_fulfilment__outbound_manifest_fulfilment_key1",
  /** unique or primary key constraint on columns "id" */
  OutboundManifestFulfilmentLineStatusPkey = "outbound_manifest_fulfilment_line_status_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<Scalars["String"]>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Max_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Min_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Mutation_Response = {
  __typename?: "outbound_manifest_fulfilment_line_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Fulfilment_Line_Status>;
};

/** input type for inserting object relation for remote table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Fulfilment_Line_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_On_Conflict = {
  constraint: Outbound_Manifest_Fulfilment_Line_Status_Constraint;
  update_columns?: Array<Outbound_Manifest_Fulfilment_Line_Status_Update_Column>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_fulfilment_line_status". */
export type Outbound_Manifest_Fulfilment_Line_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
};

/** primary key columns input for table: outbound_manifest_fulfilment_line_status */
export type Outbound_Manifest_Fulfilment_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_fulfilment_line_status" */
export enum Outbound_Manifest_Fulfilment_Line_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentLineStatusName = "outbound_manifest_fulfilment_line_status_name",
}

/** input type for updating data in table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Stddev_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "outbound_manifest_fulfilment_line_status" */
export type Outbound_Manifest_Fulfilment_Line_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Fulfilment_Line_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Fulfilment_Line_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Sum_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "outbound_manifest_fulfilment_line_status" */
export enum Outbound_Manifest_Fulfilment_Line_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentLineStatusName = "outbound_manifest_fulfilment_line_status_name",
}

export type Outbound_Manifest_Fulfilment_Line_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Var_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Var_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Fulfilment_Line_Status_Variance_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_stddev_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_stddev_pop_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_stddev_samp_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Fulfilment_Line_Sum_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_sum_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Sum_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_fulfilment_line" */
export enum Outbound_Manifest_Fulfilment_Line_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  FulfilFromProductStockId = "fulfil_from_product_stock_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentId = "outbound_manifest_fulfilment_id",
  /** column name */
  OutboundManifestFulfilmentLineStatusId = "outbound_manifest_fulfilment_line_status_id",
  /** column name */
  OutboundManifestLineId = "outbound_manifest_line_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReturnedQuantity = "returned_quantity",
}

export type Outbound_Manifest_Fulfilment_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Fulfilment_Line_Var_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_var_pop_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Var_Pop_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Fulfilment_Line_Var_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_var_samp_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Var_Samp_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Fulfilment_Line_Variance_Fields = {
  __typename?: "outbound_manifest_fulfilment_line_variance_fields";
  fulfil_from_product_stock_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Variance_Order_By = {
  fulfil_from_product_stock_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_status_id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Fulfilment_Max_Fields = {
  __typename?: "outbound_manifest_fulfilment_max_fields";
  cancelled_date?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Max_Order_By = {
  cancelled_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Fulfilment_Min_Fields = {
  __typename?: "outbound_manifest_fulfilment_min_fields";
  cancelled_date?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Min_Order_By = {
  cancelled_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Mutation_Response = {
  __typename?: "outbound_manifest_fulfilment_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Fulfilment>;
};

/** input type for inserting object relation for remote table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Fulfilment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_On_Conflict = {
  constraint: Outbound_Manifest_Fulfilment_Constraint;
  update_columns?: Array<Outbound_Manifest_Fulfilment_Update_Column>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_fulfilment". */
export type Outbound_Manifest_Fulfilment_Order_By = {
  cancelled_date?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest?: Maybe<Outbound_Manifest_Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  outbound_manifest_fulfilment_status?: Maybe<Outbound_Manifest_Fulfilment_Status_Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_fulfilment */
export type Outbound_Manifest_Fulfilment_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_fulfilment" */
export enum Outbound_Manifest_Fulfilment_Select_Column {
  /** column name */
  CancelledDate = "cancelled_date",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DispatchedDate = "dispatched_date",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentStatusId = "outbound_manifest_fulfilment_status_id",
  /** column name */
  OutboundManifestId = "outbound_manifest_id",
}

/** input type for updating data in table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Set_Input = {
  cancelled_date?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_date?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status = {
  __typename?: "outbound_manifest_fulfilment_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  outbound_manifest_fulfilment_status_name: Scalars["String"];
  /** An array relationship */
  outbound_manifest_fulfilments: Array<Outbound_Manifest_Fulfilment>;
  /** An aggregate relationship */
  outbound_manifest_fulfilments_aggregate: Outbound_Manifest_Fulfilment_Aggregate;
};

/** columns and relationships of "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_StatusOutbound_Manifest_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_StatusOutbound_Manifest_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Aggregate = {
  __typename?: "outbound_manifest_fulfilment_status_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Fulfilment_Status_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Fulfilment_Status>;
};

/** aggregate fields of "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Aggregate_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Fulfilment_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Fulfilment_Status_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Status_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Status_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Status_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Status_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Fulfilment_Status_Avg_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_fulfilment_status". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Fulfilment_Status_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest_fulfilment_status_name?: Maybe<String_Comparison_Exp>;
  outbound_manifest_fulfilments?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
  outbound_manifest_fulfilments_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_fulfilment_status" */
export enum Outbound_Manifest_Fulfilment_Status_Constraint {
  /** unique or primary key constraint on columns "outbound_manifest_fulfilment_status_name" */
  OutboundManifestFulfilmentOutboundManifestFulfilmentKey = "outbound_manifest_fulfilment__outbound_manifest_fulfilment__key",
  /** unique or primary key constraint on columns "id" */
  OutboundManifestFulfilmentStatusPkey = "outbound_manifest_fulfilment_status_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_name?: Maybe<Scalars["String"]>;
  outbound_manifest_fulfilments?: Maybe<Outbound_Manifest_Fulfilment_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Fulfilment_Status_Max_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Fulfilment_Status_Min_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Mutation_Response = {
  __typename?: "outbound_manifest_fulfilment_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Fulfilment_Status>;
};

/** input type for inserting object relation for remote table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Fulfilment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Status_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_On_Conflict = {
  constraint: Outbound_Manifest_Fulfilment_Status_Constraint;
  update_columns?: Array<Outbound_Manifest_Fulfilment_Status_Update_Column>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_fulfilment_status". */
export type Outbound_Manifest_Fulfilment_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_name?: Maybe<Order_By>;
  outbound_manifest_fulfilments_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Aggregate_Order_By>;
};

/** primary key columns input for table: outbound_manifest_fulfilment_status */
export type Outbound_Manifest_Fulfilment_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_fulfilment_status" */
export enum Outbound_Manifest_Fulfilment_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentStatusName = "outbound_manifest_fulfilment_status_name",
}

/** input type for updating data in table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Fulfilment_Status_Stddev_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Fulfilment_Status_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Fulfilment_Status_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "outbound_manifest_fulfilment_status" */
export type Outbound_Manifest_Fulfilment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Fulfilment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Fulfilment_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Fulfilment_Status_Sum_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "outbound_manifest_fulfilment_status" */
export enum Outbound_Manifest_Fulfilment_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentStatusName = "outbound_manifest_fulfilment_status_name",
}

export type Outbound_Manifest_Fulfilment_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Fulfilment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Fulfilment_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Fulfilment_Status_Var_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Fulfilment_Status_Var_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Fulfilment_Status_Variance_Fields = {
  __typename?: "outbound_manifest_fulfilment_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Fulfilment_Stddev_Fields = {
  __typename?: "outbound_manifest_fulfilment_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Fulfilment_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Fulfilment_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Fulfilment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Fulfilment_Stream_Cursor_Value_Input = {
  cancelled_date?: Maybe<Scalars["timestamptz"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Fulfilment_Sum_Fields = {
  __typename?: "outbound_manifest_fulfilment_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Sum_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_fulfilment" */
export enum Outbound_Manifest_Fulfilment_Update_Column {
  /** column name */
  CancelledDate = "cancelled_date",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DispatchedDate = "dispatched_date",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentStatusId = "outbound_manifest_fulfilment_status_id",
  /** column name */
  OutboundManifestId = "outbound_manifest_id",
}

export type Outbound_Manifest_Fulfilment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Fulfilment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Fulfilment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Fulfilment_Var_Pop_Fields = {
  __typename?: "outbound_manifest_fulfilment_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Fulfilment_Var_Samp_Fields = {
  __typename?: "outbound_manifest_fulfilment_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Fulfilment_Variance_Fields = {
  __typename?: "outbound_manifest_fulfilment_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "outbound_manifest_fulfilment" */
export type Outbound_Manifest_Fulfilment_Variance_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_status_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "outbound_manifest" */
export type Outbound_Manifest_Inc_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest" */
export type Outbound_Manifest_Insert_Input = {
  courier_service?: Maybe<Courier_Service_Obj_Rel_Insert_Input>;
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_dispatch?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder?: Maybe<Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type?: Maybe<Logistics_Type_Obj_Rel_Insert_Input>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilments?: Maybe<Outbound_Manifest_Fulfilment_Arr_Rel_Insert_Input>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_returns?: Maybe<Outbound_Manifest_Return_Arr_Rel_Insert_Input>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Obj_Rel_Insert_Input>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "outbound_manifest_line" */
export type Outbound_Manifest_Line = {
  __typename?: "outbound_manifest_line";
  cancelled_quantity: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  dispatched_quantity: Scalars["Int"];
  external_order_line_id: Scalars["Int"];
  id: Scalars["Int"];
  is_fragile: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  outbound_manifest: Outbound_Manifest;
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  outbound_manifest_id: Scalars["Int"];
  /** An object relationship */
  outbound_manifest_line_status: Outbound_Manifest_Line_Status;
  outbound_manifest_line_status_id: Scalars["Int"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  quantity: Scalars["Int"];
  returned_quantity: Scalars["Int"];
};

/** columns and relationships of "outbound_manifest_line" */
export type Outbound_Manifest_LineOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_line" */
export type Outbound_Manifest_LineOutbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate = {
  __typename?: "outbound_manifest_line_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Line>;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And = {
  arguments: Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate_Fields = {
  __typename?: "outbound_manifest_line_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Line_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Line_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Line_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Line_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Line_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Line_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Line_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Line_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Line_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Line_Avg_Fields = {
  __typename?: "outbound_manifest_line_avg_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_line". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Line_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Line_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Line_Bool_Exp>>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dispatched_quantity?: Maybe<Int_Comparison_Exp>;
  external_order_line_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_fragile?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  outbound_manifest_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_line_status?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
  outbound_manifest_line_status_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  returned_quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestLinePkey = "outbound_manifest_line_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Insert_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  is_fragile?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest?: Maybe<Outbound_Manifest_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status?: Maybe<Outbound_Manifest_Line_Status_Obj_Rel_Insert_Input>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Line_Max_Fields = {
  __typename?: "outbound_manifest_line_max_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Line_Min_Fields = {
  __typename?: "outbound_manifest_line_min_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Mutation_Response = {
  __typename?: "outbound_manifest_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Line>;
};

/** input type for inserting object relation for remote table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_line" */
export type Outbound_Manifest_Line_On_Conflict = {
  constraint: Outbound_Manifest_Line_Constraint;
  update_columns?: Array<Outbound_Manifest_Line_Update_Column>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_line". */
export type Outbound_Manifest_Line_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_fragile?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest?: Maybe<Outbound_Manifest_Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status?: Maybe<Outbound_Manifest_Line_Status_Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_line */
export type Outbound_Manifest_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Select_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DispatchedQuantity = "dispatched_quantity",
  /** column name */
  ExternalOrderLineId = "external_order_line_id",
  /** column name */
  Id = "id",
  /** column name */
  IsFragile = "is_fragile",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestId = "outbound_manifest_id",
  /** column name */
  OutboundManifestLineStatusId = "outbound_manifest_line_status_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReturnedQuantity = "returned_quantity",
}

/** select "outbound_manifest_line_aggregate_bool_exp_bool_and_arguments_columns" columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFragile = "is_fragile",
}

/** select "outbound_manifest_line_aggregate_bool_exp_bool_or_arguments_columns" columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFragile = "is_fragile",
}

/** input type for updating data in table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Set_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  is_fragile?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status = {
  __typename?: "outbound_manifest_line_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  outbound_manifest_line_status_name: Scalars["String"];
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
};

/** columns and relationships of "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_StatusOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_StatusOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Aggregate = {
  __typename?: "outbound_manifest_line_status_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Line_Status_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Line_Status>;
};

/** aggregate fields of "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Aggregate_Fields = {
  __typename?: "outbound_manifest_line_status_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Line_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Line_Status_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Line_Status_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Line_Status_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Line_Status_Avg_Fields = {
  __typename?: "outbound_manifest_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_line_status". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Line_Status_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Line_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest_line_status_name?: Maybe<String_Comparison_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_line_status" */
export enum Outbound_Manifest_Line_Status_Constraint {
  /** unique or primary key constraint on columns "outbound_manifest_line_status_name" */
  OutboundManifestLineStatusOutboundManifestLineStatusKey = "outbound_manifest_line_status_outbound_manifest_line_status_key",
  /** unique or primary key constraint on columns "id" */
  OutboundManifestLineStatusPkey = "outbound_manifest_line_status_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Line_Status_Max_Fields = {
  __typename?: "outbound_manifest_line_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Line_Status_Min_Fields = {
  __typename?: "outbound_manifest_line_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Mutation_Response = {
  __typename?: "outbound_manifest_line_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Line_Status>;
};

/** input type for inserting object relation for remote table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Line_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Line_Status_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_On_Conflict = {
  constraint: Outbound_Manifest_Line_Status_Constraint;
  update_columns?: Array<Outbound_Manifest_Line_Status_Update_Column>;
  where?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_line_status". */
export type Outbound_Manifest_Line_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_line_status_name?: Maybe<Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
};

/** primary key columns input for table: outbound_manifest_line_status */
export type Outbound_Manifest_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_line_status" */
export enum Outbound_Manifest_Line_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestLineStatusName = "outbound_manifest_line_status_name",
}

/** input type for updating data in table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Line_Status_Stddev_Fields = {
  __typename?: "outbound_manifest_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Line_Status_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Line_Status_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "outbound_manifest_line_status" */
export type Outbound_Manifest_Line_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Line_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Line_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Line_Status_Sum_Fields = {
  __typename?: "outbound_manifest_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "outbound_manifest_line_status" */
export enum Outbound_Manifest_Line_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestLineStatusName = "outbound_manifest_line_status_name",
}

export type Outbound_Manifest_Line_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Line_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Line_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Line_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Line_Status_Var_Pop_Fields = {
  __typename?: "outbound_manifest_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Line_Status_Var_Samp_Fields = {
  __typename?: "outbound_manifest_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Line_Status_Variance_Fields = {
  __typename?: "outbound_manifest_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Line_Stddev_Fields = {
  __typename?: "outbound_manifest_line_stddev_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Line_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_line_stddev_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Line_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_line_stddev_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Line_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  is_fragile?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Line_Sum_Fields = {
  __typename?: "outbound_manifest_line_sum_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  dispatched_quantity?: Maybe<Scalars["Int"]>;
  external_order_line_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Update_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DispatchedQuantity = "dispatched_quantity",
  /** column name */
  ExternalOrderLineId = "external_order_line_id",
  /** column name */
  IsFragile = "is_fragile",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestId = "outbound_manifest_id",
  /** column name */
  OutboundManifestLineStatusId = "outbound_manifest_line_status_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  ReturnedQuantity = "returned_quantity",
}

export type Outbound_Manifest_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Line_Var_Pop_Fields = {
  __typename?: "outbound_manifest_line_var_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Line_Var_Samp_Fields = {
  __typename?: "outbound_manifest_line_var_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Line_Variance_Fields = {
  __typename?: "outbound_manifest_line_variance_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  dispatched_quantity?: Maybe<Scalars["Float"]>;
  external_order_line_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_line_status_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  external_order_line_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_line_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Max_Fields = {
  __typename?: "outbound_manifest_max_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_dispatch?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Max_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_dispatch?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Min_Fields = {
  __typename?: "outbound_manifest_min_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_dispatch?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Min_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_dispatch?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest" */
export type Outbound_Manifest_Mutation_Response = {
  __typename?: "outbound_manifest_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest>;
};

/** input type for inserting object relation for remote table "outbound_manifest" */
export type Outbound_Manifest_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest" */
export type Outbound_Manifest_On_Conflict = {
  constraint: Outbound_Manifest_Constraint;
  update_columns?: Array<Outbound_Manifest_Update_Column>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest". */
export type Outbound_Manifest_Order_By = {
  courier_service?: Maybe<Courier_Service_Order_By>;
  courier_service_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_dispatch?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder?: Maybe<Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  logistics_type?: Maybe<Logistics_Type_Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_fulfilments_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Aggregate_Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
  outbound_manifest_returns_aggregate?: Maybe<Outbound_Manifest_Return_Aggregate_Order_By>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest */
export type Outbound_Manifest_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** columns and relationships of "outbound_manifest_return" */
export type Outbound_Manifest_Return = {
  __typename?: "outbound_manifest_return";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  outbound_manifest: Outbound_Manifest;
  outbound_manifest_id: Scalars["Int"];
  /** An array relationship */
  outbound_manifest_return_lines: Array<Outbound_Manifest_Return_Line>;
  /** An aggregate relationship */
  outbound_manifest_return_lines_aggregate: Outbound_Manifest_Return_Line_Aggregate;
  return_received_date?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "outbound_manifest_return" */
export type Outbound_Manifest_ReturnOutbound_Manifest_Return_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_return" */
export type Outbound_Manifest_ReturnOutbound_Manifest_Return_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_return" */
export type Outbound_Manifest_Return_Aggregate = {
  __typename?: "outbound_manifest_return_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Return_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Return>;
};

export type Outbound_Manifest_Return_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Return_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Return_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_return" */
export type Outbound_Manifest_Return_Aggregate_Fields = {
  __typename?: "outbound_manifest_return_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Return_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Return_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Return_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Return_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Return_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Return_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Return_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Return_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Return_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Return_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_return" */
export type Outbound_Manifest_Return_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Return_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Return_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Return_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Return_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Return_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Return_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Return_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Return_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Return_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Return_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Return_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Return_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Return_Avg_Fields = {
  __typename?: "outbound_manifest_return_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Avg_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_return". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Return_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Return_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Return_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifest_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_return_lines?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
  outbound_manifest_return_lines_aggregate?: Maybe<Outbound_Manifest_Return_Line_Aggregate_Bool_Exp>;
  return_received_date?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_return" */
export enum Outbound_Manifest_Return_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestReturnPkey = "outbound_manifest_return_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Inc_Input = {
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest?: Maybe<Outbound_Manifest_Obj_Rel_Insert_Input>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_lines?: Maybe<Outbound_Manifest_Return_Line_Arr_Rel_Insert_Input>;
  return_received_date?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line = {
  __typename?: "outbound_manifest_return_line";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  outbound_manifest_fulfilment_line: Outbound_Manifest_Fulfilment_Line;
  outbound_manifest_fulfilment_line_id: Scalars["Int"];
  /** An object relationship */
  outbound_manifest_return: Outbound_Manifest_Return;
  outbound_manifest_return_id: Scalars["Int"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  returned_quantity: Scalars["Int"];
};

/** columns and relationships of "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Aggregate = {
  __typename?: "outbound_manifest_return_line_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Return_Line_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Return_Line>;
};

export type Outbound_Manifest_Return_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Return_Line_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Return_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Aggregate_Fields = {
  __typename?: "outbound_manifest_return_line_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Return_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Return_Line_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Return_Line_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Return_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Return_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Return_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Return_Line_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Return_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Return_Line_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Return_Line_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Return_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Return_Line_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Return_Line_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Return_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Return_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Return_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Return_Line_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Return_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Return_Line_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Return_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Return_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Return_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Return_Line_Avg_Fields = {
  __typename?: "outbound_manifest_return_line_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_return_line". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Return_Line_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Return_Line_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Return_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_line_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_return?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
  outbound_manifest_return_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  returned_quantity?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_return_line" */
export enum Outbound_Manifest_Return_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestReturnLinePkey = "outbound_manifest_return_line_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Inc_Input = {
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return?: Maybe<Outbound_Manifest_Return_Obj_Rel_Insert_Input>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Return_Line_Max_Fields = {
  __typename?: "outbound_manifest_return_line_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Return_Line_Min_Fields = {
  __typename?: "outbound_manifest_return_line_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Mutation_Response = {
  __typename?: "outbound_manifest_return_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Return_Line>;
};

/** input type for inserting object relation for remote table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Return_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Return_Line_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_On_Conflict = {
  constraint: Outbound_Manifest_Return_Line_Constraint;
  update_columns?: Array<Outbound_Manifest_Return_Line_Update_Column>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_return_line". */
export type Outbound_Manifest_Return_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return?: Maybe<Outbound_Manifest_Return_Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_return_line */
export type Outbound_Manifest_Return_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_return_line" */
export enum Outbound_Manifest_Return_Line_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentLineId = "outbound_manifest_fulfilment_line_id",
  /** column name */
  OutboundManifestReturnId = "outbound_manifest_return_id",
  /** column name */
  ReturnedQuantity = "returned_quantity",
}

/** input type for updating data in table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Return_Line_Stddev_Fields = {
  __typename?: "outbound_manifest_return_line_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Return_Line_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_return_line_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Return_Line_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_return_line_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Return_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Return_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Return_Line_Sum_Fields = {
  __typename?: "outbound_manifest_return_line_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Int"]>;
  returned_quantity?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_return_line" */
export enum Outbound_Manifest_Return_Line_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestFulfilmentLineId = "outbound_manifest_fulfilment_line_id",
  /** column name */
  OutboundManifestReturnId = "outbound_manifest_return_id",
  /** column name */
  ReturnedQuantity = "returned_quantity",
}

export type Outbound_Manifest_Return_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Return_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Return_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Return_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Return_Line_Var_Pop_Fields = {
  __typename?: "outbound_manifest_return_line_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Return_Line_Var_Samp_Fields = {
  __typename?: "outbound_manifest_return_line_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Return_Line_Variance_Fields = {
  __typename?: "outbound_manifest_return_line_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_return_id?: Maybe<Scalars["Float"]>;
  returned_quantity?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "outbound_manifest_return_line" */
export type Outbound_Manifest_Return_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  outbound_manifest_return_id?: Maybe<Order_By>;
  returned_quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Return_Max_Fields = {
  __typename?: "outbound_manifest_return_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  return_received_date?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  return_received_date?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Return_Min_Fields = {
  __typename?: "outbound_manifest_return_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  return_received_date?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  return_received_date?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Mutation_Response = {
  __typename?: "outbound_manifest_return_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Return>;
};

/** input type for inserting object relation for remote table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Return_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Return_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_return" */
export type Outbound_Manifest_Return_On_Conflict = {
  constraint: Outbound_Manifest_Return_Constraint;
  update_columns?: Array<Outbound_Manifest_Return_Update_Column>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_return". */
export type Outbound_Manifest_Return_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest?: Maybe<Outbound_Manifest_Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_return_lines_aggregate?: Maybe<Outbound_Manifest_Return_Line_Aggregate_Order_By>;
  return_received_date?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_return */
export type Outbound_Manifest_Return_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_return" */
export enum Outbound_Manifest_Return_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestId = "outbound_manifest_id",
  /** column name */
  ReturnReceivedDate = "return_received_date",
}

/** input type for updating data in table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  return_received_date?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Return_Stddev_Fields = {
  __typename?: "outbound_manifest_return_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Return_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_return_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Return_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_return_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Return_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Return_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
  return_received_date?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Return_Sum_Fields = {
  __typename?: "outbound_manifest_return_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  outbound_manifest_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Sum_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_return" */
export enum Outbound_Manifest_Return_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestId = "outbound_manifest_id",
  /** column name */
  ReturnReceivedDate = "return_received_date",
}

export type Outbound_Manifest_Return_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Return_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Return_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Return_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Return_Var_Pop_Fields = {
  __typename?: "outbound_manifest_return_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Return_Var_Samp_Fields = {
  __typename?: "outbound_manifest_return_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Return_Variance_Fields = {
  __typename?: "outbound_manifest_return_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  outbound_manifest_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "outbound_manifest_return" */
export type Outbound_Manifest_Return_Variance_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
};

/** select columns of table "outbound_manifest" */
export enum Outbound_Manifest_Select_Column {
  /** column name */
  CourierServiceId = "courier_service_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpectedDispatch = "expected_dispatch",
  /** column name */
  ExternalOrderId = "external_order_id",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LogisticsTypeId = "logistics_type_id",
  /** column name */
  Notes = "notes",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  OutboundManifestStatusId = "outbound_manifest_status_id",
  /** column name */
  Reference = "reference",
  /** column name */
  WarehouseId = "warehouse_id",
}

/** input type for updating data in table "outbound_manifest" */
export type Outbound_Manifest_Set_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_dispatch?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_Status = {
  __typename?: "outbound_manifest_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  outbound_manifest_status_name: Scalars["String"];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
};

/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_StatusOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_StatusOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_status" */
export type Outbound_Manifest_Status_Aggregate = {
  __typename?: "outbound_manifest_status_aggregate";
  aggregate?: Maybe<Outbound_Manifest_Status_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Status>;
};

/** aggregate fields of "outbound_manifest_status" */
export type Outbound_Manifest_Status_Aggregate_Fields = {
  __typename?: "outbound_manifest_status_aggregate_fields";
  avg?: Maybe<Outbound_Manifest_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Outbound_Manifest_Status_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Status_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Status_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Status_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Status_Variance_Fields>;
};

/** aggregate fields of "outbound_manifest_status" */
export type Outbound_Manifest_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Status_Avg_Fields = {
  __typename?: "outbound_manifest_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_status". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Status_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Status_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  outbound_manifest_status_name?: Maybe<String_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_status" */
export enum Outbound_Manifest_Status_Constraint {
  /** unique or primary key constraint on columns "outbound_manifest_status_name" */
  OutboundManifestStatusOutboundManifestStatusNameKey = "outbound_manifest_status_outbound_manifest_status_name_key",
  /** unique or primary key constraint on columns "id" */
  OutboundManifestStatusPkey = "outbound_manifest_status_pkey",
}

/** input type for incrementing numeric columns in table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_status_name?: Maybe<Scalars["String"]>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Status_Max_Fields = {
  __typename?: "outbound_manifest_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Status_Min_Fields = {
  __typename?: "outbound_manifest_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Mutation_Response = {
  __typename?: "outbound_manifest_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Status>;
};

/** input type for inserting object relation for remote table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Status_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_status" */
export type Outbound_Manifest_Status_On_Conflict = {
  constraint: Outbound_Manifest_Status_Constraint;
  update_columns?: Array<Outbound_Manifest_Status_Update_Column>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_status". */
export type Outbound_Manifest_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  outbound_manifest_status_name?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
};

/** primary key columns input for table: outbound_manifest_status */
export type Outbound_Manifest_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "outbound_manifest_status" */
export enum Outbound_Manifest_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestStatusName = "outbound_manifest_status_name",
}

/** input type for updating data in table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Status_Stddev_Fields = {
  __typename?: "outbound_manifest_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Status_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Status_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  outbound_manifest_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Status_Sum_Fields = {
  __typename?: "outbound_manifest_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "outbound_manifest_status" */
export enum Outbound_Manifest_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OutboundManifestStatusName = "outbound_manifest_status_name",
}

export type Outbound_Manifest_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Status_Var_Pop_Fields = {
  __typename?: "outbound_manifest_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Status_Var_Samp_Fields = {
  __typename?: "outbound_manifest_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Status_Variance_Fields = {
  __typename?: "outbound_manifest_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Stddev_Fields = {
  __typename?: "outbound_manifest_stddev_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Stddev_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Stddev_Pop_Fields = {
  __typename?: "outbound_manifest_stddev_pop_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Stddev_Pop_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Stddev_Samp_Fields = {
  __typename?: "outbound_manifest_stddev_samp_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Stddev_Samp_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest" */
export type Outbound_Manifest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Stream_Cursor_Value_Input = {
  courier_service_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_dispatch?: Maybe<Scalars["timestamptz"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Sum_Fields = {
  __typename?: "outbound_manifest_sum_fields";
  courier_service_id?: Maybe<Scalars["Int"]>;
  external_order_id?: Maybe<Scalars["Int"]>;
  folder_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  logistics_type_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Sum_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest" */
export enum Outbound_Manifest_Update_Column {
  /** column name */
  CourierServiceId = "courier_service_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpectedDispatch = "expected_dispatch",
  /** column name */
  ExternalOrderId = "external_order_id",
  /** column name */
  FolderId = "folder_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LogisticsTypeId = "logistics_type_id",
  /** column name */
  Notes = "notes",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  OutboundManifestStatusId = "outbound_manifest_status_id",
  /** column name */
  Reference = "reference",
  /** column name */
  WarehouseId = "warehouse_id",
}

export type Outbound_Manifest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Var_Pop_Fields = {
  __typename?: "outbound_manifest_var_pop_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Var_Pop_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Var_Samp_Fields = {
  __typename?: "outbound_manifest_var_samp_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Var_Samp_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Variance_Fields = {
  __typename?: "outbound_manifest_variance_fields";
  courier_service_id?: Maybe<Scalars["Float"]>;
  external_order_id?: Maybe<Scalars["Float"]>;
  folder_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  logistics_type_id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  outbound_manifest_status_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Variance_Order_By = {
  courier_service_id?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_type_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: "product";
  base_product_id: Scalars["Int"];
  base_product_quantity: Scalars["Int"];
  /** An array relationship */
  caseChangesByToProductId: Array<Case_Change>;
  /** An aggregate relationship */
  caseChangesByToProductId_aggregate: Case_Change_Aggregate;
  /** An array relationship */
  case_changes: Array<Case_Change>;
  /** An aggregate relationship */
  case_changes_aggregate: Case_Change_Aggregate;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  created_at: Scalars["timestamptz"];
  external_product_id?: Maybe<Scalars["String"]>;
  /** An array relationship */
  file_copy_deletions: Array<File_Copy_Deletion>;
  /** An aggregate relationship */
  file_copy_deletions_aggregate: File_Copy_Deletion_Aggregate;
  fsc_certified: Scalars["Boolean"];
  grs_certified: Scalars["Boolean"];
  height_mm: Scalars["Int"];
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifest_fulfilment_lines: Array<Inbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  inbound_manifest_fulfilment_lines_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  last_updated: Scalars["timestamptz"];
  length_mm: Scalars["Int"];
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
  /** An object relationship */
  product?: Maybe<Product>;
  /** An object relationship */
  productByBaseProductId: Product;
  product_name: Scalars["String"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An array relationship */
  products: Array<Product>;
  /** An array relationship */
  productsByBaseProductId: Array<Product>;
  /** An aggregate relationship */
  productsByBaseProductId_aggregate: Product_Aggregate;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  /** An array relationship */
  recipe_inputs: Array<Recipe_Input>;
  /** An aggregate relationship */
  recipe_inputs_aggregate: Recipe_Input_Aggregate;
  /** An array relationship */
  recipe_outputs: Array<Recipe_Output>;
  /** An aggregate relationship */
  recipe_outputs_aggregate: Recipe_Output_Aggregate;
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars["Int"];
  /** An array relationship */
  stock_allocations: Array<Stock_Allocation>;
  /** An aggregate relationship */
  stock_allocations_aggregate: Stock_Allocation_Aggregate;
  /** An array relationship */
  stock_check_line_fulfilments: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stock_check_line_fulfilments_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  weight_grams: Scalars["Int"];
  width_mm: Scalars["Int"];
  /** An array relationship */
  work_order_output_lines: Array<Work_Order_Output_Line>;
  /** An aggregate relationship */
  work_order_output_lines_aggregate: Work_Order_Output_Line_Aggregate;
};

/** columns and relationships of "product" */
export type ProductCaseChangesByToProductIdArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductCaseChangesByToProductId_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductCase_ChangesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductCase_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductFile_Copy_DeletionsArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductFile_Copy_Deletions_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductInbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductInbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductOutbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProductsArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProductsByBaseProductIdArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProductsByBaseProductId_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductRecipe_InputsArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductRecipe_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductRecipe_OutputsArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductRecipe_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductStock_AllocationsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductStock_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductStock_Check_Line_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductStock_Check_Line_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductWork_Order_Output_LinesArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

/** columns and relationships of "product" */
export type ProductWork_Order_Output_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: "product_aggregate";
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

export type Product_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Product_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Product_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Product_Aggregate_Bool_Exp_Count>;
};

export type Product_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: "product_aggregate_fields";
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};

/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: Maybe<Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Max_Order_By>;
  min?: Maybe<Product_Min_Order_By>;
  stddev?: Maybe<Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Sum_Order_By>;
  var_pop?: Maybe<Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: "product_avg_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Product_Bool_Exp>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Product_Bool_Exp>>;
  base_product_id?: Maybe<Int_Comparison_Exp>;
  base_product_quantity?: Maybe<Int_Comparison_Exp>;
  caseChangesByToProductId?: Maybe<Case_Change_Bool_Exp>;
  caseChangesByToProductId_aggregate?: Maybe<Case_Change_Aggregate_Bool_Exp>;
  case_changes?: Maybe<Case_Change_Bool_Exp>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Bool_Exp>;
  case_product_id?: Maybe<Int_Comparison_Exp>;
  case_product_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_product_id?: Maybe<String_Comparison_Exp>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Bool_Exp>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Bool_Exp>;
  fsc_certified?: Maybe<Boolean_Comparison_Exp>;
  grs_certified?: Maybe<Boolean_Comparison_Exp>;
  height_mm?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_fulfilment_lines?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  inbound_manifest_fulfilment_lines_aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  length_mm?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  productByBaseProductId?: Maybe<Product_Bool_Exp>;
  product_name?: Maybe<String_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  productsByBaseProductId?: Maybe<Product_Bool_Exp>;
  productsByBaseProductId_aggregate?: Maybe<Product_Aggregate_Bool_Exp>;
  products_aggregate?: Maybe<Product_Aggregate_Bool_Exp>;
  recipe_inputs?: Maybe<Recipe_Input_Bool_Exp>;
  recipe_inputs_aggregate?: Maybe<Recipe_Input_Aggregate_Bool_Exp>;
  recipe_outputs?: Maybe<Recipe_Output_Bool_Exp>;
  recipe_outputs_aggregate?: Maybe<Recipe_Output_Aggregate_Bool_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  stock_allocations?: Maybe<Stock_Allocation_Bool_Exp>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Bool_Exp>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
  weight_grams?: Maybe<Int_Comparison_Exp>;
  width_mm?: Maybe<Int_Comparison_Exp>;
  work_order_output_lines?: Maybe<Work_Order_Output_Line_Bool_Exp>;
  work_order_output_lines_aggregate?: Maybe<Work_Order_Output_Line_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = "product_pkey",
  /** unique or primary key constraint on columns "product_name" */
  ProductProductNameKey = "product_product_name_key",
}

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  caseChangesByToProductId?: Maybe<Case_Change_Arr_Rel_Insert_Input>;
  case_changes?: Maybe<Case_Change_Arr_Rel_Insert_Input>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Arr_Rel_Insert_Input>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  inbound_manifest_fulfilment_lines?: Maybe<Inbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  productByBaseProductId?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_name?: Maybe<Scalars["String"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  products?: Maybe<Product_Arr_Rel_Insert_Input>;
  productsByBaseProductId?: Maybe<Product_Arr_Rel_Insert_Input>;
  recipe_inputs?: Maybe<Recipe_Input_Arr_Rel_Insert_Input>;
  recipe_outputs?: Maybe<Recipe_Output_Arr_Rel_Insert_Input>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars["Int"]>;
  stock_allocations?: Maybe<Stock_Allocation_Arr_Rel_Insert_Input>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
  work_order_output_lines?: Maybe<Work_Order_Output_Line_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: "product_max_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: "product_min_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: "product_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  caseChangesByToProductId_aggregate?: Maybe<Case_Change_Aggregate_Order_By>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Order_By>;
  fsc_certified?: Maybe<Order_By>;
  grs_certified?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_fulfilment_lines_aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
  product?: Maybe<Product_Order_By>;
  productByBaseProductId?: Maybe<Product_Order_By>;
  product_name?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  productsByBaseProductId_aggregate?: Maybe<Product_Aggregate_Order_By>;
  products_aggregate?: Maybe<Product_Aggregate_Order_By>;
  recipe_inputs_aggregate?: Maybe<Recipe_Input_Aggregate_Order_By>;
  recipe_outputs_aggregate?: Maybe<Recipe_Output_Aggregate_Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Order_By>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
  work_order_output_lines_aggregate?: Maybe<Work_Order_Output_Line_Aggregate_Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  BaseProductQuantity = "base_product_quantity",
  /** column name */
  CaseProductId = "case_product_id",
  /** column name */
  CaseProductQuantity = "case_product_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalProductId = "external_product_id",
  /** column name */
  FscCertified = "fsc_certified",
  /** column name */
  GrsCertified = "grs_certified",
  /** column name */
  HeightMm = "height_mm",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LengthMm = "length_mm",
  /** column name */
  ProductName = "product_name",
  /** column name */
  ServiceAccountId = "service_account_id",
  /** column name */
  WeightGrams = "weight_grams",
  /** column name */
  WidthMm = "width_mm",
}

/** select "product_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  FscCertified = "fsc_certified",
  /** column name */
  GrsCertified = "grs_certified",
}

/** select "product_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product" */
export enum Product_Select_Column_Product_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  FscCertified = "fsc_certified",
  /** column name */
  GrsCertified = "grs_certified",
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: "product_stddev_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: "product_stddev_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: "product_stddev_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** columns and relationships of "product_stock" */
export type Product_Stock = {
  __typename?: "product_stock";
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  barcode_is_unique: Scalars["Boolean"];
  barcode_uuid: Scalars["uuid"];
  /** An array relationship */
  case_change_input_lines: Array<Case_Change_Input_Line>;
  /** An aggregate relationship */
  case_change_input_lines_aggregate: Case_Change_Input_Line_Aggregate;
  /** An object relationship */
  case_change_output_line?: Maybe<Case_Change_Output_Line>;
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  file_copy_deletions: Array<File_Copy_Deletion>;
  /** An aggregate relationship */
  file_copy_deletions_aggregate: File_Copy_Deletion_Aggregate;
  id: Scalars["Int"];
  /** An object relationship */
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  initial_quantity: Scalars["Int"];
  /** An object relationship */
  internal_transfer_line?: Maybe<Internal_Transfer_Line>;
  /** An array relationship */
  internal_transfer_lines: Array<Internal_Transfer_Line>;
  /** An aggregate relationship */
  internal_transfer_lines_aggregate: Internal_Transfer_Line_Aggregate;
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location: Location;
  location_id: Scalars["Int"];
  /** An object relationship */
  organisation?: Maybe<Organisation>;
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** An object relationship */
  outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line>;
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  quantity: Scalars["Int"];
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment>;
  /** An array relationship */
  stock_allocation_fulfilments: Array<Stock_Allocation_Fulfilment>;
  /** An aggregate relationship */
  stock_allocation_fulfilments_aggregate: Stock_Allocation_Fulfilment_Aggregate;
  /** An object relationship */
  stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment>;
  /** An array relationship */
  stock_check_lines: Array<Stock_Check_Line>;
  /** An aggregate relationship */
  stock_check_lines_aggregate: Stock_Check_Line_Aggregate;
  /** An object relationship */
  stock_condition: Stock_Condition;
  stock_condition_id: Scalars["Int"];
  /** An object relationship */
  stock_status: Stock_Status;
  stock_status_id: Scalars["Int"];
  /** An array relationship */
  work_order_input_lines: Array<Work_Order_Input_Line>;
  /** An aggregate relationship */
  work_order_input_lines_aggregate: Work_Order_Input_Line_Aggregate;
  /** An object relationship */
  work_order_output_line?: Maybe<Work_Order_Output_Line>;
};

/** columns and relationships of "product_stock" */
export type Product_StockCase_Change_Input_LinesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockCase_Change_Input_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockFile_Copy_DeletionsArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockFile_Copy_Deletions_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockInternal_Transfer_LinesArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockInternal_Transfer_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockOutbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockStock_Allocation_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockStock_Allocation_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockStock_Check_LinesArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockStock_Check_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockWork_Order_Input_LinesArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

/** columns and relationships of "product_stock" */
export type Product_StockWork_Order_Input_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

/** aggregated selection of "product_stock" */
export type Product_Stock_Aggregate = {
  __typename?: "product_stock_aggregate";
  aggregate?: Maybe<Product_Stock_Aggregate_Fields>;
  nodes: Array<Product_Stock>;
};

export type Product_Stock_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Product_Stock_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Product_Stock_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Product_Stock_Aggregate_Bool_Exp_Count>;
};

export type Product_Stock_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Stock_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Stock_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Stock_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Stock_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Stock_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Stock_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_stock" */
export type Product_Stock_Aggregate_Fields = {
  __typename?: "product_stock_aggregate_fields";
  avg?: Maybe<Product_Stock_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Stock_Max_Fields>;
  min?: Maybe<Product_Stock_Min_Fields>;
  stddev?: Maybe<Product_Stock_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stock_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Stock_Sum_Fields>;
  var_pop?: Maybe<Product_Stock_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Stock_Var_Samp_Fields>;
  variance?: Maybe<Product_Stock_Variance_Fields>;
};

/** aggregate fields of "product_stock" */
export type Product_Stock_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Stock_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_stock" */
export type Product_Stock_Aggregate_Order_By = {
  avg?: Maybe<Product_Stock_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Stock_Max_Order_By>;
  min?: Maybe<Product_Stock_Min_Order_By>;
  stddev?: Maybe<Product_Stock_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stock_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stock_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Stock_Sum_Order_By>;
  var_pop?: Maybe<Product_Stock_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Stock_Var_Samp_Order_By>;
  variance?: Maybe<Product_Stock_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_stock" */
export type Product_Stock_Arr_Rel_Insert_Input = {
  data: Array<Product_Stock_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Stock_Avg_Fields = {
  __typename?: "product_stock_avg_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "product_stock" */
export type Product_Stock_Avg_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_stock". All fields are combined with a logical 'AND'. */
export type Product_Stock_Bool_Exp = {
  _and?: Maybe<Array<Product_Stock_Bool_Exp>>;
  _not?: Maybe<Product_Stock_Bool_Exp>;
  _or?: Maybe<Array<Product_Stock_Bool_Exp>>;
  allocated_to_organisation_id?: Maybe<Int_Comparison_Exp>;
  barcode_is_unique?: Maybe<Boolean_Comparison_Exp>;
  barcode_uuid?: Maybe<Uuid_Comparison_Exp>;
  case_change_input_lines?: Maybe<Case_Change_Input_Line_Bool_Exp>;
  case_change_input_lines_aggregate?: Maybe<Case_Change_Input_Line_Aggregate_Bool_Exp>;
  case_change_output_line?: Maybe<Case_Change_Output_Line_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Bool_Exp>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  initial_quantity?: Maybe<Int_Comparison_Exp>;
  internal_transfer_line?: Maybe<Internal_Transfer_Line_Bool_Exp>;
  internal_transfer_lines?: Maybe<Internal_Transfer_Line_Bool_Exp>;
  internal_transfer_lines_aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  source_case_change_output_line_id?: Maybe<Int_Comparison_Exp>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Int_Comparison_Exp>;
  source_internal_transfer_line_id?: Maybe<Int_Comparison_Exp>;
  source_outbound_manifest_return_line_id?: Maybe<Int_Comparison_Exp>;
  source_stock_allocation_fulfilment_id?: Maybe<Int_Comparison_Exp>;
  source_stock_check_line_fulfilment_id?: Maybe<Int_Comparison_Exp>;
  source_work_order_output_line_id?: Maybe<Int_Comparison_Exp>;
  stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
  stock_allocation_fulfilments?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
  stock_allocation_fulfilments_aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Bool_Exp>;
  stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_lines?: Maybe<Stock_Check_Line_Bool_Exp>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Bool_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
  stock_condition_id?: Maybe<Int_Comparison_Exp>;
  stock_status?: Maybe<Stock_Status_Bool_Exp>;
  stock_status_id?: Maybe<Int_Comparison_Exp>;
  work_order_input_lines?: Maybe<Work_Order_Input_Line_Bool_Exp>;
  work_order_input_lines_aggregate?: Maybe<Work_Order_Input_Line_Aggregate_Bool_Exp>;
  work_order_output_line?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

/** unique or primary key constraints on table "product_stock" */
export enum Product_Stock_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductStockPkey = "product_stock_pkey",
  /** unique or primary key constraint on columns "barcode_uuid" */
  ProductStockUniqueBarcodes = "product_stock_unique_barcodes",
}

/** input type for incrementing numeric columns in table "product_stock" */
export type Product_Stock_Inc_Input = {
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "product_stock" */
export type Product_Stock_Insert_Input = {
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  barcode_is_unique?: Maybe<Scalars["Boolean"]>;
  barcode_uuid?: Maybe<Scalars["uuid"]>;
  case_change_input_lines?: Maybe<Case_Change_Input_Line_Arr_Rel_Insert_Input>;
  case_change_output_line?: Maybe<Case_Change_Output_Line_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Arr_Rel_Insert_Input>;
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Obj_Rel_Insert_Input>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  internal_transfer_line?: Maybe<Internal_Transfer_Line_Obj_Rel_Insert_Input>;
  internal_transfer_lines?: Maybe<Internal_Transfer_Line_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line_Obj_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment_Obj_Rel_Insert_Input>;
  stock_allocation_fulfilments?: Maybe<Stock_Allocation_Fulfilment_Arr_Rel_Insert_Input>;
  stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment_Obj_Rel_Insert_Input>;
  stock_check_lines?: Maybe<Stock_Check_Line_Arr_Rel_Insert_Input>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status?: Maybe<Stock_Status_Obj_Rel_Insert_Input>;
  stock_status_id?: Maybe<Scalars["Int"]>;
  work_order_input_lines?: Maybe<Work_Order_Input_Line_Arr_Rel_Insert_Input>;
  work_order_output_line?: Maybe<Work_Order_Output_Line_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Stock_Max_Fields = {
  __typename?: "product_stock_max_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  barcode_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "product_stock" */
export type Product_Stock_Max_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Stock_Min_Fields = {
  __typename?: "product_stock_min_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  barcode_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "product_stock" */
export type Product_Stock_Min_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_stock" */
export type Product_Stock_Mutation_Response = {
  __typename?: "product_stock_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Stock>;
};

/** input type for inserting object relation for remote table "product_stock" */
export type Product_Stock_Obj_Rel_Insert_Input = {
  data: Product_Stock_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};

/** on_conflict condition type for table "product_stock" */
export type Product_Stock_On_Conflict = {
  constraint: Product_Stock_Constraint;
  update_columns?: Array<Product_Stock_Update_Column>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** Ordering options when selecting data from "product_stock". */
export type Product_Stock_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  barcode_is_unique?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  case_change_input_lines_aggregate?: Maybe<Case_Change_Input_Line_Aggregate_Order_By>;
  case_change_output_line?: Maybe<Case_Change_Output_Line_Order_By>;
  created_at?: Maybe<Order_By>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Order_By>;
  initial_quantity?: Maybe<Order_By>;
  internal_transfer_line?: Maybe<Internal_Transfer_Line_Order_By>;
  internal_transfer_lines_aggregate?: Maybe<Internal_Transfer_Line_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  outbound_manifest_return_line?: Maybe<Outbound_Manifest_Return_Line_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_allocation_fulfilment?: Maybe<Stock_Allocation_Fulfilment_Order_By>;
  stock_allocation_fulfilments_aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Order_By>;
  stock_check_line_fulfilment?: Maybe<Stock_Check_Line_Fulfilment_Order_By>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status?: Maybe<Stock_Status_Order_By>;
  stock_status_id?: Maybe<Order_By>;
  work_order_input_lines_aggregate?: Maybe<Work_Order_Input_Line_Aggregate_Order_By>;
  work_order_output_line?: Maybe<Work_Order_Output_Line_Order_By>;
};

/** primary key columns input for table: product_stock */
export type Product_Stock_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "product_stock" */
export enum Product_Stock_Select_Column {
  /** column name */
  AllocatedToOrganisationId = "allocated_to_organisation_id",
  /** column name */
  BarcodeIsUnique = "barcode_is_unique",
  /** column name */
  BarcodeUuid = "barcode_uuid",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  InitialQuantity = "initial_quantity",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SourceCaseChangeOutputLineId = "source_case_change_output_line_id",
  /** column name */
  SourceInboundManifestFulfilmentLineId = "source_inbound_manifest_fulfilment_line_id",
  /** column name */
  SourceInternalTransferLineId = "source_internal_transfer_line_id",
  /** column name */
  SourceOutboundManifestReturnLineId = "source_outbound_manifest_return_line_id",
  /** column name */
  SourceStockAllocationFulfilmentId = "source_stock_allocation_fulfilment_id",
  /** column name */
  SourceStockCheckLineFulfilmentId = "source_stock_check_line_fulfilment_id",
  /** column name */
  SourceWorkOrderOutputLineId = "source_work_order_output_line_id",
  /** column name */
  StockConditionId = "stock_condition_id",
  /** column name */
  StockStatusId = "stock_status_id",
}

/** select "product_stock_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_stock" */
export enum Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BarcodeIsUnique = "barcode_is_unique",
}

/** select "product_stock_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_stock" */
export enum Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BarcodeIsUnique = "barcode_is_unique",
}

/** input type for updating data in table "product_stock" */
export type Product_Stock_Set_Input = {
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  barcode_is_unique?: Maybe<Scalars["Boolean"]>;
  barcode_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Product_Stock_Stddev_Fields = {
  __typename?: "product_stock_stddev_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "product_stock" */
export type Product_Stock_Stddev_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stock_Stddev_Pop_Fields = {
  __typename?: "product_stock_stddev_pop_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "product_stock" */
export type Product_Stock_Stddev_Pop_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stock_Stddev_Samp_Fields = {
  __typename?: "product_stock_stddev_samp_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "product_stock" */
export type Product_Stock_Stddev_Samp_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product_stock" */
export type Product_Stock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stock_Stream_Cursor_Value_Input = {
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  barcode_is_unique?: Maybe<Scalars["Boolean"]>;
  barcode_uuid?: Maybe<Scalars["uuid"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Product_Stock_Sum_Fields = {
  __typename?: "product_stock_sum_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  initial_quantity?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Int"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Int"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Int"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Int"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Int"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  stock_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "product_stock" */
export type Product_Stock_Sum_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** update columns of table "product_stock" */
export enum Product_Stock_Update_Column {
  /** column name */
  AllocatedToOrganisationId = "allocated_to_organisation_id",
  /** column name */
  BarcodeIsUnique = "barcode_is_unique",
  /** column name */
  BarcodeUuid = "barcode_uuid",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  InitialQuantity = "initial_quantity",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SourceCaseChangeOutputLineId = "source_case_change_output_line_id",
  /** column name */
  SourceInboundManifestFulfilmentLineId = "source_inbound_manifest_fulfilment_line_id",
  /** column name */
  SourceInternalTransferLineId = "source_internal_transfer_line_id",
  /** column name */
  SourceOutboundManifestReturnLineId = "source_outbound_manifest_return_line_id",
  /** column name */
  SourceStockAllocationFulfilmentId = "source_stock_allocation_fulfilment_id",
  /** column name */
  SourceStockCheckLineFulfilmentId = "source_stock_check_line_fulfilment_id",
  /** column name */
  SourceWorkOrderOutputLineId = "source_work_order_output_line_id",
  /** column name */
  StockConditionId = "stock_condition_id",
  /** column name */
  StockStatusId = "stock_status_id",
}

export type Product_Stock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Stock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Stock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Stock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Stock_Var_Pop_Fields = {
  __typename?: "product_stock_var_pop_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "product_stock" */
export type Product_Stock_Var_Pop_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Stock_Var_Samp_Fields = {
  __typename?: "product_stock_var_samp_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "product_stock" */
export type Product_Stock_Var_Samp_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Stock_Variance_Fields = {
  __typename?: "product_stock_variance_fields";
  allocated_to_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  initial_quantity?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_case_change_output_line_id?: Maybe<Scalars["Float"]>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars["Float"]>;
  source_internal_transfer_line_id?: Maybe<Scalars["Float"]>;
  source_outbound_manifest_return_line_id?: Maybe<Scalars["Float"]>;
  source_stock_allocation_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_stock_check_line_fulfilment_id?: Maybe<Scalars["Float"]>;
  source_work_order_output_line_id?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  stock_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "product_stock" */
export type Product_Stock_Variance_Order_By = {
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_case_change_output_line_id?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_internal_transfer_line_id?: Maybe<Order_By>;
  source_outbound_manifest_return_line_id?: Maybe<Order_By>;
  source_stock_allocation_fulfilment_id?: Maybe<Order_By>;
  source_stock_check_line_fulfilment_id?: Maybe<Order_By>;
  source_work_order_output_line_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_product_id?: Maybe<Scalars["String"]>;
  fsc_certified?: Maybe<Scalars["Boolean"]>;
  grs_certified?: Maybe<Scalars["Boolean"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: "product_sum_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_quantity?: Maybe<Scalars["Int"]>;
  case_product_id?: Maybe<Scalars["Int"]>;
  case_product_quantity?: Maybe<Scalars["Int"]>;
  height_mm?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  length_mm?: Maybe<Scalars["Int"]>;
  service_account_id?: Maybe<Scalars["Int"]>;
  weight_grams?: Maybe<Scalars["Int"]>;
  width_mm?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  BaseProductQuantity = "base_product_quantity",
  /** column name */
  CaseProductId = "case_product_id",
  /** column name */
  CaseProductQuantity = "case_product_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalProductId = "external_product_id",
  /** column name */
  FscCertified = "fsc_certified",
  /** column name */
  GrsCertified = "grs_certified",
  /** column name */
  HeightMm = "height_mm",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LengthMm = "length_mm",
  /** column name */
  ProductName = "product_name",
  /** column name */
  ServiceAccountId = "service_account_id",
  /** column name */
  WeightGrams = "weight_grams",
  /** column name */
  WidthMm = "width_mm",
}

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: "product_var_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: "product_var_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: "product_variance_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_quantity?: Maybe<Scalars["Float"]>;
  case_product_id?: Maybe<Scalars["Float"]>;
  case_product_quantity?: Maybe<Scalars["Float"]>;
  height_mm?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  length_mm?: Maybe<Scalars["Float"]>;
  service_account_id?: Maybe<Scalars["Float"]>;
  weight_grams?: Maybe<Scalars["Float"]>;
  width_mm?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_quantity?: Maybe<Order_By>;
  case_product_id?: Maybe<Order_By>;
  case_product_quantity?: Maybe<Order_By>;
  height_mm?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  length_mm?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  weight_grams?: Maybe<Order_By>;
  width_mm?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** fetch data from the table: "attachment_type" */
  attachment_type: Array<Attachment_Type>;
  /** fetch aggregated fields from the table: "attachment_type" */
  attachment_type_aggregate: Attachment_Type_Aggregate;
  /** fetch data from the table: "attachment_type" using primary key columns */
  attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** fetch data from the table: "base_product" */
  base_product: Array<Base_Product>;
  /** fetch aggregated fields from the table: "base_product" */
  base_product_aggregate: Base_Product_Aggregate;
  /** fetch data from the table: "case_change" */
  case_change: Array<Case_Change>;
  /** fetch aggregated fields from the table: "case_change" */
  case_change_aggregate: Case_Change_Aggregate;
  /** fetch data from the table: "case_change" using primary key columns */
  case_change_by_pk?: Maybe<Case_Change>;
  /** fetch data from the table: "case_change_input_line" */
  case_change_input_line: Array<Case_Change_Input_Line>;
  /** fetch aggregated fields from the table: "case_change_input_line" */
  case_change_input_line_aggregate: Case_Change_Input_Line_Aggregate;
  /** fetch data from the table: "case_change_input_line" using primary key columns */
  case_change_input_line_by_pk?: Maybe<Case_Change_Input_Line>;
  /** fetch data from the table: "case_change_output_line" */
  case_change_output_line: Array<Case_Change_Output_Line>;
  /** fetch aggregated fields from the table: "case_change_output_line" */
  case_change_output_line_aggregate: Case_Change_Output_Line_Aggregate;
  /** fetch data from the table: "case_change_output_line" using primary key columns */
  case_change_output_line_by_pk?: Maybe<Case_Change_Output_Line>;
  /** fetch data from the table: "case_change_status" */
  case_change_status: Array<Case_Change_Status>;
  /** fetch aggregated fields from the table: "case_change_status" */
  case_change_status_aggregate: Case_Change_Status_Aggregate;
  /** fetch data from the table: "case_change_status" using primary key columns */
  case_change_status_by_pk?: Maybe<Case_Change_Status>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "courier" */
  courier: Array<Courier>;
  /** fetch aggregated fields from the table: "courier" */
  courier_aggregate: Courier_Aggregate;
  /** fetch data from the table: "courier" using primary key columns */
  courier_by_pk?: Maybe<Courier>;
  /** fetch data from the table: "courier_location" */
  courier_location: Array<Courier_Location>;
  /** fetch aggregated fields from the table: "courier_location" */
  courier_location_aggregate: Courier_Location_Aggregate;
  /** fetch data from the table: "courier_location" using primary key columns */
  courier_location_by_pk?: Maybe<Courier_Location>;
  /** fetch data from the table: "courier_service" */
  courier_service: Array<Courier_Service>;
  /** fetch aggregated fields from the table: "courier_service" */
  courier_service_aggregate: Courier_Service_Aggregate;
  /** fetch data from the table: "courier_service" using primary key columns */
  courier_service_by_pk?: Maybe<Courier_Service>;
  /** fetch data from the table: "databasechangelog" */
  databasechangelog: Array<Databasechangelog>;
  /** fetch aggregated fields from the table: "databasechangelog" */
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  /** fetch data from the table: "databasechangeloglock" */
  databasechangeloglock: Array<Databasechangeloglock>;
  /** fetch aggregated fields from the table: "databasechangeloglock" */
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  /** fetch data from the table: "databasechangeloglock" using primary key columns */
  databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** fetch data from the table: "file_copy_deletion" */
  file_copy_deletion: Array<File_Copy_Deletion>;
  /** fetch aggregated fields from the table: "file_copy_deletion" */
  file_copy_deletion_aggregate: File_Copy_Deletion_Aggregate;
  /** fetch data from the table: "file_copy_deletion" using primary key columns */
  file_copy_deletion_by_pk?: Maybe<File_Copy_Deletion>;
  /** fetch data from the table: "folder" */
  folder: Array<Folder>;
  /** fetch aggregated fields from the table: "folder" */
  folder_aggregate: Folder_Aggregate;
  /** fetch data from the table: "folder" using primary key columns */
  folder_by_pk?: Maybe<Folder>;
  /** fetch data from the table: "inbound_manifest" */
  inbound_manifest: Array<Inbound_Manifest>;
  /** fetch aggregated fields from the table: "inbound_manifest" */
  inbound_manifest_aggregate: Inbound_Manifest_Aggregate;
  /** fetch data from the table: "inbound_manifest" using primary key columns */
  inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line: Array<Inbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" using primary key columns */
  inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "inbound_manifest_line" */
  inbound_manifest_line: Array<Inbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_line" */
  inbound_manifest_line_aggregate: Inbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_line" using primary key columns */
  inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** fetch data from the table: "inbound_manifest_line_status" */
  inbound_manifest_line_status: Array<Inbound_Manifest_Line_Status>;
  /** fetch aggregated fields from the table: "inbound_manifest_line_status" */
  inbound_manifest_line_status_aggregate: Inbound_Manifest_Line_Status_Aggregate;
  /** fetch data from the table: "inbound_manifest_line_status" using primary key columns */
  inbound_manifest_line_status_by_pk?: Maybe<Inbound_Manifest_Line_Status>;
  /** fetch data from the table: "inbound_manifest_status" */
  inbound_manifest_status: Array<Inbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "inbound_manifest_status" */
  inbound_manifest_status_aggregate: Inbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "inbound_manifest_status" using primary key columns */
  inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** fetch data from the table: "internal_transfer" */
  internal_transfer: Array<Internal_Transfer>;
  /** fetch aggregated fields from the table: "internal_transfer" */
  internal_transfer_aggregate: Internal_Transfer_Aggregate;
  /** fetch data from the table: "internal_transfer" using primary key columns */
  internal_transfer_by_pk?: Maybe<Internal_Transfer>;
  /** fetch data from the table: "internal_transfer_line" */
  internal_transfer_line: Array<Internal_Transfer_Line>;
  /** fetch aggregated fields from the table: "internal_transfer_line" */
  internal_transfer_line_aggregate: Internal_Transfer_Line_Aggregate;
  /** fetch data from the table: "internal_transfer_line" using primary key columns */
  internal_transfer_line_by_pk?: Maybe<Internal_Transfer_Line>;
  /** fetch data from the table: "internal_transfer_line_status" */
  internal_transfer_line_status: Array<Internal_Transfer_Line_Status>;
  /** fetch aggregated fields from the table: "internal_transfer_line_status" */
  internal_transfer_line_status_aggregate: Internal_Transfer_Line_Status_Aggregate;
  /** fetch data from the table: "internal_transfer_line_status" using primary key columns */
  internal_transfer_line_status_by_pk?: Maybe<Internal_Transfer_Line_Status>;
  /** fetch data from the table: "internal_transfer_status" */
  internal_transfer_status: Array<Internal_Transfer_Status>;
  /** fetch aggregated fields from the table: "internal_transfer_status" */
  internal_transfer_status_aggregate: Internal_Transfer_Status_Aggregate;
  /** fetch data from the table: "internal_transfer_status" using primary key columns */
  internal_transfer_status_by_pk?: Maybe<Internal_Transfer_Status>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table: "location_type" */
  location_type: Array<Location_Type>;
  /** fetch aggregated fields from the table: "location_type" */
  location_type_aggregate: Location_Type_Aggregate;
  /** fetch data from the table: "location_type" using primary key columns */
  location_type_by_pk?: Maybe<Location_Type>;
  /** fetch data from the table: "logistics_type" */
  logistics_type: Array<Logistics_Type>;
  /** fetch aggregated fields from the table: "logistics_type" */
  logistics_type_aggregate: Logistics_Type_Aggregate;
  /** fetch data from the table: "logistics_type" using primary key columns */
  logistics_type_by_pk?: Maybe<Logistics_Type>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table: "outbound_manifest" */
  outbound_manifest: Array<Outbound_Manifest>;
  /** fetch aggregated fields from the table: "outbound_manifest" */
  outbound_manifest_aggregate: Outbound_Manifest_Aggregate;
  /** fetch data from the table: "outbound_manifest" using primary key columns */
  outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** fetch data from the table: "outbound_manifest_fulfilment" */
  outbound_manifest_fulfilment: Array<Outbound_Manifest_Fulfilment>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment" */
  outbound_manifest_fulfilment_aggregate: Outbound_Manifest_Fulfilment_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment" using primary key columns */
  outbound_manifest_fulfilment_by_pk?: Maybe<Outbound_Manifest_Fulfilment>;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line: Array<Outbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" using primary key columns */
  outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "outbound_manifest_fulfilment_line_status" */
  outbound_manifest_fulfilment_line_status: Array<Outbound_Manifest_Fulfilment_Line_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_line_status" */
  outbound_manifest_fulfilment_line_status_aggregate: Outbound_Manifest_Fulfilment_Line_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_line_status" using primary key columns */
  outbound_manifest_fulfilment_line_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line_Status>;
  /** fetch data from the table: "outbound_manifest_fulfilment_status" */
  outbound_manifest_fulfilment_status: Array<Outbound_Manifest_Fulfilment_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_status" */
  outbound_manifest_fulfilment_status_aggregate: Outbound_Manifest_Fulfilment_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_status" using primary key columns */
  outbound_manifest_fulfilment_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Status>;
  /** fetch data from the table: "outbound_manifest_line" */
  outbound_manifest_line: Array<Outbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_line" */
  outbound_manifest_line_aggregate: Outbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_line" using primary key columns */
  outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** fetch data from the table: "outbound_manifest_line_status" */
  outbound_manifest_line_status: Array<Outbound_Manifest_Line_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_line_status" */
  outbound_manifest_line_status_aggregate: Outbound_Manifest_Line_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_line_status" using primary key columns */
  outbound_manifest_line_status_by_pk?: Maybe<Outbound_Manifest_Line_Status>;
  /** fetch data from the table: "outbound_manifest_return" */
  outbound_manifest_return: Array<Outbound_Manifest_Return>;
  /** fetch aggregated fields from the table: "outbound_manifest_return" */
  outbound_manifest_return_aggregate: Outbound_Manifest_Return_Aggregate;
  /** fetch data from the table: "outbound_manifest_return" using primary key columns */
  outbound_manifest_return_by_pk?: Maybe<Outbound_Manifest_Return>;
  /** fetch data from the table: "outbound_manifest_return_line" */
  outbound_manifest_return_line: Array<Outbound_Manifest_Return_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_return_line" */
  outbound_manifest_return_line_aggregate: Outbound_Manifest_Return_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_return_line" using primary key columns */
  outbound_manifest_return_line_by_pk?: Maybe<Outbound_Manifest_Return_Line>;
  /** fetch data from the table: "outbound_manifest_status" */
  outbound_manifest_status: Array<Outbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_status" */
  outbound_manifest_status_aggregate: Outbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_status" using primary key columns */
  outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_stock" */
  product_stock: Array<Product_Stock>;
  /** fetch aggregated fields from the table: "product_stock" */
  product_stock_aggregate: Product_Stock_Aggregate;
  /** fetch data from the table: "product_stock" using primary key columns */
  product_stock_by_pk?: Maybe<Product_Stock>;
  /** fetch data from the table: "recipe" */
  recipe: Array<Recipe>;
  /** fetch aggregated fields from the table: "recipe" */
  recipe_aggregate: Recipe_Aggregate;
  /** fetch data from the table: "recipe" using primary key columns */
  recipe_by_pk?: Maybe<Recipe>;
  /** fetch data from the table: "recipe_input" */
  recipe_input: Array<Recipe_Input>;
  /** fetch aggregated fields from the table: "recipe_input" */
  recipe_input_aggregate: Recipe_Input_Aggregate;
  /** fetch data from the table: "recipe_input" using primary key columns */
  recipe_input_by_pk?: Maybe<Recipe_Input>;
  /** fetch data from the table: "recipe_output" */
  recipe_output: Array<Recipe_Output>;
  /** fetch aggregated fields from the table: "recipe_output" */
  recipe_output_aggregate: Recipe_Output_Aggregate;
  /** fetch data from the table: "recipe_output" using primary key columns */
  recipe_output_by_pk?: Maybe<Recipe_Output>;
  /** fetch data from the table: "recipe_type" */
  recipe_type: Array<Recipe_Type>;
  /** fetch aggregated fields from the table: "recipe_type" */
  recipe_type_aggregate: Recipe_Type_Aggregate;
  /** fetch data from the table: "recipe_type" using primary key columns */
  recipe_type_by_pk?: Maybe<Recipe_Type>;
  /** fetch data from the table: "service_account" */
  service_account: Array<Service_Account>;
  /** fetch aggregated fields from the table: "service_account" */
  service_account_aggregate: Service_Account_Aggregate;
  /** fetch data from the table: "service_account" using primary key columns */
  service_account_by_pk?: Maybe<Service_Account>;
  /** fetch data from the table: "stock_allocation" */
  stock_allocation: Array<Stock_Allocation>;
  /** fetch aggregated fields from the table: "stock_allocation" */
  stock_allocation_aggregate: Stock_Allocation_Aggregate;
  /** fetch data from the table: "stock_allocation" using primary key columns */
  stock_allocation_by_pk?: Maybe<Stock_Allocation>;
  /** fetch data from the table: "stock_allocation_fulfilment" */
  stock_allocation_fulfilment: Array<Stock_Allocation_Fulfilment>;
  /** fetch aggregated fields from the table: "stock_allocation_fulfilment" */
  stock_allocation_fulfilment_aggregate: Stock_Allocation_Fulfilment_Aggregate;
  /** fetch data from the table: "stock_allocation_fulfilment" using primary key columns */
  stock_allocation_fulfilment_by_pk?: Maybe<Stock_Allocation_Fulfilment>;
  /** fetch data from the table: "stock_check" */
  stock_check: Array<Stock_Check>;
  /** fetch aggregated fields from the table: "stock_check" */
  stock_check_aggregate: Stock_Check_Aggregate;
  /** fetch data from the table: "stock_check" using primary key columns */
  stock_check_by_pk?: Maybe<Stock_Check>;
  /** fetch data from the table: "stock_check_line" */
  stock_check_line: Array<Stock_Check_Line>;
  /** fetch aggregated fields from the table: "stock_check_line" */
  stock_check_line_aggregate: Stock_Check_Line_Aggregate;
  /** fetch data from the table: "stock_check_line" using primary key columns */
  stock_check_line_by_pk?: Maybe<Stock_Check_Line>;
  /** fetch data from the table: "stock_check_line_fulfilment" */
  stock_check_line_fulfilment: Array<Stock_Check_Line_Fulfilment>;
  /** fetch aggregated fields from the table: "stock_check_line_fulfilment" */
  stock_check_line_fulfilment_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  /** fetch data from the table: "stock_check_line_fulfilment" using primary key columns */
  stock_check_line_fulfilment_by_pk?: Maybe<Stock_Check_Line_Fulfilment>;
  /** fetch data from the table: "stock_check_line_fulfilment_status" */
  stock_check_line_fulfilment_status: Array<Stock_Check_Line_Fulfilment_Status>;
  /** fetch aggregated fields from the table: "stock_check_line_fulfilment_status" */
  stock_check_line_fulfilment_status_aggregate: Stock_Check_Line_Fulfilment_Status_Aggregate;
  /** fetch data from the table: "stock_check_line_fulfilment_status" using primary key columns */
  stock_check_line_fulfilment_status_by_pk?: Maybe<Stock_Check_Line_Fulfilment_Status>;
  /** fetch data from the table: "stock_check_line_status" */
  stock_check_line_status: Array<Stock_Check_Line_Status>;
  /** fetch aggregated fields from the table: "stock_check_line_status" */
  stock_check_line_status_aggregate: Stock_Check_Line_Status_Aggregate;
  /** fetch data from the table: "stock_check_line_status" using primary key columns */
  stock_check_line_status_by_pk?: Maybe<Stock_Check_Line_Status>;
  /** fetch data from the table: "stock_check_status" */
  stock_check_status: Array<Stock_Check_Status>;
  /** fetch aggregated fields from the table: "stock_check_status" */
  stock_check_status_aggregate: Stock_Check_Status_Aggregate;
  /** fetch data from the table: "stock_check_status" using primary key columns */
  stock_check_status_by_pk?: Maybe<Stock_Check_Status>;
  /** fetch data from the table: "stock_check_type" */
  stock_check_type: Array<Stock_Check_Type>;
  /** fetch aggregated fields from the table: "stock_check_type" */
  stock_check_type_aggregate: Stock_Check_Type_Aggregate;
  /** fetch data from the table: "stock_check_type" using primary key columns */
  stock_check_type_by_pk?: Maybe<Stock_Check_Type>;
  /** fetch data from the table: "stock_condition" */
  stock_condition: Array<Stock_Condition>;
  /** fetch aggregated fields from the table: "stock_condition" */
  stock_condition_aggregate: Stock_Condition_Aggregate;
  /** fetch data from the table: "stock_condition" using primary key columns */
  stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** fetch data from the table: "stock_status" */
  stock_status: Array<Stock_Status>;
  /** fetch aggregated fields from the table: "stock_status" */
  stock_status_aggregate: Stock_Status_Aggregate;
  /** fetch data from the table: "stock_status" using primary key columns */
  stock_status_by_pk?: Maybe<Stock_Status>;
  /** fetch data from the table: "vw_finance_stock_report" */
  vw_finance_stock_report: Array<Vw_Finance_Stock_Report>;
  /** fetch aggregated fields from the table: "vw_finance_stock_report" */
  vw_finance_stock_report_aggregate: Vw_Finance_Stock_Report_Aggregate;
  /** fetch data from the table: "vw_inventory_report" */
  vw_inventory_report: Array<Vw_Inventory_Report>;
  /** fetch aggregated fields from the table: "vw_inventory_report" */
  vw_inventory_report_aggregate: Vw_Inventory_Report_Aggregate;
  /** fetch data from the table: "warehouse" */
  warehouse: Array<Warehouse>;
  /** fetch aggregated fields from the table: "warehouse" */
  warehouse_aggregate: Warehouse_Aggregate;
  /** fetch data from the table: "warehouse" using primary key columns */
  warehouse_by_pk?: Maybe<Warehouse>;
  /** fetch data from the table: "work_order" */
  work_order: Array<Work_Order>;
  /** fetch aggregated fields from the table: "work_order" */
  work_order_aggregate: Work_Order_Aggregate;
  /** fetch data from the table: "work_order" using primary key columns */
  work_order_by_pk?: Maybe<Work_Order>;
  /** fetch data from the table: "work_order_input_line" */
  work_order_input_line: Array<Work_Order_Input_Line>;
  /** fetch aggregated fields from the table: "work_order_input_line" */
  work_order_input_line_aggregate: Work_Order_Input_Line_Aggregate;
  /** fetch data from the table: "work_order_input_line" using primary key columns */
  work_order_input_line_by_pk?: Maybe<Work_Order_Input_Line>;
  /** fetch data from the table: "work_order_output_line" */
  work_order_output_line: Array<Work_Order_Output_Line>;
  /** fetch aggregated fields from the table: "work_order_output_line" */
  work_order_output_line_aggregate: Work_Order_Output_Line_Aggregate;
  /** fetch data from the table: "work_order_output_line" using primary key columns */
  work_order_output_line_by_pk?: Maybe<Work_Order_Output_Line>;
  /** fetch data from the table: "work_order_status" */
  work_order_status: Array<Work_Order_Status>;
  /** fetch aggregated fields from the table: "work_order_status" */
  work_order_status_aggregate: Work_Order_Status_Aggregate;
  /** fetch data from the table: "work_order_status" using primary key columns */
  work_order_status_by_pk?: Maybe<Work_Order_Status>;
};

export type Query_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

export type Query_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

export type Query_RootAddress_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootAttachmentArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

export type Query_RootAttachment_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

export type Query_RootAttachment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootAttachment_TypeArgs = {
  distinct_on?: Maybe<Array<Attachment_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Type_Order_By>>;
  where?: Maybe<Attachment_Type_Bool_Exp>;
};

export type Query_RootAttachment_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Type_Order_By>>;
  where?: Maybe<Attachment_Type_Bool_Exp>;
};

export type Query_RootAttachment_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootBase_ProductArgs = {
  distinct_on?: Maybe<Array<Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Base_Product_Order_By>>;
  where?: Maybe<Base_Product_Bool_Exp>;
};

export type Query_RootBase_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Base_Product_Order_By>>;
  where?: Maybe<Base_Product_Bool_Exp>;
};

export type Query_RootCase_ChangeArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

export type Query_RootCase_Change_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

export type Query_RootCase_Change_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCase_Change_Input_LineArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

export type Query_RootCase_Change_Input_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

export type Query_RootCase_Change_Input_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCase_Change_Output_LineArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

export type Query_RootCase_Change_Output_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

export type Query_RootCase_Change_Output_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCase_Change_StatusArgs = {
  distinct_on?: Maybe<Array<Case_Change_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Status_Order_By>>;
  where?: Maybe<Case_Change_Status_Bool_Exp>;
};

export type Query_RootCase_Change_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Status_Order_By>>;
  where?: Maybe<Case_Change_Status_Bool_Exp>;
};

export type Query_RootCase_Change_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCountryArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};

export type Query_RootCountry_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};

export type Query_RootCountry_By_PkArgs = {
  country_code: Scalars["String"];
};

export type Query_RootCourierArgs = {
  distinct_on?: Maybe<Array<Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Order_By>>;
  where?: Maybe<Courier_Bool_Exp>;
};

export type Query_RootCourier_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Order_By>>;
  where?: Maybe<Courier_Bool_Exp>;
};

export type Query_RootCourier_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCourier_LocationArgs = {
  distinct_on?: Maybe<Array<Courier_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Location_Order_By>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

export type Query_RootCourier_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Location_Order_By>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

export type Query_RootCourier_Location_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootCourier_ServiceArgs = {
  distinct_on?: Maybe<Array<Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Service_Order_By>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

export type Query_RootCourier_Service_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Service_Order_By>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

export type Query_RootCourier_Service_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootDatabasechangelogArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};

export type Query_RootDatabasechangelog_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};

export type Query_RootDatabasechangeloglockArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

export type Query_RootDatabasechangeloglock_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

export type Query_RootDatabasechangeloglock_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootFile_Copy_DeletionArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

export type Query_RootFile_Copy_Deletion_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

export type Query_RootFile_Copy_Deletion_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootFolderArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};

export type Query_RootFolder_AggregateArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};

export type Query_RootFolder_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

export type Query_RootInbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

export type Query_RootInbound_Manifest_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInbound_Manifest_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

export type Query_RootInbound_Manifest_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInternal_TransferArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

export type Query_RootInternal_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

export type Query_RootInternal_Transfer_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInternal_Transfer_LineArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

export type Query_RootInternal_Transfer_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

export type Query_RootInternal_Transfer_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInternal_Transfer_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
};

export type Query_RootInternal_Transfer_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
};

export type Query_RootInternal_Transfer_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootInternal_Transfer_StatusArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Status_Bool_Exp>;
};

export type Query_RootInternal_Transfer_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Status_Bool_Exp>;
};

export type Query_RootInternal_Transfer_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootLocationArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

export type Query_RootLocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

export type Query_RootLocation_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootLocation_TypeArgs = {
  distinct_on?: Maybe<Array<Location_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Type_Order_By>>;
  where?: Maybe<Location_Type_Bool_Exp>;
};

export type Query_RootLocation_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Type_Order_By>>;
  where?: Maybe<Location_Type_Bool_Exp>;
};

export type Query_RootLocation_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootLogistics_TypeArgs = {
  distinct_on?: Maybe<Array<Logistics_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Logistics_Type_Order_By>>;
  where?: Maybe<Logistics_Type_Bool_Exp>;
};

export type Query_RootLogistics_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Logistics_Type_Order_By>>;
  where?: Maybe<Logistics_Type_Bool_Exp>;
};

export type Query_RootLogistics_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOrganisationArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

export type Query_RootOrganisation_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

export type Query_RootOrganisation_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_FulfilmentArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_Fulfilment_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_Fulfilment_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Fulfilment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_ReturnArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Return_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Return_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_Return_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Return_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Return_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootOutbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

export type Query_RootOutbound_Manifest_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

export type Query_RootProduct_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootProduct_StockArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

export type Query_RootProduct_Stock_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

export type Query_RootProduct_Stock_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootRecipeArgs = {
  distinct_on?: Maybe<Array<Recipe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Order_By>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

export type Query_RootRecipe_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Order_By>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

export type Query_RootRecipe_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootRecipe_InputArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

export type Query_RootRecipe_Input_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

export type Query_RootRecipe_Input_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootRecipe_OutputArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

export type Query_RootRecipe_Output_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

export type Query_RootRecipe_Output_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootRecipe_TypeArgs = {
  distinct_on?: Maybe<Array<Recipe_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Type_Order_By>>;
  where?: Maybe<Recipe_Type_Bool_Exp>;
};

export type Query_RootRecipe_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Type_Order_By>>;
  where?: Maybe<Recipe_Type_Bool_Exp>;
};

export type Query_RootRecipe_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootService_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

export type Query_RootService_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

export type Query_RootService_Account_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_AllocationArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

export type Query_RootStock_Allocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

export type Query_RootStock_Allocation_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Allocation_FulfilmentArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

export type Query_RootStock_Allocation_Fulfilment_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

export type Query_RootStock_Allocation_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_CheckArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

export type Query_RootStock_Check_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

export type Query_RootStock_Check_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Check_LineArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

export type Query_RootStock_Check_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

export type Query_RootStock_Check_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Check_Line_FulfilmentArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

export type Query_RootStock_Check_Line_Fulfilment_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

export type Query_RootStock_Check_Line_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Check_Line_Fulfilment_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
};

export type Query_RootStock_Check_Line_Fulfilment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
};

export type Query_RootStock_Check_Line_Fulfilment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Check_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
};

export type Query_RootStock_Check_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
};

export type Query_RootStock_Check_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Check_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Status_Order_By>>;
  where?: Maybe<Stock_Check_Status_Bool_Exp>;
};

export type Query_RootStock_Check_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Status_Order_By>>;
  where?: Maybe<Stock_Check_Status_Bool_Exp>;
};

export type Query_RootStock_Check_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_Check_TypeArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Type_Order_By>>;
  where?: Maybe<Stock_Check_Type_Bool_Exp>;
};

export type Query_RootStock_Check_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Type_Order_By>>;
  where?: Maybe<Stock_Check_Type_Bool_Exp>;
};

export type Query_RootStock_Check_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_ConditionArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

export type Query_RootStock_Condition_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

export type Query_RootStock_Condition_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootStock_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

export type Query_RootStock_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

export type Query_RootStock_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootVw_Finance_Stock_ReportArgs = {
  distinct_on?: Maybe<Array<Vw_Finance_Stock_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Finance_Stock_Report_Order_By>>;
  where?: Maybe<Vw_Finance_Stock_Report_Bool_Exp>;
};

export type Query_RootVw_Finance_Stock_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Finance_Stock_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Finance_Stock_Report_Order_By>>;
  where?: Maybe<Vw_Finance_Stock_Report_Bool_Exp>;
};

export type Query_RootVw_Inventory_ReportArgs = {
  distinct_on?: Maybe<Array<Vw_Inventory_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Inventory_Report_Order_By>>;
  where?: Maybe<Vw_Inventory_Report_Bool_Exp>;
};

export type Query_RootVw_Inventory_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Inventory_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Inventory_Report_Order_By>>;
  where?: Maybe<Vw_Inventory_Report_Bool_Exp>;
};

export type Query_RootWarehouseArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

export type Query_RootWarehouse_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

export type Query_RootWarehouse_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootWork_OrderArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

export type Query_RootWork_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

export type Query_RootWork_Order_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootWork_Order_Input_LineArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

export type Query_RootWork_Order_Input_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

export type Query_RootWork_Order_Input_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootWork_Order_Output_LineArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

export type Query_RootWork_Order_Output_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

export type Query_RootWork_Order_Output_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootWork_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
};

export type Query_RootWork_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
};

export type Query_RootWork_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** columns and relationships of "recipe" */
export type Recipe = {
  __typename?: "recipe";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  is_draft: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  recipe_inputs: Array<Recipe_Input>;
  /** An aggregate relationship */
  recipe_inputs_aggregate: Recipe_Input_Aggregate;
  recipe_name: Scalars["String"];
  /** An array relationship */
  recipe_outputs: Array<Recipe_Output>;
  /** An aggregate relationship */
  recipe_outputs_aggregate: Recipe_Output_Aggregate;
  /** An object relationship */
  recipe_type: Recipe_Type;
  recipe_type_id: Scalars["Int"];
  use_for_reporting: Scalars["Boolean"];
  /** An array relationship */
  work_orders: Array<Work_Order>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Order_Aggregate;
};

/** columns and relationships of "recipe" */
export type RecipeRecipe_InputsArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

/** columns and relationships of "recipe" */
export type RecipeRecipe_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

/** columns and relationships of "recipe" */
export type RecipeRecipe_OutputsArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

/** columns and relationships of "recipe" */
export type RecipeRecipe_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

/** columns and relationships of "recipe" */
export type RecipeWork_OrdersArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** columns and relationships of "recipe" */
export type RecipeWork_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** aggregated selection of "recipe" */
export type Recipe_Aggregate = {
  __typename?: "recipe_aggregate";
  aggregate?: Maybe<Recipe_Aggregate_Fields>;
  nodes: Array<Recipe>;
};

export type Recipe_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Recipe_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Recipe_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Recipe_Aggregate_Bool_Exp_Count>;
};

export type Recipe_Aggregate_Bool_Exp_Bool_And = {
  arguments: Recipe_Select_Column_Recipe_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Recipe_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Recipe_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Recipe_Select_Column_Recipe_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Recipe_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Recipe_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Recipe_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Recipe_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recipe" */
export type Recipe_Aggregate_Fields = {
  __typename?: "recipe_aggregate_fields";
  avg?: Maybe<Recipe_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Recipe_Max_Fields>;
  min?: Maybe<Recipe_Min_Fields>;
  stddev?: Maybe<Recipe_Stddev_Fields>;
  stddev_pop?: Maybe<Recipe_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recipe_Stddev_Samp_Fields>;
  sum?: Maybe<Recipe_Sum_Fields>;
  var_pop?: Maybe<Recipe_Var_Pop_Fields>;
  var_samp?: Maybe<Recipe_Var_Samp_Fields>;
  variance?: Maybe<Recipe_Variance_Fields>;
};

/** aggregate fields of "recipe" */
export type Recipe_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recipe_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "recipe" */
export type Recipe_Aggregate_Order_By = {
  avg?: Maybe<Recipe_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Recipe_Max_Order_By>;
  min?: Maybe<Recipe_Min_Order_By>;
  stddev?: Maybe<Recipe_Stddev_Order_By>;
  stddev_pop?: Maybe<Recipe_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Recipe_Stddev_Samp_Order_By>;
  sum?: Maybe<Recipe_Sum_Order_By>;
  var_pop?: Maybe<Recipe_Var_Pop_Order_By>;
  var_samp?: Maybe<Recipe_Var_Samp_Order_By>;
  variance?: Maybe<Recipe_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recipe" */
export type Recipe_Arr_Rel_Insert_Input = {
  data: Array<Recipe_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recipe_On_Conflict>;
};

/** aggregate avg on columns */
export type Recipe_Avg_Fields = {
  __typename?: "recipe_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "recipe" */
export type Recipe_Avg_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recipe". All fields are combined with a logical 'AND'. */
export type Recipe_Bool_Exp = {
  _and?: Maybe<Array<Recipe_Bool_Exp>>;
  _not?: Maybe<Recipe_Bool_Exp>;
  _or?: Maybe<Array<Recipe_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_draft?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  recipe_inputs?: Maybe<Recipe_Input_Bool_Exp>;
  recipe_inputs_aggregate?: Maybe<Recipe_Input_Aggregate_Bool_Exp>;
  recipe_name?: Maybe<String_Comparison_Exp>;
  recipe_outputs?: Maybe<Recipe_Output_Bool_Exp>;
  recipe_outputs_aggregate?: Maybe<Recipe_Output_Aggregate_Bool_Exp>;
  recipe_type?: Maybe<Recipe_Type_Bool_Exp>;
  recipe_type_id?: Maybe<Int_Comparison_Exp>;
  use_for_reporting?: Maybe<Boolean_Comparison_Exp>;
  work_orders?: Maybe<Work_Order_Bool_Exp>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "recipe" */
export enum Recipe_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipePkey = "recipe_pkey",
  /** unique or primary key constraint on columns "recipe_name" */
  RecipeRecipeNameKey = "recipe_recipe_name_key",
}

/** input type for incrementing numeric columns in table "recipe" */
export type Recipe_Inc_Input = {
  recipe_type_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "recipe_input" */
export type Recipe_Input = {
  __typename?: "recipe_input";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  quantity: Scalars["Int"];
  /** An object relationship */
  recipe: Recipe;
  recipe_id: Scalars["Int"];
};

/** aggregated selection of "recipe_input" */
export type Recipe_Input_Aggregate = {
  __typename?: "recipe_input_aggregate";
  aggregate?: Maybe<Recipe_Input_Aggregate_Fields>;
  nodes: Array<Recipe_Input>;
};

export type Recipe_Input_Aggregate_Bool_Exp = {
  count?: Maybe<Recipe_Input_Aggregate_Bool_Exp_Count>;
};

export type Recipe_Input_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Recipe_Input_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Recipe_Input_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recipe_input" */
export type Recipe_Input_Aggregate_Fields = {
  __typename?: "recipe_input_aggregate_fields";
  avg?: Maybe<Recipe_Input_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Recipe_Input_Max_Fields>;
  min?: Maybe<Recipe_Input_Min_Fields>;
  stddev?: Maybe<Recipe_Input_Stddev_Fields>;
  stddev_pop?: Maybe<Recipe_Input_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recipe_Input_Stddev_Samp_Fields>;
  sum?: Maybe<Recipe_Input_Sum_Fields>;
  var_pop?: Maybe<Recipe_Input_Var_Pop_Fields>;
  var_samp?: Maybe<Recipe_Input_Var_Samp_Fields>;
  variance?: Maybe<Recipe_Input_Variance_Fields>;
};

/** aggregate fields of "recipe_input" */
export type Recipe_Input_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recipe_Input_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "recipe_input" */
export type Recipe_Input_Aggregate_Order_By = {
  avg?: Maybe<Recipe_Input_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Recipe_Input_Max_Order_By>;
  min?: Maybe<Recipe_Input_Min_Order_By>;
  stddev?: Maybe<Recipe_Input_Stddev_Order_By>;
  stddev_pop?: Maybe<Recipe_Input_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Recipe_Input_Stddev_Samp_Order_By>;
  sum?: Maybe<Recipe_Input_Sum_Order_By>;
  var_pop?: Maybe<Recipe_Input_Var_Pop_Order_By>;
  var_samp?: Maybe<Recipe_Input_Var_Samp_Order_By>;
  variance?: Maybe<Recipe_Input_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recipe_input" */
export type Recipe_Input_Arr_Rel_Insert_Input = {
  data: Array<Recipe_Input_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recipe_Input_On_Conflict>;
};

/** aggregate avg on columns */
export type Recipe_Input_Avg_Fields = {
  __typename?: "recipe_input_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "recipe_input" */
export type Recipe_Input_Avg_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recipe_input". All fields are combined with a logical 'AND'. */
export type Recipe_Input_Bool_Exp = {
  _and?: Maybe<Array<Recipe_Input_Bool_Exp>>;
  _not?: Maybe<Recipe_Input_Bool_Exp>;
  _or?: Maybe<Array<Recipe_Input_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  recipe?: Maybe<Recipe_Bool_Exp>;
  recipe_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recipe_input" */
export enum Recipe_Input_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipeInputPkey = "recipe_input_pkey",
}

/** input type for incrementing numeric columns in table "recipe_input" */
export type Recipe_Input_Inc_Input = {
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "recipe_input" */
export type Recipe_Input_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe?: Maybe<Recipe_Obj_Rel_Insert_Input>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Recipe_Input_Max_Fields = {
  __typename?: "recipe_input_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "recipe_input" */
export type Recipe_Input_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recipe_Input_Min_Fields = {
  __typename?: "recipe_input_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "recipe_input" */
export type Recipe_Input_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recipe_input" */
export type Recipe_Input_Mutation_Response = {
  __typename?: "recipe_input_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Recipe_Input>;
};

/** on_conflict condition type for table "recipe_input" */
export type Recipe_Input_On_Conflict = {
  constraint: Recipe_Input_Constraint;
  update_columns?: Array<Recipe_Input_Update_Column>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

/** Ordering options when selecting data from "recipe_input". */
export type Recipe_Input_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe?: Maybe<Recipe_Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recipe_input */
export type Recipe_Input_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "recipe_input" */
export enum Recipe_Input_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecipeId = "recipe_id",
}

/** input type for updating data in table "recipe_input" */
export type Recipe_Input_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Recipe_Input_Stddev_Fields = {
  __typename?: "recipe_input_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "recipe_input" */
export type Recipe_Input_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recipe_Input_Stddev_Pop_Fields = {
  __typename?: "recipe_input_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "recipe_input" */
export type Recipe_Input_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recipe_Input_Stddev_Samp_Fields = {
  __typename?: "recipe_input_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "recipe_input" */
export type Recipe_Input_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "recipe_input" */
export type Recipe_Input_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Input_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recipe_Input_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Recipe_Input_Sum_Fields = {
  __typename?: "recipe_input_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "recipe_input" */
export type Recipe_Input_Sum_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** update columns of table "recipe_input" */
export enum Recipe_Input_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecipeId = "recipe_id",
}

export type Recipe_Input_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recipe_Input_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recipe_Input_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recipe_Input_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recipe_Input_Var_Pop_Fields = {
  __typename?: "recipe_input_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "recipe_input" */
export type Recipe_Input_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recipe_Input_Var_Samp_Fields = {
  __typename?: "recipe_input_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "recipe_input" */
export type Recipe_Input_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Recipe_Input_Variance_Fields = {
  __typename?: "recipe_input_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "recipe_input" */
export type Recipe_Input_Variance_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** input type for inserting data into table "recipe" */
export type Recipe_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_inputs?: Maybe<Recipe_Input_Arr_Rel_Insert_Input>;
  recipe_name?: Maybe<Scalars["String"]>;
  recipe_outputs?: Maybe<Recipe_Output_Arr_Rel_Insert_Input>;
  recipe_type?: Maybe<Recipe_Type_Obj_Rel_Insert_Input>;
  recipe_type_id?: Maybe<Scalars["Int"]>;
  use_for_reporting?: Maybe<Scalars["Boolean"]>;
  work_orders?: Maybe<Work_Order_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recipe_Max_Fields = {
  __typename?: "recipe_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_name?: Maybe<Scalars["String"]>;
  recipe_type_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "recipe" */
export type Recipe_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  recipe_name?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recipe_Min_Fields = {
  __typename?: "recipe_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_name?: Maybe<Scalars["String"]>;
  recipe_type_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "recipe" */
export type Recipe_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  recipe_name?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recipe" */
export type Recipe_Mutation_Response = {
  __typename?: "recipe_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Recipe>;
};

/** input type for inserting object relation for remote table "recipe" */
export type Recipe_Obj_Rel_Insert_Input = {
  data: Recipe_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recipe_On_Conflict>;
};

/** on_conflict condition type for table "recipe" */
export type Recipe_On_Conflict = {
  constraint: Recipe_Constraint;
  update_columns?: Array<Recipe_Update_Column>;
  where?: Maybe<Recipe_Bool_Exp>;
};

/** Ordering options when selecting data from "recipe". */
export type Recipe_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_draft?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  recipe_inputs_aggregate?: Maybe<Recipe_Input_Aggregate_Order_By>;
  recipe_name?: Maybe<Order_By>;
  recipe_outputs_aggregate?: Maybe<Recipe_Output_Aggregate_Order_By>;
  recipe_type?: Maybe<Recipe_Type_Order_By>;
  recipe_type_id?: Maybe<Order_By>;
  use_for_reporting?: Maybe<Order_By>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Order_By>;
};

/** columns and relationships of "recipe_output" */
export type Recipe_Output = {
  __typename?: "recipe_output";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  quantity: Scalars["Int"];
  /** An object relationship */
  recipe: Recipe;
  recipe_id: Scalars["Int"];
};

/** aggregated selection of "recipe_output" */
export type Recipe_Output_Aggregate = {
  __typename?: "recipe_output_aggregate";
  aggregate?: Maybe<Recipe_Output_Aggregate_Fields>;
  nodes: Array<Recipe_Output>;
};

export type Recipe_Output_Aggregate_Bool_Exp = {
  count?: Maybe<Recipe_Output_Aggregate_Bool_Exp_Count>;
};

export type Recipe_Output_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Recipe_Output_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Recipe_Output_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "recipe_output" */
export type Recipe_Output_Aggregate_Fields = {
  __typename?: "recipe_output_aggregate_fields";
  avg?: Maybe<Recipe_Output_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Recipe_Output_Max_Fields>;
  min?: Maybe<Recipe_Output_Min_Fields>;
  stddev?: Maybe<Recipe_Output_Stddev_Fields>;
  stddev_pop?: Maybe<Recipe_Output_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recipe_Output_Stddev_Samp_Fields>;
  sum?: Maybe<Recipe_Output_Sum_Fields>;
  var_pop?: Maybe<Recipe_Output_Var_Pop_Fields>;
  var_samp?: Maybe<Recipe_Output_Var_Samp_Fields>;
  variance?: Maybe<Recipe_Output_Variance_Fields>;
};

/** aggregate fields of "recipe_output" */
export type Recipe_Output_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recipe_Output_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "recipe_output" */
export type Recipe_Output_Aggregate_Order_By = {
  avg?: Maybe<Recipe_Output_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Recipe_Output_Max_Order_By>;
  min?: Maybe<Recipe_Output_Min_Order_By>;
  stddev?: Maybe<Recipe_Output_Stddev_Order_By>;
  stddev_pop?: Maybe<Recipe_Output_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Recipe_Output_Stddev_Samp_Order_By>;
  sum?: Maybe<Recipe_Output_Sum_Order_By>;
  var_pop?: Maybe<Recipe_Output_Var_Pop_Order_By>;
  var_samp?: Maybe<Recipe_Output_Var_Samp_Order_By>;
  variance?: Maybe<Recipe_Output_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "recipe_output" */
export type Recipe_Output_Arr_Rel_Insert_Input = {
  data: Array<Recipe_Output_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Recipe_Output_On_Conflict>;
};

/** aggregate avg on columns */
export type Recipe_Output_Avg_Fields = {
  __typename?: "recipe_output_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "recipe_output" */
export type Recipe_Output_Avg_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "recipe_output". All fields are combined with a logical 'AND'. */
export type Recipe_Output_Bool_Exp = {
  _and?: Maybe<Array<Recipe_Output_Bool_Exp>>;
  _not?: Maybe<Recipe_Output_Bool_Exp>;
  _or?: Maybe<Array<Recipe_Output_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  recipe?: Maybe<Recipe_Bool_Exp>;
  recipe_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "recipe_output" */
export enum Recipe_Output_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipeOutputPkey = "recipe_output_pkey",
}

/** input type for incrementing numeric columns in table "recipe_output" */
export type Recipe_Output_Inc_Input = {
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "recipe_output" */
export type Recipe_Output_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe?: Maybe<Recipe_Obj_Rel_Insert_Input>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Recipe_Output_Max_Fields = {
  __typename?: "recipe_output_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "recipe_output" */
export type Recipe_Output_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Recipe_Output_Min_Fields = {
  __typename?: "recipe_output_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "recipe_output" */
export type Recipe_Output_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "recipe_output" */
export type Recipe_Output_Mutation_Response = {
  __typename?: "recipe_output_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Recipe_Output>;
};

/** on_conflict condition type for table "recipe_output" */
export type Recipe_Output_On_Conflict = {
  constraint: Recipe_Output_Constraint;
  update_columns?: Array<Recipe_Output_Update_Column>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

/** Ordering options when selecting data from "recipe_output". */
export type Recipe_Output_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe?: Maybe<Recipe_Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recipe_output */
export type Recipe_Output_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "recipe_output" */
export enum Recipe_Output_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecipeId = "recipe_id",
}

/** input type for updating data in table "recipe_output" */
export type Recipe_Output_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Recipe_Output_Stddev_Fields = {
  __typename?: "recipe_output_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "recipe_output" */
export type Recipe_Output_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recipe_Output_Stddev_Pop_Fields = {
  __typename?: "recipe_output_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "recipe_output" */
export type Recipe_Output_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recipe_Output_Stddev_Samp_Fields = {
  __typename?: "recipe_output_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "recipe_output" */
export type Recipe_Output_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "recipe_output" */
export type Recipe_Output_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Output_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recipe_Output_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Recipe_Output_Sum_Fields = {
  __typename?: "recipe_output_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "recipe_output" */
export type Recipe_Output_Sum_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** update columns of table "recipe_output" */
export enum Recipe_Output_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecipeId = "recipe_id",
}

export type Recipe_Output_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recipe_Output_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recipe_Output_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recipe_Output_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recipe_Output_Var_Pop_Fields = {
  __typename?: "recipe_output_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "recipe_output" */
export type Recipe_Output_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recipe_Output_Var_Samp_Fields = {
  __typename?: "recipe_output_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "recipe_output" */
export type Recipe_Output_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Recipe_Output_Variance_Fields = {
  __typename?: "recipe_output_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "recipe_output" */
export type Recipe_Output_Variance_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
};

/** primary key columns input for table: recipe */
export type Recipe_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "recipe" */
export enum Recipe_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDraft = "is_draft",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RecipeName = "recipe_name",
  /** column name */
  RecipeTypeId = "recipe_type_id",
  /** column name */
  UseForReporting = "use_for_reporting",
}

/** select "recipe_aggregate_bool_exp_bool_and_arguments_columns" columns of table "recipe" */
export enum Recipe_Select_Column_Recipe_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDraft = "is_draft",
  /** column name */
  UseForReporting = "use_for_reporting",
}

/** select "recipe_aggregate_bool_exp_bool_or_arguments_columns" columns of table "recipe" */
export enum Recipe_Select_Column_Recipe_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDraft = "is_draft",
  /** column name */
  UseForReporting = "use_for_reporting",
}

/** input type for updating data in table "recipe" */
export type Recipe_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_name?: Maybe<Scalars["String"]>;
  recipe_type_id?: Maybe<Scalars["Int"]>;
  use_for_reporting?: Maybe<Scalars["Boolean"]>;
};

/** aggregate stddev on columns */
export type Recipe_Stddev_Fields = {
  __typename?: "recipe_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "recipe" */
export type Recipe_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Recipe_Stddev_Pop_Fields = {
  __typename?: "recipe_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "recipe" */
export type Recipe_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Recipe_Stddev_Samp_Fields = {
  __typename?: "recipe_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "recipe" */
export type Recipe_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "recipe" */
export type Recipe_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recipe_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_name?: Maybe<Scalars["String"]>;
  recipe_type_id?: Maybe<Scalars["Int"]>;
  use_for_reporting?: Maybe<Scalars["Boolean"]>;
};

/** aggregate sum on columns */
export type Recipe_Sum_Fields = {
  __typename?: "recipe_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  recipe_type_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "recipe" */
export type Recipe_Sum_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "recipe_type" */
export type Recipe_Type = {
  __typename?: "recipe_type";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  recipe_type_name: Scalars["String"];
  /** An array relationship */
  recipes: Array<Recipe>;
  /** An aggregate relationship */
  recipes_aggregate: Recipe_Aggregate;
};

/** columns and relationships of "recipe_type" */
export type Recipe_TypeRecipesArgs = {
  distinct_on?: Maybe<Array<Recipe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Order_By>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

/** columns and relationships of "recipe_type" */
export type Recipe_TypeRecipes_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Order_By>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

/** aggregated selection of "recipe_type" */
export type Recipe_Type_Aggregate = {
  __typename?: "recipe_type_aggregate";
  aggregate?: Maybe<Recipe_Type_Aggregate_Fields>;
  nodes: Array<Recipe_Type>;
};

/** aggregate fields of "recipe_type" */
export type Recipe_Type_Aggregate_Fields = {
  __typename?: "recipe_type_aggregate_fields";
  avg?: Maybe<Recipe_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Recipe_Type_Max_Fields>;
  min?: Maybe<Recipe_Type_Min_Fields>;
  stddev?: Maybe<Recipe_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Recipe_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Recipe_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Recipe_Type_Sum_Fields>;
  var_pop?: Maybe<Recipe_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Recipe_Type_Var_Samp_Fields>;
  variance?: Maybe<Recipe_Type_Variance_Fields>;
};

/** aggregate fields of "recipe_type" */
export type Recipe_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Recipe_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Recipe_Type_Avg_Fields = {
  __typename?: "recipe_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "recipe_type". All fields are combined with a logical 'AND'. */
export type Recipe_Type_Bool_Exp = {
  _and?: Maybe<Array<Recipe_Type_Bool_Exp>>;
  _not?: Maybe<Recipe_Type_Bool_Exp>;
  _or?: Maybe<Array<Recipe_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  recipe_type_name?: Maybe<String_Comparison_Exp>;
  recipes?: Maybe<Recipe_Bool_Exp>;
  recipes_aggregate?: Maybe<Recipe_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "recipe_type" */
export enum Recipe_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  RecipeTypePkey = "recipe_type_pkey",
  /** unique or primary key constraint on columns "recipe_type_name" */
  RecipeTypeRecipeTypeNameKey = "recipe_type_recipe_type_name_key",
}

/** input type for incrementing numeric columns in table "recipe_type" */
export type Recipe_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "recipe_type" */
export type Recipe_Type_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_type_name?: Maybe<Scalars["String"]>;
  recipes?: Maybe<Recipe_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Recipe_Type_Max_Fields = {
  __typename?: "recipe_type_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Recipe_Type_Min_Fields = {
  __typename?: "recipe_type_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_type_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "recipe_type" */
export type Recipe_Type_Mutation_Response = {
  __typename?: "recipe_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Recipe_Type>;
};

/** input type for inserting object relation for remote table "recipe_type" */
export type Recipe_Type_Obj_Rel_Insert_Input = {
  data: Recipe_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Recipe_Type_On_Conflict>;
};

/** on_conflict condition type for table "recipe_type" */
export type Recipe_Type_On_Conflict = {
  constraint: Recipe_Type_Constraint;
  update_columns?: Array<Recipe_Type_Update_Column>;
  where?: Maybe<Recipe_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "recipe_type". */
export type Recipe_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  recipe_type_name?: Maybe<Order_By>;
  recipes_aggregate?: Maybe<Recipe_Aggregate_Order_By>;
};

/** primary key columns input for table: recipe_type */
export type Recipe_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "recipe_type" */
export enum Recipe_Type_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RecipeTypeName = "recipe_type_name",
}

/** input type for updating data in table "recipe_type" */
export type Recipe_Type_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Recipe_Type_Stddev_Fields = {
  __typename?: "recipe_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Recipe_Type_Stddev_Pop_Fields = {
  __typename?: "recipe_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Recipe_Type_Stddev_Samp_Fields = {
  __typename?: "recipe_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "recipe_type" */
export type Recipe_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Recipe_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Recipe_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  recipe_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Recipe_Type_Sum_Fields = {
  __typename?: "recipe_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "recipe_type" */
export enum Recipe_Type_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RecipeTypeName = "recipe_type_name",
}

export type Recipe_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recipe_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recipe_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recipe_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recipe_Type_Var_Pop_Fields = {
  __typename?: "recipe_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Recipe_Type_Var_Samp_Fields = {
  __typename?: "recipe_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Recipe_Type_Variance_Fields = {
  __typename?: "recipe_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** update columns of table "recipe" */
export enum Recipe_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  IsDraft = "is_draft",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  RecipeName = "recipe_name",
  /** column name */
  RecipeTypeId = "recipe_type_id",
  /** column name */
  UseForReporting = "use_for_reporting",
}

export type Recipe_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Recipe_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Recipe_Set_Input>;
  /** filter the rows which have to be updated */
  where: Recipe_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Recipe_Var_Pop_Fields = {
  __typename?: "recipe_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "recipe" */
export type Recipe_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Recipe_Var_Samp_Fields = {
  __typename?: "recipe_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "recipe" */
export type Recipe_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Recipe_Variance_Fields = {
  __typename?: "recipe_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  recipe_type_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "recipe" */
export type Recipe_Variance_Order_By = {
  id?: Maybe<Order_By>;
  recipe_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "service_account" */
export type Service_Account = {
  __typename?: "service_account";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  is_deleted: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  organisations: Array<Organisation>;
  /** An aggregate relationship */
  organisations_aggregate: Organisation_Aggregate;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  service_account_name: Scalars["String"];
  service_account_uid: Scalars["String"];
};

/** columns and relationships of "service_account" */
export type Service_AccountInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "service_account" */
export type Service_AccountInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "service_account" */
export type Service_AccountOrganisationsArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

/** columns and relationships of "service_account" */
export type Service_AccountOrganisations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

/** columns and relationships of "service_account" */
export type Service_AccountProductsArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** columns and relationships of "service_account" */
export type Service_AccountProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

/** aggregated selection of "service_account" */
export type Service_Account_Aggregate = {
  __typename?: "service_account_aggregate";
  aggregate?: Maybe<Service_Account_Aggregate_Fields>;
  nodes: Array<Service_Account>;
};

export type Service_Account_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Service_Account_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Service_Account_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Service_Account_Aggregate_Bool_Exp_Count>;
};

export type Service_Account_Aggregate_Bool_Exp_Bool_And = {
  arguments: Service_Account_Select_Column_Service_Account_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Service_Account_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Account_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Service_Account_Select_Column_Service_Account_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Service_Account_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Account_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Service_Account_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Service_Account_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "service_account" */
export type Service_Account_Aggregate_Fields = {
  __typename?: "service_account_aggregate_fields";
  avg?: Maybe<Service_Account_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Service_Account_Max_Fields>;
  min?: Maybe<Service_Account_Min_Fields>;
  stddev?: Maybe<Service_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Account_Sum_Fields>;
  var_pop?: Maybe<Service_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Account_Var_Samp_Fields>;
  variance?: Maybe<Service_Account_Variance_Fields>;
};

/** aggregate fields of "service_account" */
export type Service_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Account_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "service_account" */
export type Service_Account_Aggregate_Order_By = {
  avg?: Maybe<Service_Account_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Service_Account_Max_Order_By>;
  min?: Maybe<Service_Account_Min_Order_By>;
  stddev?: Maybe<Service_Account_Stddev_Order_By>;
  stddev_pop?: Maybe<Service_Account_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Service_Account_Stddev_Samp_Order_By>;
  sum?: Maybe<Service_Account_Sum_Order_By>;
  var_pop?: Maybe<Service_Account_Var_Pop_Order_By>;
  var_samp?: Maybe<Service_Account_Var_Samp_Order_By>;
  variance?: Maybe<Service_Account_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_account" */
export type Service_Account_Arr_Rel_Insert_Input = {
  data: Array<Service_Account_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};

/** aggregate avg on columns */
export type Service_Account_Avg_Fields = {
  __typename?: "service_account_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "service_account" */
export type Service_Account_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_account". All fields are combined with a logical 'AND'. */
export type Service_Account_Bool_Exp = {
  _and?: Maybe<Array<Service_Account_Bool_Exp>>;
  _not?: Maybe<Service_Account_Bool_Exp>;
  _or?: Maybe<Array<Service_Account_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  is_deleted?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  organisations?: Maybe<Organisation_Bool_Exp>;
  organisations_aggregate?: Maybe<Organisation_Aggregate_Bool_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  products_aggregate?: Maybe<Product_Aggregate_Bool_Exp>;
  service_account_name?: Maybe<String_Comparison_Exp>;
  service_account_uid?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_account" */
export enum Service_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceAccountPkey = "service_account_pkey",
  /** unique or primary key constraint on columns "service_account_name" */
  ServiceAccountServiceAccountNameKey = "service_account_service_account_name_key",
  /** unique or primary key constraint on columns "service_account_uid" */
  ServiceAccountServiceAccountUidKey = "service_account_service_account_uid_key",
}

/** input type for incrementing numeric columns in table "service_account" */
export type Service_Account_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "service_account" */
export type Service_Account_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisations?: Maybe<Organisation_Arr_Rel_Insert_Input>;
  products?: Maybe<Product_Arr_Rel_Insert_Input>;
  service_account_name?: Maybe<Scalars["String"]>;
  service_account_uid?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Service_Account_Max_Fields = {
  __typename?: "service_account_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  service_account_name?: Maybe<Scalars["String"]>;
  service_account_uid?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "service_account" */
export type Service_Account_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  service_account_name?: Maybe<Order_By>;
  service_account_uid?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Account_Min_Fields = {
  __typename?: "service_account_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  service_account_name?: Maybe<Scalars["String"]>;
  service_account_uid?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "service_account" */
export type Service_Account_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  service_account_name?: Maybe<Order_By>;
  service_account_uid?: Maybe<Order_By>;
};

/** response of any mutation on the table "service_account" */
export type Service_Account_Mutation_Response = {
  __typename?: "service_account_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Account>;
};

/** input type for inserting object relation for remote table "service_account" */
export type Service_Account_Obj_Rel_Insert_Input = {
  data: Service_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};

/** on_conflict condition type for table "service_account" */
export type Service_Account_On_Conflict = {
  constraint: Service_Account_Constraint;
  update_columns?: Array<Service_Account_Update_Column>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "service_account". */
export type Service_Account_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  is_deleted?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisations_aggregate?: Maybe<Organisation_Aggregate_Order_By>;
  products_aggregate?: Maybe<Product_Aggregate_Order_By>;
  service_account_name?: Maybe<Order_By>;
  service_account_uid?: Maybe<Order_By>;
};

/** primary key columns input for table: service_account */
export type Service_Account_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "service_account" */
export enum Service_Account_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ServiceAccountName = "service_account_name",
  /** column name */
  ServiceAccountUid = "service_account_uid",
}

/** select "service_account_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service_account" */
export enum Service_Account_Select_Column_Service_Account_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDeleted = "is_deleted",
}

/** select "service_account_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service_account" */
export enum Service_Account_Select_Column_Service_Account_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDeleted = "is_deleted",
}

/** input type for updating data in table "service_account" */
export type Service_Account_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  service_account_name?: Maybe<Scalars["String"]>;
  service_account_uid?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Service_Account_Stddev_Fields = {
  __typename?: "service_account_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "service_account" */
export type Service_Account_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Service_Account_Stddev_Pop_Fields = {
  __typename?: "service_account_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "service_account" */
export type Service_Account_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Service_Account_Stddev_Samp_Fields = {
  __typename?: "service_account_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "service_account" */
export type Service_Account_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "service_account" */
export type Service_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Account_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  is_deleted?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  service_account_name?: Maybe<Scalars["String"]>;
  service_account_uid?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Service_Account_Sum_Fields = {
  __typename?: "service_account_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "service_account" */
export type Service_Account_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "service_account" */
export enum Service_Account_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsDeleted = "is_deleted",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ServiceAccountName = "service_account_name",
  /** column name */
  ServiceAccountUid = "service_account_uid",
}

export type Service_Account_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Service_Account_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Account_Var_Pop_Fields = {
  __typename?: "service_account_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "service_account" */
export type Service_Account_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Service_Account_Var_Samp_Fields = {
  __typename?: "service_account_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "service_account" */
export type Service_Account_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Service_Account_Variance_Fields = {
  __typename?: "service_account_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "service_account" */
export type Service_Account_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_allocation" */
export type Stock_Allocation = {
  __typename?: "stock_allocation";
  created_at: Scalars["timestamptz"];
  external_stock_allocation_id: Scalars["Int"];
  from_organisation_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  organisation: Organisation;
  /** An object relationship */
  organisationByToOrganisationId: Organisation;
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  quantity: Scalars["Int"];
  /** An array relationship */
  stock_allocation_fulfilments: Array<Stock_Allocation_Fulfilment>;
  /** An aggregate relationship */
  stock_allocation_fulfilments_aggregate: Stock_Allocation_Fulfilment_Aggregate;
  /** An object relationship */
  stock_condition: Stock_Condition;
  stock_condition_id: Scalars["Int"];
  to_organisation_id: Scalars["Int"];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars["Int"];
};

/** columns and relationships of "stock_allocation" */
export type Stock_AllocationStock_Allocation_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_allocation" */
export type Stock_AllocationStock_Allocation_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** aggregated selection of "stock_allocation" */
export type Stock_Allocation_Aggregate = {
  __typename?: "stock_allocation_aggregate";
  aggregate?: Maybe<Stock_Allocation_Aggregate_Fields>;
  nodes: Array<Stock_Allocation>;
};

export type Stock_Allocation_Aggregate_Bool_Exp = {
  count?: Maybe<Stock_Allocation_Aggregate_Bool_Exp_Count>;
};

export type Stock_Allocation_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stock_Allocation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Stock_Allocation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_allocation" */
export type Stock_Allocation_Aggregate_Fields = {
  __typename?: "stock_allocation_aggregate_fields";
  avg?: Maybe<Stock_Allocation_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Allocation_Max_Fields>;
  min?: Maybe<Stock_Allocation_Min_Fields>;
  stddev?: Maybe<Stock_Allocation_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Allocation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Allocation_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Allocation_Sum_Fields>;
  var_pop?: Maybe<Stock_Allocation_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Allocation_Var_Samp_Fields>;
  variance?: Maybe<Stock_Allocation_Variance_Fields>;
};

/** aggregate fields of "stock_allocation" */
export type Stock_Allocation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Allocation_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "stock_allocation" */
export type Stock_Allocation_Aggregate_Order_By = {
  avg?: Maybe<Stock_Allocation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Allocation_Max_Order_By>;
  min?: Maybe<Stock_Allocation_Min_Order_By>;
  stddev?: Maybe<Stock_Allocation_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Allocation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Allocation_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Allocation_Sum_Order_By>;
  var_pop?: Maybe<Stock_Allocation_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Allocation_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Allocation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_allocation" */
export type Stock_Allocation_Arr_Rel_Insert_Input = {
  data: Array<Stock_Allocation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Allocation_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Allocation_Avg_Fields = {
  __typename?: "stock_allocation_avg_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "stock_allocation" */
export type Stock_Allocation_Avg_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_allocation". All fields are combined with a logical 'AND'. */
export type Stock_Allocation_Bool_Exp = {
  _and?: Maybe<Array<Stock_Allocation_Bool_Exp>>;
  _not?: Maybe<Stock_Allocation_Bool_Exp>;
  _or?: Maybe<Array<Stock_Allocation_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_stock_allocation_id?: Maybe<Int_Comparison_Exp>;
  from_organisation_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisationByToOrganisationId?: Maybe<Organisation_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  stock_allocation_fulfilments?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
  stock_allocation_fulfilments_aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Bool_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
  stock_condition_id?: Maybe<Int_Comparison_Exp>;
  to_organisation_id?: Maybe<Int_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_allocation" */
export enum Stock_Allocation_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockAllocationPkey = "stock_allocation_pkey",
}

/** columns and relationships of "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment = {
  __typename?: "stock_allocation_fulfilment";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location?: Maybe<Location>;
  new_barcode_uuid?: Maybe<Scalars["uuid"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  product_stock: Product_Stock;
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  source_product_stock_id: Scalars["Int"];
  /** An object relationship */
  stock_allocation: Stock_Allocation;
  stock_allocation_id: Scalars["Int"];
};

/** columns and relationships of "stock_allocation_fulfilment" */
export type Stock_Allocation_FulfilmentProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "stock_allocation_fulfilment" */
export type Stock_Allocation_FulfilmentProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Aggregate = {
  __typename?: "stock_allocation_fulfilment_aggregate";
  aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Fields>;
  nodes: Array<Stock_Allocation_Fulfilment>;
};

export type Stock_Allocation_Fulfilment_Aggregate_Bool_Exp = {
  count?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Bool_Exp_Count>;
};

export type Stock_Allocation_Fulfilment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Aggregate_Fields = {
  __typename?: "stock_allocation_fulfilment_aggregate_fields";
  avg?: Maybe<Stock_Allocation_Fulfilment_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Allocation_Fulfilment_Max_Fields>;
  min?: Maybe<Stock_Allocation_Fulfilment_Min_Fields>;
  stddev?: Maybe<Stock_Allocation_Fulfilment_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Allocation_Fulfilment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Allocation_Fulfilment_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Allocation_Fulfilment_Sum_Fields>;
  var_pop?: Maybe<Stock_Allocation_Fulfilment_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Allocation_Fulfilment_Var_Samp_Fields>;
  variance?: Maybe<Stock_Allocation_Fulfilment_Variance_Fields>;
};

/** aggregate fields of "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Aggregate_Order_By = {
  avg?: Maybe<Stock_Allocation_Fulfilment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Allocation_Fulfilment_Max_Order_By>;
  min?: Maybe<Stock_Allocation_Fulfilment_Min_Order_By>;
  stddev?: Maybe<Stock_Allocation_Fulfilment_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Allocation_Fulfilment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Allocation_Fulfilment_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Allocation_Fulfilment_Sum_Order_By>;
  var_pop?: Maybe<Stock_Allocation_Fulfilment_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Allocation_Fulfilment_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Allocation_Fulfilment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Arr_Rel_Insert_Input = {
  data: Array<Stock_Allocation_Fulfilment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Allocation_Fulfilment_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Allocation_Fulfilment_Avg_Fields = {
  __typename?: "stock_allocation_fulfilment_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Avg_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_allocation_fulfilment". All fields are combined with a logical 'AND'. */
export type Stock_Allocation_Fulfilment_Bool_Exp = {
  _and?: Maybe<Array<Stock_Allocation_Fulfilment_Bool_Exp>>;
  _not?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
  _or?: Maybe<Array<Stock_Allocation_Fulfilment_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  new_barcode_uuid?: Maybe<Uuid_Comparison_Exp>;
  new_location_id?: Maybe<Int_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  stock_allocation?: Maybe<Stock_Allocation_Bool_Exp>;
  stock_allocation_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_allocation_fulfilment" */
export enum Stock_Allocation_Fulfilment_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockAllocationFulfilmentPkey = "stock_allocation_fulfilment_pkey",
}

/** input type for incrementing numeric columns in table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Inc_Input = {
  new_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  new_barcode_uuid?: Maybe<Scalars["uuid"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation?: Maybe<Stock_Allocation_Obj_Rel_Insert_Input>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Stock_Allocation_Fulfilment_Max_Fields = {
  __typename?: "stock_allocation_fulfilment_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_barcode_uuid?: Maybe<Scalars["uuid"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  new_barcode_uuid?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Allocation_Fulfilment_Min_Fields = {
  __typename?: "stock_allocation_fulfilment_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_barcode_uuid?: Maybe<Scalars["uuid"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  new_barcode_uuid?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Mutation_Response = {
  __typename?: "stock_allocation_fulfilment_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Allocation_Fulfilment>;
};

/** input type for inserting object relation for remote table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Obj_Rel_Insert_Input = {
  data: Stock_Allocation_Fulfilment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Allocation_Fulfilment_On_Conflict>;
};

/** on_conflict condition type for table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_On_Conflict = {
  constraint: Stock_Allocation_Fulfilment_Constraint;
  update_columns?: Array<Stock_Allocation_Fulfilment_Update_Column>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_allocation_fulfilment". */
export type Stock_Allocation_Fulfilment_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  new_barcode_uuid?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation?: Maybe<Stock_Allocation_Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stock_allocation_fulfilment */
export type Stock_Allocation_Fulfilment_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_allocation_fulfilment" */
export enum Stock_Allocation_Fulfilment_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  NewBarcodeUuid = "new_barcode_uuid",
  /** column name */
  NewLocationId = "new_location_id",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  StockAllocationId = "stock_allocation_id",
}

/** input type for updating data in table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_barcode_uuid?: Maybe<Scalars["uuid"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Stock_Allocation_Fulfilment_Stddev_Fields = {
  __typename?: "stock_allocation_fulfilment_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Allocation_Fulfilment_Stddev_Pop_Fields = {
  __typename?: "stock_allocation_fulfilment_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Allocation_Fulfilment_Stddev_Samp_Fields = {
  __typename?: "stock_allocation_fulfilment_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Allocation_Fulfilment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Allocation_Fulfilment_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_barcode_uuid?: Maybe<Scalars["uuid"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Stock_Allocation_Fulfilment_Sum_Fields = {
  __typename?: "stock_allocation_fulfilment_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  stock_allocation_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Sum_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** update columns of table "stock_allocation_fulfilment" */
export enum Stock_Allocation_Fulfilment_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  NewBarcodeUuid = "new_barcode_uuid",
  /** column name */
  NewLocationId = "new_location_id",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  StockAllocationId = "stock_allocation_id",
}

export type Stock_Allocation_Fulfilment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Allocation_Fulfilment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Allocation_Fulfilment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Allocation_Fulfilment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Allocation_Fulfilment_Var_Pop_Fields = {
  __typename?: "stock_allocation_fulfilment_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Allocation_Fulfilment_Var_Samp_Fields = {
  __typename?: "stock_allocation_fulfilment_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Allocation_Fulfilment_Variance_Fields = {
  __typename?: "stock_allocation_fulfilment_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  stock_allocation_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "stock_allocation_fulfilment" */
export type Stock_Allocation_Fulfilment_Variance_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  stock_allocation_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "stock_allocation" */
export type Stock_Allocation_Inc_Input = {
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_allocation" */
export type Stock_Allocation_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisationByToOrganisationId?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_allocation_fulfilments?: Maybe<Stock_Allocation_Fulfilment_Arr_Rel_Insert_Input>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Stock_Allocation_Max_Fields = {
  __typename?: "stock_allocation_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "stock_allocation" */
export type Stock_Allocation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Allocation_Min_Fields = {
  __typename?: "stock_allocation_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "stock_allocation" */
export type Stock_Allocation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stock_allocation" */
export type Stock_Allocation_Mutation_Response = {
  __typename?: "stock_allocation_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Allocation>;
};

/** input type for inserting object relation for remote table "stock_allocation" */
export type Stock_Allocation_Obj_Rel_Insert_Input = {
  data: Stock_Allocation_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Allocation_On_Conflict>;
};

/** on_conflict condition type for table "stock_allocation" */
export type Stock_Allocation_On_Conflict = {
  constraint: Stock_Allocation_Constraint;
  update_columns?: Array<Stock_Allocation_Update_Column>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_allocation". */
export type Stock_Allocation_Order_By = {
  created_at?: Maybe<Order_By>;
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisationByToOrganisationId?: Maybe<Organisation_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_allocation_fulfilments_aggregate?: Maybe<Stock_Allocation_Fulfilment_Aggregate_Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stock_allocation */
export type Stock_Allocation_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_allocation" */
export enum Stock_Allocation_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalStockAllocationId = "external_stock_allocation_id",
  /** column name */
  FromOrganisationId = "from_organisation_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  StockConditionId = "stock_condition_id",
  /** column name */
  ToOrganisationId = "to_organisation_id",
  /** column name */
  WarehouseId = "warehouse_id",
}

/** input type for updating data in table "stock_allocation" */
export type Stock_Allocation_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Stock_Allocation_Stddev_Fields = {
  __typename?: "stock_allocation_stddev_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "stock_allocation" */
export type Stock_Allocation_Stddev_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Allocation_Stddev_Pop_Fields = {
  __typename?: "stock_allocation_stddev_pop_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "stock_allocation" */
export type Stock_Allocation_Stddev_Pop_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Allocation_Stddev_Samp_Fields = {
  __typename?: "stock_allocation_stddev_samp_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "stock_allocation" */
export type Stock_Allocation_Stddev_Samp_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_allocation" */
export type Stock_Allocation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Allocation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Allocation_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Stock_Allocation_Sum_Fields = {
  __typename?: "stock_allocation_sum_fields";
  external_stock_allocation_id?: Maybe<Scalars["Int"]>;
  from_organisation_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_condition_id?: Maybe<Scalars["Int"]>;
  to_organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "stock_allocation" */
export type Stock_Allocation_Sum_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "stock_allocation" */
export enum Stock_Allocation_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExternalStockAllocationId = "external_stock_allocation_id",
  /** column name */
  FromOrganisationId = "from_organisation_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  StockConditionId = "stock_condition_id",
  /** column name */
  ToOrganisationId = "to_organisation_id",
  /** column name */
  WarehouseId = "warehouse_id",
}

export type Stock_Allocation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Allocation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Allocation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Allocation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Allocation_Var_Pop_Fields = {
  __typename?: "stock_allocation_var_pop_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "stock_allocation" */
export type Stock_Allocation_Var_Pop_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Allocation_Var_Samp_Fields = {
  __typename?: "stock_allocation_var_samp_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "stock_allocation" */
export type Stock_Allocation_Var_Samp_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Allocation_Variance_Fields = {
  __typename?: "stock_allocation_variance_fields";
  external_stock_allocation_id?: Maybe<Scalars["Float"]>;
  from_organisation_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_condition_id?: Maybe<Scalars["Float"]>;
  to_organisation_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "stock_allocation" */
export type Stock_Allocation_Variance_Order_By = {
  external_stock_allocation_id?: Maybe<Order_By>;
  from_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  to_organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_check" */
export type Stock_Check = {
  __typename?: "stock_check";
  created_at: Scalars["timestamptz"];
  due_date?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location?: Maybe<Location>;
  location_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  reference: Scalars["String"];
  /** An array relationship */
  stock_check_line_fulfilments: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stock_check_line_fulfilments_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  /** An array relationship */
  stock_check_lines: Array<Stock_Check_Line>;
  /** An aggregate relationship */
  stock_check_lines_aggregate: Stock_Check_Line_Aggregate;
  /** An object relationship */
  stock_check_status: Stock_Check_Status;
  stock_check_status_id: Scalars["Int"];
  /** An object relationship */
  stock_check_type: Stock_Check_Type;
  stock_check_type_id: Scalars["Int"];
};

/** columns and relationships of "stock_check" */
export type Stock_CheckStock_Check_Line_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_check" */
export type Stock_CheckStock_Check_Line_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_check" */
export type Stock_CheckStock_Check_LinesArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** columns and relationships of "stock_check" */
export type Stock_CheckStock_Check_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** aggregated selection of "stock_check" */
export type Stock_Check_Aggregate = {
  __typename?: "stock_check_aggregate";
  aggregate?: Maybe<Stock_Check_Aggregate_Fields>;
  nodes: Array<Stock_Check>;
};

export type Stock_Check_Aggregate_Bool_Exp = {
  count?: Maybe<Stock_Check_Aggregate_Bool_Exp_Count>;
};

export type Stock_Check_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stock_Check_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Stock_Check_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_check" */
export type Stock_Check_Aggregate_Fields = {
  __typename?: "stock_check_aggregate_fields";
  avg?: Maybe<Stock_Check_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Max_Fields>;
  min?: Maybe<Stock_Check_Min_Fields>;
  stddev?: Maybe<Stock_Check_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Variance_Fields>;
};

/** aggregate fields of "stock_check" */
export type Stock_Check_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "stock_check" */
export type Stock_Check_Aggregate_Order_By = {
  avg?: Maybe<Stock_Check_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Check_Max_Order_By>;
  min?: Maybe<Stock_Check_Min_Order_By>;
  stddev?: Maybe<Stock_Check_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Check_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Check_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Check_Sum_Order_By>;
  var_pop?: Maybe<Stock_Check_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Check_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Check_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_check" */
export type Stock_Check_Arr_Rel_Insert_Input = {
  data: Array<Stock_Check_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Check_Avg_Fields = {
  __typename?: "stock_check_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "stock_check" */
export type Stock_Check_Avg_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_check". All fields are combined with a logical 'AND'. */
export type Stock_Check_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_date?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  location_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
  stock_check_lines?: Maybe<Stock_Check_Line_Bool_Exp>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Bool_Exp>;
  stock_check_status?: Maybe<Stock_Check_Status_Bool_Exp>;
  stock_check_status_id?: Maybe<Int_Comparison_Exp>;
  stock_check_type?: Maybe<Stock_Check_Type_Bool_Exp>;
  stock_check_type_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_check" */
export enum Stock_Check_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockCheckPkey = "stock_check_pkey",
  /** unique or primary key constraint on columns "reference" */
  StockCheckReferenceKey = "stock_check_reference_key",
}

/** input type for incrementing numeric columns in table "stock_check" */
export type Stock_Check_Inc_Input = {
  location_id?: Maybe<Scalars["Int"]>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check" */
export type Stock_Check_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  location_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
  stock_check_lines?: Maybe<Stock_Check_Line_Arr_Rel_Insert_Input>;
  stock_check_status?: Maybe<Stock_Check_Status_Obj_Rel_Insert_Input>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type?: Maybe<Stock_Check_Type_Obj_Rel_Insert_Input>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "stock_check_line" */
export type Stock_Check_Line = {
  __typename?: "stock_check_line";
  created_at: Scalars["timestamptz"];
  expected_quantity: Scalars["Int"];
  expected_stock_condition_id: Scalars["Int"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product_stock: Product_Stock;
  product_stock_id: Scalars["Int"];
  /** An object relationship */
  stock_check: Stock_Check;
  stock_check_id: Scalars["Int"];
  /** An array relationship */
  stock_check_line_fulfilments: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stock_check_line_fulfilments_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  /** An object relationship */
  stock_check_line_status: Stock_Check_Line_Status;
  stock_check_line_status_id: Scalars["Int"];
  /** An object relationship */
  stock_condition: Stock_Condition;
};

/** columns and relationships of "stock_check_line" */
export type Stock_Check_LineStock_Check_Line_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_check_line" */
export type Stock_Check_LineStock_Check_Line_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** aggregated selection of "stock_check_line" */
export type Stock_Check_Line_Aggregate = {
  __typename?: "stock_check_line_aggregate";
  aggregate?: Maybe<Stock_Check_Line_Aggregate_Fields>;
  nodes: Array<Stock_Check_Line>;
};

export type Stock_Check_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Stock_Check_Line_Aggregate_Bool_Exp_Count>;
};

export type Stock_Check_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Stock_Check_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_check_line" */
export type Stock_Check_Line_Aggregate_Fields = {
  __typename?: "stock_check_line_aggregate_fields";
  avg?: Maybe<Stock_Check_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Line_Max_Fields>;
  min?: Maybe<Stock_Check_Line_Min_Fields>;
  stddev?: Maybe<Stock_Check_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Line_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Line_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Line_Variance_Fields>;
};

/** aggregate fields of "stock_check_line" */
export type Stock_Check_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "stock_check_line" */
export type Stock_Check_Line_Aggregate_Order_By = {
  avg?: Maybe<Stock_Check_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Check_Line_Max_Order_By>;
  min?: Maybe<Stock_Check_Line_Min_Order_By>;
  stddev?: Maybe<Stock_Check_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Check_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Check_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Check_Line_Sum_Order_By>;
  var_pop?: Maybe<Stock_Check_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Check_Line_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Check_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_check_line" */
export type Stock_Check_Line_Arr_Rel_Insert_Input = {
  data: Array<Stock_Check_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Check_Line_Avg_Fields = {
  __typename?: "stock_check_line_avg_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "stock_check_line" */
export type Stock_Check_Line_Avg_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_check_line". All fields are combined with a logical 'AND'. */
export type Stock_Check_Line_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Line_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Line_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_quantity?: Maybe<Int_Comparison_Exp>;
  expected_stock_condition_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  product_stock_id?: Maybe<Int_Comparison_Exp>;
  stock_check?: Maybe<Stock_Check_Bool_Exp>;
  stock_check_id?: Maybe<Int_Comparison_Exp>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
  stock_check_line_status?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
  stock_check_line_status_id?: Maybe<Int_Comparison_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_check_line" */
export enum Stock_Check_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockCheckLinePkey = "stock_check_line_pkey",
}

/** columns and relationships of "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment = {
  __typename?: "stock_check_line_fulfilment";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  location?: Maybe<Location>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  notes: Scalars["String"];
  /** An object relationship */
  product?: Maybe<Product>;
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  quantity: Scalars["Int"];
  /** An object relationship */
  stock_check: Stock_Check;
  stock_check_id: Scalars["Int"];
  /** An object relationship */
  stock_check_line?: Maybe<Stock_Check_Line>;
  /** An object relationship */
  stock_check_line_fulfilment_status: Stock_Check_Line_Fulfilment_Status;
  stock_check_line_fulfilment_status_id: Scalars["Int"];
  stock_check_line_id?: Maybe<Scalars["Int"]>;
  /** An object relationship */
  stock_condition?: Maybe<Stock_Condition>;
  /** An object relationship */
  stock_status?: Maybe<Stock_Status>;
};

/** columns and relationships of "stock_check_line_fulfilment" */
export type Stock_Check_Line_FulfilmentProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "stock_check_line_fulfilment" */
export type Stock_Check_Line_FulfilmentProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Aggregate = {
  __typename?: "stock_check_line_fulfilment_aggregate";
  aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Fields>;
  nodes: Array<Stock_Check_Line_Fulfilment>;
};

export type Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp = {
  count?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp_Count>;
};

export type Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Aggregate_Fields = {
  __typename?: "stock_check_line_fulfilment_aggregate_fields";
  avg?: Maybe<Stock_Check_Line_Fulfilment_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Line_Fulfilment_Max_Fields>;
  min?: Maybe<Stock_Check_Line_Fulfilment_Min_Fields>;
  stddev?: Maybe<Stock_Check_Line_Fulfilment_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Line_Fulfilment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Line_Fulfilment_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Line_Fulfilment_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Line_Fulfilment_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Line_Fulfilment_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Line_Fulfilment_Variance_Fields>;
};

/** aggregate fields of "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Aggregate_Order_By = {
  avg?: Maybe<Stock_Check_Line_Fulfilment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Check_Line_Fulfilment_Max_Order_By>;
  min?: Maybe<Stock_Check_Line_Fulfilment_Min_Order_By>;
  stddev?: Maybe<Stock_Check_Line_Fulfilment_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Check_Line_Fulfilment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Check_Line_Fulfilment_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Check_Line_Fulfilment_Sum_Order_By>;
  var_pop?: Maybe<Stock_Check_Line_Fulfilment_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Check_Line_Fulfilment_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Check_Line_Fulfilment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input = {
  data: Array<Stock_Check_Line_Fulfilment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Check_Line_Fulfilment_Avg_Fields = {
  __typename?: "stock_check_line_fulfilment_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Avg_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_check_line_fulfilment". All fields are combined with a logical 'AND'. */
export type Stock_Check_Line_Fulfilment_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Line_Fulfilment_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Line_Fulfilment_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  location?: Maybe<Location_Bool_Exp>;
  new_location_id?: Maybe<Int_Comparison_Exp>;
  new_product_id?: Maybe<Int_Comparison_Exp>;
  new_stock_condition_id?: Maybe<Int_Comparison_Exp>;
  new_stock_status_id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  stock_check?: Maybe<Stock_Check_Bool_Exp>;
  stock_check_id?: Maybe<Int_Comparison_Exp>;
  stock_check_line?: Maybe<Stock_Check_Line_Bool_Exp>;
  stock_check_line_fulfilment_status?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
  stock_check_line_fulfilment_status_id?: Maybe<Int_Comparison_Exp>;
  stock_check_line_id?: Maybe<Int_Comparison_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
  stock_status?: Maybe<Stock_Status_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_check_line_fulfilment" */
export enum Stock_Check_Line_Fulfilment_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockCheckLineFulfilmentPkey = "stock_check_line_fulfilment_pkey",
}

/** input type for incrementing numeric columns in table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Inc_Input = {
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location?: Maybe<Location_Obj_Rel_Insert_Input>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check?: Maybe<Stock_Check_Obj_Rel_Insert_Input>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line?: Maybe<Stock_Check_Line_Obj_Rel_Insert_Input>;
  stock_check_line_fulfilment_status?: Maybe<Stock_Check_Line_Fulfilment_Status_Obj_Rel_Insert_Input>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
  stock_status?: Maybe<Stock_Status_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Check_Line_Fulfilment_Max_Fields = {
  __typename?: "stock_check_line_fulfilment_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Check_Line_Fulfilment_Min_Fields = {
  __typename?: "stock_check_line_fulfilment_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Mutation_Response = {
  __typename?: "stock_check_line_fulfilment_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check_Line_Fulfilment>;
};

/** input type for inserting object relation for remote table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Obj_Rel_Insert_Input = {
  data: Stock_Check_Line_Fulfilment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_On_Conflict>;
};

/** on_conflict condition type for table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_On_Conflict = {
  constraint: Stock_Check_Line_Fulfilment_Constraint;
  update_columns?: Array<Stock_Check_Line_Fulfilment_Update_Column>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check_line_fulfilment". */
export type Stock_Check_Line_Fulfilment_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check?: Maybe<Stock_Check_Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line?: Maybe<Stock_Check_Line_Order_By>;
  stock_check_line_fulfilment_status?: Maybe<Stock_Check_Line_Fulfilment_Status_Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
  stock_status?: Maybe<Stock_Status_Order_By>;
};

/** primary key columns input for table: stock_check_line_fulfilment */
export type Stock_Check_Line_Fulfilment_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check_line_fulfilment" */
export enum Stock_Check_Line_Fulfilment_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  NewLocationId = "new_location_id",
  /** column name */
  NewProductId = "new_product_id",
  /** column name */
  NewStockConditionId = "new_stock_condition_id",
  /** column name */
  NewStockStatusId = "new_stock_status_id",
  /** column name */
  Notes = "notes",
  /** column name */
  Quantity = "quantity",
  /** column name */
  StockCheckId = "stock_check_id",
  /** column name */
  StockCheckLineFulfilmentStatusId = "stock_check_line_fulfilment_status_id",
  /** column name */
  StockCheckLineId = "stock_check_line_id",
}

/** input type for updating data in table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status = {
  __typename?: "stock_check_line_fulfilment_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  stock_check_line_fulfilment_status_name: Scalars["String"];
  /** An array relationship */
  stock_check_line_fulfilments: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stock_check_line_fulfilments_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
};

/** columns and relationships of "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_StatusStock_Check_Line_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_StatusStock_Check_Line_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** aggregated selection of "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Aggregate = {
  __typename?: "stock_check_line_fulfilment_status_aggregate";
  aggregate?: Maybe<Stock_Check_Line_Fulfilment_Status_Aggregate_Fields>;
  nodes: Array<Stock_Check_Line_Fulfilment_Status>;
};

/** aggregate fields of "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Aggregate_Fields = {
  __typename?: "stock_check_line_fulfilment_status_aggregate_fields";
  avg?: Maybe<Stock_Check_Line_Fulfilment_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Line_Fulfilment_Status_Max_Fields>;
  min?: Maybe<Stock_Check_Line_Fulfilment_Status_Min_Fields>;
  stddev?: Maybe<Stock_Check_Line_Fulfilment_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Line_Fulfilment_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Line_Fulfilment_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Line_Fulfilment_Status_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Line_Fulfilment_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Line_Fulfilment_Status_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Line_Fulfilment_Status_Variance_Fields>;
};

/** aggregate fields of "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Stock_Check_Line_Fulfilment_Status_Avg_Fields = {
  __typename?: "stock_check_line_fulfilment_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "stock_check_line_fulfilment_status". All fields are combined with a logical 'AND'. */
export type Stock_Check_Line_Fulfilment_Status_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  stock_check_line_fulfilment_status_name?: Maybe<String_Comparison_Exp>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_check_line_fulfilment_status" */
export enum Stock_Check_Line_Fulfilment_Status_Constraint {
  /** unique or primary key constraint on columns "stock_check_line_fulfilment_status_name" */
  StockCheckLineFulfilmentSStockCheckLineFulfilmentSKey = "stock_check_line_fulfilment_s_stock_check_line_fulfilment_s_key",
  /** unique or primary key constraint on columns "id" */
  StockCheckLineFulfilmentStatusPkey = "stock_check_line_fulfilment_status_pkey",
}

/** input type for incrementing numeric columns in table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_fulfilment_status_name?: Maybe<Scalars["String"]>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Check_Line_Fulfilment_Status_Max_Fields = {
  __typename?: "stock_check_line_fulfilment_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Stock_Check_Line_Fulfilment_Status_Min_Fields = {
  __typename?: "stock_check_line_fulfilment_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Mutation_Response = {
  __typename?: "stock_check_line_fulfilment_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check_Line_Fulfilment_Status>;
};

/** input type for inserting object relation for remote table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Obj_Rel_Insert_Input = {
  data: Stock_Check_Line_Fulfilment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Line_Fulfilment_Status_On_Conflict>;
};

/** on_conflict condition type for table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_On_Conflict = {
  constraint: Stock_Check_Line_Fulfilment_Status_Constraint;
  update_columns?: Array<Stock_Check_Line_Fulfilment_Status_Update_Column>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check_line_fulfilment_status". */
export type Stock_Check_Line_Fulfilment_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_name?: Maybe<Order_By>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_check_line_fulfilment_status */
export type Stock_Check_Line_Fulfilment_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check_line_fulfilment_status" */
export enum Stock_Check_Line_Fulfilment_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckLineFulfilmentStatusName = "stock_check_line_fulfilment_status_name",
}

/** input type for updating data in table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Line_Fulfilment_Status_Stddev_Fields = {
  __typename?: "stock_check_line_fulfilment_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Line_Fulfilment_Status_Stddev_Pop_Fields = {
  __typename?: "stock_check_line_fulfilment_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Line_Fulfilment_Status_Stddev_Samp_Fields = {
  __typename?: "stock_check_line_fulfilment_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "stock_check_line_fulfilment_status" */
export type Stock_Check_Line_Fulfilment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Line_Fulfilment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Line_Fulfilment_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_fulfilment_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Line_Fulfilment_Status_Sum_Fields = {
  __typename?: "stock_check_line_fulfilment_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "stock_check_line_fulfilment_status" */
export enum Stock_Check_Line_Fulfilment_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckLineFulfilmentStatusName = "stock_check_line_fulfilment_status_name",
}

export type Stock_Check_Line_Fulfilment_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Line_Fulfilment_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Line_Fulfilment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Line_Fulfilment_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Line_Fulfilment_Status_Var_Pop_Fields = {
  __typename?: "stock_check_line_fulfilment_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Line_Fulfilment_Status_Var_Samp_Fields = {
  __typename?: "stock_check_line_fulfilment_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Stock_Check_Line_Fulfilment_Status_Variance_Fields = {
  __typename?: "stock_check_line_fulfilment_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Line_Fulfilment_Stddev_Fields = {
  __typename?: "stock_check_line_fulfilment_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Line_Fulfilment_Stddev_Pop_Fields = {
  __typename?: "stock_check_line_fulfilment_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Line_Fulfilment_Stddev_Samp_Fields = {
  __typename?: "stock_check_line_fulfilment_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Line_Fulfilment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Line_Fulfilment_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Line_Fulfilment_Sum_Fields = {
  __typename?: "stock_check_line_fulfilment_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  new_location_id?: Maybe<Scalars["Int"]>;
  new_product_id?: Maybe<Scalars["Int"]>;
  new_stock_condition_id?: Maybe<Scalars["Int"]>;
  new_stock_status_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Int"]>;
  stock_check_line_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Sum_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** update columns of table "stock_check_line_fulfilment" */
export enum Stock_Check_Line_Fulfilment_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  NewLocationId = "new_location_id",
  /** column name */
  NewProductId = "new_product_id",
  /** column name */
  NewStockConditionId = "new_stock_condition_id",
  /** column name */
  NewStockStatusId = "new_stock_status_id",
  /** column name */
  Notes = "notes",
  /** column name */
  Quantity = "quantity",
  /** column name */
  StockCheckId = "stock_check_id",
  /** column name */
  StockCheckLineFulfilmentStatusId = "stock_check_line_fulfilment_status_id",
  /** column name */
  StockCheckLineId = "stock_check_line_id",
}

export type Stock_Check_Line_Fulfilment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Line_Fulfilment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Line_Fulfilment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Line_Fulfilment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Line_Fulfilment_Var_Pop_Fields = {
  __typename?: "stock_check_line_fulfilment_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Line_Fulfilment_Var_Samp_Fields = {
  __typename?: "stock_check_line_fulfilment_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Check_Line_Fulfilment_Variance_Fields = {
  __typename?: "stock_check_line_fulfilment_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  new_location_id?: Maybe<Scalars["Float"]>;
  new_product_id?: Maybe<Scalars["Float"]>;
  new_stock_condition_id?: Maybe<Scalars["Float"]>;
  new_stock_status_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_fulfilment_status_id?: Maybe<Scalars["Float"]>;
  stock_check_line_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "stock_check_line_fulfilment" */
export type Stock_Check_Line_Fulfilment_Variance_Order_By = {
  id?: Maybe<Order_By>;
  new_location_id?: Maybe<Order_By>;
  new_product_id?: Maybe<Order_By>;
  new_stock_condition_id?: Maybe<Order_By>;
  new_stock_status_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilment_status_id?: Maybe<Order_By>;
  stock_check_line_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "stock_check_line" */
export type Stock_Check_Line_Inc_Input = {
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check_line" */
export type Stock_Check_Line_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check?: Maybe<Stock_Check_Obj_Rel_Insert_Input>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
  stock_check_line_status?: Maybe<Stock_Check_Line_Status_Obj_Rel_Insert_Input>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Check_Line_Max_Fields = {
  __typename?: "stock_check_line_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "stock_check_line" */
export type Stock_Check_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Check_Line_Min_Fields = {
  __typename?: "stock_check_line_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "stock_check_line" */
export type Stock_Check_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stock_check_line" */
export type Stock_Check_Line_Mutation_Response = {
  __typename?: "stock_check_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check_Line>;
};

/** input type for inserting object relation for remote table "stock_check_line" */
export type Stock_Check_Line_Obj_Rel_Insert_Input = {
  data: Stock_Check_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Line_On_Conflict>;
};

/** on_conflict condition type for table "stock_check_line" */
export type Stock_Check_Line_On_Conflict = {
  constraint: Stock_Check_Line_Constraint;
  update_columns?: Array<Stock_Check_Line_Update_Column>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check_line". */
export type Stock_Check_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check?: Maybe<Stock_Check_Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
  stock_check_line_status?: Maybe<Stock_Check_Line_Status_Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
};

/** primary key columns input for table: stock_check_line */
export type Stock_Check_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check_line" */
export enum Stock_Check_Line_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpectedQuantity = "expected_quantity",
  /** column name */
  ExpectedStockConditionId = "expected_stock_condition_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductStockId = "product_stock_id",
  /** column name */
  StockCheckId = "stock_check_id",
  /** column name */
  StockCheckLineStatusId = "stock_check_line_status_id",
}

/** input type for updating data in table "stock_check_line" */
export type Stock_Check_Line_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "stock_check_line_status" */
export type Stock_Check_Line_Status = {
  __typename?: "stock_check_line_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  stock_check_line_status_name: Scalars["String"];
  /** An array relationship */
  stock_check_lines: Array<Stock_Check_Line>;
  /** An aggregate relationship */
  stock_check_lines_aggregate: Stock_Check_Line_Aggregate;
};

/** columns and relationships of "stock_check_line_status" */
export type Stock_Check_Line_StatusStock_Check_LinesArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** columns and relationships of "stock_check_line_status" */
export type Stock_Check_Line_StatusStock_Check_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** aggregated selection of "stock_check_line_status" */
export type Stock_Check_Line_Status_Aggregate = {
  __typename?: "stock_check_line_status_aggregate";
  aggregate?: Maybe<Stock_Check_Line_Status_Aggregate_Fields>;
  nodes: Array<Stock_Check_Line_Status>;
};

/** aggregate fields of "stock_check_line_status" */
export type Stock_Check_Line_Status_Aggregate_Fields = {
  __typename?: "stock_check_line_status_aggregate_fields";
  avg?: Maybe<Stock_Check_Line_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Line_Status_Max_Fields>;
  min?: Maybe<Stock_Check_Line_Status_Min_Fields>;
  stddev?: Maybe<Stock_Check_Line_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Line_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Line_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Line_Status_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Line_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Line_Status_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Line_Status_Variance_Fields>;
};

/** aggregate fields of "stock_check_line_status" */
export type Stock_Check_Line_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Line_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Stock_Check_Line_Status_Avg_Fields = {
  __typename?: "stock_check_line_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "stock_check_line_status". All fields are combined with a logical 'AND'. */
export type Stock_Check_Line_Status_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Line_Status_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Line_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  stock_check_line_status_name?: Maybe<String_Comparison_Exp>;
  stock_check_lines?: Maybe<Stock_Check_Line_Bool_Exp>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_check_line_status" */
export enum Stock_Check_Line_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockCheckLineStatusPkey = "stock_check_line_status_pkey",
  /** unique or primary key constraint on columns "stock_check_line_status_name" */
  StockCheckLineStatusStockCheckLineStatusNameKey = "stock_check_line_status_stock_check_line_status_name_key",
}

/** input type for incrementing numeric columns in table "stock_check_line_status" */
export type Stock_Check_Line_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check_line_status" */
export type Stock_Check_Line_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_status_name?: Maybe<Scalars["String"]>;
  stock_check_lines?: Maybe<Stock_Check_Line_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Check_Line_Status_Max_Fields = {
  __typename?: "stock_check_line_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Stock_Check_Line_Status_Min_Fields = {
  __typename?: "stock_check_line_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "stock_check_line_status" */
export type Stock_Check_Line_Status_Mutation_Response = {
  __typename?: "stock_check_line_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check_Line_Status>;
};

/** input type for inserting object relation for remote table "stock_check_line_status" */
export type Stock_Check_Line_Status_Obj_Rel_Insert_Input = {
  data: Stock_Check_Line_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Line_Status_On_Conflict>;
};

/** on_conflict condition type for table "stock_check_line_status" */
export type Stock_Check_Line_Status_On_Conflict = {
  constraint: Stock_Check_Line_Status_Constraint;
  update_columns?: Array<Stock_Check_Line_Status_Update_Column>;
  where?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check_line_status". */
export type Stock_Check_Line_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_check_line_status_name?: Maybe<Order_By>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_check_line_status */
export type Stock_Check_Line_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check_line_status" */
export enum Stock_Check_Line_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckLineStatusName = "stock_check_line_status_name",
}

/** input type for updating data in table "stock_check_line_status" */
export type Stock_Check_Line_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Line_Status_Stddev_Fields = {
  __typename?: "stock_check_line_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Line_Status_Stddev_Pop_Fields = {
  __typename?: "stock_check_line_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Line_Status_Stddev_Samp_Fields = {
  __typename?: "stock_check_line_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "stock_check_line_status" */
export type Stock_Check_Line_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Line_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Line_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_line_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Line_Status_Sum_Fields = {
  __typename?: "stock_check_line_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "stock_check_line_status" */
export enum Stock_Check_Line_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckLineStatusName = "stock_check_line_status_name",
}

export type Stock_Check_Line_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Line_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Line_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Line_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Line_Status_Var_Pop_Fields = {
  __typename?: "stock_check_line_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Line_Status_Var_Samp_Fields = {
  __typename?: "stock_check_line_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Stock_Check_Line_Status_Variance_Fields = {
  __typename?: "stock_check_line_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Line_Stddev_Fields = {
  __typename?: "stock_check_line_stddev_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "stock_check_line" */
export type Stock_Check_Line_Stddev_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Line_Stddev_Pop_Fields = {
  __typename?: "stock_check_line_stddev_pop_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "stock_check_line" */
export type Stock_Check_Line_Stddev_Pop_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Line_Stddev_Samp_Fields = {
  __typename?: "stock_check_line_stddev_samp_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "stock_check_line" */
export type Stock_Check_Line_Stddev_Samp_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_check_line" */
export type Stock_Check_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Line_Sum_Fields = {
  __typename?: "stock_check_line_sum_fields";
  expected_quantity?: Maybe<Scalars["Int"]>;
  expected_stock_condition_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  product_stock_id?: Maybe<Scalars["Int"]>;
  stock_check_id?: Maybe<Scalars["Int"]>;
  stock_check_line_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "stock_check_line" */
export type Stock_Check_Line_Sum_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** update columns of table "stock_check_line" */
export enum Stock_Check_Line_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ExpectedQuantity = "expected_quantity",
  /** column name */
  ExpectedStockConditionId = "expected_stock_condition_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductStockId = "product_stock_id",
  /** column name */
  StockCheckId = "stock_check_id",
  /** column name */
  StockCheckLineStatusId = "stock_check_line_status_id",
}

export type Stock_Check_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Line_Var_Pop_Fields = {
  __typename?: "stock_check_line_var_pop_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "stock_check_line" */
export type Stock_Check_Line_Var_Pop_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Line_Var_Samp_Fields = {
  __typename?: "stock_check_line_var_samp_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "stock_check_line" */
export type Stock_Check_Line_Var_Samp_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Check_Line_Variance_Fields = {
  __typename?: "stock_check_line_variance_fields";
  expected_quantity?: Maybe<Scalars["Float"]>;
  expected_stock_condition_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  product_stock_id?: Maybe<Scalars["Float"]>;
  stock_check_id?: Maybe<Scalars["Float"]>;
  stock_check_line_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "stock_check_line" */
export type Stock_Check_Line_Variance_Order_By = {
  expected_quantity?: Maybe<Order_By>;
  expected_stock_condition_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  stock_check_id?: Maybe<Order_By>;
  stock_check_line_status_id?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Stock_Check_Max_Fields = {
  __typename?: "stock_check_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "stock_check" */
export type Stock_Check_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Check_Min_Fields = {
  __typename?: "stock_check_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "stock_check" */
export type Stock_Check_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stock_check" */
export type Stock_Check_Mutation_Response = {
  __typename?: "stock_check_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check>;
};

/** input type for inserting object relation for remote table "stock_check" */
export type Stock_Check_Obj_Rel_Insert_Input = {
  data: Stock_Check_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_On_Conflict>;
};

/** on_conflict condition type for table "stock_check" */
export type Stock_Check_On_Conflict = {
  constraint: Stock_Check_Constraint;
  update_columns?: Array<Stock_Check_Update_Column>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check". */
export type Stock_Check_Order_By = {
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  location?: Maybe<Location_Order_By>;
  location_id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Order_By>;
  stock_check_status?: Maybe<Stock_Check_Status_Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type?: Maybe<Stock_Check_Type_Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stock_check */
export type Stock_Check_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check" */
export enum Stock_Check_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Notes = "notes",
  /** column name */
  Reference = "reference",
  /** column name */
  StockCheckStatusId = "stock_check_status_id",
  /** column name */
  StockCheckTypeId = "stock_check_type_id",
}

/** input type for updating data in table "stock_check" */
export type Stock_Check_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "stock_check_status" */
export type Stock_Check_Status = {
  __typename?: "stock_check_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  stock_check_status_name: Scalars["String"];
  /** An array relationship */
  stock_checks: Array<Stock_Check>;
  /** An aggregate relationship */
  stock_checks_aggregate: Stock_Check_Aggregate;
};

/** columns and relationships of "stock_check_status" */
export type Stock_Check_StatusStock_ChecksArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** columns and relationships of "stock_check_status" */
export type Stock_Check_StatusStock_Checks_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** aggregated selection of "stock_check_status" */
export type Stock_Check_Status_Aggregate = {
  __typename?: "stock_check_status_aggregate";
  aggregate?: Maybe<Stock_Check_Status_Aggregate_Fields>;
  nodes: Array<Stock_Check_Status>;
};

/** aggregate fields of "stock_check_status" */
export type Stock_Check_Status_Aggregate_Fields = {
  __typename?: "stock_check_status_aggregate_fields";
  avg?: Maybe<Stock_Check_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Status_Max_Fields>;
  min?: Maybe<Stock_Check_Status_Min_Fields>;
  stddev?: Maybe<Stock_Check_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Status_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Status_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Status_Variance_Fields>;
};

/** aggregate fields of "stock_check_status" */
export type Stock_Check_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Stock_Check_Status_Avg_Fields = {
  __typename?: "stock_check_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "stock_check_status". All fields are combined with a logical 'AND'. */
export type Stock_Check_Status_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Status_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Status_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  stock_check_status_name?: Maybe<String_Comparison_Exp>;
  stock_checks?: Maybe<Stock_Check_Bool_Exp>;
  stock_checks_aggregate?: Maybe<Stock_Check_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_check_status" */
export enum Stock_Check_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockCheckStatusPkey = "stock_check_status_pkey",
  /** unique or primary key constraint on columns "stock_check_status_name" */
  StockCheckStatusStockCheckStatusNameKey = "stock_check_status_stock_check_status_name_key",
}

/** input type for incrementing numeric columns in table "stock_check_status" */
export type Stock_Check_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check_status" */
export type Stock_Check_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_status_name?: Maybe<Scalars["String"]>;
  stock_checks?: Maybe<Stock_Check_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Check_Status_Max_Fields = {
  __typename?: "stock_check_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Stock_Check_Status_Min_Fields = {
  __typename?: "stock_check_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "stock_check_status" */
export type Stock_Check_Status_Mutation_Response = {
  __typename?: "stock_check_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check_Status>;
};

/** input type for inserting object relation for remote table "stock_check_status" */
export type Stock_Check_Status_Obj_Rel_Insert_Input = {
  data: Stock_Check_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Status_On_Conflict>;
};

/** on_conflict condition type for table "stock_check_status" */
export type Stock_Check_Status_On_Conflict = {
  constraint: Stock_Check_Status_Constraint;
  update_columns?: Array<Stock_Check_Status_Update_Column>;
  where?: Maybe<Stock_Check_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check_status". */
export type Stock_Check_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_check_status_name?: Maybe<Order_By>;
  stock_checks_aggregate?: Maybe<Stock_Check_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_check_status */
export type Stock_Check_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check_status" */
export enum Stock_Check_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckStatusName = "stock_check_status_name",
}

/** input type for updating data in table "stock_check_status" */
export type Stock_Check_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Status_Stddev_Fields = {
  __typename?: "stock_check_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Status_Stddev_Pop_Fields = {
  __typename?: "stock_check_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Status_Stddev_Samp_Fields = {
  __typename?: "stock_check_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "stock_check_status" */
export type Stock_Check_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Status_Sum_Fields = {
  __typename?: "stock_check_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "stock_check_status" */
export enum Stock_Check_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckStatusName = "stock_check_status_name",
}

export type Stock_Check_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Status_Var_Pop_Fields = {
  __typename?: "stock_check_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Status_Var_Samp_Fields = {
  __typename?: "stock_check_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Stock_Check_Status_Variance_Fields = {
  __typename?: "stock_check_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Stddev_Fields = {
  __typename?: "stock_check_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "stock_check" */
export type Stock_Check_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Stddev_Pop_Fields = {
  __typename?: "stock_check_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "stock_check" */
export type Stock_Check_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Stddev_Samp_Fields = {
  __typename?: "stock_check_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "stock_check" */
export type Stock_Check_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_check" */
export type Stock_Check_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  location_id?: Maybe<Scalars["Int"]>;
  notes?: Maybe<Scalars["String"]>;
  reference?: Maybe<Scalars["String"]>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Sum_Fields = {
  __typename?: "stock_check_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  location_id?: Maybe<Scalars["Int"]>;
  stock_check_status_id?: Maybe<Scalars["Int"]>;
  stock_check_type_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "stock_check" */
export type Stock_Check_Sum_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_check_type" */
export type Stock_Check_Type = {
  __typename?: "stock_check_type";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  stock_check_type_name: Scalars["String"];
  /** An array relationship */
  stock_checks: Array<Stock_Check>;
  /** An aggregate relationship */
  stock_checks_aggregate: Stock_Check_Aggregate;
};

/** columns and relationships of "stock_check_type" */
export type Stock_Check_TypeStock_ChecksArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** columns and relationships of "stock_check_type" */
export type Stock_Check_TypeStock_Checks_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

/** aggregated selection of "stock_check_type" */
export type Stock_Check_Type_Aggregate = {
  __typename?: "stock_check_type_aggregate";
  aggregate?: Maybe<Stock_Check_Type_Aggregate_Fields>;
  nodes: Array<Stock_Check_Type>;
};

/** aggregate fields of "stock_check_type" */
export type Stock_Check_Type_Aggregate_Fields = {
  __typename?: "stock_check_type_aggregate_fields";
  avg?: Maybe<Stock_Check_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Check_Type_Max_Fields>;
  min?: Maybe<Stock_Check_Type_Min_Fields>;
  stddev?: Maybe<Stock_Check_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Check_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Check_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Check_Type_Sum_Fields>;
  var_pop?: Maybe<Stock_Check_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Check_Type_Var_Samp_Fields>;
  variance?: Maybe<Stock_Check_Type_Variance_Fields>;
};

/** aggregate fields of "stock_check_type" */
export type Stock_Check_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Check_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Stock_Check_Type_Avg_Fields = {
  __typename?: "stock_check_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "stock_check_type". All fields are combined with a logical 'AND'. */
export type Stock_Check_Type_Bool_Exp = {
  _and?: Maybe<Array<Stock_Check_Type_Bool_Exp>>;
  _not?: Maybe<Stock_Check_Type_Bool_Exp>;
  _or?: Maybe<Array<Stock_Check_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  stock_check_type_name?: Maybe<String_Comparison_Exp>;
  stock_checks?: Maybe<Stock_Check_Bool_Exp>;
  stock_checks_aggregate?: Maybe<Stock_Check_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_check_type" */
export enum Stock_Check_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockCheckTypePkey = "stock_check_type_pkey",
  /** unique or primary key constraint on columns "stock_check_type_name" */
  StockCheckTypeStockCheckTypeNameKey = "stock_check_type_stock_check_type_name_key",
}

/** input type for incrementing numeric columns in table "stock_check_type" */
export type Stock_Check_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_check_type" */
export type Stock_Check_Type_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_type_name?: Maybe<Scalars["String"]>;
  stock_checks?: Maybe<Stock_Check_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Check_Type_Max_Fields = {
  __typename?: "stock_check_type_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Stock_Check_Type_Min_Fields = {
  __typename?: "stock_check_type_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_type_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "stock_check_type" */
export type Stock_Check_Type_Mutation_Response = {
  __typename?: "stock_check_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Check_Type>;
};

/** input type for inserting object relation for remote table "stock_check_type" */
export type Stock_Check_Type_Obj_Rel_Insert_Input = {
  data: Stock_Check_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Check_Type_On_Conflict>;
};

/** on_conflict condition type for table "stock_check_type" */
export type Stock_Check_Type_On_Conflict = {
  constraint: Stock_Check_Type_Constraint;
  update_columns?: Array<Stock_Check_Type_Update_Column>;
  where?: Maybe<Stock_Check_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_check_type". */
export type Stock_Check_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  stock_check_type_name?: Maybe<Order_By>;
  stock_checks_aggregate?: Maybe<Stock_Check_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_check_type */
export type Stock_Check_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_check_type" */
export enum Stock_Check_Type_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckTypeName = "stock_check_type_name",
}

/** input type for updating data in table "stock_check_type" */
export type Stock_Check_Type_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Stock_Check_Type_Stddev_Fields = {
  __typename?: "stock_check_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Stock_Check_Type_Stddev_Pop_Fields = {
  __typename?: "stock_check_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Stock_Check_Type_Stddev_Samp_Fields = {
  __typename?: "stock_check_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "stock_check_type" */
export type Stock_Check_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Check_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Check_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_check_type_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Stock_Check_Type_Sum_Fields = {
  __typename?: "stock_check_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "stock_check_type" */
export enum Stock_Check_Type_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockCheckTypeName = "stock_check_type_name",
}

export type Stock_Check_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Type_Var_Pop_Fields = {
  __typename?: "stock_check_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Type_Var_Samp_Fields = {
  __typename?: "stock_check_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Stock_Check_Type_Variance_Fields = {
  __typename?: "stock_check_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** update columns of table "stock_check" */
export enum Stock_Check_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  LocationId = "location_id",
  /** column name */
  Notes = "notes",
  /** column name */
  Reference = "reference",
  /** column name */
  StockCheckStatusId = "stock_check_status_id",
  /** column name */
  StockCheckTypeId = "stock_check_type_id",
}

export type Stock_Check_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Check_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Check_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Check_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Check_Var_Pop_Fields = {
  __typename?: "stock_check_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "stock_check" */
export type Stock_Check_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Check_Var_Samp_Fields = {
  __typename?: "stock_check_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "stock_check" */
export type Stock_Check_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Check_Variance_Fields = {
  __typename?: "stock_check_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  location_id?: Maybe<Scalars["Float"]>;
  stock_check_status_id?: Maybe<Scalars["Float"]>;
  stock_check_type_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "stock_check" */
export type Stock_Check_Variance_Order_By = {
  id?: Maybe<Order_By>;
  location_id?: Maybe<Order_By>;
  stock_check_status_id?: Maybe<Order_By>;
  stock_check_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_condition" */
export type Stock_Condition = {
  __typename?: "stock_condition";
  created_at: Scalars["timestamptz"];
  /** An array relationship */
  file_copy_deletions: Array<File_Copy_Deletion>;
  /** An aggregate relationship */
  file_copy_deletions_aggregate: File_Copy_Deletion_Aggregate;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An array relationship */
  stock_allocations: Array<Stock_Allocation>;
  /** An aggregate relationship */
  stock_allocations_aggregate: Stock_Allocation_Aggregate;
  /** An array relationship */
  stock_check_line_fulfilments: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stock_check_line_fulfilments_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  /** An array relationship */
  stock_check_lines: Array<Stock_Check_Line>;
  /** An aggregate relationship */
  stock_check_lines_aggregate: Stock_Check_Line_Aggregate;
  stock_condition_name: Scalars["String"];
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionFile_Copy_DeletionsArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionFile_Copy_Deletions_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionStock_AllocationsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionStock_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionStock_Check_Line_FulfilmentsArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionStock_Check_Line_Fulfilments_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionStock_Check_LinesArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** columns and relationships of "stock_condition" */
export type Stock_ConditionStock_Check_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

/** aggregated selection of "stock_condition" */
export type Stock_Condition_Aggregate = {
  __typename?: "stock_condition_aggregate";
  aggregate?: Maybe<Stock_Condition_Aggregate_Fields>;
  nodes: Array<Stock_Condition>;
};

/** aggregate fields of "stock_condition" */
export type Stock_Condition_Aggregate_Fields = {
  __typename?: "stock_condition_aggregate_fields";
  avg?: Maybe<Stock_Condition_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Condition_Max_Fields>;
  min?: Maybe<Stock_Condition_Min_Fields>;
  stddev?: Maybe<Stock_Condition_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Condition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Condition_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Condition_Sum_Fields>;
  var_pop?: Maybe<Stock_Condition_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Condition_Var_Samp_Fields>;
  variance?: Maybe<Stock_Condition_Variance_Fields>;
};

/** aggregate fields of "stock_condition" */
export type Stock_Condition_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Condition_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Stock_Condition_Avg_Fields = {
  __typename?: "stock_condition_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "stock_condition". All fields are combined with a logical 'AND'. */
export type Stock_Condition_Bool_Exp = {
  _and?: Maybe<Array<Stock_Condition_Bool_Exp>>;
  _not?: Maybe<Stock_Condition_Bool_Exp>;
  _or?: Maybe<Array<Stock_Condition_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Bool_Exp>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Bool_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  stock_allocations?: Maybe<Stock_Allocation_Bool_Exp>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Bool_Exp>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
  stock_check_lines?: Maybe<Stock_Check_Line_Bool_Exp>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Bool_Exp>;
  stock_condition_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_condition" */
export enum Stock_Condition_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockConditionPkey = "stock_condition_pkey",
  /** unique or primary key constraint on columns "stock_condition_name" */
  StockConditionStockConditionNameKey = "stock_condition_stock_condition_name_key",
}

/** input type for incrementing numeric columns in table "stock_condition" */
export type Stock_Condition_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_condition" */
export type Stock_Condition_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  file_copy_deletions?: Maybe<File_Copy_Deletion_Arr_Rel_Insert_Input>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  stock_allocations?: Maybe<Stock_Allocation_Arr_Rel_Insert_Input>;
  stock_check_line_fulfilments?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
  stock_check_lines?: Maybe<Stock_Check_Line_Arr_Rel_Insert_Input>;
  stock_condition_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Stock_Condition_Max_Fields = {
  __typename?: "stock_condition_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_condition_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Stock_Condition_Min_Fields = {
  __typename?: "stock_condition_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_condition_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "stock_condition" */
export type Stock_Condition_Mutation_Response = {
  __typename?: "stock_condition_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Condition>;
};

/** input type for inserting object relation for remote table "stock_condition" */
export type Stock_Condition_Obj_Rel_Insert_Input = {
  data: Stock_Condition_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Condition_On_Conflict>;
};

/** on_conflict condition type for table "stock_condition" */
export type Stock_Condition_On_Conflict = {
  constraint: Stock_Condition_Constraint;
  update_columns?: Array<Stock_Condition_Update_Column>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_condition". */
export type Stock_Condition_Order_By = {
  created_at?: Maybe<Order_By>;
  file_copy_deletions_aggregate?: Maybe<File_Copy_Deletion_Aggregate_Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Order_By>;
  stock_check_line_fulfilments_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
  stock_check_lines_aggregate?: Maybe<Stock_Check_Line_Aggregate_Order_By>;
  stock_condition_name?: Maybe<Order_By>;
};

/** primary key columns input for table: stock_condition */
export type Stock_Condition_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_condition" */
export enum Stock_Condition_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockConditionName = "stock_condition_name",
}

/** input type for updating data in table "stock_condition" */
export type Stock_Condition_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_condition_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Stock_Condition_Stddev_Fields = {
  __typename?: "stock_condition_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Stock_Condition_Stddev_Pop_Fields = {
  __typename?: "stock_condition_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Stock_Condition_Stddev_Samp_Fields = {
  __typename?: "stock_condition_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "stock_condition" */
export type Stock_Condition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Condition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Condition_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_condition_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Stock_Condition_Sum_Fields = {
  __typename?: "stock_condition_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "stock_condition" */
export enum Stock_Condition_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockConditionName = "stock_condition_name",
}

export type Stock_Condition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Condition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Condition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Condition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Condition_Var_Pop_Fields = {
  __typename?: "stock_condition_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Stock_Condition_Var_Samp_Fields = {
  __typename?: "stock_condition_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Stock_Condition_Variance_Fields = {
  __typename?: "stock_condition_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "stock_status" */
export type Stock_Status = {
  __typename?: "stock_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An array relationship */
  stockCheckLineFulfilmentsByNewStockStatusId: Array<Stock_Check_Line_Fulfilment>;
  /** An aggregate relationship */
  stockCheckLineFulfilmentsByNewStockStatusId_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  stock_status_name: Scalars["String"];
};

/** columns and relationships of "stock_status" */
export type Stock_StatusProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "stock_status" */
export type Stock_StatusProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "stock_status" */
export type Stock_StatusStockCheckLineFulfilmentsByNewStockStatusIdArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** columns and relationships of "stock_status" */
export type Stock_StatusStockCheckLineFulfilmentsByNewStockStatusId_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

/** aggregated selection of "stock_status" */
export type Stock_Status_Aggregate = {
  __typename?: "stock_status_aggregate";
  aggregate?: Maybe<Stock_Status_Aggregate_Fields>;
  nodes: Array<Stock_Status>;
};

/** aggregate fields of "stock_status" */
export type Stock_Status_Aggregate_Fields = {
  __typename?: "stock_status_aggregate_fields";
  avg?: Maybe<Stock_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Stock_Status_Max_Fields>;
  min?: Maybe<Stock_Status_Min_Fields>;
  stddev?: Maybe<Stock_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Status_Sum_Fields>;
  var_pop?: Maybe<Stock_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Status_Var_Samp_Fields>;
  variance?: Maybe<Stock_Status_Variance_Fields>;
};

/** aggregate fields of "stock_status" */
export type Stock_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Stock_Status_Avg_Fields = {
  __typename?: "stock_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "stock_status". All fields are combined with a logical 'AND'. */
export type Stock_Status_Bool_Exp = {
  _and?: Maybe<Array<Stock_Status_Bool_Exp>>;
  _not?: Maybe<Stock_Status_Bool_Exp>;
  _or?: Maybe<Array<Stock_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  stockCheckLineFulfilmentsByNewStockStatusId?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
  stockCheckLineFulfilmentsByNewStockStatusId_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Bool_Exp>;
  stock_status_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_status" */
export enum Stock_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockStatusPkey = "stock_status_pkey",
  /** unique or primary key constraint on columns "stock_status_name" */
  StockStatusStockStatusNameKey = "stock_status_stock_status_name_key",
}

/** input type for incrementing numeric columns in table "stock_status" */
export type Stock_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "stock_status" */
export type Stock_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  stockCheckLineFulfilmentsByNewStockStatusId?: Maybe<Stock_Check_Line_Fulfilment_Arr_Rel_Insert_Input>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Stock_Status_Max_Fields = {
  __typename?: "stock_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Stock_Status_Min_Fields = {
  __typename?: "stock_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "stock_status" */
export type Stock_Status_Mutation_Response = {
  __typename?: "stock_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Status>;
};

/** input type for inserting object relation for remote table "stock_status" */
export type Stock_Status_Obj_Rel_Insert_Input = {
  data: Stock_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Status_On_Conflict>;
};

/** on_conflict condition type for table "stock_status" */
export type Stock_Status_On_Conflict = {
  constraint: Stock_Status_Constraint;
  update_columns?: Array<Stock_Status_Update_Column>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_status". */
export type Stock_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  stockCheckLineFulfilmentsByNewStockStatusId_aggregate?: Maybe<Stock_Check_Line_Fulfilment_Aggregate_Order_By>;
  stock_status_name?: Maybe<Order_By>;
};

/** primary key columns input for table: stock_status */
export type Stock_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "stock_status" */
export enum Stock_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockStatusName = "stock_status_name",
}

/** input type for updating data in table "stock_status" */
export type Stock_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Stock_Status_Stddev_Fields = {
  __typename?: "stock_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Stock_Status_Stddev_Pop_Fields = {
  __typename?: "stock_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Stock_Status_Stddev_Samp_Fields = {
  __typename?: "stock_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "stock_status" */
export type Stock_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  stock_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Stock_Status_Sum_Fields = {
  __typename?: "stock_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "stock_status" */
export enum Stock_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  StockStatusName = "stock_status_name",
}

export type Stock_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Status_Var_Pop_Fields = {
  __typename?: "stock_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Stock_Status_Var_Samp_Fields = {
  __typename?: "stock_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Stock_Status_Variance_Fields = {
  __typename?: "stock_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** fetch data from the table in a streaming manner: "attachment" */
  attachment_stream: Array<Attachment>;
  /** fetch data from the table: "attachment_type" */
  attachment_type: Array<Attachment_Type>;
  /** fetch aggregated fields from the table: "attachment_type" */
  attachment_type_aggregate: Attachment_Type_Aggregate;
  /** fetch data from the table: "attachment_type" using primary key columns */
  attachment_type_by_pk?: Maybe<Attachment_Type>;
  /** fetch data from the table in a streaming manner: "attachment_type" */
  attachment_type_stream: Array<Attachment_Type>;
  /** fetch data from the table: "base_product" */
  base_product: Array<Base_Product>;
  /** fetch aggregated fields from the table: "base_product" */
  base_product_aggregate: Base_Product_Aggregate;
  /** fetch data from the table in a streaming manner: "base_product" */
  base_product_stream: Array<Base_Product>;
  /** fetch data from the table: "case_change" */
  case_change: Array<Case_Change>;
  /** fetch aggregated fields from the table: "case_change" */
  case_change_aggregate: Case_Change_Aggregate;
  /** fetch data from the table: "case_change" using primary key columns */
  case_change_by_pk?: Maybe<Case_Change>;
  /** fetch data from the table: "case_change_input_line" */
  case_change_input_line: Array<Case_Change_Input_Line>;
  /** fetch aggregated fields from the table: "case_change_input_line" */
  case_change_input_line_aggregate: Case_Change_Input_Line_Aggregate;
  /** fetch data from the table: "case_change_input_line" using primary key columns */
  case_change_input_line_by_pk?: Maybe<Case_Change_Input_Line>;
  /** fetch data from the table in a streaming manner: "case_change_input_line" */
  case_change_input_line_stream: Array<Case_Change_Input_Line>;
  /** fetch data from the table: "case_change_output_line" */
  case_change_output_line: Array<Case_Change_Output_Line>;
  /** fetch aggregated fields from the table: "case_change_output_line" */
  case_change_output_line_aggregate: Case_Change_Output_Line_Aggregate;
  /** fetch data from the table: "case_change_output_line" using primary key columns */
  case_change_output_line_by_pk?: Maybe<Case_Change_Output_Line>;
  /** fetch data from the table in a streaming manner: "case_change_output_line" */
  case_change_output_line_stream: Array<Case_Change_Output_Line>;
  /** fetch data from the table: "case_change_status" */
  case_change_status: Array<Case_Change_Status>;
  /** fetch aggregated fields from the table: "case_change_status" */
  case_change_status_aggregate: Case_Change_Status_Aggregate;
  /** fetch data from the table: "case_change_status" using primary key columns */
  case_change_status_by_pk?: Maybe<Case_Change_Status>;
  /** fetch data from the table in a streaming manner: "case_change_status" */
  case_change_status_stream: Array<Case_Change_Status>;
  /** fetch data from the table in a streaming manner: "case_change" */
  case_change_stream: Array<Case_Change>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "courier" */
  courier: Array<Courier>;
  /** fetch aggregated fields from the table: "courier" */
  courier_aggregate: Courier_Aggregate;
  /** fetch data from the table: "courier" using primary key columns */
  courier_by_pk?: Maybe<Courier>;
  /** fetch data from the table: "courier_location" */
  courier_location: Array<Courier_Location>;
  /** fetch aggregated fields from the table: "courier_location" */
  courier_location_aggregate: Courier_Location_Aggregate;
  /** fetch data from the table: "courier_location" using primary key columns */
  courier_location_by_pk?: Maybe<Courier_Location>;
  /** fetch data from the table in a streaming manner: "courier_location" */
  courier_location_stream: Array<Courier_Location>;
  /** fetch data from the table: "courier_service" */
  courier_service: Array<Courier_Service>;
  /** fetch aggregated fields from the table: "courier_service" */
  courier_service_aggregate: Courier_Service_Aggregate;
  /** fetch data from the table: "courier_service" using primary key columns */
  courier_service_by_pk?: Maybe<Courier_Service>;
  /** fetch data from the table in a streaming manner: "courier_service" */
  courier_service_stream: Array<Courier_Service>;
  /** fetch data from the table in a streaming manner: "courier" */
  courier_stream: Array<Courier>;
  /** fetch data from the table: "databasechangelog" */
  databasechangelog: Array<Databasechangelog>;
  /** fetch aggregated fields from the table: "databasechangelog" */
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  /** fetch data from the table in a streaming manner: "databasechangelog" */
  databasechangelog_stream: Array<Databasechangelog>;
  /** fetch data from the table: "databasechangeloglock" */
  databasechangeloglock: Array<Databasechangeloglock>;
  /** fetch aggregated fields from the table: "databasechangeloglock" */
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  /** fetch data from the table: "databasechangeloglock" using primary key columns */
  databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** fetch data from the table in a streaming manner: "databasechangeloglock" */
  databasechangeloglock_stream: Array<Databasechangeloglock>;
  /** fetch data from the table: "file_copy_deletion" */
  file_copy_deletion: Array<File_Copy_Deletion>;
  /** fetch aggregated fields from the table: "file_copy_deletion" */
  file_copy_deletion_aggregate: File_Copy_Deletion_Aggregate;
  /** fetch data from the table: "file_copy_deletion" using primary key columns */
  file_copy_deletion_by_pk?: Maybe<File_Copy_Deletion>;
  /** fetch data from the table in a streaming manner: "file_copy_deletion" */
  file_copy_deletion_stream: Array<File_Copy_Deletion>;
  /** fetch data from the table: "folder" */
  folder: Array<Folder>;
  /** fetch aggregated fields from the table: "folder" */
  folder_aggregate: Folder_Aggregate;
  /** fetch data from the table: "folder" using primary key columns */
  folder_by_pk?: Maybe<Folder>;
  /** fetch data from the table in a streaming manner: "folder" */
  folder_stream: Array<Folder>;
  /** fetch data from the table: "inbound_manifest" */
  inbound_manifest: Array<Inbound_Manifest>;
  /** fetch aggregated fields from the table: "inbound_manifest" */
  inbound_manifest_aggregate: Inbound_Manifest_Aggregate;
  /** fetch data from the table: "inbound_manifest" using primary key columns */
  inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line: Array<Inbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" using primary key columns */
  inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line_stream: Array<Inbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "inbound_manifest_line" */
  inbound_manifest_line: Array<Inbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_line" */
  inbound_manifest_line_aggregate: Inbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_line" using primary key columns */
  inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** fetch data from the table: "inbound_manifest_line_status" */
  inbound_manifest_line_status: Array<Inbound_Manifest_Line_Status>;
  /** fetch aggregated fields from the table: "inbound_manifest_line_status" */
  inbound_manifest_line_status_aggregate: Inbound_Manifest_Line_Status_Aggregate;
  /** fetch data from the table: "inbound_manifest_line_status" using primary key columns */
  inbound_manifest_line_status_by_pk?: Maybe<Inbound_Manifest_Line_Status>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_line_status" */
  inbound_manifest_line_status_stream: Array<Inbound_Manifest_Line_Status>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_line" */
  inbound_manifest_line_stream: Array<Inbound_Manifest_Line>;
  /** fetch data from the table: "inbound_manifest_status" */
  inbound_manifest_status: Array<Inbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "inbound_manifest_status" */
  inbound_manifest_status_aggregate: Inbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "inbound_manifest_status" using primary key columns */
  inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_status" */
  inbound_manifest_status_stream: Array<Inbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "inbound_manifest" */
  inbound_manifest_stream: Array<Inbound_Manifest>;
  /** fetch data from the table: "internal_transfer" */
  internal_transfer: Array<Internal_Transfer>;
  /** fetch aggregated fields from the table: "internal_transfer" */
  internal_transfer_aggregate: Internal_Transfer_Aggregate;
  /** fetch data from the table: "internal_transfer" using primary key columns */
  internal_transfer_by_pk?: Maybe<Internal_Transfer>;
  /** fetch data from the table: "internal_transfer_line" */
  internal_transfer_line: Array<Internal_Transfer_Line>;
  /** fetch aggregated fields from the table: "internal_transfer_line" */
  internal_transfer_line_aggregate: Internal_Transfer_Line_Aggregate;
  /** fetch data from the table: "internal_transfer_line" using primary key columns */
  internal_transfer_line_by_pk?: Maybe<Internal_Transfer_Line>;
  /** fetch data from the table: "internal_transfer_line_status" */
  internal_transfer_line_status: Array<Internal_Transfer_Line_Status>;
  /** fetch aggregated fields from the table: "internal_transfer_line_status" */
  internal_transfer_line_status_aggregate: Internal_Transfer_Line_Status_Aggregate;
  /** fetch data from the table: "internal_transfer_line_status" using primary key columns */
  internal_transfer_line_status_by_pk?: Maybe<Internal_Transfer_Line_Status>;
  /** fetch data from the table in a streaming manner: "internal_transfer_line_status" */
  internal_transfer_line_status_stream: Array<Internal_Transfer_Line_Status>;
  /** fetch data from the table in a streaming manner: "internal_transfer_line" */
  internal_transfer_line_stream: Array<Internal_Transfer_Line>;
  /** fetch data from the table: "internal_transfer_status" */
  internal_transfer_status: Array<Internal_Transfer_Status>;
  /** fetch aggregated fields from the table: "internal_transfer_status" */
  internal_transfer_status_aggregate: Internal_Transfer_Status_Aggregate;
  /** fetch data from the table: "internal_transfer_status" using primary key columns */
  internal_transfer_status_by_pk?: Maybe<Internal_Transfer_Status>;
  /** fetch data from the table in a streaming manner: "internal_transfer_status" */
  internal_transfer_status_stream: Array<Internal_Transfer_Status>;
  /** fetch data from the table in a streaming manner: "internal_transfer" */
  internal_transfer_stream: Array<Internal_Transfer>;
  /** fetch data from the table: "location" */
  location: Array<Location>;
  /** fetch aggregated fields from the table: "location" */
  location_aggregate: Location_Aggregate;
  /** fetch data from the table: "location" using primary key columns */
  location_by_pk?: Maybe<Location>;
  /** fetch data from the table in a streaming manner: "location" */
  location_stream: Array<Location>;
  /** fetch data from the table: "location_type" */
  location_type: Array<Location_Type>;
  /** fetch aggregated fields from the table: "location_type" */
  location_type_aggregate: Location_Type_Aggregate;
  /** fetch data from the table: "location_type" using primary key columns */
  location_type_by_pk?: Maybe<Location_Type>;
  /** fetch data from the table in a streaming manner: "location_type" */
  location_type_stream: Array<Location_Type>;
  /** fetch data from the table: "logistics_type" */
  logistics_type: Array<Logistics_Type>;
  /** fetch aggregated fields from the table: "logistics_type" */
  logistics_type_aggregate: Logistics_Type_Aggregate;
  /** fetch data from the table: "logistics_type" using primary key columns */
  logistics_type_by_pk?: Maybe<Logistics_Type>;
  /** fetch data from the table in a streaming manner: "logistics_type" */
  logistics_type_stream: Array<Logistics_Type>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table in a streaming manner: "organisation" */
  organisation_stream: Array<Organisation>;
  /** fetch data from the table: "outbound_manifest" */
  outbound_manifest: Array<Outbound_Manifest>;
  /** fetch aggregated fields from the table: "outbound_manifest" */
  outbound_manifest_aggregate: Outbound_Manifest_Aggregate;
  /** fetch data from the table: "outbound_manifest" using primary key columns */
  outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** fetch data from the table: "outbound_manifest_fulfilment" */
  outbound_manifest_fulfilment: Array<Outbound_Manifest_Fulfilment>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment" */
  outbound_manifest_fulfilment_aggregate: Outbound_Manifest_Fulfilment_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment" using primary key columns */
  outbound_manifest_fulfilment_by_pk?: Maybe<Outbound_Manifest_Fulfilment>;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line: Array<Outbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" using primary key columns */
  outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "outbound_manifest_fulfilment_line_status" */
  outbound_manifest_fulfilment_line_status: Array<Outbound_Manifest_Fulfilment_Line_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_line_status" */
  outbound_manifest_fulfilment_line_status_aggregate: Outbound_Manifest_Fulfilment_Line_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_line_status" using primary key columns */
  outbound_manifest_fulfilment_line_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_fulfilment_line_status" */
  outbound_manifest_fulfilment_line_status_stream: Array<Outbound_Manifest_Fulfilment_Line_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line_stream: Array<Outbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "outbound_manifest_fulfilment_status" */
  outbound_manifest_fulfilment_status: Array<Outbound_Manifest_Fulfilment_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_status" */
  outbound_manifest_fulfilment_status_aggregate: Outbound_Manifest_Fulfilment_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_status" using primary key columns */
  outbound_manifest_fulfilment_status_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_fulfilment_status" */
  outbound_manifest_fulfilment_status_stream: Array<Outbound_Manifest_Fulfilment_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_fulfilment" */
  outbound_manifest_fulfilment_stream: Array<Outbound_Manifest_Fulfilment>;
  /** fetch data from the table: "outbound_manifest_line" */
  outbound_manifest_line: Array<Outbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_line" */
  outbound_manifest_line_aggregate: Outbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_line" using primary key columns */
  outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** fetch data from the table: "outbound_manifest_line_status" */
  outbound_manifest_line_status: Array<Outbound_Manifest_Line_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_line_status" */
  outbound_manifest_line_status_aggregate: Outbound_Manifest_Line_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_line_status" using primary key columns */
  outbound_manifest_line_status_by_pk?: Maybe<Outbound_Manifest_Line_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_line_status" */
  outbound_manifest_line_status_stream: Array<Outbound_Manifest_Line_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_line" */
  outbound_manifest_line_stream: Array<Outbound_Manifest_Line>;
  /** fetch data from the table: "outbound_manifest_return" */
  outbound_manifest_return: Array<Outbound_Manifest_Return>;
  /** fetch aggregated fields from the table: "outbound_manifest_return" */
  outbound_manifest_return_aggregate: Outbound_Manifest_Return_Aggregate;
  /** fetch data from the table: "outbound_manifest_return" using primary key columns */
  outbound_manifest_return_by_pk?: Maybe<Outbound_Manifest_Return>;
  /** fetch data from the table: "outbound_manifest_return_line" */
  outbound_manifest_return_line: Array<Outbound_Manifest_Return_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_return_line" */
  outbound_manifest_return_line_aggregate: Outbound_Manifest_Return_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_return_line" using primary key columns */
  outbound_manifest_return_line_by_pk?: Maybe<Outbound_Manifest_Return_Line>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_return_line" */
  outbound_manifest_return_line_stream: Array<Outbound_Manifest_Return_Line>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_return" */
  outbound_manifest_return_stream: Array<Outbound_Manifest_Return>;
  /** fetch data from the table: "outbound_manifest_status" */
  outbound_manifest_status: Array<Outbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_status" */
  outbound_manifest_status_aggregate: Outbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_status" using primary key columns */
  outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_status" */
  outbound_manifest_status_stream: Array<Outbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest" */
  outbound_manifest_stream: Array<Outbound_Manifest>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_stock" */
  product_stock: Array<Product_Stock>;
  /** fetch aggregated fields from the table: "product_stock" */
  product_stock_aggregate: Product_Stock_Aggregate;
  /** fetch data from the table: "product_stock" using primary key columns */
  product_stock_by_pk?: Maybe<Product_Stock>;
  /** fetch data from the table in a streaming manner: "product_stock" */
  product_stock_stream: Array<Product_Stock>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "recipe" */
  recipe: Array<Recipe>;
  /** fetch aggregated fields from the table: "recipe" */
  recipe_aggregate: Recipe_Aggregate;
  /** fetch data from the table: "recipe" using primary key columns */
  recipe_by_pk?: Maybe<Recipe>;
  /** fetch data from the table: "recipe_input" */
  recipe_input: Array<Recipe_Input>;
  /** fetch aggregated fields from the table: "recipe_input" */
  recipe_input_aggregate: Recipe_Input_Aggregate;
  /** fetch data from the table: "recipe_input" using primary key columns */
  recipe_input_by_pk?: Maybe<Recipe_Input>;
  /** fetch data from the table in a streaming manner: "recipe_input" */
  recipe_input_stream: Array<Recipe_Input>;
  /** fetch data from the table: "recipe_output" */
  recipe_output: Array<Recipe_Output>;
  /** fetch aggregated fields from the table: "recipe_output" */
  recipe_output_aggregate: Recipe_Output_Aggregate;
  /** fetch data from the table: "recipe_output" using primary key columns */
  recipe_output_by_pk?: Maybe<Recipe_Output>;
  /** fetch data from the table in a streaming manner: "recipe_output" */
  recipe_output_stream: Array<Recipe_Output>;
  /** fetch data from the table in a streaming manner: "recipe" */
  recipe_stream: Array<Recipe>;
  /** fetch data from the table: "recipe_type" */
  recipe_type: Array<Recipe_Type>;
  /** fetch aggregated fields from the table: "recipe_type" */
  recipe_type_aggregate: Recipe_Type_Aggregate;
  /** fetch data from the table: "recipe_type" using primary key columns */
  recipe_type_by_pk?: Maybe<Recipe_Type>;
  /** fetch data from the table in a streaming manner: "recipe_type" */
  recipe_type_stream: Array<Recipe_Type>;
  /** fetch data from the table: "service_account" */
  service_account: Array<Service_Account>;
  /** fetch aggregated fields from the table: "service_account" */
  service_account_aggregate: Service_Account_Aggregate;
  /** fetch data from the table: "service_account" using primary key columns */
  service_account_by_pk?: Maybe<Service_Account>;
  /** fetch data from the table in a streaming manner: "service_account" */
  service_account_stream: Array<Service_Account>;
  /** fetch data from the table: "stock_allocation" */
  stock_allocation: Array<Stock_Allocation>;
  /** fetch aggregated fields from the table: "stock_allocation" */
  stock_allocation_aggregate: Stock_Allocation_Aggregate;
  /** fetch data from the table: "stock_allocation" using primary key columns */
  stock_allocation_by_pk?: Maybe<Stock_Allocation>;
  /** fetch data from the table: "stock_allocation_fulfilment" */
  stock_allocation_fulfilment: Array<Stock_Allocation_Fulfilment>;
  /** fetch aggregated fields from the table: "stock_allocation_fulfilment" */
  stock_allocation_fulfilment_aggregate: Stock_Allocation_Fulfilment_Aggregate;
  /** fetch data from the table: "stock_allocation_fulfilment" using primary key columns */
  stock_allocation_fulfilment_by_pk?: Maybe<Stock_Allocation_Fulfilment>;
  /** fetch data from the table in a streaming manner: "stock_allocation_fulfilment" */
  stock_allocation_fulfilment_stream: Array<Stock_Allocation_Fulfilment>;
  /** fetch data from the table in a streaming manner: "stock_allocation" */
  stock_allocation_stream: Array<Stock_Allocation>;
  /** fetch data from the table: "stock_check" */
  stock_check: Array<Stock_Check>;
  /** fetch aggregated fields from the table: "stock_check" */
  stock_check_aggregate: Stock_Check_Aggregate;
  /** fetch data from the table: "stock_check" using primary key columns */
  stock_check_by_pk?: Maybe<Stock_Check>;
  /** fetch data from the table: "stock_check_line" */
  stock_check_line: Array<Stock_Check_Line>;
  /** fetch aggregated fields from the table: "stock_check_line" */
  stock_check_line_aggregate: Stock_Check_Line_Aggregate;
  /** fetch data from the table: "stock_check_line" using primary key columns */
  stock_check_line_by_pk?: Maybe<Stock_Check_Line>;
  /** fetch data from the table: "stock_check_line_fulfilment" */
  stock_check_line_fulfilment: Array<Stock_Check_Line_Fulfilment>;
  /** fetch aggregated fields from the table: "stock_check_line_fulfilment" */
  stock_check_line_fulfilment_aggregate: Stock_Check_Line_Fulfilment_Aggregate;
  /** fetch data from the table: "stock_check_line_fulfilment" using primary key columns */
  stock_check_line_fulfilment_by_pk?: Maybe<Stock_Check_Line_Fulfilment>;
  /** fetch data from the table: "stock_check_line_fulfilment_status" */
  stock_check_line_fulfilment_status: Array<Stock_Check_Line_Fulfilment_Status>;
  /** fetch aggregated fields from the table: "stock_check_line_fulfilment_status" */
  stock_check_line_fulfilment_status_aggregate: Stock_Check_Line_Fulfilment_Status_Aggregate;
  /** fetch data from the table: "stock_check_line_fulfilment_status" using primary key columns */
  stock_check_line_fulfilment_status_by_pk?: Maybe<Stock_Check_Line_Fulfilment_Status>;
  /** fetch data from the table in a streaming manner: "stock_check_line_fulfilment_status" */
  stock_check_line_fulfilment_status_stream: Array<Stock_Check_Line_Fulfilment_Status>;
  /** fetch data from the table in a streaming manner: "stock_check_line_fulfilment" */
  stock_check_line_fulfilment_stream: Array<Stock_Check_Line_Fulfilment>;
  /** fetch data from the table: "stock_check_line_status" */
  stock_check_line_status: Array<Stock_Check_Line_Status>;
  /** fetch aggregated fields from the table: "stock_check_line_status" */
  stock_check_line_status_aggregate: Stock_Check_Line_Status_Aggregate;
  /** fetch data from the table: "stock_check_line_status" using primary key columns */
  stock_check_line_status_by_pk?: Maybe<Stock_Check_Line_Status>;
  /** fetch data from the table in a streaming manner: "stock_check_line_status" */
  stock_check_line_status_stream: Array<Stock_Check_Line_Status>;
  /** fetch data from the table in a streaming manner: "stock_check_line" */
  stock_check_line_stream: Array<Stock_Check_Line>;
  /** fetch data from the table: "stock_check_status" */
  stock_check_status: Array<Stock_Check_Status>;
  /** fetch aggregated fields from the table: "stock_check_status" */
  stock_check_status_aggregate: Stock_Check_Status_Aggregate;
  /** fetch data from the table: "stock_check_status" using primary key columns */
  stock_check_status_by_pk?: Maybe<Stock_Check_Status>;
  /** fetch data from the table in a streaming manner: "stock_check_status" */
  stock_check_status_stream: Array<Stock_Check_Status>;
  /** fetch data from the table in a streaming manner: "stock_check" */
  stock_check_stream: Array<Stock_Check>;
  /** fetch data from the table: "stock_check_type" */
  stock_check_type: Array<Stock_Check_Type>;
  /** fetch aggregated fields from the table: "stock_check_type" */
  stock_check_type_aggregate: Stock_Check_Type_Aggregate;
  /** fetch data from the table: "stock_check_type" using primary key columns */
  stock_check_type_by_pk?: Maybe<Stock_Check_Type>;
  /** fetch data from the table in a streaming manner: "stock_check_type" */
  stock_check_type_stream: Array<Stock_Check_Type>;
  /** fetch data from the table: "stock_condition" */
  stock_condition: Array<Stock_Condition>;
  /** fetch aggregated fields from the table: "stock_condition" */
  stock_condition_aggregate: Stock_Condition_Aggregate;
  /** fetch data from the table: "stock_condition" using primary key columns */
  stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** fetch data from the table in a streaming manner: "stock_condition" */
  stock_condition_stream: Array<Stock_Condition>;
  /** fetch data from the table: "stock_status" */
  stock_status: Array<Stock_Status>;
  /** fetch aggregated fields from the table: "stock_status" */
  stock_status_aggregate: Stock_Status_Aggregate;
  /** fetch data from the table: "stock_status" using primary key columns */
  stock_status_by_pk?: Maybe<Stock_Status>;
  /** fetch data from the table in a streaming manner: "stock_status" */
  stock_status_stream: Array<Stock_Status>;
  /** fetch data from the table: "vw_finance_stock_report" */
  vw_finance_stock_report: Array<Vw_Finance_Stock_Report>;
  /** fetch aggregated fields from the table: "vw_finance_stock_report" */
  vw_finance_stock_report_aggregate: Vw_Finance_Stock_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_finance_stock_report" */
  vw_finance_stock_report_stream: Array<Vw_Finance_Stock_Report>;
  /** fetch data from the table: "vw_inventory_report" */
  vw_inventory_report: Array<Vw_Inventory_Report>;
  /** fetch aggregated fields from the table: "vw_inventory_report" */
  vw_inventory_report_aggregate: Vw_Inventory_Report_Aggregate;
  /** fetch data from the table in a streaming manner: "vw_inventory_report" */
  vw_inventory_report_stream: Array<Vw_Inventory_Report>;
  /** fetch data from the table: "warehouse" */
  warehouse: Array<Warehouse>;
  /** fetch aggregated fields from the table: "warehouse" */
  warehouse_aggregate: Warehouse_Aggregate;
  /** fetch data from the table: "warehouse" using primary key columns */
  warehouse_by_pk?: Maybe<Warehouse>;
  /** fetch data from the table in a streaming manner: "warehouse" */
  warehouse_stream: Array<Warehouse>;
  /** fetch data from the table: "work_order" */
  work_order: Array<Work_Order>;
  /** fetch aggregated fields from the table: "work_order" */
  work_order_aggregate: Work_Order_Aggregate;
  /** fetch data from the table: "work_order" using primary key columns */
  work_order_by_pk?: Maybe<Work_Order>;
  /** fetch data from the table: "work_order_input_line" */
  work_order_input_line: Array<Work_Order_Input_Line>;
  /** fetch aggregated fields from the table: "work_order_input_line" */
  work_order_input_line_aggregate: Work_Order_Input_Line_Aggregate;
  /** fetch data from the table: "work_order_input_line" using primary key columns */
  work_order_input_line_by_pk?: Maybe<Work_Order_Input_Line>;
  /** fetch data from the table in a streaming manner: "work_order_input_line" */
  work_order_input_line_stream: Array<Work_Order_Input_Line>;
  /** fetch data from the table: "work_order_output_line" */
  work_order_output_line: Array<Work_Order_Output_Line>;
  /** fetch aggregated fields from the table: "work_order_output_line" */
  work_order_output_line_aggregate: Work_Order_Output_Line_Aggregate;
  /** fetch data from the table: "work_order_output_line" using primary key columns */
  work_order_output_line_by_pk?: Maybe<Work_Order_Output_Line>;
  /** fetch data from the table in a streaming manner: "work_order_output_line" */
  work_order_output_line_stream: Array<Work_Order_Output_Line>;
  /** fetch data from the table: "work_order_status" */
  work_order_status: Array<Work_Order_Status>;
  /** fetch aggregated fields from the table: "work_order_status" */
  work_order_status_aggregate: Work_Order_Status_Aggregate;
  /** fetch data from the table: "work_order_status" using primary key columns */
  work_order_status_by_pk?: Maybe<Work_Order_Status>;
  /** fetch data from the table in a streaming manner: "work_order_status" */
  work_order_status_stream: Array<Work_Order_Status>;
  /** fetch data from the table in a streaming manner: "work_order" */
  work_order_stream: Array<Work_Order>;
};

export type Subscription_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Address_Stream_Cursor_Input>>;
  where?: Maybe<Address_Bool_Exp>;
};

export type Subscription_RootAttachmentArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

export type Subscription_RootAttachment_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

export type Subscription_RootAttachment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootAttachment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Attachment_Stream_Cursor_Input>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

export type Subscription_RootAttachment_TypeArgs = {
  distinct_on?: Maybe<Array<Attachment_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Type_Order_By>>;
  where?: Maybe<Attachment_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Attachment_Type_Order_By>>;
  where?: Maybe<Attachment_Type_Bool_Exp>;
};

export type Subscription_RootAttachment_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootAttachment_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Attachment_Type_Stream_Cursor_Input>>;
  where?: Maybe<Attachment_Type_Bool_Exp>;
};

export type Subscription_RootBase_ProductArgs = {
  distinct_on?: Maybe<Array<Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Base_Product_Order_By>>;
  where?: Maybe<Base_Product_Bool_Exp>;
};

export type Subscription_RootBase_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Base_Product_Order_By>>;
  where?: Maybe<Base_Product_Bool_Exp>;
};

export type Subscription_RootBase_Product_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Base_Product_Stream_Cursor_Input>>;
  where?: Maybe<Base_Product_Bool_Exp>;
};

export type Subscription_RootCase_ChangeArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

export type Subscription_RootCase_Change_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

export type Subscription_RootCase_Change_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCase_Change_Input_LineArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

export type Subscription_RootCase_Change_Input_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Input_Line_Order_By>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

export type Subscription_RootCase_Change_Input_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCase_Change_Input_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Case_Change_Input_Line_Stream_Cursor_Input>>;
  where?: Maybe<Case_Change_Input_Line_Bool_Exp>;
};

export type Subscription_RootCase_Change_Output_LineArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

export type Subscription_RootCase_Change_Output_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Output_Line_Order_By>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

export type Subscription_RootCase_Change_Output_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCase_Change_Output_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Case_Change_Output_Line_Stream_Cursor_Input>>;
  where?: Maybe<Case_Change_Output_Line_Bool_Exp>;
};

export type Subscription_RootCase_Change_StatusArgs = {
  distinct_on?: Maybe<Array<Case_Change_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Status_Order_By>>;
  where?: Maybe<Case_Change_Status_Bool_Exp>;
};

export type Subscription_RootCase_Change_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Status_Order_By>>;
  where?: Maybe<Case_Change_Status_Bool_Exp>;
};

export type Subscription_RootCase_Change_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCase_Change_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Case_Change_Status_Stream_Cursor_Input>>;
  where?: Maybe<Case_Change_Status_Bool_Exp>;
};

export type Subscription_RootCase_Change_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Case_Change_Stream_Cursor_Input>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

export type Subscription_RootCountryArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};

export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};

export type Subscription_RootCountry_By_PkArgs = {
  country_code: Scalars["String"];
};

export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Country_Stream_Cursor_Input>>;
  where?: Maybe<Country_Bool_Exp>;
};

export type Subscription_RootCourierArgs = {
  distinct_on?: Maybe<Array<Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Order_By>>;
  where?: Maybe<Courier_Bool_Exp>;
};

export type Subscription_RootCourier_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Order_By>>;
  where?: Maybe<Courier_Bool_Exp>;
};

export type Subscription_RootCourier_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCourier_LocationArgs = {
  distinct_on?: Maybe<Array<Courier_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Location_Order_By>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

export type Subscription_RootCourier_Location_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Location_Order_By>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

export type Subscription_RootCourier_Location_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCourier_Location_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Courier_Location_Stream_Cursor_Input>>;
  where?: Maybe<Courier_Location_Bool_Exp>;
};

export type Subscription_RootCourier_ServiceArgs = {
  distinct_on?: Maybe<Array<Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Service_Order_By>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

export type Subscription_RootCourier_Service_AggregateArgs = {
  distinct_on?: Maybe<Array<Courier_Service_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Courier_Service_Order_By>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

export type Subscription_RootCourier_Service_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootCourier_Service_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Courier_Service_Stream_Cursor_Input>>;
  where?: Maybe<Courier_Service_Bool_Exp>;
};

export type Subscription_RootCourier_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Courier_Stream_Cursor_Input>>;
  where?: Maybe<Courier_Bool_Exp>;
};

export type Subscription_RootDatabasechangelogArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};

export type Subscription_RootDatabasechangelog_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};

export type Subscription_RootDatabasechangelog_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Databasechangelog_Stream_Cursor_Input>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};

export type Subscription_RootDatabasechangeloglockArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

export type Subscription_RootDatabasechangeloglock_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

export type Subscription_RootDatabasechangeloglock_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootDatabasechangeloglock_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Databasechangeloglock_Stream_Cursor_Input>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

export type Subscription_RootFile_Copy_DeletionArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

export type Subscription_RootFile_Copy_Deletion_AggregateArgs = {
  distinct_on?: Maybe<Array<File_Copy_Deletion_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<File_Copy_Deletion_Order_By>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

export type Subscription_RootFile_Copy_Deletion_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootFile_Copy_Deletion_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<File_Copy_Deletion_Stream_Cursor_Input>>;
  where?: Maybe<File_Copy_Deletion_Bool_Exp>;
};

export type Subscription_RootFolderArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};

export type Subscription_RootFolder_AggregateArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};

export type Subscription_RootFolder_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootFolder_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Folder_Stream_Cursor_Input>>;
  where?: Maybe<Folder_Bool_Exp>;
};

export type Subscription_RootInbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInbound_Manifest_Fulfilment_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInbound_Manifest_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInbound_Manifest_Line_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Inbound_Manifest_Line_Status_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Line_Status_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Inbound_Manifest_Line_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInbound_Manifest_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Inbound_Manifest_Status_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

export type Subscription_RootInbound_Manifest_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Inbound_Manifest_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

export type Subscription_RootInternal_TransferArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Order_By>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInternal_Transfer_LineArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInternal_Transfer_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Line_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInternal_Transfer_Line_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Internal_Transfer_Line_Status_Stream_Cursor_Input>>;
  where?: Maybe<Internal_Transfer_Line_Status_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Internal_Transfer_Line_Stream_Cursor_Input>>;
  where?: Maybe<Internal_Transfer_Line_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_StatusArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Status_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Internal_Transfer_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Internal_Transfer_Status_Order_By>>;
  where?: Maybe<Internal_Transfer_Status_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootInternal_Transfer_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Internal_Transfer_Status_Stream_Cursor_Input>>;
  where?: Maybe<Internal_Transfer_Status_Bool_Exp>;
};

export type Subscription_RootInternal_Transfer_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Internal_Transfer_Stream_Cursor_Input>>;
  where?: Maybe<Internal_Transfer_Bool_Exp>;
};

export type Subscription_RootLocationArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

export type Subscription_RootLocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

export type Subscription_RootLocation_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootLocation_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Location_Stream_Cursor_Input>>;
  where?: Maybe<Location_Bool_Exp>;
};

export type Subscription_RootLocation_TypeArgs = {
  distinct_on?: Maybe<Array<Location_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Type_Order_By>>;
  where?: Maybe<Location_Type_Bool_Exp>;
};

export type Subscription_RootLocation_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Type_Order_By>>;
  where?: Maybe<Location_Type_Bool_Exp>;
};

export type Subscription_RootLocation_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootLocation_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Location_Type_Stream_Cursor_Input>>;
  where?: Maybe<Location_Type_Bool_Exp>;
};

export type Subscription_RootLogistics_TypeArgs = {
  distinct_on?: Maybe<Array<Logistics_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Logistics_Type_Order_By>>;
  where?: Maybe<Logistics_Type_Bool_Exp>;
};

export type Subscription_RootLogistics_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Logistics_Type_Order_By>>;
  where?: Maybe<Logistics_Type_Bool_Exp>;
};

export type Subscription_RootLogistics_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootLogistics_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Logistics_Type_Stream_Cursor_Input>>;
  where?: Maybe<Logistics_Type_Bool_Exp>;
};

export type Subscription_RootOrganisationArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

export type Subscription_RootOrganisation_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

export type Subscription_RootOrganisation_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOrganisation_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Organisation_Stream_Cursor_Input>>;
  where?: Maybe<Organisation_Bool_Exp>;
};

export type Subscription_RootOutbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_FulfilmentArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Fulfilment_Line_Status_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Fulfilment_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Fulfilment_Status_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Fulfilment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Fulfilment_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Line_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Line_Status_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Line_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Line_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_ReturnArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Return_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Return_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Return_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Return_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Return_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Return_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Return_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Return_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Return_Line_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Return_Line_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Return_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Return_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Return_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootOutbound_Manifest_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Status_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

export type Subscription_RootOutbound_Manifest_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Outbound_Manifest_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};

export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_StockArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

export type Subscription_RootProduct_Stock_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

export type Subscription_RootProduct_Stock_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_Stock_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Stock_Stream_Cursor_Input>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Stream_Cursor_Input>>;
  where?: Maybe<Product_Bool_Exp>;
};

export type Subscription_RootRecipeArgs = {
  distinct_on?: Maybe<Array<Recipe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Order_By>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

export type Subscription_RootRecipe_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Order_By>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

export type Subscription_RootRecipe_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootRecipe_InputArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

export type Subscription_RootRecipe_Input_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Input_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Input_Order_By>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

export type Subscription_RootRecipe_Input_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootRecipe_Input_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Recipe_Input_Stream_Cursor_Input>>;
  where?: Maybe<Recipe_Input_Bool_Exp>;
};

export type Subscription_RootRecipe_OutputArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

export type Subscription_RootRecipe_Output_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Output_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Output_Order_By>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

export type Subscription_RootRecipe_Output_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootRecipe_Output_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Recipe_Output_Stream_Cursor_Input>>;
  where?: Maybe<Recipe_Output_Bool_Exp>;
};

export type Subscription_RootRecipe_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Recipe_Stream_Cursor_Input>>;
  where?: Maybe<Recipe_Bool_Exp>;
};

export type Subscription_RootRecipe_TypeArgs = {
  distinct_on?: Maybe<Array<Recipe_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Type_Order_By>>;
  where?: Maybe<Recipe_Type_Bool_Exp>;
};

export type Subscription_RootRecipe_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Recipe_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Recipe_Type_Order_By>>;
  where?: Maybe<Recipe_Type_Bool_Exp>;
};

export type Subscription_RootRecipe_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootRecipe_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Recipe_Type_Stream_Cursor_Input>>;
  where?: Maybe<Recipe_Type_Bool_Exp>;
};

export type Subscription_RootService_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

export type Subscription_RootService_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

export type Subscription_RootService_Account_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootService_Account_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Service_Account_Stream_Cursor_Input>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

export type Subscription_RootStock_AllocationArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

export type Subscription_RootStock_Allocation_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

export type Subscription_RootStock_Allocation_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Allocation_FulfilmentArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

export type Subscription_RootStock_Allocation_Fulfilment_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

export type Subscription_RootStock_Allocation_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Allocation_Fulfilment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Allocation_Fulfilment_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Allocation_Fulfilment_Bool_Exp>;
};

export type Subscription_RootStock_Allocation_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Allocation_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

export type Subscription_RootStock_CheckArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

export type Subscription_RootStock_Check_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Order_By>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

export type Subscription_RootStock_Check_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_LineArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Order_By>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_Line_FulfilmentArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Fulfilment_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Fulfilment_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_Line_Fulfilment_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Fulfilment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Fulfilment_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Fulfilment_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_Line_Fulfilment_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Line_Fulfilment_Status_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Fulfilment_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Line_Fulfilment_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Line_Fulfilment_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Line_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Line_Status_Order_By>>;
  where?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_Line_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Line_Status_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Line_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Line_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Line_Bool_Exp>;
};

export type Subscription_RootStock_Check_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Status_Order_By>>;
  where?: Maybe<Stock_Check_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Status_Order_By>>;
  where?: Maybe<Stock_Check_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Status_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Status_Bool_Exp>;
};

export type Subscription_RootStock_Check_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Bool_Exp>;
};

export type Subscription_RootStock_Check_TypeArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Type_Order_By>>;
  where?: Maybe<Stock_Check_Type_Bool_Exp>;
};

export type Subscription_RootStock_Check_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Check_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Check_Type_Order_By>>;
  where?: Maybe<Stock_Check_Type_Bool_Exp>;
};

export type Subscription_RootStock_Check_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Check_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Check_Type_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Check_Type_Bool_Exp>;
};

export type Subscription_RootStock_ConditionArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

export type Subscription_RootStock_Condition_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

export type Subscription_RootStock_Condition_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Condition_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Condition_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

export type Subscription_RootStock_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

export type Subscription_RootStock_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

export type Subscription_RootStock_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootStock_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Stock_Status_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

export type Subscription_RootVw_Finance_Stock_ReportArgs = {
  distinct_on?: Maybe<Array<Vw_Finance_Stock_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Finance_Stock_Report_Order_By>>;
  where?: Maybe<Vw_Finance_Stock_Report_Bool_Exp>;
};

export type Subscription_RootVw_Finance_Stock_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Finance_Stock_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Finance_Stock_Report_Order_By>>;
  where?: Maybe<Vw_Finance_Stock_Report_Bool_Exp>;
};

export type Subscription_RootVw_Finance_Stock_Report_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Vw_Finance_Stock_Report_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Finance_Stock_Report_Bool_Exp>;
};

export type Subscription_RootVw_Inventory_ReportArgs = {
  distinct_on?: Maybe<Array<Vw_Inventory_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Inventory_Report_Order_By>>;
  where?: Maybe<Vw_Inventory_Report_Bool_Exp>;
};

export type Subscription_RootVw_Inventory_Report_AggregateArgs = {
  distinct_on?: Maybe<Array<Vw_Inventory_Report_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Vw_Inventory_Report_Order_By>>;
  where?: Maybe<Vw_Inventory_Report_Bool_Exp>;
};

export type Subscription_RootVw_Inventory_Report_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Vw_Inventory_Report_Stream_Cursor_Input>>;
  where?: Maybe<Vw_Inventory_Report_Bool_Exp>;
};

export type Subscription_RootWarehouseArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

export type Subscription_RootWarehouse_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

export type Subscription_RootWarehouse_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootWarehouse_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Warehouse_Stream_Cursor_Input>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

export type Subscription_RootWork_OrderArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

export type Subscription_RootWork_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

export type Subscription_RootWork_Order_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootWork_Order_Input_LineArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

export type Subscription_RootWork_Order_Input_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

export type Subscription_RootWork_Order_Input_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootWork_Order_Input_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Work_Order_Input_Line_Stream_Cursor_Input>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

export type Subscription_RootWork_Order_Output_LineArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

export type Subscription_RootWork_Order_Output_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

export type Subscription_RootWork_Order_Output_Line_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootWork_Order_Output_Line_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Work_Order_Output_Line_Stream_Cursor_Input>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

export type Subscription_RootWork_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
};

export type Subscription_RootWork_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Status_Order_By>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
};

export type Subscription_RootWork_Order_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootWork_Order_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Work_Order_Status_Stream_Cursor_Input>>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
};

export type Subscription_RootWork_Order_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Work_Order_Stream_Cursor_Input>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

/** columns and relationships of "vw_finance_stock_report" */
export type Vw_Finance_Stock_Report = {
  __typename?: "vw_finance_stock_report";
  action_date_for_current_quantity?: Maybe<Scalars["date"]>;
  action_date_for_stock_movements?: Maybe<Scalars["date"]>;
  base_product?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  current_quantity?: Maybe<Scalars["numeric"]>;
  fsc_certified?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_certified?: Maybe<Scalars["String"]>;
  inbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  organisation?: Maybe<Scalars["String"]>;
  outbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  total_adjustment_on_date?: Maybe<Scalars["numeric"]>;
  warehouse?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "vw_finance_stock_report" */
export type Vw_Finance_Stock_Report_Aggregate = {
  __typename?: "vw_finance_stock_report_aggregate";
  aggregate?: Maybe<Vw_Finance_Stock_Report_Aggregate_Fields>;
  nodes: Array<Vw_Finance_Stock_Report>;
};

/** aggregate fields of "vw_finance_stock_report" */
export type Vw_Finance_Stock_Report_Aggregate_Fields = {
  __typename?: "vw_finance_stock_report_aggregate_fields";
  avg?: Maybe<Vw_Finance_Stock_Report_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Vw_Finance_Stock_Report_Max_Fields>;
  min?: Maybe<Vw_Finance_Stock_Report_Min_Fields>;
  stddev?: Maybe<Vw_Finance_Stock_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Finance_Stock_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Finance_Stock_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Finance_Stock_Report_Sum_Fields>;
  var_pop?: Maybe<Vw_Finance_Stock_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Finance_Stock_Report_Var_Samp_Fields>;
  variance?: Maybe<Vw_Finance_Stock_Report_Variance_Fields>;
};

/** aggregate fields of "vw_finance_stock_report" */
export type Vw_Finance_Stock_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Finance_Stock_Report_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Vw_Finance_Stock_Report_Avg_Fields = {
  __typename?: "vw_finance_stock_report_avg_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "vw_finance_stock_report". All fields are combined with a logical 'AND'. */
export type Vw_Finance_Stock_Report_Bool_Exp = {
  _and?: Maybe<Array<Vw_Finance_Stock_Report_Bool_Exp>>;
  _not?: Maybe<Vw_Finance_Stock_Report_Bool_Exp>;
  _or?: Maybe<Array<Vw_Finance_Stock_Report_Bool_Exp>>;
  action_date_for_current_quantity?: Maybe<Date_Comparison_Exp>;
  action_date_for_stock_movements?: Maybe<Date_Comparison_Exp>;
  base_product?: Maybe<String_Comparison_Exp>;
  base_product_id?: Maybe<Int_Comparison_Exp>;
  current_quantity?: Maybe<Numeric_Comparison_Exp>;
  fsc_certified?: Maybe<String_Comparison_Exp>;
  fsc_claim?: Maybe<String_Comparison_Exp>;
  grs_certified?: Maybe<String_Comparison_Exp>;
  inbound_quantity_on_date?: Maybe<Numeric_Comparison_Exp>;
  organisation?: Maybe<String_Comparison_Exp>;
  outbound_quantity_on_date?: Maybe<Numeric_Comparison_Exp>;
  total_adjustment_on_date?: Maybe<Numeric_Comparison_Exp>;
  warehouse?: Maybe<String_Comparison_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vw_Finance_Stock_Report_Max_Fields = {
  __typename?: "vw_finance_stock_report_max_fields";
  action_date_for_current_quantity?: Maybe<Scalars["date"]>;
  action_date_for_stock_movements?: Maybe<Scalars["date"]>;
  base_product?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  current_quantity?: Maybe<Scalars["numeric"]>;
  fsc_certified?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_certified?: Maybe<Scalars["String"]>;
  inbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  organisation?: Maybe<Scalars["String"]>;
  outbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  total_adjustment_on_date?: Maybe<Scalars["numeric"]>;
  warehouse?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate min on columns */
export type Vw_Finance_Stock_Report_Min_Fields = {
  __typename?: "vw_finance_stock_report_min_fields";
  action_date_for_current_quantity?: Maybe<Scalars["date"]>;
  action_date_for_stock_movements?: Maybe<Scalars["date"]>;
  base_product?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  current_quantity?: Maybe<Scalars["numeric"]>;
  fsc_certified?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_certified?: Maybe<Scalars["String"]>;
  inbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  organisation?: Maybe<Scalars["String"]>;
  outbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  total_adjustment_on_date?: Maybe<Scalars["numeric"]>;
  warehouse?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** Ordering options when selecting data from "vw_finance_stock_report". */
export type Vw_Finance_Stock_Report_Order_By = {
  action_date_for_current_quantity?: Maybe<Order_By>;
  action_date_for_stock_movements?: Maybe<Order_By>;
  base_product?: Maybe<Order_By>;
  base_product_id?: Maybe<Order_By>;
  current_quantity?: Maybe<Order_By>;
  fsc_certified?: Maybe<Order_By>;
  fsc_claim?: Maybe<Order_By>;
  grs_certified?: Maybe<Order_By>;
  inbound_quantity_on_date?: Maybe<Order_By>;
  organisation?: Maybe<Order_By>;
  outbound_quantity_on_date?: Maybe<Order_By>;
  total_adjustment_on_date?: Maybe<Order_By>;
  warehouse?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** select columns of table "vw_finance_stock_report" */
export enum Vw_Finance_Stock_Report_Select_Column {
  /** column name */
  ActionDateForCurrentQuantity = "action_date_for_current_quantity",
  /** column name */
  ActionDateForStockMovements = "action_date_for_stock_movements",
  /** column name */
  BaseProduct = "base_product",
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  CurrentQuantity = "current_quantity",
  /** column name */
  FscCertified = "fsc_certified",
  /** column name */
  FscClaim = "fsc_claim",
  /** column name */
  GrsCertified = "grs_certified",
  /** column name */
  InboundQuantityOnDate = "inbound_quantity_on_date",
  /** column name */
  Organisation = "organisation",
  /** column name */
  OutboundQuantityOnDate = "outbound_quantity_on_date",
  /** column name */
  TotalAdjustmentOnDate = "total_adjustment_on_date",
  /** column name */
  Warehouse = "warehouse",
  /** column name */
  WarehouseId = "warehouse_id",
}

/** aggregate stddev on columns */
export type Vw_Finance_Stock_Report_Stddev_Fields = {
  __typename?: "vw_finance_stock_report_stddev_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Vw_Finance_Stock_Report_Stddev_Pop_Fields = {
  __typename?: "vw_finance_stock_report_stddev_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Vw_Finance_Stock_Report_Stddev_Samp_Fields = {
  __typename?: "vw_finance_stock_report_stddev_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "vw_finance_stock_report" */
export type Vw_Finance_Stock_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vw_Finance_Stock_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vw_Finance_Stock_Report_Stream_Cursor_Value_Input = {
  action_date_for_current_quantity?: Maybe<Scalars["date"]>;
  action_date_for_stock_movements?: Maybe<Scalars["date"]>;
  base_product?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  current_quantity?: Maybe<Scalars["numeric"]>;
  fsc_certified?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_certified?: Maybe<Scalars["String"]>;
  inbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  organisation?: Maybe<Scalars["String"]>;
  outbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  total_adjustment_on_date?: Maybe<Scalars["numeric"]>;
  warehouse?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Vw_Finance_Stock_Report_Sum_Fields = {
  __typename?: "vw_finance_stock_report_sum_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  current_quantity?: Maybe<Scalars["numeric"]>;
  inbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  outbound_quantity_on_date?: Maybe<Scalars["numeric"]>;
  total_adjustment_on_date?: Maybe<Scalars["numeric"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
};

/** aggregate var_pop on columns */
export type Vw_Finance_Stock_Report_Var_Pop_Fields = {
  __typename?: "vw_finance_stock_report_var_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Vw_Finance_Stock_Report_Var_Samp_Fields = {
  __typename?: "vw_finance_stock_report_var_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Vw_Finance_Stock_Report_Variance_Fields = {
  __typename?: "vw_finance_stock_report_variance_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  current_quantity?: Maybe<Scalars["Float"]>;
  inbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  outbound_quantity_on_date?: Maybe<Scalars["Float"]>;
  total_adjustment_on_date?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "vw_inventory_report" */
export type Vw_Inventory_Report = {
  __typename?: "vw_inventory_report";
  alert?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  case_size?: Maybe<Scalars["Int"]>;
  days_since_first_stock?: Maybe<Scalars["Int"]>;
  fsc_cert?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_cert?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  prepacked_bundle?: Maybe<Scalars["Boolean"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["bigint"]>;
  recent_outbound_units?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["bigint"]>;
  units_in_prepacked?: Maybe<Scalars["numeric"]>;
  units_total?: Maybe<Scalars["numeric"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  weekly_usage_180d?: Maybe<Scalars["numeric"]>;
  weekly_usage_360d?: Maybe<Scalars["numeric"]>;
  weekly_usage_90d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_180d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_360d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_90d?: Maybe<Scalars["numeric"]>;
};

/** aggregated selection of "vw_inventory_report" */
export type Vw_Inventory_Report_Aggregate = {
  __typename?: "vw_inventory_report_aggregate";
  aggregate?: Maybe<Vw_Inventory_Report_Aggregate_Fields>;
  nodes: Array<Vw_Inventory_Report>;
};

/** aggregate fields of "vw_inventory_report" */
export type Vw_Inventory_Report_Aggregate_Fields = {
  __typename?: "vw_inventory_report_aggregate_fields";
  avg?: Maybe<Vw_Inventory_Report_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Vw_Inventory_Report_Max_Fields>;
  min?: Maybe<Vw_Inventory_Report_Min_Fields>;
  stddev?: Maybe<Vw_Inventory_Report_Stddev_Fields>;
  stddev_pop?: Maybe<Vw_Inventory_Report_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Vw_Inventory_Report_Stddev_Samp_Fields>;
  sum?: Maybe<Vw_Inventory_Report_Sum_Fields>;
  var_pop?: Maybe<Vw_Inventory_Report_Var_Pop_Fields>;
  var_samp?: Maybe<Vw_Inventory_Report_Var_Samp_Fields>;
  variance?: Maybe<Vw_Inventory_Report_Variance_Fields>;
};

/** aggregate fields of "vw_inventory_report" */
export type Vw_Inventory_Report_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Vw_Inventory_Report_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Vw_Inventory_Report_Avg_Fields = {
  __typename?: "vw_inventory_report_avg_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "vw_inventory_report". All fields are combined with a logical 'AND'. */
export type Vw_Inventory_Report_Bool_Exp = {
  _and?: Maybe<Array<Vw_Inventory_Report_Bool_Exp>>;
  _not?: Maybe<Vw_Inventory_Report_Bool_Exp>;
  _or?: Maybe<Array<Vw_Inventory_Report_Bool_Exp>>;
  alert?: Maybe<String_Comparison_Exp>;
  base_product_id?: Maybe<Int_Comparison_Exp>;
  case_size?: Maybe<Int_Comparison_Exp>;
  days_since_first_stock?: Maybe<Int_Comparison_Exp>;
  fsc_cert?: Maybe<String_Comparison_Exp>;
  fsc_claim?: Maybe<String_Comparison_Exp>;
  grs_cert?: Maybe<String_Comparison_Exp>;
  location?: Maybe<String_Comparison_Exp>;
  prepacked_bundle?: Maybe<Boolean_Comparison_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_name?: Maybe<String_Comparison_Exp>;
  quantity?: Maybe<Bigint_Comparison_Exp>;
  recent_outbound_units?: Maybe<String_Comparison_Exp>;
  sort?: Maybe<Bigint_Comparison_Exp>;
  units_in_prepacked?: Maybe<Numeric_Comparison_Exp>;
  units_total?: Maybe<Numeric_Comparison_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
  weekly_usage_180d?: Maybe<Numeric_Comparison_Exp>;
  weekly_usage_360d?: Maybe<Numeric_Comparison_Exp>;
  weekly_usage_90d?: Maybe<Numeric_Comparison_Exp>;
  weeks_remaining_180d?: Maybe<Numeric_Comparison_Exp>;
  weeks_remaining_360d?: Maybe<Numeric_Comparison_Exp>;
  weeks_remaining_90d?: Maybe<Numeric_Comparison_Exp>;
};

/** aggregate max on columns */
export type Vw_Inventory_Report_Max_Fields = {
  __typename?: "vw_inventory_report_max_fields";
  alert?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  case_size?: Maybe<Scalars["Int"]>;
  days_since_first_stock?: Maybe<Scalars["Int"]>;
  fsc_cert?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_cert?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["bigint"]>;
  recent_outbound_units?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["bigint"]>;
  units_in_prepacked?: Maybe<Scalars["numeric"]>;
  units_total?: Maybe<Scalars["numeric"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  weekly_usage_180d?: Maybe<Scalars["numeric"]>;
  weekly_usage_360d?: Maybe<Scalars["numeric"]>;
  weekly_usage_90d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_180d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_360d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_90d?: Maybe<Scalars["numeric"]>;
};

/** aggregate min on columns */
export type Vw_Inventory_Report_Min_Fields = {
  __typename?: "vw_inventory_report_min_fields";
  alert?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  case_size?: Maybe<Scalars["Int"]>;
  days_since_first_stock?: Maybe<Scalars["Int"]>;
  fsc_cert?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_cert?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["bigint"]>;
  recent_outbound_units?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["bigint"]>;
  units_in_prepacked?: Maybe<Scalars["numeric"]>;
  units_total?: Maybe<Scalars["numeric"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  weekly_usage_180d?: Maybe<Scalars["numeric"]>;
  weekly_usage_360d?: Maybe<Scalars["numeric"]>;
  weekly_usage_90d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_180d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_360d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_90d?: Maybe<Scalars["numeric"]>;
};

/** Ordering options when selecting data from "vw_inventory_report". */
export type Vw_Inventory_Report_Order_By = {
  alert?: Maybe<Order_By>;
  base_product_id?: Maybe<Order_By>;
  case_size?: Maybe<Order_By>;
  days_since_first_stock?: Maybe<Order_By>;
  fsc_cert?: Maybe<Order_By>;
  fsc_claim?: Maybe<Order_By>;
  grs_cert?: Maybe<Order_By>;
  location?: Maybe<Order_By>;
  prepacked_bundle?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recent_outbound_units?: Maybe<Order_By>;
  sort?: Maybe<Order_By>;
  units_in_prepacked?: Maybe<Order_By>;
  units_total?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  weekly_usage_180d?: Maybe<Order_By>;
  weekly_usage_360d?: Maybe<Order_By>;
  weekly_usage_90d?: Maybe<Order_By>;
  weeks_remaining_180d?: Maybe<Order_By>;
  weeks_remaining_360d?: Maybe<Order_By>;
  weeks_remaining_90d?: Maybe<Order_By>;
};

/** select columns of table "vw_inventory_report" */
export enum Vw_Inventory_Report_Select_Column {
  /** column name */
  Alert = "alert",
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  CaseSize = "case_size",
  /** column name */
  DaysSinceFirstStock = "days_since_first_stock",
  /** column name */
  FscCert = "fsc_cert",
  /** column name */
  FscClaim = "fsc_claim",
  /** column name */
  GrsCert = "grs_cert",
  /** column name */
  Location = "location",
  /** column name */
  PrepackedBundle = "prepacked_bundle",
  /** column name */
  ProductId = "product_id",
  /** column name */
  ProductName = "product_name",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecentOutboundUnits = "recent_outbound_units",
  /** column name */
  Sort = "sort",
  /** column name */
  UnitsInPrepacked = "units_in_prepacked",
  /** column name */
  UnitsTotal = "units_total",
  /** column name */
  WarehouseId = "warehouse_id",
  /** column name */
  WeeklyUsage_180d = "weekly_usage_180d",
  /** column name */
  WeeklyUsage_360d = "weekly_usage_360d",
  /** column name */
  WeeklyUsage_90d = "weekly_usage_90d",
  /** column name */
  WeeksRemaining_180d = "weeks_remaining_180d",
  /** column name */
  WeeksRemaining_360d = "weeks_remaining_360d",
  /** column name */
  WeeksRemaining_90d = "weeks_remaining_90d",
}

/** aggregate stddev on columns */
export type Vw_Inventory_Report_Stddev_Fields = {
  __typename?: "vw_inventory_report_stddev_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Vw_Inventory_Report_Stddev_Pop_Fields = {
  __typename?: "vw_inventory_report_stddev_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Vw_Inventory_Report_Stddev_Samp_Fields = {
  __typename?: "vw_inventory_report_stddev_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "vw_inventory_report" */
export type Vw_Inventory_Report_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Vw_Inventory_Report_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Vw_Inventory_Report_Stream_Cursor_Value_Input = {
  alert?: Maybe<Scalars["String"]>;
  base_product_id?: Maybe<Scalars["Int"]>;
  case_size?: Maybe<Scalars["Int"]>;
  days_since_first_stock?: Maybe<Scalars["Int"]>;
  fsc_cert?: Maybe<Scalars["String"]>;
  fsc_claim?: Maybe<Scalars["String"]>;
  grs_cert?: Maybe<Scalars["String"]>;
  location?: Maybe<Scalars["String"]>;
  prepacked_bundle?: Maybe<Scalars["Boolean"]>;
  product_id?: Maybe<Scalars["Int"]>;
  product_name?: Maybe<Scalars["String"]>;
  quantity?: Maybe<Scalars["bigint"]>;
  recent_outbound_units?: Maybe<Scalars["String"]>;
  sort?: Maybe<Scalars["bigint"]>;
  units_in_prepacked?: Maybe<Scalars["numeric"]>;
  units_total?: Maybe<Scalars["numeric"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  weekly_usage_180d?: Maybe<Scalars["numeric"]>;
  weekly_usage_360d?: Maybe<Scalars["numeric"]>;
  weekly_usage_90d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_180d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_360d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_90d?: Maybe<Scalars["numeric"]>;
};

/** aggregate sum on columns */
export type Vw_Inventory_Report_Sum_Fields = {
  __typename?: "vw_inventory_report_sum_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  case_size?: Maybe<Scalars["Int"]>;
  days_since_first_stock?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["bigint"]>;
  sort?: Maybe<Scalars["bigint"]>;
  units_in_prepacked?: Maybe<Scalars["numeric"]>;
  units_total?: Maybe<Scalars["numeric"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  weekly_usage_180d?: Maybe<Scalars["numeric"]>;
  weekly_usage_360d?: Maybe<Scalars["numeric"]>;
  weekly_usage_90d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_180d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_360d?: Maybe<Scalars["numeric"]>;
  weeks_remaining_90d?: Maybe<Scalars["numeric"]>;
};

/** aggregate var_pop on columns */
export type Vw_Inventory_Report_Var_Pop_Fields = {
  __typename?: "vw_inventory_report_var_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Vw_Inventory_Report_Var_Samp_Fields = {
  __typename?: "vw_inventory_report_var_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Vw_Inventory_Report_Variance_Fields = {
  __typename?: "vw_inventory_report_variance_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  case_size?: Maybe<Scalars["Float"]>;
  days_since_first_stock?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  sort?: Maybe<Scalars["Float"]>;
  units_in_prepacked?: Maybe<Scalars["Float"]>;
  units_total?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  weekly_usage_180d?: Maybe<Scalars["Float"]>;
  weekly_usage_360d?: Maybe<Scalars["Float"]>;
  weekly_usage_90d?: Maybe<Scalars["Float"]>;
  weeks_remaining_180d?: Maybe<Scalars["Float"]>;
  weeks_remaining_360d?: Maybe<Scalars["Float"]>;
  weeks_remaining_90d?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "warehouse" */
export type Warehouse = {
  __typename?: "warehouse";
  /** An object relationship */
  address: Address;
  address_id: Scalars["Int"];
  /** An array relationship */
  case_changes: Array<Case_Change>;
  /** An aggregate relationship */
  case_changes_aggregate: Case_Change_Aggregate;
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  last_updated: Scalars["timestamptz"];
  /** An array relationship */
  locations: Array<Location>;
  /** An aggregate relationship */
  locations_aggregate: Location_Aggregate;
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars["Int"];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
  /** An array relationship */
  stock_allocations: Array<Stock_Allocation>;
  /** An aggregate relationship */
  stock_allocations_aggregate: Stock_Allocation_Aggregate;
  warehouse_name: Scalars["String"];
  /** An array relationship */
  work_orders: Array<Work_Order>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Order_Aggregate;
};

/** columns and relationships of "warehouse" */
export type WarehouseCase_ChangesArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseCase_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Case_Change_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Case_Change_Order_By>>;
  where?: Maybe<Case_Change_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseLocationsArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseLocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Location_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Location_Order_By>>;
  where?: Maybe<Location_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseStock_AllocationsArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseStock_Allocations_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Allocation_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Stock_Allocation_Order_By>>;
  where?: Maybe<Stock_Allocation_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseWork_OrdersArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** columns and relationships of "warehouse" */
export type WarehouseWork_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** aggregated selection of "warehouse" */
export type Warehouse_Aggregate = {
  __typename?: "warehouse_aggregate";
  aggregate?: Maybe<Warehouse_Aggregate_Fields>;
  nodes: Array<Warehouse>;
};

export type Warehouse_Aggregate_Bool_Exp = {
  count?: Maybe<Warehouse_Aggregate_Bool_Exp_Count>;
};

export type Warehouse_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Warehouse_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Warehouse_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "warehouse" */
export type Warehouse_Aggregate_Fields = {
  __typename?: "warehouse_aggregate_fields";
  avg?: Maybe<Warehouse_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Warehouse_Max_Fields>;
  min?: Maybe<Warehouse_Min_Fields>;
  stddev?: Maybe<Warehouse_Stddev_Fields>;
  stddev_pop?: Maybe<Warehouse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Warehouse_Stddev_Samp_Fields>;
  sum?: Maybe<Warehouse_Sum_Fields>;
  var_pop?: Maybe<Warehouse_Var_Pop_Fields>;
  var_samp?: Maybe<Warehouse_Var_Samp_Fields>;
  variance?: Maybe<Warehouse_Variance_Fields>;
};

/** aggregate fields of "warehouse" */
export type Warehouse_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Warehouse_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "warehouse" */
export type Warehouse_Aggregate_Order_By = {
  avg?: Maybe<Warehouse_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Warehouse_Max_Order_By>;
  min?: Maybe<Warehouse_Min_Order_By>;
  stddev?: Maybe<Warehouse_Stddev_Order_By>;
  stddev_pop?: Maybe<Warehouse_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Warehouse_Stddev_Samp_Order_By>;
  sum?: Maybe<Warehouse_Sum_Order_By>;
  var_pop?: Maybe<Warehouse_Var_Pop_Order_By>;
  var_samp?: Maybe<Warehouse_Var_Samp_Order_By>;
  variance?: Maybe<Warehouse_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "warehouse" */
export type Warehouse_Arr_Rel_Insert_Input = {
  data: Array<Warehouse_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};

/** aggregate avg on columns */
export type Warehouse_Avg_Fields = {
  __typename?: "warehouse_avg_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "warehouse" */
export type Warehouse_Avg_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "warehouse". All fields are combined with a logical 'AND'. */
export type Warehouse_Bool_Exp = {
  _and?: Maybe<Array<Warehouse_Bool_Exp>>;
  _not?: Maybe<Warehouse_Bool_Exp>;
  _or?: Maybe<Array<Warehouse_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Int_Comparison_Exp>;
  case_changes?: Maybe<Case_Change_Bool_Exp>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  locations?: Maybe<Location_Bool_Exp>;
  locations_aggregate?: Maybe<Location_Aggregate_Bool_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
  stock_allocations?: Maybe<Stock_Allocation_Bool_Exp>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Bool_Exp>;
  warehouse_name?: Maybe<String_Comparison_Exp>;
  work_orders?: Maybe<Work_Order_Bool_Exp>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "warehouse" */
export enum Warehouse_Constraint {
  /** unique or primary key constraint on columns "id" */
  WarehousePkey = "warehouse_pkey",
  /** unique or primary key constraint on columns "warehouse_name" */
  WarehouseWarehouseNameKey = "warehouse_warehouse_name_key",
}

/** input type for incrementing numeric columns in table "warehouse" */
export type Warehouse_Inc_Input = {
  address_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "warehouse" */
export type Warehouse_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars["Int"]>;
  case_changes?: Maybe<Case_Change_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  locations?: Maybe<Location_Arr_Rel_Insert_Input>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["Int"]>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
  stock_allocations?: Maybe<Stock_Allocation_Arr_Rel_Insert_Input>;
  warehouse_name?: Maybe<Scalars["String"]>;
  work_orders?: Maybe<Work_Order_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Warehouse_Max_Fields = {
  __typename?: "warehouse_max_fields";
  address_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_name?: Maybe<Scalars["String"]>;
};

/** order by max() on columns of table "warehouse" */
export type Warehouse_Max_Order_By = {
  address_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Warehouse_Min_Fields = {
  __typename?: "warehouse_min_fields";
  address_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_name?: Maybe<Scalars["String"]>;
};

/** order by min() on columns of table "warehouse" */
export type Warehouse_Min_Order_By = {
  address_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "warehouse" */
export type Warehouse_Mutation_Response = {
  __typename?: "warehouse_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Warehouse>;
};

/** input type for inserting object relation for remote table "warehouse" */
export type Warehouse_Obj_Rel_Insert_Input = {
  data: Warehouse_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};

/** on_conflict condition type for table "warehouse" */
export type Warehouse_On_Conflict = {
  constraint: Warehouse_Constraint;
  update_columns?: Array<Warehouse_Update_Column>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** Ordering options when selecting data from "warehouse". */
export type Warehouse_Order_By = {
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  case_changes_aggregate?: Maybe<Case_Change_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  last_updated?: Maybe<Order_By>;
  locations_aggregate?: Maybe<Location_Aggregate_Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
  stock_allocations_aggregate?: Maybe<Stock_Allocation_Aggregate_Order_By>;
  warehouse_name?: Maybe<Order_By>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Order_By>;
};

/** primary key columns input for table: warehouse */
export type Warehouse_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "warehouse" */
export enum Warehouse_Select_Column {
  /** column name */
  AddressId = "address_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  WarehouseName = "warehouse_name",
}

/** input type for updating data in table "warehouse" */
export type Warehouse_Set_Input = {
  address_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Warehouse_Stddev_Fields = {
  __typename?: "warehouse_stddev_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "warehouse" */
export type Warehouse_Stddev_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Warehouse_Stddev_Pop_Fields = {
  __typename?: "warehouse_stddev_pop_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "warehouse" */
export type Warehouse_Stddev_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Warehouse_Stddev_Samp_Fields = {
  __typename?: "warehouse_stddev_samp_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "warehouse" */
export type Warehouse_Stddev_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "warehouse" */
export type Warehouse_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Warehouse_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Warehouse_Stream_Cursor_Value_Input = {
  address_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  warehouse_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Warehouse_Sum_Fields = {
  __typename?: "warehouse_sum_fields";
  address_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "warehouse" */
export type Warehouse_Sum_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** update columns of table "warehouse" */
export enum Warehouse_Update_Column {
  /** column name */
  AddressId = "address_id",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  WarehouseName = "warehouse_name",
}

export type Warehouse_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Warehouse_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Warehouse_Set_Input>;
  /** filter the rows which have to be updated */
  where: Warehouse_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Warehouse_Var_Pop_Fields = {
  __typename?: "warehouse_var_pop_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "warehouse" */
export type Warehouse_Var_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Warehouse_Var_Samp_Fields = {
  __typename?: "warehouse_var_samp_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "warehouse" */
export type Warehouse_Var_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Warehouse_Variance_Fields = {
  __typename?: "warehouse_variance_fields";
  address_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "warehouse" */
export type Warehouse_Variance_Order_By = {
  address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
};

/** columns and relationships of "work_order" */
export type Work_Order = {
  __typename?: "work_order";
  cancelled_quantity: Scalars["Int"];
  completed_quantity: Scalars["Int"];
  created_at: Scalars["timestamptz"];
  due_date: Scalars["timestamptz"];
  external_work_order_id?: Maybe<Scalars["Int"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars["Int"];
  quantity: Scalars["Int"];
  /** An object relationship */
  recipe?: Maybe<Recipe>;
  recipe_id?: Maybe<Scalars["Int"]>;
  reference: Scalars["String"];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars["Int"];
  /** An array relationship */
  work_order_input_lines: Array<Work_Order_Input_Line>;
  /** An aggregate relationship */
  work_order_input_lines_aggregate: Work_Order_Input_Line_Aggregate;
  /** An array relationship */
  work_order_output_lines: Array<Work_Order_Output_Line>;
  /** An aggregate relationship */
  work_order_output_lines_aggregate: Work_Order_Output_Line_Aggregate;
  /** An object relationship */
  work_order_status: Work_Order_Status;
  work_order_status_id: Scalars["Int"];
};

/** columns and relationships of "work_order" */
export type Work_OrderWork_Order_Input_LinesArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

/** columns and relationships of "work_order" */
export type Work_OrderWork_Order_Input_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Input_Line_Order_By>>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

/** columns and relationships of "work_order" */
export type Work_OrderWork_Order_Output_LinesArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

/** columns and relationships of "work_order" */
export type Work_OrderWork_Order_Output_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Output_Line_Order_By>>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

/** aggregated selection of "work_order" */
export type Work_Order_Aggregate = {
  __typename?: "work_order_aggregate";
  aggregate?: Maybe<Work_Order_Aggregate_Fields>;
  nodes: Array<Work_Order>;
};

export type Work_Order_Aggregate_Bool_Exp = {
  count?: Maybe<Work_Order_Aggregate_Bool_Exp_Count>;
};

export type Work_Order_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Work_Order_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Work_Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "work_order" */
export type Work_Order_Aggregate_Fields = {
  __typename?: "work_order_aggregate_fields";
  avg?: Maybe<Work_Order_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Work_Order_Max_Fields>;
  min?: Maybe<Work_Order_Min_Fields>;
  stddev?: Maybe<Work_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Order_Sum_Fields>;
  var_pop?: Maybe<Work_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Order_Var_Samp_Fields>;
  variance?: Maybe<Work_Order_Variance_Fields>;
};

/** aggregate fields of "work_order" */
export type Work_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Work_Order_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_order" */
export type Work_Order_Aggregate_Order_By = {
  avg?: Maybe<Work_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Work_Order_Max_Order_By>;
  min?: Maybe<Work_Order_Min_Order_By>;
  stddev?: Maybe<Work_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Work_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Work_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Work_Order_Sum_Order_By>;
  var_pop?: Maybe<Work_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Work_Order_Var_Samp_Order_By>;
  variance?: Maybe<Work_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "work_order" */
export type Work_Order_Arr_Rel_Insert_Input = {
  data: Array<Work_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Work_Order_Avg_Fields = {
  __typename?: "work_order_avg_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_order" */
export type Work_Order_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "work_order". All fields are combined with a logical 'AND'. */
export type Work_Order_Bool_Exp = {
  _and?: Maybe<Array<Work_Order_Bool_Exp>>;
  _not?: Maybe<Work_Order_Bool_Exp>;
  _or?: Maybe<Array<Work_Order_Bool_Exp>>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  completed_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  due_date?: Maybe<Timestamptz_Comparison_Exp>;
  external_work_order_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  recipe?: Maybe<Recipe_Bool_Exp>;
  recipe_id?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
  work_order_input_lines?: Maybe<Work_Order_Input_Line_Bool_Exp>;
  work_order_input_lines_aggregate?: Maybe<Work_Order_Input_Line_Aggregate_Bool_Exp>;
  work_order_output_lines?: Maybe<Work_Order_Output_Line_Bool_Exp>;
  work_order_output_lines_aggregate?: Maybe<Work_Order_Output_Line_Aggregate_Bool_Exp>;
  work_order_status?: Maybe<Work_Order_Status_Bool_Exp>;
  work_order_status_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_order" */
export enum Work_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderPkey = "work_order_pkey",
  /** unique or primary key constraint on columns "reference" */
  WorkOrderReferenceKey = "work_order_reference_key",
}

/** input type for incrementing numeric columns in table "work_order" */
export type Work_Order_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "work_order_input_line" */
export type Work_Order_Input_Line = {
  __typename?: "work_order_input_line";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product_stock: Product_Stock;
  quantity: Scalars["Int"];
  source_product_stock_id: Scalars["Int"];
  /** An object relationship */
  work_order?: Maybe<Work_Order>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregated selection of "work_order_input_line" */
export type Work_Order_Input_Line_Aggregate = {
  __typename?: "work_order_input_line_aggregate";
  aggregate?: Maybe<Work_Order_Input_Line_Aggregate_Fields>;
  nodes: Array<Work_Order_Input_Line>;
};

export type Work_Order_Input_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Work_Order_Input_Line_Aggregate_Bool_Exp_Count>;
};

export type Work_Order_Input_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Work_Order_Input_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "work_order_input_line" */
export type Work_Order_Input_Line_Aggregate_Fields = {
  __typename?: "work_order_input_line_aggregate_fields";
  avg?: Maybe<Work_Order_Input_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Work_Order_Input_Line_Max_Fields>;
  min?: Maybe<Work_Order_Input_Line_Min_Fields>;
  stddev?: Maybe<Work_Order_Input_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Order_Input_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Order_Input_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Order_Input_Line_Sum_Fields>;
  var_pop?: Maybe<Work_Order_Input_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Order_Input_Line_Var_Samp_Fields>;
  variance?: Maybe<Work_Order_Input_Line_Variance_Fields>;
};

/** aggregate fields of "work_order_input_line" */
export type Work_Order_Input_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Work_Order_Input_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_order_input_line" */
export type Work_Order_Input_Line_Aggregate_Order_By = {
  avg?: Maybe<Work_Order_Input_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Work_Order_Input_Line_Max_Order_By>;
  min?: Maybe<Work_Order_Input_Line_Min_Order_By>;
  stddev?: Maybe<Work_Order_Input_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Work_Order_Input_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Work_Order_Input_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Work_Order_Input_Line_Sum_Order_By>;
  var_pop?: Maybe<Work_Order_Input_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Work_Order_Input_Line_Var_Samp_Order_By>;
  variance?: Maybe<Work_Order_Input_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "work_order_input_line" */
export type Work_Order_Input_Line_Arr_Rel_Insert_Input = {
  data: Array<Work_Order_Input_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_Input_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Work_Order_Input_Line_Avg_Fields = {
  __typename?: "work_order_input_line_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "work_order_input_line". All fields are combined with a logical 'AND'. */
export type Work_Order_Input_Line_Bool_Exp = {
  _and?: Maybe<Array<Work_Order_Input_Line_Bool_Exp>>;
  _not?: Maybe<Work_Order_Input_Line_Bool_Exp>;
  _or?: Maybe<Array<Work_Order_Input_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  work_order?: Maybe<Work_Order_Bool_Exp>;
  work_order_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_order_input_line" */
export enum Work_Order_Input_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderInputLinePkey = "work_order_input_line_pkey",
}

/** input type for incrementing numeric columns in table "work_order_input_line" */
export type Work_Order_Input_Line_Inc_Input = {
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "work_order_input_line" */
export type Work_Order_Input_Line_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order?: Maybe<Work_Order_Obj_Rel_Insert_Input>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Work_Order_Input_Line_Max_Fields = {
  __typename?: "work_order_input_line_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Work_Order_Input_Line_Min_Fields = {
  __typename?: "work_order_input_line_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "work_order_input_line" */
export type Work_Order_Input_Line_Mutation_Response = {
  __typename?: "work_order_input_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Order_Input_Line>;
};

/** on_conflict condition type for table "work_order_input_line" */
export type Work_Order_Input_Line_On_Conflict = {
  constraint: Work_Order_Input_Line_Constraint;
  update_columns?: Array<Work_Order_Input_Line_Update_Column>;
  where?: Maybe<Work_Order_Input_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "work_order_input_line". */
export type Work_Order_Input_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order?: Maybe<Work_Order_Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: work_order_input_line */
export type Work_Order_Input_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "work_order_input_line" */
export enum Work_Order_Input_Line_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  WorkOrderId = "work_order_id",
}

/** input type for updating data in table "work_order_input_line" */
export type Work_Order_Input_Line_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Work_Order_Input_Line_Stddev_Fields = {
  __typename?: "work_order_input_line_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Work_Order_Input_Line_Stddev_Pop_Fields = {
  __typename?: "work_order_input_line_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Work_Order_Input_Line_Stddev_Samp_Fields = {
  __typename?: "work_order_input_line_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "work_order_input_line" */
export type Work_Order_Input_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Order_Input_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Order_Input_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Work_Order_Input_Line_Sum_Fields = {
  __typename?: "work_order_input_line_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  source_product_stock_id?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** update columns of table "work_order_input_line" */
export enum Work_Order_Input_Line_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Quantity = "quantity",
  /** column name */
  SourceProductStockId = "source_product_stock_id",
  /** column name */
  WorkOrderId = "work_order_id",
}

export type Work_Order_Input_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Work_Order_Input_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Order_Input_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Order_Input_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Order_Input_Line_Var_Pop_Fields = {
  __typename?: "work_order_input_line_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Work_Order_Input_Line_Var_Samp_Fields = {
  __typename?: "work_order_input_line_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Work_Order_Input_Line_Variance_Fields = {
  __typename?: "work_order_input_line_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  source_product_stock_id?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_order_input_line" */
export type Work_Order_Input_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** input type for inserting data into table "work_order" */
export type Work_Order_Insert_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe?: Maybe<Recipe_Obj_Rel_Insert_Input>;
  recipe_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_input_lines?: Maybe<Work_Order_Input_Line_Arr_Rel_Insert_Input>;
  work_order_output_lines?: Maybe<Work_Order_Output_Line_Arr_Rel_Insert_Input>;
  work_order_status?: Maybe<Work_Order_Status_Obj_Rel_Insert_Input>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Work_Order_Max_Fields = {
  __typename?: "work_order_max_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "work_order" */
export type Work_Order_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Work_Order_Min_Fields = {
  __typename?: "work_order_min_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "work_order" */
export type Work_Order_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "work_order" */
export type Work_Order_Mutation_Response = {
  __typename?: "work_order_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Order>;
};

/** input type for inserting object relation for remote table "work_order" */
export type Work_Order_Obj_Rel_Insert_Input = {
  data: Work_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_On_Conflict>;
};

/** on_conflict condition type for table "work_order" */
export type Work_Order_On_Conflict = {
  constraint: Work_Order_Constraint;
  update_columns?: Array<Work_Order_Update_Column>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "work_order". */
export type Work_Order_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  due_date?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe?: Maybe<Recipe_Order_By>;
  recipe_id?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_input_lines_aggregate?: Maybe<Work_Order_Input_Line_Aggregate_Order_By>;
  work_order_output_lines_aggregate?: Maybe<Work_Order_Output_Line_Aggregate_Order_By>;
  work_order_status?: Maybe<Work_Order_Status_Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** columns and relationships of "work_order_output_line" */
export type Work_Order_Output_Line = {
  __typename?: "work_order_output_line";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  /** An object relationship */
  product: Product;
  product_id: Scalars["Int"];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  quantity: Scalars["Int"];
  /** An object relationship */
  work_order?: Maybe<Work_Order>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "work_order_output_line" */
export type Work_Order_Output_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** columns and relationships of "work_order_output_line" */
export type Work_Order_Output_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "work_order_output_line" */
export type Work_Order_Output_Line_Aggregate = {
  __typename?: "work_order_output_line_aggregate";
  aggregate?: Maybe<Work_Order_Output_Line_Aggregate_Fields>;
  nodes: Array<Work_Order_Output_Line>;
};

export type Work_Order_Output_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Work_Order_Output_Line_Aggregate_Bool_Exp_Count>;
};

export type Work_Order_Output_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Work_Order_Output_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "work_order_output_line" */
export type Work_Order_Output_Line_Aggregate_Fields = {
  __typename?: "work_order_output_line_aggregate_fields";
  avg?: Maybe<Work_Order_Output_Line_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Work_Order_Output_Line_Max_Fields>;
  min?: Maybe<Work_Order_Output_Line_Min_Fields>;
  stddev?: Maybe<Work_Order_Output_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Order_Output_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Order_Output_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Order_Output_Line_Sum_Fields>;
  var_pop?: Maybe<Work_Order_Output_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Order_Output_Line_Var_Samp_Fields>;
  variance?: Maybe<Work_Order_Output_Line_Variance_Fields>;
};

/** aggregate fields of "work_order_output_line" */
export type Work_Order_Output_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Work_Order_Output_Line_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "work_order_output_line" */
export type Work_Order_Output_Line_Aggregate_Order_By = {
  avg?: Maybe<Work_Order_Output_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Work_Order_Output_Line_Max_Order_By>;
  min?: Maybe<Work_Order_Output_Line_Min_Order_By>;
  stddev?: Maybe<Work_Order_Output_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Work_Order_Output_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Work_Order_Output_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Work_Order_Output_Line_Sum_Order_By>;
  var_pop?: Maybe<Work_Order_Output_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Work_Order_Output_Line_Var_Samp_Order_By>;
  variance?: Maybe<Work_Order_Output_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "work_order_output_line" */
export type Work_Order_Output_Line_Arr_Rel_Insert_Input = {
  data: Array<Work_Order_Output_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_Output_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Work_Order_Output_Line_Avg_Fields = {
  __typename?: "work_order_output_line_avg_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "work_order_output_line". All fields are combined with a logical 'AND'. */
export type Work_Order_Output_Line_Bool_Exp = {
  _and?: Maybe<Array<Work_Order_Output_Line_Bool_Exp>>;
  _not?: Maybe<Work_Order_Output_Line_Bool_Exp>;
  _or?: Maybe<Array<Work_Order_Output_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  work_order?: Maybe<Work_Order_Bool_Exp>;
  work_order_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "work_order_output_line" */
export enum Work_Order_Output_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderOutputLinePkey = "work_order_output_line_pkey",
}

/** input type for incrementing numeric columns in table "work_order_output_line" */
export type Work_Order_Output_Line_Inc_Input = {
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "work_order_output_line" */
export type Work_Order_Output_Line_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars["Int"]>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order?: Maybe<Work_Order_Obj_Rel_Insert_Input>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Work_Order_Output_Line_Max_Fields = {
  __typename?: "work_order_output_line_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Work_Order_Output_Line_Min_Fields = {
  __typename?: "work_order_output_line_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "work_order_output_line" */
export type Work_Order_Output_Line_Mutation_Response = {
  __typename?: "work_order_output_line_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Order_Output_Line>;
};

/** input type for inserting object relation for remote table "work_order_output_line" */
export type Work_Order_Output_Line_Obj_Rel_Insert_Input = {
  data: Work_Order_Output_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_Output_Line_On_Conflict>;
};

/** on_conflict condition type for table "work_order_output_line" */
export type Work_Order_Output_Line_On_Conflict = {
  constraint: Work_Order_Output_Line_Constraint;
  update_columns?: Array<Work_Order_Output_Line_Update_Column>;
  where?: Maybe<Work_Order_Output_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "work_order_output_line". */
export type Work_Order_Output_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  work_order?: Maybe<Work_Order_Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: work_order_output_line */
export type Work_Order_Output_Line_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "work_order_output_line" */
export enum Work_Order_Output_Line_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  WorkOrderId = "work_order_id",
}

/** input type for updating data in table "work_order_output_line" */
export type Work_Order_Output_Line_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Work_Order_Output_Line_Stddev_Fields = {
  __typename?: "work_order_output_line_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Work_Order_Output_Line_Stddev_Pop_Fields = {
  __typename?: "work_order_output_line_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Work_Order_Output_Line_Stddev_Samp_Fields = {
  __typename?: "work_order_output_line_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "work_order_output_line" */
export type Work_Order_Output_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Order_Output_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Order_Output_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Work_Order_Output_Line_Sum_Fields = {
  __typename?: "work_order_output_line_sum_fields";
  id?: Maybe<Scalars["Int"]>;
  product_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  work_order_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** update columns of table "work_order_output_line" */
export enum Work_Order_Output_Line_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  ProductId = "product_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  WorkOrderId = "work_order_id",
}

export type Work_Order_Output_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Work_Order_Output_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Order_Output_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Order_Output_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Order_Output_Line_Var_Pop_Fields = {
  __typename?: "work_order_output_line_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Work_Order_Output_Line_Var_Samp_Fields = {
  __typename?: "work_order_output_line_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Work_Order_Output_Line_Variance_Fields = {
  __typename?: "work_order_output_line_variance_fields";
  id?: Maybe<Scalars["Float"]>;
  product_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  work_order_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_order_output_line" */
export type Work_Order_Output_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  work_order_id?: Maybe<Order_By>;
};

/** primary key columns input for table: work_order */
export type Work_Order_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "work_order" */
export enum Work_Order_Select_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CompletedQuantity = "completed_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  ExternalWorkOrderId = "external_work_order_id",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecipeId = "recipe_id",
  /** column name */
  Reference = "reference",
  /** column name */
  WarehouseId = "warehouse_id",
  /** column name */
  WorkOrderStatusId = "work_order_status_id",
}

/** input type for updating data in table "work_order" */
export type Work_Order_Set_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** columns and relationships of "work_order_status" */
export type Work_Order_Status = {
  __typename?: "work_order_status";
  created_at: Scalars["timestamptz"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  work_order_status_name: Scalars["String"];
  /** An array relationship */
  work_orders: Array<Work_Order>;
  /** An aggregate relationship */
  work_orders_aggregate: Work_Order_Aggregate;
};

/** columns and relationships of "work_order_status" */
export type Work_Order_StatusWork_OrdersArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** columns and relationships of "work_order_status" */
export type Work_Order_StatusWork_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Work_Order_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Work_Order_Order_By>>;
  where?: Maybe<Work_Order_Bool_Exp>;
};

/** aggregated selection of "work_order_status" */
export type Work_Order_Status_Aggregate = {
  __typename?: "work_order_status_aggregate";
  aggregate?: Maybe<Work_Order_Status_Aggregate_Fields>;
  nodes: Array<Work_Order_Status>;
};

/** aggregate fields of "work_order_status" */
export type Work_Order_Status_Aggregate_Fields = {
  __typename?: "work_order_status_aggregate_fields";
  avg?: Maybe<Work_Order_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Work_Order_Status_Max_Fields>;
  min?: Maybe<Work_Order_Status_Min_Fields>;
  stddev?: Maybe<Work_Order_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Work_Order_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Work_Order_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Work_Order_Status_Sum_Fields>;
  var_pop?: Maybe<Work_Order_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Work_Order_Status_Var_Samp_Fields>;
  variance?: Maybe<Work_Order_Status_Variance_Fields>;
};

/** aggregate fields of "work_order_status" */
export type Work_Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Work_Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Work_Order_Status_Avg_Fields = {
  __typename?: "work_order_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "work_order_status". All fields are combined with a logical 'AND'. */
export type Work_Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Work_Order_Status_Bool_Exp>>;
  _not?: Maybe<Work_Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Work_Order_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  work_order_status_name?: Maybe<String_Comparison_Exp>;
  work_orders?: Maybe<Work_Order_Bool_Exp>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "work_order_status" */
export enum Work_Order_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  WorkOrderStatusPkey = "work_order_status_pkey",
  /** unique or primary key constraint on columns "work_order_status_name" */
  WorkOrderStatusWorkOrderStatusNameKey = "work_order_status_work_order_status_name_key",
}

/** input type for incrementing numeric columns in table "work_order_status" */
export type Work_Order_Status_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "work_order_status" */
export type Work_Order_Status_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  work_order_status_name?: Maybe<Scalars["String"]>;
  work_orders?: Maybe<Work_Order_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Work_Order_Status_Max_Fields = {
  __typename?: "work_order_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  work_order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Work_Order_Status_Min_Fields = {
  __typename?: "work_order_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  work_order_status_name?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "work_order_status" */
export type Work_Order_Status_Mutation_Response = {
  __typename?: "work_order_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Work_Order_Status>;
};

/** input type for inserting object relation for remote table "work_order_status" */
export type Work_Order_Status_Obj_Rel_Insert_Input = {
  data: Work_Order_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Work_Order_Status_On_Conflict>;
};

/** on_conflict condition type for table "work_order_status" */
export type Work_Order_Status_On_Conflict = {
  constraint: Work_Order_Status_Constraint;
  update_columns?: Array<Work_Order_Status_Update_Column>;
  where?: Maybe<Work_Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "work_order_status". */
export type Work_Order_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  work_order_status_name?: Maybe<Order_By>;
  work_orders_aggregate?: Maybe<Work_Order_Aggregate_Order_By>;
};

/** primary key columns input for table: work_order_status */
export type Work_Order_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "work_order_status" */
export enum Work_Order_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  WorkOrderStatusName = "work_order_status_name",
}

/** input type for updating data in table "work_order_status" */
export type Work_Order_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  work_order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Work_Order_Status_Stddev_Fields = {
  __typename?: "work_order_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Work_Order_Status_Stddev_Pop_Fields = {
  __typename?: "work_order_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Work_Order_Status_Stddev_Samp_Fields = {
  __typename?: "work_order_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "work_order_status" */
export type Work_Order_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Order_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Order_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  work_order_status_name?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Work_Order_Status_Sum_Fields = {
  __typename?: "work_order_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "work_order_status" */
export enum Work_Order_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  WorkOrderStatusName = "work_order_status_name",
}

export type Work_Order_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Work_Order_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Order_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Order_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Order_Status_Var_Pop_Fields = {
  __typename?: "work_order_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Work_Order_Status_Var_Samp_Fields = {
  __typename?: "work_order_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Work_Order_Status_Variance_Fields = {
  __typename?: "work_order_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Work_Order_Stddev_Fields = {
  __typename?: "work_order_stddev_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "work_order" */
export type Work_Order_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Work_Order_Stddev_Pop_Fields = {
  __typename?: "work_order_stddev_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "work_order" */
export type Work_Order_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Work_Order_Stddev_Samp_Fields = {
  __typename?: "work_order_stddev_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "work_order" */
export type Work_Order_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "work_order" */
export type Work_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Work_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Work_Order_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  due_date?: Maybe<Scalars["timestamptz"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
  reference?: Maybe<Scalars["String"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Work_Order_Sum_Fields = {
  __typename?: "work_order_sum_fields";
  cancelled_quantity?: Maybe<Scalars["Int"]>;
  completed_quantity?: Maybe<Scalars["Int"]>;
  external_work_order_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  organisation_id?: Maybe<Scalars["Int"]>;
  quantity?: Maybe<Scalars["Int"]>;
  recipe_id?: Maybe<Scalars["Int"]>;
  warehouse_id?: Maybe<Scalars["Int"]>;
  work_order_status_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "work_order" */
export type Work_Order_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** update columns of table "work_order" */
export enum Work_Order_Update_Column {
  /** column name */
  CancelledQuantity = "cancelled_quantity",
  /** column name */
  CompletedQuantity = "completed_quantity",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DueDate = "due_date",
  /** column name */
  ExternalWorkOrderId = "external_work_order_id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrganisationId = "organisation_id",
  /** column name */
  Quantity = "quantity",
  /** column name */
  RecipeId = "recipe_id",
  /** column name */
  Reference = "reference",
  /** column name */
  WarehouseId = "warehouse_id",
  /** column name */
  WorkOrderStatusId = "work_order_status_id",
}

export type Work_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Work_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Work_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Work_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Work_Order_Var_Pop_Fields = {
  __typename?: "work_order_var_pop_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "work_order" */
export type Work_Order_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Work_Order_Var_Samp_Fields = {
  __typename?: "work_order_var_samp_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "work_order" */
export type Work_Order_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Work_Order_Variance_Fields = {
  __typename?: "work_order_variance_fields";
  cancelled_quantity?: Maybe<Scalars["Float"]>;
  completed_quantity?: Maybe<Scalars["Float"]>;
  external_work_order_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  organisation_id?: Maybe<Scalars["Float"]>;
  quantity?: Maybe<Scalars["Float"]>;
  recipe_id?: Maybe<Scalars["Float"]>;
  warehouse_id?: Maybe<Scalars["Float"]>;
  work_order_status_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "work_order" */
export type Work_Order_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_work_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  recipe_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
  work_order_status_id?: Maybe<Order_By>;
};

export type GetAllCountryNamesAscQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCountryNamesAscQuery = {
  __typename?: "query_root";
  country: Array<{ __typename?: "country"; country_name: string; country_code: string }>;
};

export type GetAllOrganisationNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllOrganisationNamesQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; id: number; organisation_name: string }>;
};

export type GetAddressesWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetAddressesWithDataQuery = {
  __typename?: "query_root";
  address: Array<{
    __typename?: "address";
    city: string;
    address_line_1: string;
    address_line_2: string;
    address_line_3: string;
    postcode: string;
    phone_number?: Maybe<string>;
    id: number;
    address_name: string;
    country: { __typename?: "country"; country_name: string };
  }>;
};

export type GetCaseChangeAggregatedProductStockByParamsQueryVariables = Exact<{
  baseProductId?: Maybe<Scalars["Int"]>;
  warehouseId?: Maybe<Scalars["Int"]>;
  stockConditionId?: Maybe<Scalars["Int"]>;
  organisationId?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeAggregatedProductStockByParamsQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    location: {
      __typename?: "location";
      location_name: string;
      warehouse: { __typename?: "warehouse"; warehouse_name: string };
    };
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
    product: { __typename?: "product"; product_name: string; base_product_quantity: number };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string }>;
  }>;
};

export type GetCaseChangeFromCaseBaseProdQuantityByIdQueryVariables = Exact<{
  productId?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeFromCaseBaseProdQuantityByIdQuery = {
  __typename?: "query_root";
  product: Array<{ __typename?: "product"; base_product_quantity: number }>;
};

export type GetCaseChangeFromCaseProductByParamsQueryVariables = Exact<{
  baseProductId?: Maybe<Scalars["Int"]>;
  warehouseId?: Maybe<Scalars["Int"]>;
  conditionId?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeFromCaseProductByParamsQuery = {
  __typename?: "query_root";
  product: Array<{ __typename?: "product"; product_name: string; id: number }>;
};

export type GetCaseChangeLocationsByWarehouseIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeLocationsByWarehouseIdQuery = {
  __typename?: "query_root";
  location: Array<{ __typename?: "location"; id: number; location_name: string }>;
};

export type GetCaseChangeProductStockByParamsQueryVariables = Exact<{
  baseProductId?: Maybe<Scalars["Int"]>;
  baseProductQuantity?: Maybe<Scalars["Int"]>;
  warehouseId?: Maybe<Scalars["Int"]>;
  stockConditionId?: Maybe<Scalars["Int"]>;
  organisationId?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeProductStockByParamsQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    location: { __typename?: "location"; location_name: string };
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
    product: { __typename?: "product"; product_name: string; base_product_quantity: number };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string }>;
  }>;
};

export type GetCaseChangeToCaseProductByBasedProdIdQueryVariables = Exact<{
  baseProductId?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeToCaseProductByBasedProdIdQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    product_name: string;
    id: number;
    product_stocks: Array<{
      __typename?: "product_stock";
      stock_condition: { __typename?: "stock_condition"; stock_condition_name: string; id: number };
    }>;
  }>;
};

export type GetCaseChangeToCaseProductByBaseProductParamsQueryVariables = Exact<{
  baseProductId?: Maybe<Scalars["Int"]>;
  baseProductQuantity?: Maybe<Scalars["Int"]>;
}>;

export type GetCaseChangeToCaseProductByBaseProductParamsQuery = {
  __typename?: "query_root";
  product: Array<{ __typename?: "product"; product_name: string; id: number }>;
};

export type GetCaseChangeTopDropdownsDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetCaseChangeTopDropdownsDataQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; organisation_name: string; id: number }>;
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
  }>;
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
  stock_condition: Array<{
    __typename?: "stock_condition";
    id: number;
    stock_condition_name: string;
  }>;
};

export type InsertCourierMutationVariables = Exact<{
  courierName: Scalars["String"];
}>;

export type InsertCourierMutation = {
  __typename?: "mutation_root";
  insert_courier_one?: Maybe<{ __typename?: "courier"; id: number }>;
};

export type GetAllCourierNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCourierNamesQuery = {
  __typename?: "query_root";
  courier: Array<{ __typename?: "courier"; id: number; courier_name: string }>;
};

export type InsertCourierServiceMutationVariables = Exact<{
  courierId: Scalars["Int"];
  courierServiceName: Scalars["String"];
}>;

export type InsertCourierServiceMutation = {
  __typename?: "mutation_root";
  insert_courier_service_one?: Maybe<{ __typename?: "courier_service"; id: number }>;
};

export type GetAllCourierServiceNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllCourierServiceNamesQuery = {
  __typename?: "query_root";
  courier_service: Array<{
    __typename?: "courier_service";
    id: number;
    courier_service_name: string;
  }>;
};

export type GetCourierServicesWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetCourierServicesWithDataQuery = {
  __typename?: "query_root";
  courier_service: Array<{
    __typename?: "courier_service";
    id: number;
    courier_service_name: string;
    courier: { __typename?: "courier"; courier_name: string };
  }>;
};

export type GetDashboardStockByLocationTypeQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardStockByLocationTypeQuery = {
  __typename?: "query_root";
  location_type: Array<{
    __typename?: "location_type";
    id: number;
    locations: Array<{
      __typename?: "location";
      product_stocks: Array<{
        __typename?: "product_stock";
        id: number;
        quantity: number;
        organisation?: Maybe<{
          __typename?: "organisation";
          id: number;
          organisation_name: string;
        }>;
        location: { __typename?: "location"; id: number; location_name: string };
        product: { __typename?: "product"; product_name: string; base_product_quantity: number };
        stock_status: { __typename?: "stock_status"; stock_status_name: string };
        stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
      }>;
    }>;
  }>;
};

export type GetDashboardInboundManifestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardInboundManifestsQuery = {
  __typename?: "query_root";
  inbound_manifest: Array<{
    __typename?: "inbound_manifest";
    id: number;
    expected_arrival: any;
    organisation: { __typename?: "organisation"; organisation_name: string };
  }>;
};

export type GetDashboardOutboundManifestsQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardOutboundManifestsQuery = {
  __typename?: "query_root";
  outbound_manifest: Array<{
    __typename?: "outbound_manifest";
    id: number;
    expected_dispatch: any;
    organisation: { __typename?: "organisation"; organisation_name: string };
  }>;
};

export type GetDashboardProductsReportQueryVariables = Exact<{ [key: string]: never }>;

export type GetDashboardProductsReportQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    external_product_id?: Maybe<string>;
    product_name: string;
    case_product_id?: Maybe<number>;
    base_product_quantity: number;
    product_stocks_aggregate: {
      __typename?: "product_stock_aggregate";
      aggregate?: Maybe<{ __typename?: "product_stock_aggregate_fields"; count: number }>;
    };
    service_account: { __typename?: "service_account"; service_account_name: string };
  }>;
};

export type GetProductInformationQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductInformationQuery = {
  __typename?: "query_root";
  product_aggregate: {
    __typename?: "product_aggregate";
    aggregate?: Maybe<{ __typename?: "product_aggregate_fields"; count: number }>;
  };
  product: Array<{
    __typename?: "product";
    product_name: string;
    product_stocks_aggregate: {
      __typename?: "product_stock_aggregate";
      aggregate?: Maybe<{
        __typename?: "product_stock_aggregate_fields";
        sum?: Maybe<{ __typename?: "product_stock_sum_fields"; quantity?: Maybe<number> }>;
      }>;
    };
  }>;
};

export type GetStockInformationQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockInformationQuery = {
  __typename?: "query_root";
  product_stock_aggregate: {
    __typename?: "product_stock_aggregate";
    aggregate?: Maybe<{
      __typename?: "product_stock_aggregate_fields";
      sum?: Maybe<{ __typename?: "product_stock_sum_fields"; quantity?: Maybe<number> }>;
    }>;
  };
  product_stock: Array<{
    __typename?: "product_stock";
    last_updated: any;
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
  }>;
};

export type GetInboundManifestsByDateQueryVariables = Exact<{
  selectedDate: Scalars["timestamptz"];
}>;

export type GetInboundManifestsByDateQuery = {
  __typename?: "query_root";
  inbound_manifest: Array<{
    __typename?: "inbound_manifest";
    id: number;
    organisation: { __typename?: "organisation"; organisation_name: string };
  }>;
};

export type GetOutboundManifestsByDateQueryVariables = Exact<{
  selectedDate: Scalars["timestamptz"];
}>;

export type GetOutboundManifestsByDateQuery = {
  __typename?: "query_root";
  outbound_manifest: Array<{
    __typename?: "outbound_manifest";
    id: number;
    organisation: { __typename?: "organisation"; organisation_name: string };
  }>;
};

export type GetWorkOrdersByDateQueryVariables = Exact<{
  selectedDate: Scalars["timestamptz"];
}>;

export type GetWorkOrdersByDateQuery = {
  __typename?: "query_root";
  recipe: Array<{
    __typename?: "recipe";
    work_orders: Array<{
      __typename?: "work_order";
      id: number;
      organisation: { __typename?: "organisation"; organisation_name: string };
    }>;
  }>;
};

export type GetProductStockWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductStockWithDataQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    location: {
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
    };
    stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
    product: { __typename?: "product"; product_name: string; id: number };
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
  }>;
};

export type GetFileCopiesByDateQueryVariables = Exact<{
  fromDate?: Maybe<Scalars["timestamptz"]>;
}>;

export type GetFileCopiesByDateQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    product: { __typename?: "product"; product_name: string; id: number; fsc_certified: boolean };
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string; id: number };
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    location: {
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
    };
    organisation?: Maybe<{ __typename?: "organisation"; id: number; organisation_name: string }>;
  }>;
};

export type GetFileCopiesQueryVariables = Exact<{ [key: string]: never }>;

export type GetFileCopiesQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    last_updated: any;
    product: { __typename?: "product"; product_name: string; id: number; fsc_certified: boolean };
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string; id: number };
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    location: {
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
    };
    organisation?: Maybe<{ __typename?: "organisation"; id: number; organisation_name: string }>;
  }>;
};

export type GetInboundBaseProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundBaseProductsQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
    fsc_certified?: Maybe<boolean>;
    grs_certified?: Maybe<boolean>;
  }>;
};

export type GetInboundCaseProductsByBaseProductIdQueryVariables = Exact<{
  baseProductId: Scalars["Int"];
}>;

export type GetInboundCaseProductsByBaseProductIdQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    base_product_quantity: number;
  }>;
};

export type GetInboundCourierServiceNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundCourierServiceNamesQuery = {
  __typename?: "query_root";
  courier_service: Array<{
    __typename?: "courier_service";
    id: number;
    courier_service_name: string;
  }>;
};

export type GetInboundLogisticsTypeNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundLogisticsTypeNamesQuery = {
  __typename?: "query_root";
  logistics_type: Array<{ __typename?: "logistics_type"; id: number; logistics_type_name: string }>;
};

export type GetInboundOrganisationNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundOrganisationNamesQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; id: number; organisation_name: string }>;
};

export type GetInboundWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetInboundCaseProductsQueryVariables = Exact<{
  baseProductId: Scalars["Int"];
}>;

export type GetInboundCaseProductsQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    base_product_quantity: number;
  }>;
};

export type GetInboundManifestDataByIdQueryVariables = Exact<{
  manifestId: Scalars["Int"];
}>;

export type GetInboundManifestDataByIdQuery = {
  __typename?: "query_root";
  inbound_manifest: Array<{
    __typename?: "inbound_manifest";
    id: number;
    organisation_id: number;
    expected_arrival: any;
    reference: string;
    last_updated: any;
    notes: string;
    organisation: { __typename?: "organisation"; organisation_name: string };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    courier_service?: Maybe<{
      __typename?: "courier_service";
      id: number;
      courier_service_name: string;
    }>;
    inbound_manifest_status: {
      __typename?: "inbound_manifest_status";
      inbound_manifest_status_name: string;
      id: number;
    };
    logistics_type: { __typename?: "logistics_type"; id: number; logistics_type_name: string };
    inbound_manifest_lines: Array<{
      __typename?: "inbound_manifest_line";
      id: number;
      cancelled_quantity: number;
      quantity: number;
      received_quantity: number;
      inbound_manifest_line_status: {
        __typename?: "inbound_manifest_line_status";
        id: number;
        inbound_manifest_line_status_name: string;
      };
      product: {
        __typename?: "product";
        base_product_id: number;
        id: number;
        product_name: string;
        fsc_certified: boolean;
        grs_certified: boolean;
        base_product_quantity: number;
      };
      inbound_manifest_fulfilment_lines: Array<{
        __typename?: "inbound_manifest_fulfilment_line";
        id: number;
        is_file_copy: boolean;
        inbound_manifest_line_id: number;
        last_updated: any;
        quantity: number;
        notes: string;
        product_stocks: Array<{
          __typename?: "product_stock";
          id: number;
          barcode_uuid: any;
          location: { __typename?: "location"; id: number; location_name: string };
        }>;
        product: { __typename?: "product"; id: number; product_name: string };
      }>;
    }>;
  }>;
};

export type GetInboundStockConditionNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundStockConditionNamesQuery = {
  __typename?: "query_root";
  stock_condition: Array<{
    __typename?: "stock_condition";
    id: number;
    stock_condition_name: string;
  }>;
};

export type GetLocationNamesThatCanHoldStockByWarehouseIdQueryVariables = Exact<{
  warehouseId: Scalars["Int"];
}>;

export type GetLocationNamesThatCanHoldStockByWarehouseIdQuery = {
  __typename?: "query_root";
  location: Array<{
    __typename?: "location";
    id: number;
    can_hold_stock: boolean;
    last_updated: any;
    location_name: string;
    location_type: { __typename?: "location_type"; location_type_name: string; id: number };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
  }>;
};

export type GetInboundManifestByIdQueryVariables = Exact<{
  manifestId: Scalars["Int"];
}>;

export type GetInboundManifestByIdQuery = {
  __typename?: "query_root";
  inbound_manifest: Array<{
    __typename?: "inbound_manifest";
    id: number;
    organisation_id: number;
    expected_arrival: any;
    reference: string;
    last_updated: any;
    notes: string;
    organisation: { __typename?: "organisation"; organisation_name: string };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    courier_service?: Maybe<{
      __typename?: "courier_service";
      id: number;
      courier_service_name: string;
    }>;
    inbound_manifest_status: {
      __typename?: "inbound_manifest_status";
      inbound_manifest_status_name: string;
      id: number;
    };
    logistics_type: { __typename?: "logistics_type"; id: number; logistics_type_name: string };
    inbound_manifest_lines: Array<{
      __typename?: "inbound_manifest_line";
      id: number;
      cancelled_quantity: number;
      quantity: number;
      received_quantity: number;
      inbound_manifest_line_status: {
        __typename?: "inbound_manifest_line_status";
        id: number;
        inbound_manifest_line_status_name: string;
      };
      product: {
        __typename?: "product";
        base_product_id: number;
        id: number;
        product_name: string;
        fsc_certified: boolean;
        grs_certified: boolean;
      };
      inbound_manifest_fulfilment_lines: Array<{
        __typename?: "inbound_manifest_fulfilment_line";
        id: number;
        is_file_copy: boolean;
        inbound_manifest_line_id: number;
        last_updated: any;
        quantity: number;
        product_stocks: Array<{
          __typename?: "product_stock";
          id: number;
          barcode_uuid: any;
          location: { __typename?: "location"; id: number; location_name: string };
        }>;
        product: { __typename?: "product"; id: number; product_name: string };
      }>;
    }>;
  }>;
};

export type GetInboundManifestsAscQueryVariables = Exact<{ [key: string]: never }>;

export type GetInboundManifestsAscQuery = {
  __typename?: "query_root";
  inbound_manifest: Array<{
    __typename?: "inbound_manifest";
    id: number;
    reference: string;
    expected_arrival: any;
    last_updated: any;
    organisation: { __typename?: "organisation"; organisation_name: string; id: number };
    courier_service?: Maybe<{
      __typename?: "courier_service";
      id: number;
      courier_service_name: string;
    }>;
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    inbound_manifest_status: {
      __typename?: "inbound_manifest_status";
      inbound_manifest_status_name: string;
      id: number;
    };
    inbound_manifest_lines_aggregate: {
      __typename?: "inbound_manifest_line_aggregate";
      aggregate?: Maybe<{ __typename?: "inbound_manifest_line_aggregate_fields"; count: number }>;
    };
  }>;
};

export type GetAllLocationTypeNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllLocationTypeNamesQuery = {
  __typename?: "query_root";
  location_type: Array<{ __typename?: "location_type"; id: number; location_type_name: string }>;
};

export type GetAllWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetLocationByWarehouseAndNameQueryVariables = Exact<{
  locationName?: Maybe<Scalars["String"]>;
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetLocationByWarehouseAndNameQuery = {
  __typename?: "query_root";
  location: Array<{ __typename?: "location"; location_name: string; id: number }>;
};

export type GetParentLocationNamesByWarehouseIdQueryVariables = Exact<{
  warehouseId: Scalars["Int"];
}>;

export type GetParentLocationNamesByWarehouseIdQuery = {
  __typename?: "query_root";
  location: Array<{ __typename?: "location"; id: number; location_name: string }>;
};

export type GetLocationByIdQueryVariables = Exact<{
  locationId?: Maybe<Scalars["Int"]>;
}>;

export type GetLocationByIdQuery = {
  __typename?: "query_root";
  location: Array<{
    __typename?: "location";
    id: number;
    location_name: string;
    location_type: { __typename?: "location_type"; id: number; location_type_name: string };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    product_stocks: Array<{
      __typename?: "product_stock";
      id: number;
      quantity: number;
      product: { __typename?: "product"; product_name: string; id: number };
      stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
    }>;
  }>;
};

export type GetLocationsWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetLocationsWithDataQuery = {
  __typename?: "query_root";
  location: Array<{
    __typename?: "location";
    id: number;
    location_name: string;
    can_hold_stock: boolean;
    location_type: { __typename?: "location_type"; location_type_name: string };
    warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
  }>;
};

export type GetOrganisationByIdQueryVariables = Exact<{
  organisationId: Scalars["Int"];
}>;

export type GetOrganisationByIdQuery = {
  __typename?: "query_root";
  organisation: Array<{
    __typename?: "organisation";
    id: number;
    organisation_name: string;
    product_stocks: Array<{
      __typename?: "product_stock";
      id: number;
      quantity: number;
      stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
      stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
      location: { __typename?: "location"; id: number; location_name: string };
      product: { __typename?: "product"; product_name: string };
    }>;
    inbound_manifests: Array<{
      __typename?: "inbound_manifest";
      expected_arrival: any;
      id: number;
      inbound_manifest_status: {
        __typename?: "inbound_manifest_status";
        id: number;
        inbound_manifest_status_name: string;
      };
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
      inbound_manifest_lines_aggregate: {
        __typename?: "inbound_manifest_line_aggregate";
        aggregate?: Maybe<{ __typename?: "inbound_manifest_line_aggregate_fields"; count: number }>;
      };
    }>;
    outbound_manifests: Array<{
      __typename?: "outbound_manifest";
      expected_dispatch: any;
      id: number;
      outbound_manifest_status: {
        __typename?: "outbound_manifest_status";
        outbound_manifest_status_name: string;
        id: number;
      };
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
      outbound_manifest_lines_aggregate: {
        __typename?: "outbound_manifest_line_aggregate";
        aggregate?: Maybe<{
          __typename?: "outbound_manifest_line_aggregate_fields";
          count: number;
        }>;
      };
    }>;
  }>;
};

export type GetOrganisationsWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganisationsWithDataQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; id: number; organisation_name: string }>;
};

export type GetOutboundBaseProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutboundBaseProductsQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
    fsc_certified?: Maybe<boolean>;
    grs_certified?: Maybe<boolean>;
  }>;
};

export type GetOutboundCaseProductsByBaseProductIdQueryVariables = Exact<{
  baseProductId: Scalars["Int"];
}>;

export type GetOutboundCaseProductsByBaseProductIdQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    base_product_quantity: number;
  }>;
};

export type GetOutboundCourierServiceNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutboundCourierServiceNamesQuery = {
  __typename?: "query_root";
  courier_service: Array<{
    __typename?: "courier_service";
    id: number;
    courier_service_name: string;
  }>;
};

export type GetOutboundLogisticsTypeNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutboundLogisticsTypeNamesQuery = {
  __typename?: "query_root";
  logistics_type: Array<{ __typename?: "logistics_type"; id: number; logistics_type_name: string }>;
};

export type GetOutboundOrganisationNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutboundOrganisationNamesQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; id: number; organisation_name: string }>;
};

export type GetOutboundWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutboundWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetAvailableAllocatedProductStockByBaseProductIdQueryVariables = Exact<{
  warehouseId: Scalars["Int"];
  organisationId: Scalars["Int"];
  baseProductId: Scalars["Int"];
}>;

export type GetAvailableAllocatedProductStockByBaseProductIdQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    quantity: number;
    id: number;
    location: {
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
    };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string; id: number }>;
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    product: {
      __typename?: "product";
      id: number;
      product_name: string;
      base_product_quantity: number;
      base_product_id: number;
    };
    stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
  }>;
};

export type GetOutboundManifestByIdQueryVariables = Exact<{
  manifestId: Scalars["Int"];
}>;

export type GetOutboundManifestByIdQuery = {
  __typename?: "query_root";
  outbound_manifest: Array<{
    __typename?: "outbound_manifest";
    id: number;
    organisation_id: number;
    reference: string;
    expected_dispatch: any;
    external_order_id: number;
    last_updated: any;
    notes: string;
    organisation: { __typename?: "organisation"; organisation_name: string };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    courier_service: {
      __typename?: "courier_service";
      id: number;
      courier_service_name: string;
      courier_id: number;
    };
    outbound_manifest_status: {
      __typename?: "outbound_manifest_status";
      outbound_manifest_status_name: string;
      id: number;
    };
    logistics_type: { __typename?: "logistics_type"; id: number; logistics_type_name: string };
    outbound_manifest_lines: Array<{
      __typename?: "outbound_manifest_line";
      id: number;
      cancelled_quantity: number;
      external_order_line_id: number;
      quantity: number;
      dispatched_quantity: number;
      outbound_manifest_line_status: {
        __typename?: "outbound_manifest_line_status";
        id: number;
        outbound_manifest_line_status_name: string;
      };
      product: {
        __typename?: "product";
        base_product_id: number;
        id: number;
        product_name: string;
        fsc_certified: boolean;
        grs_certified: boolean;
      };
      outbound_manifest_fulfilment_lines: Array<{
        __typename?: "outbound_manifest_fulfilment_line";
        id: number;
        outbound_manifest_line_id: number;
        last_updated: any;
        quantity: number;
        product: {
          __typename?: "product";
          id: number;
          product_name: string;
          product_stocks: Array<{
            __typename?: "product_stock";
            barcode_uuid: any;
            id: number;
            location: { __typename?: "location"; id: number; location_name: string };
          }>;
        };
        outbound_manifest_return_lines: Array<{
          __typename?: "outbound_manifest_return_line";
          returned_quantity: number;
          id: number;
        }>;
      }>;
    }>;
  }>;
};

export type GetOutboundManifestsAscQueryVariables = Exact<{ [key: string]: never }>;

export type GetOutboundManifestsAscQuery = {
  __typename?: "query_root";
  outbound_manifest: Array<{
    __typename?: "outbound_manifest";
    id: number;
    external_order_id: number;
    last_updated: any;
    reference: string;
    expected_dispatch: any;
    organisation: { __typename?: "organisation"; organisation_name: string; id: number };
    outbound_manifest_status: {
      __typename?: "outbound_manifest_status";
      outbound_manifest_status_name: string;
      id: number;
    };
    courier_service: { __typename?: "courier_service"; courier_service_name: string; id: number };
    warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
    outbound_manifest_lines_aggregate: {
      __typename?: "outbound_manifest_line_aggregate";
      aggregate?: Maybe<{ __typename?: "outbound_manifest_line_aggregate_fields"; count: number }>;
    };
  }>;
};

export type GetPerpetualInventoryQueryVariables = Exact<{ [key: string]: never }>;

export type GetPerpetualInventoryQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    last_updated: any;
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
    product: { __typename?: "product"; id: number; product_name: string };
    location: { __typename?: "location"; id: number; location_name: string };
    stock_check_line_fulfilment?: Maybe<{ __typename?: "stock_check_line_fulfilment"; id: number }>;
  }>;
};

export type GetProductStockByIdQueryVariables = Exact<{
  productStockId: Scalars["Int"];
}>;

export type GetProductStockByIdQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    last_updated: any;
    quantity: number;
    source_inbound_manifest_fulfilment_line_id?: Maybe<number>;
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string; id: number }>;
    location: {
      __typename?: "location";
      id: number;
      location_name: string;
      location_type: { __typename?: "location_type"; location_type_name: string; id: number };
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    };
    product: { __typename?: "product"; product_name: string; id: number };
    stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    inbound_manifest_fulfilment_line?: Maybe<{
      __typename?: "inbound_manifest_fulfilment_line";
      last_updated: any;
      inbound_manifest_line: {
        __typename?: "inbound_manifest_line";
        inbound_manifest: { __typename?: "inbound_manifest"; id: number };
      };
    }>;
  }>;
};

export type GetProductStocksQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductStocksQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    barcode_uuid: any;
    quantity: number;
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
    stock_status: { __typename?: "stock_status"; stock_status_name: string };
    product: { __typename?: "product"; product_name: string; external_product_id?: Maybe<string> };
    location: {
      __typename?: "location";
      location_type: { __typename?: "location_type"; location_type_name: string };
    };
  }>;
};

export type GetBaseProductNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetBaseProductNamesQuery = {
  __typename?: "query_root";
  base_product: Array<{ __typename?: "base_product"; product_name?: Maybe<string> }>;
};

export type GetBaseProductDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetBaseProductDataQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
    fsc_certified?: Maybe<boolean>;
    grs_certified?: Maybe<boolean>;
  }>;
};

export type GetUnderlyingCaseProductQueryVariables = Exact<{
  baseProductId: Scalars["Int"];
}>;

export type GetUnderlyingCaseProductQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    base_product_quantity: number;
  }>;
};

export type GetBaseProductAndStockQueryVariables = Exact<{
  baseProductId: Scalars["Int"];
}>;

export type GetBaseProductAndStockQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    fsc_certified: boolean;
    grs_certified: boolean;
    length_mm: number;
    weight_grams: number;
    width_mm: number;
    height_mm: number;
    base_product_id: number;
    base_product_quantity: number;
    case_product_quantity?: Maybe<number>;
    product_stocks: Array<{
      __typename?: "product_stock";
      id: number;
      quantity: number;
      location: { __typename?: "location"; id: number; location_name: string };
      stock_status: { __typename?: "stock_status"; stock_status_name: string };
      stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
      organisation?: Maybe<{ __typename?: "organisation"; id: number; organisation_name: string }>;
    }>;
  }>;
};

export type GetBaseProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBaseProductsQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
    fsc_certified?: Maybe<boolean>;
    grs_certified?: Maybe<boolean>;
    base_product_id?: Maybe<number>;
  }>;
};

export type GetCaseProductsByBaseProductIdQueryVariables = Exact<{
  baseProductId: Scalars["Int"];
}>;

export type GetCaseProductsByBaseProductIdQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    base_product_id: number;
    base_product_quantity: number;
    case_product_quantity?: Maybe<number>;
  }>;
};

export type GetProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetProductsQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    product_name: string;
    base_product_id: number;
    base_product_quantity: number;
    case_product_quantity?: Maybe<number>;
    fsc_certified: boolean;
    grs_certified: boolean;
  }>;
};

export type GetRecipeBaseProductNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecipeBaseProductNamesQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
  }>;
};

export type GetRecipeTypeNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecipeTypeNamesQuery = {
  __typename?: "query_root";
  recipe_type: Array<{ __typename?: "recipe_type"; id: number; recipe_type_name: string }>;
};

export type GetRecipeByIdQueryVariables = Exact<{
  recipeId?: Maybe<Scalars["Int"]>;
}>;

export type GetRecipeByIdQuery = {
  __typename?: "query_root";
  recipe: Array<{
    __typename?: "recipe";
    id: number;
    is_draft: boolean;
    recipe_name: string;
    last_updated: any;
    use_for_reporting: boolean;
    recipe_type: { __typename?: "recipe_type"; recipe_type_name: string; id: number };
    recipe_outputs: Array<{
      __typename?: "recipe_output";
      id: number;
      quantity: number;
      product: { __typename?: "product"; id: number; product_name: string };
    }>;
    recipe_inputs: Array<{
      __typename?: "recipe_input";
      id: number;
      quantity: number;
      product: { __typename?: "product"; product_name: string; id: number };
    }>;
  }>;
};

export type GetRecipesWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetRecipesWithDataQuery = {
  __typename?: "query_root";
  recipe: Array<{
    __typename?: "recipe";
    id: number;
    is_draft: boolean;
    use_for_reporting: boolean;
    recipe_name: string;
    recipe_inputs_aggregate: {
      __typename?: "recipe_input_aggregate";
      aggregate?: Maybe<{ __typename?: "recipe_input_aggregate_fields"; count: number }>;
    };
    recipe_outputs_aggregate: {
      __typename?: "recipe_output_aggregate";
      aggregate?: Maybe<{ __typename?: "recipe_output_aggregate_fields"; count: number }>;
    };
    recipe_type: { __typename?: "recipe_type"; recipe_type_name: string; id: number };
  }>;
};

export type GetCustomReportDataQueryVariables = Exact<{
  locationTypeId?: Maybe<Scalars["Int"]>;
  warehouseId?: Maybe<Scalars["Int"]>;
  productId?: Maybe<Scalars["Int"]>;
  stockConditionId?: Maybe<Scalars["Int"]>;
  organisationId?: Maybe<Scalars["Int"]>;
}>;

export type GetCustomReportDataQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
    location: {
      __typename?: "location";
      warehouse_id: number;
      location_type: { __typename?: "location_type"; id: number; location_type_name: string };
    };
    product: { __typename?: "product"; product_name: string; id: number; base_product_id: number };
  }>;
};

export type GetCustomReportLocationTypesNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomReportLocationTypesNamesQuery = {
  __typename?: "query_root";
  location_type: Array<{ __typename?: "location_type"; id: number; location_type_name: string }>;
};

export type GetCustomReportOrganisationNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomReportOrganisationNamesQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; organisation_name: string; id: number }>;
};

export type GetCustomReportProductNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomReportProductNamesQuery = {
  __typename?: "query_root";
  product: Array<{ __typename?: "product"; id: number; product_name: string }>;
};

export type GetCustomReportStockConditionNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomReportStockConditionNamesQuery = {
  __typename?: "query_root";
  stock_condition: Array<{
    __typename?: "stock_condition";
    id: number;
    stock_condition_name: string;
  }>;
};

export type GetCustomReportWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetCustomReportWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetAllProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllProductsQuery = {
  __typename?: "query_root";
  product: Array<{
    __typename?: "product";
    id: number;
    external_product_id?: Maybe<string>;
    product_name: string;
    case_product_id?: Maybe<number>;
    base_product_quantity: number;
    product_stocks_aggregate: {
      __typename?: "product_stock_aggregate";
      aggregate?: Maybe<{ __typename?: "product_stock_aggregate_fields"; count: number }>;
    };
    service_account: { __typename?: "service_account"; service_account_name: string };
  }>;
};

export type GetAllReportWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllReportWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetBaseProductsAccreditationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetBaseProductsAccreditationsQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    grs_certified?: Maybe<boolean>;
    fsc_certified?: Maybe<boolean>;
    product_name?: Maybe<string>;
  }>;
};

export type GetEmptyLocationsByWarehouseIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetEmptyLocationsByWarehouseIdQuery = {
  __typename?: "query_root";
  location: Array<{
    __typename?: "location";
    id: number;
    location_name: string;
    product_stocks_aggregate: {
      __typename?: "product_stock_aggregate";
      aggregate?: Maybe<{
        __typename?: "product_stock_aggregate_fields";
        sum?: Maybe<{ __typename?: "product_stock_sum_fields"; quantity?: Maybe<number> }>;
      }>;
    };
    location_type: { __typename?: "location_type"; location_type_name: string; id: number };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
  }>;
};

export type GetFinanceStockQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
  fromDate?: Maybe<Scalars["date"]>;
  toDate?: Maybe<Scalars["date"]>;
}>;

export type GetFinanceStockQuery = {
  __typename?: "query_root";
  vw_finance_stock_report: Array<{
    __typename?: "vw_finance_stock_report";
    base_product_id?: Maybe<number>;
    base_product?: Maybe<string>;
    fsc_certified?: Maybe<string>;
    grs_certified?: Maybe<string>;
    fsc_claim?: Maybe<string>;
    warehouse?: Maybe<string>;
    organisation?: Maybe<string>;
    action_date_for_current_quantity?: Maybe<any>;
    action_date_for_stock_movements?: Maybe<any>;
    current_quantity?: Maybe<any>;
    inbound_quantity_on_date?: Maybe<any>;
    outbound_quantity_on_date?: Maybe<any>;
    total_adjustment_on_date?: Maybe<any>;
  }>;
};

export type GetInternalTransfersQueryVariables = Exact<{ [key: string]: never }>;

export type GetInternalTransfersQuery = {
  __typename?: "query_root";
  internal_transfer: Array<{
    __typename?: "internal_transfer";
    id: number;
    last_updated: any;
    to_location: {
      __typename?: "location";
      location_name: string;
      warehouse: { __typename?: "warehouse"; warehouse_name: string };
    };
    from_location?: Maybe<{
      __typename?: "location";
      id: number;
      location_name: string;
      warehouse: { __typename?: "warehouse"; warehouse_name: string };
    }>;
    internal_transfer_status: {
      __typename?: "internal_transfer_status";
      internal_transfer_status_name: string;
    };
    internal_transfer_lines_aggregate: {
      __typename?: "internal_transfer_line_aggregate";
      aggregate?: Maybe<{ __typename?: "internal_transfer_line_aggregate_fields"; count: number }>;
    };
  }>;
};

export type GetInventoryByWarehouseIdQueryVariables = Exact<{
  warehouseId: Scalars["Int"];
}>;

export type GetInventoryByWarehouseIdQuery = {
  __typename?: "query_root";
  vw_inventory_report: Array<{
    __typename?: "vw_inventory_report";
    base_product_id?: Maybe<number>;
    product_id?: Maybe<number>;
    product_name?: Maybe<string>;
    prepacked_bundle?: Maybe<boolean>;
    weekly_usage_180d?: Maybe<any>;
    weekly_usage_360d?: Maybe<any>;
    weekly_usage_90d?: Maybe<any>;
    units_total?: Maybe<any>;
    units_in_prepacked?: Maybe<any>;
    fsc_cert?: Maybe<string>;
    grs_cert?: Maybe<string>;
    fsc_claim?: Maybe<string>;
    weeks_remaining_90d?: Maybe<any>;
    weeks_remaining_180d?: Maybe<any>;
    weeks_remaining_360d?: Maybe<any>;
    alert?: Maybe<string>;
    location?: Maybe<string>;
    quantity?: Maybe<any>;
    case_size?: Maybe<number>;
    recent_outbound_units?: Maybe<string>;
    days_since_first_stock?: Maybe<number>;
  }>;
};

export type GetManifestsByWarehouseIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
  fromDate?: Maybe<Scalars["timestamptz"]>;
  toDate?: Maybe<Scalars["timestamptz"]>;
}>;

export type GetManifestsByWarehouseIdQuery = {
  __typename?: "query_root";
  inbound_manifest_line: Array<{
    __typename?: "inbound_manifest_line";
    quantity: number;
    received_quantity: number;
    inbound_manifest: {
      __typename?: "inbound_manifest";
      id: number;
      reference: string;
      expected_arrival: any;
      notes: string;
      organisation: { __typename?: "organisation"; organisation_name: string };
      warehouse: { __typename?: "warehouse"; warehouse_name: string };
      logistics_type: { __typename?: "logistics_type"; logistics_type_name: string };
      courier_service?: Maybe<{ __typename?: "courier_service"; courier_service_name: string }>;
      inbound_manifest_status: {
        __typename?: "inbound_manifest_status";
        inbound_manifest_status_name: string;
      };
    };
    product: {
      __typename?: "product";
      product_name: string;
      base_product_quantity: number;
      product?: Maybe<{ __typename?: "product"; product_name: string }>;
    };
    inbound_manifest_fulfilment_lines: Array<{
      __typename?: "inbound_manifest_fulfilment_line";
      product_stocks: Array<{
        __typename?: "product_stock";
        location: { __typename?: "location"; location_name: string };
      }>;
    }>;
  }>;
  outbound_manifest_line: Array<{
    __typename?: "outbound_manifest_line";
    quantity: number;
    dispatched_quantity: number;
    outbound_manifest: {
      __typename?: "outbound_manifest";
      id: number;
      reference: string;
      expected_dispatch: any;
      notes: string;
      organisation: { __typename?: "organisation"; organisation_name: string };
      warehouse: { __typename?: "warehouse"; warehouse_name: string };
      logistics_type: { __typename?: "logistics_type"; logistics_type_name: string };
      courier_service: { __typename?: "courier_service"; courier_service_name: string };
      outbound_manifest_status: {
        __typename?: "outbound_manifest_status";
        outbound_manifest_status_name: string;
      };
    };
    product: {
      __typename?: "product";
      product_name: string;
      base_product_quantity: number;
      product?: Maybe<{ __typename?: "product"; product_name: string }>;
    };
    outbound_manifest_fulfilment_lines: Array<{
      __typename?: "outbound_manifest_fulfilment_line";
      product_stock: {
        __typename?: "product_stock";
        location: { __typename?: "location"; location_name: string };
      };
    }>;
  }>;
};

export type GetStockByWarehouseIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetStockByWarehouseIdQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    initial_quantity: number;
    barcode_uuid: any;
    barcode_is_unique: boolean;
    quantity: number;
    location: { __typename?: "location"; location_name: string };
    product: { __typename?: "product"; product_name: string };
    stock_status: { __typename?: "stock_status"; stock_status_name: string };
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string }>;
  }>;
};

export type GetOrganisationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetOrganisationsQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; organisation_name: string; id: number }>;
};

export type GetStockAllocationProductStocksQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockAllocationProductStocksQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string };
    stock_status: { __typename?: "stock_status"; stock_status_name: string };
    product: { __typename?: "product"; product_name: string; external_product_id?: Maybe<string> };
    location: { __typename?: "location"; location_name: string };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string; id: number }>;
  }>;
};

export type GetStockAllocationsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockAllocationsQuery = {
  __typename?: "query_root";
  stock_allocation: Array<{
    __typename?: "stock_allocation";
    id: number;
    created_at: any;
    quantity: number;
    organisation: { __typename?: "organisation"; id: number; organisation_name: string };
    organisationByToOrganisationId: {
      __typename?: "organisation";
      id: number;
      organisation_name: string;
    };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
  }>;
};

export type GetStockCheckProductStockByWarehouseIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetStockCheckProductStockByWarehouseIdQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    product: { __typename?: "product"; base_product_id: number; product_name: string; id: number };
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string; id: number };
    stock_status: { __typename?: "stock_status"; stock_status_name: string; id: number };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string; id: number }>;
    location: { __typename?: "location"; id: number; location_name: string };
  }>;
};

export type GetBasicStockCheckTypesQueryVariables = Exact<{ [key: string]: never }>;

export type GetBasicStockCheckTypesQuery = {
  __typename?: "query_root";
  stock_check_type: Array<{
    __typename?: "stock_check_type";
    stock_check_type_name: string;
    id: number;
  }>;
};

export type GetStockCheckStockConditionsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockCheckStockConditionsQuery = {
  __typename?: "query_root";
  stock_condition: Array<{
    __typename?: "stock_condition";
    id: number;
    stock_condition_name: string;
  }>;
};

export type GetStockCheckBaseProductsQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockCheckBaseProductsQuery = {
  __typename?: "query_root";
  base_product: Array<{
    __typename?: "base_product";
    id?: Maybe<number>;
    product_name?: Maybe<string>;
    fsc_certified?: Maybe<boolean>;
    grs_certified?: Maybe<boolean>;
  }>;
};

export type GetStockCheckByIdQueryVariables = Exact<{
  stockCheckId?: Maybe<Scalars["Int"]>;
}>;

export type GetStockCheckByIdQuery = {
  __typename?: "query_root";
  stock_check: Array<{
    __typename?: "stock_check";
    id: number;
    last_updated: any;
    due_date?: Maybe<any>;
    notes?: Maybe<string>;
    stock_check_status: {
      __typename?: "stock_check_status";
      stock_check_status_name: string;
      id: number;
    };
    location?: Maybe<{
      __typename?: "location";
      id: number;
      location_name: string;
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    }>;
    stock_check_lines: Array<{
      __typename?: "stock_check_line";
      id: number;
      last_updated: any;
      expected_quantity: number;
      expected_stock_condition_id: number;
      stock_check_line_status: {
        __typename?: "stock_check_line_status";
        id: number;
        stock_check_line_status_name: string;
      };
      stock_check_line_fulfilments: Array<{
        __typename?: "stock_check_line_fulfilment";
        last_updated: any;
        id: number;
        new_location_id?: Maybe<number>;
        new_product_id?: Maybe<number>;
        new_stock_condition_id?: Maybe<number>;
        new_stock_status_id?: Maybe<number>;
        quantity: number;
        notes: string;
        product?: Maybe<{ __typename?: "product"; product_name: string; id: number }>;
        location?: Maybe<{ __typename?: "location"; location_name: string; id: number }>;
        stock_condition?: Maybe<{
          __typename?: "stock_condition";
          id: number;
          stock_condition_name: string;
        }>;
        stock_status?: Maybe<{
          __typename?: "stock_status";
          id: number;
          stock_status_name: string;
        }>;
        product_stocks: Array<{
          __typename?: "product_stock";
          last_updated: any;
          quantity: number;
          id: number;
          organisation?: Maybe<{
            __typename?: "organisation";
            organisation_name: string;
            id: number;
          }>;
          product: { __typename?: "product"; product_name: string; id: number };
          location: {
            __typename?: "location";
            location_name: string;
            id: number;
            warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
          };
          stock_condition: {
            __typename?: "stock_condition";
            id: number;
            stock_condition_name: string;
          };
          stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
        }>;
      }>;
      product_stock: {
        __typename?: "product_stock";
        last_updated: any;
        quantity: number;
        id: number;
        organisation?: Maybe<{
          __typename?: "organisation";
          organisation_name: string;
          id: number;
        }>;
        product: { __typename?: "product"; product_name: string; id: number };
        location: {
          __typename?: "location";
          location_name: string;
          id: number;
          warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
        };
        stock_condition: {
          __typename?: "stock_condition";
          id: number;
          stock_condition_name: string;
        };
        stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
      };
    }>;
    stock_check_type: {
      __typename?: "stock_check_type";
      stock_check_type_name: string;
      id: number;
    };
  }>;
};

export type GetStockCheckLocationsByWarehouseQueryVariables = Exact<{
  warehouse_id: Scalars["Int"];
}>;

export type GetStockCheckLocationsByWarehouseQuery = {
  __typename?: "query_root";
  location: Array<{ __typename?: "location"; id: number; location_name: string }>;
};

export type GetStockCheckWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockCheckWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetStockChecksDescQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockChecksDescQuery = {
  __typename?: "query_root";
  stock_check: Array<{
    __typename?: "stock_check";
    id: number;
    due_date?: Maybe<any>;
    stock_check_status: {
      __typename?: "stock_check_status";
      id: number;
      stock_check_status_name: string;
    };
    stock_check_type: {
      __typename?: "stock_check_type";
      stock_check_type_name: string;
      id: number;
    };
    location?: Maybe<{
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    }>;
  }>;
};

export type GetStockTransferLocationNamesByWarehouseIdQueryVariables = Exact<{
  warehouseId: Scalars["Int"];
}>;

export type GetStockTransferLocationNamesByWarehouseIdQuery = {
  __typename?: "query_root";
  location: Array<{ __typename?: "location"; id: number; location_name: string }>;
};

export type GetStockTransferProductStockByLocationIdQueryVariables = Exact<{
  locationId: Scalars["Int"];
}>;

export type GetStockTransferProductStockByLocationIdQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    id: number;
    quantity: number;
    stock_condition: { __typename?: "stock_condition"; stock_condition_name: string; id: number };
    stock_status: { __typename?: "stock_status"; stock_status_name: string; id: number };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string; id: number }>;
    location: { __typename?: "location"; id: number; location_name: string };
    product: { __typename?: "product"; id: number; product_name: string; base_product_id: number };
  }>;
};

export type GetStockTransferWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockTransferWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetStockTransferByIdQueryVariables = Exact<{
  stockTransferId?: Maybe<Scalars["Int"]>;
}>;

export type GetStockTransferByIdQuery = {
  __typename?: "query_root";
  internal_transfer: Array<{
    __typename?: "internal_transfer";
    id: number;
    from_location?: Maybe<{
      __typename?: "location";
      id: number;
      location_name: string;
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    }>;
    to_location: {
      __typename?: "location";
      id: number;
      location_name: string;
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    };
    internal_transfer_lines: Array<{
      __typename?: "internal_transfer_line";
      id: number;
      quantity: number;
      product_stock: {
        __typename?: "product_stock";
        id: number;
        product: { __typename?: "product"; id: number; product_name: string };
      };
    }>;
    internal_transfer_status: {
      __typename?: "internal_transfer_status";
      id: number;
      internal_transfer_status_name: string;
    };
  }>;
};

export type GetStockTransfersWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetStockTransfersWithDataQuery = {
  __typename?: "query_root";
  internal_transfer: Array<{
    __typename?: "internal_transfer";
    id: number;
    from_location?: Maybe<{
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    }>;
    to_location: {
      __typename?: "location";
      id: number;
      location_name: string;
      warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    };
    internal_transfer_lines_aggregate: {
      __typename?: "internal_transfer_line_aggregate";
      aggregate?: Maybe<{ __typename?: "internal_transfer_line_aggregate_fields"; count: number }>;
    };
    internal_transfer_status: {
      __typename?: "internal_transfer_status";
      id: number;
      internal_transfer_status_name: string;
    };
  }>;
};

export type GetAllAddressNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllAddressNamesQuery = {
  __typename?: "query_root";
  address: Array<{ __typename?: "address"; id: number; address_name: string }>;
};

export type GetWarehouseByIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetWarehouseByIdQuery = {
  __typename?: "query_root";
  warehouse: Array<{
    __typename?: "warehouse";
    id: number;
    warehouse_name: string;
    address: {
      __typename?: "address";
      address_description?: Maybe<string>;
      address_line_1: string;
      address_line_2: string;
      address_line_3: string;
      address_name: string;
      city: string;
      id: number;
      name: string;
      postcode: string;
      phone_number?: Maybe<string>;
      organisation: { __typename?: "organisation"; organisation_name: string; id: number };
      country: { __typename?: "country"; country_name: string; country_code: string };
    };
    organisation: { __typename?: "organisation"; id: number; organisation_name: string };
  }>;
};

export type GetWarehousesWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetWarehousesWithDataQuery = {
  __typename?: "query_root";
  warehouse: Array<{
    __typename?: "warehouse";
    id: number;
    warehouse_name: string;
    address: {
      __typename?: "address";
      address_line_1: string;
      address_line_2: string;
      address_line_3: string;
      city: string;
      postcode: string;
      country: { __typename?: "country"; country_name: string };
    };
  }>;
};

export type GetProductStockByParamsQueryVariables = Exact<{
  warehouseId: Scalars["Int"];
  organisationId: Scalars["Int"];
  baseProductId: Scalars["Int"];
}>;

export type GetProductStockByParamsQuery = {
  __typename?: "query_root";
  product_stock: Array<{
    __typename?: "product_stock";
    quantity: number;
    id: number;
    location: {
      __typename?: "location";
      location_name: string;
      id: number;
      warehouse: { __typename?: "warehouse"; warehouse_name: string; id: number };
    };
    stock_status: { __typename?: "stock_status"; id: number; stock_status_name: string };
    organisation?: Maybe<{ __typename?: "organisation"; organisation_name: string; id: number }>;
    product: {
      __typename?: "product";
      id: number;
      product_name: string;
      base_product_quantity: number;
      base_product_id: number;
    };
    stock_condition: { __typename?: "stock_condition"; id: number; stock_condition_name: string };
  }>;
};

export type GetWorkOrderOrganisationNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkOrderOrganisationNamesQuery = {
  __typename?: "query_root";
  organisation: Array<{ __typename?: "organisation"; id: number; organisation_name: string }>;
};

export type GetWorkOrderRecipeByIdQueryVariables = Exact<{
  recipeId?: Maybe<Scalars["Int"]>;
}>;

export type GetWorkOrderRecipeByIdQuery = {
  __typename?: "query_root";
  recipe: Array<{
    __typename?: "recipe";
    id: number;
    recipe_name: string;
    recipe_inputs: Array<{
      __typename?: "recipe_input";
      id: number;
      quantity: number;
      product_id: number;
      product: {
        __typename?: "product";
        id: number;
        product_name: string;
        base_product_id: number;
        case_product_id?: Maybe<number>;
        case_product_quantity?: Maybe<number>;
      };
    }>;
    recipe_outputs: Array<{
      __typename?: "recipe_output";
      id: number;
      quantity: number;
      product: { __typename?: "product"; id: number; product_name: string };
    }>;
  }>;
};

export type GetWorkOrderRecipeNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkOrderRecipeNamesQuery = {
  __typename?: "query_root";
  recipe: Array<{ __typename?: "recipe"; id: number; recipe_name: string }>;
};

export type GetWorkOrderWarehouseNamesQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkOrderWarehouseNamesQuery = {
  __typename?: "query_root";
  warehouse: Array<{ __typename?: "warehouse"; id: number; warehouse_name: string }>;
};

export type GetWorkOrderFulfilmentByIdQueryVariables = Exact<{
  workOrderId: Scalars["Int"];
}>;

export type GetWorkOrderFulfilmentByIdQuery = {
  __typename?: "query_root";
  work_order: Array<{
    __typename?: "work_order";
    id: number;
    due_date: any;
    completed_quantity: number;
    cancelled_quantity: number;
    external_work_order_id?: Maybe<number>;
    last_updated: any;
    quantity: number;
    organisation: { __typename?: "organisation"; organisation_name: string; id: number };
    work_order_status: {
      __typename?: "work_order_status";
      work_order_status_name: string;
      id: number;
    };
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    recipe?: Maybe<{
      __typename?: "recipe";
      id: number;
      is_draft: boolean;
      recipe_name: string;
      recipe_outputs: Array<{
        __typename?: "recipe_output";
        id: number;
        quantity: number;
        product: { __typename?: "product"; id: number; product_name: string };
      }>;
    }>;
    work_order_input_lines: Array<{
      __typename?: "work_order_input_line";
      id: number;
      quantity: number;
      product_stock: {
        __typename?: "product_stock";
        id: number;
        barcode_uuid: any;
        location: { __typename?: "location"; location_name: string; id: number };
        product: { __typename?: "product"; product_name: string; id: number };
        organisation?: Maybe<{
          __typename?: "organisation";
          id: number;
          organisation_name: string;
        }>;
      };
    }>;
    work_order_output_lines: Array<{
      __typename?: "work_order_output_line";
      id: number;
      quantity: number;
      product: { __typename?: "product"; id: number; product_name: string };
      product_stocks: Array<{
        __typename?: "product_stock";
        quantity: number;
        product: { __typename?: "product"; product_name: string };
      }>;
    }>;
  }>;
};

export type GetWorkOrderLocationNamesByWarehouseIdQueryVariables = Exact<{
  warehouseId?: Maybe<Scalars["Int"]>;
}>;

export type GetWorkOrderLocationNamesByWarehouseIdQuery = {
  __typename?: "query_root";
  location: Array<{ __typename?: "location"; id: number; location_name: string }>;
};

export type GetWorkOrderViewByIdQueryVariables = Exact<{
  workOrderId?: Maybe<Scalars["Int"]>;
}>;

export type GetWorkOrderViewByIdQuery = {
  __typename?: "query_root";
  work_order: Array<{
    __typename?: "work_order";
    due_date: any;
    external_work_order_id?: Maybe<number>;
    completed_quantity: number;
    cancelled_quantity: number;
    quantity: number;
    organisation: { __typename?: "organisation"; organisation_name: string; id: number };
    work_order_status: {
      __typename?: "work_order_status";
      work_order_status_name: string;
      id: number;
    };
    recipe?: Maybe<{ __typename?: "recipe"; recipe_name: string }>;
    warehouse: { __typename?: "warehouse"; warehouse_name: string };
    work_order_input_lines: Array<{
      __typename?: "work_order_input_line";
      id: number;
      quantity: number;
      product_stock: {
        __typename?: "product_stock";
        product: { __typename?: "product"; product_name: string };
      };
    }>;
    work_order_output_lines: Array<{
      __typename?: "work_order_output_line";
      id: number;
      quantity: number;
      product: { __typename?: "product"; id: number; product_name: string };
      product_stocks: Array<{
        __typename?: "product_stock";
        initial_quantity: number;
        product: { __typename?: "product"; product_name: string };
        location: { __typename?: "location"; id: number; location_name: string };
      }>;
    }>;
  }>;
};

export type GetWorkOrdersWithDataQueryVariables = Exact<{ [key: string]: never }>;

export type GetWorkOrdersWithDataQuery = {
  __typename?: "query_root";
  work_order: Array<{
    __typename?: "work_order";
    id: number;
    due_date: any;
    external_work_order_id?: Maybe<number>;
    completed_quantity: number;
    cancelled_quantity: number;
    last_updated: any;
    quantity: number;
    work_order_status: {
      __typename?: "work_order_status";
      work_order_status_name: string;
      id: number;
    };
    recipe?: Maybe<{ __typename?: "recipe"; id: number; recipe_name: string }>;
    warehouse: { __typename?: "warehouse"; id: number; warehouse_name: string };
    organisation: { __typename?: "organisation"; id: number; organisation_name: string };
  }>;
};

export const GetAllCountryNamesAscDocument = gql`
  query getAllCountryNamesAsc {
    country(order_by: { country_name: asc }) {
      country_name
      country_code
    }
  }
`;

/**
 * __useGetAllCountryNamesAscQuery__
 *
 * To run a query within a React component, call `useGetAllCountryNamesAscQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCountryNamesAscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCountryNamesAscQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCountryNamesAscQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCountryNamesAscQuery,
    GetAllCountryNamesAscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCountryNamesAscQuery, GetAllCountryNamesAscQueryVariables>(
    GetAllCountryNamesAscDocument,
    options
  );
}
export function useGetAllCountryNamesAscLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCountryNamesAscQuery,
    GetAllCountryNamesAscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCountryNamesAscQuery, GetAllCountryNamesAscQueryVariables>(
    GetAllCountryNamesAscDocument,
    options
  );
}
export type GetAllCountryNamesAscQueryHookResult = ReturnType<typeof useGetAllCountryNamesAscQuery>;
export type GetAllCountryNamesAscLazyQueryHookResult = ReturnType<
  typeof useGetAllCountryNamesAscLazyQuery
>;
export type GetAllCountryNamesAscQueryResult = Apollo.QueryResult<
  GetAllCountryNamesAscQuery,
  GetAllCountryNamesAscQueryVariables
>;
export const GetAllOrganisationNamesDocument = gql`
  query getAllOrganisationNames {
    organisation {
      id
      organisation_name
    }
  }
`;

/**
 * __useGetAllOrganisationNamesQuery__
 *
 * To run a query within a React component, call `useGetAllOrganisationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllOrganisationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllOrganisationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllOrganisationNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllOrganisationNamesQuery,
    GetAllOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllOrganisationNamesQuery, GetAllOrganisationNamesQueryVariables>(
    GetAllOrganisationNamesDocument,
    options
  );
}
export function useGetAllOrganisationNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllOrganisationNamesQuery,
    GetAllOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllOrganisationNamesQuery, GetAllOrganisationNamesQueryVariables>(
    GetAllOrganisationNamesDocument,
    options
  );
}
export type GetAllOrganisationNamesQueryHookResult = ReturnType<
  typeof useGetAllOrganisationNamesQuery
>;
export type GetAllOrganisationNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllOrganisationNamesLazyQuery
>;
export type GetAllOrganisationNamesQueryResult = Apollo.QueryResult<
  GetAllOrganisationNamesQuery,
  GetAllOrganisationNamesQueryVariables
>;
export const GetAddressesWithDataDocument = gql`
  query getAddressesWithData {
    address {
      city
      address_line_1
      address_line_2
      address_line_3
      postcode
      phone_number
      country {
        country_name
      }
      id
      address_name
    }
  }
`;

/**
 * __useGetAddressesWithDataQuery__
 *
 * To run a query within a React component, call `useGetAddressesWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAddressesWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAddressesWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAddressesWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAddressesWithDataQuery,
    GetAddressesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAddressesWithDataQuery, GetAddressesWithDataQueryVariables>(
    GetAddressesWithDataDocument,
    options
  );
}
export function useGetAddressesWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAddressesWithDataQuery,
    GetAddressesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAddressesWithDataQuery, GetAddressesWithDataQueryVariables>(
    GetAddressesWithDataDocument,
    options
  );
}
export type GetAddressesWithDataQueryHookResult = ReturnType<typeof useGetAddressesWithDataQuery>;
export type GetAddressesWithDataLazyQueryHookResult = ReturnType<
  typeof useGetAddressesWithDataLazyQuery
>;
export type GetAddressesWithDataQueryResult = Apollo.QueryResult<
  GetAddressesWithDataQuery,
  GetAddressesWithDataQueryVariables
>;
export const GetCaseChangeAggregatedProductStockByParamsDocument = gql`
  query getCaseChangeAggregatedProductStockByParams(
    $baseProductId: Int
    $warehouseId: Int
    $stockConditionId: Int
    $organisationId: Int
  ) {
    product_stock(
      where: {
        quantity: { _gt: 0 }
        product: { base_product_id: { _eq: $baseProductId } }
        location: { warehouse_id: { _eq: $warehouseId } }
        stock_condition_id: { _eq: $stockConditionId }
        stock_status_id: { _eq: 0 }
        organisation: { id: { _eq: $organisationId } }
      }
    ) {
      id
      location {
        location_name
        warehouse {
          warehouse_name
        }
      }
      quantity
      stock_condition {
        stock_condition_name
      }
      product {
        product_name
        base_product_quantity
      }
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetCaseChangeAggregatedProductStockByParamsQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeAggregatedProductStockByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeAggregatedProductStockByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeAggregatedProductStockByParamsQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *      warehouseId: // value for 'warehouseId'
 *      stockConditionId: // value for 'stockConditionId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetCaseChangeAggregatedProductStockByParamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeAggregatedProductStockByParamsQuery,
    GetCaseChangeAggregatedProductStockByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeAggregatedProductStockByParamsQuery,
    GetCaseChangeAggregatedProductStockByParamsQueryVariables
  >(GetCaseChangeAggregatedProductStockByParamsDocument, options);
}
export function useGetCaseChangeAggregatedProductStockByParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeAggregatedProductStockByParamsQuery,
    GetCaseChangeAggregatedProductStockByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeAggregatedProductStockByParamsQuery,
    GetCaseChangeAggregatedProductStockByParamsQueryVariables
  >(GetCaseChangeAggregatedProductStockByParamsDocument, options);
}
export type GetCaseChangeAggregatedProductStockByParamsQueryHookResult = ReturnType<
  typeof useGetCaseChangeAggregatedProductStockByParamsQuery
>;
export type GetCaseChangeAggregatedProductStockByParamsLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeAggregatedProductStockByParamsLazyQuery
>;
export type GetCaseChangeAggregatedProductStockByParamsQueryResult = Apollo.QueryResult<
  GetCaseChangeAggregatedProductStockByParamsQuery,
  GetCaseChangeAggregatedProductStockByParamsQueryVariables
>;
export const GetCaseChangeFromCaseBaseProdQuantityByIdDocument = gql`
  query getCaseChangeFromCaseBaseProdQuantityById($productId: Int) {
    product(where: { id: { _eq: $productId } }) {
      base_product_quantity
    }
  }
`;

/**
 * __useGetCaseChangeFromCaseBaseProdQuantityByIdQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeFromCaseBaseProdQuantityByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeFromCaseBaseProdQuantityByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeFromCaseBaseProdQuantityByIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetCaseChangeFromCaseBaseProdQuantityByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeFromCaseBaseProdQuantityByIdQuery,
    GetCaseChangeFromCaseBaseProdQuantityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeFromCaseBaseProdQuantityByIdQuery,
    GetCaseChangeFromCaseBaseProdQuantityByIdQueryVariables
  >(GetCaseChangeFromCaseBaseProdQuantityByIdDocument, options);
}
export function useGetCaseChangeFromCaseBaseProdQuantityByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeFromCaseBaseProdQuantityByIdQuery,
    GetCaseChangeFromCaseBaseProdQuantityByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeFromCaseBaseProdQuantityByIdQuery,
    GetCaseChangeFromCaseBaseProdQuantityByIdQueryVariables
  >(GetCaseChangeFromCaseBaseProdQuantityByIdDocument, options);
}
export type GetCaseChangeFromCaseBaseProdQuantityByIdQueryHookResult = ReturnType<
  typeof useGetCaseChangeFromCaseBaseProdQuantityByIdQuery
>;
export type GetCaseChangeFromCaseBaseProdQuantityByIdLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeFromCaseBaseProdQuantityByIdLazyQuery
>;
export type GetCaseChangeFromCaseBaseProdQuantityByIdQueryResult = Apollo.QueryResult<
  GetCaseChangeFromCaseBaseProdQuantityByIdQuery,
  GetCaseChangeFromCaseBaseProdQuantityByIdQueryVariables
>;
export const GetCaseChangeFromCaseProductByParamsDocument = gql`
  query getCaseChangeFromCaseProductByParams(
    $baseProductId: Int
    $warehouseId: Int
    $conditionId: Int
  ) {
    product(
      where: {
        base_product_id: { _eq: $baseProductId }
        product_stocks: {
          quantity: { _gt: 0 }
          location: { warehouse_id: { _eq: $warehouseId } }
          stock_condition_id: { _eq: $conditionId }
        }
      }
    ) {
      product_name
      id
    }
  }
`;

/**
 * __useGetCaseChangeFromCaseProductByParamsQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeFromCaseProductByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeFromCaseProductByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeFromCaseProductByParamsQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *      warehouseId: // value for 'warehouseId'
 *      conditionId: // value for 'conditionId'
 *   },
 * });
 */
export function useGetCaseChangeFromCaseProductByParamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeFromCaseProductByParamsQuery,
    GetCaseChangeFromCaseProductByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeFromCaseProductByParamsQuery,
    GetCaseChangeFromCaseProductByParamsQueryVariables
  >(GetCaseChangeFromCaseProductByParamsDocument, options);
}
export function useGetCaseChangeFromCaseProductByParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeFromCaseProductByParamsQuery,
    GetCaseChangeFromCaseProductByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeFromCaseProductByParamsQuery,
    GetCaseChangeFromCaseProductByParamsQueryVariables
  >(GetCaseChangeFromCaseProductByParamsDocument, options);
}
export type GetCaseChangeFromCaseProductByParamsQueryHookResult = ReturnType<
  typeof useGetCaseChangeFromCaseProductByParamsQuery
>;
export type GetCaseChangeFromCaseProductByParamsLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeFromCaseProductByParamsLazyQuery
>;
export type GetCaseChangeFromCaseProductByParamsQueryResult = Apollo.QueryResult<
  GetCaseChangeFromCaseProductByParamsQuery,
  GetCaseChangeFromCaseProductByParamsQueryVariables
>;
export const GetCaseChangeLocationsByWarehouseIdDocument = gql`
  query getCaseChangeLocationsByWarehouseId($warehouseId: Int) {
    location(where: { warehouse_id: { _eq: $warehouseId } }) {
      id
      location_name
    }
  }
`;

/**
 * __useGetCaseChangeLocationsByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeLocationsByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeLocationsByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeLocationsByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetCaseChangeLocationsByWarehouseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeLocationsByWarehouseIdQuery,
    GetCaseChangeLocationsByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeLocationsByWarehouseIdQuery,
    GetCaseChangeLocationsByWarehouseIdQueryVariables
  >(GetCaseChangeLocationsByWarehouseIdDocument, options);
}
export function useGetCaseChangeLocationsByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeLocationsByWarehouseIdQuery,
    GetCaseChangeLocationsByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeLocationsByWarehouseIdQuery,
    GetCaseChangeLocationsByWarehouseIdQueryVariables
  >(GetCaseChangeLocationsByWarehouseIdDocument, options);
}
export type GetCaseChangeLocationsByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetCaseChangeLocationsByWarehouseIdQuery
>;
export type GetCaseChangeLocationsByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeLocationsByWarehouseIdLazyQuery
>;
export type GetCaseChangeLocationsByWarehouseIdQueryResult = Apollo.QueryResult<
  GetCaseChangeLocationsByWarehouseIdQuery,
  GetCaseChangeLocationsByWarehouseIdQueryVariables
>;
export const GetCaseChangeProductStockByParamsDocument = gql`
  query getCaseChangeProductStockByParams(
    $baseProductId: Int
    $baseProductQuantity: Int
    $warehouseId: Int
    $stockConditionId: Int
    $organisationId: Int
  ) {
    product_stock(
      where: {
        quantity: { _gt: 0 }
        product: {
          base_product_id: { _eq: $baseProductId }
          base_product_quantity: { _eq: $baseProductQuantity }
        }
        location: { warehouse_id: { _eq: $warehouseId } }
        stock_condition_id: { _eq: $stockConditionId }
        stock_status: { id: { _eq: 0 } }
        organisation: { id: { _eq: $organisationId } }
      }
    ) {
      id
      location {
        location_name
      }
      quantity
      stock_condition {
        stock_condition_name
      }
      product {
        product_name
        base_product_quantity
      }
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetCaseChangeProductStockByParamsQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeProductStockByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeProductStockByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeProductStockByParamsQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *      baseProductQuantity: // value for 'baseProductQuantity'
 *      warehouseId: // value for 'warehouseId'
 *      stockConditionId: // value for 'stockConditionId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetCaseChangeProductStockByParamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeProductStockByParamsQuery,
    GetCaseChangeProductStockByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeProductStockByParamsQuery,
    GetCaseChangeProductStockByParamsQueryVariables
  >(GetCaseChangeProductStockByParamsDocument, options);
}
export function useGetCaseChangeProductStockByParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeProductStockByParamsQuery,
    GetCaseChangeProductStockByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeProductStockByParamsQuery,
    GetCaseChangeProductStockByParamsQueryVariables
  >(GetCaseChangeProductStockByParamsDocument, options);
}
export type GetCaseChangeProductStockByParamsQueryHookResult = ReturnType<
  typeof useGetCaseChangeProductStockByParamsQuery
>;
export type GetCaseChangeProductStockByParamsLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeProductStockByParamsLazyQuery
>;
export type GetCaseChangeProductStockByParamsQueryResult = Apollo.QueryResult<
  GetCaseChangeProductStockByParamsQuery,
  GetCaseChangeProductStockByParamsQueryVariables
>;
export const GetCaseChangeToCaseProductByBasedProdIdDocument = gql`
  query getCaseChangeToCaseProductByBasedProdId($baseProductId: Int) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      product_name
      id
      product_stocks {
        stock_condition {
          stock_condition_name
          id
        }
      }
    }
  }
`;

/**
 * __useGetCaseChangeToCaseProductByBasedProdIdQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeToCaseProductByBasedProdIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeToCaseProductByBasedProdIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeToCaseProductByBasedProdIdQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetCaseChangeToCaseProductByBasedProdIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeToCaseProductByBasedProdIdQuery,
    GetCaseChangeToCaseProductByBasedProdIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeToCaseProductByBasedProdIdQuery,
    GetCaseChangeToCaseProductByBasedProdIdQueryVariables
  >(GetCaseChangeToCaseProductByBasedProdIdDocument, options);
}
export function useGetCaseChangeToCaseProductByBasedProdIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeToCaseProductByBasedProdIdQuery,
    GetCaseChangeToCaseProductByBasedProdIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeToCaseProductByBasedProdIdQuery,
    GetCaseChangeToCaseProductByBasedProdIdQueryVariables
  >(GetCaseChangeToCaseProductByBasedProdIdDocument, options);
}
export type GetCaseChangeToCaseProductByBasedProdIdQueryHookResult = ReturnType<
  typeof useGetCaseChangeToCaseProductByBasedProdIdQuery
>;
export type GetCaseChangeToCaseProductByBasedProdIdLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeToCaseProductByBasedProdIdLazyQuery
>;
export type GetCaseChangeToCaseProductByBasedProdIdQueryResult = Apollo.QueryResult<
  GetCaseChangeToCaseProductByBasedProdIdQuery,
  GetCaseChangeToCaseProductByBasedProdIdQueryVariables
>;
export const GetCaseChangeToCaseProductByBaseProductParamsDocument = gql`
  query getCaseChangeToCaseProductByBaseProductParams(
    $baseProductId: Int
    $baseProductQuantity: Int
  ) {
    product(
      where: {
        base_product_quantity: { _eq: $baseProductQuantity }
        base_product_id: { _eq: $baseProductId }
      }
    ) {
      product_name
      id
    }
  }
`;

/**
 * __useGetCaseChangeToCaseProductByBaseProductParamsQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeToCaseProductByBaseProductParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeToCaseProductByBaseProductParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeToCaseProductByBaseProductParamsQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *      baseProductQuantity: // value for 'baseProductQuantity'
 *   },
 * });
 */
export function useGetCaseChangeToCaseProductByBaseProductParamsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeToCaseProductByBaseProductParamsQuery,
    GetCaseChangeToCaseProductByBaseProductParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeToCaseProductByBaseProductParamsQuery,
    GetCaseChangeToCaseProductByBaseProductParamsQueryVariables
  >(GetCaseChangeToCaseProductByBaseProductParamsDocument, options);
}
export function useGetCaseChangeToCaseProductByBaseProductParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeToCaseProductByBaseProductParamsQuery,
    GetCaseChangeToCaseProductByBaseProductParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeToCaseProductByBaseProductParamsQuery,
    GetCaseChangeToCaseProductByBaseProductParamsQueryVariables
  >(GetCaseChangeToCaseProductByBaseProductParamsDocument, options);
}
export type GetCaseChangeToCaseProductByBaseProductParamsQueryHookResult = ReturnType<
  typeof useGetCaseChangeToCaseProductByBaseProductParamsQuery
>;
export type GetCaseChangeToCaseProductByBaseProductParamsLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeToCaseProductByBaseProductParamsLazyQuery
>;
export type GetCaseChangeToCaseProductByBaseProductParamsQueryResult = Apollo.QueryResult<
  GetCaseChangeToCaseProductByBaseProductParamsQuery,
  GetCaseChangeToCaseProductByBaseProductParamsQueryVariables
>;
export const GetCaseChangeTopDropdownsDataDocument = gql`
  query getCaseChangeTopDropdownsData {
    organisation {
      organisation_name
      id
    }
    base_product {
      id
      product_name
    }
    warehouse {
      id
      warehouse_name
    }
    stock_condition {
      id
      stock_condition_name
    }
  }
`;

/**
 * __useGetCaseChangeTopDropdownsDataQuery__
 *
 * To run a query within a React component, call `useGetCaseChangeTopDropdownsDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseChangeTopDropdownsDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseChangeTopDropdownsDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCaseChangeTopDropdownsDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCaseChangeTopDropdownsDataQuery,
    GetCaseChangeTopDropdownsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseChangeTopDropdownsDataQuery,
    GetCaseChangeTopDropdownsDataQueryVariables
  >(GetCaseChangeTopDropdownsDataDocument, options);
}
export function useGetCaseChangeTopDropdownsDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseChangeTopDropdownsDataQuery,
    GetCaseChangeTopDropdownsDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseChangeTopDropdownsDataQuery,
    GetCaseChangeTopDropdownsDataQueryVariables
  >(GetCaseChangeTopDropdownsDataDocument, options);
}
export type GetCaseChangeTopDropdownsDataQueryHookResult = ReturnType<
  typeof useGetCaseChangeTopDropdownsDataQuery
>;
export type GetCaseChangeTopDropdownsDataLazyQueryHookResult = ReturnType<
  typeof useGetCaseChangeTopDropdownsDataLazyQuery
>;
export type GetCaseChangeTopDropdownsDataQueryResult = Apollo.QueryResult<
  GetCaseChangeTopDropdownsDataQuery,
  GetCaseChangeTopDropdownsDataQueryVariables
>;
export const InsertCourierDocument = gql`
  mutation insertCourier($courierName: String!) {
    insert_courier_one(object: { courier_name: $courierName }) {
      id
    }
  }
`;
export type InsertCourierMutationFn = Apollo.MutationFunction<
  InsertCourierMutation,
  InsertCourierMutationVariables
>;

/**
 * __useInsertCourierMutation__
 *
 * To run a mutation, you first call `useInsertCourierMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCourierMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCourierMutation, { data, loading, error }] = useInsertCourierMutation({
 *   variables: {
 *      courierName: // value for 'courierName'
 *   },
 * });
 */
export function useInsertCourierMutation(
  baseOptions?: Apollo.MutationHookOptions<InsertCourierMutation, InsertCourierMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertCourierMutation, InsertCourierMutationVariables>(
    InsertCourierDocument,
    options
  );
}
export type InsertCourierMutationHookResult = ReturnType<typeof useInsertCourierMutation>;
export type InsertCourierMutationResult = Apollo.MutationResult<InsertCourierMutation>;
export type InsertCourierMutationOptions = Apollo.BaseMutationOptions<
  InsertCourierMutation,
  InsertCourierMutationVariables
>;
export const GetAllCourierNamesDocument = gql`
  query getAllCourierNames {
    courier {
      id
      courier_name
    }
  }
`;

/**
 * __useGetAllCourierNamesQuery__
 *
 * To run a query within a React component, call `useGetAllCourierNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCourierNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCourierNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCourierNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllCourierNamesQuery, GetAllCourierNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCourierNamesQuery, GetAllCourierNamesQueryVariables>(
    GetAllCourierNamesDocument,
    options
  );
}
export function useGetAllCourierNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCourierNamesQuery,
    GetAllCourierNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllCourierNamesQuery, GetAllCourierNamesQueryVariables>(
    GetAllCourierNamesDocument,
    options
  );
}
export type GetAllCourierNamesQueryHookResult = ReturnType<typeof useGetAllCourierNamesQuery>;
export type GetAllCourierNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllCourierNamesLazyQuery
>;
export type GetAllCourierNamesQueryResult = Apollo.QueryResult<
  GetAllCourierNamesQuery,
  GetAllCourierNamesQueryVariables
>;
export const InsertCourierServiceDocument = gql`
  mutation insertCourierService($courierId: Int!, $courierServiceName: String!) {
    insert_courier_service_one(
      object: { courier_id: $courierId, courier_service_name: $courierServiceName }
    ) {
      id
    }
  }
`;
export type InsertCourierServiceMutationFn = Apollo.MutationFunction<
  InsertCourierServiceMutation,
  InsertCourierServiceMutationVariables
>;

/**
 * __useInsertCourierServiceMutation__
 *
 * To run a mutation, you first call `useInsertCourierServiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useInsertCourierServiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [insertCourierServiceMutation, { data, loading, error }] = useInsertCourierServiceMutation({
 *   variables: {
 *      courierId: // value for 'courierId'
 *      courierServiceName: // value for 'courierServiceName'
 *   },
 * });
 */
export function useInsertCourierServiceMutation(
  baseOptions?: Apollo.MutationHookOptions<
    InsertCourierServiceMutation,
    InsertCourierServiceMutationVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<InsertCourierServiceMutation, InsertCourierServiceMutationVariables>(
    InsertCourierServiceDocument,
    options
  );
}
export type InsertCourierServiceMutationHookResult = ReturnType<
  typeof useInsertCourierServiceMutation
>;
export type InsertCourierServiceMutationResult =
  Apollo.MutationResult<InsertCourierServiceMutation>;
export type InsertCourierServiceMutationOptions = Apollo.BaseMutationOptions<
  InsertCourierServiceMutation,
  InsertCourierServiceMutationVariables
>;
export const GetAllCourierServiceNamesDocument = gql`
  query getAllCourierServiceNames {
    courier_service {
      id
      courier_service_name
    }
  }
`;

/**
 * __useGetAllCourierServiceNamesQuery__
 *
 * To run a query within a React component, call `useGetAllCourierServiceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllCourierServiceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllCourierServiceNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllCourierServiceNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllCourierServiceNamesQuery,
    GetAllCourierServiceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllCourierServiceNamesQuery, GetAllCourierServiceNamesQueryVariables>(
    GetAllCourierServiceNamesDocument,
    options
  );
}
export function useGetAllCourierServiceNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllCourierServiceNamesQuery,
    GetAllCourierServiceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllCourierServiceNamesQuery,
    GetAllCourierServiceNamesQueryVariables
  >(GetAllCourierServiceNamesDocument, options);
}
export type GetAllCourierServiceNamesQueryHookResult = ReturnType<
  typeof useGetAllCourierServiceNamesQuery
>;
export type GetAllCourierServiceNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllCourierServiceNamesLazyQuery
>;
export type GetAllCourierServiceNamesQueryResult = Apollo.QueryResult<
  GetAllCourierServiceNamesQuery,
  GetAllCourierServiceNamesQueryVariables
>;
export const GetCourierServicesWithDataDocument = gql`
  query getCourierServicesWithData {
    courier_service {
      id
      courier_service_name
      courier {
        courier_name
      }
    }
  }
`;

/**
 * __useGetCourierServicesWithDataQuery__
 *
 * To run a query within a React component, call `useGetCourierServicesWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCourierServicesWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCourierServicesWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCourierServicesWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCourierServicesWithDataQuery,
    GetCourierServicesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCourierServicesWithDataQuery, GetCourierServicesWithDataQueryVariables>(
    GetCourierServicesWithDataDocument,
    options
  );
}
export function useGetCourierServicesWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCourierServicesWithDataQuery,
    GetCourierServicesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCourierServicesWithDataQuery,
    GetCourierServicesWithDataQueryVariables
  >(GetCourierServicesWithDataDocument, options);
}
export type GetCourierServicesWithDataQueryHookResult = ReturnType<
  typeof useGetCourierServicesWithDataQuery
>;
export type GetCourierServicesWithDataLazyQueryHookResult = ReturnType<
  typeof useGetCourierServicesWithDataLazyQuery
>;
export type GetCourierServicesWithDataQueryResult = Apollo.QueryResult<
  GetCourierServicesWithDataQuery,
  GetCourierServicesWithDataQueryVariables
>;
export const GetDashboardStockByLocationTypeDocument = gql`
  query getDashboardStockByLocationType {
    location_type {
      id
      locations {
        product_stocks(where: { quantity: { _neq: 0 } }) {
          id
          organisation {
            id
            organisation_name
          }
          location {
            id
            location_name
          }
          product {
            product_name
            base_product_quantity
          }
          stock_status {
            stock_status_name
          }
          stock_condition {
            stock_condition_name
          }
          quantity
        }
      }
    }
  }
`;

/**
 * __useGetDashboardStockByLocationTypeQuery__
 *
 * To run a query within a React component, call `useGetDashboardStockByLocationTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardStockByLocationTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardStockByLocationTypeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardStockByLocationTypeQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardStockByLocationTypeQuery,
    GetDashboardStockByLocationTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardStockByLocationTypeQuery,
    GetDashboardStockByLocationTypeQueryVariables
  >(GetDashboardStockByLocationTypeDocument, options);
}
export function useGetDashboardStockByLocationTypeLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardStockByLocationTypeQuery,
    GetDashboardStockByLocationTypeQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardStockByLocationTypeQuery,
    GetDashboardStockByLocationTypeQueryVariables
  >(GetDashboardStockByLocationTypeDocument, options);
}
export type GetDashboardStockByLocationTypeQueryHookResult = ReturnType<
  typeof useGetDashboardStockByLocationTypeQuery
>;
export type GetDashboardStockByLocationTypeLazyQueryHookResult = ReturnType<
  typeof useGetDashboardStockByLocationTypeLazyQuery
>;
export type GetDashboardStockByLocationTypeQueryResult = Apollo.QueryResult<
  GetDashboardStockByLocationTypeQuery,
  GetDashboardStockByLocationTypeQueryVariables
>;
export const GetDashboardInboundManifestsDocument = gql`
  query getDashboardInboundManifests {
    inbound_manifest {
      id
      expected_arrival
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetDashboardInboundManifestsQuery__
 *
 * To run a query within a React component, call `useGetDashboardInboundManifestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardInboundManifestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardInboundManifestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardInboundManifestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardInboundManifestsQuery,
    GetDashboardInboundManifestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardInboundManifestsQuery,
    GetDashboardInboundManifestsQueryVariables
  >(GetDashboardInboundManifestsDocument, options);
}
export function useGetDashboardInboundManifestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardInboundManifestsQuery,
    GetDashboardInboundManifestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardInboundManifestsQuery,
    GetDashboardInboundManifestsQueryVariables
  >(GetDashboardInboundManifestsDocument, options);
}
export type GetDashboardInboundManifestsQueryHookResult = ReturnType<
  typeof useGetDashboardInboundManifestsQuery
>;
export type GetDashboardInboundManifestsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardInboundManifestsLazyQuery
>;
export type GetDashboardInboundManifestsQueryResult = Apollo.QueryResult<
  GetDashboardInboundManifestsQuery,
  GetDashboardInboundManifestsQueryVariables
>;
export const GetDashboardOutboundManifestsDocument = gql`
  query getDashboardOutboundManifests {
    outbound_manifest {
      id
      expected_dispatch
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetDashboardOutboundManifestsQuery__
 *
 * To run a query within a React component, call `useGetDashboardOutboundManifestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardOutboundManifestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardOutboundManifestsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardOutboundManifestsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardOutboundManifestsQuery,
    GetDashboardOutboundManifestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetDashboardOutboundManifestsQuery,
    GetDashboardOutboundManifestsQueryVariables
  >(GetDashboardOutboundManifestsDocument, options);
}
export function useGetDashboardOutboundManifestsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardOutboundManifestsQuery,
    GetDashboardOutboundManifestsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardOutboundManifestsQuery,
    GetDashboardOutboundManifestsQueryVariables
  >(GetDashboardOutboundManifestsDocument, options);
}
export type GetDashboardOutboundManifestsQueryHookResult = ReturnType<
  typeof useGetDashboardOutboundManifestsQuery
>;
export type GetDashboardOutboundManifestsLazyQueryHookResult = ReturnType<
  typeof useGetDashboardOutboundManifestsLazyQuery
>;
export type GetDashboardOutboundManifestsQueryResult = Apollo.QueryResult<
  GetDashboardOutboundManifestsQuery,
  GetDashboardOutboundManifestsQueryVariables
>;
export const GetDashboardProductsReportDocument = gql`
  query getDashboardProductsReport {
    product {
      id
      external_product_id
      product_name
      product_stocks_aggregate {
        aggregate {
          count(columns: id)
        }
      }
      service_account {
        service_account_name
      }
      case_product_id
      base_product_quantity
    }
  }
`;

/**
 * __useGetDashboardProductsReportQuery__
 *
 * To run a query within a React component, call `useGetDashboardProductsReportQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDashboardProductsReportQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDashboardProductsReportQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDashboardProductsReportQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetDashboardProductsReportQuery,
    GetDashboardProductsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetDashboardProductsReportQuery, GetDashboardProductsReportQueryVariables>(
    GetDashboardProductsReportDocument,
    options
  );
}
export function useGetDashboardProductsReportLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetDashboardProductsReportQuery,
    GetDashboardProductsReportQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetDashboardProductsReportQuery,
    GetDashboardProductsReportQueryVariables
  >(GetDashboardProductsReportDocument, options);
}
export type GetDashboardProductsReportQueryHookResult = ReturnType<
  typeof useGetDashboardProductsReportQuery
>;
export type GetDashboardProductsReportLazyQueryHookResult = ReturnType<
  typeof useGetDashboardProductsReportLazyQuery
>;
export type GetDashboardProductsReportQueryResult = Apollo.QueryResult<
  GetDashboardProductsReportQuery,
  GetDashboardProductsReportQueryVariables
>;
export const GetProductInformationDocument = gql`
  query getProductInformation {
    product_aggregate {
      aggregate {
        count
      }
    }
    product {
      product_name
      product_stocks_aggregate {
        aggregate {
          sum {
            quantity
          }
        }
      }
    }
  }
`;

/**
 * __useGetProductInformationQuery__
 *
 * To run a query within a React component, call `useGetProductInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductInformationQuery,
    GetProductInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductInformationQuery, GetProductInformationQueryVariables>(
    GetProductInformationDocument,
    options
  );
}
export function useGetProductInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductInformationQuery,
    GetProductInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductInformationQuery, GetProductInformationQueryVariables>(
    GetProductInformationDocument,
    options
  );
}
export type GetProductInformationQueryHookResult = ReturnType<typeof useGetProductInformationQuery>;
export type GetProductInformationLazyQueryHookResult = ReturnType<
  typeof useGetProductInformationLazyQuery
>;
export type GetProductInformationQueryResult = Apollo.QueryResult<
  GetProductInformationQuery,
  GetProductInformationQueryVariables
>;
export const GetStockInformationDocument = gql`
  query getStockInformation {
    product_stock_aggregate {
      aggregate {
        sum {
          quantity
        }
      }
    }
    product_stock {
      last_updated
      stock_condition {
        stock_condition_name
      }
    }
  }
`;

/**
 * __useGetStockInformationQuery__
 *
 * To run a query within a React component, call `useGetStockInformationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockInformationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockInformationQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockInformationQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStockInformationQuery, GetStockInformationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockInformationQuery, GetStockInformationQueryVariables>(
    GetStockInformationDocument,
    options
  );
}
export function useGetStockInformationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockInformationQuery,
    GetStockInformationQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockInformationQuery, GetStockInformationQueryVariables>(
    GetStockInformationDocument,
    options
  );
}
export type GetStockInformationQueryHookResult = ReturnType<typeof useGetStockInformationQuery>;
export type GetStockInformationLazyQueryHookResult = ReturnType<
  typeof useGetStockInformationLazyQuery
>;
export type GetStockInformationQueryResult = Apollo.QueryResult<
  GetStockInformationQuery,
  GetStockInformationQueryVariables
>;
export const GetInboundManifestsByDateDocument = gql`
  query getInboundManifestsByDate($selectedDate: timestamptz!) {
    inbound_manifest(where: { expected_arrival: { _eq: $selectedDate } }) {
      id
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetInboundManifestsByDateQuery__
 *
 * To run a query within a React component, call `useGetInboundManifestsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundManifestsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundManifestsByDateQuery({
 *   variables: {
 *      selectedDate: // value for 'selectedDate'
 *   },
 * });
 */
export function useGetInboundManifestsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInboundManifestsByDateQuery,
    GetInboundManifestsByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundManifestsByDateQuery, GetInboundManifestsByDateQueryVariables>(
    GetInboundManifestsByDateDocument,
    options
  );
}
export function useGetInboundManifestsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundManifestsByDateQuery,
    GetInboundManifestsByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundManifestsByDateQuery,
    GetInboundManifestsByDateQueryVariables
  >(GetInboundManifestsByDateDocument, options);
}
export type GetInboundManifestsByDateQueryHookResult = ReturnType<
  typeof useGetInboundManifestsByDateQuery
>;
export type GetInboundManifestsByDateLazyQueryHookResult = ReturnType<
  typeof useGetInboundManifestsByDateLazyQuery
>;
export type GetInboundManifestsByDateQueryResult = Apollo.QueryResult<
  GetInboundManifestsByDateQuery,
  GetInboundManifestsByDateQueryVariables
>;
export const GetOutboundManifestsByDateDocument = gql`
  query getOutboundManifestsByDate($selectedDate: timestamptz!) {
    outbound_manifest(where: { expected_dispatch: { _eq: $selectedDate } }) {
      id
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetOutboundManifestsByDateQuery__
 *
 * To run a query within a React component, call `useGetOutboundManifestsByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundManifestsByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundManifestsByDateQuery({
 *   variables: {
 *      selectedDate: // value for 'selectedDate'
 *   },
 * });
 */
export function useGetOutboundManifestsByDateQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOutboundManifestsByDateQuery,
    GetOutboundManifestsByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOutboundManifestsByDateQuery, GetOutboundManifestsByDateQueryVariables>(
    GetOutboundManifestsByDateDocument,
    options
  );
}
export function useGetOutboundManifestsByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundManifestsByDateQuery,
    GetOutboundManifestsByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOutboundManifestsByDateQuery,
    GetOutboundManifestsByDateQueryVariables
  >(GetOutboundManifestsByDateDocument, options);
}
export type GetOutboundManifestsByDateQueryHookResult = ReturnType<
  typeof useGetOutboundManifestsByDateQuery
>;
export type GetOutboundManifestsByDateLazyQueryHookResult = ReturnType<
  typeof useGetOutboundManifestsByDateLazyQuery
>;
export type GetOutboundManifestsByDateQueryResult = Apollo.QueryResult<
  GetOutboundManifestsByDateQuery,
  GetOutboundManifestsByDateQueryVariables
>;
export const GetWorkOrdersByDateDocument = gql`
  query getWorkOrdersByDate($selectedDate: timestamptz!) {
    recipe {
      work_orders(where: { due_date: { _eq: $selectedDate } }) {
        id
        organisation {
          organisation_name
        }
      }
    }
  }
`;

/**
 * __useGetWorkOrdersByDateQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersByDateQuery({
 *   variables: {
 *      selectedDate: // value for 'selectedDate'
 *   },
 * });
 */
export function useGetWorkOrdersByDateQuery(
  baseOptions: Apollo.QueryHookOptions<GetWorkOrdersByDateQuery, GetWorkOrdersByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrdersByDateQuery, GetWorkOrdersByDateQueryVariables>(
    GetWorkOrdersByDateDocument,
    options
  );
}
export function useGetWorkOrdersByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrdersByDateQuery,
    GetWorkOrdersByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkOrdersByDateQuery, GetWorkOrdersByDateQueryVariables>(
    GetWorkOrdersByDateDocument,
    options
  );
}
export type GetWorkOrdersByDateQueryHookResult = ReturnType<typeof useGetWorkOrdersByDateQuery>;
export type GetWorkOrdersByDateLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrdersByDateLazyQuery
>;
export type GetWorkOrdersByDateQueryResult = Apollo.QueryResult<
  GetWorkOrdersByDateQuery,
  GetWorkOrdersByDateQueryVariables
>;
export const GetProductStockWithDataDocument = gql`
  query getProductStockWithData {
    product_stock {
      id
      quantity
      location {
        location_name
        id
        warehouse {
          warehouse_name
          id
        }
      }
      stock_condition {
        id
        stock_condition_name
      }
      product {
        product_name
        id
      }
      stock_status {
        id
        stock_status_name
      }
    }
  }
`;

/**
 * __useGetProductStockWithDataQuery__
 *
 * To run a query within a React component, call `useGetProductStockWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStockWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStockWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductStockWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetProductStockWithDataQuery,
    GetProductStockWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductStockWithDataQuery, GetProductStockWithDataQueryVariables>(
    GetProductStockWithDataDocument,
    options
  );
}
export function useGetProductStockWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductStockWithDataQuery,
    GetProductStockWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductStockWithDataQuery, GetProductStockWithDataQueryVariables>(
    GetProductStockWithDataDocument,
    options
  );
}
export type GetProductStockWithDataQueryHookResult = ReturnType<
  typeof useGetProductStockWithDataQuery
>;
export type GetProductStockWithDataLazyQueryHookResult = ReturnType<
  typeof useGetProductStockWithDataLazyQuery
>;
export type GetProductStockWithDataQueryResult = Apollo.QueryResult<
  GetProductStockWithDataQuery,
  GetProductStockWithDataQueryVariables
>;
export const GetFileCopiesByDateDocument = gql`
  query getFileCopiesByDate($fromDate: timestamptz) {
    product_stock(
      where: {
        organisation: { organisation_name: { _eq: "Sourceful File Copy" } }
        quantity: { _gt: 0 }
        last_updated: { _gte: $fromDate }
      }
    ) {
      id
      quantity
      product {
        product_name
        id
        fsc_certified
      }
      stock_condition {
        stock_condition_name
        id
      }
      stock_status {
        id
        stock_status_name
      }
      location {
        location_name
        id
        warehouse {
          warehouse_name
          id
        }
      }
      organisation {
        id
        organisation_name
      }
    }
  }
`;

/**
 * __useGetFileCopiesByDateQuery__
 *
 * To run a query within a React component, call `useGetFileCopiesByDateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileCopiesByDateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileCopiesByDateQuery({
 *   variables: {
 *      fromDate: // value for 'fromDate'
 *   },
 * });
 */
export function useGetFileCopiesByDateQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFileCopiesByDateQuery, GetFileCopiesByDateQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileCopiesByDateQuery, GetFileCopiesByDateQueryVariables>(
    GetFileCopiesByDateDocument,
    options
  );
}
export function useGetFileCopiesByDateLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetFileCopiesByDateQuery,
    GetFileCopiesByDateQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFileCopiesByDateQuery, GetFileCopiesByDateQueryVariables>(
    GetFileCopiesByDateDocument,
    options
  );
}
export type GetFileCopiesByDateQueryHookResult = ReturnType<typeof useGetFileCopiesByDateQuery>;
export type GetFileCopiesByDateLazyQueryHookResult = ReturnType<
  typeof useGetFileCopiesByDateLazyQuery
>;
export type GetFileCopiesByDateQueryResult = Apollo.QueryResult<
  GetFileCopiesByDateQuery,
  GetFileCopiesByDateQueryVariables
>;
export const GetFileCopiesDocument = gql`
  query getFileCopies {
    product_stock(
      where: {
        organisation: { organisation_name: { _eq: "Sourceful File Copy" } }
        quantity: { _gt: 0 }
      }
    ) {
      id
      quantity
      product {
        product_name
        id
        fsc_certified
      }
      stock_condition {
        stock_condition_name
        id
      }
      stock_status {
        id
        stock_status_name
      }
      location {
        location_name
        id
        warehouse {
          warehouse_name
          id
        }
      }
      last_updated
      organisation {
        id
        organisation_name
      }
    }
  }
`;

/**
 * __useGetFileCopiesQuery__
 *
 * To run a query within a React component, call `useGetFileCopiesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFileCopiesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFileCopiesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetFileCopiesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFileCopiesQuery, GetFileCopiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFileCopiesQuery, GetFileCopiesQueryVariables>(
    GetFileCopiesDocument,
    options
  );
}
export function useGetFileCopiesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFileCopiesQuery, GetFileCopiesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFileCopiesQuery, GetFileCopiesQueryVariables>(
    GetFileCopiesDocument,
    options
  );
}
export type GetFileCopiesQueryHookResult = ReturnType<typeof useGetFileCopiesQuery>;
export type GetFileCopiesLazyQueryHookResult = ReturnType<typeof useGetFileCopiesLazyQuery>;
export type GetFileCopiesQueryResult = Apollo.QueryResult<
  GetFileCopiesQuery,
  GetFileCopiesQueryVariables
>;
export const GetInboundBaseProductsDocument = gql`
  query getInboundBaseProducts {
    base_product {
      id
      product_name
      fsc_certified
      grs_certified
    }
  }
`;

/**
 * __useGetInboundBaseProductsQuery__
 *
 * To run a query within a React component, call `useGetInboundBaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundBaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundBaseProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundBaseProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundBaseProductsQuery,
    GetInboundBaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundBaseProductsQuery, GetInboundBaseProductsQueryVariables>(
    GetInboundBaseProductsDocument,
    options
  );
}
export function useGetInboundBaseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundBaseProductsQuery,
    GetInboundBaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundBaseProductsQuery, GetInboundBaseProductsQueryVariables>(
    GetInboundBaseProductsDocument,
    options
  );
}
export type GetInboundBaseProductsQueryHookResult = ReturnType<
  typeof useGetInboundBaseProductsQuery
>;
export type GetInboundBaseProductsLazyQueryHookResult = ReturnType<
  typeof useGetInboundBaseProductsLazyQuery
>;
export type GetInboundBaseProductsQueryResult = Apollo.QueryResult<
  GetInboundBaseProductsQuery,
  GetInboundBaseProductsQueryVariables
>;
export const GetInboundCaseProductsByBaseProductIdDocument = gql`
  query getInboundCaseProductsByBaseProductId($baseProductId: Int!) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      id
      product_name
      base_product_quantity
    }
  }
`;

/**
 * __useGetInboundCaseProductsByBaseProductIdQuery__
 *
 * To run a query within a React component, call `useGetInboundCaseProductsByBaseProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundCaseProductsByBaseProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundCaseProductsByBaseProductIdQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetInboundCaseProductsByBaseProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInboundCaseProductsByBaseProductIdQuery,
    GetInboundCaseProductsByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInboundCaseProductsByBaseProductIdQuery,
    GetInboundCaseProductsByBaseProductIdQueryVariables
  >(GetInboundCaseProductsByBaseProductIdDocument, options);
}
export function useGetInboundCaseProductsByBaseProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundCaseProductsByBaseProductIdQuery,
    GetInboundCaseProductsByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundCaseProductsByBaseProductIdQuery,
    GetInboundCaseProductsByBaseProductIdQueryVariables
  >(GetInboundCaseProductsByBaseProductIdDocument, options);
}
export type GetInboundCaseProductsByBaseProductIdQueryHookResult = ReturnType<
  typeof useGetInboundCaseProductsByBaseProductIdQuery
>;
export type GetInboundCaseProductsByBaseProductIdLazyQueryHookResult = ReturnType<
  typeof useGetInboundCaseProductsByBaseProductIdLazyQuery
>;
export type GetInboundCaseProductsByBaseProductIdQueryResult = Apollo.QueryResult<
  GetInboundCaseProductsByBaseProductIdQuery,
  GetInboundCaseProductsByBaseProductIdQueryVariables
>;
export const GetInboundCourierServiceNamesDocument = gql`
  query getInboundCourierServiceNames {
    courier_service {
      id
      courier_service_name
    }
  }
`;

/**
 * __useGetInboundCourierServiceNamesQuery__
 *
 * To run a query within a React component, call `useGetInboundCourierServiceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundCourierServiceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundCourierServiceNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundCourierServiceNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundCourierServiceNamesQuery,
    GetInboundCourierServiceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInboundCourierServiceNamesQuery,
    GetInboundCourierServiceNamesQueryVariables
  >(GetInboundCourierServiceNamesDocument, options);
}
export function useGetInboundCourierServiceNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundCourierServiceNamesQuery,
    GetInboundCourierServiceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundCourierServiceNamesQuery,
    GetInboundCourierServiceNamesQueryVariables
  >(GetInboundCourierServiceNamesDocument, options);
}
export type GetInboundCourierServiceNamesQueryHookResult = ReturnType<
  typeof useGetInboundCourierServiceNamesQuery
>;
export type GetInboundCourierServiceNamesLazyQueryHookResult = ReturnType<
  typeof useGetInboundCourierServiceNamesLazyQuery
>;
export type GetInboundCourierServiceNamesQueryResult = Apollo.QueryResult<
  GetInboundCourierServiceNamesQuery,
  GetInboundCourierServiceNamesQueryVariables
>;
export const GetInboundLogisticsTypeNamesDocument = gql`
  query getInboundLogisticsTypeNames {
    logistics_type {
      id
      logistics_type_name
    }
  }
`;

/**
 * __useGetInboundLogisticsTypeNamesQuery__
 *
 * To run a query within a React component, call `useGetInboundLogisticsTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundLogisticsTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundLogisticsTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundLogisticsTypeNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundLogisticsTypeNamesQuery,
    GetInboundLogisticsTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInboundLogisticsTypeNamesQuery,
    GetInboundLogisticsTypeNamesQueryVariables
  >(GetInboundLogisticsTypeNamesDocument, options);
}
export function useGetInboundLogisticsTypeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundLogisticsTypeNamesQuery,
    GetInboundLogisticsTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundLogisticsTypeNamesQuery,
    GetInboundLogisticsTypeNamesQueryVariables
  >(GetInboundLogisticsTypeNamesDocument, options);
}
export type GetInboundLogisticsTypeNamesQueryHookResult = ReturnType<
  typeof useGetInboundLogisticsTypeNamesQuery
>;
export type GetInboundLogisticsTypeNamesLazyQueryHookResult = ReturnType<
  typeof useGetInboundLogisticsTypeNamesLazyQuery
>;
export type GetInboundLogisticsTypeNamesQueryResult = Apollo.QueryResult<
  GetInboundLogisticsTypeNamesQuery,
  GetInboundLogisticsTypeNamesQueryVariables
>;
export const GetInboundOrganisationNamesDocument = gql`
  query getInboundOrganisationNames {
    organisation {
      id
      organisation_name
    }
  }
`;

/**
 * __useGetInboundOrganisationNamesQuery__
 *
 * To run a query within a React component, call `useGetInboundOrganisationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundOrganisationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundOrganisationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundOrganisationNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundOrganisationNamesQuery,
    GetInboundOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInboundOrganisationNamesQuery,
    GetInboundOrganisationNamesQueryVariables
  >(GetInboundOrganisationNamesDocument, options);
}
export function useGetInboundOrganisationNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundOrganisationNamesQuery,
    GetInboundOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundOrganisationNamesQuery,
    GetInboundOrganisationNamesQueryVariables
  >(GetInboundOrganisationNamesDocument, options);
}
export type GetInboundOrganisationNamesQueryHookResult = ReturnType<
  typeof useGetInboundOrganisationNamesQuery
>;
export type GetInboundOrganisationNamesLazyQueryHookResult = ReturnType<
  typeof useGetInboundOrganisationNamesLazyQuery
>;
export type GetInboundOrganisationNamesQueryResult = Apollo.QueryResult<
  GetInboundOrganisationNamesQuery,
  GetInboundOrganisationNamesQueryVariables
>;
export const GetInboundWarehouseNamesDocument = gql`
  query getInboundWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetInboundWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetInboundWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundWarehouseNamesQuery,
    GetInboundWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundWarehouseNamesQuery, GetInboundWarehouseNamesQueryVariables>(
    GetInboundWarehouseNamesDocument,
    options
  );
}
export function useGetInboundWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundWarehouseNamesQuery,
    GetInboundWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundWarehouseNamesQuery, GetInboundWarehouseNamesQueryVariables>(
    GetInboundWarehouseNamesDocument,
    options
  );
}
export type GetInboundWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetInboundWarehouseNamesQuery
>;
export type GetInboundWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetInboundWarehouseNamesLazyQuery
>;
export type GetInboundWarehouseNamesQueryResult = Apollo.QueryResult<
  GetInboundWarehouseNamesQuery,
  GetInboundWarehouseNamesQueryVariables
>;
export const GetInboundCaseProductsDocument = gql`
  query getInboundCaseProducts($baseProductId: Int!) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      id
      product_name
      base_product_quantity
    }
  }
`;

/**
 * __useGetInboundCaseProductsQuery__
 *
 * To run a query within a React component, call `useGetInboundCaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundCaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundCaseProductsQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetInboundCaseProductsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInboundCaseProductsQuery,
    GetInboundCaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundCaseProductsQuery, GetInboundCaseProductsQueryVariables>(
    GetInboundCaseProductsDocument,
    options
  );
}
export function useGetInboundCaseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundCaseProductsQuery,
    GetInboundCaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundCaseProductsQuery, GetInboundCaseProductsQueryVariables>(
    GetInboundCaseProductsDocument,
    options
  );
}
export type GetInboundCaseProductsQueryHookResult = ReturnType<
  typeof useGetInboundCaseProductsQuery
>;
export type GetInboundCaseProductsLazyQueryHookResult = ReturnType<
  typeof useGetInboundCaseProductsLazyQuery
>;
export type GetInboundCaseProductsQueryResult = Apollo.QueryResult<
  GetInboundCaseProductsQuery,
  GetInboundCaseProductsQueryVariables
>;
export const GetInboundManifestDataByIdDocument = gql`
  query getInboundManifestDataById($manifestId: Int!) {
    inbound_manifest(where: { id: { _eq: $manifestId } }) {
      id
      organisation {
        organisation_name
      }
      organisation_id
      warehouse {
        id
        warehouse_name
      }
      expected_arrival
      courier_service {
        id
        courier_service_name
      }
      reference
      last_updated
      inbound_manifest_status {
        inbound_manifest_status_name
        id
      }
      logistics_type {
        id
        logistics_type_name
      }
      notes
      inbound_manifest_lines {
        id
        cancelled_quantity
        inbound_manifest_line_status {
          id
          inbound_manifest_line_status_name
        }
        quantity
        received_quantity
        product {
          base_product_id
          id
          product_name
          fsc_certified
          grs_certified
          base_product_quantity
        }
        inbound_manifest_fulfilment_lines {
          id
          is_file_copy
          inbound_manifest_line_id
          last_updated
          quantity
          product_stocks {
            id
            barcode_uuid
            location {
              id
              location_name
            }
          }
          product {
            id
            product_name
          }
          notes
        }
      }
    }
  }
`;

/**
 * __useGetInboundManifestDataByIdQuery__
 *
 * To run a query within a React component, call `useGetInboundManifestDataByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundManifestDataByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundManifestDataByIdQuery({
 *   variables: {
 *      manifestId: // value for 'manifestId'
 *   },
 * });
 */
export function useGetInboundManifestDataByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInboundManifestDataByIdQuery,
    GetInboundManifestDataByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundManifestDataByIdQuery, GetInboundManifestDataByIdQueryVariables>(
    GetInboundManifestDataByIdDocument,
    options
  );
}
export function useGetInboundManifestDataByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundManifestDataByIdQuery,
    GetInboundManifestDataByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundManifestDataByIdQuery,
    GetInboundManifestDataByIdQueryVariables
  >(GetInboundManifestDataByIdDocument, options);
}
export type GetInboundManifestDataByIdQueryHookResult = ReturnType<
  typeof useGetInboundManifestDataByIdQuery
>;
export type GetInboundManifestDataByIdLazyQueryHookResult = ReturnType<
  typeof useGetInboundManifestDataByIdLazyQuery
>;
export type GetInboundManifestDataByIdQueryResult = Apollo.QueryResult<
  GetInboundManifestDataByIdQuery,
  GetInboundManifestDataByIdQueryVariables
>;
export const GetInboundStockConditionNamesDocument = gql`
  query getInboundStockConditionNames {
    stock_condition {
      id
      stock_condition_name
    }
  }
`;

/**
 * __useGetInboundStockConditionNamesQuery__
 *
 * To run a query within a React component, call `useGetInboundStockConditionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundStockConditionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundStockConditionNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundStockConditionNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundStockConditionNamesQuery,
    GetInboundStockConditionNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetInboundStockConditionNamesQuery,
    GetInboundStockConditionNamesQueryVariables
  >(GetInboundStockConditionNamesDocument, options);
}
export function useGetInboundStockConditionNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundStockConditionNamesQuery,
    GetInboundStockConditionNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInboundStockConditionNamesQuery,
    GetInboundStockConditionNamesQueryVariables
  >(GetInboundStockConditionNamesDocument, options);
}
export type GetInboundStockConditionNamesQueryHookResult = ReturnType<
  typeof useGetInboundStockConditionNamesQuery
>;
export type GetInboundStockConditionNamesLazyQueryHookResult = ReturnType<
  typeof useGetInboundStockConditionNamesLazyQuery
>;
export type GetInboundStockConditionNamesQueryResult = Apollo.QueryResult<
  GetInboundStockConditionNamesQuery,
  GetInboundStockConditionNamesQueryVariables
>;
export const GetLocationNamesThatCanHoldStockByWarehouseIdDocument = gql`
  query getLocationNamesThatCanHoldStockByWarehouseId($warehouseId: Int!) {
    location(where: { warehouse_id: { _eq: $warehouseId }, can_hold_stock: { _eq: true } }) {
      id
      can_hold_stock
      last_updated
      location_name
      location_type {
        location_type_name
        id
      }
      warehouse {
        id
        warehouse_name
      }
    }
  }
`;

/**
 * __useGetLocationNamesThatCanHoldStockByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetLocationNamesThatCanHoldStockByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationNamesThatCanHoldStockByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationNamesThatCanHoldStockByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetLocationNamesThatCanHoldStockByWarehouseIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetLocationNamesThatCanHoldStockByWarehouseIdQuery,
    GetLocationNamesThatCanHoldStockByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLocationNamesThatCanHoldStockByWarehouseIdQuery,
    GetLocationNamesThatCanHoldStockByWarehouseIdQueryVariables
  >(GetLocationNamesThatCanHoldStockByWarehouseIdDocument, options);
}
export function useGetLocationNamesThatCanHoldStockByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationNamesThatCanHoldStockByWarehouseIdQuery,
    GetLocationNamesThatCanHoldStockByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLocationNamesThatCanHoldStockByWarehouseIdQuery,
    GetLocationNamesThatCanHoldStockByWarehouseIdQueryVariables
  >(GetLocationNamesThatCanHoldStockByWarehouseIdDocument, options);
}
export type GetLocationNamesThatCanHoldStockByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetLocationNamesThatCanHoldStockByWarehouseIdQuery
>;
export type GetLocationNamesThatCanHoldStockByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetLocationNamesThatCanHoldStockByWarehouseIdLazyQuery
>;
export type GetLocationNamesThatCanHoldStockByWarehouseIdQueryResult = Apollo.QueryResult<
  GetLocationNamesThatCanHoldStockByWarehouseIdQuery,
  GetLocationNamesThatCanHoldStockByWarehouseIdQueryVariables
>;
export const GetInboundManifestByIdDocument = gql`
  query getInboundManifestById($manifestId: Int!) {
    inbound_manifest(where: { id: { _eq: $manifestId } }) {
      id
      organisation {
        organisation_name
      }
      organisation_id
      warehouse {
        id
        warehouse_name
      }
      expected_arrival
      courier_service {
        id
        courier_service_name
      }
      reference
      last_updated
      inbound_manifest_status {
        inbound_manifest_status_name
        id
      }
      logistics_type {
        id
        logistics_type_name
      }
      notes
      inbound_manifest_lines {
        id
        cancelled_quantity
        inbound_manifest_line_status {
          id
          inbound_manifest_line_status_name
        }
        quantity
        received_quantity
        product {
          base_product_id
          id
          product_name
          fsc_certified
          grs_certified
        }
        inbound_manifest_fulfilment_lines {
          id
          is_file_copy
          inbound_manifest_line_id
          last_updated
          quantity
          product_stocks {
            id
            barcode_uuid
            location {
              id
              location_name
            }
          }
          product {
            id
            product_name
          }
        }
      }
    }
  }
`;

/**
 * __useGetInboundManifestByIdQuery__
 *
 * To run a query within a React component, call `useGetInboundManifestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundManifestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundManifestByIdQuery({
 *   variables: {
 *      manifestId: // value for 'manifestId'
 *   },
 * });
 */
export function useGetInboundManifestByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInboundManifestByIdQuery,
    GetInboundManifestByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundManifestByIdQuery, GetInboundManifestByIdQueryVariables>(
    GetInboundManifestByIdDocument,
    options
  );
}
export function useGetInboundManifestByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundManifestByIdQuery,
    GetInboundManifestByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundManifestByIdQuery, GetInboundManifestByIdQueryVariables>(
    GetInboundManifestByIdDocument,
    options
  );
}
export type GetInboundManifestByIdQueryHookResult = ReturnType<
  typeof useGetInboundManifestByIdQuery
>;
export type GetInboundManifestByIdLazyQueryHookResult = ReturnType<
  typeof useGetInboundManifestByIdLazyQuery
>;
export type GetInboundManifestByIdQueryResult = Apollo.QueryResult<
  GetInboundManifestByIdQuery,
  GetInboundManifestByIdQueryVariables
>;
export const GetInboundManifestsAscDocument = gql`
  query getInboundManifestsAsc {
    inbound_manifest(order_by: { expected_arrival: asc }) {
      id
      organisation {
        organisation_name
        id
      }
      reference
      courier_service {
        id
        courier_service_name
      }
      expected_arrival
      last_updated
      warehouse {
        id
        warehouse_name
      }
      inbound_manifest_status {
        inbound_manifest_status_name
        id
      }
      inbound_manifest_lines_aggregate {
        aggregate {
          count
        }
      }
    }
  }
`;

/**
 * __useGetInboundManifestsAscQuery__
 *
 * To run a query within a React component, call `useGetInboundManifestsAscQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInboundManifestsAscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInboundManifestsAscQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInboundManifestsAscQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInboundManifestsAscQuery,
    GetInboundManifestsAscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInboundManifestsAscQuery, GetInboundManifestsAscQueryVariables>(
    GetInboundManifestsAscDocument,
    options
  );
}
export function useGetInboundManifestsAscLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInboundManifestsAscQuery,
    GetInboundManifestsAscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInboundManifestsAscQuery, GetInboundManifestsAscQueryVariables>(
    GetInboundManifestsAscDocument,
    options
  );
}
export type GetInboundManifestsAscQueryHookResult = ReturnType<
  typeof useGetInboundManifestsAscQuery
>;
export type GetInboundManifestsAscLazyQueryHookResult = ReturnType<
  typeof useGetInboundManifestsAscLazyQuery
>;
export type GetInboundManifestsAscQueryResult = Apollo.QueryResult<
  GetInboundManifestsAscQuery,
  GetInboundManifestsAscQueryVariables
>;
export const GetAllLocationTypeNamesDocument = gql`
  query getAllLocationTypeNames {
    location_type {
      id
      location_type_name
    }
  }
`;

/**
 * __useGetAllLocationTypeNamesQuery__
 *
 * To run a query within a React component, call `useGetAllLocationTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllLocationTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllLocationTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllLocationTypeNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllLocationTypeNamesQuery,
    GetAllLocationTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllLocationTypeNamesQuery, GetAllLocationTypeNamesQueryVariables>(
    GetAllLocationTypeNamesDocument,
    options
  );
}
export function useGetAllLocationTypeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllLocationTypeNamesQuery,
    GetAllLocationTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllLocationTypeNamesQuery, GetAllLocationTypeNamesQueryVariables>(
    GetAllLocationTypeNamesDocument,
    options
  );
}
export type GetAllLocationTypeNamesQueryHookResult = ReturnType<
  typeof useGetAllLocationTypeNamesQuery
>;
export type GetAllLocationTypeNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllLocationTypeNamesLazyQuery
>;
export type GetAllLocationTypeNamesQueryResult = Apollo.QueryResult<
  GetAllLocationTypeNamesQuery,
  GetAllLocationTypeNamesQueryVariables
>;
export const GetAllWarehouseNamesDocument = gql`
  query getAllWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetAllWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetAllWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllWarehouseNamesQuery,
    GetAllWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllWarehouseNamesQuery, GetAllWarehouseNamesQueryVariables>(
    GetAllWarehouseNamesDocument,
    options
  );
}
export function useGetAllWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllWarehouseNamesQuery,
    GetAllWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllWarehouseNamesQuery, GetAllWarehouseNamesQueryVariables>(
    GetAllWarehouseNamesDocument,
    options
  );
}
export type GetAllWarehouseNamesQueryHookResult = ReturnType<typeof useGetAllWarehouseNamesQuery>;
export type GetAllWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllWarehouseNamesLazyQuery
>;
export type GetAllWarehouseNamesQueryResult = Apollo.QueryResult<
  GetAllWarehouseNamesQuery,
  GetAllWarehouseNamesQueryVariables
>;
export const GetLocationByWarehouseAndNameDocument = gql`
  query getLocationByWarehouseAndName($locationName: String, $warehouseId: Int) {
    location(
      where: { warehouse_id: { _eq: $warehouseId }, location_name: { _eq: $locationName } }
    ) {
      location_name
      id
    }
  }
`;

/**
 * __useGetLocationByWarehouseAndNameQuery__
 *
 * To run a query within a React component, call `useGetLocationByWarehouseAndNameQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByWarehouseAndNameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByWarehouseAndNameQuery({
 *   variables: {
 *      locationName: // value for 'locationName'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetLocationByWarehouseAndNameQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationByWarehouseAndNameQuery,
    GetLocationByWarehouseAndNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetLocationByWarehouseAndNameQuery,
    GetLocationByWarehouseAndNameQueryVariables
  >(GetLocationByWarehouseAndNameDocument, options);
}
export function useGetLocationByWarehouseAndNameLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationByWarehouseAndNameQuery,
    GetLocationByWarehouseAndNameQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetLocationByWarehouseAndNameQuery,
    GetLocationByWarehouseAndNameQueryVariables
  >(GetLocationByWarehouseAndNameDocument, options);
}
export type GetLocationByWarehouseAndNameQueryHookResult = ReturnType<
  typeof useGetLocationByWarehouseAndNameQuery
>;
export type GetLocationByWarehouseAndNameLazyQueryHookResult = ReturnType<
  typeof useGetLocationByWarehouseAndNameLazyQuery
>;
export type GetLocationByWarehouseAndNameQueryResult = Apollo.QueryResult<
  GetLocationByWarehouseAndNameQuery,
  GetLocationByWarehouseAndNameQueryVariables
>;
export const GetParentLocationNamesByWarehouseIdDocument = gql`
  query getParentLocationNamesByWarehouseId($warehouseId: Int!) {
    location(where: { warehouse_id: { _eq: $warehouseId }, location_type_id: { _eq: 0 } }) {
      id
      location_name
    }
  }
`;

/**
 * __useGetParentLocationNamesByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetParentLocationNamesByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParentLocationNamesByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParentLocationNamesByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetParentLocationNamesByWarehouseIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetParentLocationNamesByWarehouseIdQuery,
    GetParentLocationNamesByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetParentLocationNamesByWarehouseIdQuery,
    GetParentLocationNamesByWarehouseIdQueryVariables
  >(GetParentLocationNamesByWarehouseIdDocument, options);
}
export function useGetParentLocationNamesByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetParentLocationNamesByWarehouseIdQuery,
    GetParentLocationNamesByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetParentLocationNamesByWarehouseIdQuery,
    GetParentLocationNamesByWarehouseIdQueryVariables
  >(GetParentLocationNamesByWarehouseIdDocument, options);
}
export type GetParentLocationNamesByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetParentLocationNamesByWarehouseIdQuery
>;
export type GetParentLocationNamesByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetParentLocationNamesByWarehouseIdLazyQuery
>;
export type GetParentLocationNamesByWarehouseIdQueryResult = Apollo.QueryResult<
  GetParentLocationNamesByWarehouseIdQuery,
  GetParentLocationNamesByWarehouseIdQueryVariables
>;
export const GetLocationByIdDocument = gql`
  query getLocationById($locationId: Int) {
    location(where: { id: { _eq: $locationId } }) {
      id
      location_name
      location_type {
        id
        location_type_name
      }
      warehouse {
        id
        warehouse_name
      }
      product_stocks {
        id
        product {
          product_name
          id
        }
        quantity
        stock_condition {
          id
          stock_condition_name
        }
      }
    }
  }
`;

/**
 * __useGetLocationByIdQuery__
 *
 * To run a query within a React component, call `useGetLocationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationByIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetLocationByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(
    GetLocationByIdDocument,
    options
  );
}
export function useGetLocationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetLocationByIdQuery, GetLocationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationByIdQuery, GetLocationByIdQueryVariables>(
    GetLocationByIdDocument,
    options
  );
}
export type GetLocationByIdQueryHookResult = ReturnType<typeof useGetLocationByIdQuery>;
export type GetLocationByIdLazyQueryHookResult = ReturnType<typeof useGetLocationByIdLazyQuery>;
export type GetLocationByIdQueryResult = Apollo.QueryResult<
  GetLocationByIdQuery,
  GetLocationByIdQueryVariables
>;
export const GetLocationsWithDataDocument = gql`
  query getLocationsWithData {
    location {
      id
      location_name
      location_type {
        location_type_name
      }
      warehouse {
        warehouse_name
        id
      }
      can_hold_stock
    }
  }
`;

/**
 * __useGetLocationsWithDataQuery__
 *
 * To run a query within a React component, call `useGetLocationsWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetLocationsWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetLocationsWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetLocationsWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetLocationsWithDataQuery,
    GetLocationsWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetLocationsWithDataQuery, GetLocationsWithDataQueryVariables>(
    GetLocationsWithDataDocument,
    options
  );
}
export function useGetLocationsWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetLocationsWithDataQuery,
    GetLocationsWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetLocationsWithDataQuery, GetLocationsWithDataQueryVariables>(
    GetLocationsWithDataDocument,
    options
  );
}
export type GetLocationsWithDataQueryHookResult = ReturnType<typeof useGetLocationsWithDataQuery>;
export type GetLocationsWithDataLazyQueryHookResult = ReturnType<
  typeof useGetLocationsWithDataLazyQuery
>;
export type GetLocationsWithDataQueryResult = Apollo.QueryResult<
  GetLocationsWithDataQuery,
  GetLocationsWithDataQueryVariables
>;
export const GetOrganisationByIdDocument = gql`
  query getOrganisationById($organisationId: Int!) {
    organisation(where: { id: { _eq: $organisationId } }) {
      id
      product_stocks {
        id
        quantity
        stock_condition {
          id
          stock_condition_name
        }
        stock_status {
          id
          stock_status_name
        }
        location {
          id
          location_name
        }
        product {
          product_name
        }
      }
      inbound_manifests {
        expected_arrival
        inbound_manifest_status {
          id
          inbound_manifest_status_name
        }
        warehouse {
          id
          warehouse_name
        }
        id
        inbound_manifest_lines_aggregate {
          aggregate {
            count(columns: id)
          }
        }
      }
      outbound_manifests {
        expected_dispatch
        id
        outbound_manifest_status {
          outbound_manifest_status_name
          id
        }
        warehouse {
          id
          warehouse_name
        }
        outbound_manifest_lines_aggregate {
          aggregate {
            count(columns: id)
          }
        }
      }
      organisation_name
    }
  }
`;

/**
 * __useGetOrganisationByIdQuery__
 *
 * To run a query within a React component, call `useGetOrganisationByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationByIdQuery({
 *   variables: {
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetOrganisationByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>(
    GetOrganisationByIdDocument,
    options
  );
}
export function useGetOrganisationByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationByIdQuery,
    GetOrganisationByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganisationByIdQuery, GetOrganisationByIdQueryVariables>(
    GetOrganisationByIdDocument,
    options
  );
}
export type GetOrganisationByIdQueryHookResult = ReturnType<typeof useGetOrganisationByIdQuery>;
export type GetOrganisationByIdLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationByIdLazyQuery
>;
export type GetOrganisationByIdQueryResult = Apollo.QueryResult<
  GetOrganisationByIdQuery,
  GetOrganisationByIdQueryVariables
>;
export const GetOrganisationsWithDataDocument = gql`
  query getOrganisationsWithData {
    organisation {
      id
      organisation_name
    }
  }
`;

/**
 * __useGetOrganisationsWithDataQuery__
 *
 * To run a query within a React component, call `useGetOrganisationsWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationsWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationsWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationsWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOrganisationsWithDataQuery,
    GetOrganisationsWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganisationsWithDataQuery, GetOrganisationsWithDataQueryVariables>(
    GetOrganisationsWithDataDocument,
    options
  );
}
export function useGetOrganisationsWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOrganisationsWithDataQuery,
    GetOrganisationsWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganisationsWithDataQuery, GetOrganisationsWithDataQueryVariables>(
    GetOrganisationsWithDataDocument,
    options
  );
}
export type GetOrganisationsWithDataQueryHookResult = ReturnType<
  typeof useGetOrganisationsWithDataQuery
>;
export type GetOrganisationsWithDataLazyQueryHookResult = ReturnType<
  typeof useGetOrganisationsWithDataLazyQuery
>;
export type GetOrganisationsWithDataQueryResult = Apollo.QueryResult<
  GetOrganisationsWithDataQuery,
  GetOrganisationsWithDataQueryVariables
>;
export const GetOutboundBaseProductsDocument = gql`
  query getOutboundBaseProducts {
    base_product {
      id
      product_name
      fsc_certified
      grs_certified
    }
  }
`;

/**
 * __useGetOutboundBaseProductsQuery__
 *
 * To run a query within a React component, call `useGetOutboundBaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundBaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundBaseProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutboundBaseProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOutboundBaseProductsQuery,
    GetOutboundBaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOutboundBaseProductsQuery, GetOutboundBaseProductsQueryVariables>(
    GetOutboundBaseProductsDocument,
    options
  );
}
export function useGetOutboundBaseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundBaseProductsQuery,
    GetOutboundBaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOutboundBaseProductsQuery, GetOutboundBaseProductsQueryVariables>(
    GetOutboundBaseProductsDocument,
    options
  );
}
export type GetOutboundBaseProductsQueryHookResult = ReturnType<
  typeof useGetOutboundBaseProductsQuery
>;
export type GetOutboundBaseProductsLazyQueryHookResult = ReturnType<
  typeof useGetOutboundBaseProductsLazyQuery
>;
export type GetOutboundBaseProductsQueryResult = Apollo.QueryResult<
  GetOutboundBaseProductsQuery,
  GetOutboundBaseProductsQueryVariables
>;
export const GetOutboundCaseProductsByBaseProductIdDocument = gql`
  query getOutboundCaseProductsByBaseProductId($baseProductId: Int!) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      id
      product_name
      base_product_quantity
    }
  }
`;

/**
 * __useGetOutboundCaseProductsByBaseProductIdQuery__
 *
 * To run a query within a React component, call `useGetOutboundCaseProductsByBaseProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundCaseProductsByBaseProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundCaseProductsByBaseProductIdQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetOutboundCaseProductsByBaseProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOutboundCaseProductsByBaseProductIdQuery,
    GetOutboundCaseProductsByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOutboundCaseProductsByBaseProductIdQuery,
    GetOutboundCaseProductsByBaseProductIdQueryVariables
  >(GetOutboundCaseProductsByBaseProductIdDocument, options);
}
export function useGetOutboundCaseProductsByBaseProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundCaseProductsByBaseProductIdQuery,
    GetOutboundCaseProductsByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOutboundCaseProductsByBaseProductIdQuery,
    GetOutboundCaseProductsByBaseProductIdQueryVariables
  >(GetOutboundCaseProductsByBaseProductIdDocument, options);
}
export type GetOutboundCaseProductsByBaseProductIdQueryHookResult = ReturnType<
  typeof useGetOutboundCaseProductsByBaseProductIdQuery
>;
export type GetOutboundCaseProductsByBaseProductIdLazyQueryHookResult = ReturnType<
  typeof useGetOutboundCaseProductsByBaseProductIdLazyQuery
>;
export type GetOutboundCaseProductsByBaseProductIdQueryResult = Apollo.QueryResult<
  GetOutboundCaseProductsByBaseProductIdQuery,
  GetOutboundCaseProductsByBaseProductIdQueryVariables
>;
export const GetOutboundCourierServiceNamesDocument = gql`
  query getOutboundCourierServiceNames {
    courier_service {
      id
      courier_service_name
    }
  }
`;

/**
 * __useGetOutboundCourierServiceNamesQuery__
 *
 * To run a query within a React component, call `useGetOutboundCourierServiceNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundCourierServiceNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundCourierServiceNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutboundCourierServiceNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOutboundCourierServiceNamesQuery,
    GetOutboundCourierServiceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOutboundCourierServiceNamesQuery,
    GetOutboundCourierServiceNamesQueryVariables
  >(GetOutboundCourierServiceNamesDocument, options);
}
export function useGetOutboundCourierServiceNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundCourierServiceNamesQuery,
    GetOutboundCourierServiceNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOutboundCourierServiceNamesQuery,
    GetOutboundCourierServiceNamesQueryVariables
  >(GetOutboundCourierServiceNamesDocument, options);
}
export type GetOutboundCourierServiceNamesQueryHookResult = ReturnType<
  typeof useGetOutboundCourierServiceNamesQuery
>;
export type GetOutboundCourierServiceNamesLazyQueryHookResult = ReturnType<
  typeof useGetOutboundCourierServiceNamesLazyQuery
>;
export type GetOutboundCourierServiceNamesQueryResult = Apollo.QueryResult<
  GetOutboundCourierServiceNamesQuery,
  GetOutboundCourierServiceNamesQueryVariables
>;
export const GetOutboundLogisticsTypeNamesDocument = gql`
  query getOutboundLogisticsTypeNames {
    logistics_type {
      id
      logistics_type_name
    }
  }
`;

/**
 * __useGetOutboundLogisticsTypeNamesQuery__
 *
 * To run a query within a React component, call `useGetOutboundLogisticsTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundLogisticsTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundLogisticsTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutboundLogisticsTypeNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOutboundLogisticsTypeNamesQuery,
    GetOutboundLogisticsTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOutboundLogisticsTypeNamesQuery,
    GetOutboundLogisticsTypeNamesQueryVariables
  >(GetOutboundLogisticsTypeNamesDocument, options);
}
export function useGetOutboundLogisticsTypeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundLogisticsTypeNamesQuery,
    GetOutboundLogisticsTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOutboundLogisticsTypeNamesQuery,
    GetOutboundLogisticsTypeNamesQueryVariables
  >(GetOutboundLogisticsTypeNamesDocument, options);
}
export type GetOutboundLogisticsTypeNamesQueryHookResult = ReturnType<
  typeof useGetOutboundLogisticsTypeNamesQuery
>;
export type GetOutboundLogisticsTypeNamesLazyQueryHookResult = ReturnType<
  typeof useGetOutboundLogisticsTypeNamesLazyQuery
>;
export type GetOutboundLogisticsTypeNamesQueryResult = Apollo.QueryResult<
  GetOutboundLogisticsTypeNamesQuery,
  GetOutboundLogisticsTypeNamesQueryVariables
>;
export const GetOutboundOrganisationNamesDocument = gql`
  query getOutboundOrganisationNames {
    organisation {
      id
      organisation_name
    }
  }
`;

/**
 * __useGetOutboundOrganisationNamesQuery__
 *
 * To run a query within a React component, call `useGetOutboundOrganisationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundOrganisationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundOrganisationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutboundOrganisationNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOutboundOrganisationNamesQuery,
    GetOutboundOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetOutboundOrganisationNamesQuery,
    GetOutboundOrganisationNamesQueryVariables
  >(GetOutboundOrganisationNamesDocument, options);
}
export function useGetOutboundOrganisationNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundOrganisationNamesQuery,
    GetOutboundOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOutboundOrganisationNamesQuery,
    GetOutboundOrganisationNamesQueryVariables
  >(GetOutboundOrganisationNamesDocument, options);
}
export type GetOutboundOrganisationNamesQueryHookResult = ReturnType<
  typeof useGetOutboundOrganisationNamesQuery
>;
export type GetOutboundOrganisationNamesLazyQueryHookResult = ReturnType<
  typeof useGetOutboundOrganisationNamesLazyQuery
>;
export type GetOutboundOrganisationNamesQueryResult = Apollo.QueryResult<
  GetOutboundOrganisationNamesQuery,
  GetOutboundOrganisationNamesQueryVariables
>;
export const GetOutboundWarehouseNamesDocument = gql`
  query getOutboundWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetOutboundWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetOutboundWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutboundWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOutboundWarehouseNamesQuery,
    GetOutboundWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOutboundWarehouseNamesQuery, GetOutboundWarehouseNamesQueryVariables>(
    GetOutboundWarehouseNamesDocument,
    options
  );
}
export function useGetOutboundWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundWarehouseNamesQuery,
    GetOutboundWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetOutboundWarehouseNamesQuery,
    GetOutboundWarehouseNamesQueryVariables
  >(GetOutboundWarehouseNamesDocument, options);
}
export type GetOutboundWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetOutboundWarehouseNamesQuery
>;
export type GetOutboundWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetOutboundWarehouseNamesLazyQuery
>;
export type GetOutboundWarehouseNamesQueryResult = Apollo.QueryResult<
  GetOutboundWarehouseNamesQuery,
  GetOutboundWarehouseNamesQueryVariables
>;
export const GetAvailableAllocatedProductStockByBaseProductIdDocument = gql`
  query getAvailableAllocatedProductStockByBaseProductId(
    $warehouseId: Int!
    $organisationId: Int!
    $baseProductId: Int!
  ) {
    product_stock(
      where: {
        location: { warehouse_id: { _eq: $warehouseId } }
        product: { base_product_id: { _eq: $baseProductId } }
        quantity: { _gte: 1 }
        _or: [
          { organisation: { organisation_name: { _eq: "sourceful" } } }
          { allocated_to_organisation_id: { _eq: $organisationId } }
        ]
      }
    ) {
      location {
        location_name
        id
        warehouse {
          warehouse_name
          id
        }
      }
      organisation {
        organisation_name
        id
      }
      quantity
      stock_status {
        id
        stock_status_name
      }
      id
      organisation {
        organisation_name
        id
      }
      product {
        id
        product_name
        base_product_quantity
        base_product_id
      }
      stock_condition {
        id
        stock_condition_name
      }
    }
  }
`;

/**
 * __useGetAvailableAllocatedProductStockByBaseProductIdQuery__
 *
 * To run a query within a React component, call `useGetAvailableAllocatedProductStockByBaseProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAvailableAllocatedProductStockByBaseProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAvailableAllocatedProductStockByBaseProductIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      organisationId: // value for 'organisationId'
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetAvailableAllocatedProductStockByBaseProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetAvailableAllocatedProductStockByBaseProductIdQuery,
    GetAvailableAllocatedProductStockByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetAvailableAllocatedProductStockByBaseProductIdQuery,
    GetAvailableAllocatedProductStockByBaseProductIdQueryVariables
  >(GetAvailableAllocatedProductStockByBaseProductIdDocument, options);
}
export function useGetAvailableAllocatedProductStockByBaseProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAvailableAllocatedProductStockByBaseProductIdQuery,
    GetAvailableAllocatedProductStockByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAvailableAllocatedProductStockByBaseProductIdQuery,
    GetAvailableAllocatedProductStockByBaseProductIdQueryVariables
  >(GetAvailableAllocatedProductStockByBaseProductIdDocument, options);
}
export type GetAvailableAllocatedProductStockByBaseProductIdQueryHookResult = ReturnType<
  typeof useGetAvailableAllocatedProductStockByBaseProductIdQuery
>;
export type GetAvailableAllocatedProductStockByBaseProductIdLazyQueryHookResult = ReturnType<
  typeof useGetAvailableAllocatedProductStockByBaseProductIdLazyQuery
>;
export type GetAvailableAllocatedProductStockByBaseProductIdQueryResult = Apollo.QueryResult<
  GetAvailableAllocatedProductStockByBaseProductIdQuery,
  GetAvailableAllocatedProductStockByBaseProductIdQueryVariables
>;
export const GetOutboundManifestByIdDocument = gql`
  query getOutboundManifestById($manifestId: Int!) {
    outbound_manifest(where: { id: { _eq: $manifestId } }) {
      id
      organisation {
        organisation_name
      }
      organisation_id
      warehouse {
        id
        warehouse_name
      }
      courier_service {
        id
        courier_service_name
        courier_id
      }
      reference
      expected_dispatch
      external_order_id
      last_updated
      outbound_manifest_status {
        outbound_manifest_status_name
        id
      }
      logistics_type {
        id
        logistics_type_name
      }
      notes
      outbound_manifest_lines(order_by: { id: asc }) {
        id
        cancelled_quantity
        external_order_line_id
        outbound_manifest_line_status {
          id
          outbound_manifest_line_status_name
        }
        quantity
        dispatched_quantity
        product {
          base_product_id
          id
          product_name
          fsc_certified
          grs_certified
        }
        outbound_manifest_fulfilment_lines {
          id
          outbound_manifest_line_id
          last_updated
          quantity
          product {
            id
            product_name
            product_stocks {
              location {
                id
                location_name
              }
              barcode_uuid
              id
            }
          }
          outbound_manifest_return_lines {
            returned_quantity
            id
          }
        }
      }
      courier_service {
        courier_id
        id
        courier_service_name
      }
    }
  }
`;

/**
 * __useGetOutboundManifestByIdQuery__
 *
 * To run a query within a React component, call `useGetOutboundManifestByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundManifestByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundManifestByIdQuery({
 *   variables: {
 *      manifestId: // value for 'manifestId'
 *   },
 * });
 */
export function useGetOutboundManifestByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetOutboundManifestByIdQuery,
    GetOutboundManifestByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOutboundManifestByIdQuery, GetOutboundManifestByIdQueryVariables>(
    GetOutboundManifestByIdDocument,
    options
  );
}
export function useGetOutboundManifestByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundManifestByIdQuery,
    GetOutboundManifestByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOutboundManifestByIdQuery, GetOutboundManifestByIdQueryVariables>(
    GetOutboundManifestByIdDocument,
    options
  );
}
export type GetOutboundManifestByIdQueryHookResult = ReturnType<
  typeof useGetOutboundManifestByIdQuery
>;
export type GetOutboundManifestByIdLazyQueryHookResult = ReturnType<
  typeof useGetOutboundManifestByIdLazyQuery
>;
export type GetOutboundManifestByIdQueryResult = Apollo.QueryResult<
  GetOutboundManifestByIdQuery,
  GetOutboundManifestByIdQueryVariables
>;
export const GetOutboundManifestsAscDocument = gql`
  query getOutboundManifestsAsc {
    outbound_manifest(order_by: { expected_dispatch: asc }) {
      id
      organisation {
        organisation_name
        id
      }
      external_order_id
      last_updated
      outbound_manifest_status {
        outbound_manifest_status_name
        id
      }
      reference
      courier_service {
        courier_service_name
        id
      }
      warehouse {
        warehouse_name
        id
      }
      outbound_manifest_lines_aggregate {
        aggregate {
          count
        }
      }
      expected_dispatch
    }
  }
`;

/**
 * __useGetOutboundManifestsAscQuery__
 *
 * To run a query within a React component, call `useGetOutboundManifestsAscQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOutboundManifestsAscQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOutboundManifestsAscQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOutboundManifestsAscQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetOutboundManifestsAscQuery,
    GetOutboundManifestsAscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOutboundManifestsAscQuery, GetOutboundManifestsAscQueryVariables>(
    GetOutboundManifestsAscDocument,
    options
  );
}
export function useGetOutboundManifestsAscLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetOutboundManifestsAscQuery,
    GetOutboundManifestsAscQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOutboundManifestsAscQuery, GetOutboundManifestsAscQueryVariables>(
    GetOutboundManifestsAscDocument,
    options
  );
}
export type GetOutboundManifestsAscQueryHookResult = ReturnType<
  typeof useGetOutboundManifestsAscQuery
>;
export type GetOutboundManifestsAscLazyQueryHookResult = ReturnType<
  typeof useGetOutboundManifestsAscLazyQuery
>;
export type GetOutboundManifestsAscQueryResult = Apollo.QueryResult<
  GetOutboundManifestsAscQuery,
  GetOutboundManifestsAscQueryVariables
>;
export const GetPerpetualInventoryDocument = gql`
  query getPerpetualInventory {
    product_stock(order_by: { last_updated: asc }, where: { quantity: { _gt: 0 } }) {
      id
      quantity
      stock_status {
        id
        stock_status_name
      }
      stock_condition {
        id
        stock_condition_name
      }
      product {
        id
        product_name
      }
      last_updated
      location {
        id
        location_name
      }
      stock_check_line_fulfilment {
        id
      }
    }
  }
`;

/**
 * __useGetPerpetualInventoryQuery__
 *
 * To run a query within a React component, call `useGetPerpetualInventoryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPerpetualInventoryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPerpetualInventoryQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPerpetualInventoryQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetPerpetualInventoryQuery,
    GetPerpetualInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPerpetualInventoryQuery, GetPerpetualInventoryQueryVariables>(
    GetPerpetualInventoryDocument,
    options
  );
}
export function useGetPerpetualInventoryLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetPerpetualInventoryQuery,
    GetPerpetualInventoryQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPerpetualInventoryQuery, GetPerpetualInventoryQueryVariables>(
    GetPerpetualInventoryDocument,
    options
  );
}
export type GetPerpetualInventoryQueryHookResult = ReturnType<typeof useGetPerpetualInventoryQuery>;
export type GetPerpetualInventoryLazyQueryHookResult = ReturnType<
  typeof useGetPerpetualInventoryLazyQuery
>;
export type GetPerpetualInventoryQueryResult = Apollo.QueryResult<
  GetPerpetualInventoryQuery,
  GetPerpetualInventoryQueryVariables
>;
export const GetProductStockByIdDocument = gql`
  query getProductStockById($productStockId: Int!) {
    product_stock(where: { id: { _eq: $productStockId } }) {
      id
      last_updated
      organisation {
        organisation_name
        id
      }
      location {
        id
        location_name
        location_type {
          location_type_name
          id
        }
        warehouse {
          id
          warehouse_name
        }
      }
      product {
        product_name
        id
      }
      quantity
      stock_condition {
        id
        stock_condition_name
      }
      stock_status {
        id
        stock_status_name
      }
      source_inbound_manifest_fulfilment_line_id
      inbound_manifest_fulfilment_line {
        inbound_manifest_line {
          inbound_manifest {
            id
          }
        }
        last_updated
      }
    }
  }
`;

/**
 * __useGetProductStockByIdQuery__
 *
 * To run a query within a React component, call `useGetProductStockByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStockByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStockByIdQuery({
 *   variables: {
 *      productStockId: // value for 'productStockId'
 *   },
 * });
 */
export function useGetProductStockByIdQuery(
  baseOptions: Apollo.QueryHookOptions<GetProductStockByIdQuery, GetProductStockByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductStockByIdQuery, GetProductStockByIdQueryVariables>(
    GetProductStockByIdDocument,
    options
  );
}
export function useGetProductStockByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductStockByIdQuery,
    GetProductStockByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductStockByIdQuery, GetProductStockByIdQueryVariables>(
    GetProductStockByIdDocument,
    options
  );
}
export type GetProductStockByIdQueryHookResult = ReturnType<typeof useGetProductStockByIdQuery>;
export type GetProductStockByIdLazyQueryHookResult = ReturnType<
  typeof useGetProductStockByIdLazyQuery
>;
export type GetProductStockByIdQueryResult = Apollo.QueryResult<
  GetProductStockByIdQuery,
  GetProductStockByIdQueryVariables
>;
export const GetProductStocksDocument = gql`
  query getProductStocks {
    product_stock {
      id
      barcode_uuid
      quantity
      stock_condition {
        stock_condition_name
      }
      stock_status {
        stock_status_name
      }
      product {
        product_name
        external_product_id
      }
      location {
        location_type {
          location_type_name
        }
      }
    }
  }
`;

/**
 * __useGetProductStocksQuery__
 *
 * To run a query within a React component, call `useGetProductStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductStocksQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductStocksQuery, GetProductStocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductStocksQuery, GetProductStocksQueryVariables>(
    GetProductStocksDocument,
    options
  );
}
export function useGetProductStocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductStocksQuery, GetProductStocksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductStocksQuery, GetProductStocksQueryVariables>(
    GetProductStocksDocument,
    options
  );
}
export type GetProductStocksQueryHookResult = ReturnType<typeof useGetProductStocksQuery>;
export type GetProductStocksLazyQueryHookResult = ReturnType<typeof useGetProductStocksLazyQuery>;
export type GetProductStocksQueryResult = Apollo.QueryResult<
  GetProductStocksQuery,
  GetProductStocksQueryVariables
>;
export const GetBaseProductNamesDocument = gql`
  query getBaseProductNames {
    base_product {
      product_name
    }
  }
`;

/**
 * __useGetBaseProductNamesQuery__
 *
 * To run a query within a React component, call `useGetBaseProductNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProductNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBaseProductNamesQuery, GetBaseProductNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaseProductNamesQuery, GetBaseProductNamesQueryVariables>(
    GetBaseProductNamesDocument,
    options
  );
}
export function useGetBaseProductNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseProductNamesQuery,
    GetBaseProductNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaseProductNamesQuery, GetBaseProductNamesQueryVariables>(
    GetBaseProductNamesDocument,
    options
  );
}
export type GetBaseProductNamesQueryHookResult = ReturnType<typeof useGetBaseProductNamesQuery>;
export type GetBaseProductNamesLazyQueryHookResult = ReturnType<
  typeof useGetBaseProductNamesLazyQuery
>;
export type GetBaseProductNamesQueryResult = Apollo.QueryResult<
  GetBaseProductNamesQuery,
  GetBaseProductNamesQueryVariables
>;
export const GetBaseProductDataDocument = gql`
  query getBaseProductData {
    base_product {
      id
      product_name
      fsc_certified
      grs_certified
    }
  }
`;

/**
 * __useGetBaseProductDataQuery__
 *
 * To run a query within a React component, call `useGetBaseProductDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProductDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBaseProductDataQuery, GetBaseProductDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaseProductDataQuery, GetBaseProductDataQueryVariables>(
    GetBaseProductDataDocument,
    options
  );
}
export function useGetBaseProductDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseProductDataQuery,
    GetBaseProductDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaseProductDataQuery, GetBaseProductDataQueryVariables>(
    GetBaseProductDataDocument,
    options
  );
}
export type GetBaseProductDataQueryHookResult = ReturnType<typeof useGetBaseProductDataQuery>;
export type GetBaseProductDataLazyQueryHookResult = ReturnType<
  typeof useGetBaseProductDataLazyQuery
>;
export type GetBaseProductDataQueryResult = Apollo.QueryResult<
  GetBaseProductDataQuery,
  GetBaseProductDataQueryVariables
>;
export const GetUnderlyingCaseProductDocument = gql`
  query getUnderlyingCaseProduct($baseProductId: Int!) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      id
      product_name
      base_product_quantity
    }
  }
`;

/**
 * __useGetUnderlyingCaseProductQuery__
 *
 * To run a query within a React component, call `useGetUnderlyingCaseProductQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUnderlyingCaseProductQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUnderlyingCaseProductQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetUnderlyingCaseProductQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetUnderlyingCaseProductQuery,
    GetUnderlyingCaseProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetUnderlyingCaseProductQuery, GetUnderlyingCaseProductQueryVariables>(
    GetUnderlyingCaseProductDocument,
    options
  );
}
export function useGetUnderlyingCaseProductLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetUnderlyingCaseProductQuery,
    GetUnderlyingCaseProductQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetUnderlyingCaseProductQuery, GetUnderlyingCaseProductQueryVariables>(
    GetUnderlyingCaseProductDocument,
    options
  );
}
export type GetUnderlyingCaseProductQueryHookResult = ReturnType<
  typeof useGetUnderlyingCaseProductQuery
>;
export type GetUnderlyingCaseProductLazyQueryHookResult = ReturnType<
  typeof useGetUnderlyingCaseProductLazyQuery
>;
export type GetUnderlyingCaseProductQueryResult = Apollo.QueryResult<
  GetUnderlyingCaseProductQuery,
  GetUnderlyingCaseProductQueryVariables
>;
export const GetBaseProductAndStockDocument = gql`
  query getBaseProductAndStock($baseProductId: Int!) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      id
      product_name
      fsc_certified
      grs_certified
      length_mm
      weight_grams
      width_mm
      height_mm
      base_product_id
      base_product_quantity
      case_product_quantity
      product_stocks(where: { quantity: { _neq: 0 } }, order_by: { product_id: asc }) {
        id
        location {
          id
          location_name
        }
        quantity
        stock_status {
          stock_status_name
        }
        stock_condition {
          stock_condition_name
        }
        organisation {
          id
          organisation_name
        }
      }
    }
  }
`;

/**
 * __useGetBaseProductAndStockQuery__
 *
 * To run a query within a React component, call `useGetBaseProductAndStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductAndStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductAndStockQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetBaseProductAndStockQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetBaseProductAndStockQuery,
    GetBaseProductAndStockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaseProductAndStockQuery, GetBaseProductAndStockQueryVariables>(
    GetBaseProductAndStockDocument,
    options
  );
}
export function useGetBaseProductAndStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseProductAndStockQuery,
    GetBaseProductAndStockQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaseProductAndStockQuery, GetBaseProductAndStockQueryVariables>(
    GetBaseProductAndStockDocument,
    options
  );
}
export type GetBaseProductAndStockQueryHookResult = ReturnType<
  typeof useGetBaseProductAndStockQuery
>;
export type GetBaseProductAndStockLazyQueryHookResult = ReturnType<
  typeof useGetBaseProductAndStockLazyQuery
>;
export type GetBaseProductAndStockQueryResult = Apollo.QueryResult<
  GetBaseProductAndStockQuery,
  GetBaseProductAndStockQueryVariables
>;
export const GetBaseProductsDocument = gql`
  query getBaseProducts {
    base_product {
      id
      product_name
      fsc_certified
      grs_certified
      base_product_id
    }
  }
`;

/**
 * __useGetBaseProductsQuery__
 *
 * To run a query within a React component, call `useGetBaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetBaseProductsQuery, GetBaseProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBaseProductsQuery, GetBaseProductsQueryVariables>(
    GetBaseProductsDocument,
    options
  );
}
export function useGetBaseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetBaseProductsQuery, GetBaseProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBaseProductsQuery, GetBaseProductsQueryVariables>(
    GetBaseProductsDocument,
    options
  );
}
export type GetBaseProductsQueryHookResult = ReturnType<typeof useGetBaseProductsQuery>;
export type GetBaseProductsLazyQueryHookResult = ReturnType<typeof useGetBaseProductsLazyQuery>;
export type GetBaseProductsQueryResult = Apollo.QueryResult<
  GetBaseProductsQuery,
  GetBaseProductsQueryVariables
>;
export const GetCaseProductsByBaseProductIdDocument = gql`
  query getCaseProductsByBaseProductId($baseProductId: Int!) {
    product(where: { base_product_id: { _eq: $baseProductId } }) {
      id
      product_name
      base_product_id
      base_product_quantity
      case_product_quantity
    }
  }
`;

/**
 * __useGetCaseProductsByBaseProductIdQuery__
 *
 * To run a query within a React component, call `useGetCaseProductsByBaseProductIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCaseProductsByBaseProductIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCaseProductsByBaseProductIdQuery({
 *   variables: {
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetCaseProductsByBaseProductIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetCaseProductsByBaseProductIdQuery,
    GetCaseProductsByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCaseProductsByBaseProductIdQuery,
    GetCaseProductsByBaseProductIdQueryVariables
  >(GetCaseProductsByBaseProductIdDocument, options);
}
export function useGetCaseProductsByBaseProductIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCaseProductsByBaseProductIdQuery,
    GetCaseProductsByBaseProductIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCaseProductsByBaseProductIdQuery,
    GetCaseProductsByBaseProductIdQueryVariables
  >(GetCaseProductsByBaseProductIdDocument, options);
}
export type GetCaseProductsByBaseProductIdQueryHookResult = ReturnType<
  typeof useGetCaseProductsByBaseProductIdQuery
>;
export type GetCaseProductsByBaseProductIdLazyQueryHookResult = ReturnType<
  typeof useGetCaseProductsByBaseProductIdLazyQuery
>;
export type GetCaseProductsByBaseProductIdQueryResult = Apollo.QueryResult<
  GetCaseProductsByBaseProductIdQuery,
  GetCaseProductsByBaseProductIdQueryVariables
>;
export const GetProductsDocument = gql`
  query getProducts {
    product {
      id
      product_name
      base_product_id
      base_product_quantity
      case_product_quantity
      fsc_certified
      grs_certified
    }
  }
`;

/**
 * __useGetProductsQuery__
 *
 * To run a query within a React component, call `useGetProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetProductsQuery, GetProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductsQuery, GetProductsQueryVariables>(GetProductsDocument, options);
}
export function useGetProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetProductsQuery, GetProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductsQuery, GetProductsQueryVariables>(
    GetProductsDocument,
    options
  );
}
export type GetProductsQueryHookResult = ReturnType<typeof useGetProductsQuery>;
export type GetProductsLazyQueryHookResult = ReturnType<typeof useGetProductsLazyQuery>;
export type GetProductsQueryResult = Apollo.QueryResult<
  GetProductsQuery,
  GetProductsQueryVariables
>;
export const GetRecipeBaseProductNamesDocument = gql`
  query getRecipeBaseProductNames {
    base_product {
      id
      product_name
    }
  }
`;

/**
 * __useGetRecipeBaseProductNamesQuery__
 *
 * To run a query within a React component, call `useGetRecipeBaseProductNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeBaseProductNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeBaseProductNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecipeBaseProductNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetRecipeBaseProductNamesQuery,
    GetRecipeBaseProductNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeBaseProductNamesQuery, GetRecipeBaseProductNamesQueryVariables>(
    GetRecipeBaseProductNamesDocument,
    options
  );
}
export function useGetRecipeBaseProductNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipeBaseProductNamesQuery,
    GetRecipeBaseProductNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetRecipeBaseProductNamesQuery,
    GetRecipeBaseProductNamesQueryVariables
  >(GetRecipeBaseProductNamesDocument, options);
}
export type GetRecipeBaseProductNamesQueryHookResult = ReturnType<
  typeof useGetRecipeBaseProductNamesQuery
>;
export type GetRecipeBaseProductNamesLazyQueryHookResult = ReturnType<
  typeof useGetRecipeBaseProductNamesLazyQuery
>;
export type GetRecipeBaseProductNamesQueryResult = Apollo.QueryResult<
  GetRecipeBaseProductNamesQuery,
  GetRecipeBaseProductNamesQueryVariables
>;
export const GetRecipeTypeNamesDocument = gql`
  query getRecipeTypeNames {
    recipe_type {
      id
      recipe_type_name
    }
  }
`;

/**
 * __useGetRecipeTypeNamesQuery__
 *
 * To run a query within a React component, call `useGetRecipeTypeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeTypeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeTypeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecipeTypeNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecipeTypeNamesQuery, GetRecipeTypeNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeTypeNamesQuery, GetRecipeTypeNamesQueryVariables>(
    GetRecipeTypeNamesDocument,
    options
  );
}
export function useGetRecipeTypeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipeTypeNamesQuery,
    GetRecipeTypeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeTypeNamesQuery, GetRecipeTypeNamesQueryVariables>(
    GetRecipeTypeNamesDocument,
    options
  );
}
export type GetRecipeTypeNamesQueryHookResult = ReturnType<typeof useGetRecipeTypeNamesQuery>;
export type GetRecipeTypeNamesLazyQueryHookResult = ReturnType<
  typeof useGetRecipeTypeNamesLazyQuery
>;
export type GetRecipeTypeNamesQueryResult = Apollo.QueryResult<
  GetRecipeTypeNamesQuery,
  GetRecipeTypeNamesQueryVariables
>;
export const GetRecipeByIdDocument = gql`
  query getRecipeById($recipeId: Int) {
    recipe(where: { id: { _eq: $recipeId } }) {
      id
      is_draft
      recipe_name
      last_updated
      recipe_type {
        recipe_type_name
        id
      }
      use_for_reporting
      recipe_outputs {
        id
        quantity
        product {
          id
          product_name
        }
      }
      recipe_inputs {
        id
        product {
          product_name
          id
        }
        quantity
      }
    }
  }
`;

/**
 * __useGetRecipeByIdQuery__
 *
 * To run a query within a React component, call `useGetRecipeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipeByIdQuery({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useGetRecipeByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecipeByIdQuery, GetRecipeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipeByIdQuery, GetRecipeByIdQueryVariables>(
    GetRecipeByIdDocument,
    options
  );
}
export function useGetRecipeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRecipeByIdQuery, GetRecipeByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipeByIdQuery, GetRecipeByIdQueryVariables>(
    GetRecipeByIdDocument,
    options
  );
}
export type GetRecipeByIdQueryHookResult = ReturnType<typeof useGetRecipeByIdQuery>;
export type GetRecipeByIdLazyQueryHookResult = ReturnType<typeof useGetRecipeByIdLazyQuery>;
export type GetRecipeByIdQueryResult = Apollo.QueryResult<
  GetRecipeByIdQuery,
  GetRecipeByIdQueryVariables
>;
export const GetRecipesWithDataDocument = gql`
  query getRecipesWithData {
    recipe {
      id
      is_draft
      use_for_reporting
      recipe_name
      recipe_inputs_aggregate {
        aggregate {
          count
        }
      }
      recipe_outputs_aggregate {
        aggregate {
          count
        }
      }
      recipe_type {
        recipe_type_name
        id
      }
    }
  }
`;

/**
 * __useGetRecipesWithDataQuery__
 *
 * To run a query within a React component, call `useGetRecipesWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecipesWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecipesWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRecipesWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRecipesWithDataQuery, GetRecipesWithDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRecipesWithDataQuery, GetRecipesWithDataQueryVariables>(
    GetRecipesWithDataDocument,
    options
  );
}
export function useGetRecipesWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetRecipesWithDataQuery,
    GetRecipesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRecipesWithDataQuery, GetRecipesWithDataQueryVariables>(
    GetRecipesWithDataDocument,
    options
  );
}
export type GetRecipesWithDataQueryHookResult = ReturnType<typeof useGetRecipesWithDataQuery>;
export type GetRecipesWithDataLazyQueryHookResult = ReturnType<
  typeof useGetRecipesWithDataLazyQuery
>;
export type GetRecipesWithDataQueryResult = Apollo.QueryResult<
  GetRecipesWithDataQuery,
  GetRecipesWithDataQueryVariables
>;
export const GetCustomReportDataDocument = gql`
  query getCustomReportData(
    $locationTypeId: Int
    $warehouseId: Int
    $productId: Int
    $stockConditionId: Int
    $organisationId: Int
  ) {
    product_stock(
      where: {
        _and: [
          {
            location: {
              _and: [
                { location_type_id: { _eq: $locationTypeId } }
                { warehouse_id: { _eq: $warehouseId } }
              ]
            }
          }
          { quantity: { _neq: 0 } }
          { product_id: { _eq: $productId } }
          { organisation: { id: { _eq: $organisationId } } }
          { stock_condition: { id: { _eq: $stockConditionId } } }
        ]
      }
    ) {
      id
      quantity
      stock_status {
        id
        stock_status_name
      }
      stock_condition {
        id
        stock_condition_name
      }
      location {
        warehouse_id
        location_type {
          id
          location_type_name
        }
      }
      product {
        product_name
        id
        base_product_id
      }
    }
  }
`;

/**
 * __useGetCustomReportDataQuery__
 *
 * To run a query within a React component, call `useGetCustomReportDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportDataQuery({
 *   variables: {
 *      locationTypeId: // value for 'locationTypeId'
 *      warehouseId: // value for 'warehouseId'
 *      productId: // value for 'productId'
 *      stockConditionId: // value for 'stockConditionId'
 *      organisationId: // value for 'organisationId'
 *   },
 * });
 */
export function useGetCustomReportDataQuery(
  baseOptions?: Apollo.QueryHookOptions<GetCustomReportDataQuery, GetCustomReportDataQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetCustomReportDataQuery, GetCustomReportDataQueryVariables>(
    GetCustomReportDataDocument,
    options
  );
}
export function useGetCustomReportDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportDataQuery,
    GetCustomReportDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetCustomReportDataQuery, GetCustomReportDataQueryVariables>(
    GetCustomReportDataDocument,
    options
  );
}
export type GetCustomReportDataQueryHookResult = ReturnType<typeof useGetCustomReportDataQuery>;
export type GetCustomReportDataLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportDataLazyQuery
>;
export type GetCustomReportDataQueryResult = Apollo.QueryResult<
  GetCustomReportDataQuery,
  GetCustomReportDataQueryVariables
>;
export const GetCustomReportLocationTypesNamesDocument = gql`
  query getCustomReportLocationTypesNames {
    location_type {
      id
      location_type_name
    }
  }
`;

/**
 * __useGetCustomReportLocationTypesNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomReportLocationTypesNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportLocationTypesNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportLocationTypesNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomReportLocationTypesNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomReportLocationTypesNamesQuery,
    GetCustomReportLocationTypesNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomReportLocationTypesNamesQuery,
    GetCustomReportLocationTypesNamesQueryVariables
  >(GetCustomReportLocationTypesNamesDocument, options);
}
export function useGetCustomReportLocationTypesNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportLocationTypesNamesQuery,
    GetCustomReportLocationTypesNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomReportLocationTypesNamesQuery,
    GetCustomReportLocationTypesNamesQueryVariables
  >(GetCustomReportLocationTypesNamesDocument, options);
}
export type GetCustomReportLocationTypesNamesQueryHookResult = ReturnType<
  typeof useGetCustomReportLocationTypesNamesQuery
>;
export type GetCustomReportLocationTypesNamesLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportLocationTypesNamesLazyQuery
>;
export type GetCustomReportLocationTypesNamesQueryResult = Apollo.QueryResult<
  GetCustomReportLocationTypesNamesQuery,
  GetCustomReportLocationTypesNamesQueryVariables
>;
export const GetCustomReportOrganisationNamesDocument = gql`
  query getCustomReportOrganisationNames {
    organisation {
      organisation_name
      id
    }
  }
`;

/**
 * __useGetCustomReportOrganisationNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomReportOrganisationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportOrganisationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportOrganisationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomReportOrganisationNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomReportOrganisationNamesQuery,
    GetCustomReportOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomReportOrganisationNamesQuery,
    GetCustomReportOrganisationNamesQueryVariables
  >(GetCustomReportOrganisationNamesDocument, options);
}
export function useGetCustomReportOrganisationNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportOrganisationNamesQuery,
    GetCustomReportOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomReportOrganisationNamesQuery,
    GetCustomReportOrganisationNamesQueryVariables
  >(GetCustomReportOrganisationNamesDocument, options);
}
export type GetCustomReportOrganisationNamesQueryHookResult = ReturnType<
  typeof useGetCustomReportOrganisationNamesQuery
>;
export type GetCustomReportOrganisationNamesLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportOrganisationNamesLazyQuery
>;
export type GetCustomReportOrganisationNamesQueryResult = Apollo.QueryResult<
  GetCustomReportOrganisationNamesQuery,
  GetCustomReportOrganisationNamesQueryVariables
>;
export const GetCustomReportProductNamesDocument = gql`
  query getCustomReportProductNames {
    product {
      id
      product_name
    }
  }
`;

/**
 * __useGetCustomReportProductNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomReportProductNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportProductNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportProductNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomReportProductNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomReportProductNamesQuery,
    GetCustomReportProductNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomReportProductNamesQuery,
    GetCustomReportProductNamesQueryVariables
  >(GetCustomReportProductNamesDocument, options);
}
export function useGetCustomReportProductNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportProductNamesQuery,
    GetCustomReportProductNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomReportProductNamesQuery,
    GetCustomReportProductNamesQueryVariables
  >(GetCustomReportProductNamesDocument, options);
}
export type GetCustomReportProductNamesQueryHookResult = ReturnType<
  typeof useGetCustomReportProductNamesQuery
>;
export type GetCustomReportProductNamesLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportProductNamesLazyQuery
>;
export type GetCustomReportProductNamesQueryResult = Apollo.QueryResult<
  GetCustomReportProductNamesQuery,
  GetCustomReportProductNamesQueryVariables
>;
export const GetCustomReportStockConditionNamesDocument = gql`
  query getCustomReportStockConditionNames {
    stock_condition {
      id
      stock_condition_name
    }
  }
`;

/**
 * __useGetCustomReportStockConditionNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomReportStockConditionNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportStockConditionNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportStockConditionNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomReportStockConditionNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomReportStockConditionNamesQuery,
    GetCustomReportStockConditionNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomReportStockConditionNamesQuery,
    GetCustomReportStockConditionNamesQueryVariables
  >(GetCustomReportStockConditionNamesDocument, options);
}
export function useGetCustomReportStockConditionNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportStockConditionNamesQuery,
    GetCustomReportStockConditionNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomReportStockConditionNamesQuery,
    GetCustomReportStockConditionNamesQueryVariables
  >(GetCustomReportStockConditionNamesDocument, options);
}
export type GetCustomReportStockConditionNamesQueryHookResult = ReturnType<
  typeof useGetCustomReportStockConditionNamesQuery
>;
export type GetCustomReportStockConditionNamesLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportStockConditionNamesLazyQuery
>;
export type GetCustomReportStockConditionNamesQueryResult = Apollo.QueryResult<
  GetCustomReportStockConditionNamesQuery,
  GetCustomReportStockConditionNamesQueryVariables
>;
export const GetCustomReportWarehouseNamesDocument = gql`
  query getCustomReportWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetCustomReportWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetCustomReportWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCustomReportWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCustomReportWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCustomReportWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetCustomReportWarehouseNamesQuery,
    GetCustomReportWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetCustomReportWarehouseNamesQuery,
    GetCustomReportWarehouseNamesQueryVariables
  >(GetCustomReportWarehouseNamesDocument, options);
}
export function useGetCustomReportWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetCustomReportWarehouseNamesQuery,
    GetCustomReportWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetCustomReportWarehouseNamesQuery,
    GetCustomReportWarehouseNamesQueryVariables
  >(GetCustomReportWarehouseNamesDocument, options);
}
export type GetCustomReportWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetCustomReportWarehouseNamesQuery
>;
export type GetCustomReportWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetCustomReportWarehouseNamesLazyQuery
>;
export type GetCustomReportWarehouseNamesQueryResult = Apollo.QueryResult<
  GetCustomReportWarehouseNamesQuery,
  GetCustomReportWarehouseNamesQueryVariables
>;
export const GetAllProductsDocument = gql`
  query getAllProducts {
    product {
      id
      external_product_id
      product_name
      product_stocks_aggregate {
        aggregate {
          count(columns: id)
        }
      }
      service_account {
        service_account_name
      }
      case_product_id
      base_product_quantity
    }
  }
`;

/**
 * __useGetAllProductsQuery__
 *
 * To run a query within a React component, call `useGetAllProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
    GetAllProductsDocument,
    options
  );
}
export function useGetAllProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllProductsQuery, GetAllProductsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllProductsQuery, GetAllProductsQueryVariables>(
    GetAllProductsDocument,
    options
  );
}
export type GetAllProductsQueryHookResult = ReturnType<typeof useGetAllProductsQuery>;
export type GetAllProductsLazyQueryHookResult = ReturnType<typeof useGetAllProductsLazyQuery>;
export type GetAllProductsQueryResult = Apollo.QueryResult<
  GetAllProductsQuery,
  GetAllProductsQueryVariables
>;
export const GetAllReportWarehouseNamesDocument = gql`
  query getAllReportWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetAllReportWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetAllReportWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllReportWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllReportWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllReportWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetAllReportWarehouseNamesQuery,
    GetAllReportWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllReportWarehouseNamesQuery, GetAllReportWarehouseNamesQueryVariables>(
    GetAllReportWarehouseNamesDocument,
    options
  );
}
export function useGetAllReportWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllReportWarehouseNamesQuery,
    GetAllReportWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetAllReportWarehouseNamesQuery,
    GetAllReportWarehouseNamesQueryVariables
  >(GetAllReportWarehouseNamesDocument, options);
}
export type GetAllReportWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetAllReportWarehouseNamesQuery
>;
export type GetAllReportWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllReportWarehouseNamesLazyQuery
>;
export type GetAllReportWarehouseNamesQueryResult = Apollo.QueryResult<
  GetAllReportWarehouseNamesQuery,
  GetAllReportWarehouseNamesQueryVariables
>;
export const GetBaseProductsAccreditationsDocument = gql`
  query getBaseProductsAccreditations {
    base_product {
      grs_certified
      fsc_certified
      product_name
    }
  }
`;

/**
 * __useGetBaseProductsAccreditationsQuery__
 *
 * To run a query within a React component, call `useGetBaseProductsAccreditationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBaseProductsAccreditationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBaseProductsAccreditationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBaseProductsAccreditationsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBaseProductsAccreditationsQuery,
    GetBaseProductsAccreditationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetBaseProductsAccreditationsQuery,
    GetBaseProductsAccreditationsQueryVariables
  >(GetBaseProductsAccreditationsDocument, options);
}
export function useGetBaseProductsAccreditationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBaseProductsAccreditationsQuery,
    GetBaseProductsAccreditationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetBaseProductsAccreditationsQuery,
    GetBaseProductsAccreditationsQueryVariables
  >(GetBaseProductsAccreditationsDocument, options);
}
export type GetBaseProductsAccreditationsQueryHookResult = ReturnType<
  typeof useGetBaseProductsAccreditationsQuery
>;
export type GetBaseProductsAccreditationsLazyQueryHookResult = ReturnType<
  typeof useGetBaseProductsAccreditationsLazyQuery
>;
export type GetBaseProductsAccreditationsQueryResult = Apollo.QueryResult<
  GetBaseProductsAccreditationsQuery,
  GetBaseProductsAccreditationsQueryVariables
>;
export const GetEmptyLocationsByWarehouseIdDocument = gql`
  query getEmptyLocationsByWarehouseId($warehouseId: Int) {
    location(where: { warehouse_id: { _eq: $warehouseId } }) {
      product_stocks_aggregate {
        aggregate {
          sum {
            quantity
          }
        }
      }
      id
      location_name
      location_type {
        location_type_name
        id
      }
      warehouse {
        id
        warehouse_name
      }
    }
  }
`;

/**
 * __useGetEmptyLocationsByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetEmptyLocationsByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetEmptyLocationsByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetEmptyLocationsByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetEmptyLocationsByWarehouseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetEmptyLocationsByWarehouseIdQuery,
    GetEmptyLocationsByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetEmptyLocationsByWarehouseIdQuery,
    GetEmptyLocationsByWarehouseIdQueryVariables
  >(GetEmptyLocationsByWarehouseIdDocument, options);
}
export function useGetEmptyLocationsByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetEmptyLocationsByWarehouseIdQuery,
    GetEmptyLocationsByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetEmptyLocationsByWarehouseIdQuery,
    GetEmptyLocationsByWarehouseIdQueryVariables
  >(GetEmptyLocationsByWarehouseIdDocument, options);
}
export type GetEmptyLocationsByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetEmptyLocationsByWarehouseIdQuery
>;
export type GetEmptyLocationsByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetEmptyLocationsByWarehouseIdLazyQuery
>;
export type GetEmptyLocationsByWarehouseIdQueryResult = Apollo.QueryResult<
  GetEmptyLocationsByWarehouseIdQuery,
  GetEmptyLocationsByWarehouseIdQueryVariables
>;
export const GetFinanceStockDocument = gql`
  query getFinanceStock($warehouseId: Int, $fromDate: date, $toDate: date) {
    vw_finance_stock_report(
      order_by: { base_product: asc }
      where: {
        _and: [
          { warehouse_id: { _eq: $warehouseId } }
          {
            _or: [
              { action_date_for_current_quantity: { _gt: $toDate } }
              {
                _and: [
                  { action_date_for_stock_movements: { _gte: $fromDate } }
                  { action_date_for_stock_movements: { _lte: $toDate } }
                ]
              }
              {
                _and: [
                  { action_date_for_current_quantity: { _is_null: true } }
                  { action_date_for_stock_movements: { _is_null: true } }
                ]
              }
            ]
          }
        ]
      }
    ) {
      base_product_id
      base_product
      fsc_certified
      grs_certified
      fsc_claim
      warehouse
      organisation
      action_date_for_current_quantity
      action_date_for_stock_movements
      current_quantity
      inbound_quantity_on_date
      outbound_quantity_on_date
      total_adjustment_on_date
    }
  }
`;

/**
 * __useGetFinanceStockQuery__
 *
 * To run a query within a React component, call `useGetFinanceStockQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFinanceStockQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFinanceStockQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetFinanceStockQuery(
  baseOptions?: Apollo.QueryHookOptions<GetFinanceStockQuery, GetFinanceStockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetFinanceStockQuery, GetFinanceStockQueryVariables>(
    GetFinanceStockDocument,
    options
  );
}
export function useGetFinanceStockLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetFinanceStockQuery, GetFinanceStockQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetFinanceStockQuery, GetFinanceStockQueryVariables>(
    GetFinanceStockDocument,
    options
  );
}
export type GetFinanceStockQueryHookResult = ReturnType<typeof useGetFinanceStockQuery>;
export type GetFinanceStockLazyQueryHookResult = ReturnType<typeof useGetFinanceStockLazyQuery>;
export type GetFinanceStockQueryResult = Apollo.QueryResult<
  GetFinanceStockQuery,
  GetFinanceStockQueryVariables
>;
export const GetInternalTransfersDocument = gql`
  query getInternalTransfers {
    internal_transfer {
      id
      to_location {
        location_name
        warehouse {
          warehouse_name
        }
      }
      from_location {
        id
        location_name
        warehouse {
          warehouse_name
        }
      }
      internal_transfer_status {
        internal_transfer_status_name
      }
      last_updated
      internal_transfer_lines_aggregate {
        aggregate {
          count(columns: id)
        }
      }
    }
  }
`;

/**
 * __useGetInternalTransfersQuery__
 *
 * To run a query within a React component, call `useGetInternalTransfersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalTransfersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalTransfersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInternalTransfersQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetInternalTransfersQuery,
    GetInternalTransfersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInternalTransfersQuery, GetInternalTransfersQueryVariables>(
    GetInternalTransfersDocument,
    options
  );
}
export function useGetInternalTransfersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInternalTransfersQuery,
    GetInternalTransfersQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetInternalTransfersQuery, GetInternalTransfersQueryVariables>(
    GetInternalTransfersDocument,
    options
  );
}
export type GetInternalTransfersQueryHookResult = ReturnType<typeof useGetInternalTransfersQuery>;
export type GetInternalTransfersLazyQueryHookResult = ReturnType<
  typeof useGetInternalTransfersLazyQuery
>;
export type GetInternalTransfersQueryResult = Apollo.QueryResult<
  GetInternalTransfersQuery,
  GetInternalTransfersQueryVariables
>;
export const GetInventoryByWarehouseIdDocument = gql`
  query getInventoryByWarehouseId($warehouseId: Int!) {
    vw_inventory_report(order_by: { sort: asc }, where: { warehouse_id: { _eq: $warehouseId } }) {
      base_product_id
      product_id
      product_name
      prepacked_bundle
      weekly_usage_180d
      weekly_usage_360d
      weekly_usage_90d
      units_total
      units_in_prepacked
      fsc_cert
      grs_cert
      fsc_claim
      weeks_remaining_90d
      weeks_remaining_180d
      weeks_remaining_360d
      alert
      location
      quantity
      case_size
      recent_outbound_units
      days_since_first_stock
    }
  }
`;

/**
 * __useGetInventoryByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetInventoryByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInventoryByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInventoryByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetInventoryByWarehouseIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetInventoryByWarehouseIdQuery,
    GetInventoryByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetInventoryByWarehouseIdQuery, GetInventoryByWarehouseIdQueryVariables>(
    GetInventoryByWarehouseIdDocument,
    options
  );
}
export function useGetInventoryByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetInventoryByWarehouseIdQuery,
    GetInventoryByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetInventoryByWarehouseIdQuery,
    GetInventoryByWarehouseIdQueryVariables
  >(GetInventoryByWarehouseIdDocument, options);
}
export type GetInventoryByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetInventoryByWarehouseIdQuery
>;
export type GetInventoryByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetInventoryByWarehouseIdLazyQuery
>;
export type GetInventoryByWarehouseIdQueryResult = Apollo.QueryResult<
  GetInventoryByWarehouseIdQuery,
  GetInventoryByWarehouseIdQueryVariables
>;
export const GetManifestsByWarehouseIdDocument = gql`
  query getManifestsByWarehouseId($warehouseId: Int, $fromDate: timestamptz, $toDate: timestamptz) {
    inbound_manifest_line(
      where: {
        inbound_manifest: {
          warehouse_id: { _eq: $warehouseId }
          expected_arrival: { _gte: $fromDate, _lte: $toDate }
        }
      }
      order_by: { inbound_manifest_id: asc }
    ) {
      inbound_manifest {
        id
        reference
        organisation {
          organisation_name
        }
        warehouse {
          warehouse_name
        }
        logistics_type {
          logistics_type_name
        }
        courier_service {
          courier_service_name
        }
        inbound_manifest_status {
          inbound_manifest_status_name
        }
        expected_arrival
        notes
      }
      product {
        product_name
        product {
          product_name
        }
        base_product_quantity
      }
      quantity
      received_quantity
      inbound_manifest_fulfilment_lines {
        product_stocks {
          location {
            location_name
          }
        }
      }
    }
    outbound_manifest_line(
      where: {
        outbound_manifest: {
          warehouse_id: { _eq: $warehouseId }
          expected_dispatch: { _gte: $fromDate, _lte: $toDate }
        }
      }
      order_by: { outbound_manifest_id: asc }
    ) {
      outbound_manifest {
        id
        reference
        organisation {
          organisation_name
        }
        warehouse {
          warehouse_name
        }
        logistics_type {
          logistics_type_name
        }
        courier_service {
          courier_service_name
        }
        outbound_manifest_status {
          outbound_manifest_status_name
        }
        expected_dispatch
        notes
      }
      product {
        product_name
        base_product_quantity
        product {
          product_name
        }
      }
      quantity
      dispatched_quantity
      outbound_manifest_fulfilment_lines {
        product_stock {
          location {
            location_name
          }
        }
      }
    }
  }
`;

/**
 * __useGetManifestsByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetManifestsByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetManifestsByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetManifestsByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      fromDate: // value for 'fromDate'
 *      toDate: // value for 'toDate'
 *   },
 * });
 */
export function useGetManifestsByWarehouseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetManifestsByWarehouseIdQuery,
    GetManifestsByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetManifestsByWarehouseIdQuery, GetManifestsByWarehouseIdQueryVariables>(
    GetManifestsByWarehouseIdDocument,
    options
  );
}
export function useGetManifestsByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetManifestsByWarehouseIdQuery,
    GetManifestsByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetManifestsByWarehouseIdQuery,
    GetManifestsByWarehouseIdQueryVariables
  >(GetManifestsByWarehouseIdDocument, options);
}
export type GetManifestsByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetManifestsByWarehouseIdQuery
>;
export type GetManifestsByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetManifestsByWarehouseIdLazyQuery
>;
export type GetManifestsByWarehouseIdQueryResult = Apollo.QueryResult<
  GetManifestsByWarehouseIdQuery,
  GetManifestsByWarehouseIdQueryVariables
>;
export const GetStockByWarehouseIdDocument = gql`
  query getStockByWarehouseId($warehouseId: Int) {
    product_stock(where: { location: { warehouse_id: { _eq: $warehouseId } } }) {
      id
      initial_quantity
      barcode_uuid
      barcode_is_unique
      location {
        location_name
      }
      quantity
      product {
        product_name
      }
      stock_status {
        stock_status_name
      }
      stock_condition {
        stock_condition_name
      }
      organisation {
        organisation_name
      }
    }
  }
`;

/**
 * __useGetStockByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetStockByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetStockByWarehouseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockByWarehouseIdQuery,
    GetStockByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockByWarehouseIdQuery, GetStockByWarehouseIdQueryVariables>(
    GetStockByWarehouseIdDocument,
    options
  );
}
export function useGetStockByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockByWarehouseIdQuery,
    GetStockByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockByWarehouseIdQuery, GetStockByWarehouseIdQueryVariables>(
    GetStockByWarehouseIdDocument,
    options
  );
}
export type GetStockByWarehouseIdQueryHookResult = ReturnType<typeof useGetStockByWarehouseIdQuery>;
export type GetStockByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetStockByWarehouseIdLazyQuery
>;
export type GetStockByWarehouseIdQueryResult = Apollo.QueryResult<
  GetStockByWarehouseIdQuery,
  GetStockByWarehouseIdQueryVariables
>;
export const GetOrganisationsDocument = gql`
  query getOrganisations {
    organisation {
      organisation_name
      id
    }
  }
`;

/**
 * __useGetOrganisationsQuery__
 *
 * To run a query within a React component, call `useGetOrganisationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganisationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganisationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetOrganisationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetOrganisationsQuery, GetOrganisationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganisationsQuery, GetOrganisationsQueryVariables>(
    GetOrganisationsDocument,
    options
  );
}
export function useGetOrganisationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganisationsQuery, GetOrganisationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganisationsQuery, GetOrganisationsQueryVariables>(
    GetOrganisationsDocument,
    options
  );
}
export type GetOrganisationsQueryHookResult = ReturnType<typeof useGetOrganisationsQuery>;
export type GetOrganisationsLazyQueryHookResult = ReturnType<typeof useGetOrganisationsLazyQuery>;
export type GetOrganisationsQueryResult = Apollo.QueryResult<
  GetOrganisationsQuery,
  GetOrganisationsQueryVariables
>;
export const GetStockAllocationProductStocksDocument = gql`
  query getStockAllocationProductStocks {
    product_stock(where: { quantity: { _gt: 0 } }) {
      id
      stock_condition {
        stock_condition_name
      }
      stock_status {
        stock_status_name
      }
      product {
        product_name
        external_product_id
      }
      location {
        location_name
      }
      organisation {
        organisation_name
        id
      }
      quantity
    }
  }
`;

/**
 * __useGetStockAllocationProductStocksQuery__
 *
 * To run a query within a React component, call `useGetStockAllocationProductStocksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAllocationProductStocksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAllocationProductStocksQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockAllocationProductStocksQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockAllocationProductStocksQuery,
    GetStockAllocationProductStocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockAllocationProductStocksQuery,
    GetStockAllocationProductStocksQueryVariables
  >(GetStockAllocationProductStocksDocument, options);
}
export function useGetStockAllocationProductStocksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockAllocationProductStocksQuery,
    GetStockAllocationProductStocksQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockAllocationProductStocksQuery,
    GetStockAllocationProductStocksQueryVariables
  >(GetStockAllocationProductStocksDocument, options);
}
export type GetStockAllocationProductStocksQueryHookResult = ReturnType<
  typeof useGetStockAllocationProductStocksQuery
>;
export type GetStockAllocationProductStocksLazyQueryHookResult = ReturnType<
  typeof useGetStockAllocationProductStocksLazyQuery
>;
export type GetStockAllocationProductStocksQueryResult = Apollo.QueryResult<
  GetStockAllocationProductStocksQuery,
  GetStockAllocationProductStocksQueryVariables
>;
export const GetStockAllocationsDocument = gql`
  query getStockAllocations {
    stock_allocation(order_by: { last_updated: desc }) {
      organisation {
        id
        organisation_name
      }
      organisationByToOrganisationId {
        id
        organisation_name
      }
      id
      created_at
      warehouse {
        id
        warehouse_name
      }
      quantity
    }
  }
`;

/**
 * __useGetStockAllocationsQuery__
 *
 * To run a query within a React component, call `useGetStockAllocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockAllocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockAllocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockAllocationsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStockAllocationsQuery, GetStockAllocationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockAllocationsQuery, GetStockAllocationsQueryVariables>(
    GetStockAllocationsDocument,
    options
  );
}
export function useGetStockAllocationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockAllocationsQuery,
    GetStockAllocationsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockAllocationsQuery, GetStockAllocationsQueryVariables>(
    GetStockAllocationsDocument,
    options
  );
}
export type GetStockAllocationsQueryHookResult = ReturnType<typeof useGetStockAllocationsQuery>;
export type GetStockAllocationsLazyQueryHookResult = ReturnType<
  typeof useGetStockAllocationsLazyQuery
>;
export type GetStockAllocationsQueryResult = Apollo.QueryResult<
  GetStockAllocationsQuery,
  GetStockAllocationsQueryVariables
>;
export const GetStockCheckProductStockByWarehouseIdDocument = gql`
  query getStockCheckProductStockByWarehouseId($warehouseId: Int) {
    product_stock(
      where: { location: { warehouse_id: { _eq: $warehouseId } }, quantity: { _gte: 1 } }
    ) {
      id
      product {
        base_product_id
        product_name
        id
      }
      stock_condition {
        stock_condition_name
        id
      }
      stock_status {
        stock_status_name
        id
      }
      organisation {
        organisation_name
        id
      }
      location {
        id
        location_name
      }
      quantity
    }
  }
`;

/**
 * __useGetStockCheckProductStockByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetStockCheckProductStockByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCheckProductStockByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCheckProductStockByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetStockCheckProductStockByWarehouseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockCheckProductStockByWarehouseIdQuery,
    GetStockCheckProductStockByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockCheckProductStockByWarehouseIdQuery,
    GetStockCheckProductStockByWarehouseIdQueryVariables
  >(GetStockCheckProductStockByWarehouseIdDocument, options);
}
export function useGetStockCheckProductStockByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockCheckProductStockByWarehouseIdQuery,
    GetStockCheckProductStockByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockCheckProductStockByWarehouseIdQuery,
    GetStockCheckProductStockByWarehouseIdQueryVariables
  >(GetStockCheckProductStockByWarehouseIdDocument, options);
}
export type GetStockCheckProductStockByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetStockCheckProductStockByWarehouseIdQuery
>;
export type GetStockCheckProductStockByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetStockCheckProductStockByWarehouseIdLazyQuery
>;
export type GetStockCheckProductStockByWarehouseIdQueryResult = Apollo.QueryResult<
  GetStockCheckProductStockByWarehouseIdQuery,
  GetStockCheckProductStockByWarehouseIdQueryVariables
>;
export const GetBasicStockCheckTypesDocument = gql`
  query getBasicStockCheckTypes {
    stock_check_type(where: { stock_check_type_name: { _nin: ["Weekly", "AdHoc"] } }) {
      stock_check_type_name
      id
    }
  }
`;

/**
 * __useGetBasicStockCheckTypesQuery__
 *
 * To run a query within a React component, call `useGetBasicStockCheckTypesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBasicStockCheckTypesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBasicStockCheckTypesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBasicStockCheckTypesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetBasicStockCheckTypesQuery,
    GetBasicStockCheckTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetBasicStockCheckTypesQuery, GetBasicStockCheckTypesQueryVariables>(
    GetBasicStockCheckTypesDocument,
    options
  );
}
export function useGetBasicStockCheckTypesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetBasicStockCheckTypesQuery,
    GetBasicStockCheckTypesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetBasicStockCheckTypesQuery, GetBasicStockCheckTypesQueryVariables>(
    GetBasicStockCheckTypesDocument,
    options
  );
}
export type GetBasicStockCheckTypesQueryHookResult = ReturnType<
  typeof useGetBasicStockCheckTypesQuery
>;
export type GetBasicStockCheckTypesLazyQueryHookResult = ReturnType<
  typeof useGetBasicStockCheckTypesLazyQuery
>;
export type GetBasicStockCheckTypesQueryResult = Apollo.QueryResult<
  GetBasicStockCheckTypesQuery,
  GetBasicStockCheckTypesQueryVariables
>;
export const GetStockCheckStockConditionsDocument = gql`
  query getStockCheckStockConditions {
    stock_condition {
      id
      stock_condition_name
    }
  }
`;

/**
 * __useGetStockCheckStockConditionsQuery__
 *
 * To run a query within a React component, call `useGetStockCheckStockConditionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCheckStockConditionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCheckStockConditionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockCheckStockConditionsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockCheckStockConditionsQuery,
    GetStockCheckStockConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockCheckStockConditionsQuery,
    GetStockCheckStockConditionsQueryVariables
  >(GetStockCheckStockConditionsDocument, options);
}
export function useGetStockCheckStockConditionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockCheckStockConditionsQuery,
    GetStockCheckStockConditionsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockCheckStockConditionsQuery,
    GetStockCheckStockConditionsQueryVariables
  >(GetStockCheckStockConditionsDocument, options);
}
export type GetStockCheckStockConditionsQueryHookResult = ReturnType<
  typeof useGetStockCheckStockConditionsQuery
>;
export type GetStockCheckStockConditionsLazyQueryHookResult = ReturnType<
  typeof useGetStockCheckStockConditionsLazyQuery
>;
export type GetStockCheckStockConditionsQueryResult = Apollo.QueryResult<
  GetStockCheckStockConditionsQuery,
  GetStockCheckStockConditionsQueryVariables
>;
export const GetStockCheckBaseProductsDocument = gql`
  query getStockCheckBaseProducts {
    base_product {
      id
      product_name
      fsc_certified
      grs_certified
    }
  }
`;

/**
 * __useGetStockCheckBaseProductsQuery__
 *
 * To run a query within a React component, call `useGetStockCheckBaseProductsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCheckBaseProductsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCheckBaseProductsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockCheckBaseProductsQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockCheckBaseProductsQuery,
    GetStockCheckBaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockCheckBaseProductsQuery, GetStockCheckBaseProductsQueryVariables>(
    GetStockCheckBaseProductsDocument,
    options
  );
}
export function useGetStockCheckBaseProductsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockCheckBaseProductsQuery,
    GetStockCheckBaseProductsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockCheckBaseProductsQuery,
    GetStockCheckBaseProductsQueryVariables
  >(GetStockCheckBaseProductsDocument, options);
}
export type GetStockCheckBaseProductsQueryHookResult = ReturnType<
  typeof useGetStockCheckBaseProductsQuery
>;
export type GetStockCheckBaseProductsLazyQueryHookResult = ReturnType<
  typeof useGetStockCheckBaseProductsLazyQuery
>;
export type GetStockCheckBaseProductsQueryResult = Apollo.QueryResult<
  GetStockCheckBaseProductsQuery,
  GetStockCheckBaseProductsQueryVariables
>;
export const GetStockCheckByIdDocument = gql`
  query getStockCheckById($stockCheckId: Int) {
    stock_check(where: { id: { _eq: $stockCheckId } }) {
      id
      last_updated
      stock_check_status {
        stock_check_status_name
        id
      }
      location {
        id
        location_name
        warehouse {
          id
          warehouse_name
        }
      }
      stock_check_lines(order_by: { id: asc }) {
        stock_check_line_status {
          id
          stock_check_line_status_name
        }
        id
        last_updated
        expected_quantity
        stock_check_line_fulfilments(order_by: { id: asc }) {
          product {
            product_name
            id
          }
          last_updated
          id
          new_location_id
          location {
            location_name
            id
          }
          new_product_id
          new_stock_condition_id
          new_stock_status_id
          quantity
          stock_condition {
            id
            stock_condition_name
          }
          stock_status {
            id
            stock_status_name
          }
          product_stocks {
            last_updated
            organisation {
              organisation_name
              id
            }
            product {
              product_name
              id
            }
            quantity
            id
            location {
              warehouse {
                warehouse_name
                id
              }
              location_name
              id
            }
            stock_condition {
              id
              stock_condition_name
            }
            stock_status {
              id
              stock_status_name
            }
          }
          notes
        }
        expected_stock_condition_id
        product_stock {
          last_updated
          organisation {
            organisation_name
            id
          }
          product {
            product_name
            id
          }
          quantity
          id
          location {
            warehouse {
              warehouse_name
              id
            }
            location_name
            id
          }
          stock_condition {
            id
            stock_condition_name
          }
          stock_status {
            id
            stock_status_name
          }
        }
      }
      stock_check_type {
        stock_check_type_name
        id
      }
      due_date
      notes
    }
  }
`;

/**
 * __useGetStockCheckByIdQuery__
 *
 * To run a query within a React component, call `useGetStockCheckByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCheckByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCheckByIdQuery({
 *   variables: {
 *      stockCheckId: // value for 'stockCheckId'
 *   },
 * });
 */
export function useGetStockCheckByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStockCheckByIdQuery, GetStockCheckByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockCheckByIdQuery, GetStockCheckByIdQueryVariables>(
    GetStockCheckByIdDocument,
    options
  );
}
export function useGetStockCheckByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetStockCheckByIdQuery, GetStockCheckByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockCheckByIdQuery, GetStockCheckByIdQueryVariables>(
    GetStockCheckByIdDocument,
    options
  );
}
export type GetStockCheckByIdQueryHookResult = ReturnType<typeof useGetStockCheckByIdQuery>;
export type GetStockCheckByIdLazyQueryHookResult = ReturnType<typeof useGetStockCheckByIdLazyQuery>;
export type GetStockCheckByIdQueryResult = Apollo.QueryResult<
  GetStockCheckByIdQuery,
  GetStockCheckByIdQueryVariables
>;
export const GetStockCheckLocationsByWarehouseDocument = gql`
  query getStockCheckLocationsByWarehouse($warehouse_id: Int!) {
    location(where: { warehouse_id: { _eq: $warehouse_id } }) {
      id
      location_name
    }
  }
`;

/**
 * __useGetStockCheckLocationsByWarehouseQuery__
 *
 * To run a query within a React component, call `useGetStockCheckLocationsByWarehouseQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCheckLocationsByWarehouseQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCheckLocationsByWarehouseQuery({
 *   variables: {
 *      warehouse_id: // value for 'warehouse_id'
 *   },
 * });
 */
export function useGetStockCheckLocationsByWarehouseQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockCheckLocationsByWarehouseQuery,
    GetStockCheckLocationsByWarehouseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockCheckLocationsByWarehouseQuery,
    GetStockCheckLocationsByWarehouseQueryVariables
  >(GetStockCheckLocationsByWarehouseDocument, options);
}
export function useGetStockCheckLocationsByWarehouseLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockCheckLocationsByWarehouseQuery,
    GetStockCheckLocationsByWarehouseQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockCheckLocationsByWarehouseQuery,
    GetStockCheckLocationsByWarehouseQueryVariables
  >(GetStockCheckLocationsByWarehouseDocument, options);
}
export type GetStockCheckLocationsByWarehouseQueryHookResult = ReturnType<
  typeof useGetStockCheckLocationsByWarehouseQuery
>;
export type GetStockCheckLocationsByWarehouseLazyQueryHookResult = ReturnType<
  typeof useGetStockCheckLocationsByWarehouseLazyQuery
>;
export type GetStockCheckLocationsByWarehouseQueryResult = Apollo.QueryResult<
  GetStockCheckLocationsByWarehouseQuery,
  GetStockCheckLocationsByWarehouseQueryVariables
>;
export const GetStockCheckWarehouseNamesDocument = gql`
  query getStockCheckWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetStockCheckWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetStockCheckWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockCheckWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockCheckWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockCheckWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockCheckWarehouseNamesQuery,
    GetStockCheckWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockCheckWarehouseNamesQuery,
    GetStockCheckWarehouseNamesQueryVariables
  >(GetStockCheckWarehouseNamesDocument, options);
}
export function useGetStockCheckWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockCheckWarehouseNamesQuery,
    GetStockCheckWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockCheckWarehouseNamesQuery,
    GetStockCheckWarehouseNamesQueryVariables
  >(GetStockCheckWarehouseNamesDocument, options);
}
export type GetStockCheckWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetStockCheckWarehouseNamesQuery
>;
export type GetStockCheckWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetStockCheckWarehouseNamesLazyQuery
>;
export type GetStockCheckWarehouseNamesQueryResult = Apollo.QueryResult<
  GetStockCheckWarehouseNamesQuery,
  GetStockCheckWarehouseNamesQueryVariables
>;
export const GetStockChecksDescDocument = gql`
  query getStockChecksDesc {
    stock_check(order_by: { last_updated: desc }) {
      id
      stock_check_status {
        id
        stock_check_status_name
      }
      stock_check_type {
        stock_check_type_name
        id
      }
      location {
        location_name
        id
        warehouse {
          id
          warehouse_name
        }
      }
      due_date
    }
  }
`;

/**
 * __useGetStockChecksDescQuery__
 *
 * To run a query within a React component, call `useGetStockChecksDescQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockChecksDescQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockChecksDescQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockChecksDescQuery(
  baseOptions?: Apollo.QueryHookOptions<GetStockChecksDescQuery, GetStockChecksDescQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockChecksDescQuery, GetStockChecksDescQueryVariables>(
    GetStockChecksDescDocument,
    options
  );
}
export function useGetStockChecksDescLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockChecksDescQuery,
    GetStockChecksDescQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockChecksDescQuery, GetStockChecksDescQueryVariables>(
    GetStockChecksDescDocument,
    options
  );
}
export type GetStockChecksDescQueryHookResult = ReturnType<typeof useGetStockChecksDescQuery>;
export type GetStockChecksDescLazyQueryHookResult = ReturnType<
  typeof useGetStockChecksDescLazyQuery
>;
export type GetStockChecksDescQueryResult = Apollo.QueryResult<
  GetStockChecksDescQuery,
  GetStockChecksDescQueryVariables
>;
export const GetStockTransferLocationNamesByWarehouseIdDocument = gql`
  query getStockTransferLocationNamesByWarehouseId($warehouseId: Int!) {
    location(where: { warehouse_id: { _eq: $warehouseId }, can_hold_stock: { _eq: true } }) {
      id
      location_name
    }
  }
`;

/**
 * __useGetStockTransferLocationNamesByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetStockTransferLocationNamesByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferLocationNamesByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferLocationNamesByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetStockTransferLocationNamesByWarehouseIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockTransferLocationNamesByWarehouseIdQuery,
    GetStockTransferLocationNamesByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockTransferLocationNamesByWarehouseIdQuery,
    GetStockTransferLocationNamesByWarehouseIdQueryVariables
  >(GetStockTransferLocationNamesByWarehouseIdDocument, options);
}
export function useGetStockTransferLocationNamesByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockTransferLocationNamesByWarehouseIdQuery,
    GetStockTransferLocationNamesByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockTransferLocationNamesByWarehouseIdQuery,
    GetStockTransferLocationNamesByWarehouseIdQueryVariables
  >(GetStockTransferLocationNamesByWarehouseIdDocument, options);
}
export type GetStockTransferLocationNamesByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetStockTransferLocationNamesByWarehouseIdQuery
>;
export type GetStockTransferLocationNamesByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetStockTransferLocationNamesByWarehouseIdLazyQuery
>;
export type GetStockTransferLocationNamesByWarehouseIdQueryResult = Apollo.QueryResult<
  GetStockTransferLocationNamesByWarehouseIdQuery,
  GetStockTransferLocationNamesByWarehouseIdQueryVariables
>;
export const GetStockTransferProductStockByLocationIdDocument = gql`
  query getStockTransferProductStockByLocationId($locationId: Int!) {
    product_stock(where: { quantity: { _neq: 0 }, location_id: { _eq: $locationId } }) {
      id
      quantity
      stock_condition {
        stock_condition_name
        id
      }
      stock_status {
        stock_status_name
        id
      }
      organisation {
        organisation_name
        id
      }
      location {
        id
        location_name
      }
      product {
        id
        product_name
        base_product_id
      }
    }
  }
`;

/**
 * __useGetStockTransferProductStockByLocationIdQuery__
 *
 * To run a query within a React component, call `useGetStockTransferProductStockByLocationIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferProductStockByLocationIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferProductStockByLocationIdQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useGetStockTransferProductStockByLocationIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetStockTransferProductStockByLocationIdQuery,
    GetStockTransferProductStockByLocationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockTransferProductStockByLocationIdQuery,
    GetStockTransferProductStockByLocationIdQueryVariables
  >(GetStockTransferProductStockByLocationIdDocument, options);
}
export function useGetStockTransferProductStockByLocationIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockTransferProductStockByLocationIdQuery,
    GetStockTransferProductStockByLocationIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockTransferProductStockByLocationIdQuery,
    GetStockTransferProductStockByLocationIdQueryVariables
  >(GetStockTransferProductStockByLocationIdDocument, options);
}
export type GetStockTransferProductStockByLocationIdQueryHookResult = ReturnType<
  typeof useGetStockTransferProductStockByLocationIdQuery
>;
export type GetStockTransferProductStockByLocationIdLazyQueryHookResult = ReturnType<
  typeof useGetStockTransferProductStockByLocationIdLazyQuery
>;
export type GetStockTransferProductStockByLocationIdQueryResult = Apollo.QueryResult<
  GetStockTransferProductStockByLocationIdQuery,
  GetStockTransferProductStockByLocationIdQueryVariables
>;
export const GetStockTransferWarehouseNamesDocument = gql`
  query getStockTransferWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetStockTransferWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetStockTransferWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockTransferWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockTransferWarehouseNamesQuery,
    GetStockTransferWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetStockTransferWarehouseNamesQuery,
    GetStockTransferWarehouseNamesQueryVariables
  >(GetStockTransferWarehouseNamesDocument, options);
}
export function useGetStockTransferWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockTransferWarehouseNamesQuery,
    GetStockTransferWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockTransferWarehouseNamesQuery,
    GetStockTransferWarehouseNamesQueryVariables
  >(GetStockTransferWarehouseNamesDocument, options);
}
export type GetStockTransferWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetStockTransferWarehouseNamesQuery
>;
export type GetStockTransferWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetStockTransferWarehouseNamesLazyQuery
>;
export type GetStockTransferWarehouseNamesQueryResult = Apollo.QueryResult<
  GetStockTransferWarehouseNamesQuery,
  GetStockTransferWarehouseNamesQueryVariables
>;
export const GetStockTransferByIdDocument = gql`
  query getStockTransferById($stockTransferId: Int) {
    internal_transfer(where: { id: { _eq: $stockTransferId } }) {
      id
      from_location {
        id
        location_name
        warehouse {
          id
          warehouse_name
        }
      }
      to_location {
        id
        location_name
        warehouse {
          id
          warehouse_name
        }
      }
      internal_transfer_lines {
        id
        product_stock {
          id
          product {
            id
            product_name
          }
        }
        quantity
      }
      internal_transfer_status {
        id
        internal_transfer_status_name
      }
    }
  }
`;

/**
 * __useGetStockTransferByIdQuery__
 *
 * To run a query within a React component, call `useGetStockTransferByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransferByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransferByIdQuery({
 *   variables: {
 *      stockTransferId: // value for 'stockTransferId'
 *   },
 * });
 */
export function useGetStockTransferByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockTransferByIdQuery,
    GetStockTransferByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockTransferByIdQuery, GetStockTransferByIdQueryVariables>(
    GetStockTransferByIdDocument,
    options
  );
}
export function useGetStockTransferByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockTransferByIdQuery,
    GetStockTransferByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetStockTransferByIdQuery, GetStockTransferByIdQueryVariables>(
    GetStockTransferByIdDocument,
    options
  );
}
export type GetStockTransferByIdQueryHookResult = ReturnType<typeof useGetStockTransferByIdQuery>;
export type GetStockTransferByIdLazyQueryHookResult = ReturnType<
  typeof useGetStockTransferByIdLazyQuery
>;
export type GetStockTransferByIdQueryResult = Apollo.QueryResult<
  GetStockTransferByIdQuery,
  GetStockTransferByIdQueryVariables
>;
export const GetStockTransfersWithDataDocument = gql`
  query getStockTransfersWithData {
    internal_transfer(order_by: { last_updated: desc }) {
      from_location {
        location_name
        id
        warehouse {
          id
          warehouse_name
        }
      }
      to_location {
        id
        location_name
        warehouse {
          id
          warehouse_name
        }
      }
      id
      internal_transfer_lines_aggregate {
        aggregate {
          count(columns: id)
        }
      }
      internal_transfer_status {
        id
        internal_transfer_status_name
      }
    }
  }
`;

/**
 * __useGetStockTransfersWithDataQuery__
 *
 * To run a query within a React component, call `useGetStockTransfersWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStockTransfersWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStockTransfersWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStockTransfersWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetStockTransfersWithDataQuery,
    GetStockTransfersWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetStockTransfersWithDataQuery, GetStockTransfersWithDataQueryVariables>(
    GetStockTransfersWithDataDocument,
    options
  );
}
export function useGetStockTransfersWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetStockTransfersWithDataQuery,
    GetStockTransfersWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetStockTransfersWithDataQuery,
    GetStockTransfersWithDataQueryVariables
  >(GetStockTransfersWithDataDocument, options);
}
export type GetStockTransfersWithDataQueryHookResult = ReturnType<
  typeof useGetStockTransfersWithDataQuery
>;
export type GetStockTransfersWithDataLazyQueryHookResult = ReturnType<
  typeof useGetStockTransfersWithDataLazyQuery
>;
export type GetStockTransfersWithDataQueryResult = Apollo.QueryResult<
  GetStockTransfersWithDataQuery,
  GetStockTransfersWithDataQueryVariables
>;
export const GetAllAddressNamesDocument = gql`
  query getAllAddressNames {
    address {
      id
      address_name
    }
  }
`;

/**
 * __useGetAllAddressNamesQuery__
 *
 * To run a query within a React component, call `useGetAllAddressNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllAddressNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllAddressNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllAddressNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllAddressNamesQuery, GetAllAddressNamesQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllAddressNamesQuery, GetAllAddressNamesQueryVariables>(
    GetAllAddressNamesDocument,
    options
  );
}
export function useGetAllAddressNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetAllAddressNamesQuery,
    GetAllAddressNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllAddressNamesQuery, GetAllAddressNamesQueryVariables>(
    GetAllAddressNamesDocument,
    options
  );
}
export type GetAllAddressNamesQueryHookResult = ReturnType<typeof useGetAllAddressNamesQuery>;
export type GetAllAddressNamesLazyQueryHookResult = ReturnType<
  typeof useGetAllAddressNamesLazyQuery
>;
export type GetAllAddressNamesQueryResult = Apollo.QueryResult<
  GetAllAddressNamesQuery,
  GetAllAddressNamesQueryVariables
>;
export const GetWarehouseByIdDocument = gql`
  query getWarehouseById($warehouseId: Int) {
    warehouse(where: { id: { _eq: $warehouseId } }) {
      id
      address {
        address_description
        address_line_1
        address_line_2
        organisation {
          organisation_name
          id
        }
        address_line_3
        address_name
        city
        country {
          country_name
          country_code
        }
        id
        name
        postcode
        phone_number
      }
      warehouse_name
      organisation {
        id
        organisation_name
      }
    }
  }
`;

/**
 * __useGetWarehouseByIdQuery__
 *
 * To run a query within a React component, call `useGetWarehouseByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehouseByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehouseByIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetWarehouseByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<GetWarehouseByIdQuery, GetWarehouseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWarehouseByIdQuery, GetWarehouseByIdQueryVariables>(
    GetWarehouseByIdDocument,
    options
  );
}
export function useGetWarehouseByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetWarehouseByIdQuery, GetWarehouseByIdQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWarehouseByIdQuery, GetWarehouseByIdQueryVariables>(
    GetWarehouseByIdDocument,
    options
  );
}
export type GetWarehouseByIdQueryHookResult = ReturnType<typeof useGetWarehouseByIdQuery>;
export type GetWarehouseByIdLazyQueryHookResult = ReturnType<typeof useGetWarehouseByIdLazyQuery>;
export type GetWarehouseByIdQueryResult = Apollo.QueryResult<
  GetWarehouseByIdQuery,
  GetWarehouseByIdQueryVariables
>;
export const GetWarehousesWithDataDocument = gql`
  query getWarehousesWithData {
    warehouse {
      address {
        address_line_1
        address_line_2
        address_line_3
        city
        postcode
        country {
          country_name
        }
      }
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetWarehousesWithDataQuery__
 *
 * To run a query within a React component, call `useGetWarehousesWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWarehousesWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWarehousesWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWarehousesWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWarehousesWithDataQuery,
    GetWarehousesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWarehousesWithDataQuery, GetWarehousesWithDataQueryVariables>(
    GetWarehousesWithDataDocument,
    options
  );
}
export function useGetWarehousesWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWarehousesWithDataQuery,
    GetWarehousesWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWarehousesWithDataQuery, GetWarehousesWithDataQueryVariables>(
    GetWarehousesWithDataDocument,
    options
  );
}
export type GetWarehousesWithDataQueryHookResult = ReturnType<typeof useGetWarehousesWithDataQuery>;
export type GetWarehousesWithDataLazyQueryHookResult = ReturnType<
  typeof useGetWarehousesWithDataLazyQuery
>;
export type GetWarehousesWithDataQueryResult = Apollo.QueryResult<
  GetWarehousesWithDataQuery,
  GetWarehousesWithDataQueryVariables
>;
export const GetProductStockByParamsDocument = gql`
  query getProductStockByParams($warehouseId: Int!, $organisationId: Int!, $baseProductId: Int!) {
    product_stock(
      where: {
        location: { warehouse_id: { _eq: $warehouseId } }
        product: { base_product_id: { _eq: $baseProductId } }
        quantity: { _gte: 1 }
        _or: [
          { organisation: { organisation_name: { _eq: "sourceful" } } }
          { allocated_to_organisation_id: { _eq: $organisationId } }
        ]
      }
    ) {
      location {
        location_name
        id
        warehouse {
          warehouse_name
          id
        }
      }
      quantity
      stock_status {
        id
        stock_status_name
      }
      id
      organisation {
        organisation_name
        id
      }
      product {
        id
        product_name
        base_product_quantity
        base_product_id
      }
      stock_condition {
        id
        stock_condition_name
      }
    }
  }
`;

/**
 * __useGetProductStockByParamsQuery__
 *
 * To run a query within a React component, call `useGetProductStockByParamsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetProductStockByParamsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetProductStockByParamsQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *      organisationId: // value for 'organisationId'
 *      baseProductId: // value for 'baseProductId'
 *   },
 * });
 */
export function useGetProductStockByParamsQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetProductStockByParamsQuery,
    GetProductStockByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetProductStockByParamsQuery, GetProductStockByParamsQueryVariables>(
    GetProductStockByParamsDocument,
    options
  );
}
export function useGetProductStockByParamsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetProductStockByParamsQuery,
    GetProductStockByParamsQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetProductStockByParamsQuery, GetProductStockByParamsQueryVariables>(
    GetProductStockByParamsDocument,
    options
  );
}
export type GetProductStockByParamsQueryHookResult = ReturnType<
  typeof useGetProductStockByParamsQuery
>;
export type GetProductStockByParamsLazyQueryHookResult = ReturnType<
  typeof useGetProductStockByParamsLazyQuery
>;
export type GetProductStockByParamsQueryResult = Apollo.QueryResult<
  GetProductStockByParamsQuery,
  GetProductStockByParamsQueryVariables
>;
export const GetWorkOrderOrganisationNamesDocument = gql`
  query getWorkOrderOrganisationNames {
    organisation {
      id
      organisation_name
    }
  }
`;

/**
 * __useGetWorkOrderOrganisationNamesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderOrganisationNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderOrganisationNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderOrganisationNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkOrderOrganisationNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrderOrganisationNamesQuery,
    GetWorkOrderOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkOrderOrganisationNamesQuery,
    GetWorkOrderOrganisationNamesQueryVariables
  >(GetWorkOrderOrganisationNamesDocument, options);
}
export function useGetWorkOrderOrganisationNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderOrganisationNamesQuery,
    GetWorkOrderOrganisationNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkOrderOrganisationNamesQuery,
    GetWorkOrderOrganisationNamesQueryVariables
  >(GetWorkOrderOrganisationNamesDocument, options);
}
export type GetWorkOrderOrganisationNamesQueryHookResult = ReturnType<
  typeof useGetWorkOrderOrganisationNamesQuery
>;
export type GetWorkOrderOrganisationNamesLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderOrganisationNamesLazyQuery
>;
export type GetWorkOrderOrganisationNamesQueryResult = Apollo.QueryResult<
  GetWorkOrderOrganisationNamesQuery,
  GetWorkOrderOrganisationNamesQueryVariables
>;
export const GetWorkOrderRecipeByIdDocument = gql`
  query getWorkOrderRecipeById($recipeId: Int) {
    recipe(where: { id: { _eq: $recipeId } }) {
      id
      recipe_name
      recipe_inputs {
        id
        quantity
        product {
          id
          product_name
          base_product_id
          case_product_id
          case_product_quantity
        }
        product_id
      }
      recipe_outputs {
        id
        quantity
        product {
          id
          product_name
        }
      }
    }
  }
`;

/**
 * __useGetWorkOrderRecipeByIdQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderRecipeByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderRecipeByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderRecipeByIdQuery({
 *   variables: {
 *      recipeId: // value for 'recipeId'
 *   },
 * });
 */
export function useGetWorkOrderRecipeByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrderRecipeByIdQuery,
    GetWorkOrderRecipeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrderRecipeByIdQuery, GetWorkOrderRecipeByIdQueryVariables>(
    GetWorkOrderRecipeByIdDocument,
    options
  );
}
export function useGetWorkOrderRecipeByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderRecipeByIdQuery,
    GetWorkOrderRecipeByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkOrderRecipeByIdQuery, GetWorkOrderRecipeByIdQueryVariables>(
    GetWorkOrderRecipeByIdDocument,
    options
  );
}
export type GetWorkOrderRecipeByIdQueryHookResult = ReturnType<
  typeof useGetWorkOrderRecipeByIdQuery
>;
export type GetWorkOrderRecipeByIdLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderRecipeByIdLazyQuery
>;
export type GetWorkOrderRecipeByIdQueryResult = Apollo.QueryResult<
  GetWorkOrderRecipeByIdQuery,
  GetWorkOrderRecipeByIdQueryVariables
>;
export const GetWorkOrderRecipeNamesDocument = gql`
  query getWorkOrderRecipeNames {
    recipe {
      id
      recipe_name
    }
  }
`;

/**
 * __useGetWorkOrderRecipeNamesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderRecipeNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderRecipeNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderRecipeNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkOrderRecipeNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrderRecipeNamesQuery,
    GetWorkOrderRecipeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrderRecipeNamesQuery, GetWorkOrderRecipeNamesQueryVariables>(
    GetWorkOrderRecipeNamesDocument,
    options
  );
}
export function useGetWorkOrderRecipeNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderRecipeNamesQuery,
    GetWorkOrderRecipeNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkOrderRecipeNamesQuery, GetWorkOrderRecipeNamesQueryVariables>(
    GetWorkOrderRecipeNamesDocument,
    options
  );
}
export type GetWorkOrderRecipeNamesQueryHookResult = ReturnType<
  typeof useGetWorkOrderRecipeNamesQuery
>;
export type GetWorkOrderRecipeNamesLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderRecipeNamesLazyQuery
>;
export type GetWorkOrderRecipeNamesQueryResult = Apollo.QueryResult<
  GetWorkOrderRecipeNamesQuery,
  GetWorkOrderRecipeNamesQueryVariables
>;
export const GetWorkOrderWarehouseNamesDocument = gql`
  query getWorkOrderWarehouseNames {
    warehouse {
      id
      warehouse_name
    }
  }
`;

/**
 * __useGetWorkOrderWarehouseNamesQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderWarehouseNamesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderWarehouseNamesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderWarehouseNamesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkOrderWarehouseNamesQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrderWarehouseNamesQuery,
    GetWorkOrderWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrderWarehouseNamesQuery, GetWorkOrderWarehouseNamesQueryVariables>(
    GetWorkOrderWarehouseNamesDocument,
    options
  );
}
export function useGetWorkOrderWarehouseNamesLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderWarehouseNamesQuery,
    GetWorkOrderWarehouseNamesQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkOrderWarehouseNamesQuery,
    GetWorkOrderWarehouseNamesQueryVariables
  >(GetWorkOrderWarehouseNamesDocument, options);
}
export type GetWorkOrderWarehouseNamesQueryHookResult = ReturnType<
  typeof useGetWorkOrderWarehouseNamesQuery
>;
export type GetWorkOrderWarehouseNamesLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderWarehouseNamesLazyQuery
>;
export type GetWorkOrderWarehouseNamesQueryResult = Apollo.QueryResult<
  GetWorkOrderWarehouseNamesQuery,
  GetWorkOrderWarehouseNamesQueryVariables
>;
export const GetWorkOrderFulfilmentByIdDocument = gql`
  query getWorkOrderFulfilmentById($workOrderId: Int!) {
    work_order(where: { id: { _eq: $workOrderId } }) {
      id
      due_date
      completed_quantity
      cancelled_quantity
      organisation {
        organisation_name
        id
      }
      work_order_status {
        work_order_status_name
        id
      }
      external_work_order_id
      last_updated
      quantity
      warehouse {
        id
        warehouse_name
      }
      recipe {
        id
        is_draft
        recipe_name
        recipe_outputs {
          id
          product {
            id
            product_name
          }
          quantity
        }
      }
      work_order_input_lines {
        id
        quantity
        product_stock {
          id
          barcode_uuid
          location {
            location_name
            id
          }
          product {
            product_name
            id
          }
          organisation {
            id
            organisation_name
          }
        }
      }
      work_order_output_lines {
        id
        product {
          id
          product_name
        }
        quantity
        product_stocks {
          quantity
          product {
            product_name
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkOrderFulfilmentByIdQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderFulfilmentByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderFulfilmentByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderFulfilmentByIdQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetWorkOrderFulfilmentByIdQuery(
  baseOptions: Apollo.QueryHookOptions<
    GetWorkOrderFulfilmentByIdQuery,
    GetWorkOrderFulfilmentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrderFulfilmentByIdQuery, GetWorkOrderFulfilmentByIdQueryVariables>(
    GetWorkOrderFulfilmentByIdDocument,
    options
  );
}
export function useGetWorkOrderFulfilmentByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderFulfilmentByIdQuery,
    GetWorkOrderFulfilmentByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkOrderFulfilmentByIdQuery,
    GetWorkOrderFulfilmentByIdQueryVariables
  >(GetWorkOrderFulfilmentByIdDocument, options);
}
export type GetWorkOrderFulfilmentByIdQueryHookResult = ReturnType<
  typeof useGetWorkOrderFulfilmentByIdQuery
>;
export type GetWorkOrderFulfilmentByIdLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderFulfilmentByIdLazyQuery
>;
export type GetWorkOrderFulfilmentByIdQueryResult = Apollo.QueryResult<
  GetWorkOrderFulfilmentByIdQuery,
  GetWorkOrderFulfilmentByIdQueryVariables
>;
export const GetWorkOrderLocationNamesByWarehouseIdDocument = gql`
  query getWorkOrderLocationNamesByWarehouseId($warehouseId: Int) {
    location(where: { warehouse_id: { _eq: $warehouseId }, can_hold_stock: { _eq: true } }) {
      id
      location_name
    }
  }
`;

/**
 * __useGetWorkOrderLocationNamesByWarehouseIdQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderLocationNamesByWarehouseIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderLocationNamesByWarehouseIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderLocationNamesByWarehouseIdQuery({
 *   variables: {
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetWorkOrderLocationNamesByWarehouseIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrderLocationNamesByWarehouseIdQuery,
    GetWorkOrderLocationNamesByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<
    GetWorkOrderLocationNamesByWarehouseIdQuery,
    GetWorkOrderLocationNamesByWarehouseIdQueryVariables
  >(GetWorkOrderLocationNamesByWarehouseIdDocument, options);
}
export function useGetWorkOrderLocationNamesByWarehouseIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderLocationNamesByWarehouseIdQuery,
    GetWorkOrderLocationNamesByWarehouseIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<
    GetWorkOrderLocationNamesByWarehouseIdQuery,
    GetWorkOrderLocationNamesByWarehouseIdQueryVariables
  >(GetWorkOrderLocationNamesByWarehouseIdDocument, options);
}
export type GetWorkOrderLocationNamesByWarehouseIdQueryHookResult = ReturnType<
  typeof useGetWorkOrderLocationNamesByWarehouseIdQuery
>;
export type GetWorkOrderLocationNamesByWarehouseIdLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderLocationNamesByWarehouseIdLazyQuery
>;
export type GetWorkOrderLocationNamesByWarehouseIdQueryResult = Apollo.QueryResult<
  GetWorkOrderLocationNamesByWarehouseIdQuery,
  GetWorkOrderLocationNamesByWarehouseIdQueryVariables
>;
export const GetWorkOrderViewByIdDocument = gql`
  query getWorkOrderViewById($workOrderId: Int) {
    work_order(where: { id: { _eq: $workOrderId } }) {
      due_date
      external_work_order_id
      completed_quantity
      cancelled_quantity
      organisation {
        organisation_name
        id
      }
      work_order_status {
        work_order_status_name
        id
      }
      quantity
      recipe {
        recipe_name
      }
      warehouse {
        warehouse_name
      }
      work_order_input_lines {
        id
        quantity
        product_stock {
          product {
            product_name
          }
        }
      }
      work_order_output_lines {
        id
        product {
          id
          product_name
        }
        quantity
        product_stocks {
          initial_quantity
          product {
            product_name
          }
          location {
            id
            location_name
          }
        }
      }
    }
  }
`;

/**
 * __useGetWorkOrderViewByIdQuery__
 *
 * To run a query within a React component, call `useGetWorkOrderViewByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrderViewByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrderViewByIdQuery({
 *   variables: {
 *      workOrderId: // value for 'workOrderId'
 *   },
 * });
 */
export function useGetWorkOrderViewByIdQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrderViewByIdQuery,
    GetWorkOrderViewByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrderViewByIdQuery, GetWorkOrderViewByIdQueryVariables>(
    GetWorkOrderViewByIdDocument,
    options
  );
}
export function useGetWorkOrderViewByIdLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrderViewByIdQuery,
    GetWorkOrderViewByIdQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkOrderViewByIdQuery, GetWorkOrderViewByIdQueryVariables>(
    GetWorkOrderViewByIdDocument,
    options
  );
}
export type GetWorkOrderViewByIdQueryHookResult = ReturnType<typeof useGetWorkOrderViewByIdQuery>;
export type GetWorkOrderViewByIdLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrderViewByIdLazyQuery
>;
export type GetWorkOrderViewByIdQueryResult = Apollo.QueryResult<
  GetWorkOrderViewByIdQuery,
  GetWorkOrderViewByIdQueryVariables
>;
export const GetWorkOrdersWithDataDocument = gql`
  query getWorkOrdersWithData {
    work_order(order_by: { last_updated: desc }) {
      id
      due_date
      external_work_order_id
      completed_quantity
      cancelled_quantity
      work_order_status {
        work_order_status_name
        id
      }
      last_updated
      quantity
      recipe {
        id
        recipe_name
      }
      warehouse {
        id
        warehouse_name
      }
      organisation {
        id
        organisation_name
      }
    }
  }
`;

/**
 * __useGetWorkOrdersWithDataQuery__
 *
 * To run a query within a React component, call `useGetWorkOrdersWithDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWorkOrdersWithDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWorkOrdersWithDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetWorkOrdersWithDataQuery(
  baseOptions?: Apollo.QueryHookOptions<
    GetWorkOrdersWithDataQuery,
    GetWorkOrdersWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetWorkOrdersWithDataQuery, GetWorkOrdersWithDataQueryVariables>(
    GetWorkOrdersWithDataDocument,
    options
  );
}
export function useGetWorkOrdersWithDataLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<
    GetWorkOrdersWithDataQuery,
    GetWorkOrdersWithDataQueryVariables
  >
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetWorkOrdersWithDataQuery, GetWorkOrdersWithDataQueryVariables>(
    GetWorkOrdersWithDataDocument,
    options
  );
}
export type GetWorkOrdersWithDataQueryHookResult = ReturnType<typeof useGetWorkOrdersWithDataQuery>;
export type GetWorkOrdersWithDataLazyQueryHookResult = ReturnType<
  typeof useGetWorkOrdersWithDataLazyQuery
>;
export type GetWorkOrdersWithDataQueryResult = Apollo.QueryResult<
  GetWorkOrdersWithDataQuery,
  GetWorkOrdersWithDataQueryVariables
>;
