import { Page } from "../../../../../shared/components/templates/Page";

export default function ViewAdminDashboard() {
  return (
    <>
      <Page error={false} isLoading={false} title={"Admin Dashboard"}>
        <></>
      </Page>
    </>
  );
}
