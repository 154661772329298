export interface UpdateProductForm {
  weight_grams: string;
  length_mm: string;
  width_mm: string;
  height_mm: string;
}

export const initialFieldValues: UpdateProductForm = {
  weight_grams: "",
  length_mm: "",
  width_mm: "",
  height_mm: "",
};

export const fieldNames = {
  weight_grams: "weight_grams",
  length_mm: "length_mm",
  width_mm: "width_mm",
  height_mm: "height_mm",
};
