import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

interface InputCreateAttributeType {
  attribute_type: string;
}

export const createAttributeType = async (input: InputCreateAttributeType) => {
  try {
    const response = await PRODUCT_CATALOGUE_API.post("/attribute/types", input);
    const data = response.data;
    return {
      data: data,
    };
  } catch (err) {
    console.log("Error in creating attribute type API call", err);
    throw err;
  }
};
