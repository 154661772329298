import { PRODUCT_CATALOGUE_API } from "../../../../../api/baseConfig";
import { DeliveryCosts } from "../../shared/types";

export interface UpdateSkuMetaData {
  uuid: string;
  sku_source_type_id: number;
  pack_sizes: number[];
  image_url: string;
  delivery_costs: DeliveryCosts[];
  manufacturing_lead_time: ManufacturingLeadTime;
}

export interface ManufacturingLeadTime {
  min?: number;
  max?: number;
  units?: string;
  businessDaysOnly?: boolean;
}

export const updateSkuMetadata = async (body: UpdateSkuMetaData) => {
  try {
    return await PRODUCT_CATALOGUE_API.put("/skumetadata", body);
  } catch (err) {
    console.log("Error in update sku metadata API call", err);
    throw err;
  }
};
