import {
  CREATE_BASE_PRODUCT_FAILED,
  CREATE_BASE_PRODUCT_LOADING,
  CREATE_BASE_PRODUCT_RESET,
  CREATE_BASE_PRODUCT_SUCCESS,
} from "../types";

export interface CreateBaseProductReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateBaseProductReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createBaseProductReducer(
  state: CreateBaseProductReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_BASE_PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_BASE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_BASE_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_BASE_PRODUCT_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
