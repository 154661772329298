import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { SHIPMENT_UPDATE_TYPE } from "../../../shared/models";
import { ShipmentItem } from "../graphql/types";

export const mapShipmentUpdateTypeToDropdownItem = () => {
  return [
    {
      label: "In Transit",
      value: SHIPMENT_UPDATE_TYPE.IN_TRANSIT,
    },
    {
      label: "Update ETA",
      value: SHIPMENT_UPDATE_TYPE.UPDATED_ETA,
    },
    {
      label: "Deliver",
      value: SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE,
    },
  ];
};

export const mapShipmentOrganisationToDropdownItem = (shipment: ShipmentItem): DropdownItem => ({
  value: shipment.client_order.organisation.id,
  label: shipment.client_order.organisation.organisation_name,
});

export const mapShipmentClientOrderToDropdownItem = (shipment: ShipmentItem): DropdownItem => ({
  value: shipment.client_order.id,
  label: shipment.client_order.external_client_order_id,
});

export const mapShipmentPurchaseOrderToDropdownItem = (shipment: ShipmentItem): DropdownItem => ({
  value: shipment.purchase_order!.id,
  label: shipment.purchase_order!.purchase_order_uuid,
});
