import { ApolloError } from "@apollo/client";

import { useGetClientOrderShipmentsQuery } from "../../../../../../generated/graphql_logistics";
import { GRAPHQL_LOGISTICS } from "../../../../../../shared/constants";
import { ClientOrderShipmentsListItem } from "../types";

export interface HookResult {
  clientOrder?: ClientOrderShipmentsListItem;
  clientOrderError?: ApolloError;
  clientOrderLoading: boolean;
  refetchClientOrder: () => Promise<void>;
}

export const useClientOrderShipments = (clientOrderId: number): HookResult => {
  const { data, error, loading, refetch } = useGetClientOrderShipmentsQuery({
    context: { clientName: GRAPHQL_LOGISTICS },
    variables: { clientOrderId: clientOrderId },
  });

  return {
    clientOrder: data?.client_order[0] || undefined,
    clientOrderError: error,
    clientOrderLoading: loading,
    refetchClientOrder: async () => {
      refetch({ clientOrderId: clientOrderId });
    },
  };
};
