import { ApolloError } from "@apollo/client";

import { useGetProductLineAttachmentsByClientOrderLineIdLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { AttachmentItem } from "../types";

export interface HookResult {
  productLineAttachments?: AttachmentItem[];
  productLineAttachmentsLoading: boolean;
  productLineAttachmentsError?: ApolloError;
  getProductLineAttachmentsByClientOrderLineId: (options: Options) => Promise<void>;
}

export interface Options {
  clientOrderLineIds: number[];
}

export const useProductLineAttachmentsByClientOrderLineId = (): HookResult => {
  const [getProductLineAttachmentsByClientOrderLineId, { data, loading, error }] =
    useGetProductLineAttachmentsByClientOrderLineIdLazyQuery({
      context: {
        clientName: GRAPHQL_SOURCING,
      },
    });

  return {
    productLineAttachments: data?.client_order_line.map(col => col.folder.attachments).flat(),
    productLineAttachmentsLoading: loading,
    productLineAttachmentsError: error,
    getProductLineAttachmentsByClientOrderLineId: async ({ clientOrderLineIds }: Options) => {
      getProductLineAttachmentsByClientOrderLineId({ variables: { clientOrderLineIds } });
    },
  };
};
