import { toast } from "react-toastify";

export const validateUniqueBaseProducts = (baseProducts: number[]) => {
  const uniqueBaseProducts = new Set(baseProducts);
  if (uniqueBaseProducts.size !== baseProducts.length) {
    toast.error("Please make sure to use unique base products");
    return false;
  }

  return true;
};
