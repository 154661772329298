import { css, styled } from "@sourceful/shared-components";

export const StyledDate = styled("div", {
  color: "#005AE1",
  fontWeight: "bold",
});

export const ArrowButton = styled("button", {
  padding: "0px",
  border: "none",
  color: "#8D8D8D",
  "&:hover": {
    color: "black",
  },
});

export const SubHeaderWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginBottom: "30px",
  position: "relative",
  width: "100%",
});

export const DateWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  fontSize: "25px",
  lineHeight: "18px",
});

export const Label = styled("label", {
  fontSize: "15px",
  fontWeight: "bold",
  color: "#000000",
  variants: {
    isGray: {
      true: {
        color: "#8D8D8D",
      },
    },
    isRequired: {
      true: {
        "&::after": {
          content: "*",
        },
      },
    },
    isInlineBlock: {
      true: {
        display: "inline-block",
        marginInlineEnd: "20px",
      },
    },
    warning: {
      true: {
        color: "#D02257",
      },
    },
    success: {
      true: {
        color: "#78D178",
      },
    },
  },
});

export const DataPoint = styled("div", {
  minWidth: "150px",
  marginLeft: "10px",
  marginRight: "10px",
  marginTop: "5px",
  width: "280px",
  variants: {
    important: {
      true: {
        fontWeight: "700",
      },
    },
    withUniqueMargin: {
      true: {
        margin: "0px",
        marginRight: "15px",
        marginBottom: "15px",
      },
    },
    size: {
      small: {
        width: "150px",
      },
    },
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    halfWidth: {
      true: {
        width: "45%",
        alignContent: "left",
      },
    },
    containsButton: {
      true: {
        display: "flex",
        justifyContent: "flex-end",
      },
    },
    centralisedButton: {
      true: {
        display: "flex",
        justifyContent: "space-around",
      },
    },
    withFlexRow: {
      true: {
        display: "flex",
        alignItems: "center",
      },
    },
    centeredText: {
      true: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
    },
  },
});

export const Box = styled("div", {
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  width: "280px",
  padding: "5px 10px",
  background: "white",
  color: "grey",
  variants: {
    warning: { true: { color: "#D02257" } },
    success: {
      true: {
        color: "#78D178",
      },
    },
    smallMargin: {
      true: {
        marginRight: "20px",
      },
    },
    type: {
      messageBox: {
        width: "100%",
        marginTop: "40px",
        display: "flex",
        justifyContent: "center",
      },
      fullWidth: {
        width: "100%",
        display: "flex",
      },
      fitToContent: {
        width: "fit-content",
      },
    },
  },
});

export const InfoBox = styled("div", {
  border: "3px solid",
  borderRadius: "10px",
  width: "280px",
  padding: "5px 10px",
  fontWeight: "700",
  variants: {
    type: {
      good: {
        borderColor: "#78D178",
        color: "#78D178",
      },
      medium: {
        borderColor: "#DE7A10",
        color: "#DE7A10",
      },
      bad: {
        borderColor: "#D02257",
        color: "#D02257",
      },
    },
  },
});

export const TopPanelInputsWrapper = styled("div", {
  display: "flex",
  flexWrap: "wrap",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const PrimaryLineDataWrapper = styled("div", {
  display: "flex",
  marginTop: "20px",
  flexWrap: "wrap",
  justifyContent: "space-between",
  variants: {
    marginSize: {
      smallMargin: {
        marginTop: "0px",
      },
    },
    alignStart: {
      true: {
        justifyContent: "flex-start",
      },
    },
  },
});

export const FormattedLineDataWrapper = styled("div", {
  marginTop: "20px",
  variants: {
    marginSize: {
      smallMargin: {
        marginTop: "0px",
      },
    },
  },
});

export const PrimaryLineWrapper = styled("div", {
  background: "rgba(0, 90, 225, 0.01)",
  border: " 0.5px solid #B9B9BB",
  borderRadius: "10px",
  padding: "20px",
  marginTop: "30px",
});

export const Title = styled("p", {
  fontSize: "17px",
  fontWeight: "bold",
  margin: "0px",
  marginRight: "10px",
});

export const Subtitle = styled("p", {
  fontSize: "15px",
  color: "#8D8D8D",
  margin: "0px",
  marginTop: "5px",
});

export const SecondaryLineWrapper = styled("div", {
  background: "#FFFFFF",
  borderRadius: "10px",
  border: "1px solid rgb(196, 196, 196)",
  padding: "20px 20px",
  width: "100%",
  marginTop: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  variants: {
    type: {
      withIndex: {
        position: "relative",
      },
      darkBackground: {
        backgroundColor: "rgba(0, 90, 225, 0.01)",
      },
    },
  },
});

export const SourcefulBlueButton = css({
  background: "#005AE1",
  color: "#FFFFFF",
  border: "1px solid #005AE1",
  padding: "10px 40px",
  variants: {
    isWithinTable: {
      true: {
        padding: "5px 10px",
      },
    },
  },
});

export const SourcefulGreenButton = css({
  background: "#78D178",
  color: "#FFFFFF",
  border: "1px solid #005AE1",
  padding: "10px 40px",
  variants: {
    isWithinTable: {
      true: {
        padding: "5px 10px",
      },
    },
  },
});

export const SourcefulWhiteButton = css({
  background: "#FFFFFF",
  variants: {
    type: {
      blueText: {
        marginTop: "20px",
        color: "#005AE1",
      },
      disabled: {
        background: "#FFFFFF",
        "&:hover": {
          cursor: "not-allowed",
        },
      },
    },
  },
});

export const WMSButtonGroup = css({
  display: "flex",
  justifyContent: "flex-end",
  "& button + *": {
    marginLeft: "8px",
  },
  variants: {
    type: {
      largeMargin: {
        marginTop: "30px",
      },
      smallMargin: {
        marginTop: "10px",
      },
      extraLargeMargin: {
        marginTop: "60px",
      },
    },
    withinSecondaryLine: {
      true: {
        width: "100%",
      },
    },
  },
});

export const QRPrintButtonWrapper = styled("div", {
  width: "100%",
  display: "flex",
  justifyContent: "center",
  marginTop: "10px",
});

export const reactSelectStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
    width: "auto",
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#8D8D8D",
  }),
};

export const reactSelectDropdownStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
    width: "200px",
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#8D8D8D",
  }),
};

export const InnerPanelWrapper = styled("div", {
  margin: "0px",
  padding: "25px",
});

export const InputContainer = styled("div", {
  paddingTop: "20px",
});

export const PanelWrapper = styled("div", {
  marginTop: 30,
});

export const ButtonWrapper = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
});

export const FlexTitle = styled("div", {
  display: "flex",
  alignItems: "center",
  flexDirection: "row",
});

export const FlexHolder = styled("div", {
  display: "flex",
  width: "100%",
  alignItems: "center",
  flexDirection: "row",
  justifyContent: "flex-start",
  gap: "10px",
});

export const CertificationBadge = styled("div", {
  backgroundColor: "#005AE1",
  color: "#FFFFFF",
  display: "flex",
  minHeight: "35px",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  width: "110px",
  borderRadius: "5px",
  variants: {
    type: {
      FSC: { backgroundColor: "#1EAE98" },
      GRS: { background: "#00B7FF" },
    },
  },
});

// ToDo: Delete after cleanup
export const RectangleStatusBadge = styled("div", {
  backgroundColor: "#005AE1",
  color: "#FFFFFF",
  display: "flex",
  minHeight: "35px",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  width: "110px",
  borderRadius: "5px",
  variants: {
    status: {
      InProgress: { backgroundColor: "#FFB500" },
      Done: { background: "#78D178" },
      Rejected: { background: "#D02257" },
      Cancelled: { background: "#747477" },
      Complete: { background: "#78D178" },
      New: { background: "#005AE1" },
      Reserved: { background: "#FFA500" },
      Available: { background: "#78D178" },
      DueIn: { background: "#FFB500" },
      Missing: { background: "#D02257" },
      FoundUnexpected: { background: "#747477" },
      Pending: { background: "#B9B9B9" },
      Fulfilled: { background: "#005AE1" },
      Delivered: { background: "#78D178" },
      Approved: { background: "#78D178" },
      Passed: { background: "#78D178" },
      Failed: { background: "#D02257" },
      Archived: { background: "#D02257" },
    },
  },
});

export const DateInput = styled("input", {
  display: "block",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  width: "280px",
});

export const IndexHolder = styled("div", {
  backgroundColor: "#000C32",
  width: "30px",
  height: "30px",
  textAlign: "center",
  position: "absolute",
  top: "-15px",
  left: "-15px",
  borderRadius: "50%",

  variants: {
    type: {
      delete: {
        color: "white",
        backgroundColor: "#D02257",
        right: "-15px",
        left: "unset",
        cursor: "pointer",
      },
    },
  },
});

export const Index = styled("p", {
  margin: "0px",
  color: "#FFFFFF",
  fontSize: "20px",
  fontWeight: "bold",
});

export const StyledForm = styled("form", {});

export const SecondaryLineDataWrapper = styled("div", {
  flexDirection: "row",
  marginTop: "20px",
  width: "100%",
  flexWrap: "wrap",
  display: "flex",
  justifyContent: "space-between",
  variants: {
    alignTitle: {
      true: { alignItems: "center" },
    },
  },
});

export const LinkLabel = styled("label", {
  marginLeft: "10px",
  fontSize: "12px",

  fontWeight: "lighter",
  color: "blue",
  cursor: "pointer",
  textDecoration: "underline",
  variants: {
    isGray: {
      true: {
        color: "#8D8D8D",
      },
    },
  },
});

export const IconWrapper = styled("button", {
  display: "flex",
  alignItems: "center",
  cursor: "pointer",
  border: "none",
  color: "#005AE1",
  variants: {
    removalIconWrapper: {
      true: {
        width: "100%",
        justifyContent: "flex-end",
      },
    },
    withinList: {
      true: {
        color: "black",
        cursor: "default",
      },
    },
    approved: {
      true: {
        color: "#78D178",
      },
    },
    rejected: {
      true: {
        color: "#D02257",
      },
    },
    sourcefulBlue: {
      true: {
        color: "#005AE1",
      },
    },
    isGray: {
      true: {
        color: "#8D8D8D",
      },
    },
  },
});

export const AlignIconCenter = styled("div", {
  textAlign: "center",
});

export const CardWrapper = styled("div", {
  display: "flex",
  backgroundColor: "white",
  width: "332px",
  height: "379px",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  flexDirection: "column",
  marginTop: "10px",
});

export const CardTopSectionWrapper = styled("div", {
  width: "100%",
  paddingInlineStart: "23px",
  paddingInlineEnd: "23px",
  display: "flex",
  flexDirection: "column",
});

export const CardTitle = styled("div", {
  width: "100%",
  fontFamily: "Space Grotesk",
  fontSize: "26px",
  lineHeight: "18px",
  marginTop: "25px",
  display: "flex",
  marginBottom: "25px",
  weight: "700",
  justifyContent: "center",
  fontWeight: 700,
});

export const ScrollBar = styled("div", {
  backgroundColor: "$palette3_colour2",
  marginTop: "10px",
  overflow: "auto",
  marginLeft: "30px",
  marginRight: "12px",
  marginBottom: "30px",
});

export const ScrollItem = styled("li", {
  marginTop: "15px",
  listStyleType: "none",
  marginBottom: "15px",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
});

export const CardIconTextWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const LinkText = styled("div", {
  "&:hover": {
    color: "#005AE1",
    textDecoration: "underline",
    fontWeight: "bold",
  },
});

export const ColumnWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  variants: {
    stretchedSpaceBetween: {
      true: {
        justifyContent: "space-between",
        alignSelf: "stretch",
      },
    },
    flexGrowFillWidth: {
      true: {
        flexGrow: "2",
      },
    },
  },
});

export const RowWrapper = styled("div", {
  marginBottom: "8px",
  variants: {
    type: {
      title: {
        fontWeight: "bold",
        textDecoration: "underline",
      },
      newCase: {
        color: "#78D178",
      },
    },
  },
});

export const ButtonPositioner = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
  marginTop: "10px",
  variants: {
    fullWidth: {
      true: {
        width: "100%",
      },
    },
    centralised: {
      true: {
        width: "100%",
        justifyContent: "center",
      },
    },
  },
});

export const NumberToggleWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

export const ToggleButtonWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});

export const ToggleNumberWrapper = styled("div", {
  padding: "10px 30px",
  fontSize: "30px",
});

export const CenterTextWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "center",
  marginTop: "30px",
  fontSize: "18px",
});

export const BoldenedText = styled("div", {
  fontWeight: "bold",
  marginLeft: "5px",
});

export const HighlightedText = styled("div", {
  fontWeight: "bold",
  marginLeft: "5px",
  color: "#005AE1",
});

export const ErrorMessageButtonWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  color: "#D02257",
});

export const CenterButtonWrapper = styled("div", {
  justifyContent: "center",
  display: "flex",
  marginTop: "10px",
});

export const withinTablePadding = css({
  padding: "5px 10px",
});

export const FileInput = styled("input", {
  display: "none",
});

export const InlineCode = styled("pre", {
  fontSize: "0.75rem",
});

export const SubText = styled("div", {
  fontSize: "0.75rem",
  fontStyle: "italic",
});

export const ScrollableModal = styled("div", {
  maxHeight: "80vh",
  overflow: "auto",
});
