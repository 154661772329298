import { WorkOrderAndRecipeOutput } from "../formValues";
import { LocationNameItem } from "../graphql/types";

export const mapOutputLinesToDTO = (
  outputLines: WorkOrderAndRecipeOutput[],
  quantity: number,
  organisationId: number
) => {
  return outputLines
    .map(outputLine => {
      return outputLine.work_order_outputs.map(work_order_output => {
        return {
          location_id: work_order_output.location?.value!,
          product_id: outputLine.recipe_output_line.product.id,
          quantity: outputLine.recipe_output_line.quantity * quantity,
          organisation_id: organisationId,
        };
      });
    })
    .flat();
};

export const mapLocationNamesToDropdownObject = (locationNames: LocationNameItem[]) => {
  return locationNames.map(locationName => {
    return {
      label: locationName.location_name,
      value: locationName.id,
    };
  });
};
