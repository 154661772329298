import React from "react";

interface ErrorScreenProps {
  children: React.ReactNode;
}

function ErrorScreen({ children }: ErrorScreenProps) {
  return <div>{children}</div>;
}

export default ErrorScreen;
