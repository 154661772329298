import * as Yup from "yup";

export const validationSchema = Yup.object({
  currency_code: Yup.object()
    .shape({
      value: Yup.number(),
      label: Yup.string(),
    })
    .required("Currency Code is required")
    .typeError("Currency Code is required"),
  client_email_address: Yup.string().required("client email is required").email("Invalid email"),
  contact: Yup.object().shape({
    secondary_email: Yup.string().email("Invalid email").optional(),
    phone_number: Yup.string().required("Phone Number is required"),
    tax_number: Yup.string().optional(),
    user_uuid: Yup.string().required("User UUID is required"),
    org_id: Yup.string().required("Org ID is required"),
    org_name: Yup.string().required("Org Name is required"),
  }),
  billing_address: Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    first_name: Yup.string().required("First Name is required"),
    second_name: Yup.string().required("Second Name is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().optional(),
    address_line_3: Yup.string().optional(),
    city: Yup.string().required("City is required"),
    region_or_county_or_state: Yup.string().required("Region/County/State is required"),
    postcode: Yup.string().required("Postcode is required"),
    country_name: Yup.object()
      .shape({ value: Yup.number(), label: Yup.string() })
      .required("Country Name is required")
      .typeError("Country Name  is required"),
  }),
  shipping_address: Yup.object().shape({
    company_name: Yup.string().required("Company Name is required"),
    first_name: Yup.string().required("First Name is required"),
    second_name: Yup.string().required("Second Name is required"),
    address_line_1: Yup.string().required("Address Line 1 is required"),
    address_line_2: Yup.string().optional(),
    address_line_3: Yup.string().optional(),
    city: Yup.string().required("City is required"),
    region_or_county_or_state: Yup.string().required("Region/County/State is required"),
    postcode: Yup.string().required("Postcode is required"),
    country_name: Yup.object()
      .shape({ value: Yup.number(), label: Yup.string() })
      .required("Country Name is required")
      .typeError("Country Name  is required"),
    custom_delivery_instruction: Yup.string()
      .optional()
      .max(450, "Address Description is too long"),
  }),
  // sourceful_entity_us: Yup.object().shape({
  //   threshold: Yup.number().test(
  //     "us-threshold",
  //     "US Threshold exceeded 100,000, Please speak to Finance",
  //     value => {
  //       // can not exceed 100,000
  //       if (!value) return true;
  //       return value < 100000;
  //     }
  //   ),
  // }),
  // Array greater than 0
  // sku_id must be unique
  product_costs: Yup.array()
    .min(1, "Array should have more than 1 item")
    .of(
      Yup.object()
        .shape({
          sku_id: Yup.string()
            .test("no-space", "The string cannot contain spaces", value => {
              if (!value) return true; // If the string is not provided (undefined, null, etc.), just return true.
              // Check if the string contains spaces
              return !/\s/.test(value);
            })
            .required("SKU ID is required"),
          cost_description: Yup.string().required("Cost Description is required"),
          qty: Yup.number().required("Quantity is required"),
          unit_cost: Yup.number().required("Unit Cost is required"),
          vat: Yup.object()
            .shape({
              value: Yup.number(),
              label: Yup.string(),
            })
            .required("VAT is required")
            .typeError("VAT is required"),
          total: Yup.number().required("Total is required"),
          total_with_vat: Yup.number().required("Total with VAT is required"),
          base_product_id: Yup.number().required("Base Product ID is required"),
          base_product_name: Yup.string().required("Base Product Name is required"),
          base_product_version: Yup.number().required("Base Product Version is required"),
          content_attributes: Yup.object().required("Content Attributes is required"),
        })
        .required("Required")
    )
    .test("unique-values", "sku id in the array must be unique", product_costs => {
      if (!product_costs) return false;
      const unique = new Set(product_costs.map(item => item.sku_id));
      return product_costs.length === unique.size;
    }),
  product_one_off_costs: Yup.array().of(
    Yup.object().shape({
      sku_id: Yup.string().optional(),
      cost_description: Yup.string().required("Cost Description is required"),
      qty: Yup.number().required("Quantity is required"),
      unit_cost: Yup.number().required("Unit Cost is required"),
      vat: Yup.object()
        .shape({
          value: Yup.number(),
          label: Yup.string(),
        })
        .required("VAT is required")
        .typeError("VAT is required"),
      total: Yup.number().required("Total is required"),
      total_with_vat: Yup.number().required("Total with VAT is required"),
    })
  ),
  payment_term: Yup.object().shape({
    has_payment_term: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .required("Payment Termn is required")
      .typeError("Payment Term is required"),
    suppress_client_email: Yup.object()
      .shape({
        value: Yup.number(),
        label: Yup.string(),
      })
      .required("Suppress Client Email is required")
      .typeError("Suppress Client Email is required"),
    due_date: Yup.string().test("today", "due_date must be in the future", (due_date, obj) => {
      if (obj.parent.has_payment_term.label === "NO") return true;
      if (!due_date) return false;
      const dueDate = new Date(due_date);
      const today = new Date();
      return dueDate >= today;
    }),
    payment_term_description: Yup.string(),
  }),
  client_promised_delivery_date: Yup.object().shape({
    from_date: Yup.string()
      .test("today", "from_date must be in the future", (from_date, obj) => {
        if (!from_date) return true;
        const dueDate = new Date(from_date);
        const today = new Date();
        return dueDate > today;
      })
      .test("to_date", "to_date must also be set", (from_date, obj) => {
        if (from_date && !obj.parent.to_date) {
          return false;
        }
        return true;
      }),
    to_date: Yup.string()
      .test("today", "to_date must be in the future", (to_date, obj) => {
        if (!to_date) return true;
        const dueDate = new Date(to_date);
        const today = new Date();
        return dueDate > today;
      })
      .test("from_date", "to_date must be after from_date", (to_date, obj) => {
        if (!to_date) return true;
        if (!obj.parent.from_date) return true;

        const toDate = new Date(to_date);
        const fromDate = new Date(obj.parent.from_date);
        return toDate >= fromDate;
      }),
  }),
});
