import { ButtonGroup, css } from "@sourceful/shared-components";

import SecondaryButton from "../../../../shared/components/atoms/buttons/SecondaryButton";

const ButtonPairGroup = css({
  display: "flex",
  justifyContent: "flex-end",
  width: "100%",
  variants: {
    type: {
      smallMargin: {
        marginTop: "10px",
      },
      largeMargin: {
        marginTop: "30px",
      },
      extraLargeMargin: {
        marginTop: "60px",
      },
    },
  },
});

export interface Props {
  margins?: "smallMargin" | "largeMargin" | "extraLargeMargin";
  cancelCopy?: string;
  confirmCopy?: string;
  handleReset: () => void;
  canRenderComfirm?: boolean;
}

export const FormButtonPair = ({
  margins = "largeMargin",
  cancelCopy = "Cancel",
  confirmCopy = "Confirm",
  canRenderComfirm = true,
  handleReset,
}: Props) => {
  return (
    <ButtonGroup className={ButtonPairGroup({ type: margins })}>
      <SecondaryButton
        appearance={"whiteButton"}
        type="button"
        onClick={() => {
          handleReset();
        }}
      >
        {cancelCopy}
      </SecondaryButton>

      {canRenderComfirm && (
        <SecondaryButton appearance={"blueButton"} type="submit">
          {confirmCopy}
        </SecondaryButton>
      )}
    </ButtonGroup>
  );
};
