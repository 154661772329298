import React, { createRef, useState } from "react";

import { Modal, styled } from "@sourceful/shared-components";

import { BasicPanel } from "../../../../components/panel/panel";
import {
  ActionMenu,
  MenuOption,
} from "../../../../shared/components/organisms/ActionMenu/ActionMenu";
import { BaseProductVersion } from "../../shared/types";
import { BaseProductStatus } from "./BaseProductStatus";
import EditBaseProductVersion from "./EditBaseProductVersion";

interface BaseProductVersionRowProps {
  baseProductVersion: BaseProductVersion;
  baseProductName: string;
}

const BaseProductDescriptionContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  flexGrow: "1",
  alignItems: "center",
});

const BaseProductRowLabel = styled("div", {
  paddingRight: "20px",
});

const BaseProductButtonContainer = styled("div", {
  display: "flex",
  gap: "10px",
  marginRight: "10px",
});

const BaseProductRowContainer = styled("div", {
  display: "flex",
  alignItems: "center",
  marginTop: "5px",
  marginLeft: "30px",
  marginRight: "10px",
  marginBottom: "5px",
});

const BaseProductVersionRow: React.FC<BaseProductVersionRowProps> = ({
  baseProductVersion,
  baseProductName,
}) => {
  const [isEditBaseProductVersionModalOpen, setIsEditBaseProductVersionModalOpen] = useState(false);
  const modalRef = createRef<HTMLButtonElement>();

  const menuOptions: MenuOption[] = [
    {
      id: 1,
      label: "Edit",
      handleClick: () => setIsEditBaseProductVersionModalOpen(true),
    },
    {
      id: 2,
      label: "Set Active",
      handleClick: () => console.log("Set Active"), // TODO: Implement
    },
  ];

  return (
    <BasicPanel>
      <BaseProductRowContainer>
        <BaseProductRowLabel>{baseProductVersion.version}</BaseProductRowLabel>
        <BaseProductRowLabel>
          <BaseProductStatus isCurrentVersion={baseProductVersion.isCurrentVersion} />
        </BaseProductRowLabel>
        <BaseProductDescriptionContainer>
          <span>Description Placeholder</span>
        </BaseProductDescriptionContainer>
        <BaseProductButtonContainer>
          <ActionMenu menuOptions={menuOptions} />
        </BaseProductButtonContainer>
      </BaseProductRowContainer>
      <Modal
        id="EditBaseProductVersionModal"
        isOpen={isEditBaseProductVersionModalOpen}
        handleClose={() => setIsEditBaseProductVersionModalOpen(false)}
        triggerRef={modalRef}
      >
        <EditBaseProductVersion baseProductVersion={baseProductVersion} name={baseProductName} />
      </Modal>
    </BasicPanel>
  );
};

export default BaseProductVersionRow;
