import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreatePrePurchaseOrderBody } from "./types";

export const createPrePurchaseOrderAPI = async (
  clientOrderLineId: number,
  body: CreatePrePurchaseOrderBody
) => {
  try {
    return await SOURCING_API.post(
      `/client-order-lines/${clientOrderLineId}/pre-purchase-orders`,
      body
    );
  } catch (err) {
    console.log("Error in create pre-purchase order API call", err);
    throw err;
  }
};
