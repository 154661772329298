import { ApolloError } from "@apollo/client";

import { useGetStockCheckLocationsByWarehouseLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DropdownItem } from "../../../shared/graphql/types";
import { mapLocationNamesToDropdownObject } from "../../mappers";

export interface HookResult {
  locationNames?: DropdownItem[];
  locationNamesError?: ApolloError;
  locationNamesLoading: boolean;
  getAllLocationNamesByWarehouseId: ({ warehouseId }: { warehouseId: number }) => Promise<void>;
}

export const useAllLocationsByWarehouse = (): HookResult => {
  const [getAllLocationNamesByWarehouseId, { data, error, loading }] =
    useGetStockCheckLocationsByWarehouseLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    locationNames:
      data && Array.isArray(data.location) ? mapLocationNamesToDropdownObject(data.location) : [],
    locationNamesError: error,
    locationNamesLoading: loading,
    getAllLocationNamesByWarehouseId: async ({ warehouseId }: { warehouseId: number }) => {
      getAllLocationNamesByWarehouseId({ variables: { warehouse_id: warehouseId } });
    },
  };
};
