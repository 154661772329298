import { CheckBox } from "@sourceful/shared-components";

import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  FormattedLineDataWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { booleanReadableLabels, orderFlowTypes } from "../../shared/graphql/mappers";
import { Config, Organisation } from "../../shared/graphql/types";

interface Props {
  organisation: Organisation | null | undefined;
}
const formatPaymentDueDate = (paymentDueDate: Config["paymentDueDate"]) => {
  if (paymentDueDate?.type === "days_after_invoice" && paymentDueDate?.value) {
    return `${paymentDueDate.value} day${paymentDueDate?.value > 1 ? "s" : ""} after invoice`;
  } else if (paymentDueDate?.type === "last_day_of_month") {
    return `Last day of the month`;
  } else return "";
};

const formatOrderFlowType = (orderFlowTypeId: Config["orderFlowTypeId"]) => {
  const flowTypeReadableLabel = orderFlowTypes.find(type => type.value === orderFlowTypeId)?.label;
  return flowTypeReadableLabel ?? "";
};

const StockManagementOrganisationData = ({ organisation }: Props): JSX.Element => {
  return (
    <>
      <TopPanelInputsWrapper>
        <DataPoint halfWidth>
          <DisplayBox
            isGray
            fullWidth
            label="Stock Management Organisation ID"
            value={organisation?.id}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <DisplayBox
            isGray
            fullWidth
            label="External Organisation ID (from Auth0/IAM)"
            value={organisation?.externalOrganisationId}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <DisplayBox isGray fullWidth label="Organisation Name" value={organisation?.name} />
        </DataPoint>
        <DataPoint halfWidth>
          <DisplayBox
            isGray
            fullWidth
            label="Default Currency"
            value={organisation?.defaultCurrency}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <DisplayBox isGray fullWidth label="Locale" value={organisation?.locale} />
        </DataPoint>
      </TopPanelInputsWrapper>
      <FormattedLineDataWrapper>
        <Title>{organisation?.name} Config</Title>
      </FormattedLineDataWrapper>
      <PrimaryLineWrapper>
        <PrimaryLineDataWrapper>
          <DataPoint halfWidth>
            <DisplayBox
              isGray
              fullWidth
              label="Payment Terms"
              value={organisation?.config?.paymentTerms}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <DisplayBox
              isGray
              fullWidth
              label="Payment Due Date"
              value={formatPaymentDueDate(organisation?.config?.paymentDueDate)}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <DisplayBox
              isGray
              fullWidth
              label="Name on Xero"
              value={organisation?.config?.xeroClientName}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <DisplayBox
              isGray
              fullWidth
              label="Order Flow Type"
              value={formatOrderFlowType(organisation?.config?.orderFlowTypeId)}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <DisplayBox
              isGray
              fullWidth
              label="Payment Disclaimer"
              value={organisation?.config?.paymentDisclaimer}
            />
          </DataPoint>
        </PrimaryLineDataWrapper>
        <PrimaryLineDataWrapper>
          <DataPoint halfWidth>
            <CheckBox
              label={booleanReadableLabels.deliveryInvoicedSeparately}
              isDisabled={true}
              value={"readOnlyCheckbox"}
              id={"readOnlyCheckbox"}
              isChecked={organisation?.config?.deliveryInvoicedSeparately ?? false}
              handleChange={e => null}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <CheckBox
              label={booleanReadableLabels.computeDeliveryCostPerProduct}
              isDisabled={true}
              value={"readOnlyCheckbox"}
              id={"readOnlyCheckbox"}
              isChecked={organisation?.config?.computeDeliveryCostPerProduct ?? false}
              handleChange={e => null}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <CheckBox
              label={booleanReadableLabels.enableClientReferenceCollection}
              isDisabled={true}
              value={"readOnlyCheckbox"}
              id={"readOnlyCheckbox"}
              isChecked={organisation?.config?.enableClientReferenceCollection ?? false}
              handleChange={e => null}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <CheckBox
              label={booleanReadableLabels.prepaidStock}
              isDisabled={true}
              value={"readOnlyCheckbox"}
              id={"readOnlyCheckbox"}
              isChecked={organisation?.config?.prepaidStock ?? false}
              handleChange={e => null}
            />
          </DataPoint>
          <DataPoint halfWidth>
            <CheckBox
              label={booleanReadableLabels.displayProductUnitCost}
              isDisabled={true}
              value={"readOnlyCheckbox"}
              id={"readOnlyCheckbox"}
              isChecked={organisation?.config?.displayProductUnitCost ?? false}
              handleChange={e => null}
            />
          </DataPoint>
        </PrimaryLineDataWrapper>
      </PrimaryLineWrapper>
    </>
  );
};

export default StockManagementOrganisationData;
