export interface SupplierQuotationCost {
  quotationCostTypeId?: number;
  cost: string;
  vat: string;
  costInclVat: string;
}
export interface CartonDetails {
  unitPerCarton?: string;
  cartonLength?: string;
  cartonWidth?: string;
  cartonHeight?: string;
  cartonWeight?: string;
}
export interface SupplierQuoteLine {
  supplierQuoteRequestLineId: number;
  productId: number;
  sampleFee?: string;
  minimumOrderQuantity: string;
  leadTimeMaxDays: string;
  isSampleFeeRefundable?: boolean;
  domesticLogisticsFeeIncluded?: boolean;
  packagingOption: string;
  cartonDetails: CartonDetails;
  quotationCosts: SupplierQuotationCost[];
  isSample: boolean;
  supplierNotes: string;
  notes?: string;
  supplierReference: string;
}

export interface ExternalQuoteForm {
  validFrom: string;
  validTo: string;
  contact: {
    existingContact?: {
      value: number;
      label: string;
    };
    contactName: string;
    contactEmailAddress: string;
    contactJobTitle?: string;
    contactPhoneNumber?: string;
    preferredCommunicationMethod?: {
      value: number;
      label: string;
    };
    isExistingContact: boolean;
  };
  supplierQuoteLines: SupplierQuoteLine[];
}

export const fieldNames = {
  validFrom: "validFrom",
  validTo: "validTo",
  contactName: "contactName",
  contactJobTitle: "contactJobTitle",
  contactEmailAddress: "contactEmailAddress",
  contactPhoneNumber: "contactPhoneNumber",
  preferredCommunicationMethod: "preferredCommunicationMethod",
  isExistingContact: "isExistingContact",
  existingContact: "existingContact",
};
