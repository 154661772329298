import { Index, IndexHolder } from "../../../../styles/SharedStyles";

export interface Props {
  index: number;
}

export const IndexPill = ({ index }: Props) => {
  return (
    <IndexHolder>
      <Index>{index}</Index>
    </IndexHolder>
  );
};
