import ReactTooltip from "react-tooltip";

import { styled } from "@sourceful/shared-components";

interface Props {
  key?: string;
  statusName: string;
  type?: "sourcing" | "wms" | "dot";
}

const colours = {
  sourcefulOrange: "#FFB500",
  sourcefulGreen: "#78D178",
  sourcefulRed: "#D02257",
  sourcefulDarkGrey: "#747477",
  sourcefulBlue: "#005AE1",
  sourcefulLightGrey: "#B9B9B9",
  sourcefulPurple: "#B47EE5",
};

const CiruclarStatusBadge = styled("div", {
  borderRadius: "50%",
  backgroundColor: "#005AE1",
  color: "#FFFFFF",
  display: "flex",
  height: "20px",
  justifyContent: "center",
  alignItems: "center",
  width: "20px",
  variants: {
    status: {
      DueIn: { background: colours.sourcefulOrange },
      Reserved: { background: colours.sourcefulOrange },
      InProgress: { backgroundColor: colours.sourcefulOrange },

      Done: { background: colours.sourcefulGreen },
      Complete: { background: colours.sourcefulGreen },
      Available: { background: colours.sourcefulGreen },

      Pending: { background: colours.sourcefulLightGrey },
      Cancelled: { background: colours.sourcefulLightGrey },
      FoundUnexpected: { background: colours.sourcefulLightGrey },

      New: { background: colours.sourcefulBlue },
      Delivered: { background: colours.sourcefulBlue },
      Fulfilled: { background: colours.sourcefulBlue },

      Missing: { background: colours.sourcefulRed },
      Rejected: { background: colours.sourcefulRed },

      InTransit: { background: colours.sourcefulPurple },
    },
  },
});

const SourcingStatusBadge = styled("div", {
  borderRadius: "5px",
  color: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",
  width: "115px",
  padding: "5px 12px",
  variants: {
    type: {
      New: { background: colours.sourcefulBlue },
      Delivered: { background: colours.sourcefulBlue },
      Fulfilled: { background: colours.sourcefulBlue },

      DueIn: { background: colours.sourcefulOrange },
      Reserved: { background: colours.sourcefulOrange },
      Approved: { background: colours.sourcefulOrange },
      AwaitingQuotes: { background: colours.sourcefulOrange },
      InProgress: { backgroundColor: colours.sourcefulOrange },

      Pending: { background: colours.sourcefulLightGrey },

      Overdue: { background: colours.sourcefulRed },
      Failed: { background: colours.sourcefulRed },
      Missing: { background: colours.sourcefulRed },
      Expired: { background: colours.sourcefulRed },
      Recalled: { background: colours.sourcefulRed },
      Rejected: { background: colours.sourcefulRed },
      Deleted: { background: colours.sourcefulRed },
      InternallyRejected: { background: colours.sourcefulRed },
      ExternallyRejected: { background: colours.sourcefulRed },
      SourcefulWarehouse: { background: colours.sourcefulRed, width: "200px" },

      InTransit: { background: colours.sourcefulPurple },

      Done: { background: colours.sourcefulGreen },
      Passed: { background: colours.sourcefulGreen },
      Accepted: { background: colours.sourcefulGreen },
      Complete: { background: colours.sourcefulGreen },
      Completed: { background: colours.sourcefulGreen },
      Available: { background: colours.sourcefulGreen },
      QuotesCompleted: { background: colours.sourcefulGreen },
      ClientAddress: { background: colours.sourcefulGreen, width: "150px" },

      Cancelled: { background: colours.sourcefulDarkGrey },
      FoundUnexpected: { background: colours.sourcefulDarkGrey },
    },
  },
});

const RectangleStatusBadge = styled("div", {
  backgroundColor: "#005AE1",
  color: "#FFFFFF",
  display: "flex",
  minHeight: "35px",
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  width: "110px",
  borderRadius: "5px",
  variants: {
    status: {
      DueIn: { background: colours.sourcefulOrange },
      Reserved: { background: colours.sourcefulOrange },
      InProgress: { backgroundColor: colours.sourcefulOrange },

      Delivered: { background: colours.sourcefulGreen },
      Approved: { background: colours.sourcefulGreen },
      Passed: { background: colours.sourcefulGreen },
      Done: { background: colours.sourcefulGreen },
      Complete: { background: colours.sourcefulGreen },
      Available: { background: colours.sourcefulGreen },

      Failed: { background: colours.sourcefulRed },
      Rejected: { background: colours.sourcefulRed },
      Deleted: { background: colours.sourcefulRed },

      Cancelled: { background: colours.sourcefulDarkGrey },
      FoundUnexpected: { background: colours.sourcefulDarkGrey },

      New: { background: colours.sourcefulBlue },
      Missing: { background: colours.sourcefulRed },
      Fulfilled: { background: colours.sourcefulBlue },

      Pending: { background: colours.sourcefulLightGrey },
      FileCopy: { background: colours.sourcefulLightGrey },
    },
  },
});

const StatusBadge = ({ key, statusName, type }: Props) => {
  const formattedName = statusName.replace(/\s/g, "") as any;
  if (type === "sourcing") {
    return <SourcingStatusBadge type={formattedName}>{statusName}</SourcingStatusBadge>;
  } else if (type === "wms") {
    return <RectangleStatusBadge status={formattedName}>{statusName}</RectangleStatusBadge>;
  } else {
    return (
      <div>
        <div data-tip={`Status: ${statusName}`} data-for={`tooltip-${key}`}>
          <CiruclarStatusBadge status={formattedName} />
        </div>
        <ReactTooltip
          id={`tooltip-${key}`}
          key={`tooltip-${key}`}
          place="top"
          effect="solid"
          type="light"
          border={true}
          borderColor="#000C32"
        />
      </div>
    );
  }
};

export default StatusBadge;
