import { ApolloError } from "@apollo/client";

import { useGetPurchaseOrderLineQaInspectionQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QAInspectionItem } from "../types";

export interface HookResult {
  purchaseOrderLineQAInspection?: QAInspectionItem;
  purchaseOrderLineQAInspectionError?: ApolloError;
  purchaseOrderLineQAInspectionLoading: boolean;
  refetchPurchaseOrderLineQAInspection: () => Promise<void>;
}

export interface Options {
  purchaseOrderLineId: number;
}

export const usePurchaseOrderLineQAInspection = ({ purchaseOrderLineId }: Options): HookResult => {
  const { data, error, loading, refetch } = useGetPurchaseOrderLineQaInspectionQuery({
    variables: { purchaseOrderLineId },
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    purchaseOrderLineQAInspection: data?.purchase_order_line[0],
    purchaseOrderLineQAInspectionError: error,
    purchaseOrderLineQAInspectionLoading: loading,
    refetchPurchaseOrderLineQAInspection: async () => {
      refetch({ purchaseOrderLineId: purchaseOrderLineId });
    },
  };
};
