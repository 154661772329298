import { SetStateAction, createRef, useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router";
import Select from "react-select";
import { toast } from "react-toastify";

import { ButtonGroup, IconText, InputField, Modal } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import {
  Box,
  ButtonPositioner,
  CenterButtonWrapper,
  DataPoint,
  ErrorMessageButtonWrapper,
  Label,
  LinkLabel,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import AddProductModal from "../../shared/addProductModal/page/AddProductModal";
import { CaseChangeForm, fieldNames } from "../formValues";
import { FromCaseBaseProductQuantityOptions } from "../graphql/hooks/useCaseChangeFromCaseBaseProdQuantityById";
import { GetProductStockOptions } from "../graphql/hooks/useCaseChangeProductStockByParams";
import { useToCaseBaseProdQuantityById } from "../graphql/hooks/useCaseChangeToCaseBaseProdQuantityById";
import { useToCaseProductByBaseProductParams } from "../graphql/hooks/useCaseChangeToCaseProductByBaseProductParams";
import { AggProductStockItem, BaseProductQuantityItem, DropdownItem } from "../graphql/types";
import { StageInForm } from "../types";

interface Props {
  fromCaseProductNames: DropdownItem[];
  fromCaseBaseProductQuantity: BaseProductQuantityItem[];
  fromCaseBaseProductQuantityLoading: boolean;
  getFromCaseBaseProductQuantity: (options: FromCaseBaseProductQuantityOptions) => Promise<void>;
  locationNames: DropdownItem[];
  fromCaseProductNamesLoading: boolean;
  getProductStock: (options: GetProductStockOptions) => Promise<void>;
  toCaseProductNames: DropdownItem[];
  stageInForm: StageInForm;
  setStageInForm: (value: SetStateAction<StageInForm>) => void;
  values: CaseChangeForm;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setInitialValues: (value: SetStateAction<CaseChangeForm>) => void;
  aggregatedProductStock: AggProductStockItem[];
  aggregatedProductStockLoading: boolean;
}

const CaseSelection = ({
  aggregatedProductStock,
  aggregatedProductStockLoading,
  getProductStock,
  fromCaseBaseProductQuantity,
  fromCaseBaseProductQuantityLoading,
  getFromCaseBaseProductQuantity,
  fromCaseProductNamesLoading,
  fromCaseProductNames,
  toCaseProductNames,
  locationNames,
  setFieldValue,
  stageInForm,
  setStageInForm,
  values,
  setInitialValues,
}: Props) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedCaseChangeMethod, setSelectedCaseChangeMethod] = useState<string | null>(null);
  const [toQuantity, setToQuantity] = useState<number | null>(null);
  const [toCaseBaseProdQty, setToCaseBaseProdQty] = useState<number | null>(null);
  const modalRef = createRef<HTMLButtonElement>();
  const [isNewProductCreated, setIsNewProductCreated] = useState<boolean>(false);
  const [isProductTableOpen, setIsProductTableOpen] = useState<boolean>(false);

  let history = useHistory();

  const columns = useMemo(
    () => [
      {
        Header: "Organisation",
        widthPercent: 0.1,
        accessor: (listItem: AggProductStockItem) => listItem.organisationName,
      },
      {
        Header: "Base Product",
        widthPercent: 0.1,
        accessor: () => values.base_product?.label!,
      },
      {
        Header: "Warehouse",
        widthPercent: 0.1,
        accessor: (listItem: AggProductStockItem) => listItem.warehouseName,
      },
      {
        Header: "Product Name",
        widthPercent: 0.7,
        accessor: (listItem: AggProductStockItem) => listItem.productName,
      },
      {
        Header: "Location",
        widthPercent: 0.7,
        accessor: (listItem: AggProductStockItem) => listItem.locationName,
      },
      {
        Header: "Quantity",
        widthPercent: 0.7,
        accessor: (listItem: AggProductStockItem) => listItem.quantity,
      },
      {
        Header: "Condition",
        widthPercent: 0.1,
        accessor: (listItem: AggProductStockItem) => listItem.conditionName,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [aggregatedProductStock]
  );

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const {
    toCaseBaseProductQuantity,
    toCaseBaseProductQuantityLoading,
    getToCaseBaseProductQuantity,
  } = useToCaseBaseProdQuantityById();

  const { existingToCaseProduct, existingToCaseProductLoading, getToCaseExistingProduct } =
    useToCaseProductByBaseProductParams();

  useEffect(() => {
    if (values.from_case) {
      getFromCaseBaseProductQuantity({ productId: values.from_case?.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.from_case]);

  useEffect(() => {
    if (values.to_case) {
      getToCaseBaseProductQuantity({ productId: values.to_case?.value });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.to_case]);

  useEffect(() => {
    const NumberOfCasesBaseProdQuantityExists =
      selectedCaseChangeMethod === "NumberOfCases" &&
      fromCaseBaseProductQuantity.length > 0 &&
      !fromCaseBaseProductQuantityLoading;

    if (NumberOfCasesBaseProdQuantityExists) {
      const toCaseBaseProdQuantity =
        (fromCaseBaseProductQuantity[0].base_product_quantity * Number(values.from_quantity)) /
        Number(values.to_quantity);

      setToCaseBaseProdQty(toCaseBaseProdQuantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.to_quantity, values.from_case, values.from_quantity]);

  useEffect(() => {
    if (Number.isInteger(toCaseBaseProdQty)) {
      getToCaseExistingProduct({
        baseProductId: values.base_product?.value!,
        baseProductQuantity: toCaseBaseProdQty,
      });
    }
    if (
      !Number.isInteger(toCaseBaseProdQty) &&
      toCaseBaseProdQty !== Infinity &&
      toCaseBaseProdQty
    ) {
      toast.warning("Current case selections results in a non-whole number case");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toCaseBaseProdQty, isNewProductCreated]);

  useEffect(() => {
    if (!Number.isInteger(toQuantity) && selectedCaseChangeMethod === "CaseSize") {
      toast.warning(
        "Current selection of cases and quantities doesn't result in whole cases, please change selection"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [toQuantity]);

  useEffect(() => {
    const loading = fromCaseBaseProductQuantityLoading && toCaseBaseProductQuantityLoading;
    const casesBaseProductQuantityExists =
      fromCaseBaseProductQuantity.length > 0 && toCaseBaseProductQuantity.length > 0;
    if (selectedCaseChangeMethod === "CaseSize" && casesBaseProductQuantityExists && !loading) {
      const quantity =
        (fromCaseBaseProductQuantity[0].base_product_quantity * Number(values.from_quantity)) /
        toCaseBaseProductQuantity[0].base_product_quantity;
      setToQuantity(quantity);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    values.to_case,
    values.from_case,
    values.from_quantity,
    fromCaseBaseProductQuantityLoading,
    toCaseBaseProductQuantityLoading,
  ]);

  const resetStates = () => {
    setSelectedCaseChangeMethod(null);
    setToQuantity(null);
  };

  const foundProductGenerator = () => {
    let foundProduct = "Please input a to quantity";
    if (existingToCaseProductLoading) {
      foundProduct = "Loading...";
    }
    if (
      existingToCaseProduct &&
      !existingToCaseProductLoading &&
      existingToCaseProduct.length > 0
    ) {
      foundProduct = existingToCaseProduct[0].label;
    }

    if (
      existingToCaseProduct &&
      !existingToCaseProductLoading &&
      existingToCaseProduct.length === 0
    ) {
      foundProduct = "Doesn't exist";
    }

    if (!Number.isInteger(toCaseBaseProdQty) && toCaseBaseProdQty !== Infinity) {
      foundProduct = "Non-whole number case";
    }
    return foundProduct;
  };

  const ConfirmButtonDisabled = () => {
    let isConfirmButtonDisabled: boolean = false;

    const loading =
      fromCaseBaseProductQuantityLoading ||
      toCaseBaseProductQuantityLoading ||
      existingToCaseProductLoading;

    if (!selectedCaseChangeMethod || !values.from_case || !values.from_quantity || loading) {
      isConfirmButtonDisabled = true;
    }
    if (
      selectedCaseChangeMethod === "CaseSize" &&
      (loading ||
        !values.from_case ||
        !values.from_quantity ||
        !values.to_case ||
        !values.new_location ||
        !Number.isInteger(toQuantity))
    ) {
      isConfirmButtonDisabled = true;
    }

    if (
      selectedCaseChangeMethod === "NumberOfCases" &&
      (loading ||
        !values.from_case ||
        !values.from_quantity ||
        !values.new_location ||
        !values.to_quantity ||
        !Number.isInteger(toCaseBaseProdQty) ||
        existingToCaseProduct?.length === 0)
    ) {
      isConfirmButtonDisabled = true;
    }

    return isConfirmButtonDisabled;
  };

  const isLoadingCopy = fromCaseProductNamesLoading
    ? "Loading Cases..."
    : "No cases exist that satisfy top inputs";

  const calculatedToQuantity = (
    <Box>{Number.isInteger(toQuantity) ? toQuantity : "Please change selection"}</Box>
  );
  const toQuantityBox =
    fromCaseBaseProductQuantityLoading || toCaseBaseProductQuantityLoading ? (
      <Box>{"Calculating..."}</Box>
    ) : (
      calculatedToQuantity
    );

  return (
    <>
      {stageInForm.filteringComplete && fromCaseProductNames.length === 0 && (
        <PrimaryLineWrapper>
          <PrimaryLineDataWrapper style={{ justifyContent: "center", marginTop: "0px" }}>
            {isLoadingCopy}
          </PrimaryLineDataWrapper>
        </PrimaryLineWrapper>
      )}

      {stageInForm.filteringComplete &&
        !stageInForm.caseSelectionConfirmed &&
        fromCaseProductNames.length > 0 && (
          <PrimaryLineWrapper>
            <Title>Select Cases</Title>
            <PrimaryLineDataWrapper>
              <DataPoint>
                <Label isRequired>From Case</Label>
                <Select
                  styles={reactSelectStyling}
                  maxMenuHeight={220}
                  isSearchable={true}
                  options={fromCaseProductNames}
                  id={fieldNames.from_case}
                  value={values.from_case}
                  onChange={e => {
                    setFieldValue(fieldNames.from_case, e);
                    setInitialValues({
                      ...values,
                      from_case: e,
                    });
                  }}
                  placeholder={fromCaseProductNamesLoading ? "Loading..." : "Select Case"}
                />
              </DataPoint>
              <DataPoint>
                <Label isRequired>From Quantity</Label>
                <InputField
                  id={fieldNames.from_quantity}
                  size={"medium"}
                  type={"number"}
                  value={values.from_quantity}
                  handleChange={e => {
                    setFieldValue(fieldNames.from_quantity, e.target.value);
                  }}
                  placeholder="Enter quantity of case"
                />
              </DataPoint>
            </PrimaryLineDataWrapper>
            <PrimaryLineDataWrapper style={{ justifyContent: "center" }}>
              <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                <SecondaryButton
                  appearance={
                    selectedCaseChangeMethod === "NumberOfCases" ? "blueButton" : "whiteButton"
                  }
                  type="button"
                  disabled={!values.from_case || values.from_quantity === ""}
                  onClick={() => {
                    setInitialValues({ ...values, case_change_method: "Number of Cases" });
                    setSelectedCaseChangeMethod("NumberOfCases");
                  }}
                >
                  Number Of Cases
                </SecondaryButton>
                <SecondaryButton
                  type="button"
                  appearance={
                    selectedCaseChangeMethod === "CaseSize" ? "blueButton" : "whiteButton"
                  }
                  onClick={() => {
                    setInitialValues({ ...values, case_change_method: "Case Size" });
                    setSelectedCaseChangeMethod("CaseSize");
                  }}
                  disabled={!values.from_case || values.from_quantity === ""}
                >
                  Case Size
                </SecondaryButton>
              </ButtonGroup>
            </PrimaryLineDataWrapper>
            {values.case_change_method && (
              <>
                <PrimaryLineDataWrapper>
                  <DataPoint>
                    <Label
                      isGray={selectedCaseChangeMethod === "NumberOfCases"}
                      isRequired={selectedCaseChangeMethod !== "NumberOfCases"}
                    >
                      To Case
                    </Label>
                    {selectedCaseChangeMethod === "CaseSize" && (
                      <LinkLabel onClick={() => history.push("/inventory/products/add-product")}>
                        + Add Product
                      </LinkLabel>
                    )}
                    {selectedCaseChangeMethod === "CaseSize" ? (
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        options={toCaseProductNames}
                        id={fieldNames.to_case}
                        value={values.to_case}
                        onChange={e => {
                          setFieldValue(fieldNames.to_case, e);
                          setInitialValues({
                            ...values,
                            to_case: e,
                          });
                        }}
                        placeholder="Select Case"
                      />
                    ) : (
                      <Box>{foundProductGenerator()}</Box>
                    )}
                  </DataPoint>

                  <DataPoint>
                    <Label
                      isGray={selectedCaseChangeMethod === "CaseSize"}
                      isRequired={selectedCaseChangeMethod !== "CaseSize"}
                    >
                      To Quantity
                    </Label>
                    {selectedCaseChangeMethod === "NumberOfCases" ? (
                      <InputField
                        id={fieldNames.to_quantity}
                        size={"medium"}
                        type={"number"}
                        value={values.to_quantity}
                        handleChange={e => {
                          setFieldValue(fieldNames.to_quantity, e.target.value);
                        }}
                        placeholder="Enter quantity of case"
                      />
                    ) : (
                      toQuantityBox
                    )}
                  </DataPoint>
                  <DataPoint>
                    <Label isRequired>New Location</Label>
                    <Select
                      styles={reactSelectStyling}
                      maxMenuHeight={220}
                      isSearchable={true}
                      options={locationNames}
                      id={fieldNames.new_location}
                      value={values.new_location}
                      onChange={e => {
                        setFieldValue(fieldNames.new_location, e);
                        setInitialValues({
                          ...values,
                          new_location: e,
                        });
                      }}
                      placeholder="Select Location"
                    />
                  </DataPoint>
                </PrimaryLineDataWrapper>
                <PrimaryLineDataWrapper style={{ justifyContent: "center" }}>
                  {existingToCaseProduct &&
                    !existingToCaseProductLoading &&
                    existingToCaseProduct.length === 0 &&
                    Number.isInteger(toCaseBaseProdQty) &&
                    toCaseBaseProdQty !== Infinity && (
                      <ErrorMessageButtonWrapper style={{ marginRight: "30px" }}>
                        {`${toCaseBaseProdQty}x ${values.base_product?.label} doesn't exist`}
                        <CenterButtonWrapper>
                          <SecondaryButton
                            appearance={"blueButton"}
                            isWithinTable
                            type="button"
                            onClick={() => setIsModalOpen(true)}
                          >
                            Create Product
                          </SecondaryButton>
                        </CenterButtonWrapper>
                      </ErrorMessageButtonWrapper>
                    )}
                </PrimaryLineDataWrapper>
              </>
            )}

            <Modal
              id={"CreateProdModal"}
              isOpen={isModalOpen}
              handleClose={handleModalClose}
              triggerRef={modalRef}
            >
              <AddProductModal
                base_product_id={values.base_product?.value!}
                base_product_name={values.base_product?.label!}
                product_name={`${toCaseBaseProdQty}x ${values.base_product?.label}`}
                product_quantity={toCaseBaseProdQty!}
                setIsNewProductCreated={setIsNewProductCreated}
                isNewProductCreated={isNewProductCreated}
                setIsModalOpen={setIsModalOpen}
              ></AddProductModal>
            </Modal>

            <ButtonPositioner>
              <ButtonGroup>
                <SecondaryButton
                  appearance="whiteButtonBlueText"
                  type="button"
                  onClick={() => {
                    setIsProductTableOpen(!isProductTableOpen);
                  }}
                >
                  <IconText
                    text={"View Product Table"}
                    primaryIcon={isProductTableOpen ? "arrow-direction-up" : "arrow-direction-down"}
                  />
                </SecondaryButton>
                <SecondaryButton
                  appearance="blueButton"
                  type="button"
                  disabled={ConfirmButtonDisabled()}
                  onClick={() => {
                    if (selectedCaseChangeMethod === "CaseSize" && Number.isInteger(toQuantity)) {
                      setInitialValues({
                        ...values,
                        to_quantity: `${toQuantity}`,
                        to_case_base_product_quantity:
                          toCaseBaseProductQuantity[0].base_product_quantity,
                      });
                      setStageInForm({ ...stageInForm, caseSelectionConfirmed: true });
                      getProductStock({
                        organisationId: values.organisation?.value!,
                        warehouseId: values.warehouse?.value!,
                        baseProductId: values.base_product?.value!,
                        baseProductQuantity: fromCaseBaseProductQuantity[0].base_product_quantity,
                        stockConditionId: values.condition?.value!,
                      });
                      resetStates();
                    }

                    if (
                      selectedCaseChangeMethod === "NumberOfCases" &&
                      existingToCaseProduct &&
                      existingToCaseProduct.length !== 0
                    ) {
                      setInitialValues({
                        ...values,
                        to_case: existingToCaseProduct[0],
                        to_case_base_product_quantity: toCaseBaseProdQty,
                      });
                      setStageInForm({ ...stageInForm, caseSelectionConfirmed: true });

                      getProductStock({
                        organisationId: values.organisation?.value!,
                        warehouseId: values.warehouse?.value!,
                        baseProductId: values.base_product?.value!,
                        baseProductQuantity: fromCaseBaseProductQuantity[0].base_product_quantity,
                        stockConditionId: values.condition?.value!,
                      });
                      resetStates();
                    }
                  }}
                >
                  Confirm
                </SecondaryButton>
              </ButtonGroup>
            </ButtonPositioner>
            {isProductTableOpen && (
              <PrimaryLineWrapper>
                {aggregatedProductStockLoading ? (
                  <PrimaryLineDataWrapper>Product Stock Loading</PrimaryLineDataWrapper>
                ) : (
                  <Table data={aggregatedProductStock ?? []} columns={columns} />
                )}
              </PrimaryLineWrapper>
            )}
          </PrimaryLineWrapper>
        )}
    </>
  );
};

export default CaseSelection;
