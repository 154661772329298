import { WMS_API } from "../../../../../../api/baseConfig";
import { MarkStockCheckLineAsCompleteParams } from "./types";

export const markStockCheckLineAsCompleteAPI = async ({
  stockCheckId,
  stockCheckLineId,
}: MarkStockCheckLineAsCompleteParams) => {
  try {
    return await WMS_API.patch(
      `stock-checks/${stockCheckId}/stock-check-lines/${stockCheckLineId}/complete`
    );
  } catch (err) {
    console.log("Error in Mark stock check line as Complete API call:", err);
    throw err;
  }
};
