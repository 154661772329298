import { WMS_API } from "../../../../../api/baseConfig";
import { CreateStockTransferBody } from "./types";

export const createStockTransferAPI = async (body: CreateStockTransferBody) => {
  try {
    return await WMS_API.post("/internal-transfers/create", body);
  } catch (err) {
    console.log("Error in creating Stock Transfer API call", err);
    throw err;
  }
};
