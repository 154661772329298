import { FormikErrors, FormikTouched } from "formik";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon, InputField } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  Box,
  DataPoint,
  IconWrapper,
  Label,
  LinkText,
  PrimaryLineWrapper,
  RectangleStatusBadge,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { WorkOrderAndRecipeInputs, WorkOrderInput } from "../formValues";
import { useProductStock } from "../graphql/hooks/useProductStockByParams";
import { ProductStockItem } from "../graphql/types";

interface WorkOrderLineProps {
  line: WorkOrderAndRecipeInputs;
  organisation_id: number;
  warehouse_id: number;
  values: WorkOrderInput;
  workOrderLineIndex: number;
  recipeInputIndex: number;
  errors: FormikErrors<WorkOrderAndRecipeInputs[]> | undefined;
  touched: FormikTouched<WorkOrderAndRecipeInputs>[] | undefined;
  handleRemoveWorkOrderLine: ({
    recipeInputIndex,
    workOrderLineIndex,
  }: {
    workOrderLineIndex: number;
    recipeInputIndex: number;
  }) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const WorkOrderInputLine = ({
  values,
  setFieldValue,
  workOrderLineIndex,
  recipeInputIndex,
  handleRemoveWorkOrderLine,
  errors,
  touched,
  line,
  warehouse_id,
  organisation_id,
}: WorkOrderLineProps) => {
  const { productStock } = useProductStock({
    warehouseId: warehouse_id,
    organisationId: organisation_id,
    baseProductId: line.recipe_input.product.base_product_id,
  });

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.product.product_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/products/view-product/${row.original.product.base_product_id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Location",
        widthPercent: 0.4,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.location_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: ProductStockItem) => listItem.location.location_name,
      },
      {
        Header: "Stock status",
        widthPercent: 0.4,
        accessor: (listItem: ProductStockItem) => (
          <RectangleStatusBadge
            status={listItem.stock_status.stock_status_name.replace(/\s/g, "") as any}
          >
            {listItem.stock_status.stock_status_name}
          </RectangleStatusBadge>
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Stock condition",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
      },
      {
        Header: "Organisation",
        widthPercent: 0.1,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation?.id}`}>
            <LinkText>{row.original.organisation?.organisation_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: ProductStockItem) => listItem.organisation?.organisation_name,
      },
      {
        Header: "Choose Stock",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => {
          if (values.product_stock?.value === row.original.id) {
            return (
              <SecondaryButton isWithinTable appearance="greenButton">
                Chosen
              </SecondaryButton>
            );
          }
          return (
            <SecondaryButton
              onClick={() => {
                setFieldValue(
                  `work_order_and_recipe_inputs[${recipeInputIndex}].work_order_inputs[${workOrderLineIndex}].product_stock`,
                  {
                    label: row.original.product.product_name,
                    value: row.original.id,
                  }
                );
              }}
              type="button"
              isWithinTable
              appearance="blueButton"
            >
              Choose
            </SecondaryButton>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.product_stock]
  );

  // TODO : REFACTOR THIS - validation crashes if fieldnames are undefined which they are at the start of submitForm formik prop
  const checkFieldError = ({ fieldName }: { fieldName: "quantity" | "product_stock" }) => {
    if (
      Array.isArray(errors) &&
      Array.isArray(touched) &&
      Array.isArray(errors[recipeInputIndex]?.work_order_inputs) &&
      Array.isArray(touched[recipeInputIndex]?.work_order_inputs) &&
      Boolean((errors as any)[recipeInputIndex]?.work_order_inputs[workOrderLineIndex]) &&
      Boolean(
        (errors as any)[recipeInputIndex]?.work_order_inputs[workOrderLineIndex][fieldName]
      ) &&
      Boolean((touched as any)[recipeInputIndex]?.work_order_inputs[workOrderLineIndex]) &&
      Boolean((touched as any)[recipeInputIndex]?.work_order_inputs[workOrderLineIndex][fieldName])
    ) {
      return true;
    }
    return false;
  };

  let stockBaseQuantity =
    productStock.find(query_product_stock => query_product_stock.id === values.product_stock?.value)
      ?.product.base_product_quantity ?? 1;

  let currentProductStockQuantity =
    productStock.find(query_product_stock => query_product_stock.id === values.product_stock?.value)
      ?.quantity ?? 0;

  return (
    <>
      <PrimaryLineWrapper>
        <Table
          data={
            productStock.filter(stock => stock.stock_status.stock_status_name === "Available") ?? []
          }
          columns={columns}
        />
        {checkFieldError({ fieldName: "product_stock" }) ? (
          <ErrorMessage>Product Stock is Required</ErrorMessage>
        ) : null}
      </PrimaryLineWrapper>
      <SecondaryLineWrapper type={"withIndex"}>
        <IndexPill index={workOrderLineIndex + 1} />
        <DataPoint>
          <Label isRequired htmlFor={`quantity_${recipeInputIndex}_${workOrderLineIndex}`}>
            Quantity
          </Label>
          <InputField
            id={`quantity_${recipeInputIndex}_${workOrderLineIndex}`}
            size={"medium"}
            type={"number"}
            value={values.quantity}
            placeholder={"Select Quantity"}
            handleChange={e => {
              setFieldValue(
                `work_order_and_recipe_inputs[${recipeInputIndex}].work_order_inputs[${workOrderLineIndex}].quantity`,
                e.target.value
              );
            }}
          />
          {checkFieldError({ fieldName: "quantity" }) ? (
            <ErrorMessage>Quantity is Required</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint>
          <Label isGray>Remaining Quantity</Label>
          {values.product_stock && values.quantity ? (
            <Box>{currentProductStockQuantity - Number(values.quantity)}</Box>
          ) : (
            <Box> Please select a Product Stock and Quantity</Box>
          )}
        </DataPoint>
        <DataPoint>
          <Label isGray>Total Case Adjusted Quantity</Label>
          {values.product_stock ? (
            <Box>{stockBaseQuantity * Number(values.quantity)}</Box>
          ) : (
            <Box> Please select a Product Stock</Box>
          )}
        </DataPoint>

        <DataPoint>
          <IconWrapper
            type="button"
            onClick={() =>
              handleRemoveWorkOrderLine({
                workOrderLineIndex: workOrderLineIndex,
                recipeInputIndex: recipeInputIndex,
              })
            }
          >
            <Icon width={30} height={30} name="alert-cross-fill" />
          </IconWrapper>
        </DataPoint>
      </SecondaryLineWrapper>
    </>
  );
};

export default WorkOrderInputLine;
