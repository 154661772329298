import { useTranslation } from "react-i18next";

import { InputField } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import TextArea from "../../../../../shared/components/forms/SimpleTextArea";
import {
  DataPoint,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { SupplierResponseSignatureSectionProps, fieldNames } from "../formValues";

export const SignatureSection = ({
  values,
  setFieldValue,
  errors,
  touched,
}: SupplierResponseSignatureSectionProps) => {
  const { t } = useTranslation();

  return (
    <PrimaryLineWrapper>
      <Title>{t("supplier_response_acceptance_title")}</Title>
      <PrimaryLineDataWrapper>
        <DataPoint halfWidth>
          <Label isRequired htmlFor={fieldNames.created_by_name}>
            {t("supplier_response_acceptance_name")}
          </Label>
          <InputField
            id={fieldNames.created_by_name}
            size={"medium"}
            type={"text"}
            placeholder={t("supplier_response_acceptance_name_placeholder")}
            value={values.created_by_name}
            handleChange={e => {
              setFieldValue(fieldNames.created_by_name, e.target.value);
            }}
          />
          {errors.created_by_name && touched.created_by_name ? (
            <ErrorMessage>{errors.created_by_name}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint halfWidth>
          <Label isRequired htmlFor={fieldNames.job_title}>
            {t("supplier_response_acceptance_job_title")}
          </Label>
          <InputField
            id={fieldNames.job_title}
            size={"medium"}
            type={"text"}
            placeholder={t("supplier_response_acceptance_job_title_placeholder")}
            value={values.job_title}
            handleChange={e => {
              setFieldValue(fieldNames.job_title, e.target.value);
            }}
          />
          {errors.job_title && touched.job_title ? (
            <ErrorMessage>{errors.job_title}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint fullWidth>
          <Label htmlFor={fieldNames.initial_notes}>
            {t("supplier_response_acceptance_notes")}
          </Label>
          <TextArea
            id={fieldNames.initial_notes}
            size={"large"}
            type={"text"}
            placeholder={t("supplier_response_acceptance_notes_placeholder")}
            value={values.initial_notes}
            handleChange={e => {
              setFieldValue(fieldNames.initial_notes, e.target.value);
            }}
          />
          {errors.initial_notes && touched.initial_notes ? (
            <ErrorMessage>{errors.initial_notes}</ErrorMessage>
          ) : null}
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
};
