import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText, RectangleStatusBadge } from "../../../../../styles/SharedStyles";
import { prettifyProductName } from "../../../shared/mappers";
import { useClientOrders } from "../graphql/hooks/useClientOrders";
import { ClientOrderListItem } from "../graphql/types";

export default function ViewClientOrders() {
  const { clientOrders, clientOrdersError, clientOrdersLoading } = useClientOrders();

  const columns = useMemo(
    () => [
      {
        Header: "CO#",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) =>
          clientOrderLine.client_order.external_client_order_id,
        Cell: ({ row }: CellProps<ClientOrderListItem>) => (
          <Link to={`/client-orders/update-client-order/${row.original.client_order.id}`}>
            <LinkText>
              {row.original.client_order.external_client_order_id.substring(0, 8)}
            </LinkText>
          </Link>
        ),
      },
      {
        Header: "Client Name",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) =>
          clientOrderLine.client_order.organisation.organisation_name,
      },
      {
        Header: "Product Name",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) => clientOrderLine.product.product_name,
        Cell: ({ row }: CellProps<ClientOrderListItem>) => (
          <Link
            to={`/client-orders/update-client-order/${row.original.client_order.id}/${row.original.id}`}
          >
            <LinkText>{prettifyProductName(row.original.product.product_name)}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) => clientOrderLine.quantity,
      },
      {
        Header: "Supplier",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) =>
          clientOrderLine.supplier_quote_line?.supplier_quote.organisation.organisation_name,
      },
      {
        Header: "Created on",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) =>
          moment(clientOrderLine.created_at).format("x"),
        Cell: ({ row }: CellProps<ClientOrderListItem>) =>
          moment(row.original.created_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Last Updated on",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) =>
          moment(clientOrderLine.updated_at).format("x"),
        Cell: ({ row }: CellProps<ClientOrderListItem>) =>
          moment(row.original.updated_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Status",
        widthPercent: 0.1,
        Cell: ({ row }: CellProps<ClientOrderListItem>) => (
          <RectangleStatusBadge
            status={
              row.original.client_order_line_status.client_order_line_status_name.replace(
                /\s/g,
                ""
              ) as any
            }
          >
            {row.original.client_order_line_status.client_order_line_status_name}
          </RectangleStatusBadge>
        ),
        accessor: (clientOrderLine: ClientOrderListItem) =>
          clientOrderLine.client_order_line_status.client_order_line_status_name,
      },
      {
        Header: "Order Source",
        widthPercent: 0.1,
        accessor: (clientOrderLine: ClientOrderListItem) =>
          clientOrderLine.client_order.service_account.service_account_name,
      },
      {
        Header: "Update",
        widthPercent: 0.2,
        accessor: (clientOrderLine: ClientOrderListItem) => clientOrderLine.client_order.id,
        Cell: ({ row }: CellProps<ClientOrderListItem>) => (
          <Link
            to={`/client-orders/update-client-order/${row.original.client_order.id}/${row.original.id}`}
          >
            <SecondaryButton appearance={"blueButton"} isWithinTable>
              Update
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page title={"Client Orders"} isLoading={clientOrdersLoading} error={clientOrdersError}>
      <Panel
        withWrapper
        title={"Client Order Lines List"}
        text={"Use the table below to explore Sourceful's Client Order Lines"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table
          data={clientOrders}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
}
