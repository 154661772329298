import moment from "moment";
import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import DisplaySquare from "../../../../../shared/components/molecules/DisplaySquare";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge/StatusBadge";
import Page from "../../../../../shared/components/templates/Page/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { daysHoursMinutesSeconds } from "../../../../../util/daysHoursMinutesSeconds";
import { EditSupplierQCFiles } from "../components/EditSupplierQCFiles";
import { useSupplierByXeroId } from "../graphql/hooks/useSupplierByXeroId";
import { PurchaseOrderLineItem } from "../graphql/types";

export default function ViewSupplier() {
  const { xero_contact_id: xeroContactId } = useParams<{ xero_contact_id: string }>();

  const { supplier, supplierLoading, supplierError } = useSupplierByXeroId(xeroContactId);

  const columns = useMemo(
    () => [
      {
        Header: "PO Number",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (po: PurchaseOrderLineItem) => po.purchaseOrderNumber,
        Cell: ({ row }: CellProps<PurchaseOrderLineItem>) => (
          <Link to={`/purchase-orders/update-purchase-order/${row.original.purchaseOrderId}`}>
            {row.original.purchaseOrderNumber}
          </Link>
        ),
      },
      {
        Header: "Product",
        widthPercent: 15,
        filter: "multiRow",
        accessor: (purchaseOrderLineItem: PurchaseOrderLineItem) =>
          purchaseOrderLineItem.productName,
      },
      {
        Header: "Client Name",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (purchaseOrderLineItem: PurchaseOrderLineItem) =>
          purchaseOrderLineItem.clientName,
      },
      {
        Header: "Quantity",
        widthPercent: 1,
        filter: "multiRow",
        accessor: (purchaseOrderLineItem: PurchaseOrderLineItem) => purchaseOrderLineItem.quantity,
      },
      {
        Header: "Value",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (purchaseOrderLineItem: PurchaseOrderLineItem) =>
          (purchaseOrderLineItem.calculatedQuotationCost * purchaseOrderLineItem.quantity)
            .toFixed(2)
            .toString(),
      },
      {
        Header: "Created on",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (po: PurchaseOrderLineItem) => moment(po.createdOn).format("x"),
        Cell: ({ row }: CellProps<PurchaseOrderLineItem>) =>
          moment(row.original.createdOn).format("DD-MM-YYYY"),
      },
      {
        Header: "Expected Delivery Date",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (po: PurchaseOrderLineItem) => moment(po.expectedDeliveryDate).format("x"),
        Cell: ({ row }: CellProps<PurchaseOrderLineItem>) =>
          moment(row.original.expectedDeliveryDate).format("DD-MM-YYYY"),
      },
      {
        Header: "Delivery Date",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (po: PurchaseOrderLineItem) => moment(po.deliveryDate).format("x"),
        Cell: ({ row }: CellProps<PurchaseOrderLineItem>) =>
          row.original.deliveryDate ? moment(row.original.deliveryDate).format("DD-MM-YYYY") : "",
      },
      {
        Header: "Order Status",
        widthPercent: 2,
        filter: "multiRow",
        accessor: (purchaseOrder: PurchaseOrderLineItem) => purchaseOrder.purchaseOrderStatus,
        Cell: ({ row }: CellProps<PurchaseOrderLineItem>) => (
          <StatusBadge type="sourcing" statusName={row.original.purchaseOrderStatus} />
        ),
      },
    ],
    // eslint-disable-next-line
    [supplier]
  );

  return supplier ? (
    <Page title={"View Supplier"} isLoading={supplierLoading} error={supplierError} hasBackButton>
      <>
        <Panel withWrapper title={supplier.organisationName} allignTitle="left">
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DisplayBox withUniqueMargin label="Locale" value={supplier.locale} />
              <DataPoint halfWidth withUniqueMargin>
                <DisplayBox
                  fullWidth
                  withUniqueMargin
                  label="Xero Contact ID"
                  value={supplier.xeroContactId}
                />
              </DataPoint>
              <DisplayBox
                withUniqueMargin
                label="Onboarded On"
                value={moment(supplier.createdAt).format("DD-MM-YYYY")}
              />
            </TopPanelInputsWrapper>
            <TopPanelInputsWrapper css={{ marginTop: "20px" }}>
              <DisplaySquare
                colour="amber"
                title="Accepted Purchase Orders"
                value={supplier.acceptedPurchaseOrdersCount}
              />
              <DisplaySquare
                colour="green"
                title="Fulfilled Purchase Orders"
                value={supplier.fulfilledPurchaseOrdersCount}
              />
              <DisplaySquare
                colour="red"
                title="Expired, Rejected & Deleted Purchase Orders"
                value={supplier.expiredAndRejectedPurchaseOrdersCount}
              />
              <DisplaySquare
                colour="blue"
                title="Avg. Response Time (days:hrs:mins:secs)"
                value={daysHoursMinutesSeconds(supplier.averageResponseTimeData)}
              />
              <DisplaySquare
                colour="grey"
                title="Order Value Excl. VAT"
                value={supplier.totalValueExclVat.toLocaleString("en-US")}
              />
            </TopPanelInputsWrapper>
            <Table
              data={supplier.purchaseOrderLineItems}
              columns={columns}
              renderFilters={() => <></>}
            />
          </InnerPanelWrapper>
        </Panel>
        <Panel title="Edit supplier QC files" withWrapper allignTitle="left">
          <InnerPanelWrapper css={{ paddingTop: 0 }}>
            <EditSupplierQCFiles supplier={supplier} />
          </InnerPanelWrapper>
        </Panel>
      </>
    </Page>
  ) : (
    <Page title={"View Supplier"} isLoading={supplierLoading} error={supplierError} hasBackButton>
      <Panel withWrapper title={"Supplier"} allignTitle="left">
        No Supplier with this ID found
      </Panel>
    </Page>
  );
}
