import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createWorkOrderAPI } from "../../api/createWorkOrder";
import { CreateWorkOrderBody } from "../../api/types";
import {
  CREATE_WORK_ORDER_FAILED,
  CREATE_WORK_ORDER_LOADING,
  CREATE_WORK_ORDER_RESET,
  CREATE_WORK_ORDER_SUCCESS,
} from "../types";

export const creatingWorkOrder = () => {
  return {
    type: CREATE_WORK_ORDER_LOADING,
  };
};

export const createWorkOrderSuccess = () => {
  return {
    type: CREATE_WORK_ORDER_SUCCESS,
  };
};

export const createWorkOrderReset = () => {
  return {
    type: CREATE_WORK_ORDER_RESET,
  };
};

export const createWorkOrderFailed = () => {
  return {
    type: CREATE_WORK_ORDER_FAILED,
  };
};

export const createWorkOrderAction = (body: CreateWorkOrderBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Work Order", { hideProgressBar: true });
    dispatch(creatingWorkOrder());
    try {
      await createWorkOrderAPI(body);
      toast.success("Work Order created successfully");
      dispatch(createWorkOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating work order.");
      dispatch(createWorkOrderFailed());
    }
  };
};
