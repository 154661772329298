import { ApolloError } from "@apollo/client";

import { useGetFinanceStockLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapFinanceStockToCSV } from "../mappers/mapFinanceStockToCSV";

export interface HookResult {
  financeStock: any[];
  financeStockError?: ApolloError;
  financeStockLoading: boolean;
  getFinanceStock: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number;
  fromDate: string;
  toDate: string;
}

export const useFinanceStockReport = (): HookResult => {
  const [getFinanceStock, { data, error, loading }] = useGetFinanceStockLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    financeStock:
      data && Array.isArray(data.vw_finance_stock_report)
        ? mapFinanceStockToCSV(data.vw_finance_stock_report)
        : [],
    financeStockError: error,
    financeStockLoading: loading,
    getFinanceStock: async ({ warehouseId, fromDate, toDate }: Options) => {
      getFinanceStock({ variables: { warehouseId, fromDate, toDate } });
    },
  };
};
