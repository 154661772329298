import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon, SecondaryButton } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel from "../../../../../components/panel/panel";
import { Page } from "../../../../../shared/components/templates/Page";
import { PRODUCT_TYPE_ID } from "../../../../../shared/constants";
import { formatCurrency } from "../../../../../shared/mappers";
import { InlineCode, SubText } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { formatWithCommas } from "../../../../../util/formatElementWithCommas";
import { useAllSkus } from "../../../../pcat/shared/graphql/hooks/useAllSkus";
import { MappedSkuItem } from "../../../../pcat/shared/graphql/types";
import { useOrganisations } from "../../../shared/hooks/useOrganisations";
import { getProductStocks as getProductStocksApi } from "../../updateSku/api/getProductStocks";

const leadTime = (sku: MappedSkuItem, type: "accessor" | "cell") => {
  if (!sku.manufacturingLeadTime) return type === "cell" ? "—" : null;

  if (!sku.manufacturingLeadTime.min && !sku.manufacturingLeadTime.max)
    return type === "cell" ? "—" : null;

  if (type === "accessor") {
    return `${sku.manufacturingLeadTime.min} to ${sku.manufacturingLeadTime.max} ${
      sku.manufacturingLeadTime.units
    }, ${
      sku.manufacturingLeadTime.businessDaysOnly
        ? "business days only"
        : "includes weekends & bank holidays"
    }`;
  }

  if (type === "cell") {
    return (
      <>
        {`${sku.manufacturingLeadTime.min} - ${sku.manufacturingLeadTime.max} ${sku.manufacturingLeadTime.units}`}
        {typeof sku.manufacturingLeadTime.businessDaysOnly === "boolean" && (
          <SubText>
            {sku.manufacturingLeadTime.businessDaysOnly
              ? "business days only"
              : "includes weekends & bank holidays"}
          </SubText>
        )}
      </>
    );
  }
};

const ViewSkus = () => {
  const { skus, skusError, skusLoading } = useAllSkus();
  const [productStocks, setProductStocks] = useState<any[]>([]);

  const [getProductStocksState, fetchProductStocks] = useAPI(getProductStocksApi, true);
  const { organisations, organisationsLoading, organisationsError } = useOrganisations();

  useEffect(() => {
    const getProductStocks = async () => {
      const response = await fetchProductStocks({});
      const productStocks = Array.isArray(response) ? response[0].data : response.data;
      setProductStocks(productStocks);
    };

    getProductStocks().catch(error => {
      console.error("error getting product stocks in ViewSkus:", error);
    });
    // eslint-disable-next-line
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "SKU ID",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => sku.id,
        Cell: ({ row }: CellProps<MappedSkuItem>) => <InlineCode>{row.original.id}</InlineCode>,
        filter: "multiRow",
      },
      {
        Header: "SKU Name",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => sku.clientName,
        filter: "multiRow",
      },
      {
        Header: "Pack Size",
        widthPercent: 0.25,
        accessor: (sku: MappedSkuItem) => sku.packSizes[0],
        Cell: ({ row }: CellProps<MappedSkuItem>): any => {
          return row.original.packSizes?.length > 0 ? `${row.original.packSizes[0]}` : "—";
        },
        filter: "multiRow",
      },
      {
        Header: "Delivery Cost, Per Pack",
        widthPercent: 0.25,
        accessor: (sku: MappedSkuItem) => sku.deliveryCosts[0],
        Cell: ({ row }: CellProps<MappedSkuItem>): any => {
          const costs = row.original.deliveryCosts;
          return costs?.length > 0
            ? costs.map((cost, index) => {
                return (
                  <span key={`${cost.countryCode}-${index}`}>
                    {formatWithCommas(
                      index === costs.length - 1,
                      formatCurrency(cost.currencyCode).format(cost.costPerPackExclVat)
                    )}
                  </span>
                );
              })
            : "—";
        },
        filter: "multiRow",
      },
      {
        Header: "Assets",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => sku.imageUrl,
        Cell: ({ row }: CellProps<MappedSkuItem>) =>
          row.original.imageUrl ? (
            <a href={row.original.imageUrl} target="_blank" rel="noreferrer">
              <SecondaryButton appearance={"blueButton"} isWithinTable>
                View
              </SecondaryButton>
            </a>
          ) : (
            "—"
          ),
      },
      {
        Header: "Lead time",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => leadTime(sku, "accessor"),
        Cell: ({ row }: CellProps<MappedSkuItem>) => leadTime(row.original, "cell"),
        filter: "multiRow",
      },
      {
        Header: "Product Type",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => PRODUCT_TYPE_ID[sku.productTypeId],
        filter: "multiRow",
      },
      {
        Header: "Organisation",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => {
          return sku.organisationIds.length > 0
            ? sku.organisationIds.map(orgId => {
                const orgThatMatchesID = organisations?.find(org => org.org_id === orgId);
                return orgThatMatchesID ? orgThatMatchesID.display_name : orgId;
              })
            : ["—"];
        },
        Cell: ({ row }: CellProps<MappedSkuItem>): any => {
          return row.original.organisationIds.length > 0
            ? row.original.organisationIds.map((orgId, index) => {
                const orgThatMatchesID = organisations?.find(org => org.org_id === orgId);
                return (
                  orgThatMatchesID && (
                    <InlineCode key={orgId}>
                      {formatWithCommas(
                        index === row.original.organisationIds.length - 1,
                        orgThatMatchesID.display_name
                      )}
                    </InlineCode>
                  )
                );
              })
            : "—";
        },
        filter: "arraySome",
      },
      {
        Header: "Stock",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => {
          const stocksThatMatchID = productStocks
            .filter(stock => stock.externalProductId === sku.id && stock.quantity > 0)
            .map(stock => stock.quantity);

          return stocksThatMatchID.length > 0 ? stocksThatMatchID.join(", ") : undefined;
        },
        Cell: ({ row }: CellProps<MappedSkuItem>): any => {
          const stocksThatMatchID = productStocks
            .filter(stock => stock.externalProductId === row.original.id && stock.quantity > 0)
            .map(stock => stock.quantity);

          return stocksThatMatchID.length > 0 ? (
            <InlineCode key={`stock_${row.original.id}`}>{stocksThatMatchID.join(", ")}</InlineCode>
          ) : (
            "—"
          );
        },
        filter: "multiRow",
      },
      {
        Header: "Created on",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => moment(sku.createdAt).format("x"),
        Cell: ({ _, row }: CellProps<MappedSkuItem>) =>
          moment(row.original.createdAt).format("DD-MM-YYYY"),
        filter: "multiRow",
      },
      {
        Header: "Last updated on",
        widthPercent: 1,
        accessor: (sku: MappedSkuItem) => moment(sku.updatedAt).format("x"),
        Cell: ({ _, row }: CellProps<MappedSkuItem>) =>
          moment(row.original.updatedAt).format("DD-MM-YYYY"),
        filter: "multiRow",
      },
      {
        Header: "Update",
        widthPercent: 1,
        Cell: ({ row }: CellProps<MappedSkuItem>) => (
          <Link to={`/admin/skus/update-sku/${row.original.id}`}>
            <Icon name={"arrow-direction-right"} />
          </Link>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisations]
  );

  const isLoading =
    skusLoading || organisationsLoading || getProductStocksState === API_REQUEST_STATE.PENDING;

  const error =
    skusError || organisationsError || getProductStocksState === API_REQUEST_STATE.ERROR;

  return (
    <Page title={"Skus"} isLoading={isLoading} error={error}>
      <Panel
        withWrapper
        title={"List of all SKUs in Product Catalogue"}
        text={
          "Via this table you can view all SKUs in the Product Catalogue, add additional data to a SKU, and link a SKU with an organsation."
        }
        allignTitle="left"
        rightItems={[]}
        iconName={"alert-exclamation-outline"}
      >
        {
          // prettier-ignore
          //@ts-ignore
          <Table data={skus ? skus : []}
            columns={columns}
            renderFilters={(_: FilterSetter<any>) => <></>}
          />
        }
      </Panel>
    </Page>
  );
};

export default ViewSkus;
