import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";

export interface InTransitUpdateProps {
  handleResponse: (inTransitValues: InTransitUpdateInterface) => void;
  formValues: InTransitUpdateInterface;
  addresses: DropdownItem[];
  reset: () => void;
}

export interface DeliveryUpdateProps {
  handleDeliveredResponse: (deliveredValues: DeliveredUpdateInterface) => void;
  handleDeliveryDateResponse: (deliveryDateValues: DeliveryDateUpdateInterface) => void;
  deliveredValues: DeliveredUpdateInterface;
  deliveryDateValues: DeliveryDateUpdateInterface;
}

export interface InTransitUpdateInterface {
  shipmentIds: number[];
  fromDestination: DropdownItem | null;
  toDestination: DropdownItem | null;
  expectedDeliveryDate: string | undefined;
  notes: string;
  trackingLink: string;
}

export interface DeliveredUpdateInterface {
  shipmentIds: number[];
  actualDeliveryDate: string | undefined;
}

export interface DeliveryDateUpdateInterface {
  shipmentIds: number[];
  expectedDeliveryDate: string | undefined;
  notes: string;
}

export const initialInTransitFieldValues: InTransitUpdateInterface = {
  shipmentIds: [],
  fromDestination: null,
  toDestination: null,
  expectedDeliveryDate: undefined,
  notes: "",
  trackingLink: "",
};

export const initialDeliveredFieldValues: DeliveredUpdateInterface = {
  shipmentIds: [],
  actualDeliveryDate: undefined,
};

export const initialDeliveryDateFieldValues: DeliveryDateUpdateInterface = {
  shipmentIds: [],
  expectedDeliveryDate: undefined,
  notes: "",
};

export const inTransitFieldNames = {
  fromDestination: "fromDestination",
  toDestination: "toDestination",
  expectedDeliveryDate: "expectedDeliveryDate",
  notes: "notes",
  trackingLink: "trackingLink",
};

export const deliveredFieldNames = {
  actualDeliveryDate: "actualDeliveryDate",
};

export const deliveryDateFieldNames = {
  expectedDeliveryDate: "expectedDeliveryDate",
  notes: "notes",
};
