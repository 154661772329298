import { ApolloError } from "@apollo/client";

import { useGetManifestsByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapManifestsToCSV } from "../mappers/mapManifestsToCSV";

export interface HookResult {
  manifests: any[];
  manifestsError?: ApolloError;
  manifestsLoading: boolean;
  getManifestsByWarehouseId: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number | null;
  fromDate: string;
  toDate: string;
}

export const useManifestsByWarehouseId = (): HookResult => {
  const [getManifestsByWarehouseId, { data, error, loading }] =
    useGetManifestsByWarehouseIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    manifests:
      data &&
      Array.isArray(data.inbound_manifest_line) &&
      Array.isArray(data.outbound_manifest_line)
        ? mapManifestsToCSV(data)
        : [],
    manifestsError: error,
    manifestsLoading: loading,
    getManifestsByWarehouseId: async ({ warehouseId, fromDate, toDate }: Options) => {
      getManifestsByWarehouseId({ variables: { warehouseId, fromDate, toDate } });
    },
  };
};
