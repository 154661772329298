import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Chip, Icon, IconText, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  DateInput,
  FlexHolder,
  IconWrapper,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import {
  CreateStockCheckBody,
  CreateStockCheckLinesBody,
} from "../../shared/api/createStockCheck/types";
import { GetFilteredProductStockParams } from "../../shared/api/getFilteredProductStock/types";
import { validateUniqueBaseProducts } from "../../shared/api/getFilteredProductStock/validators";
import StockCheckLine from "../../shared/components/StockCheckLine";
import { useAllBaseProductNames } from "../../shared/graphql/hooks/useAllBaseProducts";
import { useWarehouseNames } from "../../shared/graphql/hooks/useWarehouseNames";
import { STOCK_CHECK_TYPE } from "../../shared/models";
import {
  createStockCheckAction,
  createStockCheckReset,
} from "../../shared/redux/actions/createStockCheckAction";
import {
  getFilteredProductStockAction,
  getFilteredProductStockReset,
} from "../../shared/redux/actions/getFilteredProductStockAction";
import { CreateStockCheckReducer } from "../../shared/redux/reducers/createStockCheckReducer";
import { GetFilteredProductStockReducer } from "../../shared/redux/reducers/getFilteredProductStockReducer";
import {
  AdditionalBaseProduct,
  CreateWeeklyStockCheckForm,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { mapBaseProductFormValuesToAPIParams } from "../mappers";
import { createWeeklyStockCheckValidation } from "../validation";

interface Props {
  createStockCheckState: CreateStockCheckReducer;
  createStockCheck: (body: CreateStockCheckBody) => Promise<void>;
  resetCreateStockCheck: () => void;
  getFilteredProductStockState: GetFilteredProductStockReducer;
  getFilteredProductStock: (params: GetFilteredProductStockParams) => Promise<void>;
  resetGetFilteredProductStock: () => void;
}

// TODO: states need to be held in one place and stepped through one by one using a reducer
// TODO: extract out components
function CreateWeeklytockCheck(props: Props) {
  const [initialValues, setInitialValues] =
    useState<CreateWeeklyStockCheckForm>(initialFieldValues);
  const [isEvaluatingBaseProducts, setIsEvaluatingBaseProducts] = useState<boolean>(false);
  const [isEvaluatingStockCheckLines, setIsEvaluatingStockCheckLines] = useState<boolean>(false);

  const {
    createStockCheckState,
    getFilteredProductStockState,
    getFilteredProductStock,
    createStockCheck,
    resetCreateStockCheck,
    resetGetFilteredProductStock,
  } = props;

  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useWarehouseNames();

  const { baseProductNames, baseProductNamesLoading, baseProductNamesError } =
    useAllBaseProductNames();

  const onSubmit = (values: CreateWeeklyStockCheckForm) => {
    const createStockCheckLines: CreateStockCheckBody["stockCheckLines"] =
      getFilteredProductStockState.productStocks.map(stock => {
        const createStockCheckLine: CreateStockCheckLinesBody = {
          expectedQuantity: stock.quantity,
          expectedStockConditionId: stock.stock_condition_id,
          productStockId: stock.id,
        };

        return createStockCheckLine;
      });

    const createStockCheckBody: CreateStockCheckBody = {
      notes: values.notes,
      stockCheckTypeId: STOCK_CHECK_TYPE.WEEKLY,
      dueDate: values.due_date ? new Date(values.due_date) : undefined,
      stockCheckLines: createStockCheckLines,
    };

    createStockCheck(createStockCheckBody);
  };

  useEffect(() => {
    if (createStockCheckState.success) {
      resetCreateStockCheck();
      resetGetFilteredProductStock();
      setInitialValues(initialFieldValues);
      setIsEvaluatingStockCheckLines(false);
      setIsEvaluatingBaseProducts(false);
    }
    if (createStockCheckState.error) {
      resetCreateStockCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStockCheckState.success, createStockCheckState.error]);

  const isError = warehouseNamesError || baseProductNamesError;
  const isLoading =
    warehouseNamesLoading ||
    baseProductNamesLoading ||
    getFilteredProductStockState.loading ||
    createStockCheckState.loading;

  return (
    <Page error={isError} isLoading={isLoading} title={"Operations - Stock Check"}>
      <Panel
        withWrapper
        title={"Create Weekly Stock Check"}
        subtitle={"Use the form below to create a Weekly stock check"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={createWeeklyStockCheckValidation}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              handleReset,
              setTouched,
              validateForm,
            }) => {
              const additonalBaseProductErrors = errors.additional_base_products as FormikErrors<
                AdditionalBaseProduct[]
              >;

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label
                        isRequired={true}
                        isGray={isEvaluatingBaseProducts ? true : false}
                        htmlFor={fieldNames.warehouse}
                      >
                        Warehouse
                      </Label>
                      {isEvaluatingBaseProducts ? (
                        <Box>{values.warehouse?.label}</Box>
                      ) : (
                        <Select
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          options={warehouseNames}
                          id={fieldNames.warehouse}
                          value={values.warehouse}
                          onChange={e => setFieldValue(fieldNames.warehouse, e)}
                          placeholder="Select Warehouse"
                        />
                      )}

                      {errors.warehouse && touched.warehouse ? (
                        <ErrorMessage>{errors.warehouse}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label
                        isGray={isEvaluatingBaseProducts ? true : false}
                        htmlFor={fieldNames.due_date}
                      >
                        Due Date
                      </Label>
                      {isEvaluatingBaseProducts ? (
                        <Box>
                          {values.due_date
                            ? moment(values.due_date).format("DD/MM/YYYY")
                            : "None Selected"}
                        </Box>
                      ) : (
                        <DateInput
                          id={fieldNames.due_date}
                          type="date"
                          value={values.due_date}
                          onChange={e => {
                            setFieldValue(fieldNames.due_date, e.target.value);
                          }}
                        />
                      )}

                      {errors.due_date && touched.due_date ? (
                        <ErrorMessage>{errors.due_date}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label
                        isGray={isEvaluatingBaseProducts ? true : false}
                        htmlFor={fieldNames.notes}
                      >
                        Notes
                      </Label>
                      {isEvaluatingBaseProducts ? (
                        <Box>{values.notes ? values.notes : "None Provided"}</Box>
                      ) : (
                        <InputField
                          id={fieldNames.notes}
                          size={"medium"}
                          type={"text"}
                          value={values.notes}
                          placeholder={"Input notes"}
                          handleChange={e => {
                            setFieldValue(fieldNames.notes, e.target.value);
                          }}
                        />
                      )}
                      {errors.notes && touched.notes ? (
                        <ErrorMessage>{errors.notes}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  <PrimaryLineWrapper>
                    <Title>Stock Interaction Date Range</Title>
                    <p>This automatically defaults to the last 5 days</p>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin>
                        <Label
                          isGray={isEvaluatingBaseProducts ? true : false}
                          htmlFor={fieldNames.stock_interaction_start_date}
                        >
                          Interaction Start Date (inclusive)
                        </Label>
                        {isEvaluatingBaseProducts ? (
                          <Box>
                            {values.stock_interaction_start_date
                              ? values.stock_interaction_start_date
                              : "None Selected"}
                          </Box>
                        ) : (
                          <DateInput
                            id={fieldNames.stock_interaction_start_date}
                            type="date"
                            value={values.stock_interaction_start_date}
                            onChange={e => {
                              setFieldValue(
                                fieldNames.stock_interaction_start_date,
                                e.target.value
                              );
                            }}
                          />
                        )}

                        {errors.stock_interaction_start_date &&
                        touched.stock_interaction_start_date ? (
                          <ErrorMessage>{errors.stock_interaction_start_date}</ErrorMessage>
                        ) : null}
                      </DataPoint>

                      <DataPoint withUniqueMargin>
                        <Label
                          isGray={isEvaluatingBaseProducts ? true : false}
                          htmlFor={fieldNames.stock_interaction_end_date}
                        >
                          Interaction End Date
                        </Label>
                        {isEvaluatingBaseProducts ? (
                          <Box>
                            {values.stock_interaction_end_date
                              ? values.stock_interaction_end_date
                              : "None Selected"}
                          </Box>
                        ) : (
                          <DateInput
                            id={fieldNames.stock_interaction_end_date}
                            type="date"
                            value={values.stock_interaction_end_date}
                            onChange={e => {
                              setFieldValue(fieldNames.stock_interaction_end_date, e.target.value);
                            }}
                          />
                        )}

                        {errors.stock_interaction_end_date && touched.stock_interaction_end_date ? (
                          <ErrorMessage>{errors.stock_interaction_end_date}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  {isEvaluatingBaseProducts &&
                    getFilteredProductStockState.success &&
                    !isEvaluatingStockCheckLines && (
                      <>
                        <PrimaryLineWrapper>
                          <Title>View Base Products </Title>
                          <p>
                            Below is the preview of all the base products found in the stock check
                            range
                          </p>
                          <PrimaryLineDataWrapper>
                            <SecondaryLineWrapper>
                              <Title>Base Products</Title>
                              {getFilteredProductStockState.baseProductNames.length > 0 ? (
                                <FlexHolder css={{ flexDirection: "column", textAlign: "center" }}>
                                  {getFilteredProductStockState.baseProductNames.map(
                                    baseProductName => {
                                      return (
                                        <Chip css={{ textAlign: "center" }}>{baseProductName}</Chip>
                                      );
                                    }
                                  )}
                                </FlexHolder>
                              ) : (
                                <p>No Product Stock Found</p>
                              )}
                            </SecondaryLineWrapper>
                          </PrimaryLineDataWrapper>
                        </PrimaryLineWrapper>

                        <PrimaryLineWrapper>
                          <Title>Add Additional Base Products:</Title>
                          <PrimaryLineDataWrapper>
                            {values.additional_base_products.length > 0 ? (
                              values.additional_base_products.map((value, index) => {
                                return (
                                  <>
                                    <DataPoint withUniqueMargin key={value.id}>
                                      <Label isGray={isEvaluatingStockCheckLines ? true : false}>
                                        Base Product
                                      </Label>
                                      {isEvaluatingStockCheckLines ? (
                                        <Box>{value.base_product?.label}</Box>
                                      ) : (
                                        <>
                                          <Select
                                            styles={reactSelectStyling}
                                            maxMenuHeight={220}
                                            isSearchable={true}
                                            options={baseProductNames}
                                            value={value.base_product}
                                            onChange={e => {
                                              setFieldValue(
                                                `additional_base_products[${index}].base_product`,
                                                e
                                              );
                                            }}
                                            placeholder="Select Base Product"
                                          />
                                          <IconWrapper
                                            type="button"
                                            onClick={() => {
                                              const filteredAdditionalLines =
                                                values.additional_base_products.filter(
                                                  item => item.id !== value.id
                                                );
                                              const newValues = { ...values };
                                              newValues.additional_base_products =
                                                filteredAdditionalLines;

                                              setInitialValues(newValues);
                                            }}
                                          >
                                            <Icon width={30} height={30} name="alert-cross-fill" />
                                          </IconWrapper>
                                        </>
                                      )}

                                      {Array.isArray(additonalBaseProductErrors) &&
                                      additonalBaseProductErrors[index]?.base_product ? (
                                        <ErrorMessage>
                                          {additonalBaseProductErrors[index]?.base_product}
                                        </ErrorMessage>
                                      ) : null}
                                    </DataPoint>
                                  </>
                                );
                              })
                            ) : (
                              <p>
                                If you would like to add additional base products, please click the
                                "Add Base Product button on the right". Any stock matching the
                                selected base products AND within the inputted date range will be
                                added to the stock check.
                              </p>
                            )}
                          </PrimaryLineDataWrapper>

                          <ButtonWrapper>
                            <SecondaryButton
                              type="button"
                              appearance={"whiteButtonBlueText"}
                              onClick={async () => {
                                setInitialValues({
                                  ...values,
                                  additional_base_products: [
                                    ...values.additional_base_products,
                                    {
                                      id: values.additional_base_products.length,
                                      base_product: null,
                                    },
                                  ],
                                });
                              }}
                            >
                              <IconText
                                text={"Add Base Product"}
                                primaryIcon={"alert-add-outline"}
                              />
                            </SecondaryButton>
                          </ButtonWrapper>
                        </PrimaryLineWrapper>
                      </>
                    )}

                  {(!isEvaluatingBaseProducts || !isEvaluatingStockCheckLines) && (
                    <ButtonWrapper>
                      <SecondaryButton
                        type="button"
                        appearance={"whiteButtonBlueText"}
                        onClick={async () => {
                          setTouched({
                            warehouse: true,
                            due_date: true,
                            notes: true,
                            stock_interaction_start_date: true,
                            stock_interaction_end_date: true,
                          });

                          const formErrors = await validateForm();

                          if (Object.keys(formErrors).length === 0) {
                            setInitialValues({
                              ...values,
                            });

                            const mappedBaseProducts = mapBaseProductFormValuesToAPIParams(
                              values.additional_base_products ?? []
                            );

                            const isBaseProductsValid =
                              validateUniqueBaseProducts(mappedBaseProducts);

                            if (!isBaseProductsValid) {
                              return;
                            }

                            await getFilteredProductStock({
                              warehouseId: values.warehouse?.value!,
                              stockCheckTypeId: STOCK_CHECK_TYPE.WEEKLY,
                              stockInteractionStartDate: moment(
                                values.stock_interaction_start_date
                              ).toDate(),
                              stockInteractionEndDate: moment(values.stock_interaction_end_date)
                                .add(1, "d")
                                .subtract(1, "ms")
                                .toDate(),
                              additionalBaseProductIds: mappedBaseProducts,
                              withBaseProductStock: true,
                            });

                            if (isEvaluatingBaseProducts) {
                              setIsEvaluatingStockCheckLines(true);
                            } else {
                              setIsEvaluatingBaseProducts(true);
                            }
                          }
                        }}
                        className={WMSButtonGroup({ type: "smallMargin" })}
                      >
                        <IconText
                          text={
                            isEvaluatingBaseProducts ? "Prepare Stock Check" : "Preview Stock Check"
                          }
                          primaryIcon={"alert-add-outline"}
                        />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {isEvaluatingStockCheckLines && getFilteredProductStockState.success && (
                    <>
                      <PrimaryLineWrapper>
                        <Title>Stock Check Preparation Summary</Title>
                        <PrimaryLineDataWrapper>
                          <DataPoint withUniqueMargin>
                            <Label isGray>Earliest Stock Interation Date</Label>
                            <Box>
                              {getFilteredProductStockState.earliestStockInteractionDate
                                ? moment(
                                    getFilteredProductStockState.earliestStockInteractionDate
                                  ).format("DD/MM/YYYY")
                                : "No Stock Found"}
                            </Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isGray>Latest Stock Interation Date</Label>
                            <Box>
                              {getFilteredProductStockState.latestStockInteractionDate
                                ? moment(
                                    getFilteredProductStockState.latestStockInteractionDate
                                  ).format("DD/MM/YYYY")
                                : "No Stock Found"}
                            </Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isGray>Number of Product Stocks</Label>
                            <Box>{getFilteredProductStockState.productStocks.length}</Box>
                          </DataPoint>
                        </PrimaryLineDataWrapper>
                        {getFilteredProductStockState.baseProductNames.length > 0 && (
                          <SecondaryLineWrapper>
                            <Title>Base Products</Title>
                            <FlexHolder css={{ flexDirection: "column", textAlign: "center" }}>
                              {getFilteredProductStockState.baseProductNames.map(
                                baseProductName => {
                                  return (
                                    <Chip css={{ textAlign: "center" }}>{baseProductName}</Chip>
                                  );
                                }
                              )}
                            </FlexHolder>
                          </SecondaryLineWrapper>
                        )}
                      </PrimaryLineWrapper>
                      {getFilteredProductStockState.productStocks.map((productStock, index) => {
                        return (
                          <StockCheckLine
                            key={productStock.id}
                            index={index}
                            line={productStock}
                          ></StockCheckLine>
                        );
                      })}
                    </>
                  )}

                  {isEvaluatingBaseProducts && (
                    <FormButtonPair
                      canRenderComfirm={
                        isEvaluatingStockCheckLines &&
                        getFilteredProductStockState.productStocks.length > 0 &&
                        getFilteredProductStockState.success
                      }
                      handleReset={() => {
                        handleReset();
                        setInitialValues({ ...initialFieldValues });
                        resetGetFilteredProductStock();
                        setIsEvaluatingBaseProducts(false);
                        setIsEvaluatingStockCheckLines(false);
                      }}
                    />
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createStockCheckState: state.createStockCheckReducer,
    getFilteredProductStockState: state.getFilteredProductStockReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createStockCheck: (body: CreateStockCheckBody) => dispatch(createStockCheckAction(body)),
    resetCreateStockCheck: () => dispatch(createStockCheckReset()),
    getFilteredProductStock: (params: GetFilteredProductStockParams) =>
      dispatch(getFilteredProductStockAction(params)),
    resetGetFilteredProductStock: () => dispatch(getFilteredProductStockReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWeeklytockCheck);
