import "../../../../../../redux/types/reduxTypes";
import { GET_USERS_FAILED, GET_USERS_LOADING, GET_USERS_RESET, GET_USERS_SUCCESS } from "../types";

export interface GetUsersReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: GetUsersReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createGetUsersReducer(
  state: GetUsersReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case GET_USERS_LOADING:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case GET_USERS_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case GET_USERS_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
