import {
  CREATE_WAREHOUSE_FAILED,
  CREATE_WAREHOUSE_LOADING,
  CREATE_WAREHOUSE_RESET,
  CREATE_WAREHOUSE_SUCCESS,
} from "../types";

export interface CreateWarehouseReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateWarehouseReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createWarehouseReducer(
  state: CreateWarehouseReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_WAREHOUSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_WAREHOUSE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_WAREHOUSE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_WAREHOUSE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
