import { IAM_API } from "../../../../../api/baseConfig";

export interface createIAMUserBody {
  username: string;
  email: string;
  nickname: string;
  givenname: string;
  name: string;
  userMetadata: Record<string, unknown>;
  emailVerified: boolean;
}

export const createIAMUser = async (params: createIAMUserBody) => {
  try {
    return IAM_API.post(`/v2/user`, {
      username: params.username,
      email: params.username,
      givenname: params.givenname,
      nickname: params.nickname,
      name: params.name,
      user_metadata: params.userMetadata,
      email_verified: params.emailVerified,
    });
  } catch (err) {
    console.error("Error in IAM create user API call", err);
    throw err;
  }
};
