import { ApolloError } from "@apollo/client";

import { useGetDeliveryDatesQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapDeliveryDates } from "../mappers";
import { DeliveryDateItem } from "../types";

export interface HookResult {
  deliveryDates?: DeliveryDateItem;
  deliveryDatesLoading: boolean;
  deliveryDatesError?: ApolloError;
  refetchDeliveryDates: () => Promise<void>;
}

export const useDeliveryDates = (): HookResult => {
  const { data, loading, error, refetch } = useGetDeliveryDatesQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    deliveryDates:
      data && Array.isArray(data.supplier_details)
        ? mapDeliveryDates(data.supplier_details)
        : undefined,
    deliveryDatesLoading: loading,
    deliveryDatesError: error,
    refetchDeliveryDates: async () => {
      refetch();
    },
  };
};
