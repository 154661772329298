import { ApolloError } from "@apollo/client";

import { useGetShipmentsByStatusIdLazyQuery } from "../../../../../../generated/graphql_logistics";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_LOGISTICS } from "../../../../../../shared/constants";
import { ADDRESS_TYPE, SHIPMENT_STATUS } from "../../../../shared/models";
import { MOVING_UPDATES, STATIC_UPDATES } from "../../types";
import { mapShipmentsToAddressType } from "../mappers";
import { ShipmentItem } from "../types";

export interface HookResult {
  shipments: ShipmentItem[];
  currentAddresses: DropdownItem[];
  fromAddresses: DropdownItem[];
  toAddresses: DropdownItem[];
  shipmentsError?: ApolloError;
  shipmentsLoading: boolean;
  getShipmentsByStatusId: (options: Options) => Promise<void>;
}

interface Options {
  updateTypeId: number;
}

export const useShipmentsByStatusId = (): HookResult => {
  const [getShipmentsByStatusId, { data, error, loading }] = useGetShipmentsByStatusIdLazyQuery({
    context: { clientName: GRAPHQL_LOGISTICS },
  });

  const isArray = data && Array.isArray(data.shipment);

  return {
    shipments: isArray ? data.shipment : [],
    currentAddresses: isArray ? mapShipmentsToAddressType(data.shipment, ADDRESS_TYPE.CURRENT) : [],
    fromAddresses: isArray ? mapShipmentsToAddressType(data.shipment, ADDRESS_TYPE.FROM) : [],
    toAddresses: isArray ? mapShipmentsToAddressType(data.shipment, ADDRESS_TYPE.TO) : [],
    shipmentsError: error,
    shipmentsLoading: loading,
    getShipmentsByStatusId: async ({ updateTypeId }: Options) => {
      let shipmentStatusId: number | null = null;
      if (STATIC_UPDATES.includes(updateTypeId)) {
        shipmentStatusId = SHIPMENT_STATUS.DELIVERY_INTERMEDIATE;
      }
      if (MOVING_UPDATES.includes(updateTypeId)) {
        shipmentStatusId = SHIPMENT_STATUS.IN_TRANSIT;
      }
      getShipmentsByStatusId({ variables: { shipmentStatusId } });
    },
  };
};
