import { useEffect, useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SM_ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { InlineCode } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { getIAMOrganisations } from "../api/getIAMOrganisations";

export interface IAMOrganisation {
  uuid?: string | null;
  name?: string | null;
  display_name?: string | null;
  metadata: any;
  external_account_id?: string | null;
  organisations_users_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

const OrganisationIdCell = ({ row }: CellProps<IAMOrganisation>) => (
  <InlineCode>{row.original.uuid}</InlineCode>
);

const ViewAndUpdateCell = ({ row }: CellProps<IAMOrganisation>) => (
  <Link to={`/admin/iam/organisations/${row.original.uuid}/${row.original.display_name}/members`}>
    <Icon name={"arrow-direction-right"} />
  </Link>
);

const ViewIAMOrganisations = () => {
  const [organisations, setOrganisations] = useState<IAMOrganisation[]>([]);
  const history = useHistory();
  const { roles } = useAuthorisationContext();

  const [getOrganisationsState, getOrganisations] = useAPI(getIAMOrganisations, true, {
    pending: "Fetching organisation...",
    success: "Organisations fetched successfully",
    error: "Error while trying to fetch organisations",
  });

  useEffect(() => {
    const fetchOrganisations = async () => {
      const response = await getOrganisations({
        offset: 0,
        limit: 100000,
      });
      const organisationsdata = Array.isArray(response)
        ? response[0].data.data
        : response.data.data;
      setOrganisations(organisationsdata);
    };

    fetchOrganisations().catch(error => {
      console.error("error getting organisations:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 1,
        accessor: (organisation: IAMOrganisation) => organisation.uuid,
        Cell: OrganisationIdCell,
        filter: "multiRow",
      },
      {
        Header: "Name",
        widthPercent: 1,
        accessor: (organisation: IAMOrganisation) => organisation.name,
        filter: "multiRow",
      },
      {
        Header: "Display Name",
        widthPercent: 1,
        accessor: (organisation: IAMOrganisation) => organisation.display_name,
        filter: "multiRow",
      },
      {
        Header: "Metadata",
        widthPercent: 1,
        accessor: (organisation: IAMOrganisation) => JSON.stringify(organisation.metadata),
        filter: "multiRow",
      },
      {
        Header: "Number of Members",
        widthPercent: 1,
        accessor: (organisation: IAMOrganisation) =>
          organisation.organisations_users_aggregate.aggregate.count,
        filter: "multiRow",
      },
      {
        Header: "Salesforce ID",
        accessor: (organisation: IAMOrganisation) => organisation.external_account_id,
      },
      {
        Header: "View & Update Members",
        widthPercent: 1,
        Cell: ViewAndUpdateCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisations]
  );

  const items = SM_ADMIN_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push(`/admin/iam/organisations/create`);
          },
          type: "button",
          title: "Create Organisation",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];

  return (
    <Page
      title={"IAM Organisations"}
      isLoading={getOrganisationsState === API_REQUEST_STATE.PENDING}
      error={getOrganisationsState === API_REQUEST_STATE.ERROR}
    >
      <Panel
        withWrapper
        title={"Organisation List"}
        text={"Via this table you can view all organisations"}
        allignTitle="left"
        rightItems={rightItems}
        iconName={"toggle-star-fill"}
      >
        <Table
          data={organisations ?? []}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
};

export default ViewIAMOrganisations;
