import { FormikErrors, FormikTouched } from "formik";
import { useState } from "react";

import { Icon, InputField } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import ToolTip from "../../../../../shared/components/atoms/labels/ToolTip";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  ButtonPositioner,
  ColumnWrapper,
  DataPoint,
  IconWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  RowWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { SelectedProductStockLinesInterface } from "../formValues";

interface ProductLineProps {
  values: SelectedProductStockLinesInterface;
  index: number;
  errors: FormikErrors<SelectedProductStockLinesInterface[]> | undefined;
  touched: FormikTouched<SelectedProductStockLinesInterface>[] | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  RemoveProductStock: (index: number) => void;
}

export const CaseQuantityLine = ({
  values,
  setFieldValue,
  index,
  errors,
  touched,
  RemoveProductStock,
}: ProductLineProps) => {
  const [isWarning, setIsWarning] = useState<boolean>(false);

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />

      <IconWrapper removalIconWrapper type="button" onClick={() => RemoveProductStock(index)}>
        <Icon width={30} height={30} name="alert-cross-fill" />
      </IconWrapper>

      <PrimaryLineWrapper style={{ width: "100%" }}>
        <PrimaryLineDataWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>ID</RowWrapper>
            <RowWrapper>{values.id}</RowWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>Name</RowWrapper>
            <RowWrapper>{values.product_name}</RowWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>Base Product Quantity</RowWrapper>
            <RowWrapper>{values.base_product_quantity}</RowWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>Location</RowWrapper>
            <RowWrapper>{values.location}</RowWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>Condition</RowWrapper>
            <RowWrapper>{values.condition}</RowWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>Quantity</RowWrapper>
            <RowWrapper>{values.quantity}</RowWrapper>
          </ColumnWrapper>
          <ColumnWrapper>
            <RowWrapper type={"title"}>Total Base Quantity</RowWrapper>
            <RowWrapper>{values.total_base_product_quantity}</RowWrapper>
          </ColumnWrapper>
        </PrimaryLineDataWrapper>
      </PrimaryLineWrapper>
      <ButtonPositioner fullWidth>
        <DataPoint>
          {isWarning && <Label warning>{"Insufficient quantity of stock"}</Label>}
          <ToolTip
            message={`Select what quantity of ${values.product_name} <br/> you want to use`}
            title={"Quantity *"}
            htmlFor={`quantity_${index}`}
          ></ToolTip>
          <InputField
            id={`quantity_${index}`}
            size={"medium"}
            type={"number"}
            value={values.selected_quantity}
            handleChange={e => {
              setFieldValue(
                `selected_product_stock_lines[${index}].selected_quantity`,
                e.target.value
              );
              if (parseInt(e.target.value) > parseInt(values.quantity)) {
                setIsWarning(true);
              }
              if (parseInt(e.target.value) <= parseInt(values.quantity)) {
                setIsWarning(false);
              }
            }}
          />
          <Label warning={isWarning}>{`Available Quantity: ${values.quantity}`}</Label>
          {Array.isArray(errors) &&
          errors[index]?.selected_quantity &&
          Array.isArray(touched) &&
          touched[index]?.selected_quantity ? (
            <ErrorMessage>{errors[index]?.selected_quantity}</ErrorMessage>
          ) : null}
        </DataPoint>
      </ButtonPositioner>
    </SecondaryLineWrapper>
  );
};
