import { Dispatch } from "redux";

import { getUsersAPI } from "../../api/getUserNamesPOApprovals";
import { GET_USERS_FAILED, GET_USERS_LOADING, GET_USERS_RESET, GET_USERS_SUCCESS } from "../types";

export const getUsersLoading = () => {
  return {
    type: GET_USERS_LOADING,
  };
};

export const getUsersSuccess = () => {
  return {
    type: GET_USERS_SUCCESS,
  };
};

export const getUsersFailed = () => {
  return {
    type: GET_USERS_FAILED,
  };
};

export const getUsersReset = () => {
  return {
    type: GET_USERS_RESET,
  };
};

export const getUsersAction = (ids: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const users = await getUsersAPI(ids);
      dispatch(getUsersSuccess());
      return users;
    } catch (error) {
      console.log(error, "Error Getting Users");
      dispatch(getUsersFailed());
    }
  };
};
