export interface RecipeLine {
  base_product: {
    value: number;
    label: string;
  } | null;
  quantity: string;
}

export interface RecipeForm {
  recipe_name: string;
  recipe_type: {
    value: number;
    label: string;
  } | null;
  use_for_reporting: boolean;
  inputLines: RecipeLine[];
  outputLines: RecipeLine[];
}

export const emptyRecipeLine: RecipeLine = {
  quantity: "",
  base_product: null,
};

export const initialFieldValues: RecipeForm = {
  recipe_type: null,
  recipe_name: "",
  use_for_reporting: false,
  inputLines: [emptyRecipeLine],
  outputLines: [emptyRecipeLine],
};

export const fieldNames = {
  recipe_name: "recipe_name",
  recipe_type: "recipe_type",
  use_for_reporting: "use_for_reporting",
};
