export interface CreateBasicStockCheckForm {
  warehouse: {
    value: number;
    label: string;
  } | null;
  due_date: string;
  notes: string;
  stock_check_type: {
    value: number;
    label: string;
  } | null;
  base_product: {
    value: number;
    label: string;
  } | null;
  location: {
    value: number;
    label: string;
  } | null;
}

export const fieldNames = {
  warehouse: "warehouse",
  notes: "notes",
  due_date: "due_date",
  stock_check_type: "stock_check_type",
  location: "location",
  base_product: "base_product",
};

export const initialFieldValues: CreateBasicStockCheckForm = {
  warehouse: null,
  base_product: null,
  due_date: "",
  notes: "",
  stock_check_type: null,
  location: null,
};
