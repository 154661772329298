import { ApolloError } from "@apollo/client";

import { useGetLocationByWarehouseAndNameLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { FoundLocationItem } from "../types";

export interface HookResult {
  foundLocation?: FoundLocationItem[];
  foundLocationError?: ApolloError;
  foundLocationLoading: boolean;
  getLocationByWarehouseAndName: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number | null;
  locationName: string;
}

export const useLocationByWarehouseAndName = (): HookResult => {
  const [getLocationByWarehouseAndName, { data, error, loading }] =
    useGetLocationByWarehouseAndNameLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    foundLocation: data && Array.isArray(data.location) ? data.location : [],
    foundLocationError: error,
    foundLocationLoading: loading,
    getLocationByWarehouseAndName: async ({ warehouseId, locationName }: Options) => {
      getLocationByWarehouseAndName({ variables: { warehouseId, locationName } });
    },
  };
};
