import { EmptyLocationItem } from "../types";

export const mapEmptyLocationsToCSV = (emptyLocations: EmptyLocationItem[]) => {
  const emptyLocationsOnly = emptyLocations.filter(emptyLocation => {
    return (
      emptyLocation.product_stocks_aggregate.aggregate?.sum?.quantity === 0 ||
      emptyLocation.product_stocks_aggregate.aggregate?.sum?.quantity === null
    );
  });

  return emptyLocationsOnly.map(data => {
    return {
      location_id: data.id,
      location_name: data.location_name,
      location_type: data.location_type.location_type_name,
      warehouse_name: data.warehouse.warehouse_name,
    };
  });
};
