import { ApolloError } from "@apollo/client";

import { useGetUnderlyingCaseProductLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { CaseProductItem } from "../types";

export interface HookResult {
  caseProducts: CaseProductItem[];
  caseProductsError?: ApolloError;
  caseProductsLoading: boolean;
  getUnderlyingCaseProductByBaseProductId: (options: Options) => Promise<void>;
}

export interface Options {
  baseProductId: number;
}

export const useUnderlyingCaseProductByBaseProductId = (): HookResult => {
  const [getUnderlyingCaseProductByBaseProductId, { data, error, loading }] =
    useGetUnderlyingCaseProductLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    caseProducts: data && Array.isArray(data.product) ? data.product : [],
    caseProductsError: error,
    caseProductsLoading: loading,
    getUnderlyingCaseProductByBaseProductId: async ({ baseProductId }: Options) => {
      getUnderlyingCaseProductByBaseProductId({ variables: { baseProductId } });
    },
  };
};
