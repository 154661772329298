import { Formik, FormikState } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { useToasts } from "react-toast-notifications";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  StyledForm,
  TopPanelInputsWrapper,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { CreateLocationBody } from "../api/types";
import { canHoldStockOptions } from "../constants";
import { LocationForm, fieldNames, initialFieldValues } from "../formValues";
import { useAllLocationTypeNames } from "../graphql/hooks/useAllLocationTypeNames";
import { useAllWarehouseNames } from "../graphql/hooks/useAllWarehouseNames";
import { useLocationByWarehouseAndName } from "../graphql/hooks/useLocationByWarehouseAndName";
import { useParentLocationsByWarehouseId } from "../graphql/hooks/useParentLocationsByWarehouseId";
import { createLocationAction, createLocationReset } from "../redux/actions/createLocationAction";
import { CreateLocationReducer } from "../redux/reducers/createLocationReducer";
import { validationSchema } from "../validation";

interface Props {
  createLocationState: CreateLocationReducer;
  createLocation: (body: CreateLocationBody) => void;
  resetCreateLocation: () => void;
}

interface FoundLocationQueryParams {
  locationName: string;
  warehouseId: number | null;
}

const initialFoundLocationQueryParams: FoundLocationQueryParams = {
  locationName: "",
  warehouseId: null,
};

const AddLocation = (props: Props) => {
  const [initialValues] = useState<LocationForm>(initialFieldValues);
  const [foundLocationQueryParams, setFoundLocationQueryParams] =
    useState<FoundLocationQueryParams>(initialFoundLocationQueryParams);

  const { addToast } = useToasts();

  const { createLocationState } = props;

  const { locationTypeNames, locationTypeNamesLoading, locationTypeNamesError } =
    useAllLocationTypeNames();
  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useAllWarehouseNames();
  const {
    getParentLocationsByWarehouseId,
    parentLocations,
    parentLocationsLoading,
    parentLocationsError,
  } = useParentLocationsByWarehouseId();

  const { getLocationByWarehouseAndName, foundLocation, foundLocationError } =
    useLocationByWarehouseAndName();

  const onSubmit = async (
    values: LocationForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<LocationForm>>) => void }
  ) => {
    const location: CreateLocationBody = {
      warehouse_id: values.warehouse?.value!,
      location_type_id: values.location_type?.value!,
      location_name: values.location_name,
      can_hold_stock: values.can_hold_stock?.label === "true" ? true : false,
    };

    if (values.parent_location) {
      location.parent_location_id = values.parent_location?.value;
    }

    if (foundLocation && foundLocation?.length > 0) {
      addToast("A location with this name already exists at this warehouse.", {
        appearance: "error",
        autoDismiss: true,
      });
      return;
    }

    props.createLocation(location);
    resetForm();
  };

  useEffect(() => {
    if (createLocationState.success) {
      props.resetCreateLocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLocationState.success, createLocationState.error]);

  useEffect(() => {
    getLocationByWarehouseAndName(foundLocationQueryParams);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foundLocationQueryParams]);

  const error =
    locationTypeNamesError || warehouseNamesError || parentLocationsError || foundLocationError;

  const isLoading = locationTypeNamesLoading || warehouseNamesLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Locations"}>
      <Panel withWrapper title={"Add Location"} subtitle={"Create a location within a warehouse"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleReset, handleSubmit, errors, touched }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label htmlFor={fieldNames.warehouse} isRequired>
                        Warehouse
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        placeholder={"Select a Warehouse"}
                        value={values.warehouse}
                        options={warehouseNames}
                        onChange={e => {
                          setFieldValue(fieldNames.warehouse, e);
                          setFieldValue(fieldNames.parent_location, null);
                          getParentLocationsByWarehouseId({ warehouseId: e?.value! });
                          setFoundLocationQueryParams({
                            ...foundLocationQueryParams,
                            warehouseId: e?.value!,
                          });
                        }}
                        isSearchable={true}
                        maxMenuHeight={220}
                        id={fieldNames.warehouse}
                      />
                      {errors.warehouse && touched.warehouse ? (
                        <ErrorMessage>{errors.warehouse}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label htmlFor={fieldNames.location_type} isRequired>
                        Location Type
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        value={values.location_type}
                        placeholder={"Select a Location Type"}
                        options={locationTypeNames}
                        onChange={e => setFieldValue(fieldNames.location_type, e)}
                        isSearchable={true}
                        maxMenuHeight={220}
                        id={fieldNames.location_type}
                      />
                      {errors.location_type && touched.location_type ? (
                        <ErrorMessage>{errors.location_type}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label htmlFor={fieldNames.location_name} isRequired>
                        Location Name
                      </Label>
                      <InputField
                        id={fieldNames.location_name}
                        size={"medium"}
                        type={"text"}
                        placeholder={"Type Your Location Name"}
                        value={values.location_name}
                        handleChange={e => {
                          setFieldValue(fieldNames.location_name, e.target.value);
                          setFoundLocationQueryParams({
                            ...foundLocationQueryParams,
                            locationName: e.target.value,
                          });
                        }}
                      />
                      {errors.location_name && touched.location_name ? (
                        <ErrorMessage>{errors.location_name}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label htmlFor={fieldNames.can_hold_stock} isRequired>
                        Can Hold Stock
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        value={values.can_hold_stock}
                        placeholder={"Select Correct Option"}
                        options={canHoldStockOptions}
                        onChange={e => setFieldValue(fieldNames.can_hold_stock, e)}
                        isSearchable={true}
                        maxMenuHeight={220}
                        id={fieldNames.can_hold_stock}
                      />
                      {errors.can_hold_stock && touched.can_hold_stock ? (
                        <ErrorMessage>{errors.can_hold_stock}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    {values.warehouse !== null && (
                      <DataPoint>
                        <Label htmlFor={fieldNames.parent_location}>Parent Location</Label>
                        <Select
                          styles={reactSelectStyling}
                          value={values.parent_location}
                          options={parentLocations}
                          placeholder={
                            parentLocationsLoading ? "Options Loading" : "Select Parent Location"
                          }
                          onChange={e => setFieldValue(fieldNames.parent_location, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                          id={fieldNames.parent_location}
                        />
                        {errors.parent_location && touched.parent_location ? (
                          <ErrorMessage>{errors.parent_location}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    )}
                  </TopPanelInputsWrapper>

                  <FormButtonPair handleReset={handleReset} />
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createLocationState: state.createLocationReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createLocation: (body: CreateLocationBody) => dispatch(createLocationAction(body)),
    resetCreateLocation: () => dispatch(createLocationReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddLocation);
