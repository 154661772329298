import { SOURCING_API } from "../../../../../api/baseConfig";

export const createQuotationModelExportLineAPI = async (id: number) => {
  try {
    return await SOURCING_API.post(`/quotation-model-export-lines/${id}`);
  } catch (err) {
    console.log("Error in create quotation model export line API call", err);
    throw err;
  }
};
