import {
  CREATE_STOCK_CHECK_FAILURE,
  CREATE_STOCK_CHECK_RESET,
  CREATE_STOCK_CHECK_SUCCESS,
  CREATING_STOCK_CHECK,
} from "../types";

export interface CreateStockCheckReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateStockCheckReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createStockCheckReducer(
  state: CreateStockCheckReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATING_STOCK_CHECK:
      return {
        ...state,
        loading: true,
      };
    case CREATE_STOCK_CHECK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_STOCK_CHECK_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_STOCK_CHECK_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
