import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import {
  Box,
  DataPoint,
  FlexHolder,
  InfoBox,
  Label,
  PrimaryLineDataWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { StockCheckLineItem } from "../../shared/graphql/types";
import { accuracyClassifier, calculateStockCheckLinePercentageAccuracy } from "../helpers";

interface StockCheckLineToFulfilProps {
  stockCheckLine: StockCheckLineItem;
  index: number;
}

const StockCheckLineToFulfil = ({ stockCheckLine, index }: StockCheckLineToFulfilProps) => {
  const percentageAccuracy = calculateStockCheckLinePercentageAccuracy({
    stockCheckLine: stockCheckLine,
  });

  return (
    <>
      <FlexHolder css={{ justifyContent: "space-between" }}>
        <Title>Stock Check Line {index + 1}</Title>
        <StatusBadge
          type="wms"
          statusName={stockCheckLine.stock_check_line_status.stock_check_line_status_name}
        />
      </FlexHolder>

      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin={true}>
          <Label isGray>Product</Label>
          <Box>{stockCheckLine.product_stock.product.product_name} </Box>
        </DataPoint>
        <DataPoint withUniqueMargin={true}>
          <Label isGray>Organisation</Label>
          <Box>{stockCheckLine.product_stock.organisation?.organisation_name} </Box>
        </DataPoint>
        <DataPoint withUniqueMargin={true}>
          <Label isGray> Expected Condition </Label>
          <Box> {stockCheckLine.product_stock.stock_condition.stock_condition_name} </Box>
        </DataPoint>
        <DataPoint withUniqueMargin={true}>
          <Label isGray> Location </Label>
          <Box> {stockCheckLine.product_stock.location.location_name} </Box>
        </DataPoint>
        <DataPoint withUniqueMargin={true}>
          <Label isGray> Expected Status </Label>
          <Box> {stockCheckLine.product_stock.stock_status.stock_status_name} </Box>
        </DataPoint>
        <DataPoint important withUniqueMargin={true}>
          <Label isGray> Expected Quantity </Label>
          <Box> {stockCheckLine.expected_quantity} </Box>
        </DataPoint>
        <DataPoint withUniqueMargin={true}>
          <Label isGray> Fulfilments Made </Label>
          <Box> {stockCheckLine.stock_check_line_fulfilments.length} </Box>
        </DataPoint>
        <DataPoint>
          <Label isGray> Accuracy</Label>
          <InfoBox type={accuracyClassifier({ percentageAccuracy: percentageAccuracy })}>
            {percentageAccuracy.toFixed()} %
          </InfoBox>
        </DataPoint>
      </PrimaryLineDataWrapper>
    </>
  );
};

export default StockCheckLineToFulfil;
