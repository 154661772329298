import { ChangeEvent, useEffect, useRef, useState } from "react";
import { useMediaQuery } from "react-responsive";

import { breakpoints, styled } from "@sourceful/shared-components";

import PaginationButton from "./PaginationButton";

const PaginationWrapper = styled("div", {
  padding: "1.5rem",
  width: "100%",
  alignItems: "center",
  display: "flex",
  justifyContent: "space-between",
  "@lg": {
    padding: "1.5rem 3rem",
  },
});

const PageCountSection = styled("div", {
  display: "grid",
  gridTemplateColumns: "max-content max-content",
  alignItems: "center",
  gridGap: "10px",
});

const PaginationInput = styled("input", {
  width: "44px",
  textAlign: "center",
  borderColor: "#404044",
  backgroundColor: "#f6f6f6",
  borderWidth: "2px",
  borderRadius: "$medium",

  "&::-webkit-outer-spin-button": {
    " -webkit-appearance": "none",
    margin: "0",
  },
  "&::-webkit-inner-spin-button": {
    " -webkit-appearance": "none",
    margin: "0",
  },

  /* Firefox */
  "&[type=number]": {
    "-moz-appearance": "textfield",
  },
});

const PageCount = styled("strong", {
  color: "#404044",
});

interface PaginationProps {
  pageCount: number;
  previousPage: (pageIndex: number) => void;
  nextPage: (pageIndex: number) => void;
  pageIndex: number;
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (page: number) => void;
  pageOptions: number[];
}

const Pagination = ({
  nextPage,
  previousPage,
  pageIndex,
  canNextPage,
  canPreviousPage,
  gotoPage,
  pageOptions,
}: PaginationProps) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const isTabletOrDesktop = useMediaQuery({ query: `(min-width: ${breakpoints.md}px)` });

  const [pageInputValue, setPageInputValue] = useState(String(pageIndex + 1));

  useEffect(() => {
    setPageInputValue(String(pageIndex + 1));
  }, [pageIndex]);

  const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const value = Number(e.target.value);
    if (value > pageOptions.length) {
      return setPageInputValue(String(pageOptions.length));
    }

    if (Math.sign(value) === -1) {
      return setPageInputValue("1");
    }

    setPageInputValue(e.target.value);
  };

  return (
    <PaginationWrapper>
      <PaginationButton
        handleClick={() => {
          previousPage(pageIndex - 1);
        }}
        isDisabled={!canPreviousPage}
        type="previous"
        isIconOnly={!isTabletOrDesktop}
      />

      <PageCountSection>
        <PaginationInput
          ref={inputRef}
          type="number"
          value={pageInputValue}
          onChange={handleInputChange}
          onKeyPress={e => {
            if (e.key === "Enter") {
              inputRef?.current?.blur();
            }
          }}
          onBlur={() => {
            const newPageIndex = Number(pageInputValue) - 1;

            gotoPage(newPageIndex);
          }}
        />
        <PageCount>
          of {pageOptions.length} {isTabletOrDesktop && "pages"}
        </PageCount>
      </PageCountSection>

      <PaginationButton
        handleClick={() => {
          nextPage(pageIndex + 1);
        }}
        isDisabled={!canNextPage}
        type="next"
        isIconOnly={!isTabletOrDesktop}
      />
    </PaginationWrapper>
  );
};

export default Pagination;
