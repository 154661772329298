import { ApolloError } from "@apollo/client";

import { useGetStockCheckProductStockByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStock: ProductStockItem[];
  productStockError?: ApolloError;
  productStockLoading: boolean;
  getProductStockByWarehouseId: (options: Options) => Promise<void>;
}

interface Options {
  warehouseId: number;
}

export const useProductStockByWarehouseId = (): HookResult => {
  const [getProductStockByWarehouseId, { data, error, loading }] =
    useGetStockCheckProductStockByWarehouseIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    productStock: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    productStockError: error,
    productStockLoading: loading,
    getProductStockByWarehouseId: async ({ warehouseId }: Options) => {
      getProductStockByWarehouseId({ variables: { warehouseId } });
    },
  };
};
