import moment from "moment";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import { PurchaseOrderApprovalItem } from "../graphql/types";
import { PURCHASE_ORDER_STATUS } from "../types";

export const mapSupplierResponseStatus = (status: string) => {
  if (status === "Approved") return "Sent";
  if (status === "Pending" || status === "Internally Rejected") return "Not Sent";
  if (status === "Accepted" || status === "In Transit" || status === "Delivered") return "Accepted";
  if (status === "Expired" || status === "Externally Rejected") return "Rejected";
};

export const mapStatusToType = (status: string) => {
  if (status in Object.values(PURCHASE_ORDER_STATUS)) {
    return status.replace(/\s/g, "") as any;
  }
  return "";
};

export const mapFinanceAttachmentsToDTO = (attachments: UploadedFile[]) => {
  return attachments.map((item: UploadedFile) => {
    return {
      originalFilename: item.original_filename,
      uuid: item.attachment_uuid,
      notes: "",
      metadata: JSON.stringify({ fileType: "finance" }),
    };
  });
};

export const mapPurchaseOrderApprovalsToObject = (approvals: PurchaseOrderApprovalItem[]) => {
  return approvals.map(approval => {
    return {
      isApproved: approval.is_approved,
      type: approval.purchase_order_approval_type.purchase_order_approval_type_name,
      userId: approval.user_id,
      createdAt: moment(approval.created_at).format("DD-MM-YYYY"),
    };
  });
};

export const mapApprovalToDateSent = (approvals: PurchaseOrderApprovalItem[]) => {
  // eslint-disable-next-line array-callback-return
  return approvals.map(approval => {
    if (approval.purchase_order_approval_type.purchase_order_approval_type_name === "Order") {
      return moment(approval.created_at).format("DD-MM-YYYY");
    }
  });
};
