import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, InputField } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import {
  Box,
  LinkText,
  PrimaryLineWrapper,
  RectangleStatusBadge,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { DataPoint, Label, SecondaryLineWrapper } from "../../../../../styles/SharedStyles";
import { FulfilOutboundManifestLineBody } from "../api/types";
import { OutboundManifestFulfilmentLineForm, initialFieldValues } from "../formValues";
import { useAvailableAllocatedProductStockByBaseProductId } from "../graphql/hooks/useAvailableAllocatedProductStockByBaseProductId";
import { OutboundManifestLineItem, ProductStockItem } from "../graphql/types";
import {
  fulfilOutboundManifestLine,
  fulfilOutboundManifestLineReset,
} from "../redux/actions/fulfillOutboundManifestLineAction";
import { FulfilOutboundManifestLineReducer } from "../redux/reducers/fulfilOutboundManifestLineReducer";

export interface OBMFulfillerProps {
  organisation_id: number;
  warehouse_id: number;
  outbound_manifest_id: number;
  setOBMLineToFulfil: (val: number | null) => void;
  outbound_manifest_line: OutboundManifestLineItem;
  fulfilOBMLineState: FulfilOutboundManifestLineReducer;
  fulfilOBMLine: (body: FulfilOutboundManifestLineBody) => void;
  fulfilOutboundManifestLineReset: () => void;
  refetchOutboundManifest: () => void;
}

const initialErrors = {
  quantity: false,
  product_stock: false,
};

const FulfilOutboundManifestLine = ({
  warehouse_id,
  organisation_id,
  outbound_manifest_id,
  outbound_manifest_line,
  setOBMLineToFulfil,
  fulfilOBMLineState,
  fulfilOBMLine,
  fulfilOutboundManifestLineReset,
  refetchOutboundManifest,
}: OBMFulfillerProps) => {
  const [values, setValues] = useState<OutboundManifestFulfilmentLineForm>(initialFieldValues);
  const [errors, setErrors] = useState<{
    quantity: boolean;
    product_stock: boolean;
  }>(initialErrors);

  const { productStock } = useAvailableAllocatedProductStockByBaseProductId({
    organisationId: organisation_id,
    warehouseId: warehouse_id,
    baseProductId: outbound_manifest_line.product.base_product_id!,
  });

  const handleFulfil = ({
    outbound_manifest_line_id,
    quantity,
    product_stock_id,
    outbound_manifest_id,
  }: {
    outbound_manifest_line_id: number;
    quantity: number;
    product_stock_id: number;
    outbound_manifest_id: number;
  }) => {
    const fulfilmentLine: FulfilOutboundManifestLineBody = {
      quantity: quantity,
      outbound_manifest_line_id,
      product_stock_id: product_stock_id,
      outbound_manifest_id: outbound_manifest_id,
    };

    fulfilOBMLine(fulfilmentLine);
  };

  useEffect(() => {
    if (fulfilOBMLineState.success) {
      refetchOutboundManifest();
      fulfilOutboundManifestLineReset();
      setOBMLineToFulfil(null);
    }
    if (fulfilOBMLineState.error) {
      fulfilOutboundManifestLineReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilOBMLineState.success, fulfilOBMLineState.error]);

  const columns = [
    {
      Header: "#",
      widthPercent: 0.05,
      accessor: (listItem: ProductStockItem) => listItem.id,
    },
    {
      Header: "Product Name",
      widthPercent: 0.1,
      accessor: (listItem: ProductStockItem) => listItem.product.product_name,
      Cell: ({ _, row }: CellProps<ProductStockItem>) => (
        <Link to={`/inventory/products/view-product/${row.original.product.base_product_id}`}>
          <LinkText>{row.original.product.product_name}</LinkText>
        </Link>
      ),
    },
    {
      Header: "Location",
      widthPercent: 0.4,
      Cell: ({ _, row }: CellProps<ProductStockItem>) => (
        <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
          <LinkText>{row.original.location.location_name}</LinkText>
        </Link>
      ),
      accessor: (listItem: ProductStockItem) => listItem.location.location_name,
    },
    {
      Header: "Stock status",
      widthPercent: 0.4,
      accessor: (listItem: ProductStockItem) => (
        <RectangleStatusBadge
          status={listItem.stock_status.stock_status_name.replace(/\s/g, "") as any}
        >
          {listItem.stock_status.stock_status_name}
        </RectangleStatusBadge>
      ),
    },
    {
      Header: "Quantity",
      widthPercent: 0.1,
      accessor: (listItem: ProductStockItem) => listItem.quantity,
    },
    {
      Header: "Stock condition",
      widthPercent: 0.1,
      accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
    },
    {
      Header: "Organisation",
      widthPercent: 0.1,
      Cell: ({ _, row }: CellProps<ProductStockItem>) => (
        <Link to={`/inventory/organisations/view-organisation/${row.original.organisation?.id}`}>
          <LinkText>{row.original.organisation?.organisation_name}</LinkText>
        </Link>
      ),
      accessor: (listItem: ProductStockItem) => listItem.organisation?.organisation_name,
    },
    {
      Header: "Choose Stock",
      widthPercent: 0.05,

      Cell: ({ _, row }: CellProps<ProductStockItem>) => {
        if (values.product_stock === row.original.id) {
          return <SecondaryButton appearance={"greenButton"}>Chosen</SecondaryButton>;
        }
        return (
          <SecondaryButton
            onClick={() => {
              setErrors(errors => ({ ...errors, product_stock: false }));
              setValues(current_values => ({ ...current_values, product_stock: row.original.id }));
            }}
            type="button"
            isWithinTable
            appearance="blueButton"
          >
            Choose
          </SecondaryButton>
        );
      },
    },
  ];

  const onSubmit = () => {
    if (!values.product_stock && values.quantity === "") {
      setErrors({ quantity: true, product_stock: true });
    }

    if (!values.product_stock) {
      setErrors(errors => ({ ...errors, product_stock: true }));
    }

    if (!values.quantity) {
      setErrors(errors => ({ ...errors, quantity: true }));
    }

    if (values.product_stock && values.quantity !== "") {
      handleFulfil({
        product_stock_id: values.product_stock!,
        quantity: Number(values.quantity),
        outbound_manifest_line_id: outbound_manifest_line.id,
        outbound_manifest_id: outbound_manifest_id,
      });

      setValues(initialFieldValues);
      setErrors(initialErrors);
      refetchOutboundManifest();
    }
  };

  let stockBaseQuantity =
    productStock.find(stock => stock.id === values.product_stock)?.product.base_product_quantity ??
    1;

  let currentProductStockQuantity =
    productStock.find(stock => stock.id === values.product_stock)?.quantity ?? 0;

  return (
    <>
      <PrimaryLineWrapper>
        <Table data={productStock} columns={columns} />
        {errors.product_stock ? <ErrorMessage>Product Stock is required</ErrorMessage> : null}
      </PrimaryLineWrapper>

      <SecondaryLineWrapper>
        <DataPoint>
          <Label isRequired>Quantity </Label>
          {values.product_stock ? (
            <InputField
              type="number"
              size="medium"
              value={values.quantity}
              placeholder={"Input quantity"}
              handleChange={e => {
                if (e.target.value.length > 0) {
                  setErrors(errors => ({ ...errors, quantity: false }));
                }
                setValues(currentValues => ({ ...currentValues, quantity: e.target.value }));
              }}
            />
          ) : (
            <Box> Please select a Product Stock</Box>
          )}
          {errors.quantity ? <ErrorMessage>Quantity is Required</ErrorMessage> : null}
        </DataPoint>
        <DataPoint>
          <Label isGray>Remaining Quantity</Label>
          {values.product_stock && values.quantity ? (
            <Box>{currentProductStockQuantity - Number(values.quantity)}</Box>
          ) : (
            <Box> Please select a Product Stock and Quantity</Box>
          )}
        </DataPoint>
        <DataPoint>
          <Label isGray>Total Case Adjusted Quantity</Label>
          {values.product_stock ? (
            <Box>{stockBaseQuantity * Number(values.quantity)}</Box>
          ) : (
            <Box> Please select a Product Stock</Box>
          )}
        </DataPoint>
        <ButtonGroup className={WMSButtonGroup({ type: "largeMargin", withinSecondaryLine: true })}>
          <SecondaryButton
            appearance="whiteButton"
            onClick={() => setOBMLineToFulfil(null)}
            type="button"
          >
            Cancel
          </SecondaryButton>
          <SecondaryButton
            appearance="blueButton"
            onClick={() => {
              onSubmit();
            }}
          >
            Fulfil
          </SecondaryButton>
        </ButtonGroup>
      </SecondaryLineWrapper>
    </>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    fulfilOBMLineState: state.fulfilOutboundManifestLineReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    fulfilOBMLine: (body: FulfilOutboundManifestLineBody) =>
      dispatch(fulfilOutboundManifestLine(body)),
    fulfilOutboundManifestLineReset: () => dispatch(fulfilOutboundManifestLineReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfilOutboundManifestLine);
