import { Document, Page, Text, View } from "@react-pdf/renderer";
import moment from "moment";

import { PDFStyleSheet } from "../../../../../styles/PdfStyles";
import { ManifestItem } from "../graphql/types";

// TODO: This page requires proper styling
export const PDF = ({ manifest }: { manifest: ManifestItem | undefined }) => (
  <Document>
    <Page size={"A4"} style={PDFStyleSheet.body} wrap={true}>
      <Text style={PDFStyleSheet.header} fixed>
        Outbound Manifest ID: {manifest?.id}
      </Text>
      <Text style={PDFStyleSheet.header} fixed>
        Date: {moment(new Date()).format("DD-MM-YYYY")}
      </Text>

      <View style={PDFStyleSheet.manifestDetailsWrapper}>
        <View style={PDFStyleSheet.InputRow}>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Manifest ID</Text>
            <Text style={PDFStyleSheet.box}>#{manifest?.id}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Organisation</Text>
            <Text style={PDFStyleSheet.box}>{manifest?.organisation.organisation_name}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Warehouse</Text>
            <Text style={PDFStyleSheet.box}>{manifest?.warehouse.warehouse_name}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Status </Text>
            <Text style={PDFStyleSheet.box}>
              {manifest?.outbound_manifest_status.outbound_manifest_status_name}
            </Text>
          </View>
        </View>
        <View style={PDFStyleSheet.InputRow}>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Logistics Type</Text>
            <Text style={PDFStyleSheet.box}>{manifest?.logistics_type.logistics_type_name}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}>Expected Collection Date</Text>
            <Text style={PDFStyleSheet.box}>
              {moment(manifest?.expected_dispatch).format("DD-MM-YYYY")}
            </Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Number of Manifest Lines </Text>
            <Text style={PDFStyleSheet.box}>{manifest?.outbound_manifest_lines.length}</Text>
          </View>
        </View>
      </View>
      <View>
        {manifest?.outbound_manifest_lines.map(obmLine => (
          <View style={PDFStyleSheet.ManifestLineWrapper} key={obmLine.id}>
            <Text style={PDFStyleSheet.ManifestLineTitle}> Manifest line ID: {obmLine.id} </Text>
            <View style={PDFStyleSheet.ManifestDataPointWrapper}>
              <View style={PDFStyleSheet.InputRow}>
                <View style={PDFStyleSheet.manifestLineDataPoint}>
                  <Text style={PDFStyleSheet.label}> Product Name </Text>
                  <Text style={PDFStyleSheet.manifestLineBox}>{obmLine.product.product_name}</Text>
                </View>
                <View style={PDFStyleSheet.manifestLineDataPoint}>
                  <Text style={PDFStyleSheet.label}> Quantity </Text>
                  <Text style={PDFStyleSheet.manifestLineBox}>{obmLine.quantity}</Text>
                </View>
                <View style={PDFStyleSheet.manifestLineDataPoint}>
                  <Text style={PDFStyleSheet.label}> Dispatched Quantity </Text>
                  <Text style={PDFStyleSheet.manifestLineBox}>{obmLine.dispatched_quantity}</Text>
                </View>
                <View style={PDFStyleSheet.manifestLineDataPoint}>
                  <Text style={PDFStyleSheet.label}> Line Status </Text>
                  <Text style={PDFStyleSheet.manifestLineBox}>
                    {obmLine.outbound_manifest_line_status.outbound_manifest_line_status_name}
                  </Text>
                </View>
                <View style={PDFStyleSheet.manifestLineDataPoint}>
                  <Text style={PDFStyleSheet.label}> Number of Fulfilment Lines </Text>
                  <Text style={PDFStyleSheet.manifestLineBox}>
                    {obmLine.outbound_manifest_fulfilment_lines.length}
                  </Text>
                </View>
              </View>
            </View>
            {obmLine.outbound_manifest_fulfilment_lines.map((ffline, index) => (
              <View style={PDFStyleSheet.FulfillmentLineWrapper}>
                <View style={PDFStyleSheet.indexHolder}>
                  <Text style={PDFStyleSheet.index}>{index + 1}</Text>
                </View>
                <Text style={PDFStyleSheet.ManifestLineTitle}>
                  Fulfillment Line ID: {ffline.id}{" "}
                </Text>
                <View style={PDFStyleSheet.ManifestDataPointWrapper}>
                  <View style={PDFStyleSheet.fulfilmentLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Fulfillment Line Product name </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>{ffline.product.product_name}</Text>
                  </View>
                  <View style={PDFStyleSheet.fulfilmentLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Fulfilment Line Quantity </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>{ffline.quantity}</Text>
                  </View>
                </View>
              </View>
            ))}
          </View>
        ))}
        <Text
          style={PDFStyleSheet.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed={true}
        />
      </View>
    </Page>
  </Document>
);
