import { useState } from "react";
import { Row } from "react-table";

import { InputField, PrimaryButton, styled } from "@sourceful/shared-components";

import { PendingDemandItem } from "../graphql/types";

const QuantityInputContainer = styled("div", {
  display: "flex",
  height: "61px",
  minWidth: "240px",
  alignItems: "center",
});

const InnerContainer = styled("div", { marginRight: "1em" });

export interface QuantityChangeProps {
  lineId: number;
  quantity: number;
  lineIndex: number;
}

export interface QuantityInputProps {
  row: Row<PendingDemandItem>;
  index: number;
  handleChangeQuantities: (demandItem: PendingDemandItem) => void;
  handleIsEditingChange: (lineId: number) => void;
  lineIdToEdit?: number;
}

export const QuantityInput = ({
  row,
  index,
  handleChangeQuantities,
  handleIsEditingChange,
  lineIdToEdit,
}: QuantityInputProps) => {
  const [localQuantity, setLocalQuantity] = useState(row.original.quantity_to_purchase ?? 0);
  const clientOrderLineId = row.original.client_order_line?.id ?? 0;

  return (
    <QuantityInputContainer key={`input_container_${clientOrderLineId}_${index}`}>
      {lineIdToEdit === clientOrderLineId ? (
        <>
          <InnerContainer>
            <InputField
              key={`quantity_${index}`}
              id={`quantity_${index}`}
              type={"number"}
              value={localQuantity.toString()}
              placeholder={"Input Quantity"}
              handleChange={e => setLocalQuantity(Number(e.target.value))}
            />
          </InnerContainer>
          <PrimaryButton
            onClick={() => {
              handleIsEditingChange(clientOrderLineId);
              handleChangeQuantities({ ...row.original, quantity_to_purchase: localQuantity });
            }}
          >
            Confirm
          </PrimaryButton>
        </>
      ) : (
        <>
          <InnerContainer>{localQuantity.toString()}</InnerContainer>
          <PrimaryButton onClick={() => handleIsEditingChange(clientOrderLineId)}>
            Edit
          </PrimaryButton>
        </>
      )}
    </QuantityInputContainer>
  );
};
