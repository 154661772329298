import { LOGISTICS_API } from "../../../../../api/baseConfig";
import { UpdateShipmentDeliveryDateBody } from "./types";

export interface UpdateShipmentDeliveryDateRequest {
  shipmentId: number;
  body: UpdateShipmentDeliveryDateBody;
}

export const updateShipmentDeliveryDateAPI = async ({
  shipmentId,
  body,
}: UpdateShipmentDeliveryDateRequest) => {
  try {
    return await LOGISTICS_API.patch(`/shipments/${shipmentId}/update-eta`, body);
  } catch (err) {
    console.log("Error in updating delivery date for shipment API call", err);
    throw err;
  }
};
