import { SUPPLIER_CURRENCY_MAP } from "../../../../../shared/constants";
import { extractProductName } from "../../../shared/mappers";
import {
  CountryNameItem,
  ProductItem,
  QuotationCostTypeItem,
  SupplierDetailsItem,
  SupplierItem,
} from "../types";

export const mapSuppliersToDropdownObject = (suppliers: SupplierItem[]) => {
  return suppliers.map(supplier => {
    return {
      label: supplier.organisation_name,
      value: supplier.id,
    };
  });
};

export const mapSupplierLocaleToCurrencyObject = (supplierDetails: SupplierDetailsItem[]) => {
  return supplierDetails.map(supplierDetail => {
    const locale = supplierDetail.supplier_details[0].locale;

    const currency = SUPPLIER_CURRENCY_MAP[locale];

    return {
      id: supplierDetail.id,
      locale: locale,
      currencyCode: currency,
    };
  });
};

export const mapProductNamesToDropdownObject = (products: ProductItem[]) => {
  return products.map(product => {
    return {
      label: extractProductName(product.product_name),
      value: product.id,
    };
  });
};

export const mapProductSKUsToDropdownObject = (products: ProductItem[]) => {
  return products.map(product => {
    return {
      label: product.line_reference,
      value: product.id,
    };
  });
};

export const mapQuotationCostTypesToDropdownItem = (
  quotationCostTypes: QuotationCostTypeItem[]
) => {
  return quotationCostTypes.map(quotationCostType => {
    return {
      label: quotationCostType.quotation_cost_type_name,
      value: quotationCostType.id,
    };
  });
};

export const mapCountryNamesToDropdownObject = (countryNames: CountryNameItem[]) => {
  return countryNames.map((countryName, index) => {
    return {
      label: countryName.country_name,
      value: index,
    };
  });
};
