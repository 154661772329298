import { useState } from "react";
import { toast } from "react-toastify";

export enum API_REQUEST_STATE {
  UNKNOWN = 0,
  PENDING,
  SUCCESS,
  ERROR,
}

export const useAPI = <T, R>(
  api: (args: T) => Promise<R>,
  hideAlerts?: boolean,
  messages?: { pending?: string; success?: string; error?: string }
): [API_REQUEST_STATE, (data: T | T[]) => Promise<R | R[]>] => {
  const [state, setState] = useState<API_REQUEST_STATE>(API_REQUEST_STATE.UNKNOWN);

  const call = async (data: T | T[]): Promise<R | R[]> => {
    setState(API_REQUEST_STATE.PENDING);
    if (!hideAlerts) toast(messages?.pending || "Pending action...", { hideProgressBar: true });
    let promises = [];
    if (Array.isArray(data)) {
      promises = data.map(api);
    } else {
      promises = [api(data)];
    }

    const results = await Promise.allSettled(promises);
    if (results.some(result => result.status === "rejected")) {
      setState(API_REQUEST_STATE.ERROR);
      if (!hideAlerts)
        toast.error(messages?.error || "Something went wrong with executing the action.");
    } else {
      setState(API_REQUEST_STATE.SUCCESS);
      if (!hideAlerts) toast.success(messages?.success || "Action completed successfully.");
    }

    return results
      .filter(result => result.status === "fulfilled")
      .map(result => (result as PromiseFulfilledResult<R>).value);
  };

  return [state, call];
};
