import axios, { AxiosInstance, AxiosRequestConfig } from "axios";

export class HttpClient {
  instance: AxiosInstance;
  tokenGenerator: any;

  constructor(baseUrl: string, overrides?: Partial<AxiosRequestConfig>) {
    this.instance = axios.create({
      baseURL: baseUrl,
    });

    this.instance.interceptors.request.use(
      async config => {
        const token = await this.getToken();

        return {
          ...config,
          headers: { ...config.headers, Authorization: `Bearer ${token}` },
          ...overrides,
        };
      },
      error => {
        Promise.reject(error);
      }
    );

    return this;
  }

  setTokenGenerator(tokenGenerator: any) {
    this.tokenGenerator = tokenGenerator;
    return this;
  }

  getToken() {
    return this.tokenGenerator();
  }
}

export class UnauthenticatedHTTPClient {
  instance: AxiosInstance;
  constructor(baseUrl: string) {
    this.instance = axios.create({
      baseURL: baseUrl,
    });
    return this;
  }
}

const {
  REACT_APP_WMS_API_API_BASE_URL,
  REACT_APP_SOURCING_API_BASE_URL,
  REACT_APP_LOGISTICS_API_BASE_URL,
  REACT_APP_FILE_UPLOADER_API_BASE_URL,
  REACT_APP_PRODUCT_CATALOGUE_API_BASE_URL,
  REACT_APP_ORDER_SERVICE_API_BASE_URL,
  REACT_APP_PRODUCT_PRICING_API_BASE_URL,
  REACT_APP_IAM_API_BASE_URL,
  REACT_APP_STOCK_MANAGEMENT_API_BASE_URL,
} = process.env;

const wmsBaseUrl = `${REACT_APP_WMS_API_API_BASE_URL || window.REACT_APP_API_ORIGIN}/sourceful-wms`;

const sourcingBaseUrl = `${
  REACT_APP_SOURCING_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/sourcing/api/v1`;

const logisticsBaseUrl = `${
  REACT_APP_LOGISTICS_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/logistics/api/v1`;

const gcsBaseUrl = `${
  REACT_APP_FILE_UPLOADER_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/gcs-file-uploader`;

const productCatalogueBaseUrl = `${
  REACT_APP_PRODUCT_CATALOGUE_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/product-catalogue`;

const orderServiceBaseUrl = `${
  REACT_APP_ORDER_SERVICE_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/order-service`;

const iamBaseUrl = `${
  REACT_APP_IAM_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/identity-access-management`;

const productPricingBaseUrl = `${
  REACT_APP_PRODUCT_PRICING_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/pricing`;

const stockManagementBaseUrl = `${
  REACT_APP_STOCK_MANAGEMENT_API_BASE_URL || window.REACT_APP_API_ORIGIN
}/stock-management-api/api/v1`;

export const WMS_API_CLIENT = new HttpClient(wmsBaseUrl);
export const SOURCING_API_CLIENT = new HttpClient(sourcingBaseUrl);
export const LOGISTICS_API_CLIENT = new HttpClient(logisticsBaseUrl);
export const GCS_API_CLIENT = new HttpClient(gcsBaseUrl, { responseType: "arraybuffer" });
export const PRODUCT_CATALOGUE_API_CLIENT = new HttpClient(productCatalogueBaseUrl);
export const ORDER_SERVICE_CLIENT = new HttpClient(orderServiceBaseUrl);
export const PRODUCT_PRICING_API_CLIENT = new HttpClient(productPricingBaseUrl);
export const UNAUTHENTICATED_SOURCING_API_CLIENT = new UnauthenticatedHTTPClient(sourcingBaseUrl);
export const IAM_API_CLIENT = new HttpClient(iamBaseUrl);
export const STOCK_MANAGEMENT_API_CLIENT = new HttpClient(stockManagementBaseUrl);

export const WMS_API = WMS_API_CLIENT.instance;
export const SOURCING_API = SOURCING_API_CLIENT.instance;
export const LOGISTICS_API = LOGISTICS_API_CLIENT.instance;
export const UNAUTHENTICATED_SOURCING_API = UNAUTHENTICATED_SOURCING_API_CLIENT.instance;
export const GCS_API = GCS_API_CLIENT.instance;
export const PRODUCT_CATALOGUE_API = PRODUCT_CATALOGUE_API_CLIENT.instance;
export const ORDER_SERVICE_API = ORDER_SERVICE_CLIENT.instance;
export const IAM_API = IAM_API_CLIENT.instance;
export const PRODUCT_PRICING_API = PRODUCT_PRICING_API_CLIENT.instance;
export const STOCK_MANAGEMENT_API = STOCK_MANAGEMENT_API_CLIENT.instance;
