import { WorkOrderAndRecipeInputs, emptyWorkOrderInput } from "../formValues";
import { OrganisationNameItem, RecipeNameItem, WarehouseNameItem } from "../graphql/types";

export const mapWarehouseNamesToDrodownObject = (warehouseNames: WarehouseNameItem[]) => {
  return warehouseNames.map(warehouseName => {
    return {
      label: warehouseName.warehouse_name,
      value: warehouseName.id,
    };
  });
};

export const mapRecipeNamesToDrodownObject = (recipeNames: RecipeNameItem[]) => {
  return recipeNames.map(recipeName => {
    return {
      label: recipeName.recipe_name,
      value: recipeName.id,
    };
  });
};

export const mapOrganisationNamesToDrodownObject = (organisationNames: OrganisationNameItem[]) => {
  return organisationNames.map(organisationName => {
    return {
      label: organisationName.organisation_name,
      value: organisationName.id,
    };
  });
};

export const mapInputLinesToDTO = (inputLines: WorkOrderAndRecipeInputs[]) => {
  return inputLines
    .map(allInputs => {
      return allInputs.work_order_inputs.map(inputLine => {
        return {
          source_product_stock_id: inputLine.product_stock?.value!,
          quantity: Number(inputLine.quantity),
        };
      });
    })
    .flat();
};

export const mapRecipeInputsToRecipeLine = (inputLines: any[], multiplier: number) => {
  return inputLines.map(line => {
    return {
      recipe_input: {
        product: {
          id: line.product.id,
          name: line.product.product_name,
          base_product_id: line.product.base_product_id!,
        },
        quantity: String(line.quantity * multiplier),
      },
      work_order_inputs: [emptyWorkOrderInput],
    };
  });
};
