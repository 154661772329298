import { IconName } from "./IconSprite";

export interface IconProps {
  className?: string;
  width?: number;
  height?: number;
  name?: IconName;
}

export const Icon = ({
  className = "icon",
  width = 24,
  height = 24,
  name = "document-note",
}: IconProps) => {
  return (
    <svg
      className={className}
      width={width}
      height={height}
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <use href={`#${name}`}></use>
    </svg>
  );
};

export default Icon;
