import { SidebarItem } from "../SideBar";

export const ordersSidebarItems: SidebarItem[] = [
  {
    heading: "Order Builder",
    headingUrl: "orders/dashboard",
    icon: "tools-brush",
    links: [
      {
        name: "Create Order",
        URL: "orders/dashboard",
      },
    ],
  },
];
