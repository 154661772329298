import { useAuth0 } from "@auth0/auth0-react";
import { useEffect, useState } from "react";

import { API_REQUEST_STATE, useAPI } from "../../../../util/api/apiHook";
import { getOrganisations as getOrganisationsApi } from "../../skus/updateSku/api/getOrganisations";

const LIMIT = 50;

export interface HookResult {
  organisations?: any[];
  organisationsError?: any;
  organisationsLoading: boolean;
}

export const useOrganisations = (): HookResult => {
  const { getAccessTokenSilently } = useAuth0();
  const [getOrganisationsState, fetchOrganisations] = useAPI(getOrganisationsApi, true);
  const [organisations, setOrganisations] = useState<any[]>([]);
  useEffect(() => {
    const getOrganisations = async () => {
      const token = await getAccessTokenSilently();

      let currentOrganisations: any[] = [];

      let offset = 0;
      while (true) {
        const response = await fetchOrganisations({ offset, limit: LIMIT, token });
        const newOrganisations = Array.isArray(response) ? response[0].data : response.data;

        if (newOrganisations.length === 0) {
          break;
        }

        currentOrganisations.push(...newOrganisations);
        offset += newOrganisations.length;
      }
      setOrganisations(currentOrganisations);
    };
    const fetchData = async () => {
      try {
        return await getOrganisations();
      } catch (error) {
        console.error("Error getting organisations in CreateOrganisation:", error);
      }
    };

    fetchData();

    // eslint-disable-next-line
  }, []);

  return {
    organisations,
    organisationsError:
      getOrganisationsState === API_REQUEST_STATE.ERROR ? getOrganisationsState : undefined,
    organisationsLoading: getOrganisationsState === API_REQUEST_STATE.PENDING,
  };
};
