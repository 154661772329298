import { styled } from "@sourceful/shared-components";

interface Props {
  colour: "red" | "green" | "amber" | "grey" | "blue";
  title: string;
  value: string | number;
}

const colours = {
  sourcefulAmber: "#FFE9A8",
  sourcefulGreen: "#C9EFC5",
  sourcefulRed: "#F787A9",
  sourcefulBlue: "#C0D7FB",
  sourcefulLightGrey: "#DFDDDD",
};

const SquareBox = styled("div", {
  marginBlock: "10px",
  height: "150px",
  flexDirection: "column",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  textAlign: "center",
  padding: "11px",
  borderRadius: "15px",
  minWidth: "150px",
  width: "calc(20% - 25px)",
  maxWidth: "500px",

  variants: {
    color: {
      red: { background: colours.sourcefulRed },
      amber: { background: colours.sourcefulAmber },
      green: { background: colours.sourcefulGreen },
      grey: { background: colours.sourcefulLightGrey },
      blue: { background: colours.sourcefulBlue },
    },
  },
});

const ValueParagraph = styled("p", {
  margin: "0px",
  marginBlock: "10px",
  fontSize: "1.75em",
  fontWeight: "bold",
});

const DisplaySquare = ({ colour, title, value }: Props) => (
  <SquareBox color={colour}>
    <ValueParagraph>{value}</ValueParagraph>
    <p style={{ margin: "0px" }}>{title}</p>
  </SquareBox>
);

export default DisplaySquare;
