import { ApolloError } from "@apollo/client";

import { useGetStockCheckWarehouseNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapWarehouseNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  warehouseNames: DropdownItem[];
  warehouseNamesError?: ApolloError;
  warehouseNamesLoading: boolean;
}

export const useWarehouseNames = (): HookResult => {
  const { data, error, loading } = useGetStockCheckWarehouseNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    warehouseNames:
      data && Array.isArray(data.warehouse)
        ? mapWarehouseNamesToDropdownObject(data.warehouse)
        : [],
    warehouseNamesError: error,
    warehouseNamesLoading: loading,
  };
};
