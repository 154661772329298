import { SOURCING_API } from "../../../../../api/baseConfig";
import { UpdateQAInspectionBody } from "./types/index";

export interface UpdateQAInspectionRequest {
  qaInspectionId: number;
  body: UpdateQAInspectionBody;
}

export const updateQAInspectionAPI = async ({
  qaInspectionId,
  body,
}: UpdateQAInspectionRequest) => {
  try {
    return await SOURCING_API.patch(`/quality-assurance-inspections/${qaInspectionId}`, body);
  } catch (err) {
    console.log("Error in update QA Inspection API call", err);
    throw err;
  }
};
