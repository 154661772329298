import { WMS_API } from "../../../../../api/baseConfig";
import { RecipeRequestBody } from "./types";

export const recipeRequestAPI = async (body: RecipeRequestBody) => {
  try {
    return await WMS_API.post("/recipes", body);
  } catch (err) {
    console.log("Error in recipe request API call", err);
    throw err;
  }
};
