import * as Yup from "yup";

export const validationSchema = Yup.object({
  courier_id: Yup.object()
    .shape({
      value: Yup.number().required("Courier is required"),
    })
    .typeError("Organisation is required"),
  courier_service_name: Yup.string().required("Courier Service Name is required"),
});
