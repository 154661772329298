import { ApolloError } from "@apollo/client";

import { useGetBaseProductAndStockQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapQueryResultToProductAndStockItem } from "../../mappers";
import { ProductAndStockItem } from "../types";

export interface HookResult {
  product?: ProductAndStockItem;
  productError?: ApolloError;
  productLoading: boolean;
  refetchProduct: () => Promise<void>;
}

export const useBaseProductAndStock = (baseProductId: number, productId: number): HookResult => {
  const { data, error, loading, refetch } = useGetBaseProductAndStockQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      baseProductId,
    },
  });

  return {
    product:
      data && Array.isArray(data.product)
        ? mapQueryResultToProductAndStockItem(data.product, productId)
        : undefined,
    productError: error,
    productLoading: loading,
    refetchProduct: async () => {
      refetch();
    },
  };
};
