import { FormikErrors, FormikTouched, useFormikContext } from "formik";
import { useEffect } from "react";

import {
  DataPoint,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../styles/SharedStyles";
import { fieldObjects } from "../formValues";
import { CreateOrderForm, FinalTotal } from "../types";
import CurrencySimpleInput from "./InputWithCurrency";

export interface UpdateFinalProps {
  values: FinalTotal;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<FinalTotal> | undefined;
  touched: FormikTouched<FinalTotal> | undefined;
  currency: string;
}

const UpdateFinal = ({ values, setFieldValue, errors, touched, currency }: UpdateFinalProps) => {
  const {
    values: {
      product_costs,
      product_one_off_costs,
      carbon_offset_costs,
      discount_costs,
      shipping_costs,
    },
  } = useFormikContext<CreateOrderForm>();

  useEffect(() => {
    let subtotal = 0;
    let total = 0;
    let discount_with_vat = 0;

    product_costs.forEach((product_cost, index) => {
      subtotal += product_cost.total;
      total += product_cost.total_with_vat;
    });

    product_one_off_costs.forEach((product_one_off_cost, index) => {
      subtotal += product_one_off_cost.total;
      total += product_one_off_cost.total_with_vat;
    });

    shipping_costs.forEach((shipping_cost, index) => {
      subtotal += shipping_cost.total;
      total += shipping_cost.total_with_vat;
    });

    discount_costs.forEach((discount_cost, index) => {
      discount_with_vat += discount_cost.total_with_vat;
      total += discount_cost.total_with_vat;
    });

    carbon_offset_costs.forEach((carbon_offset_cost, index) => {
      subtotal += carbon_offset_cost.total;
      total += carbon_offset_cost.total_with_vat;
    });

    const vat = total - (discount_with_vat + subtotal);
    setFieldValue(fieldObjects.final_total.subtotal.fieldName, subtotal);
    setFieldValue(fieldObjects.final_total.discount.fieldName, discount_with_vat);
    setFieldValue(fieldObjects.final_total.vat.fieldName, vat);
    setFieldValue(fieldObjects.final_total.total.fieldName, total);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product_costs, product_one_off_costs, carbon_offset_costs, discount_costs, shipping_costs]);

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <SecondaryLineDataWrapper>
        <DataPoint fullWidth>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.final_total.subtotal.fieldName}
            name={fieldObjects.final_total.subtotal.fieldName}
            type={"text"}
            placeholder={fieldObjects.final_total.subtotal.fieldPlaceholder}
            value={values.subtotal}
            error={errors ? errors.subtotal : undefined}
            touched={touched?.subtotal}
            label={`${fieldObjects.final_total.subtotal.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.final_total.subtotal.fieldName, e.target.value);
            }}
            currency={currency}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.final_total.discount.fieldName}
            name={fieldObjects.final_total.discount.fieldName}
            type={"text"}
            placeholder={fieldObjects.final_total.discount.fieldPlaceholder}
            value={values.discount}
            error={errors ? errors.discount : undefined}
            touched={touched?.discount}
            label={`${fieldObjects.final_total.discount.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.final_total.discount.fieldName, e.target.value);
            }}
            currency={currency}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.final_total.vat.fieldName}
            name={fieldObjects.final_total.vat.fieldName}
            type={"text"}
            placeholder={fieldObjects.final_total.vat.fieldPlaceholder}
            value={values.vat}
            error={errors ? errors.vat : undefined}
            touched={touched?.vat}
            label={`${fieldObjects.final_total.vat.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.final_total.vat.fieldName, e.target.value);
            }}
            currency={currency}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.final_total.total.fieldName}
            name={fieldObjects.final_total.total.fieldName}
            type={"text"}
            placeholder={fieldObjects.final_total.total.fieldPlaceholder}
            value={values.total}
            error={errors ? errors.total : undefined}
            touched={touched?.total}
            label={`${fieldObjects.final_total.total.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.final_total.total.fieldName, e.target.value);
            }}
            currency={currency}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>
    </SecondaryLineWrapper>
  );
};

export default UpdateFinal;
