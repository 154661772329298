import { ApolloError } from "@apollo/client";

import { useGetRecipeByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { RecipeItem } from "../types";

export interface HookResult {
  recipe?: RecipeItem;
  recipeError?: ApolloError;
  recipeLoading: boolean;
}

export const useRecipeById = (recipeId: number): HookResult => {
  const { data, error, loading } = useGetRecipeByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      recipeId,
    },
  });

  return {
    recipe: data && Array.isArray(data.recipe) ? data.recipe[0] : undefined,
    recipeError: error,
    recipeLoading: loading,
  };
};
