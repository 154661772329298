import { ApolloError } from "@apollo/client";

import { useGetXeroContactIdByOrganisationIdLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";

export interface HookResult {
  xeroContactId?: string;
  xeroContactIdLoading: boolean;
  xeroContactIdError?: ApolloError;
  getXeroContactIdByOrganisationId: (options: Options) => Promise<void>;
}

export interface Options {
  organisationId: number;
}

export const useXeroContactIdByOrganisationId = (): HookResult => {
  const [getXeroContactIdByOrganisationId, { data, loading, error }] =
    useGetXeroContactIdByOrganisationIdLazyQuery({
      context: {
        clientName: GRAPHQL_SOURCING,
      },
    });

  return {
    xeroContactId: data?.supplier_details
      ? String(data?.supplier_details[0].xero_contact_id)
      : undefined,
    xeroContactIdLoading: loading,
    xeroContactIdError: error,
    getXeroContactIdByOrganisationId: async ({ organisationId }: Options) => {
      getXeroContactIdByOrganisationId({ variables: { organisationId } });
    },
  };
};
