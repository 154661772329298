import { ErrorType } from "../../../../../../../shared/api/errors/errorTypes";
import {
  CREATE_INTERNAL_QUOTES_FAILED,
  CREATE_INTERNAL_QUOTES_LOADING,
  CREATE_INTERNAL_QUOTES_RESET,
  CREATE_INTERNAL_QUOTES_SUCCESS,
} from "../types";

export interface CreateInternalQuoteReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: CreateInternalQuoteReducer = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function createInternalQuoteReducer(
  state: CreateInternalQuoteReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_INTERNAL_QUOTES_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_INTERNAL_QUOTES_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_INTERNAL_QUOTES_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case CREATE_INTERNAL_QUOTES_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
