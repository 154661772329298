import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

export const getAttributeTemplates = async () => {
  try {
    const response = await PRODUCT_CATALOGUE_API.get("/attribute/templates");
    return response.data.data;
  } catch (err) {
    console.log("Error in getting attribute templates API call", err);
    throw err;
  }
};
