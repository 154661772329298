import { FormikErrors, FormikTouched } from "formik";

export interface QuoteLineProps {
  values: QuoteLine;
  index: number;
  errors: FormikErrors<QuoteLine[]> | undefined;
  touched: FormikTouched<QuoteLine>[] | undefined;
  handleRemoveQuoteLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  allQuoteLines: QuoteLine[];
}

export interface QuoteLine {
  id: number | null;
  rfq_line_id: number | null;
  minimum_order_quantity: string;
  lead_time_max_days: string;
}

export const emptyQuoteLine: QuoteLine = {
  id: null,
  minimum_order_quantity: "",
  rfq_line_id: null,
  lead_time_max_days: "",
};
