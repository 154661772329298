import { ApolloError } from "@apollo/client";

import { useGetCustomReportStockConditionNamesQuery } from "../../../../../../generated/graphql_wms";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  stockConditionNames: DropdownItem[];
  stockConditionNamesError?: ApolloError;
  stockConditionNamesLoading: boolean;
}

export const useStockConditionNames = (): HookResult => {
  const { data, error, loading } = useGetCustomReportStockConditionNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockConditionNames:
      data && Array.isArray(data.stock_condition)
        ? data.stock_condition.map(stockCondition => {
            return {
              value: stockCondition.id,
              label: stockCondition.stock_condition_name,
            };
          })
        : [],
    stockConditionNamesError: error,
    stockConditionNamesLoading: loading,
  };
};
