import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import Page from "../../../../../shared/components/templates/Page/Page";
import { withinTablePadding } from "../../../../../styles/SharedStyles";
import { useQuotationCostTypes } from "../graphql/hooks/useQuotationCostTypes";
import { QuotationCostTypeItem } from "../graphql/types";

export default function ViewAdminPanel() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { quotationCostTypes, quotationCostTypesLoading, quotationCostTypesError } =
    useQuotationCostTypes();

  const items = ADMIN_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/sourcing/admin/create-quotation-cost-type");
          },
          type: "button",
          title: "Create Quotation Cost Type",
          iconName: "alert-add-outline",
        },
        {
          id: 2,
          handleClick: () => {
            history.push("/sourcing/admin/create-supplier-details");
          },
          type: "button",
          title: "Create Supplier Details",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];

  const columns = useMemo(
    () => [
      {
        Header: "Quotation Cost Type Name",
        widthPercent: 0.1,
        accessor: (quotationCost: QuotationCostTypeItem) => quotationCost.quotation_cost_type_name,
      },
      {
        Header: "Distribution",
        widthPercent: 0.1,
        accessor: (quotationCost: QuotationCostTypeItem) =>
          quotationCost.quotation_cost_distribution,
      },
      {
        Header: "Account Type",
        widthPercent: 0.1,
        accessor: (quotationCost: QuotationCostTypeItem) =>
          quotationCost.quotation_cost_account_type,
      },
      {
        Header: "Update",
        widthPercent: 0.05,
        Cell: ({ row }: CellProps<QuotationCostTypeItem>) => {
          return (
            <Link to={`/sourcing/admin/update-quotation-cost-type/${row.original.id}`}>
              <SecondaryButton
                className={withinTablePadding()}
                appearance={"blueButton"}
                isWithinTable
              >
                Update
              </SecondaryButton>
            </Link>
          );
        },
      },
    ],
    []
  );

  return (
    <Page
      title={"Quotation Costs"}
      isLoading={quotationCostTypesLoading}
      error={quotationCostTypesError}
      hasBackButton
    >
      <Panel
        withWrapper
        title={"Admin Panel"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        rightItems={rightItems}
      >
        <Table data={quotationCostTypes} columns={columns} />
      </Panel>
    </Page>
  );
}
