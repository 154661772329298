import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { IconWrapper, LinkText } from "../../../../../styles/SharedStyles";
import { useBaseProducts } from "../graphql/hooks/useBaseProducts";
import { BaseProductItem } from "../graphql/types";

export default function ViewBaseProducts() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { baseProducts, baseProductsLoading, baseProductsError } = useBaseProducts();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/inventory/base-products/add-base-product");
          },
          type: "button",
          title: "Add Base Product",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        widthPercent: 0.4,
        accessor: (listItem: BaseProductItem) => listItem.product_name,
        Cell: ({ _, row }: CellProps<BaseProductItem>) => (
          <Link
            to={`/inventory/products/view-product/${row.original.id}/${row.original.base_product_id}`}
          >
            <LinkText>{row.original.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "FSC Certified",
        widthPercent: 0.4,
        accessor: (listItem: BaseProductItem) => (listItem.fsc_certified ? "True" : "False"),
        Cell: ({ _, row }: CellProps<BaseProductItem>) => {
          if (row.original.fsc_certified) {
            return (
              <IconWrapper withinList sourcefulBlue>
                <Icon name="alert-tick-outline" />
              </IconWrapper>
            );
          }
          return (
            <IconWrapper withinList isGray>
              <Icon name="alert-cross-outline" />
            </IconWrapper>
          );
        },
      },
      {
        Header: "GRS Certified",
        widthPercent: 0.4,
        accessor: (listItem: BaseProductItem) => (listItem.grs_certified ? "True" : "False"),
        Cell: ({ _, row }: CellProps<BaseProductItem>) => {
          if (row.original.grs_certified) {
            return (
              <IconWrapper withinList sourcefulBlue>
                <Icon name="alert-tick-outline" />
              </IconWrapper>
            );
          }
          return (
            <IconWrapper withinList isGray>
              <Icon name="alert-cross-outline" />
            </IconWrapper>
          );
        },
      },
      {
        Header: "View All Case Sizes",
        widthPercent: 0.2,
        accessor: (listItem: BaseProductItem) => listItem.id,
        Cell: ({ _, row }: CellProps<BaseProductItem>) => (
          <Link to={`/inventory/base-products/view-case-sizes/${row.original.id}`}>
            <SecondaryButton appearance={"blueButton"} isWithinTable>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page error={baseProductsError} isLoading={baseProductsLoading} title={"Base Products"}>
      <Panel
        withWrapper
        title={"Base Products"}
        text={"Use the table below to view Sourceful's Base Products"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={baseProducts} columns={columns} />
      </Panel>
    </Page>
  );
}
