import moment from "moment";

import { RequestForQuotationItem } from "../graphql/types";

export const mapRFQToTableRow = (requestsForQuotations: RequestForQuotationItem[]) => {
  return requestsForQuotations.map(rfq => {
    return {
      rfqId: rfq.id,
      supplierId: rfq.organisation.id,
      supplierName: rfq.organisation.organisation_name,
      submittedOn: moment(new Date(rfq.created_at)).format("MM/DD/YYYY"),
      status: rfq.supplier_quote_request_status.supplier_quote_request_status_name,
    };
  });
};
