import { ErrorType } from "../../../../../../../shared/api/errors/errorTypes";
import {
  REJECT_REQUEST_FOR_QUOTE_FAILED,
  REJECT_REQUEST_FOR_QUOTE_LOADING,
  REJECT_REQUEST_FOR_QUOTE_RESET,
  REJECT_REQUEST_FOR_QUOTE_SUCCESS,
} from "../types";

export interface RejectSupplierQuoteRequestReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: RejectSupplierQuoteRequestReducer = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function rejectSupplierQuoteRequestReducer(
  state: RejectSupplierQuoteRequestReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case REJECT_REQUEST_FOR_QUOTE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REJECT_REQUEST_FOR_QUOTE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case REJECT_REQUEST_FOR_QUOTE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case REJECT_REQUEST_FOR_QUOTE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
