import { FormikErrors, FormikTouched } from "formik";

import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../styles/SharedStyles";
import { isFeatureFlagEnabled } from "../../../../util/featureFlags";
import { countryCodeOptions, fieldObjects } from "../formValues";
import { BillingAddress } from "../types";
import { filterCNCountryCodeOptions } from "../util/FilterCNOptions";

export interface BillingDetailsProps {
  values: BillingAddress;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  errors: FormikErrors<BillingAddress> | undefined;
  touched: FormikTouched<BillingAddress> | undefined;
}

const BillingDetails = ({ values, errors, touched, setFieldValue }: BillingDetailsProps) => {
  const filteredCountries = filterCNCountryCodeOptions(countryCodeOptions);

  return (
    <PrimaryLineWrapper>
      <Title>Contact Information - Billing Details</Title>
      <PrimaryLineDataWrapper>
        <DataPoint halfWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.first_name.fieldName}
            name={fieldObjects.billing_address.first_name.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.first_name.fieldPlaceholder}
            value={values.first_name}
            error={errors?.first_name}
            touched={touched?.first_name}
            label={fieldObjects.billing_address.first_name.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.first_name.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.second_name.fieldName}
            name={fieldObjects.billing_address.second_name.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.second_name.fieldPlaceholder}
            value={values.second_name}
            error={errors?.second_name}
            touched={touched?.second_name}
            label={fieldObjects.billing_address.second_name.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.second_name.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.company_name.fieldName}
            name={fieldObjects.billing_address.company_name.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.company_name.fieldPlaceholder}
            value={values.company_name}
            error={errors?.company_name}
            touched={touched?.company_name}
            label={fieldObjects.billing_address.company_name.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.company_name.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.address_line_1.fieldName}
            name={fieldObjects.billing_address.address_line_1.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.address_line_1.fieldPlaceholder}
            value={values.address_line_1}
            error={errors?.address_line_1}
            touched={touched?.address_line_1}
            label={fieldObjects.billing_address.address_line_1.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.address_line_1.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            htmlFor={fieldObjects.billing_address.address_line_2.fieldName}
            name={fieldObjects.billing_address.address_line_2.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.address_line_2.fieldPlaceholder}
            value={values.address_line_2}
            error={errors?.address_line_2}
            touched={touched?.address_line_2}
            label={fieldObjects.billing_address.address_line_2.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.address_line_2.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint fullWidth>
          <SimpleInput
            htmlFor={fieldObjects.billing_address.address_line_3.fieldName}
            name={fieldObjects.billing_address.address_line_3.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.address_line_3.fieldPlaceholder}
            value={values.address_line_3}
            error={errors?.address_line_3}
            touched={touched?.address_line_3}
            label={fieldObjects.billing_address.address_line_3.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.address_line_3.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.city.fieldName}
            name={fieldObjects.billing_address.city.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.city.fieldPlaceholder}
            value={values.city}
            error={errors?.city}
            touched={touched?.city}
            label={fieldObjects.billing_address.city.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.city.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.region_or_county_or_state.fieldName}
            name={fieldObjects.billing_address.region_or_county_or_state.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.region_or_county_or_state.fieldPlaceholder}
            tooltipMessage="For US, use the 2 Digit State Code e.g. CA for California"
            value={values.region_or_county_or_state}
            error={errors?.region_or_county_or_state}
            touched={touched?.region_or_county_or_state}
            label={fieldObjects.billing_address.region_or_county_or_state.fieldLabel}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.billing_address.region_or_county_or_state.fieldName,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            htmlFor={fieldObjects.billing_address.postcode.fieldName}
            name={fieldObjects.billing_address.postcode.fieldName}
            type="text"
            placeholder={fieldObjects.billing_address.postcode.fieldPlaceholder}
            value={values.postcode}
            error={errors?.postcode}
            touched={touched?.postcode}
            label={fieldObjects.billing_address.postcode.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.billing_address.postcode.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.billing_address.country_name.fieldName}
            name={fieldObjects.billing_address.country_name.fieldName}
            placeholder={fieldObjects.billing_address.country_name.fieldPlaceholder}
            options={
              isFeatureFlagEnabled("REACT_APP_FEATURE_FLAG_CN_ORDERS")
                ? countryCodeOptions
                : filteredCountries
            }
            value={values.country_name}
            error={errors?.country_name?.label}
            touched={touched?.country_name?.label}
            label={fieldObjects.billing_address.country_name.fieldLabel}
            changeHandler={e =>
              setFieldValue(fieldObjects.billing_address.country_name.fieldName, e)
            }
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
};

export default BillingDetails;
