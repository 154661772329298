import React, { useState } from "react";
import { toast } from "react-toastify";

import { Heading, PrimaryButton, styled } from "@sourceful/shared-components";

import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import { InputContainer } from "../../../../styles/SharedStyles";
import { createAttributeType } from "../api/createAttributeType";

const AttributeTypeContainer = styled("div", {
  padding: "0 10px 20px 10px",
  minWidth: "400px",
});

interface CreateAttributeTypeModalProps {
  handleCreateAttributeTypeSuccess: () => void;
}

const CreateAttributeTypeModal = ({
  handleCreateAttributeTypeSuccess,
}: CreateAttributeTypeModalProps) => {
  const [attributeType, setAttributeType] = useState("");

  const handleSubmit = async () => {
    try {
      await createAttributeType({ attribute_type: attributeType });
      handleCreateAttributeTypeSuccess();
      toast.success("Success! Attribute Type created.");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while creating attribute type.");
    }
  };

  return (
    <AttributeTypeContainer>
      <InputContainer>
        <Heading fontSize={5}>New Attribute Type</Heading>
      </InputContainer>

      <InputContainer>
        <SimpleInput
          name="AttributeType"
          value={attributeType}
          changeHandler={e => setAttributeType(e.target.value)}
          type="text"
          placeholder="Enter attribute type"
          error={undefined}
          touched={undefined}
        />
      </InputContainer>
      <InputContainer>
        <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
      </InputContainer>
    </AttributeTypeContainer>
  );
};

export default CreateAttributeTypeModal;
