import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createOutboundManifestAPI } from "../../api/createOutboundManifestAPI";
import { CreateOutboundManifestBody } from "../../api/types";
import {
  CREATE_OUTBOUND_MANIFEST_FAILURE,
  CREATE_OUTBOUND_MANIFEST_RESET,
  CREATE_OUTBOUND_MANIFEST_SUCCESS,
  CREATING_OUTBOUND_MANIFEST,
} from "../types/";

export const creatingOutboundManifest = () => {
  return {
    type: CREATING_OUTBOUND_MANIFEST,
  };
};

export const createOutboundManifestSuccess = () => {
  return {
    type: CREATE_OUTBOUND_MANIFEST_SUCCESS,
  };
};

export const createOutboundManifestReset = () => {
  return {
    type: CREATE_OUTBOUND_MANIFEST_RESET,
  };
};

export const createOutboundManifestFailure = () => {
  return {
    type: CREATE_OUTBOUND_MANIFEST_FAILURE,
  };
};

export const createOutboundManifestAction = (body: CreateOutboundManifestBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Outbound Manifest", { hideProgressBar: true });
    console.log("Creating Outbound Manifest - In Action");
    dispatch(creatingOutboundManifest());
    try {
      await createOutboundManifestAPI(body);
      toast.success("Outbound Manifest created successfully.");
      dispatch(createOutboundManifestSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating outbound manifest.");
      dispatch(createOutboundManifestFailure());
    }
  };
};
