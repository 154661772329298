import { CHANGE_OBM_LINE_STATUS, CHANGE_OBM_STATUS } from "../redux/types";

export const mapManifestStatusToMessage = (status: string) => {
  switch (status) {
    case CHANGE_OBM_STATUS.CANCEL:
      return "canceled";
    case CHANGE_OBM_STATUS.REJECT:
      return "rejected";
    case CHANGE_OBM_STATUS.MARK_AS_DONE:
      return "done";
  }
};

export const mapManifestLineStatusToMessage = (status: string) => {
  switch (status) {
    case CHANGE_OBM_LINE_STATUS.CANCEL:
      return "canceled";
    case CHANGE_OBM_LINE_STATUS.REJECT:
      return "rejected";
    case CHANGE_OBM_LINE_STATUS.MARK_AS_DONE:
      return "done";
  }
};
