import { styled } from "@sourceful/shared-components";

import { DataPoint } from "../../../../styles/SharedStyles";

export const FlexEndContainer = styled(DataPoint, {
  display: "flex",
  alignItems: "flex-end",
  width: "20%",
  justifyContent: "flex-end",
  minWidth: "auto",
  marginRight: "0 !important",
});
