import { Formik, FormikErrors } from "formik";
import _ from "lodash";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import AttachmentUploader, {
  PrefilledAttachment,
} from "../../../../../components/AttachmentUploader/AttachmentUploader";
import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import TextArea from "../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { ReactSelectOption } from "../../../../../util/interfaces";
import { emptyAttachment } from "../../../shared/constants";
import { prettifyProductName } from "../../../shared/mappers";
import GetQuotationCalculationBody from "../../shared/api/types/getQuotationCalculation";
import {
  getQuotationCalculationAction,
  getQuotationCalculationReset,
} from "../../shared/redux/actions/getQuotationCalculation";
import { GetQuotationCalculationReducer } from "../../shared/redux/reducers/getQuotationCalculationReducer";
import { getPurchaseOrderByIdAPI } from "../api/getPurchaseOrderById";
import { CreatePurchaseOrderBody } from "../api/types";
import ProductLine from "../components/ProductLine";
import ProductLineSimple from "../components/ProductLineSimple";
import * as copy from "../copy";
import {
  CLIENT_ADDRESS_DELIVERY_TYPE_ID,
  SOURCEFUL_WAREHOUSE_TYPE_ID,
  deliveryTypeOptions,
  emptyPurchseOrderLine,
  fieldObjects,
  initialFieldValues,
} from "../formValues";
import { useAddressesByOrganisationId } from "../graphql/hooks/useAddressesByOrganisationId";
import { useContactsByOrganisationName } from "../graphql/hooks/useContactsByOrganisationName";
import { useOrderPendingDemand } from "../graphql/hooks/usePendingDemand";
import { useSuppliers } from "../graphql/hooks/useSuppliers";
import { useWarehouseAddresses } from "../graphql/hooks/useWarehouseAddresses";
import { useXeroContactIdByOrganisationId } from "../graphql/hooks/useXeroContactIdByOrganisationId";
import { PendingDemandItem, SupplierItem } from "../graphql/types";
import {
  getDefaultEstimatedDate,
  getMaxLeadTimeDate,
  getUniquePurchaseOrderLines,
  mapAttachmentsToDTO,
  mapDemandToOrders,
  mapDemandToProducts,
  mapSupplierOptions,
} from "../mappers";
import {
  createPurchaseOrderAction,
  createPurchaseOrderReset,
} from "../redux/actions/createPurchaseOrderAction";
import { CreatePurchaseOrderReducer } from "../redux/reducers/createPurchaseOrderReducer";
import {
  LeadTimes,
  ProductPendingDemand,
  PurchaseOrderForm,
  PurchaseOrderLine,
  QuoteLineIdAndUnitCostPairs,
} from "../types";
import { mapAddressToString } from "../utils/mapAddressToString";
import { mapPurchaseOrderToFormValues } from "../utils/mapPurchaseOrderToFormValues";
import { validationSchema } from "../validation";

interface Props {
  purchaseOrderState: CreatePurchaseOrderReducer;
  createPurchaseOrder: (body: CreatePurchaseOrderBody) => void;
  resetPurchaseOrder: () => void;
  quotationCalculationState: GetQuotationCalculationReducer;
  getQuotationCalculation: (
    body: GetQuotationCalculationBody
  ) => Promise<QuoteLineIdAndUnitCostPairs[]>;
  resetQuotationCalculation: () => void;
}

function CreatePurchaseOrder({
  purchaseOrderState,
  resetPurchaseOrder,
  quotationCalculationState,
  getQuotationCalculation,
  resetQuotationCalculation,
  createPurchaseOrder,
}: Props) {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const [initialValues, setInitialValues] = useState<PurchaseOrderForm>(initialFieldValues);
  const [clientOrderOptions, setClientOrderOptions] = useState<ReactSelectOption[]>([]);
  const [isAddingPOLines, setIsAddingPOLines] = useState<boolean>(false);
  const [productPendingDemand, setProductPendingDemand] = useState<ProductPendingDemand[]>([]);
  const [isSummaryTableOpen, setIsSummaryTableOpen] = useState<boolean>(false);
  const [estimatedDeliveryDate, setEstimatedDeliveryDate] = useState<string>();
  const [selectedContactIds, setSelectedContactIds] = useState<number[]>([]);
  const [selectedClientOrderAddressIds, setSelectedClientOrderAddressIds] = useState<number[]>([]);
  const [leadTimes, setLeadTimes] = useState<LeadTimes>({});
  const [selectedSupplier, setSelectedSupplier] = useState<SupplierItem | null>(null);
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();
  const [getPurchaseOrderState, getPurchaseOrder] = useAPI(getPurchaseOrderByIdAPI, true);

  const { suppliers, suppliersError, suppliersLoading, getSuppliers } = useSuppliers();
  const { pendingDemand, clientOptions, pendingDemandError, pendingDemandLoading } =
    useOrderPendingDemand();
  const { contacts, contactsError, getContactsByOrganisationName } =
    useContactsByOrganisationName();
  const { xeroContactId, xeroContactIdError, getXeroContactIdByOrganisationId } =
    useXeroContactIdByOrganisationId();
  const { addresses, addressesError, getAddressesByOrganisationId } =
    useAddressesByOrganisationId();

  const { addresses: warehouseAddresses, addressesError: warehouseAddressesError } =
    useWarehouseAddresses();

  const obtainUniqueClientOrders = ({ clients }: { clients: any }) => {
    const clientValues = Array.isArray(clients) ? clients.map(client => client.value) : [];
    const filteredPendingDemand = pendingDemand.filter(demand => {
      const organisationId = demand.client_order_line?.client_order.organisation.id;
      return organisationId && clientValues.includes(organisationId);
    });
    setClientOrderOptions(mapDemandToOrders(filteredPendingDemand));
  };

  const handleLeadTime = (leadTime: number | undefined, productId: number) => {
    const existingKeys = Object.keys(leadTimes).map(key => Number(key));
    const newLeadTimes = { ...leadTimes };

    if (leadTime) newLeadTimes[productId] = leadTime;
    else if (existingKeys.includes(productId)) delete newLeadTimes[productId];

    if (Object.keys(newLeadTimes).length > 0) {
      const uniqueLeadTimes: number[] = _.uniq(Object.values(newLeadTimes));

      if (uniqueLeadTimes.length > 1) {
        toast.error(copy.errorDifferentleadTimes);
        return;
      }
      setEstimatedDeliveryDate(getMaxLeadTimeDate(uniqueLeadTimes[0], selectedSupplier));
      setLeadTimes(newLeadTimes);
    } else {
      setEstimatedDeliveryDate(undefined);
    }
  };

  const obtainProducts = ({ clientOrderIds }: { clientOrderIds: number[] }) => {
    const filteredPendingDemand = pendingDemand.filter(demand => {
      return clientOrderIds.some(
        clientOrderId => clientOrderId === demand.client_order_line?.client_order.id
      );
    });
    const productPendingDemand: ProductPendingDemand[] = mapDemandToProducts(filteredPendingDemand);
    setProductPendingDemand(productPendingDemand);
  };

  const onSubmit = (values: PurchaseOrderForm) => {
    if (!SOURCING_EDIT_ROLES.some(role => roles.includes(role))) {
      toast.error(copy.errorIncorrectPermissions);
      return;
    }

    const uniquePOLines = getUniquePurchaseOrderLines(values.purchase_order_lines);

    if (uniquePOLines.length !== values.purchase_order_lines.length) {
      toast.error(copy.errorDuplicateProduct);
      return;
    }

    if (!xeroContactId) {
      toast.error(copy.errorMissingSupplierContactId);
      return;
    }

    const purchaseOrderLines = values.purchase_order_lines.map(line => {
      const clientOrderLines = line.client_order_lines.map(clientOrderLine => {
        return {
          clientOrderLineId: Number(clientOrderLine.client_order_line!.id),
          quantity: Number(clientOrderLine.quantity_to_purchase),
        };
      });

      return {
        productId: line.product?.value!,
        currencyCode: line.supplier_quote_line!.currencyCode,
        supplierQuoteLineId: Number(line.supplier_quote_line!.quoteLineId),
        isQaInspectionRequired: line.is_qa_required,
        clientOrderLinesDetails: clientOrderLines,
        qaInspectionNotes: line.qa_notes,
        calculatedQuotationCost: line.supplier_quote_line!.unitCost,
        notes: "None",
        attachments: [],
      };
    });

    let selectedDelivery;

    if (values.delivery_type?.label === "Client Address") {
      selectedDelivery = addresses?.find(address => address.id === values.destination?.value);
    } else {
      selectedDelivery = warehouseAddresses?.find(
        warehouseAddress => warehouseAddress.id === values.destination?.value
      );
    }

    const address = mapAddressToString(selectedDelivery);
    const expectedDate = estimatedDeliveryDate ?? getDefaultEstimatedDate();

    const fileCopyLines = values.purchase_order_lines
      .filter(line => line.fileCopyQty !== null && line.product !== null)
      .map(line => ({
        productId: line.product!.value,
        quantity: line.fileCopyQty!,
      }));

    const purchaseOrderBody = {
      clientOrderIds: values.client_orders?.map(clientOrder => clientOrder.value),
      attentionToContactId: values.attention_to?.value!,
      addressId: values.destination?.value!,
      deliveryAddress: address,
      supplierOrganisationId: values.manufacturer?.value!,
      isProduction: true,
      notes: values.order_notes,
      externalOrderNotes: values.external_order_notes,
      expectedDeliveryDate: new Date(expectedDate).toISOString(),
      attachments: mapAttachmentsToDTO(values.attachments),
      orderLines: purchaseOrderLines,
      fileCopyLines,
    };

    createPurchaseOrder(purchaseOrderBody);
  };

  useEffect(() => {
    if (purchaseOrderId && pendingDemand) {
      getPurchaseOrder(purchaseOrderId).then(response => {
        const prefilledValues = mapPurchaseOrderToFormValues({
          response,
          pendingDemand,
          setSelectedContactIds,
          setSelectedClientOrderAddressIds,
          getSuppliers,
          setIsAddingPOLines,
          getAddressesByOrganisationId,
          getXeroContactIdByOrganisationId,
        });

        if (!prefilledValues) return;

        setInitialValues(prefilledValues);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pendingDemandLoading]);

  useEffect(() => {
    if (purchaseOrderState.success) {
      history.push("/purchase-orders");
      resetPurchaseOrder();
    }
    if (purchaseOrderState.error) resetPurchaseOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrderState]);

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        widthPercent: 0.4,
        accessor: (line: PendingDemandItem) =>
          prettifyProductName(line.product?.product_name || ""),
      },
      {
        Header: "Client",
        widthPercent: 0.3,
        accessor: (line: PendingDemandItem) =>
          line.client_order_line?.client_order.organisation.organisation_name,
      },
      {
        Header: "Client Order",
        widthPercent: 0.4,
        accessor: (line: PendingDemandItem) =>
          line.client_order_line?.client_order.external_client_order_id,
      },
      {
        Header: "Suggested Supplier",
        widthPercent: 0.2,
        accessor: (line: PendingDemandItem) =>
          line.client_order_line?.supplier_quote_line?.supplier_quote.organisation
            .organisation_name,
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (line: PendingDemandItem) => `${line.quantity_to_purchase} units`,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [pendingDemand]
  );
  const isLoading =
    pendingDemandLoading ||
    purchaseOrderState.loading ||
    getPurchaseOrderState === API_REQUEST_STATE.PENDING;

  const error =
    pendingDemandError ||
    addressesError ||
    suppliersError ||
    contactsError ||
    xeroContactIdError ||
    warehouseAddressesError;

  const setDeliveryType = ({ setFieldValue, selectedDeliveryType, values }: any) => {
    if (
      selectedDeliveryType?.value === CLIENT_ADDRESS_DELIVERY_TYPE_ID &&
      values.clients.length > 1
    ) {
      toast.error("Please select only one client to deliver to a client address.");
      return;
    }

    setFieldValue(fieldObjects.delivery_type.fieldName, selectedDeliveryType ?? null);
    setFieldValue(fieldObjects.destination.fieldName, null);
    setFieldValue(fieldObjects.attention_to.fieldName, null);

    if (selectedDeliveryType?.value === SOURCEFUL_WAREHOUSE_TYPE_ID) {
      getContactsByOrganisationName({ organisationName: "sourceful" });
    }

    if (selectedDeliveryType?.value === CLIENT_ADDRESS_DELIVERY_TYPE_ID) {
      if (values.clients.length === 0) {
        toast.warn("Please select a client.");
        return;
      }

      setInitialValues({
        ...values,
        [fieldObjects.destination.fieldName]: null,
        [fieldObjects.attention_to.fieldName]: null,
        [fieldObjects.delivery_type.fieldName]: selectedDeliveryType,
      });
      getAddressesByOrganisationId({ organisationId: values.clients[0].value });
      getContactsByOrganisationName({ organisationName: values.clients[0].label });
    }
  };

  const setPrefilledAttachment = (uploadedFile: UploadedFile): PrefilledAttachment | undefined => {
    if (uploadedFile.attachment_uuid === "" && uploadedFile.original_filename === "") {
      return;
    }
    return {
      attachmentUUID: uploadedFile.attachment_uuid,
      originalFilename: uploadedFile.original_filename,
    };
  };

  const getProductLineKey = (poLine: PurchaseOrderLine): string => {
    const prefix = "product_line_";
    if (poLine.quotation_calculation_pair) {
      return `${prefix}${poLine.quotation_calculation_pair.label}`;
    }

    return `${prefix}${poLine.product?.value}`;
  };

  const supplierOptions = mapSupplierOptions(suppliers);

  return (
    <Page title={copy.pageTitle} isLoading={isLoading} error={error}>
      <Panel withWrapper title={copy.panelTitle}>
        <InnerPanelWrapper>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              validateForm,
              setTouched,
            }) => {
              let countryCode: string;
              if (values.delivery_type?.label === "Client Address") {
                countryCode =
                  addresses?.find(address => address.id === values.destination?.value)
                    ?.country_code || "";
              } else {
                countryCode =
                  warehouseAddresses?.find(
                    warehouseAddress => warehouseAddress.id === values.destination?.value
                  )?.country_code || "";
              }

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <Title>Client</Title>
                    <PrimaryLineDataWrapper>
                      {!isAddingPOLines && (
                        <>
                          <DataPoint withUniqueMargin halfWidth>
                            <Label isRequired htmlFor={fieldObjects.clients.fieldName}>
                              {fieldObjects.clients.fieldLabel}
                            </Label>
                            <Select
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              isSearchable={true}
                              options={clientOptions}
                              id={fieldObjects.clients.fieldName}
                              isMulti
                              value={values.clients}
                              onChange={e => {
                                setFieldValue(fieldObjects.clients.fieldName, e);
                                setSelectedContactIds([]);
                                setSelectedClientOrderAddressIds([]);
                                setFieldValue(fieldObjects.delivery_type.fieldName, null);
                                setFieldValue(fieldObjects.destination.fieldName, null);
                                setFieldValue(fieldObjects.attention_to.fieldName, null);
                                setFieldValue(fieldObjects.manufacturer.fieldName, null);
                                obtainUniqueClientOrders({ clients: e });
                                setIsSummaryTableOpen(false);

                                if (e?.length > 1) {
                                  const warehouseDeliveryType = deliveryTypeOptions.find(
                                    deliveryType =>
                                      deliveryType.value === SOURCEFUL_WAREHOUSE_TYPE_ID
                                  );
                                  setDeliveryType({
                                    setFieldValue,
                                    selectedDeliveryType: warehouseDeliveryType,
                                    values: values,
                                  });
                                }
                              }}
                              placeholder={fieldObjects.clients.fieldPlaceholder}
                            />
                            {errors.clients && touched.clients ? (
                              <ErrorMessage>{JSON.stringify(errors.clients)}</ErrorMessage>
                            ) : null}
                          </DataPoint>

                          <DataPoint withUniqueMargin halfWidth>
                            <Label isRequired htmlFor={fieldObjects.client_orders.fieldName}>
                              {fieldObjects.client_orders.fieldLabel}
                            </Label>
                            <Select
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              isSearchable={true}
                              options={clientOrderOptions}
                              id={fieldObjects.client_orders.fieldName}
                              isMulti
                              value={values.client_orders}
                              onChange={e => {
                                setFieldValue(fieldObjects.client_orders.fieldName, e);
                                setIsSummaryTableOpen(false);
                                const selectedClientOrders = pendingDemand.filter(demand => {
                                  return e.some(
                                    ({ value }) =>
                                      value === demand.client_order_line?.client_order.id
                                  );
                                });
                                if (selectedClientOrders) {
                                  const contactIds = selectedClientOrders.map(clientOrder => {
                                    return clientOrder.client_order_line?.client_order.contact_id!;
                                  });
                                  setSelectedContactIds(contactIds);

                                  const addressIds = selectedClientOrders.map(clientOrder => {
                                    return clientOrder.client_order_line?.client_order.address_id!;
                                  });
                                  setSelectedClientOrderAddressIds(addressIds);

                                  const productIds: number[] = selectedClientOrders
                                    .filter(demand => demand.product)
                                    .map(demand => demand.product!.id);

                                  getSuppliers({ productIds });
                                } else {
                                  getSuppliers({ productIds: [] });
                                }
                              }}
                              placeholder={fieldObjects.client_orders.fieldPlaceholder}
                            />
                            {errors.client_orders && touched.client_orders ? (
                              <ErrorMessage>{errors.client_orders}</ErrorMessage>
                            ) : null}
                          </DataPoint>

                          {values.clients && values.client_orders && (
                            <DataPoint fullWidth centralisedButton>
                              <SecondaryButton
                                type="button"
                                appearance="whiteButtonBlueText"
                                onClick={() => setIsSummaryTableOpen(!isSummaryTableOpen)}
                              >
                                <IconText
                                  text={`${
                                    isSummaryTableOpen ? "Hide" : "Display"
                                  } Client Order Pending Demand`}
                                  primaryIcon={`arrow-direction-${
                                    isSummaryTableOpen ? "up" : "down"
                                  }`}
                                />
                              </SecondaryButton>
                            </DataPoint>
                          )}
                          {isSummaryTableOpen && (
                            <DataPoint fullWidth>
                              <Table
                                columns={columns}
                                hideUtilityBar={true}
                                data={
                                  pendingDemand.filter(demand =>
                                    values.client_orders.some(
                                      ({ value }) =>
                                        value === demand.client_order_line?.client_order.id
                                    )
                                  ) ?? []
                                }
                              />
                            </DataPoint>
                          )}
                        </>
                      )}
                      {isAddingPOLines && (
                        <>
                          <DataPoint withUniqueMargin halfWidth>
                            <DisplayBox
                              fullWidth
                              isGray
                              label={fieldObjects.clients.fieldLabel}
                              value={values.clients.map(client => client.label).join(",  ")}
                            />
                          </DataPoint>
                          <DataPoint withUniqueMargin halfWidth>
                            <DisplayBox
                              fullWidth
                              isGray
                              label={fieldObjects.client_orders.fieldLabel}
                              value={values.client_orders
                                .map(clientOrder => clientOrder.label)
                                .join(",  ")}
                            />
                          </DataPoint>
                          {values.clients && values.client_orders && (
                            <DataPoint fullWidth centralisedButton>
                              <SecondaryButton
                                type="button"
                                appearance="whiteButtonBlueText"
                                className={WMSButtonGroup({ type: "smallMargin" })}
                                onClick={() => setIsSummaryTableOpen(!isSummaryTableOpen)}
                              >
                                <IconText
                                  text={`${isSummaryTableOpen ? "Close" : "Open"} Summary Table`}
                                  primaryIcon={`arrow-direction-${
                                    isSummaryTableOpen ? "up" : "down"
                                  }`}
                                />
                              </SecondaryButton>
                            </DataPoint>
                          )}
                          {isSummaryTableOpen && (
                            <DataPoint fullWidth>
                              <Table
                                data={
                                  pendingDemand.filter(demand =>
                                    values.client_orders.some(
                                      ({ value }) =>
                                        value === demand.client_order_line?.client_order.id
                                    )
                                  ) ?? []
                                }
                                columns={columns}
                                hideUtilityBar={true}
                              />
                            </DataPoint>
                          )}
                        </>
                      )}
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  <PrimaryLineWrapper>
                    <Title>Manufacturer</Title>
                    <PrimaryLineDataWrapper>
                      {!isAddingPOLines && (
                        <DataPoint withUniqueMargin halfWidth>
                          <SimpleSelect
                            isRequired
                            isDisabled={!values.client_orders || values.client_orders.length === 0}
                            htmlFor={fieldObjects.manufacturer.fieldName}
                            label={fieldObjects.manufacturer.fieldLabel}
                            tooltipMessage={
                              "Please select at least one client <br/> order to see available manufacturers."
                            }
                            options={supplierOptions}
                            name={fieldObjects.manufacturer.fieldName}
                            value={values.manufacturer}
                            changeHandler={e => {
                              const selectedDeliveryType = deliveryTypeOptions.find(
                                deliveryType => deliveryType.value === (e as any)?.deliveryTypeId
                              );
                              if (selectedDeliveryType && !values.delivery_type) {
                                setDeliveryType({
                                  setFieldValue,
                                  selectedDeliveryType,
                                  values: {
                                    ...values,
                                    [fieldObjects.manufacturer.fieldName]: e,
                                  },
                                });
                              } else {
                                setFieldValue(fieldObjects.destination.fieldName, null);
                                setFieldValue(fieldObjects.attention_to.fieldName, null);
                              }

                              setFieldValue(fieldObjects.manufacturer.fieldName, e);

                              const selectedSupplier = suppliers.find(
                                supplier => supplier.id === e?.value
                              );

                              if (selectedSupplier) setSelectedSupplier(selectedSupplier);
                            }}
                            placeholder={fieldObjects.manufacturer.fieldPlaceholder}
                            error={errors.manufacturer}
                            touched={touched.manufacturer}
                          />
                          {values.client_orders &&
                            values.client_orders.length > 0 &&
                            !suppliersLoading &&
                            suppliers.length === 0 && (
                              <ErrorMessage>
                                There are no suppliers with valid quotes for the products in the
                                selected orders.
                              </ErrorMessage>
                            )}
                        </DataPoint>
                      )}
                      {isAddingPOLines && (
                        <DataPoint withUniqueMargin>
                          <Label isGray htmlFor={fieldObjects.manufacturer.fieldName}>
                            {fieldObjects.manufacturer.fieldLabel}
                          </Label>
                          <Box>{values.manufacturer?.label}</Box>
                        </DataPoint>
                      )}
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  <PrimaryLineWrapper>
                    <Title>Delivery</Title>
                    <PrimaryLineDataWrapper>
                      {!isAddingPOLines && (
                        <>
                          {values.clients ? (
                            <>
                              <DataPoint withUniqueMargin>
                                <Label isRequired htmlFor={fieldObjects.delivery_type.fieldName}>
                                  {fieldObjects.delivery_type.fieldLabel}
                                </Label>
                                <Select
                                  styles={reactSelectStyling}
                                  maxMenuHeight={220}
                                  isSearchable={true}
                                  options={deliveryTypeOptions}
                                  id={fieldObjects.delivery_type.fieldName}
                                  value={values.delivery_type}
                                  onChange={selectedDeliveryType => {
                                    setDeliveryType({
                                      setFieldValue,
                                      selectedDeliveryType,
                                      values,
                                    });
                                  }}
                                  placeholder={fieldObjects.delivery_type.fieldPlaceholder}
                                />
                                {errors.delivery_type && touched.delivery_type ? (
                                  <ErrorMessage>{errors.delivery_type}</ErrorMessage>
                                ) : null}
                              </DataPoint>
                              <DataPoint withUniqueMargin>
                                <Label isRequired htmlFor={fieldObjects.destination.fieldName}>
                                  {fieldObjects.destination.fieldLabel}
                                </Label>
                                <Select
                                  styles={reactSelectStyling}
                                  maxMenuHeight={220}
                                  isSearchable={true}
                                  options={
                                    values.delivery_type?.label === "Sourceful Warehouse"
                                      ? warehouseAddresses?.map(address => {
                                          return {
                                            label: address.address_name,
                                            value: address.id,
                                          };
                                        }) ?? []
                                      : values.delivery_type?.label === "Client Address"
                                      ? addresses
                                          ?.filter(address =>
                                            selectedClientOrderAddressIds.includes(address.id)
                                          )
                                          .map(address => {
                                            return {
                                              label: address.address_name,
                                              value: address.id,
                                            };
                                          }) ?? []
                                      : []
                                  }
                                  id={fieldObjects.destination.fieldName}
                                  value={values.destination}
                                  onChange={e =>
                                    setFieldValue(fieldObjects.destination.fieldName, e)
                                  }
                                  placeholder={fieldObjects.destination.fieldPlaceholder}
                                />
                                {errors.destination && touched.destination ? (
                                  <ErrorMessage>{errors.destination}</ErrorMessage>
                                ) : null}
                              </DataPoint>

                              <DataPoint withUniqueMargin>
                                <Label isRequired htmlFor={fieldObjects.attention_to.fieldName}>
                                  {fieldObjects.attention_to.fieldLabel}
                                </Label>
                                <Select
                                  styles={reactSelectStyling}
                                  maxMenuHeight={220}
                                  isSearchable={true}
                                  options={
                                    values.delivery_type?.label === "Sourceful Warehouse"
                                      ? contacts?.map(contact => {
                                          return {
                                            label: contact.contact_name,
                                            value: contact.id,
                                          };
                                        }) ?? []
                                      : values.delivery_type?.label === "Client Address"
                                      ? contacts
                                          ?.filter(contact =>
                                            selectedContactIds.includes(contact.id)
                                          )
                                          .map(contact => {
                                            return {
                                              label: contact.contact_name,
                                              value: contact.id,
                                            };
                                          }) ?? []
                                      : []
                                  }
                                  id={fieldObjects.attention_to.fieldName}
                                  value={values.attention_to}
                                  onChange={e => {
                                    setFieldValue(fieldObjects.attention_to.fieldName, e);
                                  }}
                                  placeholder={fieldObjects.attention_to.fieldPlaceholder}
                                />
                                {errors.attention_to && touched.attention_to ? (
                                  <ErrorMessage>{errors.attention_to}</ErrorMessage>
                                ) : null}
                              </DataPoint>
                              <DataPoint withUniqueMargin>
                                <Label isGray htmlFor={fieldObjects.delivery_date.fieldName}>
                                  {fieldObjects.delivery_date.fieldLabel}
                                </Label>
                                <Box> {fieldObjects.delivery_date.fieldBox}</Box>
                              </DataPoint>
                            </>
                          ) : (
                            <>
                              <DataPoint withUniqueMargin>
                                <Label isGray htmlFor={fieldObjects.delivery_type.fieldName}>
                                  {fieldObjects.delivery_type.fieldLabel}
                                </Label>
                                <Box>{fieldObjects.clients.fieldBox}</Box>
                              </DataPoint>
                              <DataPoint withUniqueMargin>
                                <Label isGray htmlFor={fieldObjects.destination.fieldName}>
                                  {fieldObjects.destination.fieldName}
                                </Label>
                                <Box>{fieldObjects.clients.fieldBox}</Box>
                              </DataPoint>
                              <DataPoint withUniqueMargin>
                                <Label isGray htmlFor={fieldObjects.attention_to.fieldName}>
                                  {fieldObjects.delivery_type.fieldLabel}
                                </Label>
                                <Box>{fieldObjects.clients.fieldBox}</Box>
                              </DataPoint>
                              <DataPoint withUniqueMargin>
                                <Label isGray htmlFor={fieldObjects.delivery_date.fieldName}>
                                  {fieldObjects.delivery_date.fieldLabel}
                                </Label>
                                <Box>{fieldObjects.clients.fieldBox}</Box>
                              </DataPoint>
                            </>
                          )}
                        </>
                      )}
                      {isAddingPOLines && (
                        <>
                          <DataPoint withUniqueMargin>
                            <Label isGray isRequired htmlFor={fieldObjects.delivery_type.fieldName}>
                              {fieldObjects.delivery_type.fieldLabel}
                            </Label>
                            <Box>{values.delivery_type?.label}</Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isGray isRequired htmlFor={fieldObjects.destination.fieldName}>
                              {fieldObjects.destination.fieldLabel}
                            </Label>
                            <Box>{values.destination?.label}</Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isRequired isGray htmlFor={fieldObjects.attention_to.fieldName}>
                              {fieldObjects.attention_to.fieldLabel}
                            </Label>
                            <Box>{values.attention_to?.label}</Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isGray htmlFor={fieldObjects.delivery_date.fieldName}>
                              {fieldObjects.delivery_date.fieldLabel}
                            </Label>
                            <Box>
                              {estimatedDeliveryDate
                                ? estimatedDeliveryDate
                                : "Please select a Quote"}
                            </Box>
                          </DataPoint>
                        </>
                      )}
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  {!isAddingPOLines && (
                    <ButtonWrapper>
                      <SecondaryButton
                        css={{ marginTop: 20 }}
                        type="button"
                        appearance={"whiteButtonBlueText"}
                        onClick={async () => {
                          setTouched({
                            clients: [],
                            client_orders: [],
                            delivery_type: true,
                            destination: true,
                            manufacturer: true,
                            attention_to: true,
                          });

                          const formErrors = await validateForm();

                          if (Object.keys(formErrors).length === 0) {
                            setInitialValues({
                              ...values,
                              purchase_order_lines: [emptyPurchseOrderLine],
                            });
                            setLeadTimes({});
                            setIsAddingPOLines(true);
                            const clientOrdersIds: number[] = values.client_orders.map(
                              clientOrder => clientOrder.value
                            );
                            obtainProducts({ clientOrderIds: clientOrdersIds });
                            getXeroContactIdByOrganisationId({
                              organisationId: values.manufacturer?.value!,
                            });
                          }
                        }}
                      >
                        <IconText
                          text={"Add Purchase Order Lines"}
                          primaryIcon={"alert-add-outline"}
                        />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {isAddingPOLines && (
                    <>
                      <PrimaryLineWrapper>
                        <Title> Products </Title>
                        {values.purchase_order_lines.map((po_line, index) => {
                          const selectedProducts = values.purchase_order_lines.map(
                            poLine => poLine.product?.value
                          );
                          return (
                            <>
                              <ProductLine
                                key={getProductLineKey(po_line)}
                                countryCode={countryCode}
                                productDropdownData={productPendingDemand.filter(
                                  demand => !selectedProducts.includes(demand.product.id)
                                )}
                                originalPendingDemand={pendingDemand.filter(demand =>
                                  values.client_orders.some(
                                    ({ value }) =>
                                      value === demand.client_order_line?.client_order.id
                                  )
                                )}
                                manufacturer={values.manufacturer!}
                                productLine={po_line}
                                errors={
                                  errors.purchase_order_lines as FormikErrors<PurchaseOrderLine[]>
                                }
                                touched={touched.purchase_order_lines}
                                quotationCalculationState={quotationCalculationState}
                                getQuotationCalculation={getQuotationCalculation}
                                resetQuotationCalculation={resetQuotationCalculation}
                                index={index}
                                handleLeadTime={handleLeadTime}
                                setFieldValue={setFieldValue}
                                handleRemovePurchaseOrderLine={(index: number) => {
                                  const filteredPOLines = values.purchase_order_lines.filter(
                                    (_, i) => i !== index
                                  );
                                  const newValues = { ...values };
                                  newValues.purchase_order_lines = filteredPOLines;
                                  if (filteredPOLines.length === 0) {
                                    setInitialValues(newValues);
                                    setIsAddingPOLines(false);
                                  }
                                  setInitialValues(newValues);
                                }}
                              />

                              {po_line.fileCopyQty && po_line.product && (
                                <ProductLineSimple
                                  productLabel={po_line.product.label}
                                  quantity={po_line.fileCopyQty}
                                  setFieldValue={setFieldValue}
                                  index={index}
                                />
                              )}
                            </>
                          );
                        })}

                        <ButtonWrapper>
                          <SecondaryButton
                            type="button"
                            css={{ marginTop: 20 }}
                            disabled={
                              productPendingDemand.filter(
                                demand =>
                                  !values.purchase_order_lines
                                    .map(poLine => poLine.product?.value)
                                    .includes(demand.product.id)
                              ).length === 0
                            }
                            onClick={() =>
                              setInitialValues({
                                ...values,
                                purchase_order_lines: [
                                  ...values.purchase_order_lines,
                                  emptyPurchseOrderLine,
                                ],
                              })
                            }
                            appearance={"whiteButtonBlueText"}
                          >
                            <IconText
                              text={"Add Purchase Order Line"}
                              primaryIcon={"alert-add-outline"}
                            />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </PrimaryLineWrapper>

                      <PrimaryLineWrapper>
                        <Title> {fieldObjects.attachments.fieldLabel} </Title>
                        <PrimaryLineDataWrapper style={{ flexDirection: "row" }}>
                          {values.attachments.length === 0 && <div>No attachments</div>}
                          <DataPoint fullWidth>
                            {values.attachments.map((_attachment, index_attachment) => (
                              <AttachmentUploader
                                prefilledAttachment={setPrefilledAttachment(_attachment)}
                                key={index_attachment}
                                indexAttachment={index_attachment}
                                parameterName={fieldObjects.attachments.fieldName}
                                setFieldValue={setFieldValue}
                                handleRemoveAttachmentLine={(index_attachment: number) => {
                                  const filteredAttachments = values.attachments.filter(
                                    (_item, i) => i !== index_attachment
                                  );
                                  setFieldValue(
                                    fieldObjects.attachments.fieldName,
                                    filteredAttachments
                                  );
                                }}
                              />
                            ))}

                            <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                              <SecondaryButton
                                type="button"
                                appearance={"whiteButtonBlueText"}
                                onClick={() => {
                                  const attachments: UploadedFile[] = [...values.attachments];
                                  attachments.push({ ...emptyAttachment });
                                  setFieldValue(fieldObjects.attachments.fieldName, attachments);
                                }}
                              >
                                <IconText
                                  text={"Upload New Attachment"}
                                  primaryIcon={"alert-add-outline"}
                                />
                              </SecondaryButton>
                            </ButtonWrapper>
                          </DataPoint>
                        </PrimaryLineDataWrapper>
                      </PrimaryLineWrapper>

                      <PrimaryLineWrapper>
                        <Title> Additional Information </Title>
                        <PrimaryLineDataWrapper>
                          <DataPoint halfWidth>
                            <Label htmlFor={fieldObjects.order_notes.fieldName}>
                              {fieldObjects.order_notes.fieldLabel}
                            </Label>
                            <TextArea
                              id={fieldObjects.order_notes.fieldName}
                              type="text"
                              value={values.order_notes}
                              placeholder={fieldObjects.order_notes.fieldPlaceholder}
                              handleChange={e =>
                                setFieldValue(fieldObjects.order_notes.fieldName, e.target.value)
                              }
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <Label htmlFor={fieldObjects.external_order_notes.fieldName}>
                              {fieldObjects.external_order_notes.fieldLabel}
                            </Label>
                            <TextArea
                              id={fieldObjects.external_order_notes.fieldName}
                              type="text"
                              value={values.external_order_notes}
                              placeholder={fieldObjects.external_order_notes.fieldPlaceholder}
                              handleChange={e =>
                                setFieldValue(
                                  fieldObjects.external_order_notes.fieldName,
                                  e.target.value
                                )
                              }
                            />
                          </DataPoint>
                        </PrimaryLineDataWrapper>
                      </PrimaryLineWrapper>

                      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButton"}
                          onClick={() => {
                            setIsAddingPOLines(false);
                            setInitialValues({ ...initialFieldValues });
                          }}
                        >
                          Cancel
                        </SecondaryButton>

                        <SecondaryButton
                          type="button"
                          appearance={"blueButton"}
                          onClick={() => {
                            if (
                              !values.purchase_order_lines.every(
                                line => line.quotation_calculation_pair
                              )
                            ) {
                              toast.error(copy.errorMissingSupplierQuote);
                            }
                            handleSubmit();
                          }}
                        >
                          Confirm
                        </SecondaryButton>
                      </ButtonGroup>
                    </>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    purchaseOrderState: state.createPurchaseOrderReducer,
    quotationCalculationState: state.getQuotationCalculationReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createPurchaseOrder: (body: CreatePurchaseOrderBody) =>
      dispatch(createPurchaseOrderAction(body)),
    resetPurchaseOrder: () => dispatch(createPurchaseOrderReset()),
    getQuotationCalculation: (body: GetQuotationCalculationBody) =>
      dispatch(getQuotationCalculationAction(body)),
    resetQuotationCalculation: () => dispatch(getQuotationCalculationReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrder);
