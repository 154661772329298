import { SOURCING_API } from "../../../../../api/baseConfig";

export const markAsInTransitPurchaseOrderAPI = async (purchaseOrderId: number) => {
  try {
    return await SOURCING_API.patch(`/purchase-orders/${purchaseOrderId}/mark-as-in-transit`, {
      purchase_order_id: purchaseOrderId,
    });
  } catch (err) {
    console.log("Error in marking purchase order as in transit API call", err);
    throw err;
  }
};
