import {
  CASE_CHANGE_FAILED,
  CASE_CHANGE_LOADING,
  CASE_CHANGE_RESET,
  CASE_CHANGE_SUCCESS,
} from "../types";

export interface CaseChangeReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CaseChangeReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function caseChangeReducer(
  state: CaseChangeReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CASE_CHANGE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CASE_CHANGE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CASE_CHANGE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CASE_CHANGE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
