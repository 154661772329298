import React, { useRef } from "react";

import { IconText } from "@sourceful/shared-components";

import SecondaryButton from "../shared/components/atoms/buttons/SecondaryButton";
import { FileInput } from "../styles/SharedStyles";

interface FileUploaderProps {
  onFileSelectSuccess: (file: File) => void;
  onFileSelectError: (error: { error: string }) => void;
  acceptedFiles?: string;
  id?: string;
}

const FILE_SIZE_LIMIT = 202400000;

const FileUploader = ({
  onFileSelectSuccess,
  onFileSelectError,
  acceptedFiles,
  id,
}: FileUploaderProps) => {
  const hiddenFileInput = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenFileInput.current) {
      hiddenFileInput.current.click();
    }
  };

  const handleFileInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!e.target.files) {
      return;
    }

    const file = e.target.files[0];

    if (file?.size > FILE_SIZE_LIMIT) {
      return onFileSelectError({ error: "File size cannot exceed more than 200MB" });
    }

    file && onFileSelectSuccess(file);
  };

  return (
    <>
      <SecondaryButton type="button" onClick={handleClick} appearance={"whiteButtonBlueText"}>
        <IconText text={"Choose File"} primaryIcon={"alert-eye-open"} />
      </SecondaryButton>

      <FileInput
        id={`upload-button-${id}`}
        type="file"
        name="file"
        onChange={handleFileInput}
        ref={hiddenFileInput}
        accept={acceptedFiles}
      />
    </>
  );
};

export default FileUploader;
