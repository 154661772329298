import { useState } from "react";

import { styled } from "@sourceful/shared-components";

import { ToolTip } from "../../shared/components/atoms/labels/ToolTip/ToolTip";

export const FilterTogglesWrapper = styled("ul", {
  listStyle: "none",
  display: "flex",
  width: "100%",
  margin: "0px",
  padding: "0px",
});

export const ToolTipWrapper = styled("div", {
  color: "#005AE1",
  paddingTop: "0px",
  marginLeft: "5px",
  marginTop: "0px",
});

export const FilterButton = styled("button", {
  paddingLeft: "30px",
  whiteSpace: "nowrap",
  width: "100%",
  border: "0px",
  fontSize: "20px",
  cursor: "pointer",
  fontWeight: 700,
  color: "#979799",
  "&:active": {
    boxShadow: "inset 0px -3px 0px black",
    color: "#000000",
  },
  "&:hover": {
    boxShadow: "inset 0px -3px 0px black",
    color: "#000000",
  },
  display: "flex",
  alignItems: "center",
  variants: {
    isActive: {
      true: {
        color: "#000C32",
        boxShadow: "inset 0px -3px 0px black",
      },
    },
  },
});

export interface FilterTogglesProps {
  filters: FilterProps[];
}

export interface FilterProps {
  filteredMatches: number;
  header: string;
  handleFilter: (header: string) => void;
  isActive?: boolean;
  toolTipDescription?: string;
}

export const FilterToggles = ({ filters }: FilterTogglesProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  return (
    <FilterTogglesWrapper>
      {filters.map((filter, index) => {
        return (
          <li key={filter.header}>
            <FilterButton
              isActive={index === activeIndex ? true : false}
              onClick={() => {
                filter.handleFilter(filter.header);
                setActiveIndex(index);
              }}
            >
              {filter.header}
              <div style={{ fontSize: "10px", paddingLeft: "8px" }}>({filter.filteredMatches})</div>
              {filter.toolTipDescription && (
                <ToolTip message={filter.toolTipDescription} index={index}></ToolTip>
              )}
            </FilterButton>
          </li>
        );
      })}
    </FilterTogglesWrapper>
  );
};
