import { ApolloError } from "@apollo/client";

import { useGetOutboundCaseProductsByBaseProductIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { CaseProductItem } from "../types";

export interface HookResult {
  caseProducts?: CaseProductItem[];
  caseProductsError?: ApolloError;
  caseProductsLoading: boolean;
  getOutboundCaseProductsByBaseProductId: (options: Options) => Promise<void>;
}

export interface Options {
  baseProductId: number;
}

export const useOutboundCaseProductsByBaseProductId = (): HookResult => {
  const [getOutboundCaseProductsByBaseProductId, { data, error, loading }] =
    useGetOutboundCaseProductsByBaseProductIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    caseProducts: data && Array.isArray(data.product) ? data.product : undefined,
    caseProductsError: error,
    caseProductsLoading: loading,
    getOutboundCaseProductsByBaseProductId: async ({ baseProductId }: Options) => {
      getOutboundCaseProductsByBaseProductId({ variables: { baseProductId } });
    },
  };
};
