import { useTranslation } from "react-i18next";

import { Heading } from "@sourceful/shared-components";

import { Page } from "../../../../../../shared/components/templates/Page";
import { WhitePrimaryLineWrapper } from "../page/createSupplierResponse";

export const SupplierQuoteSubmitted = () => {
  const { t } = useTranslation();
  return (
    <Page title={t("quote_supplier_response_page_title")} hasBackButton={false} isLoading={false}>
      <WhitePrimaryLineWrapper css={{ width: "800px", maxWidth: "90%" }}>
        <Heading level={3} fontSize={6} css={{ marginTop: "20px" }}>
          {t("quote_supplier_response_submitted_section_title")}
        </Heading>
        <p>{t("quote_supplier_response_submitted_section_description")}</p>
      </WhitePrimaryLineWrapper>
    </Page>
  );
};
