import { WMS_API } from "../../../../../../api/baseConfig";

export const completeOutboundManifestAPI = async (outbound_manifest_id: number) => {
  try {
    return await WMS_API.patch(`/outbound-manifests/${outbound_manifest_id}/complete`);
  } catch (err) {
    console.log("Error in complete outbound manifest API call:", err);
    throw err;
  }
};
