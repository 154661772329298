import { Icon, IconText } from "@sourceful/shared-components";

import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import { IndexPill } from "../../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../../styles/SharedStyles";
import { mapQuotationCostTypesToDropdownItem } from "../../../graphql/mappers";
import { fieldNames } from "../formValues";
import { QuoteLineProps } from "../types";

const UpdatedQuoteLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveQuoteLine,
  errors,
  touched,
  quotationCostTypes,
}: QuoteLineProps) => {
  const costTypesOptions = mapQuotationCostTypesToDropdownItem(quotationCostTypes);

  const selectedCostType = quotationCostTypes.filter(
    quotationCostType => quotationCostType.id === values.costType?.value
  );

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint halfWidth>
          <SimpleSelect
            isRequired
            tooltipMessage={
              "For a description of each cost type, <br /> please select it from the dropdown."
            }
            htmlFor={`costType_${index}`}
            name={fieldNames.quoteLine.costType}
            placeholder={"Select Cost Type"}
            options={costTypesOptions}
            value={values.costType}
            error={
              Array.isArray(errors) && errors[index]?.costType ? errors[index]!.costType : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.costType}
            label={"Cost Type"}
            changeHandler={e => setFieldValue(`quoteLines[${index}].costType`, e)}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <SimpleInput
            htmlFor={`cost_${index}`}
            name={fieldNames.quoteLine.cost}
            type={"text"}
            placeholder="1.56"
            value={values.cost}
            error={Array.isArray(errors) && errors[index]?.cost ? errors[index]!.cost : undefined}
            touched={Array.isArray(touched) && touched[index]?.cost}
            label="Cost excl. VAT"
            changeHandler={e => {
              setFieldValue(`quoteLines[${index}].cost`, e.target.value);
            }}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>
      <DataPoint halfWidth>
        {selectedCostType?.length > 0 && (
          <IconText
            css={{ color: "grey", marginLeft: "10px" }}
            text={selectedCostType[0].cost_description}
            primaryIcon="alert-question-outline"
          />
        )}
      </DataPoint>
      <DataPoint halfWidth containsButton>
        <IconWrapper type="button" onClick={() => handleRemoveQuoteLine(index)}>
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DataPoint>
    </SecondaryLineWrapper>
  );
};

export default UpdatedQuoteLine;
