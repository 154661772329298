import { ApolloError } from "@apollo/client";

import { useGetPurchaseOrderByIdQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { PurchaseOrderItem } from "../types";

export interface HookResult {
  purchaseOrder?: PurchaseOrderItem;
  purchaseOrderError?: ApolloError;
  purchaseOrderLoading: boolean;
  refetchPurchaseOrder: () => Promise<void>;
}

export interface Options {
  purchaseOrderId: number;
}

export const usePurchaseOrderById = ({ purchaseOrderId }: Options): HookResult => {
  const { data, error, loading, refetch } = useGetPurchaseOrderByIdQuery({
    variables: { purchaseOrderId },
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    purchaseOrder: data?.purchase_order[0],
    purchaseOrderError: error,
    purchaseOrderLoading: loading,
    refetchPurchaseOrder: async () => {
      refetch({ purchaseOrderId: purchaseOrderId });
    },
  };
};
