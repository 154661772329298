import dayjs from "dayjs";
import { SetStateAction, useState } from "react";

import { styled } from "@sourceful/shared-components";

import { UniqueColumnData } from "../Table";
import SearchBar from "../searchBar/SearchBar";

const ScrollBar = styled("div", {
  backgroundColor: "$palette3_colour2",
  overflow: "auto",
  height: "158px",

  "&::-webkit-scrollbar": {
    backgroundColor: "white",
    width: "8px",
  },
  "&::-webkit-scrollbar-thumb": {
    backgroundColor: "#B9B9BB",
    borderRadius: "5px",
  },
});

const Heading = styled("div", {
  boxShadow: "inset 0px -1px 0px lightgrey",
  paddingBottom: "5px",
  fontWeight: 700,
});

const ScrollItem = styled("li", {
  marginTop: "5px",
  listStyleType: "none",
  marginBottom: "9px",
});

const TopSection = styled("div", {
  marginTop: "10px",
  marginBottom: "15px",
  width: "100%",
});

const Container = styled("div", {
  width: "auto",
  marginTop: "10px",
  padding: "30px",
});

interface CustomStyling {
  [key: string]: {};
}

export interface CheckboxScrollProps {
  options: {
    value: string;
    checked: boolean;
  }[];
  hasSearchBar?: boolean;
  heading: UniqueColumnData["column"];
  accessorType: UniqueColumnData["accessorType"];
  style?: CustomStyling;
  searchPlaceholder: string;
  setAllOptions: React.Dispatch<SetStateAction<any>>;
}

export const CheckboxScroll = ({
  options,
  hasSearchBar,
  heading,
  searchPlaceholder,
  setAllOptions,
  style,
  accessorType,
}: CheckboxScrollProps) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [optionsInState, setOptionsInState] = useState(options);

  return (
    <Container style={style?.container}>
      <Heading style={style?.heading}>{heading}</Heading>
      {hasSearchBar && (
        <TopSection>
          <SearchBar searchPlaceholder={searchPlaceholder} setSearchTerm={setSearchTerm} />
        </TopSection>
      )}
      <ScrollBar style={style?.scrollbar}>
        {optionsInState
          // eslint-disable-next-line array-callback-return
          ?.filter(opt => {
            if (searchTerm === "" || opt.value.toLowerCase().includes(searchTerm.toLowerCase())) {
              return opt.value;
            }
          })
          .map(opt => {
            let momentDate = undefined;
            if (accessorType && accessorType === "date") {
              momentDate = dayjs(opt.value);
              if (momentDate.isValid()) momentDate = momentDate.format("DD-MM-YYYY");
              else momentDate = undefined;
            }

            return (
              <ScrollItem key={`key_${heading}_${opt.value}`}>
                <input
                  id={`id_${heading}_${opt.value}`}
                  type="checkbox"
                  checked={opt.checked}
                  onChange={() => {
                    const copyOfOptions = [...optionsInState];
                    const indexOfOption = optionsInState.findIndex(
                      option => option.value === opt.value
                    );
                    copyOfOptions[indexOfOption].checked = !copyOfOptions[indexOfOption].checked;
                    setOptionsInState(copyOfOptions);
                    setAllOptions((currentState: any) => ({
                      ...currentState,
                      [String(heading)]: copyOfOptions,
                    }));
                  }}
                />
                <label
                  style={{ paddingLeft: "10px", paddingRight: "10px" }}
                  htmlFor={`id_${heading}_${opt.value}`}
                >
                  {momentDate ?? opt.value}
                </label>
              </ScrollItem>
            );
          })}
      </ScrollBar>
    </Container>
  );
};
