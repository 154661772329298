import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { styled } from "@sourceful/shared-components";

import { activeServiceAction } from "../../../../redux/actions/activeServiceAction";
import { ActiveServiceReducer } from "../../../../redux/reducers/activeServiceReducer";
import { StoreTypes } from "../../../../redux/store/storeTypes";
import Page from "../../../../shared/components/templates/Page/Page";
import { getFeatureFlagPcatEnabled } from "../../../../util/featureFlags";
import { MenuCard } from "../components/MenuCard";

const HomePageWrapper = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const HomePageRow = styled("div", {
  display: "flex",
  width: "80%",
});

const HomeCardsWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
  marginTop: "30px",
  marginBottom: "30px",
  justifyContent: "center",
});

interface HomepageProps {
  activeServiceState: ActiveServiceReducer;
  changeActiveService: (body: ActiveServiceReducer) => void;
}

function ViewHomepage(props: HomepageProps) {
  const { changeActiveService } = props;

  return (
    <Page isLoading={false} title={""} hasBackButton={false}>
      <HomePageWrapper>
        <HomePageRow>
          <HomeCardsWrapper>
            <MenuCard
              redirect="/sourcing"
              type="Sourcing"
              changeActiveService={changeActiveService}
            />
            <MenuCard redirect="/dashboard" type="WMS" changeActiveService={changeActiveService} />
            <MenuCard
              redirect="/logistics/dashboard"
              type="Logistics"
              changeActiveService={changeActiveService}
            />
            {getFeatureFlagPcatEnabled() && (
              <MenuCard
                redirect="/product-catalogue"
                type="PCAT"
                changeActiveService={changeActiveService}
              />
            )}
            <MenuCard
              redirect="/orders/dashboard"
              type="Orders"
              title="Order Builder"
              changeActiveService={changeActiveService}
            />
            <MenuCard redirect="/admin" type="Admin" changeActiveService={changeActiveService} />
          </HomeCardsWrapper>
        </HomePageRow>
      </HomePageWrapper>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    activeServiceState: state.activeServiceReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    changeActiveService: (body: ActiveServiceReducer) => dispatch(activeServiceAction(body)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewHomepage);
