import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { v4 as uuidv4 } from "uuid";

import { IconText, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { ServiceAccounts } from "../../../../../util/interfaces";
import { CreateInboundManifestBody } from "../api/types";
import ManifestLine from "../components/InboundManifestLine";
import {
  InboundManifestForm,
  ManifestLineInterface,
  emptyManifestLine,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { useInboundBaseProducts } from "../graphql/hooks/useInboundBaseProducts";
import { useInboundCourierServiceNames } from "../graphql/hooks/useInboundCourierServiceNames";
import { useInboundLogisticsTypeNames } from "../graphql/hooks/useInboundLogisticsTypeNames";
import { useInboundOrganisationNames } from "../graphql/hooks/useInboundOrganisationNames";
import { useInboundWarehouseNames } from "../graphql/hooks/useInboundWarehouseNames";
import { mapManifestLinesToDTO } from "../mappers";
import {
  createInboundManifestAction,
  createInboundManifestReset,
} from "../redux/actions/createInboundManifestActions";
import { Props } from "../types";
import { validationSchema } from "../validation";

function CreateInboundManifest(props: Props) {
  const [initialValues, setInitialValues] = useState<InboundManifestForm>(initialFieldValues);
  const [isAddingManifestLines, setIsAddingManifestLines] = useState<boolean>(false);

  const { createInboundManifestState } = props;

  const { logisticsTypeNames, logisticsTypeNamesLoading, logisticsTypeNamesError } =
    useInboundLogisticsTypeNames();
  const { courierServiceNames, courierServiceNamesLoading, courierServiceNamesError } =
    useInboundCourierServiceNames();
  const { organisationNames, organisationNamesLoading, organisationNamesError } =
    useInboundOrganisationNames();
  const { baseProducts, baseProductsLoading, baseProductsError } = useInboundBaseProducts();
  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useInboundWarehouseNames();

  const onSubmit = (values: InboundManifestForm) => {
    const manifestLines = mapManifestLinesToDTO(values.manifest_lines);

    const random5Chars = uuidv4().substring(0, 5);
    const inboundManifestBody: CreateInboundManifestBody = {
      service_account_id: ServiceAccounts.WMS,
      organisation_id: values.organisation?.value!,
      warehouse_id: values.warehouse?.value!,
      logistics_type_id: values.logistics_type?.value!,
      courier_service_id: values.courier_service?.value!,
      expected_arrival_date: new Date(values.expected_arrival_date).toISOString(),
      inbound_manifest_lines: [...manifestLines],
      notes: values.notes,
      reference: `${values.organisation?.label}-${moment(values.expected_arrival_date).format(
        "L"
      )}-${random5Chars}`,
    };

    props.createInboundManifest(inboundManifestBody);
  };

  useEffect(() => {
    if (createInboundManifestState.success) {
      props.resetCreateInboundManifest();
      setInitialValues({ ...initialFieldValues });
      setIsAddingManifestLines(false);
    }

    if (createInboundManifestState.error) {
      props.resetCreateInboundManifest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInboundManifestState.success, createInboundManifestState.error]);

  const isLoading =
    organisationNamesLoading ||
    warehouseNamesLoading ||
    logisticsTypeNamesLoading ||
    createInboundManifestState.loading ||
    baseProductsLoading ||
    courierServiceNamesLoading;

  const error =
    organisationNamesError ||
    warehouseNamesError ||
    baseProductsError ||
    logisticsTypeNamesError ||
    courierServiceNamesError;

  return (
    <Page error={error} isLoading={isLoading} title={"Create Inbound Manifest"}>
      <Panel
        withWrapper
        title={"Create Inbound Manifest "}
        subtitle={"Small description of the inbound manifest"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleReset,
              setTouched,
              validateForm,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.organisation}>
                        Organisation
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        options={organisationNames}
                        id={fieldNames.organisation}
                        value={values.organisation}
                        onChange={e => setFieldValue(fieldNames.organisation, e)}
                        placeholder="Select Organisation"
                      />
                      {errors.organisation && touched.organisation ? (
                        <ErrorMessage>{errors.organisation}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.warehouse}>
                        Warehouse
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        id={fieldNames.warehouse}
                        value={values.warehouse}
                        options={warehouseNames}
                        onChange={e => setFieldValue(fieldNames.warehouse, e)}
                        placeholder="Select Warehouse"
                      />
                      {errors.warehouse && touched.warehouse ? (
                        <ErrorMessage>{errors.warehouse}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.courier_service}>
                        Courier
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        options={courierServiceNames}
                        id={fieldNames.courier_service}
                        value={values.courier_service}
                        onChange={e => {
                          setFieldValue(fieldNames.courier_service, e);
                        }}
                        placeholder="Select Courier"
                      />
                      {errors.courier_service && touched.courier_service ? (
                        <ErrorMessage>{errors.courier_service}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <ToolTip
                        message={"Select how the stock will <br/> arrive into the warehouse "}
                        htmlFor={fieldNames.logistics_type}
                        title={"Logistics Type *"}
                      />
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        id={fieldNames.logistics_type}
                        value={values.logistics_type}
                        options={logisticsTypeNames}
                        onChange={e => setFieldValue(fieldNames.logistics_type, e)}
                        placeholder="Select Logistics Type"
                      />
                      {errors.logistics_type && touched.logistics_type ? (
                        <ErrorMessage>{errors.logistics_type}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.expected_arrival_date}>
                        Expected Arrival Date
                      </Label>
                      <DateInput
                        id={fieldNames.expected_arrival_date}
                        type="date"
                        value={values.expected_arrival_date}
                        onChange={handleChange}
                      />
                      {errors.expected_arrival_date && touched.expected_arrival_date ? (
                        <ErrorMessage>{errors.expected_arrival_date}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  {!isAddingManifestLines && (
                    <ButtonWrapper>
                      <SecondaryButton
                        className={WMSButtonGroup({ type: "smallMargin" })}
                        type="button"
                        appearance="whiteButtonBlueText"
                        onClick={async () => {
                          setTouched({
                            warehouse: true,
                            organisation: true,
                            expected_arrival_date: true,
                            logistics_type: true,
                            courier_service: true,
                          });

                          const formErrors = await validateForm();

                          if (Object.keys(formErrors).length === 0) {
                            setInitialValues({ ...values, manifest_lines: [emptyManifestLine] });
                            setIsAddingManifestLines(true);
                          }
                        }}
                      >
                        <IconText text={"Add Manifest Lines"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}
                  {isAddingManifestLines && (
                    <>
                      <PrimaryLineWrapper>
                        <ToolTip
                          message={
                            "Provide details for the expectation <br/> that you wish to create"
                          }
                          title={"Manifest Lines"}
                          smallTitleVariant
                        />
                        {values.manifest_lines.map((value, index) => {
                          return (
                            <ManifestLine
                              baseProductData={baseProducts}
                              values={value}
                              errors={
                                errors.manifest_lines as FormikErrors<ManifestLineInterface[]>
                              }
                              touched={touched.manifest_lines}
                              index={index}
                              setFieldValue={setFieldValue}
                              handleRemoveManifestLine={(index: number) => {
                                const fitleredManifestLines = values.manifest_lines.filter(
                                  (item, i) => i !== index
                                );
                                const newValues = { ...values };
                                newValues.manifest_lines = fitleredManifestLines;
                                if (fitleredManifestLines.length === 0) {
                                  setInitialValues(newValues);
                                  setIsAddingManifestLines(false);
                                }
                                setInitialValues(newValues);
                              }}
                            />
                          );
                        })}

                        <ButtonWrapper>
                          <SecondaryButton
                            className={WMSButtonGroup({ type: "smallMargin" })}
                            type="button"
                            appearance="whiteButtonBlueText"
                            onClick={() => {
                              setInitialValues({
                                ...values,
                                manifest_lines: [...values.manifest_lines, emptyManifestLine],
                              });
                            }}
                          >
                            <IconText
                              text={"Add Manifest Line"}
                              primaryIcon={"alert-add-outline"}
                            />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </PrimaryLineWrapper>
                      <DataPoint fullWidth={true}>
                        <Label htmlFor={fieldNames.notes}>Note</Label>
                        <InputField
                          id={fieldNames.notes}
                          size={"medium"}
                          type={"text"}
                          value={values.notes}
                          placeholder={"Add a note here"}
                          handleChange={e => setFieldValue(fieldNames.notes, e.target.value)}
                        />
                      </DataPoint>

                      <FormButtonPair
                        handleReset={() => {
                          handleReset();
                          setInitialValues({ ...initialFieldValues });
                          setIsAddingManifestLines(false);
                        }}
                      />
                    </>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createInboundManifestState: state.createInboundManifestReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createInboundManifest: (body: CreateInboundManifestBody) =>
      dispatch(createInboundManifestAction(body)),
    resetCreateInboundManifest: () => dispatch(createInboundManifestReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInboundManifest);
