import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { recipeRequestAPI } from "../../api/recipeRequestAPI";
import { RecipeRequestBody } from "../../api/types";
import {
  RECIPE_REQUEST_FAILED,
  RECIPE_REQUEST_LOADING,
  RECIPE_REQUEST_RESET,
  RECIPE_REQUEST_SUCCESS,
} from "../types";

export const recipeRequestLoading = () => {
  return {
    type: RECIPE_REQUEST_LOADING,
  };
};

export const recipeRequestSuccess = () => {
  return {
    type: RECIPE_REQUEST_SUCCESS,
  };
};

export const recipeRequestFailed = () => {
  return {
    type: RECIPE_REQUEST_FAILED,
  };
};

export const recipeRequestReset = () => {
  return {
    type: RECIPE_REQUEST_RESET,
  };
};

export const recipeRequestAction = (body: RecipeRequestBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Recipe", { hideProgressBar: true });
    dispatch(recipeRequestLoading());
    try {
      await recipeRequestAPI(body);
      toast.success("Recipe created successfully.");
      dispatch(recipeRequestSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating recipe.");
      dispatch(recipeRequestFailed());
    }
  };
};
