import { WMS_API } from "../../../../../api/baseConfig";
import { CreateOutboundReturnBody } from "./types/";

export const returnOutboundManifestLineAPI = async (
  returnOutboundManifestLineBody: CreateOutboundReturnBody
) => {
  try {
    return await WMS_API.post(
      `/outbound-manifest-fulfilment-lines/${returnOutboundManifestLineBody.outbound_manifest_fulfilment_line_id}/return`,
      returnOutboundManifestLineBody
    );
  } catch (err) {
    console.log("Error in return outbound manifest Line API call:", err);
    throw err;
  }
};
