import { ApolloError } from "@apollo/client";

import { useGetInventoryByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapInventoryToCSV } from "../mappers/mapInventoryToCSV";

export interface HookResult {
  inventory: any[];
  inventoryError?: ApolloError;
  inventoryLoading: boolean;
  getInventoryByWarehouseId: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number;
}

export const useInventoryByWarehouseId = (): HookResult => {
  const [getInventoryByWarehouseId, { data, error, loading }] =
    useGetInventoryByWarehouseIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    inventory:
      data && Array.isArray(data.vw_inventory_report)
        ? mapInventoryToCSV(data.vw_inventory_report)
        : [],
    inventoryError: error,
    inventoryLoading: loading,
    getInventoryByWarehouseId: async ({ warehouseId }: Options) => {
      getInventoryByWarehouseId({ variables: { warehouseId } });
    },
  };
};
