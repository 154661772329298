export const CREATE_PURCHASE_ORDER_APPROVAL_LOADING = "CREATE_PURCHASE_ORDER_APPROVAL_LOADING";
export const CREATE_PURCHASE_ORDER_APPROVAL_SUCCESS = "CREATE_PURCHASE_ORDER_APPROVAL_SUCCESS";
export const CREATE_PURCHASE_ORDER_APPROVAL_FAILED = "CREATE_PURCHASE_ORDER_APPROVAL_FAILED";
export const CREATE_PURCHASE_ORDER_APPROVAL_RESET = "CREATE_PURCHASE_ORDER_APPROVAL_RESET";

export const MARK_AS_IN_TRANSIT_PURCHASE_ORDER_LOADING =
  "MARK_AS_IN_TRANSIT_PURCHASE_ORDER_LOADING";
export const MARK_AS_IN_TRANSIT_PURCHASE_ORDER_SUCCESS =
  "MARK_AS_IN_TRANSIT_PURCHASE_ORDER_SUCCESS";
export const MARK_AS_IN_TRANSIT_PURCHASE_ORDER_FAILED = "MARK_AS_IN_TRANSIT_PURCHASE_ORDER_FAILED";
export const MARK_AS_IN_TRANSIT_PURCHASE_ORDER_RESET = "MARK_AS_IN_TRANSIT_PURCHASE_ORDER_RESET";

export const MARK_AS_DELIVERED_PURCHASE_ORDER_LOADING = "MARK_AS_DELIVERED_PURCHASE_ORDER_LOADING";
export const MARK_AS_DELIVERED_PURCHASE_ORDER_SUCCESS = "MARK_AS_DELIVERED_PURCHASE_ORDER_SUCCESS";
export const MARK_AS_DELIVERED_PURCHASE_ORDER_FAILED = "MARK_AS_DELIVERED_PURCHASE_ORDER_FAILED";
export const MARK_AS_DELIVERED_PURCHASE_ORDER_RESET = "MARK_AS_DELIVERED_PURCHASE_ORDER_RESET";

export const UPDATE_PURCHASE_ORDER_DELIVERY_DATE_LOADING =
  "UPDATE_PURCHASE_ORDER_DELIVERY_DATE_LOADING";
export const UPDATE_PURCHASE_ORDER_DELIVERY_DATE_SUCCESS =
  "UPDATE_PURCHASE_ORDER_DELIVERY_DATE_SUCCESS";
export const UPDATE_PURCHASE_ORDER_DELIVERY_DATE_FAILED =
  "UPDATE_PURCHASE_ORDER_DELIVERY_DATE_FAILED";
export const UPDATE_PURCHASE_ORDER_DELIVERY_DATE_RESET =
  "UPDATE_PURCHASE_ORDER_DELIVERY_DATE_RESET";

export const ATTACH_INVOICES_TO_PURCHASE_ORDER_FAILED = "ATTACH_INVOICES_TO_PURCHASE_ORDER_FAILED";
export const ATTACH_INVOICES_TO_PURCHASE_ORDER_LOADING =
  "ATTACH_INVOICES_TO_PURCHASE_ORDER_LOADING";
export const ATTACH_INVOICES_TO_PURCHASE_ORDER_RESET = "ATTACH_INVOICES_TO_PURCHASE_ORDER_RESET";
export const ATTACH_INVOICES_TO_PURCHASE_ORDER_SUCCESS =
  "ATTACH_INVOICES_TO_PURCHASE_ORDER_SUCCESS";

export const GET_USERS_LOADING = "GET_USERS_LOADING";
export const GET_USERS_SUCCESS = "GET_USERS_SUCCESS";
export const GET_USERS_FAILED = "GET_USERS_FAILED";
export const GET_USERS_RESET = "GET_USERS_RESET";
