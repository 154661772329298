import { createHash } from "crypto";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useState } from "react";

import { ClientAddress, ClientContact, ClientOrderContact } from "../api/createOrder/types";
import "./AddressSelector.css";

interface AddressSelectorProps {
  addresses: ClientOrderContact[];
  setFieldValue: any;
  onSelectContact: (setFieldValue: any, contact: ClientContact) => void;
  onSelectShipping: (setFieldValue: any, shipping: ClientAddress, delivery_notes: string) => void;
  onSelectBilling: (setFieldValue: any, billing: ClientAddress) => void;
}

const AddressSelector: React.FC<AddressSelectorProps> = ({
  addresses,
  setFieldValue,
  onSelectContact,
  onSelectBilling,
  onSelectShipping,
}) => {
  const [selectedContact, setSelectedContact] = useState<number | null>(null);
  const [selectedShipping, setSelectedShipping] = useState<number | null>(null);
  const [selectedBilling, setSelectedBilling] = useState<number | null>(null);

  const generateHash = (obj: any): string => {
    const str = JSON.stringify(obj);
    const hash = createHash("sha256");
    hash.update(str);
    return hash.digest("hex");
  };

  const deduplicateAddresses = (data: ClientOrderContact[]): ClientOrderContact[] => {
    const uniqueData: ClientOrderContact[] = [];
    const hashes = new Set<string>();
    for (const item of data) {
      const item_to_hash = {
        contact: item.contact,
        shipping: item.shipping,
        billing: item.billing,
        delivery_notes: item.orders[0]?.delivery_notes,
      };
      const hash = generateHash(item_to_hash);
      if (!hashes.has(hash)) {
        uniqueData.push(item);
        hashes.add(hash);
      }
    }
    return uniqueData;
  };

  const uniqueAddresses = deduplicateAddresses(addresses);

  return (
    <div className="address-selector">
      <h3>Select an Address</h3>
      <div className="address-list">
        {uniqueAddresses.map(data => (
          <div key={data.id} className="address-item">
            <div className="order-info">
              <strong>Last Used on: </strong>
              {moment(data.orders[0]?.date_created).format("YYYY-MM-DD") ?? "N/A"}.{" "}
              <strong>Order: </strong> {data.orders[0]?.uuid ?? "N/A"}
            </div>
            <div
              className={`address-section ${selectedContact === data.id ? "selected" : ""}`}
              onClick={() => {
                setSelectedContact(selectedContact === data.id ? null : data.id);
                onSelectContact(setFieldValue, data.contact);
              }}
              onKeyDown={event => {
                if (event.key === "Enter" || event.key === " ") {
                  setSelectedContact(selectedContact === data.id ? null : data.id);
                  onSelectContact(setFieldValue, data.contact);
                }
              }}
              tabIndex={0}
              role="button"
            >
              <p>
                <strong>Contact Information</strong>
              </p>
              <p>
                <strong>Secondary Email:</strong> {data.contact.secondary_email}
              </p>
              <p>
                <strong>Phone:</strong> {data.contact.phone_number}
              </p>
              <p>
                <strong>Tax Number:</strong> {data.contact.tax_number}
              </p>
            </div>
            <div
              className={`address-section ${selectedBilling === data.id ? "selected" : ""}`}
              onClick={() => {
                setSelectedBilling(selectedBilling === data.id ? null : data.id);
                onSelectBilling(setFieldValue, data.billing);
              }}
              onKeyDown={event => {
                if (event.key === "Enter" || event.key === " ") {
                  setSelectedBilling(selectedBilling === data.id ? null : data.id);
                  onSelectBilling(setFieldValue, data.billing);
                }
              }}
              tabIndex={0}
              role="button"
            >
              <p>
                <strong>Billing Address</strong>
              </p>
              <p>
                {data.billing.first_name} {data.billing.last_name}
              </p>
              <p>{data.billing.company_name}</p>
              <p>{data.billing.address_line_1}</p>
              {data.billing.address_line_2 && <p>{data.billing.address_line_2}</p>}
              {data.billing.address_line_3 && <p>{data.billing.address_line_3}</p>}
              <p>{data.billing.city}</p>
              <p>
                {data.billing.region} {data.billing.postcode}
              </p>
              <p>{data.billing.country_code}</p>
            </div>
            <div
              className={`address-section ${selectedShipping === data.id ? "selected" : ""}`}
              onClick={() => {
                setSelectedShipping(selectedShipping === data.id ? null : data.id);
                onSelectShipping(
                  setFieldValue,
                  data.shipping,
                  data.orders[0]?.delivery_notes ?? ""
                );
              }}
              onKeyDown={event => {
                if (event.key === "Enter" || event.key === "") {
                  setSelectedShipping(selectedShipping === data.id ? null : data.id);
                  onSelectShipping(
                    setFieldValue,
                    data.shipping,
                    data.orders[0]?.delivery_notes ?? ""
                  );
                }
              }}
              tabIndex={0}
              role="button"
            >
              {generateHash(data.billing) === generateHash(data.shipping) ? (
                <p>
                  <strong>Shipping Address</strong> (Same as Billing)
                </p>
              ) : (
                <p>
                  <strong>Shipping Address</strong>
                </p>
              )}
              <p>
                {data.shipping.first_name} {data.shipping.last_name}
              </p>
              <p>{data.shipping.company_name}</p>
              <p>{data.shipping.address_line_1}</p>
              {data.shipping.address_line_2 && <p>{data.shipping.address_line_2}</p>}
              {data.shipping.address_line_3 && <p>{data.shipping.address_line_3}</p>}
              <p>{data.shipping.city}</p>
              <p>
                {data.shipping.region} {data.shipping.postcode}
              </p>
              <p>{data.shipping.country_code}</p>
              <p>
                <strong>Delivery Notes:</strong> {data.orders[0]?.delivery_notes ?? ""}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

AddressSelector.propTypes = {
  addresses: PropTypes.array.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  onSelectContact: PropTypes.func.isRequired,
  onSelectShipping: PropTypes.func.isRequired,
  onSelectBilling: PropTypes.func.isRequired,
};

export default AddressSelector;
