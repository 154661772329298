import {
  BaseProductVersion,
  ContentAttribute,
  PhysicalAttribute,
  ValueEnum,
} from "../../shared/types";

const mapValueEnums = (valueEnums: any[], enumType: string): ValueEnum[] => {
  const mappedValueEnums: ValueEnum[] = valueEnums.map((valueEnum: any) => {
    if (enumType === "size") {
      const sizeEnum: ValueEnum = {
        id: valueEnum.id,
        unit: valueEnum.unit,
        width: valueEnum.width,
        height: valueEnum.height,
        length: valueEnum.length,
      };

      return sizeEnum;
    }

    const basicEnum: ValueEnum = {
      id: valueEnum.id,
      value: valueEnum.value,
    };

    return basicEnum;
  });

  return mappedValueEnums;
};

const mapContentAttributes = (contentAttributesResponse: any): ContentAttribute[] => {
  if (
    !contentAttributesResponse ||
    !contentAttributesResponse.hasOwnProperty("jsonSchema") ||
    !contentAttributesResponse["jsonSchema"].hasOwnProperty("properties")
  ) {
    return [];
  }

  const propertyKeys = Object.keys(contentAttributesResponse["jsonSchema"]["properties"]);
  const properties = contentAttributesResponse["jsonSchema"]["properties"];

  return propertyKeys.map(key => {
    const contentAttribute: ContentAttribute = {
      id: properties[key]["$id"],
      enum: mapValueEnums(properties[key]["enum"], properties[key]["type"]),
      type: properties[key]["type"],
      title: properties[key]["title"],
    };

    return contentAttribute;
  });
};

const mapPhysicalAttributes = (physicalAttributesResponse: any): PhysicalAttribute[] => {
  if (
    !physicalAttributesResponse ||
    !physicalAttributesResponse.hasOwnProperty("physical_properties")
  ) {
    return [];
  }

  const propertyKeys = Object.keys(physicalAttributesResponse["physical_properties"]);
  const properties = physicalAttributesResponse["physical_properties"];

  return propertyKeys.map(key => {
    const physicalAttribute: PhysicalAttribute = {
      id: properties[key]["$id"],
      enum: mapValueEnums(properties[key]["enum"], properties[key]["type"]),
      type: properties[key]["type"],
      title: properties[key]["title"],
    };

    return physicalAttribute;
  });
};

export const mapResponseToBaseProductVersion = (response: unknown[]): BaseProductVersion[] => {
  const baseProductVersions: BaseProductVersion[] = [];

  response.forEach((item: any) => {
    const baseProductVersion: BaseProductVersion = {
      id: item.id,
      version: item.version_id,
      isCurrentVersion: item.is_current_version,
      contentAttributes: mapContentAttributes(item.content_attributes),
      physicalAttributes: mapPhysicalAttributes(item.physical_attributes),
    };

    baseProductVersions.push(baseProductVersion);
  });

  return baseProductVersions;
};
