import dayjs from "dayjs";
import React, { useEffect, useState } from "react";
import {
  Column,
  ColumnInstance,
  IdType,
  Row,
  TableState,
  useFilters,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";

import { styled } from "@sourceful/shared-components";

import { ArrayElement } from "../../util/interfaces";
import { FilterToggles, FilterTogglesProps } from "./FilterToggles";
import Pagination from "./Pagination";
import SortIcon from "./SortIcon";
import UtilityBar, { AddRowProps } from "./TableUtilityBar";
import { StyledTD, StyledTH, TableStyles } from "./tableStyles";

const FilterTogglesWrapper = styled("div", {
  marginTop: "50px",
});

const getColWidth = <T extends object>(column: ColumnInstance<T>) => {
  return column.collapse ? "0.0000000001%" : column.widthPercent ? `${column.widthPercent}%` : "1%";
};

const getHiddenCols = <T extends object>(columns: ColumnInstance<T>[]) => {
  return columns
    .filter(column => column.isHidden)
    .map(column => column.id ?? (column.Header as IdType<T>));
};

export interface ReactTableProps<T extends object = { Header: string }> {
  columns: Column<T>[];
  data: T[];
  isSortingDisabled?: boolean;
  renderFilters?: any;
  pageSizes?: number[];
  rowAddOptions?: AddRowProps;
  topbarComponents?: JSX.Element;
  isMinWidthDisabled?: boolean;
  initialState?: Partial<TableState<T>>;
  queryPagination?: {
    pageIndex: number;
    setter: (newPageNumber: number) => void;
  };
  isLoading?: boolean;
  filterToggles?: FilterTogglesProps["filters"];
  hideUtilityBar?: boolean;
  children?: React.ReactNode;
}

export interface UniqueColumnData {
  column: ArrayElement<ReactTableProps["columns"]>["Header"];
  accessorType?: "date";
  data: any[];
}

interface ExtendedColumnInstance<T extends object = {}> extends ColumnInstance<T> {
  previewLongColumn?: boolean;
}

function Table<T extends object = { Header: string }>({
  columns,
  data,
  renderFilters,
  rowAddOptions,
  initialState,
  queryPagination,
  isLoading,
  filterToggles,
  hideUtilityBar,
  children,
}: ReactTableProps<T>) {
  const [searchTerm, setSearchTerm] = useState("");

  const filteredData = React.useMemo(
    () =>
      data.filter(row => {
        for (let col of columns) {
          const accessor = col?.accessor;

          // @ts-ignore
          const value = typeof accessor === "function" ? accessor(row) : row[accessor];
          const searchTermLowerCase = searchTerm.toLowerCase();
          if (value && value.toString().toLowerCase().includes(searchTermLowerCase)) return true;
        }

        return false;
      }),
    [searchTerm, data, columns]
  );

  //Type correctly
  const filterTypes = React.useMemo(
    () => ({
      // RECOMMENDED
      arraySome: (rows: any, ids: any, filterValue: any) => {
        /* 
          rows: array of full table rows, each as an array of all its params 
          ids: the IDs of the columns that have been selected as filters
          filterValue: the values that have been selected as filters
        */
        rows = rows.filter((row: any) => {
          return ids.some((id: any) => {
            let rowValue = row.values[id];

            const rowDate = dayjs(rowValue);
            if (rowDate.isValid()) rowValue = rowDate.format("DD-MM-YYYY");

            return filterValue.some((value: any) => {
              const valueDate = dayjs(value);
              if (valueDate.isValid()) value = valueDate.format("DD-MM-YYYY");

              return String(rowValue).includes(String(value));
            });
          });
        });
        return rows;
      },
      multiRow: (rows: Row<T>[], columnIds: string[], filterValue: string[]) => {
        let r = [...rows];
        columnIds.forEach(columnId => {
          r = r.filter(row => filterValue.includes(row.values[columnId]));
        });
        return r;
      },
    }),
    []
  );

  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    setAllFilters,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    state: { pageIndex },
  } = useTable(
    {
      columns,
      data: searchTerm ? filteredData : data,
      autoResetGlobalFilter: false,
      autoResetFilters: false,
      filterTypes: filterTypes,
      hideUtilityBar,
      initialState: {
        pageIndex: queryPagination?.pageIndex ?? 0,
        hiddenColumns: getHiddenCols(columns as ColumnInstance<T>[]),
        ...initialState,
      },
    },
    useFilters,
    useSortBy,
    usePagination
  );

  useEffect(() => {
    if (queryPagination?.pageIndex !== undefined) {
      gotoPage(queryPagination?.pageIndex);
    }
  }, [queryPagination?.pageIndex, gotoPage]);

  const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);

  const hasQueryPagination = queryPagination?.setter;

  const queryPaginationSetter = hasQueryPagination
    ? (index: number) => queryPagination?.setter(index + 1)
    : null;

  const UniqueColumnsData = React.useMemo(
    () =>
      columns
        .filter(col => !col.disableFilters)
        .map(col => {
          let uniqueData: any[] = [];

          let isDate = false;

          for (let row of data) {
            const accessor = col?.accessor;
            // @ts-ignore
            const value = typeof accessor === "function" ? accessor(row) : row[accessor];
            if (Array.isArray(value)) {
              value.forEach(v => {
                if (v && uniqueData.indexOf(v.toString()) === -1) uniqueData.push(v.toString());
              });
            } else {
              if (value && uniqueData.indexOf(value.toString()) === -1)
                uniqueData.push(value.toString());
              if (value instanceof Date) isDate = true;
            }
          }

          return {
            column: col.Header,
            accessorType: isDate ? "date" : undefined,
            data: uniqueData,
          } as UniqueColumnData;
        }),
    [data, columns]
  );

  // Render the UI for your table
  return (
    <TableStyles>
      {!hideUtilityBar && (
        <UtilityBar
          handleSearch={handleSearch}
          searchTerm={searchTerm}
          renderFilters={renderFilters}
          setAllFilters={setAllFilters}
          rowAddOptions={rowAddOptions}
          UniqueColumnsData={UniqueColumnsData}
          gotoLastPage={() => gotoPage(pageCount - 1)}
        >
          {children}
        </UtilityBar>
      )}
      <FilterTogglesWrapper>
        {filterToggles && <FilterToggles filters={filterToggles} />}
      </FilterTogglesWrapper>
      <div className="tableWrap">
        <table {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <StyledTH
                    {...column.getHeaderProps({ ...column.getSortByToggleProps() })}
                    css={{ width: getColWidth(column) }}
                  >
                    <div className="th-wrapper">
                      {column.render("Header")}
                      <SortIcon column={column} />
                    </div>
                  </StyledTH>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {isLoading && (
              <tr>
                <StyledTD colSpan={headerGroups.length ? headerGroups[0].headers.length : 1}>
                  Loading
                </StyledTD>
              </tr>
            )}

            {!isLoading && page.length < 1 && (
              <tr>
                <StyledTD colSpan={headerGroups.length ? headerGroups[0].headers.length : 1}>
                  No Rows
                </StyledTD>
              </tr>
            )}

            {page.map(row => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map(cell => {
                    const preview = (cell.column as ExtendedColumnInstance<T>).previewLongColumn;

                    return (
                      <StyledTD {...cell.getCellProps()} css={{ width: getColWidth(cell.column) }}>
                        <div className={preview ? "has-preview-tooltip" : ""}>
                          {cell.render("Cell")}
                          {preview && (
                            <span>
                              {Array.isArray(cell.value) && cell.value.length > 1 ? (
                                <>
                                  {cell.value.map(cellItem => (
                                    <div key={cellItem.toString()}>{cellItem}</div>
                                  ))}
                                </>
                              ) : (
                                cell.value
                              )}
                            </span>
                          )}
                        </div>
                      </StyledTD>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {pageCount > 1 && (
        <Pagination
          nextPage={queryPaginationSetter || nextPage}
          previousPage={queryPaginationSetter || previousPage}
          gotoPage={queryPaginationSetter || gotoPage}
          pageCount={pageCount}
          pageIndex={pageIndex}
          canNextPage={canNextPage}
          canPreviousPage={canPreviousPage}
          pageOptions={pageOptions}
        />
      )}
    </TableStyles>
  );
}

export default Table;
