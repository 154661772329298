import { ORDER_SERVICE_API } from "../../../../../api/baseConfig";

export const checkStateThresholdApi = async (us_state: string) => {
  try {
    return await ORDER_SERVICE_API.get(`/us-threshold`, {
      params: {
        us_state: us_state,
      },
    });
  } catch (err) {
    console.log("Error in get user details api call", err);
    throw err;
  }
};
