import { Formik, FormikState } from "formik";
import { useEffect } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { CheckBox, InputField } from "@sourceful/shared-components";

import { StoreTypes } from "../../../../../redux/store/storeTypes";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import TextArea from "../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import {
  ButtonPositioner,
  DataPoint,
  Label,
  SecondaryLineWrapper,
  StyledForm,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { SecondaryLineDataWrapper } from "../../../../../styles/SharedStyles";
import { FulfilInboundManifestLineBody } from "../api/types";
import { InboundManifestFulfilmentLineForm, fieldNames, initialFieldValues } from "../formValues";
import { useInboundCaseProductsByBaseProductId } from "../graphql/hooks/useInboundCaseProductsByBaseProductId";
import { useInboundStockConditionNames } from "../graphql/hooks/useInboundStockConditionNames";
import { useLocationNamesThatCanHoldStockByWarehouseId } from "../graphql/hooks/useLocationNamesThatCanHoldStockByWarehouseId";
import {
  fulfilInboundManifestLine,
  fulfilInboundManifestLineReset,
} from "../redux/actions/fulfilInboundManifestLineAction";
import { IBMFulfillerProps } from "../types";
import { validationSchema } from "../validation";

const FulfilInboundManifestLine = ({
  inbound_manifest_line,
  setIBMLineToFulfil,
  warehouse_id,
  inbound_manifest_id,
  fulfilIBMLineState,
  fulfilIBMLine,
  fulfilInboundManifestLineReset,
  refetchInboundManifest,
  total_base_product_quantity,
}: IBMFulfillerProps) => {
  const { caseProducts } = useInboundCaseProductsByBaseProductId(
    inbound_manifest_line.product.base_product_id!
  );
  const { stockConditionNames } = useInboundStockConditionNames();
  const { locationNames } = useLocationNamesThatCanHoldStockByWarehouseId(warehouse_id);

  useEffect(() => {
    if (fulfilIBMLineState.success) {
      setIBMLineToFulfil(null);
      refetchInboundManifest();
      fulfilInboundManifestLineReset();
    }
    if (fulfilIBMLineState.error) {
      fulfilInboundManifestLineReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilIBMLineState.success, fulfilIBMLineState.error]);

  const onSubmit = (
    values: InboundManifestFulfilmentLineForm,
    {
      resetForm,
    }: {
      resetForm: (nextState?: Partial<FormikState<InboundManifestFulfilmentLineForm>>) => void;
    }
  ) => {
    const fulfilmentLine: FulfilInboundManifestLineBody = {
      inbound_manifest_id: inbound_manifest_id,
      inbound_manifest_line_id: inbound_manifest_line.id,
      quantity: Number(values.quantity),
      location_id: values.location?.value!,
      product_id: values.product?.value!,
      condition_id: values.condition?.value!,
      is_file_copy: values.is_file_copy,
      notes: values.notes,
    };

    fulfilIBMLine(fulfilmentLine);

    resetForm();
  };

  return (
    <Formik
      initialValues={initialFieldValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        let productQuantity =
          caseProducts.find(product => product.id === values.product?.value)
            ?.base_product_quantity ?? 1;

        return (
          <StyledForm onSubmit={handleSubmit}>
            <SecondaryLineWrapper>
              <SecondaryLineDataWrapper>
                <DataPoint>
                  <Label isRequired htmlFor={fieldNames.product}>
                    Product
                  </Label>
                  <Select
                    placeholder="Select Product To Fulfil Line"
                    maxMenuHeight={220}
                    isSearchable={true}
                    options={caseProducts.map(product => {
                      return {
                        label: product.product_name,
                        value: product.id,
                      };
                    })}
                    value={values.product}
                    id={fieldNames.product}
                    onChange={e => setFieldValue(fieldNames.product, e)}
                    styles={reactSelectStyling}
                  />
                  {errors.product && touched.product ? (
                    <ErrorMessage>{errors.product}</ErrorMessage>
                  ) : null}
                </DataPoint>
                <DataPoint>
                  <Label isRequired>Quantity</Label>
                  <InputField
                    type="number"
                    size="medium"
                    value={values.quantity}
                    placeholder={"Input quantity"}
                    handleChange={e => setFieldValue(fieldNames.quantity, e.target.value)}
                  />
                  {errors.quantity && touched.quantity ? (
                    <ErrorMessage>{errors.quantity}</ErrorMessage>
                  ) : null}
                </DataPoint>
                <DisplayBox
                  label={"Total Quantity"}
                  unknownString={"Please select a Product"}
                  value={values.product ? productQuantity * Number(values.quantity) : undefined}
                />
                <DataPoint>
                  <Label htmlFor={fieldNames.is_file_copy}>File Copy</Label>
                  <CheckBox
                    handleChange={e => setFieldValue(fieldNames.is_file_copy, e.target.checked)}
                    value={fieldNames.is_file_copy}
                    id={fieldNames.is_file_copy}
                    label={"Yes"}
                    isChecked={values.is_file_copy}
                  />
                  {errors.is_file_copy && touched.is_file_copy ? (
                    <ErrorMessage>{errors.is_file_copy}</ErrorMessage>
                  ) : null}
                </DataPoint>
                <DataPoint>
                  <Label isRequired>Location </Label>
                  <Select
                    placeholder="Select Location To Fulfil Line"
                    maxMenuHeight={220}
                    isSearchable={true}
                    options={locationNames}
                    searchable={true}
                    value={values.location}
                    onChange={e => setFieldValue(fieldNames.location, e)}
                    styles={reactSelectStyling}
                  />
                  {errors.location && touched.location ? (
                    <ErrorMessage>{errors.location}</ErrorMessage>
                  ) : null}
                </DataPoint>
                <DataPoint>
                  <Label isRequired>Condition</Label>
                  <Select
                    placeholder="Select Condition"
                    maxMenuHeight={220}
                    isSearchable={true}
                    options={stockConditionNames}
                    searchable={true}
                    value={values.condition}
                    onChange={e => setFieldValue(fieldNames.condition, e)}
                    styles={reactSelectStyling}
                  />
                  {errors.condition && touched.condition ? (
                    <ErrorMessage>{errors.condition}</ErrorMessage>
                  ) : null}
                </DataPoint>

                <DataPoint fullWidth>
                  <Label htmlFor={fieldNames.notes}>Notes</Label>
                  <TextArea
                    id={fieldNames.notes}
                    size={"medium"}
                    type={"text"}
                    value={values.notes}
                    placeholder={"Enter additional information"}
                    handleChange={e => {
                      setFieldValue(fieldNames.notes, e.target.value);
                    }}
                  />
                </DataPoint>
              </SecondaryLineDataWrapper>
              {productQuantity * Number(values.quantity) > total_base_product_quantity && (
                <ButtonPositioner fullWidth>
                  <ErrorMessage>
                    You are overfulfilling this line. Is this your intended action?
                  </ErrorMessage>
                </ButtonPositioner>
              )}
              <FormButtonPair confirmCopy="Fulfil" handleReset={() => setIBMLineToFulfil(null)} />
            </SecondaryLineWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    fulfilIBMLineState: state.fulfilInboundManifestLineReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    fulfilIBMLine: (body: FulfilInboundManifestLineBody) =>
      dispatch(fulfilInboundManifestLine(body)),
    fulfilInboundManifestLineReset: () => dispatch(fulfilInboundManifestLineReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfilInboundManifestLine);
