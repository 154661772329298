import { datadogLogs } from "@datadog/browser-logs";
import { datadogRum } from "@datadog/browser-rum";
import * as Sentry from "@sentry/react";
import LogRocket from "logrocket";

import getRuntimeEnv from "./getRuntimeEnv";

export const setupMonitoring = () => {
  const DATADOG_APPLICATION_ID = getRuntimeEnv("REACT_APP_DATADOG_APPLICATION_ID");
  const DATADOG_CLIENT_TOKEN = getRuntimeEnv("REACT_APP_DATADOG_CLIENT_TOKEN");
  const DATADOG_SITE = getRuntimeEnv("REACT_APP_DATADOG_SITE");
  const DATADOG_SERVICE = getRuntimeEnv("REACT_APP_DATADOG_SERVICE");
  const DATADOG_ENVIRONMENT = getRuntimeEnv("REACT_APP_DATADOG_ENVIRONMENT");
  const LOGROCKET_URL = getRuntimeEnv("REACT_APP_LOGROCKET_URL");
  const SENTRY_URL = getRuntimeEnv("REACT_APP_SENTRY_URL");
  const SENTRY_ENVIRONMENT = getRuntimeEnv("REACT_APP_ENVIRONMENT");
  const VERSION_SHA = getRuntimeEnv("REACT_APP_VERSION_SHA");
  //eslint-disable-next-line @typescript-eslint/no-unused-vars
  const API_BASE = getRuntimeEnv("REACT_APP_API_ORIGIN");

  LogRocket.init(LOGROCKET_URL);

  Sentry.init({
    dsn: SENTRY_URL,
    environment: SENTRY_ENVIRONMENT,
  });

  LogRocket.getSessionURL(sessionURL => {
    Sentry.configureScope(scope => {
      scope.setExtra("sessionURL", sessionURL);
    });
  });

  datadogRum.init({
    applicationId: DATADOG_APPLICATION_ID,
    clientToken: DATADOG_CLIENT_TOKEN,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: DATADOG_ENVIRONMENT,

    // Specify a version number to identify the deployed version of your application in Datadog
    version: VERSION_SHA,
    sessionSampleRate: 100,
    sessionReplaySampleRate: 100,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    trackFrustrations: true,
    defaultPrivacyLevel: "mask-user-input",
  });

  datadogLogs.init({
    version: VERSION_SHA,
    clientToken: DATADOG_APPLICATION_ID,
    site: DATADOG_SITE,
    service: DATADOG_SERVICE,
    env: DATADOG_ENVIRONMENT,
    forwardErrorsToLogs: true,
    sessionSampleRate: 100,
    forwardConsoleLogs: ["log", "warn", "error"],
  });

  datadogRum.startSessionReplayRecording();
};
