import * as Yup from "yup";

import {
  STOCK_MANAGEMENT_COUNTRY_CODES,
  STOCK_MANAGEMENT_CURRENCIES,
  STOCK_MANAGEMENT_LOCALES,
} from "../../../../../shared/constants";

export const addAddressValidationSchema = Yup.object({
  isWarehouse: Yup.boolean().required("Is warehouse is required"),
  isInternal: Yup.boolean().required("Is internal is required"),
  addressName: Yup.string().required("Address name is required"),
  addressDescription: Yup.string().required("Address description is required."),
  addressLine1: Yup.string().required("Address line 1 is required"),
  addressLine2: Yup.string().optional(),
  addressLine3: Yup.string().optional(),
  city: Yup.string().required("City is required"),
  postcode: Yup.string().required("Postcode is required"),
  countryCode: Yup.object()
    .shape({
      label: Yup.string().oneOf(STOCK_MANAGEMENT_COUNTRY_CODES),
      value: Yup.number(),
    })
    .required("Country code is required")
    .nullable(),
  region: Yup.string().optional().required("Region is required"),
  deliveryTimelineMin: Yup.number().required("Delivery timeline: minimum is required"),
  deliveryTimelineMax: Yup.number()
    .required("Delivery timeline: maximum is required")
    .when("deliveryTimelineMin", (deliveryTimelineMin: number, schema: any) => {
      return schema.test({
        test: (deliveryTimelineMax: number) => {
          return deliveryTimelineMax > deliveryTimelineMin;
        },
        message: "Delivery timeline: maximum must be greater than minimum",
      });
    }),
  deliveryTimelineUnits: Yup.string().required("Delivery timeline units is required"),
  deliveryTimelineBusinessDaysOnly: Yup.boolean().required(
    "Delivery timeline business days only is required"
  ),
  primaryContactId: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number(),
    })
    .required("Primary contact is required")
    .nullable(),
});

export const updateAddressValidationSchema = Yup.object({
  isWarehouse: Yup.boolean().optional(),
  isInternal: Yup.boolean().optional(),
  addressName: Yup.string().optional(),
  addressDescription: Yup.string().optional(),
  addressLine1: Yup.string().optional(),
  addressLine2: Yup.string().optional().nullable(),
  addressLine3: Yup.string().optional().nullable(),
  city: Yup.string().optional(),
  postcode: Yup.string().optional(),
  countryCode: Yup.object()
    .shape({
      label: Yup.string().oneOf(STOCK_MANAGEMENT_COUNTRY_CODES),
      value: Yup.number(),
    })
    .optional()
    .nullable(),
  region: Yup.string().optional().optional(),
  deliveryTimelineMin: Yup.number().optional(),
  deliveryTimelineMax: Yup.number()
    .optional()
    .when("deliveryTimelineMin", (deliveryTimelineMin: number, schema: any) => {
      return schema.test({
        test: (deliveryTimelineMax: number) => {
          return deliveryTimelineMax > deliveryTimelineMin;
        },
        message: "Delivery timeline: maximum must be greater than minimum",
      });
    }),
  deliveryTimelineUnits: Yup.string().optional(),
  deliveryTimelineBusinessDaysOnly: Yup.boolean().optional(),
  primaryContactId: Yup.object()
    .shape({
      label: Yup.string(),
      value: Yup.number(),
    })
    .required("Primary contact is required")
    .nullable(),
});

export const addContactValidationSchema = Yup.object({
  firstName: Yup.string().required("First name is required"),
  lastName: Yup.string().required("Last name is required"),
  emailAddress: Yup.string().email("Invalid email address").required("Email address is required"),
  phoneNumber: Yup.string().required("Phone number is required"),
  jobTitle: Yup.string().required("Job title is required"),
  preferredCommunicationMethodId: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .required("Preferred communication method is required")
    .nullable(),
  addressId: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .optional()
    .nullable(),
});

export const updateContactValidationSchema = Yup.object({
  firstName: Yup.string().optional(),
  lastName: Yup.string().optional(),
  emailAddress: Yup.string().email("Invalid email address").optional(),
  phoneNumber: Yup.string().optional().nullable(),
  jobTitle: Yup.string().optional(),
  preferredCommunicationMethodId: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .required("Preferred communication method is required")
    .nullable(),
  addressId: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .optional()
    .nullable(),
});

export const updateOrganisationConfigValidationSchema = Yup.object({
  paymentTerms: Yup.string().optional(),
  paymentDueDate: Yup.object()
    .shape({
      type: Yup.object().shape({
        value: Yup.string().oneOf(["days_after_invoice", "last_day_of_month"]),
      }),
      value: Yup.number(),
    })
    .optional(),
  xeroClientName: Yup.string().optional(),
  orderFlowTypeId: Yup.object().shape({ value: Yup.number(), label: Yup.string() }).optional(),
  autostockEnabled: Yup.boolean().optional(),
  mainProviderType: Yup.string().optional(),
  paymentDisclaimer: Yup.string().optional(),
  productsSourceType: Yup.string().optional(),
  selfserviceEnabled: Yup.boolean().optional(),
  orderProcessingType: Yup.string().optional(),
  productPricingSourceType: Yup.string().optional(),
  deliveryInvoicedSeparately: Yup.boolean().optional(),
  computeDeliveryCostPerProduct: Yup.boolean().optional(),
  enableClientReferenceCollection: Yup.boolean().optional(),
  prepaidStock: Yup.boolean().optional(),
  displayProductUnitCost: Yup.boolean().optional(),
}).optional();

export const updateOrganisationValidationSchema = Yup.object({
  name: Yup.string().optional(),
  locale: Yup.object()
    .shape({
      label: Yup.string().oneOf(STOCK_MANAGEMENT_LOCALES),
      value: Yup.number(),
    })
    .optional(),
  defaultCurrency: Yup.object()
    .shape({
      label: Yup.string().oneOf(STOCK_MANAGEMENT_CURRENCIES),
      value: Yup.number(),
    })
    .optional(),
  externalOrganisationId: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .optional(),
  config: updateOrganisationConfigValidationSchema,
});
