import { GridLoader } from "react-spinners";

function LoadingScreen() {
  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <GridLoader color={"#005AE1"} size={50} />
    </div>
  );
}

export default LoadingScreen;
