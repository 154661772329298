import { ApolloError } from "@apollo/client";

import { useGetOrderPendingDemandQuery } from "../../../../../../generated/graphql_sourcing";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapDemandToClients } from "../../mappers";
import { PendingDemandItem } from "../types";

export interface HookResult {
  pendingDemand: PendingDemandItem[];
  clientOptions: DropdownItem[];
  pendingDemandError?: ApolloError;
  pendingDemandLoading: boolean;
}

export const useOrderPendingDemand = (): HookResult => {
  const { data, error, loading } = useGetOrderPendingDemandQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    pendingDemand: data?.pending_demand || [],
    clientOptions: data ? mapDemandToClients(data.pending_demand) : [],
    pendingDemandError: error,
    pendingDemandLoading: loading,
  };
};
