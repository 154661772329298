import { SOURCING_API } from "../../../../../api/baseConfig";
import { UserName } from "./types";

export const getUsersByRoleAPI = async (role: string) => {
  try {
    const response = await SOURCING_API.get(`/iam/v2/user-roles?role=${role}`);
    const users: UserName[] = response?.data?.users || [];
    return users;
  } catch (err) {
    console.log("Error in getting users by role API call", err);
    throw err;
  }
};
