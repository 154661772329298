import { styled } from "@sourceful/shared-components";

interface AddressItem {
  id: number;
  address_name: string;
  address_line_1: string;
  address_line_2?: string | null;
  address_line_3?: string | null;
  city: string;
  country_code: string;
  postcode: string;
}

const AddressContainer = styled("div", {
  display: "flex",
});

const AddressLine = styled("p", {
  margin: "0",
});

const AddressLabel = styled("p", {
  width: "130px",
  display: "inline-block",
  fontWeight: "bold",
  margin: "0",
});

export interface Props {
  address: AddressItem;
  title?: string;
}

const AddressBox = ({ address, title }: Props) => (
  <AddressContainer>
    {title && <AddressLabel>{title}: </AddressLabel>}
    <div>
      <AddressLine>{address.address_name},</AddressLine>
      {address.address_line_1 && <AddressLine>{address.address_line_1},</AddressLine>}
      {address.address_line_2 && <AddressLine>{address.address_line_2},</AddressLine>}
      {address.address_line_3 && <AddressLine>{address.address_line_3},</AddressLine>}
      <AddressLine>
        {address.city}, {address.postcode}, {address.country_code}
      </AddressLine>
    </div>
  </AddressContainer>
);

export default AddressBox;
