import { WMS_API } from "../../../../../api/baseConfig";
import { CreateWarehouseBody } from "./types";

export const createWarehouseAPI = async (body: CreateWarehouseBody) => {
  try {
    return await WMS_API.post("/warehouses", body);
  } catch (err) {
    console.log("Error in case change API call", err);
    throw err;
  }
};
