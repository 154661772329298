import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";
import { AddStockManagementAddressBody } from "./types";

export const addStockManagementAddress = async (body: AddStockManagementAddressBody) => {
  try {
    return await STOCK_MANAGEMENT_API.post("/addresses", body);
  } catch (err) {
    console.log("Error in add stock management address to organisation API call", err);
    throw err;
  }
};
