import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

interface CreateAttributeTemplateOptionsBody {
  attribute_template_id: number;
  option_value: {
    [key: string]: string | number;
  };
  option_name: string;
  rfq: boolean;
}

export const createAttributeTemplateOptions = async (
  body: CreateAttributeTemplateOptionsBody[]
) => {
  try {
    const response = await PRODUCT_CATALOGUE_API.post("/attribute/options", body);
    const data = response.data;
    return {
      data: data,
    };
  } catch (err) {
    console.log("Error in creating attribute template options API call", err);
    throw err;
  }
};
