import { IAM_API } from "../../../../../api/baseConfig";

export interface getIAMOrganisationParams {
  orgUuid: string;
  userUuid: string;
}

export const getIAMOrganisationMemberRoles = async (params: getIAMOrganisationParams) => {
  try {
    return await IAM_API.get(`/v2/organisation/${params.orgUuid}/members/${params.userUuid}`, {});
  } catch (err) {
    console.error("Error in organisation member API call", err);
    throw err;
  }
};
