import { SUPPLIER_LOCALES } from "../../../../../shared/constants";

export const localeOptions = SUPPLIER_LOCALES.map((locale, index) => ({
  value: index,
  label: locale,
}));

export const invoiceTypes = [{ value: 2, label: "Taxable" }];

export const deliveryType = [
  { value: 1, label: "Client Address" },
  { value: 2, label: "Sourceful Warehouse" },
  { value: 3, label: "Both client address and Sourceful warehouse" },
];
