import { Formik } from "formik";
import moment from "moment";
import { useState } from "react";
import Select from "react-select";

import { ButtonGroup, Icon } from "@sourceful/shared-components";

import { Report, ReportsCard } from "../../../../../components/ReportsCard/ReportsCard";
import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  FlexHolder,
  IconWrapper,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { CustomReportForm, fieldNames, initialFieldValues } from "../formValues";
import { useCustomReportData } from "../graphql/hooks/useCustomReportData";
import { useLocationTypeNames } from "../graphql/hooks/useCustomReportLocationTypesNames";
import { useOrganisationNames } from "../graphql/hooks/useCustomReportOrganisationNames";
import { useProductNames } from "../graphql/hooks/useCustomReportProductNames";
import { useStockConditionNames } from "../graphql/hooks/useCustomReportStockConditionNames";
import { useWarehouseNames } from "../graphql/hooks/useCustomReportWarehouseNames";
import { mapCustomReportToCSVData } from "../mappers";
import { CustomReportColumns, FilterQueryInterface } from "../types";

const CreateCustomReport = () => {
  const [initialValues, setInitialValues] = useState<CustomReportForm>(initialFieldValues);
  const [isTableExpanded, setIsTableExpanded] = useState<boolean>(false);
  const { organisationNames, organisationNamesLoading, organisationNamesError } =
    useOrganisationNames();
  const { productNames, productNamesLoading, productNamesError } = useProductNames();
  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useWarehouseNames();
  const { stockConditionNames, stockConditionNamesLoading, stockConditionNamesError } =
    useStockConditionNames();
  const { locationTypeNames, locationTypeNamesLoading, locationTypeNamesError } =
    useLocationTypeNames();

  const { customReportData, customReportDataLoading, customReportDataError, getCustomReportData } =
    useCustomReportData();

  const reportData: Report = {
    fileTitle: `custom_report_${moment().format()}`,
    displayTitle: `Download Custom Report Data`,
    data: mapCustomReportToCSVData(customReportData),
  };

  const onSubmit = async (values: CustomReportForm) => {
    const filterData: FilterQueryInterface = {
      locationTypeId: values.location?.value ?? null,
      warehouseId: values.warehouse?.value ?? null,
      productId: values.stock?.value ?? null,
      stockConditionId: values.condition?.value ?? null,
      organisationId: values.organisation?.value ?? null,
    };

    getCustomReportData(filterData);
  };

  const error =
    customReportDataError ||
    organisationNamesError ||
    productNamesError ||
    locationTypeNamesError ||
    stockConditionNamesError ||
    warehouseNamesError;

  const isLoading =
    organisationNamesLoading ||
    productNamesLoading ||
    locationTypeNamesLoading ||
    stockConditionNamesLoading ||
    warehouseNamesLoading ||
    customReportDataLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Inventory - Reports"}>
      <>
        <Panel
          withWrapper
          title={"Create custom stock reports"}
          subtitle={"Query database with specific parameters to generate more granular report"}
        >
          <InnerPanelWrapper>
            <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
              {({ values, setFieldValue, handleReset, handleSubmit, errors, touched }) => {
                const handleClick = () => {
                  handleReset();
                  setIsTableExpanded(false);
                  setInitialValues(initialFieldValues);
                };

                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <TopPanelInputsWrapper>
                      <DataPoint>
                        <FlexHolder>
                          <Label htmlFor={fieldNames.organisation}> Organisation </Label>
                          <IconWrapper
                            type="button"
                            onClick={() => setFieldValue(fieldNames.organisation, "")}
                          >
                            <Icon width={20} height={20} name={"alert-cross-default"} />
                          </IconWrapper>
                        </FlexHolder>
                        <Select
                          id={fieldNames.organisation}
                          styles={reactSelectStyling}
                          value={values.organisation}
                          options={organisationNames}
                          onChange={e => setFieldValue(fieldNames.organisation, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                        />
                        {errors.organisation && touched.organisation ? (
                          <ErrorMessage>{errors.organisation}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                      <DataPoint>
                        <FlexHolder>
                          <Label htmlFor={fieldNames.warehouse}> Warehouse </Label>
                          <IconWrapper
                            type="button"
                            onClick={() => setFieldValue(fieldNames.warehouse, "")}
                          >
                            <Icon width={20} height={20} name={"alert-cross-default"} />
                          </IconWrapper>
                        </FlexHolder>
                        <Select
                          id={fieldNames.warehouse}
                          styles={reactSelectStyling}
                          value={values.warehouse}
                          options={warehouseNames}
                          onChange={e => setFieldValue(fieldNames.warehouse, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                        />
                        {errors.warehouse && touched.warehouse ? (
                          <ErrorMessage>{errors.warehouse}</ErrorMessage>
                        ) : null}
                      </DataPoint>

                      <DataPoint>
                        <FlexHolder>
                          <ToolTip
                            message={"Location within specified warehouse"}
                            title={"Location"}
                            htmlFor={fieldNames.location}
                          />
                          <IconWrapper
                            type="button"
                            onClick={e => setFieldValue(fieldNames.location, "")}
                          >
                            <Icon width={20} height={20} name={"alert-cross-default"} />
                          </IconWrapper>
                        </FlexHolder>
                        <Select
                          id={fieldNames.location}
                          styles={reactSelectStyling}
                          value={values.location}
                          options={locationTypeNames}
                          onChange={e => setFieldValue(fieldNames.location, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                        />
                        {errors.location && touched.location ? (
                          <ErrorMessage>{errors.location}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                      <DataPoint>
                        <FlexHolder>
                          <Label htmlFor={fieldNames.condition}> Condition </Label>
                          <IconWrapper
                            type="button"
                            onClick={e => setFieldValue(fieldNames.condition, "")}
                          >
                            <Icon width={20} height={20} name={"alert-cross-default"} />
                          </IconWrapper>
                        </FlexHolder>
                        <Select
                          id={fieldNames.condition}
                          styles={reactSelectStyling}
                          value={values.condition}
                          options={stockConditionNames}
                          onChange={e => setFieldValue(fieldNames.condition, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                        />
                        {errors.condition && touched.condition ? (
                          <ErrorMessage>{errors.condition}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                      <DataPoint>
                        <FlexHolder>
                          <Label htmlFor={fieldNames.stock}> Stock </Label>
                          <IconWrapper
                            type="button"
                            onClick={e => setFieldValue(fieldNames.stock, "")}
                          >
                            <Icon width={20} height={20} name={"alert-cross-default"} />
                          </IconWrapper>
                        </FlexHolder>
                        <Select
                          id={fieldNames.stock}
                          styles={reactSelectStyling}
                          value={values.stock}
                          options={productNames}
                          onChange={e => setFieldValue(fieldNames.stock, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                        />
                        {errors.stock && touched.stock ? (
                          <ErrorMessage>{errors.stock}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </TopPanelInputsWrapper>

                    <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
                      <SecondaryButton onClick={handleClick} appearance={"whiteButton"}>
                        Cancel
                      </SecondaryButton>
                      <SecondaryButton
                        appearance={"blueButton"}
                        type="submit"
                        onClick={() => {
                          setIsTableExpanded(true);
                        }}
                      >
                        Create
                      </SecondaryButton>
                    </ButtonGroup>
                  </StyledForm>
                );
              }}
            </Formik>
          </InnerPanelWrapper>
        </Panel>

        {reportData && isTableExpanded && (
          <>
            <PrimaryLineWrapper>
              <Title>Generated Report </Title>
              <ReportsCard report={reportData} />
            </PrimaryLineWrapper>
          </>
        )}

        {isTableExpanded && (
          <Panel
            withWrapper
            title={"Custom Stock Report"}
            text={
              "The results of the query can be previewed in the table below. The report generated in the cell above contains the identical data."
            }
            allignTitle="left"
            iconName={"alert-exclamation-outline"}
          >
            <Table
              isLoading={!customReportData && customReportDataLoading}
              data={customReportData}
              columns={CustomReportColumns}
            />
          </Panel>
        )}
      </>
    </Page>
  );
};

export default CreateCustomReport;
