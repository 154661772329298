import { ErrorType } from "../../../../../../../shared/api/errors/errorTypes";
import {
  UPDATE_QUOTATION_COST_TYPE_FAILED,
  UPDATE_QUOTATION_COST_TYPE_LOADING,
  UPDATE_QUOTATION_COST_TYPE_RESET,
  UPDATE_QUOTATION_COST_TYPE_SUCCESS,
} from "../types";

export interface UpdateQuotationCostTypeReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: UpdateQuotationCostTypeReducer = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function updateQuotationCostTypeReducer(
  state: UpdateQuotationCostTypeReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case UPDATE_QUOTATION_COST_TYPE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_QUOTATION_COST_TYPE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case UPDATE_QUOTATION_COST_TYPE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case UPDATE_QUOTATION_COST_TYPE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
