import { DeliveryCosts, DeliveryCostsFormValues } from "../../shared/types";

export const mapProductTypeToDropdownItem = () => {
  return [
    {
      label: "Warehouse",
      value: 1,
    },
    {
      label: "Dropship",
      value: 2,
    },
    {
      label: "Other",
      value: 3,
    },
  ];
};

export const mapDeliveryCostsForForm = (
  deliveryCosts: DeliveryCosts[]
): DeliveryCostsFormValues[] => {
  if (deliveryCosts && deliveryCosts.length > 0) {
    return deliveryCosts.map((deliveryCost, index) => ({
      costPerPackExclVat: deliveryCost.costPerPackExclVat,
      countryCode: {
        label: deliveryCost.countryCode,
        value: index,
      },
      currencyCode: {
        label: deliveryCost.currencyCode,
        value: index,
      },
    }));
  } else {
    return [];
  }
};

export const validDeliveryCosts = (deliveryCosts: DeliveryCostsFormValues[]) => {
  return deliveryCosts.filter(
    deliveryCost => deliveryCost?.countryCode && deliveryCost.currencyCode
  );
};

export const mapDeliveryCostsToSubmitForm = (
  deliveryCosts: DeliveryCostsFormValues[]
): DeliveryCosts[] => {
  const deliveryCostsToSubmit = validDeliveryCosts(deliveryCosts);
  return deliveryCostsToSubmit.map(deliveryCost => ({
    costPerPackExclVat: Number(deliveryCost.costPerPackExclVat),
    countryCode: deliveryCost.countryCode.label,
    currencyCode: deliveryCost.currencyCode.label,
  }));
};
