import { ApolloError } from "@apollo/client";

import { useGetOutboundManifestByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { OutboundManifestItem } from "../types";

export interface HookResult {
  manifest?: OutboundManifestItem;
  manifestError?: ApolloError;
  manifestLoading: boolean;
  refetchOutboundManifest: () => Promise<void>;
}

export const useOutboundManifestById = (manifestId: number): HookResult => {
  const { data, error, loading, refetch } = useGetOutboundManifestByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: { manifestId },
  });

  return {
    manifest: data && Array.isArray(data.outbound_manifest) ? data.outbound_manifest[0] : undefined,
    manifestError: error,
    manifestLoading: loading,
    refetchOutboundManifest: async () => {
      refetch({ manifestId });
    },
  };
};
