import { SOURCING_API } from "../../../../../../api/baseConfig";
import { UpdateQuotationCostTypeBody } from "./types";

export const updateQuotationCostTypeAPI = async ({
  quotation_cost_type_id,
  ...payload
}: UpdateQuotationCostTypeBody) => {
  try {
    return await SOURCING_API.patch(`/quotation-cost-types/${quotation_cost_type_id}`, payload);
  } catch (err) {
    console.log("Error in update quotation cost type API call", err);
    throw err;
  }
};
