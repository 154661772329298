import { IAM_API } from "../../../../../api/baseConfig";

export interface getIAMOrganisationParams {
  orgUUID: string;
}

export const getIAMOrganisationMembers = async (params: getIAMOrganisationParams) => {
  try {
    return await IAM_API.get(`/v2/organisation/${params.orgUUID}/members`, {});
  } catch (err) {
    console.error("Error in get organisation member API call", err);
    throw err;
  }
};
