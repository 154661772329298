import { useHistory } from "react-router-dom";

import { ButtonGroup } from "@sourceful/shared-components";

import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { WMSButtonGroup } from "../../../../../styles/SharedStyles";
import {
  PURCHASE_ORDER_STATUS,
  RegeneratePurchaseOrderProps,
  negativePurchaseOrderStatuses,
} from "../types";

const RegeneratePurchaseOrder = ({ poStatus, poId }: RegeneratePurchaseOrderProps) => {
  const history = useHistory();

  const isNegativePurchaseOrderStatus = () => {
    return poStatus && negativePurchaseOrderStatuses.includes(poStatus as PURCHASE_ORDER_STATUS);
  };

  return (
    <VisibleFor roles={SOURCING_EDIT_ROLES}>
      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
        {isNegativePurchaseOrderStatus() && (
          <SecondaryButton
            type="button"
            onClick={() => history.push(`/purchase-orders/create-purchase-order/${poId}`)}
          >
            Regenerate Purchase Order
          </SecondaryButton>
        )}
      </ButtonGroup>
    </VisibleFor>
  );
};

export default RegeneratePurchaseOrder;
