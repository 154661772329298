import { ApolloError } from "@apollo/client";

import { useGetCustomReportDataLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { CustomReportDataItem } from "../types";

export interface HookResult {
  customReportData: CustomReportDataItem[];
  customReportDataError?: ApolloError;
  customReportDataLoading: boolean;
  getCustomReportData: (options: Options) => Promise<void>;
}

interface Options {
  locationTypeId: number | null;
  warehouseId: number | null;
  productId: number | null;
  stockConditionId: number | null;
  organisationId: number | null;
}

export const useCustomReportData = (): HookResult => {
  const [getCustomReportData, { data, error, loading }] = useGetCustomReportDataLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    customReportData: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    customReportDataError: error,
    customReportDataLoading: loading,
    getCustomReportData: async ({
      locationTypeId,
      warehouseId,
      productId,
      stockConditionId,
      organisationId,
    }: Options) => {
      getCustomReportData({
        variables: { locationTypeId, warehouseId, productId, stockConditionId, organisationId },
      });
    },
  };
};
