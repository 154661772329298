import { styled } from "@sourceful/shared-components";

export const SearchBarInput = styled("input", {
  width: "100%",
  display: "flex",
  alignItems: "center",
  borderRadius: "4px",
  minWidth: "201px",
  boxShadow: "inset 0px 0px 0px 1px #C4C4C4",
  border: "none",
  color: "#C4C4C4",
  padding: "0px 10px",
  fontSize: "11px",
  fontWeight: "400",
  lineHeight: "22.4px",
  letterSpacing: "-2.5%",
  "&::-webkit-input-placeholder": {
    color: "grey",
    fontWeight: "bold",
  },
  backgroundColor: "$palette3_colour1_tint70",
});

export interface SearchBarProps {
  searchPlaceholder: string;
  setSearchTerm: (searchTerm: string) => void;
}

export const SearchBar = ({ searchPlaceholder, setSearchTerm }: SearchBarProps) => {
  return (
    <SearchBarInput
      onChange={e => {
        setSearchTerm(e.target.value);
      }}
      placeholder={searchPlaceholder}
    ></SearchBarInput>
  );
};

export default SearchBar;
