import moment from "moment";

export interface CreateWeeklyStockCheckForm {
  warehouse: {
    value: number;
    label: string;
  } | null;
  stock_interaction_end_date: string;
  due_date: string;
  notes: string;
  stock_interaction_start_date: string;
  additional_base_products: AdditionalBaseProduct[];
}

export interface AdditionalBaseProduct {
  id: number;
  base_product: {
    value: number;
    label: string;
  } | null;
}

export const fieldNames = {
  warehouse: "warehouse",
  notes: "notes",
  due_date: "due_date",
  stock_interaction_start_date: "stock_interaction_start_date",
  stock_interaction_end_date: "stock_interaction_end_date",
};

export const emptyBaseProduct: AdditionalBaseProduct = {
  id: 0,
  base_product: null,
};

const fiveDaysAgo = new Date();
fiveDaysAgo.setDate(fiveDaysAgo.getDate() - 4);

const today = new Date();

export const initialFieldValues: CreateWeeklyStockCheckForm = {
  warehouse: null,
  stock_interaction_end_date: moment(today.toISOString()).format("YYYY-MM-DD"),
  stock_interaction_start_date: moment(fiveDaysAgo.toISOString()).format("YYYY-MM-DD"),
  due_date: "",
  notes: "",
  additional_base_products: [],
};
