import * as Yup from "yup";

export const validationSchema = Yup.object({
  locale: Yup.object()
    .shape({ value: Yup.number().required("Locale is required") })
    .typeError("Locale is required"),
  supplier_organisation_name: Yup.string().required("Organisation Name is required"),
  external_organisation_id: Yup.string().required("Organisation ID is required"),
  contact_first_name: Yup.string().required("First Name is required"),
  contact_last_name: Yup.string().required("Last Name is required"),
  contact_email_address: Yup.string().email("Invalid email address").required("Email is required"),
  contact_phone_number: Yup.string().required("Phone Number is required"),
  address_line_1: Yup.string().required("Address Line is required"),
  city: Yup.string().required("City is required"),
  postcode: Yup.string().required("Postcode is required"),
  country_code: Yup.object()
    .shape({ value: Yup.number().required("Country Code is required") })
    .typeError("Country Code is required"),
  order_number_prefix: Yup.string().required("Order Number Prefix is required"),
  invoice_type: Yup.object()
    .shape({ value: Yup.number().required("Yes/No Answer is required") })
    .typeError("Yes/No Answer is required"),
  delivery_type: Yup.object()
    .shape({ value: Yup.number().required("Delivery Type is required") })
    .typeError("Delivery Type is required"),
  delivery_date_delta: Yup.number().min(0, "Number of days must be greater than or equal to 0"),
});
