import { ApolloError } from "@apollo/client";

import { useGetCustomReportProductNamesQuery } from "../../../../../../generated/graphql_wms";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  productNames: DropdownItem[];
  productNamesError?: ApolloError;
  productNamesLoading: boolean;
}

export const useProductNames = (): HookResult => {
  const { data, error, loading } = useGetCustomReportProductNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    productNames:
      data && Array.isArray(data.product)
        ? data.product.map(product => {
            return {
              value: product.id,
              label: product.product_name,
            };
          })
        : [],
    productNamesError: error,
    productNamesLoading: loading,
  };
};
