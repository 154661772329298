import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import Panel from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import { SUPPLIER_QUOTE_REQUEST_STATUS } from "../../../../../shared/constants";
import { InnerPanelWrapper, TopPanelInputsWrapper } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { fetchSkuAPI } from "../../../shared/fetchSku/api/fetchSku";
import { ProductCatalogueSkuBody } from "../../../shared/fetchSku/api/types";
import { createQuotationModelExportLineAPI } from "../api/createQuotationModelExportLine";
import { SupplierQuoteRequestLine } from "../components/SupplierQuoteRequestLine";
import { useQuotationModelExportLine } from "../graphql/hooks/useQuotationModelExportLine";
import { useSupplierQuoteRequest } from "../graphql/hooks/useSupplierQuoteRequest";
import { SupplierQuoteRequestLineObject, SupplierQuoteRequestObject } from "../graphql/types";
import { groupedQuoteLinesToIdList } from "../helpers";

const ViewSupplierQuoteRequest = () => {
  const { rfq_id: rfqId } = useParams<{ rfq_id: string }>();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_, fetchSku] = useAPI(fetchSkuAPI, true);
  const [savedSupplierQuoteRequest, setSavedSupplierQuoteRequest] = useState<
    SupplierQuoteRequestObject | undefined
  >();
  const { roles } = useAuthorisationContext();

  const [createQuotationModelExportLineState, createQuotationModelExportLine] = useAPI(
    createQuotationModelExportLineAPI
  );
  const [quotationModelLoading, setQuotationModelLoading] = useState(false);
  const [quotationModelSuccess, setQuotationModelSuccess] = useState(false);

  const [openQuoteLine, setOpenQuoteLine] = useState<number | null>(null);
  const [productDetailsList, setProductDetailsList] = useState<ProductCatalogueSkuBody[]>([]);

  const { supplierQuoteRequest, supplierQuoteRequestLoading, supplierQuoteRequestError } =
    useSupplierQuoteRequest(Number(rfqId));

  useEffect(() => {
    if (supplierQuoteRequest && !savedSupplierQuoteRequest) {
      setSavedSupplierQuoteRequest(supplierQuoteRequest);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierQuoteRequest]);

  useEffect(() => {
    if (savedSupplierQuoteRequest) {
      const getProductDetails = async (groupedLine: SupplierQuoteRequestLineObject) => {
        if (groupedLine.product.external_product_id) {
          const productDetails = await fetchSku({ skuId: groupedLine.product.external_product_id });
          if (productDetails && Array.isArray(productDetails)) {
            setProductDetailsList(arr => {
              return [...arr, productDetails[0]];
            });
          }
        }
      };
      savedSupplierQuoteRequest?.groupedLines.forEach(groupedLine => {
        if (groupedLine) getProductDetails(groupedLine);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedSupplierQuoteRequest]);

  const lineIds = useMemo(() => {
    return groupedQuoteLinesToIdList(supplierQuoteRequest?.groupedLines);
  }, [supplierQuoteRequest?.groupedLines]);

  const {
    quotationModelExportLine,
    quotationModelExportLineLoading,
    quotationModelExportLineError,
  } = useQuotationModelExportLine(lineIds);

  const handleSetOpenQuoteLine = (index: number | null) => {
    setOpenQuoteLine(index);
  };

  const shouldShowExportQuotationModelButton =
    supplierQuoteRequest?.statusId === SUPPLIER_QUOTE_REQUEST_STATUS.COMPLETED &&
    !quotationModelExportLine &&
    !quotationModelSuccess;

  const handleExportToQuotationModel = () => {
    if (!supplierQuoteRequest) {
      toast.error("Supplier quote request not found");
      return;
    }
    if (!SOURCING_EDIT_ROLES.some(role => roles.includes(role))) {
      toast.error("Incorrect approval permissions");
      return;
    }
    setQuotationModelLoading(true);

    createQuotationModelExportLine(supplierQuoteRequest.supplierQuotes[0].id);
    setQuotationModelLoading(false);
  };

  useEffect(() => {
    if (createQuotationModelExportLineState === API_REQUEST_STATE.SUCCESS) {
      setQuotationModelSuccess(true);
      toast.success("Successfully exported to Quotation Model");
    }
    if (createQuotationModelExportLineState === API_REQUEST_STATE.ERROR) {
      toast.error("Error exporting to Quotation Model");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuotationModelExportLineState]);

  const loading =
    supplierQuoteRequestLoading || quotationModelExportLineLoading || quotationModelLoading;
  const error = supplierQuoteRequestError || quotationModelExportLineError;

  return (
    <Page
      isLoading={loading}
      error={error}
      title={"View Request For Quotation"}
      topLevelButtons={
        shouldShowExportQuotationModelButton && !loading
          ? [
              {
                id: 1,
                title: "Export to Quotation Model",
                iconName: "document-note",
                handleClick: handleExportToQuotationModel,
              },
            ]
          : []
      }
    >
      <Panel
        withWrapper
        title={`Request For Quotation`}
        subtitle={"View Request For Quotation and supplier response details below"}
        sourcingStatusName={supplierQuoteRequest?.status}
      >
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <DisplayBox label="Supplier" value={supplierQuoteRequest?.organisation} />
            <DisplayBox label="Currency" value={supplierQuoteRequest?.currencyCode} />
            {supplierQuoteRequest?.clientName && (
              <DisplayBox label="Client Name" value={supplierQuoteRequest?.clientName} />
            )}
            <DisplayBox label="Valid From" value={supplierQuoteRequest?.validFrom} />
            <DisplayBox label="Valid To" value={supplierQuoteRequest?.validTo} />
          </TopPanelInputsWrapper>

          {supplierQuoteRequest?.groupedLines.map((supplierQuoteRequestLine, index) => {
            const productDetails = productDetailsList.find(
              product =>
                product && product.id === supplierQuoteRequestLine.product.external_product_id
            );
            return (
              <SupplierQuoteRequestLine
                key={`supplierQuoteRequestLine_${index}${productDetails ? productDetails.id : ""}`}
                index={index}
                group={supplierQuoteRequestLine}
                openQuoteLine={openQuoteLine}
                handleSetOpenQuoteLine={handleSetOpenQuoteLine}
                productDetails={productDetails}
              />
            );
          })}
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default ViewSupplierQuoteRequest;
