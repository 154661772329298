import { WMS_API } from "../../../../../api/baseConfig";
import { CreateOutboundManifestBody } from "./types";

export const createOutboundManifestAPI = async (
  createOutboundManifestAPIBody: CreateOutboundManifestBody
) => {
  try {
    return await WMS_API.post("/outbound-manifests/create", createOutboundManifestAPIBody);
  } catch (err) {
    console.log("Error in create outbound manifest API call:", err);
    throw err;
  }
};
