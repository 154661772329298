import {
  MARKING_STOCK_CHECK_AS_COMPLETE,
  MARK_STOCK_CHECK_AS_COMPLETE_FAILURE,
  MARK_STOCK_CHECK_AS_COMPLETE_RESET,
  MARK_STOCK_CHECK_AS_COMPLETE_SUCCESS,
} from "../types";

export interface MarkStockCheckAsCompleteReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: MarkStockCheckAsCompleteReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function markStockCheckAsCompleteReducer(
  state: MarkStockCheckAsCompleteReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case MARKING_STOCK_CHECK_AS_COMPLETE:
      return {
        ...state,
        loading: true,
      };
    case MARK_STOCK_CHECK_AS_COMPLETE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case MARK_STOCK_CHECK_AS_COMPLETE_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case MARK_STOCK_CHECK_AS_COMPLETE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
