import { ApolloError } from "@apollo/client";

import { useGetAllCourierNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  courierNames?: string[];
  courierNamesError?: ApolloError;
  courierNamesLoading: boolean;
  refetchCourierNames: () => Promise<void>;
}

export const useAllCourierNames = (): HookResult => {
  const { data, error, loading, refetch } = useGetAllCourierNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    courierNames:
      data && Array.isArray(data.courier) ? data.courier.map(courier => courier.courier_name) : [],
    courierNamesError: error,
    courierNamesLoading: loading,
    refetchCourierNames: async () => {
      refetch();
    },
  };
};
