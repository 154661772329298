import { SOURCING_API } from "../../../../../api/baseConfig";
import { UpdatePurchaseOrderDeliveryDateBody } from "./types";

export const updatePurchaseOrderDeliveryDateAPI = async (
  purchaseOrderId: Number,
  body: UpdatePurchaseOrderDeliveryDateBody
) => {
  try {
    return await SOURCING_API.patch(`/purchase-orders/${purchaseOrderId}`, {
      updated_delivery_date: body.updatedDeliveryDate,
    });
  } catch (err) {
    console.log("Error in update purchase order delivery date API call", err);
    throw err;
  }
};
