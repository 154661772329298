import { ApolloError } from "@apollo/client";

import { useGetCaseChangeFromCaseBaseProdQuantityByIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { BaseProductQuantityItem } from "../types";

export interface HookResult {
  toCaseBaseProductQuantity: BaseProductQuantityItem[];
  toCaseBaseProductQuantityError?: ApolloError;
  toCaseBaseProductQuantityLoading: boolean;
  getToCaseBaseProductQuantity: (options: Options) => Promise<void>;
}

interface Options {
  productId: number | null;
}

export const useToCaseBaseProdQuantityById = (): HookResult => {
  const [getToCaseBaseProductQuantity, { data, error, loading }] =
    useGetCaseChangeFromCaseBaseProdQuantityByIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    toCaseBaseProductQuantity: data && Array.isArray(data.product) ? data.product : [],
    toCaseBaseProductQuantityError: error,
    toCaseBaseProductQuantityLoading: loading,
    getToCaseBaseProductQuantity: async ({ productId }: Options) => {
      getToCaseBaseProductQuantity({
        variables: { productId },
      });
    },
  };
};
