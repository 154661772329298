import moment from "moment";
import { useState } from "react";

import { Icon } from "@sourceful/shared-components";

import { VerticalCard } from "../../../../../components/card/VerticalCard";
import { SimpleDateInput } from "../../../../../shared/components/forms/SimpleDateInput";
import { Page } from "../../../../../shared/components/templates/Page";
import { CardsWrapper } from "../../../../../styles/AdminPage";
import {
  ArrowButton,
  DateWrapper,
  InnerPanelWrapper,
  StyledDate,
  SubHeaderWrapper,
} from "../../../../../styles/SharedStyles";
import { useInboundManifestsByDate } from "../graphql/hooks/useInboundManifestsByDate";
import { useOutboundManifestsByDate } from "../graphql/hooks/useOutboundManifestsByDate";
import { useWorkOrdersByDate } from "../graphql/hooks/useWorkOrdersByDate";

export default function ViewManifestDashboard() {
  const [selectedDate, setSelectedDate] = useState<string>(moment(new Date()).format("YYYY-MM-DD"));

  const { inboundManifests, inboundManifestsLoading, inboundManifestsError } =
    useInboundManifestsByDate(selectedDate);
  const { outboundManifests, outboundManifestsLoading, outboundManifestsError } =
    useOutboundManifestsByDate(selectedDate);
  const { workOrders, workOrdersLoading, workOrdersError } = useWorkOrdersByDate(selectedDate);

  const error = workOrdersError || outboundManifestsError || inboundManifestsError;

  return (
    <Page error={error} isLoading={false} title={"Manifests"}>
      <InnerPanelWrapper>
        <SubHeaderWrapper>
          <DateWrapper>
            <ArrowButton
              key={1}
              onClick={() =>
                setSelectedDate(currentDate =>
                  moment(currentDate).subtract(1, "days").toISOString()
                )
              }
            >
              <Icon name="arrow-direction-left"></Icon>
            </ArrowButton>
            <StyledDate>
              <SimpleDateInput
                name="selectedDate"
                htmlFor="selectedDate"
                label=""
                value={moment(selectedDate).format("YYYY-MM-DD")}
                error={""}
                touched={false}
                changeHandler={event => {
                  setSelectedDate(event.target.value);
                }}
              />
            </StyledDate>
            <ArrowButton
              onClick={() =>
                setSelectedDate(currentDate => moment(currentDate).add(1, "days").toISOString())
              }
              key={2}
            >
              <Icon name="arrow-direction-right"></Icon>
            </ArrowButton>
          </DateWrapper>
        </SubHeaderWrapper>

        <CardsWrapper>
          <VerticalCard
            cardTitle={"Inbound"}
            notificationNumber={String(inboundManifests.length)}
            linkText="View inbound manifest"
            link="/manifests/inbound/review-inbound-manifest/"
            scrollBarContent={inboundManifestsLoading ? [] : inboundManifests}
          />
          <VerticalCard
            cardTitle={"Outbound"}
            notificationNumber={String(outboundManifests.length)}
            linkText="View outbound manifest"
            link="/manifests/outbound/review-outbound-manifest/"
            scrollBarContent={outboundManifestsLoading ? [] : outboundManifests}
          />

          <VerticalCard
            cardTitle={"Work Orders"}
            notificationNumber={String(workOrders?.length)}
            linkText="View work order manifest"
            link="/manifests/work-orders/view/"
            scrollBarContent={workOrdersLoading ? [] : workOrders}
          />
        </CardsWrapper>
      </InnerPanelWrapper>
    </Page>
  );
}
