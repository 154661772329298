export enum REPORT_TYPE {
  EMPTY_LOCATIONS = 1,
  ALL_PRODUCTS = 2,
  PRODUCT_STOCK = 3,
  INTERNAL_TRANSFERS = 4,
  INVENTORY_REPORT = 5,
  BASE_PRODUCTS_ACCREDITATIONS = 6,
  MANIFESTS = 7,
  FINANCE_STOCK_REPORT = 8,
}
