import { ApolloError } from "@apollo/client";

import { useGetCaseChangeToCaseProductByBasedProdIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapProductNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  toCaseProductNames: DropdownItem[];
  toCaseProductNamesError?: ApolloError;
  toCaseProductNamesLoading: boolean;
  getToCaseProductNames: (options: Options) => Promise<void>;
}

interface Options {
  baseProductId: number | null;
}

export const useToCaseProductByBaseProdId = (): HookResult => {
  const [getToCaseProductNames, { data, error, loading }] =
    useGetCaseChangeToCaseProductByBasedProdIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    toCaseProductNames:
      data && Array.isArray(data.product) ? mapProductNamesToDropdownObject(data.product) : [],
    toCaseProductNamesLoading: loading,
    toCaseProductNamesError: error,
    getToCaseProductNames: async ({ baseProductId }: Options) => {
      getToCaseProductNames({
        variables: { baseProductId },
      });
    },
  };
};
