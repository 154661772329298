import * as Yup from "yup";

export const validationSchema = Yup.object({
  organisation_id: Yup.object()
    .shape({
      value: Yup.number().required("Organisation is required"),
    })
    .typeError("Organisation is required"),
  address_name: Yup.string().required("Address Name is required"),
  address_line_1: Yup.string().required("Address Line 1 is required"),
  city: Yup.string().required("City is required"),
  postcode: Yup.string().required("Postcode is required"),
  country_name: Yup.object()
    .shape({
      value: Yup.number().required("Country is required"),
    })
    .typeError("Country is required"),
});
