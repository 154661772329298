import { combineReducers } from "redux";

import splitShipmentReducer from "../../modules/logistics/shipments/viewClientOrderShipments/redux/reducers/splitShipmentReducer";
import createQuotationCostTypeReducer from "../../modules/sourcing/admin/quotationCostTypes/createQuotationCostType/redux/reducers/createQuotationCostTypeReducer";
import updateQuotationCostTypeReducer from "../../modules/sourcing/admin/quotationCostTypes/updateQuotationCostType/redux/reducers/updateQuotationCostTypeReducer";
import createPrePurchaseOrderReducer from "../../modules/sourcing/clientOrders/updateClientOrder/redux/reducers/createPrePurchaseOrderReducer";
import createPurchaseOrderReducer from "../../modules/sourcing/purchaseOrders/createPurchaseOrder/redux/reducers/createPurchaseOrderReducer";
import createSupplierResponseReducer from "../../modules/sourcing/purchaseOrders/createPurchaseOrderSupplierResponse/redux/reducers/createSupplierResponseReducer";
import getQuotationCalculationReducer from "../../modules/sourcing/purchaseOrders/shared/redux/reducers/getQuotationCalculationReducer";
import attachInvoicesToPurchaseOrderReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/attachInvoicesToPurchaseOrderReducer";
import createPurchaseOrderApprovalReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/createPurchaseOrderApprovalReducer";
import createGetUsersByRoleReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/getUserNamesByRoleReducer";
import createGetUsersReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/getUserNamesReducer";
import markAsDeliveredPurchaseOrderReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/markAsDeliveredPurchaseOrderReducer";
import markAsInTransitPurchaseOrderReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/markAsInTransitPurchaseOrderReducer";
import updatePurchaseOrderDeliveryDateReducer from "../../modules/sourcing/purchaseOrders/updatePurchaseOrders/redux/reducers/updatePurchaseOrderDeliveryDateReducer";
import createInternalQuoteReducer from "../../modules/sourcing/supplierQuotes/createQuotes/createInternalQuotes/redux/reducers/createInternalQuoteReducer";
import rejectSupplierQuoteRequestReducer from "../../modules/sourcing/supplierQuotes/createQuotes/createSupplierResponse/redux/reducers/rejectSupplierQuoteRequestReducer";
import createSupplierQuotesReducer from "../../modules/sourcing/supplierQuotes/createQuotes/redux/reducers/createSupplierQuotesReducer";
import createAddressReducer from "../../modules/wms/addresses/addAddress/redux/reducers/createAddressReducer";
import stockSyncReducer from "../../modules/wms/admin/viewWMSAdminPanel/redux/reducers/stockSyncReducer";
import caseChangeReducer from "../../modules/wms/caseChange/createChange/redux/reducers/caseChangeReducer";
import deleteFileCopiesReducer from "../../modules/wms/fileCopies/deleteFileCopies/redux/reducers/deleteFileCopiesReducer";
import createInboundManifestReducer from "../../modules/wms/inboundManifests/createInboundManifest/redux/reducers/createInboundManifestReducer";
import fulfilInboundManifestLineReducer from "../../modules/wms/inboundManifests/fulfilInboundManifest/redux/reducers/fulfilInboundManifestLineReducer";
import terminateInboundManifestLineReducer from "../../modules/wms/inboundManifests/fulfilInboundManifest/redux/reducers/terminateInboundManifestLineReducer";
import terminateInboundManifestReducer from "../../modules/wms/inboundManifests/fulfilInboundManifest/redux/reducers/terminateInboundManifestReducer";
import createLocationReducer from "../../modules/wms/locations/addLocation/redux/reducers/createLocationReducer";
import createOrganisationReducer from "../../modules/wms/organisations/addOrganisation/redux/reducers/createOrganisationReducer";
import createOutboundManifestReducer from "../../modules/wms/outboundManifests/createOutboundManifest/redux/reducers/createOutboundManifestReducer";
import fulfilOutboundManifestLineReducer from "../../modules/wms/outboundManifests/fulfilOutboundManifest/redux/reducers/fulfilOutboundManifestLineReducer";
import returnOutboundManifestLineReducer from "../../modules/wms/outboundManifests/fulfilOutboundManifest/redux/reducers/returnOutboundManifestLineReducer";
import terminateOutboundManifestLineReducer from "../../modules/wms/outboundManifests/fulfilOutboundManifest/redux/reducers/terminateOutboundManifestLineReducer";
import terminateOutboundManifestReducer from "../../modules/wms/outboundManifests/fulfilOutboundManifest/redux/reducers/terminateOutboundManifestReducer";
import adjustStockAdHocReducer from "../../modules/wms/perpetualInventory/viewAndUpdatePerpetualInventory/redux/reducers/adjustStockAdHocReducer";
import createBaseProductReducer from "../../modules/wms/products/addBaseProduct/redux/reducers/createBaseProductReducer";
import createCaseProductReducer from "../../modules/wms/products/addProduct/redux/reducers/createCaseProductReducer";
import updateProductReducer from "../../modules/wms/products/viewBaseProductAndStock/redux/reducers/createCaseProductReducer";
import recipeRequestReducer from "../../modules/wms/recipes/addRecipe/redux/reducers/recipeRequestReducer";
import createStockAllocationReducer from "../../modules/wms/stockAllocation/createStockAllocation/redux/reducers/ createStockAllocationReducer";
import fulfilStockCheckLineReducer from "../../modules/wms/stockChecks/fulfilStockCheck/redux/reducers/fulfilStockCheckLineReducer";
import markStockCheckAsCompleteReducer from "../../modules/wms/stockChecks/fulfilStockCheck/redux/reducers/markStockCheckAsCompleteReducer";
import markStockCheckLineAsCompleteReducer from "../../modules/wms/stockChecks/fulfilStockCheck/redux/reducers/markStockCheckLineAsCompleteReducer";
import createStockCheckReducer from "../../modules/wms/stockChecks/shared/redux/reducers/createStockCheckReducer";
import getFilteredProductStockReducer from "../../modules/wms/stockChecks/shared/redux/reducers/getFilteredProductStockReducer";
import createStockTransferReducer from "../../modules/wms/stockTransfer/createStockTransfer/redux/reducers/createStockTransferReducer";
import createWarehouseReducer from "../../modules/wms/warehouses/addWarehouse/redux/reducers/createWarehouseReducer";
import createWorkOrderReducer from "../../modules/wms/workOrders/addWorkOrder/redux/reducers/createWorkOrderReducer";
import fulfilWorkOrderReducer from "../../modules/wms/workOrders/fulfilWorkOrder/redux/reducers/fulfilWorkOrderReducer";
import activeServiceReducer from "./activeServiceReducer";

const rootReducer = combineReducers({
  fulfilInboundManifestLineReducer,
  fulfilOutboundManifestLineReducer,
  createInboundManifestReducer,
  createOutboundManifestReducer,
  adjustStockAdHocReducer,
  recipeRequestReducer,
  createWorkOrderReducer,
  terminateInboundManifestReducer,
  terminateInboundManifestLineReducer,
  createStockTransferReducer,
  createStockCheckReducer,
  createStockAllocationReducer,
  fulfilStockCheckLineReducer,
  terminateOutboundManifestReducer,
  terminateOutboundManifestLineReducer,
  fulfilWorkOrderReducer,
  returnOutboundManifestLineReducer,
  activeServiceReducer,
  createPurchaseOrderReducer,
  createSupplierResponseReducer,
  rejectSupplierQuoteRequestReducer,
  createPrePurchaseOrderReducer,
  createPurchaseOrderApprovalReducer,
  createSupplierQuotesReducer,
  updatePurchaseOrderDeliveryDateReducer,
  markAsInTransitPurchaseOrderReducer,
  attachInvoicesToPurchaseOrderReducer,
  deleteFileCopiesReducer,
  markAsDeliveredPurchaseOrderReducer,
  createGetUsersByRoleReducer,
  createGetUsersReducer,
  getFilteredProductStockReducer,
  markStockCheckLineAsCompleteReducer,
  markStockCheckAsCompleteReducer,
  caseChangeReducer,
  createOrganisationReducer,
  createAddressReducer,
  createWarehouseReducer,
  createBaseProductReducer,
  createCaseProductReducer,
  createLocationReducer,
  createQuotationCostTypeReducer,
  updateQuotationCostTypeReducer,
  updateProductReducer,
  getQuotationCalculationReducer,
  createInternalQuoteReducer,
  stockSyncReducer,
  splitShipmentReducer,
});

export default rootReducer;
