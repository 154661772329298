import React from "react";
import { Link } from "react-router-dom";

import { Icon, styled } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

import { NotificationBadge } from "../notificationBadge/NotificationBadge";

export const DropdownWrapper = styled("div", {
  boxSizing: "border-box",
  width: "160px",
});

export const DropdownTitle = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  fontSize: "16px",
  color: "#000C32",
  variants: {
    isActive: {
      true: {
        fontWeight: "bold",
        color: "#005AE1",
        "&:hover": {
          color: "#005AE1",
        },
      },
    },
  },
  "&:hover": {
    color: "$palette3_colour1_tint20",
  },
});

export const NotificationArrowWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const IconTitleWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const SubHeaderNotificationWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
});

export const SubHeadingWrapper = styled("li", {
  paddingBottom: "6px",
  paddingTop: "6px",
});

export const HeadingWrapper = styled("div", {});

const Subheading = styled("span", {
  fontSize: "14px",
  color: "#000C32",
  "&:hover": {
    color: "$palette3_colour1_tint20",
  },
  variants: {
    isActive: {
      true: {
        fontWeight: "bold",
        color: "#005AE1",
        "&:hover": {
          color: "#005AE1",
        },
      },
    },
  },
});

export interface DropdownProps {
  subHeading: SubHeadingProps[];
  heading: string;
  setIsExpanded: (route: string) => void;
  isExpanded: boolean;
  iconName: IconName;
  notificationNumber?: string;
  pathname?: string;
  headingURL: string;
}

interface SubHeadingProps {
  subHeading: {
    name: string;
    URL: string;
  };
  notificationNumber?: string;
}

export const DropdownSideBar = ({
  subHeading,
  heading,
  headingURL,
  setIsExpanded,
  isExpanded,
  iconName,
  notificationNumber,
  pathname,
}: DropdownProps) => {
  const headingLink = `/${headingURL}`;

  return (
    <DropdownWrapper>
      <HeadingWrapper>
        <DropdownTitle
          isActive={pathname === headingLink}
          onClick={() => {
            setIsExpanded(headingURL);
          }}
        >
          <IconTitleWrapper>
            <Icon name={iconName} height={20} width={20} />
            <Link style={{ marginLeft: "10px" }} to={headingLink}>
              {heading}
            </Link>
          </IconTitleWrapper>

          <NotificationArrowWrapper>
            <div>
              {notificationNumber && (
                <NotificationBadge isActive={pathname === headingLink} text={notificationNumber} />
              )}
            </div>
            <div>
              {isExpanded ? (
                <Icon name={"arrow-direction-up"} />
              ) : isExpanded === false ? (
                <Icon name={"arrow-direction-down"} />
              ) : null}
            </div>
          </NotificationArrowWrapper>
        </DropdownTitle>
      </HeadingWrapper>
      {isExpanded && (
        <ul
          style={{
            listStyleType: "none",
            padding: 0,
            fontSize: "12px",
            fontFamily: "Space Grotesk",
          }}
        >
          {subHeading?.map(item => {
            const path = headingLink + `/${item.subHeading.URL}`;
            return (
              <SubHeadingWrapper key={item.subHeading.name}>
                <SubHeaderNotificationWrapper>
                  <Link to={path}>
                    <Subheading isActive={pathname === path}>{item.subHeading.name}</Subheading>
                  </Link>
                  <div style={{ marginRight: "24px" }}>
                    {item.notificationNumber && (
                      <NotificationBadge
                        isActive={pathname === path}
                        text={item.notificationNumber}
                      />
                    )}
                  </div>
                </SubHeaderNotificationWrapper>
              </SubHeadingWrapper>
            );
          })}
        </ul>
      )}
    </DropdownWrapper>
  );
};
