import { ApolloError } from "@apollo/client";

import { useGetSuppliersDashboardDataQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapSuppliersDashboard } from "../mappers";
import { SupplierDashboardItem } from "../types";

export interface HookResult {
  suppliers: SupplierDashboardItem[];
  suppliersLoading: boolean;
  suppliersError?: ApolloError;
}

export const useSuppliers = (): HookResult => {
  const { data, loading, error } = useGetSuppliersDashboardDataQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    suppliers:
      data && Array.isArray(data.supplier_details)
        ? mapSuppliersDashboard(data.supplier_details)
        : [],
    suppliersLoading: loading,
    suppliersError: error,
  };
};
