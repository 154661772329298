import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { IconWrapper } from "../../../../../styles/SharedStyles";
import { useRecipes } from "../graphql/hooks/useRecipesWithData";
import { RecipeItem } from "../graphql/types";

export default function ViewRecipes() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/manifests/recipe/recipe-request");
          },
          type: "button",
          title: "Add recipe",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const { recipes, recipesLoading, recipesError } = useRecipes();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.1,
        accessor: (listItem: RecipeItem) => listItem.id,
      },
      {
        Header: "Recipe Name",
        widthPercent: 0.3,
        accessor: (listItem: RecipeItem) => listItem.recipe_name,
      },
      {
        Header: "Type Name",
        widthPercent: 0.2,
        accessor: (listItem: RecipeItem) => listItem.recipe_type.recipe_type_name,
      },
      {
        Header: "# Inputs",
        widthPercent: 0.1,
        accessor: (listItem: RecipeItem) => listItem.recipe_inputs_aggregate.aggregate?.count,
      },
      {
        Header: "# Outputs",
        widthPercent: 0.1,
        accessor: (listItem: RecipeItem) => listItem.recipe_outputs_aggregate.aggregate?.count,
      },
      {
        Header: "Use For Bundle Stock Reporting",
        widthPercent: 0.4,
        accessor: (listItem: RecipeItem) => (listItem.use_for_reporting ? "True" : "False"),
        Cell: ({ _, row }: CellProps<RecipeItem>) => {
          if (row.original.use_for_reporting) {
            return (
              <IconWrapper withinList sourcefulBlue>
                <Icon name="alert-tick-outline" />
              </IconWrapper>
            );
          }
          return (
            <IconWrapper withinList isGray>
              <Icon name="alert-cross-outline" />
            </IconWrapper>
          );
        },
      },

      {
        Header: "View",
        widthPercent: 0.1,
        accessor: (listItem: RecipeItem) => listItem.id,
        Cell: ({ _, row }: CellProps<RecipeItem>) => (
          <Link to={`/manifests/recipe/view-recipe/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page error={recipesError} isLoading={recipesLoading} title={"Manifests - Recipe"}>
      <Panel
        withWrapper
        title={"Recipe List"}
        text={"Use the table below to view Sourceful's Recipes"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        toolTipMessage={
          "A request to repackage items using <br/> 'ingredient' items and resulting in <br/> 'dish' items"
        }
      >
        <Table data={recipes} columns={columns} />
      </Panel>
    </Page>
  );
}
