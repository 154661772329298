export const daysHoursMinutesSeconds = (ms: number) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  const secZero = sec < 10 ? "0" + sec : sec;
  const minutesZero = minutes < 10 ? "0" + minutes : minutes;
  const hoursZero = hours < 10 ? "0" + hours : hours;

  return days + ":" + hoursZero + ":" + minutesZero + ":" + secZero;
};
