import { ApolloError } from "@apollo/client";

import { useGetWorkOrderRecipeByIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { RecipeItem } from "../types";

export interface HookResult {
  recipe?: RecipeItem;
  recipeError?: ApolloError;
  recipeLoading: boolean;
  getRecipeById: (options: Options) => Promise<void>;
}

interface Options {
  recipeId: number;
}

export const useRecipeById = (): HookResult => {
  const [getRecipeById, { data, error, loading }] = useGetWorkOrderRecipeByIdLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    recipe: data && Array.isArray(data.recipe) ? data.recipe[0] : undefined,
    recipeError: error,
    recipeLoading: loading,
    getRecipeById: async ({ recipeId }: Options) => getRecipeById({ variables: { recipeId } }),
  };
};
