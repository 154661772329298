import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { createRef, useEffect, useMemo, useState } from "react";

import { ButtonGroup, Modal } from "@sourceful/shared-components";

import { ModalContainer } from "../../../../../components/ModalContainer/ModalContainer";
import Table from "../../../../../components/Table";
import LoadingScreen from "../../../../../components/loadingScreen/LoadingScreen";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton/SecondaryButton";
import Label from "../../../../../shared/components/atoms/labels/Label";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { mapAddressToDropdownItem } from "../../../shared/graphql/mappers/mapAddressToDropdownItem";
import { getLastShipmentUpdate } from "../../../shared/helpers/getLastShipmentUpdate";
import { SHIPMENT_UPDATE_TYPE } from "../../../shared/models";
import { createShipmentDeliveredUpdateAPI } from "../../shared/api/createShipmentDeliveredUpdate";
import { createShipmentInTransitUpdateAPI } from "../../shared/api/createShipmentInTransitUpdate";
import {
  CreateShipmentDeliveredUpdateBody,
  CreateShipmentInTransitUpdateBody,
  UpdateShipmentDeliveryDateBody,
} from "../../shared/api/types";
import { updateShipmentDeliveryDateAPI } from "../../shared/api/updateShipmentDeliveryDate";
import {
  DeliveredUpdateInterface,
  DeliveryDateUpdateInterface,
  InTransitUpdateInterface,
  initialDeliveredFieldValues,
  initialDeliveryDateFieldValues,
  initialInTransitFieldValues,
} from "../../shared/components";
import DeliveryUpdateForm from "../../shared/components/DeliveryUpdateForm";
import InTransitUpdateForm from "../../shared/components/InTransitUpdateForm";
import { useInternalAddresses } from "../../shared/graphql/hooks/useInternalAddresses";
import {
  batchShipmentUpdateFieldNames,
  initialBatchShipmentUpdateFieldValues,
  tableCols,
} from "../formValues";
import {
  filterByClientOrderNumber,
  filterByClientOrganisation,
  filterByCurrentAddress,
  filterByFromAddress,
  filterByPurchaseOrderNumber,
  filterByToAddress,
} from "../functions";
import { useShipmentsByStatusId } from "../graphql/hooks/useShipmentsByStatusId";
import { ShipmentItem } from "../graphql/types";
import {
  mapShipmentClientOrderToDropdownItem,
  mapShipmentOrganisationToDropdownItem,
  mapShipmentPurchaseOrderToDropdownItem,
  mapShipmentUpdateTypeToDropdownItem,
} from "../mappers";
import { BatchShipmentUpdateInterface, MOVING_UPDATES, STATIC_UPDATES } from "../types";

const UpdateShipmentsInBatch = () => {
  const {
    shipments,
    currentAddresses,
    toAddresses,
    fromAddresses,
    shipmentsLoading,
    shipmentsError,
    getShipmentsByStatusId,
  } = useShipmentsByStatusId();

  const { internalAddresses, internalAddressesError, internalAddressesLoading } =
    useInternalAddresses();

  const columns = useMemo(() => tableCols, []);
  const modalRef = createRef<HTMLButtonElement>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [shipmentUpdateType, setShipmentUpdateType] = useState<SHIPMENT_UPDATE_TYPE | null>(null);
  const [selectedShipments, setSelectedShipments] = useState<ShipmentItem[]>([]);

  const [createDeliveredUpdateState, createDeliveredUpdate] = useAPI(
    createShipmentDeliveredUpdateAPI
  );
  const [createInTransitUpdateState, createInTransitUpdate] = useAPI(
    createShipmentInTransitUpdateAPI
  );
  const [updateShipmentDeliveryDateState, updateShipmentDeliveryDate] = useAPI(
    updateShipmentDeliveryDateAPI
  );

  useEffect(() => {
    if (shipments && shipments.length > 0) {
      setSelectedShipments(shipments);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shipments]);

  const filterShipments = (values: BatchShipmentUpdateInterface) => {
    let filtered = shipments;
    if (values.toAddress) {
      filtered = filtered.filter(shipment => {
        const update = getLastShipmentUpdate(
          shipment.shipment_updates,
          SHIPMENT_UPDATE_TYPE.IN_TRANSIT
        );
        if (!update || !update.addressByToDestinationId) return false;
        return update.addressByToDestinationId.id === values.toAddress!.value;
      });
    }
    if (values.fromAddress) {
      filtered = filtered.filter(shipment => {
        const update = getLastShipmentUpdate(
          shipment.shipment_updates,
          SHIPMENT_UPDATE_TYPE.IN_TRANSIT
        );
        if (!update || !update.addressByFromDestinationId) return false;
        return update.addressByFromDestinationId.id === values.fromAddress!.value;
      });
    }
    if (values.currentAddress) {
      filtered = filtered.filter(shipment => {
        const update = getLastShipmentUpdate(
          shipment.shipment_updates,
          SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE
        );
        if (!update || !update.address) return false;
        return update.address.id === values.currentAddress!.value;
      });
    }
    if (values.clientOrganisations.length > 0) {
      filtered = filtered.filter(shipment =>
        values.clientOrganisations.some(org => org.value === shipment.client_order.organisation.id)
      );
    }
    if (values.clientOrderNumbers.length > 0) {
      filtered = filtered.filter(shipment =>
        values.clientOrderNumbers.some(co => co.value === shipment.client_order.id)
      );
    }
    if (values.purchaseOrderNumbers.length > 0) {
      filtered = filtered.filter(shipment =>
        values.purchaseOrderNumbers.some(
          po => shipment.purchase_order && po.value === shipment.purchase_order.id
        )
      );
    }
    setSelectedShipments(filtered);
  };

  const resetValues = () => {
    setSelectedShipments(shipments ? shipments : []);
    setIsModalOpen(false);
    setShipmentUpdateType(null);
  };

  const handleInTransitUpdate = (values: InTransitUpdateInterface) => {
    setIsModalOpen(false);

    const inTransitUpdate: CreateShipmentInTransitUpdateBody = {
      fromAddressId: values.fromDestination!.value,
      toAddressId: values.toDestination!.value,
      estimatedDeliveryDate: values.expectedDeliveryDate!,
      notes: values.notes,
      trackingLink: values.trackingLink,
    };

    createInTransitUpdate(
      (values.shipmentIds as number[]).map(shipmentId => ({
        body: inTransitUpdate,
        shipmentId,
      }))
    );
  };

  useEffect(() => {
    if (createInTransitUpdateState === API_REQUEST_STATE.SUCCESS) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createInTransitUpdateState]);

  const handleDeliveredUpdate = (values: DeliveredUpdateInterface) => {
    setIsModalOpen(false);

    const deliveredUpdate: CreateShipmentDeliveredUpdateBody = {
      actualDeliveryDate: values.actualDeliveryDate!,
    };

    createDeliveredUpdate(
      (values.shipmentIds as number[]).map(shipmentId => ({
        body: deliveredUpdate,
        shipmentId,
      }))
    );
  };

  useEffect(() => {
    if (createDeliveredUpdateState === API_REQUEST_STATE.SUCCESS) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createDeliveredUpdateState]);

  const handleDeliveryDateUpdate = (values: DeliveryDateUpdateInterface) => {
    setIsModalOpen(false);

    const deliveryDateUpdate: UpdateShipmentDeliveryDateBody = {
      updatedDeliveryDate: values.expectedDeliveryDate!,
      notes: values.notes,
    };

    updateShipmentDeliveryDate(
      (values.shipmentIds as number[]).map(shipmentId => ({
        body: deliveryDateUpdate,
        shipmentId,
      }))
    );
  };

  useEffect(() => {
    if (updateShipmentDeliveryDateState === API_REQUEST_STATE.SUCCESS) {
      resetValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateShipmentDeliveryDateState]);

  const error = internalAddressesError || shipmentsError;

  return (
    <Page title={"Batch Shipment Updates"} isLoading={internalAddressesLoading} error={error}>
      <Panel title="Generate an update for multiple shipments" withWrapper>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialBatchShipmentUpdateFieldValues}
            enableReinitialize
            onSubmit={values => filterShipments(values)}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched, resetForm }) => {
              const clientOrderAddresses = mapAddressToDropdownItem(
                selectedShipments.map(shipment => shipment.client_order.address)
              );
              const sharedAddresses = clientOrderAddresses.filter(address =>
                selectedShipments.every(
                  shipment => shipment.client_order.address.id === address.value
                )
              );
              const singleAddress =
                values.updateType && STATIC_UPDATES.includes(values.updateType.value);
              const showAdditionalFilters = singleAddress
                ? !!values.currentAddress
                : !!values.toAddress && !!values.fromAddress;

              const filteredShipments = shipments
                .filter(filterByToAddress(values.toAddress))
                .filter(filterByFromAddress(values.fromAddress))
                .filter(filterByCurrentAddress(values.currentAddress));

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleSelect
                          isRequired
                          htmlFor={batchShipmentUpdateFieldNames.updateType}
                          name={batchShipmentUpdateFieldNames.updateType}
                          value={values.updateType}
                          error={errors.updateType}
                          touched={touched.updateType}
                          label="Shipment Update Type"
                          changeHandler={e => {
                            resetValues();
                            setFieldValue(batchShipmentUpdateFieldNames.updateType, e);
                            getShipmentsByStatusId({ updateTypeId: e ? e.value : e });
                            setShipmentUpdateType(e ? e.value : e);
                          }}
                          placeholder="Select Shipment Update Type"
                          options={mapShipmentUpdateTypeToDropdownItem()}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                    {values.updateType && MOVING_UPDATES.includes(values.updateType.value) && (
                      <PrimaryLineDataWrapper>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            htmlFor={batchShipmentUpdateFieldNames.fromAddress}
                            name={batchShipmentUpdateFieldNames.fromAddress}
                            value={values.fromAddress}
                            error={errors.fromAddress}
                            touched={touched.fromAddress}
                            label="From Address"
                            changeHandler={e => {
                              setFieldValue(batchShipmentUpdateFieldNames.fromAddress, e);
                              handleSubmit();
                            }}
                            placeholder="Select From Address"
                            options={fromAddresses}
                          />
                        </DataPoint>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            htmlFor={batchShipmentUpdateFieldNames.toAddress}
                            name={batchShipmentUpdateFieldNames.toAddress}
                            value={values.toAddress}
                            error={errors.toAddress}
                            touched={touched.toAddress}
                            label="To Address"
                            changeHandler={e => {
                              setFieldValue(batchShipmentUpdateFieldNames.toAddress, e);
                              handleSubmit();
                            }}
                            placeholder="Select To Address"
                            options={toAddresses}
                          />
                        </DataPoint>
                      </PrimaryLineDataWrapper>
                    )}
                    {values.updateType && STATIC_UPDATES.includes(values.updateType.value) && (
                      <PrimaryLineDataWrapper>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            htmlFor={batchShipmentUpdateFieldNames.currentAddress}
                            name={batchShipmentUpdateFieldNames.currentAddress}
                            value={values.currentAddress}
                            error={errors.currentAddress}
                            touched={touched.currentAddress}
                            label="Current Address"
                            changeHandler={e => {
                              setFieldValue(batchShipmentUpdateFieldNames.currentAddress, e);
                              handleSubmit();
                            }}
                            placeholder="Select Current Address"
                            options={currentAddresses}
                          />
                        </DataPoint>
                      </PrimaryLineDataWrapper>
                    )}
                    {values.updateType && shipments.length > 0 && showAdditionalFilters && (
                      <SecondaryLineWrapper>
                        <Label>Additional Filters (Optional): </Label>
                        <SecondaryLineDataWrapper>
                          <DataPoint>
                            <SimpleSelect
                              isMulti
                              htmlFor={batchShipmentUpdateFieldNames.clientOrganisations}
                              name={batchShipmentUpdateFieldNames.clientOrganisations}
                              value={values.clientOrganisations}
                              error={errors.clientOrganisations}
                              touched={touched.clientOrganisations}
                              label="Client Organisation"
                              changeHandler={e => {
                                setFieldValue(batchShipmentUpdateFieldNames.clientOrganisations, e);
                                handleSubmit();
                              }}
                              placeholder="Select Client Organisation"
                              options={_.uniqBy(
                                filteredShipments
                                  .filter(filterByPurchaseOrderNumber(values.purchaseOrderNumbers))
                                  .filter(filterByClientOrderNumber(values.clientOrderNumbers))
                                  .map(mapShipmentOrganisationToDropdownItem),
                                "value"
                              )}
                            />
                          </DataPoint>
                          <DataPoint>
                            <SimpleSelect
                              isMulti
                              label="Client Order"
                              htmlFor={batchShipmentUpdateFieldNames.clientOrderNumbers}
                              name={batchShipmentUpdateFieldNames.clientOrderNumbers}
                              value={values.clientOrderNumbers}
                              error={errors.clientOrderNumbers}
                              touched={touched.clientOrderNumbers}
                              options={_.uniqBy(
                                filteredShipments
                                  .filter(filterByPurchaseOrderNumber(values.purchaseOrderNumbers))
                                  .filter(filterByClientOrganisation(values.clientOrganisations))
                                  .map(mapShipmentClientOrderToDropdownItem),
                                "value"
                              )}
                              placeholder="Select Client Order"
                              changeHandler={e => {
                                setFieldValue(batchShipmentUpdateFieldNames.clientOrderNumbers, e);
                                handleSubmit();
                              }}
                            />
                          </DataPoint>
                          <DataPoint>
                            <SimpleSelect
                              isMulti
                              label="Purchase Order"
                              htmlFor={batchShipmentUpdateFieldNames.purchaseOrderNumbers}
                              name={batchShipmentUpdateFieldNames.purchaseOrderNumbers}
                              value={values.purchaseOrderNumbers}
                              error={errors.purchaseOrderNumbers}
                              touched={touched.purchaseOrderNumbers}
                              options={_.uniqBy(
                                filteredShipments
                                  .filter(filterByClientOrganisation(values.clientOrganisations))
                                  .filter(filterByClientOrderNumber(values.clientOrderNumbers))
                                  .filter(shipment => !!shipment.purchase_order)
                                  .map(mapShipmentPurchaseOrderToDropdownItem),
                                "value"
                              )}
                              placeholder="Select Purchase Order"
                              changeHandler={e => {
                                setFieldValue(
                                  batchShipmentUpdateFieldNames.purchaseOrderNumbers,
                                  e
                                );
                                handleSubmit();
                              }}
                            />
                          </DataPoint>
                        </SecondaryLineDataWrapper>
                      </SecondaryLineWrapper>
                    )}
                  </PrimaryLineWrapper>
                  <PrimaryLineWrapper>
                    {values.updateType && !shipmentsLoading && (
                      <Table data={selectedShipments} columns={columns} />
                    )}
                    {shipmentsLoading && <LoadingScreen />}
                  </PrimaryLineWrapper>

                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      appearance="whiteButton"
                      onClick={() => {
                        resetForm();
                        resetValues();
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    {selectedShipments.length > 0 && (
                      <SecondaryButton
                        appearance="blueButton"
                        onClick={() => {
                          setIsModalOpen(true);
                        }}
                      >
                        Generate Update For Selected Shipments ({selectedShipments.length})
                      </SecondaryButton>
                    )}
                  </ButtonGroup>

                  <Modal
                    id={`shipmentUpdateModal`}
                    isOpen={isModalOpen}
                    handleClose={() => resetValues()}
                    triggerRef={modalRef}
                  >
                    <ModalContainer>
                      {STATIC_UPDATES.some(type => type === shipmentUpdateType) && (
                        <InTransitUpdateForm
                          addresses={internalAddresses.concat(sharedAddresses)}
                          handleResponse={handleInTransitUpdate}
                          formValues={{
                            ...initialInTransitFieldValues,
                            shipmentIds: selectedShipments.map(shipment => shipment.id),
                            fromDestination: values.currentAddress,
                          }}
                          reset={resetValues}
                        />
                      )}
                      {MOVING_UPDATES.some(type => type === shipmentUpdateType) && (
                        <DeliveryUpdateForm
                          handleDeliveredResponse={handleDeliveredUpdate}
                          handleDeliveryDateResponse={handleDeliveryDateUpdate}
                          deliveredValues={{
                            ...initialDeliveredFieldValues,
                            shipmentIds: selectedShipments.map(shipment => shipment.id),
                            actualDeliveryDate: moment(Date.now()).format("YYYY-MM-DD"),
                          }}
                          deliveryDateValues={{
                            ...initialDeliveryDateFieldValues,
                            shipmentIds: selectedShipments.map(shipment => shipment.id),
                            expectedDeliveryDate: moment(Date.now()).format("YYYY-MM-DD"),
                          }}
                        />
                      )}
                    </ModalContainer>
                  </Modal>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default UpdateShipmentsInBatch;
