import { styled } from "@sourceful/shared-components";

export const reactSelectStyling = {
  control: (providedStyling: any) => ({
    ...providedStyling,
    border: "1px solid #C4C4C4",
    borderRadius: "10px",
    width: "280px",
    marginBottom: "8px",
  }),
  placeholder: (providedStyling: any) => ({
    ...providedStyling,
    whiteSpace: "nowrap",
    color: "#8D8D8D",
  }),
};
export const PanelWrapper = styled("div", {
  marginTop: 60,
});

export const InnerPanelWrapper = styled("div", {
  margin: "0px",
  padding: "25px",
});

export const DataPoint = styled("div", {
  margin: "10px 30px",
  width: "280px",
  variants: {
    isIcon: {
      true: {
        minWidth: "max-content",
        marginTop: "0px",
        marginRight: "0px",
      },
    },
  },
});

export const Label = styled("label", {
  fontSize: "15px",
  fontWeight: "bold",
  color: "#000C32",
  display: "inline-block",
  marginBottom: "8px",
  variants: {
    isGray: {
      true: {
        color: "#8D8D8D",
      },
    },
  },
});

export const Box = styled("div", {
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  width: "280px",
  padding: "5px 8px",
  margin: "0px",
  display: "flex",
  alignItems: "center",
  backgroundColor: "white",
  minHeight: "38px",
});

export const StyledForm = styled("form", {});
