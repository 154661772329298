import { Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { CreateAddressBody } from "../api/types";
import { AddressForm, fieldNames, initialFieldValues } from "../formValues";
import { useAllCountryNamesAsc } from "../graphql/hooks/useAllCountryNamesAsc";
import { useAllOrganisationNames } from "../graphql/hooks/useAllOrganisationNames";
import { mapCountryNamesToDrodownObject } from "../mappers";
import { createAddressAction, createAddressReset } from "../redux/actions/createAddressAction";
import { CreateAddressReducer } from "../redux/reducers/createAddressReducer";
import { validationSchema } from "../validation";

interface Props {
  createAddressState: CreateAddressReducer;
  createAddress: (body: CreateAddressBody) => void;
  resetCreateAddress: () => void;
}

function AddAddress(props: Props) {
  const [initialValues] = useState<AddressForm>(initialFieldValues);

  const { createAddressState } = props;

  const onSubmit = async (values: AddressForm) => {
    const address: CreateAddressBody = {
      organisation_id: values.organisation_id?.value!,
      address_name: values.address_name,
      name: values.name !== "" ? values.name : values.address_name,
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      address_line_3: values.address_line_3 !== "" ? values.address_line_3 : undefined,
      city: values.city,
      postcode: values.postcode,
      country_code: countryNames?.filter(
        country => values.country_name?.label === country.country_name
      )[0].country_code,
      address_description:
        values.address_description !== "" ? values.address_description : undefined,
      phone_number: values.phone_number !== "" ? values.phone_number : undefined,
    };

    props.createAddress(address);
  };

  useEffect(() => {
    if (createAddressState.success) {
      props.resetCreateAddress();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createAddressState.success, createAddressState.error]);

  const { organisationNames, organisationNamesLoading, organisationNamesError } =
    useAllOrganisationNames();
  const { countryNames, countryNamesLoading, countryNamesError } = useAllCountryNamesAsc();

  const countryNamesOptions = mapCountryNamesToDrodownObject(countryNames || []);

  const error = countryNamesError || organisationNamesError;
  const isLoading = countryNamesLoading || organisationNamesLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Address Book"}>
      <Panel withWrapper title={"Add Address"} subtitle={"Add an Address using the inputs below"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleReset,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <SimpleSelect
                      isRequired
                      htmlFor={fieldNames.organisation_id}
                      name={fieldNames.organisation_id}
                      placeholder="Select Organisation Here"
                      options={organisationNames}
                      value={values.organisation_id}
                      error={errors.organisation_id}
                      touched={touched.organisation_id}
                      label="Organisation"
                      changeHandler={e => setFieldValue(fieldNames.organisation_id, e)}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.address_name}
                      name={fieldNames.address_name}
                      type="text"
                      placeholder="Enter Address Name Here"
                      value={values.address_name}
                      error={errors.address_name}
                      touched={touched.address_name}
                      label="Address Name"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={fieldNames.name}
                      name={fieldNames.name}
                      type="text"
                      placeholder="Enter Name Here"
                      value={values.name}
                      error={errors.name}
                      touched={touched.name}
                      label="Name"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.address_line_1}
                      name={fieldNames.address_line_1}
                      type="text"
                      placeholder="Enter First Line of the Address  Here"
                      value={values.address_line_1}
                      error={errors.address_line_1}
                      touched={touched.address_line_1}
                      label="Address Line 1"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.address_line_2}
                      name={fieldNames.address_line_2}
                      type="text"
                      placeholder="Enter Second Line of the Address  Here"
                      value={values.address_line_2}
                      error={errors.address_line_2}
                      touched={touched.address_line_2}
                      label="Address Line 2"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={fieldNames.address_line_3}
                      name={fieldNames.address_line_3}
                      type="text"
                      placeholder="Enter Third Line of the Address  Here"
                      value={values.address_line_3}
                      error={errors.address_line_3}
                      touched={touched.address_line_3}
                      label="Address Line 3"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={fieldNames.address_description}
                      name={fieldNames.address_description}
                      type="text"
                      placeholder="Enter Floor Number, Stairs ... here"
                      value={values.address_description}
                      error={errors.address_description}
                      touched={touched.address_description}
                      label="Address Description"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.city}
                      name={fieldNames.city}
                      type="text"
                      placeholder="Enter City here"
                      value={values.city}
                      error={errors.city}
                      touched={touched.city}
                      label="City"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.postcode}
                      name={fieldNames.postcode}
                      type="text"
                      placeholder="Enter Postcode here"
                      value={values.postcode}
                      error={errors.postcode}
                      touched={touched.postcode}
                      label="Postcode"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleSelect
                      isRequired
                      htmlFor={fieldNames.country_name}
                      name={fieldNames.country_name}
                      placeholder="Select Country Here"
                      options={countryNamesOptions}
                      value={values.country_name}
                      error={errors.country_name}
                      touched={touched.country_name}
                      label="Country"
                      changeHandler={e => setFieldValue(fieldNames.country_name, e)}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={fieldNames.phone_number}
                      name={fieldNames.phone_number}
                      type="text"
                      placeholder="Enter Phone Number"
                      value={values.phone_number}
                      error={errors.phone_number}
                      touched={touched.phone_number}
                      label="Phone Number"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                </TopPanelInputsWrapper>

                <FormButtonPair handleReset={handleReset} />
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createAddressState: state.createAddressReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createAddress: (body: CreateAddressBody) => dispatch(createAddressAction(body)),
    resetCreateAddress: () => dispatch(createAddressReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddAddress);
