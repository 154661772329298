import { useEffect, useMemo, useState } from "react";
import { CellProps } from "react-table";
import { toast } from "react-toastify";

import { CheckBox, Icon, IconText } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import Table from "../../../../../components/Table";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import TextArea from "../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { prettifyProductName } from "../../../shared/mappers";
import GetQuotationCalculationBody from "../../shared/api/types/getQuotationCalculation";
import { mapAttachmentsToSet } from "../../shared/mappers";
import { useProductLineAttachmentsByClientOrderLineId } from "../graphql/hooks/useProductLineAttachmentsByClientOrderLineId";
import { useSupplierQuoteLine } from "../graphql/hooks/useSupplierQuoteLine";
import { PendingDemandItem } from "../graphql/types";
import { mapProductDropdownItems } from "../mappers";
import { ProductLineProps, QuotationCostProps, QuoteLineIdAndUnitCostPairs } from "../types";
import { QuantityInput } from "./QuantityInput";

const DEFAULT_FILE_COPY_QTY = 5;

const ProductLine = ({
  productLine,
  setFieldValue,
  index,
  handleRemovePurchaseOrderLine,
  handleLeadTime,
  manufacturer,
  errors,
  originalPendingDemand,
  touched,
  productDropdownData,
  quotationCalculationState,
  getQuotationCalculation,
  countryCode,
}: ProductLineProps) => {
  const [lineIdToEdit, setLineIdToEdit] = useState<number | undefined>(undefined);
  const [displayMessage, setDisplayMessage] = useState<string | null>(null);
  const [quotationCalculationPairs, setQuotationCalculationPairs] = useState<
    QuoteLineIdAndUnitCostPairs[] | null
  >(null);
  const [quotesGenerated, setQuotesGenerated] = useState<boolean>(false);
  const [hasQuoteSelected] = useState<boolean>(!!productLine.quotation_calculation_pair);

  const { supplierQuoteLine, getSupplierQuoteLineById } = useSupplierQuoteLine();

  const { productLineAttachments, getProductLineAttachmentsByClientOrderLineId } =
    useProductLineAttachmentsByClientOrderLineId();

  const handleIsEditingChange = (lineId: number) =>
    setLineIdToEdit(lineIdToEdit => (lineIdToEdit ? undefined : lineId));

  const handleChangeQuantities = (updatedDemand: PendingDemandItem) => {
    const lineIndex = productLine.client_order_lines.findIndex(
      demand => demand.client_order_line?.id === updatedDemand.client_order_line?.id
    );
    setFieldValue(`purchase_order_lines[${index}].client_order_lines[${lineIndex}]`, updatedDemand);
  };

  const submitGetQuotationCalculation = async (quotationCost: QuotationCostProps) => {
    const body: GetQuotationCalculationBody = { ...quotationCost, countryCode };
    const result = await getQuotationCalculation(body);
    setQuotationCalculationPairs(result);
  };

  const getAttachmentsForProduct = (productId: number): PendingDemandItem[] => {
    const pendingDemand = originalPendingDemand.filter(demand => demand.product?.id === productId);

    const clientOrderLineIds = pendingDemand.map(
      pendingDemandItem => pendingDemandItem.client_order_line!.id
    );

    getProductLineAttachmentsByClientOrderLineId({ clientOrderLineIds });

    return pendingDemand;
  };

  useEffect(() => {
    if (quotationCalculationPairs && quotationCalculationPairs?.length === 0)
      setDisplayMessage("There are no valid quotes for this selection");
  }, [quotationCalculationPairs]);

  useEffect(() => {
    getAttachmentsForProduct(productLine.product?.value!);
    if (hasQuoteSelected) {
      getSupplierQuoteLineById({
        supplierQuoteLineId: productLine.quotation_calculation_pair?.value!,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        widthPercent: 0.5,
        accessor: (line: PendingDemandItem) =>
          prettifyProductName(line.product?.product_name || ""),
      },
      {
        Header: "Client Order",
        widthPercent: 0.3,
        accessor: (line: PendingDemandItem) =>
          prettifyProductName(line.client_order_line?.client_order.external_client_order_id || ""),
      },
      {
        Header: "Quantity To Purchase",
        widthPercent: 0.2,
        accessor: (line: PendingDemandItem) => line.quantity_to_purchase,
        Cell: ({ row }: CellProps<PendingDemandItem>) => (
          <>
            {row && row.original && row.original.client_order_line && (
              <QuantityInput
                lineIdToEdit={lineIdToEdit}
                handleIsEditingChange={handleIsEditingChange}
                handleChangeQuantities={handleChangeQuantities}
                row={row}
                index={index}
              />
            )}
          </>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productLine.product, lineIdToEdit]
  );

  const shouldDisplayQuoteInfo = () => {
    return (
      (quotesGenerated &&
        quotationCalculationPairs !== null &&
        quotationCalculationPairs.length > 0 &&
        !quotationCalculationState.loading) ||
      hasQuoteSelected
    );
  };

  const getQuoteSelectionValue = () => {
    if (productLine.quotation_calculation_pair) {
      return productLine.quotation_calculation_pair;
    }
    return null;
  };

  // Remove error from quote selection if quote is selected
  const getQuoteSelectionError = () => {
    if (!getQuoteSelectionValue()) {
      return Array.isArray(errors) && errors[index]?.quotation_calculation_pair
        ? errors[index]!.quotation_calculation_pair
        : undefined;
    }
  };

  return (
    <SecondaryLineWrapper type="withIndex">
      {displayMessage && <ErrorMessage>{displayMessage}</ErrorMessage>}
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint halfWidth>
          <SimpleSelect
            isRequired
            isGray={quotesGenerated}
            label="Product"
            htmlFor={`product_${index}`}
            isDisabled={quotesGenerated}
            options={mapProductDropdownItems(productDropdownData)}
            value={productLine.product}
            changeHandler={e => {
              setFieldValue(`purchase_order_lines[${index}].product`, e);
              setFieldValue(`purchase_order_lines[${index}].fileCopyQty`, DEFAULT_FILE_COPY_QTY);

              if (!e) return;
              const pendingDemand = getAttachmentsForProduct(e.value);
              setFieldValue(`purchase_order_lines[${index}].client_order_lines`, pendingDemand);
            }}
            placeholder="Select Product"
            name="Product"
            error={
              Array.isArray(errors) && errors[index]?.product ? errors[index]!.product : undefined
            }
            touched={
              Array.isArray(touched) && touched[index]?.product
                ? touched[index]!.product
                : undefined
            }
          />
        </DataPoint>
        <div style={{ width: "45%", flexFlow: "wrap", display: "flex" }}>
          <DataPoint halfWidth>
            <DisplayBox
              label="Total Quantity"
              value={`${productLine.client_order_lines.reduce((prev, current) => {
                return prev + (current.quantity_to_purchase ?? 0);
              }, 0)} units`}
            />
          </DataPoint>
          <DataPoint halfWidth containsButton>
            <IconWrapper
              type="button"
              css={{
                color: "#8D8D8D",
                "&:hover": { color: "#005AE1" },
                alignSelf: "bottom",
              }}
              onClick={() => {
                if (productLine.product) handleLeadTime(undefined, productLine.product.value);
                handleRemovePurchaseOrderLine(index);
              }}
            >
              <Icon width={30} height={30} name="navigation-trash" />
            </IconWrapper>
          </DataPoint>
        </div>

        {!!productLine.product && !quotesGenerated && (
          <DataPoint fullWidth>
            <Table data={productLine.client_order_lines} columns={columns} hideUtilityBar={true} />
          </DataPoint>
        )}
        <DataPoint fullWidth centralisedButton>
          <SecondaryButton
            disabled={!!lineIdToEdit}
            appearance={"whiteButtonBlueText"}
            type="button"
            style={{ marginTop: "20px" }}
            onClick={() => {
              if (productLine.product && !quotesGenerated) {
                const totalQuantity = productLine.client_order_lines.reduce((prev, current) => {
                  return prev + (current.quantity_to_purchase ?? 0);
                }, 0);
                submitGetQuotationCalculation({
                  productId: productLine.product?.value,
                  supplierId: manufacturer.value,
                  quantity: totalQuantity,
                });
                setQuotesGenerated(prevQuotesGenerated => !prevQuotesGenerated);
              }
              if (!productLine.product) {
                toast.error("Please select a product and quantity");
              }
              if (quotesGenerated) {
                setQuotesGenerated(prevQuotesGenerated => !prevQuotesGenerated);
                setFieldValue(`purchase_order_lines[${index}].quotation_calculation_pair`, null);
                setFieldValue(`purchase_order_lines[${index}].supplier_quote_line_id`, "");
                setDisplayMessage(null);
                if (productLine.product) handleLeadTime(undefined, productLine.product.value);
              }
            }}
          >
            {quotesGenerated || hasQuoteSelected ? (
              <IconText secondaryIcon={"navigation-refresh"} text="Re-generate Quotes" />
            ) : (
              "Generate Quotes"
            )}
          </SecondaryButton>
        </DataPoint>
      </SecondaryLineDataWrapper>
      {shouldDisplayQuoteInfo() && (
        <>
          <SecondaryLineDataWrapper>
            <DataPoint>
              <SimpleSelect
                isRequired
                tooltipMessage={`<strong> The quote displays the full cost per unit </strong> <br /> Product Unit, Delivery and Setup costs are <br />  included in this price. Only valid quotes whose <br /> MOQ is lower that the value in the input box <br /> labelled quantity are displayed below.`}
                isGray={quotesGenerated}
                label="Quote"
                htmlFor={`quotation_calculation_pair_${index}`}
                options={
                  quotationCalculationPairs
                    ? quotationCalculationPairs.map(quotationCalculation => {
                        return {
                          value: quotationCalculation.quoteLineId,
                          label: `${
                            quotationCalculation.currencyCode
                          } ${quotationCalculation.unitCost.toString().substring(0, 4)}, ${
                            quotationCalculation.maxLeadTimeDays ?? "-"
                          } days`,
                        };
                      })
                    : []
                }
                value={getQuoteSelectionValue()}
                changeHandler={e => {
                  const quotationCost = quotationCalculationPairs!.find(
                    quotationCostPair => quotationCostPair.quoteLineId === e?.value
                  );
                  if (quotationCost) {
                    setFieldValue(`purchase_order_lines[${index}].quotation_calculation_pair`, e);
                    setFieldValue(
                      `purchase_order_lines[${index}].supplier_quote_line`,
                      quotationCost
                    );
                    handleLeadTime(quotationCost.maxLeadTimeDays, productLine.product!.value);
                  }

                  getSupplierQuoteLineById({ supplierQuoteLineId: e?.value! });
                }}
                placeholder={"Select Quote"}
                name="Quote"
                error={getQuoteSelectionError()}
                touched={
                  Array.isArray(touched) && touched[index]?.quotation_calculation_pair
                    ? touched[index]!.quotation_calculation_pair
                    : undefined
                }
              />
            </DataPoint>
            <DataPoint withUniqueMargin>
              <DisplayBox
                label="Supplier Reference"
                isGray
                value={
                  supplierQuoteLine ? supplierQuoteLine.supplier_reference : "Please Select a Quote"
                }
              />
            </DataPoint>
            <DataPoint withUniqueMargin>
              <DisplayBox
                isGray
                label="Unit price (Excl. VAT)"
                value={
                  productLine.quotation_calculation_pair
                    ? productLine.quotation_calculation_pair?.label.substring(
                        0,
                        productLine.quotation_calculation_pair?.label.lastIndexOf(",")
                      )
                    : "Please Select a Quote"
                }
              />
            </DataPoint>
          </SecondaryLineDataWrapper>

          <PrimaryLineWrapper css={{ width: "100%" }}>
            <DataPoint>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <CheckBox
                  handleChange={e => {
                    setFieldValue(
                      `purchase_order_lines[${index}].is_qa_required`,
                      e.target.checked
                    );
                  }}
                  value={"qa_inspection_required"}
                  id={`qa_inspection_required_${index}`}
                  label={"QC Inspection Required"}
                  isChecked={productLine.is_qa_required}
                />
                <ToolTip message={"Please input any further notes in the input box below"} />
              </div>
            </DataPoint>

            {productLine.is_qa_required && (
              <DataPoint fullWidth withUniqueMargin css={{ marginTop: "20px" }}>
                <TextArea
                  id={`qa_inspection_notes_${index}`}
                  size={"medium"}
                  type={"text"}
                  value={productLine.qa_notes}
                  placeholder={"Input QC Inspection Notes"}
                  handleChange={e => {
                    setFieldValue(`purchase_order_lines[${index}].qa_notes`, e.target.value);
                  }}
                />
              </DataPoint>
            )}
          </PrimaryLineWrapper>

          <SecondaryLineWrapper>
            <Title> Attachments </Title>
            <SecondaryLineDataWrapper style={{ flexDirection: "row" }}>
              <DataPoint halfWidth>
                {productLineAttachments &&
                  mapAttachmentsToSet(productLineAttachments).map(attachment => (
                    <AttachmentDownloader
                      key={attachment.id}
                      originalFilename={attachment.original_filename}
                      attachmentUUID={attachment.attachment_uuid}
                      metadata={attachment.metadata}
                    />
                  ))}
              </DataPoint>
            </SecondaryLineDataWrapper>
          </SecondaryLineWrapper>
        </>
      )}
    </SecondaryLineWrapper>
  );
};

export default ProductLine;
