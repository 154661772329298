import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleDateInput from "../../../../../../shared/components/forms/SimpleDateInput";
import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { mapSupplierCurrenciestoDropdownObject } from "../../../../suppliers/shared/mappers";
import { useCountryNames } from "../../../graphql/hooks/useCountryNames";
import { useProducts } from "../../../graphql/hooks/useProducts";
import { useQuotationCostTypes } from "../../../graphql/hooks/useQuotationCostTypes";
import { useSupplierDetails } from "../../../graphql/hooks/useSupplierDetails";
import { useSuppliers } from "../../../graphql/hooks/useSuppliers";
import { mapCountryNamesToDropdownObject } from "../../../graphql/mappers";
import { QuotationCostInterface } from "../../../updateQuotes/types";
import { syncDeliveryDateWithLeadTime } from "../../../util/syncDeliveryDateWithLeadTime";
import { CreateQuotationCostLinesBody, CreateQuoteBody, CreateQuoteLinesBody } from "../api/types";
import UpdatedQuoteLine from "../components/UpdatedQuoteLine";
import { QuoteForm, emptyQuoteLine, fieldNames, initialFieldValues } from "../formValues";
import {
  createInternalQuoteAction,
  createInternalQuoteReset,
} from "../redux/actions/createInternalQuoteAction";
import { CreateInternalQuoteReducer } from "../redux/reducers/createInternalQuoteReducer";
import { validationSchema } from "../validation";

interface Props {
  createInternalQuoteState: CreateInternalQuoteReducer;
  createInternalQuote: (body: CreateQuoteBody) => void;
  resetInternalQuote: () => void;
}

const CreateInternalQuotes = (props: Props) => {
  const [initialValues, setInitialValues] = useState<QuoteForm>(initialFieldValues);
  const [isAddingQuoteLines, setIsAddingQuoteLines] = useState<boolean>(false);

  const { roles } = useAuthorisationContext();

  const { productNames, productSKUs, productsLoading, productsError } = useProducts();
  const { suppliers, suppliersLoading, suppliersError } = useSuppliers();
  const { supplierDetails, supplierDetailsLoading, supplierDetailsError } = useSupplierDetails();
  const { countryNames, countryNamesLoading, countryNamesError } = useCountryNames();
  const { quotationCostTypes, quotationCostTypesLoading, quotationCostTypesError } =
    useQuotationCostTypes();
  const currencyCodes = mapSupplierCurrenciestoDropdownObject();

  const countryNamesOptions = useMemo(
    () => mapCountryNamesToDropdownObject(countryNames || []),
    [countryNames]
  );

  useEffect(() => {
    if (props.createInternalQuoteState.success) {
      setInitialValues({ ...initialFieldValues });
      props.resetInternalQuote();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createInternalQuoteState.success]);

  const onSubmit = (values: QuoteForm) => {
    if (!SOURCING_EDIT_ROLES.some(role => roles.includes(role))) {
      toast.error("Incorrect approval permissions");
      return;
    }

    if (!values.productSKU) {
      toast.error("Please select a product");
      return;
    }

    const momentValidFrom = moment(values.quoteValidFrom);
    const momentValidTo = moment(values.quoteValidTo);

    const validToIsLarger = momentValidTo > momentValidFrom;
    if (!validToIsLarger) {
      toast.error("Please ensure your valid to date is later than your valid from date");
      return;
    }

    const isPastFrom = moment().startOf("day") > momentValidFrom;
    const isPastTo = moment().startOf("day") > momentValidTo;

    if (isPastFrom || isPastTo) {
      toast.error("Valid date cannot be in the past");
      return;
    }

    const leadTimeMaxDays = Number(values.leadTimeMaxDays);
    const timeToHandoff = values.timeToHandoff !== "" ? Number(values.timeToHandoff) : undefined;

    if (timeToHandoff && leadTimeMaxDays < timeToHandoff) {
      toast.error(
        "Lead time has to be at least equal to time to handoff as it may include delivery."
      );
      return;
    }

    const unitCostQuotationType = quotationCostTypes.find(
      quotationCostType => quotationCostType.quotation_cost_type_name === "Product Unit Cost"
    );

    if (!unitCostQuotationType) {
      toast.error(
        "Something went wrong with finding the correct type of quotation cost. Please refresh the page"
      );
      return;
    }

    const quotationCostLines: CreateQuotationCostLinesBody[] = values.quoteLines.map(line => {
      return {
        cost_excl_vat: Number(line.cost),
        currency_code: values.currencyCode!.label,
        quotation_cost_type_id: line.costType!.value,
      };
    });

    const productUnitCosts = quotationCostLines.filter(
      quotationCostLine => quotationCostLine.quotation_cost_type_id === unitCostQuotationType.id
    );

    if (productUnitCosts.length === 0) {
      toast.error("Please insert at least one line with type `Product Unit Cost`");
      return;
    }

    const quotationLine: CreateQuoteLinesBody = {
      product_id: Number(values.productSKU.value),
      lead_time_max_days: timeToHandoff ?? leadTimeMaxDays,
      minimum_order_quantity: Number(values.moq),
      currency_code: values.currencyCode!.label,
      quotation_cost_lines: quotationCostLines,
      supplier_reference: values.supplierReference || undefined,
      country_code: values.country
        ? countryNames?.filter(country => values.country?.label === country.country_name)[0]
            .country_code
        : undefined,
    };

    const quotation: CreateQuoteBody = {
      supplier_organisation_id: Number(values.supplier!.value),
      valid_from: values.quoteValidFrom,
      valid_to: values.quoteValidTo,
      quote_line: quotationLine,
    };

    props.createInternalQuote(quotation);
  };

  const error =
    productsError ||
    supplierDetailsError ||
    suppliersError ||
    quotationCostTypesError ||
    countryNamesError;
  const isLoading =
    productsLoading ||
    supplierDetailsLoading ||
    suppliersLoading ||
    quotationCostTypesLoading ||
    countryNamesLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Quotation Form"}>
      <Panel withWrapper title={"Create a Quote"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched, handleReset }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <SimpleSelect
                      isRequired
                      htmlFor={fieldNames.supplier}
                      name={fieldNames.supplier}
                      placeholder="Select a supplier"
                      options={suppliers}
                      value={values.supplier}
                      error={errors.supplier}
                      touched={touched.supplier}
                      label="Supplier"
                      changeHandler={e => {
                        setFieldValue(fieldNames.supplier, e);
                        setFieldValue(fieldNames.currencyCode, null);
                      }}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleDateInput
                      isRequired
                      htmlFor={fieldNames.quoteValidFrom}
                      name={fieldNames.quoteValidFrom}
                      value={values.quoteValidFrom}
                      error={errors.quoteValidFrom}
                      touched={touched.quoteValidFrom}
                      label="Quote Valid From"
                      changeHandler={e => {
                        setFieldValue(fieldNames.quoteValidFrom, e.target.value);
                      }}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleDateInput
                      isRequired
                      htmlFor={fieldNames.quoteValidTo}
                      name={fieldNames.quoteValidTo}
                      value={values.quoteValidTo}
                      error={errors.quoteValidTo}
                      touched={touched.quoteValidTo}
                      label="Quote Valid To"
                      changeHandler={e => {
                        setFieldValue(fieldNames.quoteValidTo, e.target.value);
                      }}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleSelect
                      isRequired
                      htmlFor={fieldNames.currencyCode}
                      name={fieldNames.currencyCode}
                      placeholder="Select a currency code"
                      options={currencyCodes}
                      value={values.currencyCode}
                      error={errors.currencyCode}
                      touched={touched.currencyCode}
                      label="Quote currency code"
                      changeHandler={e => {
                        setFieldValue(fieldNames.currencyCode, e);
                        if (e && values.supplier) {
                          const currencyCode = e.label;
                          const supplierCurrencyCode = supplierDetails.find(
                            supplierDetails => supplierDetails.id === values.supplier!.value
                          )?.currencyCode;
                          if (currencyCode !== supplierCurrencyCode) {
                            toast.warn(
                              `You've selected ${currencyCode}, while the default locale currency is ${supplierCurrencyCode}. It is okay to proceed if intentional, but do double check.`
                            );
                          }
                        }
                      }}
                    />
                  </DataPoint>
                </TopPanelInputsWrapper>
                <PrimaryLineWrapper>
                  <Title>Quotation Details</Title>

                  <PrimaryLineDataWrapper>
                    <DataPoint halfWidth>
                      <SimpleSelect
                        name={fieldNames.productSKU}
                        placeholder="Select Product SKU"
                        options={productSKUs}
                        value={values.productSKU}
                        error={undefined}
                        touched={undefined}
                        label="Product SKU"
                        changeHandler={e => {
                          setFieldValue(fieldNames.productSKU, e);
                          if (e) {
                            const productName = productNames.filter(
                              product => product.value === e.value
                            );
                            if (productName.length === 1) {
                              setFieldValue(fieldNames.productName, productName[0]);
                            }
                          }
                        }}
                      />
                    </DataPoint>
                    <DataPoint halfWidth>
                      <SimpleSelect
                        name={fieldNames.productName}
                        placeholder="Select Product Name"
                        options={productNames}
                        value={values.productName}
                        error={undefined}
                        touched={undefined}
                        label="Product Name"
                        changeHandler={e => {
                          setFieldValue(fieldNames.productName, e);
                          if (e) {
                            const productSKU = productSKUs.filter(
                              product => product.value === e.value
                            );
                            if (productSKU.length === 1) {
                              setFieldValue(fieldNames.productSKU, productSKU[0]);
                            }
                          }
                        }}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>

                  <PrimaryLineDataWrapper>
                    <DataPoint>
                      <SimpleInput
                        isRequired
                        htmlFor={fieldNames.moq}
                        name={fieldNames.moq}
                        placeholder="100"
                        type="text"
                        value={values.moq}
                        error={errors.moq}
                        touched={touched.moq}
                        label="Minimum Order Quantity"
                        changeHandler={e => setFieldValue(fieldNames.moq, e.target.value)}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleDateInput
                        isRequired={false}
                        htmlFor={fieldNames.deliveryDate}
                        name={fieldNames.deliveryDate}
                        value={values.deliveryDate}
                        error={errors.deliveryDate}
                        touched={touched.deliveryDate}
                        label="Delivery Date"
                        changeHandler={e => {
                          setFieldValue(fieldNames.deliveryDate, e.target.value);
                          syncDeliveryDateWithLeadTime(
                            fieldNames.deliveryDate,
                            e.target.value,
                            setFieldValue
                          );
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isRequired
                        tooltipMessage="Lead time as agreed with client for them to receive the order."
                        htmlFor={fieldNames.leadTimeMaxDays}
                        name={fieldNames.leadTimeMaxDays}
                        placeholder="14"
                        type="text"
                        value={values.leadTimeMaxDays}
                        error={errors.leadTimeMaxDays}
                        touched={touched.leadTimeMaxDays}
                        label="Lead Time to Client"
                        changeHandler={e => {
                          setFieldValue(fieldNames.leadTimeMaxDays, e.target.value);
                          syncDeliveryDateWithLeadTime(
                            fieldNames.leadTimeMaxDays,
                            e.target.value,
                            setFieldValue
                          );
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        tooltipMessage="Time for production and delivery to Sourceful."
                        htmlFor={fieldNames.timeToHandoff}
                        name={fieldNames.timeToHandoff}
                        placeholder="14"
                        type="text"
                        value={values.timeToHandoff}
                        error={errors.timeToHandoff}
                        touched={touched.timeToHandoff}
                        label="Time to Handoff"
                        changeHandler={e => {
                          setFieldValue(fieldNames.timeToHandoff, e.target.value);
                        }}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>
                <PrimaryLineWrapper>
                  <Title>Supplier Reference Details</Title>
                  <PrimaryLineDataWrapper>
                    <DataPoint halfWidth>
                      <SimpleSelect
                        tooltipMessage="If a country is provided, the quote will be usable only for orders with delivery address to that country. ,<br /> If unsure, leave emptyand you would be able to use the quote across all addresses without restrictions."
                        name={fieldNames.country}
                        placeholder="Select Country"
                        options={countryNamesOptions}
                        value={values.country}
                        error={errors.country}
                        touched={touched.country}
                        label="Country Name"
                        changeHandler={e => {
                          setFieldValue(fieldNames.country, e);
                        }}
                      />
                    </DataPoint>

                    <DataPoint halfWidth>
                      <SimpleInput
                        tooltipMessage="If a reference is provided, it would be displayed in the magic link page and the Purchase<br />Order document going out to the supplier when an order with this quote is created."
                        htmlFor={fieldNames.supplierReference}
                        name={fieldNames.supplierReference}
                        type="text"
                        placeholder="Enter Supplier Reference"
                        value={values.supplierReference}
                        error={errors.supplierReference}
                        touched={touched.supplierReference}
                        label="Quotation Supplier Reference"
                        changeHandler={e =>
                          setFieldValue(fieldNames.supplierReference, e.target.value)
                        }
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>
                {!isAddingQuoteLines && (
                  <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                    <SecondaryButton
                      type="button"
                      onClick={() => {
                        setInitialValues({
                          ...values,
                          quoteLines: [{ ...emptyQuoteLine }],
                        });
                        setIsAddingQuoteLines(true);
                      }}
                      appearance={"whiteButtonBlueText"}
                    >
                      <IconText text={"Insert Quote"} primaryIcon={"alert-add-outline"} />
                    </SecondaryButton>
                  </ButtonWrapper>
                )}
                {isAddingQuoteLines && (
                  <>
                    <PrimaryLineWrapper>
                      <Title>Quotation Cost Breakdown</Title>

                      <IconText
                        text={
                          "Please ensure that at least one of the quotation costs is Product Unit Cost"
                        }
                        primaryIcon="alert-exclamation-outline"
                      />

                      {values.quoteLines.map((quoteLine, index) => (
                        <UpdatedQuoteLine
                          key={index}
                          index={index}
                          values={quoteLine}
                          errors={errors.quoteLines as FormikErrors<QuotationCostInterface[]>}
                          touched={touched.quoteLines}
                          handleRemoveQuoteLine={(index: number) => {
                            const filteredQuoteLines = values.quoteLines.filter(
                              (_, i) => i !== index
                            );
                            const newValues = { ...values };
                            newValues.quoteLines = filteredQuoteLines;
                            if (filteredQuoteLines.length === 0) {
                              setInitialValues(newValues);
                              setIsAddingQuoteLines(false);
                            }
                            setInitialValues(newValues);
                          }}
                          setFieldValue={setFieldValue}
                          quotationCostTypes={quotationCostTypes}
                        />
                      ))}

                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setInitialValues({
                              ...values,
                              quoteLines: [...values.quoteLines, { ...emptyQuoteLine }],
                            });
                          }}
                        >
                          <IconText
                            text={"Add new quotation cost line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>
                  </>
                )}
                {typeof errors.quoteLines === "string" &&
                  errors.quoteLines &&
                  touched.quoteLines && <ErrorMessage>{errors.quoteLines}</ErrorMessage>}
                <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                  <SecondaryButton
                    type="button"
                    appearance="whiteButton"
                    onClick={() => {
                      handleReset();
                      setInitialValues({ ...initialFieldValues });
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <SecondaryButton
                    type="button"
                    appearance="blueButton"
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Create Quote
                  </SecondaryButton>
                </ButtonGroup>
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createInternalQuoteState: state.createInternalQuoteReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createInternalQuote: (body: CreateQuoteBody) => dispatch(createInternalQuoteAction(body)),
    resetInternalQuote: () => dispatch(createInternalQuoteReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInternalQuotes);
