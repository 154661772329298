import { SOURCING_API } from "../../../../../../api/baseConfig";
import { CreateQuoteBody } from "./types";

export const createInternalQuoteAPI = async (body: CreateQuoteBody) => {
  try {
    return await SOURCING_API.post("/internal-quotes", body);
  } catch (err) {
    console.log("Error in create Quotes API call", err);
    throw err;
  }
};
