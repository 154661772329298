import { ApolloError } from "@apollo/client";

import { useGetAddressesWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { AddressItem } from "../types";

export interface HookResult {
  addresses: AddressItem[];
  addressesError?: ApolloError;
  addressesLoading: boolean;
}

export const useAddressesWithData = (): HookResult => {
  const { data, error, loading } = useGetAddressesWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    addresses: data && Array.isArray(data.address) ? data.address : [],
    addressesError: error,
    addressesLoading: loading,
  };
};
