import { ApolloError } from "@apollo/client";

import { useGetPurchaseOrderStatusForQuoteQuery } from "../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../shared/constants";
import { PurchaseOrderStatus } from "../types";

export interface HookResult {
  purchaseOrderStatus?: PurchaseOrderStatus[];
  purchaseOrderStatusError?: ApolloError;
  purchaseOrderStatusLoading: boolean;
}

export const usePurchaseOrderStatus = (quoteId?: number): HookResult => {
  const { data, error, loading } = useGetPurchaseOrderStatusForQuoteQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
    variables: {
      quoteId: quoteId,
    },
  });

  return {
    purchaseOrderStatus:
      data && Array.isArray(data.purchase_order) ? data.purchase_order : undefined,
    purchaseOrderStatusError: error,
    purchaseOrderStatusLoading: loading,
  };
};
