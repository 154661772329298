import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { returnOutboundManifestLineAPI } from "../../api/returnOutboundManifestLineAPI";
import { CreateOutboundReturnBody } from "../../api/types";
import {
  RETURN_OUTBOUND_MANIFEST_LINE_FAILED,
  RETURN_OUTBOUND_MANIFEST_LINE_LOADING,
  RETURN_OUTBOUND_MANIFEST_LINE_RESET,
  RETURN_OUTBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export const returningOutboundManifestLine = () => {
  return {
    type: RETURN_OUTBOUND_MANIFEST_LINE_LOADING,
  };
};

export const returnOutboundManifestLineSuccess = () => {
  return {
    type: RETURN_OUTBOUND_MANIFEST_LINE_SUCCESS,
  };
};

export const returnOutboundManifestLineFailed = () => {
  return {
    type: RETURN_OUTBOUND_MANIFEST_LINE_FAILED,
  };
};

export const returnOutboundManifestLineReset = () => {
  return {
    type: RETURN_OUTBOUND_MANIFEST_LINE_RESET,
  };
};

export const returnOutboundManifestLineAction = (body: CreateOutboundReturnBody) => {
  return async (dispatch: Dispatch) => {
    toast("Returning Outbound Manifest Line", { hideProgressBar: true });
    dispatch(returningOutboundManifestLine());
    try {
      await returnOutboundManifestLineAPI(body);
      toast.success("Outbound Manifest Line returned successfully.");
      dispatch(returnOutboundManifestLineSuccess());
    } catch (err) {
      toast.error("Something went wrong with returning outbound manifest line.");
      dispatch(returnOutboundManifestLineFailed());
    }
  };
};
