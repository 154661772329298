import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { attachInvoicesToPurchaseOrderAPI } from "../../api/attachInvoicesToPurchaseOrder";
import { AttachInvoicesToPurchaseOrderBody } from "../../api/types/index";
import {
  ATTACH_INVOICES_TO_PURCHASE_ORDER_FAILED,
  ATTACH_INVOICES_TO_PURCHASE_ORDER_LOADING,
  ATTACH_INVOICES_TO_PURCHASE_ORDER_RESET,
  ATTACH_INVOICES_TO_PURCHASE_ORDER_SUCCESS,
} from "../types/index";

export const attachInvoicesToPurchaseOrderLoading = () => {
  return {
    type: ATTACH_INVOICES_TO_PURCHASE_ORDER_LOADING,
  };
};

export const attachInvoicesToPurchaseOrderSuccess = () => {
  return {
    type: ATTACH_INVOICES_TO_PURCHASE_ORDER_SUCCESS,
  };
};

export const attachInvoicesToPurchaseOrderFailed = () => {
  return {
    type: ATTACH_INVOICES_TO_PURCHASE_ORDER_FAILED,
  };
};

export const attachInvoicesToPurchaseOrderReset = () => {
  return {
    type: ATTACH_INVOICES_TO_PURCHASE_ORDER_RESET,
  };
};

export const attachInvoicesToPurchaseOrderAction = (
  purchaseOrderId: number,
  body: AttachInvoicesToPurchaseOrderBody
) => {
  return async (dispatch: Dispatch) => {
    toast("Attaching Invoices", { hideProgressBar: true });
    dispatch(attachInvoicesToPurchaseOrderLoading());
    try {
      await attachInvoicesToPurchaseOrderAPI(purchaseOrderId, body);
      toast.success("Invoices attached to Purchase Order successfully");
      dispatch(attachInvoicesToPurchaseOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with attaching invoices.");
      dispatch(attachInvoicesToPurchaseOrderFailed());
    }
  };
};
