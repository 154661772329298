import { AxiosResponse } from "axios";
import { Formik } from "formik";
import { useEffect, useState } from "react";

import { CheckBox, Icon, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { PRODUCT_TYPE_ID } from "../../../../../shared/constants";
import {
  mapCountryCodeToDropdownItem,
  mapCurrencyCodeToDropdownItem,
} from "../../../../../shared/mappers";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  IconWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
} from "../../../../../styles/SharedStyles";
import { ProductCatalogueSkuBody } from "../../../../sourcing/shared/fetchSku/api/types";
import {
  DeliveryCostsFormValues,
  UpdateSkuDataInterface,
  updateSkuDataFieldNames,
} from "../../shared/types";
import { UpdateSkuMetaData } from "../api/updateSkuMetadata";
import {
  mapDeliveryCostsForForm,
  mapDeliveryCostsToSubmitForm,
  mapProductTypeToDropdownItem,
  validDeliveryCosts,
} from "../mappers";
import { TitleWrapper, Warning } from "../page/UpdateSku";
import { updateSkuDataValidationSchema } from "../validation";

interface UpdateSkuFormProps {
  sku: ProductCatalogueSkuBody | undefined;
  skuId: string;
  updateSku: (
    data: UpdateSkuMetaData | UpdateSkuMetaData[]
  ) => Promise<AxiosResponse<any> | AxiosResponse<any>[]>;
}

const UpdateSkuForm = ({ sku, skuId, updateSku }: UpdateSkuFormProps) => {
  const [initialUpdateSkuDataValues, setInitialUpdateSkuDataValues] =
    useState<UpdateSkuDataInterface>({
      imageURL: "https://",
      packSize: 0,
      productType: {
        label: "Other",
        value: 3,
      },
      deliveryCosts: [],
      leadTimeMin: undefined,
      leadTimeMax: undefined,
      leadTimeUnits: undefined,
      businessDaysOnly: false,
    });

  useEffect(() => {
    if (sku) {
      setInitialUpdateSkuDataValues({
        imageURL: sku.image_url || "https://",
        packSize: sku.pack_sizes ? sku.pack_sizes[0] : 0,
        productType: {
          label: PRODUCT_TYPE_ID[sku.sku_source_type_id],
          value: sku.sku_source_type_id || 3,
        },
        deliveryCosts: mapDeliveryCostsForForm(sku.delivery_costs),
        leadTimeMin: sku.manufacturing_lead_time?.min || undefined,
        leadTimeMax: sku.manufacturing_lead_time?.max || undefined,
        leadTimeUnits: sku.manufacturing_lead_time?.units || "days",
        businessDaysOnly: sku.manufacturing_lead_time?.businessDaysOnly || false,
      });
    }
    // eslint-disable-next-line
  }, [sku]);

  const handleSubmitUpdateSkuMetadata = async (values: any) => {
    if (sku && values) {
      const body: UpdateSkuMetaData = {
        uuid: skuId,
        sku_source_type_id: values.productType.value,
        pack_sizes: [Number(values.packSize)],
        image_url: values.imageURL,
        delivery_costs:
          PRODUCT_TYPE_ID[values.productType.value] === "Warehouse"
            ? mapDeliveryCostsToSubmitForm(values.deliveryCosts)
            : [],
        manufacturing_lead_time:
          PRODUCT_TYPE_ID[values.productType.value] === "Dropship"
            ? {
                min: Number(values.leadTimeMin),
                max: Number(values.leadTimeMax),
                units: values.leadTimeUnits,
                businessDaysOnly: values.businessDaysOnly,
              }
            : {},
      };
      await updateSku(body).catch(error => {
        console.error("error updating sku in UpdateSku:", error);
      });
    }
  };

  const handleAddDeliveryCost = (setFieldValue: any, values: any) => {
    if (sku) {
      const newDeliveryCostIndex: number = values.deliveryCosts.length;
      const validDeliveryCostsArray = validDeliveryCosts(values.deliveryCosts);
      const newCountryCode: string =
        validDeliveryCostsArray.find(
          (deliveryCost: DeliveryCostsFormValues) => deliveryCost.countryCode.label === "GB"
        ) !== undefined
          ? "US"
          : "GB";
      const newCurrencyCode: string =
        validDeliveryCostsArray.find(
          (deliveryCost: DeliveryCostsFormValues) => deliveryCost.currencyCode.label === "GBP"
        ) !== undefined
          ? "USD"
          : "GBP";
      const newDeliveryCosts: DeliveryCostsFormValues[] = [
        ...validDeliveryCostsArray,
        {
          costPerPackExclVat: 0,
          countryCode: { label: newCountryCode, value: newDeliveryCostIndex },
          currencyCode: { label: newCurrencyCode, value: newDeliveryCostIndex },
        },
      ];
      setFieldValue(updateSkuDataFieldNames.deliveryCosts, newDeliveryCosts);
    }
  };

  return (
    <Formik
      initialValues={initialUpdateSkuDataValues}
      enableReinitialize
      onSubmit={async (values: UpdateSkuDataInterface) => {
        await handleSubmitUpdateSkuMetadata(values);
      }}
      validationSchema={updateSkuDataValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => (
        <StyledForm onSubmit={handleSubmit}>
          <PrimaryLineWrapper>
            <TitleWrapper>
              <Title>Auxiliary SKU information</Title>
              {values.packSize <= 0 && <Warning>Pack size must be set</Warning>}
            </TitleWrapper>
            <PrimaryLineDataWrapper>
              <DataPoint halfWidth>
                <SimpleInput
                  htmlFor={updateSkuDataFieldNames.imageURL}
                  name={updateSkuDataFieldNames.imageURL}
                  size={"medium"}
                  error={errors.imageURL}
                  touched={touched.imageURL}
                  label={"Image URL"}
                  type={"text"}
                  placeholder={"https://"}
                  value={values.imageURL}
                  changeHandler={event => {
                    setFieldValue(updateSkuDataFieldNames.imageURL, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint>
                <SimpleInput
                  htmlFor={updateSkuDataFieldNames.packSize}
                  name={updateSkuDataFieldNames.packSize}
                  size={"medium"}
                  error={errors.packSize}
                  touched={touched.packSize}
                  label={"Pack Size"}
                  placeholder={"0"}
                  min={0}
                  type={"number"}
                  value={values.packSize}
                  changeHandler={event => {
                    setFieldValue(updateSkuDataFieldNames.packSize, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint>
                <SimpleSelect
                  htmlFor={updateSkuDataFieldNames.productType}
                  name={updateSkuDataFieldNames.productType}
                  maxMenuHeight={220}
                  error={undefined}
                  touched={undefined}
                  placeholder="Select product type"
                  tooltipMessage="Lead time details can only be added for Dropship products."
                  label="Product Type"
                  options={mapProductTypeToDropdownItem()}
                  value={values.productType}
                  changeHandler={event => {
                    setFieldValue(updateSkuDataFieldNames.productType, event);
                  }}
                />
              </DataPoint>
            </PrimaryLineDataWrapper>

            {values.productType.label === "Dropship" && (
              <>
                <PrimaryLineDataWrapper>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={updateSkuDataFieldNames.leadTimeMin}
                      name={updateSkuDataFieldNames.leadTimeMin}
                      size={"medium"}
                      error={errors.leadTimeMin}
                      touched={touched.leadTimeMin}
                      label={"Lead time: minimum"}
                      placeholder={"0"}
                      min={0}
                      type={"number"}
                      value={values.leadTimeMin}
                      changeHandler={event => {
                        setFieldValue(updateSkuDataFieldNames.leadTimeMin, event.target.value);
                      }}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={updateSkuDataFieldNames.leadTimeMax}
                      name={updateSkuDataFieldNames.leadTimeMax}
                      size={"medium"}
                      error={errors.leadTimeMax}
                      touched={touched.leadTimeMax}
                      label={"Lead time: maximum"}
                      placeholder={"0"}
                      min={0}
                      type={"number"}
                      value={values.leadTimeMax}
                      changeHandler={event => {
                        setFieldValue(updateSkuDataFieldNames.leadTimeMax, event.target.value);
                      }}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleInput
                      htmlFor={updateSkuDataFieldNames.leadTimeUnits}
                      name={updateSkuDataFieldNames.leadTimeUnits}
                      size={"medium"}
                      error={errors.leadTimeUnits}
                      touched={touched.leadTimeUnits}
                      label={"Lead time units (e.g. days)"}
                      placeholder={"days"}
                      type={"text"}
                      value={values.leadTimeUnits}
                      changeHandler={event => {
                        setFieldValue(updateSkuDataFieldNames.leadTimeUnits, event.target.value);
                      }}
                    />
                  </DataPoint>
                </PrimaryLineDataWrapper>
                <PrimaryLineDataWrapper>
                  <DataPoint>
                    <CheckBox
                      label={"Lead time includes business days only"}
                      value={updateSkuDataFieldNames.businessDaysOnly}
                      id={updateSkuDataFieldNames.businessDaysOnly}
                      isChecked={values.businessDaysOnly ?? false}
                      handleChange={event => {
                        setFieldValue(
                          updateSkuDataFieldNames.businessDaysOnly,
                          event.target.checked
                        );
                      }}
                    />
                  </DataPoint>
                </PrimaryLineDataWrapper>
              </>
            )}
            <PrimaryLineDataWrapper alignStart>
              {validDeliveryCosts(values.deliveryCosts).length > 0 &&
                PRODUCT_TYPE_ID[values.productType.value] === "Warehouse" &&
                validDeliveryCosts(values.deliveryCosts).map(
                  (deliveryCost: DeliveryCostsFormValues, index: number) => {
                    return (
                      <Box
                        type={"fitToContent"}
                        smallMargin
                        key={`${deliveryCost.countryCode.value}`}
                      >
                        <DataPoint>
                          <SimpleInput
                            htmlFor={updateSkuDataFieldNames.deliveryCosts}
                            name={updateSkuDataFieldNames.deliveryCosts}
                            size={"medium"}
                            error={undefined}
                            touched={undefined}
                            label={"Delivery Fee: per pack, excluding VAT"}
                            placeholder={"0"}
                            min={0}
                            type={"number"}
                            value={deliveryCost.costPerPackExclVat}
                            changeHandler={event => {
                              setFieldValue(`deliveryCosts[${index}]`, {
                                ...deliveryCost,
                                costPerPackExclVat: event.target.value,
                              });
                            }}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            htmlFor={updateSkuDataFieldNames.deliveryCosts}
                            name={updateSkuDataFieldNames.deliveryCosts}
                            maxMenuHeight={220}
                            error={undefined}
                            touched={undefined}
                            placeholder="Select country code"
                            label="Delivery Fee: Country Code"
                            options={mapCountryCodeToDropdownItem([
                              { country_code: "GB" },
                              { country_code: "US" },
                            ])}
                            value={deliveryCost.countryCode}
                            changeHandler={event => {
                              setFieldValue(`deliveryCosts[${index}]`, {
                                ...deliveryCost,
                                countryCode: event,
                              });
                            }}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            htmlFor={updateSkuDataFieldNames.deliveryCosts}
                            name={updateSkuDataFieldNames.deliveryCosts}
                            maxMenuHeight={220}
                            error={undefined}
                            touched={undefined}
                            placeholder="Select currency code"
                            label="Delivery Fee: Currency Code"
                            options={mapCurrencyCodeToDropdownItem([
                              { currency_code: "GBP" },
                              { currency_code: "USD" },
                            ])}
                            value={deliveryCost.currencyCode}
                            changeHandler={event => {
                              setFieldValue(`deliveryCosts[${index}]`, {
                                ...deliveryCost,
                                currencyCode: event,
                              });
                            }}
                          />
                        </DataPoint>

                        <IconWrapper
                          type="button"
                          onClick={() => {
                            if (values.deliveryCosts.length === 1) {
                              setFieldValue(updateSkuDataFieldNames.deliveryCosts, []);
                            }
                            setFieldValue(`deliveryCosts[${index}]`, undefined);
                          }}
                          removalIconWrapper
                        >
                          <Icon name={"alert-cross-outline"} />
                        </IconWrapper>
                      </Box>
                    );
                  }
                )}
            </PrimaryLineDataWrapper>
            <PrimaryLineDataWrapper>
              {/* delivery fees are only added to warehouse products, and for now 
                      you can only add two for the two supported currencies: USD & GPB */}
              {PRODUCT_TYPE_ID[values.productType.value] === "Warehouse" &&
                (validDeliveryCosts(values.deliveryCosts).length === 0 ||
                  validDeliveryCosts(values.deliveryCosts).length === 1) && (
                  <ButtonWrapper style={{ marginTop: "10px" }}>
                    <SecondaryButton
                      onClick={() => handleAddDeliveryCost(setFieldValue, values)}
                      appearance={"blueButton"}
                      isWithinTable
                      type="button"
                    >
                      Add new delivery fee
                    </SecondaryButton>
                  </ButtonWrapper>
                )}
            </PrimaryLineDataWrapper>
            <VisibleFor roles={SM_ADMIN_ROLES}>
              <ButtonWrapper style={{ marginTop: "10px" }}>
                <SecondaryButton type="submit" appearance={"blueButton"} isWithinTable>
                  Update SKU
                </SecondaryButton>
              </ButtonWrapper>
            </VisibleFor>
          </PrimaryLineWrapper>
        </StyledForm>
      )}
    </Formik>
  );
};

export default UpdateSkuForm;
