import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import Panel from "../../../../../components/panel/panel";
import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  Box,
  DataPoint,
  FlexTitle,
  InnerPanelWrapper,
  Label,
  PanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { isEmptyOrUndefined } from "../../../../../util/isEmptyOrUndefined";
import { negativePurchaseOrderStatuses } from "../../../purchaseOrders/updatePurchaseOrders/types";
import { prettifyProductName } from "../../../shared/mappers";
import { CreatePrePurchaseOrderBody } from "../api/types";
import ClientOrderLineFulfilment from "../components/ClientOrderLineFulfilment";
import ClientOrderLineFulfilmentForm from "../components/ClientOrderLineFulfilmentForm";
import DeleteClientOrder from "../components/DeleteClientOrder";
import { ViewRelatedPurchaseOrders } from "../components/ViewRelatedPurchaseOrders";
import { FULFILMENT_TYPE } from "../formValues";
import { useClientOrder } from "../graphql/hooks/useClientOrder";
import { PurchaseOrderLine } from "../graphql/types";
import { flattenedClientOrderAttachments } from "../mappers";
import {
  createPrePurchaseOrderAction,
  createPrePurchaseOrderReset,
} from "../redux/actions/createPrePurchaseOrderAction";
import { CreatePrePurchaseOrderReducer } from "../redux/reducers/createPrePurchaseOrderReducer";

interface Props {
  prePurchaseOrderState: CreatePrePurchaseOrderReducer;
  createPrePurchaseOrder: (body: CreatePrePurchaseOrderBody, clientOrderId: number) => void;
  resetPrePurchaseOrder: () => void;
}

function UpdateClientOrder(props: Props) {
  const [lineToView, setLineToView] = useState<number | null>(null);
  const [lineToFulfil, setLineToFulfil] = useState<number | null>(null);

  const { prePurchaseOrderState, createPrePurchaseOrder, resetPrePurchaseOrder } = props;

  const { clientOrderId, clientOrderLineId } =
    useParams<{ clientOrderId: string; clientOrderLineId: string }>();

  const [showPurchaseOrders, setShowPurchaseOrders] = useState<boolean>(false);

  const [purchaseOrderLines, setPurchaseOrderLines] = useState<PurchaseOrderLine[]>([]);

  const [allPurchaseOrdersInNegativeState, setAllPurchaseOrdersInNegativeState] =
    useState<boolean>(false);

  const { clientOrder, clientOrderLoading, clientOrderError, refetchClientOrder } = useClientOrder({
    clientOrderId: Number(clientOrderId),
  });

  const getAllPurchaseOrderLines = useCallback(() => {
    const purchaseOrderLines: PurchaseOrderLine[] = [];

    clientOrder?.client_order_lines.forEach(clientOrderLine => {
      clientOrderLine.pre_purchase_orders.forEach(prePurchaseOrder => {
        prePurchaseOrder.purchase_order_line_allocations.forEach(purchaseOrderLineAllocation => {
          purchaseOrderLines.push(purchaseOrderLineAllocation.purchase_order_line);
        });
      });
    });

    return purchaseOrderLines;
  }, [clientOrder]);

  const getPurchaseOrderLinesForClientOrderLine = useCallback(
    (clientOrderLineId: number) => {
      const purchaseOrderLines: PurchaseOrderLine[] = [];

      clientOrder?.client_order_lines.forEach(clientOrderLine => {
        if (clientOrderLine.id === clientOrderLineId) {
          clientOrderLine.pre_purchase_orders.forEach(prePurchaseOrder => {
            prePurchaseOrder.purchase_order_line_allocations.forEach(
              purchaseOrderLineAllocation => {
                purchaseOrderLines.push(purchaseOrderLineAllocation.purchase_order_line);
              }
            );
          });
        }
      });

      return purchaseOrderLines;
    },
    [clientOrder]
  );

  useEffect(() => {
    const allPOsNegativeState = purchaseOrderLines.every(purchaseOrderLine => {
      const purchaseOrderStatus =
        purchaseOrderLine.purchase_order.purchase_order_status.purchase_order_status_name;

      return negativePurchaseOrderStatuses.includes(purchaseOrderStatus);
    });

    setAllPurchaseOrdersInNegativeState(allPOsNegativeState);
  }, [purchaseOrderLines]);

  useEffect(() => {
    if (prePurchaseOrderState.success) {
      resetPrePurchaseOrder();
      setLineToFulfil(null);
      setLineToView(null);
      refetchClientOrder();
    }

    if (prePurchaseOrderState.error) {
      resetPrePurchaseOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prePurchaseOrderState]);

  useEffect(() => {
    if (isEmptyOrUndefined(clientOrderLineId)) {
      setPurchaseOrderLines(getAllPurchaseOrderLines());
    } else {
      setPurchaseOrderLines(getPurchaseOrderLinesForClientOrderLine(Number(clientOrderLineId)));
    }
  }, [clientOrderLineId, getAllPurchaseOrderLines, getPurchaseOrderLinesForClientOrderLine]);

  const handleFulfil = ({
    client_order_line_id,
    product_id,
    quantity,
    suggested_supplier_quote_line_id,
  }: {
    client_order_line_id: number;
    product_id: number;
    quantity: number;
    suggested_supplier_quote_line_id?: number | null;
  }) => {
    const selectedClientOrderLine = clientOrder?.client_order_lines.find(
      client_order_line => client_order_line.id === client_order_line_id
    );

    const clientOrderLineAllocatedQty = selectedClientOrderLine?.pre_purchase_orders.reduce(
      (sum, fulfilment) => sum + fulfilment.quantity,
      0
    );

    const clientOrderLineFulfilledQty = selectedClientOrderLine?.fulfilled_quantity;
    const clientOrderLineQty = selectedClientOrderLine?.quantity;
    const clientOrderLineCancelledQty = selectedClientOrderLine?.cancelled_quantity;

    const tooMuchAllocatedQty = clientOrderLineAllocatedQty! + quantity > clientOrderLineQty!;

    if (tooMuchAllocatedQty) {
      toast.error("You cannot allocated more than has been purchased.");
      return;
    }

    const tooMuchQty =
      quantity + clientOrderLineFulfilledQty! > clientOrderLineQty! - clientOrderLineCancelledQty!;

    if (tooMuchQty) {
      toast.error("Overfulfilment Attempt ");
      return;
    }
    const body: CreatePrePurchaseOrderBody = {
      product_id,
      quantity,
      suggested_supplier_quote_line_id: suggested_supplier_quote_line_id
        ? suggested_supplier_quote_line_id
        : undefined,
    };

    createPrePurchaseOrder(body, client_order_line_id);
  };

  const handleViewRelatedPurchaseOrders = () => {
    setShowPurchaseOrders(showPurchaseOrders => !showPurchaseOrders);
  };

  const isLoading = clientOrderLoading || prePurchaseOrderState.loading;

  const error = clientOrderError;

  return (
    <Page title={"Client Orders"} isLoading={isLoading} error={error}>
      <Panel
        withWrapper
        title={"Update Client Order"}
        subtitle={
          "Update the Client Order by choosing ways to fulfil each of the order lines. Multiple fulfilment of different types are allowed."
        }
        sourcingStatusName={clientOrder?.client_order_status.client_order_status_name}
      >
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <DataPoint>
              <Label isGray> Client Order ID</Label>
              <Box>{`#${clientOrder?.external_client_order_id || ""}`}</Box>
            </DataPoint>
            <DataPoint>
              <Label isGray> Client Name</Label>
              <Box>{clientOrder?.organisation.organisation_name}</Box>
            </DataPoint>
            <DataPoint>
              <Label isGray>Date Order Placed</Label>
              <Box>{moment(clientOrder?.created_at).format("DD-MM-YYYY")}</Box>
            </DataPoint>
            <DataPoint>
              {/* TODO: Make it clickable so user can view full address */}
              <Label isGray>Delivery Address</Label>
              <Box>{clientOrder?.address.address_name}</Box>
            </DataPoint>
            <DataPoint>
              <Label isGray> Source Service</Label>
              <Box>{clientOrder?.service_account.service_account_name}</Box>
            </DataPoint>
            {(clientOrder?.client_promised_delivery_from_date ||
              clientOrder?.client_promised_delivery_to_date) && (
              <DataPoint>
                <Label isGray> Client Expected Delivery Date</Label>
                <Box>
                  {clientOrder?.client_promised_delivery_from_date && (
                    <>
                      from{" "}
                      {moment(clientOrder?.client_promised_delivery_from_date).format("DD-MM-YYYY")}{" "}
                      to{" "}
                    </>
                  )}
                  {moment(clientOrder?.client_promised_delivery_to_date).format("DD-MM-YYYY")}
                </Box>
              </DataPoint>
            )}
          </TopPanelInputsWrapper>
          {clientOrder?.client_order_lines
            .filter(orderLine => {
              if (clientOrderLineId) return orderLine.id === Number(clientOrderLineId);
              return true;
            })
            .map((client_order_line, index) => {
              const allocatedQty = client_order_line.pre_purchase_orders.reduce(
                (sum, fulfilment) => sum + fulfilment.quantity,
                0
              );

              return (
                <PanelWrapper key={client_order_line.id}>
                  <PrimaryLineWrapper>
                    <FlexTitle>
                      <Title>Client Order Line #{index + 1}</Title>
                      <StatusBadge
                        key={`status-badge-${client_order_line.id}`}
                        type="dot"
                        statusName={
                          client_order_line.client_order_line_status.client_order_line_status_name
                        }
                      />
                    </FlexTitle>
                    <PrimaryLineDataWrapper>
                      <DisplayBox
                        fullWidth
                        withUniqueMargin
                        isGray
                        label="Product"
                        value={prettifyProductName(client_order_line.product.product_name)}
                      />
                      <SecondaryLineDataWrapper>
                        <DataPoint withUniqueMargin={true}>
                          <Label isGray> Quantity </Label>
                          <Box> {client_order_line.quantity} </Box>
                        </DataPoint>
                        <DataPoint withUniqueMargin={true}>
                          <ToolTip
                            isGray
                            message={
                              "Quantity that has been selected for a specific fulfilment type (ex: purchase order or existing stock)"
                            }
                            title={"Allocated for Fulfilment Quantity"}
                          />
                          <Box>{allocatedQty}</Box>
                        </DataPoint>
                        <DataPoint withUniqueMargin={true}>
                          <ToolTip
                            isGray
                            message={
                              "Quantity that has been fulfilled through the selected method."
                            }
                            title={"Fulfilled Quantity"}
                          />
                          <Box> {client_order_line.fulfilled_quantity} </Box>
                        </DataPoint>
                        <DataPoint withUniqueMargin={true}>
                          <Label isGray> Delivered Quantity</Label>
                          <Box> {client_order_line.delivered_quantity} </Box>
                        </DataPoint>
                      </SecondaryLineDataWrapper>
                    </PrimaryLineDataWrapper>

                    {/* TODO: if bundle product, add 2 buttons - order inputs and show input fulfillments */}
                    <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                      {lineToView !== client_order_line.id &&
                        client_order_line.pre_purchase_orders.length > 0 && (
                          <SecondaryButton
                            onClick={() => setLineToView(client_order_line.id)}
                            appearance={"whiteButtonBlueText"}
                          >
                            View Fulfilments
                          </SecondaryButton>
                        )}

                      {lineToView === client_order_line.id && (
                        <SecondaryButton
                          onClick={() => setLineToView(null)}
                          appearance={"whiteButton"}
                        >
                          Stop Viewing Fulfilments
                        </SecondaryButton>
                      )}
                      {lineToFulfil !== client_order_line.id &&
                        allocatedQty < client_order_line.quantity &&
                        client_order_line.client_order_line_status.client_order_line_status_name !==
                          "Archived" && (
                          <VisibleFor roles={SOURCING_EDIT_ROLES}>
                            <SecondaryButton
                              onClick={() => setLineToFulfil(client_order_line.id)}
                              appearance={"blueButton"}
                            >
                              Fulfil
                            </SecondaryButton>
                          </VisibleFor>
                        )}

                      {lineToFulfil === client_order_line.id && (
                        <SecondaryButton
                          onClick={() => setLineToFulfil(null)}
                          appearance={"blueButton"}
                        >
                          Cancel Fulfil
                        </SecondaryButton>
                      )}
                    </ButtonGroup>
                    {/* TODO: Display actual Location of stock using client order line designation table or purchase order allocation*/}
                    {lineToView === client_order_line.id &&
                      client_order_line.pre_purchase_orders.map(
                        (pre_purchase_order, fulfilmentIndex) => {
                          return (
                            <ClientOrderLineFulfilment
                              key={fulfilmentIndex}
                              fulfilmentIndex={fulfilmentIndex}
                              client_order_line_fulfilment={pre_purchase_order}
                            />
                          );
                        }
                      )}
                  </PrimaryLineWrapper>

                  {lineToFulfil === client_order_line.id && (
                    <ClientOrderLineFulfilmentForm
                      handleFulfil={handleFulfil}
                      lineToFulfil={lineToFulfil}
                      orderLineIndex={index}
                      clientOrderLineFulfilmentTypes={[
                        {
                          label: FULFILMENT_TYPE.PURCHASE_ORDER,
                          value: 1,
                        },
                      ]}
                      setLineToFulfil={setLineToFulfil}
                      productId={client_order_line.product.id}
                      suggestedSupplierQuoteLineId={
                        client_order_line.suggested_supplier_quote_line_id
                      }
                    />
                  )}
                </PanelWrapper>
              );
            })}
          <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
            <SecondaryButton
              appearance={"whiteButtonBlueText"}
              onClick={handleViewRelatedPurchaseOrders}
            >
              {showPurchaseOrders ? "Hide" : "View"} Related Purchase Orders
            </SecondaryButton>
            {clientOrderLineId && (
              <Link to={`/client-orders/update-client-order/${clientOrderId}`}>
                <SecondaryButton appearance={"whiteButtonBlueText"}>
                  View Full Order
                </SecondaryButton>
              </Link>
            )}
          </ButtonGroup>
          {showPurchaseOrders && (
            <ViewRelatedPurchaseOrders purchaseOrderLines={purchaseOrderLines} />
          )}
          <PrimaryLineWrapper>
            <Title>Attachments</Title>
            <PrimaryLineDataWrapper>
              {clientOrder?.folder && clientOrder?.folder.attachments.length > 0 ? (
                <>
                  {flattenedClientOrderAttachments(clientOrder).map(attachment => (
                    <AttachmentDownloader
                      key={attachment.id}
                      originalFilename={attachment.original_filename}
                      attachmentUUID={attachment.attachment_uuid}
                      metadata={attachment.metadata}
                    />
                  ))}
                </>
              ) : (
                <div> No attachments have been added to this client order.</div>
              )}
            </PrimaryLineDataWrapper>
          </PrimaryLineWrapper>
          {!!clientOrder && (
            <DeleteClientOrder
              clientOrderId={clientOrder.id}
              clientOrderStatus={clientOrder.client_order_status.client_order_status_name}
              allPurchaseOrdersInNegativeState={allPurchaseOrdersInNegativeState}
              refetchClientOrder={refetchClientOrder}
            />
          )}
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    prePurchaseOrderState: state.createPrePurchaseOrderReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createPrePurchaseOrder: (body: CreatePrePurchaseOrderBody, clientOrderLineId: number) =>
      dispatch(createPrePurchaseOrderAction(clientOrderLineId, body)),
    resetPrePurchaseOrder: () => dispatch(createPrePurchaseOrderReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateClientOrder);
