import React, { useState } from "react";

import { Icon, styled } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

export interface MenuOption {
  id: number;
  label: string;
  iconName?: IconName;
  handleClick: () => void;
}

interface MenuItemProps {
  label: string;
  iconName?: IconName;
  handleClick: () => void;
}

interface ActionMenuProps {
  menuOptions: MenuOption[];
}

const MenuItemContainer = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  position: "absolute",
  top: "5%",
  zIndex: 1,
  right: "-8%",
  width: "10%",
});

const ActionMenuContainer = styled("div", {
  display: "flex",
  flexGrow: "1",
  alignItems: "center",
});

const IconWrapper = styled("div", {
  "&:hover": {
    cursor: "pointer",
  },
});

const MenuItem: React.FC<MenuItemProps> = ({ label, iconName, handleClick }) => {
  const hasIcon = iconName !== undefined && iconName !== null;

  const Container = styled("div", {
    width: "100%",
    borderRadius: "6px",
    background: "#F5F5F5",

    "&:hover": {
      background: "#D3D3D3",
      cursor: "pointer",
    },
  });

  return (
    <Container onClick={handleClick}>
      {hasIcon && <Icon name={iconName} />}
      {label}
    </Container>
  );
};

export const ActionMenu: React.FC<ActionMenuProps> = ({ menuOptions }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenuClosed = () => setIsMenuOpen(prevMenuOpen => !prevMenuOpen);

  return (
    <ActionMenuContainer>
      <IconWrapper onClick={toggleMenuClosed}>
        <Icon name="navigation-menu-all" />
      </IconWrapper>
      {isMenuOpen && (
        <MenuItemContainer>
          {menuOptions.map(menuOption => (
            <MenuItem
              key={menuOption.id}
              label={menuOption.label}
              iconName={menuOption.iconName}
              handleClick={() => {
                menuOption.handleClick();
                toggleMenuClosed();
              }}
            />
          ))}
        </MenuItemContainer>
      )}
    </ActionMenuContainer>
  );
};
