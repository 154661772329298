import * as Yup from "yup";

export const validationSchema = Yup.object({
  product_name: Yup.string().required("Name is required"),
  base_product_quantity: Yup.string().required("Product Quantity is required"),

  base_product: Yup.object()
    .shape({
      value: Yup.number().required("Base Product is required"),
    })
    .typeError("Base Product is required"),
  case_product: Yup.object()
    .shape({
      value: Yup.number().required("Underlying Product is required"),
    })
    .typeError("Underlying Product is required"),
});
