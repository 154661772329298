import { CSVLink } from "react-csv";
import { Link } from "react-router-dom";

import { Icon, SecondaryButton, styled } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

export const CardWrapper = styled("div", {
  display: "flex",
  backgroundColor: "white",
  width: "332px",
  height: "379px",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  flexDirection: "column",
  justifyContent: "space-between",
  marginTop: "10px",
});

export const CardTopSectionWrapper = styled("div", {
  width: "100%",
  paddingInlineStart: "23px",
  paddingInlineEnd: "23px",
  display: "flex",
  flexDirection: "column",
});

export const CardTitle = styled("div", {
  width: "100%",
  fontFamily: "Space Grotesk",
  fontSize: "28px",
  lineHeight: "18px",
  marginTop: "25px",
  display: "flex",
  marginBottom: "25px",
  weight: "700",
  justifyContent: "center",
  fontWeight: 700,
});

export const ScrollBar = styled("div", {
  backgroundColor: "$palette3_colour2",
  marginTop: "10px",
  overflow: "auto",
  marginLeft: "30px",
  marginRight: "12px",
  marginBottom: "30px",
});

export const ScrollItem = styled("li", {
  marginTop: "15px",
  listStyleType: "none",
  marginBottom: "50px",
  display: "flex",
  flexDirection: "column",
  alignContent: "center",
});

const DownloadLink = styled("div", {
  fontSize: "15px",
  lineHeight: "18px",
  textDecoration: "underline",
  color: "#000C32",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
  },
});

const defaultContentStyles = {
  marginBottom: "20px",
  width: "30%",
  maxWidth: "475px",
  height: "100%",
  minHeight: "473px",
};

export interface VerticalCardProps {
  scrollBarContent: ScrollBarContentProps[];
  cardTitle: string;
  contentStyles?: any;
  notificationNumber?: string;
  sortBy?: boolean;
  linkText?: string;
  link?: string;
  button?: Button;
}

export interface LinkProps {
  data: any[];
  filename: string;
  displayTitle: string;
}

export interface ScrollBarContentProps {
  iconName: IconName;
  title: string;
  linkProps?: LinkProps;
  value?: string;
  linkText?: string;
  linkPath?: string;
  isTitleClickable?: boolean;
}

export interface Button {
  title: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: any;
}

export const VerticalCardCategories = ({
  contentStyles = defaultContentStyles,
  cardTitle,
  scrollBarContent,
  notificationNumber,
  sortBy,
  button,
}: VerticalCardProps) => {
  return (
    <CardWrapper css={contentStyles}>
      <div>
        <CardTopSectionWrapper>
          <CardTitle>
            {cardTitle}{" "}
            {notificationNumber && (
              <div
                style={{ paddingLeft: "6px", fontSize: "12px" }}
              >{`(${notificationNumber})`}</div>
            )}
          </CardTitle>
          {scrollBarContent.length === 0 && (
            <div
              style={{
                justifyContent: "center",
                marginTop: "60px",
                display: "flex",
                flexDirection: "row",
                color: "#8D8D8D",
                fontSize: "18px",
              }}
            >
              Hmmm... nothing to see here
            </div>
          )}
          {sortBy && scrollBarContent.length !== 0 && (
            <div style={{ display: "flex", flexDirection: "row", alignContent: "center" }}>
              <div
                style={{
                  fontSize: "15px",
                  lineHeight: "18px",
                  display: "flex",
                  flexDirection: "row",
                  width: "100%",
                  justifyContent: "center",
                }}
              ></div>
            </div>
          )}
        </CardTopSectionWrapper>

        <ScrollBar>
          {scrollBarContent.map(item => {
            return (
              <ScrollItem key={item.title}>
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <div>
                    <Icon name={item.iconName}></Icon>
                  </div>
                  {!item.isTitleClickable && <div style={{ marginLeft: "10px" }}>{item.title}</div>}
                  {item.isTitleClickable && (
                    <div>
                      <Link
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          marginLeft: "10px",
                          textDecoration: "underline",
                          fontSize: "15px",
                        }}
                        to={item.linkPath || ""}
                      >
                        {item.title}
                      </Link>
                    </div>
                  )}
                  {item.value && (
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      <div style={{ marginLeft: "2px", marginRight: "5px" }}>{":"}</div>
                      <div
                        style={{
                          fontWeight: "bold",
                          fontSize: "15px",
                          textTransform: "capitalize",
                        }}
                      >
                        {item.value}
                      </div>
                    </div>
                  )}
                </div>
                {item.linkPath && !item.isTitleClickable && (
                  <div>
                    <Link
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        marginLeft: "35px",
                        textDecoration: "underline",
                        color: "#8D8D8D",
                        fontSize: "13px",
                      }}
                      to={item.linkPath}
                    >
                      {item.linkText}
                    </Link>
                  </div>
                )}
                {item.linkProps && (
                  <div>
                    <CSVLink data={item.linkProps.data} filename={item.linkProps.filename}>
                      <DownloadLink>{item.linkProps.displayTitle}</DownloadLink>
                    </CSVLink>
                  </div>
                )}
              </ScrollItem>
            );
          })}
        </ScrollBar>
      </div>

      {button && (
        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            marginBottom: "20px",
          }}
        >
          <SecondaryButton css={button.style} key={"1"} onClick={button.handleClick}>
            {button.title}
          </SecondaryButton>
        </div>
      )}
    </CardWrapper>
  );
};
