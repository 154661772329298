export const FULFILLING_OUTBOUND_MANIFEST_LINE = "FULFILLING_OUTBOUND_MANIFEST_LINE";
export const FULFIL_OUTBOUND_MANIFEST_LINE_SUCCESS = "FULFIL_OUTBOUND_MANIFEST_LINE_SUCCESS";
export const FULFIL_OUTBOUND_MANIFEST_LINE_FAILED = "FULFIL_OUTBOUND_MANIFEST_LINE_FAILED";
export const FULFIL_OUTBOUND_MANIFEST_LINE_RESET = "FULFIL_OUTBOUND_MANIFEST_LINE_RESET";

export const RETURN_OUTBOUND_MANIFEST_LINE_LOADING = "RETURN_OUTBOUND_MANIFEST_LINE_LOADING";
export const RETURN_OUTBOUND_MANIFEST_LINE_SUCCESS = "RETURN_OUTBOUND_MANIFEST_LINE_SUCCESS";
export const RETURN_OUTBOUND_MANIFEST_LINE_FAILED = "RETURN_OUTBOUND_MANIFEST_LINE_FAILED";
export const RETURN_OUTBOUND_MANIFEST_LINE_RESET = "RETURN_OUTBOUND_MANIFEST_RESET";

export const TERMINATE_OUTBOUND_MANIFEST_LINE_LOADING = "TERMINATE_OUTBOUND_MANIFEST_LINE_LOADING";
export const TERMINATE_OUTBOUND_MANIFEST_LINE_SUCCESS = "TERMINATE_OUTBOUND_MANIFEST_LINE_SUCCESS";
export const TERMINATE_OUTBOUND_MANIFEST_LINE_FAILED = "TERMINATE_OUTBOUND_MANIFEST_LINE_FAILED";
export const TERMINATE_OUTBOUND_MANIFEST_LINE_RESET = "TERMINATE_OUTBOUND_MANIFEST_RESET";

export const TERMINATE_OUTBOUND_MANIFEST_LOADING = "TERMINATE_OUTBOUND_MANIFEST_LOADING";
export const TERMINATE_OUTBOUND_MANIFEST_SUCCESS = "TERMINATE_OUTBOUND_MANIFEST_SUCCESS";
export const TERMINATE_OUTBOUND_MANIFEST_FAILED = "TERMINATE_OUTBOUND_MANIFEST_FAILED";
export const TERMINATE_OUTBOUND_MANIFEST_RESET = "TERMINATE_OUTBOUND_MANIFEST_RESET";

export enum CHANGE_OBM_LINE_STATUS {
  MARK_AS_DONE = "MARK_AS_DONE",
  CANCEL = "CANCEL",
  REJECT = "REJECT",
}

export enum CHANGE_OBM_STATUS {
  MARK_AS_DONE = "MARK_AS_DONE",
  REJECT = "REJECT",
  CANCEL = "CANCEL",
}
