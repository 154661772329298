import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { fulfilOutboundManifestLineAPI } from "../../api/fulfilOutboundManifestLineAPI";
import { FulfilOutboundManifestLineBody } from "../../api/types";
import {
  FULFILLING_OUTBOUND_MANIFEST_LINE,
  FULFIL_OUTBOUND_MANIFEST_LINE_FAILED,
  FULFIL_OUTBOUND_MANIFEST_LINE_RESET,
  FULFIL_OUTBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export const fulfillingOutboundManifestLine = () => {
  return {
    type: FULFILLING_OUTBOUND_MANIFEST_LINE,
  };
};

export const fulfilOutboundManifestLineSuccess = () => {
  return {
    type: FULFIL_OUTBOUND_MANIFEST_LINE_SUCCESS,
  };
};

export const fulfilOutboundManifestLineFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: FULFIL_OUTBOUND_MANIFEST_LINE_FAILED,
    errors: errors,
  };
};

export const fulfilOutboundManifestLineReset = () => {
  return {
    type: FULFIL_OUTBOUND_MANIFEST_LINE_RESET,
  };
};

export const fulfilOutboundManifestLine = (body: FulfilOutboundManifestLineBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating outbound manifest line fulfilment", { hideProgressBar: true });
    dispatch(fulfillingOutboundManifestLine());
    try {
      await fulfilOutboundManifestLineAPI(body);
      toast.success("Outbound manifest line fulfilment created successfully.");
      dispatch(fulfilOutboundManifestLineSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating outbound manifest line fulfilment.");
      dispatch(fulfilOutboundManifestLineFailed({ errors: (err as any).response.data.errors }));
    }
  };
};
