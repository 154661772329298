import React from "react";

import { ROLE, useAuthorisationContext } from "../../../../providers/AuthorisationProvider";

export interface VisibleForProps {
  children: React.ReactChildren | React.ReactElement;
  roles: ROLE[];
}

const VisibleFor = ({ roles, children, ...props }: VisibleForProps) => {
  const authorisationContext = useAuthorisationContext();

  const { roles: userRoles } = authorisationContext;

  const isAuthorised = roles.some(role => userRoles.includes(role));

  if (!isAuthorised) {
    return null;
  }

  return (
    <>
      {React.Children.map(children, child => {
        return child;
      })}
    </>
  );
};

export default VisibleFor;
