import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import {
  STOCK_SYNC_FAILED,
  STOCK_SYNC_LOADING,
  STOCK_SYNC_RESET,
  STOCK_SYNC_SUCCESS,
} from "../types";

export interface StockSyncReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: StockSyncReducer = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function stockSyncReducer(state: StockSyncReducer = initialState, action: any = {}) {
  switch (action.type) {
    case STOCK_SYNC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOCK_SYNC_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case STOCK_SYNC_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case STOCK_SYNC_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
