import { ApolloError } from "@apollo/client";

import { useGetCustomReportLocationTypesNamesQuery } from "../../../../../../generated/graphql_wms";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  locationTypeNames: DropdownItem[];
  locationTypeNamesError?: ApolloError;
  locationTypeNamesLoading: boolean;
}

export const useLocationTypeNames = (): HookResult => {
  const { data, error, loading } = useGetCustomReportLocationTypesNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    locationTypeNames:
      data && Array.isArray(data.location_type)
        ? data.location_type.map(locationType => {
            return {
              value: locationType.id,
              label: locationType.location_type_name,
            };
          })
        : [],
    locationTypeNamesError: error,
    locationTypeNamesLoading: loading,
  };
};
