import { styled } from "@sourceful/shared-components";

//ToDo: Delete after other pages are cleanup
export const SourcingStatusBadge = styled("div", {
  borderRadius: "5px",
  color: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",
  width: "115px",
  padding: "5px 12px",
  variants: {
    type: {
      Accepted: { background: "#78D178" },
      Delivered: { background: "#005AE1" },
      ProductionComplete: { background: "#005AE1" },
      Approved: { background: "#FFB500" },
      Pending: { background: "#B9B9BB" },
      Expired: { background: "#D02257" },
      Recalled: { background: "#D02257" },
      InternallyRejected: { background: "#D02257" },
      ExternallyRejected: { background: "#D02257" },
      InTransit: { background: "#B47EE5" },
      SourcefulWarehouse: { background: "#D02257", width: "200px" },
      ClientAddress: { background: "#78D178", width: "150px" },
      AwaitingQuotes: { background: "#FFB500" },
      QuotesCompleted: { background: "#78D178" },
      Fulfilled: { background: "#005AE1" },
      InProgress: { backgroundColor: "#FFB500" },
      Done: { background: "#78D178" },
      Rejected: { background: "#D02257" },
      Cancelled: { background: "#747477" },
      Complete: { background: "#78D178" },
      New: { background: "#005AE1" },
      Reserved: { background: "#FFA500" },
      Available: { background: "#78D178" },
      DueIn: { background: "#FFB500" },
      Missing: { background: "#D02257" },
      FoundUnexpected: { background: "#747477" },
      Passed: { background: "#78D178" },
      Failed: { background: "#D02257" },
      Completed: { background: "#78D178" },
      Deleted: { background: "#D02257" },
      Archived: { background: "#D02257" },
    },
  },
});

export const TitleBadgeSpacer = styled("div", {
  flexDirection: "row",
  display: "flex",
  justifyContent: "space-between",
});

export const URLHolder = styled("a", {
  fontSize: 14,
  cursor: "pointer",
  textDecoration: "underline",
  "&:hover": {
    textDecoration: "none",
  },
  variants: {
    type: {
      bigMargin: {
        marginLeft: "10px",
      },
    },
    isGray: {
      true: {
        color: "#979799",
      },
    },
  },
});

export const AttachementsWrapper = styled("div", {
  marginTop: "10px",
  flexDirection: "column",
  display: "flex",
});

export const ExternalAttachmentsWrapper = styled("div", {
  background: "#FFFFFF",
  borderRadius: "10px",
  border: "0.5px solid #B9B9BB",
  padding: "20px 20px",
  marginTop: "20px",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  flexWrap: "wrap",
  width: "100%",
  variants: {
    type: {
      withIndex: {
        position: "relative",
      },
      darkBackground: {
        backgroundColor: "rgba(0, 90, 225, 0.01)",
      },
    },
  },
});

export const DiplayAttachment = styled("div", {
  display: "flex",
  alignItems: "center",
});

export const InlineBlock = styled("div", {
  display: "inline-block",
});

export const RowSpacer = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
});

export const PublicSourcefulLogoSpacer = styled("div", {
  marginTop: "10px",
  marginRight: "5px",
});
