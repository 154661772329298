import { AddressItem } from "../types";

export const prettifyProductName = (productName: string) => {
  const firstIndex = productName.indexOf("-");
  const lastIndex = productName.indexOf("]");
  if (firstIndex === -1 || lastIndex === -1) {
    return productName;
  }
  return productName
    .substring(0, firstIndex)
    .concat("] ")
    .concat(productName.substring(lastIndex + 1));
};

export const extractProductName = (productName: string) => {
  const lastIndex = productName.indexOf("]");
  if (lastIndex === -1) {
    return productName;
  }

  return productName.substring(lastIndex + 1);
};

export const prettifyAddress = (address: AddressItem) => {
  const addressString = [
    address.address_line_1,
    address.address_line_2,
    address.address_line_3,
    address.city,
    address.country_code,
    address.postcode,
  ]
    .filter(Boolean)
    .join(", ");

  return addressString;
};
