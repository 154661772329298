import { ApolloError } from "@apollo/client";

import { useGetCaseChangeFromCaseBaseProdQuantityByIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { BaseProductQuantityItem } from "../types";

export interface HookResult {
  fromCaseBaseProductQuantity: BaseProductQuantityItem[];
  fromCaseBaseProductQuantityError?: ApolloError;
  fromCaseBaseProductQuantityLoading: boolean;
  getFromCaseBaseProductQuantity: (options: FromCaseBaseProductQuantityOptions) => Promise<void>;
}

export interface FromCaseBaseProductQuantityOptions {
  productId: number;
}

export const useFromCaseBaseProdQuantityById = (): HookResult => {
  const [getFromCaseBaseProductQuantity, { data, error, loading }] =
    useGetCaseChangeFromCaseBaseProdQuantityByIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    fromCaseBaseProductQuantity: data && Array.isArray(data.product) ? data.product : [],
    fromCaseBaseProductQuantityError: error,
    fromCaseBaseProductQuantityLoading: loading,
    getFromCaseBaseProductQuantity: async ({ productId }: FromCaseBaseProductQuantityOptions) => {
      getFromCaseBaseProductQuantity({
        variables: { productId },
      });
    },
  };
};
