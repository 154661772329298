import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { createStockAllocationAPI } from "../../api/createStockAllocation";
import { CreateStockAllocationBody } from "../../api/types";
import {
  CREATE_STOCK_ALLOCATION_FAILED,
  CREATE_STOCK_ALLOCATION_LOADING,
  CREATE_STOCK_ALLOCATION_RESET,
  CREATE_STOCK_ALLOCATION_SUCCESS,
} from "../types";

export const creatingStockAllocation = () => {
  return {
    type: CREATE_STOCK_ALLOCATION_LOADING,
  };
};

export const createStockAllocationSuccess = () => {
  return {
    type: CREATE_STOCK_ALLOCATION_SUCCESS,
  };
};

export const createStockAllocationReset = () => {
  return {
    type: CREATE_STOCK_ALLOCATION_RESET,
  };
};

export const createStockAllocationFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CREATE_STOCK_ALLOCATION_FAILED,
    errors: errors,
  };
};

export const createStockAllocationAction = (body: CreateStockAllocationBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Stock Allocation", { hideProgressBar: true });
    dispatch(creatingStockAllocation());
    try {
      await createStockAllocationAPI(body);
      toast.success("Stock Allocation created successfully.");
      dispatch(createStockAllocationSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating stock allocation.");
      dispatch(createStockAllocationFailed({ errors: (err as any).response.data.errors }));
    }
  };
};
