const fieldNames = {
  deliveryDate: "deliveryDate",
  leadTimeMaxDays: "leadTimeMaxDays",
};

export type ValueSetter = (field: string, value: any, shouldValidate?: boolean | undefined) => void;

export const syncDeliveryDateWithLeadTime = (
  fieldName: string,
  value: string,
  setFieldValue: ValueSetter
) => {
  const MINUTE = 1000 * 60;
  const HOUR = MINUTE * 60;
  const DAY = HOUR * 24;

  // Put a given date into UTC timezone with a time of 00:00
  const normalizeDate = (isoDate: string): Date | undefined => {
    const dateToken = isoDate.split("T")[0];

    if (!dateToken) {
      return;
    }

    const normalDate = new Date();
    const normalTimeZone = "T00:00:00.000+00:00"; // Use 00:00 in UTC timezone

    normalDate.setTime(Date.parse(dateToken + normalTimeZone));

    return normalDate;
  };

  const getLeadTimeFromSelectedDate = (selectedDate: Date): string => {
    const today = new Date();
    const todayNormal = normalizeDate(today.toISOString());

    if (!todayNormal) {
      return "";
    }

    if (selectedDate.getTime() < todayNormal.getTime()) {
      return "";
    }

    const timeDifference = selectedDate.getTime() - todayNormal.getTime();
    const days = timeDifference / DAY;

    return days.toString();
  };

  const getSelectedDateFromLeadTime = (leadTime: string): string => {
    const today = new Date();

    // Normalize to 00:00 for consistency with getLeadTimeFromSelectedDate()
    const todayNormal = normalizeDate(today.toISOString());

    if (!todayNormal) {
      return "";
    }

    const leadTimeInMS = parseInt(leadTime) * DAY;
    todayNormal.setTime(todayNormal.getTime() + leadTimeInMS);

    return todayNormal.toISOString().split("T")[0];
  };

  const resetValue = (fieldName: string): void => {
    setFieldValue(
      fieldName === fieldNames.deliveryDate ? fieldNames.leadTimeMaxDays : fieldNames.deliveryDate,
      ""
    );
  };

  if (value === "") {
    resetValue(fieldName);
    return;
  }

  if (fieldName === fieldNames.deliveryDate) {
    const selectedDate = new Date();
    selectedDate.setTime(Date.parse(value));

    const normalSelectedDate = normalizeDate(selectedDate.toISOString());

    if (!normalSelectedDate) {
      return;
    }

    const leadTime = getLeadTimeFromSelectedDate(normalSelectedDate);
    setFieldValue(fieldNames.leadTimeMaxDays, leadTime);

    return;
  }

  if (fieldName === fieldNames.leadTimeMaxDays) {
    const selectedDateAsString = getSelectedDateFromLeadTime(value);

    setFieldValue(fieldNames.deliveryDate, selectedDateAsString);
  }
};
