import { ApolloError } from "@apollo/client";

import { useGetWorkOrderFulfilmentByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { WorkOrderItem } from "../types";

export interface HookResult {
  workOrder?: WorkOrderItem;
  workOrderError?: ApolloError;
  workOrderLoading: boolean;
  refetchWorkOrder: () => Promise<void>;
}

export const useWorkOrderById = (workOrderId: number): HookResult => {
  const { data, error, loading, refetch } = useGetWorkOrderFulfilmentByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      workOrderId,
    },
  });

  return {
    workOrder: data && Array.isArray(data.work_order) ? data.work_order[0] : undefined,
    workOrderError: error,
    workOrderLoading: loading,
    refetchWorkOrder: async () => {
      refetch({ workOrderId: workOrderId });
    },
  };
};
