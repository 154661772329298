import { Icon } from "@sourceful/shared-components";

import { IndexHolder } from "../../../../styles/SharedStyles";

export interface Props {
  onClick: () => void;
}

export const DeletePill = ({ onClick }: Props) => {
  return (
    <IndexHolder type="delete" onClick={onClick}>
      <Icon name="alert-cross-default" />
    </IndexHolder>
  );
};
