import objToQueryString from "../../styles/objToQueryString";
import { GCS_API } from "../baseConfig";

export interface DownloadAttachmentBody {
  filename: string;
  uuid_name: string;
  metadata?: any;
}

export const downloadAttachmentAPI = async (body: DownloadAttachmentBody) => {
  const attachmentQueryString = objToQueryString(body);

  try {
    return await GCS_API.get(`/download?${attachmentQueryString}`);
  } catch (err) {
    console.log("Error in download attachment API call", err);
    throw err;
  }
};
