import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";

export interface LinkSkuToOrganisationInterface {
  orgIds: DropdownItem[] | null;
  orgIdsToBeRemoved: string[];
}
export const initialLinkSkuToOrganisationValues: LinkSkuToOrganisationInterface = {
  orgIds: null,
  orgIdsToBeRemoved: [],
};

export interface NewPriceInterface {
  pricePerUnit: string;
  currencyCode: string;
  minOrderQuantity: string;
  validFrom: string;
}

export const initialNewPriceValues: NewPriceInterface = {
  pricePerUnit: "",
  currencyCode: "",
  minOrderQuantity: "",
  validFrom: "",
};

export const linkSkuToOrganisationFieldName = {
  orgIds: "orgIds",
  orgIdsToBeRemoved: "orgIdsToBeRemoved",
};

export interface DeliveryCosts {
  costPerPackExclVat: number;
  countryCode: string;
  currencyCode: string;
}

export interface DeliveryCostsFormValues {
  costPerPackExclVat: number;
  countryCode: DropdownItem;
  currencyCode: DropdownItem;
}

export interface UpdateSkuDataInterface {
  imageURL: string;
  packSize: number;
  productType: DropdownItem;
  deliveryCosts: DeliveryCostsFormValues[];
  leadTimeMin: number | undefined;
  leadTimeMax: number | undefined;
  leadTimeUnits: string | undefined;
  businessDaysOnly: boolean | undefined;
}

export interface AddProductPriceInterface {
  currencyCode: string;
  minOrderQuantity: number;
  pricePerUnit: number;
  validFrom: string;
}

export interface ResetProductPriceInterface {
  sku: string;
}
export interface ExpireProductPriceInterface {
  productId: number;
}

export const updateSkuDataFieldNames = {
  imageURL: "imageURL",
  packSize: "packSize",
  productType: "productType",
  deliveryCosts: "deliveryCosts",
  leadTimeMin: "leadTimeMin",
  leadTimeMax: "leadTimeMax",
  leadTimeUnits: "leadTimeUnits",
  businessDaysOnly: "businessDaysOnly",
};

export const addProductPriceFieldNames = {
  pricePerUnit: "pricePerUnit",
  currencyCode: "currencyCode",
  minOrderQuantity: "minOrderQuantity",
  validFrom: "validFrom",
};
