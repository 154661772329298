import { WMS_API } from "../../../../../../api/baseConfig";

export const rejectInboundManifestAPI = async (inbound_manifest_id: number) => {
  try {
    return await WMS_API.patch(`/inbound-manifests/${inbound_manifest_id}/reject`);
  } catch (err) {
    console.log("Error in reject inbound manifest API call:", err);
    throw err;
  }
};
