import { WMS_API } from "../../../../../api/baseConfig";
import { CreateStockAllocationBody } from "./types";

export const createStockAllocationAPI = async (body: CreateStockAllocationBody) => {
  try {
    return await WMS_API.post("/stock-allocations/create", body);
  } catch (err) {
    console.log("Error in creating Stock Allocation API call", err);
    throw err;
  }
};
