import * as Yup from "yup";

export const validationSchema = Yup.object({
  location_name: Yup.string().required("Location Name is required"),
  location_type: Yup.object()
    .shape({
      value: Yup.number().required("Location Type is required"),
    })
    .typeError("Location Type is required"),

  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),

  can_hold_stock: Yup.object()
    .shape({
      value: Yup.number().required("Field is required"),
    })
    .typeError("Field is required"),
});
