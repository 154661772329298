import { Children } from "react";

import { styled } from "@sourceful/shared-components";

const TimelineWrapper = styled("div", {
  display: "flex",
});
const TimelinePoint = styled("div", {
  width: "10px",
  minWidth: "10px",
  height: "10px",
  borderRadius: "50%",
  background: "black",
});
const TimelineConnector = styled("div", {
  width: "2px",
  background: "black",
  margin: "10px 0 5px 3px",
  flexGrow: "1",
});
const TimelineSeparator = styled("div", {
  display: "flex",
  flexDirection: "column",
  minHeight: "50px",
  paddingTop: "8px",
  paddingRight: "12px",
});

interface TimelineSectionProps {
  children: React.ReactNode;
}

export const TimelineSection = ({ children }: TimelineSectionProps) => {
  return <div>{children}</div>;
};

interface TimelineProps {
  children: React.ReactNode;
}

const Timeline = ({ children }: TimelineProps) => {
  const arrayChildren = Children.toArray(children);
  return (
    <>
      {arrayChildren.map((child, index) => (
        <TimelineWrapper key={index}>
          <TimelineSeparator>
            <TimelinePoint />
            {index < arrayChildren.length - 1 && <TimelineConnector />}
          </TimelineSeparator>
          {child}
        </TimelineWrapper>
      ))}
    </>
  );
};

export default Timeline;
