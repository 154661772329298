import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { createRef, useState } from "react";
import QRCode from "react-qr-code";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";

import { ButtonGroup, Modal } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  CertificationBadge,
  FlexHolder,
  FlexTitle,
  InnerPanelWrapper,
  PanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  QRPrintButtonWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { useInboundManifestById } from "../graphql/hooks/useInboundManifestById";
import { PDF } from "../helpers";

const ViewInboundManifest = () => {
  const { inbound_manifest_id } = useParams<{ inbound_manifest_id: string }>();
  const [QRCodeBarcode, setQRCodeBarcode] = useState("");
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);

  const qrCodeRef = createRef<any | null>();
  const modalRef = createRef<HTMLButtonElement>();

  const handlePrint = useReactToPrint({
    content: () => qrCodeRef.current,
    pageStyle:
      "@page { size: auto;  margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const { manifest, manifestLoading, manifestError } = useInboundManifestById(
    Number(inbound_manifest_id)
  );

  return (
    <Page error={manifestError} isLoading={manifestLoading} title={"Review Inbound Manifest"}>
      <>
        <Panel
          withWrapper
          title={`Review Inbound Manifest ID #${manifest?.id}`}
          subtitle={"Review Inbound Manifest below"}
          manifestStatusName={manifest?.inbound_manifest_status.inbound_manifest_status_name}
        >
          {
            <InnerPanelWrapper>
              <TopPanelInputsWrapper>
                <DisplayBox
                  label={"Organisation"}
                  value={manifest?.organisation.organisation_name}
                />
                <DisplayBox label={"Warehouse"} value={manifest?.warehouse.warehouse_name} />
                <DisplayBox
                  label={"Logistics Type"}
                  tooltipMessage={"How stock will arrive <br/> at the warehouse"}
                  value={manifest?.logistics_type.logistics_type_name}
                />
                <DisplayBox
                  label={"Expected Arrival Date"}
                  value={moment(manifest?.expected_arrival).format("DD-MM-YYYY")}
                />
                <DisplayBox
                  label={"Courier Service"}
                  value={manifest?.courier_service?.courier_service_name}
                />
                <DisplayBox label={"Reference"} value={manifest?.reference} />
                <DisplayBox
                  label={"Number of manifest lines"}
                  value={manifest?.inbound_manifest_lines.length}
                />
                <DisplayBox label={"Notes"} value={manifest?.notes} unknownString={"No Notes"} />
              </TopPanelInputsWrapper>

              {manifest?.inbound_manifest_lines.map(ibmLine => (
                <PrimaryLineWrapper key={ibmLine.id}>
                  <FlexTitle>
                    <Title>Manifest Line #{ibmLine.id}</Title>
                    <StatusBadge
                      type="dot"
                      statusName={
                        ibmLine.inbound_manifest_line_status.inbound_manifest_line_status_name
                      }
                    />
                  </FlexTitle>
                  <FlexHolder>
                    {ibmLine.product.fsc_certified && (
                      <CertificationBadge type="FSC"> FSC Certified </CertificationBadge>
                    )}
                    {ibmLine.product.grs_certified && (
                      <CertificationBadge type="GRS"> GRS Certified </CertificationBadge>
                    )}
                  </FlexHolder>

                  <PrimaryLineDataWrapper>
                    <DisplayBox label={"Product Name"} value={ibmLine.product.product_name} />
                    <DisplayBox label={"Quantity"} value={ibmLine.quantity} />
                    <DisplayBox label={"Received Quantity"} value={ibmLine.received_quantity} />
                    <DisplayBox
                      label={"Number of Fulfilment Lines"}
                      value={ibmLine.inbound_manifest_fulfilment_lines.length}
                    />
                  </PrimaryLineDataWrapper>

                  {ibmLine.inbound_manifest_fulfilment_lines.map((ffline, index) => (
                    <SecondaryLineWrapper type="withIndex" key={index}>
                      <IndexPill index={index + 1} />
                      <Title>Fulfilment Line #{ffline.id}</Title>
                      <FlexHolder>
                        {ffline.is_file_copy && <StatusBadge type="wms" statusName={"File Copy"} />}
                      </FlexHolder>
                      <SecondaryLineDataWrapper>
                        <DisplayBox
                          label={"Fulfilment Line Product"}
                          value={ffline.product.product_name}
                        />
                        <DisplayBox label={"Fulfilment Line Quantity"} value={ffline.quantity} />
                        <DisplayBox
                          label={"Fulfilment Location"}
                          value={ffline.product_stocks[0].location.location_name}
                        />
                        <div>
                          <ToolTip message={"Generate QR code for..."} title={"Stock QR Code"} />
                          <SecondaryButton
                            onClick={() => {
                              setQRCodeBarcode(ffline.product_stocks[0].barcode_uuid);
                              setIsQRModalOpen(true);
                            }}
                            appearance={"blueButton"}
                          >
                            View/Print
                          </SecondaryButton>
                        </div>
                      </SecondaryLineDataWrapper>
                    </SecondaryLineWrapper>
                  ))}
                </PrimaryLineWrapper>
              ))}

              <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                <PDFDownloadLink
                  document={<PDF manifestData={manifest} />}
                  fileName={`inbound_manifest_id_${inbound_manifest_id}_generated_${moment(
                    new Date()
                  ).format("DD-MM-YYYY")}.pdf`}
                >
                  {({ loading, error }) => {
                    return (
                      <SecondaryButton appearance={"blueButton"}>
                        {loading ? "Loading Document" : "Download PDF"}
                        {error && "An error occured, unable to download"}
                      </SecondaryButton>
                    );
                  }}
                </PDFDownloadLink>
              </ButtonGroup>
            </InnerPanelWrapper>
          }
        </Panel>

        <Modal
          id={"QRCodeModal"}
          isOpen={isQRModalOpen}
          handleClose={() => {
            setIsQRModalOpen(false);
            setQRCodeBarcode("");
          }}
          triggerRef={modalRef}
        >
          <PanelWrapper>
            <InnerPanelWrapper>
              <div ref={qrCodeRef}>
                <QRCode value={QRCodeBarcode} />
              </div>

              <QRPrintButtonWrapper>
                <SecondaryButton onClick={handlePrint} appearance={"blueButton"}>
                  Print
                </SecondaryButton>
              </QRPrintButtonWrapper>
            </InnerPanelWrapper>
          </PanelWrapper>
        </Modal>
      </>
    </Page>
  );
};

export default ViewInboundManifest;
