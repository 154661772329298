import { ApolloError } from "@apollo/client";

import { useGetAllWarehouseNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapWarehouseNamesToDrodownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  warehouseNames?: DropdownItem[];
  warehouseNamesError?: ApolloError;
  warehouseNamesLoading: boolean;
}

export const useAllWarehouseNames = (): HookResult => {
  const { data, error, loading } = useGetAllWarehouseNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    warehouseNames:
      data && Array.isArray(data.warehouse) ? mapWarehouseNamesToDrodownObject(data.warehouse) : [],
    warehouseNamesError: error,
    warehouseNamesLoading: loading,
  };
};
