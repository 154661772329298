import { IAM_API } from "../../../../../api/baseConfig";

export const getUserDetailApi = async (email: string) => {
  try {
    return await IAM_API.get("v2/users", {
      params: {
        email: `_eq:${email}`,
      },
    });
  } catch (err) {
    console.log("Error in get user details api call", err);
    throw err;
  }
};
