import { ApolloError } from "@apollo/client";

import { useGetSupplierDetailsForQuotesQuery } from "../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../shared/constants";
import { mapSupplierLocaleToCurrencyObject } from "../mappers";
import { SupplierCurrencyItem } from "../types";

export interface HookResult {
  supplierDetails: SupplierCurrencyItem[];
  supplierDetailsError?: ApolloError;
  supplierDetailsLoading: boolean;
}

export const useSupplierDetails = (): HookResult => {
  const { data, error, loading } = useGetSupplierDetailsForQuotesQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    supplierDetails:
      data && Array.isArray(data.organisation)
        ? mapSupplierLocaleToCurrencyObject(data.organisation)
        : [],
    supplierDetailsError: error,
    supplierDetailsLoading: loading,
  };
};
