import { useParams } from "react-router-dom";

import Panel from "../../../../../components/panel/panel";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  InnerPanelWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useRecipeById } from "../graphql/hooks/useRecipeById";

const ViewRecipe = () => {
  const { recipe_id } = useParams<{ recipe_id: string }>();

  const { recipe, recipeLoading, recipeError } = useRecipeById(Number(recipe_id));

  return (
    <Page error={recipeError} isLoading={recipeLoading} title={"View Recipe"}>
      <Panel
        withWrapper
        title={"View Recipe "}
        subtitle={
          "Recipe is a request to repackage using 'ingredient' items and resulting in 'dish' items"
        }
      >
        {
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DisplayBox label={"ID"} value={`#${recipe?.id}`} />
              <DisplayBox label={"Recipe Name"} value={recipe?.recipe_name} />
              <DisplayBox label={"Recipe Type"} value={recipe?.recipe_type.recipe_type_name} />
              <DisplayBox
                label={"Use For Bundle Stock Reporting"}
                value={String(recipe?.use_for_reporting)}
              />
            </TopPanelInputsWrapper>
            <PrimaryLineWrapper key={"inputs"}>
              <Title>Recipe Inputs</Title>
              {recipe?.recipe_inputs.map((input, index) => (
                <SecondaryLineWrapper type="withIndex" key={index}>
                  <IndexPill index={index + 1} />
                  <Title>Recipe Input #{index + 1}</Title>
                  <SecondaryLineDataWrapper>
                    <DisplayBox label={"Product Name"} value={input.product.product_name} />
                    <DisplayBox label={"Quantity"} value={input.quantity} />
                  </SecondaryLineDataWrapper>
                </SecondaryLineWrapper>
              ))}
            </PrimaryLineWrapper>
            <PrimaryLineWrapper key={"outputs"}>
              <Title>Recipe Outputs</Title>
              {recipe?.recipe_outputs.map((output, index) => (
                <SecondaryLineWrapper type="withIndex" key={index}>
                  <IndexPill index={index + 1} />
                  <Title>Recipe Output #{index + 1}</Title>
                  <SecondaryLineDataWrapper>
                    <DisplayBox label={"Product Name"} value={output.product.product_name} />
                    <DisplayBox label={"Quantity"} value={output.quantity} />
                  </SecondaryLineDataWrapper>
                </SecondaryLineWrapper>
              ))}
            </PrimaryLineWrapper>
          </InnerPanelWrapper>
        }
      </Panel>
    </Page>
  );
};

export default ViewRecipe;
