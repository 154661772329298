/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";

import { ButtonGroup } from "@sourceful/shared-components";

import { Report, ReportsCard } from "../../../../../components/ReportsCard/ReportsCard";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { ReportForm, fieldNames, reportOptions } from "../formValues";
import { useAllProducts } from "../graphql/hooks/useAllProducts";
import { useAllReportWarehouseNames } from "../graphql/hooks/useAllReportWarehouseNames";
import { useBaseProductsAccreditations } from "../graphql/hooks/useBaseProductsAccreditations";
import { useEmptyLocationsByWarehouseId } from "../graphql/hooks/useEmptyLocationsByWarehouseId";
import { useFinanceStockReport } from "../graphql/hooks/useFinanceStockReport";
import { useInternalTransfers } from "../graphql/hooks/useInternalTransfers";
import { useInventoryByWarehouseId } from "../graphql/hooks/useInventoryByWarehouseId";
import { useManifestsByWarehouseId } from "../graphql/hooks/useManifestsByWarehouseId";
import { useStockByWarehouseId } from "../graphql/hooks/useStockByWarehouseId";
import { DropdownItem } from "../graphql/types";
import { REPORT_TYPE } from "../types";

export function CreateDefinedReport() {
  const [selectedToDate, setSelectedToDate] = useState(moment().toDate().toString());
  const [selectedFromDate, setSelectedFromDate] = useState(
    moment().subtract(12, "day").toDate().toString()
  );
  const [reportData, setReportData] = useState<Report | null>(null);
  const [selectedReport, setSelectedReport] = useState<number | null>(null);
  const [warehouseNeeded, setWarehouseNeeded] = useState<boolean>(false);
  const [dateRangeNeeded, setDateRangeNeeded] = useState<boolean>(false);
  const [selectedWarehouse, setSelectedWarehouse] = useState<DropdownItem | undefined>(undefined);

  const {
    handleSubmit,
    control,
    reset,
    watch,
    formState: { errors },
  } = useForm<ReportForm>();

  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } =
    useAllReportWarehouseNames();

  const {
    emptyLocations,
    emptyLocationsLoading,
    emptyLocationsError,
    getEmptyLocationsByWarehouseId,
  } = useEmptyLocationsByWarehouseId();

  const { stock, stockLoading, stockError, getStockByWarehouseId } = useStockByWarehouseId();
  const { products, productsLoading, productsError, getAllProducts } = useAllProducts();
  const {
    internalTransfers,
    internalTransfersLoading,
    internalTransfersError,
    getInternalTransfers,
  } = useInternalTransfers();

  const {
    baseProductsAccreditations,
    baseProductsAccreditationsLoading,
    baseProductsAccreditationsError,
    getBaseProductsAccreditations,
  } = useBaseProductsAccreditations();

  const { inventory, inventoryLoading, inventoryError, getInventoryByWarehouseId } =
    useInventoryByWarehouseId();

  const { financeStock, financeStockLoading, financeStockError, getFinanceStock } =
    useFinanceStockReport();

  const { manifests, manifestsLoading, manifestsError, getManifestsByWarehouseId } =
    useManifestsByWarehouseId();

  const onSubmit = (values: ReportForm) => {
    if (values?.report?.value === REPORT_TYPE.EMPTY_LOCATIONS) {
      getEmptyLocationsByWarehouseId({ warehouseId: selectedWarehouse?.value || null }).catch(
        error => {
          console.error("error calling getEmptyLocationsByWarehouseId:", error);
        }
      );
    }

    if (values?.report?.value === REPORT_TYPE.ALL_PRODUCTS) {
      getAllProducts().catch(error => {
        console.error("error calling getAllProducts:", error);
      });
    }

    if (values?.report?.value === REPORT_TYPE.PRODUCT_STOCK) {
      getStockByWarehouseId({ warehouseId: selectedWarehouse?.value || null }).catch(error => {
        console.error("error calling getStockByWarehouseId:", error);
      });
    }

    if (values?.report?.value === REPORT_TYPE.INTERNAL_TRANSFERS) {
      getInternalTransfers().catch(error => {
        console.error("error calling getInternalTransfers:", error);
      });
    }

    if (values?.report?.value === REPORT_TYPE.BASE_PRODUCTS_ACCREDITATIONS) {
      getBaseProductsAccreditations().catch(error => {
        console.error("error calling getBaseProductsAccreditations:", error);
      });
    }

    if (values?.report?.value === REPORT_TYPE.INVENTORY_REPORT) {
      getInventoryByWarehouseId({
        warehouseId: selectedWarehouse?.value!,
      }).catch(error => {
        console.error("error calling getInventoryByWarehouseId:", error);
      });
    }

    if (values?.report?.value === REPORT_TYPE.MANIFESTS) {
      getManifestsByWarehouseId({
        warehouseId: selectedWarehouse?.value!,
        fromDate: new Date(selectedFromDate).toISOString(),
        toDate: new Date(selectedToDate).toISOString(),
      }).catch(error => {
        console.error("error calling getManifestsByWarehouseId:", error);
      });
    }

    if (values?.report?.value === REPORT_TYPE.FINANCE_STOCK_REPORT) {
      getFinanceStock({
        warehouseId: selectedWarehouse?.value!,
        fromDate: new Date(selectedFromDate).toISOString(),
        toDate: new Date(selectedToDate).toISOString(),
      }).catch(error => {
        console.error("error calling getFinanceStock:", error);
      });
    }
  };

  const isError =
    warehouseNamesError ??
    emptyLocationsError ??
    productsError ??
    stockError ??
    internalTransfersError ??
    inventoryError ??
    financeStockError ??
    manifestsError ??
    baseProductsAccreditationsError;
  const isLoading =
    productsLoading ??
    emptyLocationsLoading ??
    internalTransfersLoading ??
    inventoryLoading ??
    financeStockLoading ??
    stockLoading ??
    warehouseNamesLoading ??
    manifestsLoading ??
    baseProductsAccreditationsLoading;

  const allFields = watch();

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.EMPTY_LOCATIONS) {
      const reportDataEmptyLocations: Report = {
        fileTitle: `empty_locations_report_in_${selectedWarehouse?.label}_generated`,
        displayTitle: `Download Empty Locations Data in ${selectedWarehouse?.label}`,
        data: emptyLocations,
      };
      setReportData(reportDataEmptyLocations);
    }
  }, [emptyLocationsLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.ALL_PRODUCTS) {
      const reportDataAllProducts: Report = {
        fileTitle: `all_products_report_generated`,
        displayTitle: `Download All Products Report`,
        data: products,
      };
      setReportData(reportDataAllProducts);
    }
  }, [productsLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.BASE_PRODUCTS_ACCREDITATIONS) {
      const reportDataBaseProductsAccreditations: Report = {
        fileTitle: `all_base_products_accreditations_report_generated`,
        displayTitle: `Download All Base Products Accreditations Report`,
        data: baseProductsAccreditations,
      };
      setReportData(reportDataBaseProductsAccreditations);
    }
  }, [baseProductsAccreditationsLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.PRODUCT_STOCK) {
      const reportDataProductStock: Report = {
        fileTitle: `product_stock_report_generated`,
        displayTitle: `Download Product Stock Report`,
        data: stock,
      };
      setReportData(reportDataProductStock);
    }
  }, [stockLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.INTERNAL_TRANSFERS) {
      const reportDataInternalTransfer: Report = {
        fileTitle: `internal_transfer_report_generated`,
        displayTitle: `Download Internal Transfer Report`,
        data: internalTransfers,
      };
      setReportData(reportDataInternalTransfer);
    }
  }, [internalTransfersLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.INVENTORY_REPORT) {
      const reportDataInventory: Report = {
        fileTitle: `inventory_report_in_${selectedWarehouse?.label}_generated`,
        displayTitle: `Download Inventory Report Data in ${selectedWarehouse?.label}`,
        data: inventory,
      };
      setReportData(reportDataInventory);
    }
  }, [inventoryLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.MANIFESTS) {
      const reportDataManifests: Report = {
        fileTitle: `manifests_in_${selectedWarehouse?.label}_generated`,
        displayTitle: `Download Manifests Report Data in ${selectedWarehouse?.label}`,
        data: manifests,
      };
      setReportData(reportDataManifests);
    }
  }, [manifestsLoading]);

  useEffect(() => {
    if (selectedReport && selectedReport === REPORT_TYPE.FINANCE_STOCK_REPORT) {
      const reportDataFinanceStock: Report = {
        fileTitle: `finance_stock_report_in_${selectedWarehouse?.label}_generated`,
        displayTitle: `Download Finance Stock Report Data in ${selectedWarehouse?.label}`,
        data: financeStock,
      };
      setReportData(reportDataFinanceStock);
    }
  }, [financeStockLoading]);

  return (
    <Page isLoading={isLoading} error={isError} title={"Inventory - Reports"}>
      <Panel withWrapper title={"Common Reports"} subtitle={"Create Common Reports"}>
        <InnerPanelWrapper>
          <StyledForm onSubmit={handleSubmit(onSubmit)}>
            <TopPanelInputsWrapper>
              <DataPoint>
                <Label htmlFor={fieldNames.report} isGray={reportData ? true : false} isRequired>
                  Reports
                </Label>
                {!reportData ? (
                  <Controller
                    name={"report"}
                    control={control}
                    rules={{ required: "Report is required" }}
                    render={({ field }) => {
                      return (
                        <Select
                          {...field}
                          onChange={e => {
                            field.onChange(e);
                            setSelectedReport(e?.value || null);
                            if (
                              e?.value === REPORT_TYPE.EMPTY_LOCATIONS ||
                              e?.value === REPORT_TYPE.PRODUCT_STOCK ||
                              e?.value === REPORT_TYPE.INVENTORY_REPORT ||
                              e?.value === REPORT_TYPE.MANIFESTS ||
                              e?.value === REPORT_TYPE.FINANCE_STOCK_REPORT
                            )
                              setWarehouseNeeded(true);
                            else setWarehouseNeeded(false);

                            if (
                              e?.value === REPORT_TYPE.MANIFESTS ||
                              e?.value === REPORT_TYPE.FINANCE_STOCK_REPORT
                            )
                              setDateRangeNeeded(true);
                            else setDateRangeNeeded(false);
                          }}
                          options={reportOptions}
                          maxMenuHeight={220}
                          isSearchable={true}
                          placeholder="Select Report"
                          styles={reactSelectStyling}
                        />
                      );
                    }}
                  />
                ) : (
                  <Box>{allFields.report?.label}</Box>
                )}
                {errors.report && (
                  <ErrorMessage>{errors.report && (errors.report as any).message}</ErrorMessage>
                )}
              </DataPoint>
              {warehouseNeeded && (
                <DataPoint>
                  <Label
                    htmlFor={fieldNames.warehouse}
                    isGray={reportData ? true : false}
                    isRequired
                  >
                    Warehouse
                  </Label>
                  {!reportData ? (
                    <Controller
                      name={"warehouse"}
                      control={control}
                      rules={{ required: "Warehouse is required" }}
                      render={({ field }) => (
                        <Select
                          {...field}
                          options={warehouseNames}
                          onChange={e => {
                            field.onChange(e);
                            setSelectedWarehouse(e!);
                          }}
                          maxMenuHeight={220}
                          isSearchable={true}
                          placeholder="Select Report"
                          styles={reactSelectStyling}
                        />
                      )}
                    />
                  ) : (
                    <Box>{allFields.warehouse?.label}</Box>
                  )}
                  {errors?.warehouse && (
                    <ErrorMessage>{(errors.warehouse as any).message}</ErrorMessage>
                  )}
                </DataPoint>
              )}

              {dateRangeNeeded && (
                <>
                  <DataPoint>
                    <Label
                      htmlFor={fieldNames.fromDate}
                      isGray={reportData ? true : false}
                      isRequired
                    >
                      From Date
                    </Label>
                    {!reportData ? (
                      <Controller
                        name={"fromDate"}
                        control={control}
                        render={({ field }) => {
                          if (!field.value) {
                            field.value = moment().subtract(12, "day").format("YYYY-MM-DD");
                          }

                          return (
                            <DateInput
                              value={
                                field.value ?? moment().subtract(12, "day").format("YYYY-MM-DD")
                              }
                              id={fieldNames.fromDate}
                              type="date"
                              onChange={e => {
                                field.onChange(e);
                                setSelectedFromDate(
                                  moment(e.target.valueAsDate).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          );
                        }}
                      />
                    ) : (
                      <Box>{moment(selectedFromDate).format("YYYY-MM-DD")}</Box>
                    )}
                  </DataPoint>
                  <DataPoint>
                    <Label
                      htmlFor={fieldNames.toDate}
                      isGray={reportData ? true : false}
                      isRequired
                    >
                      To Date
                    </Label>
                    {!reportData ? (
                      <Controller
                        name={"toDate"}
                        control={control}
                        render={({ field }) => {
                          if (!field.value) {
                            field.value = moment().format("YYYY-MM-DD");
                          }

                          return (
                            <DateInput
                              value={field.value ?? moment().format("YYYY-MM-DD")}
                              id={fieldNames.fromDate}
                              type="date"
                              onChange={e => {
                                field.onChange(e);
                                setSelectedToDate(
                                  moment(e.target.valueAsDate).format("YYYY-MM-DD")
                                );
                              }}
                            />
                          );
                        }}
                      />
                    ) : (
                      <Box>{moment(selectedToDate).format("YYYY-MM-DD")}</Box>
                    )}
                  </DataPoint>
                </>
              )}
            </TopPanelInputsWrapper>

            {reportData && (
              <>
                <PrimaryLineWrapper>
                  <Title>Generated Report</Title>
                  <ReportsCard report={reportData} />
                </PrimaryLineWrapper>
              </>
            )}

            <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
              <SecondaryButton
                appearance={"whiteButton"}
                type="reset"
                onClick={() => {
                  reset();
                  setWarehouseNeeded(false);
                  setDateRangeNeeded(false);
                  setReportData(null);
                  setSelectedReport(null);
                }}
              >
                Cancel
              </SecondaryButton>
              {!reportData && (
                <SecondaryButton appearance={"blueButton"} type="submit">
                  Confirm
                </SecondaryButton>
              )}
            </ButtonGroup>
          </StyledForm>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

export default CreateDefinedReport;
