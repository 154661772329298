export interface OrganisationForm {
  organisation_name: string;
}

export const initialFieldValues: OrganisationForm = {
  organisation_name: "",
};

export const fieldNames = {
  organisation_name: "organisation_name",
};
