import { SidebarItem } from "../SideBar";

export const wmsSidebarItems: SidebarItem[] = [
  {
    heading: "My Dashboard",
    headingUrl: "dashboard",
    icon: "navigation-workspaces",
    links: [
      {
        name: "Dashboard",
        URL: "/dashboard",
      },
    ],
  },
  {
    heading: "Manifests",
    headingUrl: "manifests",
    icon: "alert-tick-outline",
    links: [
      {
        name: "Inbound",
        URL: "inbound",
      },
      {
        name: "Outbound",
        URL: "outbound",
      },
      {
        name: "Recipe",
        URL: "recipe",
      },
      {
        name: "Work Orders",
        URL: "work-orders",
      },
    ],
  },
  {
    heading: "Operations",
    headingUrl: "operations",
    icon: "tools-options",
    links: [
      {
        name: "Stock Check",
        URL: "stock-check",
      },
      {
        name: "Stock Check Adhoc",
        URL: "stock-check-adhoc",
      },
      {
        name: "Stock Transfer",
        URL: "stock-transfer",
      },
      {
        name: "Case Change",
        URL: "case-change",
      },
      {
        name: "Stock Allocation",
        URL: "stock-allocation",
      },
    ],
  },
  {
    heading: "Inventory",
    headingUrl: "inventory",
    icon: "tools-view-3d",
    links: [
      {
        name: "Products",
        URL: "products",
      },
      {
        name: "Base Products",
        URL: "base-products",
      },
      {
        name: "Stock levels",
        URL: "stock-levels",
      },
      {
        name: "File Copies",
        URL: "file-copies",
      },
      {
        name: "Organisations",
        URL: "organisations",
      },
      {
        name: "Courier Services",
        URL: "courier-services",
      },
      {
        name: "Reports",
        URL: "reports",
      },
      {
        name: "Custom Stock Report",
        URL: "create-reports",
      },
    ],
  },
  {
    heading: "Warehouse",
    headingUrl: "warehouse-management",
    icon: "shopping-shipping",
    links: [
      {
        name: "Location",
        URL: "location",
      },
      {
        name: "Address Book",
        URL: "address-book",
      },
    ],
  },
  {
    isAdmin: true,
    heading: "Admin",
    headingUrl: "wms-admin",
    icon: "alert-information-outline",
    links: [
      {
        name: "Stock Sync",
        URL: "",
      },
    ],
  },
  {
    heading: "Help",
    headingUrl: "help",
    icon: "alert-question-outline",
    links: [
      {
        name: "WMS Setup Guide",
        URL: "wms-setup-guide",
      },
      {
        name: "WMS User Manual",
        URL: "wms-user-manual",
      },
      {
        name: "Sourcing User Manual",
        URL: "sourcing-user-manual",
      },
    ],
  },
];
