import moment from "moment";

import {
  InboundManifestLineItem,
  ManifestQueryItem,
  ManifestReportItem,
  OutboundManifestLineItem,
} from "../types";

const mapInboundManifestLinesToCSV = (ibmLines: InboundManifestLineItem[]) => {
  return ibmLines.map(ibmLine => {
    const lineNames = ibmLine.inbound_manifest_fulfilment_lines.reduce<string[]>(
      (locations, ffline) => {
        return [
          ...locations,
          ...ffline.product_stocks.map(({ location: { location_name } }) => location_name),
        ];
      },
      []
    );

    const uniqueLineNames = new Set(lineNames);

    return {
      id: ibmLine.inbound_manifest.id,
      manifest_type: "Inbound",
      reference: ibmLine.inbound_manifest.reference,
      account: ibmLine.inbound_manifest.organisation.organisation_name,
      warehouse: ibmLine.inbound_manifest.warehouse.warehouse_name,
      location: uniqueLineNames.size === 1 ? [...uniqueLineNames][0] : "Multiple",
      logistics_type: ibmLine.inbound_manifest.logistics_type.logistics_type_name,
      courier_service: ibmLine.inbound_manifest.courier_service?.courier_service_name || "",
      status: ibmLine.inbound_manifest.inbound_manifest_status.inbound_manifest_status_name,
      expected_date: moment(ibmLine.inbound_manifest.expected_arrival).format("YYYY/MM/DD"),
      base_product: ibmLine.product.product
        ? ibmLine.product.product.product_name
        : ibmLine.product.product_name,
      product: ibmLine.product.product_name,
      expected_qty: ibmLine.quantity,
      recieved_qty: ibmLine.received_quantity,
      dispatched_qty: undefined,
      total_expected_qty: ibmLine.quantity * ibmLine.product.base_product_quantity,
      notes: ibmLine.inbound_manifest.notes,
    };
  });
};

const mapOutboundManifestLinesToCSV = (obmLines: OutboundManifestLineItem[]) => {
  return obmLines.map(obmLine => {
    const lineNames = obmLine.outbound_manifest_fulfilment_lines.reduce<string[]>(
      (locations, ffline) => {
        return [...locations, ffline.product_stock.location.location_name];
      },
      []
    );

    const uniqueLineNames = new Set(lineNames);

    return {
      id: obmLine.outbound_manifest.id,
      manifest_type: "Outbound",
      reference: obmLine.outbound_manifest.reference,
      account: obmLine.outbound_manifest.organisation.organisation_name,
      warehouse: obmLine.outbound_manifest.warehouse.warehouse_name,
      location: uniqueLineNames.size === 1 ? [...uniqueLineNames][0] : "Multiple",
      logistics_type: obmLine.outbound_manifest.logistics_type.logistics_type_name,
      courier_service: obmLine.outbound_manifest.courier_service?.courier_service_name,
      status: obmLine.outbound_manifest.outbound_manifest_status.outbound_manifest_status_name,
      expected_date: moment(obmLine.outbound_manifest.expected_dispatch).format("YYYY/MM/DD"),
      base_product: obmLine.product.product
        ? obmLine.product.product.product_name
        : obmLine.product.product_name,
      product: obmLine.product.product_name,
      expected_qty: obmLine.quantity,
      recieved_qty: undefined,
      dispatched_qty: obmLine.dispatched_quantity,
      total_expected_qty: obmLine.product.base_product_quantity * obmLine.quantity,
      notes: obmLine.outbound_manifest.notes,
    };
  });
};

export const mapManifestsToCSV = (manifests: ManifestQueryItem) => {
  const ibmLines: ManifestReportItem[] = mapInboundManifestLinesToCSV(
    manifests.inbound_manifest_line
  );
  const obmLines: ManifestReportItem[] = mapOutboundManifestLinesToCSV(
    manifests.outbound_manifest_line
  );

  const combinedIbmObmLines = ibmLines.concat(obmLines);

  combinedIbmObmLines.sort((manifestItemOne, manifestItemTwo) => {
    return (
      new Date(manifestItemOne.expected_date).getTime() -
      new Date(manifestItemTwo.expected_date).getTime()
    );
  });

  const ukDateFormattedLines = combinedIbmObmLines.map(line => {
    return {
      ...line,
      expected_date: moment(line.expected_date).format("DD-MM-YYYY"),
    };
  });

  return ukDateFormattedLines;
};
