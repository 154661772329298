import { WMS_API } from "../../../../../api/baseConfig";
import { CreateWorkOrderBody } from "./types";

export const createWorkOrderAPI = async (body: CreateWorkOrderBody) => {
  try {
    return await WMS_API.post("/work-orders", body);
  } catch (err) {
    console.log("Error in creating work Order API call", err);
    throw err;
  }
};
