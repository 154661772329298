import { ApolloError } from "@apollo/client";

import { useGetLocationsWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { LocationItem } from "../types";

export interface HookResult {
  locations: LocationItem[];
  locationsError?: ApolloError;
  locationsLoading: boolean;
}

export const useLocationsWithData = (): HookResult => {
  const { data, error, loading } = useGetLocationsWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    locations: data && Array.isArray(data.location) ? data.location : [],
    locationsError: error,
    locationsLoading: loading,
  };
};
