export const FULFILLING_INBOUND_MANIFEST_LINE = "FULFILLING_INBOUND_MANIFEST_LINE";
export const FULFIL_INBOUND_MANIFEST_LINE_SUCCESS = "FULFIL_INBOUND_MANIFEST_LINE_SUCCESS";
export const FULFIL_INBOUND_MANIFEST_LINE_FAILED = "FULFIL_INBOUND_MANIFEST_LINE_FAILED";
export const FULFIL_INBOUND_MANIFEST_LINE_RESET = "FULFIL_INBOUND_MANIFEST_LINE_RESET";

export const TERMINATE_INBOUND_MANIFEST_LINE_LOADING = "TERMINATE_INBOUND_MANIFEST_LINE_LOADING";
export const TERMINATE_INBOUND_MANIFEST_LINE_SUCCESS = "TERMINATE_INBOUND_MANIFEST_LINE_SUCCESS";
export const TERMINATE_INBOUND_MANIFEST_LINE_FAILED = "TERMINATE_INBOUND_MANIFEST_LINE_FAILED";
export const TERMINATE_INBOUND_MANIFEST_LINE_RESET = "TERMINATE_INBOUND_MANIFEST_RESET";

export const TERMINATE_INBOUND_MANIFEST_LOADING = "TERMINATE_INBOUND_MANIFEST_LOADING";
export const TERMINATE_INBOUND_MANIFEST_SUCCESS = "TERMINATE_INBOUND_MANIFEST_SUCCESS";
export const TERMINATE_INBOUND_MANIFEST_FAILED = "TERMINATE_INBOUND_MANIFEST_FAILED";
export const TERMINATE_INBOUND_MANIFEST_RESET = "TERMINATE_INBOUND_MANIFEST_RESET";

export enum CHANGE_IBM_LINE_STATUS {
  CANCEL = "CANCEL",
  REJECT = "REJECT",
  MARK_AS_DONE = "MARK_AS_DONE",
}

export enum CHANGE_IBM_STATUS {
  CANCEL = "CANCEL",
  REJECT = "REJECT",
  MARK_AS_DONE = "MARK_AS_DONE",
}
