import { useTranslation } from "react-i18next";

import Panel from "../../../../../components/panel/panel";
import { Page } from "../../../../../shared/components/templates/Page";
import { InnerPanelWrapper } from "../../../../../styles/SharedStyles";
import { InformativeErrorPageProps } from "../formValues";

const MISSING_ORDER = {
  pageTitle: "supplier_response_page_title",
  panelTitle: "supplier_response_missing_order",
  panelSubtitle: "supplier_response_missing_order_instructions",
};

const EXPIRED_LINK = {
  pageTitle: "supplier_response_page_title",
  panelTitle: "supplier_response_expired_link",
  panelSubtitle: "supplier_response_expired_link_instructions",
};

export const InformativeErrorPage = ({ type }: InformativeErrorPageProps) => {
  const { t } = useTranslation();

  let translationKeys;

  if (type === "missingOrder") {
    translationKeys = MISSING_ORDER;
  }

  if (type === "expiredLink") {
    translationKeys = EXPIRED_LINK;
  }

  return (
    <Page title={t(translationKeys?.pageTitle || "")} hasBackButton={false} isLoading={false}>
      <Panel
        withWrapper={true}
        title={t(translationKeys?.panelTitle || "")}
        subtitle={t(translationKeys?.panelSubtitle || "")}
      >
        <InnerPanelWrapper></InnerPanelWrapper>
      </Panel>
    </Page>
  );
};
