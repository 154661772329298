import { ApolloError } from "@apollo/client";

import { useGetDashboardStockByLocationTypeQuery } from "../../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../../shared/constants";
import { StockByLocationItem } from "../types";

export interface HookResult {
  stockByLocationType?: StockByLocationItem[];
  stockByLocationTypeError?: ApolloError;
  stockByLocationTypeLoading: boolean;
}

export const useStockByLocationType = (): HookResult => {
  const { data, error, loading } = useGetDashboardStockByLocationTypeQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockByLocationType:
      data && Array.isArray(data.location_type)
        ? data.location_type.map(item => {
            return {
              location_type: item.id,
              product_stocks: item.locations.flatMap(location => location.product_stocks),
            };
          })
        : [],
    stockByLocationTypeError: error,
    stockByLocationTypeLoading: loading,
  };
};
