import { ApolloError } from "@apollo/client";

import { useGetWorkOrderWarehouseNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapWarehouseNamesToDrodownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  warehouseNames: DropdownItem[];
  warehouseNamesError?: ApolloError;
  warehouseNamesLoading: boolean;
}

export const useWarehouseNames = (): HookResult => {
  const { data, error, loading } = useGetWorkOrderWarehouseNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    warehouseNames:
      data && Array.isArray(data.warehouse) ? mapWarehouseNamesToDrodownObject(data.warehouse) : [],
    warehouseNamesError: error,
    warehouseNamesLoading: loading,
  };
};
