import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ButtonGroup } from "@sourceful/shared-components";

import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { addOrUpdateProductStock as addOrUpdateProductStockApi } from "../api/addOrUpdateProductStock";

interface ProductStockInterface {
  quantity: number | undefined;
  organisationId: string | undefined;
}

const emptyProductStock: ProductStockInterface = {
  quantity: 0,
  organisationId: "",
};

interface ProductStockProps {
  skuId: string;
  productStocks: any[];
  organisations?: any[];
  refetchProductStocks: () => Promise<void>;
}

const ProductStock = ({
  skuId,
  productStocks,
  organisations,
  refetchProductStocks,
}: ProductStockProps) => {
  const [newProductStockOrg, setNewProductStockOrg] = useState<DropdownItem | null>(null);
  const [localStocks, setLocalStocks] = useState<any[]>(productStocks);
  const [newQuantities, setNewQuenitites] = useState<number[]>([]);
  const [addOrUpdateProductStockState, addOrUpdateProductStock] = useAPI(
    addOrUpdateProductStockApi
  );

  const updateProductStocks = async ({ quantity, organisationId }: ProductStockInterface) => {
    if (quantity === undefined || quantity < 0) {
      toast.error("Please enter a quanitity for the product stock");
      return;
    }

    if (!organisationId) {
      toast.error("Please select an organisation for the product stock");
      return;
    }

    await addOrUpdateProductStock({
      externalProductId: skuId,
      externalOrganisationId: organisationId,
      quantity,
    });

    refetchProductStocks();
  };

  useEffect(() => {
    if (addOrUpdateProductStockState === API_REQUEST_STATE.SUCCESS) {
      refetchProductStocks();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addOrUpdateProductStockState]);

  return (
    <PrimaryLineWrapper>
      <Title>Product Stock</Title>

      <div>You can only add stock for products of warehouse type.</div>

      {localStocks.length === 0 && <p>No product stocks have been added yet.</p>}

      {localStocks.map((productStock, index) => {
        const externalOrgId = productStock.externalOrganisationId;
        let organisation;
        if (organisations) {
          organisation = organisations.find(org => org.externalOrganisationId === externalOrgId)
            ? organisations.find(org => org.externalOrganisationId === externalOrgId).name
            : externalOrgId;
        }

        const isNew = productStock.id === undefined;

        return (
          <PrimaryLineDataWrapper key={`productStock_${productStock.id}`}>
            <DataPoint withUniqueMargin>
              {isNew ? (
                <div style={{ marginLeft: "10px" }}>
                  <SimpleSelect
                    name={`newProductStockOrganisation`}
                    placeholder={"Select Organisation"}
                    options={
                      organisations?.map(org => ({
                        label: org.name,
                        value: org.externalOrganisationId,
                      })) ?? []
                    }
                    value={newProductStockOrg}
                    error={undefined}
                    touched={undefined}
                    label={"Organisation"}
                    changeHandler={event => setNewProductStockOrg(event as DropdownItem)}
                  />
                </div>
              ) : (
                <DisplayBox label="Organisation" value={organisation} />
              )}
            </DataPoint>
            <DataPoint withUniqueMargin>
              <DisplayBox label="Quantity" value={productStock.quantity.toString()} />
            </DataPoint>
            <DataPoint withUniqueMargin>
              <SimpleInput
                type="number"
                tooltipMessage="Please enter the quantity you want to have <br/> at the end, not the difference."
                htmlFor={`${productStock.id}-quantity`}
                name={`${productStock.id}-quantity`}
                placeholder="Update Quantity"
                size={"medium"}
                error={undefined}
                touched={undefined}
                label={"New Quantity"}
                value={newQuantities[index]}
                changeHandler={event => {
                  let qty = [...newQuantities];
                  qty[index] = parseInt(event.target.value);
                  setNewQuenitites([...qty]);
                }}
              />
            </DataPoint>
            <DataPoint withUniqueMargin>
              {isNew ? (
                <SecondaryButton
                  disabled={!organisations}
                  onClick={() =>
                    updateProductStocks({
                      organisationId: organisations?.find(
                        org => org.externalOrganisationId === newProductStockOrg?.value
                      )?.externalOrganisationId,
                      quantity: newQuantities[index],
                    })
                  }
                  css={{ marginTop: "15px" }}
                  appearance="whiteButtonBlueText"
                >
                  Insert
                </SecondaryButton>
              ) : (
                <SecondaryButton
                  onClick={() =>
                    updateProductStocks({
                      organisationId: externalOrgId,
                      quantity: newQuantities[index],
                    })
                  }
                  css={{ marginTop: "15px" }}
                  appearance="whiteButtonBlueText"
                >
                  Update
                </SecondaryButton>
              )}
            </DataPoint>
          </PrimaryLineDataWrapper>
        );
      })}

      {localStocks.length === productStocks.length && (
        <ButtonGroup css={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
          <SecondaryButton onClick={() => setLocalStocks([...productStocks, emptyProductStock])}>
            Add new product stock
          </SecondaryButton>
        </ButtonGroup>
      )}
    </PrimaryLineWrapper>
  );
};

export default ProductStock;
