import { SOURCING_API, UNAUTHENTICATED_SOURCING_API } from "../../../../../api/baseConfig";
import { FetchSkuBody, ProductCatalogueSkuBody } from "./types";

export const fetchSkuAPI = async ({
  skuId,
  token,
}: FetchSkuBody): Promise<ProductCatalogueSkuBody> => {
  try {
    if (token) {
      const { data } = await UNAUTHENTICATED_SOURCING_API.post(`/products/${skuId}`, {
        access_token: token,
      });

      return data.productDetails;
    } else {
      const { data } = await SOURCING_API.post(`/products/${skuId}`);

      return data.productDetails;
    }
  } catch (err) {
    console.error("Error in fetch sku get API call", err);
    throw err;
  }
};
