import { createRef, useState } from "react";

import { Heading, Modal } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  ScrollableModal,
  Title,
} from "../../../../../styles/SharedStyles";
import AttributesSummary from "../../../shared/components/AttributesSummary";
import { ProductCatalogueSkuBody } from "../../../shared/fetchSku/api/types";
import { prettifyProductName } from "../../../shared/mappers";
import { SupplierQuoteRequestLineObject } from "../graphql/types";
import { SupplierQuoteLine } from "./SupplierQuoteLine";

interface SupplierQuoteRequestLineProps {
  group: SupplierQuoteRequestLineObject;
  index: number;
  handleSetOpenQuoteLine: (index: number | null) => void;
  openQuoteLine: number | null;
  productDetails?: ProductCatalogueSkuBody;
}

export const SupplierQuoteRequestLine = ({
  group,
  index,
  handleSetOpenQuoteLine,
  openQuoteLine,
  productDetails,
}: SupplierQuoteRequestLineProps) => {
  const modalRef = createRef<HTMLButtonElement>();
  const [showingProductDetails, setShowingProductDetails] = useState(false);

  return (
    <PrimaryLineWrapper>
      <Title>Quote Request Line</Title>
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin halfWidth>
          <DisplayBox
            withUniqueMargin
            fullWidth
            label="Product Name"
            value={prettifyProductName(group.product.product_name)}
          />
        </DataPoint>
        <DataPoint withUniqueMargin halfWidth>
          <DisplayBox label="Internal Notes" value={group.notes} fullWidth withUniqueMargin />
        </DataPoint>
        {group.lines.length === 0 && (
          <DataPoint withUniqueMargin halfWidth>
            <DisplayBox label="Requested Qty(s)" value={group.moqs} fullWidth withUniqueMargin />
          </DataPoint>
        )}
      </PrimaryLineDataWrapper>
      {productDetails && (
        <PrimaryLineDataWrapper>
          <SecondaryButton
            type="button"
            onClick={() => setShowingProductDetails(true)}
            appearance="blueButton"
            ref={modalRef}
          >
            View Product Details
          </SecondaryButton>
        </PrimaryLineDataWrapper>
      )}
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin fullWidth>
          <Title>Attachments</Title>
          {!group.folder || group.folder.attachments.length === 0 ? (
            <PrimaryLineDataWrapper>
              No attachments have been added to this product line.
            </PrimaryLineDataWrapper>
          ) : (
            <>
              {group.folder.attachments.map(attachment => (
                <PrimaryLineDataWrapper key={`attachment_${attachment.id}`}>
                  <AttachmentDownloader
                    key={`attachment_${attachment.id}`}
                    originalFilename={attachment.original_filename}
                    attachmentUUID={attachment.attachment_uuid}
                    metadata={attachment.metadata}
                  />
                </PrimaryLineDataWrapper>
              ))}
            </>
          )}
        </DataPoint>
      </PrimaryLineDataWrapper>

      {group.lines.map(line => (
        <SupplierQuoteLine
          key={`line_${line.id}`}
          openQuoteLine={openQuoteLine}
          handleSetOpenQuoteLine={handleSetOpenQuoteLine}
          line={line}
        />
      ))}

      <Modal
        isOpen={showingProductDetails}
        handleClose={() => setShowingProductDetails(false)}
        id={`product_details_${index}`}
        triggerRef={modalRef}
      >
        <ScrollableModal>
          <Heading level={3} fontSize={5} css={{ margin: "0 10px 10px" }}>
            Product Details
          </Heading>
          {productDetails && showingProductDetails && (
            <AttributesSummary
              physicalAttributes={productDetails?.physical_attributes}
              contentAttributes={productDetails?.content_attributes}
            />
          )}
        </ScrollableModal>
      </Modal>
    </PrimaryLineWrapper>
  );
};
