import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup } from "@sourceful/shared-components";

import LoadingScreen from "../../../../../components/loadingScreen/LoadingScreen";
import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InfoBox,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import StockCheckLineFulfilmentProductStock from "../../shared/components/StockCheckLineFulfilmentProductStock";
import StockCheckLineOriginalStock from "../../shared/components/StockCheckLineOriginalStock";
import StockCheckLineToFulfil from "../../shared/components/StockCheckLineToFulfil";
import { useStockCheckById } from "../../shared/graphql/hooks/useStockCheckById";
import {
  accuracyClassifier,
  calculateStockCheckPercentageAccuracy,
  isActiveStockCheckLine,
} from "../../shared/helpers";
import { STOCK_CHECK_LINE_STATUS, STOCK_CHECK_STATUS } from "../../shared/models";
import { FulfilStockCheckLineBody } from "../api/fulfilStockCheckLine/types";
import { MarkStockCheckAsCompleteParams } from "../api/markStockCheckAsComplete/types";
import { MarkStockCheckLineAsCompleteParams } from "../api/markStockCheckLineAsComplete/types";
import FulfilStockCheckLine from "../component/FulfilStockCheckLine";
import { FulfilStockCheckLineForm, initialFieldValues } from "../formValues";
import { useStockConditions } from "../graphql/hooks/useStockConditions";
import { mapStockCheckLineFulfilmentFormValuesToAPIBody } from "../mappers";
import {
  fulfilStockCheckLineAction,
  fulfilStockCheckLineReset,
} from "../redux/actions/fulfilStockCheckLineAction";
import {
  markStockCheckAsCompleteAction,
  markStockCheckAsCompleteReset,
} from "../redux/actions/markStockCheckAsCompleteAction";
import {
  markStockCheckLineAsCompleteAction,
  markStockCheckLineAsCompleteReset,
} from "../redux/actions/markStockCheckLineAsCompleteAction";
import { FulfilStockCheckLineReducer } from "../redux/reducers/fulfilStockCheckLineReducer";
import { MarkStockCheckAsCompleteReducer } from "../redux/reducers/markStockCheckAsCompleteReducer";
import { MarkStockCheckLineAsCompleteReducer } from "../redux/reducers/markStockCheckLineAsCompleteReducer";
import { fulfilStockCheckLineValidation } from "../validation";

interface Props {
  fulfilStockCheckLineState: FulfilStockCheckLineReducer;
  markStockCheckAsCompleteState: MarkStockCheckAsCompleteReducer;
  markStockCheckLineAsCompleteState: MarkStockCheckLineAsCompleteReducer;
  fulfilStockCheckLine: (body: FulfilStockCheckLineBody) => Promise<void>;
  resetFulfilStockCheckLine: () => void;
  markStockCheckAsComplete: (body: MarkStockCheckAsCompleteParams) => Promise<void>;
  resetMarkStockCheckAsComplete: () => void;
  markStockCheckLineAsComplete: (body: MarkStockCheckLineAsCompleteParams) => Promise<void>;
  resetMarkStockCheckLineAsComplete: () => void;
}

// TODO: Extract out location and product box as its own component
function FulfilStockCheck(props: Props) {
  const [initialValues, setInitialValues] = useState<FulfilStockCheckLineForm>(initialFieldValues);
  const { stock_check_id } = useParams<{ stock_check_id: string }>();
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const [lineToDisplayFulfilments, setLineToDisplayFulfilments] = useState<number | null>(null);
  const [lineToDisplayOriginalStock, setLineToDisplayOriginalStock] = useState<number | null>(null);
  const [lineToFulfil, setLineToFulfil] = useState<number | null>(null);
  const [percentageAccuracy, setPercentageAccuracy] = useState<number | null>(null);

  const { stockCheck, stockCheckLoading, stockCheckError, refetchStockCheck } = useStockCheckById(
    Number(stock_check_id)
  );

  const { stockConditionsLoading, stockConditions, stockConditionsError } = useStockConditions();

  useEffect(() => {
    if (stockCheck) {
      const accuracy = calculateStockCheckPercentageAccuracy({ stockCheck: stockCheck });
      setPercentageAccuracy(accuracy);
    }
  }, [stockCheck]);

  const markStockCheckLineAsCompleteAlert = (
    body: MarkStockCheckLineAsCompleteParams,
    index: number
  ) => {
    return confirmAlert({
      title: `Confirm Change to Stock Check Line ${index}`,
      message: `Are you sure you want to mark this Stock Check line ${index} as COMPLETE?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => markStockCheckLineAsComplete(body),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const markStockCheckAsCompleteAlert = (body: MarkStockCheckAsCompleteParams) => {
    return confirmAlert({
      title: "Confirm Change to Stock Check",
      message: `Are you sure you want to mark this Stock Check and All Its Stock Check Lines as COMPLETE?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => markStockCheckAsComplete(body),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const {
    markStockCheckLineAsCompleteState,
    markStockCheckLineAsComplete,
    markStockCheckAsCompleteState,
    fulfilStockCheckLineState,
    resetFulfilStockCheckLine,
    resetMarkStockCheckAsComplete,
    resetMarkStockCheckLineAsComplete,
    markStockCheckAsComplete,
    fulfilStockCheckLine,
  } = props;

  useEffect(() => {
    if (markStockCheckLineAsCompleteState.success) {
      resetMarkStockCheckLineAsComplete();
      refetchStockCheck();
    }

    if (markStockCheckLineAsCompleteState.error) {
      resetMarkStockCheckLineAsComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markStockCheckLineAsCompleteState.success, markStockCheckLineAsCompleteState.error]);

  useEffect(() => {
    if (markStockCheckAsCompleteState.success) {
      resetMarkStockCheckAsComplete();
      refetchStockCheck();
    }

    if (markStockCheckAsCompleteState.error) {
      resetMarkStockCheckAsComplete();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markStockCheckAsCompleteState.success, markStockCheckAsCompleteState.error]);

  useEffect(() => {
    if (fulfilStockCheckLineState.success) {
      setLineToFulfil(null);
      setInitialValues(initialFieldValues);
      resetFulfilStockCheckLine();
      refetchStockCheck();
    }

    if (fulfilStockCheckLineState.error) {
      resetFulfilStockCheckLine();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilStockCheckLineState.success, fulfilStockCheckLineState.error]);

  const onSubmit = (values: FulfilStockCheckLineForm) => {
    if (!lineToFulfil) {
      toast.error("Please Select the Stock Check Line you wish to fulfil");
      return;
    }

    const fulfilStockCheckLineBody = mapStockCheckLineFulfilmentFormValuesToAPIBody({
      values: values,
      stockCheckId: Number(stock_check_id),
      stockCheckLine: lineToFulfil,
    });

    setInitialLoading(false);
    fulfilStockCheckLine(fulfilStockCheckLineBody);
  };

  const isError = stockCheckError || stockConditionsError;
  const isLoading =
    (stockCheckLoading && initialLoading) ||
    stockConditionsLoading ||
    markStockCheckAsCompleteState.loading;

  return (
    <Page error={isError} isLoading={isLoading} title={"Operations - Fulfil Stock Check"}>
      <Panel
        withWrapper
        title={"Fulfil Stock Check"}
        subtitle={"Use the form below to Fulfil a  stock check"}
        sourcingStatusName={stockCheck?.stock_check_status.stock_check_status_name}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={fulfilStockCheckLineValidation}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched, resetForm }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DisplayBox isGray label="Stock Check ID" value={`#${stockCheck?.id}`} />
                  <DisplayBox
                    isGray
                    label="Stock Check Type"
                    value={stockCheck?.stock_check_type.stock_check_type_name}
                  />
                  <DisplayBox isGray label="Location" value={stockCheck?.location?.location_name} />
                  <DisplayBox
                    isGray
                    label="Status"
                    value={stockCheck?.stock_check_status?.stock_check_status_name}
                  />
                  <DisplayBox
                    isGray
                    label="Due Date"
                    value={
                      stockCheck?.due_date && moment(stockCheck?.due_date).format("DD/MM/YYYY")
                    }
                  />
                  <DisplayBox isGray label="Notes" value={stockCheck?.notes} />
                  <DataPoint>
                    <Label isGray> Accuracy</Label>
                    {percentageAccuracy && (
                      <InfoBox
                        type={accuracyClassifier({ percentageAccuracy: percentageAccuracy })}
                      >
                        {percentageAccuracy.toFixed(2)} %
                      </InfoBox>
                    )}
                  </DataPoint>
                </TopPanelInputsWrapper>

                {stockCheck?.stock_check_lines.map((stockCheckLine, index) => {
                  const isActiveDisplayFulfillmentsLine = isActiveStockCheckLine({
                    lineId: stockCheckLine.id,
                    selectedLineId: lineToDisplayFulfilments,
                  });

                  const isActiveDisplayOriginalStockLine = isActiveStockCheckLine({
                    lineId: stockCheckLine.id,
                    selectedLineId: lineToDisplayOriginalStock,
                  });

                  const isActiveLineToFulfil = isActiveStockCheckLine({
                    lineId: stockCheckLine.id,
                    selectedLineId: lineToFulfil,
                  });

                  const isCompleted =
                    stockCheckLine.stock_check_line_status.id ===
                      STOCK_CHECK_LINE_STATUS.COMPLETE ||
                    stockCheck.stock_check_status.id === STOCK_CHECK_STATUS.COMPLETE;

                  return (
                    <PrimaryLineWrapper key={stockCheckLine.id}>
                      <StockCheckLineToFulfil stockCheckLine={stockCheckLine} index={index} />

                      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                        {stockCheckLine.stock_check_line_fulfilments.length > 0 && (
                          <SecondaryButton
                            type="button"
                            onClick={() => {
                              if (isActiveDisplayFulfillmentsLine) {
                                setLineToDisplayFulfilments(null);
                              } else {
                                setLineToDisplayFulfilments(stockCheckLine.id);
                              }
                            }}
                            appearance={"whiteButton"}
                          >
                            {isActiveDisplayFulfillmentsLine
                              ? "Hide Fulfilments"
                              : "Display Fulfilments"}
                          </SecondaryButton>
                        )}

                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            if (isActiveDisplayOriginalStockLine) {
                              setLineToDisplayOriginalStock(null);
                            } else {
                              setLineToDisplayOriginalStock(stockCheckLine.id);
                            }
                          }}
                          appearance={"whiteButton"}
                        >
                          {isActiveDisplayOriginalStockLine
                            ? "Hide Original Stock"
                            : "Display Original Stock"}
                        </SecondaryButton>

                        {!isCompleted && (
                          <>
                            <SecondaryButton
                              type="button"
                              onClick={() => {
                                resetForm();
                                setLineToFulfil(stockCheckLine.id);
                              }}
                              appearance={"whiteButtonBlueText"}
                            >
                              Fulfil
                            </SecondaryButton>
                            <SecondaryButton
                              type="button"
                              onClick={() => {
                                setInitialLoading(false);
                                markStockCheckLineAsCompleteAlert(
                                  {
                                    stockCheckId: stockCheck?.id,
                                    stockCheckLineId: stockCheckLine.id,
                                  },
                                  index + 1
                                );
                              }}
                              appearance={"blueButton"}
                            >
                              Mark As Complete
                            </SecondaryButton>
                          </>
                        )}
                      </ButtonGroup>

                      {isActiveLineToFulfil && (
                        <FulfilStockCheckLine
                          setFieldValue={setFieldValue}
                          errors={errors}
                          touched={touched}
                          values={values}
                          stockConditions={stockConditions}
                          setLineToFulfil={setLineToFulfil}
                        />
                      )}

                      {isActiveLineToFulfil &&
                        (stockCheckLoading || fulfilStockCheckLineState.loading) && (
                          <LoadingScreen />
                        )}

                      {isActiveDisplayFulfillmentsLine &&
                        stockCheckLine.stock_check_line_fulfilments.map((fulfilment, index) => {
                          return fulfilment.product_stocks.map(productStock => {
                            return (
                              <StockCheckLineFulfilmentProductStock
                                key={productStock.id}
                                productStock={productStock}
                                stockCheckLineFulfilment={fulfilment}
                                index={index}
                              />
                            );
                          });
                        })}

                      {isActiveDisplayOriginalStockLine && (
                        <StockCheckLineOriginalStock productStock={stockCheckLine.product_stock} />
                      )}
                    </PrimaryLineWrapper>
                  );
                })}

                {stockCheck?.stock_check_status.id !== STOCK_CHECK_STATUS.COMPLETE && (
                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      type="button"
                      onClick={() => {
                        markStockCheckAsCompleteAlert({
                          stockCheckId: stockCheck?.id!,
                        });
                      }}
                      appearance={"blueButton"}
                    >
                      Mark As Complete
                    </SecondaryButton>
                  </ButtonGroup>
                )}
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    fulfilStockCheckLineState: state.fulfilStockCheckLineReducer,
    markStockCheckAsCompleteState: state.markStockCheckAsCompleteReducer,
    markStockCheckLineAsCompleteState: state.markStockCheckLineAsCompleteReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    fulfilStockCheckLine: (body: FulfilStockCheckLineBody) =>
      dispatch(fulfilStockCheckLineAction(body)),
    resetFulfilStockCheckLine: () => dispatch(fulfilStockCheckLineReset()),
    markStockCheckAsComplete: (body: MarkStockCheckAsCompleteParams) =>
      dispatch(markStockCheckAsCompleteAction(body)),
    resetMarkStockCheckAsComplete: () => dispatch(markStockCheckAsCompleteReset()),
    markStockCheckLineAsComplete: (body: MarkStockCheckLineAsCompleteParams) =>
      dispatch(markStockCheckLineAsCompleteAction(body)),
    resetMarkStockCheckLineAsComplete: () => dispatch(markStockCheckLineAsCompleteReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfilStockCheck);
