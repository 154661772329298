import * as Yup from "yup";

export const validationSchema = Yup.object({
  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),

  stock_check_lines: Yup.array().of(
    Yup.object({
      product_stock: Yup.object()
        .shape({
          value: Yup.number().required("Product Stock is required"),
        })
        .typeError("Product Stock is required"),
    })
  ),
});
