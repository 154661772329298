import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { AlignIconCenter, LinkText } from "../../../../../styles/SharedStyles";
import { useLocationsWithData } from "../graphql/hooks/useLocationsWithData";
import { LocationItem } from "../graphql/types";

export default function ViewLocations() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { locations, locationsLoading, locationsError } = useLocationsWithData();

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.1,
        accessor: (location: LocationItem) => location.id,
      },
      {
        Header: "Warehouse",
        widthPercent: 0.6,
        filter: "arraySome",
        accessor: (location: LocationItem) => location.warehouse.warehouse_name,
        Cell: ({ _, row }: CellProps<LocationItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Location Type",
        widthPercent: 0.1,
        accessor: (location: LocationItem) => location.location_type.location_type_name,
      },
      {
        Header: "Location Name",
        widthPercent: 0.6,
        accessor: (location: LocationItem) => location.location_name,
      },
      {
        Header: "Can Hold Stock",
        widthPercent: 0.1,
        accessor: (location: LocationItem) => (location.can_hold_stock ? "True" : "False"),
        Cell: ({ _, row }: CellProps<LocationItem>) => {
          return row.original.can_hold_stock ? (
            <AlignIconCenter>
              <Icon name={"alert-tick-outline"}></Icon>
            </AlignIconCenter>
          ) : (
            <AlignIconCenter>
              <Icon name={"alert-cross-outline"}></Icon>
            </AlignIconCenter>
          );
        },
      },
      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<LocationItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/warehouse-management/add-location");
          },
          type: "button",
          title: "Add Location",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  return (
    <Page error={locationsError} isLoading={locationsLoading} title={"Locations"}>
      <Panel
        withWrapper
        title={"Location List"}
        text={"Use the table below to explore Sourceful's locations"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={locations} columns={columns} />
      </Panel>
    </Page>
  );
}
