export interface FilterQueryInterface {
  locationTypeId: null | number;
  warehouseId: null | number;
  productId: null | number;
  stockConditionId: null | number;
  organisationId: null | number;
}

export const CustomReportColumns: any = [
  {
    Header: "ID",
    widthPercent: 0.1,
    accessor: (product: any) => product.id,
  },
  {
    Header: "Name",
    widthPercent: 1.5,
    accessor: (product: any) => product.product.product_name,
  },
  {
    Header: "Quantity",
    widthPercent: 0.6,
    accessor: (product: any) => product.quantity,
  },
  {
    Header: "Condition",
    widthPercent: 0.9,
    accessor: (product: any) => product.stock_condition.stock_condition_name,
  },
  {
    Header: "Status",
    widthPercent: 0.4,
    accessor: (product: any) => product.stock_status.stock_status_name,
  },
];
