import { ShipmentUpdateItem } from "../graphql/types/ShipmentUpdateItem";

export const getLastShipmentUpdate = (
  shipmentUpdates: ShipmentUpdateItem[],
  type?: number
): ShipmentUpdateItem | undefined => {
  if (shipmentUpdates.length === 0) {
    return undefined;
  }
  const lastIndex = shipmentUpdates.length - 1;

  if (type) {
    for (let index = lastIndex; index >= 0; index--) {
      const updateAtIndex = shipmentUpdates[index];
      if (updateAtIndex.shipment_update_type.id === type) {
        return updateAtIndex;
      }
    }
    return undefined;
  }
  return shipmentUpdates[lastIndex];
};
