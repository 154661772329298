import * as Yup from "yup";

import { CHANGE_STOCK_CONDITION } from "../constants";

export const fulfilStockCheckLineValidation = Yup.object({
  action: Yup.object()
    .shape({
      value: Yup.number().required("Action is required"),
    })
    .typeError("Action is required"),
  quantity: Yup.string().required("Quantity is Required"),
  stock_condition: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .typeError("Stock Condition is required")
    .when("action", {
      is: (
        stock_check_type: {
          value: number;
          label: string;
        } | null
      ) => stock_check_type?.label === CHANGE_STOCK_CONDITION,
      then: Yup.object()
        .shape({
          value: Yup.number().required("New Stock Condition is required"),
        })
        .typeError("New Stock Condition is required"),
      otherwise: Yup.object().nullable(),
    }),
});
