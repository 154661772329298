import { useHistory } from "react-router-dom";

import { HorizontalCard } from "../../../../../components/card/HorizontalCard";
import { VerticalCard } from "../../../../../components/card/VerticalCard";
import { Page } from "../../../../../shared/components/templates/Page";
import { CardsWrapper, HorizontalCardWrapper } from "../../../../../styles/AdminPage";
import InventoryTableByLocationType from "../../shared/inventoryTableByLocationType/page/InventoryTableByLocationType";
import { useDashboardInboundManifests } from "../graphql/hooks/useDashboardInboundManifests";
import { useDashboardOutboundManifests } from "../graphql/hooks/useOutboundManifestsByDate";

export default function ViewHomeDashboard() {
  let history = useHistory();

  const { inboundManifests, inboundManifestsLoading, inboundManifestsError } =
    useDashboardInboundManifests();
  const { outboundManifests, outboundManifestsLoading, outboundManifestsError } =
    useDashboardOutboundManifests();

  const error = inboundManifestsError || outboundManifestsError;
  const isLoading = inboundManifestsLoading || outboundManifestsLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"WMS Dashboard"} hasBackButton={false}>
      <>
        <CardsWrapper>
          <VerticalCard
            sortBy
            cardTitle={"Inbound Manifests"}
            linkText="View inbound manifest"
            link="/manifests/inbound/review-inbound-manifest/"
            tooltip="Inbound Manifest: A list of items <br/> that a warehouse should be  <br/> expecting to recieve"
            scrollBarContent={inboundManifests}
          />
          <VerticalCard
            sortBy
            cardTitle={"Outbound Manifests"}
            linkText="View outbound manifest"
            link="/manifests/outbound/review-outbound-manifest/"
            tooltip="Outbound Manifest: A list of <br/> items that a warehouse should <br/> expect to be collected"
            scrollBarContent={outboundManifests}
          />

          <HorizontalCardWrapper>
            <HorizontalCard
              title={"Stock check"}
              description={"Perform stock check operations easily"}
              primaryButton={{
                id: 1,
                iconName: "tools-options",
                buttonText: "Stock check",
                handleClick: () => {
                  history.push("/operations/create-stock-check");
                },
              }}
              secondaryButton={{
                id: 2,
                iconName: "tools-options",
                buttonText: "Adhoc check",
                handleClick: () => {
                  history.push("/operations/stock-check-adhoc");
                },
              }}
            />
            <HorizontalCard
              title={"Stock reporting"}
              primaryButton={{
                id: 3,
                iconName: "alert-add-outline",
                buttonText: "Change reports",
                handleClick: () => {
                  history.push("/inventory/reports");
                },
              }}
            />
          </HorizontalCardWrapper>
        </CardsWrapper>

        <InventoryTableByLocationType />
      </>
    </Page>
  );
}
