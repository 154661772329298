import React from "react";

import { styled } from "@sourceful/shared-components";

import { Icon } from "../../../../components/icons/Icon";
import { IconName } from "../../../../components/icons/IconSprite";
import Label from "../../atoms/labels/Label";

const InputWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  position: "relative",

  ".primary-icon": {
    zIndex: 10,
    position: "absolute",
    top: "19%",
    left: "0.7em",
    color: "#404044",
  },

  ".secondary-icon": {
    zIndex: 10,
    position: "absolute",
    top: "19%",
    right: "0.7em",
    color: "#404044",
  },
});

const StyledTextArea = styled("textarea", {
  display: "flex",
  position: "relative",
  width: "100%",
  borderRadius: "6px",
  background: "$lightest",
  border: "1px solid #DCDCDD",
  boxSizing: "border-box",
  outline: "none",
  color: "$darkest",
  letterSpacing: "-0.025em",
  alignItems: "center",
  "&:hover": {
    border: "1px solid #404044",
  },
  "&:focus": {
    border: "1px solid #005AE1",
    boxShadow: " 0 0 0 4px #99BDF3",
  },
  "&:disabled": {
    background: "#F6F6F6",
    color: "#B9B9BB",
    border: "1px solid #DCDCDD",
    "&:hover": {
      border: "1px solid #DCDCDD",
    },
  },
  variants: {
    size: {
      medium: {
        height: "70px",
        fontSize: "14px",
        lineHeight: "26px",
        paddingLeft: "12px",
        paddingRight: "12px",
      },
      small: {
        height: "40px",
        fontSize: "12px",
        lineHeight: "19px",
        paddingLeft: "10px",
        paddingRight: "10px",
      },
      large: {
        height: "100px",
        fontSize: "15px",
        lineHeight: "26px",
        paddingLeft: "15px",
        paddingRight: "15px",
      },
    },
    appearance: {
      pill: {
        borderRadius: "$pill",
      },
    },

    isValid: {
      true: {
        border: "1px solid #0A6446",
      },
      false: {
        border: "1px solid #CA466E",
      },
    },
  },
});

const InputFieldWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  position: "relative",
  fontFamily: "Space Grotesk",
  width: "auto",
});

const HelperTextWrapper = styled("p", {
  fontSize: "12px",
  lineHeight: "19px",
  letterSpacing: "-0.025em",
  color: "#747477",
  height: "auto",
  margin: "0px",
  padding: "0px",
  marginTop: "8px",
  variants: {
    isValid: {
      true: {
        color: "#0A6446",
      },
      false: {
        color: "#CA466E",
      },
    },
  },
});

export interface InputFieldProps {
  placeholder?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  id?: string;
  name?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  type: "email" | "number" | "text";
  helperText?: string;
  label?: string;
  errors?: any[];
  size?: "large" | "medium" | "small";
  appearance?: "pill";
  primaryIcon?: IconName;
}

const inputSizeToIconPx = {
  small: 20,
  medium: 25,
  large: 30,
};

interface InputProps {
  placeholder?: string;
  value: string;
  handleChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  handleBlur?: (e: React.FocusEvent<HTMLTextAreaElement>) => void;
  id?: string;
  name?: string;
  isRequired?: boolean;
  isDisabled?: boolean;
  type: "email" | "number" | "text";
  size?: "large" | "medium" | "small";
  appearance?: "pill";
  primaryIcon?: IconName;
  secondaryIcon?: IconName | (() => JSX.Element);
  isValid?: boolean;
}

const Input = ({
  primaryIcon,
  secondaryIcon,
  placeholder,
  value,
  handleChange,
  handleBlur,
  id,
  isRequired,
  isDisabled,
  size = "large",
  name,
  appearance,
  isValid,
}: InputProps) => (
  <InputWrapper>
    {primaryIcon && (
      <Icon
        name={primaryIcon}
        className="primary-icon"
        width={inputSizeToIconPx[size]}
        height={inputSizeToIconPx[size]}
      />
    )}
    <StyledTextArea
      appearance={appearance}
      disabled={isDisabled}
      required={isRequired}
      id={id}
      name={name || id}
      placeholder={placeholder}
      onChange={handleChange}
      onBlur={handleBlur}
      value={value}
      aria-invalid={!isValid}
      isValid={isValid}
      size={size}
    />

    {secondaryIcon && typeof secondaryIcon === "string" ? (
      <Icon
        className="secondary-icon"
        name={secondaryIcon}
        width={inputSizeToIconPx[size]}
        height={inputSizeToIconPx[size]}
      />
    ) : (
      secondaryIcon && secondaryIcon()
    )}
  </InputWrapper>
);

const TextArea = ({
  primaryIcon,
  placeholder,
  value,
  handleChange,
  handleBlur,
  id,
  isRequired,
  isDisabled,
  type,
  label,
  helperText,
  errors,
  size = "large",
  name,
  appearance,
}: InputFieldProps) => {
  const isValid = errors && errors.length === 0;
  const isInvalid = errors && errors.length > 0;

  return (
    <InputFieldWrapper css={{ ".secondary-icon": { color: "#0A6446" } }}>
      {label && (
        <Label isGray={isDisabled} isRequired={isRequired} htmlFor={id}>
          {label}
        </Label>
      )}

      <Input
        appearance={appearance}
        type={type}
        isDisabled={isDisabled}
        isRequired={isRequired}
        id={id}
        name={name || id}
        placeholder={placeholder}
        handleChange={handleChange}
        handleBlur={handleBlur}
        value={value}
        aria-invalid={isInvalid ? true : isValid && false}
        isValid={isValid ? true : isInvalid && false}
        size={size}
        primaryIcon={primaryIcon}
        secondaryIcon={isValid && !isDisabled ? "alert-tick-default" : undefined}
      />

      {helperText && !isDisabled && (
        <HelperTextWrapper isValid={isValid ? true : isInvalid && false}>
          {helperText}
        </HelperTextWrapper>
      )}
    </InputFieldWrapper>
  );
};

export default TextArea;
