import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { stockSyncAPI } from "../../api/stockSyncAPI";
import {
  STOCK_SYNC_FAILED,
  STOCK_SYNC_LOADING,
  STOCK_SYNC_RESET,
  STOCK_SYNC_SUCCESS,
} from "../types";

export const stockSyncLoading = () => {
  return {
    type: STOCK_SYNC_LOADING,
  };
};

export const stockSyncSuccess = () => {
  return {
    type: STOCK_SYNC_SUCCESS,
  };
};

export const stockSyncFailed = () => {
  return {
    type: STOCK_SYNC_FAILED,
  };
};

export const stockSyncReset = () => {
  return {
    type: STOCK_SYNC_RESET,
  };
};

export const stockSyncAction = () => {
  return async (dispatch: Dispatch) => {
    toast("Stock syncing", { hideProgressBar: true });
    console.log("Stock sync - Action");
    dispatch(stockSyncLoading());
    try {
      await stockSyncAPI();
      toast.success("Stock sync completed.");
      dispatch(stockSyncSuccess());
    } catch (err) {
      toast.error("Something went wrong with stock syncing.");
      dispatch(stockSyncFailed());
    }
  };
};
