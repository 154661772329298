import { ApolloError } from "@apollo/client";

import { useGetQaInspectionsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QAInspectionItem } from "../types";

export interface HookResult {
  qaInspections: QAInspectionItem[];
  qaInspectionsError?: ApolloError;
  qaInspectionsLoading: boolean;
}

export const useQAInspections = (): HookResult => {
  const { data, error, loading } = useGetQaInspectionsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    qaInspections:
      data && Array.isArray(data.quality_assurance_inspection)
        ? data.quality_assurance_inspection
        : [],
    qaInspectionsError: error,
    qaInspectionsLoading: loading,
  };
};
