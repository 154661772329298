import { LOGISTICS_API } from "../../../../../api/baseConfig";
import { CreateShipmentDeliveredUpdateBody } from "./types";

export interface CreateShipmentDeliveredUpdateRequest {
  shipmentId: number;
  body: CreateShipmentDeliveredUpdateBody;
}

export const createShipmentDeliveredUpdateAPI = async ({
  shipmentId,
  body,
}: CreateShipmentDeliveredUpdateRequest) => {
  try {
    return await LOGISTICS_API.patch(`/shipments/${shipmentId}/delivered`, body);
  } catch (err) {
    console.log("Error in create delivered update for shipment API call", err);
    throw err;
  }
};
