import { createRef, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";

import { ButtonGroup, Heading, IconText, Modal, styled } from "@sourceful/shared-components";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import AttachmentUploader from "../../../../../components/AttachmentUploader/AttachmentUploader";
import { FlexEndContainer } from "../../../../../components/ModalContainer/ModalContainer";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { DeletePill } from "../../../../../shared/components/molecules/DeletePill/DeletePill";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  ButtonWrapper,
  DataPoint,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { useAPI } from "../../../../../util/api/apiHook";
import AttributesSummary from "../../../shared/components/AttributesSummary";
import { emptyAttachment } from "../../../shared/constants";
import { fetchSkuAPI } from "../../../shared/fetchSku/api/fetchSku";
import { ProductCatalogueSkuBody } from "../../../shared/fetchSku/api/types";
import { mapProductsToDropdownObject } from "../mappers";
import { ProductLineProps } from "../types";
import { QuantityTags } from "./QuantityTags";

const AttachmentContainer = styled("div", {
  marginLeft: "10px",
  padding: "20px 0",
});

export const ProductLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveProductLine,
  productsData,
  errors,
  touched,
}: ProductLineProps) => {
  const [deleteLineModalOpen, setDeleteLineModalOpen] = useState(false);
  const [menuIsOpen, setMenuIsOpen] = useState(false);
  const [displayProductDetails, setDisplayProductDetails] = useState(false);
  const [productDetails, setProductDetails] = useState<ProductCatalogueSkuBody>();
  const modalRef = createRef<HTMLButtonElement>();
  const [currentQuantity, setCurrentQuantity] = useState<string>();

  const products = useMemo(() => {
    return mapProductsToDropdownObject(productsData);
  }, [productsData]);

  const selectedProduct = useMemo(() => {
    return productsData.find(product => product.id === values.product?.value);
  }, [productsData, values.product]);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchSkuState, fetchSku] = useAPI(fetchSkuAPI, true);

  useEffect(() => {
    const fn = async () => {
      if (selectedProduct?.external_product_id) {
        const payload = { skuId: selectedProduct.external_product_id };
        const productDetails = await fetchSku(payload);

        if (productDetails && Array.isArray(productDetails)) {
          setProductDetails(productDetails[0]);
        }
      }
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProduct]);

  return (
    <SecondaryLineWrapper type={"withIndex"} key={index}>
      <IndexPill index={index + 1} />
      <DeletePill onClick={() => setDeleteLineModalOpen(true)} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={`product_${index}`}
            error={errors?.product}
            touched={touched?.product}
            label={"Product SKU"}
            tooltipMessage={"Type a few characters to search for a product SKU"}
            name={`product_${index}`}
            value={values.product}
            options={products}
            changeHandler={e => {
              setFieldValue(`product_lines[${index}].product`, e);
            }}
            placeholder="Select Product"
            onInputChange={input => {
              if (input && input.length >= 2) {
                setMenuIsOpen(true);
              } else {
                setMenuIsOpen(false);
              }
            }}
            menuIsOpen={menuIsOpen}
          />
          {productDetails && (
            <div style={{ marginTop: "10px" }}>
              <SecondaryButton onClick={() => setDisplayProductDetails(!displayProductDetails)}>
                {displayProductDetails ? "Hide" : "View"} Details
              </SecondaryButton>
            </div>
          )}
        </DataPoint>
        <DataPoint>
          <div style={{ marginBottom: "10px" }}>
            {/* TODO: Add ref to input elements so we can have a listener for enter button */}
            <SimpleInput
              isRequired
              tooltipMessage={
                "It's possible to add more than one quantity, this allows the supplier to quote for multiple quantities"
              }
              min={0}
              htmlFor={`quantity_${index}`}
              placeholder="Quantity"
              error={Array.isArray(errors?.quantity) ? errors?.quantity[index] : errors?.quantity}
              touched={touched?.quantity}
              label={"Quantity"}
              name={`quantity_${index}`}
              size={"medium"}
              type={"number"}
              value={currentQuantity}
              changeHandler={e => setCurrentQuantity(e.target.value)}
            />
          </div>
          <SecondaryButton
            type="button"
            onClick={() => {
              if (currentQuantity && values.quantity.includes(currentQuantity)) {
                toast.warn("Quantity already added");
              }

              if (!!currentQuantity && !values.quantity.includes(currentQuantity)) {
                setFieldValue(
                  `product_lines[${index}].quantity[${values.quantity.length}]`,
                  currentQuantity
                );
                setCurrentQuantity("");
              }
            }}
            appearance={"whiteButtonBlueText"}
          >
            Add Quantity
          </SecondaryButton>

          <QuantityTags
            quantityValues={values.quantity}
            index={index}
            setFieldValue={setFieldValue}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            htmlFor={`notes_${index}`}
            placeholder="Notes"
            error={errors?.notes}
            touched={touched?.notes}
            label={"Notes"}
            name={`notes_${index}`}
            size={"medium"}
            type={"text"}
            value={values.notes}
            changeHandler={e => {
              setFieldValue(`product_lines[${index}].notes`, e.target.value);
            }}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>
      {productDetails && displayProductDetails && (
        <SecondaryLineDataWrapper>
          <AttributesSummary
            physicalAttributes={productDetails?.physical_attributes}
            contentAttributes={productDetails?.content_attributes}
          />
        </SecondaryLineDataWrapper>
      )}
      <AttachmentContainer>
        {values.attachments.map((_, index_attachment) => {
          return (
            <AttachmentUploader
              key={index_attachment}
              indexAttachment={index_attachment}
              parameterName={`product_lines[${index}].attachments`}
              setFieldValue={setFieldValue}
              handleRemoveAttachmentLine={(index_attachment: number) => {
                const filteredAttachments = values.attachments.filter(
                  (_, i) => i !== index_attachment
                );
                setFieldValue(`product_lines[${index}].attachments`, filteredAttachments);
              }}
            />
          );
        })}
        <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
          <SecondaryButton
            type="button"
            onClick={() => {
              const attachments: UploadedFile[] = [...values.attachments];
              attachments.push({ ...emptyAttachment });
              setFieldValue(`product_lines[${index}].attachments`, attachments);
            }}
            appearance={"whiteButtonBlueText"}
          >
            <IconText text={"Upload New Attachment"} primaryIcon={"alert-add-outline"} />
          </SecondaryButton>
        </ButtonWrapper>
      </AttachmentContainer>

      <Modal
        id="delete-product-line-modal"
        isOpen={deleteLineModalOpen}
        handleClose={() => setDeleteLineModalOpen(false)}
        triggerRef={modalRef}
      >
        <Heading level={6} css={{ paddingTop: "20px" }}>
          <p>Are you sure you want to delete this product line?</p>
        </Heading>
        <FlexEndContainer>
          <ButtonGroup>
            <SecondaryButton onClick={() => setDeleteLineModalOpen(false)} appearance="whiteButton">
              Cancel
            </SecondaryButton>
            <SecondaryButton onClick={() => handleRemoveProductLine(index)}>Delete</SecondaryButton>
          </ButtonGroup>
        </FlexEndContainer>
      </Modal>
    </SecondaryLineWrapper>
  );
};
