export interface AddressForm {
  organisation_id: {
    value: number;
    label: string;
  } | null;
  address_name: string;
  name: string;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  postcode: string;
  country_name: {
    value: number;
    label: string;
  } | null;
  phone_number: string;
  address_description: string;
}

export const initialFieldValues: AddressForm = {
  organisation_id: null,
  address_name: "",
  name: "",
  address_line_1: "",
  address_line_2: "",
  address_line_3: "",
  city: "",
  postcode: "",
  country_name: null,
  phone_number: "",
  address_description: "",
};

export const fieldNames = {
  organisation_id: "organisation_id",
  address_name: "address_name",
  name: "name",
  address_line_1: "address_line_1",
  address_line_2: "address_line_2",
  address_line_3: "address_line_3",
  city: "city",
  postcode: "postcode",
  country_name: "country_name",
  phone_number: "phone_number",
  address_description: "address_description",
};
