import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};


/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "address" */
export type Address = {
  __typename?: 'address';
  address_description?: Maybe<Scalars['String']>;
  address_line_1: Scalars['String'];
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_name: Scalars['String'];
  city: Scalars['String'];
  /** An array relationship */
  client_orders: Array<Client_Order>;
  /** An aggregate relationship */
  client_orders_aggregate: Client_Order_Aggregate;
  /** An object relationship */
  country: Country;
  country_code: Scalars['String'];
  created_at: Scalars['timestamptz'];
  external_address_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  is_internal: Scalars['Boolean'];
  /** An array relationship */
  logisticsOrdersByToAddressId: Array<Logistics_Order>;
  /** An aggregate relationship */
  logisticsOrdersByToAddressId_aggregate: Logistics_Order_Aggregate;
  /** An array relationship */
  logistics_orders: Array<Logistics_Order>;
  /** An aggregate relationship */
  logistics_orders_aggregate: Logistics_Order_Aggregate;
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
  postcode: Scalars['String'];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An array relationship */
  purchase_orders: Array<Purchase_Order>;
  /** An aggregate relationship */
  purchase_orders_aggregate: Purchase_Order_Aggregate;
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  /** An array relationship */
  shipmentUpdatesByFromDestinationId: Array<Shipment_Update>;
  /** An aggregate relationship */
  shipmentUpdatesByFromDestinationId_aggregate: Shipment_Update_Aggregate;
  /** An array relationship */
  shipmentUpdatesByToDestinationId: Array<Shipment_Update>;
  /** An aggregate relationship */
  shipmentUpdatesByToDestinationId_aggregate: Shipment_Update_Aggregate;
  /** An array relationship */
  shipment_updates: Array<Shipment_Update>;
  /** An aggregate relationship */
  shipment_updates_aggregate: Shipment_Update_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  warehouse?: Maybe<Warehouse>;
  warehouse_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  wms_stock_change_outputs: Array<Wms_Stock_Change_Output>;
  /** An aggregate relationship */
  wms_stock_change_outputs_aggregate: Wms_Stock_Change_Output_Aggregate;
};


/** columns and relationships of "address" */
export type AddressClient_OrdersArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressClient_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressLogisticsOrdersByToAddressIdArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressLogisticsOrdersByToAddressId_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressLogistics_OrdersArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressLogistics_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressPurchase_OrdersArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressPurchase_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressShipmentUpdatesByFromDestinationIdArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressShipmentUpdatesByFromDestinationId_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressShipmentUpdatesByToDestinationIdArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressShipmentUpdatesByToDestinationId_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressShipment_UpdatesArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressShipment_Updates_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressWms_Stock_Change_OutputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


/** columns and relationships of "address" */
export type AddressWms_Stock_Change_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** aggregated selection of "address" */
export type Address_Aggregate = {
  __typename?: 'address_aggregate';
  aggregate?: Maybe<Address_Aggregate_Fields>;
  nodes: Array<Address>;
};

export type Address_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Address_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Address_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Address_Aggregate_Bool_Exp_Count>;
};

export type Address_Aggregate_Bool_Exp_Bool_And = {
  arguments: Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Address_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Address_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Address_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Address_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Address_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "address" */
export type Address_Aggregate_Fields = {
  __typename?: 'address_aggregate_fields';
  avg?: Maybe<Address_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Address_Max_Fields>;
  min?: Maybe<Address_Min_Fields>;
  stddev?: Maybe<Address_Stddev_Fields>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Fields>;
  sum?: Maybe<Address_Sum_Fields>;
  var_pop?: Maybe<Address_Var_Pop_Fields>;
  var_samp?: Maybe<Address_Var_Samp_Fields>;
  variance?: Maybe<Address_Variance_Fields>;
};


/** aggregate fields of "address" */
export type Address_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Address_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "address" */
export type Address_Aggregate_Order_By = {
  avg?: Maybe<Address_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Address_Max_Order_By>;
  min?: Maybe<Address_Min_Order_By>;
  stddev?: Maybe<Address_Stddev_Order_By>;
  stddev_pop?: Maybe<Address_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Address_Stddev_Samp_Order_By>;
  sum?: Maybe<Address_Sum_Order_By>;
  var_pop?: Maybe<Address_Var_Pop_Order_By>;
  var_samp?: Maybe<Address_Var_Samp_Order_By>;
  variance?: Maybe<Address_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "address" */
export type Address_Arr_Rel_Insert_Input = {
  data: Array<Address_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** aggregate avg on columns */
export type Address_Avg_Fields = {
  __typename?: 'address_avg_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "address" */
export type Address_Avg_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "address". All fields are combined with a logical 'AND'. */
export type Address_Bool_Exp = {
  _and?: Maybe<Array<Address_Bool_Exp>>;
  _not?: Maybe<Address_Bool_Exp>;
  _or?: Maybe<Array<Address_Bool_Exp>>;
  address_description?: Maybe<String_Comparison_Exp>;
  address_line_1?: Maybe<String_Comparison_Exp>;
  address_line_2?: Maybe<String_Comparison_Exp>;
  address_line_3?: Maybe<String_Comparison_Exp>;
  address_name?: Maybe<String_Comparison_Exp>;
  city?: Maybe<String_Comparison_Exp>;
  client_orders?: Maybe<Client_Order_Bool_Exp>;
  client_orders_aggregate?: Maybe<Client_Order_Aggregate_Bool_Exp>;
  country?: Maybe<Country_Bool_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_address_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_internal?: Maybe<Boolean_Comparison_Exp>;
  logisticsOrdersByToAddressId?: Maybe<Logistics_Order_Bool_Exp>;
  logisticsOrdersByToAddressId_aggregate?: Maybe<Logistics_Order_Aggregate_Bool_Exp>;
  logistics_orders?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Bool_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
  postcode?: Maybe<String_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  purchase_orders?: Maybe<Purchase_Order_Bool_Exp>;
  purchase_orders_aggregate?: Maybe<Purchase_Order_Aggregate_Bool_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  shipmentUpdatesByFromDestinationId?: Maybe<Shipment_Update_Bool_Exp>;
  shipmentUpdatesByFromDestinationId_aggregate?: Maybe<Shipment_Update_Aggregate_Bool_Exp>;
  shipmentUpdatesByToDestinationId?: Maybe<Shipment_Update_Bool_Exp>;
  shipmentUpdatesByToDestinationId_aggregate?: Maybe<Shipment_Update_Aggregate_Bool_Exp>;
  shipment_updates?: Maybe<Shipment_Update_Bool_Exp>;
  shipment_updates_aggregate?: Maybe<Shipment_Update_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "address" */
export enum Address_Constraint {
  /** unique or primary key constraint on columns "id" */
  AddressPkey = 'address_pkey'
}

/** input type for incrementing numeric columns in table "address" */
export type Address_Inc_Input = {
  external_address_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "address" */
export type Address_Insert_Input = {
  address_description?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  client_orders?: Maybe<Client_Order_Arr_Rel_Insert_Input>;
  country?: Maybe<Country_Obj_Rel_Insert_Input>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_address_id?: Maybe<Scalars['Int']>;
  is_internal?: Maybe<Scalars['Boolean']>;
  logisticsOrdersByToAddressId?: Maybe<Logistics_Order_Arr_Rel_Insert_Input>;
  logistics_orders?: Maybe<Logistics_Order_Arr_Rel_Insert_Input>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
  postcode?: Maybe<Scalars['String']>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  purchase_orders?: Maybe<Purchase_Order_Arr_Rel_Insert_Input>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  shipmentUpdatesByFromDestinationId?: Maybe<Shipment_Update_Arr_Rel_Insert_Input>;
  shipmentUpdatesByToDestinationId?: Maybe<Shipment_Update_Arr_Rel_Insert_Input>;
  shipment_updates?: Maybe<Shipment_Update_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars['Int']>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Address_Max_Fields = {
  __typename?: 'address_max_fields';
  address_description?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "address" */
export type Address_Max_Order_By = {
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  address_name?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Address_Min_Fields = {
  __typename?: 'address_min_fields';
  address_description?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  postcode?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "address" */
export type Address_Min_Order_By = {
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  address_name?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  postcode?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "address" */
export type Address_Mutation_Response = {
  __typename?: 'address_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Address>;
};

/** input type for inserting object relation for remote table "address" */
export type Address_Obj_Rel_Insert_Input = {
  data: Address_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Address_On_Conflict>;
};

/** on_conflict condition type for table "address" */
export type Address_On_Conflict = {
  constraint: Address_Constraint;
  update_columns?: Array<Address_Update_Column>;
  where?: Maybe<Address_Bool_Exp>;
};

/** Ordering options when selecting data from "address". */
export type Address_Order_By = {
  address_description?: Maybe<Order_By>;
  address_line_1?: Maybe<Order_By>;
  address_line_2?: Maybe<Order_By>;
  address_line_3?: Maybe<Order_By>;
  address_name?: Maybe<Order_By>;
  city?: Maybe<Order_By>;
  client_orders_aggregate?: Maybe<Client_Order_Aggregate_Order_By>;
  country?: Maybe<Country_Order_By>;
  country_code?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_internal?: Maybe<Order_By>;
  logisticsOrdersByToAddressId_aggregate?: Maybe<Logistics_Order_Aggregate_Order_By>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
  postcode?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  purchase_orders_aggregate?: Maybe<Purchase_Order_Aggregate_Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  shipmentUpdatesByFromDestinationId_aggregate?: Maybe<Shipment_Update_Aggregate_Order_By>;
  shipmentUpdatesByToDestinationId_aggregate?: Maybe<Shipment_Update_Aggregate_Order_By>;
  shipment_updates_aggregate?: Maybe<Shipment_Update_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Order_By>;
};

/** primary key columns input for table: address */
export type Address_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "address" */
export enum Address_Select_Column {
  /** column name */
  AddressDescription = 'address_description',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AddressLine_3 = 'address_line_3',
  /** column name */
  AddressName = 'address_name',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalAddressId = 'external_address_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseId = 'warehouse_id'
}

/** select "address_aggregate_bool_exp_bool_and_arguments_columns" columns of table "address" */
export enum Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsInternal = 'is_internal'
}

/** select "address_aggregate_bool_exp_bool_or_arguments_columns" columns of table "address" */
export enum Address_Select_Column_Address_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsInternal = 'is_internal'
}

/** input type for updating data in table "address" */
export type Address_Set_Input = {
  address_description?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_address_id?: Maybe<Scalars['Int']>;
  is_internal?: Maybe<Scalars['Boolean']>;
  postcode?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Address_Stddev_Fields = {
  __typename?: 'address_stddev_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "address" */
export type Address_Stddev_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Address_Stddev_Pop_Fields = {
  __typename?: 'address_stddev_pop_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "address" */
export type Address_Stddev_Pop_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Address_Stddev_Samp_Fields = {
  __typename?: 'address_stddev_samp_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "address" */
export type Address_Stddev_Samp_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "address" */
export type Address_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Address_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Address_Stream_Cursor_Value_Input = {
  address_description?: Maybe<Scalars['String']>;
  address_line_1?: Maybe<Scalars['String']>;
  address_line_2?: Maybe<Scalars['String']>;
  address_line_3?: Maybe<Scalars['String']>;
  address_name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_internal?: Maybe<Scalars['Boolean']>;
  postcode?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Address_Sum_Fields = {
  __typename?: 'address_sum_fields';
  external_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "address" */
export type Address_Sum_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "address" */
export enum Address_Update_Column {
  /** column name */
  AddressDescription = 'address_description',
  /** column name */
  AddressLine_1 = 'address_line_1',
  /** column name */
  AddressLine_2 = 'address_line_2',
  /** column name */
  AddressLine_3 = 'address_line_3',
  /** column name */
  AddressName = 'address_name',
  /** column name */
  City = 'city',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalAddressId = 'external_address_id',
  /** column name */
  IsInternal = 'is_internal',
  /** column name */
  Postcode = 'postcode',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseId = 'warehouse_id'
}

export type Address_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Address_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Address_Set_Input>;
  /** filter the rows which have to be updated */
  where: Address_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Address_Var_Pop_Fields = {
  __typename?: 'address_var_pop_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "address" */
export type Address_Var_Pop_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Address_Var_Samp_Fields = {
  __typename?: 'address_var_samp_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "address" */
export type Address_Var_Samp_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Address_Variance_Fields = {
  __typename?: 'address_variance_fields';
  external_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "address" */
export type Address_Variance_Order_By = {
  external_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "attachment" */
export type Attachment = {
  __typename?: 'attachment';
  attachment_uuid: Scalars['String'];
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  folder: Folder;
  folder_id: Scalars['Int'];
  id: Scalars['Int'];
  metadata: Scalars['jsonb'];
  notes?: Maybe<Scalars['String']>;
  original_filename: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  user_id: Scalars['String'];
};


/** columns and relationships of "attachment" */
export type AttachmentMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "attachment" */
export type Attachment_Aggregate = {
  __typename?: 'attachment_aggregate';
  aggregate?: Maybe<Attachment_Aggregate_Fields>;
  nodes: Array<Attachment>;
};

export type Attachment_Aggregate_Bool_Exp = {
  count?: Maybe<Attachment_Aggregate_Bool_Exp_Count>;
};

export type Attachment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Attachment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Attachment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "attachment" */
export type Attachment_Aggregate_Fields = {
  __typename?: 'attachment_aggregate_fields';
  avg?: Maybe<Attachment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Attachment_Max_Fields>;
  min?: Maybe<Attachment_Min_Fields>;
  stddev?: Maybe<Attachment_Stddev_Fields>;
  stddev_pop?: Maybe<Attachment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Attachment_Stddev_Samp_Fields>;
  sum?: Maybe<Attachment_Sum_Fields>;
  var_pop?: Maybe<Attachment_Var_Pop_Fields>;
  var_samp?: Maybe<Attachment_Var_Samp_Fields>;
  variance?: Maybe<Attachment_Variance_Fields>;
};


/** aggregate fields of "attachment" */
export type Attachment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Attachment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "attachment" */
export type Attachment_Aggregate_Order_By = {
  avg?: Maybe<Attachment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Attachment_Max_Order_By>;
  min?: Maybe<Attachment_Min_Order_By>;
  stddev?: Maybe<Attachment_Stddev_Order_By>;
  stddev_pop?: Maybe<Attachment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Attachment_Stddev_Samp_Order_By>;
  sum?: Maybe<Attachment_Sum_Order_By>;
  var_pop?: Maybe<Attachment_Var_Pop_Order_By>;
  var_samp?: Maybe<Attachment_Var_Samp_Order_By>;
  variance?: Maybe<Attachment_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Attachment_Append_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** input type for inserting array relation for remote table "attachment" */
export type Attachment_Arr_Rel_Insert_Input = {
  data: Array<Attachment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Attachment_On_Conflict>;
};

/** aggregate avg on columns */
export type Attachment_Avg_Fields = {
  __typename?: 'attachment_avg_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "attachment" */
export type Attachment_Avg_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "attachment". All fields are combined with a logical 'AND'. */
export type Attachment_Bool_Exp = {
  _and?: Maybe<Array<Attachment_Bool_Exp>>;
  _not?: Maybe<Attachment_Bool_Exp>;
  _or?: Maybe<Array<Attachment_Bool_Exp>>;
  attachment_uuid?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  folder?: Maybe<Folder_Bool_Exp>;
  folder_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  metadata?: Maybe<Jsonb_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  original_filename?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  user_id?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "attachment" */
export enum Attachment_Constraint {
  /** unique or primary key constraint on columns "attachment_uuid" */
  AttachmentAttachmentUuidKey = 'attachment_attachment_uuid_key',
  /** unique or primary key constraint on columns "id" */
  AttachmentPkey = 'attachment_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Attachment_Delete_At_Path_Input = {
  metadata?: Maybe<Array<Scalars['String']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Attachment_Delete_Elem_Input = {
  metadata?: Maybe<Scalars['Int']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Attachment_Delete_Key_Input = {
  metadata?: Maybe<Scalars['String']>;
};

/** input type for incrementing numeric columns in table "attachment" */
export type Attachment_Inc_Input = {
  folder_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "attachment" */
export type Attachment_Insert_Input = {
  attachment_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  folder?: Maybe<Folder_Obj_Rel_Insert_Input>;
  folder_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  notes?: Maybe<Scalars['String']>;
  original_filename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Attachment_Max_Fields = {
  __typename?: 'attachment_max_fields';
  attachment_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  original_filename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "attachment" */
export type Attachment_Max_Order_By = {
  attachment_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  original_filename?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Attachment_Min_Fields = {
  __typename?: 'attachment_min_fields';
  attachment_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  original_filename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "attachment" */
export type Attachment_Min_Order_By = {
  attachment_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  original_filename?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "attachment" */
export type Attachment_Mutation_Response = {
  __typename?: 'attachment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Attachment>;
};

/** on_conflict condition type for table "attachment" */
export type Attachment_On_Conflict = {
  constraint: Attachment_Constraint;
  update_columns?: Array<Attachment_Update_Column>;
  where?: Maybe<Attachment_Bool_Exp>;
};

/** Ordering options when selecting data from "attachment". */
export type Attachment_Order_By = {
  attachment_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  folder?: Maybe<Folder_Order_By>;
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  metadata?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  original_filename?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  user_id?: Maybe<Order_By>;
};

/** primary key columns input for table: attachment */
export type Attachment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Attachment_Prepend_Input = {
  metadata?: Maybe<Scalars['jsonb']>;
};

/** select columns of table "attachment" */
export enum Attachment_Select_Column {
  /** column name */
  AttachmentUuid = 'attachment_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  Id = 'id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Notes = 'notes',
  /** column name */
  OriginalFilename = 'original_filename',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "attachment" */
export type Attachment_Set_Input = {
  attachment_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  notes?: Maybe<Scalars['String']>;
  original_filename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Attachment_Stddev_Fields = {
  __typename?: 'attachment_stddev_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "attachment" */
export type Attachment_Stddev_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Attachment_Stddev_Pop_Fields = {
  __typename?: 'attachment_stddev_pop_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "attachment" */
export type Attachment_Stddev_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Attachment_Stddev_Samp_Fields = {
  __typename?: 'attachment_stddev_samp_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "attachment" */
export type Attachment_Stddev_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "attachment" */
export type Attachment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Attachment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Attachment_Stream_Cursor_Value_Input = {
  attachment_uuid?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  folder_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['jsonb']>;
  notes?: Maybe<Scalars['String']>;
  original_filename?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  user_id?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Attachment_Sum_Fields = {
  __typename?: 'attachment_sum_fields';
  folder_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "attachment" */
export type Attachment_Sum_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** update columns of table "attachment" */
export enum Attachment_Update_Column {
  /** column name */
  AttachmentUuid = 'attachment_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FolderId = 'folder_id',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Notes = 'notes',
  /** column name */
  OriginalFilename = 'original_filename',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Attachment_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Attachment_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Attachment_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Attachment_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Attachment_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Attachment_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Attachment_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Attachment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Attachment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Attachment_Var_Pop_Fields = {
  __typename?: 'attachment_var_pop_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "attachment" */
export type Attachment_Var_Pop_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Attachment_Var_Samp_Fields = {
  __typename?: 'attachment_var_samp_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "attachment" */
export type Attachment_Var_Samp_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Attachment_Variance_Fields = {
  __typename?: 'attachment_variance_fields';
  folder_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "attachment" */
export type Attachment_Variance_Order_By = {
  folder_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
};

/** columns and relationships of "client_order" */
export type Client_Order = {
  __typename?: 'client_order';
  /** An object relationship */
  address: Address;
  created_at: Scalars['timestamptz'];
  external_client_order_id: Scalars['String'];
  final_address_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['Int'];
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  /** An array relationship */
  shipments: Array<Shipment>;
  /** An aggregate relationship */
  shipments_aggregate: Shipment_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "client_order" */
export type Client_OrderShipmentsArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


/** columns and relationships of "client_order" */
export type Client_OrderShipments_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};

/** aggregated selection of "client_order" */
export type Client_Order_Aggregate = {
  __typename?: 'client_order_aggregate';
  aggregate?: Maybe<Client_Order_Aggregate_Fields>;
  nodes: Array<Client_Order>;
};

export type Client_Order_Aggregate_Bool_Exp = {
  count?: Maybe<Client_Order_Aggregate_Bool_Exp_Count>;
};

export type Client_Order_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Client_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Client_Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "client_order" */
export type Client_Order_Aggregate_Fields = {
  __typename?: 'client_order_aggregate_fields';
  avg?: Maybe<Client_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Client_Order_Max_Fields>;
  min?: Maybe<Client_Order_Min_Fields>;
  stddev?: Maybe<Client_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Client_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Client_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Client_Order_Sum_Fields>;
  var_pop?: Maybe<Client_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Client_Order_Var_Samp_Fields>;
  variance?: Maybe<Client_Order_Variance_Fields>;
};


/** aggregate fields of "client_order" */
export type Client_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Client_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "client_order" */
export type Client_Order_Aggregate_Order_By = {
  avg?: Maybe<Client_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Client_Order_Max_Order_By>;
  min?: Maybe<Client_Order_Min_Order_By>;
  stddev?: Maybe<Client_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Client_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Client_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Client_Order_Sum_Order_By>;
  var_pop?: Maybe<Client_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Client_Order_Var_Samp_Order_By>;
  variance?: Maybe<Client_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "client_order" */
export type Client_Order_Arr_Rel_Insert_Input = {
  data: Array<Client_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Client_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Client_Order_Avg_Fields = {
  __typename?: 'client_order_avg_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "client_order" */
export type Client_Order_Avg_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "client_order". All fields are combined with a logical 'AND'. */
export type Client_Order_Bool_Exp = {
  _and?: Maybe<Array<Client_Order_Bool_Exp>>;
  _not?: Maybe<Client_Order_Bool_Exp>;
  _or?: Maybe<Array<Client_Order_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_client_order_id?: Maybe<String_Comparison_Exp>;
  final_address_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  shipments?: Maybe<Shipment_Bool_Exp>;
  shipments_aggregate?: Maybe<Shipment_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "client_order" */
export enum Client_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  ClientOrderPkey = 'client_order_pkey'
}

/** input type for incrementing numeric columns in table "client_order" */
export type Client_Order_Inc_Input = {
  final_address_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "client_order" */
export type Client_Order_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_client_order_id?: Maybe<Scalars['String']>;
  final_address_id?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  shipments?: Maybe<Shipment_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Client_Order_Max_Fields = {
  __typename?: 'client_order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_client_order_id?: Maybe<Scalars['String']>;
  final_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "client_order" */
export type Client_Order_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_client_order_id?: Maybe<Order_By>;
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Client_Order_Min_Fields = {
  __typename?: 'client_order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_client_order_id?: Maybe<Scalars['String']>;
  final_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "client_order" */
export type Client_Order_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_client_order_id?: Maybe<Order_By>;
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "client_order" */
export type Client_Order_Mutation_Response = {
  __typename?: 'client_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Client_Order>;
};

/** input type for inserting object relation for remote table "client_order" */
export type Client_Order_Obj_Rel_Insert_Input = {
  data: Client_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Client_Order_On_Conflict>;
};

/** on_conflict condition type for table "client_order" */
export type Client_Order_On_Conflict = {
  constraint: Client_Order_Constraint;
  update_columns?: Array<Client_Order_Update_Column>;
  where?: Maybe<Client_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "client_order". */
export type Client_Order_Order_By = {
  address?: Maybe<Address_Order_By>;
  created_at?: Maybe<Order_By>;
  external_client_order_id?: Maybe<Order_By>;
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  shipments_aggregate?: Maybe<Shipment_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: client_order */
export type Client_Order_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "client_order" */
export enum Client_Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalClientOrderId = 'external_client_order_id',
  /** column name */
  FinalAddressId = 'final_address_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "client_order" */
export type Client_Order_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_client_order_id?: Maybe<Scalars['String']>;
  final_address_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Client_Order_Stddev_Fields = {
  __typename?: 'client_order_stddev_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "client_order" */
export type Client_Order_Stddev_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Client_Order_Stddev_Pop_Fields = {
  __typename?: 'client_order_stddev_pop_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "client_order" */
export type Client_Order_Stddev_Pop_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Client_Order_Stddev_Samp_Fields = {
  __typename?: 'client_order_stddev_samp_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "client_order" */
export type Client_Order_Stddev_Samp_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "client_order" */
export type Client_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Client_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Client_Order_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_client_order_id?: Maybe<Scalars['String']>;
  final_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Client_Order_Sum_Fields = {
  __typename?: 'client_order_sum_fields';
  final_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "client_order" */
export type Client_Order_Sum_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** update columns of table "client_order" */
export enum Client_Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalClientOrderId = 'external_client_order_id',
  /** column name */
  FinalAddressId = 'final_address_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Client_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Client_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Client_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Client_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Client_Order_Var_Pop_Fields = {
  __typename?: 'client_order_var_pop_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "client_order" */
export type Client_Order_Var_Pop_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Client_Order_Var_Samp_Fields = {
  __typename?: 'client_order_var_samp_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "client_order" */
export type Client_Order_Var_Samp_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Client_Order_Variance_Fields = {
  __typename?: 'client_order_variance_fields';
  final_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "client_order" */
export type Client_Order_Variance_Order_By = {
  final_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "country" */
export type Country = {
  __typename?: 'country';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  continent: Scalars['String'];
  country_code: Scalars['String'];
  country_name: Scalars['String'];
  created_at: Scalars['timestamptz'];
  internet_tld: Scalars['String'];
  official_name: Scalars['String'];
  region: Scalars['String'];
  sovereignty: Scalars['String'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "country" */
export type CountryAddressesArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "country" */
export type CountryAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};

/** aggregated selection of "country" */
export type Country_Aggregate = {
  __typename?: 'country_aggregate';
  aggregate?: Maybe<Country_Aggregate_Fields>;
  nodes: Array<Country>;
};

/** aggregate fields of "country" */
export type Country_Aggregate_Fields = {
  __typename?: 'country_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<Country_Max_Fields>;
  min?: Maybe<Country_Min_Fields>;
};


/** aggregate fields of "country" */
export type Country_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Country_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "country". All fields are combined with a logical 'AND'. */
export type Country_Bool_Exp = {
  _and?: Maybe<Array<Country_Bool_Exp>>;
  _not?: Maybe<Country_Bool_Exp>;
  _or?: Maybe<Array<Country_Bool_Exp>>;
  addresses?: Maybe<Address_Bool_Exp>;
  addresses_aggregate?: Maybe<Address_Aggregate_Bool_Exp>;
  continent?: Maybe<String_Comparison_Exp>;
  country_code?: Maybe<String_Comparison_Exp>;
  country_name?: Maybe<String_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  internet_tld?: Maybe<String_Comparison_Exp>;
  official_name?: Maybe<String_Comparison_Exp>;
  region?: Maybe<String_Comparison_Exp>;
  sovereignty?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "country" */
export enum Country_Constraint {
  /** unique or primary key constraint on columns "country_code" */
  CountryPkey = 'country_pkey'
}

/** input type for inserting data into table "country" */
export type Country_Insert_Input = {
  addresses?: Maybe<Address_Arr_Rel_Insert_Input>;
  continent?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  internet_tld?: Maybe<Scalars['String']>;
  official_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sovereignty?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Country_Max_Fields = {
  __typename?: 'country_max_fields';
  continent?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  internet_tld?: Maybe<Scalars['String']>;
  official_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sovereignty?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Country_Min_Fields = {
  __typename?: 'country_min_fields';
  continent?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  internet_tld?: Maybe<Scalars['String']>;
  official_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sovereignty?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "country" */
export type Country_Mutation_Response = {
  __typename?: 'country_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Country>;
};

/** input type for inserting object relation for remote table "country" */
export type Country_Obj_Rel_Insert_Input = {
  data: Country_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Country_On_Conflict>;
};

/** on_conflict condition type for table "country" */
export type Country_On_Conflict = {
  constraint: Country_Constraint;
  update_columns?: Array<Country_Update_Column>;
  where?: Maybe<Country_Bool_Exp>;
};

/** Ordering options when selecting data from "country". */
export type Country_Order_By = {
  addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  continent?: Maybe<Order_By>;
  country_code?: Maybe<Order_By>;
  country_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  internet_tld?: Maybe<Order_By>;
  official_name?: Maybe<Order_By>;
  region?: Maybe<Order_By>;
  sovereignty?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: country */
export type Country_Pk_Columns_Input = {
  country_code: Scalars['String'];
};

/** select columns of table "country" */
export enum Country_Select_Column {
  /** column name */
  Continent = 'continent',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InternetTld = 'internet_tld',
  /** column name */
  OfficialName = 'official_name',
  /** column name */
  Region = 'region',
  /** column name */
  Sovereignty = 'sovereignty',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "country" */
export type Country_Set_Input = {
  continent?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  internet_tld?: Maybe<Scalars['String']>;
  official_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sovereignty?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** Streaming cursor of the table "country" */
export type Country_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Country_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Country_Stream_Cursor_Value_Input = {
  continent?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  country_name?: Maybe<Scalars['String']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  internet_tld?: Maybe<Scalars['String']>;
  official_name?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  sovereignty?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** update columns of table "country" */
export enum Country_Update_Column {
  /** column name */
  Continent = 'continent',
  /** column name */
  CountryCode = 'country_code',
  /** column name */
  CountryName = 'country_name',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InternetTld = 'internet_tld',
  /** column name */
  OfficialName = 'official_name',
  /** column name */
  Region = 'region',
  /** column name */
  Sovereignty = 'sovereignty',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Country_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Country_Set_Input>;
  /** filter the rows which have to be updated */
  where: Country_Bool_Exp;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "databasechangelog" */
export type Databasechangelog = {
  __typename?: 'databasechangelog';
  author: Scalars['String'];
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted: Scalars['timestamp'];
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype: Scalars['String'];
  filename: Scalars['String'];
  id: Scalars['String'];
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted: Scalars['Int'];
  tag?: Maybe<Scalars['String']>;
};

/** aggregated selection of "databasechangelog" */
export type Databasechangelog_Aggregate = {
  __typename?: 'databasechangelog_aggregate';
  aggregate?: Maybe<Databasechangelog_Aggregate_Fields>;
  nodes: Array<Databasechangelog>;
};

/** aggregate fields of "databasechangelog" */
export type Databasechangelog_Aggregate_Fields = {
  __typename?: 'databasechangelog_aggregate_fields';
  avg?: Maybe<Databasechangelog_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Databasechangelog_Max_Fields>;
  min?: Maybe<Databasechangelog_Min_Fields>;
  stddev?: Maybe<Databasechangelog_Stddev_Fields>;
  stddev_pop?: Maybe<Databasechangelog_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Databasechangelog_Stddev_Samp_Fields>;
  sum?: Maybe<Databasechangelog_Sum_Fields>;
  var_pop?: Maybe<Databasechangelog_Var_Pop_Fields>;
  var_samp?: Maybe<Databasechangelog_Var_Samp_Fields>;
  variance?: Maybe<Databasechangelog_Variance_Fields>;
};


/** aggregate fields of "databasechangelog" */
export type Databasechangelog_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Databasechangelog_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Databasechangelog_Avg_Fields = {
  __typename?: 'databasechangelog_avg_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "databasechangelog". All fields are combined with a logical 'AND'. */
export type Databasechangelog_Bool_Exp = {
  _and?: Maybe<Array<Databasechangelog_Bool_Exp>>;
  _not?: Maybe<Databasechangelog_Bool_Exp>;
  _or?: Maybe<Array<Databasechangelog_Bool_Exp>>;
  author?: Maybe<String_Comparison_Exp>;
  comments?: Maybe<String_Comparison_Exp>;
  contexts?: Maybe<String_Comparison_Exp>;
  dateexecuted?: Maybe<Timestamp_Comparison_Exp>;
  deployment_id?: Maybe<String_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  exectype?: Maybe<String_Comparison_Exp>;
  filename?: Maybe<String_Comparison_Exp>;
  id?: Maybe<String_Comparison_Exp>;
  labels?: Maybe<String_Comparison_Exp>;
  liquibase?: Maybe<String_Comparison_Exp>;
  md5sum?: Maybe<String_Comparison_Exp>;
  orderexecuted?: Maybe<Int_Comparison_Exp>;
  tag?: Maybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "databasechangelog" */
export type Databasechangelog_Inc_Input = {
  orderexecuted?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "databasechangelog" */
export type Databasechangelog_Insert_Input = {
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Databasechangelog_Max_Fields = {
  __typename?: 'databasechangelog_max_fields';
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Databasechangelog_Min_Fields = {
  __typename?: 'databasechangelog_min_fields';
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "databasechangelog" */
export type Databasechangelog_Mutation_Response = {
  __typename?: 'databasechangelog_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Databasechangelog>;
};

/** Ordering options when selecting data from "databasechangelog". */
export type Databasechangelog_Order_By = {
  author?: Maybe<Order_By>;
  comments?: Maybe<Order_By>;
  contexts?: Maybe<Order_By>;
  dateexecuted?: Maybe<Order_By>;
  deployment_id?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  exectype?: Maybe<Order_By>;
  filename?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  labels?: Maybe<Order_By>;
  liquibase?: Maybe<Order_By>;
  md5sum?: Maybe<Order_By>;
  orderexecuted?: Maybe<Order_By>;
  tag?: Maybe<Order_By>;
};

/** select columns of table "databasechangelog" */
export enum Databasechangelog_Select_Column {
  /** column name */
  Author = 'author',
  /** column name */
  Comments = 'comments',
  /** column name */
  Contexts = 'contexts',
  /** column name */
  Dateexecuted = 'dateexecuted',
  /** column name */
  DeploymentId = 'deployment_id',
  /** column name */
  Description = 'description',
  /** column name */
  Exectype = 'exectype',
  /** column name */
  Filename = 'filename',
  /** column name */
  Id = 'id',
  /** column name */
  Labels = 'labels',
  /** column name */
  Liquibase = 'liquibase',
  /** column name */
  Md5sum = 'md5sum',
  /** column name */
  Orderexecuted = 'orderexecuted',
  /** column name */
  Tag = 'tag'
}

/** input type for updating data in table "databasechangelog" */
export type Databasechangelog_Set_Input = {
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Databasechangelog_Stddev_Fields = {
  __typename?: 'databasechangelog_stddev_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Databasechangelog_Stddev_Pop_Fields = {
  __typename?: 'databasechangelog_stddev_pop_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Databasechangelog_Stddev_Samp_Fields = {
  __typename?: 'databasechangelog_stddev_samp_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "databasechangelog" */
export type Databasechangelog_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Databasechangelog_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Databasechangelog_Stream_Cursor_Value_Input = {
  author?: Maybe<Scalars['String']>;
  comments?: Maybe<Scalars['String']>;
  contexts?: Maybe<Scalars['String']>;
  dateexecuted?: Maybe<Scalars['timestamp']>;
  deployment_id?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  exectype?: Maybe<Scalars['String']>;
  filename?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  labels?: Maybe<Scalars['String']>;
  liquibase?: Maybe<Scalars['String']>;
  md5sum?: Maybe<Scalars['String']>;
  orderexecuted?: Maybe<Scalars['Int']>;
  tag?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Databasechangelog_Sum_Fields = {
  __typename?: 'databasechangelog_sum_fields';
  orderexecuted?: Maybe<Scalars['Int']>;
};

export type Databasechangelog_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Databasechangelog_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Databasechangelog_Set_Input>;
  /** filter the rows which have to be updated */
  where: Databasechangelog_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Databasechangelog_Var_Pop_Fields = {
  __typename?: 'databasechangelog_var_pop_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Databasechangelog_Var_Samp_Fields = {
  __typename?: 'databasechangelog_var_samp_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Databasechangelog_Variance_Fields = {
  __typename?: 'databasechangelog_variance_fields';
  orderexecuted?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "databasechangeloglock" */
export type Databasechangeloglock = {
  __typename?: 'databasechangeloglock';
  id: Scalars['Int'];
  locked: Scalars['Boolean'];
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregated selection of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate = {
  __typename?: 'databasechangeloglock_aggregate';
  aggregate?: Maybe<Databasechangeloglock_Aggregate_Fields>;
  nodes: Array<Databasechangeloglock>;
};

/** aggregate fields of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate_Fields = {
  __typename?: 'databasechangeloglock_aggregate_fields';
  avg?: Maybe<Databasechangeloglock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Databasechangeloglock_Max_Fields>;
  min?: Maybe<Databasechangeloglock_Min_Fields>;
  stddev?: Maybe<Databasechangeloglock_Stddev_Fields>;
  stddev_pop?: Maybe<Databasechangeloglock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Databasechangeloglock_Stddev_Samp_Fields>;
  sum?: Maybe<Databasechangeloglock_Sum_Fields>;
  var_pop?: Maybe<Databasechangeloglock_Var_Pop_Fields>;
  var_samp?: Maybe<Databasechangeloglock_Var_Samp_Fields>;
  variance?: Maybe<Databasechangeloglock_Variance_Fields>;
};


/** aggregate fields of "databasechangeloglock" */
export type Databasechangeloglock_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Databasechangeloglock_Avg_Fields = {
  __typename?: 'databasechangeloglock_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "databasechangeloglock". All fields are combined with a logical 'AND'. */
export type Databasechangeloglock_Bool_Exp = {
  _and?: Maybe<Array<Databasechangeloglock_Bool_Exp>>;
  _not?: Maybe<Databasechangeloglock_Bool_Exp>;
  _or?: Maybe<Array<Databasechangeloglock_Bool_Exp>>;
  id?: Maybe<Int_Comparison_Exp>;
  locked?: Maybe<Boolean_Comparison_Exp>;
  lockedby?: Maybe<String_Comparison_Exp>;
  lockgranted?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "databasechangeloglock" */
export enum Databasechangeloglock_Constraint {
  /** unique or primary key constraint on columns "id" */
  DatabasechangeloglockPkey = 'databasechangeloglock_pkey'
}

/** input type for incrementing numeric columns in table "databasechangeloglock" */
export type Databasechangeloglock_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "databasechangeloglock" */
export type Databasechangeloglock_Insert_Input = {
  id?: Maybe<Scalars['Int']>;
  locked?: Maybe<Scalars['Boolean']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregate max on columns */
export type Databasechangeloglock_Max_Fields = {
  __typename?: 'databasechangeloglock_max_fields';
  id?: Maybe<Scalars['Int']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type Databasechangeloglock_Min_Fields = {
  __typename?: 'databasechangeloglock_min_fields';
  id?: Maybe<Scalars['Int']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** response of any mutation on the table "databasechangeloglock" */
export type Databasechangeloglock_Mutation_Response = {
  __typename?: 'databasechangeloglock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Databasechangeloglock>;
};

/** on_conflict condition type for table "databasechangeloglock" */
export type Databasechangeloglock_On_Conflict = {
  constraint: Databasechangeloglock_Constraint;
  update_columns?: Array<Databasechangeloglock_Update_Column>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};

/** Ordering options when selecting data from "databasechangeloglock". */
export type Databasechangeloglock_Order_By = {
  id?: Maybe<Order_By>;
  locked?: Maybe<Order_By>;
  lockedby?: Maybe<Order_By>;
  lockgranted?: Maybe<Order_By>;
};

/** primary key columns input for table: databasechangeloglock */
export type Databasechangeloglock_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "databasechangeloglock" */
export enum Databasechangeloglock_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Lockedby = 'lockedby',
  /** column name */
  Lockgranted = 'lockgranted'
}

/** input type for updating data in table "databasechangeloglock" */
export type Databasechangeloglock_Set_Input = {
  id?: Maybe<Scalars['Int']>;
  locked?: Maybe<Scalars['Boolean']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregate stddev on columns */
export type Databasechangeloglock_Stddev_Fields = {
  __typename?: 'databasechangeloglock_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Databasechangeloglock_Stddev_Pop_Fields = {
  __typename?: 'databasechangeloglock_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Databasechangeloglock_Stddev_Samp_Fields = {
  __typename?: 'databasechangeloglock_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "databasechangeloglock" */
export type Databasechangeloglock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Databasechangeloglock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Databasechangeloglock_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['Int']>;
  locked?: Maybe<Scalars['Boolean']>;
  lockedby?: Maybe<Scalars['String']>;
  lockgranted?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type Databasechangeloglock_Sum_Fields = {
  __typename?: 'databasechangeloglock_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "databasechangeloglock" */
export enum Databasechangeloglock_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Locked = 'locked',
  /** column name */
  Lockedby = 'lockedby',
  /** column name */
  Lockgranted = 'lockgranted'
}

export type Databasechangeloglock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Databasechangeloglock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Databasechangeloglock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Databasechangeloglock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Databasechangeloglock_Var_Pop_Fields = {
  __typename?: 'databasechangeloglock_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Databasechangeloglock_Var_Samp_Fields = {
  __typename?: 'databasechangeloglock_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Databasechangeloglock_Variance_Fields = {
  __typename?: 'databasechangeloglock_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "folder" */
export type Folder = {
  __typename?: 'folder';
  /** An array relationship */
  attachments: Array<Attachment>;
  /** An aggregate relationship */
  attachments_aggregate: Attachment_Aggregate;
  created_at: Scalars['timestamptz'];
  folder_name: Scalars['String'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "folder" */
export type FolderAttachmentsArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


/** columns and relationships of "folder" */
export type FolderAttachments_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};

/** aggregated selection of "folder" */
export type Folder_Aggregate = {
  __typename?: 'folder_aggregate';
  aggregate?: Maybe<Folder_Aggregate_Fields>;
  nodes: Array<Folder>;
};

/** aggregate fields of "folder" */
export type Folder_Aggregate_Fields = {
  __typename?: 'folder_aggregate_fields';
  avg?: Maybe<Folder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Folder_Max_Fields>;
  min?: Maybe<Folder_Min_Fields>;
  stddev?: Maybe<Folder_Stddev_Fields>;
  stddev_pop?: Maybe<Folder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Folder_Stddev_Samp_Fields>;
  sum?: Maybe<Folder_Sum_Fields>;
  var_pop?: Maybe<Folder_Var_Pop_Fields>;
  var_samp?: Maybe<Folder_Var_Samp_Fields>;
  variance?: Maybe<Folder_Variance_Fields>;
};


/** aggregate fields of "folder" */
export type Folder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Folder_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Folder_Avg_Fields = {
  __typename?: 'folder_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "folder". All fields are combined with a logical 'AND'. */
export type Folder_Bool_Exp = {
  _and?: Maybe<Array<Folder_Bool_Exp>>;
  _not?: Maybe<Folder_Bool_Exp>;
  _or?: Maybe<Array<Folder_Bool_Exp>>;
  attachments?: Maybe<Attachment_Bool_Exp>;
  attachments_aggregate?: Maybe<Attachment_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  folder_name?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "folder" */
export enum Folder_Constraint {
  /** unique or primary key constraint on columns "folder_name" */
  FolderFolderNameKey = 'folder_folder_name_key',
  /** unique or primary key constraint on columns "id" */
  FolderPkey = 'folder_pkey'
}

/** input type for inserting data into table "folder" */
export type Folder_Insert_Input = {
  attachments?: Maybe<Attachment_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Folder_Max_Fields = {
  __typename?: 'folder_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Folder_Min_Fields = {
  __typename?: 'folder_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "folder" */
export type Folder_Mutation_Response = {
  __typename?: 'folder_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Folder>;
};

/** input type for inserting object relation for remote table "folder" */
export type Folder_Obj_Rel_Insert_Input = {
  data: Folder_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Folder_On_Conflict>;
};

/** on_conflict condition type for table "folder" */
export type Folder_On_Conflict = {
  constraint: Folder_Constraint;
  update_columns?: Array<Folder_Update_Column>;
  where?: Maybe<Folder_Bool_Exp>;
};

/** Ordering options when selecting data from "folder". */
export type Folder_Order_By = {
  attachments_aggregate?: Maybe<Attachment_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  folder_name?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: folder */
export type Folder_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "folder" */
export enum Folder_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FolderName = 'folder_name',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "folder" */
export type Folder_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Folder_Stddev_Fields = {
  __typename?: 'folder_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Folder_Stddev_Pop_Fields = {
  __typename?: 'folder_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Folder_Stddev_Samp_Fields = {
  __typename?: 'folder_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "folder" */
export type Folder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Folder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Folder_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  folder_name?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Folder_Sum_Fields = {
  __typename?: 'folder_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "folder" */
export enum Folder_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  FolderName = 'folder_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Folder_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Folder_Set_Input>;
  /** filter the rows which have to be updated */
  where: Folder_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Folder_Var_Pop_Fields = {
  __typename?: 'folder_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Folder_Var_Samp_Fields = {
  __typename?: 'folder_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Folder_Variance_Fields = {
  __typename?: 'folder_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "inbound_manifest" */
export type Inbound_Manifest = {
  __typename?: 'inbound_manifest';
  created_at: Scalars['timestamptz'];
  expected_arrival: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  /** An object relationship */
  inbound_manifest_status: Inbound_Manifest_Status;
  inbound_manifest_status_id: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars['Int'];
};


/** columns and relationships of "inbound_manifest" */
export type Inbound_ManifestInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "inbound_manifest" */
export type Inbound_ManifestInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest" */
export type Inbound_Manifest_Aggregate = {
  __typename?: 'inbound_manifest_aggregate';
  aggregate?: Maybe<Inbound_Manifest_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest>;
};

export type Inbound_Manifest_Aggregate_Bool_Exp = {
  count?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp_Count>;
};

export type Inbound_Manifest_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inbound_manifest" */
export type Inbound_Manifest_Aggregate_Fields = {
  __typename?: 'inbound_manifest_aggregate_fields';
  avg?: Maybe<Inbound_Manifest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inbound_Manifest_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Variance_Fields>;
};


/** aggregate fields of "inbound_manifest" */
export type Inbound_Manifest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inbound_manifest" */
export type Inbound_Manifest_Aggregate_Order_By = {
  avg?: Maybe<Inbound_Manifest_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inbound_Manifest_Max_Order_By>;
  min?: Maybe<Inbound_Manifest_Min_Order_By>;
  stddev?: Maybe<Inbound_Manifest_Stddev_Order_By>;
  stddev_pop?: Maybe<Inbound_Manifest_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inbound_Manifest_Stddev_Samp_Order_By>;
  sum?: Maybe<Inbound_Manifest_Sum_Order_By>;
  var_pop?: Maybe<Inbound_Manifest_Var_Pop_Order_By>;
  var_samp?: Maybe<Inbound_Manifest_Var_Samp_Order_By>;
  variance?: Maybe<Inbound_Manifest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inbound_manifest" */
export type Inbound_Manifest_Arr_Rel_Insert_Input = {
  data: Array<Inbound_Manifest_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Avg_Fields = {
  __typename?: 'inbound_manifest_avg_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Avg_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inbound_manifest". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_arrival?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
  inbound_manifest_status_id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest" */
export enum Inbound_Manifest_Constraint {
  /** unique or primary key constraint on columns "id" */
  InboundManifestPkey = 'inbound_manifest_pkey'
}

/** columns and relationships of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line = {
  __typename?: 'inbound_manifest_fulfilment_line';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  inbound_manifest_line: Inbound_Manifest_Line;
  inbound_manifest_line_id: Scalars['Int'];
  is_file_copy: Scalars['Boolean'];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  quantity: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_LineProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_LineProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate = {
  __typename?: 'inbound_manifest_fulfilment_line_aggregate';
  aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Fulfilment_Line>;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count>;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And = {
  arguments: Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_aggregate_fields';
  avg?: Maybe<Inbound_Manifest_Fulfilment_Line_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inbound_Manifest_Fulfilment_Line_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Fulfilment_Line_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Fulfilment_Line_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Fulfilment_Line_Variance_Fields>;
};


/** aggregate fields of "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Aggregate_Order_By = {
  avg?: Maybe<Inbound_Manifest_Fulfilment_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inbound_Manifest_Fulfilment_Line_Max_Order_By>;
  min?: Maybe<Inbound_Manifest_Fulfilment_Line_Min_Order_By>;
  stddev?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inbound_Manifest_Fulfilment_Line_Sum_Order_By>;
  var_pop?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inbound_Manifest_Fulfilment_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inbound_Manifest_Fulfilment_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input = {
  data: Array<Inbound_Manifest_Fulfilment_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Fulfilment_Line_Avg_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_avg_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_fulfilment_line". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Fulfilment_Line_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_line_id?: Maybe<Int_Comparison_Exp>;
  is_file_copy?: Maybe<Boolean_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  InboundManifestFulfilmentLinePkey = 'inbound_manifest_fulfilment_line_pkey'
}

/** input type for incrementing numeric columns in table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Obj_Rel_Insert_Input>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  is_file_copy?: Maybe<Scalars['Boolean']>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Fulfilment_Line_Max_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Fulfilment_Line_Min_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Mutation_Response = {
  __typename?: 'inbound_manifest_fulfilment_line_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Fulfilment_Line>;
};

/** input type for inserting object relation for remote table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Fulfilment_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_On_Conflict = {
  constraint: Inbound_Manifest_Fulfilment_Line_Constraint;
  update_columns?: Array<Inbound_Manifest_Fulfilment_Line_Update_Column>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_fulfilment_line". */
export type Inbound_Manifest_Fulfilment_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  is_file_copy?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_fulfilment_line */
export type Inbound_Manifest_Fulfilment_Line_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestLineId = 'inbound_manifest_line_id',
  /** column name */
  IsFileCopy = 'is_file_copy',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "inbound_manifest_fulfilment_line_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFileCopy = 'is_file_copy'
}

/** select "inbound_manifest_fulfilment_line_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Select_Column_Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFileCopy = 'is_file_copy'
}

/** input type for updating data in table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  is_file_copy?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  is_file_copy?: Maybe<Scalars['Boolean']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Fulfilment_Line_Sum_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_sum_fields';
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "inbound_manifest_fulfilment_line" */
export enum Inbound_Manifest_Fulfilment_Line_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestLineId = 'inbound_manifest_line_id',
  /** column name */
  IsFileCopy = 'is_file_copy',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Inbound_Manifest_Fulfilment_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Fulfilment_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Fulfilment_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Fulfilment_Line_Var_Pop_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Fulfilment_Line_Var_Samp_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Fulfilment_Line_Variance_Fields = {
  __typename?: 'inbound_manifest_fulfilment_line_variance_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inbound_manifest_fulfilment_line" */
export type Inbound_Manifest_Fulfilment_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "inbound_manifest" */
export type Inbound_Manifest_Inc_Input = {
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "inbound_manifest" */
export type Inbound_Manifest_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamptz']>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Obj_Rel_Insert_Input>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "inbound_manifest_line" */
export type Inbound_Manifest_Line = {
  __typename?: 'inbound_manifest_line';
  cancelled_quantity: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  inbound_manifest: Inbound_Manifest;
  /** An array relationship */
  inbound_manifest_fulfilment_lines: Array<Inbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  inbound_manifest_fulfilment_lines_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  inbound_manifest_id: Scalars['Int'];
  /** An object relationship */
  inbound_manifest_status: Inbound_Manifest_Status;
  inbound_manifest_status_id: Scalars['Int'];
  is_fragile: Scalars['Boolean'];
  /** An object relationship */
  logistics_order: Logistics_Order;
  logistics_order_id: Scalars['Int'];
  missing_quantity: Scalars['Int'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  quantity: Scalars['Int'];
  received_quantity: Scalars['Int'];
  rejected_quantity: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "inbound_manifest_line" */
export type Inbound_Manifest_LineInbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


/** columns and relationships of "inbound_manifest_line" */
export type Inbound_Manifest_LineInbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate = {
  __typename?: 'inbound_manifest_line_aggregate';
  aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Line>;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp_Count>;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And = {
  arguments: Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Inbound_Manifest_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate_Fields = {
  __typename?: 'inbound_manifest_line_aggregate_fields';
  avg?: Maybe<Inbound_Manifest_Line_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inbound_Manifest_Line_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Line_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Line_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Line_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Line_Variance_Fields>;
};


/** aggregate fields of "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Aggregate_Order_By = {
  avg?: Maybe<Inbound_Manifest_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Inbound_Manifest_Line_Max_Order_By>;
  min?: Maybe<Inbound_Manifest_Line_Min_Order_By>;
  stddev?: Maybe<Inbound_Manifest_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Inbound_Manifest_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Inbound_Manifest_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Inbound_Manifest_Line_Sum_Order_By>;
  var_pop?: Maybe<Inbound_Manifest_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Inbound_Manifest_Line_Var_Samp_Order_By>;
  variance?: Maybe<Inbound_Manifest_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Arr_Rel_Insert_Input = {
  data: Array<Inbound_Manifest_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Line_Avg_Fields = {
  __typename?: 'inbound_manifest_line_avg_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_line". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Line_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Line_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Line_Bool_Exp>>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifest_fulfilment_lines?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  inbound_manifest_fulfilment_lines_aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  inbound_manifest_id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
  inbound_manifest_status_id?: Maybe<Int_Comparison_Exp>;
  is_fragile?: Maybe<Boolean_Comparison_Exp>;
  logistics_order?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_order_id?: Maybe<Int_Comparison_Exp>;
  missing_quantity?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  received_quantity?: Maybe<Int_Comparison_Exp>;
  rejected_quantity?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  InboundManifestLinePkey = 'inbound_manifest_line_pkey'
}

/** input type for incrementing numeric columns in table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Insert_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest?: Maybe<Inbound_Manifest_Obj_Rel_Insert_Input>;
  inbound_manifest_fulfilment_lines?: Maybe<Inbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Obj_Rel_Insert_Input>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  is_fragile?: Maybe<Scalars['Boolean']>;
  logistics_order?: Maybe<Logistics_Order_Obj_Rel_Insert_Input>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Line_Max_Fields = {
  __typename?: 'inbound_manifest_line_max_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Line_Min_Fields = {
  __typename?: 'inbound_manifest_line_min_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Mutation_Response = {
  __typename?: 'inbound_manifest_line_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Line>;
};

/** input type for inserting object relation for remote table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_line" */
export type Inbound_Manifest_Line_On_Conflict = {
  constraint: Inbound_Manifest_Line_Constraint;
  update_columns?: Array<Inbound_Manifest_Line_Update_Column>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_line". */
export type Inbound_Manifest_Line_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest?: Maybe<Inbound_Manifest_Order_By>;
  inbound_manifest_fulfilment_lines_aggregate?: Maybe<Inbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  is_fragile?: Maybe<Order_By>;
  logistics_order?: Maybe<Logistics_Order_Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_line */
export type Inbound_Manifest_Line_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Select_Column {
  /** column name */
  CancelledQuantity = 'cancelled_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestId = 'inbound_manifest_id',
  /** column name */
  InboundManifestStatusId = 'inbound_manifest_status_id',
  /** column name */
  IsFragile = 'is_fragile',
  /** column name */
  LogisticsOrderId = 'logistics_order_id',
  /** column name */
  MissingQuantity = 'missing_quantity',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReceivedQuantity = 'received_quantity',
  /** column name */
  RejectedQuantity = 'rejected_quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "inbound_manifest_line_aggregate_bool_exp_bool_and_arguments_columns" columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFragile = 'is_fragile'
}

/** select "inbound_manifest_line_aggregate_bool_exp_bool_or_arguments_columns" columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Select_Column_Inbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFragile = 'is_fragile'
}

/** input type for updating data in table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Set_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  is_fragile?: Maybe<Scalars['Boolean']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Line_Stddev_Fields = {
  __typename?: 'inbound_manifest_line_stddev_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Line_Stddev_Pop_Fields = {
  __typename?: 'inbound_manifest_line_stddev_pop_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Line_Stddev_Samp_Fields = {
  __typename?: 'inbound_manifest_line_stddev_samp_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Line_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  is_fragile?: Maybe<Scalars['Boolean']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Line_Sum_Fields = {
  __typename?: 'inbound_manifest_line_sum_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  missing_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  received_quantity?: Maybe<Scalars['Int']>;
  rejected_quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** update columns of table "inbound_manifest_line" */
export enum Inbound_Manifest_Line_Update_Column {
  /** column name */
  CancelledQuantity = 'cancelled_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestId = 'inbound_manifest_id',
  /** column name */
  InboundManifestStatusId = 'inbound_manifest_status_id',
  /** column name */
  IsFragile = 'is_fragile',
  /** column name */
  LogisticsOrderId = 'logistics_order_id',
  /** column name */
  MissingQuantity = 'missing_quantity',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  ReceivedQuantity = 'received_quantity',
  /** column name */
  RejectedQuantity = 'rejected_quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Inbound_Manifest_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Line_Var_Pop_Fields = {
  __typename?: 'inbound_manifest_line_var_pop_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Line_Var_Samp_Fields = {
  __typename?: 'inbound_manifest_line_var_samp_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Line_Variance_Fields = {
  __typename?: 'inbound_manifest_line_variance_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  missing_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  received_quantity?: Maybe<Scalars['Float']>;
  rejected_quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inbound_manifest_line" */
export type Inbound_Manifest_Line_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  missing_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  received_quantity?: Maybe<Order_By>;
  rejected_quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Max_Fields = {
  __typename?: 'inbound_manifest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Min_Fields = {
  __typename?: 'inbound_manifest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "inbound_manifest" */
export type Inbound_Manifest_Mutation_Response = {
  __typename?: 'inbound_manifest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest>;
};

/** input type for inserting object relation for remote table "inbound_manifest" */
export type Inbound_Manifest_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest" */
export type Inbound_Manifest_On_Conflict = {
  constraint: Inbound_Manifest_Constraint;
  update_columns?: Array<Inbound_Manifest_Update_Column>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest". */
export type Inbound_Manifest_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_arrival?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest */
export type Inbound_Manifest_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inbound_manifest" */
export enum Inbound_Manifest_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedArrival = 'expected_arrival',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestStatusId = 'inbound_manifest_status_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseId = 'warehouse_id'
}

/** input type for updating data in table "inbound_manifest" */
export type Inbound_Manifest_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamptz']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_Status = {
  __typename?: 'inbound_manifest_status';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  inbound_manifest_status_name: Scalars['String'];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_StatusInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_StatusInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_StatusInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "inbound_manifest_status" */
export type Inbound_Manifest_StatusInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "inbound_manifest_status" */
export type Inbound_Manifest_Status_Aggregate = {
  __typename?: 'inbound_manifest_status_aggregate';
  aggregate?: Maybe<Inbound_Manifest_Status_Aggregate_Fields>;
  nodes: Array<Inbound_Manifest_Status>;
};

/** aggregate fields of "inbound_manifest_status" */
export type Inbound_Manifest_Status_Aggregate_Fields = {
  __typename?: 'inbound_manifest_status_aggregate_fields';
  avg?: Maybe<Inbound_Manifest_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Inbound_Manifest_Status_Max_Fields>;
  min?: Maybe<Inbound_Manifest_Status_Min_Fields>;
  stddev?: Maybe<Inbound_Manifest_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Inbound_Manifest_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Inbound_Manifest_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Inbound_Manifest_Status_Sum_Fields>;
  var_pop?: Maybe<Inbound_Manifest_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Inbound_Manifest_Status_Var_Samp_Fields>;
  variance?: Maybe<Inbound_Manifest_Status_Variance_Fields>;
};


/** aggregate fields of "inbound_manifest_status" */
export type Inbound_Manifest_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Inbound_Manifest_Status_Avg_Fields = {
  __typename?: 'inbound_manifest_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "inbound_manifest_status". All fields are combined with a logical 'AND'. */
export type Inbound_Manifest_Status_Bool_Exp = {
  _and?: Maybe<Array<Inbound_Manifest_Status_Bool_Exp>>;
  _not?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
  _or?: Maybe<Array<Inbound_Manifest_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  inbound_manifest_status_name?: Maybe<String_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "inbound_manifest_status" */
export enum Inbound_Manifest_Status_Constraint {
  /** unique or primary key constraint on columns "inbound_manifest_status_name" */
  InboundManifestStatusInboundManifestStatusNameKey = 'inbound_manifest_status_inbound_manifest_status_name_key',
  /** unique or primary key constraint on columns "id" */
  InboundManifestStatusPkey = 'inbound_manifest_status_pkey'
}

/** input type for incrementing numeric columns in table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  inbound_manifest_status_name?: Maybe<Scalars['String']>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Inbound_Manifest_Status_Max_Fields = {
  __typename?: 'inbound_manifest_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Inbound_Manifest_Status_Min_Fields = {
  __typename?: 'inbound_manifest_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Mutation_Response = {
  __typename?: 'inbound_manifest_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Inbound_Manifest_Status>;
};

/** input type for inserting object relation for remote table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Obj_Rel_Insert_Input = {
  data: Inbound_Manifest_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Inbound_Manifest_Status_On_Conflict>;
};

/** on_conflict condition type for table "inbound_manifest_status" */
export type Inbound_Manifest_Status_On_Conflict = {
  constraint: Inbound_Manifest_Status_Constraint;
  update_columns?: Array<Inbound_Manifest_Status_Update_Column>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "inbound_manifest_status". */
export type Inbound_Manifest_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  inbound_manifest_status_name?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: inbound_manifest_status */
export type Inbound_Manifest_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "inbound_manifest_status" */
export enum Inbound_Manifest_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestStatusName = 'inbound_manifest_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Status_Stddev_Fields = {
  __typename?: 'inbound_manifest_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Status_Stddev_Pop_Fields = {
  __typename?: 'inbound_manifest_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Status_Stddev_Samp_Fields = {
  __typename?: 'inbound_manifest_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "inbound_manifest_status" */
export type Inbound_Manifest_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Status_Sum_Fields = {
  __typename?: 'inbound_manifest_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "inbound_manifest_status" */
export enum Inbound_Manifest_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InboundManifestStatusName = 'inbound_manifest_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Inbound_Manifest_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Status_Var_Pop_Fields = {
  __typename?: 'inbound_manifest_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Status_Var_Samp_Fields = {
  __typename?: 'inbound_manifest_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Status_Variance_Fields = {
  __typename?: 'inbound_manifest_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Inbound_Manifest_Stddev_Fields = {
  __typename?: 'inbound_manifest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Inbound_Manifest_Stddev_Pop_Fields = {
  __typename?: 'inbound_manifest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Inbound_Manifest_Stddev_Samp_Fields = {
  __typename?: 'inbound_manifest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "inbound_manifest" */
export type Inbound_Manifest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Inbound_Manifest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Inbound_Manifest_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_arrival?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Inbound_Manifest_Sum_Fields = {
  __typename?: 'inbound_manifest_sum_fields';
  id?: Maybe<Scalars['Int']>;
  inbound_manifest_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Sum_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "inbound_manifest" */
export enum Inbound_Manifest_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedArrival = 'expected_arrival',
  /** column name */
  InboundManifestStatusId = 'inbound_manifest_status_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseId = 'warehouse_id'
}

export type Inbound_Manifest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Inbound_Manifest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Inbound_Manifest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Inbound_Manifest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Inbound_Manifest_Var_Pop_Fields = {
  __typename?: 'inbound_manifest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Inbound_Manifest_Var_Samp_Fields = {
  __typename?: 'inbound_manifest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Inbound_Manifest_Variance_Fields = {
  __typename?: 'inbound_manifest_variance_fields';
  id?: Maybe<Scalars['Float']>;
  inbound_manifest_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "inbound_manifest" */
export type Inbound_Manifest_Variance_Order_By = {
  id?: Maybe<Order_By>;
  inbound_manifest_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};


export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "logistics_order" */
export type Logistics_Order = {
  __typename?: 'logistics_order';
  /** An object relationship */
  address: Address;
  /** An object relationship */
  addressByToAddressId: Address;
  cancelled_quantity: Scalars['Int'];
  completed_quantity: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  external_order_id: Scalars['Int'];
  from_address_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  /** An object relationship */
  logistics_order_status: Logistics_Order_Status;
  logistics_order_status_id: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['Int'];
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  quantity: Scalars['Int'];
  reference: Scalars['String'];
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  to_address_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "logistics_order" */
export type Logistics_OrderInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "logistics_order" */
export type Logistics_OrderInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "logistics_order" */
export type Logistics_OrderOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "logistics_order" */
export type Logistics_OrderOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** aggregated selection of "logistics_order" */
export type Logistics_Order_Aggregate = {
  __typename?: 'logistics_order_aggregate';
  aggregate?: Maybe<Logistics_Order_Aggregate_Fields>;
  nodes: Array<Logistics_Order>;
};

export type Logistics_Order_Aggregate_Bool_Exp = {
  count?: Maybe<Logistics_Order_Aggregate_Bool_Exp_Count>;
};

export type Logistics_Order_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Logistics_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Logistics_Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "logistics_order" */
export type Logistics_Order_Aggregate_Fields = {
  __typename?: 'logistics_order_aggregate_fields';
  avg?: Maybe<Logistics_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Logistics_Order_Max_Fields>;
  min?: Maybe<Logistics_Order_Min_Fields>;
  stddev?: Maybe<Logistics_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Logistics_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Logistics_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Logistics_Order_Sum_Fields>;
  var_pop?: Maybe<Logistics_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Logistics_Order_Var_Samp_Fields>;
  variance?: Maybe<Logistics_Order_Variance_Fields>;
};


/** aggregate fields of "logistics_order" */
export type Logistics_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logistics_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "logistics_order" */
export type Logistics_Order_Aggregate_Order_By = {
  avg?: Maybe<Logistics_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Logistics_Order_Max_Order_By>;
  min?: Maybe<Logistics_Order_Min_Order_By>;
  stddev?: Maybe<Logistics_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Logistics_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Logistics_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Logistics_Order_Sum_Order_By>;
  var_pop?: Maybe<Logistics_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Logistics_Order_Var_Samp_Order_By>;
  variance?: Maybe<Logistics_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "logistics_order" */
export type Logistics_Order_Arr_Rel_Insert_Input = {
  data: Array<Logistics_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Logistics_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Logistics_Order_Avg_Fields = {
  __typename?: 'logistics_order_avg_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "logistics_order" */
export type Logistics_Order_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "logistics_order". All fields are combined with a logical 'AND'. */
export type Logistics_Order_Bool_Exp = {
  _and?: Maybe<Array<Logistics_Order_Bool_Exp>>;
  _not?: Maybe<Logistics_Order_Bool_Exp>;
  _or?: Maybe<Array<Logistics_Order_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  addressByToAddressId?: Maybe<Address_Bool_Exp>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  completed_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_order_id?: Maybe<Int_Comparison_Exp>;
  from_address_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  logistics_order_status?: Maybe<Logistics_Order_Status_Bool_Exp>;
  logistics_order_status_id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  reference?: Maybe<String_Comparison_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  to_address_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "logistics_order" */
export enum Logistics_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  LogisticsOrderPkey = 'logistics_order_pkey',
  /** unique or primary key constraint on columns "reference" */
  LogisticsOrderReferenceKey = 'logistics_order_reference_key'
}

/** input type for incrementing numeric columns in table "logistics_order" */
export type Logistics_Order_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "logistics_order" */
export type Logistics_Order_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  addressByToAddressId?: Maybe<Address_Obj_Rel_Insert_Input>;
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  logistics_order_status?: Maybe<Logistics_Order_Status_Obj_Rel_Insert_Input>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Logistics_Order_Max_Fields = {
  __typename?: 'logistics_order_max_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "logistics_order" */
export type Logistics_Order_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Logistics_Order_Min_Fields = {
  __typename?: 'logistics_order_min_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "logistics_order" */
export type Logistics_Order_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "logistics_order" */
export type Logistics_Order_Mutation_Response = {
  __typename?: 'logistics_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Logistics_Order>;
};

/** input type for inserting object relation for remote table "logistics_order" */
export type Logistics_Order_Obj_Rel_Insert_Input = {
  data: Logistics_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Logistics_Order_On_Conflict>;
};

/** on_conflict condition type for table "logistics_order" */
export type Logistics_Order_On_Conflict = {
  constraint: Logistics_Order_Constraint;
  update_columns?: Array<Logistics_Order_Update_Column>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "logistics_order". */
export type Logistics_Order_Order_By = {
  address?: Maybe<Address_Order_By>;
  addressByToAddressId?: Maybe<Address_Order_By>;
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  logistics_order_status?: Maybe<Logistics_Order_Status_Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  reference?: Maybe<Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: logistics_order */
export type Logistics_Order_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "logistics_order" */
export enum Logistics_Order_Select_Column {
  /** column name */
  CancelledQuantity = 'cancelled_quantity',
  /** column name */
  CompletedQuantity = 'completed_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalOrderId = 'external_order_id',
  /** column name */
  FromAddressId = 'from_address_id',
  /** column name */
  Id = 'id',
  /** column name */
  LogisticsOrderStatusId = 'logistics_order_status_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Reference = 'reference',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  ToAddressId = 'to_address_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "logistics_order" */
export type Logistics_Order_Set_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "logistics_order_status" */
export type Logistics_Order_Status = {
  __typename?: 'logistics_order_status';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  logistics_order_status_name: Scalars['String'];
  /** An array relationship */
  logistics_orders: Array<Logistics_Order>;
  /** An aggregate relationship */
  logistics_orders_aggregate: Logistics_Order_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "logistics_order_status" */
export type Logistics_Order_StatusLogistics_OrdersArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "logistics_order_status" */
export type Logistics_Order_StatusLogistics_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};

/** aggregated selection of "logistics_order_status" */
export type Logistics_Order_Status_Aggregate = {
  __typename?: 'logistics_order_status_aggregate';
  aggregate?: Maybe<Logistics_Order_Status_Aggregate_Fields>;
  nodes: Array<Logistics_Order_Status>;
};

/** aggregate fields of "logistics_order_status" */
export type Logistics_Order_Status_Aggregate_Fields = {
  __typename?: 'logistics_order_status_aggregate_fields';
  avg?: Maybe<Logistics_Order_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Logistics_Order_Status_Max_Fields>;
  min?: Maybe<Logistics_Order_Status_Min_Fields>;
  stddev?: Maybe<Logistics_Order_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Logistics_Order_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Logistics_Order_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Logistics_Order_Status_Sum_Fields>;
  var_pop?: Maybe<Logistics_Order_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Logistics_Order_Status_Var_Samp_Fields>;
  variance?: Maybe<Logistics_Order_Status_Variance_Fields>;
};


/** aggregate fields of "logistics_order_status" */
export type Logistics_Order_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Logistics_Order_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Logistics_Order_Status_Avg_Fields = {
  __typename?: 'logistics_order_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "logistics_order_status". All fields are combined with a logical 'AND'. */
export type Logistics_Order_Status_Bool_Exp = {
  _and?: Maybe<Array<Logistics_Order_Status_Bool_Exp>>;
  _not?: Maybe<Logistics_Order_Status_Bool_Exp>;
  _or?: Maybe<Array<Logistics_Order_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  logistics_order_status_name?: Maybe<String_Comparison_Exp>;
  logistics_orders?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "logistics_order_status" */
export enum Logistics_Order_Status_Constraint {
  /** unique or primary key constraint on columns "logistics_order_status_name" */
  LogisticsOrderStatusLogisticsOrderStatusNameKey = 'logistics_order_status_logistics_order_status_name_key',
  /** unique or primary key constraint on columns "id" */
  LogisticsOrderStatusPkey = 'logistics_order_status_pkey'
}

/** input type for incrementing numeric columns in table "logistics_order_status" */
export type Logistics_Order_Status_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "logistics_order_status" */
export type Logistics_Order_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_name?: Maybe<Scalars['String']>;
  logistics_orders?: Maybe<Logistics_Order_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Logistics_Order_Status_Max_Fields = {
  __typename?: 'logistics_order_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Logistics_Order_Status_Min_Fields = {
  __typename?: 'logistics_order_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "logistics_order_status" */
export type Logistics_Order_Status_Mutation_Response = {
  __typename?: 'logistics_order_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Logistics_Order_Status>;
};

/** input type for inserting object relation for remote table "logistics_order_status" */
export type Logistics_Order_Status_Obj_Rel_Insert_Input = {
  data: Logistics_Order_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Logistics_Order_Status_On_Conflict>;
};

/** on_conflict condition type for table "logistics_order_status" */
export type Logistics_Order_Status_On_Conflict = {
  constraint: Logistics_Order_Status_Constraint;
  update_columns?: Array<Logistics_Order_Status_Update_Column>;
  where?: Maybe<Logistics_Order_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "logistics_order_status". */
export type Logistics_Order_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_name?: Maybe<Order_By>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: logistics_order_status */
export type Logistics_Order_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "logistics_order_status" */
export enum Logistics_Order_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LogisticsOrderStatusName = 'logistics_order_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "logistics_order_status" */
export type Logistics_Order_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Logistics_Order_Status_Stddev_Fields = {
  __typename?: 'logistics_order_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Logistics_Order_Status_Stddev_Pop_Fields = {
  __typename?: 'logistics_order_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Logistics_Order_Status_Stddev_Samp_Fields = {
  __typename?: 'logistics_order_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "logistics_order_status" */
export type Logistics_Order_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Logistics_Order_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Logistics_Order_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Logistics_Order_Status_Sum_Fields = {
  __typename?: 'logistics_order_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "logistics_order_status" */
export enum Logistics_Order_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  LogisticsOrderStatusName = 'logistics_order_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Logistics_Order_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Logistics_Order_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Logistics_Order_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Logistics_Order_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Logistics_Order_Status_Var_Pop_Fields = {
  __typename?: 'logistics_order_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Logistics_Order_Status_Var_Samp_Fields = {
  __typename?: 'logistics_order_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Logistics_Order_Status_Variance_Fields = {
  __typename?: 'logistics_order_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Logistics_Order_Stddev_Fields = {
  __typename?: 'logistics_order_stddev_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "logistics_order" */
export type Logistics_Order_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Logistics_Order_Stddev_Pop_Fields = {
  __typename?: 'logistics_order_stddev_pop_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "logistics_order" */
export type Logistics_Order_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Logistics_Order_Stddev_Samp_Fields = {
  __typename?: 'logistics_order_stddev_samp_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "logistics_order" */
export type Logistics_Order_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "logistics_order" */
export type Logistics_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Logistics_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Logistics_Order_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  reference?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Logistics_Order_Sum_Fields = {
  __typename?: 'logistics_order_sum_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  completed_quantity?: Maybe<Scalars['Int']>;
  external_order_id?: Maybe<Scalars['Int']>;
  from_address_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_status_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "logistics_order" */
export type Logistics_Order_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** update columns of table "logistics_order" */
export enum Logistics_Order_Update_Column {
  /** column name */
  CancelledQuantity = 'cancelled_quantity',
  /** column name */
  CompletedQuantity = 'completed_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalOrderId = 'external_order_id',
  /** column name */
  FromAddressId = 'from_address_id',
  /** column name */
  LogisticsOrderStatusId = 'logistics_order_status_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  Reference = 'reference',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  ToAddressId = 'to_address_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Logistics_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Logistics_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Logistics_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Logistics_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Logistics_Order_Var_Pop_Fields = {
  __typename?: 'logistics_order_var_pop_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "logistics_order" */
export type Logistics_Order_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Logistics_Order_Var_Samp_Fields = {
  __typename?: 'logistics_order_var_samp_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "logistics_order" */
export type Logistics_Order_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Logistics_Order_Variance_Fields = {
  __typename?: 'logistics_order_variance_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  completed_quantity?: Maybe<Scalars['Float']>;
  external_order_id?: Maybe<Scalars['Float']>;
  from_address_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_status_id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "logistics_order" */
export type Logistics_Order_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  completed_quantity?: Maybe<Order_By>;
  external_order_id?: Maybe<Order_By>;
  from_address_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_status_id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  /** delete data from the table: "address" */
  delete_address?: Maybe<Address_Mutation_Response>;
  /** delete single row from the table: "address" */
  delete_address_by_pk?: Maybe<Address>;
  /** delete data from the table: "attachment" */
  delete_attachment?: Maybe<Attachment_Mutation_Response>;
  /** delete single row from the table: "attachment" */
  delete_attachment_by_pk?: Maybe<Attachment>;
  /** delete data from the table: "client_order" */
  delete_client_order?: Maybe<Client_Order_Mutation_Response>;
  /** delete single row from the table: "client_order" */
  delete_client_order_by_pk?: Maybe<Client_Order>;
  /** delete data from the table: "country" */
  delete_country?: Maybe<Country_Mutation_Response>;
  /** delete single row from the table: "country" */
  delete_country_by_pk?: Maybe<Country>;
  /** delete data from the table: "databasechangelog" */
  delete_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** delete data from the table: "databasechangeloglock" */
  delete_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** delete single row from the table: "databasechangeloglock" */
  delete_databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** delete data from the table: "folder" */
  delete_folder?: Maybe<Folder_Mutation_Response>;
  /** delete single row from the table: "folder" */
  delete_folder_by_pk?: Maybe<Folder>;
  /** delete data from the table: "inbound_manifest" */
  delete_inbound_manifest?: Maybe<Inbound_Manifest_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest" */
  delete_inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** delete data from the table: "inbound_manifest_fulfilment_line" */
  delete_inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_fulfilment_line" */
  delete_inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** delete data from the table: "inbound_manifest_line" */
  delete_inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_line" */
  delete_inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** delete data from the table: "inbound_manifest_status" */
  delete_inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Mutation_Response>;
  /** delete single row from the table: "inbound_manifest_status" */
  delete_inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** delete data from the table: "logistics_order" */
  delete_logistics_order?: Maybe<Logistics_Order_Mutation_Response>;
  /** delete single row from the table: "logistics_order" */
  delete_logistics_order_by_pk?: Maybe<Logistics_Order>;
  /** delete data from the table: "logistics_order_status" */
  delete_logistics_order_status?: Maybe<Logistics_Order_Status_Mutation_Response>;
  /** delete single row from the table: "logistics_order_status" */
  delete_logistics_order_status_by_pk?: Maybe<Logistics_Order_Status>;
  /** delete data from the table: "organisation" */
  delete_organisation?: Maybe<Organisation_Mutation_Response>;
  /** delete single row from the table: "organisation" */
  delete_organisation_by_pk?: Maybe<Organisation>;
  /** delete data from the table: "outbound_manifest" */
  delete_outbound_manifest?: Maybe<Outbound_Manifest_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest" */
  delete_outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** delete data from the table: "outbound_manifest_fulfilment_line" */
  delete_outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_fulfilment_line" */
  delete_outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** delete data from the table: "outbound_manifest_line" */
  delete_outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_line" */
  delete_outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** delete data from the table: "outbound_manifest_status" */
  delete_outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Mutation_Response>;
  /** delete single row from the table: "outbound_manifest_status" */
  delete_outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** delete data from the table: "product" */
  delete_product?: Maybe<Product_Mutation_Response>;
  /** delete single row from the table: "product" */
  delete_product_by_pk?: Maybe<Product>;
  /** delete data from the table: "product_stock" */
  delete_product_stock?: Maybe<Product_Stock_Mutation_Response>;
  /** delete single row from the table: "product_stock" */
  delete_product_stock_by_pk?: Maybe<Product_Stock>;
  /** delete data from the table: "purchase_order" */
  delete_purchase_order?: Maybe<Purchase_Order_Mutation_Response>;
  /** delete single row from the table: "purchase_order" */
  delete_purchase_order_by_pk?: Maybe<Purchase_Order>;
  /** delete data from the table: "service_account" */
  delete_service_account?: Maybe<Service_Account_Mutation_Response>;
  /** delete single row from the table: "service_account" */
  delete_service_account_by_pk?: Maybe<Service_Account>;
  /** delete data from the table: "shipment" */
  delete_shipment?: Maybe<Shipment_Mutation_Response>;
  /** delete single row from the table: "shipment" */
  delete_shipment_by_pk?: Maybe<Shipment>;
  /** delete data from the table: "shipment_status" */
  delete_shipment_status?: Maybe<Shipment_Status_Mutation_Response>;
  /** delete single row from the table: "shipment_status" */
  delete_shipment_status_by_pk?: Maybe<Shipment_Status>;
  /** delete data from the table: "shipment_update" */
  delete_shipment_update?: Maybe<Shipment_Update_Mutation_Response>;
  /** delete single row from the table: "shipment_update" */
  delete_shipment_update_by_pk?: Maybe<Shipment_Update>;
  /** delete data from the table: "shipment_update_type" */
  delete_shipment_update_type?: Maybe<Shipment_Update_Type_Mutation_Response>;
  /** delete single row from the table: "shipment_update_type" */
  delete_shipment_update_type_by_pk?: Maybe<Shipment_Update_Type>;
  /** delete data from the table: "stock_condition" */
  delete_stock_condition?: Maybe<Stock_Condition_Mutation_Response>;
  /** delete single row from the table: "stock_condition" */
  delete_stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** delete data from the table: "stock_status" */
  delete_stock_status?: Maybe<Stock_Status_Mutation_Response>;
  /** delete single row from the table: "stock_status" */
  delete_stock_status_by_pk?: Maybe<Stock_Status>;
  /** delete data from the table: "stock_sync_log" */
  delete_stock_sync_log?: Maybe<Stock_Sync_Log_Mutation_Response>;
  /** delete single row from the table: "stock_sync_log" */
  delete_stock_sync_log_by_pk?: Maybe<Stock_Sync_Log>;
  /** delete data from the table: "warehouse" */
  delete_warehouse?: Maybe<Warehouse_Mutation_Response>;
  /** delete single row from the table: "warehouse" */
  delete_warehouse_by_pk?: Maybe<Warehouse>;
  /** delete data from the table: "wms_stock_change" */
  delete_wms_stock_change?: Maybe<Wms_Stock_Change_Mutation_Response>;
  /** delete single row from the table: "wms_stock_change" */
  delete_wms_stock_change_by_pk?: Maybe<Wms_Stock_Change>;
  /** delete data from the table: "wms_stock_change_input" */
  delete_wms_stock_change_input?: Maybe<Wms_Stock_Change_Input_Mutation_Response>;
  /** delete single row from the table: "wms_stock_change_input" */
  delete_wms_stock_change_input_by_pk?: Maybe<Wms_Stock_Change_Input>;
  /** delete data from the table: "wms_stock_change_output" */
  delete_wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Mutation_Response>;
  /** delete single row from the table: "wms_stock_change_output" */
  delete_wms_stock_change_output_by_pk?: Maybe<Wms_Stock_Change_Output>;
  /** delete data from the table: "wms_stock_change_type" */
  delete_wms_stock_change_type?: Maybe<Wms_Stock_Change_Type_Mutation_Response>;
  /** delete single row from the table: "wms_stock_change_type" */
  delete_wms_stock_change_type_by_pk?: Maybe<Wms_Stock_Change_Type>;
  /** insert data into the table: "address" */
  insert_address?: Maybe<Address_Mutation_Response>;
  /** insert a single row into the table: "address" */
  insert_address_one?: Maybe<Address>;
  /** insert data into the table: "attachment" */
  insert_attachment?: Maybe<Attachment_Mutation_Response>;
  /** insert a single row into the table: "attachment" */
  insert_attachment_one?: Maybe<Attachment>;
  /** insert data into the table: "client_order" */
  insert_client_order?: Maybe<Client_Order_Mutation_Response>;
  /** insert a single row into the table: "client_order" */
  insert_client_order_one?: Maybe<Client_Order>;
  /** insert data into the table: "country" */
  insert_country?: Maybe<Country_Mutation_Response>;
  /** insert a single row into the table: "country" */
  insert_country_one?: Maybe<Country>;
  /** insert data into the table: "databasechangelog" */
  insert_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** insert a single row into the table: "databasechangelog" */
  insert_databasechangelog_one?: Maybe<Databasechangelog>;
  /** insert data into the table: "databasechangeloglock" */
  insert_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** insert a single row into the table: "databasechangeloglock" */
  insert_databasechangeloglock_one?: Maybe<Databasechangeloglock>;
  /** insert data into the table: "folder" */
  insert_folder?: Maybe<Folder_Mutation_Response>;
  /** insert a single row into the table: "folder" */
  insert_folder_one?: Maybe<Folder>;
  /** insert data into the table: "inbound_manifest" */
  insert_inbound_manifest?: Maybe<Inbound_Manifest_Mutation_Response>;
  /** insert data into the table: "inbound_manifest_fulfilment_line" */
  insert_inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_fulfilment_line" */
  insert_inbound_manifest_fulfilment_line_one?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** insert data into the table: "inbound_manifest_line" */
  insert_inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_line" */
  insert_inbound_manifest_line_one?: Maybe<Inbound_Manifest_Line>;
  /** insert a single row into the table: "inbound_manifest" */
  insert_inbound_manifest_one?: Maybe<Inbound_Manifest>;
  /** insert data into the table: "inbound_manifest_status" */
  insert_inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Mutation_Response>;
  /** insert a single row into the table: "inbound_manifest_status" */
  insert_inbound_manifest_status_one?: Maybe<Inbound_Manifest_Status>;
  /** insert data into the table: "logistics_order" */
  insert_logistics_order?: Maybe<Logistics_Order_Mutation_Response>;
  /** insert a single row into the table: "logistics_order" */
  insert_logistics_order_one?: Maybe<Logistics_Order>;
  /** insert data into the table: "logistics_order_status" */
  insert_logistics_order_status?: Maybe<Logistics_Order_Status_Mutation_Response>;
  /** insert a single row into the table: "logistics_order_status" */
  insert_logistics_order_status_one?: Maybe<Logistics_Order_Status>;
  /** insert data into the table: "organisation" */
  insert_organisation?: Maybe<Organisation_Mutation_Response>;
  /** insert a single row into the table: "organisation" */
  insert_organisation_one?: Maybe<Organisation>;
  /** insert data into the table: "outbound_manifest" */
  insert_outbound_manifest?: Maybe<Outbound_Manifest_Mutation_Response>;
  /** insert data into the table: "outbound_manifest_fulfilment_line" */
  insert_outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_fulfilment_line" */
  insert_outbound_manifest_fulfilment_line_one?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** insert data into the table: "outbound_manifest_line" */
  insert_outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_line" */
  insert_outbound_manifest_line_one?: Maybe<Outbound_Manifest_Line>;
  /** insert a single row into the table: "outbound_manifest" */
  insert_outbound_manifest_one?: Maybe<Outbound_Manifest>;
  /** insert data into the table: "outbound_manifest_status" */
  insert_outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Mutation_Response>;
  /** insert a single row into the table: "outbound_manifest_status" */
  insert_outbound_manifest_status_one?: Maybe<Outbound_Manifest_Status>;
  /** insert data into the table: "product" */
  insert_product?: Maybe<Product_Mutation_Response>;
  /** insert a single row into the table: "product" */
  insert_product_one?: Maybe<Product>;
  /** insert data into the table: "product_stock" */
  insert_product_stock?: Maybe<Product_Stock_Mutation_Response>;
  /** insert a single row into the table: "product_stock" */
  insert_product_stock_one?: Maybe<Product_Stock>;
  /** insert data into the table: "purchase_order" */
  insert_purchase_order?: Maybe<Purchase_Order_Mutation_Response>;
  /** insert a single row into the table: "purchase_order" */
  insert_purchase_order_one?: Maybe<Purchase_Order>;
  /** insert data into the table: "service_account" */
  insert_service_account?: Maybe<Service_Account_Mutation_Response>;
  /** insert a single row into the table: "service_account" */
  insert_service_account_one?: Maybe<Service_Account>;
  /** insert data into the table: "shipment" */
  insert_shipment?: Maybe<Shipment_Mutation_Response>;
  /** insert a single row into the table: "shipment" */
  insert_shipment_one?: Maybe<Shipment>;
  /** insert data into the table: "shipment_status" */
  insert_shipment_status?: Maybe<Shipment_Status_Mutation_Response>;
  /** insert a single row into the table: "shipment_status" */
  insert_shipment_status_one?: Maybe<Shipment_Status>;
  /** insert data into the table: "shipment_update" */
  insert_shipment_update?: Maybe<Shipment_Update_Mutation_Response>;
  /** insert a single row into the table: "shipment_update" */
  insert_shipment_update_one?: Maybe<Shipment_Update>;
  /** insert data into the table: "shipment_update_type" */
  insert_shipment_update_type?: Maybe<Shipment_Update_Type_Mutation_Response>;
  /** insert a single row into the table: "shipment_update_type" */
  insert_shipment_update_type_one?: Maybe<Shipment_Update_Type>;
  /** insert data into the table: "stock_condition" */
  insert_stock_condition?: Maybe<Stock_Condition_Mutation_Response>;
  /** insert a single row into the table: "stock_condition" */
  insert_stock_condition_one?: Maybe<Stock_Condition>;
  /** insert data into the table: "stock_status" */
  insert_stock_status?: Maybe<Stock_Status_Mutation_Response>;
  /** insert a single row into the table: "stock_status" */
  insert_stock_status_one?: Maybe<Stock_Status>;
  /** insert data into the table: "stock_sync_log" */
  insert_stock_sync_log?: Maybe<Stock_Sync_Log_Mutation_Response>;
  /** insert a single row into the table: "stock_sync_log" */
  insert_stock_sync_log_one?: Maybe<Stock_Sync_Log>;
  /** insert data into the table: "warehouse" */
  insert_warehouse?: Maybe<Warehouse_Mutation_Response>;
  /** insert a single row into the table: "warehouse" */
  insert_warehouse_one?: Maybe<Warehouse>;
  /** insert data into the table: "wms_stock_change" */
  insert_wms_stock_change?: Maybe<Wms_Stock_Change_Mutation_Response>;
  /** insert data into the table: "wms_stock_change_input" */
  insert_wms_stock_change_input?: Maybe<Wms_Stock_Change_Input_Mutation_Response>;
  /** insert a single row into the table: "wms_stock_change_input" */
  insert_wms_stock_change_input_one?: Maybe<Wms_Stock_Change_Input>;
  /** insert a single row into the table: "wms_stock_change" */
  insert_wms_stock_change_one?: Maybe<Wms_Stock_Change>;
  /** insert data into the table: "wms_stock_change_output" */
  insert_wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Mutation_Response>;
  /** insert a single row into the table: "wms_stock_change_output" */
  insert_wms_stock_change_output_one?: Maybe<Wms_Stock_Change_Output>;
  /** insert data into the table: "wms_stock_change_type" */
  insert_wms_stock_change_type?: Maybe<Wms_Stock_Change_Type_Mutation_Response>;
  /** insert a single row into the table: "wms_stock_change_type" */
  insert_wms_stock_change_type_one?: Maybe<Wms_Stock_Change_Type>;
  /** update data of the table: "address" */
  update_address?: Maybe<Address_Mutation_Response>;
  /** update single row of the table: "address" */
  update_address_by_pk?: Maybe<Address>;
  /** update multiples rows of table: "address" */
  update_address_many?: Maybe<Array<Maybe<Address_Mutation_Response>>>;
  /** update data of the table: "attachment" */
  update_attachment?: Maybe<Attachment_Mutation_Response>;
  /** update single row of the table: "attachment" */
  update_attachment_by_pk?: Maybe<Attachment>;
  /** update multiples rows of table: "attachment" */
  update_attachment_many?: Maybe<Array<Maybe<Attachment_Mutation_Response>>>;
  /** update data of the table: "client_order" */
  update_client_order?: Maybe<Client_Order_Mutation_Response>;
  /** update single row of the table: "client_order" */
  update_client_order_by_pk?: Maybe<Client_Order>;
  /** update multiples rows of table: "client_order" */
  update_client_order_many?: Maybe<Array<Maybe<Client_Order_Mutation_Response>>>;
  /** update data of the table: "country" */
  update_country?: Maybe<Country_Mutation_Response>;
  /** update single row of the table: "country" */
  update_country_by_pk?: Maybe<Country>;
  /** update multiples rows of table: "country" */
  update_country_many?: Maybe<Array<Maybe<Country_Mutation_Response>>>;
  /** update data of the table: "databasechangelog" */
  update_databasechangelog?: Maybe<Databasechangelog_Mutation_Response>;
  /** update multiples rows of table: "databasechangelog" */
  update_databasechangelog_many?: Maybe<Array<Maybe<Databasechangelog_Mutation_Response>>>;
  /** update data of the table: "databasechangeloglock" */
  update_databasechangeloglock?: Maybe<Databasechangeloglock_Mutation_Response>;
  /** update single row of the table: "databasechangeloglock" */
  update_databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** update multiples rows of table: "databasechangeloglock" */
  update_databasechangeloglock_many?: Maybe<Array<Maybe<Databasechangeloglock_Mutation_Response>>>;
  /** update data of the table: "folder" */
  update_folder?: Maybe<Folder_Mutation_Response>;
  /** update single row of the table: "folder" */
  update_folder_by_pk?: Maybe<Folder>;
  /** update multiples rows of table: "folder" */
  update_folder_many?: Maybe<Array<Maybe<Folder_Mutation_Response>>>;
  /** update data of the table: "inbound_manifest" */
  update_inbound_manifest?: Maybe<Inbound_Manifest_Mutation_Response>;
  /** update single row of the table: "inbound_manifest" */
  update_inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** update data of the table: "inbound_manifest_fulfilment_line" */
  update_inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_fulfilment_line" */
  update_inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** update multiples rows of table: "inbound_manifest_fulfilment_line" */
  update_inbound_manifest_fulfilment_line_many?: Maybe<Array<Maybe<Inbound_Manifest_Fulfilment_Line_Mutation_Response>>>;
  /** update data of the table: "inbound_manifest_line" */
  update_inbound_manifest_line?: Maybe<Inbound_Manifest_Line_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_line" */
  update_inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** update multiples rows of table: "inbound_manifest_line" */
  update_inbound_manifest_line_many?: Maybe<Array<Maybe<Inbound_Manifest_Line_Mutation_Response>>>;
  /** update multiples rows of table: "inbound_manifest" */
  update_inbound_manifest_many?: Maybe<Array<Maybe<Inbound_Manifest_Mutation_Response>>>;
  /** update data of the table: "inbound_manifest_status" */
  update_inbound_manifest_status?: Maybe<Inbound_Manifest_Status_Mutation_Response>;
  /** update single row of the table: "inbound_manifest_status" */
  update_inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** update multiples rows of table: "inbound_manifest_status" */
  update_inbound_manifest_status_many?: Maybe<Array<Maybe<Inbound_Manifest_Status_Mutation_Response>>>;
  /** update data of the table: "logistics_order" */
  update_logistics_order?: Maybe<Logistics_Order_Mutation_Response>;
  /** update single row of the table: "logistics_order" */
  update_logistics_order_by_pk?: Maybe<Logistics_Order>;
  /** update multiples rows of table: "logistics_order" */
  update_logistics_order_many?: Maybe<Array<Maybe<Logistics_Order_Mutation_Response>>>;
  /** update data of the table: "logistics_order_status" */
  update_logistics_order_status?: Maybe<Logistics_Order_Status_Mutation_Response>;
  /** update single row of the table: "logistics_order_status" */
  update_logistics_order_status_by_pk?: Maybe<Logistics_Order_Status>;
  /** update multiples rows of table: "logistics_order_status" */
  update_logistics_order_status_many?: Maybe<Array<Maybe<Logistics_Order_Status_Mutation_Response>>>;
  /** update data of the table: "organisation" */
  update_organisation?: Maybe<Organisation_Mutation_Response>;
  /** update single row of the table: "organisation" */
  update_organisation_by_pk?: Maybe<Organisation>;
  /** update multiples rows of table: "organisation" */
  update_organisation_many?: Maybe<Array<Maybe<Organisation_Mutation_Response>>>;
  /** update data of the table: "outbound_manifest" */
  update_outbound_manifest?: Maybe<Outbound_Manifest_Mutation_Response>;
  /** update single row of the table: "outbound_manifest" */
  update_outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** update data of the table: "outbound_manifest_fulfilment_line" */
  update_outbound_manifest_fulfilment_line?: Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_fulfilment_line" */
  update_outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** update multiples rows of table: "outbound_manifest_fulfilment_line" */
  update_outbound_manifest_fulfilment_line_many?: Maybe<Array<Maybe<Outbound_Manifest_Fulfilment_Line_Mutation_Response>>>;
  /** update data of the table: "outbound_manifest_line" */
  update_outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_line" */
  update_outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** update multiples rows of table: "outbound_manifest_line" */
  update_outbound_manifest_line_many?: Maybe<Array<Maybe<Outbound_Manifest_Line_Mutation_Response>>>;
  /** update multiples rows of table: "outbound_manifest" */
  update_outbound_manifest_many?: Maybe<Array<Maybe<Outbound_Manifest_Mutation_Response>>>;
  /** update data of the table: "outbound_manifest_status" */
  update_outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Mutation_Response>;
  /** update single row of the table: "outbound_manifest_status" */
  update_outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** update multiples rows of table: "outbound_manifest_status" */
  update_outbound_manifest_status_many?: Maybe<Array<Maybe<Outbound_Manifest_Status_Mutation_Response>>>;
  /** update data of the table: "product" */
  update_product?: Maybe<Product_Mutation_Response>;
  /** update single row of the table: "product" */
  update_product_by_pk?: Maybe<Product>;
  /** update multiples rows of table: "product" */
  update_product_many?: Maybe<Array<Maybe<Product_Mutation_Response>>>;
  /** update data of the table: "product_stock" */
  update_product_stock?: Maybe<Product_Stock_Mutation_Response>;
  /** update single row of the table: "product_stock" */
  update_product_stock_by_pk?: Maybe<Product_Stock>;
  /** update multiples rows of table: "product_stock" */
  update_product_stock_many?: Maybe<Array<Maybe<Product_Stock_Mutation_Response>>>;
  /** update data of the table: "purchase_order" */
  update_purchase_order?: Maybe<Purchase_Order_Mutation_Response>;
  /** update single row of the table: "purchase_order" */
  update_purchase_order_by_pk?: Maybe<Purchase_Order>;
  /** update multiples rows of table: "purchase_order" */
  update_purchase_order_many?: Maybe<Array<Maybe<Purchase_Order_Mutation_Response>>>;
  /** update data of the table: "service_account" */
  update_service_account?: Maybe<Service_Account_Mutation_Response>;
  /** update single row of the table: "service_account" */
  update_service_account_by_pk?: Maybe<Service_Account>;
  /** update multiples rows of table: "service_account" */
  update_service_account_many?: Maybe<Array<Maybe<Service_Account_Mutation_Response>>>;
  /** update data of the table: "shipment" */
  update_shipment?: Maybe<Shipment_Mutation_Response>;
  /** update single row of the table: "shipment" */
  update_shipment_by_pk?: Maybe<Shipment>;
  /** update multiples rows of table: "shipment" */
  update_shipment_many?: Maybe<Array<Maybe<Shipment_Mutation_Response>>>;
  /** update data of the table: "shipment_status" */
  update_shipment_status?: Maybe<Shipment_Status_Mutation_Response>;
  /** update single row of the table: "shipment_status" */
  update_shipment_status_by_pk?: Maybe<Shipment_Status>;
  /** update multiples rows of table: "shipment_status" */
  update_shipment_status_many?: Maybe<Array<Maybe<Shipment_Status_Mutation_Response>>>;
  /** update data of the table: "shipment_update" */
  update_shipment_update?: Maybe<Shipment_Update_Mutation_Response>;
  /** update single row of the table: "shipment_update" */
  update_shipment_update_by_pk?: Maybe<Shipment_Update>;
  /** update multiples rows of table: "shipment_update" */
  update_shipment_update_many?: Maybe<Array<Maybe<Shipment_Update_Mutation_Response>>>;
  /** update data of the table: "shipment_update_type" */
  update_shipment_update_type?: Maybe<Shipment_Update_Type_Mutation_Response>;
  /** update single row of the table: "shipment_update_type" */
  update_shipment_update_type_by_pk?: Maybe<Shipment_Update_Type>;
  /** update multiples rows of table: "shipment_update_type" */
  update_shipment_update_type_many?: Maybe<Array<Maybe<Shipment_Update_Type_Mutation_Response>>>;
  /** update data of the table: "stock_condition" */
  update_stock_condition?: Maybe<Stock_Condition_Mutation_Response>;
  /** update single row of the table: "stock_condition" */
  update_stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** update multiples rows of table: "stock_condition" */
  update_stock_condition_many?: Maybe<Array<Maybe<Stock_Condition_Mutation_Response>>>;
  /** update data of the table: "stock_status" */
  update_stock_status?: Maybe<Stock_Status_Mutation_Response>;
  /** update single row of the table: "stock_status" */
  update_stock_status_by_pk?: Maybe<Stock_Status>;
  /** update multiples rows of table: "stock_status" */
  update_stock_status_many?: Maybe<Array<Maybe<Stock_Status_Mutation_Response>>>;
  /** update data of the table: "stock_sync_log" */
  update_stock_sync_log?: Maybe<Stock_Sync_Log_Mutation_Response>;
  /** update single row of the table: "stock_sync_log" */
  update_stock_sync_log_by_pk?: Maybe<Stock_Sync_Log>;
  /** update multiples rows of table: "stock_sync_log" */
  update_stock_sync_log_many?: Maybe<Array<Maybe<Stock_Sync_Log_Mutation_Response>>>;
  /** update data of the table: "warehouse" */
  update_warehouse?: Maybe<Warehouse_Mutation_Response>;
  /** update single row of the table: "warehouse" */
  update_warehouse_by_pk?: Maybe<Warehouse>;
  /** update multiples rows of table: "warehouse" */
  update_warehouse_many?: Maybe<Array<Maybe<Warehouse_Mutation_Response>>>;
  /** update data of the table: "wms_stock_change" */
  update_wms_stock_change?: Maybe<Wms_Stock_Change_Mutation_Response>;
  /** update single row of the table: "wms_stock_change" */
  update_wms_stock_change_by_pk?: Maybe<Wms_Stock_Change>;
  /** update data of the table: "wms_stock_change_input" */
  update_wms_stock_change_input?: Maybe<Wms_Stock_Change_Input_Mutation_Response>;
  /** update single row of the table: "wms_stock_change_input" */
  update_wms_stock_change_input_by_pk?: Maybe<Wms_Stock_Change_Input>;
  /** update multiples rows of table: "wms_stock_change_input" */
  update_wms_stock_change_input_many?: Maybe<Array<Maybe<Wms_Stock_Change_Input_Mutation_Response>>>;
  /** update multiples rows of table: "wms_stock_change" */
  update_wms_stock_change_many?: Maybe<Array<Maybe<Wms_Stock_Change_Mutation_Response>>>;
  /** update data of the table: "wms_stock_change_output" */
  update_wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Mutation_Response>;
  /** update single row of the table: "wms_stock_change_output" */
  update_wms_stock_change_output_by_pk?: Maybe<Wms_Stock_Change_Output>;
  /** update multiples rows of table: "wms_stock_change_output" */
  update_wms_stock_change_output_many?: Maybe<Array<Maybe<Wms_Stock_Change_Output_Mutation_Response>>>;
  /** update data of the table: "wms_stock_change_type" */
  update_wms_stock_change_type?: Maybe<Wms_Stock_Change_Type_Mutation_Response>;
  /** update single row of the table: "wms_stock_change_type" */
  update_wms_stock_change_type_by_pk?: Maybe<Wms_Stock_Change_Type>;
  /** update multiples rows of table: "wms_stock_change_type" */
  update_wms_stock_change_type_many?: Maybe<Array<Maybe<Wms_Stock_Change_Type_Mutation_Response>>>;
};


/** mutation root */
export type Mutation_RootDelete_AddressArgs = {
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Address_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_AttachmentArgs = {
  where: Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Attachment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Client_OrderArgs = {
  where: Client_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Client_Order_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_CountryArgs = {
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Country_By_PkArgs = {
  country_code: Scalars['String'];
};


/** mutation root */
export type Mutation_RootDelete_DatabasechangelogArgs = {
  where: Databasechangelog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_DatabasechangeloglockArgs = {
  where: Databasechangeloglock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Databasechangeloglock_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_FolderArgs = {
  where: Folder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Folder_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Inbound_ManifestArgs = {
  where: Inbound_Manifest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Fulfilment_LineArgs = {
  where: Inbound_Manifest_Fulfilment_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_LineArgs = {
  where: Inbound_Manifest_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Line_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_StatusArgs = {
  where: Inbound_Manifest_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Inbound_Manifest_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Logistics_OrderArgs = {
  where: Logistics_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Logistics_Order_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Logistics_Order_StatusArgs = {
  where: Logistics_Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Logistics_Order_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_OrganisationArgs = {
  where: Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Organisation_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Outbound_ManifestArgs = {
  where: Outbound_Manifest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_LineArgs = {
  where: Outbound_Manifest_Fulfilment_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_LineArgs = {
  where: Outbound_Manifest_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Line_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_StatusArgs = {
  where: Outbound_Manifest_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Outbound_Manifest_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ProductArgs = {
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Product_StockArgs = {
  where: Product_Stock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Product_Stock_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Purchase_OrderArgs = {
  where: Purchase_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Purchase_Order_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Service_AccountArgs = {
  where: Service_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Account_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_ShipmentArgs = {
  where: Shipment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shipment_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Shipment_StatusArgs = {
  where: Shipment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shipment_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Shipment_UpdateArgs = {
  where: Shipment_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shipment_Update_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Shipment_Update_TypeArgs = {
  where: Shipment_Update_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Shipment_Update_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_ConditionArgs = {
  where: Stock_Condition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Condition_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_StatusArgs = {
  where: Stock_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Status_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Stock_Sync_LogArgs = {
  where: Stock_Sync_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stock_Sync_Log_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_WarehouseArgs = {
  where: Warehouse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Warehouse_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_ChangeArgs = {
  where: Wms_Stock_Change_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_InputArgs = {
  where: Wms_Stock_Change_Input_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_Input_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_OutputArgs = {
  where: Wms_Stock_Change_Output_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_Output_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_TypeArgs = {
  where: Wms_Stock_Change_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Wms_Stock_Change_Type_By_PkArgs = {
  id: Scalars['Int'];
};


/** mutation root */
export type Mutation_RootInsert_AddressArgs = {
  objects: Array<Address_Insert_Input>;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Address_OneArgs = {
  object: Address_Insert_Input;
  on_conflict?: Maybe<Address_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AttachmentArgs = {
  objects: Array<Attachment_Insert_Input>;
  on_conflict?: Maybe<Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Attachment_OneArgs = {
  object: Attachment_Insert_Input;
  on_conflict?: Maybe<Attachment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_OrderArgs = {
  objects: Array<Client_Order_Insert_Input>;
  on_conflict?: Maybe<Client_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Client_Order_OneArgs = {
  object: Client_Order_Insert_Input;
  on_conflict?: Maybe<Client_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CountryArgs = {
  objects: Array<Country_Insert_Input>;
  on_conflict?: Maybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Country_OneArgs = {
  object: Country_Insert_Input;
  on_conflict?: Maybe<Country_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_DatabasechangelogArgs = {
  objects: Array<Databasechangelog_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Databasechangelog_OneArgs = {
  object: Databasechangelog_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_DatabasechangeloglockArgs = {
  objects: Array<Databasechangeloglock_Insert_Input>;
  on_conflict?: Maybe<Databasechangeloglock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Databasechangeloglock_OneArgs = {
  object: Databasechangeloglock_Insert_Input;
  on_conflict?: Maybe<Databasechangeloglock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FolderArgs = {
  objects: Array<Folder_Insert_Input>;
  on_conflict?: Maybe<Folder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Folder_OneArgs = {
  object: Folder_Insert_Input;
  on_conflict?: Maybe<Folder_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_ManifestArgs = {
  objects: Array<Inbound_Manifest_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Fulfilment_LineArgs = {
  objects: Array<Inbound_Manifest_Fulfilment_Line_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Fulfilment_Line_OneArgs = {
  object: Inbound_Manifest_Fulfilment_Line_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Fulfilment_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_LineArgs = {
  objects: Array<Inbound_Manifest_Line_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Line_OneArgs = {
  object: Inbound_Manifest_Line_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_OneArgs = {
  object: Inbound_Manifest_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_StatusArgs = {
  objects: Array<Inbound_Manifest_Status_Insert_Input>;
  on_conflict?: Maybe<Inbound_Manifest_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Inbound_Manifest_Status_OneArgs = {
  object: Inbound_Manifest_Status_Insert_Input;
  on_conflict?: Maybe<Inbound_Manifest_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logistics_OrderArgs = {
  objects: Array<Logistics_Order_Insert_Input>;
  on_conflict?: Maybe<Logistics_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logistics_Order_OneArgs = {
  object: Logistics_Order_Insert_Input;
  on_conflict?: Maybe<Logistics_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logistics_Order_StatusArgs = {
  objects: Array<Logistics_Order_Status_Insert_Input>;
  on_conflict?: Maybe<Logistics_Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Logistics_Order_Status_OneArgs = {
  object: Logistics_Order_Status_Insert_Input;
  on_conflict?: Maybe<Logistics_Order_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_OrganisationArgs = {
  objects: Array<Organisation_Insert_Input>;
  on_conflict?: Maybe<Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Organisation_OneArgs = {
  object: Organisation_Insert_Input;
  on_conflict?: Maybe<Organisation_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_ManifestArgs = {
  objects: Array<Outbound_Manifest_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_LineArgs = {
  objects: Array<Outbound_Manifest_Fulfilment_Line_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Fulfilment_Line_OneArgs = {
  object: Outbound_Manifest_Fulfilment_Line_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_LineArgs = {
  objects: Array<Outbound_Manifest_Line_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Line_OneArgs = {
  object: Outbound_Manifest_Line_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_OneArgs = {
  object: Outbound_Manifest_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_StatusArgs = {
  objects: Array<Outbound_Manifest_Status_Insert_Input>;
  on_conflict?: Maybe<Outbound_Manifest_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Outbound_Manifest_Status_OneArgs = {
  object: Outbound_Manifest_Status_Insert_Input;
  on_conflict?: Maybe<Outbound_Manifest_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ProductArgs = {
  objects: Array<Product_Insert_Input>;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_OneArgs = {
  object: Product_Insert_Input;
  on_conflict?: Maybe<Product_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_StockArgs = {
  objects: Array<Product_Stock_Insert_Input>;
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Product_Stock_OneArgs = {
  object: Product_Stock_Insert_Input;
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_OrderArgs = {
  objects: Array<Purchase_Order_Insert_Input>;
  on_conflict?: Maybe<Purchase_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Purchase_Order_OneArgs = {
  object: Purchase_Order_Insert_Input;
  on_conflict?: Maybe<Purchase_Order_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_AccountArgs = {
  objects: Array<Service_Account_Insert_Input>;
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Account_OneArgs = {
  object: Service_Account_Insert_Input;
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ShipmentArgs = {
  objects: Array<Shipment_Insert_Input>;
  on_conflict?: Maybe<Shipment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_OneArgs = {
  object: Shipment_Insert_Input;
  on_conflict?: Maybe<Shipment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_StatusArgs = {
  objects: Array<Shipment_Status_Insert_Input>;
  on_conflict?: Maybe<Shipment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_Status_OneArgs = {
  object: Shipment_Status_Insert_Input;
  on_conflict?: Maybe<Shipment_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_UpdateArgs = {
  objects: Array<Shipment_Update_Insert_Input>;
  on_conflict?: Maybe<Shipment_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_Update_OneArgs = {
  object: Shipment_Update_Insert_Input;
  on_conflict?: Maybe<Shipment_Update_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_Update_TypeArgs = {
  objects: Array<Shipment_Update_Type_Insert_Input>;
  on_conflict?: Maybe<Shipment_Update_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Shipment_Update_Type_OneArgs = {
  object: Shipment_Update_Type_Insert_Input;
  on_conflict?: Maybe<Shipment_Update_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_ConditionArgs = {
  objects: Array<Stock_Condition_Insert_Input>;
  on_conflict?: Maybe<Stock_Condition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Condition_OneArgs = {
  object: Stock_Condition_Insert_Input;
  on_conflict?: Maybe<Stock_Condition_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_StatusArgs = {
  objects: Array<Stock_Status_Insert_Input>;
  on_conflict?: Maybe<Stock_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Status_OneArgs = {
  object: Stock_Status_Insert_Input;
  on_conflict?: Maybe<Stock_Status_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Sync_LogArgs = {
  objects: Array<Stock_Sync_Log_Insert_Input>;
  on_conflict?: Maybe<Stock_Sync_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stock_Sync_Log_OneArgs = {
  object: Stock_Sync_Log_Insert_Input;
  on_conflict?: Maybe<Stock_Sync_Log_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_WarehouseArgs = {
  objects: Array<Warehouse_Insert_Input>;
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Warehouse_OneArgs = {
  object: Warehouse_Insert_Input;
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_ChangeArgs = {
  objects: Array<Wms_Stock_Change_Insert_Input>;
  on_conflict?: Maybe<Wms_Stock_Change_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_InputArgs = {
  objects: Array<Wms_Stock_Change_Input_Insert_Input>;
  on_conflict?: Maybe<Wms_Stock_Change_Input_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_Input_OneArgs = {
  object: Wms_Stock_Change_Input_Insert_Input;
  on_conflict?: Maybe<Wms_Stock_Change_Input_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_OneArgs = {
  object: Wms_Stock_Change_Insert_Input;
  on_conflict?: Maybe<Wms_Stock_Change_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_OutputArgs = {
  objects: Array<Wms_Stock_Change_Output_Insert_Input>;
  on_conflict?: Maybe<Wms_Stock_Change_Output_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_Output_OneArgs = {
  object: Wms_Stock_Change_Output_Insert_Input;
  on_conflict?: Maybe<Wms_Stock_Change_Output_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_TypeArgs = {
  objects: Array<Wms_Stock_Change_Type_Insert_Input>;
  on_conflict?: Maybe<Wms_Stock_Change_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Wms_Stock_Change_Type_OneArgs = {
  object: Wms_Stock_Change_Type_Insert_Input;
  on_conflict?: Maybe<Wms_Stock_Change_Type_On_Conflict>;
};


/** mutation root */
export type Mutation_RootUpdate_AddressArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  where: Address_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Address_By_PkArgs = {
  _inc?: Maybe<Address_Inc_Input>;
  _set?: Maybe<Address_Set_Input>;
  pk_columns: Address_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Address_ManyArgs = {
  updates: Array<Address_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AttachmentArgs = {
  _append?: Maybe<Attachment_Append_Input>;
  _delete_at_path?: Maybe<Attachment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Attachment_Delete_Elem_Input>;
  _delete_key?: Maybe<Attachment_Delete_Key_Input>;
  _inc?: Maybe<Attachment_Inc_Input>;
  _prepend?: Maybe<Attachment_Prepend_Input>;
  _set?: Maybe<Attachment_Set_Input>;
  where: Attachment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_By_PkArgs = {
  _append?: Maybe<Attachment_Append_Input>;
  _delete_at_path?: Maybe<Attachment_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Attachment_Delete_Elem_Input>;
  _delete_key?: Maybe<Attachment_Delete_Key_Input>;
  _inc?: Maybe<Attachment_Inc_Input>;
  _prepend?: Maybe<Attachment_Prepend_Input>;
  _set?: Maybe<Attachment_Set_Input>;
  pk_columns: Attachment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Attachment_ManyArgs = {
  updates: Array<Attachment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Client_OrderArgs = {
  _inc?: Maybe<Client_Order_Inc_Input>;
  _set?: Maybe<Client_Order_Set_Input>;
  where: Client_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Order_By_PkArgs = {
  _inc?: Maybe<Client_Order_Inc_Input>;
  _set?: Maybe<Client_Order_Set_Input>;
  pk_columns: Client_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Client_Order_ManyArgs = {
  updates: Array<Client_Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CountryArgs = {
  _set?: Maybe<Country_Set_Input>;
  where: Country_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Country_By_PkArgs = {
  _set?: Maybe<Country_Set_Input>;
  pk_columns: Country_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Country_ManyArgs = {
  updates: Array<Country_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DatabasechangelogArgs = {
  _inc?: Maybe<Databasechangelog_Inc_Input>;
  _set?: Maybe<Databasechangelog_Set_Input>;
  where: Databasechangelog_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Databasechangelog_ManyArgs = {
  updates: Array<Databasechangelog_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_DatabasechangeloglockArgs = {
  _inc?: Maybe<Databasechangeloglock_Inc_Input>;
  _set?: Maybe<Databasechangeloglock_Set_Input>;
  where: Databasechangeloglock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Databasechangeloglock_By_PkArgs = {
  _inc?: Maybe<Databasechangeloglock_Inc_Input>;
  _set?: Maybe<Databasechangeloglock_Set_Input>;
  pk_columns: Databasechangeloglock_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Databasechangeloglock_ManyArgs = {
  updates: Array<Databasechangeloglock_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FolderArgs = {
  _set?: Maybe<Folder_Set_Input>;
  where: Folder_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Folder_By_PkArgs = {
  _set?: Maybe<Folder_Set_Input>;
  pk_columns: Folder_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Folder_ManyArgs = {
  updates: Array<Folder_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_ManifestArgs = {
  _inc?: Maybe<Inbound_Manifest_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Set_Input>;
  where: Inbound_Manifest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Set_Input>;
  pk_columns: Inbound_Manifest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Fulfilment_LineArgs = {
  _inc?: Maybe<Inbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Fulfilment_Line_Set_Input>;
  where: Inbound_Manifest_Fulfilment_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Fulfilment_Line_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Fulfilment_Line_Set_Input>;
  pk_columns: Inbound_Manifest_Fulfilment_Line_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Fulfilment_Line_ManyArgs = {
  updates: Array<Inbound_Manifest_Fulfilment_Line_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_LineArgs = {
  _inc?: Maybe<Inbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Line_Set_Input>;
  where: Inbound_Manifest_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Line_Set_Input>;
  pk_columns: Inbound_Manifest_Line_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Line_ManyArgs = {
  updates: Array<Inbound_Manifest_Line_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_ManyArgs = {
  updates: Array<Inbound_Manifest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_StatusArgs = {
  _inc?: Maybe<Inbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Status_Set_Input>;
  where: Inbound_Manifest_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Status_By_PkArgs = {
  _inc?: Maybe<Inbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Inbound_Manifest_Status_Set_Input>;
  pk_columns: Inbound_Manifest_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Inbound_Manifest_Status_ManyArgs = {
  updates: Array<Inbound_Manifest_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Logistics_OrderArgs = {
  _inc?: Maybe<Logistics_Order_Inc_Input>;
  _set?: Maybe<Logistics_Order_Set_Input>;
  where: Logistics_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Logistics_Order_By_PkArgs = {
  _inc?: Maybe<Logistics_Order_Inc_Input>;
  _set?: Maybe<Logistics_Order_Set_Input>;
  pk_columns: Logistics_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Logistics_Order_ManyArgs = {
  updates: Array<Logistics_Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Logistics_Order_StatusArgs = {
  _inc?: Maybe<Logistics_Order_Status_Inc_Input>;
  _set?: Maybe<Logistics_Order_Status_Set_Input>;
  where: Logistics_Order_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Logistics_Order_Status_By_PkArgs = {
  _inc?: Maybe<Logistics_Order_Status_Inc_Input>;
  _set?: Maybe<Logistics_Order_Status_Set_Input>;
  pk_columns: Logistics_Order_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Logistics_Order_Status_ManyArgs = {
  updates: Array<Logistics_Order_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_OrganisationArgs = {
  _inc?: Maybe<Organisation_Inc_Input>;
  _set?: Maybe<Organisation_Set_Input>;
  where: Organisation_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Organisation_By_PkArgs = {
  _inc?: Maybe<Organisation_Inc_Input>;
  _set?: Maybe<Organisation_Set_Input>;
  pk_columns: Organisation_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Organisation_ManyArgs = {
  updates: Array<Organisation_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_ManifestArgs = {
  _inc?: Maybe<Outbound_Manifest_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Set_Input>;
  where: Outbound_Manifest_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Set_Input>;
  pk_columns: Outbound_Manifest_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_LineArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Set_Input>;
  where: Outbound_Manifest_Fulfilment_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Set_Input>;
  pk_columns: Outbound_Manifest_Fulfilment_Line_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Fulfilment_Line_ManyArgs = {
  updates: Array<Outbound_Manifest_Fulfilment_Line_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_LineArgs = {
  _inc?: Maybe<Outbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Line_Set_Input>;
  where: Outbound_Manifest_Line_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Line_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Line_Set_Input>;
  pk_columns: Outbound_Manifest_Line_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Line_ManyArgs = {
  updates: Array<Outbound_Manifest_Line_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_ManyArgs = {
  updates: Array<Outbound_Manifest_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_StatusArgs = {
  _inc?: Maybe<Outbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Status_Set_Input>;
  where: Outbound_Manifest_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Status_By_PkArgs = {
  _inc?: Maybe<Outbound_Manifest_Status_Inc_Input>;
  _set?: Maybe<Outbound_Manifest_Status_Set_Input>;
  pk_columns: Outbound_Manifest_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Outbound_Manifest_Status_ManyArgs = {
  updates: Array<Outbound_Manifest_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ProductArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  where: Product_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_By_PkArgs = {
  _inc?: Maybe<Product_Inc_Input>;
  _set?: Maybe<Product_Set_Input>;
  pk_columns: Product_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_ManyArgs = {
  updates: Array<Product_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Product_StockArgs = {
  _inc?: Maybe<Product_Stock_Inc_Input>;
  _set?: Maybe<Product_Stock_Set_Input>;
  where: Product_Stock_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Stock_By_PkArgs = {
  _inc?: Maybe<Product_Stock_Inc_Input>;
  _set?: Maybe<Product_Stock_Set_Input>;
  pk_columns: Product_Stock_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Product_Stock_ManyArgs = {
  updates: Array<Product_Stock_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_OrderArgs = {
  _inc?: Maybe<Purchase_Order_Inc_Input>;
  _set?: Maybe<Purchase_Order_Set_Input>;
  where: Purchase_Order_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_Order_By_PkArgs = {
  _inc?: Maybe<Purchase_Order_Inc_Input>;
  _set?: Maybe<Purchase_Order_Set_Input>;
  pk_columns: Purchase_Order_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Purchase_Order_ManyArgs = {
  updates: Array<Purchase_Order_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_AccountArgs = {
  _inc?: Maybe<Service_Account_Inc_Input>;
  _set?: Maybe<Service_Account_Set_Input>;
  where: Service_Account_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Account_By_PkArgs = {
  _inc?: Maybe<Service_Account_Inc_Input>;
  _set?: Maybe<Service_Account_Set_Input>;
  pk_columns: Service_Account_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Account_ManyArgs = {
  updates: Array<Service_Account_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ShipmentArgs = {
  _inc?: Maybe<Shipment_Inc_Input>;
  _set?: Maybe<Shipment_Set_Input>;
  where: Shipment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_By_PkArgs = {
  _inc?: Maybe<Shipment_Inc_Input>;
  _set?: Maybe<Shipment_Set_Input>;
  pk_columns: Shipment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_ManyArgs = {
  updates: Array<Shipment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_StatusArgs = {
  _inc?: Maybe<Shipment_Status_Inc_Input>;
  _set?: Maybe<Shipment_Status_Set_Input>;
  where: Shipment_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Status_By_PkArgs = {
  _inc?: Maybe<Shipment_Status_Inc_Input>;
  _set?: Maybe<Shipment_Status_Set_Input>;
  pk_columns: Shipment_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Status_ManyArgs = {
  updates: Array<Shipment_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_UpdateArgs = {
  _inc?: Maybe<Shipment_Update_Inc_Input>;
  _set?: Maybe<Shipment_Update_Set_Input>;
  where: Shipment_Update_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Update_By_PkArgs = {
  _inc?: Maybe<Shipment_Update_Inc_Input>;
  _set?: Maybe<Shipment_Update_Set_Input>;
  pk_columns: Shipment_Update_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Update_ManyArgs = {
  updates: Array<Shipment_Update_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Update_TypeArgs = {
  _inc?: Maybe<Shipment_Update_Type_Inc_Input>;
  _set?: Maybe<Shipment_Update_Type_Set_Input>;
  where: Shipment_Update_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Update_Type_By_PkArgs = {
  _inc?: Maybe<Shipment_Update_Type_Inc_Input>;
  _set?: Maybe<Shipment_Update_Type_Set_Input>;
  pk_columns: Shipment_Update_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Shipment_Update_Type_ManyArgs = {
  updates: Array<Shipment_Update_Type_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_ConditionArgs = {
  _inc?: Maybe<Stock_Condition_Inc_Input>;
  _set?: Maybe<Stock_Condition_Set_Input>;
  where: Stock_Condition_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Condition_By_PkArgs = {
  _inc?: Maybe<Stock_Condition_Inc_Input>;
  _set?: Maybe<Stock_Condition_Set_Input>;
  pk_columns: Stock_Condition_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Condition_ManyArgs = {
  updates: Array<Stock_Condition_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_StatusArgs = {
  _inc?: Maybe<Stock_Status_Inc_Input>;
  _set?: Maybe<Stock_Status_Set_Input>;
  where: Stock_Status_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Status_By_PkArgs = {
  _inc?: Maybe<Stock_Status_Inc_Input>;
  _set?: Maybe<Stock_Status_Set_Input>;
  pk_columns: Stock_Status_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Status_ManyArgs = {
  updates: Array<Stock_Status_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Sync_LogArgs = {
  _inc?: Maybe<Stock_Sync_Log_Inc_Input>;
  _set?: Maybe<Stock_Sync_Log_Set_Input>;
  where: Stock_Sync_Log_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Sync_Log_By_PkArgs = {
  _inc?: Maybe<Stock_Sync_Log_Inc_Input>;
  _set?: Maybe<Stock_Sync_Log_Set_Input>;
  pk_columns: Stock_Sync_Log_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stock_Sync_Log_ManyArgs = {
  updates: Array<Stock_Sync_Log_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_WarehouseArgs = {
  _inc?: Maybe<Warehouse_Inc_Input>;
  _set?: Maybe<Warehouse_Set_Input>;
  where: Warehouse_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Warehouse_By_PkArgs = {
  _inc?: Maybe<Warehouse_Inc_Input>;
  _set?: Maybe<Warehouse_Set_Input>;
  pk_columns: Warehouse_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Warehouse_ManyArgs = {
  updates: Array<Warehouse_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_ChangeArgs = {
  _inc?: Maybe<Wms_Stock_Change_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Set_Input>;
  where: Wms_Stock_Change_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_By_PkArgs = {
  _inc?: Maybe<Wms_Stock_Change_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Set_Input>;
  pk_columns: Wms_Stock_Change_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_InputArgs = {
  _inc?: Maybe<Wms_Stock_Change_Input_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Input_Set_Input>;
  where: Wms_Stock_Change_Input_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_Input_By_PkArgs = {
  _inc?: Maybe<Wms_Stock_Change_Input_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Input_Set_Input>;
  pk_columns: Wms_Stock_Change_Input_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_Input_ManyArgs = {
  updates: Array<Wms_Stock_Change_Input_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_ManyArgs = {
  updates: Array<Wms_Stock_Change_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_OutputArgs = {
  _inc?: Maybe<Wms_Stock_Change_Output_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Output_Set_Input>;
  where: Wms_Stock_Change_Output_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_Output_By_PkArgs = {
  _inc?: Maybe<Wms_Stock_Change_Output_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Output_Set_Input>;
  pk_columns: Wms_Stock_Change_Output_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_Output_ManyArgs = {
  updates: Array<Wms_Stock_Change_Output_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_TypeArgs = {
  _inc?: Maybe<Wms_Stock_Change_Type_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Type_Set_Input>;
  where: Wms_Stock_Change_Type_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_Type_By_PkArgs = {
  _inc?: Maybe<Wms_Stock_Change_Type_Inc_Input>;
  _set?: Maybe<Wms_Stock_Change_Type_Set_Input>;
  pk_columns: Wms_Stock_Change_Type_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Wms_Stock_Change_Type_ManyArgs = {
  updates: Array<Wms_Stock_Change_Type_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "organisation" */
export type Organisation = {
  __typename?: 'organisation';
  /** An array relationship */
  client_orders: Array<Client_Order>;
  /** An aggregate relationship */
  client_orders_aggregate: Client_Order_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_organisation_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  /** An array relationship */
  logistics_orders: Array<Logistics_Order>;
  /** An aggregate relationship */
  logistics_orders_aggregate: Logistics_Order_Aggregate;
  organisation_name: Scalars['String'];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An array relationship */
  purchase_orders: Array<Purchase_Order>;
  /** An aggregate relationship */
  purchase_orders_aggregate: Purchase_Order_Aggregate;
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  warehouses: Array<Warehouse>;
  /** An aggregate relationship */
  warehouses_aggregate: Warehouse_Aggregate;
  /** An array relationship */
  wms_stock_change_outputs: Array<Wms_Stock_Change_Output>;
  /** An aggregate relationship */
  wms_stock_change_outputs_aggregate: Wms_Stock_Change_Output_Aggregate;
};


/** columns and relationships of "organisation" */
export type OrganisationClient_OrdersArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationClient_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationLogistics_OrdersArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationLogistics_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationPurchase_OrdersArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationPurchase_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationWarehousesArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationWarehouses_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationWms_Stock_Change_OutputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


/** columns and relationships of "organisation" */
export type OrganisationWms_Stock_Change_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** aggregated selection of "organisation" */
export type Organisation_Aggregate = {
  __typename?: 'organisation_aggregate';
  aggregate?: Maybe<Organisation_Aggregate_Fields>;
  nodes: Array<Organisation>;
};

export type Organisation_Aggregate_Bool_Exp = {
  count?: Maybe<Organisation_Aggregate_Bool_Exp_Count>;
};

export type Organisation_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Organisation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Organisation_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "organisation" */
export type Organisation_Aggregate_Fields = {
  __typename?: 'organisation_aggregate_fields';
  avg?: Maybe<Organisation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Organisation_Max_Fields>;
  min?: Maybe<Organisation_Min_Fields>;
  stddev?: Maybe<Organisation_Stddev_Fields>;
  stddev_pop?: Maybe<Organisation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Organisation_Stddev_Samp_Fields>;
  sum?: Maybe<Organisation_Sum_Fields>;
  var_pop?: Maybe<Organisation_Var_Pop_Fields>;
  var_samp?: Maybe<Organisation_Var_Samp_Fields>;
  variance?: Maybe<Organisation_Variance_Fields>;
};


/** aggregate fields of "organisation" */
export type Organisation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Organisation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "organisation" */
export type Organisation_Aggregate_Order_By = {
  avg?: Maybe<Organisation_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Organisation_Max_Order_By>;
  min?: Maybe<Organisation_Min_Order_By>;
  stddev?: Maybe<Organisation_Stddev_Order_By>;
  stddev_pop?: Maybe<Organisation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Organisation_Stddev_Samp_Order_By>;
  sum?: Maybe<Organisation_Sum_Order_By>;
  var_pop?: Maybe<Organisation_Var_Pop_Order_By>;
  var_samp?: Maybe<Organisation_Var_Samp_Order_By>;
  variance?: Maybe<Organisation_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "organisation" */
export type Organisation_Arr_Rel_Insert_Input = {
  data: Array<Organisation_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Organisation_On_Conflict>;
};

/** aggregate avg on columns */
export type Organisation_Avg_Fields = {
  __typename?: 'organisation_avg_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "organisation" */
export type Organisation_Avg_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "organisation". All fields are combined with a logical 'AND'. */
export type Organisation_Bool_Exp = {
  _and?: Maybe<Array<Organisation_Bool_Exp>>;
  _not?: Maybe<Organisation_Bool_Exp>;
  _or?: Maybe<Array<Organisation_Bool_Exp>>;
  client_orders?: Maybe<Client_Order_Bool_Exp>;
  client_orders_aggregate?: Maybe<Client_Order_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_organisation_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  logistics_orders?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Bool_Exp>;
  organisation_name?: Maybe<String_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  purchase_orders?: Maybe<Purchase_Order_Bool_Exp>;
  purchase_orders_aggregate?: Maybe<Purchase_Order_Aggregate_Bool_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warehouses?: Maybe<Warehouse_Bool_Exp>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Bool_Exp>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "organisation" */
export enum Organisation_Constraint {
  /** unique or primary key constraint on columns "organisation_name" */
  AccountAccountNameKey = 'account_account_name_key',
  /** unique or primary key constraint on columns "id" */
  AccountPkey = 'account_pkey'
}

/** input type for incrementing numeric columns in table "organisation" */
export type Organisation_Inc_Input = {
  external_organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "organisation" */
export type Organisation_Insert_Input = {
  client_orders?: Maybe<Client_Order_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_organisation_id?: Maybe<Scalars['Int']>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  logistics_orders?: Maybe<Logistics_Order_Arr_Rel_Insert_Input>;
  organisation_name?: Maybe<Scalars['String']>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  purchase_orders?: Maybe<Purchase_Order_Arr_Rel_Insert_Input>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouses?: Maybe<Warehouse_Arr_Rel_Insert_Input>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Organisation_Max_Fields = {
  __typename?: 'organisation_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_organisation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "organisation" */
export type Organisation_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Organisation_Min_Fields = {
  __typename?: 'organisation_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_organisation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "organisation" */
export type Organisation_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "organisation" */
export type Organisation_Mutation_Response = {
  __typename?: 'organisation_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Organisation>;
};

/** input type for inserting object relation for remote table "organisation" */
export type Organisation_Obj_Rel_Insert_Input = {
  data: Organisation_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Organisation_On_Conflict>;
};

/** on_conflict condition type for table "organisation" */
export type Organisation_On_Conflict = {
  constraint: Organisation_Constraint;
  update_columns?: Array<Organisation_Update_Column>;
  where?: Maybe<Organisation_Bool_Exp>;
};

/** Ordering options when selecting data from "organisation". */
export type Organisation_Order_By = {
  client_orders_aggregate?: Maybe<Client_Order_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Order_By>;
  organisation_name?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  purchase_orders_aggregate?: Maybe<Purchase_Order_Aggregate_Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Order_By>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Order_By>;
};

/** primary key columns input for table: organisation */
export type Organisation_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "organisation" */
export enum Organisation_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalOrganisationId = 'external_organisation_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationName = 'organisation_name',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "organisation" */
export type Organisation_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_organisation_id?: Maybe<Scalars['Int']>;
  organisation_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Organisation_Stddev_Fields = {
  __typename?: 'organisation_stddev_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "organisation" */
export type Organisation_Stddev_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Organisation_Stddev_Pop_Fields = {
  __typename?: 'organisation_stddev_pop_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "organisation" */
export type Organisation_Stddev_Pop_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Organisation_Stddev_Samp_Fields = {
  __typename?: 'organisation_stddev_samp_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "organisation" */
export type Organisation_Stddev_Samp_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "organisation" */
export type Organisation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Organisation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Organisation_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  external_organisation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Organisation_Sum_Fields = {
  __typename?: 'organisation_sum_fields';
  external_organisation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "organisation" */
export type Organisation_Sum_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** update columns of table "organisation" */
export enum Organisation_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalOrganisationId = 'external_organisation_id',
  /** column name */
  OrganisationName = 'organisation_name',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Organisation_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Organisation_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Organisation_Set_Input>;
  /** filter the rows which have to be updated */
  where: Organisation_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Organisation_Var_Pop_Fields = {
  __typename?: 'organisation_var_pop_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "organisation" */
export type Organisation_Var_Pop_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Organisation_Var_Samp_Fields = {
  __typename?: 'organisation_var_samp_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "organisation" */
export type Organisation_Var_Samp_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Organisation_Variance_Fields = {
  __typename?: 'organisation_variance_fields';
  external_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "organisation" */
export type Organisation_Variance_Order_By = {
  external_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "outbound_manifest" */
export type Outbound_Manifest = {
  __typename?: 'outbound_manifest';
  /** An object relationship */
  address: Address;
  created_at: Scalars['timestamptz'];
  expected_dispatch: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['Int'];
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
  /** An object relationship */
  outbound_manifest_status: Outbound_Manifest_Status;
  outbound_manifest_status_id: Scalars['Int'];
  to_address_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  warehouse: Warehouse;
  warehouse_id: Scalars['Int'];
};


/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "outbound_manifest" */
export type Outbound_ManifestOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest" */
export type Outbound_Manifest_Aggregate = {
  __typename?: 'outbound_manifest_aggregate';
  aggregate?: Maybe<Outbound_Manifest_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest>;
};

export type Outbound_Manifest_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Outbound_Manifest_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest" */
export type Outbound_Manifest_Aggregate_Fields = {
  __typename?: 'outbound_manifest_aggregate_fields';
  avg?: Maybe<Outbound_Manifest_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outbound_Manifest_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Variance_Fields>;
};


/** aggregate fields of "outbound_manifest" */
export type Outbound_Manifest_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outbound_manifest" */
export type Outbound_Manifest_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest" */
export type Outbound_Manifest_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Avg_Fields = {
  __typename?: 'outbound_manifest_avg_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Avg_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_dispatch?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
  outbound_manifest_status_id?: Maybe<Int_Comparison_Exp>;
  to_address_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warehouse?: Maybe<Warehouse_Bool_Exp>;
  warehouse_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest" */
export enum Outbound_Manifest_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestPkey = 'outbound_manifest_pkey'
}

/** columns and relationships of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line = {
  __typename?: 'outbound_manifest_fulfilment_line';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  outbound_manifest_line: Outbound_Manifest_Line;
  outbound_manifest_line_id: Scalars['Int'];
  /** An object relationship */
  product_stock: Product_Stock;
  quantity: Scalars['Int'];
  source_product_stock_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate = {
  __typename?: 'outbound_manifest_fulfilment_line_aggregate';
  aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Fulfilment_Line>;
};

export type Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp = {
  count?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_aggregate_fields';
  avg?: Maybe<Outbound_Manifest_Fulfilment_Line_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outbound_Manifest_Fulfilment_Line_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Line_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Line_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Line_Variance_Fields>;
};


/** aggregate fields of "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Fulfilment_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Fulfilment_Line_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Fulfilment_Line_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Fulfilment_Line_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Fulfilment_Line_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Fulfilment_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Fulfilment_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Fulfilment_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Fulfilment_Line_Avg_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_avg_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Avg_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_fulfilment_line". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Fulfilment_Line_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_line_id?: Maybe<Int_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_fulfilment_line" */
export enum Outbound_Manifest_Fulfilment_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestFulfilmentLinePkey = 'outbound_manifest_fulfilment_line_pkey'
}

/** input type for incrementing numeric columns in table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Obj_Rel_Insert_Input>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Fulfilment_Line_Max_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Fulfilment_Line_Min_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Mutation_Response = {
  __typename?: 'outbound_manifest_fulfilment_line_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Fulfilment_Line>;
};

/** on_conflict condition type for table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_On_Conflict = {
  constraint: Outbound_Manifest_Fulfilment_Line_Constraint;
  update_columns?: Array<Outbound_Manifest_Fulfilment_Line_Update_Column>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_fulfilment_line". */
export type Outbound_Manifest_Fulfilment_Line_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_line?: Maybe<Outbound_Manifest_Line_Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_fulfilment_line */
export type Outbound_Manifest_Fulfilment_Line_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outbound_manifest_fulfilment_line" */
export enum Outbound_Manifest_Fulfilment_Line_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OutboundManifestLineId = 'outbound_manifest_line_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SourceProductStockId = 'source_product_stock_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Fulfilment_Line_Sum_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_sum_fields';
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_line_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Sum_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_fulfilment_line" */
export enum Outbound_Manifest_Fulfilment_Line_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OutboundManifestLineId = 'outbound_manifest_line_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SourceProductStockId = 'source_product_stock_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Outbound_Manifest_Fulfilment_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Fulfilment_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Fulfilment_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Fulfilment_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Fulfilment_Line_Var_Pop_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Fulfilment_Line_Var_Samp_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Fulfilment_Line_Variance_Fields = {
  __typename?: 'outbound_manifest_fulfilment_line_variance_fields';
  id?: Maybe<Scalars['Float']>;
  outbound_manifest_line_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outbound_manifest_fulfilment_line" */
export type Outbound_Manifest_Fulfilment_Line_Variance_Order_By = {
  id?: Maybe<Order_By>;
  outbound_manifest_line_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
};

/** input type for incrementing numeric columns in table "outbound_manifest" */
export type Outbound_Manifest_Inc_Input = {
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "outbound_manifest" */
export type Outbound_Manifest_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_dispatch?: Maybe<Scalars['timestamptz']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Obj_Rel_Insert_Input>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse?: Maybe<Warehouse_Obj_Rel_Insert_Input>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "outbound_manifest_line" */
export type Outbound_Manifest_Line = {
  __typename?: 'outbound_manifest_line';
  cancelled_quantity: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  dispatched_quantity: Scalars['Int'];
  id: Scalars['Int'];
  is_fragile: Scalars['Boolean'];
  /** An object relationship */
  logistics_order: Logistics_Order;
  logistics_order_id: Scalars['Int'];
  /** An object relationship */
  outbound_manifest: Outbound_Manifest;
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  outbound_manifest_id: Scalars['Int'];
  /** An object relationship */
  outbound_manifest_status: Outbound_Manifest_Status;
  outbound_manifest_status_id: Scalars['Int'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  quantity: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "outbound_manifest_line" */
export type Outbound_Manifest_LineOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


/** columns and relationships of "outbound_manifest_line" */
export type Outbound_Manifest_LineOutbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate = {
  __typename?: 'outbound_manifest_line_aggregate';
  aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Line>;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp_Count>;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And = {
  arguments: Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Outbound_Manifest_Line_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate_Fields = {
  __typename?: 'outbound_manifest_line_aggregate_fields';
  avg?: Maybe<Outbound_Manifest_Line_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outbound_Manifest_Line_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Line_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Line_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Line_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Line_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Line_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Line_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Line_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Line_Variance_Fields>;
};


/** aggregate fields of "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Aggregate_Order_By = {
  avg?: Maybe<Outbound_Manifest_Line_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Outbound_Manifest_Line_Max_Order_By>;
  min?: Maybe<Outbound_Manifest_Line_Min_Order_By>;
  stddev?: Maybe<Outbound_Manifest_Line_Stddev_Order_By>;
  stddev_pop?: Maybe<Outbound_Manifest_Line_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Outbound_Manifest_Line_Stddev_Samp_Order_By>;
  sum?: Maybe<Outbound_Manifest_Line_Sum_Order_By>;
  var_pop?: Maybe<Outbound_Manifest_Line_Var_Pop_Order_By>;
  var_samp?: Maybe<Outbound_Manifest_Line_Var_Samp_Order_By>;
  variance?: Maybe<Outbound_Manifest_Line_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Arr_Rel_Insert_Input = {
  data: Array<Outbound_Manifest_Line_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Line_Avg_Fields = {
  __typename?: 'outbound_manifest_line_avg_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Avg_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_line". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Line_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Line_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Line_Bool_Exp>>;
  cancelled_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  dispatched_quantity?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_fragile?: Maybe<Boolean_Comparison_Exp>;
  logistics_order?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_order_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  outbound_manifest_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
  outbound_manifest_status_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Constraint {
  /** unique or primary key constraint on columns "id" */
  OutboundManifestLinePkey = 'outbound_manifest_line_pkey'
}

/** input type for incrementing numeric columns in table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Inc_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Insert_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_fragile?: Maybe<Scalars['Boolean']>;
  logistics_order?: Maybe<Logistics_Order_Obj_Rel_Insert_Input>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest?: Maybe<Outbound_Manifest_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Obj_Rel_Insert_Input>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Line_Max_Fields = {
  __typename?: 'outbound_manifest_line_max_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Max_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Line_Min_Fields = {
  __typename?: 'outbound_manifest_line_min_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Min_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Mutation_Response = {
  __typename?: 'outbound_manifest_line_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Line>;
};

/** input type for inserting object relation for remote table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Line_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Line_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_line" */
export type Outbound_Manifest_Line_On_Conflict = {
  constraint: Outbound_Manifest_Line_Constraint;
  update_columns?: Array<Outbound_Manifest_Line_Update_Column>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_line". */
export type Outbound_Manifest_Line_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_fragile?: Maybe<Order_By>;
  logistics_order?: Maybe<Logistics_Order_Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest?: Maybe<Outbound_Manifest_Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_line */
export type Outbound_Manifest_Line_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Select_Column {
  /** column name */
  CancelledQuantity = 'cancelled_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatchedQuantity = 'dispatched_quantity',
  /** column name */
  Id = 'id',
  /** column name */
  IsFragile = 'is_fragile',
  /** column name */
  LogisticsOrderId = 'logistics_order_id',
  /** column name */
  OutboundManifestId = 'outbound_manifest_id',
  /** column name */
  OutboundManifestStatusId = 'outbound_manifest_status_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "outbound_manifest_line_aggregate_bool_exp_bool_and_arguments_columns" columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFragile = 'is_fragile'
}

/** select "outbound_manifest_line_aggregate_bool_exp_bool_or_arguments_columns" columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Select_Column_Outbound_Manifest_Line_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFragile = 'is_fragile'
}

/** input type for updating data in table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Set_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_fragile?: Maybe<Scalars['Boolean']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Line_Stddev_Fields = {
  __typename?: 'outbound_manifest_line_stddev_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stddev_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Line_Stddev_Pop_Fields = {
  __typename?: 'outbound_manifest_line_stddev_pop_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stddev_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Line_Stddev_Samp_Fields = {
  __typename?: 'outbound_manifest_line_stddev_samp_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stddev_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Line_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Line_Stream_Cursor_Value_Input = {
  cancelled_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  is_fragile?: Maybe<Scalars['Boolean']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Line_Sum_Fields = {
  __typename?: 'outbound_manifest_line_sum_fields';
  cancelled_quantity?: Maybe<Scalars['Int']>;
  dispatched_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  logistics_order_id?: Maybe<Scalars['Int']>;
  outbound_manifest_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Sum_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest_line" */
export enum Outbound_Manifest_Line_Update_Column {
  /** column name */
  CancelledQuantity = 'cancelled_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DispatchedQuantity = 'dispatched_quantity',
  /** column name */
  Id = 'id',
  /** column name */
  IsFragile = 'is_fragile',
  /** column name */
  LogisticsOrderId = 'logistics_order_id',
  /** column name */
  OutboundManifestId = 'outbound_manifest_id',
  /** column name */
  OutboundManifestStatusId = 'outbound_manifest_status_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Outbound_Manifest_Line_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Line_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Line_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Line_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Line_Var_Pop_Fields = {
  __typename?: 'outbound_manifest_line_var_pop_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Var_Pop_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Line_Var_Samp_Fields = {
  __typename?: 'outbound_manifest_line_var_samp_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Var_Samp_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Line_Variance_Fields = {
  __typename?: 'outbound_manifest_line_variance_fields';
  cancelled_quantity?: Maybe<Scalars['Float']>;
  dispatched_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  logistics_order_id?: Maybe<Scalars['Float']>;
  outbound_manifest_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outbound_manifest_line" */
export type Outbound_Manifest_Line_Variance_Order_By = {
  cancelled_quantity?: Maybe<Order_By>;
  dispatched_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_order_id?: Maybe<Order_By>;
  outbound_manifest_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Max_Fields = {
  __typename?: 'outbound_manifest_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_dispatch?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_dispatch?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Min_Fields = {
  __typename?: 'outbound_manifest_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_dispatch?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  expected_dispatch?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "outbound_manifest" */
export type Outbound_Manifest_Mutation_Response = {
  __typename?: 'outbound_manifest_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest>;
};

/** input type for inserting object relation for remote table "outbound_manifest" */
export type Outbound_Manifest_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest" */
export type Outbound_Manifest_On_Conflict = {
  constraint: Outbound_Manifest_Constraint;
  update_columns?: Array<Outbound_Manifest_Update_Column>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest". */
export type Outbound_Manifest_Order_By = {
  address?: Maybe<Address_Order_By>;
  created_at?: Maybe<Order_By>;
  expected_dispatch?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
  outbound_manifest_status?: Maybe<Outbound_Manifest_Status_Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse?: Maybe<Warehouse_Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest */
export type Outbound_Manifest_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outbound_manifest" */
export enum Outbound_Manifest_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedDispatch = 'expected_dispatch',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  OutboundManifestStatusId = 'outbound_manifest_status_id',
  /** column name */
  ToAddressId = 'to_address_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseId = 'warehouse_id'
}

/** input type for updating data in table "outbound_manifest" */
export type Outbound_Manifest_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_dispatch?: Maybe<Scalars['timestamptz']>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_Status = {
  __typename?: 'outbound_manifest_status';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
  outbound_manifest_status_name: Scalars['String'];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_StatusOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_StatusOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_StatusOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "outbound_manifest_status" */
export type Outbound_Manifest_StatusOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "outbound_manifest_status" */
export type Outbound_Manifest_Status_Aggregate = {
  __typename?: 'outbound_manifest_status_aggregate';
  aggregate?: Maybe<Outbound_Manifest_Status_Aggregate_Fields>;
  nodes: Array<Outbound_Manifest_Status>;
};

/** aggregate fields of "outbound_manifest_status" */
export type Outbound_Manifest_Status_Aggregate_Fields = {
  __typename?: 'outbound_manifest_status_aggregate_fields';
  avg?: Maybe<Outbound_Manifest_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Outbound_Manifest_Status_Max_Fields>;
  min?: Maybe<Outbound_Manifest_Status_Min_Fields>;
  stddev?: Maybe<Outbound_Manifest_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Outbound_Manifest_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Outbound_Manifest_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Outbound_Manifest_Status_Sum_Fields>;
  var_pop?: Maybe<Outbound_Manifest_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Outbound_Manifest_Status_Var_Samp_Fields>;
  variance?: Maybe<Outbound_Manifest_Status_Variance_Fields>;
};


/** aggregate fields of "outbound_manifest_status" */
export type Outbound_Manifest_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Outbound_Manifest_Status_Avg_Fields = {
  __typename?: 'outbound_manifest_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "outbound_manifest_status". All fields are combined with a logical 'AND'. */
export type Outbound_Manifest_Status_Bool_Exp = {
  _and?: Maybe<Array<Outbound_Manifest_Status_Bool_Exp>>;
  _not?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
  _or?: Maybe<Array<Outbound_Manifest_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
  outbound_manifest_status_name?: Maybe<String_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "outbound_manifest_status" */
export enum Outbound_Manifest_Status_Constraint {
  /** unique or primary key constraint on columns "outbound_manifest_status_name" */
  OutboundManifestStatusOutboundManifestStatusNameKey = 'outbound_manifest_status_outbound_manifest_status_name_key',
  /** unique or primary key constraint on columns "id" */
  OutboundManifestStatusPkey = 'outbound_manifest_status_pkey'
}

/** input type for incrementing numeric columns in table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
  outbound_manifest_status_name?: Maybe<Scalars['String']>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Outbound_Manifest_Status_Max_Fields = {
  __typename?: 'outbound_manifest_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Outbound_Manifest_Status_Min_Fields = {
  __typename?: 'outbound_manifest_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Mutation_Response = {
  __typename?: 'outbound_manifest_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Outbound_Manifest_Status>;
};

/** input type for inserting object relation for remote table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Obj_Rel_Insert_Input = {
  data: Outbound_Manifest_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Outbound_Manifest_Status_On_Conflict>;
};

/** on_conflict condition type for table "outbound_manifest_status" */
export type Outbound_Manifest_Status_On_Conflict = {
  constraint: Outbound_Manifest_Status_Constraint;
  update_columns?: Array<Outbound_Manifest_Status_Update_Column>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "outbound_manifest_status". */
export type Outbound_Manifest_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
  outbound_manifest_status_name?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: outbound_manifest_status */
export type Outbound_Manifest_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "outbound_manifest_status" */
export enum Outbound_Manifest_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OutboundManifestStatusName = 'outbound_manifest_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Status_Stddev_Fields = {
  __typename?: 'outbound_manifest_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Status_Stddev_Pop_Fields = {
  __typename?: 'outbound_manifest_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Status_Stddev_Samp_Fields = {
  __typename?: 'outbound_manifest_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "outbound_manifest_status" */
export type Outbound_Manifest_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Status_Sum_Fields = {
  __typename?: 'outbound_manifest_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "outbound_manifest_status" */
export enum Outbound_Manifest_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  OutboundManifestStatusName = 'outbound_manifest_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Outbound_Manifest_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Status_Var_Pop_Fields = {
  __typename?: 'outbound_manifest_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Status_Var_Samp_Fields = {
  __typename?: 'outbound_manifest_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Status_Variance_Fields = {
  __typename?: 'outbound_manifest_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Outbound_Manifest_Stddev_Fields = {
  __typename?: 'outbound_manifest_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Outbound_Manifest_Stddev_Pop_Fields = {
  __typename?: 'outbound_manifest_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Outbound_Manifest_Stddev_Samp_Fields = {
  __typename?: 'outbound_manifest_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "outbound_manifest" */
export type Outbound_Manifest_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Outbound_Manifest_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Outbound_Manifest_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_dispatch?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Outbound_Manifest_Sum_Fields = {
  __typename?: 'outbound_manifest_sum_fields';
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifest_status_id?: Maybe<Scalars['Int']>;
  to_address_id?: Maybe<Scalars['Int']>;
  warehouse_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Sum_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** update columns of table "outbound_manifest" */
export enum Outbound_Manifest_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedDispatch = 'expected_dispatch',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  OutboundManifestStatusId = 'outbound_manifest_status_id',
  /** column name */
  ToAddressId = 'to_address_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseId = 'warehouse_id'
}

export type Outbound_Manifest_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Outbound_Manifest_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Outbound_Manifest_Set_Input>;
  /** filter the rows which have to be updated */
  where: Outbound_Manifest_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Outbound_Manifest_Var_Pop_Fields = {
  __typename?: 'outbound_manifest_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Outbound_Manifest_Var_Samp_Fields = {
  __typename?: 'outbound_manifest_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Outbound_Manifest_Variance_Fields = {
  __typename?: 'outbound_manifest_variance_fields';
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  outbound_manifest_status_id?: Maybe<Scalars['Float']>;
  to_address_id?: Maybe<Scalars['Float']>;
  warehouse_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "outbound_manifest" */
export type Outbound_Manifest_Variance_Order_By = {
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifest_status_id?: Maybe<Order_By>;
  to_address_id?: Maybe<Order_By>;
  warehouse_id?: Maybe<Order_By>;
};

/** columns and relationships of "product" */
export type Product = {
  __typename?: 'product';
  created_at: Scalars['timestamptz'];
  external_product_id?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  /** An array relationship */
  inbound_manifest_lines: Array<Inbound_Manifest_Line>;
  /** An aggregate relationship */
  inbound_manifest_lines_aggregate: Inbound_Manifest_Line_Aggregate;
  /** An array relationship */
  logistics_orders: Array<Logistics_Order>;
  /** An aggregate relationship */
  logistics_orders_aggregate: Logistics_Order_Aggregate;
  /** An array relationship */
  outbound_manifest_lines: Array<Outbound_Manifest_Line>;
  /** An aggregate relationship */
  outbound_manifest_lines_aggregate: Outbound_Manifest_Line_Aggregate;
  product_name: Scalars['String'];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wms_stock_change_outputs: Array<Wms_Stock_Change_Output>;
  /** An aggregate relationship */
  wms_stock_change_outputs_aggregate: Wms_Stock_Change_Output_Aggregate;
};


/** columns and relationships of "product" */
export type ProductInbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductInbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductLogistics_OrdersArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductLogistics_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductOutbound_Manifest_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductOutbound_Manifest_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductWms_Stock_Change_OutputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


/** columns and relationships of "product" */
export type ProductWms_Stock_Change_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** aggregated selection of "product" */
export type Product_Aggregate = {
  __typename?: 'product_aggregate';
  aggregate?: Maybe<Product_Aggregate_Fields>;
  nodes: Array<Product>;
};

export type Product_Aggregate_Bool_Exp = {
  count?: Maybe<Product_Aggregate_Bool_Exp_Count>;
};

export type Product_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Product_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product" */
export type Product_Aggregate_Fields = {
  __typename?: 'product_aggregate_fields';
  avg?: Maybe<Product_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Max_Fields>;
  min?: Maybe<Product_Min_Fields>;
  stddev?: Maybe<Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Sum_Fields>;
  var_pop?: Maybe<Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Variance_Fields>;
};


/** aggregate fields of "product" */
export type Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product" */
export type Product_Aggregate_Order_By = {
  avg?: Maybe<Product_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Max_Order_By>;
  min?: Maybe<Product_Min_Order_By>;
  stddev?: Maybe<Product_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Sum_Order_By>;
  var_pop?: Maybe<Product_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Var_Samp_Order_By>;
  variance?: Maybe<Product_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product" */
export type Product_Arr_Rel_Insert_Input = {
  data: Array<Product_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Avg_Fields = {
  __typename?: 'product_avg_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product" */
export type Product_Avg_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product". All fields are combined with a logical 'AND'. */
export type Product_Bool_Exp = {
  _and?: Maybe<Array<Product_Bool_Exp>>;
  _not?: Maybe<Product_Bool_Exp>;
  _or?: Maybe<Array<Product_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_product_id?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Bool_Exp>;
  logistics_orders?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Bool_Exp>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Bool_Exp>;
  product_name?: Maybe<String_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "product" */
export enum Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductPkey = 'product_pkey',
  /** unique or primary key constraint on columns "product_name" */
  ProductProductNameKey = 'product_product_name_key'
}

/** input type for incrementing numeric columns in table "product" */
export type Product_Inc_Input = {
  service_account_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "product" */
export type Product_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_product_id?: Maybe<Scalars['String']>;
  inbound_manifest_lines?: Maybe<Inbound_Manifest_Line_Arr_Rel_Insert_Input>;
  logistics_orders?: Maybe<Logistics_Order_Arr_Rel_Insert_Input>;
  outbound_manifest_lines?: Maybe<Outbound_Manifest_Line_Arr_Rel_Insert_Input>;
  product_name?: Maybe<Scalars['String']>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Max_Fields = {
  __typename?: 'product_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_product_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product" */
export type Product_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Min_Fields = {
  __typename?: 'product_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_product_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product" */
export type Product_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_name?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product" */
export type Product_Mutation_Response = {
  __typename?: 'product_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product>;
};

/** input type for inserting object relation for remote table "product" */
export type Product_Obj_Rel_Insert_Input = {
  data: Product_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_On_Conflict>;
};

/** on_conflict condition type for table "product" */
export type Product_On_Conflict = {
  constraint: Product_Constraint;
  update_columns?: Array<Product_Update_Column>;
  where?: Maybe<Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product". */
export type Product_Order_By = {
  created_at?: Maybe<Order_By>;
  external_product_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_lines_aggregate?: Maybe<Inbound_Manifest_Line_Aggregate_Order_By>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Order_By>;
  outbound_manifest_lines_aggregate?: Maybe<Outbound_Manifest_Line_Aggregate_Order_By>;
  product_name?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Order_By>;
};

/** primary key columns input for table: product */
export type Product_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product" */
export enum Product_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalProductId = 'external_product_id',
  /** column name */
  Id = 'id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "product" */
export type Product_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_product_id?: Maybe<Scalars['String']>;
  product_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Stddev_Fields = {
  __typename?: 'product_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product" */
export type Product_Stddev_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stddev_Pop_Fields = {
  __typename?: 'product_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product" */
export type Product_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stddev_Samp_Fields = {
  __typename?: 'product_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product" */
export type Product_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "product_stock" */
export type Product_Stock = {
  __typename?: 'product_stock';
  /** An object relationship */
  address: Address;
  address_id: Scalars['Int'];
  allocated_to_organisation_id: Scalars['Int'];
  barcode_is_unique: Scalars['Boolean'];
  barcode_uuid: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  initial_quantity: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  /** An array relationship */
  outbound_manifest_fulfilment_lines: Array<Outbound_Manifest_Fulfilment_Line>;
  /** An aggregate relationship */
  outbound_manifest_fulfilment_lines_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  quantity: Scalars['Int'];
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  /** An array relationship */
  stockSyncLogsBySourceProductStockId: Array<Stock_Sync_Log>;
  /** An aggregate relationship */
  stockSyncLogsBySourceProductStockId_aggregate: Stock_Sync_Log_Aggregate;
  /** An object relationship */
  stock_condition: Stock_Condition;
  stock_condition_id: Scalars['Int'];
  /** An object relationship */
  stock_status: Stock_Status;
  stock_status_id: Scalars['Int'];
  /** An array relationship */
  stock_sync_logs: Array<Stock_Sync_Log>;
  /** An aggregate relationship */
  stock_sync_logs_aggregate: Stock_Sync_Log_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wms_stock_change_inputs: Array<Wms_Stock_Change_Input>;
  /** An aggregate relationship */
  wms_stock_change_inputs_aggregate: Wms_Stock_Change_Input_Aggregate;
  /** An object relationship */
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output>;
};


/** columns and relationships of "product_stock" */
export type Product_StockOutbound_Manifest_Fulfilment_LinesArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockOutbound_Manifest_Fulfilment_Lines_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockStockSyncLogsBySourceProductStockIdArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockStockSyncLogsBySourceProductStockId_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockStock_Sync_LogsArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockStock_Sync_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockWms_Stock_Change_InputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


/** columns and relationships of "product_stock" */
export type Product_StockWms_Stock_Change_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};

/** aggregated selection of "product_stock" */
export type Product_Stock_Aggregate = {
  __typename?: 'product_stock_aggregate';
  aggregate?: Maybe<Product_Stock_Aggregate_Fields>;
  nodes: Array<Product_Stock>;
};

export type Product_Stock_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Product_Stock_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Product_Stock_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Product_Stock_Aggregate_Bool_Exp_Count>;
};

export type Product_Stock_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Product_Stock_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Stock_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Product_Stock_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Stock_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Stock_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Product_Stock_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_stock" */
export type Product_Stock_Aggregate_Fields = {
  __typename?: 'product_stock_aggregate_fields';
  avg?: Maybe<Product_Stock_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Product_Stock_Max_Fields>;
  min?: Maybe<Product_Stock_Min_Fields>;
  stddev?: Maybe<Product_Stock_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Stock_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Stock_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Stock_Sum_Fields>;
  var_pop?: Maybe<Product_Stock_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Stock_Var_Samp_Fields>;
  variance?: Maybe<Product_Stock_Variance_Fields>;
};


/** aggregate fields of "product_stock" */
export type Product_Stock_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Stock_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "product_stock" */
export type Product_Stock_Aggregate_Order_By = {
  avg?: Maybe<Product_Stock_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Stock_Max_Order_By>;
  min?: Maybe<Product_Stock_Min_Order_By>;
  stddev?: Maybe<Product_Stock_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Stock_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Stock_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Stock_Sum_Order_By>;
  var_pop?: Maybe<Product_Stock_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Stock_Var_Samp_Order_By>;
  variance?: Maybe<Product_Stock_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_stock" */
export type Product_Stock_Arr_Rel_Insert_Input = {
  data: Array<Product_Stock_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Stock_Avg_Fields = {
  __typename?: 'product_stock_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "product_stock" */
export type Product_Stock_Avg_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_stock". All fields are combined with a logical 'AND'. */
export type Product_Stock_Bool_Exp = {
  _and?: Maybe<Array<Product_Stock_Bool_Exp>>;
  _not?: Maybe<Product_Stock_Bool_Exp>;
  _or?: Maybe<Array<Product_Stock_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Int_Comparison_Exp>;
  allocated_to_organisation_id?: Maybe<Int_Comparison_Exp>;
  barcode_is_unique?: Maybe<Boolean_Comparison_Exp>;
  barcode_uuid?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
  initial_quantity?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Bool_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Int_Comparison_Exp>;
  source_wms_stock_change_output_id?: Maybe<Int_Comparison_Exp>;
  stockSyncLogsBySourceProductStockId?: Maybe<Stock_Sync_Log_Bool_Exp>;
  stockSyncLogsBySourceProductStockId_aggregate?: Maybe<Stock_Sync_Log_Aggregate_Bool_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
  stock_condition_id?: Maybe<Int_Comparison_Exp>;
  stock_status?: Maybe<Stock_Status_Bool_Exp>;
  stock_status_id?: Maybe<Int_Comparison_Exp>;
  stock_sync_logs?: Maybe<Stock_Sync_Log_Bool_Exp>;
  stock_sync_logs_aggregate?: Maybe<Stock_Sync_Log_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_inputs?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
  wms_stock_change_inputs_aggregate?: Maybe<Wms_Stock_Change_Input_Aggregate_Bool_Exp>;
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** unique or primary key constraints on table "product_stock" */
export enum Product_Stock_Constraint {
  /** unique or primary key constraint on columns "id" */
  ProductStockPkey = 'product_stock_pkey',
  /** unique or primary key constraint on columns "barcode_uuid" */
  ProductStockUniqueBarcodes = 'product_stock_unique_barcodes'
}

/** input type for incrementing numeric columns in table "product_stock" */
export type Product_Stock_Inc_Input = {
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  initial_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "product_stock" */
export type Product_Stock_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  barcode_is_unique?: Maybe<Scalars['Boolean']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Obj_Rel_Insert_Input>;
  initial_quantity?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  outbound_manifest_fulfilment_lines?: Maybe<Outbound_Manifest_Fulfilment_Line_Arr_Rel_Insert_Input>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stockSyncLogsBySourceProductStockId?: Maybe<Stock_Sync_Log_Arr_Rel_Insert_Input>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status?: Maybe<Stock_Status_Obj_Rel_Insert_Input>;
  stock_status_id?: Maybe<Scalars['Int']>;
  stock_sync_logs?: Maybe<Stock_Sync_Log_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_inputs?: Maybe<Wms_Stock_Change_Input_Arr_Rel_Insert_Input>;
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Product_Stock_Max_Fields = {
  __typename?: 'product_stock_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  initial_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "product_stock" */
export type Product_Stock_Max_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Stock_Min_Fields = {
  __typename?: 'product_stock_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  initial_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "product_stock" */
export type Product_Stock_Min_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_stock" */
export type Product_Stock_Mutation_Response = {
  __typename?: 'product_stock_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Stock>;
};

/** input type for inserting object relation for remote table "product_stock" */
export type Product_Stock_Obj_Rel_Insert_Input = {
  data: Product_Stock_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Stock_On_Conflict>;
};

/** on_conflict condition type for table "product_stock" */
export type Product_Stock_On_Conflict = {
  constraint: Product_Stock_Constraint;
  update_columns?: Array<Product_Stock_Update_Column>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};

/** Ordering options when selecting data from "product_stock". */
export type Product_Stock_Order_By = {
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  barcode_is_unique?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifest_fulfilment_line?: Maybe<Inbound_Manifest_Fulfilment_Line_Order_By>;
  initial_quantity?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  outbound_manifest_fulfilment_lines_aggregate?: Maybe<Outbound_Manifest_Fulfilment_Line_Aggregate_Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stockSyncLogsBySourceProductStockId_aggregate?: Maybe<Stock_Sync_Log_Aggregate_Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status?: Maybe<Stock_Status_Order_By>;
  stock_status_id?: Maybe<Order_By>;
  stock_sync_logs_aggregate?: Maybe<Stock_Sync_Log_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_inputs_aggregate?: Maybe<Wms_Stock_Change_Input_Aggregate_Order_By>;
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Order_By>;
};

/** primary key columns input for table: product_stock */
export type Product_Stock_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "product_stock" */
export enum Product_Stock_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  AllocatedToOrganisationId = 'allocated_to_organisation_id',
  /** column name */
  BarcodeIsUnique = 'barcode_is_unique',
  /** column name */
  BarcodeUuid = 'barcode_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  InitialQuantity = 'initial_quantity',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SourceInboundManifestFulfilmentLineId = 'source_inbound_manifest_fulfilment_line_id',
  /** column name */
  SourceWmsStockChangeOutputId = 'source_wms_stock_change_output_id',
  /** column name */
  StockConditionId = 'stock_condition_id',
  /** column name */
  StockStatusId = 'stock_status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "product_stock_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_stock" */
export enum Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  BarcodeIsUnique = 'barcode_is_unique'
}

/** select "product_stock_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_stock" */
export enum Product_Stock_Select_Column_Product_Stock_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  BarcodeIsUnique = 'barcode_is_unique'
}

/** input type for updating data in table "product_stock" */
export type Product_Stock_Set_Input = {
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  barcode_is_unique?: Maybe<Scalars['Boolean']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  initial_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Product_Stock_Stddev_Fields = {
  __typename?: 'product_stock_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "product_stock" */
export type Product_Stock_Stddev_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Stock_Stddev_Pop_Fields = {
  __typename?: 'product_stock_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "product_stock" */
export type Product_Stock_Stddev_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Stock_Stddev_Samp_Fields = {
  __typename?: 'product_stock_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "product_stock" */
export type Product_Stock_Stddev_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product_stock" */
export type Product_Stock_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stock_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stock_Stream_Cursor_Value_Input = {
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  barcode_is_unique?: Maybe<Scalars['Boolean']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  initial_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Product_Stock_Sum_Fields = {
  __typename?: 'product_stock_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  allocated_to_organisation_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  initial_quantity?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Int']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product_stock" */
export type Product_Stock_Sum_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** update columns of table "product_stock" */
export enum Product_Stock_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  AllocatedToOrganisationId = 'allocated_to_organisation_id',
  /** column name */
  BarcodeIsUnique = 'barcode_is_unique',
  /** column name */
  BarcodeUuid = 'barcode_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  InitialQuantity = 'initial_quantity',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  SourceInboundManifestFulfilmentLineId = 'source_inbound_manifest_fulfilment_line_id',
  /** column name */
  SourceWmsStockChangeOutputId = 'source_wms_stock_change_output_id',
  /** column name */
  StockConditionId = 'stock_condition_id',
  /** column name */
  StockStatusId = 'stock_status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Product_Stock_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Stock_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Stock_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Stock_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Stock_Var_Pop_Fields = {
  __typename?: 'product_stock_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product_stock" */
export type Product_Stock_Var_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Stock_Var_Samp_Fields = {
  __typename?: 'product_stock_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product_stock" */
export type Product_Stock_Var_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Stock_Variance_Fields = {
  __typename?: 'product_stock_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  allocated_to_organisation_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_quantity?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Scalars['Float']>;
  source_wms_stock_change_output_id?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product_stock" */
export type Product_Stock_Variance_Order_By = {
  address_id?: Maybe<Order_By>;
  allocated_to_organisation_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_quantity?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  source_inbound_manifest_fulfilment_line_id?: Maybe<Order_By>;
  source_wms_stock_change_output_id?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product" */
export type Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_product_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['Int']>;
  product_name?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Product_Sum_Fields = {
  __typename?: 'product_sum_fields';
  id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "product" */
export type Product_Sum_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** update columns of table "product" */
export enum Product_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalProductId = 'external_product_id',
  /** column name */
  ProductName = 'product_name',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Product_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Var_Pop_Fields = {
  __typename?: 'product_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "product" */
export type Product_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Var_Samp_Fields = {
  __typename?: 'product_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "product" */
export type Product_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Variance_Fields = {
  __typename?: 'product_variance_fields';
  id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "product" */
export type Product_Variance_Order_By = {
  id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "purchase_order" */
export type Purchase_Order = {
  __typename?: 'purchase_order';
  /** An object relationship */
  address: Address;
  created_at: Scalars['timestamptz'];
  external_purchase_order_id: Scalars['Int'];
  id: Scalars['Int'];
  initial_address_id: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  purchase_order_uuid: Scalars['String'];
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  /** An array relationship */
  shipments: Array<Shipment>;
  /** An aggregate relationship */
  shipments_aggregate: Shipment_Aggregate;
  supplier_organisation_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "purchase_order" */
export type Purchase_OrderShipmentsArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


/** columns and relationships of "purchase_order" */
export type Purchase_OrderShipments_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};

/** aggregated selection of "purchase_order" */
export type Purchase_Order_Aggregate = {
  __typename?: 'purchase_order_aggregate';
  aggregate?: Maybe<Purchase_Order_Aggregate_Fields>;
  nodes: Array<Purchase_Order>;
};

export type Purchase_Order_Aggregate_Bool_Exp = {
  count?: Maybe<Purchase_Order_Aggregate_Bool_Exp_Count>;
};

export type Purchase_Order_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Purchase_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Purchase_Order_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "purchase_order" */
export type Purchase_Order_Aggregate_Fields = {
  __typename?: 'purchase_order_aggregate_fields';
  avg?: Maybe<Purchase_Order_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Purchase_Order_Max_Fields>;
  min?: Maybe<Purchase_Order_Min_Fields>;
  stddev?: Maybe<Purchase_Order_Stddev_Fields>;
  stddev_pop?: Maybe<Purchase_Order_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Purchase_Order_Stddev_Samp_Fields>;
  sum?: Maybe<Purchase_Order_Sum_Fields>;
  var_pop?: Maybe<Purchase_Order_Var_Pop_Fields>;
  var_samp?: Maybe<Purchase_Order_Var_Samp_Fields>;
  variance?: Maybe<Purchase_Order_Variance_Fields>;
};


/** aggregate fields of "purchase_order" */
export type Purchase_Order_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Purchase_Order_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "purchase_order" */
export type Purchase_Order_Aggregate_Order_By = {
  avg?: Maybe<Purchase_Order_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Purchase_Order_Max_Order_By>;
  min?: Maybe<Purchase_Order_Min_Order_By>;
  stddev?: Maybe<Purchase_Order_Stddev_Order_By>;
  stddev_pop?: Maybe<Purchase_Order_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Purchase_Order_Stddev_Samp_Order_By>;
  sum?: Maybe<Purchase_Order_Sum_Order_By>;
  var_pop?: Maybe<Purchase_Order_Var_Pop_Order_By>;
  var_samp?: Maybe<Purchase_Order_Var_Samp_Order_By>;
  variance?: Maybe<Purchase_Order_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "purchase_order" */
export type Purchase_Order_Arr_Rel_Insert_Input = {
  data: Array<Purchase_Order_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Purchase_Order_On_Conflict>;
};

/** aggregate avg on columns */
export type Purchase_Order_Avg_Fields = {
  __typename?: 'purchase_order_avg_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "purchase_order" */
export type Purchase_Order_Avg_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "purchase_order". All fields are combined with a logical 'AND'. */
export type Purchase_Order_Bool_Exp = {
  _and?: Maybe<Array<Purchase_Order_Bool_Exp>>;
  _not?: Maybe<Purchase_Order_Bool_Exp>;
  _or?: Maybe<Array<Purchase_Order_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_purchase_order_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  initial_address_id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  purchase_order_uuid?: Maybe<String_Comparison_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  shipments?: Maybe<Shipment_Bool_Exp>;
  shipments_aggregate?: Maybe<Shipment_Aggregate_Bool_Exp>;
  supplier_organisation_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "purchase_order" */
export enum Purchase_Order_Constraint {
  /** unique or primary key constraint on columns "id" */
  PurchaseOrderPkey = 'purchase_order_pkey'
}

/** input type for incrementing numeric columns in table "purchase_order" */
export type Purchase_Order_Inc_Input = {
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "purchase_order" */
export type Purchase_Order_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  purchase_order_uuid?: Maybe<Scalars['String']>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  shipments?: Maybe<Shipment_Arr_Rel_Insert_Input>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Purchase_Order_Max_Fields = {
  __typename?: 'purchase_order_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  purchase_order_uuid?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "purchase_order" */
export type Purchase_Order_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  purchase_order_uuid?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Purchase_Order_Min_Fields = {
  __typename?: 'purchase_order_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  purchase_order_uuid?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "purchase_order" */
export type Purchase_Order_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  purchase_order_uuid?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "purchase_order" */
export type Purchase_Order_Mutation_Response = {
  __typename?: 'purchase_order_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Purchase_Order>;
};

/** input type for inserting object relation for remote table "purchase_order" */
export type Purchase_Order_Obj_Rel_Insert_Input = {
  data: Purchase_Order_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Purchase_Order_On_Conflict>;
};

/** on_conflict condition type for table "purchase_order" */
export type Purchase_Order_On_Conflict = {
  constraint: Purchase_Order_Constraint;
  update_columns?: Array<Purchase_Order_Update_Column>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};

/** Ordering options when selecting data from "purchase_order". */
export type Purchase_Order_Order_By = {
  address?: Maybe<Address_Order_By>;
  created_at?: Maybe<Order_By>;
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  purchase_order_uuid?: Maybe<Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  shipments_aggregate?: Maybe<Shipment_Aggregate_Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: purchase_order */
export type Purchase_Order_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "purchase_order" */
export enum Purchase_Order_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalPurchaseOrderId = 'external_purchase_order_id',
  /** column name */
  Id = 'id',
  /** column name */
  InitialAddressId = 'initial_address_id',
  /** column name */
  PurchaseOrderUuid = 'purchase_order_uuid',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  SupplierOrganisationId = 'supplier_organisation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "purchase_order" */
export type Purchase_Order_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  purchase_order_uuid?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Purchase_Order_Stddev_Fields = {
  __typename?: 'purchase_order_stddev_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "purchase_order" */
export type Purchase_Order_Stddev_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Purchase_Order_Stddev_Pop_Fields = {
  __typename?: 'purchase_order_stddev_pop_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "purchase_order" */
export type Purchase_Order_Stddev_Pop_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Purchase_Order_Stddev_Samp_Fields = {
  __typename?: 'purchase_order_stddev_samp_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "purchase_order" */
export type Purchase_Order_Stddev_Samp_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "purchase_order" */
export type Purchase_Order_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Purchase_Order_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Purchase_Order_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  purchase_order_uuid?: Maybe<Scalars['String']>;
  service_account_id?: Maybe<Scalars['Int']>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Purchase_Order_Sum_Fields = {
  __typename?: 'purchase_order_sum_fields';
  external_purchase_order_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  initial_address_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  supplier_organisation_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "purchase_order" */
export type Purchase_Order_Sum_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** update columns of table "purchase_order" */
export enum Purchase_Order_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalPurchaseOrderId = 'external_purchase_order_id',
  /** column name */
  InitialAddressId = 'initial_address_id',
  /** column name */
  PurchaseOrderUuid = 'purchase_order_uuid',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  SupplierOrganisationId = 'supplier_organisation_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Purchase_Order_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Purchase_Order_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Purchase_Order_Set_Input>;
  /** filter the rows which have to be updated */
  where: Purchase_Order_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Purchase_Order_Var_Pop_Fields = {
  __typename?: 'purchase_order_var_pop_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "purchase_order" */
export type Purchase_Order_Var_Pop_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Purchase_Order_Var_Samp_Fields = {
  __typename?: 'purchase_order_var_samp_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "purchase_order" */
export type Purchase_Order_Var_Samp_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Purchase_Order_Variance_Fields = {
  __typename?: 'purchase_order_variance_fields';
  external_purchase_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  initial_address_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
  supplier_organisation_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "purchase_order" */
export type Purchase_Order_Variance_Order_By = {
  external_purchase_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  initial_address_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  supplier_organisation_id?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** fetch data from the table: "client_order" */
  client_order: Array<Client_Order>;
  /** fetch aggregated fields from the table: "client_order" */
  client_order_aggregate: Client_Order_Aggregate;
  /** fetch data from the table: "client_order" using primary key columns */
  client_order_by_pk?: Maybe<Client_Order>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table: "databasechangelog" */
  databasechangelog: Array<Databasechangelog>;
  /** fetch aggregated fields from the table: "databasechangelog" */
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  /** fetch data from the table: "databasechangeloglock" */
  databasechangeloglock: Array<Databasechangeloglock>;
  /** fetch aggregated fields from the table: "databasechangeloglock" */
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  /** fetch data from the table: "databasechangeloglock" using primary key columns */
  databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** fetch data from the table: "folder" */
  folder: Array<Folder>;
  /** fetch aggregated fields from the table: "folder" */
  folder_aggregate: Folder_Aggregate;
  /** fetch data from the table: "folder" using primary key columns */
  folder_by_pk?: Maybe<Folder>;
  /** fetch data from the table: "inbound_manifest" */
  inbound_manifest: Array<Inbound_Manifest>;
  /** fetch aggregated fields from the table: "inbound_manifest" */
  inbound_manifest_aggregate: Inbound_Manifest_Aggregate;
  /** fetch data from the table: "inbound_manifest" using primary key columns */
  inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line: Array<Inbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" using primary key columns */
  inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "inbound_manifest_line" */
  inbound_manifest_line: Array<Inbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_line" */
  inbound_manifest_line_aggregate: Inbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_line" using primary key columns */
  inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** fetch data from the table: "inbound_manifest_status" */
  inbound_manifest_status: Array<Inbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "inbound_manifest_status" */
  inbound_manifest_status_aggregate: Inbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "inbound_manifest_status" using primary key columns */
  inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** fetch data from the table: "logistics_order" */
  logistics_order: Array<Logistics_Order>;
  /** fetch aggregated fields from the table: "logistics_order" */
  logistics_order_aggregate: Logistics_Order_Aggregate;
  /** fetch data from the table: "logistics_order" using primary key columns */
  logistics_order_by_pk?: Maybe<Logistics_Order>;
  /** fetch data from the table: "logistics_order_status" */
  logistics_order_status: Array<Logistics_Order_Status>;
  /** fetch aggregated fields from the table: "logistics_order_status" */
  logistics_order_status_aggregate: Logistics_Order_Status_Aggregate;
  /** fetch data from the table: "logistics_order_status" using primary key columns */
  logistics_order_status_by_pk?: Maybe<Logistics_Order_Status>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table: "outbound_manifest" */
  outbound_manifest: Array<Outbound_Manifest>;
  /** fetch aggregated fields from the table: "outbound_manifest" */
  outbound_manifest_aggregate: Outbound_Manifest_Aggregate;
  /** fetch data from the table: "outbound_manifest" using primary key columns */
  outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line: Array<Outbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" using primary key columns */
  outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "outbound_manifest_line" */
  outbound_manifest_line: Array<Outbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_line" */
  outbound_manifest_line_aggregate: Outbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_line" using primary key columns */
  outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** fetch data from the table: "outbound_manifest_status" */
  outbound_manifest_status: Array<Outbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_status" */
  outbound_manifest_status_aggregate: Outbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_status" using primary key columns */
  outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_stock" */
  product_stock: Array<Product_Stock>;
  /** fetch aggregated fields from the table: "product_stock" */
  product_stock_aggregate: Product_Stock_Aggregate;
  /** fetch data from the table: "product_stock" using primary key columns */
  product_stock_by_pk?: Maybe<Product_Stock>;
  /** fetch data from the table: "purchase_order" */
  purchase_order: Array<Purchase_Order>;
  /** fetch aggregated fields from the table: "purchase_order" */
  purchase_order_aggregate: Purchase_Order_Aggregate;
  /** fetch data from the table: "purchase_order" using primary key columns */
  purchase_order_by_pk?: Maybe<Purchase_Order>;
  /** fetch data from the table: "service_account" */
  service_account: Array<Service_Account>;
  /** fetch aggregated fields from the table: "service_account" */
  service_account_aggregate: Service_Account_Aggregate;
  /** fetch data from the table: "service_account" using primary key columns */
  service_account_by_pk?: Maybe<Service_Account>;
  /** fetch data from the table: "shipment" */
  shipment: Array<Shipment>;
  /** fetch aggregated fields from the table: "shipment" */
  shipment_aggregate: Shipment_Aggregate;
  /** fetch data from the table: "shipment" using primary key columns */
  shipment_by_pk?: Maybe<Shipment>;
  /** fetch data from the table: "shipment_status" */
  shipment_status: Array<Shipment_Status>;
  /** fetch aggregated fields from the table: "shipment_status" */
  shipment_status_aggregate: Shipment_Status_Aggregate;
  /** fetch data from the table: "shipment_status" using primary key columns */
  shipment_status_by_pk?: Maybe<Shipment_Status>;
  /** fetch data from the table: "shipment_update" */
  shipment_update: Array<Shipment_Update>;
  /** fetch aggregated fields from the table: "shipment_update" */
  shipment_update_aggregate: Shipment_Update_Aggregate;
  /** fetch data from the table: "shipment_update" using primary key columns */
  shipment_update_by_pk?: Maybe<Shipment_Update>;
  /** fetch data from the table: "shipment_update_type" */
  shipment_update_type: Array<Shipment_Update_Type>;
  /** fetch aggregated fields from the table: "shipment_update_type" */
  shipment_update_type_aggregate: Shipment_Update_Type_Aggregate;
  /** fetch data from the table: "shipment_update_type" using primary key columns */
  shipment_update_type_by_pk?: Maybe<Shipment_Update_Type>;
  /** fetch data from the table: "stock_condition" */
  stock_condition: Array<Stock_Condition>;
  /** fetch aggregated fields from the table: "stock_condition" */
  stock_condition_aggregate: Stock_Condition_Aggregate;
  /** fetch data from the table: "stock_condition" using primary key columns */
  stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** fetch data from the table: "stock_status" */
  stock_status: Array<Stock_Status>;
  /** fetch aggregated fields from the table: "stock_status" */
  stock_status_aggregate: Stock_Status_Aggregate;
  /** fetch data from the table: "stock_status" using primary key columns */
  stock_status_by_pk?: Maybe<Stock_Status>;
  /** fetch data from the table: "stock_sync_log" */
  stock_sync_log: Array<Stock_Sync_Log>;
  /** fetch aggregated fields from the table: "stock_sync_log" */
  stock_sync_log_aggregate: Stock_Sync_Log_Aggregate;
  /** fetch data from the table: "stock_sync_log" using primary key columns */
  stock_sync_log_by_pk?: Maybe<Stock_Sync_Log>;
  /** fetch data from the table: "warehouse" */
  warehouse: Array<Warehouse>;
  /** fetch aggregated fields from the table: "warehouse" */
  warehouse_aggregate: Warehouse_Aggregate;
  /** fetch data from the table: "warehouse" using primary key columns */
  warehouse_by_pk?: Maybe<Warehouse>;
  /** fetch data from the table: "wms_stock_change" */
  wms_stock_change: Array<Wms_Stock_Change>;
  /** fetch aggregated fields from the table: "wms_stock_change" */
  wms_stock_change_aggregate: Wms_Stock_Change_Aggregate;
  /** fetch data from the table: "wms_stock_change" using primary key columns */
  wms_stock_change_by_pk?: Maybe<Wms_Stock_Change>;
  /** fetch data from the table: "wms_stock_change_input" */
  wms_stock_change_input: Array<Wms_Stock_Change_Input>;
  /** fetch aggregated fields from the table: "wms_stock_change_input" */
  wms_stock_change_input_aggregate: Wms_Stock_Change_Input_Aggregate;
  /** fetch data from the table: "wms_stock_change_input" using primary key columns */
  wms_stock_change_input_by_pk?: Maybe<Wms_Stock_Change_Input>;
  /** fetch data from the table: "wms_stock_change_output" */
  wms_stock_change_output: Array<Wms_Stock_Change_Output>;
  /** fetch aggregated fields from the table: "wms_stock_change_output" */
  wms_stock_change_output_aggregate: Wms_Stock_Change_Output_Aggregate;
  /** fetch data from the table: "wms_stock_change_output" using primary key columns */
  wms_stock_change_output_by_pk?: Maybe<Wms_Stock_Change_Output>;
  /** fetch data from the table: "wms_stock_change_type" */
  wms_stock_change_type: Array<Wms_Stock_Change_Type>;
  /** fetch aggregated fields from the table: "wms_stock_change_type" */
  wms_stock_change_type_aggregate: Wms_Stock_Change_Type_Aggregate;
  /** fetch data from the table: "wms_stock_change_type" using primary key columns */
  wms_stock_change_type_by_pk?: Maybe<Wms_Stock_Change_Type>;
};


export type Query_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Query_RootAddress_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootAttachmentArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Query_RootAttachment_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Query_RootAttachment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootClient_OrderArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


export type Query_RootClient_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


export type Query_RootClient_Order_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootCountryArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


export type Query_RootCountry_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


export type Query_RootCountry_By_PkArgs = {
  country_code: Scalars['String'];
};


export type Query_RootDatabasechangelogArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};


export type Query_RootDatabasechangelog_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};


export type Query_RootDatabasechangeloglockArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};


export type Query_RootDatabasechangeloglock_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};


export type Query_RootDatabasechangeloglock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootFolderArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};


export type Query_RootFolder_AggregateArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};


export type Query_RootFolder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


export type Query_RootInbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


export type Query_RootInbound_Manifest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Query_RootInbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Query_RootInbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


export type Query_RootInbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


export type Query_RootInbound_Manifest_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootInbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};


export type Query_RootInbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};


export type Query_RootInbound_Manifest_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLogistics_OrderArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


export type Query_RootLogistics_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


export type Query_RootLogistics_Order_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootLogistics_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Status_Order_By>>;
  where?: Maybe<Logistics_Order_Status_Bool_Exp>;
};


export type Query_RootLogistics_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Status_Order_By>>;
  where?: Maybe<Logistics_Order_Status_Bool_Exp>;
};


export type Query_RootLogistics_Order_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOrganisationArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Query_RootOrganisation_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Query_RootOrganisation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootOutbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};


export type Query_RootOutbound_Manifest_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Query_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootProduct_StockArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


export type Query_RootProduct_Stock_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


export type Query_RootProduct_Stock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootPurchase_OrderArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


export type Query_RootPurchase_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


export type Query_RootPurchase_Order_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootService_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};


export type Query_RootService_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};


export type Query_RootService_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootShipmentArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


export type Query_RootShipment_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


export type Query_RootShipment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootShipment_StatusArgs = {
  distinct_on?: Maybe<Array<Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Status_Order_By>>;
  where?: Maybe<Shipment_Status_Bool_Exp>;
};


export type Query_RootShipment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Status_Order_By>>;
  where?: Maybe<Shipment_Status_Bool_Exp>;
};


export type Query_RootShipment_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootShipment_UpdateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


export type Query_RootShipment_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


export type Query_RootShipment_Update_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootShipment_Update_TypeArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Type_Order_By>>;
  where?: Maybe<Shipment_Update_Type_Bool_Exp>;
};


export type Query_RootShipment_Update_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Type_Order_By>>;
  where?: Maybe<Shipment_Update_Type_Bool_Exp>;
};


export type Query_RootShipment_Update_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStock_ConditionArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};


export type Query_RootStock_Condition_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};


export type Query_RootStock_Condition_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStock_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};


export type Query_RootStock_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};


export type Query_RootStock_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootStock_Sync_LogArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


export type Query_RootStock_Sync_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


export type Query_RootStock_Sync_Log_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWarehouseArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


export type Query_RootWarehouse_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


export type Query_RootWarehouse_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWms_Stock_ChangeArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWms_Stock_Change_InputArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_Input_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_Input_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWms_Stock_Change_OutputArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_Output_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_Output_By_PkArgs = {
  id: Scalars['Int'];
};


export type Query_RootWms_Stock_Change_TypeArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Type_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Type_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
};


export type Query_RootWms_Stock_Change_Type_By_PkArgs = {
  id: Scalars['Int'];
};

/** columns and relationships of "service_account" */
export type Service_Account = {
  __typename?: 'service_account';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  /** An array relationship */
  client_orders: Array<Client_Order>;
  /** An aggregate relationship */
  client_orders_aggregate: Client_Order_Aggregate;
  created_at: Scalars['timestamptz'];
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id: Scalars['Int'];
  /** An array relationship */
  logistics_orders: Array<Logistics_Order>;
  /** An aggregate relationship */
  logistics_orders_aggregate: Logistics_Order_Aggregate;
  /** An array relationship */
  organisations: Array<Organisation>;
  /** An aggregate relationship */
  organisations_aggregate: Organisation_Aggregate;
  /** An array relationship */
  products: Array<Product>;
  /** An aggregate relationship */
  products_aggregate: Product_Aggregate;
  /** An array relationship */
  purchase_orders: Array<Purchase_Order>;
  /** An aggregate relationship */
  purchase_orders_aggregate: Purchase_Order_Aggregate;
  service_account_name: Scalars['String'];
  service_account_uid: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  warehouses: Array<Warehouse>;
  /** An aggregate relationship */
  warehouses_aggregate: Warehouse_Aggregate;
};


/** columns and relationships of "service_account" */
export type Service_AccountAddressesArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountClient_OrdersArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountClient_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountLogistics_OrdersArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountLogistics_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountOrganisationsArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountOrganisations_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountProductsArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountProducts_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountPurchase_OrdersArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountPurchase_Orders_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountWarehousesArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


/** columns and relationships of "service_account" */
export type Service_AccountWarehouses_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** aggregated selection of "service_account" */
export type Service_Account_Aggregate = {
  __typename?: 'service_account_aggregate';
  aggregate?: Maybe<Service_Account_Aggregate_Fields>;
  nodes: Array<Service_Account>;
};

/** aggregate fields of "service_account" */
export type Service_Account_Aggregate_Fields = {
  __typename?: 'service_account_aggregate_fields';
  avg?: Maybe<Service_Account_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Service_Account_Max_Fields>;
  min?: Maybe<Service_Account_Min_Fields>;
  stddev?: Maybe<Service_Account_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Account_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Account_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Account_Sum_Fields>;
  var_pop?: Maybe<Service_Account_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Account_Var_Samp_Fields>;
  variance?: Maybe<Service_Account_Variance_Fields>;
};


/** aggregate fields of "service_account" */
export type Service_Account_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Service_Account_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Service_Account_Avg_Fields = {
  __typename?: 'service_account_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "service_account". All fields are combined with a logical 'AND'. */
export type Service_Account_Bool_Exp = {
  _and?: Maybe<Array<Service_Account_Bool_Exp>>;
  _not?: Maybe<Service_Account_Bool_Exp>;
  _or?: Maybe<Array<Service_Account_Bool_Exp>>;
  addresses?: Maybe<Address_Bool_Exp>;
  addresses_aggregate?: Maybe<Address_Aggregate_Bool_Exp>;
  client_orders?: Maybe<Client_Order_Bool_Exp>;
  client_orders_aggregate?: Maybe<Client_Order_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  logistics_orders?: Maybe<Logistics_Order_Bool_Exp>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Bool_Exp>;
  organisations?: Maybe<Organisation_Bool_Exp>;
  organisations_aggregate?: Maybe<Organisation_Aggregate_Bool_Exp>;
  products?: Maybe<Product_Bool_Exp>;
  products_aggregate?: Maybe<Product_Aggregate_Bool_Exp>;
  purchase_orders?: Maybe<Purchase_Order_Bool_Exp>;
  purchase_orders_aggregate?: Maybe<Purchase_Order_Aggregate_Bool_Exp>;
  service_account_name?: Maybe<String_Comparison_Exp>;
  service_account_uid?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warehouses?: Maybe<Warehouse_Bool_Exp>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "service_account" */
export enum Service_Account_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceAccountPkey = 'service_account_pkey',
  /** unique or primary key constraint on columns "service_account_name" */
  ServiceAccountServiceAccountNameKey = 'service_account_service_account_name_key',
  /** unique or primary key constraint on columns "service_account_uid" */
  ServiceAccountServiceAccountUidKey = 'service_account_service_account_uid_key'
}

/** input type for incrementing numeric columns in table "service_account" */
export type Service_Account_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "service_account" */
export type Service_Account_Insert_Input = {
  addresses?: Maybe<Address_Arr_Rel_Insert_Input>;
  client_orders?: Maybe<Client_Order_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  logistics_orders?: Maybe<Logistics_Order_Arr_Rel_Insert_Input>;
  organisations?: Maybe<Organisation_Arr_Rel_Insert_Input>;
  products?: Maybe<Product_Arr_Rel_Insert_Input>;
  purchase_orders?: Maybe<Purchase_Order_Arr_Rel_Insert_Input>;
  service_account_name?: Maybe<Scalars['String']>;
  service_account_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouses?: Maybe<Warehouse_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Service_Account_Max_Fields = {
  __typename?: 'service_account_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  service_account_name?: Maybe<Scalars['String']>;
  service_account_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Service_Account_Min_Fields = {
  __typename?: 'service_account_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  service_account_name?: Maybe<Scalars['String']>;
  service_account_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "service_account" */
export type Service_Account_Mutation_Response = {
  __typename?: 'service_account_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Account>;
};

/** input type for inserting object relation for remote table "service_account" */
export type Service_Account_Obj_Rel_Insert_Input = {
  data: Service_Account_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Service_Account_On_Conflict>;
};

/** on_conflict condition type for table "service_account" */
export type Service_Account_On_Conflict = {
  constraint: Service_Account_Constraint;
  update_columns?: Array<Service_Account_Update_Column>;
  where?: Maybe<Service_Account_Bool_Exp>;
};

/** Ordering options when selecting data from "service_account". */
export type Service_Account_Order_By = {
  addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  client_orders_aggregate?: Maybe<Client_Order_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  logistics_orders_aggregate?: Maybe<Logistics_Order_Aggregate_Order_By>;
  organisations_aggregate?: Maybe<Organisation_Aggregate_Order_By>;
  products_aggregate?: Maybe<Product_Aggregate_Order_By>;
  purchase_orders_aggregate?: Maybe<Purchase_Order_Aggregate_Order_By>;
  service_account_name?: Maybe<Order_By>;
  service_account_uid?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouses_aggregate?: Maybe<Warehouse_Aggregate_Order_By>;
};

/** primary key columns input for table: service_account */
export type Service_Account_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "service_account" */
export enum Service_Account_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceAccountName = 'service_account_name',
  /** column name */
  ServiceAccountUid = 'service_account_uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "service_account" */
export type Service_Account_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  service_account_name?: Maybe<Scalars['String']>;
  service_account_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Service_Account_Stddev_Fields = {
  __typename?: 'service_account_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Service_Account_Stddev_Pop_Fields = {
  __typename?: 'service_account_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Service_Account_Stddev_Samp_Fields = {
  __typename?: 'service_account_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "service_account" */
export type Service_Account_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Account_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Account_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  deleted_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  service_account_name?: Maybe<Scalars['String']>;
  service_account_uid?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Service_Account_Sum_Fields = {
  __typename?: 'service_account_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "service_account" */
export enum Service_Account_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ServiceAccountName = 'service_account_name',
  /** column name */
  ServiceAccountUid = 'service_account_uid',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Service_Account_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Service_Account_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Service_Account_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Account_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Account_Var_Pop_Fields = {
  __typename?: 'service_account_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Service_Account_Var_Samp_Fields = {
  __typename?: 'service_account_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Service_Account_Variance_Fields = {
  __typename?: 'service_account_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "shipment" */
export type Shipment = {
  __typename?: 'shipment';
  /** An object relationship */
  client_order: Client_Order;
  client_order_id: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An object relationship */
  purchase_order?: Maybe<Purchase_Order>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  /** An object relationship */
  shipment_status: Shipment_Status;
  shipment_status_id: Scalars['Int'];
  /** An array relationship */
  shipment_updates: Array<Shipment_Update>;
  /** An aggregate relationship */
  shipment_updates_aggregate: Shipment_Update_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "shipment" */
export type ShipmentShipment_UpdatesArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "shipment" */
export type ShipmentShipment_Updates_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};

/** aggregated selection of "shipment" */
export type Shipment_Aggregate = {
  __typename?: 'shipment_aggregate';
  aggregate?: Maybe<Shipment_Aggregate_Fields>;
  nodes: Array<Shipment>;
};

export type Shipment_Aggregate_Bool_Exp = {
  count?: Maybe<Shipment_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shipment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Shipment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "shipment" */
export type Shipment_Aggregate_Fields = {
  __typename?: 'shipment_aggregate_fields';
  avg?: Maybe<Shipment_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Shipment_Max_Fields>;
  min?: Maybe<Shipment_Min_Fields>;
  stddev?: Maybe<Shipment_Stddev_Fields>;
  stddev_pop?: Maybe<Shipment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shipment_Stddev_Samp_Fields>;
  sum?: Maybe<Shipment_Sum_Fields>;
  var_pop?: Maybe<Shipment_Var_Pop_Fields>;
  var_samp?: Maybe<Shipment_Var_Samp_Fields>;
  variance?: Maybe<Shipment_Variance_Fields>;
};


/** aggregate fields of "shipment" */
export type Shipment_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shipment_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shipment" */
export type Shipment_Aggregate_Order_By = {
  avg?: Maybe<Shipment_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Shipment_Max_Order_By>;
  min?: Maybe<Shipment_Min_Order_By>;
  stddev?: Maybe<Shipment_Stddev_Order_By>;
  stddev_pop?: Maybe<Shipment_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Shipment_Stddev_Samp_Order_By>;
  sum?: Maybe<Shipment_Sum_Order_By>;
  var_pop?: Maybe<Shipment_Var_Pop_Order_By>;
  var_samp?: Maybe<Shipment_Var_Samp_Order_By>;
  variance?: Maybe<Shipment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "shipment" */
export type Shipment_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Shipment_On_Conflict>;
};

/** aggregate avg on columns */
export type Shipment_Avg_Fields = {
  __typename?: 'shipment_avg_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "shipment" */
export type Shipment_Avg_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment". All fields are combined with a logical 'AND'. */
export type Shipment_Bool_Exp = {
  _and?: Maybe<Array<Shipment_Bool_Exp>>;
  _not?: Maybe<Shipment_Bool_Exp>;
  _or?: Maybe<Array<Shipment_Bool_Exp>>;
  client_order?: Maybe<Client_Order_Bool_Exp>;
  client_order_id?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  purchase_order?: Maybe<Purchase_Order_Bool_Exp>;
  purchase_order_id?: Maybe<Int_Comparison_Exp>;
  shipment_status?: Maybe<Shipment_Status_Bool_Exp>;
  shipment_status_id?: Maybe<Int_Comparison_Exp>;
  shipment_updates?: Maybe<Shipment_Update_Bool_Exp>;
  shipment_updates_aggregate?: Maybe<Shipment_Update_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipment" */
export enum Shipment_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShipmentPkey = 'shipment_pkey'
}

/** input type for incrementing numeric columns in table "shipment" */
export type Shipment_Inc_Input = {
  client_order_id?: Maybe<Scalars['Int']>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment" */
export type Shipment_Insert_Input = {
  client_order?: Maybe<Client_Order_Obj_Rel_Insert_Input>;
  client_order_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  purchase_order?: Maybe<Purchase_Order_Obj_Rel_Insert_Input>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status?: Maybe<Shipment_Status_Obj_Rel_Insert_Input>;
  shipment_status_id?: Maybe<Scalars['Int']>;
  shipment_updates?: Maybe<Shipment_Update_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Shipment_Max_Fields = {
  __typename?: 'shipment_max_fields';
  client_order_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "shipment" */
export type Shipment_Max_Order_By = {
  client_order_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shipment_Min_Fields = {
  __typename?: 'shipment_min_fields';
  client_order_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "shipment" */
export type Shipment_Min_Order_By = {
  client_order_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "shipment" */
export type Shipment_Mutation_Response = {
  __typename?: 'shipment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shipment>;
};

/** input type for inserting object relation for remote table "shipment" */
export type Shipment_Obj_Rel_Insert_Input = {
  data: Shipment_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Shipment_On_Conflict>;
};

/** on_conflict condition type for table "shipment" */
export type Shipment_On_Conflict = {
  constraint: Shipment_Constraint;
  update_columns?: Array<Shipment_Update_Column>;
  where?: Maybe<Shipment_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment". */
export type Shipment_Order_By = {
  client_order?: Maybe<Client_Order_Order_By>;
  client_order_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order?: Maybe<Purchase_Order_Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status?: Maybe<Shipment_Status_Order_By>;
  shipment_status_id?: Maybe<Order_By>;
  shipment_updates_aggregate?: Maybe<Shipment_Update_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: shipment */
export type Shipment_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "shipment" */
export enum Shipment_Select_Column {
  /** column name */
  ClientOrderId = 'client_order_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  PurchaseOrderId = 'purchase_order_id',
  /** column name */
  ShipmentStatusId = 'shipment_status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "shipment" */
export type Shipment_Set_Input = {
  client_order_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** columns and relationships of "shipment_status" */
export type Shipment_Status = {
  __typename?: 'shipment_status';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  shipment_status_name: Scalars['String'];
  /** An array relationship */
  shipments: Array<Shipment>;
  /** An aggregate relationship */
  shipments_aggregate: Shipment_Aggregate;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "shipment_status" */
export type Shipment_StatusShipmentsArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


/** columns and relationships of "shipment_status" */
export type Shipment_StatusShipments_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};

/** aggregated selection of "shipment_status" */
export type Shipment_Status_Aggregate = {
  __typename?: 'shipment_status_aggregate';
  aggregate?: Maybe<Shipment_Status_Aggregate_Fields>;
  nodes: Array<Shipment_Status>;
};

/** aggregate fields of "shipment_status" */
export type Shipment_Status_Aggregate_Fields = {
  __typename?: 'shipment_status_aggregate_fields';
  avg?: Maybe<Shipment_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Shipment_Status_Max_Fields>;
  min?: Maybe<Shipment_Status_Min_Fields>;
  stddev?: Maybe<Shipment_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Shipment_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shipment_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Shipment_Status_Sum_Fields>;
  var_pop?: Maybe<Shipment_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Shipment_Status_Var_Samp_Fields>;
  variance?: Maybe<Shipment_Status_Variance_Fields>;
};


/** aggregate fields of "shipment_status" */
export type Shipment_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shipment_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Shipment_Status_Avg_Fields = {
  __typename?: 'shipment_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "shipment_status". All fields are combined with a logical 'AND'. */
export type Shipment_Status_Bool_Exp = {
  _and?: Maybe<Array<Shipment_Status_Bool_Exp>>;
  _not?: Maybe<Shipment_Status_Bool_Exp>;
  _or?: Maybe<Array<Shipment_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  shipment_status_name?: Maybe<String_Comparison_Exp>;
  shipments?: Maybe<Shipment_Bool_Exp>;
  shipments_aggregate?: Maybe<Shipment_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipment_status" */
export enum Shipment_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShipmentStatusPkey = 'shipment_status_pkey',
  /** unique or primary key constraint on columns "shipment_status_name" */
  ShipmentStatusShipmentStatusNameKey = 'shipment_status_shipment_status_name_key'
}

/** input type for incrementing numeric columns in table "shipment_status" */
export type Shipment_Status_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_status" */
export type Shipment_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_status_name?: Maybe<Scalars['String']>;
  shipments?: Maybe<Shipment_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Shipment_Status_Max_Fields = {
  __typename?: 'shipment_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Shipment_Status_Min_Fields = {
  __typename?: 'shipment_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "shipment_status" */
export type Shipment_Status_Mutation_Response = {
  __typename?: 'shipment_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shipment_Status>;
};

/** input type for inserting object relation for remote table "shipment_status" */
export type Shipment_Status_Obj_Rel_Insert_Input = {
  data: Shipment_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Shipment_Status_On_Conflict>;
};

/** on_conflict condition type for table "shipment_status" */
export type Shipment_Status_On_Conflict = {
  constraint: Shipment_Status_Constraint;
  update_columns?: Array<Shipment_Status_Update_Column>;
  where?: Maybe<Shipment_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_status". */
export type Shipment_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_status_name?: Maybe<Order_By>;
  shipments_aggregate?: Maybe<Shipment_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: shipment_status */
export type Shipment_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "shipment_status" */
export enum Shipment_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShipmentStatusName = 'shipment_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "shipment_status" */
export type Shipment_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Shipment_Status_Stddev_Fields = {
  __typename?: 'shipment_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Shipment_Status_Stddev_Pop_Fields = {
  __typename?: 'shipment_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Shipment_Status_Stddev_Samp_Fields = {
  __typename?: 'shipment_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "shipment_status" */
export type Shipment_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Shipment_Status_Sum_Fields = {
  __typename?: 'shipment_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "shipment_status" */
export enum Shipment_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShipmentStatusName = 'shipment_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Shipment_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shipment_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shipment_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Shipment_Status_Var_Pop_Fields = {
  __typename?: 'shipment_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Shipment_Status_Var_Samp_Fields = {
  __typename?: 'shipment_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Shipment_Status_Variance_Fields = {
  __typename?: 'shipment_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev on columns */
export type Shipment_Stddev_Fields = {
  __typename?: 'shipment_stddev_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "shipment" */
export type Shipment_Stddev_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Shipment_Stddev_Pop_Fields = {
  __typename?: 'shipment_stddev_pop_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "shipment" */
export type Shipment_Stddev_Pop_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Shipment_Stddev_Samp_Fields = {
  __typename?: 'shipment_stddev_samp_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "shipment" */
export type Shipment_Stddev_Samp_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "shipment" */
export type Shipment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Stream_Cursor_Value_Input = {
  client_order_id?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Shipment_Sum_Fields = {
  __typename?: 'shipment_sum_fields';
  client_order_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  purchase_order_id?: Maybe<Scalars['Int']>;
  shipment_status_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "shipment" */
export type Shipment_Sum_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** columns and relationships of "shipment_update" */
export type Shipment_Update = {
  __typename?: 'shipment_update';
  /** An object relationship */
  address?: Maybe<Address>;
  /** An object relationship */
  addressByFromDestinationId?: Maybe<Address>;
  /** An object relationship */
  addressByToDestinationId?: Maybe<Address>;
  created_at: Scalars['timestamptz'];
  current_destination_id?: Maybe<Scalars['Int']>;
  expected_date?: Maybe<Scalars['timestamptz']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  shipment: Shipment;
  shipment_id: Scalars['Int'];
  /** An object relationship */
  shipment_update_type: Shipment_Update_Type;
  shipment_update_type_id: Scalars['Int'];
  to_destination_id?: Maybe<Scalars['Int']>;
  tracking_link?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};

/** aggregated selection of "shipment_update" */
export type Shipment_Update_Aggregate = {
  __typename?: 'shipment_update_aggregate';
  aggregate?: Maybe<Shipment_Update_Aggregate_Fields>;
  nodes: Array<Shipment_Update>;
};

export type Shipment_Update_Aggregate_Bool_Exp = {
  count?: Maybe<Shipment_Update_Aggregate_Bool_Exp_Count>;
};

export type Shipment_Update_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Shipment_Update_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Shipment_Update_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "shipment_update" */
export type Shipment_Update_Aggregate_Fields = {
  __typename?: 'shipment_update_aggregate_fields';
  avg?: Maybe<Shipment_Update_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Shipment_Update_Max_Fields>;
  min?: Maybe<Shipment_Update_Min_Fields>;
  stddev?: Maybe<Shipment_Update_Stddev_Fields>;
  stddev_pop?: Maybe<Shipment_Update_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shipment_Update_Stddev_Samp_Fields>;
  sum?: Maybe<Shipment_Update_Sum_Fields>;
  var_pop?: Maybe<Shipment_Update_Var_Pop_Fields>;
  var_samp?: Maybe<Shipment_Update_Var_Samp_Fields>;
  variance?: Maybe<Shipment_Update_Variance_Fields>;
};


/** aggregate fields of "shipment_update" */
export type Shipment_Update_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shipment_Update_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "shipment_update" */
export type Shipment_Update_Aggregate_Order_By = {
  avg?: Maybe<Shipment_Update_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Shipment_Update_Max_Order_By>;
  min?: Maybe<Shipment_Update_Min_Order_By>;
  stddev?: Maybe<Shipment_Update_Stddev_Order_By>;
  stddev_pop?: Maybe<Shipment_Update_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Shipment_Update_Stddev_Samp_Order_By>;
  sum?: Maybe<Shipment_Update_Sum_Order_By>;
  var_pop?: Maybe<Shipment_Update_Var_Pop_Order_By>;
  var_samp?: Maybe<Shipment_Update_Var_Samp_Order_By>;
  variance?: Maybe<Shipment_Update_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "shipment_update" */
export type Shipment_Update_Arr_Rel_Insert_Input = {
  data: Array<Shipment_Update_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Shipment_Update_On_Conflict>;
};

/** aggregate avg on columns */
export type Shipment_Update_Avg_Fields = {
  __typename?: 'shipment_update_avg_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "shipment_update" */
export type Shipment_Update_Avg_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "shipment_update". All fields are combined with a logical 'AND'. */
export type Shipment_Update_Bool_Exp = {
  _and?: Maybe<Array<Shipment_Update_Bool_Exp>>;
  _not?: Maybe<Shipment_Update_Bool_Exp>;
  _or?: Maybe<Array<Shipment_Update_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  addressByFromDestinationId?: Maybe<Address_Bool_Exp>;
  addressByToDestinationId?: Maybe<Address_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  current_destination_id?: Maybe<Int_Comparison_Exp>;
  expected_date?: Maybe<Timestamptz_Comparison_Exp>;
  from_destination_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  shipment?: Maybe<Shipment_Bool_Exp>;
  shipment_id?: Maybe<Int_Comparison_Exp>;
  shipment_update_type?: Maybe<Shipment_Update_Type_Bool_Exp>;
  shipment_update_type_id?: Maybe<Int_Comparison_Exp>;
  to_destination_id?: Maybe<Int_Comparison_Exp>;
  tracking_link?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** update columns of table "shipment" */
export enum Shipment_Update_Column {
  /** column name */
  ClientOrderId = 'client_order_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  PurchaseOrderId = 'purchase_order_id',
  /** column name */
  ShipmentStatusId = 'shipment_status_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** unique or primary key constraints on table "shipment_update" */
export enum Shipment_Update_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShipmentUpdatePkey = 'shipment_update_pkey'
}

/** input type for incrementing numeric columns in table "shipment_update" */
export type Shipment_Update_Inc_Input = {
  current_destination_id?: Maybe<Scalars['Int']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_update" */
export type Shipment_Update_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  addressByFromDestinationId?: Maybe<Address_Obj_Rel_Insert_Input>;
  addressByToDestinationId?: Maybe<Address_Obj_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  current_destination_id?: Maybe<Scalars['Int']>;
  expected_date?: Maybe<Scalars['timestamptz']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  shipment?: Maybe<Shipment_Obj_Rel_Insert_Input>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type?: Maybe<Shipment_Update_Type_Obj_Rel_Insert_Input>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
  tracking_link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Shipment_Update_Max_Fields = {
  __typename?: 'shipment_update_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_destination_id?: Maybe<Scalars['Int']>;
  expected_date?: Maybe<Scalars['timestamptz']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
  tracking_link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "shipment_update" */
export type Shipment_Update_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  current_destination_id?: Maybe<Order_By>;
  expected_date?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
  tracking_link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Shipment_Update_Min_Fields = {
  __typename?: 'shipment_update_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  current_destination_id?: Maybe<Scalars['Int']>;
  expected_date?: Maybe<Scalars['timestamptz']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
  tracking_link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "shipment_update" */
export type Shipment_Update_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  current_destination_id?: Maybe<Order_By>;
  expected_date?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
  tracking_link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "shipment_update" */
export type Shipment_Update_Mutation_Response = {
  __typename?: 'shipment_update_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shipment_Update>;
};

/** on_conflict condition type for table "shipment_update" */
export type Shipment_Update_On_Conflict = {
  constraint: Shipment_Update_Constraint;
  update_columns?: Array<Shipment_Update_Update_Column>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_update". */
export type Shipment_Update_Order_By = {
  address?: Maybe<Address_Order_By>;
  addressByFromDestinationId?: Maybe<Address_Order_By>;
  addressByToDestinationId?: Maybe<Address_Order_By>;
  created_at?: Maybe<Order_By>;
  current_destination_id?: Maybe<Order_By>;
  expected_date?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  shipment?: Maybe<Shipment_Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type?: Maybe<Shipment_Update_Type_Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
  tracking_link?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: shipment_update */
export type Shipment_Update_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "shipment_update" */
export enum Shipment_Update_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentDestinationId = 'current_destination_id',
  /** column name */
  ExpectedDate = 'expected_date',
  /** column name */
  FromDestinationId = 'from_destination_id',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  ShipmentUpdateTypeId = 'shipment_update_type_id',
  /** column name */
  ToDestinationId = 'to_destination_id',
  /** column name */
  TrackingLink = 'tracking_link',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "shipment_update" */
export type Shipment_Update_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  current_destination_id?: Maybe<Scalars['Int']>;
  expected_date?: Maybe<Scalars['timestamptz']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
  tracking_link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Shipment_Update_Stddev_Fields = {
  __typename?: 'shipment_update_stddev_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "shipment_update" */
export type Shipment_Update_Stddev_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Shipment_Update_Stddev_Pop_Fields = {
  __typename?: 'shipment_update_stddev_pop_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "shipment_update" */
export type Shipment_Update_Stddev_Pop_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Shipment_Update_Stddev_Samp_Fields = {
  __typename?: 'shipment_update_stddev_samp_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "shipment_update" */
export type Shipment_Update_Stddev_Samp_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "shipment_update" */
export type Shipment_Update_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Update_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Update_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  current_destination_id?: Maybe<Scalars['Int']>;
  expected_date?: Maybe<Scalars['timestamptz']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
  tracking_link?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Shipment_Update_Sum_Fields = {
  __typename?: 'shipment_update_sum_fields';
  current_destination_id?: Maybe<Scalars['Int']>;
  from_destination_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  shipment_id?: Maybe<Scalars['Int']>;
  shipment_update_type_id?: Maybe<Scalars['Int']>;
  to_destination_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "shipment_update" */
export type Shipment_Update_Sum_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** columns and relationships of "shipment_update_type" */
export type Shipment_Update_Type = {
  __typename?: 'shipment_update_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  shipment_update_type_name: Scalars['String'];
  /** An array relationship */
  shipment_updates: Array<Shipment_Update>;
  /** An aggregate relationship */
  shipment_updates_aggregate: Shipment_Update_Aggregate;
  update_message?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamptz'];
};


/** columns and relationships of "shipment_update_type" */
export type Shipment_Update_TypeShipment_UpdatesArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


/** columns and relationships of "shipment_update_type" */
export type Shipment_Update_TypeShipment_Updates_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};

/** aggregated selection of "shipment_update_type" */
export type Shipment_Update_Type_Aggregate = {
  __typename?: 'shipment_update_type_aggregate';
  aggregate?: Maybe<Shipment_Update_Type_Aggregate_Fields>;
  nodes: Array<Shipment_Update_Type>;
};

/** aggregate fields of "shipment_update_type" */
export type Shipment_Update_Type_Aggregate_Fields = {
  __typename?: 'shipment_update_type_aggregate_fields';
  avg?: Maybe<Shipment_Update_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Shipment_Update_Type_Max_Fields>;
  min?: Maybe<Shipment_Update_Type_Min_Fields>;
  stddev?: Maybe<Shipment_Update_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Shipment_Update_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Shipment_Update_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Shipment_Update_Type_Sum_Fields>;
  var_pop?: Maybe<Shipment_Update_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Shipment_Update_Type_Var_Samp_Fields>;
  variance?: Maybe<Shipment_Update_Type_Variance_Fields>;
};


/** aggregate fields of "shipment_update_type" */
export type Shipment_Update_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Shipment_Update_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Shipment_Update_Type_Avg_Fields = {
  __typename?: 'shipment_update_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "shipment_update_type". All fields are combined with a logical 'AND'. */
export type Shipment_Update_Type_Bool_Exp = {
  _and?: Maybe<Array<Shipment_Update_Type_Bool_Exp>>;
  _not?: Maybe<Shipment_Update_Type_Bool_Exp>;
  _or?: Maybe<Array<Shipment_Update_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  shipment_update_type_name?: Maybe<String_Comparison_Exp>;
  shipment_updates?: Maybe<Shipment_Update_Bool_Exp>;
  shipment_updates_aggregate?: Maybe<Shipment_Update_Aggregate_Bool_Exp>;
  update_message?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "shipment_update_type" */
export enum Shipment_Update_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  ShipmentUpdateTypePkey = 'shipment_update_type_pkey',
  /** unique or primary key constraint on columns "shipment_update_type_name" */
  ShipmentUpdateTypeShipmentUpdateTypeNameKey = 'shipment_update_type_shipment_update_type_name_key'
}

/** input type for incrementing numeric columns in table "shipment_update_type" */
export type Shipment_Update_Type_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "shipment_update_type" */
export type Shipment_Update_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_update_type_name?: Maybe<Scalars['String']>;
  shipment_updates?: Maybe<Shipment_Update_Arr_Rel_Insert_Input>;
  update_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate max on columns */
export type Shipment_Update_Type_Max_Fields = {
  __typename?: 'shipment_update_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_update_type_name?: Maybe<Scalars['String']>;
  update_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Shipment_Update_Type_Min_Fields = {
  __typename?: 'shipment_update_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_update_type_name?: Maybe<Scalars['String']>;
  update_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "shipment_update_type" */
export type Shipment_Update_Type_Mutation_Response = {
  __typename?: 'shipment_update_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Shipment_Update_Type>;
};

/** input type for inserting object relation for remote table "shipment_update_type" */
export type Shipment_Update_Type_Obj_Rel_Insert_Input = {
  data: Shipment_Update_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Shipment_Update_Type_On_Conflict>;
};

/** on_conflict condition type for table "shipment_update_type" */
export type Shipment_Update_Type_On_Conflict = {
  constraint: Shipment_Update_Type_Constraint;
  update_columns?: Array<Shipment_Update_Type_Update_Column>;
  where?: Maybe<Shipment_Update_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "shipment_update_type". */
export type Shipment_Update_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_update_type_name?: Maybe<Order_By>;
  shipment_updates_aggregate?: Maybe<Shipment_Update_Aggregate_Order_By>;
  update_message?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: shipment_update_type */
export type Shipment_Update_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "shipment_update_type" */
export enum Shipment_Update_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShipmentUpdateTypeName = 'shipment_update_type_name',
  /** column name */
  UpdateMessage = 'update_message',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "shipment_update_type" */
export type Shipment_Update_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_update_type_name?: Maybe<Scalars['String']>;
  update_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Shipment_Update_Type_Stddev_Fields = {
  __typename?: 'shipment_update_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Shipment_Update_Type_Stddev_Pop_Fields = {
  __typename?: 'shipment_update_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Shipment_Update_Type_Stddev_Samp_Fields = {
  __typename?: 'shipment_update_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "shipment_update_type" */
export type Shipment_Update_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Shipment_Update_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Shipment_Update_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  shipment_update_type_name?: Maybe<Scalars['String']>;
  update_message?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Shipment_Update_Type_Sum_Fields = {
  __typename?: 'shipment_update_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "shipment_update_type" */
export enum Shipment_Update_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  ShipmentUpdateTypeName = 'shipment_update_type_name',
  /** column name */
  UpdateMessage = 'update_message',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Shipment_Update_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shipment_Update_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shipment_Update_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Update_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Shipment_Update_Type_Var_Pop_Fields = {
  __typename?: 'shipment_update_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Shipment_Update_Type_Var_Samp_Fields = {
  __typename?: 'shipment_update_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Shipment_Update_Type_Variance_Fields = {
  __typename?: 'shipment_update_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "shipment_update" */
export enum Shipment_Update_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CurrentDestinationId = 'current_destination_id',
  /** column name */
  ExpectedDate = 'expected_date',
  /** column name */
  FromDestinationId = 'from_destination_id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ShipmentId = 'shipment_id',
  /** column name */
  ShipmentUpdateTypeId = 'shipment_update_type_id',
  /** column name */
  ToDestinationId = 'to_destination_id',
  /** column name */
  TrackingLink = 'tracking_link',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Shipment_Update_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shipment_Update_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shipment_Update_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Update_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Shipment_Update_Var_Pop_Fields = {
  __typename?: 'shipment_update_var_pop_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "shipment_update" */
export type Shipment_Update_Var_Pop_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Shipment_Update_Var_Samp_Fields = {
  __typename?: 'shipment_update_var_samp_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "shipment_update" */
export type Shipment_Update_Var_Samp_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Shipment_Update_Variance_Fields = {
  __typename?: 'shipment_update_variance_fields';
  current_destination_id?: Maybe<Scalars['Float']>;
  from_destination_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  shipment_id?: Maybe<Scalars['Float']>;
  shipment_update_type_id?: Maybe<Scalars['Float']>;
  to_destination_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "shipment_update" */
export type Shipment_Update_Variance_Order_By = {
  current_destination_id?: Maybe<Order_By>;
  from_destination_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  shipment_id?: Maybe<Order_By>;
  shipment_update_type_id?: Maybe<Order_By>;
  to_destination_id?: Maybe<Order_By>;
};

export type Shipment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Shipment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Shipment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Shipment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Shipment_Var_Pop_Fields = {
  __typename?: 'shipment_var_pop_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "shipment" */
export type Shipment_Var_Pop_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Shipment_Var_Samp_Fields = {
  __typename?: 'shipment_var_samp_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "shipment" */
export type Shipment_Var_Samp_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Shipment_Variance_Fields = {
  __typename?: 'shipment_variance_fields';
  client_order_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  purchase_order_id?: Maybe<Scalars['Float']>;
  shipment_status_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "shipment" */
export type Shipment_Variance_Order_By = {
  client_order_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  purchase_order_id?: Maybe<Order_By>;
  shipment_status_id?: Maybe<Order_By>;
};

/** columns and relationships of "stock_condition" */
export type Stock_Condition = {
  __typename?: 'stock_condition';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  stock_condition_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wms_stock_change_outputs: Array<Wms_Stock_Change_Output>;
  /** An aggregate relationship */
  wms_stock_change_outputs_aggregate: Wms_Stock_Change_Output_Aggregate;
};


/** columns and relationships of "stock_condition" */
export type Stock_ConditionProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "stock_condition" */
export type Stock_ConditionProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "stock_condition" */
export type Stock_ConditionWms_Stock_Change_OutputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


/** columns and relationships of "stock_condition" */
export type Stock_ConditionWms_Stock_Change_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** aggregated selection of "stock_condition" */
export type Stock_Condition_Aggregate = {
  __typename?: 'stock_condition_aggregate';
  aggregate?: Maybe<Stock_Condition_Aggregate_Fields>;
  nodes: Array<Stock_Condition>;
};

/** aggregate fields of "stock_condition" */
export type Stock_Condition_Aggregate_Fields = {
  __typename?: 'stock_condition_aggregate_fields';
  avg?: Maybe<Stock_Condition_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Condition_Max_Fields>;
  min?: Maybe<Stock_Condition_Min_Fields>;
  stddev?: Maybe<Stock_Condition_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Condition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Condition_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Condition_Sum_Fields>;
  var_pop?: Maybe<Stock_Condition_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Condition_Var_Samp_Fields>;
  variance?: Maybe<Stock_Condition_Variance_Fields>;
};


/** aggregate fields of "stock_condition" */
export type Stock_Condition_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Condition_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Stock_Condition_Avg_Fields = {
  __typename?: 'stock_condition_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "stock_condition". All fields are combined with a logical 'AND'. */
export type Stock_Condition_Bool_Exp = {
  _and?: Maybe<Array<Stock_Condition_Bool_Exp>>;
  _not?: Maybe<Stock_Condition_Bool_Exp>;
  _or?: Maybe<Array<Stock_Condition_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  stock_condition_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_condition" */
export enum Stock_Condition_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockConditionPkey = 'stock_condition_pkey',
  /** unique or primary key constraint on columns "stock_condition_name" */
  StockConditionStockConditionNameKey = 'stock_condition_stock_condition_name_key'
}

/** input type for incrementing numeric columns in table "stock_condition" */
export type Stock_Condition_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_condition" */
export type Stock_Condition_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  stock_condition_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Condition_Max_Fields = {
  __typename?: 'stock_condition_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_condition_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Stock_Condition_Min_Fields = {
  __typename?: 'stock_condition_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_condition_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "stock_condition" */
export type Stock_Condition_Mutation_Response = {
  __typename?: 'stock_condition_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Condition>;
};

/** input type for inserting object relation for remote table "stock_condition" */
export type Stock_Condition_Obj_Rel_Insert_Input = {
  data: Stock_Condition_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Condition_On_Conflict>;
};

/** on_conflict condition type for table "stock_condition" */
export type Stock_Condition_On_Conflict = {
  constraint: Stock_Condition_Constraint;
  update_columns?: Array<Stock_Condition_Update_Column>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_condition". */
export type Stock_Condition_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  stock_condition_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_condition */
export type Stock_Condition_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_condition" */
export enum Stock_Condition_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StockConditionName = 'stock_condition_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stock_condition" */
export type Stock_Condition_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_condition_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stock_Condition_Stddev_Fields = {
  __typename?: 'stock_condition_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Stock_Condition_Stddev_Pop_Fields = {
  __typename?: 'stock_condition_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Stock_Condition_Stddev_Samp_Fields = {
  __typename?: 'stock_condition_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "stock_condition" */
export type Stock_Condition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Condition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Condition_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_condition_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Stock_Condition_Sum_Fields = {
  __typename?: 'stock_condition_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "stock_condition" */
export enum Stock_Condition_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StockConditionName = 'stock_condition_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Stock_Condition_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Condition_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Condition_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Condition_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Condition_Var_Pop_Fields = {
  __typename?: 'stock_condition_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Stock_Condition_Var_Samp_Fields = {
  __typename?: 'stock_condition_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Stock_Condition_Variance_Fields = {
  __typename?: 'stock_condition_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "stock_status" */
export type Stock_Status = {
  __typename?: 'stock_status';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  stock_status_name: Scalars['String'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wms_stock_change_outputs: Array<Wms_Stock_Change_Output>;
  /** An aggregate relationship */
  wms_stock_change_outputs_aggregate: Wms_Stock_Change_Output_Aggregate;
};


/** columns and relationships of "stock_status" */
export type Stock_StatusProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "stock_status" */
export type Stock_StatusProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "stock_status" */
export type Stock_StatusWms_Stock_Change_OutputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


/** columns and relationships of "stock_status" */
export type Stock_StatusWms_Stock_Change_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** aggregated selection of "stock_status" */
export type Stock_Status_Aggregate = {
  __typename?: 'stock_status_aggregate';
  aggregate?: Maybe<Stock_Status_Aggregate_Fields>;
  nodes: Array<Stock_Status>;
};

/** aggregate fields of "stock_status" */
export type Stock_Status_Aggregate_Fields = {
  __typename?: 'stock_status_aggregate_fields';
  avg?: Maybe<Stock_Status_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Status_Max_Fields>;
  min?: Maybe<Stock_Status_Min_Fields>;
  stddev?: Maybe<Stock_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Status_Sum_Fields>;
  var_pop?: Maybe<Stock_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Status_Var_Samp_Fields>;
  variance?: Maybe<Stock_Status_Variance_Fields>;
};


/** aggregate fields of "stock_status" */
export type Stock_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Status_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Stock_Status_Avg_Fields = {
  __typename?: 'stock_status_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "stock_status". All fields are combined with a logical 'AND'. */
export type Stock_Status_Bool_Exp = {
  _and?: Maybe<Array<Stock_Status_Bool_Exp>>;
  _not?: Maybe<Stock_Status_Bool_Exp>;
  _or?: Maybe<Array<Stock_Status_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  stock_status_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "stock_status" */
export enum Stock_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockStatusPkey = 'stock_status_pkey',
  /** unique or primary key constraint on columns "stock_status_name" */
  StockStatusStockStatusNameKey = 'stock_status_stock_status_name_key'
}

/** input type for incrementing numeric columns in table "stock_status" */
export type Stock_Status_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_status" */
export type Stock_Status_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  stock_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Stock_Status_Max_Fields = {
  __typename?: 'stock_status_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate min on columns */
export type Stock_Status_Min_Fields = {
  __typename?: 'stock_status_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** response of any mutation on the table "stock_status" */
export type Stock_Status_Mutation_Response = {
  __typename?: 'stock_status_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Status>;
};

/** input type for inserting object relation for remote table "stock_status" */
export type Stock_Status_Obj_Rel_Insert_Input = {
  data: Stock_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Status_On_Conflict>;
};

/** on_conflict condition type for table "stock_status" */
export type Stock_Status_On_Conflict = {
  constraint: Stock_Status_Constraint;
  update_columns?: Array<Stock_Status_Update_Column>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_status". */
export type Stock_Status_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  stock_status_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Order_By>;
};

/** primary key columns input for table: stock_status */
export type Stock_Status_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_status" */
export enum Stock_Status_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StockStatusName = 'stock_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "stock_status" */
export type Stock_Status_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate stddev on columns */
export type Stock_Status_Stddev_Fields = {
  __typename?: 'stock_status_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Stock_Status_Stddev_Pop_Fields = {
  __typename?: 'stock_status_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Stock_Status_Stddev_Samp_Fields = {
  __typename?: 'stock_status_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "stock_status" */
export type Stock_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  stock_status_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Stock_Status_Sum_Fields = {
  __typename?: 'stock_status_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "stock_status" */
export enum Stock_Status_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  StockStatusName = 'stock_status_name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Stock_Status_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Status_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Status_Var_Pop_Fields = {
  __typename?: 'stock_status_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Stock_Status_Var_Samp_Fields = {
  __typename?: 'stock_status_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Stock_Status_Variance_Fields = {
  __typename?: 'stock_status_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "stock_sync_log" */
export type Stock_Sync_Log = {
  __typename?: 'stock_sync_log';
  actual_quantity: Scalars['Int'];
  created_at: Scalars['timestamptz'];
  expected_quantity: Scalars['Int'];
  id: Scalars['Int'];
  notes?: Maybe<Scalars['String']>;
  /** An object relationship */
  productStockBySourceProductStockId?: Maybe<Product_Stock>;
  /** An object relationship */
  product_stock: Product_Stock;
  product_stock_id: Scalars['Int'];
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** aggregated selection of "stock_sync_log" */
export type Stock_Sync_Log_Aggregate = {
  __typename?: 'stock_sync_log_aggregate';
  aggregate?: Maybe<Stock_Sync_Log_Aggregate_Fields>;
  nodes: Array<Stock_Sync_Log>;
};

export type Stock_Sync_Log_Aggregate_Bool_Exp = {
  count?: Maybe<Stock_Sync_Log_Aggregate_Bool_Exp_Count>;
};

export type Stock_Sync_Log_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Stock_Sync_Log_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "stock_sync_log" */
export type Stock_Sync_Log_Aggregate_Fields = {
  __typename?: 'stock_sync_log_aggregate_fields';
  avg?: Maybe<Stock_Sync_Log_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Stock_Sync_Log_Max_Fields>;
  min?: Maybe<Stock_Sync_Log_Min_Fields>;
  stddev?: Maybe<Stock_Sync_Log_Stddev_Fields>;
  stddev_pop?: Maybe<Stock_Sync_Log_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stock_Sync_Log_Stddev_Samp_Fields>;
  sum?: Maybe<Stock_Sync_Log_Sum_Fields>;
  var_pop?: Maybe<Stock_Sync_Log_Var_Pop_Fields>;
  var_samp?: Maybe<Stock_Sync_Log_Var_Samp_Fields>;
  variance?: Maybe<Stock_Sync_Log_Variance_Fields>;
};


/** aggregate fields of "stock_sync_log" */
export type Stock_Sync_Log_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "stock_sync_log" */
export type Stock_Sync_Log_Aggregate_Order_By = {
  avg?: Maybe<Stock_Sync_Log_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Stock_Sync_Log_Max_Order_By>;
  min?: Maybe<Stock_Sync_Log_Min_Order_By>;
  stddev?: Maybe<Stock_Sync_Log_Stddev_Order_By>;
  stddev_pop?: Maybe<Stock_Sync_Log_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Stock_Sync_Log_Stddev_Samp_Order_By>;
  sum?: Maybe<Stock_Sync_Log_Sum_Order_By>;
  var_pop?: Maybe<Stock_Sync_Log_Var_Pop_Order_By>;
  var_samp?: Maybe<Stock_Sync_Log_Var_Samp_Order_By>;
  variance?: Maybe<Stock_Sync_Log_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "stock_sync_log" */
export type Stock_Sync_Log_Arr_Rel_Insert_Input = {
  data: Array<Stock_Sync_Log_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Stock_Sync_Log_On_Conflict>;
};

/** aggregate avg on columns */
export type Stock_Sync_Log_Avg_Fields = {
  __typename?: 'stock_sync_log_avg_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Avg_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "stock_sync_log". All fields are combined with a logical 'AND'. */
export type Stock_Sync_Log_Bool_Exp = {
  _and?: Maybe<Array<Stock_Sync_Log_Bool_Exp>>;
  _not?: Maybe<Stock_Sync_Log_Bool_Exp>;
  _or?: Maybe<Array<Stock_Sync_Log_Bool_Exp>>;
  actual_quantity?: Maybe<Int_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  expected_quantity?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  notes?: Maybe<String_Comparison_Exp>;
  productStockBySourceProductStockId?: Maybe<Product_Stock_Bool_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  product_stock_id?: Maybe<Int_Comparison_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_output_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "stock_sync_log" */
export enum Stock_Sync_Log_Constraint {
  /** unique or primary key constraint on columns "id" */
  StockSyncLogPkey = 'stock_sync_log_pkey'
}

/** input type for incrementing numeric columns in table "stock_sync_log" */
export type Stock_Sync_Log_Inc_Input = {
  actual_quantity?: Maybe<Scalars['Int']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "stock_sync_log" */
export type Stock_Sync_Log_Insert_Input = {
  actual_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  productStockBySourceProductStockId?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Obj_Rel_Insert_Input>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Stock_Sync_Log_Max_Fields = {
  __typename?: 'stock_sync_log_max_fields';
  actual_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Max_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Stock_Sync_Log_Min_Fields = {
  __typename?: 'stock_sync_log_min_fields';
  actual_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Min_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "stock_sync_log" */
export type Stock_Sync_Log_Mutation_Response = {
  __typename?: 'stock_sync_log_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Stock_Sync_Log>;
};

/** on_conflict condition type for table "stock_sync_log" */
export type Stock_Sync_Log_On_Conflict = {
  constraint: Stock_Sync_Log_Constraint;
  update_columns?: Array<Stock_Sync_Log_Update_Column>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};

/** Ordering options when selecting data from "stock_sync_log". */
export type Stock_Sync_Log_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  notes?: Maybe<Order_By>;
  productStockBySourceProductStockId?: Maybe<Product_Stock_Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_output?: Maybe<Wms_Stock_Change_Output_Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** primary key columns input for table: stock_sync_log */
export type Stock_Sync_Log_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "stock_sync_log" */
export enum Stock_Sync_Log_Select_Column {
  /** column name */
  ActualQuantity = 'actual_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedQuantity = 'expected_quantity',
  /** column name */
  Id = 'id',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProductStockId = 'product_stock_id',
  /** column name */
  SourceProductStockId = 'source_product_stock_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeOutputId = 'wms_stock_change_output_id'
}

/** input type for updating data in table "stock_sync_log" */
export type Stock_Sync_Log_Set_Input = {
  actual_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Stock_Sync_Log_Stddev_Fields = {
  __typename?: 'stock_sync_log_stddev_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Stddev_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Stock_Sync_Log_Stddev_Pop_Fields = {
  __typename?: 'stock_sync_log_stddev_pop_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Stddev_Pop_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Stock_Sync_Log_Stddev_Samp_Fields = {
  __typename?: 'stock_sync_log_stddev_samp_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Stddev_Samp_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "stock_sync_log" */
export type Stock_Sync_Log_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stock_Sync_Log_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stock_Sync_Log_Stream_Cursor_Value_Input = {
  actual_quantity?: Maybe<Scalars['Int']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  notes?: Maybe<Scalars['String']>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Stock_Sync_Log_Sum_Fields = {
  __typename?: 'stock_sync_log_sum_fields';
  actual_quantity?: Maybe<Scalars['Int']>;
  expected_quantity?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  product_stock_id?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  wms_stock_change_output_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Sum_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** update columns of table "stock_sync_log" */
export enum Stock_Sync_Log_Update_Column {
  /** column name */
  ActualQuantity = 'actual_quantity',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpectedQuantity = 'expected_quantity',
  /** column name */
  Notes = 'notes',
  /** column name */
  ProductStockId = 'product_stock_id',
  /** column name */
  SourceProductStockId = 'source_product_stock_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeOutputId = 'wms_stock_change_output_id'
}

export type Stock_Sync_Log_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Stock_Sync_Log_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Stock_Sync_Log_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stock_Sync_Log_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stock_Sync_Log_Var_Pop_Fields = {
  __typename?: 'stock_sync_log_var_pop_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Var_Pop_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Stock_Sync_Log_Var_Samp_Fields = {
  __typename?: 'stock_sync_log_var_samp_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Var_Samp_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Stock_Sync_Log_Variance_Fields = {
  __typename?: 'stock_sync_log_variance_fields';
  actual_quantity?: Maybe<Scalars['Float']>;
  expected_quantity?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  product_stock_id?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_output_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "stock_sync_log" */
export type Stock_Sync_Log_Variance_Order_By = {
  actual_quantity?: Maybe<Order_By>;
  expected_quantity?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  product_stock_id?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_output_id?: Maybe<Order_By>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "address" */
  address: Array<Address>;
  /** fetch aggregated fields from the table: "address" */
  address_aggregate: Address_Aggregate;
  /** fetch data from the table: "address" using primary key columns */
  address_by_pk?: Maybe<Address>;
  /** fetch data from the table in a streaming manner: "address" */
  address_stream: Array<Address>;
  /** fetch data from the table: "attachment" */
  attachment: Array<Attachment>;
  /** fetch aggregated fields from the table: "attachment" */
  attachment_aggregate: Attachment_Aggregate;
  /** fetch data from the table: "attachment" using primary key columns */
  attachment_by_pk?: Maybe<Attachment>;
  /** fetch data from the table in a streaming manner: "attachment" */
  attachment_stream: Array<Attachment>;
  /** fetch data from the table: "client_order" */
  client_order: Array<Client_Order>;
  /** fetch aggregated fields from the table: "client_order" */
  client_order_aggregate: Client_Order_Aggregate;
  /** fetch data from the table: "client_order" using primary key columns */
  client_order_by_pk?: Maybe<Client_Order>;
  /** fetch data from the table in a streaming manner: "client_order" */
  client_order_stream: Array<Client_Order>;
  /** fetch data from the table: "country" */
  country: Array<Country>;
  /** fetch aggregated fields from the table: "country" */
  country_aggregate: Country_Aggregate;
  /** fetch data from the table: "country" using primary key columns */
  country_by_pk?: Maybe<Country>;
  /** fetch data from the table in a streaming manner: "country" */
  country_stream: Array<Country>;
  /** fetch data from the table: "databasechangelog" */
  databasechangelog: Array<Databasechangelog>;
  /** fetch aggregated fields from the table: "databasechangelog" */
  databasechangelog_aggregate: Databasechangelog_Aggregate;
  /** fetch data from the table in a streaming manner: "databasechangelog" */
  databasechangelog_stream: Array<Databasechangelog>;
  /** fetch data from the table: "databasechangeloglock" */
  databasechangeloglock: Array<Databasechangeloglock>;
  /** fetch aggregated fields from the table: "databasechangeloglock" */
  databasechangeloglock_aggregate: Databasechangeloglock_Aggregate;
  /** fetch data from the table: "databasechangeloglock" using primary key columns */
  databasechangeloglock_by_pk?: Maybe<Databasechangeloglock>;
  /** fetch data from the table in a streaming manner: "databasechangeloglock" */
  databasechangeloglock_stream: Array<Databasechangeloglock>;
  /** fetch data from the table: "folder" */
  folder: Array<Folder>;
  /** fetch aggregated fields from the table: "folder" */
  folder_aggregate: Folder_Aggregate;
  /** fetch data from the table: "folder" using primary key columns */
  folder_by_pk?: Maybe<Folder>;
  /** fetch data from the table in a streaming manner: "folder" */
  folder_stream: Array<Folder>;
  /** fetch data from the table: "inbound_manifest" */
  inbound_manifest: Array<Inbound_Manifest>;
  /** fetch aggregated fields from the table: "inbound_manifest" */
  inbound_manifest_aggregate: Inbound_Manifest_Aggregate;
  /** fetch data from the table: "inbound_manifest" using primary key columns */
  inbound_manifest_by_pk?: Maybe<Inbound_Manifest>;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line: Array<Inbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line_aggregate: Inbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_fulfilment_line" using primary key columns */
  inbound_manifest_fulfilment_line_by_pk?: Maybe<Inbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_fulfilment_line" */
  inbound_manifest_fulfilment_line_stream: Array<Inbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "inbound_manifest_line" */
  inbound_manifest_line: Array<Inbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "inbound_manifest_line" */
  inbound_manifest_line_aggregate: Inbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "inbound_manifest_line" using primary key columns */
  inbound_manifest_line_by_pk?: Maybe<Inbound_Manifest_Line>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_line" */
  inbound_manifest_line_stream: Array<Inbound_Manifest_Line>;
  /** fetch data from the table: "inbound_manifest_status" */
  inbound_manifest_status: Array<Inbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "inbound_manifest_status" */
  inbound_manifest_status_aggregate: Inbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "inbound_manifest_status" using primary key columns */
  inbound_manifest_status_by_pk?: Maybe<Inbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "inbound_manifest_status" */
  inbound_manifest_status_stream: Array<Inbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "inbound_manifest" */
  inbound_manifest_stream: Array<Inbound_Manifest>;
  /** fetch data from the table: "logistics_order" */
  logistics_order: Array<Logistics_Order>;
  /** fetch aggregated fields from the table: "logistics_order" */
  logistics_order_aggregate: Logistics_Order_Aggregate;
  /** fetch data from the table: "logistics_order" using primary key columns */
  logistics_order_by_pk?: Maybe<Logistics_Order>;
  /** fetch data from the table: "logistics_order_status" */
  logistics_order_status: Array<Logistics_Order_Status>;
  /** fetch aggregated fields from the table: "logistics_order_status" */
  logistics_order_status_aggregate: Logistics_Order_Status_Aggregate;
  /** fetch data from the table: "logistics_order_status" using primary key columns */
  logistics_order_status_by_pk?: Maybe<Logistics_Order_Status>;
  /** fetch data from the table in a streaming manner: "logistics_order_status" */
  logistics_order_status_stream: Array<Logistics_Order_Status>;
  /** fetch data from the table in a streaming manner: "logistics_order" */
  logistics_order_stream: Array<Logistics_Order>;
  /** fetch data from the table: "organisation" */
  organisation: Array<Organisation>;
  /** fetch aggregated fields from the table: "organisation" */
  organisation_aggregate: Organisation_Aggregate;
  /** fetch data from the table: "organisation" using primary key columns */
  organisation_by_pk?: Maybe<Organisation>;
  /** fetch data from the table in a streaming manner: "organisation" */
  organisation_stream: Array<Organisation>;
  /** fetch data from the table: "outbound_manifest" */
  outbound_manifest: Array<Outbound_Manifest>;
  /** fetch aggregated fields from the table: "outbound_manifest" */
  outbound_manifest_aggregate: Outbound_Manifest_Aggregate;
  /** fetch data from the table: "outbound_manifest" using primary key columns */
  outbound_manifest_by_pk?: Maybe<Outbound_Manifest>;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line: Array<Outbound_Manifest_Fulfilment_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line_aggregate: Outbound_Manifest_Fulfilment_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_fulfilment_line" using primary key columns */
  outbound_manifest_fulfilment_line_by_pk?: Maybe<Outbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_fulfilment_line" */
  outbound_manifest_fulfilment_line_stream: Array<Outbound_Manifest_Fulfilment_Line>;
  /** fetch data from the table: "outbound_manifest_line" */
  outbound_manifest_line: Array<Outbound_Manifest_Line>;
  /** fetch aggregated fields from the table: "outbound_manifest_line" */
  outbound_manifest_line_aggregate: Outbound_Manifest_Line_Aggregate;
  /** fetch data from the table: "outbound_manifest_line" using primary key columns */
  outbound_manifest_line_by_pk?: Maybe<Outbound_Manifest_Line>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_line" */
  outbound_manifest_line_stream: Array<Outbound_Manifest_Line>;
  /** fetch data from the table: "outbound_manifest_status" */
  outbound_manifest_status: Array<Outbound_Manifest_Status>;
  /** fetch aggregated fields from the table: "outbound_manifest_status" */
  outbound_manifest_status_aggregate: Outbound_Manifest_Status_Aggregate;
  /** fetch data from the table: "outbound_manifest_status" using primary key columns */
  outbound_manifest_status_by_pk?: Maybe<Outbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest_status" */
  outbound_manifest_status_stream: Array<Outbound_Manifest_Status>;
  /** fetch data from the table in a streaming manner: "outbound_manifest" */
  outbound_manifest_stream: Array<Outbound_Manifest>;
  /** fetch data from the table: "product" */
  product: Array<Product>;
  /** fetch aggregated fields from the table: "product" */
  product_aggregate: Product_Aggregate;
  /** fetch data from the table: "product" using primary key columns */
  product_by_pk?: Maybe<Product>;
  /** fetch data from the table: "product_stock" */
  product_stock: Array<Product_Stock>;
  /** fetch aggregated fields from the table: "product_stock" */
  product_stock_aggregate: Product_Stock_Aggregate;
  /** fetch data from the table: "product_stock" using primary key columns */
  product_stock_by_pk?: Maybe<Product_Stock>;
  /** fetch data from the table in a streaming manner: "product_stock" */
  product_stock_stream: Array<Product_Stock>;
  /** fetch data from the table in a streaming manner: "product" */
  product_stream: Array<Product>;
  /** fetch data from the table: "purchase_order" */
  purchase_order: Array<Purchase_Order>;
  /** fetch aggregated fields from the table: "purchase_order" */
  purchase_order_aggregate: Purchase_Order_Aggregate;
  /** fetch data from the table: "purchase_order" using primary key columns */
  purchase_order_by_pk?: Maybe<Purchase_Order>;
  /** fetch data from the table in a streaming manner: "purchase_order" */
  purchase_order_stream: Array<Purchase_Order>;
  /** fetch data from the table: "service_account" */
  service_account: Array<Service_Account>;
  /** fetch aggregated fields from the table: "service_account" */
  service_account_aggregate: Service_Account_Aggregate;
  /** fetch data from the table: "service_account" using primary key columns */
  service_account_by_pk?: Maybe<Service_Account>;
  /** fetch data from the table in a streaming manner: "service_account" */
  service_account_stream: Array<Service_Account>;
  /** fetch data from the table: "shipment" */
  shipment: Array<Shipment>;
  /** fetch aggregated fields from the table: "shipment" */
  shipment_aggregate: Shipment_Aggregate;
  /** fetch data from the table: "shipment" using primary key columns */
  shipment_by_pk?: Maybe<Shipment>;
  /** fetch data from the table: "shipment_status" */
  shipment_status: Array<Shipment_Status>;
  /** fetch aggregated fields from the table: "shipment_status" */
  shipment_status_aggregate: Shipment_Status_Aggregate;
  /** fetch data from the table: "shipment_status" using primary key columns */
  shipment_status_by_pk?: Maybe<Shipment_Status>;
  /** fetch data from the table in a streaming manner: "shipment_status" */
  shipment_status_stream: Array<Shipment_Status>;
  /** fetch data from the table in a streaming manner: "shipment" */
  shipment_stream: Array<Shipment>;
  /** fetch data from the table: "shipment_update" */
  shipment_update: Array<Shipment_Update>;
  /** fetch aggregated fields from the table: "shipment_update" */
  shipment_update_aggregate: Shipment_Update_Aggregate;
  /** fetch data from the table: "shipment_update" using primary key columns */
  shipment_update_by_pk?: Maybe<Shipment_Update>;
  /** fetch data from the table in a streaming manner: "shipment_update" */
  shipment_update_stream: Array<Shipment_Update>;
  /** fetch data from the table: "shipment_update_type" */
  shipment_update_type: Array<Shipment_Update_Type>;
  /** fetch aggregated fields from the table: "shipment_update_type" */
  shipment_update_type_aggregate: Shipment_Update_Type_Aggregate;
  /** fetch data from the table: "shipment_update_type" using primary key columns */
  shipment_update_type_by_pk?: Maybe<Shipment_Update_Type>;
  /** fetch data from the table in a streaming manner: "shipment_update_type" */
  shipment_update_type_stream: Array<Shipment_Update_Type>;
  /** fetch data from the table: "stock_condition" */
  stock_condition: Array<Stock_Condition>;
  /** fetch aggregated fields from the table: "stock_condition" */
  stock_condition_aggregate: Stock_Condition_Aggregate;
  /** fetch data from the table: "stock_condition" using primary key columns */
  stock_condition_by_pk?: Maybe<Stock_Condition>;
  /** fetch data from the table in a streaming manner: "stock_condition" */
  stock_condition_stream: Array<Stock_Condition>;
  /** fetch data from the table: "stock_status" */
  stock_status: Array<Stock_Status>;
  /** fetch aggregated fields from the table: "stock_status" */
  stock_status_aggregate: Stock_Status_Aggregate;
  /** fetch data from the table: "stock_status" using primary key columns */
  stock_status_by_pk?: Maybe<Stock_Status>;
  /** fetch data from the table in a streaming manner: "stock_status" */
  stock_status_stream: Array<Stock_Status>;
  /** fetch data from the table: "stock_sync_log" */
  stock_sync_log: Array<Stock_Sync_Log>;
  /** fetch aggregated fields from the table: "stock_sync_log" */
  stock_sync_log_aggregate: Stock_Sync_Log_Aggregate;
  /** fetch data from the table: "stock_sync_log" using primary key columns */
  stock_sync_log_by_pk?: Maybe<Stock_Sync_Log>;
  /** fetch data from the table in a streaming manner: "stock_sync_log" */
  stock_sync_log_stream: Array<Stock_Sync_Log>;
  /** fetch data from the table: "warehouse" */
  warehouse: Array<Warehouse>;
  /** fetch aggregated fields from the table: "warehouse" */
  warehouse_aggregate: Warehouse_Aggregate;
  /** fetch data from the table: "warehouse" using primary key columns */
  warehouse_by_pk?: Maybe<Warehouse>;
  /** fetch data from the table in a streaming manner: "warehouse" */
  warehouse_stream: Array<Warehouse>;
  /** fetch data from the table: "wms_stock_change" */
  wms_stock_change: Array<Wms_Stock_Change>;
  /** fetch aggregated fields from the table: "wms_stock_change" */
  wms_stock_change_aggregate: Wms_Stock_Change_Aggregate;
  /** fetch data from the table: "wms_stock_change" using primary key columns */
  wms_stock_change_by_pk?: Maybe<Wms_Stock_Change>;
  /** fetch data from the table: "wms_stock_change_input" */
  wms_stock_change_input: Array<Wms_Stock_Change_Input>;
  /** fetch aggregated fields from the table: "wms_stock_change_input" */
  wms_stock_change_input_aggregate: Wms_Stock_Change_Input_Aggregate;
  /** fetch data from the table: "wms_stock_change_input" using primary key columns */
  wms_stock_change_input_by_pk?: Maybe<Wms_Stock_Change_Input>;
  /** fetch data from the table in a streaming manner: "wms_stock_change_input" */
  wms_stock_change_input_stream: Array<Wms_Stock_Change_Input>;
  /** fetch data from the table: "wms_stock_change_output" */
  wms_stock_change_output: Array<Wms_Stock_Change_Output>;
  /** fetch aggregated fields from the table: "wms_stock_change_output" */
  wms_stock_change_output_aggregate: Wms_Stock_Change_Output_Aggregate;
  /** fetch data from the table: "wms_stock_change_output" using primary key columns */
  wms_stock_change_output_by_pk?: Maybe<Wms_Stock_Change_Output>;
  /** fetch data from the table in a streaming manner: "wms_stock_change_output" */
  wms_stock_change_output_stream: Array<Wms_Stock_Change_Output>;
  /** fetch data from the table in a streaming manner: "wms_stock_change" */
  wms_stock_change_stream: Array<Wms_Stock_Change>;
  /** fetch data from the table: "wms_stock_change_type" */
  wms_stock_change_type: Array<Wms_Stock_Change_Type>;
  /** fetch aggregated fields from the table: "wms_stock_change_type" */
  wms_stock_change_type_aggregate: Wms_Stock_Change_Type_Aggregate;
  /** fetch data from the table: "wms_stock_change_type" using primary key columns */
  wms_stock_change_type_by_pk?: Maybe<Wms_Stock_Change_Type>;
  /** fetch data from the table in a streaming manner: "wms_stock_change_type" */
  wms_stock_change_type_stream: Array<Wms_Stock_Change_Type>;
};


export type Subscription_RootAddressArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAddress_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAddress_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Address_Stream_Cursor_Input>>;
  where?: Maybe<Address_Bool_Exp>;
};


export type Subscription_RootAttachmentArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Subscription_RootAttachment_AggregateArgs = {
  distinct_on?: Maybe<Array<Attachment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Attachment_Order_By>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Subscription_RootAttachment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootAttachment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Attachment_Stream_Cursor_Input>>;
  where?: Maybe<Attachment_Bool_Exp>;
};


export type Subscription_RootClient_OrderArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


export type Subscription_RootClient_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Client_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Client_Order_Order_By>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


export type Subscription_RootClient_Order_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootClient_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Client_Order_Stream_Cursor_Input>>;
  where?: Maybe<Client_Order_Bool_Exp>;
};


export type Subscription_RootCountryArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_AggregateArgs = {
  distinct_on?: Maybe<Array<Country_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Country_Order_By>>;
  where?: Maybe<Country_Bool_Exp>;
};


export type Subscription_RootCountry_By_PkArgs = {
  country_code: Scalars['String'];
};


export type Subscription_RootCountry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Country_Stream_Cursor_Input>>;
  where?: Maybe<Country_Bool_Exp>;
};


export type Subscription_RootDatabasechangelogArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};


export type Subscription_RootDatabasechangelog_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangelog_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangelog_Order_By>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};


export type Subscription_RootDatabasechangelog_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Databasechangelog_Stream_Cursor_Input>>;
  where?: Maybe<Databasechangelog_Bool_Exp>;
};


export type Subscription_RootDatabasechangeloglockArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};


export type Subscription_RootDatabasechangeloglock_AggregateArgs = {
  distinct_on?: Maybe<Array<Databasechangeloglock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Databasechangeloglock_Order_By>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};


export type Subscription_RootDatabasechangeloglock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootDatabasechangeloglock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Databasechangeloglock_Stream_Cursor_Input>>;
  where?: Maybe<Databasechangeloglock_Bool_Exp>;
};


export type Subscription_RootFolderArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};


export type Subscription_RootFolder_AggregateArgs = {
  distinct_on?: Maybe<Array<Folder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Folder_Order_By>>;
  where?: Maybe<Folder_Bool_Exp>;
};


export type Subscription_RootFolder_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootFolder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Folder_Stream_Cursor_Input>>;
  where?: Maybe<Folder_Bool_Exp>;
};


export type Subscription_RootInbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInbound_Manifest_Fulfilment_Line_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Inbound_Manifest_Fulfilment_Line_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Line_Order_By>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInbound_Manifest_Line_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Inbound_Manifest_Line_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Line_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Status_Order_By>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootInbound_Manifest_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Inbound_Manifest_Status_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Status_Bool_Exp>;
};


export type Subscription_RootInbound_Manifest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Inbound_Manifest_Stream_Cursor_Input>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


export type Subscription_RootLogistics_OrderArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


export type Subscription_RootLogistics_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Order_By>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


export type Subscription_RootLogistics_Order_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLogistics_Order_StatusArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Status_Order_By>>;
  where?: Maybe<Logistics_Order_Status_Bool_Exp>;
};


export type Subscription_RootLogistics_Order_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Logistics_Order_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Logistics_Order_Status_Order_By>>;
  where?: Maybe<Logistics_Order_Status_Bool_Exp>;
};


export type Subscription_RootLogistics_Order_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootLogistics_Order_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Logistics_Order_Status_Stream_Cursor_Input>>;
  where?: Maybe<Logistics_Order_Status_Bool_Exp>;
};


export type Subscription_RootLogistics_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Logistics_Order_Stream_Cursor_Input>>;
  where?: Maybe<Logistics_Order_Bool_Exp>;
};


export type Subscription_RootOrganisationArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOrganisation_AggregateArgs = {
  distinct_on?: Maybe<Array<Organisation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Organisation_Order_By>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOrganisation_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOrganisation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Organisation_Stream_Cursor_Input>>;
  where?: Maybe<Organisation_Bool_Exp>;
};


export type Subscription_RootOutbound_ManifestArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutbound_Manifest_Fulfilment_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_Fulfilment_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Fulfilment_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_Fulfilment_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutbound_Manifest_Fulfilment_Line_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Outbound_Manifest_Fulfilment_Line_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Fulfilment_Line_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_LineArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_Line_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Line_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Line_Order_By>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_Line_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutbound_Manifest_Line_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Outbound_Manifest_Line_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Line_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_StatusArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Status_Order_By>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootOutbound_Manifest_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Outbound_Manifest_Status_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Status_Bool_Exp>;
};


export type Subscription_RootOutbound_Manifest_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Outbound_Manifest_Stream_Cursor_Input>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


export type Subscription_RootProductArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Order_By>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootProduct_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProduct_StockArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


export type Subscription_RootProduct_Stock_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


export type Subscription_RootProduct_Stock_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootProduct_Stock_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Product_Stock_Stream_Cursor_Input>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


export type Subscription_RootProduct_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Product_Stream_Cursor_Input>>;
  where?: Maybe<Product_Bool_Exp>;
};


export type Subscription_RootPurchase_OrderArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


export type Subscription_RootPurchase_Order_AggregateArgs = {
  distinct_on?: Maybe<Array<Purchase_Order_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Purchase_Order_Order_By>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


export type Subscription_RootPurchase_Order_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootPurchase_Order_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Purchase_Order_Stream_Cursor_Input>>;
  where?: Maybe<Purchase_Order_Bool_Exp>;
};


export type Subscription_RootService_AccountArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};


export type Subscription_RootService_Account_AggregateArgs = {
  distinct_on?: Maybe<Array<Service_Account_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Service_Account_Order_By>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};


export type Subscription_RootService_Account_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootService_Account_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Service_Account_Stream_Cursor_Input>>;
  where?: Maybe<Service_Account_Bool_Exp>;
};


export type Subscription_RootShipmentArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


export type Subscription_RootShipment_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Order_By>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


export type Subscription_RootShipment_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootShipment_StatusArgs = {
  distinct_on?: Maybe<Array<Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Status_Order_By>>;
  where?: Maybe<Shipment_Status_Bool_Exp>;
};


export type Subscription_RootShipment_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Status_Order_By>>;
  where?: Maybe<Shipment_Status_Bool_Exp>;
};


export type Subscription_RootShipment_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootShipment_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Shipment_Status_Stream_Cursor_Input>>;
  where?: Maybe<Shipment_Status_Bool_Exp>;
};


export type Subscription_RootShipment_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Shipment_Stream_Cursor_Input>>;
  where?: Maybe<Shipment_Bool_Exp>;
};


export type Subscription_RootShipment_UpdateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


export type Subscription_RootShipment_Update_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Order_By>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


export type Subscription_RootShipment_Update_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootShipment_Update_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Shipment_Update_Stream_Cursor_Input>>;
  where?: Maybe<Shipment_Update_Bool_Exp>;
};


export type Subscription_RootShipment_Update_TypeArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Type_Order_By>>;
  where?: Maybe<Shipment_Update_Type_Bool_Exp>;
};


export type Subscription_RootShipment_Update_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Shipment_Update_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Shipment_Update_Type_Order_By>>;
  where?: Maybe<Shipment_Update_Type_Bool_Exp>;
};


export type Subscription_RootShipment_Update_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootShipment_Update_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Shipment_Update_Type_Stream_Cursor_Input>>;
  where?: Maybe<Shipment_Update_Type_Bool_Exp>;
};


export type Subscription_RootStock_ConditionArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};


export type Subscription_RootStock_Condition_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Condition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Condition_Order_By>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};


export type Subscription_RootStock_Condition_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStock_Condition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stock_Condition_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Condition_Bool_Exp>;
};


export type Subscription_RootStock_StatusArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};


export type Subscription_RootStock_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Status_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Status_Order_By>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};


export type Subscription_RootStock_Status_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStock_Status_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stock_Status_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Status_Bool_Exp>;
};


export type Subscription_RootStock_Sync_LogArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


export type Subscription_RootStock_Sync_Log_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


export type Subscription_RootStock_Sync_Log_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootStock_Sync_Log_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Stock_Sync_Log_Stream_Cursor_Input>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


export type Subscription_RootWarehouseArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


export type Subscription_RootWarehouse_AggregateArgs = {
  distinct_on?: Maybe<Array<Warehouse_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Warehouse_Order_By>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


export type Subscription_RootWarehouse_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWarehouse_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Warehouse_Stream_Cursor_Input>>;
  where?: Maybe<Warehouse_Bool_Exp>;
};


export type Subscription_RootWms_Stock_ChangeArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWms_Stock_Change_InputArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_Input_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_Input_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWms_Stock_Change_Input_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Wms_Stock_Change_Input_Stream_Cursor_Input>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_OutputArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_Output_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_Output_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWms_Stock_Change_Output_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Wms_Stock_Change_Output_Stream_Cursor_Input>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Wms_Stock_Change_Stream_Cursor_Input>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_TypeArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Type_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Type_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Type_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
};


export type Subscription_RootWms_Stock_Change_Type_By_PkArgs = {
  id: Scalars['Int'];
};


export type Subscription_RootWms_Stock_Change_Type_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<Wms_Stock_Change_Type_Stream_Cursor_Input>>;
  where?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};


/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars['uuid']>;
  _gt?: Maybe<Scalars['uuid']>;
  _gte?: Maybe<Scalars['uuid']>;
  _in?: Maybe<Array<Scalars['uuid']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['uuid']>;
  _lte?: Maybe<Scalars['uuid']>;
  _neq?: Maybe<Scalars['uuid']>;
  _nin?: Maybe<Array<Scalars['uuid']>>;
};

/** columns and relationships of "warehouse" */
export type Warehouse = {
  __typename?: 'warehouse';
  /** An array relationship */
  addresses: Array<Address>;
  /** An aggregate relationship */
  addresses_aggregate: Address_Aggregate;
  created_at: Scalars['timestamptz'];
  external_warehouse_id?: Maybe<Scalars['Int']>;
  id: Scalars['Int'];
  /** An array relationship */
  inbound_manifests: Array<Inbound_Manifest>;
  /** An aggregate relationship */
  inbound_manifests_aggregate: Inbound_Manifest_Aggregate;
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['Int'];
  /** An array relationship */
  outbound_manifests: Array<Outbound_Manifest>;
  /** An aggregate relationship */
  outbound_manifests_aggregate: Outbound_Manifest_Aggregate;
  /** An object relationship */
  service_account: Service_Account;
  service_account_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  warehouse_name: Scalars['String'];
};


/** columns and relationships of "warehouse" */
export type WarehouseAddressesArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "warehouse" */
export type WarehouseAddresses_AggregateArgs = {
  distinct_on?: Maybe<Array<Address_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Address_Order_By>>;
  where?: Maybe<Address_Bool_Exp>;
};


/** columns and relationships of "warehouse" */
export type WarehouseInbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "warehouse" */
export type WarehouseInbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Inbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Inbound_Manifest_Order_By>>;
  where?: Maybe<Inbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "warehouse" */
export type WarehouseOutbound_ManifestsArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};


/** columns and relationships of "warehouse" */
export type WarehouseOutbound_Manifests_AggregateArgs = {
  distinct_on?: Maybe<Array<Outbound_Manifest_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Outbound_Manifest_Order_By>>;
  where?: Maybe<Outbound_Manifest_Bool_Exp>;
};

/** aggregated selection of "warehouse" */
export type Warehouse_Aggregate = {
  __typename?: 'warehouse_aggregate';
  aggregate?: Maybe<Warehouse_Aggregate_Fields>;
  nodes: Array<Warehouse>;
};

export type Warehouse_Aggregate_Bool_Exp = {
  count?: Maybe<Warehouse_Aggregate_Bool_Exp_Count>;
};

export type Warehouse_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Warehouse_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Warehouse_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "warehouse" */
export type Warehouse_Aggregate_Fields = {
  __typename?: 'warehouse_aggregate_fields';
  avg?: Maybe<Warehouse_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Warehouse_Max_Fields>;
  min?: Maybe<Warehouse_Min_Fields>;
  stddev?: Maybe<Warehouse_Stddev_Fields>;
  stddev_pop?: Maybe<Warehouse_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Warehouse_Stddev_Samp_Fields>;
  sum?: Maybe<Warehouse_Sum_Fields>;
  var_pop?: Maybe<Warehouse_Var_Pop_Fields>;
  var_samp?: Maybe<Warehouse_Var_Samp_Fields>;
  variance?: Maybe<Warehouse_Variance_Fields>;
};


/** aggregate fields of "warehouse" */
export type Warehouse_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Warehouse_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "warehouse" */
export type Warehouse_Aggregate_Order_By = {
  avg?: Maybe<Warehouse_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Warehouse_Max_Order_By>;
  min?: Maybe<Warehouse_Min_Order_By>;
  stddev?: Maybe<Warehouse_Stddev_Order_By>;
  stddev_pop?: Maybe<Warehouse_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Warehouse_Stddev_Samp_Order_By>;
  sum?: Maybe<Warehouse_Sum_Order_By>;
  var_pop?: Maybe<Warehouse_Var_Pop_Order_By>;
  var_samp?: Maybe<Warehouse_Var_Samp_Order_By>;
  variance?: Maybe<Warehouse_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "warehouse" */
export type Warehouse_Arr_Rel_Insert_Input = {
  data: Array<Warehouse_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};

/** aggregate avg on columns */
export type Warehouse_Avg_Fields = {
  __typename?: 'warehouse_avg_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "warehouse" */
export type Warehouse_Avg_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "warehouse". All fields are combined with a logical 'AND'. */
export type Warehouse_Bool_Exp = {
  _and?: Maybe<Array<Warehouse_Bool_Exp>>;
  _not?: Maybe<Warehouse_Bool_Exp>;
  _or?: Maybe<Array<Warehouse_Bool_Exp>>;
  addresses?: Maybe<Address_Bool_Exp>;
  addresses_aggregate?: Maybe<Address_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_warehouse_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  inbound_manifests?: Maybe<Inbound_Manifest_Bool_Exp>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Bool_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  outbound_manifests?: Maybe<Outbound_Manifest_Bool_Exp>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Bool_Exp>;
  service_account?: Maybe<Service_Account_Bool_Exp>;
  service_account_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  warehouse_name?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "warehouse" */
export enum Warehouse_Constraint {
  /** unique or primary key constraint on columns "id" */
  WarehousePkey = 'warehouse_pkey',
  /** unique or primary key constraint on columns "warehouse_name" */
  WarehouseWarehouseNameKey = 'warehouse_warehouse_name_key'
}

/** input type for incrementing numeric columns in table "warehouse" */
export type Warehouse_Inc_Input = {
  external_warehouse_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "warehouse" */
export type Warehouse_Insert_Input = {
  addresses?: Maybe<Address_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_warehouse_id?: Maybe<Scalars['Int']>;
  inbound_manifests?: Maybe<Inbound_Manifest_Arr_Rel_Insert_Input>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars['Int']>;
  outbound_manifests?: Maybe<Outbound_Manifest_Arr_Rel_Insert_Input>;
  service_account?: Maybe<Service_Account_Obj_Rel_Insert_Input>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_name?: Maybe<Scalars['String']>;
};

/** aggregate max on columns */
export type Warehouse_Max_Fields = {
  __typename?: 'warehouse_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_warehouse_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "warehouse" */
export type Warehouse_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_name?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Warehouse_Min_Fields = {
  __typename?: 'warehouse_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_warehouse_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "warehouse" */
export type Warehouse_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_name?: Maybe<Order_By>;
};

/** response of any mutation on the table "warehouse" */
export type Warehouse_Mutation_Response = {
  __typename?: 'warehouse_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Warehouse>;
};

/** input type for inserting object relation for remote table "warehouse" */
export type Warehouse_Obj_Rel_Insert_Input = {
  data: Warehouse_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Warehouse_On_Conflict>;
};

/** on_conflict condition type for table "warehouse" */
export type Warehouse_On_Conflict = {
  constraint: Warehouse_Constraint;
  update_columns?: Array<Warehouse_Update_Column>;
  where?: Maybe<Warehouse_Bool_Exp>;
};

/** Ordering options when selecting data from "warehouse". */
export type Warehouse_Order_By = {
  addresses_aggregate?: Maybe<Address_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  inbound_manifests_aggregate?: Maybe<Inbound_Manifest_Aggregate_Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  outbound_manifests_aggregate?: Maybe<Outbound_Manifest_Aggregate_Order_By>;
  service_account?: Maybe<Service_Account_Order_By>;
  service_account_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  warehouse_name?: Maybe<Order_By>;
};

/** primary key columns input for table: warehouse */
export type Warehouse_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "warehouse" */
export enum Warehouse_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalWarehouseId = 'external_warehouse_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseName = 'warehouse_name'
}

/** input type for updating data in table "warehouse" */
export type Warehouse_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_warehouse_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Warehouse_Stddev_Fields = {
  __typename?: 'warehouse_stddev_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "warehouse" */
export type Warehouse_Stddev_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Warehouse_Stddev_Pop_Fields = {
  __typename?: 'warehouse_stddev_pop_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "warehouse" */
export type Warehouse_Stddev_Pop_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Warehouse_Stddev_Samp_Fields = {
  __typename?: 'warehouse_stddev_samp_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "warehouse" */
export type Warehouse_Stddev_Samp_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "warehouse" */
export type Warehouse_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Warehouse_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Warehouse_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_warehouse_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  warehouse_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Warehouse_Sum_Fields = {
  __typename?: 'warehouse_sum_fields';
  external_warehouse_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  service_account_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "warehouse" */
export type Warehouse_Sum_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** update columns of table "warehouse" */
export enum Warehouse_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalWarehouseId = 'external_warehouse_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ServiceAccountId = 'service_account_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WarehouseName = 'warehouse_name'
}

export type Warehouse_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Warehouse_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Warehouse_Set_Input>;
  /** filter the rows which have to be updated */
  where: Warehouse_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Warehouse_Var_Pop_Fields = {
  __typename?: 'warehouse_var_pop_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "warehouse" */
export type Warehouse_Var_Pop_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Warehouse_Var_Samp_Fields = {
  __typename?: 'warehouse_var_samp_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "warehouse" */
export type Warehouse_Var_Samp_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Warehouse_Variance_Fields = {
  __typename?: 'warehouse_variance_fields';
  external_warehouse_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  service_account_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "warehouse" */
export type Warehouse_Variance_Order_By = {
  external_warehouse_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  service_account_id?: Maybe<Order_By>;
};

/** columns and relationships of "wms_stock_change" */
export type Wms_Stock_Change = {
  __typename?: 'wms_stock_change';
  created_at: Scalars['timestamptz'];
  external_id: Scalars['Int'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An array relationship */
  wms_stock_change_inputs: Array<Wms_Stock_Change_Input>;
  /** An aggregate relationship */
  wms_stock_change_inputs_aggregate: Wms_Stock_Change_Input_Aggregate;
  /** An array relationship */
  wms_stock_change_outputs: Array<Wms_Stock_Change_Output>;
  /** An aggregate relationship */
  wms_stock_change_outputs_aggregate: Wms_Stock_Change_Output_Aggregate;
  /** An object relationship */
  wms_stock_change_type: Wms_Stock_Change_Type;
  wms_stock_change_type_id: Scalars['Int'];
};


/** columns and relationships of "wms_stock_change" */
export type Wms_Stock_ChangeWms_Stock_Change_InputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change" */
export type Wms_Stock_ChangeWms_Stock_Change_Inputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Input_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change" */
export type Wms_Stock_ChangeWms_Stock_Change_OutputsArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change" */
export type Wms_Stock_ChangeWms_Stock_Change_Outputs_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Output_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** aggregated selection of "wms_stock_change" */
export type Wms_Stock_Change_Aggregate = {
  __typename?: 'wms_stock_change_aggregate';
  aggregate?: Maybe<Wms_Stock_Change_Aggregate_Fields>;
  nodes: Array<Wms_Stock_Change>;
};

export type Wms_Stock_Change_Aggregate_Bool_Exp = {
  count?: Maybe<Wms_Stock_Change_Aggregate_Bool_Exp_Count>;
};

export type Wms_Stock_Change_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Wms_Stock_Change_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "wms_stock_change" */
export type Wms_Stock_Change_Aggregate_Fields = {
  __typename?: 'wms_stock_change_aggregate_fields';
  avg?: Maybe<Wms_Stock_Change_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wms_Stock_Change_Max_Fields>;
  min?: Maybe<Wms_Stock_Change_Min_Fields>;
  stddev?: Maybe<Wms_Stock_Change_Stddev_Fields>;
  stddev_pop?: Maybe<Wms_Stock_Change_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wms_Stock_Change_Stddev_Samp_Fields>;
  sum?: Maybe<Wms_Stock_Change_Sum_Fields>;
  var_pop?: Maybe<Wms_Stock_Change_Var_Pop_Fields>;
  var_samp?: Maybe<Wms_Stock_Change_Var_Samp_Fields>;
  variance?: Maybe<Wms_Stock_Change_Variance_Fields>;
};


/** aggregate fields of "wms_stock_change" */
export type Wms_Stock_Change_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wms_stock_change" */
export type Wms_Stock_Change_Aggregate_Order_By = {
  avg?: Maybe<Wms_Stock_Change_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wms_Stock_Change_Max_Order_By>;
  min?: Maybe<Wms_Stock_Change_Min_Order_By>;
  stddev?: Maybe<Wms_Stock_Change_Stddev_Order_By>;
  stddev_pop?: Maybe<Wms_Stock_Change_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wms_Stock_Change_Stddev_Samp_Order_By>;
  sum?: Maybe<Wms_Stock_Change_Sum_Order_By>;
  var_pop?: Maybe<Wms_Stock_Change_Var_Pop_Order_By>;
  var_samp?: Maybe<Wms_Stock_Change_Var_Samp_Order_By>;
  variance?: Maybe<Wms_Stock_Change_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wms_stock_change" */
export type Wms_Stock_Change_Arr_Rel_Insert_Input = {
  data: Array<Wms_Stock_Change_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wms_Stock_Change_On_Conflict>;
};

/** aggregate avg on columns */
export type Wms_Stock_Change_Avg_Fields = {
  __typename?: 'wms_stock_change_avg_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Avg_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wms_stock_change". All fields are combined with a logical 'AND'. */
export type Wms_Stock_Change_Bool_Exp = {
  _and?: Maybe<Array<Wms_Stock_Change_Bool_Exp>>;
  _not?: Maybe<Wms_Stock_Change_Bool_Exp>;
  _or?: Maybe<Array<Wms_Stock_Change_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_inputs?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
  wms_stock_change_inputs_aggregate?: Maybe<Wms_Stock_Change_Input_Aggregate_Bool_Exp>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp>;
  wms_stock_change_type?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
  wms_stock_change_type_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "wms_stock_change" */
export enum Wms_Stock_Change_Constraint {
  /** unique or primary key constraint on columns "id" */
  WmsStockChangePkey = 'wms_stock_change_pkey'
}

/** input type for incrementing numeric columns in table "wms_stock_change" */
export type Wms_Stock_Change_Inc_Input = {
  external_id?: Maybe<Scalars['Int']>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "wms_stock_change_input" */
export type Wms_Stock_Change_Input = {
  __typename?: 'wms_stock_change_input';
  created_at: Scalars['timestamptz'];
  external_id: Scalars['Int'];
  id: Scalars['Int'];
  input_quantity: Scalars['Int'];
  input_stock_barcode: Scalars['uuid'];
  /** An object relationship */
  product_stock: Product_Stock;
  source_product_stock_id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wms_stock_change: Wms_Stock_Change;
  wms_stock_change_id: Scalars['Int'];
};

/** aggregated selection of "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Aggregate = {
  __typename?: 'wms_stock_change_input_aggregate';
  aggregate?: Maybe<Wms_Stock_Change_Input_Aggregate_Fields>;
  nodes: Array<Wms_Stock_Change_Input>;
};

export type Wms_Stock_Change_Input_Aggregate_Bool_Exp = {
  count?: Maybe<Wms_Stock_Change_Input_Aggregate_Bool_Exp_Count>;
};

export type Wms_Stock_Change_Input_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Aggregate_Fields = {
  __typename?: 'wms_stock_change_input_aggregate_fields';
  avg?: Maybe<Wms_Stock_Change_Input_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wms_Stock_Change_Input_Max_Fields>;
  min?: Maybe<Wms_Stock_Change_Input_Min_Fields>;
  stddev?: Maybe<Wms_Stock_Change_Input_Stddev_Fields>;
  stddev_pop?: Maybe<Wms_Stock_Change_Input_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wms_Stock_Change_Input_Stddev_Samp_Fields>;
  sum?: Maybe<Wms_Stock_Change_Input_Sum_Fields>;
  var_pop?: Maybe<Wms_Stock_Change_Input_Var_Pop_Fields>;
  var_samp?: Maybe<Wms_Stock_Change_Input_Var_Samp_Fields>;
  variance?: Maybe<Wms_Stock_Change_Input_Variance_Fields>;
};


/** aggregate fields of "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wms_Stock_Change_Input_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Aggregate_Order_By = {
  avg?: Maybe<Wms_Stock_Change_Input_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wms_Stock_Change_Input_Max_Order_By>;
  min?: Maybe<Wms_Stock_Change_Input_Min_Order_By>;
  stddev?: Maybe<Wms_Stock_Change_Input_Stddev_Order_By>;
  stddev_pop?: Maybe<Wms_Stock_Change_Input_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wms_Stock_Change_Input_Stddev_Samp_Order_By>;
  sum?: Maybe<Wms_Stock_Change_Input_Sum_Order_By>;
  var_pop?: Maybe<Wms_Stock_Change_Input_Var_Pop_Order_By>;
  var_samp?: Maybe<Wms_Stock_Change_Input_Var_Samp_Order_By>;
  variance?: Maybe<Wms_Stock_Change_Input_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Arr_Rel_Insert_Input = {
  data: Array<Wms_Stock_Change_Input_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wms_Stock_Change_Input_On_Conflict>;
};

/** aggregate avg on columns */
export type Wms_Stock_Change_Input_Avg_Fields = {
  __typename?: 'wms_stock_change_input_avg_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Avg_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wms_stock_change_input". All fields are combined with a logical 'AND'. */
export type Wms_Stock_Change_Input_Bool_Exp = {
  _and?: Maybe<Array<Wms_Stock_Change_Input_Bool_Exp>>;
  _not?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
  _or?: Maybe<Array<Wms_Stock_Change_Input_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  input_quantity?: Maybe<Int_Comparison_Exp>;
  input_stock_barcode?: Maybe<Uuid_Comparison_Exp>;
  product_stock?: Maybe<Product_Stock_Bool_Exp>;
  source_product_stock_id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change?: Maybe<Wms_Stock_Change_Bool_Exp>;
  wms_stock_change_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "wms_stock_change_input" */
export enum Wms_Stock_Change_Input_Constraint {
  /** unique or primary key constraint on columns "id" */
  WmsStockChangeInputPkey = 'wms_stock_change_input_pkey'
}

/** input type for incrementing numeric columns in table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Inc_Input = {
  external_id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  input_stock_barcode?: Maybe<Scalars['uuid']>;
  product_stock?: Maybe<Product_Stock_Obj_Rel_Insert_Input>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change?: Maybe<Wms_Stock_Change_Obj_Rel_Insert_Input>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Wms_Stock_Change_Input_Max_Fields = {
  __typename?: 'wms_stock_change_input_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  input_stock_barcode?: Maybe<Scalars['uuid']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  input_stock_barcode?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wms_Stock_Change_Input_Min_Fields = {
  __typename?: 'wms_stock_change_input_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  input_stock_barcode?: Maybe<Scalars['uuid']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  input_stock_barcode?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Mutation_Response = {
  __typename?: 'wms_stock_change_input_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wms_Stock_Change_Input>;
};

/** on_conflict condition type for table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_On_Conflict = {
  constraint: Wms_Stock_Change_Input_Constraint;
  update_columns?: Array<Wms_Stock_Change_Input_Update_Column>;
  where?: Maybe<Wms_Stock_Change_Input_Bool_Exp>;
};

/** Ordering options when selecting data from "wms_stock_change_input". */
export type Wms_Stock_Change_Input_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  input_stock_barcode?: Maybe<Order_By>;
  product_stock?: Maybe<Product_Stock_Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change?: Maybe<Wms_Stock_Change_Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** primary key columns input for table: wms_stock_change_input */
export type Wms_Stock_Change_Input_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "wms_stock_change_input" */
export enum Wms_Stock_Change_Input_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  InputQuantity = 'input_quantity',
  /** column name */
  InputStockBarcode = 'input_stock_barcode',
  /** column name */
  SourceProductStockId = 'source_product_stock_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeId = 'wms_stock_change_id'
}

/** input type for updating data in table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  input_stock_barcode?: Maybe<Scalars['uuid']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Wms_Stock_Change_Input_Stddev_Fields = {
  __typename?: 'wms_stock_change_input_stddev_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Stddev_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wms_Stock_Change_Input_Stddev_Pop_Fields = {
  __typename?: 'wms_stock_change_input_stddev_pop_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Stddev_Pop_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wms_Stock_Change_Input_Stddev_Samp_Fields = {
  __typename?: 'wms_stock_change_input_stddev_samp_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Stddev_Samp_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wms_Stock_Change_Input_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wms_Stock_Change_Input_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  input_stock_barcode?: Maybe<Scalars['uuid']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Wms_Stock_Change_Input_Sum_Fields = {
  __typename?: 'wms_stock_change_input_sum_fields';
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  input_quantity?: Maybe<Scalars['Int']>;
  source_product_stock_id?: Maybe<Scalars['Int']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Sum_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** update columns of table "wms_stock_change_input" */
export enum Wms_Stock_Change_Input_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  InputQuantity = 'input_quantity',
  /** column name */
  InputStockBarcode = 'input_stock_barcode',
  /** column name */
  SourceProductStockId = 'source_product_stock_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeId = 'wms_stock_change_id'
}

export type Wms_Stock_Change_Input_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Wms_Stock_Change_Input_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Wms_Stock_Change_Input_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wms_Stock_Change_Input_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wms_Stock_Change_Input_Var_Pop_Fields = {
  __typename?: 'wms_stock_change_input_var_pop_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Var_Pop_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wms_Stock_Change_Input_Var_Samp_Fields = {
  __typename?: 'wms_stock_change_input_var_samp_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Var_Samp_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wms_Stock_Change_Input_Variance_Fields = {
  __typename?: 'wms_stock_change_input_variance_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  input_quantity?: Maybe<Scalars['Float']>;
  source_product_stock_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wms_stock_change_input" */
export type Wms_Stock_Change_Input_Variance_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  input_quantity?: Maybe<Order_By>;
  source_product_stock_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** input type for inserting data into table "wms_stock_change" */
export type Wms_Stock_Change_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_inputs?: Maybe<Wms_Stock_Change_Input_Arr_Rel_Insert_Input>;
  wms_stock_change_outputs?: Maybe<Wms_Stock_Change_Output_Arr_Rel_Insert_Input>;
  wms_stock_change_type?: Maybe<Wms_Stock_Change_Type_Obj_Rel_Insert_Input>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Wms_Stock_Change_Max_Fields = {
  __typename?: 'wms_stock_change_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wms_Stock_Change_Min_Fields = {
  __typename?: 'wms_stock_change_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wms_stock_change" */
export type Wms_Stock_Change_Mutation_Response = {
  __typename?: 'wms_stock_change_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wms_Stock_Change>;
};

/** input type for inserting object relation for remote table "wms_stock_change" */
export type Wms_Stock_Change_Obj_Rel_Insert_Input = {
  data: Wms_Stock_Change_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Wms_Stock_Change_On_Conflict>;
};

/** on_conflict condition type for table "wms_stock_change" */
export type Wms_Stock_Change_On_Conflict = {
  constraint: Wms_Stock_Change_Constraint;
  update_columns?: Array<Wms_Stock_Change_Update_Column>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};

/** Ordering options when selecting data from "wms_stock_change". */
export type Wms_Stock_Change_Order_By = {
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_inputs_aggregate?: Maybe<Wms_Stock_Change_Input_Aggregate_Order_By>;
  wms_stock_change_outputs_aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Order_By>;
  wms_stock_change_type?: Maybe<Wms_Stock_Change_Type_Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "wms_stock_change_output" */
export type Wms_Stock_Change_Output = {
  __typename?: 'wms_stock_change_output';
  /** An object relationship */
  address: Address;
  address_id: Scalars['Int'];
  barcode_uuid: Scalars['uuid'];
  created_at: Scalars['timestamptz'];
  external_id: Scalars['Int'];
  id: Scalars['Int'];
  /** An object relationship */
  organisation: Organisation;
  organisation_id: Scalars['Int'];
  /** An object relationship */
  product: Product;
  product_id: Scalars['Int'];
  /** An array relationship */
  product_stocks: Array<Product_Stock>;
  /** An aggregate relationship */
  product_stocks_aggregate: Product_Stock_Aggregate;
  quantity: Scalars['Int'];
  /** An object relationship */
  stock_condition: Stock_Condition;
  stock_condition_id: Scalars['Int'];
  /** An object relationship */
  stock_status: Stock_Status;
  stock_status_id: Scalars['Int'];
  /** An array relationship */
  stock_sync_logs: Array<Stock_Sync_Log>;
  /** An aggregate relationship */
  stock_sync_logs_aggregate: Stock_Sync_Log_Aggregate;
  updated_at: Scalars['timestamptz'];
  /** An object relationship */
  wms_stock_change: Wms_Stock_Change;
  wms_stock_change_id: Scalars['Int'];
};


/** columns and relationships of "wms_stock_change_output" */
export type Wms_Stock_Change_OutputProduct_StocksArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change_output" */
export type Wms_Stock_Change_OutputProduct_Stocks_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Stock_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Product_Stock_Order_By>>;
  where?: Maybe<Product_Stock_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change_output" */
export type Wms_Stock_Change_OutputStock_Sync_LogsArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change_output" */
export type Wms_Stock_Change_OutputStock_Sync_Logs_AggregateArgs = {
  distinct_on?: Maybe<Array<Stock_Sync_Log_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Stock_Sync_Log_Order_By>>;
  where?: Maybe<Stock_Sync_Log_Bool_Exp>;
};

/** aggregated selection of "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Aggregate = {
  __typename?: 'wms_stock_change_output_aggregate';
  aggregate?: Maybe<Wms_Stock_Change_Output_Aggregate_Fields>;
  nodes: Array<Wms_Stock_Change_Output>;
};

export type Wms_Stock_Change_Output_Aggregate_Bool_Exp = {
  count?: Maybe<Wms_Stock_Change_Output_Aggregate_Bool_Exp_Count>;
};

export type Wms_Stock_Change_Output_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Aggregate_Fields = {
  __typename?: 'wms_stock_change_output_aggregate_fields';
  avg?: Maybe<Wms_Stock_Change_Output_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wms_Stock_Change_Output_Max_Fields>;
  min?: Maybe<Wms_Stock_Change_Output_Min_Fields>;
  stddev?: Maybe<Wms_Stock_Change_Output_Stddev_Fields>;
  stddev_pop?: Maybe<Wms_Stock_Change_Output_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wms_Stock_Change_Output_Stddev_Samp_Fields>;
  sum?: Maybe<Wms_Stock_Change_Output_Sum_Fields>;
  var_pop?: Maybe<Wms_Stock_Change_Output_Var_Pop_Fields>;
  var_samp?: Maybe<Wms_Stock_Change_Output_Var_Samp_Fields>;
  variance?: Maybe<Wms_Stock_Change_Output_Variance_Fields>;
};


/** aggregate fields of "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wms_Stock_Change_Output_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Aggregate_Order_By = {
  avg?: Maybe<Wms_Stock_Change_Output_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Wms_Stock_Change_Output_Max_Order_By>;
  min?: Maybe<Wms_Stock_Change_Output_Min_Order_By>;
  stddev?: Maybe<Wms_Stock_Change_Output_Stddev_Order_By>;
  stddev_pop?: Maybe<Wms_Stock_Change_Output_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Wms_Stock_Change_Output_Stddev_Samp_Order_By>;
  sum?: Maybe<Wms_Stock_Change_Output_Sum_Order_By>;
  var_pop?: Maybe<Wms_Stock_Change_Output_Var_Pop_Order_By>;
  var_samp?: Maybe<Wms_Stock_Change_Output_Var_Samp_Order_By>;
  variance?: Maybe<Wms_Stock_Change_Output_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Arr_Rel_Insert_Input = {
  data: Array<Wms_Stock_Change_Output_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Wms_Stock_Change_Output_On_Conflict>;
};

/** aggregate avg on columns */
export type Wms_Stock_Change_Output_Avg_Fields = {
  __typename?: 'wms_stock_change_output_avg_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Avg_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "wms_stock_change_output". All fields are combined with a logical 'AND'. */
export type Wms_Stock_Change_Output_Bool_Exp = {
  _and?: Maybe<Array<Wms_Stock_Change_Output_Bool_Exp>>;
  _not?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
  _or?: Maybe<Array<Wms_Stock_Change_Output_Bool_Exp>>;
  address?: Maybe<Address_Bool_Exp>;
  address_id?: Maybe<Int_Comparison_Exp>;
  barcode_uuid?: Maybe<Uuid_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  external_id?: Maybe<Int_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  organisation?: Maybe<Organisation_Bool_Exp>;
  organisation_id?: Maybe<Int_Comparison_Exp>;
  product?: Maybe<Product_Bool_Exp>;
  product_id?: Maybe<Int_Comparison_Exp>;
  product_stocks?: Maybe<Product_Stock_Bool_Exp>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Bool_Exp>;
  quantity?: Maybe<Int_Comparison_Exp>;
  stock_condition?: Maybe<Stock_Condition_Bool_Exp>;
  stock_condition_id?: Maybe<Int_Comparison_Exp>;
  stock_status?: Maybe<Stock_Status_Bool_Exp>;
  stock_status_id?: Maybe<Int_Comparison_Exp>;
  stock_sync_logs?: Maybe<Stock_Sync_Log_Bool_Exp>;
  stock_sync_logs_aggregate?: Maybe<Stock_Sync_Log_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change?: Maybe<Wms_Stock_Change_Bool_Exp>;
  wms_stock_change_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "wms_stock_change_output" */
export enum Wms_Stock_Change_Output_Constraint {
  /** unique or primary key constraint on columns "id" */
  WmsStockChangeOutputPkey = 'wms_stock_change_output_pkey'
}

/** input type for incrementing numeric columns in table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Inc_Input = {
  address_id?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Insert_Input = {
  address?: Maybe<Address_Obj_Rel_Insert_Input>;
  address_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  organisation?: Maybe<Organisation_Obj_Rel_Insert_Input>;
  organisation_id?: Maybe<Scalars['Int']>;
  product?: Maybe<Product_Obj_Rel_Insert_Input>;
  product_id?: Maybe<Scalars['Int']>;
  product_stocks?: Maybe<Product_Stock_Arr_Rel_Insert_Input>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition?: Maybe<Stock_Condition_Obj_Rel_Insert_Input>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status?: Maybe<Stock_Status_Obj_Rel_Insert_Input>;
  stock_status_id?: Maybe<Scalars['Int']>;
  stock_sync_logs?: Maybe<Stock_Sync_Log_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change?: Maybe<Wms_Stock_Change_Obj_Rel_Insert_Input>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Wms_Stock_Change_Output_Max_Fields = {
  __typename?: 'wms_stock_change_output_max_fields';
  address_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Max_Order_By = {
  address_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Wms_Stock_Change_Output_Min_Fields = {
  __typename?: 'wms_stock_change_output_min_fields';
  address_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Min_Order_By = {
  address_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Mutation_Response = {
  __typename?: 'wms_stock_change_output_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wms_Stock_Change_Output>;
};

/** input type for inserting object relation for remote table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Obj_Rel_Insert_Input = {
  data: Wms_Stock_Change_Output_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Wms_Stock_Change_Output_On_Conflict>;
};

/** on_conflict condition type for table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_On_Conflict = {
  constraint: Wms_Stock_Change_Output_Constraint;
  update_columns?: Array<Wms_Stock_Change_Output_Update_Column>;
  where?: Maybe<Wms_Stock_Change_Output_Bool_Exp>;
};

/** Ordering options when selecting data from "wms_stock_change_output". */
export type Wms_Stock_Change_Output_Order_By = {
  address?: Maybe<Address_Order_By>;
  address_id?: Maybe<Order_By>;
  barcode_uuid?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation?: Maybe<Organisation_Order_By>;
  organisation_id?: Maybe<Order_By>;
  product?: Maybe<Product_Order_By>;
  product_id?: Maybe<Order_By>;
  product_stocks_aggregate?: Maybe<Product_Stock_Aggregate_Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition?: Maybe<Stock_Condition_Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status?: Maybe<Stock_Status_Order_By>;
  stock_status_id?: Maybe<Order_By>;
  stock_sync_logs_aggregate?: Maybe<Stock_Sync_Log_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change?: Maybe<Wms_Stock_Change_Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** primary key columns input for table: wms_stock_change_output */
export type Wms_Stock_Change_Output_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "wms_stock_change_output" */
export enum Wms_Stock_Change_Output_Select_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BarcodeUuid = 'barcode_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  StockConditionId = 'stock_condition_id',
  /** column name */
  StockStatusId = 'stock_status_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeId = 'wms_stock_change_id'
}

/** input type for updating data in table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Set_Input = {
  address_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Wms_Stock_Change_Output_Stddev_Fields = {
  __typename?: 'wms_stock_change_output_stddev_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Stddev_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wms_Stock_Change_Output_Stddev_Pop_Fields = {
  __typename?: 'wms_stock_change_output_stddev_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Stddev_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wms_Stock_Change_Output_Stddev_Samp_Fields = {
  __typename?: 'wms_stock_change_output_stddev_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Stddev_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wms_Stock_Change_Output_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wms_Stock_Change_Output_Stream_Cursor_Value_Input = {
  address_id?: Maybe<Scalars['Int']>;
  barcode_uuid?: Maybe<Scalars['uuid']>;
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Wms_Stock_Change_Output_Sum_Fields = {
  __typename?: 'wms_stock_change_output_sum_fields';
  address_id?: Maybe<Scalars['Int']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  organisation_id?: Maybe<Scalars['Int']>;
  product_id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  stock_condition_id?: Maybe<Scalars['Int']>;
  stock_status_id?: Maybe<Scalars['Int']>;
  wms_stock_change_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Sum_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** update columns of table "wms_stock_change_output" */
export enum Wms_Stock_Change_Output_Update_Column {
  /** column name */
  AddressId = 'address_id',
  /** column name */
  BarcodeUuid = 'barcode_uuid',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  OrganisationId = 'organisation_id',
  /** column name */
  ProductId = 'product_id',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  StockConditionId = 'stock_condition_id',
  /** column name */
  StockStatusId = 'stock_status_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeId = 'wms_stock_change_id'
}

export type Wms_Stock_Change_Output_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Wms_Stock_Change_Output_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Wms_Stock_Change_Output_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wms_Stock_Change_Output_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wms_Stock_Change_Output_Var_Pop_Fields = {
  __typename?: 'wms_stock_change_output_var_pop_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Var_Pop_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wms_Stock_Change_Output_Var_Samp_Fields = {
  __typename?: 'wms_stock_change_output_var_samp_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Var_Samp_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wms_Stock_Change_Output_Variance_Fields = {
  __typename?: 'wms_stock_change_output_variance_fields';
  address_id?: Maybe<Scalars['Float']>;
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  organisation_id?: Maybe<Scalars['Float']>;
  product_id?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Float']>;
  stock_condition_id?: Maybe<Scalars['Float']>;
  stock_status_id?: Maybe<Scalars['Float']>;
  wms_stock_change_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wms_stock_change_output" */
export type Wms_Stock_Change_Output_Variance_Order_By = {
  address_id?: Maybe<Order_By>;
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  organisation_id?: Maybe<Order_By>;
  product_id?: Maybe<Order_By>;
  quantity?: Maybe<Order_By>;
  stock_condition_id?: Maybe<Order_By>;
  stock_status_id?: Maybe<Order_By>;
  wms_stock_change_id?: Maybe<Order_By>;
};

/** primary key columns input for table: wms_stock_change */
export type Wms_Stock_Change_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "wms_stock_change" */
export enum Wms_Stock_Change_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeTypeId = 'wms_stock_change_type_id'
}

/** input type for updating data in table "wms_stock_change" */
export type Wms_Stock_Change_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** aggregate stddev on columns */
export type Wms_Stock_Change_Stddev_Fields = {
  __typename?: 'wms_stock_change_stddev_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Stddev_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Wms_Stock_Change_Stddev_Pop_Fields = {
  __typename?: 'wms_stock_change_stddev_pop_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Stddev_Pop_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Wms_Stock_Change_Stddev_Samp_Fields = {
  __typename?: 'wms_stock_change_stddev_samp_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Stddev_Samp_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "wms_stock_change" */
export type Wms_Stock_Change_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wms_Stock_Change_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wms_Stock_Change_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Wms_Stock_Change_Sum_Fields = {
  __typename?: 'wms_stock_change_sum_fields';
  external_id?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['Int']>;
  wms_stock_change_type_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Sum_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** columns and relationships of "wms_stock_change_type" */
export type Wms_Stock_Change_Type = {
  __typename?: 'wms_stock_change_type';
  created_at: Scalars['timestamptz'];
  id: Scalars['Int'];
  updated_at: Scalars['timestamptz'];
  wms_stock_change_type_name: Scalars['String'];
  /** An array relationship */
  wms_stock_changes: Array<Wms_Stock_Change>;
  /** An aggregate relationship */
  wms_stock_changes_aggregate: Wms_Stock_Change_Aggregate;
};


/** columns and relationships of "wms_stock_change_type" */
export type Wms_Stock_Change_TypeWms_Stock_ChangesArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};


/** columns and relationships of "wms_stock_change_type" */
export type Wms_Stock_Change_TypeWms_Stock_Changes_AggregateArgs = {
  distinct_on?: Maybe<Array<Wms_Stock_Change_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<Wms_Stock_Change_Order_By>>;
  where?: Maybe<Wms_Stock_Change_Bool_Exp>;
};

/** aggregated selection of "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Aggregate = {
  __typename?: 'wms_stock_change_type_aggregate';
  aggregate?: Maybe<Wms_Stock_Change_Type_Aggregate_Fields>;
  nodes: Array<Wms_Stock_Change_Type>;
};

/** aggregate fields of "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Aggregate_Fields = {
  __typename?: 'wms_stock_change_type_aggregate_fields';
  avg?: Maybe<Wms_Stock_Change_Type_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<Wms_Stock_Change_Type_Max_Fields>;
  min?: Maybe<Wms_Stock_Change_Type_Min_Fields>;
  stddev?: Maybe<Wms_Stock_Change_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Wms_Stock_Change_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Wms_Stock_Change_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Wms_Stock_Change_Type_Sum_Fields>;
  var_pop?: Maybe<Wms_Stock_Change_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Wms_Stock_Change_Type_Var_Samp_Fields>;
  variance?: Maybe<Wms_Stock_Change_Type_Variance_Fields>;
};


/** aggregate fields of "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Wms_Stock_Change_Type_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type Wms_Stock_Change_Type_Avg_Fields = {
  __typename?: 'wms_stock_change_type_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "wms_stock_change_type". All fields are combined with a logical 'AND'. */
export type Wms_Stock_Change_Type_Bool_Exp = {
  _and?: Maybe<Array<Wms_Stock_Change_Type_Bool_Exp>>;
  _not?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
  _or?: Maybe<Array<Wms_Stock_Change_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
  wms_stock_change_type_name?: Maybe<String_Comparison_Exp>;
  wms_stock_changes?: Maybe<Wms_Stock_Change_Bool_Exp>;
  wms_stock_changes_aggregate?: Maybe<Wms_Stock_Change_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "wms_stock_change_type" */
export enum Wms_Stock_Change_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  WmsStockChangeTypePkey = 'wms_stock_change_type_pkey',
  /** unique or primary key constraint on columns "wms_stock_change_type_name" */
  WmsStockChangeTypeWmsStockChangeTypeNameKey = 'wms_stock_change_type_wms_stock_change_type_name_key'
}

/** input type for incrementing numeric columns in table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Inc_Input = {
  id?: Maybe<Scalars['Int']>;
};

/** input type for inserting data into table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Insert_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_name?: Maybe<Scalars['String']>;
  wms_stock_changes?: Maybe<Wms_Stock_Change_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Wms_Stock_Change_Type_Max_Fields = {
  __typename?: 'wms_stock_change_type_max_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_name?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type Wms_Stock_Change_Type_Min_Fields = {
  __typename?: 'wms_stock_change_type_min_fields';
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_name?: Maybe<Scalars['String']>;
};

/** response of any mutation on the table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Mutation_Response = {
  __typename?: 'wms_stock_change_type_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int'];
  /** data from the rows affected by the mutation */
  returning: Array<Wms_Stock_Change_Type>;
};

/** input type for inserting object relation for remote table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Obj_Rel_Insert_Input = {
  data: Wms_Stock_Change_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Wms_Stock_Change_Type_On_Conflict>;
};

/** on_conflict condition type for table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_On_Conflict = {
  constraint: Wms_Stock_Change_Type_Constraint;
  update_columns?: Array<Wms_Stock_Change_Type_Update_Column>;
  where?: Maybe<Wms_Stock_Change_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "wms_stock_change_type". */
export type Wms_Stock_Change_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
  wms_stock_change_type_name?: Maybe<Order_By>;
  wms_stock_changes_aggregate?: Maybe<Wms_Stock_Change_Aggregate_Order_By>;
};

/** primary key columns input for table: wms_stock_change_type */
export type Wms_Stock_Change_Type_Pk_Columns_Input = {
  id: Scalars['Int'];
};

/** select columns of table "wms_stock_change_type" */
export enum Wms_Stock_Change_Type_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeTypeName = 'wms_stock_change_type_name'
}

/** input type for updating data in table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Set_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_name?: Maybe<Scalars['String']>;
};

/** aggregate stddev on columns */
export type Wms_Stock_Change_Type_Stddev_Fields = {
  __typename?: 'wms_stock_change_type_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type Wms_Stock_Change_Type_Stddev_Pop_Fields = {
  __typename?: 'wms_stock_change_type_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type Wms_Stock_Change_Type_Stddev_Samp_Fields = {
  __typename?: 'wms_stock_change_type_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "wms_stock_change_type" */
export type Wms_Stock_Change_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Wms_Stock_Change_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Wms_Stock_Change_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['Int']>;
  updated_at?: Maybe<Scalars['timestamptz']>;
  wms_stock_change_type_name?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Wms_Stock_Change_Type_Sum_Fields = {
  __typename?: 'wms_stock_change_type_sum_fields';
  id?: Maybe<Scalars['Int']>;
};

/** update columns of table "wms_stock_change_type" */
export enum Wms_Stock_Change_Type_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeTypeName = 'wms_stock_change_type_name'
}

export type Wms_Stock_Change_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Wms_Stock_Change_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Wms_Stock_Change_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wms_Stock_Change_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wms_Stock_Change_Type_Var_Pop_Fields = {
  __typename?: 'wms_stock_change_type_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type Wms_Stock_Change_Type_Var_Samp_Fields = {
  __typename?: 'wms_stock_change_type_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type Wms_Stock_Change_Type_Variance_Fields = {
  __typename?: 'wms_stock_change_type_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** update columns of table "wms_stock_change" */
export enum Wms_Stock_Change_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  WmsStockChangeTypeId = 'wms_stock_change_type_id'
}

export type Wms_Stock_Change_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Wms_Stock_Change_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Wms_Stock_Change_Set_Input>;
  /** filter the rows which have to be updated */
  where: Wms_Stock_Change_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Wms_Stock_Change_Var_Pop_Fields = {
  __typename?: 'wms_stock_change_var_pop_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Var_Pop_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Wms_Stock_Change_Var_Samp_Fields = {
  __typename?: 'wms_stock_change_var_samp_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Var_Samp_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Wms_Stock_Change_Variance_Fields = {
  __typename?: 'wms_stock_change_variance_fields';
  external_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  wms_stock_change_type_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "wms_stock_change" */
export type Wms_Stock_Change_Variance_Order_By = {
  external_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  wms_stock_change_type_id?: Maybe<Order_By>;
};

export type GetAddressFragment = { __typename?: 'address', id: number, address_line_1: string, address_name: string, address_line_2?: Maybe<string>, address_line_3?: Maybe<string>, city: string, country_code: string, postcode: string };

export type GetShipmentUpdateFragment = { __typename?: 'shipment_update', id: number, expected_date?: Maybe<any>, notes?: Maybe<string>, tracking_link?: Maybe<string>, address?: Maybe<(
    { __typename?: 'address' }
    & GetAddressFragment
  )>, addressByToDestinationId?: Maybe<(
    { __typename?: 'address' }
    & GetAddressFragment
  )>, addressByFromDestinationId?: Maybe<(
    { __typename?: 'address' }
    & GetAddressFragment
  )>, shipment_update_type: { __typename?: 'shipment_update_type', id: number, shipment_update_type_name: string, update_message?: Maybe<string> } };

export type GetInternalAddressesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetInternalAddressesQuery = { __typename?: 'query_root', address: Array<(
    { __typename?: 'address' }
    & GetAddressFragment
  )> };

export type GetShipmentsByStatusIdQueryVariables = Exact<{
  shipmentStatusId?: Maybe<Scalars['Int']>;
}>;


export type GetShipmentsByStatusIdQuery = { __typename?: 'query_root', shipment: Array<{ __typename?: 'shipment', id: number, shipment_status_id: number, shipment_status: { __typename?: 'shipment_status', shipment_status_name: string }, client_order: { __typename?: 'client_order', id: number, external_client_order_id: string, address: (
        { __typename?: 'address' }
        & GetAddressFragment
      ), organisation: { __typename?: 'organisation', id: number, organisation_name: string } }, purchase_order?: Maybe<{ __typename?: 'purchase_order', id: number, purchase_order_uuid: string, organisation: { __typename?: 'organisation', id: number, organisation_name: string } }>, shipment_updates: Array<(
      { __typename?: 'shipment_update' }
      & GetShipmentUpdateFragment
    )> }> };

export type GetClientOrderShipmentsQueryVariables = Exact<{
  clientOrderId?: Maybe<Scalars['Int']>;
}>;


export type GetClientOrderShipmentsQuery = { __typename?: 'query_root', client_order: Array<{ __typename?: 'client_order', id: number, external_client_order_id: string, final_address_id: number, shipments: Array<{ __typename?: 'shipment', id: number, shipment_updates: Array<(
        { __typename?: 'shipment_update' }
        & GetShipmentUpdateFragment
      )>, shipment_status: { __typename?: 'shipment_status', id: number, shipment_status_name: string }, purchase_order?: Maybe<{ __typename?: 'purchase_order', purchase_order_uuid: string, address: (
          { __typename?: 'address' }
          & GetAddressFragment
        ) }> }>, address: (
      { __typename?: 'address' }
      & GetAddressFragment
    ) }> };

export type GetShipmentsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetShipmentsQuery = { __typename?: 'query_root', shipment: Array<{ __typename?: 'shipment', id: number, client_order_id: number, created_at: any, purchase_order_id?: Maybe<number>, client_order: { __typename?: 'client_order', id: number, external_client_order_id: string, organisation: { __typename?: 'organisation', organisation_name: string } }, shipment_status: { __typename?: 'shipment_status', shipment_status_name: string, id: number }, shipment_updates: Array<(
      { __typename?: 'shipment_update' }
      & GetShipmentUpdateFragment
    )>, purchase_order?: Maybe<{ __typename?: 'purchase_order', external_purchase_order_id: number, purchase_order_uuid: string, organisation: { __typename?: 'organisation', organisation_name: string } }> }> };

export const GetAddressFragmentDoc = gql`
    fragment getAddress on address {
  id
  address_line_1
  address_name
  address_line_2
  address_line_3
  city
  country_code
  postcode
}
    `;
export const GetShipmentUpdateFragmentDoc = gql`
    fragment getShipmentUpdate on shipment_update {
  id
  address {
    ...getAddress
  }
  addressByToDestinationId {
    ...getAddress
  }
  addressByFromDestinationId {
    ...getAddress
  }
  shipment_update_type {
    id
    shipment_update_type_name
    update_message
  }
  expected_date
  notes
  tracking_link
}
    ${GetAddressFragmentDoc}`;
export const GetInternalAddressesDocument = gql`
    query getInternalAddresses {
  address(where: {is_internal: {_eq: true}}) {
    ...getAddress
  }
}
    ${GetAddressFragmentDoc}`;

/**
 * __useGetInternalAddressesQuery__
 *
 * To run a query within a React component, call `useGetInternalAddressesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetInternalAddressesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetInternalAddressesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetInternalAddressesQuery(baseOptions?: Apollo.QueryHookOptions<GetInternalAddressesQuery, GetInternalAddressesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetInternalAddressesQuery, GetInternalAddressesQueryVariables>(GetInternalAddressesDocument, options);
      }
export function useGetInternalAddressesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetInternalAddressesQuery, GetInternalAddressesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetInternalAddressesQuery, GetInternalAddressesQueryVariables>(GetInternalAddressesDocument, options);
        }
export type GetInternalAddressesQueryHookResult = ReturnType<typeof useGetInternalAddressesQuery>;
export type GetInternalAddressesLazyQueryHookResult = ReturnType<typeof useGetInternalAddressesLazyQuery>;
export type GetInternalAddressesQueryResult = Apollo.QueryResult<GetInternalAddressesQuery, GetInternalAddressesQueryVariables>;
export const GetShipmentsByStatusIdDocument = gql`
    query getShipmentsByStatusId($shipmentStatusId: Int) {
  shipment(
    where: {shipment_status_id: {_eq: $shipmentStatusId, _nin: [1, 4]}}
    order_by: {created_at: asc}
  ) {
    id
    shipment_status_id
    shipment_status {
      shipment_status_name
    }
    client_order {
      id
      external_client_order_id
      address {
        ...getAddress
      }
      organisation {
        id
        organisation_name
      }
    }
    purchase_order {
      id
      purchase_order_uuid
      organisation {
        id
        organisation_name
      }
    }
    shipment_updates(order_by: {created_at: asc}) {
      ...getShipmentUpdate
    }
  }
}
    ${GetAddressFragmentDoc}
${GetShipmentUpdateFragmentDoc}`;

/**
 * __useGetShipmentsByStatusIdQuery__
 *
 * To run a query within a React component, call `useGetShipmentsByStatusIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentsByStatusIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentsByStatusIdQuery({
 *   variables: {
 *      shipmentStatusId: // value for 'shipmentStatusId'
 *   },
 * });
 */
export function useGetShipmentsByStatusIdQuery(baseOptions?: Apollo.QueryHookOptions<GetShipmentsByStatusIdQuery, GetShipmentsByStatusIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentsByStatusIdQuery, GetShipmentsByStatusIdQueryVariables>(GetShipmentsByStatusIdDocument, options);
      }
export function useGetShipmentsByStatusIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentsByStatusIdQuery, GetShipmentsByStatusIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentsByStatusIdQuery, GetShipmentsByStatusIdQueryVariables>(GetShipmentsByStatusIdDocument, options);
        }
export type GetShipmentsByStatusIdQueryHookResult = ReturnType<typeof useGetShipmentsByStatusIdQuery>;
export type GetShipmentsByStatusIdLazyQueryHookResult = ReturnType<typeof useGetShipmentsByStatusIdLazyQuery>;
export type GetShipmentsByStatusIdQueryResult = Apollo.QueryResult<GetShipmentsByStatusIdQuery, GetShipmentsByStatusIdQueryVariables>;
export const GetClientOrderShipmentsDocument = gql`
    query getClientOrderShipments($clientOrderId: Int) {
  client_order(where: {id: {_eq: $clientOrderId}}, order_by: {created_at: asc}) {
    id
    external_client_order_id
    final_address_id
    shipments {
      id
      shipment_updates {
        ...getShipmentUpdate
      }
      shipment_status {
        id
        shipment_status_name
      }
      purchase_order {
        address {
          ...getAddress
        }
        purchase_order_uuid
      }
    }
    address {
      ...getAddress
    }
  }
}
    ${GetShipmentUpdateFragmentDoc}
${GetAddressFragmentDoc}`;

/**
 * __useGetClientOrderShipmentsQuery__
 *
 * To run a query within a React component, call `useGetClientOrderShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientOrderShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientOrderShipmentsQuery({
 *   variables: {
 *      clientOrderId: // value for 'clientOrderId'
 *   },
 * });
 */
export function useGetClientOrderShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientOrderShipmentsQuery, GetClientOrderShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientOrderShipmentsQuery, GetClientOrderShipmentsQueryVariables>(GetClientOrderShipmentsDocument, options);
      }
export function useGetClientOrderShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientOrderShipmentsQuery, GetClientOrderShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientOrderShipmentsQuery, GetClientOrderShipmentsQueryVariables>(GetClientOrderShipmentsDocument, options);
        }
export type GetClientOrderShipmentsQueryHookResult = ReturnType<typeof useGetClientOrderShipmentsQuery>;
export type GetClientOrderShipmentsLazyQueryHookResult = ReturnType<typeof useGetClientOrderShipmentsLazyQuery>;
export type GetClientOrderShipmentsQueryResult = Apollo.QueryResult<GetClientOrderShipmentsQuery, GetClientOrderShipmentsQueryVariables>;
export const GetShipmentsDocument = gql`
    query getShipments {
  shipment {
    id
    client_order_id
    created_at
    purchase_order_id
    client_order {
      id
      organisation {
        organisation_name
      }
      external_client_order_id
    }
    shipment_status {
      shipment_status_name
      id
    }
    shipment_updates {
      ...getShipmentUpdate
    }
    purchase_order {
      organisation {
        organisation_name
      }
      external_purchase_order_id
      purchase_order_uuid
    }
  }
}
    ${GetShipmentUpdateFragmentDoc}`;

/**
 * __useGetShipmentsQuery__
 *
 * To run a query within a React component, call `useGetShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetShipmentsQuery, GetShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentsQuery, GetShipmentsQueryVariables>(GetShipmentsDocument, options);
      }
export function useGetShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentsQuery, GetShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentsQuery, GetShipmentsQueryVariables>(GetShipmentsDocument, options);
        }
export type GetShipmentsQueryHookResult = ReturnType<typeof useGetShipmentsQuery>;
export type GetShipmentsLazyQueryHookResult = ReturnType<typeof useGetShipmentsLazyQuery>;
export type GetShipmentsQueryResult = Apollo.QueryResult<GetShipmentsQuery, GetShipmentsQueryVariables>;