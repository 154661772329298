import { ApolloError } from "@apollo/client";

import { useGetSupplierQuoteRequestQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QuoteRequestItem } from "../types";

export interface HookResult {
  supplierQuoteRequest?: QuoteRequestItem;
  supplierQuoteRequestError?: ApolloError;
  supplierQuoteRequestLoading: boolean;
}

export const useSupplierQuoteRequest = (rfqId: number): HookResult => {
  const { data, error, loading } = useGetSupplierQuoteRequestQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
    variables: { rfqId: rfqId },
  });

  return {
    supplierQuoteRequest:
      data && Array.isArray(data.supplier_quote_request)
        ? data.supplier_quote_request[0]
        : undefined,
    supplierQuoteRequestError: error,
    supplierQuoteRequestLoading: loading,
  };
};
