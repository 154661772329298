import { FormikErrors, FormikTouched } from "formik";

import { OrderLineItem } from "../graphql/types";
import { RejectionResponseInterface, SupplierResponseInterface } from "../types";

export const fieldNames = {
  created_by_name: "created_by_name",
  job_title: "job_title",
  initial_notes: "initial_notes",
};

export const initialFieldValues: SupplierResponseInterface = {
  created_by_name: "",
  job_title: "",
  initial_notes: "",
  purchase_order_lines_data: [],
};

export const initialRejectionResponse: RejectionResponseInterface = {
  rejection_notes: "",
  rejection_reason: null,
};

export interface SupplierResponseProductLineProps {
  orderLine: OrderLineItem;
  index: number;
  requiredRefs: boolean;
  values: SupplierResponseInterface;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleAttachmentDownload: (attachmentUUID: string) => void;
  isFileCopyLine: boolean;
}

export interface InformativeErrorPageProps {
  type: "missingOrder" | "expiredLink";
}

export interface SupplierResponseSignatureSectionProps {
  values: SupplierResponseInterface;
  errors: FormikErrors<SupplierResponseInterface>;
  touched: FormikTouched<SupplierResponseInterface>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const modalFieldNames = {
  rejection_reason: "rejection_reason",
  rejection_notes: "rejection_notes",
};

export const modalInitialFieldValues: RejectionResponseInterface = {
  rejection_reason: null,
  rejection_notes: "",
};
