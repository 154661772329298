import { ApolloError } from "@apollo/client";

import { useGetInboundManifestsAscQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ManifestItem } from "../types";

export interface HookResult {
  manifests: ManifestItem[];
  manifestsError?: ApolloError;
  manifestsLoading: boolean;
}

export const useInboundManifestsAsc = (): HookResult => {
  const { data, error, loading } = useGetInboundManifestsAscQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    manifests: data && Array.isArray(data.inbound_manifest) ? data.inbound_manifest : [],
    manifestsError: error,
    manifestsLoading: loading,
  };
};
