import { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ButtonGroup, Heading, Modal } from "@sourceful/shared-components";

import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  InnerPanelWrapper,
  PanelWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { recallPurchaseOrderAPI } from "../api/recallPurchaseOrder";
import { PURCHASE_ORDER_STATUS, RecallPurchaseOrderProps } from "../types";

const RecallPurchaseOrder = ({ poStatus, refetchPurchaseOrder }: RecallPurchaseOrderProps) => {
  const [isConfirmRecallOpen, setIsConfirmRecallOpen] = useState(false);
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const [recallPurchaseOrderState, recallPurchaseOrder] = useAPI(recallPurchaseOrderAPI, false, {
    pending: "Recalling Purchase Order",
    success: "The purchase order has been recalled successfully.",
    error: "The purchase order could not be recalled. Please try again.",
  });

  const confirmRecallModalRef = createRef<HTMLButtonElement>();

  useEffect(() => {
    if (recallPurchaseOrderState === API_REQUEST_STATE.SUCCESS) refetchPurchaseOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recallPurchaseOrderState]);

  return (
    <>
      <Modal
        id={"confirmRecallModal"}
        isOpen={isConfirmRecallOpen}
        handleClose={() => setIsConfirmRecallOpen(false)}
        triggerRef={confirmRecallModalRef}
      >
        <PanelWrapper>
          <InnerPanelWrapper>
            <Heading level={3} fontSize={5} css={{ margin: "0 10px 10px" }}>
              Are you sure you want to recall this order?
            </Heading>
            <ButtonGroup
              className={WMSButtonGroup({ type: "largeMargin" })}
              css={{ marginTop: "auto" }}
            >
              <SecondaryButton
                appearance="whiteButton"
                onClick={() => setIsConfirmRecallOpen(false)}
              >
                Cancel
              </SecondaryButton>
              <SecondaryButton
                appearance="blueButton"
                type="submit"
                onClick={() => {
                  recallPurchaseOrder(purchaseOrderId);
                  setIsConfirmRecallOpen(false);
                }}
              >
                Confirm
              </SecondaryButton>
            </ButtonGroup>
          </InnerPanelWrapper>
        </PanelWrapper>
      </Modal>
      <VisibleFor roles={SOURCING_EDIT_ROLES}>
        <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
          {poStatus === PURCHASE_ORDER_STATUS.APPROVED && (
            <SecondaryButton type="button" onClick={() => setIsConfirmRecallOpen(true)}>
              Recall Purchase Order from Supplier
            </SecondaryButton>
          )}
        </ButtonGroup>
      </VisibleFor>
    </>
  );
};

export default RecallPurchaseOrder;
