import { ApolloError } from "@apollo/client";

import { useGetInboundCourierServiceNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapCourierServiceNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  courierServiceNames?: DropdownItem[];
  courierServiceNamesError?: ApolloError;
  courierServiceNamesLoading: boolean;
}

export const useInboundCourierServiceNames = (): HookResult => {
  const { data, error, loading } = useGetInboundCourierServiceNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    courierServiceNames:
      data && Array.isArray(data.courier_service)
        ? mapCourierServiceNamesToDropdownObject(data.courier_service)
        : [],
    courierServiceNamesError: error,
    courierServiceNamesLoading: loading,
  };
};
