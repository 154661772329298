import { ORDER_SERVICE_API } from "../../../../../api/baseConfig";
import { InsertOrderAndContact } from "./types";

export const createOrderApi = async (body: InsertOrderAndContact) => {
  try {
    return await ORDER_SERVICE_API.post("/order-creation/new", body);
  } catch (err) {
    console.log("Error in create order api call", err);
    throw err;
  }
};
