import { PDFDownloadLink } from "@react-pdf/renderer";
import moment from "moment";
import { useParams } from "react-router-dom";

import { ButtonGroup } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  CertificationBadge,
  DataPoint,
  FlexHolder,
  FlexTitle,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { useOutboundManifestById } from "../graphql/hooks/useOutboundManifestById";
import { PDF } from "../helpers";

const ViewOutboundManifest = () => {
  const { outbound_manifest_id } = useParams<{ outbound_manifest_id: string }>();

  const { manifest, manifestLoading, manifestError } = useOutboundManifestById(
    Number(outbound_manifest_id)
  );

  return (
    <Page title={"Review Outbound Manifest"} error={manifestError} isLoading={manifestLoading}>
      <Panel
        withWrapper
        title={`Review Outbound Manifest #${manifest?.id}`}
        subtitle={"Review Outbound Manifest below"}
        manifestStatusName={manifest?.outbound_manifest_status.outbound_manifest_status_name}
      >
        {
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DisplayBox label={"Organisation"} value={manifest?.organisation.organisation_name} />
              <DisplayBox label={"Warehouse"} value={manifest?.warehouse.warehouse_name} />
              <DisplayBox
                label={"Logistics Type"}
                tooltipMessage={"How stock will leave <br/> the warehouse"}
                value={manifest?.logistics_type.logistics_type_name}
              />
              <DisplayBox
                label={"Expected Collection Date"}
                value={moment(manifest?.expected_dispatch).format("DD-MM-YYYY")}
              />
              <DisplayBox
                label={"Number of Manifest Lines"}
                value={manifest?.outbound_manifest_lines.length}
              />
              <DisplayBox
                label={"Courier"}
                value={manifest?.courier_service.courier_service_name}
              />
              <DisplayBox label={"Reference"} value={manifest?.reference} />
              <DisplayBox label={"Notes"} value={manifest?.notes} unknownString={"No Notes"} />
            </TopPanelInputsWrapper>

            {manifest?.outbound_manifest_lines.map(obmLine => (
              <PrimaryLineWrapper key={obmLine.id}>
                <FlexTitle>
                  <Title>Manifest Line #{obmLine.id}</Title>
                  <StatusBadge
                    type="dot"
                    statusName={
                      obmLine.outbound_manifest_line_status.outbound_manifest_line_status_name
                    }
                  />
                </FlexTitle>
                <FlexHolder>
                  {obmLine.product?.fsc_certified && (
                    <CertificationBadge type="FSC"> FSC Certified </CertificationBadge>
                  )}
                  {obmLine.product?.grs_certified && (
                    <CertificationBadge type="GRS"> GRS Certified </CertificationBadge>
                  )}
                </FlexHolder>
                <PrimaryLineDataWrapper>
                  <DisplayBox
                    withUniqueMargin
                    label={"Product Name"}
                    value={obmLine.product.product_name}
                  />
                  <DisplayBox withUniqueMargin label={"Quantity"} value={obmLine.quantity} />
                  <DisplayBox
                    withUniqueMargin
                    label={"Dispatched Quantity"}
                    value={obmLine.dispatched_quantity}
                  />
                  <DisplayBox
                    withUniqueMargin
                    label={"Number of Fulfilment Lines"}
                    value={obmLine.outbound_manifest_fulfilment_lines.length}
                  />
                </PrimaryLineDataWrapper>
                {obmLine.outbound_manifest_fulfilment_lines.map((ffline, index) => {
                  return (
                    <SecondaryLineWrapper type="withIndex">
                      <IndexPill index={index + 1} />
                      <Title>Fulfilment Line #{ffline.id}</Title>
                      <SecondaryLineDataWrapper>
                        <DataPoint>
                          <Label>Fulfilment Line Product</Label>
                          <Box>{ffline.product.product_name}</Box>
                        </DataPoint>
                        <DataPoint>
                          <Label>Fulfilment Line Quantity</Label>
                          <Box>{ffline.quantity}</Box>
                        </DataPoint>
                      </SecondaryLineDataWrapper>

                      {ffline.outbound_manifest_return_lines.length > 0 && (
                        <SecondaryLineWrapper>
                          {ffline.outbound_manifest_return_lines.map(returnLine => (
                            <SecondaryLineDataWrapper>
                              <Title>Returned Line #{returnLine.id}</Title>
                              <DisplayBox
                                label={"Returned Quantity"}
                                value={returnLine.returned_quantity}
                              />
                            </SecondaryLineDataWrapper>
                          ))}
                        </SecondaryLineWrapper>
                      )}
                    </SecondaryLineWrapper>
                  );
                })}
              </PrimaryLineWrapper>
            ))}

            <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
              <PDFDownloadLink
                document={<PDF manifest={manifest} />}
                fileName={`outbound_manifest_id_${outbound_manifest_id}_generated_${moment(
                  new Date()
                ).format("DD-MM-YYYY")}.pdf`}
              >
                {({ loading, error }) => {
                  return (
                    <SecondaryButton appearance={"blueButton"}>
                      {loading ? "Loading Document" : "Download PDF"}
                      {error && "An error occured, unable to download"}
                    </SecondaryButton>
                  );
                }}
              </PDFDownloadLink>
            </ButtonGroup>
          </InnerPanelWrapper>
        }
      </Panel>
    </Page>
  );
};

export default ViewOutboundManifest;
