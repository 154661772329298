import { Formik, FormikState } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";

import { ButtonGroup } from "@sourceful/shared-components";

import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleDateInput from "../../../../../shared/components/forms/SimpleDateInput";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import {
  AddProductPriceInterface,
  NewPriceInterface,
  addProductPriceFieldNames,
  initialNewPriceValues,
} from "../../shared/types";
import { addProductPriceApi } from "../api/addProductPrice";
import { DropdownItem } from "../graphql/types";
import { addNewPriceValidationSchema } from "../validation";

const AddNewPrice = ({
  skuId,
  handleClose,
  currencies,
  refetchPrices,
  allMatchingOrgsPrePaid,
}: {
  skuId: string;
  handleClose: () => void;
  currencies: DropdownItem[];
  refetchPrices: () => Promise<void>;
  allMatchingOrgsPrePaid?: boolean;
}) => {
  const [addPriceState, addPrice] = useAPI(addProductPriceApi, false);

  const handleAddProductPrice = async ({
    pricePerUnit,
    currencyCode,
    minOrderQuantity,
    validFrom,
  }: AddProductPriceInterface) => {
    let validFromInput: string | null = validFrom;
    const todaysDate = new Date();
    const inputDate = new Date(validFrom);

    if (inputDate.setHours(0, 0, 0, 0) === todaysDate.setHours(0, 0, 0, 0)) {
      validFromInput = null;
    } else if (inputDate < todaysDate) {
      toast.error("Valid from date cannot be in the past");
      return;
    } else {
      validFromInput = inputDate.toISOString();
    }

    await addPrice({
      sku: skuId,
      price_per_unit: pricePerUnit,
      currency_code: currencyCode,
      min_order_quantity: minOrderQuantity,
      valid_from: validFromInput,
    });
  };

  useEffect(() => {
    if (addPriceState === API_REQUEST_STATE.SUCCESS) {
      refetchPrices();
      handleClose();
    } else if (addPriceState === API_REQUEST_STATE.ERROR) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addPriceState]);

  const onSubmit = async (
    values: NewPriceInterface,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<NewPriceInterface>>) => void }
  ) => {
    handleAddProductPrice({
      pricePerUnit: Number(values.pricePerUnit),
      currencyCode: values.currencyCode,
      minOrderQuantity: Number(values.minOrderQuantity),
      validFrom: values.validFrom,
    });

    resetForm();
  };

  return (
    <div>
      <Title>Add New Price</Title>

      <Formik
        initialValues={
          allMatchingOrgsPrePaid
            ? {
                ...initialNewPriceValues,
                pricePerUnit: "0",
              }
            : initialNewPriceValues
        }
        enableReinitialize
        onSubmit={onSubmit}
        validationSchema={addNewPriceValidationSchema}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => (
          <StyledForm
            onSubmit={handleSubmit}
            css={{ display: "flex", flexDirection: "column", flex: 1, height: "350px" }}
          >
            <PrimaryLineDataWrapper>
              <DataPoint halfWidth>
                <SimpleSelect
                  isRequired
                  htmlFor={addProductPriceFieldNames.currencyCode}
                  label="Currency"
                  name={addProductPriceFieldNames.currencyCode}
                  error={errors.currencyCode}
                  touched={touched.currencyCode}
                  options={currencies}
                  value={currencies.find(currency => currency.label === values.currencyCode)}
                  changeHandler={e => {
                    setFieldValue(addProductPriceFieldNames.currencyCode, e ? e.label : "");
                  }}
                  placeholder="Select Currency"
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleDateInput
                  isRequired
                  name={addProductPriceFieldNames.validFrom}
                  label={"Valid from"}
                  value={values.validFrom}
                  changeHandler={e =>
                    setFieldValue(addProductPriceFieldNames.validFrom, e.target.value)
                  }
                  error={errors.validFrom}
                  touched={touched.validFrom}
                />
              </DataPoint>
              <DataPoint halfWidth css={{ marginTop: "25px" }}>
                <SimpleInput
                  htmlFor={addProductPriceFieldNames.minOrderQuantity}
                  name={addProductPriceFieldNames.minOrderQuantity}
                  size={"medium"}
                  error={errors.minOrderQuantity}
                  touched={touched.minOrderQuantity}
                  label={"Minimum order quantity"}
                  type={"number"}
                  placeholder={"Minimum order quantity"}
                  value={values.minOrderQuantity}
                  changeHandler={event => {
                    setFieldValue(addProductPriceFieldNames.minOrderQuantity, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth css={{ marginTop: "25px" }}>
                <SimpleInput
                  isDisabled={allMatchingOrgsPrePaid}
                  htmlFor={addProductPriceFieldNames.pricePerUnit}
                  name={addProductPriceFieldNames.pricePerUnit}
                  size={"medium"}
                  error={errors.pricePerUnit}
                  touched={touched.pricePerUnit}
                  label={"Price per unit"}
                  type={"number"}
                  placeholder={"Price per unit"}
                  value={values.pricePerUnit}
                  changeHandler={event => {
                    setFieldValue(addProductPriceFieldNames.pricePerUnit, event.target.value);
                  }}
                />
              </DataPoint>
            </PrimaryLineDataWrapper>
            <ButtonGroup
              className={WMSButtonGroup({ type: "largeMargin" })}
              css={{ marginTop: "auto" }}
            >
              <SecondaryButton appearance="whiteButton" onClick={handleClose}>
                Cancel
              </SecondaryButton>
              <SecondaryButton appearance="blueButton" type="submit">
                Add new price
              </SecondaryButton>
            </ButtonGroup>
          </StyledForm>
        )}
      </Formik>
    </div>
  );
};

export default AddNewPrice;
