import React, { useState } from "react";
import { toast } from "react-toastify";

import { Heading, PrimaryButton, styled } from "@sourceful/shared-components";

import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import { InputContainer } from "../../../../styles/SharedStyles";
import { createBaseProduct } from "../api/createBaseProduct";

const CreateBaseProductContainer = styled("div", {
  padding: "0 10px 10px",
  minWidth: "400px",
});

interface CreateBaseProductModalProps {
  handleCreateBaseProductSuccess: () => void;
}

const CreateBaseProductModal = ({
  handleCreateBaseProductSuccess,
}: CreateBaseProductModalProps) => {
  const [name, setName] = useState("");
  const [type, setType] = useState("");

  const handleSubmit = async () => {
    try {
      await createBaseProduct({ name, type });
      handleCreateBaseProductSuccess();
      toast.success("Success! Base product created.");
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while creating base product.");
    }
  };

  return (
    <CreateBaseProductContainer>
      <Heading fontSize={4}>New Base Product</Heading>

      <InputContainer>
        <SimpleInput
          label="Name"
          name="BaseProductName"
          value={name}
          changeHandler={e => setName(e.target.value)}
          type="text"
          placeholder="Enter base product name"
          error={undefined}
          touched={undefined}
        />
      </InputContainer>
      <InputContainer>
        <SimpleInput
          label="Type"
          name="BaseProductType"
          value={type}
          changeHandler={e => setType(e.target.value)}
          type="text"
          placeholder="Enter base product type"
          error={undefined}
          touched={undefined}
        />
      </InputContainer>
      <InputContainer>
        <PrimaryButton onClick={handleSubmit}>Submit</PrimaryButton>
      </InputContainer>
    </CreateBaseProductContainer>
  );
};

export default CreateBaseProductModal;
