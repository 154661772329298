import moment from "moment";
import { createRef, useState } from "react";

import { ButtonGroup, Modal } from "@sourceful/shared-components";

import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { GetProductPricesResponse } from "../api/getProductPrices";
import { useCurrenciesForPrices } from "../graphql/hooks/useCurrenciesForPrices";
import { TitleWrapper, Warning } from "../page/UpdateSku";
import AddNewPrice from "./AddNewPrice";
import ExpirePrice from "./ExpirePrice";
import ResetPrices from "./ResetPrices";

const SkuPrices = ({
  skuPrices,
  skuId,
  refetchPrices,
  allMatchingOrgsPrePaid,
}: {
  skuPrices: GetProductPricesResponse[];
  skuId: string;
  refetchPrices: () => Promise<void>;
  allMatchingOrgsPrePaid?: boolean;
}) => {
  const [expirePriceId, setExpirePriceId] = useState<number | undefined>();
  const expireModalRef = createRef<HTMLButtonElement>();
  const [showResetPricesModal, setShowResetPricesModal] = useState<boolean>(false);
  const resetModalRef = createRef<HTMLButtonElement>();
  const [showAddPriceModal, setShowAddPriceModal] = useState<boolean>(false);
  const addModalRef = createRef<HTMLButtonElement>();
  const { currencies } = useCurrenciesForPrices();

  return (
    <PrimaryLineWrapper>
      <TitleWrapper>
        <Title>SKU Prices</Title>

        {allMatchingOrgsPrePaid && <Warning>MOQ must be set</Warning>}
      </TitleWrapper>

      {skuPrices.length === 0 && <p>No prices have been added yet.</p>}

      {skuPrices.map((skuPrice: GetProductPricesResponse) => {
        return (
          <div key={skuPrice.id}>
            <PrimaryLineDataWrapper>
              <DataPoint withUniqueMargin>
                <DisplayBox label="MOQ" value={skuPrice.min_order_quantity} />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <DisplayBox
                  label="Price per unit"
                  value={`${skuPrice.price_per_unit} ${skuPrice.currency_code}`}
                />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <DisplayBox
                  label="Valid From - To"
                  value={`${moment(skuPrice.valid_from).format("DD-MM-YYYY")} -
                    ${skuPrice.valid_to ? moment(skuPrice.valid_to).format("DD-MM-YYYY") : "N/A"}`}
                />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <SecondaryButton
                  onClick={() => setExpirePriceId(skuPrice.id)}
                  css={{ marginTop: "15px" }}
                  appearance="whiteButtonBlueText"
                >
                  Expire
                </SecondaryButton>
              </DataPoint>
            </PrimaryLineDataWrapper>
          </div>
        );
      })}

      <ButtonGroup css={{ display: "flex", justifyContent: "flex-end", marginTop: "30px" }}>
        {allMatchingOrgsPrePaid ? (
          <SecondaryButton onClick={() => setShowAddPriceModal(true)}>Set MOQ</SecondaryButton>
        ) : (
          <SecondaryButton onClick={() => setShowAddPriceModal(true)}>
            Add new price
          </SecondaryButton>
        )}
        {skuPrices.length !== 0 && (
          <SecondaryButton onClick={() => setShowResetPricesModal(true)}>
            Reset prices
          </SecondaryButton>
        )}
      </ButtonGroup>

      <Modal
        id="expire-price-modal"
        isOpen={!!expirePriceId}
        handleClose={() => setExpirePriceId(undefined)}
        triggerRef={expireModalRef}
      >
        {expirePriceId && (
          <ExpirePrice
            id={expirePriceId}
            handleClose={() => setExpirePriceId(undefined)}
            refetchPrices={refetchPrices}
          />
        )}
      </Modal>

      <Modal
        id="reset-price-modal"
        isOpen={showResetPricesModal}
        handleClose={() => setShowResetPricesModal(false)}
        triggerRef={resetModalRef}
      >
        {showResetPricesModal && (
          <ResetPrices
            skuId={skuId}
            handleClose={() => setShowResetPricesModal(false)}
            refetchPrices={refetchPrices}
          />
        )}
      </Modal>

      <Modal
        id="add-price-modal"
        isOpen={showAddPriceModal}
        handleClose={() => setShowAddPriceModal(false)}
        triggerRef={addModalRef}
      >
        {showAddPriceModal && (
          <AddNewPrice
            allMatchingOrgsPrePaid={allMatchingOrgsPrePaid}
            skuId={skuId}
            handleClose={() => setShowAddPriceModal(false)}
            currencies={currencies}
            refetchPrices={refetchPrices}
          />
        )}
      </Modal>
    </PrimaryLineWrapper>
  );
};

export default SkuPrices;
