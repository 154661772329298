import { OrderExportItem } from "../types";

export const mapOrderExportToCSV = (orderExport: OrderExportItem[]) => {
  return orderExport.map(data => {
    return {
      Service: data.service,
      "PO Number": data.po_number,
      "Order Number": data.order_number,
      "Supplier Organisation": data.supplier_organisation,
      "Client Organisation": data.client_organisation,
      "PO Created": data.created_on,
      "PO Status": data.purchase_order_status_name,
      "Delivery Date": data.delivery_date,
      "Days to ETA": data.days_to_eta,
      "Sourced Locations": data.sourced_locations,
      "Total Products": data.total_products,
      SKU: data.external_product_id,
      Product: data.product_name,
      Quantity: data.quantity,
      "Cost excl VAT": data.cost_excl_vat,
      Dielines: data.dielines,
      QC: data.qc,
      "Order ID": data.external_client_order_id,
      Accreditation: data.accreditation,
      "Contact Name": data.contact_name,
      "Contact Email": data.contact_email_address,
      "Contact Phone": data.contact_phone_number,
      "Client Order Address": data.full_address,
    };
  });
};
