import * as Yup from "yup";

export const validationSchema = Yup.object({
  client: Yup.array()
    .of(
      Yup.object()
        .shape({ value: Yup.number().required("Client is required") })
        .typeError("Client is required")
    )
    .min(1, "Client is required"),
  client_orders: Yup.array()
    .of(
      Yup.object()
        .shape({ value: Yup.number().required("Client Order is required") })
        .typeError("Client Order is required")
    )
    .min(1, "Client order is required"),
  delivery_type: Yup.object()
    .shape({ value: Yup.number().required("Delivery Type is required") })
    .typeError("Delivery Type is required"),
  destination: Yup.object()
    .shape({ value: Yup.number().required("Destination is required") })
    .typeError("Destination is required"),
  manufacturer: Yup.object()
    .shape({ value: Yup.number().required("Manufacturer is required") })
    .typeError("Manufacturer is required"),
  attention_to: Yup.object()
    .shape({ value: Yup.number().required("Attention To is required") })
    .typeError("Attention To is required"),
  purchase_order_lines: Yup.array().of(
    Yup.object({
      product: Yup.object()
        .shape({ value: Yup.number().required("Product is required") })
        .typeError("Product is required"),
      quotation_calculation_pair: Yup.object()
        .shape({ value: Yup.number().required("Quote is required") })
        .typeError("Quote is required"),
      fileCopyQty: Yup.number().optional().nullable(),
    })
  ),
});
