import { useState } from "react";

import { Heading, PrimaryButton, SecondaryButton, styled } from "@sourceful/shared-components";

import { InnerPanelWrapper } from "../../../../styles/SharedStyles";
import AttributeTemplateForm from "../../attributeTemplateForm/AttributeTemplateForm";
import AttributeTemplateOptionsForm from "../../attributeTemplateOptionsForm/AttributeTemplateOptionsForm";
import { AttributeTemplate } from "../api/types";

const AttributeTypeWrapper = styled("div", {
  minWidth: "700px",
  maxWidth: "90%",
  maxHeight: "90vh",
  overflow: "auto",
});

const AttributeTemplateButton = styled(SecondaryButton, {
  width: "100%",
  marginBottom: "10px",
});

const AttributeTemplatesWrapper = styled("div", {
  margin: "20px 0",
});

interface AttributeTypeProps {
  id: number;
  attribute_type: string;
  templates: AttributeTemplate[];
  refetchTemplates: () => void;
}

const AttributeType = ({ id, attribute_type, templates, refetchTemplates }: AttributeTypeProps) => {
  const [selectedAttributeTemplateId, setSelectedAttributeTemplateId] = useState<
    number | undefined
  >();
  const [addingNewAttributeTemplate, setAddingNewAttributeTemplate] = useState(false);

  const selectedTemplateAttribute = templates.find(({ id }) => id === selectedAttributeTemplateId);

  const handleGoBack = () => {
    setSelectedAttributeTemplateId(undefined);
    setAddingNewAttributeTemplate(false);
  };

  const handleCreateAttributeTemplateSuccess = () => {
    setSelectedAttributeTemplateId(undefined);
    setAddingNewAttributeTemplate(false);
    refetchTemplates();
  };

  return (
    <AttributeTypeWrapper>
      <InnerPanelWrapper>
        {selectedAttributeTemplateId && selectedTemplateAttribute ? (
          <AttributeTemplateOptionsForm
            handleGoBack={handleGoBack}
            template={selectedTemplateAttribute}
          />
        ) : addingNewAttributeTemplate ? (
          <AttributeTemplateForm
            handleGoBack={handleGoBack}
            attributeTypeId={id}
            handleCreateAttributeTemplateSuccess={handleCreateAttributeTemplateSuccess}
          />
        ) : (
          <>
            <Heading fontSize={5}>{attribute_type}</Heading>

            <AttributeTemplatesWrapper>
              {(!templates || templates.length) === 0 ? (
                <p>There are no attribute templates, click below to create one</p>
              ) : (
                <p>Click on an attribute template to add template options:</p>
              )}
              {templates.map((template, index) => (
                <AttributeTemplateButton
                  onClick={() => setSelectedAttributeTemplateId(template.id)}
                  key={template.id}
                >
                  {template.attribute_template_name}
                </AttributeTemplateButton>
              ))}
            </AttributeTemplatesWrapper>

            <PrimaryButton onClick={() => setAddingNewAttributeTemplate(true)}>
              Add New Attribute Template
            </PrimaryButton>
          </>
        )}
      </InnerPanelWrapper>
    </AttributeTypeWrapper>
  );
};

export default AttributeType;
