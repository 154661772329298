import { SidebarItem } from "../SideBar";

export const logisticsSidebarItems: SidebarItem[] = [
  {
    heading: "My Dashboard",
    headingUrl: "logistics/dashboard",
    icon: "navigation-workspaces",
    links: [
      {
        name: "My Dashboard",
        URL: "logistics/dashboard",
      },
    ],
  },
  {
    heading: "Shipments",
    headingUrl: "shipments",
    icon: "shopping-shipping",
    links: [
      {
        name: "Shipments",
        URL: "shipments",
      },
    ],
  },
  {
    heading: "Help",
    headingUrl: "help",
    icon: "alert-question-outline",
    links: [
      {
        name: "WMS Setup Guide",
        URL: "wms-setup-guide",
      },
      {
        name: "WMS User Manual",
        URL: "wms-user-manual",
      },
      {
        name: "Sourcing User Manual",
        URL: "sourcing-user-manual",
      },
    ],
  },
];
