import { CountryNameItem, OrganisationNameItem } from "../graphql/types";

export const mapOrganisationNamesToDrodownObject = (organisationNames: OrganisationNameItem[]) => {
  return organisationNames.map(organisationName => {
    return {
      label: organisationName.organisation_name,
      value: organisationName.id,
    };
  });
};

export const mapCountryNamesToDrodownObject = (countryNames: CountryNameItem[]) => {
  return countryNames.map((countryName, index) => {
    return {
      label: countryName.country_name,
      value: index,
    };
  });
};
