import { ApolloError } from "@apollo/client";

import { useGetOutboundLogisticsTypeNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapLogisticsTypeNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  logisticsTypeNames?: DropdownItem[];
  logisticsTypeNamesError?: ApolloError;
  logisticsTypeNamesLoading: boolean;
}

export const useOutboundLogisticsTypeNames = (): HookResult => {
  const { data, error, loading } = useGetOutboundLogisticsTypeNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    logisticsTypeNames:
      data && Array.isArray(data.logistics_type)
        ? mapLogisticsTypeNamesToDropdownObject(data.logistics_type)
        : [],
    logisticsTypeNamesError: error,
    logisticsTypeNamesLoading: loading,
  };
};
