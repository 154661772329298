import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";
import { UpdateStockManagementContactBody } from "./types";

export const updateStockManagementContact = async (body: UpdateStockManagementContactBody) => {
  try {
    return await STOCK_MANAGEMENT_API.patch("/contacts", body);
  } catch (err) {
    console.log("Error in update stock management contact API call", err);
    throw err;
  }
};
