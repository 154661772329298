/* eslint-disable react-hooks/exhaustive-deps */
import { Formik, FormikProps, FormikState } from "formik";
import * as qs from "query-string";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";

import { ButtonGroup, Modal } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import LoadingScreen from "../../../../../components/loadingScreen/LoadingScreen";
import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorScreen from "../../../../../shared/components/templates/ErrorScreen";
import { Page } from "../../../../../shared/components/templates/Page";
import { EnhancedAttachment } from "../../../../../shared/types/EnhancedAttachment";
import {
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { mapAttachmentsToSet } from "../../shared/mappers";
import { JWTPurchaseOrder } from "../api/JWTPurchaseOrder";
import {
  CreateSupplierResponseBody,
  JWTPurchaseOrderBody,
  SupplierProductReference,
} from "../api/types";
import { InformativeErrorPage } from "../components/InformativeErrorPage";
import { ProductLine } from "../components/ProductLine";
import RejectionModal from "../components/RejectionModal";
import { SignatureSection } from "../components/SignatureSection";
import { initialFieldValues, initialRejectionResponse } from "../formValues";
import { usePurchaseOrderForSupplierResponse } from "../graphql/hooks/usePurchaseOrderForSupplierResponse";
import { OrderLineItem } from "../graphql/types";
import { mapProductReferencetoDTO } from "../mappers";
import {
  createSupplierResponseAction,
  createSupplierResponseReset,
} from "../redux/actions/createSupplierResponseAction";
import { CreateSupplierResponseReducer } from "../redux/reducers/createSupplierResponseReducer";
import { RejectionResponseInterface, SupplierResponseInterface } from "../types";

interface Props {
  createSupplierResponseState: CreateSupplierResponseReducer;
  createSupplierResponse: (
    purchaseOrderId: number,
    body: CreateSupplierResponseBody,
    accessToken: string
  ) => void;
  resetCreateSupplierResponse: () => void;
}

const CHAR_SEQ = "%-%";

const isFileCopyLine = (line: OrderLineItem, poLines: OrderLineItem[]): boolean => {
  const matchingProductLines = poLines.filter(pol => pol.product.id === line.product.id);
  if (matchingProductLines.length > 1) {
    const minQuantity = Math.min(...matchingProductLines.map(pol => pol.quantity));
    return line.quantity === minQuantity;
  }
  return false;
};

function CreatePurchaseOrderSupplierResponse(props: Props) {
  const [initialValues, setInitialValues] = useState<SupplierResponseInterface>(initialFieldValues);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const rejectionResponse: RejectionResponseInterface = initialRejectionResponse;
  const [pathName, setPathName] = useState<string>(
    "/access/purchase-orders/supplier-response/status"
  );
  const [hasTokenBeenVerified, setHasTokenBeenVerified] = useState<boolean>(false);
  const redirect = useHistory();
  const { t } = useTranslation();
  const location = useLocation();
  const params = qs.parse(location.search);
  const modalRef = createRef<HTMLButtonElement>();
  const { createSupplierResponseState } = props;

  const validationSchema = Yup.object().shape({
    created_by_name: Yup.string().required(`${t("supplier_response_acceptance_name_error")}`),
    job_title: Yup.string().required(`${t("supplier_response_acceptance_job_title_error")}`),
  });

  const [attachmentsWithSignedUrls, setAttachmentsWithSignedUrls] = useState<EnhancedAttachment[]>(
    []
  );

  const handleAttachmentDownload = (attachmentUUID: string) => {
    const foundAttachment = attachmentsWithSignedUrls.find(
      attachment => attachment.uuid === attachmentUUID
    );

    if (foundAttachment && foundAttachment.signedUrl) {
      // force browser to download attachment
      const url = `${foundAttachment.signedUrl}&response-content-disposition=attachment; filename="${foundAttachment.originalFilename}"`;
      window.open(encodeURI(url), "_blank");
    }
  };

  const handleResponse = (
    values: SupplierResponseInterface,
    type: "accept" | "reject",
    rejectionResponse: RejectionResponseInterface
  ) => {
    const rejectionNotes = type === "reject" ? rejectionResponse.rejection_notes : undefined;
    const rejectionReason =
      type === "reject" ? rejectionResponse.rejection_reason?.value : undefined;

    if (purchaseOrder) {
      const supplierProductReferences: SupplierProductReference[] = mapProductReferencetoDTO(
        values.purchase_order_lines_data,
        purchaseOrder.organisation.id
      );

      const createSupplierResponseBody: CreateSupplierResponseBody = {
        created_by_name: values.created_by_name,
        job_title: values.job_title,
        initial_notes: values.initial_notes,
        is_accepted: type === "accept" ? true : false,
        rejection_reason: rejectionReason,
        rejection_notes: rejectionNotes,
        supplier_product_references: supplierProductReferences,
      };

      setPathName(
        `/access/purchase-orders/supplier-response/status?purchaseOrderId=${purchaseOrder.purchase_order_document_uid}&type=${type}`
      );

      props.createSupplierResponse(
        purchaseOrder.id,
        createSupplierResponseBody,
        params.token! as string
      );
    }
  };

  useEffect(() => {
    if (createSupplierResponseState.success) {
      props.resetCreateSupplierResponse();
      redirect.push(pathName);
    }

    if (createSupplierResponseState.error) {
      props.resetCreateSupplierResponse();
    }
  }, [createSupplierResponseState]);

  const handleRejectResponse = (
    values: SupplierResponseInterface,
    rejectionResponse: RejectionResponseInterface
  ) => {
    handleResponse(values, "reject", rejectionResponse);
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onSubmit = (
    _values: SupplierResponseInterface,
    {
      resetForm,
    }: { resetForm: (nextState?: Partial<FormikState<SupplierResponseInterface>>) => void }
  ) => {
    resetForm();
  };

  useEffect(() => {
    if (!params.token || !params.purchaseOrderId || !params.supplierOrganisationId) {
      setIsValidToken(false);
      setHasTokenBeenVerified(true);
    }

    const verifyJWT = async ({ token, purchaseOrderId }: JWTPurchaseOrderBody) => {
      try {
        const response = await JWTPurchaseOrder({
          token: token,
          purchaseOrderId,
        });

        if (response.status === 200) {
          if (Array.isArray(response.data?.attachments)) {
            setAttachmentsWithSignedUrls(response.data?.attachments);
          }
          await setIsValidToken(true);
          await setHasTokenBeenVerified(true);
          return;
        } else {
          await setIsValidToken(false);
          await setHasTokenBeenVerified(true);
        }
      } catch (e) {
        await setIsValidToken(false);
        await setHasTokenBeenVerified(true);
      }
    };

    if (
      params.token &&
      typeof params.token === "string" &&
      params.purchaseOrderId &&
      typeof params.purchaseOrderId === "string"
    ) {
      verifyJWT({ token: params.token, purchaseOrderId: params.purchaseOrderId });
    }
  }, [location.pathname]);

  const { purchaseOrder, purchaseOrderError, purchaseOrderLoading } =
    usePurchaseOrderForSupplierResponse({
      purchaseOrderId: params.purchaseOrderId ? Number(params.purchaseOrderId) : 0,
      supplierOrgId: params.supplierOrganisationId ? Number(params.supplierOrganisationId) : 0,
    });

  const isLoading = purchaseOrderLoading || createSupplierResponseState.loading;
  const isError =
    purchaseOrderError ||
    !params.supplierOrganisationId ||
    !params.token ||
    !params.purchaseOrderId;

  const requiredRefs =
    purchaseOrder?.organisation.supplier_details[0].uses_product_references || false;

  useEffect(() => {
    if (
      purchaseOrder &&
      purchaseOrder.supplier_response_id &&
      params.token &&
      params.purchaseOrderId &&
      params.supplierOrganisationId &&
      isValidToken
    ) {
      if (purchaseOrder?.purchase_order_supplier_response?.is_accepted) {
        redirect.push(
          `/access/purchase-orders/supplier-response/status?purchaseOrderId=${purchaseOrder.purchase_order_document_uid}&type=accept`
        );
      } else {
        redirect.push(
          `/access/purchase-orders/supplier-response/status?purchaseOrderId=${purchaseOrder.purchase_order_document_uid}&type=reject`
        );
      }
    }

    if (
      purchaseOrder &&
      params.token &&
      params.purchaseOrderId &&
      params.supplierOrganisationId &&
      isValidToken
    ) {
      setInitialValues({
        ...initialValues,
        purchase_order_lines_data: purchaseOrder.purchase_order_lines.map(pol => {
          const refId =
            pol.product.supplier_products.length === 0
              ? undefined
              : pol.product.supplier_products[0].id;
          const refs = (
            pol.product.supplier_products.length === 0
              ? ""
              : pol.product.supplier_products[0].supplier_reference
          ).split(CHAR_SEQ);

          return {
            reference_id: refId,
            product_id: pol.product.id,
            supplier_order_number: refs.length === 2 ? refs[0] : "",
            supplier_product_id: refs.length === 2 ? refs[1] : refs[0],
            is_file_copy: isFileCopyLine(pol, purchaseOrder.purchase_order_lines),
          };
        }),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder, isValidToken]);

  if (isError) {
    return <ErrorScreen>{t("supplier_response_error_page")}</ErrorScreen>;
  }

  if (!isValidToken && hasTokenBeenVerified && !purchaseOrderLoading && purchaseOrder) {
    return <InformativeErrorPage type="expiredLink" />;
  }

  const handleButtonClick = async (
    type: "reject" | "accept",
    formikProps: FormikProps<SupplierResponseInterface>
  ) => {
    const { values, submitForm, validateForm } = formikProps;

    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }
    submitForm();

    switch (type) {
      case "reject":
        setIsModalOpen(true);
        break;
      case "accept":
        handleResponse(values, "accept", rejectionResponse);
        break;
    }
  };

  if (purchaseOrder && isValidToken && !purchaseOrderLoading && hasTokenBeenVerified) {
    return (
      <Page title={t("supplier_response_page_title")} hasBackButton={false} isLoading={isLoading}>
        <Panel
          withWrapper={true}
          title={`${purchaseOrder.purchase_order_document_uid}`}
          subtitle={t("supplier_response_subtitle_instructions")}
        >
          <InnerPanelWrapper>
            <Formik
              initialValues={initialValues}
              onSubmit={() => {}}
              enableReinitialize
              validationSchema={validationSchema}
            >
              {formikProps => {
                const { values, setFieldValue, handleSubmit, errors, touched } = formikProps;
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <PrimaryLineWrapper>
                      <Title>{t("supplier_response_products_title")}</Title>
                      {values.purchase_order_lines_data.map((_order_line, index) => (
                        <ProductLine
                          key={index}
                          orderLine={purchaseOrder.purchase_order_lines[index]}
                          index={index}
                          requiredRefs={requiredRefs}
                          isFileCopyLine={_order_line.is_file_copy}
                          values={values}
                          setFieldValue={setFieldValue}
                          handleAttachmentDownload={handleAttachmentDownload}
                        />
                      ))}
                    </PrimaryLineWrapper>

                    <PrimaryLineWrapper>
                      <Title>{t("supplier_response_attachments_title")}</Title>
                      {purchaseOrder.folder.attachments.length === 0 && (
                        <PrimaryLineDataWrapper>
                          {t("supplier_response_attachments_not_found")}
                        </PrimaryLineDataWrapper>
                      )}
                      {mapAttachmentsToSet(purchaseOrder.folder.attachments).map(
                        (attachment, index) => (
                          <PrimaryLineDataWrapper key={attachment.id}>
                            <AttachmentDownloader
                              key={index}
                              originalFilename={attachment.original_filename}
                              attachmentUUID={attachment.attachment_uuid}
                              onDownload={attachmentUUID => {
                                handleAttachmentDownload(attachmentUUID);
                              }}
                              metadata={attachment.metadata}
                            />
                          </PrimaryLineDataWrapper>
                        )
                      )}
                    </PrimaryLineWrapper>

                    {!!purchaseOrder.external_order_notes && (
                      <PrimaryLineWrapper>
                        <Title>{t("supplier_response_external_notes")}</Title>
                        {purchaseOrder.external_order_notes}
                      </PrimaryLineWrapper>
                    )}

                    <SignatureSection
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                    />

                    <Modal
                      id="rejection_modal"
                      isOpen={isModalOpen}
                      handleClose={() => setIsModalOpen(false)}
                      triggerRef={modalRef}
                    >
                      <RejectionModal
                        purchaseOrderNumber={purchaseOrder.purchase_order_document_uid || ""}
                        setIsModalOpen={setIsModalOpen}
                        handleResponse={handleRejectResponse}
                        formValues={formikProps.values}
                      ></RejectionModal>
                    </Modal>

                    <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                      <SecondaryButton
                        appearance={"whiteButton"}
                        type="submit"
                        onClick={e => handleButtonClick("reject", formikProps)}
                      >
                        {t("supplier_response_reject_button")}
                      </SecondaryButton>

                      <SecondaryButton
                        appearance={"blueButton"}
                        type="submit"
                        onClick={e => handleButtonClick("accept", formikProps)}
                      >
                        {t("supplier_response_accept_button")}
                      </SecondaryButton>
                    </ButtonGroup>
                  </StyledForm>
                );
              }}
            </Formik>
          </InnerPanelWrapper>
        </Panel>
      </Page>
    );
  }
  if (!purchaseOrderLoading && !purchaseOrder && !isValidToken && hasTokenBeenVerified) {
    return <InformativeErrorPage type="missingOrder" />;
  } else {
    return <LoadingScreen></LoadingScreen>;
  }
}

function mapStateToProps(state: StoreTypes) {
  return {
    createSupplierResponseState: state.createSupplierResponseReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createSupplierResponse: (
      purchaseOrderId: number,
      body: CreateSupplierResponseBody,
      accessToken: string
    ) => dispatch(createSupplierResponseAction(purchaseOrderId, body, accessToken)),
    resetCreateSupplierResponse: () => dispatch(createSupplierResponseReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreatePurchaseOrderSupplierResponse);
