import { PRODUCT_CATALOGUE_API } from "../../../../../api/baseConfig";

export interface UnlinkSkuFromOrgParams {
  skuId: string;
  orgId: string;
}

export const UnlinkSkuFromOrg = async (params: UnlinkSkuFromOrgParams) => {
  try {
    return await PRODUCT_CATALOGUE_API.delete(`/sku/${params.skuId}/${params.orgId}`);
  } catch (err) {
    console.log("Error in unlink sku from organisation API call", err);
    throw err;
  }
};
