import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreateSupplierBody } from "./types";

export const createSupplierAPI = async (body: CreateSupplierBody) => {
  try {
    return await SOURCING_API.post("/suppliers", body);
  } catch (err) {
    console.log("Error in create supplier API call", err);
    throw err;
  }
};
