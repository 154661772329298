import { ApolloError } from "@apollo/client";

import { useGetWorkOrdersByDateQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DashboardItem } from "../types";

export interface HookResult {
  workOrders: DashboardItem[];
  workOrdersError?: ApolloError;
  workOrdersLoading: boolean;
}

export const useWorkOrdersByDate = (selectedDate: string): HookResult => {
  const { data, error, loading } = useGetWorkOrdersByDateQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      selectedDate,
    },
  });

  return {
    workOrders:
      data && Array.isArray(data.recipe)
        ? data.recipe
            .flatMap(recipe => recipe.work_orders)
            .map(workOrder => {
              return {
                orderId: workOrder.id,
                organisationName: workOrder.organisation.organisation_name,
                iconName: "default-calendar",
              };
            })
        : [],
    workOrdersError: error,
    workOrdersLoading: loading,
  };
};
