import React from "react";

import {
  ButtonGroup,
  Heading,
  Icon,
  IconText,
  SecondaryButton,
  styled,
} from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

import { ToolTip } from "../../shared/components/atoms/labels/ToolTip/ToolTip";
import { PanelWrapper, RectangleStatusBadge } from "../../styles/SharedStyles";
import { SourcingStatusBadge } from "../../styles/SourcingStyles";

export interface PanelProps {
  children: React.ReactNode;
  contentStyles?: any; //TO DO : Export StichesCSS from shared components
  title: string;
  subtitle?: string;
  text?: string;
  allignTitle?: "left" | "center";
  rightItems?: RightItems[] | null;
  iconName?: IconName;
  manifestStatusName?: string;
  sourcingStatusName?: string;
  toolTipMessage?: string;
  withWrapper?: boolean;
}

export type RightItemIcon = {
  type: "icon";
  id: number;
  iconName: IconName;
  handleClick: () => void;
};

export type RightItemText = {
  type: "text";
  id: number;
  title: string;
  handleClick: () => void;
};

export type RightItemButton = {
  type: "button";
  id: number;
  title: string;
  iconName: IconName;
  handleClick: () => void;
};

export type RightItems = RightItemIcon | RightItemText | RightItemButton;

const PanelStyles = {
  position: "relative",
  background: "#FFFFFF",
  borderRadius: "10px",
  border: "1px solid #C4C4C4",
  height: "auto",
  width: "auto",
};

const Main = styled("main", PanelStyles);
export const BasicPanel = styled("div", PanelStyles);

const HeaderWithIcon = styled("div", {
  display: "flex",
  color: "#000C32",
});

const IconWrapper = styled("div", {
  paddingRight: "8px",
});

const HeaderWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  padding: "25px",
});

const Subtitle = styled("p", {
  fontSize: "15px",
  color: "#8D8D8D",
  margin: "0px",
  textAlign: "center",
  marginTop: "5px",
});

const Text = styled("p", {
  fontSize: "15px",
  margin: "0px",
  marginTop: "35px",
  color: "#000C32",
});

const RightItemsWrapper = styled("ul", {
  position: "absolute",
  right: "0px",
  top: "0px",
  margin: "20px",
});

const Button = styled("button", {
  padding: "0px",
  cursor: "pointer",
  border: "none",
  paddingRight: "10px",
  textDecoration: "underline",
  "&:hover": {
    textDecoration: "none",
  },
  variants: {
    isBold: {
      true: {
        fontWeight: "bold",
      },
    },
  },
});

export const TitleWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
});

export const StatusBadge = styled("div", {
  backgroundColor: "#005AE1",
  color: "#FFFFFF",
  display: "flex",
  height: "35px",
  justifyContent: "center",
  alignItems: "center",
  width: "110px",
  borderRadius: "5px",
});

const StatusBadgeWrapper = styled("div", {
  position: "absolute",
  right: 0,
  marginRight: "35px",
});

const ListItem = styled("li", {
  listStyle: "none",
  display: "flex",
});

const Panel = ({
  children,
  contentStyles,
  title,
  subtitle,
  allignTitle = "center",
  rightItems,
  iconName,
  text,
  manifestStatusName,
  sourcingStatusName,
  toolTipMessage,
  withWrapper,
}: PanelProps) => {
  return (
    <>
      {withWrapper ? (
        <PanelWrapper>
          <Main css={contentStyles}>
            <HeaderWrapper css={{ alignItems: allignTitle }}>
              <TitleWrapper>
                {iconName ? (
                  <HeaderWithIcon>
                    <IconWrapper>
                      <Icon width={30} height={30} name={iconName} />
                    </IconWrapper>
                    <Heading level={5}>{title}</Heading>
                  </HeaderWithIcon>
                ) : (
                  <HeaderWithIcon>
                    <Heading level={5}>{title}</Heading>
                  </HeaderWithIcon>
                )}
                {toolTipMessage && <ToolTip message={toolTipMessage}></ToolTip>}
              </TitleWrapper>
              {manifestStatusName && (
                <StatusBadgeWrapper>
                  <RectangleStatusBadge status={manifestStatusName.replace(/\s/g, "") as any}>
                    {manifestStatusName}
                  </RectangleStatusBadge>
                </StatusBadgeWrapper>
              )}
              {sourcingStatusName && (
                <StatusBadgeWrapper>
                  <SourcingStatusBadge type={sourcingStatusName.replace(/\s/g, "") as any}>
                    {sourcingStatusName}
                  </SourcingStatusBadge>
                </StatusBadgeWrapper>
              )}
              {subtitle && <Subtitle> {subtitle} </Subtitle>}
              {text && <Text>{text}</Text>}
              {rightItems && (
                <RightItemsWrapper>
                  <ListItem>
                    <ButtonGroup>
                      {rightItems.map(item => {
                        if (item.type === "icon") {
                          return (
                            <Button onClick={item.handleClick} key={item.id}>
                              <Icon name={item.iconName} />
                            </Button>
                          );
                        }
                        if (item.type === "text") {
                          return (
                            <Button isBold={true} key={item.id} onClick={item.handleClick}>
                              {item.title}
                            </Button>
                          );
                        }
                        return (
                          <SecondaryButton
                            key={item.id}
                            onClick={item.handleClick}
                            css={{
                              backgroundColor: "#005AE1",
                              borderColor: "#005AE1",
                              color: "#FFFFFF",
                            }}
                          >
                            <IconText text={item.title} primaryIcon={item.iconName} />
                          </SecondaryButton>
                        );
                      })}
                    </ButtonGroup>
                  </ListItem>
                </RightItemsWrapper>
              )}
            </HeaderWrapper>
            {children}
          </Main>
        </PanelWrapper>
      ) : (
        <Main css={contentStyles}>
          <HeaderWrapper css={{ alignItems: allignTitle }}>
            <TitleWrapper>
              {iconName ? (
                <HeaderWithIcon>
                  <IconWrapper>
                    <Icon width={30} height={30} name={iconName} />
                  </IconWrapper>
                  <Heading level={5}>{title}</Heading>
                </HeaderWithIcon>
              ) : (
                <HeaderWithIcon>
                  <Heading level={5}>{title}</Heading>
                </HeaderWithIcon>
              )}
              {toolTipMessage && <ToolTip message={toolTipMessage}></ToolTip>}
            </TitleWrapper>
            {manifestStatusName && (
              <StatusBadgeWrapper>
                <RectangleStatusBadge status={manifestStatusName.replace(/\s/g, "") as any}>
                  {manifestStatusName}
                </RectangleStatusBadge>
              </StatusBadgeWrapper>
            )}
            {sourcingStatusName && (
              <StatusBadgeWrapper>
                <SourcingStatusBadge type={sourcingStatusName.replace(/\s/g, "") as any}>
                  {sourcingStatusName}
                </SourcingStatusBadge>
              </StatusBadgeWrapper>
            )}
            {subtitle && <Subtitle> {subtitle} </Subtitle>}
            {text && <Text>{text}</Text>}
            {rightItems && (
              <RightItemsWrapper>
                <ListItem>
                  <ButtonGroup>
                    {rightItems.map(item => {
                      if (item.type === "icon") {
                        return (
                          <Button onClick={item.handleClick} key={item.id}>
                            <Icon name={item.iconName} />
                          </Button>
                        );
                      }
                      if (item.type === "text") {
                        return (
                          <Button isBold={true} key={item.id} onClick={item.handleClick}>
                            {item.title}
                          </Button>
                        );
                      }
                      return (
                        <SecondaryButton
                          key={item.id}
                          onClick={item.handleClick}
                          css={{
                            backgroundColor: "#005AE1",
                            borderColor: "#005AE1",
                            color: "#FFFFFF",
                          }}
                        >
                          <IconText text={item.title} primaryIcon={item.iconName} />
                        </SecondaryButton>
                      );
                    })}
                  </ButtonGroup>
                </ListItem>
              </RightItemsWrapper>
            )}
          </HeaderWrapper>
          {children}
        </Main>
      )}
    </>
  );
};

export default Panel;
