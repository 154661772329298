import { DropdownItem } from "../graphql/types";

export interface StockAllocationForm {
  product_stock_id: DropdownItem | null;
  quantity: number;
  organisation_id: DropdownItem | null;
  from_organisation_id: DropdownItem | null;
}

export const initialFieldValues: StockAllocationForm = {
  product_stock_id: null,
  quantity: 0,
  organisation_id: null,
  from_organisation_id: null,
};

export const fieldNames = {
  product_stock_id: "product_stock_id",
  quantity: "quantity",
  organisation_id: "organisation_id",
  from_organisation_id: "from_organisation_id",
};
