import { SOURCING_API } from "../../../../../api/baseConfig";
import { UpdateSupplierDeliveryDateConfigBody } from "./types";

export const updateSupplierDeliveryDateConfigAPI = async (
  body: UpdateSupplierDeliveryDateConfigBody
) => {
  try {
    return await SOURCING_API.patch("/suppliers/supplier-config", body);
  } catch (err) {
    console.log("Error in update supplier delivery date config API call", err);
    throw err;
  }
};
