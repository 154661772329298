import { Formik } from "formik";
import { useHistory } from "react-router-dom";
import AsyncCreatableSelect from "react-select/async-creatable";
import { toast } from "react-toastify";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { addIAMMembers } from "../api/addIAMMembers";
import { assignMemberRoles } from "../api/assignMemberRoles";
import { createIAMOrganisation } from "../api/createIAMOrganisation";
import { createIAMUser } from "../api/createIAMUser";
import { getIAMOrganisations } from "../api/getIAMOrganisations";
import { getIAMUsers } from "../api/getIAMUsers";
import { markIAMUserOnboarded } from "../api/markIAMUserOnboarded";
import { createUserFormSchema } from "../validation";

// id the same across the env and the only options right now for external users
const loadRoleOptions = [
  {
    label: "client_admin - admin role for external client user in external organisation",
    value: 2,
  },
  {
    label: "client_user - user role for external client user in external organisation",
    value: 21,
  },
  {
    label: "supplier_admin - admin role for external supplier user in external organisation",
    value: 19,
  },
  {
    label: "supplier_user - user role for external supplier user in external organisation",
    value: 20,
  },
];

export interface RoleOption {
  label: string;
  value: number;
}

export interface CreateUserForm {
  email: string;
  phoneNumber: string;
  fullName: string;
  roles: RoleOption[];
  organisationName: {
    label: string;
    value: string;
  } | null;
}

export const initialFieldValues: CreateUserForm = {
  email: "",
  phoneNumber: "",
  fullName: "",
  organisationName: null,
  roles: [],
};

export const fieldObjects = {
  roles: {
    fieldName: "roles",
    fieldLabel: "Roles",
    fieldPlaceholder: "Add roles",
    fieldBox: "Please select roles",
  },
  email: {
    fieldName: "email",
    fieldLabel: "Email",
    fieldPlaceholder: "Add User Email",
    fieldBox: "Please fill User Email",
  },
  phoneNumber: {
    fieldName: "phoneNumber",
    fieldLabel: "Phone Number",
    fieldPlaceholder: "Add Phone Number",
    fieldBox: "Please fill Phone Number",
  },
  fullName: {
    fieldName: "fullName",
    fieldLabel: "Full Name",
    fieldPlaceholder: "Add Full Name",
    fieldBox: "Please fill Full Name",
  },
  organisationName: {
    fieldName: "organisationName",
    fieldLabel: "Organisation Name",
    fieldPlaceholder: "Add Organisation Name",
    fieldBox: "Please fill Organisation Name",
  },
};

export interface IAMOrganisation {
  uuid?: string | null;
  name?: string | null;
  display_name?: string | null;
  external_account_id?: string | null;
  metadata: any;
  organisations_users_aggregate: {
    aggregate: {
      count: number;
    };
  };
}

export interface OrgOption {
  label: string;
  value: string;
}

const CreateUser = () => {
  const history = useHistory();

  const [, getOrganisations] = useAPI(getIAMOrganisations, true, {
    pending: "Fetching organisation...",
    success: "Organisations fetched successfully",
    error: "Error while trying to fetch organisations",
  });

  const [, createOrganisation] = useAPI(createIAMOrganisation, true, {
    pending: "creating org...",
    success: "org added successfully",
    error: "Error while trying to add org",
  });

  const [addMembersState, addMembers] = useAPI(addIAMMembers, true, {
    pending: "add users membership...",
    success: "users membership added successfully",
    error: "Error while trying to add membership",
  });

  const [addRolesState, addRoles] = useAPI(assignMemberRoles, true, {
    pending: "add users roles...",
    success: "users roles added successfully",
    error: "Error while trying to add roles",
  });

  const [createUserState, createUser] = useAPI(createIAMUser, false, {
    pending: "creating user...",
    success: "user added successfully",
    error: "Error while trying to add user",
  });

  const [markUserOnboardedState, markUserOnboarded] = useAPI(markIAMUserOnboarded, false, {
    pending: "creating user...",
    success: "user added successfully",
    error: "Error while trying to add user",
  });

  const [, getUsers] = useAPI(getIAMUsers, true, {
    pending: "Fetching users...",
    success: "users fetched successfully",
    error: "Error while trying to fetch users",
  });

  const createHandleCreate = (
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    return async (displayName: string) => {
      const response = await createOrganisation({
        displayName: displayName,
        metadata: "{}",
      });
      const newoption = Array.isArray(response) ? response[0].data : response.data;
      setFieldValue(fieldObjects.organisationName.fieldName, {
        label: `${displayName} - org size: 0`,
        value: newoption.uuid,
      });
    };
  };

  const loadOptions = async (value: string) => {
    const response = await getOrganisations({
      offset: 0,
      limit: 50,
      filters: [{ key: "name", value: `_regex:${value}` }], // Assuming your API can filter users based on a query
    });
    const organisationsdata = Array.isArray(response) ? response[0].data.data : response.data.data;
    const filteredOptions = organisationsdata.map((org: IAMOrganisation) => ({
      label: `${org.name} - org size: ${org.organisations_users_aggregate.aggregate.count}${
        org.external_account_id ? ` (Salesforce Enabled)` : ``
      }`,
      value: org.uuid,
    }));
    return filteredOptions;
  };
  const onSubmit = async (values: CreateUserForm) => {
    const response = await getUsers({
      offset: 0,
      limit: 50,
      filters: [{ key: "email", value: `_eq:${values.email}` }], // Assuming your API can filter users based on a query
    });
    const usersData = Array.isArray(response) ? response[0].data.data : response.data.data;

    if (Array.isArray(usersData) && usersData.length > 0) {
      toast.error("User with the same email address already exists");
      return;
    }

    const create_user_response = await createUser({
      username: values.email,
      email: values.email,
      nickname: values.email,
      givenname: values.fullName,
      name: values.fullName,
      emailVerified: true,
      userMetadata: {
        phone_number: values.phoneNumber,
        onboarded: "true",
      },
    });
    const createUsersData = Array.isArray(create_user_response)
      ? create_user_response[0].data.data
      : create_user_response.data.data;
    const user_uuid = createUsersData.user_uuid;
    const org_uuid = values.organisationName!.value;
    const org_display_name = values.organisationName!.label;

    await addMembers({
      userUUIDs: [user_uuid],
      organisationId: org_uuid,
    });

    await addRoles({
      roleIDs: values.roles.map(role => role.value),
      organisationUUID: org_uuid,
      userUUID: user_uuid,
    });

    await markUserOnboarded({
      orgUuid: org_uuid,
      userUuid: user_uuid,
    });

    history.push(`/admin/iam/organisations/${org_uuid}/${org_display_name}/members`);
  };

  const isLoading =
    markUserOnboardedState === API_REQUEST_STATE.PENDING ||
    addRolesState === API_REQUEST_STATE.PENDING ||
    addMembersState === API_REQUEST_STATE.PENDING ||
    createUserState === API_REQUEST_STATE.PENDING;
  const isError =
    markUserOnboardedState === API_REQUEST_STATE.ERROR ||
    addRolesState === API_REQUEST_STATE.ERROR ||
    addMembersState === API_REQUEST_STATE.ERROR ||
    createUserState === API_REQUEST_STATE.ERROR;

  return (
    <Page title={"Create New User"} isLoading={isLoading} error={isError}>
      <Panel withWrapper title={"Provide user details"}>
        <InnerPanelWrapper>
          <Formik
            enableReinitialize
            initialValues={initialFieldValues}
            onSubmit={onSubmit}
            validationSchema={createUserFormSchema}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              validateForm,
              setTouched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <Title>Create User</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.email.fieldName}>
                          {fieldObjects.email.fieldLabel}
                        </Label>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldObjects.email.fieldName}
                          name={fieldObjects.email.fieldName}
                          size={"medium"}
                          error={errors.email}
                          touched={touched.email}
                          type={"text"}
                          value={values.email}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.email.fieldName, e.target.value);
                          }}
                          placeholder={fieldObjects.email.fieldPlaceholder}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.fullName.fieldName}>
                          {fieldObjects.fullName.fieldLabel}
                        </Label>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldObjects.fullName.fieldName}
                          name={fieldObjects.fullName.fieldName}
                          size={"medium"}
                          error={errors.fullName}
                          touched={touched.fullName}
                          type={"text"}
                          value={values.fullName}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.fullName.fieldName, e.target.value);
                          }}
                          placeholder={fieldObjects.fullName.fieldPlaceholder}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.phoneNumber.fieldName}>
                          {fieldObjects.phoneNumber.fieldLabel}
                        </Label>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldObjects.phoneNumber.fieldName}
                          name={fieldObjects.phoneNumber.fieldName}
                          size={"medium"}
                          error={errors.phoneNumber}
                          touched={touched.phoneNumber}
                          type={"text"}
                          value={values.phoneNumber}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.phoneNumber.fieldName, e.target.value);
                          }}
                          placeholder={fieldObjects.phoneNumber.fieldPlaceholder}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin fullWidth>
                        <SimpleSelect
                          isRequired
                          options={loadRoleOptions}
                          htmlFor={fieldObjects.roles.fieldName}
                          isMulti
                          value={values.roles}
                          changeHandler={e => setFieldValue(fieldObjects.roles.fieldName, e)}
                          placeholder={fieldObjects.roles.fieldPlaceholder}
                          name={fieldObjects.roles.fieldName}
                          error={errors.roles}
                          touched={touched.roles}
                          label={fieldObjects.roles.fieldLabel}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.organisationName.fieldName}>
                          {fieldObjects.organisationName.fieldLabel}
                        </Label>
                        <AsyncCreatableSelect
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          loadOptions={loadOptions}
                          id={fieldObjects.organisationName.fieldName}
                          value={values.organisationName}
                          onCreateOption={createHandleCreate(setFieldValue)}
                          onChange={e => {
                            setFieldValue(fieldObjects.organisationName.fieldName, e);
                          }}
                          placeholder={fieldObjects.organisationName.fieldPlaceholder}
                        />
                        {errors.organisationName && touched.organisationName ? (
                          <ErrorMessage>{JSON.stringify(errors.organisationName)}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <SecondaryButton
                    type="button"
                    appearance={"blueButton"}
                    onClick={() => handleSubmit()}
                  >
                    Confirm
                  </SecondaryButton>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default CreateUser;
