import { Formik } from "formik";
import { useEffect } from "react";
import { toast } from "react-toastify";

import { CheckBox } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor/VisibleFor";
import {
  STOCK_MANAGEMENT_CURRENCIES,
  STOCK_MANAGEMENT_LOCALES,
} from "../../../../../shared/constants";
import {
  mapCurrencyCodeToDropdownItem,
  mapIAMOrganisationsToDropdownItem,
  mapLocaleToDropdownItem,
  mapPaymentDueDateTypeToDropdownItem,
} from "../../../../../shared/mappers";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
} from "../../../../../styles/SharedStyles";
import { useAPI } from "../../../../../util/api/apiHook";
import { useOrganisations } from "../../../shared/hooks/useOrganisations";
import { booleanReadableLabels, orderFlowTypes } from "../../shared/graphql/mappers";
import {
  CreateStockManagementOrganisationBody,
  createStockManagementOrganisation,
} from "../api/createOrganisation";
import {
  CreateOrganisationDataInterface,
  createOrganisationFieldNames,
  initialCreateOrganisationDataValues,
} from "../formValues";
import { createOrganisationDataValidationSchema } from "../validation";

const CreateOrganisation = () => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [createOrganisationState, createOrganisation] = useAPI(
    createStockManagementOrganisation,
    false,
    {
      pending: "Creating organisation...",
      success: "Organisation created successfully",
      error: "Error while trying to create organisation",
    }
  );
  const { organisations, organisationsLoading, organisationsError } = useOrganisations();

  useEffect(() => {
    if (organisationsError) {
      toast.error("Error while trying to get organisations");
    }
  }, [organisationsError]);

  const handleSubmitCreateOrganisation = async (values: CreateOrganisationDataInterface) => {
    const externalOrgId: any = values.externalOrganisationId?.value;
    const orderFlowTypeId: any = values.config.orderFlowTypeId?.value;
    const paymentDueDateType: any = values.config.paymentDueDate.type?.value;
    const defaultCurrency: any = values.defaultCurrency?.label;
    const locale = values?.locale?.label as string;
    const body: CreateStockManagementOrganisationBody = {
      name: values.name,
      organisationTypeId: values.organisationTypeId,
      config: {
        paymentTerms: values.config.paymentTerms,
        paymentDueDate: {
          type: paymentDueDateType,
          value: values.config.paymentDueDate.value,
        },
        xeroClientName: values.config.xeroClientName,
        orderFlowTypeId: orderFlowTypeId,
        autostockEnabled: values.config.autostockEnabled,
        mainProviderType: values.config.mainProviderType,
        paymentDisclaimer: values.config.paymentDisclaimer,
        productsSourceType: values.config.productsSourceType,
        selfserviceEnabled: values.config.selfserviceEnabled,
        orderProcessingType: values.config.orderProcessingType,
        productPricingSourceType: values.config.productPricingSourceType,
        deliveryInvoicedSeparately: values.config.deliveryInvoicedSeparately,
        computeDeliveryCostPerProduct: values.config.computeDeliveryCostPerProduct,
        enableClientReferenceCollection: values.config.enableClientReferenceCollection,
        prepaidStock: values.config.prepaidStock,
        displayProductUnitCost: values.config.displayProductUnitCost,
      },
      locale: locale,
      defaultCurrency: defaultCurrency,
      externalOrganisationId: externalOrgId,
    };
    try {
      await createOrganisation(body);
    } catch (error) {
      console.error("error creating organisation in CreateOrganisation:", error);
    }
  };

  return (
    <Page title={"Create Organisation"} isLoading={organisationsLoading} error={organisationsError}>
      <Panel
        withWrapper={true}
        title={"Onboard a new organisation to the Stock Management platform"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialCreateOrganisationDataValues}
            enableReinitialize
            onSubmit={async (values: CreateOrganisationDataInterface, { resetForm }) => {
              await handleSubmitCreateOrganisation(values);
              resetForm();
            }}
            validationSchema={createOrganisationDataValidationSchema}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <Title>1. Select IAM Organisation</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleSelect
                          isRequired
                          isSearchable
                          isDisabled={organisationsError}
                          htmlFor={createOrganisationFieldNames.externalOrganisationId}
                          name={createOrganisationFieldNames.externalOrganisationId}
                          value={values.externalOrganisationId}
                          options={mapIAMOrganisationsToDropdownItem(organisations ?? [])}
                          placeholder={
                            organisationsLoading ? "Loading..." : "Select Organisation(s)"
                          }
                          label="An organisation must first be created on Auth0/IAM before it can be onboarded to the Stock Management platform. If it's not listed here please contact the development team."
                          changeHandler={event => {
                            setFieldValue(
                              createOrganisationFieldNames.externalOrganisationId,
                              event
                            );
                            setFieldValue(createOrganisationFieldNames.name, event?.label);
                          }}
                          error={errors.externalOrganisationId}
                          touched={touched.externalOrganisationId}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <PrimaryLineWrapper>
                    <Title>2. Fill in organisation details</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          htmlFor={createOrganisationFieldNames.name}
                          name={createOrganisationFieldNames.name}
                          size={"medium"}
                          error={errors.name}
                          touched={touched.name}
                          label={"Organisation name"}
                          type={"text"}
                          placeholder={""}
                          value={values.name}
                          changeHandler={event => {
                            setFieldValue(createOrganisationFieldNames.name, event.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint>
                        <SimpleSelect
                          isRequired
                          htmlFor={createOrganisationFieldNames.defaultCurrency}
                          name={createOrganisationFieldNames.defaultCurrency}
                          maxMenuHeight={220}
                          error={errors.defaultCurrency}
                          touched={touched.defaultCurrency}
                          placeholder="Select currency code"
                          label="Default Currency Code"
                          options={mapCurrencyCodeToDropdownItem(
                            STOCK_MANAGEMENT_CURRENCIES.map(currency => {
                              return {
                                currency_code: currency,
                              };
                            })
                          )}
                          value={values.defaultCurrency}
                          changeHandler={event => {
                            setFieldValue(createOrganisationFieldNames.defaultCurrency, event);
                          }}
                        />
                      </DataPoint>
                      <DataPoint>
                        <SimpleSelect
                          isRequired
                          htmlFor={createOrganisationFieldNames.locale}
                          name={createOrganisationFieldNames.locale}
                          maxMenuHeight={220}
                          error={errors.locale}
                          touched={touched.locale}
                          placeholder="Select locale"
                          label="Organisation Locale"
                          options={mapLocaleToDropdownItem(STOCK_MANAGEMENT_LOCALES)}
                          value={values.locale}
                          changeHandler={event => {
                            setFieldValue(createOrganisationFieldNames.locale, event);
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <PrimaryLineWrapper>
                    <Title>3. Define organisation config</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          htmlFor={createOrganisationFieldNames.paymentTerms}
                          name={createOrganisationFieldNames.paymentTerms}
                          size={"medium"}
                          error={errors.config?.paymentTerms}
                          touched={touched.config?.paymentTerms}
                          label={"Payment terms (example: '30 days after invoice')"}
                          type={"text"}
                          placeholder={""}
                          value={values.config.paymentTerms}
                          changeHandler={event => {
                            setFieldValue(
                              createOrganisationFieldNames.paymentTerms,
                              event.target.value
                            );
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint>
                        <SimpleSelect
                          isRequired
                          htmlFor={createOrganisationFieldNames.paymentDueDateType}
                          name={createOrganisationFieldNames.paymentDueDateType}
                          maxMenuHeight={220}
                          error={errors.config?.paymentDueDate?.type}
                          touched={touched.config?.paymentDueDate?.type}
                          placeholder="Select type"
                          label="Payment due date type"
                          options={mapPaymentDueDateTypeToDropdownItem([
                            {
                              value: "last_day_of_month",
                              readable: "Last day of the month",
                            },
                            {
                              value: "days_after_invoice",
                              readable: "(x) days after invoice",
                            },
                          ])}
                          value={values.config.paymentDueDate.type}
                          changeHandler={event => {
                            if (event?.value === "last_day_of_month") {
                              setFieldValue(createOrganisationFieldNames.paymentDueDateValue, 0);
                            }
                            setFieldValue(createOrganisationFieldNames.paymentDueDateType, event);
                          }}
                        />
                      </DataPoint>
                      {values.config.paymentDueDate.type?.label === "(x) days after invoice" && (
                        <DataPoint>
                          <SimpleInput
                            isRequired
                            htmlFor={createOrganisationFieldNames.paymentDueDateValue}
                            name={createOrganisationFieldNames.paymentDueDateValue}
                            size={"medium"}
                            error={errors.config?.paymentDueDate?.value}
                            touched={touched.config?.paymentDueDate?.value}
                            label={"Payment due date: # of days"}
                            placeholder={"1"}
                            min={1}
                            type={"number"}
                            value={values.config.paymentDueDate.value}
                            changeHandler={event => {
                              setFieldValue(
                                createOrganisationFieldNames.paymentDueDateValue,
                                Number(event.target.value)
                              );
                            }}
                          />
                        </DataPoint>
                      )}
                    </PrimaryLineDataWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          htmlFor={createOrganisationFieldNames.xeroClientName}
                          name={createOrganisationFieldNames.xeroClientName}
                          size={"medium"}
                          error={errors.config?.xeroClientName}
                          touched={touched.config?.xeroClientName}
                          label={"Xero Client Name (must match client name on Xero)"}
                          type={"text"}
                          placeholder={""}
                          value={values.config.xeroClientName}
                          changeHandler={event => {
                            setFieldValue(
                              createOrganisationFieldNames.xeroClientName,
                              event.target.value
                            );
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleSelect
                          isRequired
                          htmlFor={createOrganisationFieldNames.orderFlowTypeId}
                          name={createOrganisationFieldNames.orderFlowTypeId}
                          maxMenuHeight={220}
                          error={errors.config?.orderFlowTypeId}
                          touched={touched.config?.orderFlowTypeId}
                          placeholder="Select type"
                          label="Order flow type"
                          options={orderFlowTypes}
                          value={values.config.orderFlowTypeId}
                          changeHandler={event => {
                            setFieldValue(createOrganisationFieldNames.orderFlowTypeId, event);
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          htmlFor={createOrganisationFieldNames.paymentDisclaimer}
                          name={createOrganisationFieldNames.paymentDisclaimer}
                          size={"medium"}
                          error={errors.config?.paymentDisclaimer}
                          touched={touched.config?.paymentDisclaimer}
                          label={
                            "Payment disclaimer (this will be shown on the Stock Management 'view basket' page for clients to read. A payment disclaimer example: 'You won't be charged yet. Your basket total will be added to your agreed payment plan for this month.')"
                          }
                          type={"text"}
                          placeholder={""}
                          value={values.config.paymentDisclaimer}
                          changeHandler={event => {
                            setFieldValue(
                              createOrganisationFieldNames.paymentDisclaimer,
                              event.target.value
                            );
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <CheckBox
                          label={booleanReadableLabels.deliveryInvoicedSeparately}
                          value={createOrganisationFieldNames.deliveryInvoicedSeparately}
                          id={createOrganisationFieldNames.deliveryInvoicedSeparately}
                          isChecked={values.config.deliveryInvoicedSeparately ?? false}
                          handleChange={event => {
                            setFieldValue(
                              createOrganisationFieldNames.deliveryInvoicedSeparately,
                              event.target.checked
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <CheckBox
                          label={booleanReadableLabels.computeDeliveryCostPerProduct}
                          value={createOrganisationFieldNames.computeDeliveryCostPerProduct}
                          id={createOrganisationFieldNames.computeDeliveryCostPerProduct}
                          isChecked={values.config.computeDeliveryCostPerProduct ?? false}
                          handleChange={event => {
                            setFieldValue(
                              createOrganisationFieldNames.computeDeliveryCostPerProduct,
                              event.target.checked
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <CheckBox
                          label={booleanReadableLabels.enableClientReferenceCollection}
                          value={createOrganisationFieldNames.enableClientReferenceCollection}
                          id={createOrganisationFieldNames.enableClientReferenceCollection}
                          isChecked={values.config.enableClientReferenceCollection ?? false}
                          handleChange={event => {
                            setFieldValue(
                              createOrganisationFieldNames.enableClientReferenceCollection,
                              event.target.checked
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <CheckBox
                          label={booleanReadableLabels.prepaidStock}
                          value={createOrganisationFieldNames.prepaidStock}
                          id={createOrganisationFieldNames.prepaidStock}
                          isChecked={values.config.prepaidStock ?? false}
                          handleChange={event => {
                            setFieldValue(
                              createOrganisationFieldNames.prepaidStock,
                              event.target.checked
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <CheckBox
                          label={booleanReadableLabels.displayProductUnitCost}
                          value={createOrganisationFieldNames.displayProductUnitCost}
                          id={createOrganisationFieldNames.displayProductUnitCost}
                          isChecked={values.config.displayProductUnitCost ?? false}
                          handleChange={event => {
                            setFieldValue(
                              createOrganisationFieldNames.displayProductUnitCost,
                              event.target.checked
                            );
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <VisibleFor roles={SM_ADMIN_ROLES}>
                    <ButtonWrapper style={{ marginTop: "10px" }}>
                      <SecondaryButton type="submit" appearance={"blueButton"} isWithinTable>
                        Create Organisation
                      </SecondaryButton>
                    </ButtonWrapper>
                  </VisibleFor>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default CreateOrganisation;
