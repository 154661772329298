import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";

export const initialCreateOrganisationDataValues: CreateOrganisationDataInterface = {
  defaultCurrency: null,
  externalOrganisationId: null,
  organisationTypeId: 1, // hardcoded as Client type
  config: {
    paymentTerms: "",
    paymentDueDate: { type: null, value: 0 },
    xeroClientName: "",
    orderFlowTypeId: null,
    autostockEnabled: false, // hardcoded as false
    mainProviderType: "sourceful", // hardcoded as Sourceful
    paymentDisclaimer: "",
    productsSourceType: "sourceful", // hardcoded as Sourceful
    selfserviceEnabled: true, // hardcoded as true
    orderProcessingType: "sourceful", // hardcoded as Sourceful
    productPricingSourceType: "sourceful", // hardcoded as Sourceful
    deliveryInvoicedSeparately: false,
    computeDeliveryCostPerProduct: false,
    enableClientReferenceCollection: false,
    prepaidStock: false,
    displayProductUnitCost: false,
  },
  name: "",
  locale: null,
};

export interface CreateOrganisationDataInterface {
  defaultCurrency: DropdownItem | null;
  externalOrganisationId: DropdownItem | null;
  organisationTypeId: number;
  config: OrganisationConfig;
  name: string;
  locale: DropdownItem | null;
}
export interface PaymentDueDate {
  type: DropdownItem | null;
  value: number;
}

export interface OrganisationConfig {
  paymentTerms: string;
  paymentDueDate: PaymentDueDate;
  xeroClientName: string;
  orderFlowTypeId: DropdownItem | null;
  autostockEnabled: boolean;
  mainProviderType: string;
  paymentDisclaimer: string;
  productsSourceType: string;
  selfserviceEnabled: boolean;
  orderProcessingType: string;
  productPricingSourceType: string;
  deliveryInvoicedSeparately: boolean;
  computeDeliveryCostPerProduct: boolean;
  enableClientReferenceCollection: boolean;
  displayProductUnitCost: boolean;
  prepaidStock: boolean;
}

export const createOrganisationFieldNames = {
  defaultCurrency: "defaultCurrency",
  externalOrganisationId: "externalOrganisationId",
  organisationTypeId: "organisationTypeId",
  paymentTerms: "config.paymentTerms",
  paymentDueDateType: "config.paymentDueDate.type",
  paymentDueDateValue: "config.paymentDueDate.value",
  xeroClientName: "config.xeroClientName",
  orderFlowTypeId: "config.orderFlowTypeId",
  autostockEnabled: "config.autostockEnabled",
  paymentDisclaimer: "config.paymentDisclaimer",
  selfserviceEnabled: "config.selfserviceEnabled",
  deliveryInvoicedSeparately: "config.deliveryInvoicedSeparately",
  computeDeliveryCostPerProduct: "config.computeDeliveryCostPerProduct",
  enableClientReferenceCollection: "config.enableClientReferenceCollection",
  prepaidStock: "config.prepaidStock",
  displayProductUnitCost: "config.displayProductUnitCost",
  name: "name",
  locale: "locale",
};
