import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { cancelOutboundManifestLineAPI } from "../../api/terminateOutboundManifestLine/cancelOutboundManifestLineAPI";
import { completeOutboundManifestLineAPI } from "../../api/terminateOutboundManifestLine/completeOutboundManifestLineAPI";
import { rejectOutboundManifestLineAPI } from "../../api/terminateOutboundManifestLine/rejectOutboundManifestLineAPI";
import {
  CHANGE_OBM_LINE_STATUS,
  TERMINATE_OUTBOUND_MANIFEST_LINE_FAILED,
  TERMINATE_OUTBOUND_MANIFEST_LINE_LOADING,
  TERMINATE_OUTBOUND_MANIFEST_LINE_RESET,
  TERMINATE_OUTBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export const terminatingOutboundManifestLine = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_LINE_LOADING,
  };
};

export const terminateOutboundManifestLineSuccess = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_LINE_SUCCESS,
  };
};

export const terminateOutboundManifestLineFailed = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_LINE_FAILED,
  };
};

export const terminateOutboundManifestLineReset = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_LINE_RESET,
  };
};

export interface TerminateOutboundManifestLineActionBody {
  type: CHANGE_OBM_LINE_STATUS;
  outbound_manifest_line_id: number;
  outbound_manifest_id: number;
}

export const terminateOutboundManifestLineAction = (
  body: TerminateOutboundManifestLineActionBody
) => {
  return async (dispatch: Dispatch) => {
    toast(`Terminating Outbound Manifest Line with type ${body.type}`, { hideProgressBar: true });
    dispatch(terminatingOutboundManifestLine());
    try {
      if (body.type === CHANGE_OBM_LINE_STATUS.MARK_AS_DONE) {
        await completeOutboundManifestLineAPI(body);
      }
      if (body.type === CHANGE_OBM_LINE_STATUS.CANCEL) {
        await cancelOutboundManifestLineAPI(body);
      }
      if (body.type === CHANGE_OBM_LINE_STATUS.REJECT) {
        await rejectOutboundManifestLineAPI(body);
      }
      toast.success("Outbound Manifest Line terminated successfully.");
      dispatch(terminateOutboundManifestLineSuccess());
    } catch (err) {
      toast.error("Something went wrong with terminating outbound manifest line.");
      dispatch(terminateOutboundManifestLineFailed());
    }
  };
};
