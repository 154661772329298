import { ReactNode, createRef, useState } from "react";
import { Filters, IdType } from "react-table";

import {
  IconText,
  Modal,
  PrimaryButton,
  SearchField,
  SecondaryButton,
  styled,
} from "@sourceful/shared-components";

import { UniqueColumnData } from ".";
import { FilterPopup } from "../filterPopup/FilterPopup";

const TableUtilityBarWrapper = styled("div", {
  padding: "0px 25px",
});

const Topbar = styled("div", {
  paddingTop: "10px",
  display: "grid",
  gridGap: "10px",
  "@sm": {
    display: "flex",
    alignItems: "center",
  },
});

const ButtonGroup = styled("div", {
  display: "grid",
  gridGap: "10px",
  "@sm": {
    display: "flex",
    "& > button": {
      "& + button": {
        marginLeft: "8px",
      },
    },
  },
});

const Text = styled("p", {
  color: "#000C32",
  padding: "0px 10px",
});

export type FilterSetter<T> = (columnId: IdType<T>, updater: any) => void;

export interface AddRowProps {
  handleClick: () => void;
  label: string;
  navigateToLastPage?: boolean;
}

interface TableUtilityBarProps<T extends object = { Header: string }> {
  searchTerm: string;
  handleSearch: (searchTerm: string) => void;
  renderFilters?: (setFilter: FilterSetter<T>) => ReactNode;
  setAllFilters: (updater: Filters<T> | ((filters: Filters<T>) => Filters<T>)) => void;
  rowAddOptions?: AddRowProps;
  gotoLastPage: () => void;
  UniqueColumnsData: UniqueColumnData[];
  children?: React.ReactNode;
}

const TableUtilityBar = ({
  handleSearch,
  searchTerm,
  renderFilters,
  setAllFilters,
  rowAddOptions,
  gotoLastPage,
  UniqueColumnsData,
  children,
}: TableUtilityBarProps) => {
  const [areFiltersVisible, setAreFiltersVisible] = useState(false);
  const modalRef = createRef<HTMLButtonElement>();

  return (
    <TableUtilityBarWrapper>
      <Topbar>
        {(renderFilters || rowAddOptions) && (
          <ButtonGroup>
            {rowAddOptions && (
              <PrimaryButton
                appearance="light"
                onClick={() => {
                  rowAddOptions.handleClick();
                  rowAddOptions.navigateToLastPage && gotoLastPage();
                }}
              >
                <IconText primaryIcon="navigation-plus" text={rowAddOptions.label} />
              </PrimaryButton>
            )}
            {renderFilters && (
              <SecondaryButton
                appearance={"outline"}
                css={{
                  borderRadius: "5px",
                  border: "1px solid rgb(220, 220, 221)",
                  justifyContent: "center",
                  marginRight: "25px",
                }}
                onClick={() => setAreFiltersVisible(!areFiltersVisible)}
              >
                <Text>Filters</Text>
              </SecondaryButton>
            )}
          </ButtonGroup>
        )}
        {children}
        <SearchField
          placeholder="Search The Rows Below"
          handleChange={e => handleSearch(e.target.value)}
          value={searchTerm}
          handleClear={() => handleSearch("")}
        />
      </Topbar>

      {renderFilters && (
        <Modal
          id="modal"
          isOpen={areFiltersVisible}
          handleClose={() => setAreFiltersVisible(false)}
          triggerRef={modalRef}
        >
          <FilterPopup
            setAreFiltersVisible={setAreFiltersVisible}
            setAllFilters={setAllFilters}
            UniqueColumnsData={UniqueColumnsData}
          />
        </Modal>
      )}
    </TableUtilityBarWrapper>
  );
};

export default TableUtilityBar;
