import { ApolloError } from "@apollo/client";

import { useGetEmptyLocationsByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapEmptyLocationsToCSV } from "../mappers/mapEmptyLocationsToCSV";

export interface HookResult {
  emptyLocations: any[];
  emptyLocationsError?: ApolloError;
  emptyLocationsLoading: boolean;
  getEmptyLocationsByWarehouseId: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number | null;
}

export const useEmptyLocationsByWarehouseId = (): HookResult => {
  const [getEmptyLocationsByWarehouseId, { data, error, loading }] =
    useGetEmptyLocationsByWarehouseIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    emptyLocations:
      data && Array.isArray(data.location) ? mapEmptyLocationsToCSV(data.location) : [],
    emptyLocationsError: error,
    emptyLocationsLoading: loading,
    getEmptyLocationsByWarehouseId: async ({ warehouseId }: Options) => {
      getEmptyLocationsByWarehouseId({ variables: { warehouseId } });
    },
  };
};
