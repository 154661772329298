import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { markStockCheckAsCompleteAPI } from "../../api/markStockCheckAsComplete/markStockCheckAsComplete";
import { MarkStockCheckAsCompleteParams } from "../../api/markStockCheckAsComplete/types";
import {
  MARKING_STOCK_CHECK_AS_COMPLETE,
  MARK_STOCK_CHECK_AS_COMPLETE_FAILURE,
  MARK_STOCK_CHECK_AS_COMPLETE_RESET,
  MARK_STOCK_CHECK_AS_COMPLETE_SUCCESS,
} from "../types";

export const markingStockCheckAsComplete = () => {
  return {
    type: MARKING_STOCK_CHECK_AS_COMPLETE,
  };
};

export const markStockCheckAsCompleteSuccess = () => {
  return {
    type: MARK_STOCK_CHECK_AS_COMPLETE_SUCCESS,
  };
};

export const markStockCheckAsCompleteReset = () => {
  return {
    type: MARK_STOCK_CHECK_AS_COMPLETE_RESET,
  };
};

export const markStockCheckAsCompleteFailure = () => {
  return {
    type: MARK_STOCK_CHECK_AS_COMPLETE_FAILURE,
  };
};

export const markStockCheckAsCompleteAction = (params: MarkStockCheckAsCompleteParams) => {
  return async (dispatch: Dispatch) => {
    toast("Marking as complete Stock Check ", { hideProgressBar: true });
    dispatch(markingStockCheckAsComplete());
    try {
      await markStockCheckAsCompleteAPI(params);
      toast.success("Stock Check  marked as complete successfully.");
      dispatch(markStockCheckAsCompleteSuccess());
    } catch (err) {
      toast.error("Something went wrong with marking as complete Stock Check .");
      dispatch(markStockCheckAsCompleteFailure());
    }
  };
};
