import { ApolloError } from "@apollo/client";

import { useGetContactsByOrganisationIdLazyQuery } from "../../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { ContactItem } from "../types";

export interface HookResult {
  contacts?: ContactItem[];
  contactsLoading: boolean;
  contactsError?: ApolloError;
  getContactsByOrganisationId: (options: Options) => Promise<void>;
}

export interface Options {
  organisationId: number;
}

export const useContactsByOrganisationId = (): HookResult => {
  const [getContactsByOrganisationId, { data, loading, error }] =
    useGetContactsByOrganisationIdLazyQuery({
      context: { clientName: GRAPHQL_SOURCING },
    });

  return {
    contacts: data && Array.isArray(data.contact) ? data.contact : [],
    contactsLoading: loading,
    contactsError: error,
    getContactsByOrganisationId: async ({ organisationId }: Options) => {
      getContactsByOrganisationId({ variables: { organisationId } });
    },
  };
};
