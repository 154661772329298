import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createInboundManifestAPI } from "../../api/createInboundManifestAPI";
import { CreateInboundManifestBody } from "../../api/types";
import {
  CREATE_INBOUND_MANIFEST_FAILURE,
  CREATE_INBOUND_MANIFEST_RESET,
  CREATE_INBOUND_MANIFEST_SUCCESS,
  CREATING_INBOUND_MANIFEST,
} from "../types";

export const creatingInboundManifest = () => {
  return {
    type: CREATING_INBOUND_MANIFEST,
  };
};

export const createInboundManifestSuccess = () => {
  return {
    type: CREATE_INBOUND_MANIFEST_SUCCESS,
  };
};

export const createInboundManifestReset = () => {
  return {
    type: CREATE_INBOUND_MANIFEST_RESET,
  };
};

export const createInboundManifestFailure = () => {
  return {
    type: CREATE_INBOUND_MANIFEST_FAILURE,
  };
};

export const createInboundManifestAction = (body: CreateInboundManifestBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Inbound Manifest", { hideProgressBar: true });
    dispatch(creatingInboundManifest());
    try {
      await createInboundManifestAPI(body);
      toast.success("Inbound Manifest created successfully.");
      dispatch(createInboundManifestSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating inbound manifest.");
      dispatch(createInboundManifestFailure());
    }
  };
};
