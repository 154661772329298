// FYI:
// abstract away generated types and hooks
// so it is not tightly coupled to graphql-codegen
import { PURCHASE_ORDER_STATUS } from "../../../../purchaseOrders/updatePurchaseOrders/types";

// TODO: next iteration would be to add a mapping between values returned to i.e. .status instead of .client_order_line_status_name
export interface AttachmentItem {
  attachment_uuid: string;
  original_filename: string;
  id: number;
  metadata: any;
}

export interface PurchaseOrder {
  id: number;
  created_at: any;
  expected_delivery_date: any;
  supplier_organisation?: { organisation_name: string } | null;
  purchase_order_document_uid?: string | null;
  purchase_order_status: {
    purchase_order_status_name: PURCHASE_ORDER_STATUS;
  };
}

export interface PurchaseOrderLine {
  purchase_order: PurchaseOrder;
  product: { product_name: string };
}

export interface PurchaseOrderLineAllocation {
  purchase_order_line: PurchaseOrderLine;
}

export interface PrePurchaseOrderItem {
  allocated_quantity: number;
  quantity: number;
  purchase_order_line_allocations: Array<PurchaseOrderLineAllocation>;
}
export interface DropdownItem {
  label: string;
  value: number;
}

export interface ClientOrderLine {
  id: number;
  quantity: number;
  fulfilled_quantity: number;
  delivered_quantity: number;
  cancelled_quantity: number;
  suggested_supplier_quote_line_id?: number | null;
  client_order_line_status: {
    client_order_line_status_name: string;
  };
  folder: {
    attachments: Array<AttachmentItem>;
  };
  pre_purchase_orders: Array<PrePurchaseOrderItem>;
  product: { product_name: string; id: number };
}

export interface ClientOrder {
  external_client_order_id: string;
  id: number;
  created_at: any;
  service_account: {
    id: number;
    service_account_name: string;
  };
  address: { address_name: string; id: number };
  client_order_status: {
    client_order_status_name: string;
    id: number;
  };
  client_promised_delivery_from_date: string | null;
  client_promised_delivery_to_date: string | null;
  organisation: { id: number; organisation_name: string };
  folder: {
    attachments: Array<AttachmentItem>;
  };
  client_order_lines: Array<ClientOrderLine>;
}

export const mapGQLClientOrderToDomain = (gqlClientOrder: unknown): ClientOrder => {
  return gqlClientOrder as ClientOrder;
};

// FYI:
// abstract away generated types and hooks
// so it is not tightly coupled to graphql-codegen
// TODO: next iteration would be to add a mapping between values returned to i.e. .status instead of .client_order_line_status_name
export interface ClientOrderFulfilmentType {
  id: number;
  client_order_line_fulfilment_type_name: string;
}
