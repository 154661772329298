import { WMS_API } from "../../../../../api/baseConfig";
import { DeleteFileCopiesBody } from "./types";

export const deleteFileCopiesAPI = async (deletFileCopiesBody: DeleteFileCopiesBody) => {
  try {
    return await WMS_API.patch("/file-copies/delete", deletFileCopiesBody);
  } catch (err) {
    console.log("Error in delete File Copy API call:", err);
    throw err;
  }
};
