import { useEffect, useMemo, useState } from "react";
import { Link, useHistory, useParams } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SM_ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { InlineCode } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { getIAMOrganisationMembers } from "../api/getIAMOrganisationMember";

export interface IAMOrganisationMembers {
  uuid?: string | null;
  name?: string | null;
  username?: string | null;
  nickname?: string | null;
  email?: string | null;
  organisations_users_roles: {
    role: {
      name: string;
    };
  }[];
}

const OrganisationMemberIdCell = ({ row }: CellProps<IAMOrganisationMembers>) => (
  <InlineCode>{row.original.uuid}</InlineCode>
);

const OrganisationRolesCell = ({ row }: CellProps<IAMOrganisationMembers>) => (
  <>
    {row.original.organisations_users_roles.map(organisations_user_role => (
      <InlineCode>{organisations_user_role.role.name}</InlineCode>
    ))}
  </>
);

const ViewIAMOrganisationMembers = () => {
  const { orgUUID, displayName } = useParams<{ orgUUID: string; displayName: string }>();
  const [organisationMembers, setOrganisationMembers] = useState<any[]>([]);

  const ViewAndUpdateCell = ({ row }: CellProps<IAMOrganisationMembers>) => (
    <Link
      to={`/admin/iam/organisations/${orgUUID}/${displayName}/member/${row.original.uuid}/${row.original.email}`}
    >
      <Icon name={"arrow-direction-right"} />
    </Link>
  );

  const history = useHistory();
  const { roles } = useAuthorisationContext();

  const [getOrganisationMembersState, getOrganisationMembers] = useAPI(
    getIAMOrganisationMembers,
    true
  );

  useEffect(() => {
    const fetchOrganisationMembers = async () => {
      const response = await getOrganisationMembers({
        orgUUID: orgUUID,
      });
      const organisationMembersdata = Array.isArray(response)
        ? response[0].data.data
        : response.data.data;
      setOrganisationMembers(organisationMembersdata);
    };

    fetchOrganisationMembers().catch(error => {
      console.error("error getting organisation members:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "Member ID",
        widthPercent: 1,
        accessor: (organisationMember: IAMOrganisationMembers) => organisationMember.uuid,
        Cell: OrganisationMemberIdCell,
        filter: "multiRow",
      },
      {
        Header: "Name",
        widthPercent: 1,
        accessor: (organisationMember: IAMOrganisationMembers) => organisationMember.name,
        filter: "multiRow",
      },
      {
        Header: "Display Name",
        widthPercent: 1,
        accessor: (organisationMember: IAMOrganisationMembers) => organisationMember.username,
        filter: "multiRow",
      },
      {
        Header: "Roles",
        widthPercent: 1,
        accessor: (organisationMember: IAMOrganisationMembers) => organisationMember.username,
        Cell: OrganisationRolesCell,
        filter: "multiRow",
      },
      {
        Header: "View & Update",
        widthPercent: 1,
        Cell: ViewAndUpdateCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisationMembers]
  );

  const items = SM_ADMIN_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push(`/admin/iam/organisations/${orgUUID}/${displayName}/members/add`);
          },
          type: "button",
          title: "Add Members",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];

  return (
    <Page
      title={"IAM Organisations Members"}
      isLoading={getOrganisationMembersState === API_REQUEST_STATE.PENDING}
      error={getOrganisationMembersState === API_REQUEST_STATE.ERROR}
    >
      <Panel
        withWrapper
        title={displayName}
        text={"Via this table you can view all Members"}
        allignTitle="left"
        rightItems={rightItems}
        iconName={"toggle-star-fill"}
      >
        <Table
          data={organisationMembers ?? []}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
};

export default ViewIAMOrganisationMembers;
