import { DropdownItem } from "../graphql/types";

export const canHoldStockOptions: DropdownItem[] = [
  {
    value: 1,
    label: "true",
  },
  {
    value: 2,
    label: "false",
  },
];
