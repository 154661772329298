import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

export const getAttributeTypes = async () => {
  try {
    const response = await PRODUCT_CATALOGUE_API.get("/attribute/types");
    return response.data.data;
  } catch (err) {
    console.log("Error in getting attribute types templates API call", err);
    throw err;
  }
};
