import { IAM_API } from "../../../../../api/baseConfig";

export interface assignMemberRolesBody {
  role_ids: number[];
  organisationUUID: string;
  userUUID: string;
}

export const assignMemberRoles = async (params: assignMemberRolesBody) => {
  try {
    return await IAM_API.post(`/v2/organisation/member/roles`, {
      role_ids: params.role_ids,
      org_uuid: params.organisationUUID,
      user_uuid: params.userUUID,
    });
  } catch (err) {
    console.error("Error in IAM assign roles API call", err);
    throw err;
  }
};
