import { AxiosRequestConfig } from "axios";

import { IAM_API } from "../../../../../api/baseConfig";

export interface FetchOrganisationsBody {
  offset?: number;
  limit?: number;
  token?: string;
}

export const getOrganisations = async ({ offset, limit, token }: FetchOrganisationsBody) => {
  try {
    const config: AxiosRequestConfig = { headers: { Authorization: `Bearer ${token}` } };
    return IAM_API.get(`/organisations?offset=${offset || 0}&limit=${limit || 50}`, config);
  } catch (err) {
    console.error("Error in get organisations API call", err);
    throw err;
  }
};
