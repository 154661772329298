import { ApolloError } from "@apollo/client";

import { useGetQaInspectionTypesQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QAInspectionTypeItem } from "../types";

export interface HookResult {
  qaInspectionTypes: QAInspectionTypeItem[];
  qaInspectionTypesError?: ApolloError;
  qaInspectionTypesLoading: boolean;
}

export const useQAInspectionTypes = (): HookResult => {
  const { data, error, loading } = useGetQaInspectionTypesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    qaInspectionTypes:
      data && Array.isArray(data.quality_assurance_inspection_type)
        ? data.quality_assurance_inspection_type
        : [],
    qaInspectionTypesError: error,
    qaInspectionTypesLoading: loading,
  };
};
