import { MappedSkuItem, SkuItem } from "../types";

export const mapSkus = (skus: SkuItem[]): MappedSkuItem[] => {
  return skus.map(sku => {
    return {
      id: sku.id,
      clientName: sku.client_name,
      createdAt: sku.created_at,
      deliveryCosts: sku.delivery_costs || [],
      imageUrl: sku.image_url || "",
      manufacturingLeadTime: sku.manufacturing_lead_time,
      packSizes: sku.pack_sizes || [],
      productTypeId: sku.sku_source_type_id,
      updatedAt: sku.updated_at,
      organisationIds: sku.sku_orgs.map(org_id => org_id.org_id),
    };
  });
};
