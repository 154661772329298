import { ExtendedQuoteRequestLine } from "../../graphql/types";

export const groupQuoteLinesByProduct = (
  quoteLines: ExtendedQuoteRequestLine[]
): ExtendedQuoteRequestLine[][] => {
  const groupedQuoteLines: ExtendedQuoteRequestLine[][] = [];
  quoteLines.forEach(line => {
    const existingGroup = groupedQuoteLines.find(group => group[0].product.id === line.product.id);
    if (existingGroup) {
      existingGroup.push(line);
    } else {
      groupedQuoteLines.push([line]);
    }
  });
  return groupedQuoteLines;
};
