import * as Yup from "yup";

export const validationSchema = Yup.object({
  quoteValidFrom: Yup.string().required("Date is required"),
  quoteValidTo: Yup.string().required("Date is required"),
  supplier: Yup.object()
    .shape({
      value: Yup.number().required("Supplier is required"),
    })
    .typeError("Supplier is required"),
  quotationCosts: Yup.array()
    .of(
      Yup.object({
        id: Yup.number().optional(),
        costType: Yup.object()
          .shape({
            value: Yup.number().required("Cost type is required"),
          })
          .typeError("Cost type is required"),
        cost: Yup.string().required("Cost is required"),
      })
    )
    .min(1, "Please provide at least 1 Quotation Cost"),
  moq: Yup.string().required("MOQ is required"),
  leadTimeMaxDays: Yup.string().required("Production lead time is required"),
  notes: Yup.string().optional(),
  packagingOption: Yup.string().optional(),
  domesticLogisticsFeeIncluded: Yup.boolean().optional(),
  cartonDetails: Yup.object({
    cartonLength: Yup.string().optional(),
    cartonWidth: Yup.string().optional(),
    cartonHeight: Yup.string().optional(),
    cartonWeight: Yup.string().optional(),
    unitPerCarton: Yup.string().optional(),
  }),
});
