import { IAM_API } from "../../../../../api/baseConfig";

export interface getIAMUsersParams {
  offset: number;
  limit: number;
  filters: {
    key: string;
    value: string;
  }[];
}

export const getIAMUsers = async (params: getIAMUsersParams) => {
  try {
    return await IAM_API.get(`/v2/users`, {
      params: {
        offset: params.offset,
        limit: params.limit,
        ...params.filters
          .map(filter => {
            return {
              [filter.key]: filter.value,
            };
          })
          .reduce((acc, obj) => ({ ...acc, ...obj }), {}),
      },
    });
  } catch (err) {
    console.error("Error in IAM get users API call", err);
    throw err;
  }
};
