import { ApolloError } from "@apollo/client";

import { useGetCountryNamesQuery } from "../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../shared/constants";
import { CountryNameItem } from "../types";

export interface HookResult {
  countryNames: CountryNameItem[];
  countryNamesError?: ApolloError;
  countryNamesLoading: boolean;
}

export const useCountryNames = (): HookResult => {
  const { data, error, loading } = useGetCountryNamesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    countryNames: data && Array.isArray(data.country) ? data.country : [],
    countryNamesError: error,
    countryNamesLoading: loading,
  };
};
