import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { SUPPLIER_CURRENCIES } from "../../../../../shared/constants";

export const mapSupplierCurrenciestoDropdownObject = (): DropdownItem[] => {
  return SUPPLIER_CURRENCIES.map((currency, index) => {
    return {
      label: currency,
      value: index,
    };
  });
};
