import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../components/Table";
import StatusBadge from "../../../../shared/components/molecules/StatusBadge";
import { statusSummaryItems } from "../tableValues";
import { StatusSummaryItem } from "../types";

function SourcingUserManual() {
  const columns = useMemo(
    () => [
      {
        Header: "Order Status",
        widthPercent: 0.1,
        accessor: (statusSummaryItem: StatusSummaryItem) => statusSummaryItem.statusName,
        Cell: ({ row }: CellProps<StatusSummaryItem>) => (
          <StatusBadge type="sourcing" statusName={row.original.statusName} />
        ),
      },
      {
        Header: "Description",
        widthPercent: 0.9,
        accessor: (statusSummaryItem: StatusSummaryItem) => statusSummaryItem.statusOverview,
      },
      {
        Header: "Action Details",
        widthPercent: 0.9,
        accessor: (statusSummaryItem: StatusSummaryItem) => statusSummaryItem.statusTrigger,
      },
    ],
    []
  );

  return (
    <div>
      <h1>Sourcing User Manual</h1>
      <Link style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }} to={`/help`}>
        Back To Help
      </Link>
      <h2>Client Orders</h2>
      <ul>
        <li>
          Each client order received by Sourcing will appear in the
          <Link
            style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
            to={`/client-orders`}
          >
            Client Orders{"  "}
          </Link>
          tab on the left.
        </li>
        <li>
          The summary table gives a view of each client order line, thus showing a detailed view of
          each product that needs to be sourced.
        </li>
        <li>
          Client Orders need to be fulfilled manually with the appropriate fulfilment type.
          Currently only <b>Purchase Order</b> is available, however as we expand the service there
          would be various warehouse options. You can also choose to only partially fulfil a client
          order. These updates happen in the "update client order page", which can be accessed from
          the <b>Update</b> button on each line.
        </li>
        <li>
          Each client order line will have a different status (state), which can be one of the
          following:
          <ul>
            <li>
              <b>Pending</b>: no orders have been placed for this client order line
            </li>
            <li>
              <b>In Progress</b>: a purchase order has been created for some of the quantity for
              this product line
            </li>
            <li>
              <b>Fulfiled</b>: a purchase order has been created for all of the quantity for this
              product line
            </li>
            <li>
              <b>Delivered</b>: all purchase orders responsible for this product line have been
              delivered
            </li>
          </ul>
        </li>
      </ul>

      <h2>Pending Demand</h2>
      <p>
        Pending demand is a summary of all fulfilments that need to be completed via{" "}
        <b>Purchase Order</b>. Currently, this will be a view of all fulfilments as WMS is not yet
        an option for fulfiling client orders.
      </p>

      <h2>Purchase Order</h2>
      <p>
        Business-wide the term <b>Purchase Order</b> is used specifically for the PO document
        generated by Xero. Treat this as the equivalent of <b>Supplier Order</b> until terminology
        is standardised.
      </p>

      <h3>Create Purchase Order</h3>
      <ul>
        <li>
          To create a purchase order, click the Create Purchase Order button. You can only create
          purchase orders for products that appear in the Pending Demand view, so if something is
          not showing up as expected, please double check the client order lines have been set up
          correctly for fulfilment.
        </li>
        <li>
          You would be asked to select a client and a client order number, which will give you a
          summary of what you can place an order for.
        </li>
        <li>
          Delivery address can be the client address as specified on the client order or any of the
          Sourceful warehouses. Please make sure that you select the address where you'd like the
          order to be dispatched to by the supplier. Most UK suppliers currently would deliver
          directly to clients, while China orders are likely to go through the China warehouse
          first.
        </li>
        <li>
          Suppliers can be selected from an existing list. If you'd like to set up a new supplier,
          please message Elitsa.
        </li>
        <li>
          Currently, only one product can be purchased per product line, in order to ensure correct
          delivery dates are set up.
        </li>
        <li>
          Creating a purchase order does not mean it gets sent to supplier automatically! It would
          first need to be reviewed and approved before the supplier receives it.
        </li>
      </ul>

      <h3>Update Purchase Order</h3>
      <ul>
        <li>
          <b>Update Delivery Date</b>: delivery date is set to be approximately the lead time. This
          can be updated based on supplier's estimates.
        </li>
        <li>
          <b>Approvals</b>: the order should receive 4 stages of approvals. If the order is rejected
          internally before being sent to a supplier, a new order would have to be created. Each
          approval triggers an email notification about what has happened and what the next steps
          should be.
        </li>
        <li>
          <b>Order Approval</b>: This is the approval that sends the order to the supplier! Please
          only accept/reject when absolutely certain.
        </li>
        <li>
          <b>Mark as in transit</b>: Once the supplier has accepted the order, there would be an
          opportunity to mark the order as in transit upon receiving dispatch confirmation and
          details.
        </li>
        <li>
          <b>Mark as delivered</b>: Once in transit, there would be an opportunity to mark the order
          as delivered once received.
        </li>
        <li>
          <b>Order Statuses</b>: Purchase Order Status is critical for understading where the order
          is at and what the next locical steps are, so that we can optimise the flow and reduce
          waiting time. The table below gives a summary of available statuses.
        </li>
      </ul>

      <Table columns={columns} data={statusSummaryItems} hideUtilityBar={true} />

      <h2>Quality Control Inspection</h2>
      <ul>
        <li>QC Inspections are performed for each product line in each purchase order.</li>
        <li>
          An inspection is <b>passed</b> if the last inspection of a specific type has passed.
        </li>
        <li>
          An inspection is <b>failed</b> if the last inspection of a specific type has failed.
        </li>
        <li>
          Inspections can be created from the Update Purchase Order page or updated from the QC
          summary table.
        </li>
      </ul>

      <h2>RFQs and Quotations</h2>
      <p>
        Currently baselined RFQ and Quotation functionality exists to create both. Further expansion
        to follow.
      </p>
    </div>
  );
}

export default SourcingUserManual;
