import { useParams } from "react-router-dom";

import Panel from "../../../../../components/panel/panel";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useStockTransferById } from "../graphql/hooks/useStockTransferById";

const ViewStockTransfer = () => {
  const { stock_transdfer_id } = useParams<{ stock_transdfer_id: string }>();

  const { stockTransfer, stockTransferLoading, stockTransferError } = useStockTransferById(
    Number(stock_transdfer_id)
  );

  return (
    <Page error={stockTransferError} isLoading={stockTransferLoading} title="View Stock Transfer">
      <Panel
        withWrapper
        title={"Review Stock Transfer "}
        subtitle={"Review Stock Transfer below"}
        manifestStatusName={stockTransfer?.internal_transfer_status.internal_transfer_status_name}
      >
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <DisplayBox label="Stock Transfer ID" value={stockTransfer?.id} />
            <DisplayBox
              label="Warehouse"
              value={stockTransfer?.from_location?.warehouse.warehouse_name}
            />
            <DisplayBox label="From Location" value={stockTransfer?.from_location?.location_name} />
            <DisplayBox label="To Location" value={stockTransfer?.to_location?.location_name} />
          </TopPanelInputsWrapper>
          {stockTransfer?.internal_transfer_lines.map((transferLine, index) => (
            <PrimaryLineWrapper key={transferLine.id}>
              <Title>Internal Transfer Line #{index + 1}</Title>
              <PrimaryLineDataWrapper>
                <DisplayBox
                  withUniqueMargin
                  label="Product Stock ID"
                  value={transferLine.product_stock.id}
                />
                <DisplayBox
                  withUniqueMargin
                  label="Product Name"
                  value={transferLine.product_stock.product.product_name}
                />
                <DisplayBox withUniqueMargin label="Quantity" value={transferLine.quantity} />
              </PrimaryLineDataWrapper>
            </PrimaryLineWrapper>
          ))}
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default ViewStockTransfer;
