import { Formik } from "formik";

import { ButtonGroup, CheckBox, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor/VisibleFor";
import { STOCK_MANAGEMENT_COUNTRY_CODES } from "../../../../../shared/constants";
import {
  ColumnWrapper,
  DataPoint,
  PrimaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
} from "../../../../../styles/SharedStyles";
import { Warning } from "../../../skus/updateSku/page/UpdateSku";
import {
  AddAddressToOrganisationInterface,
  addAddressFieldNames,
  initialAddAddressToOrganisationValues,
} from "../../shared/types";
import { FormattedContact } from "../page/UpdateStockManagementOrgansiation";
import { addAddressValidationSchema } from "../validation";

interface Props {
  onClose: () => void;
  onAddAddress: (values: AddAddressToOrganisationInterface) => void;
  organisationContacts: FormattedContact[];
}

const AddStockManagementAddress = ({
  onClose,
  onAddAddress,
  organisationContacts,
}: Props): JSX.Element => {
  const filteredOrganisationContacts = organisationContacts.filter(
    ({ addressId, id }) => id !== undefined && addressId === null
  );

  return (
    <Formik
      initialValues={initialAddAddressToOrganisationValues}
      enableReinitialize
      onSubmit={(values: AddAddressToOrganisationInterface, { resetForm }) => {
        onAddAddress(values);
        resetForm();
      }}
      validationSchema={addAddressValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <SecondaryLineWrapper
              type={"darkBackground"}
              css={{
                alignItems: "normal",
              }}
            >
              <PrimaryLineDataWrapper marginSize={"smallMargin"} alignStart>
                <DataPoint>
                  <Title>Add new address</Title>
                </DataPoint>
                <DataPoint fullWidth>
                  {filteredOrganisationContacts.length === 0 && (
                    <Warning css={{ width: "400px", maxWidth: "90%" }}>
                      There are no contacts available to assign to this address. Please add a
                      contact to the organisation before adding an address.
                    </Warning>
                  )}
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.addressName}
                    name={addAddressFieldNames.addressName}
                    size={"medium"}
                    error={errors.addressName}
                    touched={touched.addressName}
                    label={"Address name"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressName}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.addressName, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.addressDescription}
                    name={addAddressFieldNames.addressDescription}
                    size={"medium"}
                    error={errors.addressDescription}
                    touched={touched.addressDescription}
                    label={"Address description"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressDescription}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.addressDescription, event.target.value);
                    }}
                  />
                </DataPoint>

                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.addressLine1}
                    name={addAddressFieldNames.addressLine1}
                    size={"medium"}
                    error={errors.addressLine1}
                    touched={touched.addressLine1}
                    label={"Address line 1"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressLine1}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.addressLine1, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    htmlFor={addAddressFieldNames.addressLine2}
                    name={addAddressFieldNames.addressLine2}
                    size={"medium"}
                    error={errors.addressLine2}
                    touched={touched.addressLine2}
                    label={"Address line 2"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressLine2}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.addressLine2, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    htmlFor={addAddressFieldNames.addressLine3}
                    name={addAddressFieldNames.addressLine3}
                    size={"medium"}
                    error={errors.addressLine3}
                    touched={touched.addressLine3}
                    label={"Address line 3"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressLine3}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.addressLine3, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.city}
                    name={addAddressFieldNames.city}
                    size={"medium"}
                    error={errors.city}
                    touched={touched.city}
                    label={"City"}
                    placeholder={""}
                    type={"text"}
                    value={values.city}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.city, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.postcode}
                    name={addAddressFieldNames.postcode}
                    size={"medium"}
                    error={errors.postcode}
                    touched={touched.postcode}
                    label={"Postcode"}
                    placeholder={""}
                    type={"text"}
                    value={values.postcode}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.postcode, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.region}
                    name={addAddressFieldNames.region}
                    size={"medium"}
                    error={errors.region}
                    touched={touched.region}
                    label={"Region or US State"}
                    placeholder={""}
                    type={"text"}
                    value={values.region}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.region, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleSelect
                    htmlFor={addAddressFieldNames.countryCode}
                    name={addAddressFieldNames.countryCode}
                    error={errors.countryCode}
                    touched={touched.countryCode}
                    placeholder="Select country code"
                    label="Country Code"
                    options={STOCK_MANAGEMENT_COUNTRY_CODES.map((countryCode, index) => ({
                      label: countryCode,
                      value: index,
                    }))}
                    value={values.countryCode}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.countryCode, event);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleSelect
                    isRequired
                    htmlFor={addAddressFieldNames.primaryContactId}
                    name={addAddressFieldNames.primaryContactId}
                    error={errors.primaryContactId}
                    touched={touched.primaryContactId}
                    placeholder="Select a primary contact"
                    label="Primary contact"
                    options={filteredOrganisationContacts.map(contact => ({
                      label: `${contact.firstName} ${contact.lastName}`,
                      value: contact.indexId,
                    }))}
                    value={values.primaryContactId}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.primaryContactId, event);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.deliveryTimelineMin}
                    name={addAddressFieldNames.deliveryTimelineMin}
                    size={"medium"}
                    error={errors.deliveryTimelineMin}
                    touched={touched.deliveryTimelineMin}
                    label={"Delivery timeline: minimum (days, or type of unit specified below)"}
                    min={0}
                    placeholder={""}
                    type={"number"}
                    value={values.deliveryTimelineMin}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.deliveryTimelineMin, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.deliveryTimelineMax}
                    name={addAddressFieldNames.deliveryTimelineMax}
                    size={"medium"}
                    error={errors.deliveryTimelineMax}
                    touched={touched.deliveryTimelineMax}
                    label={"Delivery timeline: maximum (days, or type of unit specified below)"}
                    min={values.deliveryTimelineMin || 0}
                    placeholder={"0"}
                    type={"number"}
                    value={values.deliveryTimelineMax}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.deliveryTimelineMax, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addAddressFieldNames.deliveryTimelineUnits}
                    name={addAddressFieldNames.deliveryTimelineUnits}
                    size={"medium"}
                    error={errors.deliveryTimelineUnits}
                    touched={touched.deliveryTimelineUnits}
                    label={"Delivery timeline: units (days are currently supported)"}
                    placeholder={""}
                    type={"text"}
                    value={values.deliveryTimelineUnits}
                    changeHandler={event => {
                      setFieldValue(addAddressFieldNames.deliveryTimelineUnits, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint fullWidth>
                  <CheckBox
                    label={"Delivery timeline includes business days only"}
                    value={addAddressFieldNames.deliveryTimelineBusinessDaysOnly}
                    id={addAddressFieldNames.deliveryTimelineBusinessDaysOnly}
                    isChecked={values.deliveryTimelineBusinessDaysOnly ?? false}
                    handleChange={event => {
                      setFieldValue(
                        addAddressFieldNames.deliveryTimelineBusinessDaysOnly,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint fullWidth>
                  <CheckBox
                    label={"This is a warehouse address"}
                    value={addAddressFieldNames.isWarehouse}
                    id={addAddressFieldNames.isWarehouse}
                    isChecked={values.isWarehouse ?? false}
                    handleChange={event => {
                      setFieldValue(addAddressFieldNames.isWarehouse, event.target.checked);
                    }}
                  />
                </DataPoint>
                <ColumnWrapper stretchedSpaceBetween>
                  <VisibleFor roles={SM_ADMIN_ROLES}>
                    <ButtonGroup css={{ marginTop: "20px" }}>
                      <SecondaryButton type="button" appearance="outline" onClick={() => onClose()}>
                        Cancel
                      </SecondaryButton>
                      <SecondaryButton type="submit" appearance={"blueButton"}>
                        Save address
                      </SecondaryButton>
                    </ButtonGroup>
                  </VisibleFor>
                </ColumnWrapper>
              </PrimaryLineDataWrapper>
            </SecondaryLineWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default AddStockManagementAddress;
