import { Link } from "react-router-dom";

interface Props {}

function WMSUserManual(props: Props) {
  return (
    <div>
      <h1>User Manual</h1>
      <Link style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }} to={`/help`}>
        Back To Help
      </Link>
      <h2>Operations (Internal)</h2>
      <h3>Fulfilling Inbound Manifests (IBM)</h3>
      <h4>This will happen when stock arrives in the warehouse.</h4>
      <p>
        1) Find the appropriate Inbound Manifest in the Inbound Manifest List (Manifests → Inbound){" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/manifests/inbound`}
        >
          Inbound Manifest List
        </Link>
      </p>
      <p>2) Click 'Fulfil'</p>
      <p>
        3) Add fulfilment lines to each Inbound Manifest Line with the stock you are checking in,
        the quantity and the location where you are putting the stock
      </p>
      <p>4) Multiple fulfilment lines can be added to each Inbound Manifest Line</p>
      <h3>Fulfilling Outbound Manifests (OBM)</h3>
      <h4>This will happen when stock is collected from the warehouse.</h4>
      <p>
        1) Find the appropriate Outbound Manifest in the Outbound Manifest List (Manifests →
        Outbound){" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/manifests/outbound`}
        >
          Outbound Manifest List
        </Link>
      </p>
      <p>2) Click 'Fulfil'</p>
      <p>
        3) Add fulfilment lines to each Outbound Manifest Line with the stock you have picked and
        packed
      </p>
      <p>4) Multiple fulfilment lines can be added to each Outbound Manifest Line</p>
      <h3>Fulfil Work Order (WO)</h3>
      <h4>
        This will happen once you have made the output stock that has been requested per the recipe.
      </h4>
      <p>
        1) Find the appropriate Work Order that you have been working on (Manifests → Work Orders){" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/manifests/work-orders`}
        >
          Work Order List
        </Link>
      </p>
      <p>2) Click 'Fulfil'</p>
      <p>3) Enter the quantity of the Work Order that you have fulfilled</p>
      <h3>Stock Adjustment</h3>
      <p>
        In order to try and help reflect reality as much as possible, it is essential that stock
        records are maintained as accurately as possible. This means that sometimes there will be a
        requirement to adjust stock. The WMS has the capability to help record these changes as
        follows:
      </p>
      <ul>
        <li>
          Ad-Hoc: You can use this type of adjustment if you need to make any adjustments to stock
          individually on a one-off basis.{" "}
          <Link
            style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
            to={`/operations/stock-adjust-adhoc`}
          >
            Stock Adjust Ad-Hoc
          </Link>
        </li>
        <li>
          By Location: As part of your daily operations, you may need to check stock by location and
          make adjustments.{" "}
          <Link
            style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
            to={`/operations/stock-adjust-by-location`}
          >
            Stock Adjust By Location
          </Link>
        </li>
      </ul>
      <h3>Perpetual Inventory (PI)</h3>
      <p>
        PI is a way of keeping stock records as up to date and accurate as possible. Essentially,
        the PI area in the WMS shows an ordered list of stock based on the longest time since it was
        updated. This ordering provides the best way to check stock physically to ensure that the
        records are accurate and can be updated if necessary.{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/operations/perpetual-inventory`}
        >
          Perpetual Inventory
        </Link>
      </p>
      <h3>Stock Transfer</h3>
      <p>
        This area is the place to record internal movements of stock within ONE warehouse from one
        location to another. This is to ensure that the stock records remain accurate and so that
        stock can be easily found on outbound fulfilment. Stock cannot be transferred from a
        location in one warehouse to a new location in another.{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/operations/stock-transfer`}
        >
          Stock Transfer
        </Link>
      </p>
      <h2>External Users</h2>
      <h3>Creating an expectation of stock arriving INTO a warehouse</h3>
      <p>
        {` 1) Ensure Products exist that you want to create an expectation for (Inventory → Products → Add Product)`}
      </p>
      <p>
        {` 2) Manifests → Inbound → Create Inbound Manifest`}{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/manifests/inbound/create-inbound-manifest`}
        >
          + Create Inbound Manifest
        </Link>
      </p>
      <h3>Creating an expectation of stock to be COLLECTED from a warehouse</h3>
      <p>
        {` 1) Manifests → Outbound → Create Outbound Manifest`}{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/manifests/outbound/create-outbound-manifest`}
        >
          + Create Outbound Manifest
        </Link>
      </p>
      <ul>
        <li>
          On the manifest lines, you are creating an expectation of products, not actual stock that
          needs to be selected.
        </li>
      </ul>
      <h3>Requesting a stock check</h3>
      <p>
        1) Stock Check → Create Stock Check{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/operations/create-stock-check`}
        >
          + Create Stock Check Request
        </Link>
      </p>
      <p>2) Select the warehouse you wish to request a stock check in</p>
      <p>3) Select which type of stock check you want:</p>
      <ul>
        <li>Manual → manually select the records of stock you want to have checked</li>
        <li>Full → request a stock check of the entire warehouse</li>
        <li>Location → request a check of stock in a certain location</li>
        <li>Product → request a check of all stock that matches the specified product</li>
      </ul>
      <h2>Concepts</h2>
      <h3>Recipes and Work Orders</h3>
      <p>
        There is some confusion as to what a recipe is compared to a work order. In order to aid
        understanding, the best way to think about this is to imagine a restaurant.
      </p>
      <p>
        In a restaurant, there is a menu which states the food that is available and then when you
        want to eat something from the menu, you place an order based on the menu.
      </p>
      <p>
        Typically each menu item has a recipe behind it which states how the food is made, what
        quantities of ingredients to use and what the final dish should be.
      </p>
      <p>
        In a similar vein, we have the ability to create Recipes in the WMS; this is done by
        choosing a set of inputs and outputs (translated in our metaphor as ingredients and the food
        item/dish respectively). When we wish to use the Recipe, we must use a Work Order to
        instruct the warehouse staff to essentially 'cook' the Recipe.{" "}
      </p>
      <p>
        The output of a Work Order creates new stock items and deducts the quantity of stock used as
        ingredients.
      </p>
    </div>
  );
}

export default WMSUserManual;
