import * as Yup from "yup";

export const validationSchema = Yup.object({
  product_stock_id: Yup.object()
    .shape({
      value: Yup.number().required("Product stock is required"),
    })
    .typeError("Product stock is required"),
  organisation_id: Yup.object()
    .shape({
      value: Yup.number().required("Organisation is required"),
    })
    .typeError("Organisation is required"),
  quantity: Yup.number().required("Quantity is required"),
});
