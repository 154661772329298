import { useAuth0 } from "@auth0/auth0-react";
import { ReactElement, useEffect } from "react";
import { connect } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { activeServiceAction } from "../redux/actions/activeServiceAction";
import { ActiveServiceReducer } from "../redux/reducers/activeServiceReducer";
import { StoreTypes } from "../redux/store/storeTypes";
import { SERVICE_ACCOUNTS } from "../shared/constants";

interface PrivateRouteProps extends RouteProps {
  activeServiceState: ActiveServiceReducer;
  changeActiveService: (body: ActiveServiceReducer) => void;
  component: (props: any) => ReactElement;
  service?: SERVICE_ACCOUNTS;
}

const PrivateRoute = ({ component: Component, service, path, ...rest }: PrivateRouteProps) => {
  const { activeServiceState, changeActiveService } = rest;
  const { isLoading, isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    if (isLoading || isAuthenticated) {
      return;
    }

    loginWithRedirect({ appState: { targetUrl: document.location } });
  }, [isLoading, isAuthenticated, loginWithRedirect, path]);

  useEffect(() => {
    if (service && service !== activeServiceState.activeService) {
      changeActiveService({ activeService: service });
    }
  }, [service, activeServiceState.activeService, changeActiveService]);

  if (isLoading) {
    return null;
  }

  const render = (props: any) => (isAuthenticated === true ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

function mapStateToProps(state: StoreTypes) {
  return {
    activeServiceState: state.activeServiceReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    changeActiveService: (body: ActiveServiceReducer) => dispatch(activeServiceAction(body)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
