import { Formik } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Chip, IconText, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  DateInput,
  FlexHolder,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import {
  CreateStockCheckBody,
  CreateStockCheckLinesBody,
} from "../../shared/api/createStockCheck/types";
import { GetFilteredProductStockParams } from "../../shared/api/getFilteredProductStock/types";
import StockCheckLine from "../../shared/components/StockCheckLine";
import { useAllBaseProductNames } from "../../shared/graphql/hooks/useAllBaseProducts";
import { useAllLocationsByWarehouse } from "../../shared/graphql/hooks/useAllLocationsByWarehouse";
import { useWarehouseNames } from "../../shared/graphql/hooks/useWarehouseNames";
import {
  createStockCheckAction,
  createStockCheckReset,
} from "../../shared/redux/actions/createStockCheckAction";
import {
  getFilteredProductStockAction,
  getFilteredProductStockReset,
} from "../../shared/redux/actions/getFilteredProductStockAction";
import { CreateStockCheckReducer } from "../../shared/redux/reducers/createStockCheckReducer";
import { GetFilteredProductStockReducer } from "../../shared/redux/reducers/getFilteredProductStockReducer";
import { CreateBasicStockCheckForm, fieldNames, initialFieldValues } from "../formValues";
import { useBasicStockCheckTypes } from "../graphql/hooks/useBasicStockCheckTypes";
import { createBasicStockCheckValidation } from "../validation";

interface Props {
  createStockCheckState: CreateStockCheckReducer;
  createStockCheck: (body: CreateStockCheckBody) => Promise<void>;
  resetCreateStockCheck: () => void;
  getFilteredProductStockState: GetFilteredProductStockReducer;
  getFilteredProductStock: (params: GetFilteredProductStockParams) => Promise<void>;
  resetGetFilteredProductStock: () => void;
}

function CreateBasicStockCheck(props: Props) {
  const [initialValues, setInitialValues] = useState<CreateBasicStockCheckForm>(initialFieldValues);
  const [isEvaluatingStockCheckLines, setIsEvaluatingStockCheckLines] = useState<boolean>(false);

  const {
    createStockCheck,
    createStockCheckState,
    resetCreateStockCheck,
    getFilteredProductStockState,
    getFilteredProductStock,
    resetGetFilteredProductStock,
  } = props;

  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useWarehouseNames();
  const { stockCheckTypes, stockCheckTypesLoading, stockCheckTypesError } =
    useBasicStockCheckTypes();
  const { locationNames, locationNamesError, getAllLocationNamesByWarehouseId } =
    useAllLocationsByWarehouse();
  const { baseProductNames, baseProductNamesLoading, baseProductNamesError } =
    useAllBaseProductNames();

  const onSubmit = (values: CreateBasicStockCheckForm) => {
    const createStockCheckLines: CreateStockCheckBody["stockCheckLines"] =
      getFilteredProductStockState.productStocks.map(stock => {
        const createStockCheckLine: CreateStockCheckLinesBody = {
          expectedQuantity: stock.quantity,
          expectedStockConditionId: stock.stock_condition_id,
          productStockId: stock.id,
        };

        return createStockCheckLine;
      });

    const createStockCheckBody: CreateStockCheckBody = {
      notes: values.notes,
      locationId: values.location?.value,
      stockCheckTypeId: values.stock_check_type?.value!,
      dueDate: values.due_date ? new Date(values.due_date) : undefined,
      stockCheckLines: createStockCheckLines,
    };

    createStockCheck(createStockCheckBody);
  };

  useEffect(() => {
    if (createStockCheckState.success) {
      resetCreateStockCheck();
      resetGetFilteredProductStock();
      setInitialValues(initialFieldValues);
      setIsEvaluatingStockCheckLines(false);
    }

    if (createStockCheckState.error) {
      resetCreateStockCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStockCheckState.success, createStockCheckState.error]);

  const isError =
    warehouseNamesError || stockCheckTypesError || locationNamesError || baseProductNamesError;
  const isLoading =
    warehouseNamesLoading ||
    stockCheckTypesLoading ||
    baseProductNamesLoading ||
    getFilteredProductStockState.loading ||
    createStockCheckState.loading;

  return (
    <Page error={isError} isLoading={isLoading} title={"Operations - Stock Check"}>
      <Panel
        withWrapper
        title={"Create Basic Stock Check"}
        subtitle={"Use the form below to create a basic stock check"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={createBasicStockCheckValidation}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              setTouched,
              validateForm,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label
                        isRequired={true}
                        isGray={isEvaluatingStockCheckLines ? true : false}
                        htmlFor={fieldNames.warehouse}
                      >
                        Warehouse
                      </Label>
                      {isEvaluatingStockCheckLines ? (
                        <Box>{values.warehouse?.label}</Box>
                      ) : (
                        <Select
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          options={warehouseNames}
                          id={fieldNames.warehouse}
                          value={values.warehouse}
                          onChange={e => {
                            setFieldValue(fieldNames.warehouse, e);
                            setFieldValue(fieldNames.location, null);
                            getAllLocationNamesByWarehouseId({ warehouseId: e?.value! });
                          }}
                          placeholder="Select Warehouse"
                        />
                      )}

                      {errors.warehouse && touched.warehouse ? (
                        <ErrorMessage>{errors.warehouse}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label
                        isRequired={true}
                        isGray={isEvaluatingStockCheckLines ? true : false}
                        htmlFor={fieldNames.stock_check_type}
                      >
                        Stock Check Type
                      </Label>
                      {isEvaluatingStockCheckLines ? (
                        <Box>{values.stock_check_type?.label}</Box>
                      ) : (
                        <Select
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          options={stockCheckTypes}
                          id={fieldNames.stock_check_type}
                          value={values.stock_check_type}
                          onChange={e => setFieldValue(fieldNames.stock_check_type, e)}
                          placeholder="Select Stock Check Type"
                        />
                      )}

                      {errors.stock_check_type && touched.stock_check_type ? (
                        <ErrorMessage>{errors.stock_check_type}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label
                        isGray={isEvaluatingStockCheckLines ? true : false}
                        htmlFor={fieldNames.due_date}
                      >
                        Due Date
                      </Label>
                      {isEvaluatingStockCheckLines ? (
                        <Box>
                          {values.due_date
                            ? moment(values.due_date).format("DD/MM/YYYY")
                            : "None Selected"}
                        </Box>
                      ) : (
                        <DateInput
                          id={fieldNames.due_date}
                          type="date"
                          value={values.due_date}
                          onChange={e => {
                            setFieldValue(fieldNames.due_date, e.target.value);
                          }}
                        />
                      )}

                      {errors.due_date && touched.due_date ? (
                        <ErrorMessage>{errors.due_date}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label
                        isGray={isEvaluatingStockCheckLines ? true : false}
                        htmlFor={fieldNames.notes}
                      >
                        Notes
                      </Label>
                      {isEvaluatingStockCheckLines ? (
                        <Box>{values.notes ? values.notes : "None Provided"}</Box>
                      ) : (
                        <InputField
                          id={fieldNames.notes}
                          size={"medium"}
                          type={"text"}
                          value={values.notes}
                          placeholder={"Input notes"}
                          handleChange={e => {
                            setFieldValue(fieldNames.notes, e.target.value);
                          }}
                        />
                      )}
                      {errors.notes && touched.notes ? (
                        <ErrorMessage>{errors.notes}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  {values.stock_check_type?.label === "Location" && (
                    <PrimaryLineWrapper>
                      <Title>Location</Title>
                      <PrimaryLineDataWrapper>
                        <DataPoint withUniqueMargin>
                          <Label
                            isRequired
                            isGray={isEvaluatingStockCheckLines ? true : false}
                            htmlFor={fieldNames.location}
                          >
                            Location
                          </Label>
                          {isEvaluatingStockCheckLines ? (
                            <Box>{values.location?.label}</Box>
                          ) : (
                            <Select
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              isSearchable={true}
                              options={locationNames}
                              id={fieldNames.location}
                              value={values.location}
                              onChange={e => {
                                setFieldValue(fieldNames.location, e);
                              }}
                              placeholder="Select Location"
                            />
                          )}
                          {errors.location && touched.location ? (
                            <ErrorMessage>{errors.location}</ErrorMessage>
                          ) : null}
                        </DataPoint>
                      </PrimaryLineDataWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {values.stock_check_type?.label === "Product" && (
                    <PrimaryLineWrapper>
                      <Title>Product</Title>
                      <PrimaryLineDataWrapper>
                        <DataPoint withUniqueMargin>
                          <Label
                            isRequired
                            isGray={isEvaluatingStockCheckLines ? true : false}
                            htmlFor={fieldNames.base_product}
                          >
                            Base Product
                          </Label>
                          {isEvaluatingStockCheckLines ? (
                            <Box>{values.base_product?.label}</Box>
                          ) : (
                            <Select
                              styles={reactSelectStyling}
                              maxMenuHeight={220}
                              isSearchable={true}
                              options={baseProductNames}
                              id={fieldNames.base_product}
                              value={values.base_product}
                              onChange={e => {
                                setFieldValue(fieldNames.base_product, e);
                              }}
                              placeholder="Select Base Product"
                            />
                          )}
                          {errors.base_product && touched.base_product ? (
                            <ErrorMessage>{errors.base_product}</ErrorMessage>
                          ) : null}
                        </DataPoint>
                      </PrimaryLineDataWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {!isEvaluatingStockCheckLines && (
                    <ButtonWrapper>
                      <SecondaryButton
                        type="button"
                        appearance={"whiteButtonBlueText"}
                        onClick={async () => {
                          setTouched({
                            warehouse: true,
                            due_date: true,
                            notes: true,
                            stock_check_type: true,
                            base_product:
                              values.stock_check_type?.label === "Product" ? true : false,
                            location: values.stock_check_type?.label === "Location" ? true : false,
                          });

                          const formErrors = await validateForm();

                          if (Object.keys(formErrors).length === 0) {
                            setInitialValues({ ...values });

                            getFilteredProductStock({
                              warehouseId: values.warehouse?.value!,
                              stockCheckTypeId: values.stock_check_type?.value!,
                              baseProductId: values.base_product?.value,
                              locationId: values.location?.value,
                            });

                            setIsEvaluatingStockCheckLines(true);
                          }
                        }}
                        className={WMSButtonGroup({ type: "smallMargin" })}
                      >
                        <IconText text={"Prepare Stock Check"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {isEvaluatingStockCheckLines && getFilteredProductStockState.success && (
                    <>
                      <PrimaryLineWrapper>
                        <Title>Stock Check Preparation Summary</Title>
                        <PrimaryLineDataWrapper>
                          <DataPoint withUniqueMargin>
                            <Label isGray>Earliest Stock Interation Date</Label>
                            <Box>
                              {getFilteredProductStockState.earliestStockInteractionDate
                                ? moment(
                                    getFilteredProductStockState.earliestStockInteractionDate
                                  ).format("DD/MM/YYYY")
                                : "No Stock Found"}
                            </Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isGray>Latest Stock Interation Date</Label>
                            <Box>
                              {getFilteredProductStockState.latestStockInteractionDate
                                ? moment(
                                    getFilteredProductStockState.latestStockInteractionDate
                                  ).format("DD/MM/YYYY")
                                : "No Stock Found"}
                            </Box>
                          </DataPoint>
                          <DataPoint withUniqueMargin>
                            <Label isGray>Number of Product Stocks</Label>
                            <Box>{getFilteredProductStockState.productStocks.length}</Box>
                          </DataPoint>
                        </PrimaryLineDataWrapper>
                        {getFilteredProductStockState.baseProductNames.length > 0 && (
                          <SecondaryLineWrapper>
                            <Title>Base Products</Title>
                            <FlexHolder css={{ flexDirection: "column", textAlign: "center" }}>
                              {getFilteredProductStockState.baseProductNames.map(
                                baseProductName => {
                                  return (
                                    <Chip css={{ textAlign: "center" }}>{baseProductName}</Chip>
                                  );
                                }
                              )}
                            </FlexHolder>
                          </SecondaryLineWrapper>
                        )}
                      </PrimaryLineWrapper>
                      {getFilteredProductStockState.productStocks.map((productStock, index) => {
                        return (
                          <StockCheckLine
                            key={productStock.id}
                            index={index}
                            line={productStock}
                          ></StockCheckLine>
                        );
                      })}
                    </>
                  )}

                  {isEvaluatingStockCheckLines && (
                    <FormButtonPair
                      canRenderComfirm={getFilteredProductStockState.productStocks.length > 0}
                      handleReset={() => {
                        setInitialValues({ ...initialFieldValues });
                        resetGetFilteredProductStock();
                        setIsEvaluatingStockCheckLines(false);
                      }}
                    />
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createStockCheckState: state.createStockCheckReducer,
    getFilteredProductStockState: state.getFilteredProductStockReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createStockCheck: (body: CreateStockCheckBody) => dispatch(createStockCheckAction(body)),
    resetCreateStockCheck: () => dispatch(createStockCheckReset()),
    getFilteredProductStock: (params: GetFilteredProductStockParams) =>
      dispatch(getFilteredProductStockAction(params)),
    resetGetFilteredProductStock: () => dispatch(getFilteredProductStockReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateBasicStockCheck);
