import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";
import { BaseProduct, BaseProductPagination, BaseProductResponse } from "./types";

interface GetBaseProductsProps {
  page: number;
  page_size: number;
  show_images: boolean;
  sort: string;
  id?: string;
}

export const getBaseProducts = async ({
  page = 1,
  page_size = 10,
  show_images = true,
  sort = "asc:id",
  id = undefined,
}: GetBaseProductsProps): Promise<BaseProductResponse> => {
  try {
    const params: {
      page: number;
      page_size: number;
      show_images: boolean;
      sort: string;
      id?: string;
    } = {
      page: page,
      page_size: page_size,
      show_images: show_images,
      sort: sort,
    };
    if (id) {
      params.id = id;
    }
    const response = await PRODUCT_CATALOGUE_API.get("/baseproducts", {
      params: params,
    });
    const data: BaseProduct[] = Array.isArray(response.data.data) ? response.data.data : [];
    const pagination: BaseProductPagination =
      typeof response.data.pagination === "object" ? response.data.pagination : {};
    return {
      data: data,
      pagination: pagination,
    };
  } catch (err) {
    console.log("Error in getting products API call", err);
    throw err;
  }
};
