import { DropdownItem } from "../components/forms/SimpleSelect/SimpleSelect";
import { CountryCodeItem } from "../types/CountryCodeItem";
import { CurrencyItem } from "../types/CurrencyItem";

export const mapCountryCodeToDropdownItem = (countryCode: CountryCodeItem[]): DropdownItem[] => {
  return countryCode.map((countryCode, index) => {
    return {
      label: countryCode.country_code,
      value: index,
    };
  });
};

export const mapLocaleToDropdownItem = (locale: string[]): DropdownItem[] => {
  return locale.map((locale, index) => {
    return {
      label: locale,
      value: index,
    };
  });
};

export const mapCurrencyCodeToDropdownItem = (currencyCode: CurrencyItem[]): DropdownItem[] => {
  return currencyCode.map((currencyCode, index) => {
    return {
      label: currencyCode.currency_code,
      value: index,
    };
  });
};

export const mapStockManagementOrganisationsToDropdownItem = (
  organisations: any[] | undefined
): DropdownItem[] => {
  if (!organisations) return [];
  return organisations.map(organisation => {
    return {
      label: organisation?.name,
      value: organisation?.externalOrganisationId,
    };
  });
};

export const mapIAMOrganisationsToDropdownItem = (
  organisations: any[] | undefined
): DropdownItem[] => {
  if (!organisations) return [];
  return organisations.map(organisation => {
    return {
      label: organisation.display_name,
      value: organisation.org_id,
    };
  });
};

export const mapStockManagementAddressesToDropdownItem = (
  addresses: any[] | undefined | null
): DropdownItem[] => {
  if (!addresses) return [];
  return addresses.map(address => {
    return {
      label: address.name,
      value: address.id,
    };
  });
};

export const mapPaymentDueDateTypeToDropdownItem = (paymentDueDateType: any[]): DropdownItem[] => {
  return paymentDueDateType.map(paymentDueDateType => {
    return {
      label: paymentDueDateType.readable,
      value: paymentDueDateType.value,
    };
  });
};

export const formatCurrency = (currencyCode: string) => {
  return new Intl.NumberFormat(undefined, {
    style: "currency",
    currency: currencyCode,
  });
};
