import { ApolloError } from "@apollo/client";

import { useGetCaseChangeTopDropdownsDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import {
  mapBaseProductNamesToDropdownObject,
  mapConditionNamesToDropdownObject,
  mapOrganisationNamesToDropdownObject,
  mapWarehouseNamesToDropdownObject,
} from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  organisationNames: DropdownItem[];
  baseProductNames: DropdownItem[];
  warehouseNames: DropdownItem[];
  conditionNames: DropdownItem[];
  topDropdownsDataError?: ApolloError;
  topDropdownsDataLoading: boolean;
}

export const useTopDropdownsData = (): HookResult => {
  const { data, error, loading } = useGetCaseChangeTopDropdownsDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    organisationNames:
      data && Array.isArray(data.organisation)
        ? mapOrganisationNamesToDropdownObject(data.organisation)
        : [],
    baseProductNames:
      data && Array.isArray(data.base_product)
        ? mapBaseProductNamesToDropdownObject(data.base_product)
        : [],
    warehouseNames:
      data && Array.isArray(data.warehouse)
        ? mapWarehouseNamesToDropdownObject(data.warehouse)
        : [],
    conditionNames:
      data && Array.isArray(data.stock_condition)
        ? mapConditionNamesToDropdownObject(data.stock_condition)
        : [],
    topDropdownsDataError: error,
    topDropdownsDataLoading: loading,
  };
};
