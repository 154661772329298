import { ApolloError } from "@apollo/client";

import { useGetOutboundManifestsByDateQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DashboardItem } from "../types";

export interface HookResult {
  outboundManifests: DashboardItem[];
  outboundManifestsError?: ApolloError;
  outboundManifestsLoading: boolean;
}

export const useOutboundManifestsByDate = (selectedDate: string): HookResult => {
  const { data, error, loading } = useGetOutboundManifestsByDateQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      selectedDate,
    },
  });

  return {
    outboundManifests:
      data && Array.isArray(data.outbound_manifest)
        ? data.outbound_manifest.map(outboundManifest => {
            return {
              orderId: outboundManifest.id,
              organisationName: outboundManifest.organisation.organisation_name,
              iconName: "default-calendar",
            };
          })
        : [],
    outboundManifestsError: error,
    outboundManifestsLoading: loading,
  };
};
