import { ApolloError } from "@apollo/client";

import { useGetInboundStockConditionNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapStockConditionNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  stockConditionNames: DropdownItem[];
  stockConditionNamesError?: ApolloError;
  stockConditionNamesLoading: boolean;
}

export const useInboundStockConditionNames = (): HookResult => {
  const { data, error, loading } = useGetInboundStockConditionNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockConditionNames:
      data && Array.isArray(data.stock_condition)
        ? mapStockConditionNamesToDropdownObject(data.stock_condition)
        : [],
    stockConditionNamesError: error,
    stockConditionNamesLoading: loading,
  };
};
