import { OrderLineInterface } from "../graphql/types";

export const updatePoLinesWithFileCopyQty = (data: OrderLineInterface[]) => {
  const fileCopyLines = data.filter(line => line.calculated_quotation_cost === 0);

  const updatedLines = data
    .filter(line => line.calculated_quotation_cost !== 0)
    .map(line => {
      const fileCopyLine = fileCopyLines.find(
        fileCopyLine => fileCopyLine.product.id === line.product.id
      );

      return {
        ...line,
        fileCopyQty: fileCopyLine ? fileCopyLine.quantity : undefined,
      };
    });

  return updatedLines;
};

export const getUniquePoLines = (purchaseOrderLines: OrderLineInterface[]) => {
  return purchaseOrderLines.reduce((acc: OrderLineInterface[], line: OrderLineInterface) => {
    if (
      acc.some(
        accLine =>
          accLine.purchase_order_line_allocations[0].client_order.id ===
          line.purchase_order_line_allocations[0].client_order.id
      ) ||
      !line.purchase_order_line_allocations[0].client_order.delivery_instructions
    ) {
      return acc;
    }

    return [...acc, line];
  }, []);
};
