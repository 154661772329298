import { ApolloError } from "@apollo/client";

import { useGetAllCountryNamesAscQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { CountryNameItem } from "../types";

export interface HookResult {
  countryNames: CountryNameItem[];
  countryNamesError?: ApolloError;
  countryNamesLoading: boolean;
}

export const useAllCountryNamesAsc = (): HookResult => {
  const { data, error, loading } = useGetAllCountryNamesAscQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    countryNames: data && Array.isArray(data.country) ? data.country : [],
    countryNamesError: error,
    countryNamesLoading: loading,
  };
};
