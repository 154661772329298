import { Icon, IconText, PrimaryButton } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

interface PaginationButtonProps {
  handleClick: () => void;
  isDisabled?: boolean;
  type: "next" | "previous";
  isIconOnly?: boolean;
}

const PaginationButton = ({ handleClick, isDisabled, type, isIconOnly }: PaginationButtonProps) => {
  const icon: IconName = type === "previous" ? "arrow-direction-left" : "arrow-direction-right";
  const label = type === "previous" ? "Previous" : "Next";

  return (
    <PrimaryButton
      onClick={handleClick}
      type="button"
      disabled={isDisabled}
      aria-label={type === "previous" ? "navigate-to-previous-page" : "navigate-to-next-page"}
      appearance={isIconOnly ? "iconOnly" : undefined}
    >
      {isIconOnly ? (
        <Icon name={icon} />
      ) : (
        <IconText
          primaryIcon={type === "previous" ? icon : undefined}
          secondaryIcon={type === "previous" ? undefined : icon}
          text={label}
        />
      )}
    </PrimaryButton>
  );
};

export default PaginationButton;
