import moment from "moment";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useOutboundManifestsAsc } from "../graphql/hooks/useOutboundManifestsAsc";
import { ManifestItem } from "../graphql/types";

export default function ViewOutboundManifests() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const isAuthorised = WMS_EDIT_ROLES.some(role => roles.includes(role));

  const rightItems: PanelProps["rightItems"] = isAuthorised
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/manifests/outbound/create-outbound-manifest");
          },
          type: "button",
          title: "Add Manifest",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const { manifests, manifestsLoading, manifestsError } = useOutboundManifestsAsc();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (manifest: ManifestItem) => manifest.id,
      },
      {
        Header: "Reference",
        widthPercent: 0.5,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => manifest.reference,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/manifests/outbound/fulfil-outbound-manifest/${row.original.id}`}>
            {row.original.reference}
          </Link>
        ),
      },
      {
        Header: "Organisation",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => manifest.organisation.organisation_name,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation.id}`}>
            <LinkText>{row.original.organisation?.organisation_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Status",
        widthPercent: 0.05,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) =>
          manifest.outbound_manifest_status.outbound_manifest_status_name,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <StatusBadge
            type="wms"
            statusName={row.original.outbound_manifest_status.outbound_manifest_status_name}
          />
        ),
      },
      {
        Header: "Collection Date",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => moment(manifest.expected_dispatch).format("x"),
        Cell: ({ _, row }: CellProps<ManifestItem>) =>
          moment(row.original.expected_dispatch).format("DD-MM-YYYY"),
      },
      {
        Header: "Warehouse",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => manifest.warehouse.warehouse_name,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Lines",
        widthPercent: 0.1,
        filter: "arraySome",
        accessor: (manifest: ManifestItem) =>
          manifest.outbound_manifest_lines_aggregate.aggregate?.count,
      },
      {
        Header: "Fulfil",
        widthPercent: 0.15,
        Cell: ({ _, row }: CellProps<ManifestItem>) =>
          isAuthorised &&
          (row.original.outbound_manifest_status.id === 0 ||
            row.original.outbound_manifest_status.id === 4) && (
            <Link to={`/manifests/outbound/fulfil-outbound-manifest/${row.original.id}`}>
              <SecondaryButton isWithinTable appearance={"blueButton"}>
                Fulfil
              </SecondaryButton>
            </Link>
          ),
      },
      {
        Header: "View",
        widthPercent: 0.15,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/manifests/outbound/review-outbound-manifest/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    [isAuthorised]
  );

  return (
    <Page title={"Manifests - Outbound"} error={manifestsError} isLoading={manifestsLoading}>
      <Panel
        withWrapper
        title={"Outbound Manifests"}
        text={"Use the table below to view Sourceful's Outbound Manifests"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        toolTipMessage={"A list of items that a warehouse <br/> should expect to be collected"}
      >
        <Table data={manifests} columns={columns} renderFilters={(_: FilterSetter<any>) => <></>} />
      </Panel>
    </Page>
  );
}
