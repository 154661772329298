import { FormikErrors, FormikTouched } from "formik";
import { useTranslation } from "react-i18next";

import { Heading, IconText } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import { DataPoint, PrimaryLineDataWrapper } from "../../../../../../styles/SharedStyles";
import { ExternalQuoteForm, SupplierQuotationCost, SupplierQuoteLine } from "../formValues";
import { QuotationCostTypeItem } from "../graphql/types";

interface SupplierQuotationCostsProps {
  index: number;
  values: ExternalQuoteForm;
  errors: FormikErrors<SupplierQuoteLine>;
  touched: FormikTouched<SupplierQuoteLine>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  quotationCostTypes: QuotationCostTypeItem[];
  isLineSample?: boolean;
}

const roundUp = (value: number) => Math.round(value * 100) / 100;
const getCostInclVat = (cost: number, vat: number) => roundUp(cost + (vat / 100) * cost);

export const SupplierQuotationCosts = ({
  index,
  values,
  errors,
  touched,
  setFieldValue,
  quotationCostTypes,
  isLineSample,
}: SupplierQuotationCostsProps) => {
  const { t } = useTranslation();

  const handleUpdateCost = ({
    field,
    value,
    costIndex,
  }: {
    field: string;
    value: number;
    costIndex: number;
  }) => {
    const cost = values.supplierQuoteLines[index].quotationCosts[costIndex].cost
      ? Number(values.supplierQuoteLines[index].quotationCosts[costIndex].cost)
      : undefined;
    const vat = values.supplierQuoteLines[index].quotationCosts[costIndex].vat
      ? Number(values.supplierQuoteLines[index].quotationCosts[costIndex].vat)
      : undefined;

    if (field === "vat") {
      if (cost && value) {
        const newCostInclVat = getCostInclVat(cost, value);
        setFieldValue(
          `supplierQuoteLines[${index}].quotationCosts[${costIndex}].costInclVat`,
          newCostInclVat
        );
      } else {
        setFieldValue(`supplierQuoteLines[${index}].quotationCosts[${costIndex}].costInclVat`, "");
      }
    }

    if (field === "cost") {
      if (vat && value) {
        const newCostInclVat = getCostInclVat(value, vat);
        setFieldValue(
          `supplierQuoteLines[${index}].quotationCosts[${costIndex}].costInclVat`,
          newCostInclVat
        );
      } else {
        setFieldValue(`supplierQuoteLines[${index}].quotationCosts[${costIndex}].costInclVat`, "");
      }
    }
  };

  return (
    <>
      {!isLineSample && (
        <>
          <hr />
          <Heading level={3} fontSize={6} css={{ marginTop: "20px" }}>
            {isLineSample
              ? t("quote_supplier_response_sample_quotation_cost_section_title")
              : t("quote_supplier_response_quotation_cost_section_title")}
          </Heading>
        </>
      )}

      {values.supplierQuoteLines[index].quotationCosts.map((quotationCost, costIndex) => {
        const costType = quotationCostTypes.find(
          ({ id }) => id === quotationCost.quotationCostTypeId
        );

        const costTypeValues = quotationCostTypes.map(costType => ({
          value: costType.id,
          label: costType.quotation_cost_type_name,
        }));

        const hasErrors = errors && errors.quotationCosts && errors.quotationCosts[costIndex];
        const isTouched = touched && touched.quotationCosts && touched.quotationCosts[costIndex];

        const isCostRequired = isLineSample
          ? costType?.is_cost_required_sample
          : costType?.is_cost_required_default;

        return (
          <PrimaryLineDataWrapper key={`costType_${costIndex}`}>
            <DataPoint withUniqueMargin>
              {!!(isCostRequired && costIndex === 0) ? (
                <DisplayBox
                  fullWidth
                  withUniqueMargin
                  label={t("quote_supplier_response_quotation_cost_type")}
                  value={costType?.quotation_cost_type_name}
                />
              ) : (
                <SimpleSelect
                  name={`${costType?.id}_${index}`}
                  placeholder={t("quote_supplier_response_quotation_cost_type")}
                  label={t("quote_supplier_response_quotation_cost_type")}
                  error={
                    hasErrors &&
                    (errors!.quotationCosts![costIndex] as FormikErrors<SupplierQuotationCost>)
                      .quotationCostTypeId
                  }
                  touched={
                    isTouched &&
                    (touched!.quotationCosts![costIndex] as FormikTouched<SupplierQuotationCost>)
                      .quotationCostTypeId
                  }
                  options={costTypeValues}
                  value={costTypeValues.find(
                    ({ value }) => value === quotationCost.quotationCostTypeId
                  )}
                  changeHandler={e => {
                    setFieldValue(
                      `supplierQuoteLines[${index}].quotationCosts[${costIndex}].quotationCostTypeId`,
                      e?.value
                    );
                  }}
                />
              )}
            </DataPoint>
            <DataPoint withUniqueMargin size="small">
              <SimpleInput
                label={t("quote_supplier_response_quotation_cost")}
                name={`${costType?.quotation_cost_type_name}_${index}_${costIndex}`}
                placeholder={"0.00"}
                error={
                  hasErrors &&
                  (errors!.quotationCosts![costIndex] as FormikErrors<SupplierQuotationCost>).cost
                }
                touched={
                  isTouched &&
                  (touched!.quotationCosts![costIndex] as FormikTouched<SupplierQuotationCost>).cost
                }
                type="number"
                value={values.supplierQuoteLines[index].quotationCosts[costIndex].cost}
                changeHandler={e => {
                  handleUpdateCost({
                    field: "cost",
                    value: Number(e.target.value),
                    costIndex: costIndex,
                  });

                  setFieldValue(
                    `supplierQuoteLines[${index}].quotationCosts[${costIndex}].cost`,
                    e.target.value
                  );
                }}
              />
            </DataPoint>
            <DataPoint withUniqueMargin size="small">
              <SimpleInput
                label={t("quote_supplier_response_quotation_cost_vat")}
                name={`${costType?.quotation_cost_type_name}_${index}_${costIndex}_vat`}
                placeholder={"0.00"}
                error={
                  hasErrors &&
                  (errors!.quotationCosts![costIndex] as FormikErrors<SupplierQuotationCost>).vat
                }
                touched={
                  isTouched &&
                  (touched!.quotationCosts![costIndex] as FormikTouched<SupplierQuotationCost>).vat
                }
                type="number"
                value={values.supplierQuoteLines[index].quotationCosts[costIndex].vat}
                changeHandler={e => {
                  handleUpdateCost({
                    field: "vat",
                    value: Number(e.target.value),
                    costIndex: costIndex,
                  });

                  setFieldValue(
                    `supplierQuoteLines[${index}].quotationCosts[${costIndex}].vat`,
                    e.target.value
                  );
                }}
              />
            </DataPoint>
            <DisplayBox
              label={t("quote_supplier_response_quotation_cost_incl_vat")}
              value={values.supplierQuoteLines[index].quotationCosts[costIndex].costInclVat}
              withUniqueMargin
              tooltipMessage={t("quote_supplier_response_quotation_cost_incl_vat_tooltip")}
            />
            {!(isCostRequired && costIndex === 0) ? (
              <DataPoint withUniqueMargin size="small">
                <SecondaryButton
                  type="button"
                  css={{ marginTop: "16px" }}
                  appearance="whiteButton"
                  onClick={() => {
                    setFieldValue(
                      `supplierQuoteLines[${index}].quotationCosts`,
                      values.supplierQuoteLines[index].quotationCosts.filter(
                        (_, i) => i !== costIndex
                      )
                    );
                  }}
                >
                  <IconText
                    primaryIcon="navigation-trash"
                    text={t("quote_supplier_response_quotation_remove")}
                  />
                </SecondaryButton>
              </DataPoint>
            ) : (
              <DataPoint size="small"></DataPoint>
            )}
          </PrimaryLineDataWrapper>
        );
      })}
      {!isLineSample && (
        <div style={{ display: "flex", justifyContent: "flex-end", margin: "15px 0" }}>
          <SecondaryButton
            type="button"
            appearance="whiteButtonBlueText"
            onClick={() => {
              setFieldValue(
                `supplierQuoteLines[${index}].quotationCosts`,
                values.supplierQuoteLines[index].quotationCosts.concat({
                  quotationCostTypeId: undefined,
                  cost: "",
                  vat: "",
                  costInclVat: "",
                })
              );
            }}
          >
            {t("quote_supplier_response_add_quotation_cost")}
          </SecondaryButton>
        </div>
      )}
    </>
  );
};
