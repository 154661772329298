import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Icon, styled } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

import { ADMIN_ROLES } from "../../providers/AuthorisationProvider";
import { activeServiceAction } from "../../redux/actions/activeServiceAction";
import { ActiveServiceReducer } from "../../redux/reducers/activeServiceReducer";
import { StoreTypes } from "../../redux/store/storeTypes";
import VisibleFor from "../../shared/components/wrappers/VisibleFor";
import { DropdownSideBar } from "../dropdown/Dropdown";
import { adminSidebarItems } from "./sidebarItems/adminSidebarItems";
import { logisticsSidebarItems } from "./sidebarItems/logisticsSidebarItems";
import { ordersSidebarItems } from "./sidebarItems/ordersSidebarItems";
import { pcatSidebarItems } from "./sidebarItems/pcatSidebarItems";
import { sourcingSidebarItems } from "./sidebarItems/sourcingSidebarItems";
import { wmsSidebarItems } from "./sidebarItems/wmsSidebarItems";

export const IconTitleWrapper = styled("div", {
  whiteSpace: "pre-line",
  display: "grid",
  gridTemplateColumns: "32px 220px",
  alignItems: "center",
  fontSize: "17px",
  lineHeight: "17px",
  cursor: "pointer",
  color: "#000C32",
  fontFamily: "Space Grotesk",
  "&:hover": {
    color: "$palette3_colour1_tint20",
  },
  variants: {
    isActive: {
      true: {
        fontWeight: "bold",
        color: "#005AE1",
        "&:hover": {
          color: "#005AE1",
        },
      },
    },
  },
});

const GlobalWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  fontFamily: "Space Grotesk",
  height: "100%",
  transition: "all 0.5s",
  width: "200px",

  variants: {
    showing: {
      true: {
        width: "200px",
      },
      false: {
        width: "0px",
      },
    },
    hasOverflow: {
      true: {
        overflow: "default",
      },
      false: {
        overflow: "hidden",
      },
    },
  },
});

const SidebarWrapper = styled("div", {
  padding: "20px",
  background: "#ffffff",
  height: "100%",
  borderRight: "1px solid #c4c4c4",
});

const UnorderedList = styled("ul", {
  listStyle: "none",
  padding: "0px",
  margin: "0px",
  marginTop: "5px",
  position: "sticky",
  top: "40px",
  display: "flex",
  flexDirection: "column",
  gap: "30px",
});

const ListItem = styled("li", {
  marginTop: "10px",
});

interface SidebarLink {
  name: string;
  URL: string;
}

export interface SidebarItem {
  isAdmin?: boolean;
  heading: string;
  headingUrl: string;
  icon: IconName;
  links: SidebarLink[];
}

export interface SideBarProps {
  activeServiceState: ActiveServiceReducer;
  changeActiveService: (body: ActiveServiceReducer) => void;
  showing?: boolean;
}

function SideBar({ activeServiceState, showing = true }: SideBarProps) {
  const [routesDropdown, setRoutesDropdown] = useState<string[]>([]);
  const [hasOverflow, setHasOverflow] = useState<boolean>(true);

  let location = useLocation();

  const setIsExpanded = (route: string) => {
    const isOpen = routesDropdown.includes(route);
    if (isOpen) {
      const newRoutes = routesDropdown.filter(openRoute => openRoute !== route);
      setRoutesDropdown(newRoutes);
    } else {
      const newRoutes = [...routesDropdown, route];
      setRoutesDropdown(newRoutes);
    }
  };

  useEffect(() => {
    const subheadingExists = location.pathname.split(" ")[0].split("/")[2];
    if (subheadingExists) {
      const headingURL = location.pathname.split(" ")[0].split("/")[1];
      setRoutesDropdown([headingURL]);
    }
  }, [location.pathname]);

  const items: SidebarItem[] | undefined = useMemo(() => {
    if (activeServiceState.activeService === "WMS") {
      return wmsSidebarItems;
    }
    if (activeServiceState.activeService === "Sourcing") {
      return sourcingSidebarItems;
    }
    if (activeServiceState.activeService === "Logistics") {
      return logisticsSidebarItems;
    }
    if (activeServiceState.activeService === "Admin") {
      return adminSidebarItems;
    }
    if (activeServiceState.activeService === "PCAT") {
      return pcatSidebarItems;
    }
    if (activeServiceState.activeService === "Orders") {
      return ordersSidebarItems;
    }
  }, [activeServiceState.activeService]);

  // Delay adding the overflow to the sidebar so that the animation can play
  useEffect(() => {
    if (showing) {
      setTimeout(() => {
        setHasOverflow(true);
      }, 500);
    } else {
      setHasOverflow(false);
    }
  }, [showing]);

  const checkAdminRender = ({
    children,
    isAdminRoute,
  }: {
    children: React.ReactNode;
    isAdminRoute: boolean;
  }) => {
    return isAdminRoute ? (
      <VisibleFor roles={ADMIN_ROLES}>
        <>{children}</>
      </VisibleFor>
    ) : (
      <>{children}</>
    );
  };

  if (!items) return null;

  return (
    <GlobalWrapper showing={`${!!showing}`} hasOverflow={!!hasOverflow}>
      <SidebarWrapper>
        <UnorderedList>
          {items &&
            items.map(item => (
              <div key={item.heading}>
                {checkAdminRender({
                  children:
                    item.links.length > 1 ? (
                      <ListItem>
                        <DropdownSideBar
                          heading={item.heading}
                          headingURL={item.headingUrl}
                          pathname={location.pathname}
                          subHeading={item.links.map((link: SidebarLink) => ({
                            subHeading: link,
                          }))}
                          setIsExpanded={setIsExpanded}
                          isExpanded={routesDropdown.includes(item.headingUrl)}
                          iconName={item.icon}
                        />
                      </ListItem>
                    ) : (
                      <ListItem>
                        <IconTitleWrapper isActive={location.pathname.includes(item.heading)}>
                          <Icon name={item.icon} width={20} height={20} />
                          <Link to={`/${item.headingUrl}`}>{item.heading}</Link>
                        </IconTitleWrapper>
                      </ListItem>
                    ),
                  isAdminRoute: !!item.isAdmin,
                })}
              </div>
            ))}
        </UnorderedList>
      </SidebarWrapper>
    </GlobalWrapper>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    activeServiceState: state.activeServiceReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    changeActiveService: (body: ActiveServiceReducer) => dispatch(activeServiceAction(body)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SideBar);
