import { FormikErrors, FormikTouched } from "formik";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import {
  DataPoint,
  IconWrapper,
  LinkText,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { TransferLine } from "../formValues";
import { ProductStockItem } from "../graphql/types";

interface StockTransferLineProps {
  values: TransferLine;
  index: number;
  errors: FormikErrors<TransferLine[]> | undefined;
  touched: FormikTouched<TransferLine>[] | undefined;
  handleRemoveTransferLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  dropdownData: ProductStockItem[];
}

const StockTransferLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveTransferLine,
  errors,
  touched,
  dropdownData,
}: StockTransferLineProps) => {
  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.product.product_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/products/view-product/${row.original.product.base_product_id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Location",
        widthPercent: 0.4,
        accessor: (listItem: ProductStockItem) => listItem.location.location_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.location_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Stock status",
        widthPercent: 0.4,
        accessor: (listItem: ProductStockItem) => listItem.stock_status.stock_status_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <StatusBadge type="wms" statusName={row.original.stock_status.stock_status_name} />
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Stock condition",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
      },
      {
        Header: "Organisation",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.organisation?.organisation_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation?.id}`}>
            <LinkText>{row.original.organisation?.organisation_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Choose Stock",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => {
          return values.source_product_stock?.value === row.original.id ? (
            <SecondaryButton isWithinTable appearance="greenButton">
              Chosen
            </SecondaryButton>
          ) : (
            <SecondaryButton
              type="button"
              isWithinTable
              appearance="blueButton"
              onClick={() => {
                setFieldValue(`transfer_lines[${index}].source_product_stock`, {
                  label: row.original.product.product_name,
                  value: row.original.id,
                });
              }}
            >
              Choose
            </SecondaryButton>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [values.source_product_stock]
  );

  let currentProductStockQuantity =
    dropdownData.find(
      query_product_stock => query_product_stock.id === values.source_product_stock?.value
    )?.quantity ?? 0;

  return (
    <>
      <SecondaryLineWrapper type="withIndex">
        <IndexPill index={index + 1} />
        <Table data={dropdownData} columns={columns} />

        {Array.isArray(errors) &&
        errors[index]?.source_product_stock &&
        Array.isArray(touched) &&
        touched[index]?.source_product_stock ? (
          <ErrorMessage>{errors[index]?.source_product_stock}</ErrorMessage>
        ) : null}

        <SecondaryLineWrapper>
          <DataPoint>
            <SimpleInput
              isDisabled={!values.source_product_stock}
              label="Quantity"
              htmlFor={`quantity_${index}`}
              name={`quantity_${index}`}
              type="number"
              value={values.quantity}
              placeholder={
                values.source_product_stock ? "Select Quantity" : "Please select a product stock"
              }
              changeHandler={e => {
                setFieldValue(`transfer_lines[${index}].quantity`, e.target.value);
              }}
              error={errors ? errors[index]?.quantity : ""}
              touched={touched && touched[index]?.quantity}
            />
          </DataPoint>

          <DisplayBox
            label="Remaining Product Stock"
            value={
              values.source_product_stock
                ? String(currentProductStockQuantity - Number(values.quantity))
                : "Please select a product stock"
            }
          />
        </SecondaryLineWrapper>
        <DataPoint>
          <IconWrapper type="button" onClick={() => handleRemoveTransferLine(index)}>
            <Icon width={30} height={30} name="alert-cross-fill" />
          </IconWrapper>
        </DataPoint>
      </SecondaryLineWrapper>
    </>
  );
};

export default StockTransferLine;
