import { FormikErrors, FormikTouched } from "formik";
import { createRef, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { ButtonGroup, Heading, Modal, styled } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../../components/AttachmentDownloader/AttachmentDownloader";
import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import { EnhancedAttachment } from "../../../../../../shared/types/EnhancedAttachment";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  SecondaryLineDataWrapper,
  Title,
} from "../../../../../../styles/SharedStyles";
import { useAPI } from "../../../../../../util/api/apiHook";
import AttributesSummary from "../../../../shared/components/AttributesSummary";
import { fetchSkuAPI } from "../../../../shared/fetchSku/api/fetchSku";
import { ProductCatalogueSkuBody } from "../../../../shared/fetchSku/api/types";
import { prettifyProductName } from "../../../../shared/mappers";
import { ExtendedQuoteRequestLine } from "../../graphql/types";
import { ExternalQuoteForm, SupplierQuoteLine } from "../formValues";
import { QuotationCostTypeItem } from "../graphql/types";
import { WhitePrimaryLineWrapper } from "../page/createSupplierResponse";
import { SupplierQuoteRequestLine } from "./SupplierQuoteRequestLine";

const ProductDetailsModalContainer = styled("div", {
  maxHeight: "80vh",
  overflow: "auto",
});

interface SupplierQuoteLineProductGroupProps {
  lines: ExtendedQuoteRequestLine[];
  index: number;
  attachmentsWithSignedUrls: EnhancedAttachment[];
  values: ExternalQuoteForm;
  errors: FormikErrors<ExternalQuoteForm>;
  touched: FormikTouched<ExternalQuoteForm>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  quotationCostTypes: QuotationCostTypeItem[];
  handleSetOpenQuoteLine: (index: number | null) => void;
  openQuoteLine: number | null;
  handleAddSample: (index: number) => void;
  linesEdited: number[];
  handleAddLinesEdited: (index: number) => void;
  token: string;
}

export const SupplierQuoteLineProductGroup = ({
  lines,
  index,
  values,
  errors,
  touched,
  setFieldValue,
  quotationCostTypes,
  attachmentsWithSignedUrls,
  handleSetOpenQuoteLine,
  openQuoteLine,
  handleAddSample,
  linesEdited,
  handleAddLinesEdited,
  token,
}: SupplierQuoteLineProductGroupProps) => {
  const { t } = useTranslation();
  const [productDetails, setProductDetails] = useState<ProductCatalogueSkuBody>();
  const modalRef = createRef<HTMLButtonElement>();
  const [showingProductDetails, setShowingProductDetails] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [fetchSkuState, fetchSku] = useAPI(fetchSkuAPI, true);

  const handleAttachmentDownload = (attachmentUUID: string) => {
    const foundAttachment = attachmentsWithSignedUrls.find(
      attachment => attachment.uuid === attachmentUUID
    );

    if (foundAttachment && foundAttachment.signedUrl) {
      // force browser to download attachment
      const url = `${foundAttachment.signedUrl}&response-content-disposition=attachment; filename="${foundAttachment.originalFilename}"`;
      window.open(encodeURI(url), "_blank");
    }
  };

  const groupContainsSample = lines.some(line => line.is_sample);

  useEffect(() => {
    const fn = async () => {
      if (lines[0].product.external_product_id) {
        const payload = { skuId: lines[0].product.external_product_id, token };
        const productDetails = await fetchSku(payload);

        if (productDetails && Array.isArray(productDetails)) {
          setProductDetails(productDetails[0]);
        }
      }
    };
    fn();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WhitePrimaryLineWrapper>
      <Title>{`${t("quote_supplier_response_rfq_section_title")}${index + 1}`}</Title>
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin halfWidth>
          <DisplayBox
            fullWidth
            withUniqueMargin
            label={t("quote_supplier_response_product")}
            value={prettifyProductName(lines[0].product.product_name)}
          />
        </DataPoint>
        <DataPoint withUniqueMargin halfWidth>
          <DisplayBox
            label={t("quote_request_line_notes")}
            value={lines[0].notes}
            fullWidth
            withUniqueMargin
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
      {productDetails && (
        <PrimaryLineDataWrapper>
          <SecondaryButton
            type="button"
            onClick={() => setShowingProductDetails(true)}
            appearance="blueButton"
            ref={modalRef}
          >
            View Product Details
          </SecondaryButton>
        </PrimaryLineDataWrapper>
      )}
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin fullWidth>
          <Title>{t("supplier_response_attachments_title")}</Title>
          {!lines[0].folder || lines[0].folder.attachments.length === 0 ? (
            <SecondaryLineDataWrapper>
              {t("quote_supplier_response_product_line_attachments_not_found")}
            </SecondaryLineDataWrapper>
          ) : (
            <>
              {lines[0].folder.attachments.map(attachment => (
                <SecondaryLineDataWrapper key={`attachment_${attachment.id}`}>
                  <AttachmentDownloader
                    key={`attachment_${attachment.id}`}
                    originalFilename={attachment.original_filename}
                    attachmentUUID={attachment.attachment_uuid}
                    onDownload={attachmentUUID => {
                      handleAttachmentDownload(attachmentUUID);
                    }}
                    metadata={attachment.metadata}
                  />
                </SecondaryLineDataWrapper>
              ))}
            </>
          )}
        </DataPoint>
      </PrimaryLineDataWrapper>

      {lines.map((line, lineIndex) => (
        <SupplierQuoteRequestLine
          linesEdited={linesEdited}
          handleAddLinesEdited={handleAddLinesEdited}
          key={`line_${line.id}_${lineIndex}`}
          openQuoteLine={openQuoteLine}
          handleSetOpenQuoteLine={handleSetOpenQuoteLine}
          line={line}
          values={values}
          errors={
            errors.supplierQuoteLines
              ? (errors.supplierQuoteLines[line.index] as FormikErrors<SupplierQuoteLine>)
              : {}
          }
          touched={
            touched.supplierQuoteLines
              ? (touched.supplierQuoteLines[line.index] as FormikTouched<SupplierQuoteLine>)
              : {}
          }
          setFieldValue={setFieldValue}
          quotationCostTypes={quotationCostTypes}
        />
      ))}

      <Modal
        isOpen={showingProductDetails}
        handleClose={() => setShowingProductDetails(false)}
        id={`product_details_${index}`}
        triggerRef={modalRef}
      >
        <ProductDetailsModalContainer>
          <Heading level={3} fontSize={5} css={{ margin: "0 10px 10px" }}>
            Product Details
          </Heading>
          {productDetails && showingProductDetails && (
            <AttributesSummary
              physicalAttributes={productDetails?.physical_attributes}
              contentAttributes={productDetails?.content_attributes}
            />
          )}
        </ProductDetailsModalContainer>
      </Modal>

      {!groupContainsSample && (
        <ButtonGroup style={{ margin: "15px", justifyContent: "flex-end" }}>
          <SecondaryButton
            appearance="blueButton"
            type="button"
            onClick={() => handleAddSample(index)}
          >
            Add Sample
          </SecondaryButton>
        </ButtonGroup>
      )}
    </WhitePrimaryLineWrapper>
  );
};
