import { ApolloError } from "@apollo/client";

import { useGetPendingDemandQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { PendingDemand } from "../types";

export interface HookResult {
  data: PendingDemand[];
  error?: ApolloError;
  isLoading: boolean;
}

// FYI:
// abstract away generated types and hooks
// so it is not tightly coupled to graphql-codegen
// TODO: next iteration would be to add a mapping between values returned to i.e. .status instead of .client_order_line_status_name
export const usePendingDemand = (): HookResult => {
  const { data, error, loading } = useGetPendingDemandQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    data: data?.pending_demand || [],
    error,
    isLoading: loading,
  };
};
