import { ApolloError } from "@apollo/client";

import { useGetCountryCodesQuery } from "../../../../../../generated/graphql_sourcing";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapCountryCodeToDropdownItem } from "../../../../../../shared/mappers";

export interface HookResult {
  countryCodes: DropdownItem[];
  countryCodesLoading: boolean;
  countryCodesError?: ApolloError;
}
export const useCountryCodes = (): HookResult => {
  const { data, loading, error } = useGetCountryCodesQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    countryCodes:
      data && Array.isArray(data.country) ? mapCountryCodeToDropdownItem(data.country) : [],
    countryCodesLoading: loading,
    countryCodesError: error,
  };
};
