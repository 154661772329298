import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import {
  UPDATE_PURCHASE_ORDER_DELIVERY_DATE_FAILED,
  UPDATE_PURCHASE_ORDER_DELIVERY_DATE_LOADING,
  UPDATE_PURCHASE_ORDER_DELIVERY_DATE_RESET,
  UPDATE_PURCHASE_ORDER_DELIVERY_DATE_SUCCESS,
} from "../types/index";

export interface UpdatePurchaseOrderDeliveryDateReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: UpdatePurchaseOrderDeliveryDateReducer = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function updatePurchaseOrderDeliveryDateReducer(
  state: UpdatePurchaseOrderDeliveryDateReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case UPDATE_PURCHASE_ORDER_DELIVERY_DATE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PURCHASE_ORDER_DELIVERY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case UPDATE_PURCHASE_ORDER_DELIVERY_DATE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case UPDATE_PURCHASE_ORDER_DELIVERY_DATE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
