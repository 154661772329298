import { StatusSummaryItem } from "../types";

export const statusSummaryItems: StatusSummaryItem[] = [
  {
    statusName: "Pending",
    statusOverview:
      "The order is only visible and known to Sourceful people and needs to be reviewed before it is sent to a supplier.",
    statusTrigger:
      "The available actions are to approve Dielines and Order, so that the order can move forward",
  },
  {
    statusName: "Internally Rejected",
    statusOverview:
      "The order has been rejected internally by members of the Sourceful team either because artwork/dielines are incorrect or because there is something else wrong with the order, including pricing, supplier or overall demand requriements.",
    statusTrigger:
      "The order has not been sent to supplier and cannot be successfully fulfilled. A new purchase order needs to be created to satisfy the client order demand.",
  },
  {
    statusName: "Approved",
    statusOverview:
      "The order has been internally approved using the `Order` approval and has been successfully sent to supplier.",
    statusTrigger: "A supplier response is pending.",
  },
  {
    statusName: "Accepted",
    statusOverview: "The order has been sent to the selected supplier and they have accepted it.",
    statusTrigger:
      "The supplier has accepted responsibility for fulfiling the order and the products can be considered to be in production.",
  },
  {
    statusName: "Externally Rejected",
    statusOverview:
      "The order has been sent to the selected supplier, however they have rejected it.",
    statusTrigger:
      "The order cannot be completed successfully and a new purchase order needs to be created to fulfil the client order demand. Details about the rejection reason can be viewed on the Update Purchase Order page (only visible once rejected).",
  },
  {
    statusName: "Expired",
    statusOverview:
      "The order has been sent to the selected supplier, however they have not replied in the 48H window (adjusted for weekends).",
    statusTrigger:
      "The order cannot be completed successfully and a new purchase order needs to be created to fulfil the client order demand.",
  },
  {
    statusName: "In Transit",
    statusOverview:
      "The order is in transit after the supplier responds and sends dispatch confirmation and details.",
    statusTrigger:
      "The order can be marked as in transit only if it has been accepted by the manufacturer. This unlocks the funcitonality to also mark the order as delivered.",
  },
  {
    statusName: "Delivered",
    statusOverview:
      "The order has been delivered to the purchase order destination (not final destination!) and has been marked as such.",
    statusTrigger:
      "The order can be marked as delivered only if it is already in transit. Once the order is marked as delivered, its status should not change.",
  },
];
