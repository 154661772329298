import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { fulfilInboundManifestLineAPI } from "../../api/fulfilInboundManifestLineAPI";
import { FulfilInboundManifestLineBody } from "../../api/types";
import {
  FULFILLING_INBOUND_MANIFEST_LINE,
  FULFIL_INBOUND_MANIFEST_LINE_FAILED,
  FULFIL_INBOUND_MANIFEST_LINE_RESET,
  FULFIL_INBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export const fulfillingInboundManifestLine = () => {
  return {
    type: FULFILLING_INBOUND_MANIFEST_LINE,
  };
};

export const fulfilInboundManifestLineSuccess = () => {
  return {
    type: FULFIL_INBOUND_MANIFEST_LINE_SUCCESS,
  };
};

export const fulfilInboundManifestLineFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: FULFIL_INBOUND_MANIFEST_LINE_FAILED,
    errors: errors,
  };
};

export const fulfilInboundManifestLineReset = () => {
  return {
    type: FULFIL_INBOUND_MANIFEST_LINE_RESET,
  };
};

export const fulfilInboundManifestLine = (body: FulfilInboundManifestLineBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating inbound manifest line fulfilment", { hideProgressBar: true });
    dispatch(fulfillingInboundManifestLine());
    try {
      await fulfilInboundManifestLineAPI(body);
      toast.success("Inbound manifest line fulfilment created successfully.");
      dispatch(fulfilInboundManifestLineSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating inbound manifest line fulfilment.");
      dispatch(fulfilInboundManifestLineFailed({ errors: (err as any).response.data.errors }));
    }
  };
};
