import _ from "lodash";

import { SUPPLIER_COUNTRY_CODE_MAP, SUPPLIER_CURRENCY_MAP } from "../../../../../shared/constants";
import {
  AttachmentItem,
  PurchaseOrderItem as UpdatePOPurchaseOrderItem,
} from "../../updatePurchaseOrders/graphql/types";
import { PurchaseOrderItem as ViewPOPurchaseOrderItem } from "../../viewPurchaseOrders/graphql/types";

export const clientNamesMapper = (
  purchaseOrderItem: ViewPOPurchaseOrderItem | UpdatePOPurchaseOrderItem
): string[] => {
  return _.uniq(
    purchaseOrderItem.purchase_order_lines.flatMap(poLine =>
      poLine.purchase_order_line_allocations.map(
        poLineAllocation =>
          poLineAllocation.client_order?.organisation.organisation_name ?? "Unobtainable"
      )
    )
  );
};

export const productNamesMapper = (
  purchaseOrderItem: ViewPOPurchaseOrderItem | UpdatePOPurchaseOrderItem
): string[] => {
  return _.uniq(
    purchaseOrderItem.purchase_order_lines.flatMap(poLine => poLine.product.product_name)
  );
};

export const mapAttachmentsToSet = (attachments: AttachmentItem[]) => {
  return attachments.filter((value, index, self) => {
    return self.findIndex(v => v.attachment_uuid === value.attachment_uuid) === index;
  });
};

export const countryCodeFromLocale = (locale: string | undefined): string => {
  if (locale === undefined) {
    return "";
  }

  return SUPPLIER_COUNTRY_CODE_MAP[locale];
};

export const currencyFromLocale = (locale: string | undefined): string => {
  if (locale === undefined) {
    return "";
  }

  return SUPPLIER_CURRENCY_MAP[locale];
};
