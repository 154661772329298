import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { splitShipmentAPI } from "../../api/splitShipment";
import {
  SPLIT_SHIPMENT_FAILED,
  SPLIT_SHIPMENT_LOADING,
  SPLIT_SHIPMENT_RESET,
  SPLIT_SHIPMENT_SUCCESS,
  SplitShipmentActionType,
} from "../types";

export interface SplitShipmentAction {
  type: SplitShipmentActionType;
  errors?: Error[];
}

export const splitShipmentLoading = () => {
  return { type: SPLIT_SHIPMENT_LOADING };
};

export const splitShipmentSuccess = () => {
  return { type: SPLIT_SHIPMENT_SUCCESS };
};

export const splitShipmentFailed = () => {
  return { type: SPLIT_SHIPMENT_FAILED };
};

export const splitShipmentReset = () => {
  return { type: SPLIT_SHIPMENT_RESET };
};

export const splitShipmentAction = (shipmentId: number) => {
  return async (dispatch: Dispatch) => {
    toast("Splitting Shipment", { hideProgressBar: true });
    dispatch(splitShipmentLoading());
    try {
      await splitShipmentAPI(shipmentId);
      toast.success("Shipment split successfully");
      dispatch(splitShipmentSuccess());
    } catch (err) {
      toast.error("Something went wrong with splitting this shipment.");
      dispatch(splitShipmentFailed());
    }
  };
};
