import { FormikErrors, FormikTouched } from "formik";
import { toast } from "react-toastify";

import { IconText } from "@sourceful/shared-components";

import SecondaryButton from "../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import {
  ButtonWrapper,
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../styles/SharedStyles";
import { checkStateThresholdApi } from "../api/checkStateThreshold";
import { boolOptions, eurTaxOptions, fieldObjects } from "../formValues";
import { CreateOrderForm } from "../types";

export interface FinanceDetailsProps {
  values: CreateOrderForm;
  errors: FormikErrors<CreateOrderForm>;
  touched: FormikTouched<CreateOrderForm>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export function FinanceDetails({ values, errors, touched, setFieldValue }: FinanceDetailsProps) {
  return (
    <>
      {values.currency_code.label === "GBP" && (
        <FinanceDetailsUK
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      )}
      {values.currency_code.label === "USD" && (
        <FinanceDetailsUS
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      )}
      {values.currency_code.label === "EUR" && (
        <FinanceDetailsNL
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      )}
      {values.currency_code.label === "CNY" && (
        <FinanceDetailsCN
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      )}
    </>
  );
}

function FinanceDetailsUK({ values, errors, touched, setFieldValue }: FinanceDetailsProps) {
  return (
    <PrimaryLineWrapper>
      <Title>For Sourceful UK Entity</Title>
      <PrimaryLineDataWrapper>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.sourceful_entity_uk.vat_exempt.fieldName}
            name={fieldObjects.sourceful_entity_uk.vat_exempt.fieldName}
            placeholder="Select VAT Exempt Status Here"
            options={boolOptions}
            value={values.sourceful_entity_uk.vat_exempt}
            error={errors.sourceful_entity_uk?.vat_exempt?.label}
            touched={touched.sourceful_entity_uk?.vat_exempt?.label}
            label="VAT Exempt?"
            tooltipMessage="Is the client exempt from VAT due to their company status (Charity, Non-Profit, etc)"
            changeHandler={e =>
              setFieldValue(fieldObjects.sourceful_entity_uk.vat_exempt.fieldName, e)
            }
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.sourceful_entity_uk.outside_uk.fieldName}
            name={fieldObjects.sourceful_entity_uk.outside_uk.fieldName}
            placeholder="Select Outside UK Statues Here"
            options={boolOptions}
            value={values.sourceful_entity_uk.outside_uk}
            error={errors.sourceful_entity_uk?.outside_uk?.label}
            touched={touched.sourceful_entity_uk?.outside_uk?.label}
            label="Outside UK?"
            tooltipMessage="Is the client delivery address outside of the UK AND the end product is not sold in the UK?"
            changeHandler={e =>
              setFieldValue(fieldObjects.sourceful_entity_uk.outside_uk.fieldName, e)
            }
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
}

function FinanceDetailsUS({ values, errors, touched, setFieldValue }: FinanceDetailsProps) {
  const handleFetchUsThreshold = async (setFieldValue: any, values: CreateOrderForm) => {
    try {
      const res = await checkStateThresholdApi(values.shipping_address.region_or_county_or_state);

      setFieldValue(fieldObjects.sourceful_entity_us.threshold.fieldName, res.data);
    } catch (error) {
      toast.error(
        "Error fetching US threshold details.  Please make sure you've entered a valid state code in the Delivery Address. " +
          error
      );
    }
  };

  return (
    <PrimaryLineWrapper>
      <Title>For Sourceful US Entity</Title>
      <PrimaryLineDataWrapper>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.sourceful_entity_us.vat_exempt.fieldName}
            name={fieldObjects.sourceful_entity_us.vat_exempt.fieldName}
            placeholder="Select Tax Type Here"
            options={boolOptions}
            tooltipMessage={"Does the client have an Exemption Certificate?"}
            value={values.sourceful_entity_us.vat_exempt}
            error={errors.sourceful_entity_us?.vat_exempt?.label}
            touched={touched.billing_address?.country_name?.label}
            label="Exemption Certificate"
            changeHandler={e =>
              setFieldValue(fieldObjects.sourceful_entity_us.vat_exempt.fieldName, e)
            }
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isRequired
            isDisabled={true}
            htmlFor={fieldObjects.sourceful_entity_us.threshold.fieldName}
            name={fieldObjects.sourceful_entity_us.threshold.fieldName}
            type="text"
            placeholder=""
            value={values.sourceful_entity_us.threshold}
            error={errors.sourceful_entity_us?.threshold}
            touched={touched.sourceful_entity_us?.threshold}
            label="US Threshold"
            tooltipMessage="Press the Button to get the latest US Threshold"
            changeHandler={e => {
              setFieldValue(fieldObjects.sourceful_entity_us.threshold.fieldName, e.target.value);
            }}
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
        <SecondaryButton
          type="button"
          appearance={"whiteButtonBlueText"}
          onClick={() => {
            handleFetchUsThreshold(setFieldValue, values);
          }}
        >
          <IconText text={"Check US Sales Threshold"} />
        </SecondaryButton>
      </ButtonWrapper>
    </PrimaryLineWrapper>
  );
}

function FinanceDetailsNL({ values, errors, touched, setFieldValue }: FinanceDetailsProps) {
  return (
    <PrimaryLineWrapper>
      <Title>For Sourceful EU Entity</Title>
      <PrimaryLineDataWrapper>
        <DataPoint>
          <SimpleSelect
            isRequired
            isDisabled={true}
            htmlFor={fieldObjects.sourceful_entity_eu.tax_type.fieldName}
            name={fieldObjects.sourceful_entity_eu.tax_type.fieldName}
            placeholder="Select Tax Type Here"
            options={eurTaxOptions}
            value={
              values.billing_address.country_name.label === "NL"
                ? eurTaxOptions[0]
                : eurTaxOptions[1]
            }
            tooltipMessage={
              "This is automatically set based on the Billing Address Country. Works out if billing is to NL or to another EU country"
            }
            error={errors.sourceful_entity_eu?.tax_type?.label}
            touched={touched.sourceful_entity_eu?.tax_type?.label}
            label="Tax Type"
            changeHandler={e =>
              setFieldValue(fieldObjects.sourceful_entity_eu.tax_type.fieldName, e)
            }
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
}

function FinanceDetailsCN({ values, errors, touched, setFieldValue }: FinanceDetailsProps) {
  const thirdPartyAgreementDisabled =
    values.sourceful_entity_cn.client_3rd_party_cn_entity.label === "NO";

  return (
    <PrimaryLineWrapper>
      <Title>For Sourceful CN Entity</Title>
      <PrimaryLineDataWrapper>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.sourceful_entity_cn.client_cn_entity.fieldName}
            name={fieldObjects.sourceful_entity_cn.client_cn_entity.fieldName}
            placeholder={fieldObjects.sourceful_entity_cn.client_cn_entity.fieldPlaceholder}
            options={boolOptions}
            value={values.sourceful_entity_cn.client_cn_entity}
            error={errors.sourceful_entity_cn?.client_cn_entity?.label}
            touched={touched.sourceful_entity_cn?.client_cn_entity?.label}
            label={fieldObjects.sourceful_entity_cn.client_cn_entity.fieldLabel}
            tooltipMessage="Does the client have a CN entity that we can bill against?"
            changeHandler={e =>
              setFieldValue(fieldObjects.sourceful_entity_cn.client_cn_entity.fieldName, e)
            }
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity.fieldName}
            name={fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity.fieldName}
            placeholder={
              fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity.fieldPlaceholder
            }
            options={boolOptions}
            value={values.sourceful_entity_cn.client_3rd_party_cn_entity}
            error={errors.sourceful_entity_cn?.client_3rd_party_cn_entity?.label}
            touched={touched.sourceful_entity_cn?.client_3rd_party_cn_entity?.label}
            label={fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity.fieldLabel}
            tooltipMessage="Does the client have a 3rd party CN entity?"
            changeHandler={e => {
              setFieldValue(
                fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity.fieldName,
                e
              );
              if (e?.label === "NO") {
                setFieldValue(
                  fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.fieldName,
                  { value: 2, label: "NO" }
                );
              }
            }}
          />
        </DataPoint>
        {/** TODO: Refactor YES/NO values to be true/false instead of 1/2 */}
        <DataPoint>
          <div
            data-testid={
              fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.fieldName
            }
            style={{ cursor: thirdPartyAgreementDisabled ? "not-allowed" : "default" }}
          >
            <SimpleSelect
              isRequired
              htmlFor={
                fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.fieldName
              }
              name={fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.fieldName}
              placeholder={
                fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement
                  .fieldPlaceholder
              }
              options={boolOptions}
              value={values.sourceful_entity_cn.client_3rd_party_cn_entity_agreement}
              error={errors.sourceful_entity_cn?.client_3rd_party_cn_entity_agreement?.label}
              touched={touched.sourceful_entity_cn?.client_3rd_party_cn_entity_agreement?.label}
              label={
                fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.fieldLabel
              }
              tooltipMessage="Does the client's 3rd party agree to be billed on their behalf?"
              changeHandler={e =>
                setFieldValue(
                  fieldObjects.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.fieldName,
                  e
                )
              }
              isDisabled={thirdPartyAgreementDisabled}
            />
          </div>
        </DataPoint>
      </PrimaryLineDataWrapper>
    </PrimaryLineWrapper>
  );
}
