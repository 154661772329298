import { ApolloError } from "@apollo/client";

import { useGetAddressesByOrganisationIdLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { AddressItem } from "../types";

export interface HookResult {
  addresses?: AddressItem[];
  addressesLoading: boolean;
  addressesError?: ApolloError;
  getAddressesByOrganisationId: (options: Options) => Promise<void>;
}

export interface Options {
  organisationId: number;
}

export const useAddressesByOrganisationId = (): HookResult => {
  const [getAddressesByOrganisationId, { data, loading, error }] =
    useGetAddressesByOrganisationIdLazyQuery({
      context: {
        clientName: GRAPHQL_SOURCING,
      },
    });

  return {
    addresses: data?.address,
    addressesLoading: loading,
    addressesError: error,
    getAddressesByOrganisationId: async ({ organisationId }: Options) => {
      getAddressesByOrganisationId({ variables: { organisationId } });
    },
  };
};
