import { ApolloError } from "@apollo/client";

import { useGetShipmentsQuery } from "../../../../../../generated/graphql_logistics";
import { GRAPHQL_LOGISTICS } from "../../../../../../shared/constants";
import { ShipmentListItem } from "../types";

export interface HookResult {
  shipments: ShipmentListItem[];
  shipmentsError?: ApolloError;
  shipmentsLoading: boolean;
}

// FYI:
// abstract away generated types and hooks
// so it is not tightly coupled to graphql-codegen
// TODO: next iteration would be to add a mapping between values returned to i.e. .status instead of .client_order_line_status_name
export const useShipments = (): HookResult => {
  const { data, error, loading } = useGetShipmentsQuery({
    context: {
      clientName: GRAPHQL_LOGISTICS,
    },
  });

  return {
    shipments: data?.shipment || [],
    shipmentsError: error,
    shipmentsLoading: loading,
  };
};
