import { ApolloError } from "@apollo/client";
import _ from "lodash";

import { useGetSuppliersLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapSupplier } from "../../mappers";
import { SupplierItem } from "../types";

export interface HookResult {
  suppliers: SupplierItem[];
  suppliersError?: ApolloError;
  suppliersLoading: boolean;
  getSuppliers: (options: Options) => Promise<void>;
}

interface Options {
  productIds: number[];
}

export const useSuppliers = (): HookResult => {
  const [getSuppliers, { data, error, loading }] = useGetSuppliersLazyQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    suppliers:
      data && Array.isArray(data?.supplier_quote_line)
        ? _.uniqBy(mapSupplier(data.supplier_quote_line), `id`)
        : [],
    suppliersError: error,
    suppliersLoading: loading,
    getSuppliers: async ({ productIds }: Options) => {
      const today = new Date();
      getSuppliers({ variables: { productIds, today } });
    },
  };
};
