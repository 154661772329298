import { WMS_API } from "../../../../../api/baseConfig";
import { CreateLocationBody } from "./types";

export const createLocationAPI = async (body: CreateLocationBody) => {
  try {
    return await WMS_API.post("/locations", body);
  } catch (err) {
    console.log("Error in location API call", err);
    throw err;
  }
};
