import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useProductStock } from "../graphql/hooks/useProductStockWithData";
import { ProductStockItem } from "../graphql/types";

export default function ViewOperationsDashboard() {
  const { productStock, productStockLoading, productStockError } = useProductStock();

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.id,
      },
      {
        Header: "Product",
        widthPercent: 0.7,
        accessor: (listItem: ProductStockItem) => listItem.product.product_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Warehouse",
        widthPercent: 0.7,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.location.warehouse.id}`}>
            <LinkText>{row.original.location.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: ProductStockItem) => listItem.location.warehouse.warehouse_name,
      },
      {
        Header: "Location",
        widthPercent: 0.7,
        accessor: (listItem: ProductStockItem) => listItem.location.location_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.location_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Status",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stock_status.stock_status_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <StatusBadge type="wms" statusName={row.original.stock_status.stock_status_name} />
        ),
      },
      {
        Header: "Condition",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
      },
    ],
    []
  );

  return (
    <Page error={productStockError} isLoading={productStockLoading} title={"Operations"}>
      <Panel
        withWrapper
        title={"Product Stock"}
        text={"Use the table below to view Sourceful's Product Stock"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={productStock} columns={columns} />
      </Panel>
    </Page>
  );
}
