import { ApolloError } from "@apollo/client";

import { useGetQaInspectionStatusesQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QAInspectionStatusItem } from "../types";

export interface HookResult {
  qaInspectionStatuses: QAInspectionStatusItem[];
  qaInspectionStatusesError?: ApolloError;
  qaInspectionStatusesLoading: boolean;
}

export const useQAInspectionStatuses = (): HookResult => {
  const { data, error, loading } = useGetQaInspectionStatusesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    qaInspectionStatuses:
      data && Array.isArray(data.quality_assurance_status) ? data.quality_assurance_status : [],
    qaInspectionStatusesError: error,
    qaInspectionStatusesLoading: loading,
  };
};
