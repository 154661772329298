import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createQuotationCostTypeAPI } from "../../api/createQuotationCostTypeAPI";
import { CreateQuotationCostTypeBody } from "../../api/types";
import {
  CREATE_QUOTATION_COST_TYPE_FAILED,
  CREATE_QUOTATION_COST_TYPE_LOADING,
  CREATE_QUOTATION_COST_TYPE_RESET,
  CREATE_QUOTATION_COST_TYPE_SUCCESS,
} from "../types";

export const createQuotationCostTypeLoading = () => {
  return {
    type: CREATE_QUOTATION_COST_TYPE_LOADING,
  };
};

export const createQuotationCostTypeSuccess = () => {
  return {
    type: CREATE_QUOTATION_COST_TYPE_SUCCESS,
  };
};

export const createQuotationCostTypeFailed = () => {
  return {
    type: CREATE_QUOTATION_COST_TYPE_FAILED,
  };
};

export const createQuotationCostTypeReset = () => {
  return {
    type: CREATE_QUOTATION_COST_TYPE_RESET,
  };
};

export const createQuotationCostTypeAction = (body: CreateQuotationCostTypeBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating quotation cost type", { hideProgressBar: true });
    console.log("Creating quotation cost type - Action");
    dispatch(createQuotationCostTypeLoading());
    try {
      await createQuotationCostTypeAPI(body);
      toast.success("Created quotation cost type.");
      dispatch(createQuotationCostTypeSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating quotation cost type.");
      dispatch(createQuotationCostTypeFailed());
    }
  };
};
