import * as Yup from "yup";

const additionalBaseProductsSchema = Yup.object({
  id: Yup.number().defined(),
  base_product: Yup.object()
    .shape({
      value: Yup.number().required("Base Product is required"),
    })
    .typeError("Base Product is required"),
});

export const createWeeklyStockCheckValidation = Yup.object({
  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),
  stock_interaction_start_date: Yup.string().defined().required(),
  stock_interaction_end_date: Yup.string().defined().required(),
  additional_base_products: Yup.array().of(additionalBaseProductsSchema).defined().required(),
});
