import { useMemo } from "react";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ProductStockItem } from "../graphql/types";

interface StockAllocationProductStocksProps {
  productStocks: ProductStockItem[];
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  selectedId?: number;
  setSelectedProductStockQuantity: (value: number) => void;
}
const StockAllocationProductStocks = ({
  productStocks,
  setFieldValue,
  selectedId,
  setSelectedProductStockQuantity,
}: StockAllocationProductStocksProps) => {
  const handleSelect = (item: ProductStockItem) => {
    setSelectedProductStockQuantity(item.quantity);

    setFieldValue("product_stock_id", {
      label: item.id,
      value: item.id,
    });

    setFieldValue("from_organisation_id", {
      label: item.organisation ? item.organisation.organisation_name : "",
      value: item.organisation ? item.organisation.id : "",
    });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Product Stock ID",
        widthPercent: 0.05,
        accessor: (productStock: ProductStockItem) => productStock.id,
      },
      {
        Header: "Organisation",
        widthPercent: 0.2,
        accessor: (productStock: ProductStockItem) => productStock.organisation?.organisation_name,
      },
      {
        Header: "Condition",
        widthPercent: 0.1,
        accessor: (productStock: ProductStockItem) =>
          productStock.stock_condition.stock_condition_name,
      },
      {
        Header: "Status",
        widthPercent: 0.1,
        accessor: (productStock: ProductStockItem) => productStock.stock_status.stock_status_name,
      },
      {
        Header: "Product",
        widthPercent: 0.4,
        accessor: (productStock: ProductStockItem) => productStock.product.product_name,
      },
      {
        Header: "Location",
        widthPercent: 0.1,
        accessor: (productStock: ProductStockItem) => productStock.location.location_name,
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (productStock: ProductStockItem) => productStock.quantity,
      },
      {
        Header: "Choose Stock",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => {
          return selectedId === row.original.id ? (
            <SecondaryButton appearance={"greenButton"}>Chosen</SecondaryButton>
          ) : (
            <SecondaryButton type="button" onClick={() => handleSelect(row.original)}>
              Choose
            </SecondaryButton>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productStocks, selectedId]
  );

  return (
    <>
      <Table
        data={productStocks}
        columns={columns}
        renderFilters={(_: FilterSetter<any>) => <></>}
      />
    </>
  );
};

export default StockAllocationProductStocks;
