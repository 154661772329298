import { ApolloError } from "@apollo/client";

import { useGetInboundOrganisationNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapOrganisationNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  organisationNames?: DropdownItem[];
  organisationNamesError?: ApolloError;
  organisationNamesLoading: boolean;
}

export const useInboundOrganisationNames = (): HookResult => {
  const { data, error, loading } = useGetInboundOrganisationNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    organisationNames:
      data && Array.isArray(data.organisation)
        ? mapOrganisationNamesToDropdownObject(data.organisation)
        : [],
    organisationNamesError: error,
    organisationNamesLoading: loading,
  };
};
