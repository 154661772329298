import { PurchaseOrderForm, PurchaseOrderLine } from "../types";

export const CLIENT_ADDRESS_DELIVERY_TYPE_ID = 1;
export const SOURCEFUL_WAREHOUSE_TYPE_ID = 2;
export const CLIENT_ADDRESS_OR_SOURCEFUL_WAREHOUSE_TYPE_ID = 3;

export const deliveryTypeOptions = [
  {
    value: CLIENT_ADDRESS_DELIVERY_TYPE_ID,
    label: "Client Address",
  },
  {
    value: SOURCEFUL_WAREHOUSE_TYPE_ID,
    label: "Sourceful Warehouse",
  },
];

export const fieldObjects = {
  clients: {
    fieldName: "clients",
    fieldLabel: "Clients",
    fieldPlaceholder: "Select Clients",
    fieldBox: "Please select Clients",
  },
  client_orders: {
    fieldName: "client_orders",
    fieldLabel: "Client Orders",
    fieldPlaceholder: "Select Client Orders",
  },
  delivery_date: {
    fieldName: "estimated_delivery_date",
    fieldLabel: "Estimated Delivery Date",
    fieldPlaceholder: "",
    fieldBox: "Please create Purchase Order Lines",
  },
  delivery_type: {
    fieldName: "delivery_type",
    fieldLabel: "Delivery Type",
    fieldPlaceholder: "Select Delivery Type",
  },
  destination: {
    fieldName: "destination",
    fieldLabel: "Destination",
    fieldPlaceholder: "Select Destination",
  },
  manufacturer: {
    fieldName: "manufacturer",
    fieldLabel: "Manufacturer",
    fieldPlaceholder: "Select Manufacturer",
  },
  order_notes: {
    fieldName: "order_notes",
    fieldLabel: "Additional Notes (for Internal Use)",
    fieldPlaceholder:
      "Input additional notes for this purchase order. Please note that this will not appear on the PO document.",
  },
  external_order_notes: {
    fieldName: "external_order_notes",
    fieldLabel: "External Notes",
    fieldPlaceholder:
      "Input external notes for this purchase order. Please note that this will appear on the Magic Link page.",
  },
  attention_to: {
    fieldName: "attention_to",
    fieldLabel: "Attention To",
    fieldPlaceholder: "Select Attention To",
  },
  attachments: {
    fieldName: "attachments",
    fieldLabel: "Attachments",
  },
};

export const emptyPurchseOrderLine: PurchaseOrderLine = {
  product: null,
  is_qa_required: false,
  qa_notes: "",
  quotation_calculation_pair: null,
  attachments: [],
  client_order_lines: [],
  supplier_quote_line: null,
  fileCopyQty: null,
};

export const initialFieldValues: PurchaseOrderForm = {
  clients: [],
  client_orders: [],
  delivery_type: null,
  destination: null,
  manufacturer: null,
  order_notes: "",
  external_order_notes: "",
  purchase_order_lines: [],
  attention_to: null,
  attachments: [],
};
