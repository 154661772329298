import {
  ColumnWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  RowWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { CaseChangeForm } from "../formValues";
import { StageInForm } from "../types";

interface Props {
  stageInForm: StageInForm;
  values: CaseChangeForm;
}

const CaseChangeSummary = ({ stageInForm, values }: Props) => {
  return (
    <>
      {stageInForm.productStockSelectionConfirmed && (
        <PrimaryLineWrapper>
          <Title>Summary</Title>
          <PrimaryLineDataWrapper>
            <ColumnWrapper>
              <RowWrapper>-</RowWrapper>
              {values.selected_product_stock_lines.map(_line => {
                return <RowWrapper>Selected Stock</RowWrapper>;
              })}
              <RowWrapper type={"newCase"}>Resulting Stock</RowWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <RowWrapper type={"title"}>Product Name</RowWrapper>
              {values.selected_product_stock_lines.map(line => {
                return <RowWrapper>{line.product_name}</RowWrapper>;
              })}
              <RowWrapper type={"newCase"}>{values.to_case?.label}</RowWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <RowWrapper type={"title"}>Selected Quantity</RowWrapper>

              {values.selected_product_stock_lines.map(line => {
                return <RowWrapper>{line.selected_quantity}</RowWrapper>;
              })}
              <RowWrapper type={"newCase"}>{values.to_quantity}</RowWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <RowWrapper type={"title"}>Base Product Quantity</RowWrapper>

              {values.selected_product_stock_lines.map(line => {
                return <RowWrapper>{line.base_product_quantity}</RowWrapper>;
              })}

              <RowWrapper type={"newCase"}>{values.to_case_base_product_quantity}</RowWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <RowWrapper type={"title"}>Location</RowWrapper>

              {values.selected_product_stock_lines.map(line => {
                return <RowWrapper>{line.location}</RowWrapper>;
              })}
              <RowWrapper type={"newCase"}>{values.new_location?.label}</RowWrapper>
            </ColumnWrapper>
            <ColumnWrapper>
              <RowWrapper type={"title"}>Condition</RowWrapper>

              {values.selected_product_stock_lines.map(line => {
                return <RowWrapper>{line.condition}</RowWrapper>;
              })}
              <RowWrapper type={"newCase"}>{values.condition?.label}</RowWrapper>
            </ColumnWrapper>

            <ColumnWrapper>
              <RowWrapper type={"title"}>Total Base Quantity</RowWrapper>
              {values.selected_product_stock_lines.map(line => {
                return (
                  <RowWrapper>
                    {Number(line.base_product_quantity) * Number(line.selected_quantity)}
                  </RowWrapper>
                );
              })}
              <RowWrapper type={"newCase"}>
                {Number(values.to_quantity) * values.to_case_base_product_quantity!}
              </RowWrapper>
            </ColumnWrapper>
          </PrimaryLineDataWrapper>
        </PrimaryLineWrapper>
      )}
    </>
  );
};

export default CaseChangeSummary;
