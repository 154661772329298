import { PRODUCT_PRICING_API } from "../../../../../api/baseConfig";

export const resetProductPriceApi = async (sku_id: string) => {
  try {
    return await PRODUCT_PRICING_API.post(`/expire_product_pricings_by_sku?sku=${sku_id}`);
  } catch (err) {
    console.log("Error in resetting product price API call", err);
    throw err;
  }
};
