import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createQuotesAPI } from "../../api/createQuotesAPI";
import { CreateQuotesBody } from "../../api/types";
import {
  CREATE_SUPPLIER_QUOTES_FAILED,
  CREATE_SUPPLIER_QUOTES_LOADING,
  CREATE_SUPPLIER_QUOTES_RESET,
  CREATE_SUPPLIER_QUOTES_SUCCESS,
} from "../types";

export const createSupplierQuotesLoading = () => {
  return {
    type: CREATE_SUPPLIER_QUOTES_LOADING,
  };
};

export const createSupplierQuotesSuccess = () => {
  return {
    type: CREATE_SUPPLIER_QUOTES_SUCCESS,
  };
};

export const createSupplierQuotesFailed = () => {
  return {
    type: CREATE_SUPPLIER_QUOTES_FAILED,
  };
};

export const createSupplierQuotesReset = () => {
  return {
    type: CREATE_SUPPLIER_QUOTES_RESET,
  };
};

export const createSupplierQuotesAction = (body: CreateQuotesBody, access_token?: string) => {
  return async (dispatch: Dispatch) => {
    toast("Creating quotes", { hideProgressBar: true });
    console.log("Creating Supplier Quotes - Action");
    dispatch(createSupplierQuotesLoading());
    try {
      await createQuotesAPI(body, access_token);
      toast.success("Created quotes successfully.");
      dispatch(createSupplierQuotesSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating quotes.");
      dispatch(createSupplierQuotesFailed());
    }
  };
};
