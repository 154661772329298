import { SidebarItem } from "../SideBar";

export const sourcingSidebarItems: SidebarItem[] = [
  {
    heading: "My Dashboard",
    headingUrl: "sourcing",
    icon: "navigation-workspaces",
    links: [
      {
        name: "My Dashboard",
        URL: "sourcing",
      },
    ],
  },
  {
    heading: "Client Orders",
    headingUrl: "client-orders",
    icon: "navigation-people",
    links: [
      {
        name: "Client Orders",
        URL: "",
      },
      {
        name: "Pending Demand",
        URL: "pending-demand",
      },
    ],
  },
  {
    heading: "Purchase Orders",
    headingUrl: "purchase-orders",
    icon: "shopping-shipping",
    links: [
      {
        name: "Purchase Orders",
        URL: "purchase-orders",
      },
    ],
  },
  {
    heading: "QC",
    headingUrl: "qc-orders",
    icon: "toggle-star-outline",
    links: [
      {
        name: "Quality Control",
        URL: "qc-orders",
      },
    ],
  },
  {
    heading: "Quotations",
    headingUrl: "quotations",
    icon: "document-note",
    links: [
      {
        name: "Quotations",
        URL: "",
      },
      {
        name: "RFQs",
        URL: "request-for-quotation",
      },
    ],
  },
  {
    heading: "Reports",
    headingUrl: "reports",
    icon: "toggle-star-outline",
    links: [
      {
        name: "Reports",
        URL: "reports",
      },
    ],
  },
  {
    heading: "Suppliers",
    headingUrl: "suppliers",
    icon: "navigation-settings",
    links: [
      {
        name: "Suppliers Dashboard ",
        URL: "suppliers-dashboard",
      },
      {
        name: "Locale Holiday ",
        URL: "create-locale-holiday",
      },
      {
        name: "Supplier Details",
        URL: "create-supplier",
      },
      {
        name: "Delivery Dates",
        URL: "view-delivery-dates",
      },
    ],
  },
  {
    isAdmin: true,
    heading: "Admin",
    headingUrl: "sourcing/admin",
    icon: "alert-information-outline",
    links: [
      {
        name: "Quotation Cost Type",
        URL: "create-quotation-cost-type",
      },
    ],
  },
  {
    heading: "Help",
    headingUrl: "help",
    icon: "alert-question-outline",
    links: [
      {
        name: "WMS Setup Guide",
        URL: "wms-setup-guide",
      },
      {
        name: "WMS User Manual",
        URL: "wms-user-manual",
      },
      {
        name: "Sourcing User Manual",
        URL: "sourcing-user-manual",
      },
    ],
  },
];
