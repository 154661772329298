import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { createOrganisationAPI } from "../../api/createOrganisation";
import { CreateOrganisationBody } from "../../api/types";
import {
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_LOADING,
  CREATE_ORGANISATION_RESET,
  CREATE_ORGANISATION_SUCCESS,
} from "../types";

export const createOrganisationLoading = () => {
  return {
    type: CREATE_ORGANISATION_LOADING,
  };
};

export const createOrganisationSuccess = () => {
  return {
    type: CREATE_ORGANISATION_SUCCESS,
  };
};

export const createOrganisationFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CREATE_ORGANISATION_FAILED,
    errors: errors,
  };
};

export const createOrganisationReset = () => {
  return {
    type: CREATE_ORGANISATION_RESET,
  };
};

export const createOrganisationAction = (body: CreateOrganisationBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating organisation", { hideProgressBar: true });
    dispatch(createOrganisationLoading());
    try {
      await createOrganisationAPI(body);
      toast.success("Organisation created successfully.");
      dispatch(createOrganisationSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating organisation.");
      dispatch(createOrganisationFailed({ errors: (err as any).response?.data?.errors }));
    }
  };
};
