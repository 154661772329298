import * as Apollo from "@apollo/client";
import { gql } from "@apollo/client";

export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  jsonb: any;
  timestamp: any;
  timestamptz: any;
  uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: Maybe<Scalars["Boolean"]>;
  _gt?: Maybe<Scalars["Boolean"]>;
  _gte?: Maybe<Scalars["Boolean"]>;
  _in?: Maybe<Array<Scalars["Boolean"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Boolean"]>;
  _lte?: Maybe<Scalars["Boolean"]>;
  _neq?: Maybe<Scalars["Boolean"]>;
  _nin?: Maybe<Array<Scalars["Boolean"]>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars["Int"]>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars["Int"]>>;
  _eq?: Maybe<Array<Scalars["Int"]>>;
  _gt?: Maybe<Array<Scalars["Int"]>>;
  _gte?: Maybe<Array<Scalars["Int"]>>;
  _in?: Maybe<Array<Array<Scalars["Int"]>>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Array<Scalars["Int"]>>;
  _lte?: Maybe<Array<Scalars["Int"]>>;
  _neq?: Maybe<Array<Scalars["Int"]>>;
  _nin?: Maybe<Array<Array<Scalars["Int"]>>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: Maybe<Scalars["Int"]>;
  _gt?: Maybe<Scalars["Int"]>;
  _gte?: Maybe<Scalars["Int"]>;
  _in?: Maybe<Array<Scalars["Int"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["Int"]>;
  _lte?: Maybe<Scalars["Int"]>;
  _neq?: Maybe<Scalars["Int"]>;
  _nin?: Maybe<Array<Scalars["Int"]>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: Maybe<Scalars["String"]>;
  _gt?: Maybe<Scalars["String"]>;
  _gte?: Maybe<Scalars["String"]>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars["String"]>;
  _in?: Maybe<Array<Scalars["String"]>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars["String"]>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars["String"]>;
  _lt?: Maybe<Scalars["String"]>;
  _lte?: Maybe<Scalars["String"]>;
  _neq?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars["String"]>;
  _nin?: Maybe<Array<Scalars["String"]>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars["String"]>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars["String"]>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars["String"]>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars["String"]>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = "ASC",
  /** descending ordering of the cursor */
  Desc = "DESC",
}

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Array_Comparison_Exp = {
  /** is the array contained in the given array value */
  _contained_in?: Maybe<Array<Scalars["jsonb"]>>;
  /** does the array contain the given value */
  _contains?: Maybe<Array<Scalars["jsonb"]>>;
  _eq?: Maybe<Array<Scalars["jsonb"]>>;
  _gt?: Maybe<Array<Scalars["jsonb"]>>;
  _gte?: Maybe<Array<Scalars["jsonb"]>>;
  _in?: Maybe<Array<Array<Scalars["jsonb"]>>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Array<Scalars["jsonb"]>>;
  _lte?: Maybe<Array<Scalars["jsonb"]>>;
  _neq?: Maybe<Array<Scalars["jsonb"]>>;
  _nin?: Maybe<Array<Array<Scalars["jsonb"]>>>;
};

export type Jsonb_Cast_Exp = {
  String?: Maybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: Maybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars["jsonb"]>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars["jsonb"]>;
  _eq?: Maybe<Scalars["jsonb"]>;
  _gt?: Maybe<Scalars["jsonb"]>;
  _gte?: Maybe<Scalars["jsonb"]>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars["String"]>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars["String"]>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars["String"]>>;
  _in?: Maybe<Array<Scalars["jsonb"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["jsonb"]>;
  _lte?: Maybe<Scalars["jsonb"]>;
  _neq?: Maybe<Scalars["jsonb"]>;
  _nin?: Maybe<Array<Scalars["jsonb"]>>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: "mutation_root";
  /** delete data from the table: "product_service.base_product" */
  delete_product_service_base_product?: Maybe<Product_Service_Base_Product_Mutation_Response>;
  /** delete single row from the table: "product_service.base_product" */
  delete_product_service_base_product_by_pk?: Maybe<Product_Service_Base_Product>;
  /** delete data from the table: "product_service.base_product_status" */
  delete_product_service_base_product_status?: Maybe<Product_Service_Base_Product_Status_Mutation_Response>;
  /** delete single row from the table: "product_service.base_product_status" */
  delete_product_service_base_product_status_by_pk?: Maybe<Product_Service_Base_Product_Status>;
  /** delete data from the table: "product_service.base_product_version" */
  delete_product_service_base_product_version?: Maybe<Product_Service_Base_Product_Version_Mutation_Response>;
  /** delete single row from the table: "product_service.base_product_version" */
  delete_product_service_base_product_version_by_pk?: Maybe<Product_Service_Base_Product_Version>;
  /** delete data from the table: "product_service.sku" */
  delete_product_service_sku?: Maybe<Product_Service_Sku_Mutation_Response>;
  /** delete single row from the table: "product_service.sku" */
  delete_product_service_sku_by_pk?: Maybe<Product_Service_Sku>;
  /** delete data from the table: "product_service.sku_org" */
  delete_product_service_sku_org?: Maybe<Product_Service_Sku_Org_Mutation_Response>;
  /** delete single row from the table: "product_service.sku_org" */
  delete_product_service_sku_org_by_pk?: Maybe<Product_Service_Sku_Org>;
  /** delete data from the table: "product_service.sku_source_type" */
  delete_product_service_sku_source_type?: Maybe<Product_Service_Sku_Source_Type_Mutation_Response>;
  /** delete single row from the table: "product_service.sku_source_type" */
  delete_product_service_sku_source_type_by_pk?: Maybe<Product_Service_Sku_Source_Type>;
  /** insert data into the table: "product_service.base_product" */
  insert_product_service_base_product?: Maybe<Product_Service_Base_Product_Mutation_Response>;
  /** insert a single row into the table: "product_service.base_product" */
  insert_product_service_base_product_one?: Maybe<Product_Service_Base_Product>;
  /** insert data into the table: "product_service.base_product_status" */
  insert_product_service_base_product_status?: Maybe<Product_Service_Base_Product_Status_Mutation_Response>;
  /** insert a single row into the table: "product_service.base_product_status" */
  insert_product_service_base_product_status_one?: Maybe<Product_Service_Base_Product_Status>;
  /** insert data into the table: "product_service.base_product_version" */
  insert_product_service_base_product_version?: Maybe<Product_Service_Base_Product_Version_Mutation_Response>;
  /** insert a single row into the table: "product_service.base_product_version" */
  insert_product_service_base_product_version_one?: Maybe<Product_Service_Base_Product_Version>;
  /** insert data into the table: "product_service.sku" */
  insert_product_service_sku?: Maybe<Product_Service_Sku_Mutation_Response>;
  /** insert a single row into the table: "product_service.sku" */
  insert_product_service_sku_one?: Maybe<Product_Service_Sku>;
  /** insert data into the table: "product_service.sku_org" */
  insert_product_service_sku_org?: Maybe<Product_Service_Sku_Org_Mutation_Response>;
  /** insert a single row into the table: "product_service.sku_org" */
  insert_product_service_sku_org_one?: Maybe<Product_Service_Sku_Org>;
  /** insert data into the table: "product_service.sku_source_type" */
  insert_product_service_sku_source_type?: Maybe<Product_Service_Sku_Source_Type_Mutation_Response>;
  /** insert a single row into the table: "product_service.sku_source_type" */
  insert_product_service_sku_source_type_one?: Maybe<Product_Service_Sku_Source_Type>;
  /** update data of the table: "product_service.base_product" */
  update_product_service_base_product?: Maybe<Product_Service_Base_Product_Mutation_Response>;
  /** update single row of the table: "product_service.base_product" */
  update_product_service_base_product_by_pk?: Maybe<Product_Service_Base_Product>;
  /** update multiples rows of table: "product_service.base_product" */
  update_product_service_base_product_many?: Maybe<
    Array<Maybe<Product_Service_Base_Product_Mutation_Response>>
  >;
  /** update data of the table: "product_service.base_product_status" */
  update_product_service_base_product_status?: Maybe<Product_Service_Base_Product_Status_Mutation_Response>;
  /** update single row of the table: "product_service.base_product_status" */
  update_product_service_base_product_status_by_pk?: Maybe<Product_Service_Base_Product_Status>;
  /** update multiples rows of table: "product_service.base_product_status" */
  update_product_service_base_product_status_many?: Maybe<
    Array<Maybe<Product_Service_Base_Product_Status_Mutation_Response>>
  >;
  /** update data of the table: "product_service.base_product_version" */
  update_product_service_base_product_version?: Maybe<Product_Service_Base_Product_Version_Mutation_Response>;
  /** update single row of the table: "product_service.base_product_version" */
  update_product_service_base_product_version_by_pk?: Maybe<Product_Service_Base_Product_Version>;
  /** update multiples rows of table: "product_service.base_product_version" */
  update_product_service_base_product_version_many?: Maybe<
    Array<Maybe<Product_Service_Base_Product_Version_Mutation_Response>>
  >;
  /** update data of the table: "product_service.sku" */
  update_product_service_sku?: Maybe<Product_Service_Sku_Mutation_Response>;
  /** update single row of the table: "product_service.sku" */
  update_product_service_sku_by_pk?: Maybe<Product_Service_Sku>;
  /** update multiples rows of table: "product_service.sku" */
  update_product_service_sku_many?: Maybe<Array<Maybe<Product_Service_Sku_Mutation_Response>>>;
  /** update data of the table: "product_service.sku_org" */
  update_product_service_sku_org?: Maybe<Product_Service_Sku_Org_Mutation_Response>;
  /** update single row of the table: "product_service.sku_org" */
  update_product_service_sku_org_by_pk?: Maybe<Product_Service_Sku_Org>;
  /** update multiples rows of table: "product_service.sku_org" */
  update_product_service_sku_org_many?: Maybe<
    Array<Maybe<Product_Service_Sku_Org_Mutation_Response>>
  >;
  /** update data of the table: "product_service.sku_source_type" */
  update_product_service_sku_source_type?: Maybe<Product_Service_Sku_Source_Type_Mutation_Response>;
  /** update single row of the table: "product_service.sku_source_type" */
  update_product_service_sku_source_type_by_pk?: Maybe<Product_Service_Sku_Source_Type>;
  /** update multiples rows of table: "product_service.sku_source_type" */
  update_product_service_sku_source_type_many?: Maybe<
    Array<Maybe<Product_Service_Sku_Source_Type_Mutation_Response>>
  >;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Base_ProductArgs = {
  where: Product_Service_Base_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Base_Product_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Base_Product_StatusArgs = {
  where: Product_Service_Base_Product_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Base_Product_Status_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Base_Product_VersionArgs = {
  where: Product_Service_Base_Product_Version_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Base_Product_Version_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_SkuArgs = {
  where: Product_Service_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Sku_By_PkArgs = {
  id: Scalars["uuid"];
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Sku_OrgArgs = {
  where: Product_Service_Sku_Org_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Sku_Org_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Sku_Source_TypeArgs = {
  where: Product_Service_Sku_Source_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Product_Service_Sku_Source_Type_By_PkArgs = {
  id: Scalars["Int"];
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Base_ProductArgs = {
  objects: Array<Product_Service_Base_Product_Insert_Input>;
  on_conflict?: Maybe<Product_Service_Base_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Base_Product_OneArgs = {
  object: Product_Service_Base_Product_Insert_Input;
  on_conflict?: Maybe<Product_Service_Base_Product_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Base_Product_StatusArgs = {
  objects: Array<Product_Service_Base_Product_Status_Insert_Input>;
  on_conflict?: Maybe<Product_Service_Base_Product_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Base_Product_Status_OneArgs = {
  object: Product_Service_Base_Product_Status_Insert_Input;
  on_conflict?: Maybe<Product_Service_Base_Product_Status_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Base_Product_VersionArgs = {
  objects: Array<Product_Service_Base_Product_Version_Insert_Input>;
  on_conflict?: Maybe<Product_Service_Base_Product_Version_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Base_Product_Version_OneArgs = {
  object: Product_Service_Base_Product_Version_Insert_Input;
  on_conflict?: Maybe<Product_Service_Base_Product_Version_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_SkuArgs = {
  objects: Array<Product_Service_Sku_Insert_Input>;
  on_conflict?: Maybe<Product_Service_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Sku_OneArgs = {
  object: Product_Service_Sku_Insert_Input;
  on_conflict?: Maybe<Product_Service_Sku_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Sku_OrgArgs = {
  objects: Array<Product_Service_Sku_Org_Insert_Input>;
  on_conflict?: Maybe<Product_Service_Sku_Org_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Sku_Org_OneArgs = {
  object: Product_Service_Sku_Org_Insert_Input;
  on_conflict?: Maybe<Product_Service_Sku_Org_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Sku_Source_TypeArgs = {
  objects: Array<Product_Service_Sku_Source_Type_Insert_Input>;
  on_conflict?: Maybe<Product_Service_Sku_Source_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Product_Service_Sku_Source_Type_OneArgs = {
  object: Product_Service_Sku_Source_Type_Insert_Input;
  on_conflict?: Maybe<Product_Service_Sku_Source_Type_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_ProductArgs = {
  _set?: Maybe<Product_Service_Base_Product_Set_Input>;
  where: Product_Service_Base_Product_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_By_PkArgs = {
  _set?: Maybe<Product_Service_Base_Product_Set_Input>;
  pk_columns: Product_Service_Base_Product_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_ManyArgs = {
  updates: Array<Product_Service_Base_Product_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_StatusArgs = {
  _set?: Maybe<Product_Service_Base_Product_Status_Set_Input>;
  where: Product_Service_Base_Product_Status_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_Status_By_PkArgs = {
  _set?: Maybe<Product_Service_Base_Product_Status_Set_Input>;
  pk_columns: Product_Service_Base_Product_Status_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_Status_ManyArgs = {
  updates: Array<Product_Service_Base_Product_Status_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_VersionArgs = {
  _append?: Maybe<Product_Service_Base_Product_Version_Append_Input>;
  _delete_at_path?: Maybe<Product_Service_Base_Product_Version_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Service_Base_Product_Version_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Service_Base_Product_Version_Delete_Key_Input>;
  _inc?: Maybe<Product_Service_Base_Product_Version_Inc_Input>;
  _prepend?: Maybe<Product_Service_Base_Product_Version_Prepend_Input>;
  _set?: Maybe<Product_Service_Base_Product_Version_Set_Input>;
  where: Product_Service_Base_Product_Version_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_Version_By_PkArgs = {
  _append?: Maybe<Product_Service_Base_Product_Version_Append_Input>;
  _delete_at_path?: Maybe<Product_Service_Base_Product_Version_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Service_Base_Product_Version_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Service_Base_Product_Version_Delete_Key_Input>;
  _inc?: Maybe<Product_Service_Base_Product_Version_Inc_Input>;
  _prepend?: Maybe<Product_Service_Base_Product_Version_Prepend_Input>;
  _set?: Maybe<Product_Service_Base_Product_Version_Set_Input>;
  pk_columns: Product_Service_Base_Product_Version_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Base_Product_Version_ManyArgs = {
  updates: Array<Product_Service_Base_Product_Version_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_SkuArgs = {
  _append?: Maybe<Product_Service_Sku_Append_Input>;
  _delete_at_path?: Maybe<Product_Service_Sku_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Service_Sku_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Service_Sku_Delete_Key_Input>;
  _inc?: Maybe<Product_Service_Sku_Inc_Input>;
  _prepend?: Maybe<Product_Service_Sku_Prepend_Input>;
  _set?: Maybe<Product_Service_Sku_Set_Input>;
  where: Product_Service_Sku_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_By_PkArgs = {
  _append?: Maybe<Product_Service_Sku_Append_Input>;
  _delete_at_path?: Maybe<Product_Service_Sku_Delete_At_Path_Input>;
  _delete_elem?: Maybe<Product_Service_Sku_Delete_Elem_Input>;
  _delete_key?: Maybe<Product_Service_Sku_Delete_Key_Input>;
  _inc?: Maybe<Product_Service_Sku_Inc_Input>;
  _prepend?: Maybe<Product_Service_Sku_Prepend_Input>;
  _set?: Maybe<Product_Service_Sku_Set_Input>;
  pk_columns: Product_Service_Sku_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_ManyArgs = {
  updates: Array<Product_Service_Sku_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_OrgArgs = {
  _inc?: Maybe<Product_Service_Sku_Org_Inc_Input>;
  _set?: Maybe<Product_Service_Sku_Org_Set_Input>;
  where: Product_Service_Sku_Org_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_Org_By_PkArgs = {
  _inc?: Maybe<Product_Service_Sku_Org_Inc_Input>;
  _set?: Maybe<Product_Service_Sku_Org_Set_Input>;
  pk_columns: Product_Service_Sku_Org_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_Org_ManyArgs = {
  updates: Array<Product_Service_Sku_Org_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_Source_TypeArgs = {
  _inc?: Maybe<Product_Service_Sku_Source_Type_Inc_Input>;
  _set?: Maybe<Product_Service_Sku_Source_Type_Set_Input>;
  where: Product_Service_Sku_Source_Type_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_Source_Type_By_PkArgs = {
  _inc?: Maybe<Product_Service_Sku_Source_Type_Inc_Input>;
  _set?: Maybe<Product_Service_Sku_Source_Type_Set_Input>;
  pk_columns: Product_Service_Sku_Source_Type_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Product_Service_Sku_Source_Type_ManyArgs = {
  updates: Array<Product_Service_Sku_Source_Type_Updates>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = "asc",
  /** in ascending order, nulls first */
  AscNullsFirst = "asc_nulls_first",
  /** in ascending order, nulls last */
  AscNullsLast = "asc_nulls_last",
  /** in descending order, nulls first */
  Desc = "desc",
  /** in descending order, nulls first */
  DescNullsFirst = "desc_nulls_first",
  /** in descending order, nulls last */
  DescNullsLast = "desc_nulls_last",
}

/** columns and relationships of "product_service.base_product" */
export type Product_Service_Base_Product = {
  __typename?: "product_service_base_product";
  /** An array relationship */
  base_product_versions: Array<Product_Service_Base_Product_Version>;
  /** An aggregate relationship */
  base_product_versions_aggregate: Product_Service_Base_Product_Version_Aggregate;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  image_url?: Maybe<Scalars["String"]>;
  last_updated: Scalars["timestamptz"];
  name: Scalars["String"];
  type: Scalars["String"];
};

/** columns and relationships of "product_service.base_product" */
export type Product_Service_Base_ProductBase_Product_VersionsArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

/** columns and relationships of "product_service.base_product" */
export type Product_Service_Base_ProductBase_Product_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

/** aggregated selection of "product_service.base_product" */
export type Product_Service_Base_Product_Aggregate = {
  __typename?: "product_service_base_product_aggregate";
  aggregate?: Maybe<Product_Service_Base_Product_Aggregate_Fields>;
  nodes: Array<Product_Service_Base_Product>;
};

/** aggregate fields of "product_service.base_product" */
export type Product_Service_Base_Product_Aggregate_Fields = {
  __typename?: "product_service_base_product_aggregate_fields";
  avg?: Maybe<Product_Service_Base_Product_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Service_Base_Product_Max_Fields>;
  min?: Maybe<Product_Service_Base_Product_Min_Fields>;
  stddev?: Maybe<Product_Service_Base_Product_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Service_Base_Product_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Service_Base_Product_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Service_Base_Product_Sum_Fields>;
  var_pop?: Maybe<Product_Service_Base_Product_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Service_Base_Product_Var_Samp_Fields>;
  variance?: Maybe<Product_Service_Base_Product_Variance_Fields>;
};

/** aggregate fields of "product_service.base_product" */
export type Product_Service_Base_Product_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Service_Base_Product_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Product_Service_Base_Product_Avg_Fields = {
  __typename?: "product_service_base_product_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "product_service.base_product". All fields are combined with a logical 'AND'. */
export type Product_Service_Base_Product_Bool_Exp = {
  _and?: Maybe<Array<Product_Service_Base_Product_Bool_Exp>>;
  _not?: Maybe<Product_Service_Base_Product_Bool_Exp>;
  _or?: Maybe<Array<Product_Service_Base_Product_Bool_Exp>>;
  base_product_versions?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  base_product_versions_aggregate?: Maybe<Product_Service_Base_Product_Version_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  name?: Maybe<String_Comparison_Exp>;
  type?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_service.base_product" */
export enum Product_Service_Base_Product_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseProductPkey = "base_product_pkey",
}

/** input type for inserting data into table "product_service.base_product" */
export type Product_Service_Base_Product_Insert_Input = {
  base_product_versions?: Maybe<Product_Service_Base_Product_Version_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Product_Service_Base_Product_Max_Fields = {
  __typename?: "product_service_base_product_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Product_Service_Base_Product_Min_Fields = {
  __typename?: "product_service_base_product_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "product_service.base_product" */
export type Product_Service_Base_Product_Mutation_Response = {
  __typename?: "product_service_base_product_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Service_Base_Product>;
};

/** input type for inserting object relation for remote table "product_service.base_product" */
export type Product_Service_Base_Product_Obj_Rel_Insert_Input = {
  data: Product_Service_Base_Product_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Base_Product_On_Conflict>;
};

/** on_conflict condition type for table "product_service.base_product" */
export type Product_Service_Base_Product_On_Conflict = {
  constraint: Product_Service_Base_Product_Constraint;
  update_columns?: Array<Product_Service_Base_Product_Update_Column>;
  where?: Maybe<Product_Service_Base_Product_Bool_Exp>;
};

/** Ordering options when selecting data from "product_service.base_product". */
export type Product_Service_Base_Product_Order_By = {
  base_product_versions_aggregate?: Maybe<Product_Service_Base_Product_Version_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  name?: Maybe<Order_By>;
  type?: Maybe<Order_By>;
};

/** primary key columns input for table: product_service.base_product */
export type Product_Service_Base_Product_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "product_service.base_product" */
export enum Product_Service_Base_Product_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Name = "name",
  /** column name */
  Type = "type",
}

/** input type for updating data in table "product_service.base_product" */
export type Product_Service_Base_Product_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.base_product_status" */
export type Product_Service_Base_Product_Status = {
  __typename?: "product_service_base_product_status";
  /** An array relationship */
  base_product_versions: Array<Product_Service_Base_Product_Version>;
  /** An aggregate relationship */
  base_product_versions_aggregate: Product_Service_Base_Product_Version_Aggregate;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description: Scalars["String"];
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  status: Scalars["String"];
};

/** columns and relationships of "product_service.base_product_status" */
export type Product_Service_Base_Product_StatusBase_Product_VersionsArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

/** columns and relationships of "product_service.base_product_status" */
export type Product_Service_Base_Product_StatusBase_Product_Versions_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

/** aggregated selection of "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Aggregate = {
  __typename?: "product_service_base_product_status_aggregate";
  aggregate?: Maybe<Product_Service_Base_Product_Status_Aggregate_Fields>;
  nodes: Array<Product_Service_Base_Product_Status>;
};

/** aggregate fields of "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Aggregate_Fields = {
  __typename?: "product_service_base_product_status_aggregate_fields";
  avg?: Maybe<Product_Service_Base_Product_Status_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Service_Base_Product_Status_Max_Fields>;
  min?: Maybe<Product_Service_Base_Product_Status_Min_Fields>;
  stddev?: Maybe<Product_Service_Base_Product_Status_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Service_Base_Product_Status_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Service_Base_Product_Status_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Service_Base_Product_Status_Sum_Fields>;
  var_pop?: Maybe<Product_Service_Base_Product_Status_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Service_Base_Product_Status_Var_Samp_Fields>;
  variance?: Maybe<Product_Service_Base_Product_Status_Variance_Fields>;
};

/** aggregate fields of "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Service_Base_Product_Status_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Product_Service_Base_Product_Status_Avg_Fields = {
  __typename?: "product_service_base_product_status_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "product_service.base_product_status". All fields are combined with a logical 'AND'. */
export type Product_Service_Base_Product_Status_Bool_Exp = {
  _and?: Maybe<Array<Product_Service_Base_Product_Status_Bool_Exp>>;
  _not?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
  _or?: Maybe<Array<Product_Service_Base_Product_Status_Bool_Exp>>;
  base_product_versions?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  base_product_versions_aggregate?: Maybe<Product_Service_Base_Product_Version_Aggregate_Bool_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  description?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  status?: Maybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_service.base_product_status" */
export enum Product_Service_Base_Product_Status_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseProductStatusPkey = "base_product_status_pkey",
}

/** input type for inserting data into table "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Insert_Input = {
  base_product_versions?: Maybe<Product_Service_Base_Product_Version_Arr_Rel_Insert_Input>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate max on columns */
export type Product_Service_Base_Product_Status_Max_Fields = {
  __typename?: "product_service_base_product_status_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate min on columns */
export type Product_Service_Base_Product_Status_Min_Fields = {
  __typename?: "product_service_base_product_status_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** response of any mutation on the table "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Mutation_Response = {
  __typename?: "product_service_base_product_status_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Service_Base_Product_Status>;
};

/** input type for inserting object relation for remote table "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Obj_Rel_Insert_Input = {
  data: Product_Service_Base_Product_Status_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Base_Product_Status_On_Conflict>;
};

/** on_conflict condition type for table "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_On_Conflict = {
  constraint: Product_Service_Base_Product_Status_Constraint;
  update_columns?: Array<Product_Service_Base_Product_Status_Update_Column>;
  where?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
};

/** Ordering options when selecting data from "product_service.base_product_status". */
export type Product_Service_Base_Product_Status_Order_By = {
  base_product_versions_aggregate?: Maybe<Product_Service_Base_Product_Version_Aggregate_Order_By>;
  created_at?: Maybe<Order_By>;
  description?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  status?: Maybe<Order_By>;
};

/** primary key columns input for table: product_service.base_product_status */
export type Product_Service_Base_Product_Status_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "product_service.base_product_status" */
export enum Product_Service_Base_Product_Status_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Status = "status",
}

/** input type for updating data in table "product_service.base_product_status" */
export type Product_Service_Base_Product_Status_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate stddev on columns */
export type Product_Service_Base_Product_Status_Stddev_Fields = {
  __typename?: "product_service_base_product_status_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Product_Service_Base_Product_Status_Stddev_Pop_Fields = {
  __typename?: "product_service_base_product_status_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Product_Service_Base_Product_Status_Stddev_Samp_Fields = {
  __typename?: "product_service_base_product_status_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "product_service_base_product_status" */
export type Product_Service_Base_Product_Status_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Service_Base_Product_Status_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Service_Base_Product_Status_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  description?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  status?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Product_Service_Base_Product_Status_Sum_Fields = {
  __typename?: "product_service_base_product_status_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "product_service.base_product_status" */
export enum Product_Service_Base_Product_Status_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Description = "description",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Status = "status",
}

export type Product_Service_Base_Product_Status_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Service_Base_Product_Status_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Service_Base_Product_Status_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Service_Base_Product_Status_Var_Pop_Fields = {
  __typename?: "product_service_base_product_status_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Product_Service_Base_Product_Status_Var_Samp_Fields = {
  __typename?: "product_service_base_product_status_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Product_Service_Base_Product_Status_Variance_Fields = {
  __typename?: "product_service_base_product_status_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Product_Service_Base_Product_Stddev_Fields = {
  __typename?: "product_service_base_product_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Product_Service_Base_Product_Stddev_Pop_Fields = {
  __typename?: "product_service_base_product_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Product_Service_Base_Product_Stddev_Samp_Fields = {
  __typename?: "product_service_base_product_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "product_service_base_product" */
export type Product_Service_Base_Product_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Service_Base_Product_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Service_Base_Product_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  image_url?: Maybe<Scalars["String"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  name?: Maybe<Scalars["String"]>;
  type?: Maybe<Scalars["String"]>;
};

/** aggregate sum on columns */
export type Product_Service_Base_Product_Sum_Fields = {
  __typename?: "product_service_base_product_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "product_service.base_product" */
export enum Product_Service_Base_Product_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  Name = "name",
  /** column name */
  Type = "type",
}

export type Product_Service_Base_Product_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Service_Base_Product_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Service_Base_Product_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Service_Base_Product_Var_Pop_Fields = {
  __typename?: "product_service_base_product_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Product_Service_Base_Product_Var_Samp_Fields = {
  __typename?: "product_service_base_product_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Product_Service_Base_Product_Variance_Fields = {
  __typename?: "product_service_base_product_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** columns and relationships of "product_service.base_product_version" */
export type Product_Service_Base_Product_Version = {
  __typename?: "product_service_base_product_version";
  /** An object relationship */
  base_product: Product_Service_Base_Product;
  base_product_id: Scalars["Int"];
  /** An object relationship */
  base_product_status?: Maybe<Product_Service_Base_Product_Status>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  content_attributes: Scalars["jsonb"];
  created_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  is_current_version: Scalars["Boolean"];
  last_updated: Scalars["timestamptz"];
  physical_attributes: Scalars["jsonb"];
  /** An array relationship */
  skus: Array<Product_Service_Sku>;
  /** An aggregate relationship */
  skus_aggregate: Product_Service_Sku_Aggregate;
  version_id: Scalars["Int"];
};

/** columns and relationships of "product_service.base_product_version" */
export type Product_Service_Base_Product_VersionContent_AttributesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.base_product_version" */
export type Product_Service_Base_Product_VersionPhysical_AttributesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.base_product_version" */
export type Product_Service_Base_Product_VersionSkusArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

/** columns and relationships of "product_service.base_product_version" */
export type Product_Service_Base_Product_VersionSkus_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

/** aggregated selection of "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Aggregate = {
  __typename?: "product_service_base_product_version_aggregate";
  aggregate?: Maybe<Product_Service_Base_Product_Version_Aggregate_Fields>;
  nodes: Array<Product_Service_Base_Product_Version>;
};

export type Product_Service_Base_Product_Version_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Count>;
};

export type Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Service_Base_Product_Version_Select_Column_Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Service_Base_Product_Version_Select_Column_Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Aggregate_Fields = {
  __typename?: "product_service_base_product_version_aggregate_fields";
  avg?: Maybe<Product_Service_Base_Product_Version_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Service_Base_Product_Version_Max_Fields>;
  min?: Maybe<Product_Service_Base_Product_Version_Min_Fields>;
  stddev?: Maybe<Product_Service_Base_Product_Version_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Service_Base_Product_Version_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Service_Base_Product_Version_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Service_Base_Product_Version_Sum_Fields>;
  var_pop?: Maybe<Product_Service_Base_Product_Version_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Service_Base_Product_Version_Var_Samp_Fields>;
  variance?: Maybe<Product_Service_Base_Product_Version_Variance_Fields>;
};

/** aggregate fields of "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Aggregate_Order_By = {
  avg?: Maybe<Product_Service_Base_Product_Version_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Service_Base_Product_Version_Max_Order_By>;
  min?: Maybe<Product_Service_Base_Product_Version_Min_Order_By>;
  stddev?: Maybe<Product_Service_Base_Product_Version_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Service_Base_Product_Version_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Service_Base_Product_Version_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Service_Base_Product_Version_Sum_Order_By>;
  var_pop?: Maybe<Product_Service_Base_Product_Version_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Service_Base_Product_Version_Var_Samp_Order_By>;
  variance?: Maybe<Product_Service_Base_Product_Version_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Service_Base_Product_Version_Append_Input = {
  content_attributes?: Maybe<Scalars["jsonb"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Arr_Rel_Insert_Input = {
  data: Array<Product_Service_Base_Product_Version_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Base_Product_Version_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Service_Base_Product_Version_Avg_Fields = {
  __typename?: "product_service_base_product_version_avg_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Avg_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_service.base_product_version". All fields are combined with a logical 'AND'. */
export type Product_Service_Base_Product_Version_Bool_Exp = {
  _and?: Maybe<Array<Product_Service_Base_Product_Version_Bool_Exp>>;
  _not?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  _or?: Maybe<Array<Product_Service_Base_Product_Version_Bool_Exp>>;
  base_product?: Maybe<Product_Service_Base_Product_Bool_Exp>;
  base_product_id?: Maybe<Int_Comparison_Exp>;
  base_product_status?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
  base_product_status_id?: Maybe<Int_Comparison_Exp>;
  base_product_version_status_id?: Maybe<Int_Comparison_Exp>;
  content_attributes?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  is_current_version?: Maybe<Boolean_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  physical_attributes?: Maybe<Jsonb_Comparison_Exp>;
  skus?: Maybe<Product_Service_Sku_Bool_Exp>;
  skus_aggregate?: Maybe<Product_Service_Sku_Aggregate_Bool_Exp>;
  version_id?: Maybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_service.base_product_version" */
export enum Product_Service_Base_Product_Version_Constraint {
  /** unique or primary key constraint on columns "id" */
  BaseProductVersionPkey = "base_product_version_pkey",
  /** unique or primary key constraint on columns "base_product_id", "version_id" */
  UniqueBaseProductIdVersionId = "unique_base_product_id_version_id",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Service_Base_Product_Version_Delete_At_Path_Input = {
  content_attributes?: Maybe<Array<Scalars["String"]>>;
  physical_attributes?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Service_Base_Product_Version_Delete_Elem_Input = {
  content_attributes?: Maybe<Scalars["Int"]>;
  physical_attributes?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Service_Base_Product_Version_Delete_Key_Input = {
  content_attributes?: Maybe<Scalars["String"]>;
  physical_attributes?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Inc_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Insert_Input = {
  base_product?: Maybe<Product_Service_Base_Product_Obj_Rel_Insert_Input>;
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status?: Maybe<Product_Service_Base_Product_Status_Obj_Rel_Insert_Input>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  is_current_version?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
  skus?: Maybe<Product_Service_Sku_Arr_Rel_Insert_Input>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** aggregate max on columns */
export type Product_Service_Base_Product_Version_Max_Fields = {
  __typename?: "product_service_base_product_version_max_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** order by max() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Max_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Service_Base_Product_Version_Min_Fields = {
  __typename?: "product_service_base_product_version_min_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** order by min() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Min_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Mutation_Response = {
  __typename?: "product_service_base_product_version_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Service_Base_Product_Version>;
};

/** input type for inserting object relation for remote table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Obj_Rel_Insert_Input = {
  data: Product_Service_Base_Product_Version_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Base_Product_Version_On_Conflict>;
};

/** on_conflict condition type for table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_On_Conflict = {
  constraint: Product_Service_Base_Product_Version_Constraint;
  update_columns?: Array<Product_Service_Base_Product_Version_Update_Column>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

/** Ordering options when selecting data from "product_service.base_product_version". */
export type Product_Service_Base_Product_Version_Order_By = {
  base_product?: Maybe<Product_Service_Base_Product_Order_By>;
  base_product_id?: Maybe<Order_By>;
  base_product_status?: Maybe<Product_Service_Base_Product_Status_Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  content_attributes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  is_current_version?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  physical_attributes?: Maybe<Order_By>;
  skus_aggregate?: Maybe<Product_Service_Sku_Aggregate_Order_By>;
  version_id?: Maybe<Order_By>;
};

/** primary key columns input for table: product_service.base_product_version */
export type Product_Service_Base_Product_Version_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Service_Base_Product_Version_Prepend_Input = {
  content_attributes?: Maybe<Scalars["jsonb"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "product_service.base_product_version" */
export enum Product_Service_Base_Product_Version_Select_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  BaseProductStatusId = "base_product_status_id",
  /** column name */
  BaseProductVersionStatusId = "base_product_version_status_id",
  /** column name */
  ContentAttributes = "content_attributes",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsCurrentVersion = "is_current_version",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  PhysicalAttributes = "physical_attributes",
  /** column name */
  VersionId = "version_id",
}

/** select "product_service_base_product_version_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_service.base_product_version" */
export enum Product_Service_Base_Product_Version_Select_Column_Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsCurrentVersion = "is_current_version",
}

/** select "product_service_base_product_version_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_service.base_product_version" */
export enum Product_Service_Base_Product_Version_Select_Column_Product_Service_Base_Product_Version_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsCurrentVersion = "is_current_version",
}

/** input type for updating data in table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Set_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  is_current_version?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** aggregate stddev on columns */
export type Product_Service_Base_Product_Version_Stddev_Fields = {
  __typename?: "product_service_base_product_version_stddev_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Stddev_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Service_Base_Product_Version_Stddev_Pop_Fields = {
  __typename?: "product_service_base_product_version_stddev_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Stddev_Pop_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Service_Base_Product_Version_Stddev_Samp_Fields = {
  __typename?: "product_service_base_product_version_stddev_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Stddev_Samp_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product_service_base_product_version" */
export type Product_Service_Base_Product_Version_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Service_Base_Product_Version_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Service_Base_Product_Version_Stream_Cursor_Value_Input = {
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  is_current_version?: Maybe<Scalars["Boolean"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** aggregate sum on columns */
export type Product_Service_Base_Product_Version_Sum_Fields = {
  __typename?: "product_service_base_product_version_sum_fields";
  base_product_id?: Maybe<Scalars["Int"]>;
  base_product_status_id?: Maybe<Scalars["Int"]>;
  base_product_version_status_id?: Maybe<Scalars["Int"]>;
  id?: Maybe<Scalars["Int"]>;
  version_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Sum_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** update columns of table "product_service.base_product_version" */
export enum Product_Service_Base_Product_Version_Update_Column {
  /** column name */
  BaseProductId = "base_product_id",
  /** column name */
  BaseProductStatusId = "base_product_status_id",
  /** column name */
  BaseProductVersionStatusId = "base_product_version_status_id",
  /** column name */
  ContentAttributes = "content_attributes",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  IsCurrentVersion = "is_current_version",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  PhysicalAttributes = "physical_attributes",
  /** column name */
  VersionId = "version_id",
}

export type Product_Service_Base_Product_Version_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Product_Service_Base_Product_Version_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Product_Service_Base_Product_Version_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Product_Service_Base_Product_Version_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Product_Service_Base_Product_Version_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Service_Base_Product_Version_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Product_Service_Base_Product_Version_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Service_Base_Product_Version_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Service_Base_Product_Version_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Service_Base_Product_Version_Var_Pop_Fields = {
  __typename?: "product_service_base_product_version_var_pop_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Var_Pop_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Service_Base_Product_Version_Var_Samp_Fields = {
  __typename?: "product_service_base_product_version_var_samp_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Var_Samp_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Service_Base_Product_Version_Variance_Fields = {
  __typename?: "product_service_base_product_version_variance_fields";
  base_product_id?: Maybe<Scalars["Float"]>;
  base_product_status_id?: Maybe<Scalars["Float"]>;
  base_product_version_status_id?: Maybe<Scalars["Float"]>;
  id?: Maybe<Scalars["Float"]>;
  version_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "product_service.base_product_version" */
export type Product_Service_Base_Product_Version_Variance_Order_By = {
  base_product_id?: Maybe<Order_By>;
  base_product_status_id?: Maybe<Order_By>;
  base_product_version_status_id?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  version_id?: Maybe<Order_By>;
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_Sku = {
  __typename?: "product_service_sku";
  artworks: Scalars["jsonb"];
  /** An object relationship */
  base_product_version: Product_Service_Base_Product_Version;
  base_product_version_id: Scalars["Int"];
  client_name: Scalars["String"];
  content_attributes: Scalars["jsonb"];
  created_at: Scalars["timestamptz"];
  created_by_user_uid: Scalars["String"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  delivery_costs?: Maybe<Array<Scalars["jsonb"]>>;
  hash: Scalars["String"];
  id: Scalars["uuid"];
  image_url?: Maybe<Scalars["String"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  manufacturing_lead_time?: Maybe<Scalars["jsonb"]>;
  pack_sizes?: Maybe<Array<Scalars["Int"]>>;
  physical_attributes: Scalars["jsonb"];
  /** An array relationship */
  sku_orgs: Array<Product_Service_Sku_Org>;
  /** An aggregate relationship */
  sku_orgs_aggregate: Product_Service_Sku_Org_Aggregate;
  /** An object relationship */
  sku_source_type: Product_Service_Sku_Source_Type;
  sku_source_type_id: Scalars["Int"];
  supplier_name: Scalars["String"];
  updated_at: Scalars["timestamptz"];
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_SkuArtworksArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_SkuContent_AttributesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_SkuManufacturing_Lead_TimeArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_SkuPhysical_AttributesArgs = {
  path?: Maybe<Scalars["String"]>;
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_SkuSku_OrgsArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Org_Order_By>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

/** columns and relationships of "product_service.sku" */
export type Product_Service_SkuSku_Orgs_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Org_Order_By>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

/** aggregated selection of "product_service.sku" */
export type Product_Service_Sku_Aggregate = {
  __typename?: "product_service_sku_aggregate";
  aggregate?: Maybe<Product_Service_Sku_Aggregate_Fields>;
  nodes: Array<Product_Service_Sku>;
};

export type Product_Service_Sku_Aggregate_Bool_Exp = {
  bool_and?: Maybe<Product_Service_Sku_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<Product_Service_Sku_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<Product_Service_Sku_Aggregate_Bool_Exp_Count>;
};

export type Product_Service_Sku_Aggregate_Bool_Exp_Bool_And = {
  arguments: Product_Service_Sku_Select_Column_Product_Service_Sku_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Sku_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Service_Sku_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Product_Service_Sku_Select_Column_Product_Service_Sku_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Sku_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Product_Service_Sku_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Sku_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_service.sku" */
export type Product_Service_Sku_Aggregate_Fields = {
  __typename?: "product_service_sku_aggregate_fields";
  avg?: Maybe<Product_Service_Sku_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Service_Sku_Max_Fields>;
  min?: Maybe<Product_Service_Sku_Min_Fields>;
  stddev?: Maybe<Product_Service_Sku_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Service_Sku_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Service_Sku_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Service_Sku_Sum_Fields>;
  var_pop?: Maybe<Product_Service_Sku_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Service_Sku_Var_Samp_Fields>;
  variance?: Maybe<Product_Service_Sku_Variance_Fields>;
};

/** aggregate fields of "product_service.sku" */
export type Product_Service_Sku_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_service.sku" */
export type Product_Service_Sku_Aggregate_Order_By = {
  avg?: Maybe<Product_Service_Sku_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Service_Sku_Max_Order_By>;
  min?: Maybe<Product_Service_Sku_Min_Order_By>;
  stddev?: Maybe<Product_Service_Sku_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Service_Sku_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Service_Sku_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Service_Sku_Sum_Order_By>;
  var_pop?: Maybe<Product_Service_Sku_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Service_Sku_Var_Samp_Order_By>;
  variance?: Maybe<Product_Service_Sku_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Product_Service_Sku_Append_Input = {
  artworks?: Maybe<Scalars["jsonb"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  manufacturing_lead_time?: Maybe<Scalars["jsonb"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
};

/** input type for inserting array relation for remote table "product_service.sku" */
export type Product_Service_Sku_Arr_Rel_Insert_Input = {
  data: Array<Product_Service_Sku_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Sku_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Service_Sku_Avg_Fields = {
  __typename?: "product_service_sku_avg_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "product_service.sku" */
export type Product_Service_Sku_Avg_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_service.sku". All fields are combined with a logical 'AND'. */
export type Product_Service_Sku_Bool_Exp = {
  _and?: Maybe<Array<Product_Service_Sku_Bool_Exp>>;
  _not?: Maybe<Product_Service_Sku_Bool_Exp>;
  _or?: Maybe<Array<Product_Service_Sku_Bool_Exp>>;
  artworks?: Maybe<Jsonb_Comparison_Exp>;
  base_product_version?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
  base_product_version_id?: Maybe<Int_Comparison_Exp>;
  client_name?: Maybe<String_Comparison_Exp>;
  content_attributes?: Maybe<Jsonb_Comparison_Exp>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  created_by_user_uid?: Maybe<String_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  delivery_costs?: Maybe<Jsonb_Array_Comparison_Exp>;
  hash?: Maybe<String_Comparison_Exp>;
  id?: Maybe<Uuid_Comparison_Exp>;
  image_url?: Maybe<String_Comparison_Exp>;
  is_draft?: Maybe<Boolean_Comparison_Exp>;
  manufacturing_lead_time?: Maybe<Jsonb_Comparison_Exp>;
  pack_sizes?: Maybe<Int_Array_Comparison_Exp>;
  physical_attributes?: Maybe<Jsonb_Comparison_Exp>;
  sku_orgs?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
  sku_orgs_aggregate?: Maybe<Product_Service_Sku_Org_Aggregate_Bool_Exp>;
  sku_source_type?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
  sku_source_type_id?: Maybe<Int_Comparison_Exp>;
  supplier_name?: Maybe<String_Comparison_Exp>;
  updated_at?: Maybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_service.sku" */
export enum Product_Service_Sku_Constraint {
  /** unique or primary key constraint on columns "hash" */
  SkuHashKey = "sku_hash_key",
  /** unique or primary key constraint on columns "id" */
  SkuPkey = "sku_pkey",
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Product_Service_Sku_Delete_At_Path_Input = {
  artworks?: Maybe<Array<Scalars["String"]>>;
  content_attributes?: Maybe<Array<Scalars["String"]>>;
  manufacturing_lead_time?: Maybe<Array<Scalars["String"]>>;
  physical_attributes?: Maybe<Array<Scalars["String"]>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Product_Service_Sku_Delete_Elem_Input = {
  artworks?: Maybe<Scalars["Int"]>;
  content_attributes?: Maybe<Scalars["Int"]>;
  manufacturing_lead_time?: Maybe<Scalars["Int"]>;
  physical_attributes?: Maybe<Scalars["Int"]>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Product_Service_Sku_Delete_Key_Input = {
  artworks?: Maybe<Scalars["String"]>;
  content_attributes?: Maybe<Scalars["String"]>;
  manufacturing_lead_time?: Maybe<Scalars["String"]>;
  physical_attributes?: Maybe<Scalars["String"]>;
};

/** input type for incrementing numeric columns in table "product_service.sku" */
export type Product_Service_Sku_Inc_Input = {
  base_product_version_id?: Maybe<Scalars["Int"]>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "product_service.sku" */
export type Product_Service_Sku_Insert_Input = {
  artworks?: Maybe<Scalars["jsonb"]>;
  base_product_version?: Maybe<Product_Service_Base_Product_Version_Obj_Rel_Insert_Input>;
  base_product_version_id?: Maybe<Scalars["Int"]>;
  client_name?: Maybe<Scalars["String"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_uid?: Maybe<Scalars["String"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  delivery_costs?: Maybe<Array<Scalars["jsonb"]>>;
  hash?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  manufacturing_lead_time?: Maybe<Scalars["jsonb"]>;
  pack_sizes?: Maybe<Array<Scalars["Int"]>>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
  sku_orgs?: Maybe<Product_Service_Sku_Org_Arr_Rel_Insert_Input>;
  sku_source_type?: Maybe<Product_Service_Sku_Source_Type_Obj_Rel_Insert_Input>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
  supplier_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate max on columns */
export type Product_Service_Sku_Max_Fields = {
  __typename?: "product_service_sku_max_fields";
  base_product_version_id?: Maybe<Scalars["Int"]>;
  client_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_uid?: Maybe<Scalars["String"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  delivery_costs?: Maybe<Array<Scalars["jsonb"]>>;
  hash?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  pack_sizes?: Maybe<Array<Scalars["Int"]>>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
  supplier_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by max() on columns of table "product_service.sku" */
export type Product_Service_Sku_Max_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  client_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_user_uid?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  delivery_costs?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  pack_sizes?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
  supplier_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Service_Sku_Min_Fields = {
  __typename?: "product_service_sku_min_fields";
  base_product_version_id?: Maybe<Scalars["Int"]>;
  client_name?: Maybe<Scalars["String"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_uid?: Maybe<Scalars["String"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  delivery_costs?: Maybe<Array<Scalars["jsonb"]>>;
  hash?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  pack_sizes?: Maybe<Array<Scalars["Int"]>>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
  supplier_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** order by min() on columns of table "product_service.sku" */
export type Product_Service_Sku_Min_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  client_name?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_user_uid?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  delivery_costs?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  pack_sizes?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
  supplier_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_service.sku" */
export type Product_Service_Sku_Mutation_Response = {
  __typename?: "product_service_sku_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Service_Sku>;
};

/** input type for inserting object relation for remote table "product_service.sku" */
export type Product_Service_Sku_Obj_Rel_Insert_Input = {
  data: Product_Service_Sku_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Sku_On_Conflict>;
};

/** on_conflict condition type for table "product_service.sku" */
export type Product_Service_Sku_On_Conflict = {
  constraint: Product_Service_Sku_Constraint;
  update_columns?: Array<Product_Service_Sku_Update_Column>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

/** Ordering options when selecting data from "product_service.sku". */
export type Product_Service_Sku_Order_By = {
  artworks?: Maybe<Order_By>;
  base_product_version?: Maybe<Product_Service_Base_Product_Version_Order_By>;
  base_product_version_id?: Maybe<Order_By>;
  client_name?: Maybe<Order_By>;
  content_attributes?: Maybe<Order_By>;
  created_at?: Maybe<Order_By>;
  created_by_user_uid?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  delivery_costs?: Maybe<Order_By>;
  hash?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  image_url?: Maybe<Order_By>;
  is_draft?: Maybe<Order_By>;
  manufacturing_lead_time?: Maybe<Order_By>;
  pack_sizes?: Maybe<Order_By>;
  physical_attributes?: Maybe<Order_By>;
  sku_orgs_aggregate?: Maybe<Product_Service_Sku_Org_Aggregate_Order_By>;
  sku_source_type?: Maybe<Product_Service_Sku_Source_Type_Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
  supplier_name?: Maybe<Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** columns and relationships of "product_service.sku_org" */
export type Product_Service_Sku_Org = {
  __typename?: "product_service_sku_org";
  created_at: Scalars["timestamptz"];
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id: Scalars["Int"];
  last_updated: Scalars["timestamptz"];
  org_id: Scalars["String"];
  /** An object relationship */
  sku: Product_Service_Sku;
  sku_id: Scalars["uuid"];
};

/** aggregated selection of "product_service.sku_org" */
export type Product_Service_Sku_Org_Aggregate = {
  __typename?: "product_service_sku_org_aggregate";
  aggregate?: Maybe<Product_Service_Sku_Org_Aggregate_Fields>;
  nodes: Array<Product_Service_Sku_Org>;
};

export type Product_Service_Sku_Org_Aggregate_Bool_Exp = {
  count?: Maybe<Product_Service_Sku_Org_Aggregate_Bool_Exp_Count>;
};

export type Product_Service_Sku_Org_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
  filter?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "product_service.sku_org" */
export type Product_Service_Sku_Org_Aggregate_Fields = {
  __typename?: "product_service_sku_org_aggregate_fields";
  avg?: Maybe<Product_Service_Sku_Org_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Service_Sku_Org_Max_Fields>;
  min?: Maybe<Product_Service_Sku_Org_Min_Fields>;
  stddev?: Maybe<Product_Service_Sku_Org_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Service_Sku_Org_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Service_Sku_Org_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Service_Sku_Org_Sum_Fields>;
  var_pop?: Maybe<Product_Service_Sku_Org_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Service_Sku_Org_Var_Samp_Fields>;
  variance?: Maybe<Product_Service_Sku_Org_Variance_Fields>;
};

/** aggregate fields of "product_service.sku_org" */
export type Product_Service_Sku_Org_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** order by aggregate values of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Aggregate_Order_By = {
  avg?: Maybe<Product_Service_Sku_Org_Avg_Order_By>;
  count?: Maybe<Order_By>;
  max?: Maybe<Product_Service_Sku_Org_Max_Order_By>;
  min?: Maybe<Product_Service_Sku_Org_Min_Order_By>;
  stddev?: Maybe<Product_Service_Sku_Org_Stddev_Order_By>;
  stddev_pop?: Maybe<Product_Service_Sku_Org_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<Product_Service_Sku_Org_Stddev_Samp_Order_By>;
  sum?: Maybe<Product_Service_Sku_Org_Sum_Order_By>;
  var_pop?: Maybe<Product_Service_Sku_Org_Var_Pop_Order_By>;
  var_samp?: Maybe<Product_Service_Sku_Org_Var_Samp_Order_By>;
  variance?: Maybe<Product_Service_Sku_Org_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "product_service.sku_org" */
export type Product_Service_Sku_Org_Arr_Rel_Insert_Input = {
  data: Array<Product_Service_Sku_Org_Insert_Input>;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Sku_Org_On_Conflict>;
};

/** aggregate avg on columns */
export type Product_Service_Sku_Org_Avg_Fields = {
  __typename?: "product_service_sku_org_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by avg() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Avg_Order_By = {
  id?: Maybe<Order_By>;
};

/** Boolean expression to filter rows from the table "product_service.sku_org". All fields are combined with a logical 'AND'. */
export type Product_Service_Sku_Org_Bool_Exp = {
  _and?: Maybe<Array<Product_Service_Sku_Org_Bool_Exp>>;
  _not?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
  _or?: Maybe<Array<Product_Service_Sku_Org_Bool_Exp>>;
  created_at?: Maybe<Timestamptz_Comparison_Exp>;
  deleted_at?: Maybe<Timestamptz_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  last_updated?: Maybe<Timestamptz_Comparison_Exp>;
  org_id?: Maybe<String_Comparison_Exp>;
  sku?: Maybe<Product_Service_Sku_Bool_Exp>;
  sku_id?: Maybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_service.sku_org" */
export enum Product_Service_Sku_Org_Constraint {
  /** unique or primary key constraint on columns "org_id", "sku_id" */
  SkuOrgOrgIdAndSkuIdx = "sku_org_org_id_and_sku_idx",
  /** unique or primary key constraint on columns "id" */
  SkuOrgPkey = "sku_org_pkey",
}

/** input type for incrementing numeric columns in table "product_service.sku_org" */
export type Product_Service_Sku_Org_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "product_service.sku_org" */
export type Product_Service_Sku_Org_Insert_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  org_id?: Maybe<Scalars["String"]>;
  sku?: Maybe<Product_Service_Sku_Obj_Rel_Insert_Input>;
  sku_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate max on columns */
export type Product_Service_Sku_Org_Max_Fields = {
  __typename?: "product_service_sku_org_max_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  org_id?: Maybe<Scalars["String"]>;
  sku_id?: Maybe<Scalars["uuid"]>;
};

/** order by max() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Max_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  org_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** aggregate min on columns */
export type Product_Service_Sku_Org_Min_Fields = {
  __typename?: "product_service_sku_org_min_fields";
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  org_id?: Maybe<Scalars["String"]>;
  sku_id?: Maybe<Scalars["uuid"]>;
};

/** order by min() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Min_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  org_id?: Maybe<Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** response of any mutation on the table "product_service.sku_org" */
export type Product_Service_Sku_Org_Mutation_Response = {
  __typename?: "product_service_sku_org_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Service_Sku_Org>;
};

/** on_conflict condition type for table "product_service.sku_org" */
export type Product_Service_Sku_Org_On_Conflict = {
  constraint: Product_Service_Sku_Org_Constraint;
  update_columns?: Array<Product_Service_Sku_Org_Update_Column>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

/** Ordering options when selecting data from "product_service.sku_org". */
export type Product_Service_Sku_Org_Order_By = {
  created_at?: Maybe<Order_By>;
  deleted_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  last_updated?: Maybe<Order_By>;
  org_id?: Maybe<Order_By>;
  sku?: Maybe<Product_Service_Sku_Order_By>;
  sku_id?: Maybe<Order_By>;
};

/** primary key columns input for table: product_service.sku_org */
export type Product_Service_Sku_Org_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "product_service.sku_org" */
export enum Product_Service_Sku_Org_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrgId = "org_id",
  /** column name */
  SkuId = "sku_id",
}

/** input type for updating data in table "product_service.sku_org" */
export type Product_Service_Sku_Org_Set_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  org_id?: Maybe<Scalars["String"]>;
  sku_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate stddev on columns */
export type Product_Service_Sku_Org_Stddev_Fields = {
  __typename?: "product_service_sku_org_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Stddev_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Service_Sku_Org_Stddev_Pop_Fields = {
  __typename?: "product_service_sku_org_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Stddev_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Service_Sku_Org_Stddev_Samp_Fields = {
  __typename?: "product_service_sku_org_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Stddev_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product_service_sku_org" */
export type Product_Service_Sku_Org_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Service_Sku_Org_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Service_Sku_Org_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamptz"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  id?: Maybe<Scalars["Int"]>;
  last_updated?: Maybe<Scalars["timestamptz"]>;
  org_id?: Maybe<Scalars["String"]>;
  sku_id?: Maybe<Scalars["uuid"]>;
};

/** aggregate sum on columns */
export type Product_Service_Sku_Org_Sum_Fields = {
  __typename?: "product_service_sku_org_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Sum_Order_By = {
  id?: Maybe<Order_By>;
};

/** update columns of table "product_service.sku_org" */
export enum Product_Service_Sku_Org_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  Id = "id",
  /** column name */
  LastUpdated = "last_updated",
  /** column name */
  OrgId = "org_id",
  /** column name */
  SkuId = "sku_id",
}

export type Product_Service_Sku_Org_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Service_Sku_Org_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Service_Sku_Org_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Service_Sku_Org_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Service_Sku_Org_Var_Pop_Fields = {
  __typename?: "product_service_sku_org_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Var_Pop_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Service_Sku_Org_Var_Samp_Fields = {
  __typename?: "product_service_sku_org_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Var_Samp_Order_By = {
  id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Service_Sku_Org_Variance_Fields = {
  __typename?: "product_service_sku_org_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "product_service.sku_org" */
export type Product_Service_Sku_Org_Variance_Order_By = {
  id?: Maybe<Order_By>;
};

/** primary key columns input for table: product_service.sku */
export type Product_Service_Sku_Pk_Columns_Input = {
  id: Scalars["uuid"];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Product_Service_Sku_Prepend_Input = {
  artworks?: Maybe<Scalars["jsonb"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  manufacturing_lead_time?: Maybe<Scalars["jsonb"]>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
};

/** select columns of table "product_service.sku" */
export enum Product_Service_Sku_Select_Column {
  /** column name */
  Artworks = "artworks",
  /** column name */
  BaseProductVersionId = "base_product_version_id",
  /** column name */
  ClientName = "client_name",
  /** column name */
  ContentAttributes = "content_attributes",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserUid = "created_by_user_uid",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  DeliveryCosts = "delivery_costs",
  /** column name */
  Hash = "hash",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  IsDraft = "is_draft",
  /** column name */
  ManufacturingLeadTime = "manufacturing_lead_time",
  /** column name */
  PackSizes = "pack_sizes",
  /** column name */
  PhysicalAttributes = "physical_attributes",
  /** column name */
  SkuSourceTypeId = "sku_source_type_id",
  /** column name */
  SupplierName = "supplier_name",
  /** column name */
  UpdatedAt = "updated_at",
}

/** select "product_service_sku_aggregate_bool_exp_bool_and_arguments_columns" columns of table "product_service.sku" */
export enum Product_Service_Sku_Select_Column_Product_Service_Sku_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsDraft = "is_draft",
}

/** select "product_service_sku_aggregate_bool_exp_bool_or_arguments_columns" columns of table "product_service.sku" */
export enum Product_Service_Sku_Select_Column_Product_Service_Sku_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsDraft = "is_draft",
}

/** input type for updating data in table "product_service.sku" */
export type Product_Service_Sku_Set_Input = {
  artworks?: Maybe<Scalars["jsonb"]>;
  base_product_version_id?: Maybe<Scalars["Int"]>;
  client_name?: Maybe<Scalars["String"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_uid?: Maybe<Scalars["String"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  delivery_costs?: Maybe<Array<Scalars["jsonb"]>>;
  hash?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  manufacturing_lead_time?: Maybe<Scalars["jsonb"]>;
  pack_sizes?: Maybe<Array<Scalars["Int"]>>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
  supplier_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** columns and relationships of "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type = {
  __typename?: "product_service_sku_source_type";
  created_at: Scalars["timestamp"];
  id: Scalars["Int"];
  sku_source_type: Scalars["String"];
  /** An array relationship */
  skus: Array<Product_Service_Sku>;
  /** An aggregate relationship */
  skus_aggregate: Product_Service_Sku_Aggregate;
  updated_at: Scalars["timestamp"];
};

/** columns and relationships of "product_service.sku_source_type" */
export type Product_Service_Sku_Source_TypeSkusArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

/** columns and relationships of "product_service.sku_source_type" */
export type Product_Service_Sku_Source_TypeSkus_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

/** aggregated selection of "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Aggregate = {
  __typename?: "product_service_sku_source_type_aggregate";
  aggregate?: Maybe<Product_Service_Sku_Source_Type_Aggregate_Fields>;
  nodes: Array<Product_Service_Sku_Source_Type>;
};

/** aggregate fields of "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Aggregate_Fields = {
  __typename?: "product_service_sku_source_type_aggregate_fields";
  avg?: Maybe<Product_Service_Sku_Source_Type_Avg_Fields>;
  count: Scalars["Int"];
  max?: Maybe<Product_Service_Sku_Source_Type_Max_Fields>;
  min?: Maybe<Product_Service_Sku_Source_Type_Min_Fields>;
  stddev?: Maybe<Product_Service_Sku_Source_Type_Stddev_Fields>;
  stddev_pop?: Maybe<Product_Service_Sku_Source_Type_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Product_Service_Sku_Source_Type_Stddev_Samp_Fields>;
  sum?: Maybe<Product_Service_Sku_Source_Type_Sum_Fields>;
  var_pop?: Maybe<Product_Service_Sku_Source_Type_Var_Pop_Fields>;
  var_samp?: Maybe<Product_Service_Sku_Source_Type_Var_Samp_Fields>;
  variance?: Maybe<Product_Service_Sku_Source_Type_Variance_Fields>;
};

/** aggregate fields of "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<Product_Service_Sku_Source_Type_Select_Column>>;
  distinct?: Maybe<Scalars["Boolean"]>;
};

/** aggregate avg on columns */
export type Product_Service_Sku_Source_Type_Avg_Fields = {
  __typename?: "product_service_sku_source_type_avg_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Boolean expression to filter rows from the table "product_service.sku_source_type". All fields are combined with a logical 'AND'. */
export type Product_Service_Sku_Source_Type_Bool_Exp = {
  _and?: Maybe<Array<Product_Service_Sku_Source_Type_Bool_Exp>>;
  _not?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
  _or?: Maybe<Array<Product_Service_Sku_Source_Type_Bool_Exp>>;
  created_at?: Maybe<Timestamp_Comparison_Exp>;
  id?: Maybe<Int_Comparison_Exp>;
  sku_source_type?: Maybe<String_Comparison_Exp>;
  skus?: Maybe<Product_Service_Sku_Bool_Exp>;
  skus_aggregate?: Maybe<Product_Service_Sku_Aggregate_Bool_Exp>;
  updated_at?: Maybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "product_service.sku_source_type" */
export enum Product_Service_Sku_Source_Type_Constraint {
  /** unique or primary key constraint on columns "id" */
  SkuSourceTypePkey = "sku_source_type_pkey",
}

/** input type for incrementing numeric columns in table "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Inc_Input = {
  id?: Maybe<Scalars["Int"]>;
};

/** input type for inserting data into table "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Insert_Input = {
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  sku_source_type?: Maybe<Scalars["String"]>;
  skus?: Maybe<Product_Service_Sku_Arr_Rel_Insert_Input>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** aggregate max on columns */
export type Product_Service_Sku_Source_Type_Max_Fields = {
  __typename?: "product_service_sku_source_type_max_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  sku_source_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** aggregate min on columns */
export type Product_Service_Sku_Source_Type_Min_Fields = {
  __typename?: "product_service_sku_source_type_min_fields";
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  sku_source_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** response of any mutation on the table "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Mutation_Response = {
  __typename?: "product_service_sku_source_type_mutation_response";
  /** number of rows affected by the mutation */
  affected_rows: Scalars["Int"];
  /** data from the rows affected by the mutation */
  returning: Array<Product_Service_Sku_Source_Type>;
};

/** input type for inserting object relation for remote table "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Obj_Rel_Insert_Input = {
  data: Product_Service_Sku_Source_Type_Insert_Input;
  /** upsert condition */
  on_conflict?: Maybe<Product_Service_Sku_Source_Type_On_Conflict>;
};

/** on_conflict condition type for table "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_On_Conflict = {
  constraint: Product_Service_Sku_Source_Type_Constraint;
  update_columns?: Array<Product_Service_Sku_Source_Type_Update_Column>;
  where?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
};

/** Ordering options when selecting data from "product_service.sku_source_type". */
export type Product_Service_Sku_Source_Type_Order_By = {
  created_at?: Maybe<Order_By>;
  id?: Maybe<Order_By>;
  sku_source_type?: Maybe<Order_By>;
  skus_aggregate?: Maybe<Product_Service_Sku_Aggregate_Order_By>;
  updated_at?: Maybe<Order_By>;
};

/** primary key columns input for table: product_service.sku_source_type */
export type Product_Service_Sku_Source_Type_Pk_Columns_Input = {
  id: Scalars["Int"];
};

/** select columns of table "product_service.sku_source_type" */
export enum Product_Service_Sku_Source_Type_Select_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SkuSourceType = "sku_source_type",
  /** column name */
  UpdatedAt = "updated_at",
}

/** input type for updating data in table "product_service.sku_source_type" */
export type Product_Service_Sku_Source_Type_Set_Input = {
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  sku_source_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** aggregate stddev on columns */
export type Product_Service_Sku_Source_Type_Stddev_Fields = {
  __typename?: "product_service_sku_source_type_stddev_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_pop on columns */
export type Product_Service_Sku_Source_Type_Stddev_Pop_Fields = {
  __typename?: "product_service_sku_source_type_stddev_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev_samp on columns */
export type Product_Service_Sku_Source_Type_Stddev_Samp_Fields = {
  __typename?: "product_service_sku_source_type_stddev_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** Streaming cursor of the table "product_service_sku_source_type" */
export type Product_Service_Sku_Source_Type_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Service_Sku_Source_Type_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Service_Sku_Source_Type_Stream_Cursor_Value_Input = {
  created_at?: Maybe<Scalars["timestamp"]>;
  id?: Maybe<Scalars["Int"]>;
  sku_source_type?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamp"]>;
};

/** aggregate sum on columns */
export type Product_Service_Sku_Source_Type_Sum_Fields = {
  __typename?: "product_service_sku_source_type_sum_fields";
  id?: Maybe<Scalars["Int"]>;
};

/** update columns of table "product_service.sku_source_type" */
export enum Product_Service_Sku_Source_Type_Update_Column {
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  Id = "id",
  /** column name */
  SkuSourceType = "sku_source_type",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Product_Service_Sku_Source_Type_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Service_Sku_Source_Type_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Service_Sku_Source_Type_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Service_Sku_Source_Type_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Service_Sku_Source_Type_Var_Pop_Fields = {
  __typename?: "product_service_sku_source_type_var_pop_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate var_samp on columns */
export type Product_Service_Sku_Source_Type_Var_Samp_Fields = {
  __typename?: "product_service_sku_source_type_var_samp_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate variance on columns */
export type Product_Service_Sku_Source_Type_Variance_Fields = {
  __typename?: "product_service_sku_source_type_variance_fields";
  id?: Maybe<Scalars["Float"]>;
};

/** aggregate stddev on columns */
export type Product_Service_Sku_Stddev_Fields = {
  __typename?: "product_service_sku_stddev_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev() on columns of table "product_service.sku" */
export type Product_Service_Sku_Stddev_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Product_Service_Sku_Stddev_Pop_Fields = {
  __typename?: "product_service_sku_stddev_pop_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_pop() on columns of table "product_service.sku" */
export type Product_Service_Sku_Stddev_Pop_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Product_Service_Sku_Stddev_Samp_Fields = {
  __typename?: "product_service_sku_stddev_samp_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by stddev_samp() on columns of table "product_service.sku" */
export type Product_Service_Sku_Stddev_Samp_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** Streaming cursor of the table "product_service_sku" */
export type Product_Service_Sku_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Product_Service_Sku_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Product_Service_Sku_Stream_Cursor_Value_Input = {
  artworks?: Maybe<Scalars["jsonb"]>;
  base_product_version_id?: Maybe<Scalars["Int"]>;
  client_name?: Maybe<Scalars["String"]>;
  content_attributes?: Maybe<Scalars["jsonb"]>;
  created_at?: Maybe<Scalars["timestamptz"]>;
  created_by_user_uid?: Maybe<Scalars["String"]>;
  deleted_at?: Maybe<Scalars["timestamptz"]>;
  delivery_costs?: Maybe<Array<Scalars["jsonb"]>>;
  hash?: Maybe<Scalars["String"]>;
  id?: Maybe<Scalars["uuid"]>;
  image_url?: Maybe<Scalars["String"]>;
  is_draft?: Maybe<Scalars["Boolean"]>;
  manufacturing_lead_time?: Maybe<Scalars["jsonb"]>;
  pack_sizes?: Maybe<Array<Scalars["Int"]>>;
  physical_attributes?: Maybe<Scalars["jsonb"]>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
  supplier_name?: Maybe<Scalars["String"]>;
  updated_at?: Maybe<Scalars["timestamptz"]>;
};

/** aggregate sum on columns */
export type Product_Service_Sku_Sum_Fields = {
  __typename?: "product_service_sku_sum_fields";
  base_product_version_id?: Maybe<Scalars["Int"]>;
  sku_source_type_id?: Maybe<Scalars["Int"]>;
};

/** order by sum() on columns of table "product_service.sku" */
export type Product_Service_Sku_Sum_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** update columns of table "product_service.sku" */
export enum Product_Service_Sku_Update_Column {
  /** column name */
  Artworks = "artworks",
  /** column name */
  BaseProductVersionId = "base_product_version_id",
  /** column name */
  ClientName = "client_name",
  /** column name */
  ContentAttributes = "content_attributes",
  /** column name */
  CreatedAt = "created_at",
  /** column name */
  CreatedByUserUid = "created_by_user_uid",
  /** column name */
  DeletedAt = "deleted_at",
  /** column name */
  DeliveryCosts = "delivery_costs",
  /** column name */
  Hash = "hash",
  /** column name */
  Id = "id",
  /** column name */
  ImageUrl = "image_url",
  /** column name */
  IsDraft = "is_draft",
  /** column name */
  ManufacturingLeadTime = "manufacturing_lead_time",
  /** column name */
  PackSizes = "pack_sizes",
  /** column name */
  PhysicalAttributes = "physical_attributes",
  /** column name */
  SkuSourceTypeId = "sku_source_type_id",
  /** column name */
  SupplierName = "supplier_name",
  /** column name */
  UpdatedAt = "updated_at",
}

export type Product_Service_Sku_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: Maybe<Product_Service_Sku_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: Maybe<Product_Service_Sku_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: Maybe<Product_Service_Sku_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: Maybe<Product_Service_Sku_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: Maybe<Product_Service_Sku_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: Maybe<Product_Service_Sku_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: Maybe<Product_Service_Sku_Set_Input>;
  /** filter the rows which have to be updated */
  where: Product_Service_Sku_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Product_Service_Sku_Var_Pop_Fields = {
  __typename?: "product_service_sku_var_pop_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_pop() on columns of table "product_service.sku" */
export type Product_Service_Sku_Var_Pop_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Product_Service_Sku_Var_Samp_Fields = {
  __typename?: "product_service_sku_var_samp_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by var_samp() on columns of table "product_service.sku" */
export type Product_Service_Sku_Var_Samp_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

/** aggregate variance on columns */
export type Product_Service_Sku_Variance_Fields = {
  __typename?: "product_service_sku_variance_fields";
  base_product_version_id?: Maybe<Scalars["Float"]>;
  sku_source_type_id?: Maybe<Scalars["Float"]>;
};

/** order by variance() on columns of table "product_service.sku" */
export type Product_Service_Sku_Variance_Order_By = {
  base_product_version_id?: Maybe<Order_By>;
  sku_source_type_id?: Maybe<Order_By>;
};

export type Query_Root = {
  __typename?: "query_root";
  /** fetch data from the table: "product_service.base_product" */
  product_service_base_product: Array<Product_Service_Base_Product>;
  /** fetch aggregated fields from the table: "product_service.base_product" */
  product_service_base_product_aggregate: Product_Service_Base_Product_Aggregate;
  /** fetch data from the table: "product_service.base_product" using primary key columns */
  product_service_base_product_by_pk?: Maybe<Product_Service_Base_Product>;
  /** fetch data from the table: "product_service.base_product_status" */
  product_service_base_product_status: Array<Product_Service_Base_Product_Status>;
  /** fetch aggregated fields from the table: "product_service.base_product_status" */
  product_service_base_product_status_aggregate: Product_Service_Base_Product_Status_Aggregate;
  /** fetch data from the table: "product_service.base_product_status" using primary key columns */
  product_service_base_product_status_by_pk?: Maybe<Product_Service_Base_Product_Status>;
  /** fetch data from the table: "product_service.base_product_version" */
  product_service_base_product_version: Array<Product_Service_Base_Product_Version>;
  /** fetch aggregated fields from the table: "product_service.base_product_version" */
  product_service_base_product_version_aggregate: Product_Service_Base_Product_Version_Aggregate;
  /** fetch data from the table: "product_service.base_product_version" using primary key columns */
  product_service_base_product_version_by_pk?: Maybe<Product_Service_Base_Product_Version>;
  /** fetch data from the table: "product_service.sku" */
  product_service_sku: Array<Product_Service_Sku>;
  /** fetch aggregated fields from the table: "product_service.sku" */
  product_service_sku_aggregate: Product_Service_Sku_Aggregate;
  /** fetch data from the table: "product_service.sku" using primary key columns */
  product_service_sku_by_pk?: Maybe<Product_Service_Sku>;
  /** fetch data from the table: "product_service.sku_org" */
  product_service_sku_org: Array<Product_Service_Sku_Org>;
  /** fetch aggregated fields from the table: "product_service.sku_org" */
  product_service_sku_org_aggregate: Product_Service_Sku_Org_Aggregate;
  /** fetch data from the table: "product_service.sku_org" using primary key columns */
  product_service_sku_org_by_pk?: Maybe<Product_Service_Sku_Org>;
  /** fetch data from the table: "product_service.sku_source_type" */
  product_service_sku_source_type: Array<Product_Service_Sku_Source_Type>;
  /** fetch aggregated fields from the table: "product_service.sku_source_type" */
  product_service_sku_source_type_aggregate: Product_Service_Sku_Source_Type_Aggregate;
  /** fetch data from the table: "product_service.sku_source_type" using primary key columns */
  product_service_sku_source_type_by_pk?: Maybe<Product_Service_Sku_Source_Type>;
};

export type Query_RootProduct_Service_Base_ProductArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Bool_Exp>;
};

export type Query_RootProduct_Service_Base_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Bool_Exp>;
};

export type Query_RootProduct_Service_Base_Product_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootProduct_Service_Base_Product_StatusArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Status_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
};

export type Query_RootProduct_Service_Base_Product_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Status_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
};

export type Query_RootProduct_Service_Base_Product_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootProduct_Service_Base_Product_VersionArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

export type Query_RootProduct_Service_Base_Product_Version_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

export type Query_RootProduct_Service_Base_Product_Version_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootProduct_Service_SkuArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

export type Query_RootProduct_Service_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

export type Query_RootProduct_Service_Sku_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Query_RootProduct_Service_Sku_OrgArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Org_Order_By>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

export type Query_RootProduct_Service_Sku_Org_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Org_Order_By>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

export type Query_RootProduct_Service_Sku_Org_By_PkArgs = {
  id: Scalars["Int"];
};

export type Query_RootProduct_Service_Sku_Source_TypeArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Source_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Source_Type_Order_By>>;
  where?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
};

export type Query_RootProduct_Service_Sku_Source_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Source_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Source_Type_Order_By>>;
  where?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
};

export type Query_RootProduct_Service_Sku_Source_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_Root = {
  __typename?: "subscription_root";
  /** fetch data from the table: "product_service.base_product" */
  product_service_base_product: Array<Product_Service_Base_Product>;
  /** fetch aggregated fields from the table: "product_service.base_product" */
  product_service_base_product_aggregate: Product_Service_Base_Product_Aggregate;
  /** fetch data from the table: "product_service.base_product" using primary key columns */
  product_service_base_product_by_pk?: Maybe<Product_Service_Base_Product>;
  /** fetch data from the table: "product_service.base_product_status" */
  product_service_base_product_status: Array<Product_Service_Base_Product_Status>;
  /** fetch aggregated fields from the table: "product_service.base_product_status" */
  product_service_base_product_status_aggregate: Product_Service_Base_Product_Status_Aggregate;
  /** fetch data from the table: "product_service.base_product_status" using primary key columns */
  product_service_base_product_status_by_pk?: Maybe<Product_Service_Base_Product_Status>;
  /** fetch data from the table in a streaming manner: "product_service.base_product_status" */
  product_service_base_product_status_stream: Array<Product_Service_Base_Product_Status>;
  /** fetch data from the table in a streaming manner: "product_service.base_product" */
  product_service_base_product_stream: Array<Product_Service_Base_Product>;
  /** fetch data from the table: "product_service.base_product_version" */
  product_service_base_product_version: Array<Product_Service_Base_Product_Version>;
  /** fetch aggregated fields from the table: "product_service.base_product_version" */
  product_service_base_product_version_aggregate: Product_Service_Base_Product_Version_Aggregate;
  /** fetch data from the table: "product_service.base_product_version" using primary key columns */
  product_service_base_product_version_by_pk?: Maybe<Product_Service_Base_Product_Version>;
  /** fetch data from the table in a streaming manner: "product_service.base_product_version" */
  product_service_base_product_version_stream: Array<Product_Service_Base_Product_Version>;
  /** fetch data from the table: "product_service.sku" */
  product_service_sku: Array<Product_Service_Sku>;
  /** fetch aggregated fields from the table: "product_service.sku" */
  product_service_sku_aggregate: Product_Service_Sku_Aggregate;
  /** fetch data from the table: "product_service.sku" using primary key columns */
  product_service_sku_by_pk?: Maybe<Product_Service_Sku>;
  /** fetch data from the table: "product_service.sku_org" */
  product_service_sku_org: Array<Product_Service_Sku_Org>;
  /** fetch aggregated fields from the table: "product_service.sku_org" */
  product_service_sku_org_aggregate: Product_Service_Sku_Org_Aggregate;
  /** fetch data from the table: "product_service.sku_org" using primary key columns */
  product_service_sku_org_by_pk?: Maybe<Product_Service_Sku_Org>;
  /** fetch data from the table in a streaming manner: "product_service.sku_org" */
  product_service_sku_org_stream: Array<Product_Service_Sku_Org>;
  /** fetch data from the table: "product_service.sku_source_type" */
  product_service_sku_source_type: Array<Product_Service_Sku_Source_Type>;
  /** fetch aggregated fields from the table: "product_service.sku_source_type" */
  product_service_sku_source_type_aggregate: Product_Service_Sku_Source_Type_Aggregate;
  /** fetch data from the table: "product_service.sku_source_type" using primary key columns */
  product_service_sku_source_type_by_pk?: Maybe<Product_Service_Sku_Source_Type>;
  /** fetch data from the table in a streaming manner: "product_service.sku_source_type" */
  product_service_sku_source_type_stream: Array<Product_Service_Sku_Source_Type>;
  /** fetch data from the table in a streaming manner: "product_service.sku" */
  product_service_sku_stream: Array<Product_Service_Sku>;
};

export type Subscription_RootProduct_Service_Base_ProductArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_Service_Base_Product_StatusArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Status_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_Status_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Status_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Status_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_Status_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_Service_Base_Product_Status_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Service_Base_Product_Status_Stream_Cursor_Input>>;
  where?: Maybe<Product_Service_Base_Product_Status_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Service_Base_Product_Stream_Cursor_Input>>;
  where?: Maybe<Product_Service_Base_Product_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_VersionArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_Version_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Base_Product_Version_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Base_Product_Version_Order_By>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Base_Product_Version_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_Service_Base_Product_Version_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Service_Base_Product_Version_Stream_Cursor_Input>>;
  where?: Maybe<Product_Service_Base_Product_Version_Bool_Exp>;
};

export type Subscription_RootProduct_Service_SkuArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Order_By>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_By_PkArgs = {
  id: Scalars["uuid"];
};

export type Subscription_RootProduct_Service_Sku_OrgArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Org_Order_By>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_Org_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Org_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Org_Order_By>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_Org_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_Service_Sku_Org_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Service_Sku_Org_Stream_Cursor_Input>>;
  where?: Maybe<Product_Service_Sku_Org_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_Source_TypeArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Source_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Source_Type_Order_By>>;
  where?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_Source_Type_AggregateArgs = {
  distinct_on?: Maybe<Array<Product_Service_Sku_Source_Type_Select_Column>>;
  limit?: Maybe<Scalars["Int"]>;
  offset?: Maybe<Scalars["Int"]>;
  order_by?: Maybe<Array<Product_Service_Sku_Source_Type_Order_By>>;
  where?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_Source_Type_By_PkArgs = {
  id: Scalars["Int"];
};

export type Subscription_RootProduct_Service_Sku_Source_Type_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Service_Sku_Source_Type_Stream_Cursor_Input>>;
  where?: Maybe<Product_Service_Sku_Source_Type_Bool_Exp>;
};

export type Subscription_RootProduct_Service_Sku_StreamArgs = {
  batch_size: Scalars["Int"];
  cursor: Array<Maybe<Product_Service_Sku_Stream_Cursor_Input>>;
  where?: Maybe<Product_Service_Sku_Bool_Exp>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamp"]>;
  _gt?: Maybe<Scalars["timestamp"]>;
  _gte?: Maybe<Scalars["timestamp"]>;
  _in?: Maybe<Array<Scalars["timestamp"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamp"]>;
  _lte?: Maybe<Scalars["timestamp"]>;
  _neq?: Maybe<Scalars["timestamp"]>;
  _nin?: Maybe<Array<Scalars["timestamp"]>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars["timestamptz"]>;
  _gt?: Maybe<Scalars["timestamptz"]>;
  _gte?: Maybe<Scalars["timestamptz"]>;
  _in?: Maybe<Array<Scalars["timestamptz"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["timestamptz"]>;
  _lte?: Maybe<Scalars["timestamptz"]>;
  _neq?: Maybe<Scalars["timestamptz"]>;
  _nin?: Maybe<Array<Scalars["timestamptz"]>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: Maybe<Scalars["uuid"]>;
  _gt?: Maybe<Scalars["uuid"]>;
  _gte?: Maybe<Scalars["uuid"]>;
  _in?: Maybe<Array<Scalars["uuid"]>>;
  _is_null?: Maybe<Scalars["Boolean"]>;
  _lt?: Maybe<Scalars["uuid"]>;
  _lte?: Maybe<Scalars["uuid"]>;
  _neq?: Maybe<Scalars["uuid"]>;
  _nin?: Maybe<Array<Scalars["uuid"]>>;
};

export type GetAllSkusQueryVariables = Exact<{ [key: string]: never }>;

export type GetAllSkusQuery = {
  __typename?: "query_root";
  product_service_sku: Array<{
    __typename?: "product_service_sku";
    id: any;
    client_name: string;
    created_at: any;
    delivery_costs?: Maybe<Array<any>>;
    image_url?: Maybe<string>;
    manufacturing_lead_time?: Maybe<any>;
    pack_sizes?: Maybe<Array<number>>;
    sku_source_type_id: number;
    updated_at: any;
    sku_orgs: Array<{ __typename?: "product_service_sku_org"; org_id: string }>;
  }>;
};

export const GetAllSkusDocument = gql`
  query getAllSkus {
    product_service_sku(order_by: { created_at: desc }) {
      id
      client_name
      created_at
      delivery_costs
      image_url
      manufacturing_lead_time
      pack_sizes
      sku_orgs(where: { deleted_at: { _is_null: true } }) {
        org_id
      }
      sku_source_type_id
      updated_at
    }
  }
`;

/**
 * __useGetAllSkusQuery__
 *
 * To run a query within a React component, call `useGetAllSkusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAllSkusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAllSkusQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAllSkusQuery(
  baseOptions?: Apollo.QueryHookOptions<GetAllSkusQuery, GetAllSkusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetAllSkusQuery, GetAllSkusQueryVariables>(GetAllSkusDocument, options);
}
export function useGetAllSkusLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetAllSkusQuery, GetAllSkusQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetAllSkusQuery, GetAllSkusQueryVariables>(
    GetAllSkusDocument,
    options
  );
}
export type GetAllSkusQueryHookResult = ReturnType<typeof useGetAllSkusQuery>;
export type GetAllSkusLazyQueryHookResult = ReturnType<typeof useGetAllSkusLazyQuery>;
export type GetAllSkusQueryResult = Apollo.QueryResult<GetAllSkusQuery, GetAllSkusQueryVariables>;
