import { Formik, FormikErrors, FormikState } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { IconText } from "@sourceful/shared-components";

import Panel from "../../../../../../components/panel/panel";
import {
  ADMIN_ROLES,
  SOURCING_EDIT_ROLES,
} from "../../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../../redux/store/storeTypes";
import { SecondaryButton } from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import { FormButtonPair } from "../../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../../shared/components/wrappers/VisibleFor";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { QuotationCostTypeTranslationLine } from "../../shared/components/QuotationCostTypeTranslationLine/QuotationCostTypeTranslationLine";
import { useQuotationCostLocales } from "../../shared/graphql/hooks/useQuotationCostLocales";
import { costRequiredOptions, getCostRequiredValue } from "../../shared/mappers";
import {
  AdminQuotationCostTypeTranslationInterface,
  emptyQuotationCostTranslation,
} from "../../shared/types";
import { UpdateQuotationCostTypeBody } from "../api/types";
import { fieldNames, initialFieldValues } from "../formValues";
import { useQuotationCostTypeById } from "../graphql/hooks/useQuotationCostTypeById";
import { mapTranslationToDropdownObject } from "../mappers";
import {
  updateQuotationCostTypeAction,
  updateQuotationCostTypeReset,
} from "../redux/actions/updateQuotationCostTypeAction";
import { UpdateQuotationCostTypeReducer } from "../redux/reducers/updateQuotationCostTypeReducer";
import { AdminQuotationCostTypeInterface } from "../types";
import { validationSchema } from "../validation";

interface Props {
  updateQuotationCostTypeState: UpdateQuotationCostTypeReducer;
  updateQuotationCostType: (body: UpdateQuotationCostTypeBody) => void;
  resetUpdateQuotationCostType: () => void;
}

const UpdateQuotationCostType = (props: Props) => {
  const { quotationCostTypeId } = useParams<{ quotationCostTypeId: string }>();

  const {
    quotationCostType,
    quotationCostTypeLoading,
    quotationCostTypeError,
    refetchQuotationCostType,
  } = useQuotationCostTypeById(Number(quotationCostTypeId));

  const { updateQuotationCostTypeState } = props;

  const { locales, localesLoading, localesError } = useQuotationCostLocales();

  const onSubmit = async (
    values: AdminQuotationCostTypeInterface,
    {
      resetForm,
    }: { resetForm: (nextState?: Partial<FormikState<AdminQuotationCostTypeInterface>>) => void }
  ) => {
    if (!quotationCostType) {
      return;
    }

    const translationLocales = values.quotation_cost_translations.map(
      translationLine => translationLine.locale!.label
    );

    if (translationLocales.length !== _.uniq(translationLocales).length) {
      toast.error("Please make sure each locale has at most one translation.");
      return;
    }

    const translation = Object.assign(
      {
        en_GB: {
          name: quotationCostType.quotation_cost_type_name,
          description: values.quotation_cost_description,
        },
      },
      ...values.quotation_cost_translations.map(translationLine => ({
        [translationLine.locale!.label]: {
          name: translationLine.quotation_cost_name,
          description: translationLine.quotation_cost_description,
        },
      }))
    );

    const updatedQuotationCostType: UpdateQuotationCostTypeBody = {
      quotation_cost_type_id: Number(quotationCostTypeId),
      cost_description: values.quotation_cost_description,
      translation: JSON.stringify(translation),
      is_cost_required_default: values.is_cost_required_default,
      is_cost_required_sample: values.is_cost_required_sample,
    };

    props.updateQuotationCostType(updatedQuotationCostType);
    resetForm();
  };

  useEffect(() => {
    if (updateQuotationCostTypeState.success) {
      props.resetUpdateQuotationCostType();
      refetchQuotationCostType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQuotationCostTypeState.success, updateQuotationCostTypeState.error]);

  const error = localesError || quotationCostTypeError;
  const isLoading = localesLoading || quotationCostTypeLoading;

  return (
    <VisibleFor roles={ADMIN_ROLES}>
      <Page title={"Update Quotation Cost Type"} hasBackButton error={error} isLoading={isLoading}>
        <Panel
          title={`Update Quotation Cost Type ${quotationCostType?.quotation_cost_type_name}`}
          withWrapper
        >
          <InnerPanelWrapper>
            <Formik
              initialValues={{
                ...initialFieldValues,
                quotation_cost_description: quotationCostType?.cost_description || "",
                is_cost_required_default: quotationCostType?.is_cost_required_default || false,
                is_cost_required_sample: quotationCostType?.is_cost_required_sample || false,
                quotation_cost_translations: mapTranslationToDropdownObject(
                  quotationCostType?.translation || [],
                  locales
                ),
              }}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {formikProps => {
                const { values, setFieldValue, handleSubmit, handleReset, errors, touched } =
                  formikProps;

                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <PrimaryLineWrapper>
                      <PrimaryLineDataWrapper>
                        <DisplayBox
                          isGray
                          label="Cost Type Name"
                          value={quotationCostType?.quotation_cost_type_name}
                        />
                        <DisplayBox
                          isGray
                          label="Cost Distribution"
                          value={quotationCostType?.quotation_cost_distribution}
                        />
                        <DisplayBox
                          isGray
                          label="Account Type"
                          value={quotationCostType?.quotation_cost_account_type}
                        />

                        <DataPoint fullWidth css={{ marginTop: "20px" }}>
                          <SimpleTextArea
                            label="Cost Description"
                            type={"text"}
                            value={values.quotation_cost_description}
                            placeholder="Please provide a description of what the cost includes/represents, which will be meaningful for both suppliers and internal teams."
                            handleChange={e => {
                              setFieldValue(fieldNames.quotation_cost_description, e.target.value);
                            }}
                          />
                          {errors.quotation_cost_description &&
                          touched.quotation_cost_description ? (
                            <ErrorMessage>{errors.quotation_cost_description}</ErrorMessage>
                          ) : null}
                        </DataPoint>
                      </PrimaryLineDataWrapper>

                      <PrimaryLineDataWrapper>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            tooltipMessage="Setting this value to true will make this cost type required by default for all suppliers when completing an RFQ."
                            htmlFor={"is_cost_required_default"}
                            name={"is_cost_required_default"}
                            value={getCostRequiredValue(values.is_cost_required_default)}
                            error={errors.is_cost_required_default}
                            touched={touched.is_cost_required_default}
                            label="Is Cost Required Default"
                            changeHandler={e => {
                              setFieldValue("is_cost_required_default", e ? e.value : false);
                            }}
                            placeholder="Is Cost Required Default"
                            options={costRequiredOptions}
                          />
                        </DataPoint>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            tooltipMessage="Setting this value to true will make this cost type required in samples for all suppliers when completing an RFQ."
                            htmlFor={"is_cost_required_sample"}
                            name={"is_cost_required_sample"}
                            value={getCostRequiredValue(values.is_cost_required_sample)}
                            error={errors.is_cost_required_sample}
                            touched={touched.is_cost_required_sample}
                            label="Is Cost Required Sample"
                            changeHandler={e => {
                              setFieldValue("is_cost_required_sample", e ? e.value : false);
                            }}
                            placeholder="Is Cost Required Sample"
                            options={costRequiredOptions}
                          />
                        </DataPoint>
                      </PrimaryLineDataWrapper>

                      {values.quotation_cost_translations.length > 0 && (
                        <PrimaryLineWrapper>
                          {values.quotation_cost_translations.map((translationLine, index) => (
                            <QuotationCostTypeTranslationLine
                              key={`translation_line_${index}`}
                              values={translationLine}
                              locales={locales}
                              index={index}
                              errors={
                                errors.quotation_cost_translations as FormikErrors<
                                  AdminQuotationCostTypeTranslationInterface[]
                                >
                              }
                              touched={touched.quotation_cost_translations}
                              handleRemoveTranslationLine={(lineIndex: number) => {
                                const newValues = { ...values };
                                newValues.quotation_cost_translations =
                                  values.quotation_cost_translations.filter(
                                    (_item, i) => i !== lineIndex
                                  );
                                setFieldValue(
                                  fieldNames.quotation_cost_translations,
                                  newValues.quotation_cost_translations
                                );
                              }}
                              setFieldValue={setFieldValue}
                            />
                          ))}
                        </PrimaryLineWrapper>
                      )}
                    </PrimaryLineWrapper>
                    <VisibleFor roles={SOURCING_EDIT_ROLES}>
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setFieldValue(fieldNames.quotation_cost_translations, [
                              ...values.quotation_cost_translations,
                              emptyQuotationCostTranslation,
                            ]);
                          }}
                        >
                          <IconText
                            text={"Add Translation Line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </VisibleFor>
                    <FormButtonPair handleReset={handleReset} />
                  </StyledForm>
                );
              }}
            </Formik>
          </InnerPanelWrapper>
        </Panel>
      </Page>
    </VisibleFor>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    updateQuotationCostTypeState: state.updateQuotationCostTypeReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    updateQuotationCostType: (body: UpdateQuotationCostTypeBody) =>
      dispatch(updateQuotationCostTypeAction(body)),
    resetUpdateQuotationCostType: () => dispatch(updateQuotationCostTypeReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdateQuotationCostType);
