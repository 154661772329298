import {
  UPDATE_PRODUCT_FAILED,
  UPDATE_PRODUCT_LOADING,
  UPDATE_PRODUCT_RESET,
  UPDATE_PRODUCT_SUCCESS,
} from "../types";

export interface UpdateProductReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: UpdateProductReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function updateProductReducer(
  state: UpdateProductReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case UPDATE_PRODUCT_LOADING:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_PRODUCT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case UPDATE_PRODUCT_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case UPDATE_PRODUCT_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
