import { Formik, FormikState } from "formik";
import { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import { CellProps } from "react-table";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, InputField } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonPositioner,
  CertificationBadge,
  DataPoint,
  InnerPanelWrapper,
  Label,
  LinkText,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { initialFieldValues } from "../../addProduct/formValues";
import { UpdateProductBody } from "../api/types";
import { UpdateProductForm, fieldNames } from "../formValues";
import { useBaseProductAndStock } from "../graphql/hooks/useBaseProductAndStock";
import { ProductStockItem } from "../graphql/types";
import { updateProductAction, updateProductReset } from "../redux/actions/updateProductAction";
import { UpdateProductReducer } from "../redux/reducers/createCaseProductReducer";

interface Props {
  updateProductState: UpdateProductReducer;
  updateProduct: (productId: number, body: UpdateProductBody) => void;
  resetUpdateProduct: () => void;
}

const ViewBaseProductAndStock = (props: Props) => {
  let history = useHistory();
  const { product_id, base_product_id } =
    useParams<{ product_id: string; base_product_id: string }>();

  const [initialValues] = useState<UpdateProductForm>(initialFieldValues);
  const [isEditingProduct, setIsEditingProduct] = useState<boolean>(false);

  const { product, productLoading, productError, refetchProduct } = useBaseProductAndStock(
    Number(base_product_id),
    Number(product_id)
  );

  const columns = useMemo(
    () => [
      {
        Header: "Product Name",
        widthPercent: 0.2,
        accessor: (listItem: ProductStockItem) => listItem.productName,
      },
      {
        Header: "Location",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.location,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Stock status",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stockStatus,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <StatusBadge type="wms" statusName={row.original.stockStatus} />
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Organisation",
        widthPercent: 0.1,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation.id}`}>
            <LinkText>{row.original.organisation.name}</LinkText>
          </Link>
        ),
        accessor: (listItem: ProductStockItem) => listItem.organisation.name,
      },
      {
        Header: "Total Qty",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.totalQuantity,
      },
      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <SecondaryButton type="button" isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    if (props.updateProductState.success) {
      props.resetUpdateProduct();
      refetchProduct();
      setIsEditingProduct(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.updateProductState.success, props.updateProductState.error]);

  const onSubmit = async (
    values: UpdateProductForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<UpdateProductForm>>) => void }
  ) => {
    const updateProductBody: UpdateProductBody = {};

    if (values.width_mm) {
      updateProductBody.width_mm = Number(values.width_mm);
    }
    if (values.height_mm) {
      updateProductBody.height_mm = Number(values.height_mm);
    }
    if (values.weight_grams) {
      updateProductBody.weight_grams = Number(values.weight_grams);
    }
    if (values.length_mm) {
      updateProductBody.length_mm = Number(values.length_mm);
    }

    props.updateProduct(product?.selectedProduct.id!, updateProductBody);
    resetForm();
  };

  return (
    <Page error={productError} isLoading={productLoading} title={"View Product"}>
      <Panel
        withWrapper
        title={`${product?.selectedProduct.product_name}`}
        subtitle={"View product stock records of this product below"}
      >
        <InnerPanelWrapper>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={onSubmit}>
            {({ values, setFieldValue, handleReset, handleSubmit }) => (
              <StyledForm onSubmit={handleSubmit}>
                {product?.selectedProduct.fsc_certified ||
                  (product?.selectedProduct.grs_certified && (
                    <TopPanelInputsWrapper>
                      {product?.selectedProduct.fsc_certified && (
                        <DataPoint>
                          <CertificationBadge type="FSC"> FSC Certified </CertificationBadge>
                        </DataPoint>
                      )}
                      {product?.selectedProduct.grs_certified && (
                        <DataPoint>
                          <CertificationBadge type="GRS"> GRS Certified </CertificationBadge>
                        </DataPoint>
                      )}
                    </TopPanelInputsWrapper>
                  ))}
                <TopPanelInputsWrapper>
                  <DisplayBox label={"Height (mm)"} value={product?.selectedProduct.height_mm} />
                  <DisplayBox label={"Length (mm)"} value={product?.selectedProduct.length_mm} />
                  <DisplayBox label={"Width (mm)"} value={product?.selectedProduct.width_mm} />
                  <DisplayBox label={"Weight (g)"} value={product?.selectedProduct.weight_grams} />
                  <DisplayBox
                    label={"Total Stock"}
                    value={product?.productStock.reduce(
                      (sum, stockRecord) => sum + stockRecord.totalQuantity,
                      0
                    )}
                  />
                  {/* {!isEditingProduct && (
                    <DataPoint containsButton>
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          setIsEditingProduct(true);
                        }}
                        appearance={"whiteButtonBlueText"}
                      >
                        Edit Measurements
                      </SecondaryButton>
                    </DataPoint>
                  )} */}
                </TopPanelInputsWrapper>

                <SecondaryLineDataWrapper>
                  <ButtonPositioner centralised>
                    <ButtonGroup>
                      {product?.baseProduct.id !== product?.selectedProduct.id && (
                        <SecondaryButton
                          onClick={() => {
                            history.push(
                              `/inventory/products/view-product/${product?.baseProduct.id}/${product?.baseProduct.id}`
                            );
                          }}
                          appearance={"blueButton"}
                        >
                          View Base Product
                        </SecondaryButton>
                      )}
                      {!isEditingProduct && (
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            setIsEditingProduct(true);
                          }}
                          appearance={"whiteButtonBlueText"}
                        >
                          Edit Measurements
                        </SecondaryButton>
                      )}
                    </ButtonGroup>
                  </ButtonPositioner>
                </SecondaryLineDataWrapper>

                {isEditingProduct && (
                  <SecondaryLineWrapper>
                    <SecondaryLineDataWrapper>
                      <DataPoint withUniqueMargin>
                        <Label htmlFor={fieldNames.height_mm}>Height (mm)</Label>
                        <InputField
                          id={fieldNames.height_mm}
                          size={"medium"}
                          type={"number"}
                          value={values.height_mm}
                          placeholder={"Input Height"}
                          handleChange={e => {
                            setFieldValue(fieldNames.height_mm, e.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin>
                        <Label htmlFor={fieldNames.length_mm}>Length (mm)</Label>
                        <InputField
                          id={fieldNames.length_mm}
                          size={"medium"}
                          type={"number"}
                          value={values.length_mm}
                          placeholder={"Input Length"}
                          handleChange={e => {
                            setFieldValue(fieldNames.length_mm, e.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin>
                        <Label htmlFor={fieldNames.width_mm}>Width (mm)</Label>
                        <InputField
                          id={fieldNames.width_mm}
                          size={"medium"}
                          type={"number"}
                          value={values.width_mm}
                          placeholder={"Input Width"}
                          handleChange={e => {
                            setFieldValue(fieldNames.width_mm, e.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin>
                        <Label htmlFor={fieldNames.weight_grams}>Weight (g)</Label>
                        <InputField
                          id={fieldNames.weight_grams}
                          size={"medium"}
                          type={"number"}
                          value={values.weight_grams}
                          placeholder={"Input Weight"}
                          handleChange={e => {
                            setFieldValue(fieldNames.weight_grams, e.target.value);
                          }}
                        />
                      </DataPoint>
                    </SecondaryLineDataWrapper>
                    <SecondaryLineDataWrapper>
                      <ButtonPositioner fullWidth>
                        <DataPoint withUniqueMargin containsButton>
                          <FormButtonPair
                            confirmCopy="Update"
                            handleReset={() => {
                              handleReset();
                              setIsEditingProduct(false);
                            }}
                          />
                        </DataPoint>
                      </ButtonPositioner>
                    </SecondaryLineDataWrapper>
                  </SecondaryLineWrapper>
                )}

                <PrimaryLineWrapper>
                  <Table data={product?.productStock || []} columns={columns} />
                </PrimaryLineWrapper>
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    updateProductState: state.updateProductReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    updateProduct: (productId: number, body: UpdateProductBody) =>
      dispatch(updateProductAction(productId, body)),
    resetUpdateProduct: () => dispatch(updateProductReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewBaseProductAndStock);
