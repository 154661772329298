export enum SHIPMENT_UPDATE_TYPE {
  IN_PRODUCTION = 1,
  UPDATED_ETA = 2,
  IN_TRANSIT = 3,
  DELIVERED_INTERMEDIATE = 4,
  DELIVERED_FINAL = 5,
  SPLIT = 6,
}

export enum SHIPMENT_STATUS {
  SOURCING = 1,
  IN_TRANSIT = 2,
  DELIVERY_INTERMEDIATE = 3,
  DELIVERY_FINAL = 4,
}

export enum ADDRESS_TYPE {
  FROM = "FROM",
  TO = "TO",
  CURRENT = "CURRENT",
}
