import { AxiosRequestConfig } from "axios";

import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";

export interface GetProductStocksBody {
  skuId?: string;
}

export const getProductStocks = async ({ skuId }: GetProductStocksBody) => {
  try {
    const config: AxiosRequestConfig = {
      params: { externalProductId: skuId },
    };

    return STOCK_MANAGEMENT_API.get("/product-stocks", config);
  } catch (err) {
    console.error("Error in get product stocks API call", err);
    throw err;
  }
};
