import { WMS_API } from "../../../../../../api/baseConfig";
import { TerminateOutboundManifestLineActionBody } from "../../redux/actions/terminateOutboundManifestLineAction";

export const cancelOutboundManifestLineAPI = async (
  body: TerminateOutboundManifestLineActionBody
) => {
  try {
    return await WMS_API.patch(
      `/outbound-manifest-lines/${body.outbound_manifest_line_id}/cancel`,
      body
    );
  } catch (err) {
    console.log("Error in cancel outbound manifest Line API call:", err);
    throw err;
  }
};
