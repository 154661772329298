import {
  CREATE_PURCHASE_ORDER_APPROVAL_FAILED,
  CREATE_PURCHASE_ORDER_APPROVAL_LOADING,
  CREATE_PURCHASE_ORDER_APPROVAL_RESET,
  CREATE_PURCHASE_ORDER_APPROVAL_SUCCESS,
} from "../types/index";

export interface CreatePurchaseOrderApprovalReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreatePurchaseOrderApprovalReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createPurchaseOrderApprovalReducer(
  state: CreatePurchaseOrderApprovalReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_PURCHASE_ORDER_APPROVAL_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_PURCHASE_ORDER_APPROVAL_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_PURCHASE_ORDER_APPROVAL_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_PURCHASE_ORDER_APPROVAL_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
