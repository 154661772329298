import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { usePendingDemand } from "../graphql/hooks/usePendingDemand";
import { PendingDemand } from "../graphql/types";

export default function ViewPendingDemand() {
  let history = useHistory();

  const { roles } = useAuthorisationContext();

  const { data: pendingDemand, error, isLoading } = usePendingDemand();

  const columns = useMemo(
    () => [
      {
        Header: "Client Order #",
        widthPercent: 0.1,
        accessor: (line: PendingDemand) => line.client_order?.external_client_order_id,
        Cell: ({ row }: CellProps<PendingDemand>) => (
          <Link to={`/client-orders/update-client-order/${row.original.client_order?.id}`}>
            <LinkText>{row.original.client_order?.external_client_order_id || ""}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Client",
        widthPercent: 0.2,
        accessor: (line: PendingDemand) => line.client_order?.organisation?.organisation_name,
      },
      {
        Header: "Product Name",
        widthPercent: 0.2,
        accessor: (line: PendingDemand) => line.product?.product_name,
      },
      {
        Header: "Quantity To Purchase",
        widthPercent: 0.2,
        accessor: (line: PendingDemand) => line.quantity_to_purchase,
      },
    ],
    []
  );

  const rightItems: PanelProps["rightItems"] = SOURCING_EDIT_ROLES.some(role =>
    roles.includes(role)
  )
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/purchase-orders/create-purchase-order");
          },
          type: "button",
          title: "Create Purchase Order",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  return (
    <Page title={"Pending Demand"} isLoading={isLoading} error={error}>
      <Panel
        withWrapper
        title={"Pending Demand List"}
        text={
          "Use the table below to explore Sourceful's pending demand. The table below displays only orders selected for fulfilment with purchase orders and not yet completely fulfilled."
        }
        allignTitle="left"
        rightItems={rightItems}
        iconName={"alert-exclamation-outline"}
      >
        <Table data={pendingDemand} columns={columns} />
      </Panel>
    </Page>
  );
}
