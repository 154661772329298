import { Formik } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { createIAMOrganisation } from "../api/createIAMOrganisation";

export interface UserOption {
  label: string;
  value: string;
}

export interface AddOrgForm {
  displayName: string;
  metadata: string;
}

export interface UsersResponse {
  name: string;
  email: string;
  uuid: string;
  organisations_users: {
    organisation: {
      uuid: string;
    };
  }[];
}

export const fieldObjects = {
  displayName: {
    fieldName: "displayName",
    fieldLabel: "displayName",
    fieldPlaceholder: "Add Organisation Name",
    fieldBox: "Please fill Organisation Name",
  },
  metadata: {
    fieldName: "metadata",
    fieldLabel: "metadata",
    fieldPlaceholder: "Add metadata",
    fieldBox: "Add metadata",
  },
};

export const initialFieldValues: AddOrgForm = {
  displayName: "",
  metadata: "{}",
};

const CreateIAMOrganisation = () => {
  const [initialValues] = useState<AddOrgForm>(initialFieldValues);
  const history = useHistory();

  const [createOrganisationState, createOrganisation] = useAPI(createIAMOrganisation, false, {
    pending: "creating org...",
    success: "org added successfully",
    error: "Error while trying to add org",
  });

  const onSubmit = (values: AddOrgForm) => {
    console.log(createOrganisationState);
    createOrganisation({
      displayName: values.displayName,
      metadata: values.metadata,
    })
      .catch(error => {
        console.error("error adding org:", error);
        toast.error("Error adding org");
      })
      .then(response => {
        console.log("org created successfully");
        toast.success("org created successfully");
        history.push(`/admin/iam/organisations/`);
      });
  };

  return (
    <Page
      title={"IAM Organisations"}
      isLoading={createOrganisationState === API_REQUEST_STATE.PENDING}
      error={createOrganisationState === API_REQUEST_STATE.ERROR}
    >
      <Panel withWrapper title={"Create Organisation"}>
        <InnerPanelWrapper>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              validateForm,
              setTouched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.displayName.fieldName}>
                          {fieldObjects.displayName.fieldLabel}
                        </Label>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldObjects.displayName.fieldName}
                          name={fieldObjects.displayName.fieldName}
                          size={"medium"}
                          error={errors.displayName}
                          touched={touched.displayName}
                          type={"text"}
                          value={values.displayName}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.displayName.fieldName, e.target.value);
                          }}
                          placeholder={fieldObjects.displayName.fieldPlaceholder}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.metadata.fieldName}>
                          {fieldObjects.metadata.fieldLabel}
                        </Label>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldObjects.metadata.fieldName}
                          name={fieldObjects.metadata.fieldName}
                          size={"medium"}
                          error={errors.metadata}
                          touched={touched.metadata}
                          type={"text"}
                          value={values.metadata}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.metadata.fieldName, e.target.value);
                          }}
                          placeholder={fieldObjects.metadata.fieldPlaceholder}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <SecondaryButton
                    type="button"
                    appearance={"blueButton"}
                    onClick={() => {
                      handleSubmit();
                    }}
                  >
                    Confirm
                  </SecondaryButton>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default CreateIAMOrganisation;
