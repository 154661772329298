interface IconProps {
  width?: number;
  height?: number;
}

export const OrderIcon = ({ width = 35, height = 35 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M27 18H24V11C24 10.45 23.55 10 23 10H15C14.45 10 14 10.45 14 11V18H12H11C10.45 18 10 18.45 10 19V27C10 27.55 10.45 28 11 28H27C27.55 28 28 27.55 28 27V19C28 18.45 27.55 18 27 18ZM18 26H12V20H18V26ZM16 18V12H22V18H16ZM26 26H20V20H26V26Z"
      fill="#0038FF"
    />
  </svg>
);

export const LogisticsIcon = ({ width = 35, height = 35 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M30 20C30 14.5 25.5 10 20 10C14.5 10 10 14.5 10 20C10 25.5 14.5 30 20 30C25.5 30 30 25.5 30 20ZM27.9 19H25C24.8 16.6 24 14.4 22.7 12.5C25.5 13.5 27.6 16 27.9 19ZM23 21H17.1C17.3 23.5 18.3 25.8 20 27.6C21.7 25.8 22.7 23.5 23 21ZM17.1 19C17.3 16.5 18.4 14.2 20 12.4C21.7 14.2 22.8 16.5 23 19H17.1ZM15.1 19C15.3 16.6 16.1 14.4 17.5 12.4C14.6 13.4 12.5 15.9 12.1 19H15.1ZM12.1 21H15.1C15.3 23.4 16.1 25.6 17.4 27.6C14.5 26.6 12.5 24 12.1 21ZM25 21C24.8 23.4 24 25.6 22.6 27.6C25.5 26.6 27.5 24.1 27.9 21H25Z"
      fill="#0038FF"
    />
  </svg>
);

export const PCatIcon = ({ width = 35, height = 35 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10 11C10 10.4477 10.4477 10 11 10H29C29.5523 10 30 10.4477 30 11V29C30 29.5523 29.5523 30 29 30H11C10.4477 30 10 29.5523 10 29V11ZM12 12V28H28V12H12Z"
      fill="#0038FF"
    />
    <path d="M26 26H18V24H26V26Z" fill="#0038FF" />
    <path d="M26 21H18V19H26V21Z" fill="#0038FF" />
    <path d="M26 16H18V14H26V16Z" fill="#0038FF" />
    <path d="M16 16H14V14H16V16Z" fill="#0038FF" />
    <path d="M16 21H14V19H16V21Z" fill="#0038FF" />
    <path d="M16 26H14V24H16V26Z" fill="#0038FF" />
  </svg>
);

export const SourcingIcon = ({ width = 35, height = 35 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.93 28.41L26.02 24.5C27.22 22.97 27.94 21.06 27.94 18.97C27.94 14.02 23.92 10 18.97 10C14.02 10 10 14.02 10 18.97C10 23.92 14.02 27.94 18.97 27.94C21.11 27.94 23.08 27.18 24.62 25.93L28.51 29.82L29.92 28.41H29.93ZM18.97 25.94C15.13 25.94 12 22.81 12 18.97C12 15.13 15.13 12 18.97 12C22.81 12 25.94 15.13 25.94 18.97C25.94 22.81 22.81 25.94 18.97 25.94Z"
      fill="#0038FF"
    />
    <path
      d="M17.71 20.58L15.41 17.88L13.91 19.18L17.01 22.78C17.22 23 17.4 23.18 17.76 23.18C18.12 23.18 18.36 22.85 18.51 22.68L23.61 16.18L22.01 14.98L17.71 20.58Z"
      fill="#0038FF"
    />
  </svg>
);

export const WMSIcon = ({ width = 35, height = 35 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24.73 21.01C24.62 20.42 24.1 19.99 23.5 19.99H21.85L21.94 19.97L20.04 12.22C19.91 11.64 19.41 11.24 18.82 11.24H13.27C12.58 11.24 12.02 11.8 12.02 12.49V17.99H11.27C10.58 17.99 10.02 18.55 10.02 19.24V25.97C10.02 26.05 10 26.12 10 26.19C10 28.28 11.7 29.99 13.8 29.99C15.24 29.99 16.48 29.17 17.12 27.99H18.75C19.16 29.15 20.26 29.99 21.57 29.99C22.88 29.99 23.97 29.15 24.39 27.99H24.74C25.12 27.99 25.48 27.82 25.72 27.52C25.96 27.22 26.05 26.83 25.96 26.47L24.73 21.01ZM18.24 13.24L19.89 19.99H18.33L17.79 17.99H14.02V13.24H18.24ZM16.26 19.99L16.8 21.99H22.9L23.52 24.72C23 24.27 22.32 23.99 21.58 23.99C20.28 23.99 19.18 24.83 18.76 25.99H17.58C17.47 23.99 15.83 22.39 13.8 22.39C13.15 22.39 12.56 22.57 12.02 22.85V19.98H16.25L16.26 19.99ZM13.81 27.99C12.82 27.99 12.01 27.18 12.01 26.19C12.01 25.2 12.82 24.39 13.81 24.39C14.8 24.39 15.61 25.2 15.61 26.19C15.61 27.18 14.8 27.99 13.81 27.99ZM21.58 27.99C21.03 27.99 20.58 27.54 20.58 26.99C20.58 26.44 21.03 25.99 21.58 25.99C22.13 25.99 22.58 26.44 22.58 26.99C22.58 27.54 22.13 27.99 21.58 27.99Z"
      fill="#0038FF"
    />
    <path
      d="M29.57 20.72L27.74 21.17L25.31 10L23.36 10.43L25.96 22.35C26.03 22.68 26.24 22.97 26.52 23.14C26.72 23.26 26.95 23.33 27.18 23.33C27.28 23.33 27.38 23.32 27.48 23.29L30.05 22.66L29.57 20.72Z"
      fill="#0038FF"
    />
  </svg>
);

export const AdminIcon = ({ width = 35, height = 35 }: IconProps) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.03 20C20.27 20 22.03 18.24 22.03 16C22.03 13.76 20.27 12 18.03 12C15.79 12 14.03 13.76 14.03 16C14.03 18.24 15.79 20 18.03 20ZM18.03 14C19.15 14 20.03 14.88 20.03 16C20.03 17.12 19.15 18 18.03 18C16.91 18 16.03 17.12 16.03 16C16.03 14.88 16.91 14 18.03 14Z"
      fill="#0038FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M17.99 22.01C13.51 22.01 10 25.52 10 30H12C12 26.64 14.63 24.01 17.99 24.01C21.35 24.01 23.98 26.64 23.98 30H25.98C25.98 25.52 22.47 22.01 17.99 22.01Z"
      fill="#0038FF"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M29.17 13.5C29.17 13.24 29.32 13.01 29.53 12.89L30.01 12.61L29.01 10.88L28.61 11.11C28.5 11.17 28.37 11.21 28.24 11.21C27.82 11.21 27.48 10.87 27.48 10.45V10H25.48V10.46C25.48 10.88 25.14 11.21 24.72 11.21C24.59 11.21 24.46 11.17 24.35 11.11L23.94 10.87L22.94 12.6L23.42 12.88C23.64 13 23.78 13.22 23.78 13.49C23.78 13.76 23.63 13.98 23.42 14.1L22.94 14.38L23.94 16.11L24.35 15.88C24.46 15.82 24.59 15.78 24.72 15.78C25.14 15.78 25.48 16.12 25.48 16.53V17H27.48V16.54C27.48 16.12 27.82 15.78 28.24 15.78C28.38 15.78 28.5 15.82 28.61 15.88L29.01 16.11L30.01 14.38L29.52 14.1C29.31 13.98 29.16 13.76 29.16 13.5H29.17ZM26.48 14.66C25.82 14.66 25.31 14.15 25.31 13.5C25.31 12.85 25.82 12.34 26.48 12.34C27.14 12.34 27.64 12.85 27.64 13.5C27.64 14.15 27.13 14.66 26.48 14.66Z"
      fill="#0038FF"
    />
  </svg>
);
