import { Formik, FormikErrors, FormikTouched } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import { InnerPanelWrapper } from "../../../../../styles/AddPage";
import {
  DataPoint,
  FlexTitle,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { FulfilWOBody, FulfilWorkOrderOutputLineBody } from "../api/types";
import WorkOrderOutputLine from "../components/WorkOrderOutputLine";
import {
  WordOrderFulfilmentForm,
  WorkOrderAndRecipeOutput,
  emptyWorkOrderOutput,
  initialValuesData,
} from "../formValues";
import { useWorkOrderById } from "../graphql/hooks/useWorkOrderFulfilmentById";
import { useLocationNamesByWarehouseId } from "../graphql/hooks/useWorkOrderLocationNamesByWarehouseId";
import { mapOutputLinesToDTO } from "../mappers";
import {
  fulfilWorkOrderAction,
  fulfilWorkOrderReset,
} from "../redux/actions/fulfilWorkOrderAction";
import { FulfilWorkOrderReducer } from "../redux/reducers/fulfilWorkOrderReducer";
import { validationSchema } from "../validation";

interface Props {
  fulfilWorkOrderState: FulfilWorkOrderReducer;
  fulfilWorkOrder: (body: FulfilWOBody) => void;
  resetFulfilWorkOrder: () => void;
}

export function FulfilWorkOrder(props: Props) {
  const [initialValues, setInitialValues] = useState<WordOrderFulfilmentForm>(initialValuesData);
  const { work_order_id } = useParams<{ work_order_id: string }>();

  const { fulfilWorkOrderState, resetFulfilWorkOrder } = props;

  const onSubmit = (values: WordOrderFulfilmentForm) => {
    const qty = Number(values.quantity);
    const qtyToFulfil =
      (workOrder?.quantity || 0) -
      ((workOrder?.completed_quantity || 0) - (workOrder?.cancelled_quantity || 0));

    if (qty > qtyToFulfil) {
      toast.error("Overfulfilment attempt. Please reduce quantity.");
      return;
    }

    const outputLines: FulfilWorkOrderOutputLineBody[] = mapOutputLinesToDTO(
      values.work_order_and_recipe_outputs,
      qty,
      workOrder?.organisation.id!
    );

    const FulfilWorkOrderBody: FulfilWOBody = {
      work_order_id: Number(work_order_id),
      quantity: qty,
      work_order_outputs: outputLines,
    };

    props.fulfilWorkOrder(FulfilWorkOrderBody);
  };

  const { workOrder, workOrderLoading, workOrderError, refetchWorkOrder } = useWorkOrderById(
    Number(work_order_id)
  );
  const { locationNames, locationNamesLoading, locationNamesError } = useLocationNamesByWarehouseId(
    workOrder?.warehouse.id
  );

  useEffect(() => {
    if (workOrder) {
      const recipeLinePairs = workOrder.recipe!.recipe_outputs.map(line => {
        const matchedWorkOrderOutputLines = workOrder.work_order_output_lines.filter(
          output_line => output_line.product.id === line.product.id
        );

        return {
          recipe_output_line: {
            product: {
              id: line.product.id,
              product_name: line.product.product_name,
            },
            quantity: line.quantity,
          },
          existing_work_order_output_lines: matchedWorkOrderOutputLines,
          work_order_outputs: [{ ...emptyWorkOrderOutput }],
        };
      });

      setInitialValues({
        ...initialValues,
        work_order_and_recipe_outputs: recipeLinePairs,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [workOrder]);

  useEffect(() => {
    if (fulfilWorkOrderState.success) {
      resetFulfilWorkOrder();
      setInitialValues({ ...initialValuesData });
      refetchWorkOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fulfilWorkOrderState.success, fulfilWorkOrderState.error]);

  const error = workOrderError || locationNamesError;
  const isLoading = workOrderLoading || fulfilWorkOrderState.loading || locationNamesLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Manifests - Fulfil Work Order"}>
      <Panel
        withWrapper
        title={`Fulfil Work Order #${workOrder?.id}`}
        subtitle={"Fulfil a work order here"}
        manifestStatusName={workOrder?.work_order_status.work_order_status_name}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched, handleReset }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DisplayBox label="Warehouse" value={workOrder?.warehouse.warehouse_name} />
                    <DisplayBox
                      label="Organisation"
                      value={workOrder?.organisation.organisation_name}
                    />
                    <DisplayBox
                      label="External Work Order ID"
                      value={workOrder?.external_work_order_id}
                    />
                    <DisplayBox label="Recipe Name" value={workOrder?.recipe?.recipe_name} />
                    <DisplayBox
                      label="Due Date"
                      value={moment(workOrder?.due_date).format("DD-MM-YYYY")}
                    />
                    <DisplayBox label="Quantity" value={workOrder?.quantity} />
                    <DisplayBox label="Completed Quantity" value={workOrder?.completed_quantity} />
                    <DisplayBox label="Cancelled Quantity" value={workOrder?.cancelled_quantity} />
                  </TopPanelInputsWrapper>

                  <PrimaryLineWrapper>
                    <Title>Create New Work Order Output Lines</Title>
                    <DataPoint withUniqueMargin>
                      <Label isRequired>Select Quantity</Label>
                      <InputField
                        id={"quantity"}
                        size={"medium"}
                        type={"number"}
                        value={values.quantity}
                        placeholder={"Select Quantity"}
                        handleChange={e => setFieldValue("quantity", e.target.value)}
                      />
                      {errors.quantity && touched.quantity ? (
                        <ErrorMessage>{errors.quantity}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    {values.work_order_and_recipe_outputs.map((line, recipeOutputIndex) => (
                      <PrimaryLineWrapper key={recipeOutputIndex}>
                        <Title>Work Order Output Line #{recipeOutputIndex + 1}</Title>
                        <PrimaryLineDataWrapper>
                          <DisplayBox
                            label="Recipe Product Name"
                            value={line.recipe_output_line.product.product_name}
                          />
                          <DisplayBox
                            label="Recipe Quantity"
                            value={line.recipe_output_line.quantity}
                          />
                        </PrimaryLineDataWrapper>

                        {line.existing_work_order_output_lines.map((ffline, ffLineIndex) => (
                          <SecondaryLineWrapper key={ffLineIndex}>
                            <FlexTitle>
                              <Title>Work Order Line #{ffline.id}</Title>
                            </FlexTitle>
                            <SecondaryLineDataWrapper>
                              <DisplayBox
                                withUniqueMargin
                                label="Fulfilled Quantity"
                                value={ffline.quantity}
                              />
                            </SecondaryLineDataWrapper>
                          </SecondaryLineWrapper>
                        ))}

                        {line.work_order_outputs.map((WorkOrderOutput, workOrderLineIndex) => (
                          <WorkOrderOutputLine
                            key={workOrderLineIndex}
                            line={line}
                            multiplier={values.quantity}
                            locationNames={locationNames}
                            recipeOutputIndex={recipeOutputIndex}
                            workOrderLineIndex={workOrderLineIndex}
                            touched={
                              touched.work_order_and_recipe_outputs
                                ? (touched.work_order_and_recipe_outputs[
                                    workOrderLineIndex
                                  ] as FormikTouched<WorkOrderAndRecipeOutput>)
                                : undefined
                            }
                            errors={
                              errors.work_order_and_recipe_outputs
                                ? (errors.work_order_and_recipe_outputs[
                                    workOrderLineIndex
                                  ] as FormikErrors<WorkOrderAndRecipeOutput>)
                                : undefined
                            }
                            values={WorkOrderOutput}
                            setFieldValue={setFieldValue}
                          />
                        ))}
                      </PrimaryLineWrapper>
                    ))}
                  </PrimaryLineWrapper>

                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton type="reset" appearance={"whiteButton"} onClick={handleReset}>
                      Cancel
                    </SecondaryButton>
                    <SecondaryButton appearance={"blueButton"} type="submit">
                      Confirm
                    </SecondaryButton>
                  </ButtonGroup>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    fulfilWorkOrderState: state.fulfilWorkOrderReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    fulfilWorkOrder: (body: FulfilWOBody) => dispatch(fulfilWorkOrderAction(body)),
    resetFulfilWorkOrder: () => dispatch(fulfilWorkOrderReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfilWorkOrder);
