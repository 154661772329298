import { useHistory } from "react-router";

import { styled } from "@sourceful/shared-components";

import {
  AdminIcon,
  LogisticsIcon,
  OrderIcon,
  PCatIcon,
  SourcingIcon,
  WMSIcon,
} from "../../../../components/icons/ServiceIcons";
import { ActiveServiceReducer } from "../../../../redux/reducers/activeServiceReducer";
import { CardTitle, CardTopSectionWrapper, CardWrapper } from "../../../../styles/SharedStyles";

export const IconWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  alignItems: "center",
  justifyContent: "center",
});

export interface MenuCardProps {
  contentStyles?: any;
  redirect: string;
  title?: string;
  type: "Sourcing" | "Logistics" | "WMS" | "Admin" | "PCAT" | "Orders";
  changeActiveService: (body: ActiveServiceReducer) => void;
}

const defaultContentStyles = {
  minWidth: "200px",
  maxWidth: "300px",
  height: "300px",
  margin: "20px",
  borderColor: "#005AE1",
  justifyContent: "center",
  borderWidth: "2px",
  backgroundColor: "inherit",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "underline",
    borderWidth: "3px",
    boxShadow: "5px 10px 18px #888888",
  },
};

export const MenuCard = ({
  type,
  title = type,
  redirect,
  changeActiveService,
  contentStyles = defaultContentStyles,
}: MenuCardProps) => {
  const history = useHistory();

  const cardRedirect = () => {
    changeActiveService({ activeService: type });
    history.push(redirect);
  };

  return (
    <CardWrapper css={contentStyles} onClick={_e => cardRedirect()}>
      <CardTopSectionWrapper>
        <IconWrapper>
          {type === "WMS" && <WMSIcon width={180} height={180} />}
          {type === "Sourcing" && <SourcingIcon width={180} height={180} />}
          {type === "Logistics" && <LogisticsIcon width={180} height={180} />}
          {type === "PCAT" && <PCatIcon width={180} height={180} />}
          {type === "Orders" && <OrderIcon width={180} height={180} />}
          {type === "Admin" && <AdminIcon width={180} height={180} />}
        </IconWrapper>
        <CardTitle>{title.toUpperCase()}</CardTitle>
      </CardTopSectionWrapper>
    </CardWrapper>
  );
};
