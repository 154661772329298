import { CustomReportDataItem } from "../graphql/types";

export const mapCustomReportToCSVData = (customReport: CustomReportDataItem[]) => {
  return customReport.map(data => {
    return {
      stock_id: data.id,
      product: data.product.product_name,
      quantity: data.quantity,
      stock_condition: data.stock_condition.stock_condition_name,
      stock_status: data.stock_status.stock_status_name,
    };
  });
};
