import { ApolloError } from "@apollo/client";

import { useGetSupplierDetailsForRfQsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { SupplierDetailsItem } from "../types";

export interface HookResult {
  supplierDetails: SupplierDetailsItem[];
  supplierDetailsError?: ApolloError;
  supplierDetailsLoading: boolean;
}

export const useSupplierDetails = (): HookResult => {
  const { data, error, loading } = useGetSupplierDetailsForRfQsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    supplierDetails: data && Array.isArray(data.organisation) ? data.organisation : [],
    supplierDetailsError: error,
    supplierDetailsLoading: loading,
  };
};
