import { ApprovalsCopyInterface } from "../types";

export const DIELINES_APPROVAL: ApprovalsCopyInterface = {
  approvalTitle: "Dielines Approval",
  approvalDescription:
    "Use this section to confirm that any and all dielienes have been correctly attached to each order line in this Purchase Order. Only users who have the correct permissions will be able to approve or reject this Purchase Order.",
  permissions: "Richard Merreywether, Ed Baptist, Wing Chan, Yangfan Zhou",
};

export const ORDER_APPROVAL: ApprovalsCopyInterface = {
  approvalTitle: "Order Approval",
  approvalDescription:
    " This section can be used by the Order Team to approve or reject this purchase Order. Only users who have the correct permissions will be able to approve or reject this Purchase Order. Approving this will send the order to the supplier, so make sure that all details and attachments are correct!",
  permissions: "Sharon Chan, Wing Chan, Yangfan Zhou",
};

export const FINANCE_APPROVAL: ApprovalsCopyInterface = {
  approvalTitle: "Finance Approval",
  approvalDescription:
    " This section should be used only after a taxable invoice has been uploaded. Approval in this section will mean that the Finance team is happy with the invoice and its correspondence to the purchase order document.",
  permissions: "Grace Ghoong, Wing Chan",
};

export const EXEC_APPROVAL: ApprovalsCopyInterface = {
  approvalTitle: "Executive Approval",
  approvalDescription:
    "Final approval to designate that the Executive team is happy with this order. The approval has no automated implication, however, it's intended usage is to allow the finances for this order to be released.",
  permissions: "Wing Chan, Sharon Chan, Yangfan Zhou",
};

export const FINANCE_ATTACHMENTS = {
  attachmentsTitle: "Financials",
  attachmentsDescription:
    "This section is intended specifically for adding taxable invoice document as provided by the supplier/manufacturer responsible for this order.",
  invoicesTitle: "Existing Invoices",
  invoicesMissing: "No Exisiting Invoice",
};
