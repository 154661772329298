import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { UpdateQuotationCostTypeBody } from "../../api/types";
import { updateQuotationCostTypeAPI } from "../../api/updateQuotationCostTypeAPI";
import {
  UPDATE_QUOTATION_COST_TYPE_FAILED,
  UPDATE_QUOTATION_COST_TYPE_LOADING,
  UPDATE_QUOTATION_COST_TYPE_RESET,
  UPDATE_QUOTATION_COST_TYPE_SUCCESS,
} from "../types";

export const updateQuotationCostTypeLoading = () => {
  return {
    type: UPDATE_QUOTATION_COST_TYPE_LOADING,
  };
};

export const updateQuotationCostTypeSuccess = () => {
  return {
    type: UPDATE_QUOTATION_COST_TYPE_SUCCESS,
  };
};

export const updateQuotationCostTypeFailed = () => {
  return {
    type: UPDATE_QUOTATION_COST_TYPE_FAILED,
  };
};

export const updateQuotationCostTypeReset = () => {
  return {
    type: UPDATE_QUOTATION_COST_TYPE_RESET,
  };
};

export const updateQuotationCostTypeAction = (body: UpdateQuotationCostTypeBody) => {
  return async (dispatch: Dispatch) => {
    toast("Updating quotation cost type", { hideProgressBar: true });
    console.log("Updating quotation cost type - Action");
    dispatch(updateQuotationCostTypeLoading());
    try {
      await updateQuotationCostTypeAPI(body);
      toast.success("Updated quotation cost type.");
      dispatch(updateQuotationCostTypeSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating quotation cost type.");
      dispatch(updateQuotationCostTypeFailed());
    }
  };
};
