export interface InboundManifestFulfilmentLineForm {
  product: {
    value: number;
    label: string;
  } | null;
  is_file_copy: boolean;
  location: {
    value: number;
    label: string;
  } | null;
  quantity: string;
  condition: {
    value: number;
    label: string;
  } | null;
  notes: string;
}

export const initialFieldValues: InboundManifestFulfilmentLineForm = {
  product: null,
  location: null,
  quantity: "",
  condition: null,
  is_file_copy: false,
  notes: "",
};

export const fieldNames = {
  product: "product",
  location: "location",
  quantity: "quantity",
  condition: "condition",
  is_file_copy: "is_file_copy",
  notes: "notes",
};
