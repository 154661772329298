import { ExtendedProductStock } from "../../api/getFilteredProductStock/types";
import {
  GETTING_FILTERED_PRODUCT_STOCK,
  GET_FILTERED_PRODUCT_STOCK_FAILURE,
  GET_FILTERED_PRODUCT_STOCK_RESET,
  GET_FILTERED_PRODUCT_STOCK_SUCCESS,
} from "../types";

export interface GetFilteredProductStockReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  baseProductNames: string[];
  productStocks: ExtendedProductStock[];
  earliestStockInteractionDate: Date | null;
  latestStockInteractionDate: Date | null;
}

const initialState: GetFilteredProductStockReducer = {
  loading: false,
  success: false,
  error: false,
  baseProductNames: [],
  productStocks: [],
  earliestStockInteractionDate: null,
  latestStockInteractionDate: null,
};

export default function getFilteredProductStockReducer(
  state: GetFilteredProductStockReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case GETTING_FILTERED_PRODUCT_STOCK:
      return {
        ...state,
        loading: true,
      };
    case GET_FILTERED_PRODUCT_STOCK_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        baseProductNames: action.baseProductNames,
        productStocks: action.productStocks,
        earliestStockInteractionDate: action.earliestStockInteractionDate,
        latestStockInteractionDate: action.latestStockInteractionDate,
      };
    case GET_FILTERED_PRODUCT_STOCK_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case GET_FILTERED_PRODUCT_STOCK_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
