import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  InnerPanelWrapper,
  Label,
  LinkText,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useLocationById } from "../graphql/hooks/useLocationById";
import { ProductStockItem } from "../graphql/types";

export default function ViewLocation() {
  const { location_id } = useParams<{ location_id: string }>();

  const { location, locationLoading, locationError } = useLocationById(Number(location_id));

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.6,
        accessor: (product_stock: ProductStockItem) => product_stock.id,
      },
      {
        Header: "Product Name",
        widthPercent: 0.6,
        filter: "arraySome",
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Quantity",
        widthPercent: 0.6,
        accessor: (product_stock: ProductStockItem) => product_stock.quantity,
      },
      {
        Header: "Condition",
        widthPercent: 0.6,
        accessor: (product_stock: ProductStockItem) =>
          product_stock.stock_condition.stock_condition_name,
      },
    ],
    []
  );

  return (
    <Page isLoading={locationLoading} error={locationError} title={"View Location"}>
      <Panel
        withWrapper
        title={`Location #${location?.id}`}
        subtitle={"View location details below"}
      >
        {
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DataPoint>
                <Label>Location Name</Label>
                <Box>{location?.location_name}</Box>
              </DataPoint>
              <DataPoint>
                <Label>Warehouse</Label>
                <Box>{location?.warehouse.warehouse_name}</Box>
              </DataPoint>
              <DataPoint>
                <Label>Location Type</Label>
                <Box>{location?.location_type.location_type_name}</Box>
              </DataPoint>
            </TopPanelInputsWrapper>
            <Panel
              withWrapper
              title={"Product Stock"}
              text={`Use the table below to view product stock at ${location?.location_name}`}
              allignTitle="left"
              iconName={"alert-exclamation-outline"}
            >
              <Table data={location?.product_stocks || []} columns={columns} />
            </Panel>
          </InnerPanelWrapper>
        }
      </Panel>
    </Page>
  );
}
