export interface CourierServiceForm {
  courier_id: {
    value: number;
    label: string;
  } | null;
  courier_service_name: string;
}

export const initialFieldValues: CourierServiceForm = {
  courier_id: null,
  courier_service_name: "",
};

export const fieldNames = {
  courier_id: "courier_id",
  courier_service_name: "courier_service_name",
};
