export interface CreateOrderForm {
  currency_code: {
    value: number;
    label: string;
  };
  client_email_address: string;
  contact: {
    secondary_email: string;
    phone_number: string;
    tax_number: string;
    user_uuid: string;
    user_uuid_internal: string;
    org_id: string;
    org_name: string;
  };
  billing_address: BillingAddress;
  shipping_address: ShippingAddress;
  sourceful_entity_uk: {
    vat_exempt: {
      value: number;
      label: string;
    };
    outside_uk: {
      value: number;
      label: string;
    };
  };
  sourceful_entity_eu: {
    tax_type: {
      value: number;
      label: string;
    };
  };
  sourceful_entity_us: {
    vat_exempt: {
      value: number;
      label: string;
    };
    threshold: number;
  };
  sourceful_entity_cn: {
    client_cn_entity: {
      value: number;
      label: string;
    };
    client_3rd_party_cn_entity: {
      value: number;
      label: string;
    };
    client_3rd_party_cn_entity_agreement: {
      value: number;
      label: string;
    };
  };
  product_costs: ProductCost[];
  product_one_off_costs: ProductOneOffCost[];
  shipping_costs: ShippingCost[];
  carbon_offset_costs: CarbonOffsetCost[];
  discount_costs: DiscountCost[];
  final_total: FinalTotal;
  payment_term: PaymentTerm;
  client_promised_delivery_date: ClientPromisedDeliveryDate;
  client_reference: string;
  external_opportunity_id: string;
}

export interface BillingAddress {
  company_name: string;
  first_name: string;
  second_name: string;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  region_or_county_or_state: string;
  postcode: string;
  country_name: {
    value: number;
    label: string;
  };
  phone_number: string;
}

export interface ShippingAddress {
  company_name: string;
  first_name: string;
  second_name: string;
  address_line_1: string;
  address_line_2: string;
  address_line_3: string;
  city: string;
  region_or_county_or_state: string;
  postcode: string;
  country_name: {
    value: number;
    label: string;
  };
  phone_number: string;
  custom_delivery_instruction: string;
}

export const DEFAULT_PAYMENT_PLAN_TYPE: PaymentPlanType = 1;
export const SPLIT_PAYMENT_PLAN_TYPE: PaymentPlanType = 2;

export type PaymentPlanType = 1 | 2;

export const DEFAULT_PAYMENT_PLAN_TYPE_LABEL = "Default (Pay later w/ automatic approval)";
export const SPLIT_PAYMENT_PLAN_TYPE_LABEL = "Split (pay % up front & rest later)";

export type PaymentPlanTypeLabel =
  | typeof DEFAULT_PAYMENT_PLAN_TYPE_LABEL
  | typeof SPLIT_PAYMENT_PLAN_TYPE_LABEL;

export interface PaymentTerm {
  has_payment_term: {
    value: number;
    label: string;
  };
  due_date: string;
  payment_term_description: string;
  suppress_client_email: {
    value: number;
    label: string;
  };
  payment_plan_type: {
    value: PaymentPlanType;
    label: string;
  };
}

export interface ClientPromisedDeliveryDate {
  from_date: string;
  to_date: string;
}

export interface FinalTotal {
  subtotal: number;
  discount: number;
  vat: number;
  total: number;
}

export interface CarbonOffsetCost {
  kg: number;
  price_per_kg: number;
  vat: {
    value: number;
    label: string;
  };
  total: number;
  total_with_vat: number;
}

export interface ProductCost {
  sku_id: string;
  cost_description: string;
  qty: number;
  unit_cost: number;
  vat: {
    value: number;
    label: string;
  };
  min_days: number;
  max_days: number;
  total: number;
  total_with_vat: number;

  // hidden fields
  base_product_id: number;
  base_product_name: string;
  base_product_version: number;
  content_attributes: any;
  artworks: any;
}

export interface ProductOneOffCost {
  sku_id: string;
  cost_description: string;
  qty: number;
  unit_cost: number;
  vat: {
    value: number;
    label: string;
  };
  total: number;
  total_with_vat: number;
}

export interface ShippingCost {
  cost_description: string;
  amount: number;
  min_days: number;
  max_days: number;
  vat: {
    value: number;
    label: string;
  };
  total: number;
  total_with_vat: number;
  shipping_option: {
    value: number;
    label: string;
  };
}

export interface DiscountCost {
  cost_description: string;
  amount: number;
  vat: {
    value: number;
    label: string;
  };
  total: number;
  total_with_vat: number;
  discount_option: {
    value: number;
    label: string;
  };
}
