import React from "react";
import { Link } from "react-router-dom";

import { Heading, styled } from "@sourceful/shared-components";

import { BasicPanel } from "../../../../components/panel/panel";
import { SecondaryButton } from "../../../../shared/components/atoms/buttons/SecondaryButton";
import { defaultImageUrl } from "../../shared/constants";

const ProductCard = styled(BasicPanel, {
  display: "flex",
  flexDirection: "column",
  width: "200px",
  height: "380px",
  textAlign: "center",
  alignItems: "center",
  overflow: "hidden",
  marginBottom: "20px",
});

const ProductCardBody = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  flexDirection: "column",
  flexGrow: "1",
  paddingBottom: "20px",
  alignItems: "center",
});

const ProductCardText = styled("p", {
  margin: "0",
});

const ProductCardBottom = styled("div", {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  flexDirection: "column",
});

const ProductImage = styled("img", {
  width: "100%",
  display: "block",
  margin: "0 auto",
  textAlign: "center",
});

interface BaseProductPanelProps {
  name?: string;
  productId?: string;
  productType?: string;
  imgSrc?: string;
  viewProductHandler: (productId: string) => void;
}

const BaseProductPanel: React.FC<BaseProductPanelProps> = ({
  name = "Default Name",
  productId = "Default ID",
  imgSrc = defaultImageUrl,
  productType = "Default Type",
  viewProductHandler,
}) => {
  // if imgSrc is null, use a default image
  if (!imgSrc) {
    imgSrc = defaultImageUrl;
  }
  return (
    <ProductCard>
      <ProductImage src={`${imgSrc}`} alt="Product" />
      <ProductCardBody>
        <Heading fontSize={6} css={{ marginTop: "10px" }}>
          {name}
        </Heading>
        <ProductCardBottom>
          <div>
            <ProductCardText>ID: {productId}</ProductCardText>
            <ProductCardText>Type: {productType}</ProductCardText>
          </div>

          <div>
            <SecondaryButton css={{ marginLeft: "0", marginTop: "10px" }}>
              <Link to={`/product-catalogue/baseproduct/${productId}`}>View Product</Link>
            </SecondaryButton>
          </div>
        </ProductCardBottom>
      </ProductCardBody>
    </ProductCard>
  );
};

export default BaseProductPanel;
