import { ApolloError } from "@apollo/client";

import { useGetStockChecksDescQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { StockCheckItem } from "../types";

export interface HookResult {
  stockChecks: StockCheckItem[];
  stockChecksError?: ApolloError;
  stockChecksLoading: boolean;
}

export const useStockCheckDesc = (): HookResult => {
  const { data, error, loading } = useGetStockChecksDescQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockChecks: data && Array.isArray(data.stock_check) ? data.stock_check : [],
    stockChecksError: error,
    stockChecksLoading: loading,
  };
};
