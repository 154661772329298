import { WMS_API } from "../../../../../api/baseConfig";
import { CreateCaseProductBody } from "./types";

export const createCaseProductAPI = async (body: CreateCaseProductBody) => {
  try {
    return await WMS_API.post("/products/case", body);
  } catch (err) {
    console.log("Error in create case product API call", err);
    throw err;
  }
};
