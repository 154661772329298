import { FormikErrors, FormikTouched } from "formik";
import { useTranslation } from "react-i18next";

import { Heading, styled } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
} from "../../../../../../styles/SharedStyles";
import { ExtendedQuoteRequestLine } from "../../graphql/types";
import { ExternalQuoteForm, SupplierQuoteLine } from "../formValues";
import { QuotationCostTypeItem } from "../graphql/types";
import { SupplierLogisticsDetails } from "./SupplierLogisticsDetails";
import { SupplierQuotationCosts } from "./SupplierQuotationCosts";

const SupplierQuoteLineCloser = styled("div", {
  maxHeight: "0",
  overflow: "hidden",

  variants: {
    isLineOpen: {
      true: {
        maxHeight: "unset",
        overflow: "visible",
      },
    },
  },
});

const AboveDiv = styled("div", {
  position: "relative",
  bottom: "100px",
});

interface SupplierQuoteRequestLineProps {
  line: ExtendedQuoteRequestLine;
  values: ExternalQuoteForm;
  errors: FormikErrors<SupplierQuoteLine>;
  touched: FormikTouched<SupplierQuoteLine>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  quotationCostTypes: QuotationCostTypeItem[];
  handleSetOpenQuoteLine: (index: number | null) => void;
  openQuoteLine: number | null;
  linesEdited: number[];
  handleAddLinesEdited: (index: number) => void;
}

export const SupplierQuoteRequestLine = ({
  line,
  values,
  errors,
  touched,
  setFieldValue,
  quotationCostTypes,
  handleSetOpenQuoteLine,
  openQuoteLine,
  linesEdited,
  handleAddLinesEdited,
}: SupplierQuoteRequestLineProps) => {
  const { t } = useTranslation();

  const setValue = (field: string, value: any, shouldValidate?: boolean | undefined) => {
    if (!linesEdited.includes(line.index)) {
      handleAddLinesEdited(line.index);
    }
    setFieldValue(field, value, shouldValidate);
  };

  const getDomesticLogisticsFeeValueTrue = {
    value: 1,
    label: t("quote_supplier_response_domestic_logistics_fee_included"),
  };
  const getDomesticLogisticsFeeValueFalse = {
    value: 0,
    label: t("quote_supplier_response_domestic_logistics_fee_not_included"),
  };

  const getDomesticLogisticFeeValue = () => {
    if (values.supplierQuoteLines[line.index].domesticLogisticsFeeIncluded === undefined) {
      return null;
    }
    return values.supplierQuoteLines[line.index].domesticLogisticsFeeIncluded
      ? getDomesticLogisticsFeeValueTrue
      : getDomesticLogisticsFeeValueFalse;
  };

  const isSampleRefundableValueTrue = {
    value: 1,
    label: t("quote_supplier_response_is_sample_fee_refundable_true"),
  };
  const isSampleRefundableValueFalse = {
    value: 0,
    label: t("quote_supplier_response_is_sample_fee_refundable_false"),
  };

  const getIsSampleFeeRefundableValue = () => {
    if (values.supplierQuoteLines[line.index].isSampleFeeRefundable === undefined) {
      return null;
    }
    return values.supplierQuoteLines[line.index].isSampleFeeRefundable
      ? isSampleRefundableValueTrue
      : isSampleRefundableValueFalse;
  };

  const isOpen = openQuoteLine === line.index;
  const lineErrors =
    errors && Object.entries(errors).length > 0 && touched && Object.entries(touched).length > 0;
  const lineComplete =
    linesEdited.includes(line.index) &&
    ((errors && Object.entries(errors).length === 0) || !errors);

  const isLineSample = line.is_sample;
  const fillInDetailsString = isLineSample
    ? t("quote_supplier_response_fill_in_sample_details")
    : t("quote_supplier_response_fill_in_details");

  const getLineStyle = () => {
    if (lineErrors) return { border: "2px solid rgb(252, 129, 129)" };
    if (lineComplete) return { border: "2px solid rgb(21 194 21)" };
    return {};
  };

  return (
    <PrimaryLineWrapper key={line.index} css={getLineStyle()}>
      <AboveDiv id={`line_${line.index}`} />
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin halfWidth>
          {isLineSample ? (
            <SimpleInput
              label={t("quote_supplier_response_sample_quantity")}
              name={`supplierQuoteLines[${line.index}].minimum_order_quantity`}
              type="number"
              placeholder={t("quote_supplier_response_quantity")}
              error={errors && errors.minimumOrderQuantity}
              value={values.supplierQuoteLines[line.index].minimumOrderQuantity}
              changeHandler={e => {
                setValue(`supplierQuoteLines[${line.index}].minimumOrderQuantity`, e.target.value);
              }}
              touched={touched && touched.minimumOrderQuantity}
            />
          ) : (
            <DisplayBox
              fullWidth
              withUniqueMargin
              label={t("quote_supplier_response_quantity")}
              value={line.minimum_order_quantity}
            />
          )}
        </DataPoint>

        {line.product.fsc_certification && (
          <DisplayBox
            withUniqueMargin
            label={t("quote_supplier_response_fsc_certification_code")}
            value={line.product.fsc_certification.fsc_certification_name}
          />
        )}
        <SecondaryButton
          css={{ marginTop: "15px" }}
          appearance="blueButton"
          type="button"
          onClick={() => {
            if (isOpen) {
              handleSetOpenQuoteLine(null);
            } else {
              handleSetOpenQuoteLine(line.index);
              // Scroll the current quote line into view using the "above div"
              setTimeout(() => {
                document
                  .getElementById(`line_${line.index}`)
                  ?.scrollIntoView({ behavior: "smooth" });
              });
            }
          }}
        >
          {isOpen ? "Close" : fillInDetailsString}
        </SecondaryButton>
      </PrimaryLineDataWrapper>
      <SupplierQuoteLineCloser isLineOpen={isOpen}>
        <SupplierQuotationCosts
          isLineSample={isLineSample}
          index={line.index}
          quotationCostTypes={quotationCostTypes}
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setValue}
        />
        {!isLineSample && (
          <>
            <hr />
            <SupplierLogisticsDetails
              index={line.index}
              values={values}
              errors={errors}
              touched={touched}
              setFieldValue={setValue}
            />
            <hr />
            <Heading level={3} fontSize={6} css={{ marginTop: "20px" }}>
              {isLineSample
                ? t("quote_supplier_response_additional_sample_details_section_title")
                : t("quote_supplier_response_additional_details_section_title")}
            </Heading>
          </>
        )}
        <PrimaryLineDataWrapper>
          <DataPoint withUniqueMargin halfWidth>
            <SimpleInput
              type="number"
              label={t("quote_supplier_response_production_turnaround")}
              placeholder={t("quote_supplier_response_production_turnaround")}
              error={errors && errors.leadTimeMaxDays}
              touched={touched && touched.leadTimeMaxDays}
              name={`supplierQuoteLines[${line.index}].leadTimeMaxDays`}
              value={values.supplierQuoteLines[line.index].leadTimeMaxDays}
              changeHandler={e => {
                setValue(`supplierQuoteLines[${line.index}].leadTimeMaxDays`, e.target.value);
              }}
            />
          </DataPoint>
          {!isLineSample && (
            <DataPoint withUniqueMargin halfWidth>
              <SimpleSelect
                label={t("quote_supplier_response_domestic_logistics_fee")}
                error={errors && errors.domesticLogisticsFeeIncluded}
                touched={touched && touched.domesticLogisticsFeeIncluded}
                placeholder={t("quote_supplier_response_domestic_logistics_fee")}
                isSearchable
                name={`supplierQuoteLines[${line.index}].domesticLogisticsFeeIncluded`}
                options={[getDomesticLogisticsFeeValueTrue, getDomesticLogisticsFeeValueFalse]}
                value={getDomesticLogisticFeeValue()}
                changeHandler={e => {
                  setFieldValue(
                    `supplierQuoteLines[${line.index}].domesticLogisticsFeeIncluded`,
                    e?.value
                  );
                }}
              />
            </DataPoint>
          )}
          {isLineSample && (
            <DataPoint withUniqueMargin halfWidth>
              <SimpleSelect
                label={t("quote_supplier_response_is_sample_fee_refundable")}
                error={errors && errors.isSampleFeeRefundable}
                touched={touched && touched.isSampleFeeRefundable}
                placeholder={t("quote_supplier_response_is_sample_fee_refundable")}
                isSearchable
                name={`supplierQuoteLines[${line.index}].isSampleFeeRefundable`}
                options={[isSampleRefundableValueTrue, isSampleRefundableValueFalse]}
                value={getIsSampleFeeRefundableValue()}
                changeHandler={e => {
                  setValue(`supplierQuoteLines[${line.index}].isSampleFeeRefundable`, e?.value);
                }}
              />
            </DataPoint>
          )}
        </PrimaryLineDataWrapper>
        {!isLineSample && (
          <PrimaryLineDataWrapper>
            <DataPoint withUniqueMargin fullWidth>
              <SimpleTextArea
                size="medium"
                type="text"
                label={t("quote_supplier_response_notes")}
                placeholder={t("quote_supplier_response_notes")}
                name={`supplierQuoteLines[${line.index}].supplierNotes`}
                value={values.supplierQuoteLines[line.index].supplierNotes}
                handleChange={e => {
                  setValue(`supplierQuoteLines[${line.index}].supplierNotes`, e.target.value);
                }}
              />
            </DataPoint>
          </PrimaryLineDataWrapper>
        )}
      </SupplierQuoteLineCloser>
    </PrimaryLineWrapper>
  );
};
