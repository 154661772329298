import moment from "moment";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import {
  DataPoint,
  LinkText,
  PrimaryLineWrapper,
  SecondaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { PurchaseOrderLine } from "../graphql/types";

function PurchaseOrderLinkCell({ row }: CellProps<PurchaseOrderLine>) {
  return (
    <Link to={`/purchase-orders/update-purchase-order/${row.original.purchase_order.id}`}>
      <LinkText>{row.original.purchase_order.purchase_order_document_uid}</LinkText>
    </Link>
  );
}

interface RelatedPurchaseOrdersProps {
  purchaseOrderLines: PurchaseOrderLine[] | undefined;
}

export function ViewRelatedPurchaseOrders({ purchaseOrderLines }: RelatedPurchaseOrdersProps) {
  const columns = useMemo(
    () => [
      {
        Header: "PO#",
        widthPercent: 0.1,
        accessor: (purchaseOrderLine: PurchaseOrderLine) =>
          purchaseOrderLine.purchase_order.purchase_order_document_uid,
        Cell: PurchaseOrderLinkCell,
      },
      {
        Header: "Product Name",
        widthPercent: 0.4,
        accessor: (purchaseOrderLine: PurchaseOrderLine) => purchaseOrderLine.product.product_name,
      },
      {
        Header: "Supplier",
        widthPercent: 0.1,
        accessor: (purchaseOrderLine: PurchaseOrderLine) =>
          purchaseOrderLine.purchase_order.supplier_organisation?.organisation_name,
      },
      {
        Header: "Created At",
        widthPercent: 0.1,
        accessor: (purchaseOrderLine: PurchaseOrderLine) =>
          moment(purchaseOrderLine.purchase_order.created_at).format("x"),
        Cell: ({ row }: CellProps<PurchaseOrderLine>) =>
          moment(row.original.purchase_order.created_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Expected Delivery Date",
        widthPercent: 0.1,
        accessor: (purchaseOrderLine: PurchaseOrderLine) =>
          moment(purchaseOrderLine.purchase_order.expected_delivery_date).format("x"),
        Cell: ({ row }: CellProps<PurchaseOrderLine>) =>
          moment(row.original.purchase_order.expected_delivery_date).format("DD-MM-YYYY"),
      },
    ],
    []
  );

  return (
    <PrimaryLineWrapper>
      <DataPoint fullWidth>
        <Title>Related Purchase Orders</Title>
      </DataPoint>
      <SecondaryLineWrapper>
        <DataPoint fullWidth>
          <Title>View the details of the associated purchase orders below</Title>
        </DataPoint>
        <DataPoint fullWidth>
          <Table hideUtilityBar data={purchaseOrderLines ?? []} columns={columns} />
        </DataPoint>
      </SecondaryLineWrapper>
    </PrimaryLineWrapper>
  );
}
