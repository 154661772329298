import { UploadedFile } from "../../../../../attachments/fileUpload";

export const fieldNames = {
  estimated_delivery_date: "estimated_delivery_date",
  finance_attachments: "finance_attachments",
};

export interface FinanceAttachmentsInterface {
  finance_attachments: UploadedFile[];
}

export const initialFieldValues: FinanceAttachmentsInterface = {
  finance_attachments: [],
};

export enum ActionType {
  approve = "approve",
  reject = "reject",
}

export enum ApprovalTypeIds {
  "order" = 1,
  "dielines" = 2,
  "finance" = 3,
  "exec" = 4,
}

export interface ApprovalInfo {
  userId: string;
  isApproved: boolean;
  type: string;
  userName: string;
}
