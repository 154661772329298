import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { getFilteredProductStockAPI } from "../../api/getFilteredProductStock/getFilteredProductStockAPI";
import {
  GetFilteredProductStockParams,
  ProductStock,
} from "../../api/getFilteredProductStock/types";
import {
  GETTING_FILTERED_PRODUCT_STOCK,
  GET_FILTERED_PRODUCT_STOCK_FAILURE,
  GET_FILTERED_PRODUCT_STOCK_RESET,
  GET_FILTERED_PRODUCT_STOCK_SUCCESS,
} from "../types";

export const gettingFilteredProductStock = () => {
  return {
    type: GETTING_FILTERED_PRODUCT_STOCK,
  };
};

export const getFilteredProductStockSuccess = ({
  productStocks,
  baseProductNames,
  latestStockInteractionDate,
  earliestStockInteractionDate,
}: {
  productStocks: ProductStock[];
  baseProductNames: string[];
  latestStockInteractionDate: Date | null;
  earliestStockInteractionDate: Date | null;
}) => {
  return {
    type: GET_FILTERED_PRODUCT_STOCK_SUCCESS,
    productStocks,
    baseProductNames,
    earliestStockInteractionDate,
    latestStockInteractionDate,
  };
};

export const getFilteredProductStockReset = () => {
  return {
    type: GET_FILTERED_PRODUCT_STOCK_RESET,
  };
};

export const getFilteredProductStockFailure = () => {
  return {
    type: GET_FILTERED_PRODUCT_STOCK_FAILURE,
  };
};

export const getFilteredProductStockAction = (params: GetFilteredProductStockParams) => {
  return async (dispatch: Dispatch) => {
    toast("Preparing the Stock Check", { hideProgressBar: true });
    dispatch(gettingFilteredProductStock());
    try {
      const response = await getFilteredProductStockAPI(params);
      toast.success("StockCheck prepared successfully.");
      dispatch(
        getFilteredProductStockSuccess({
          productStocks: response.data.productStocks,
          latestStockInteractionDate: response.data.latestStockInteractionDate,
          earliestStockInteractionDate: response.data.earliestStockInteractionDate,
          baseProductNames: response.data.baseProductNames,
        })
      );
    } catch (err) {
      toast.error("Something went wrong with preparing StockCheck.");
      dispatch(getFilteredProductStockFailure());
    }
  };
};
