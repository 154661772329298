import { ApolloError } from "@apollo/client";

import { useGetRecipesWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { RecipeItem } from "../types";

export interface HookResult {
  recipes: RecipeItem[];
  recipesError?: ApolloError;
  recipesLoading: boolean;
}

export const useRecipes = (): HookResult => {
  const { data, error, loading } = useGetRecipesWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    recipes: data && Array.isArray(data.recipe) ? data.recipe : [],
    recipesError: error,
    recipesLoading: loading,
  };
};
