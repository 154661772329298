import { FormikErrors, FormikTouched } from "formik";
import { Dispatch, SetStateAction } from "react";
import Select from "react-select";

import { InputField } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import {
  DataPoint,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { DropdownItem } from "../../shared/graphql/types";
import { CHANGE_STOCK_CONDITION } from "../constants";
import { FulfilStockCheckLineForm, fieldNames, stockCheckFulfilmentActions } from "../formValues";

interface FulfilStockCheckLineProps {
  values: FulfilStockCheckLineForm;
  stockConditions: DropdownItem[] | undefined;
  errors: FormikErrors<FulfilStockCheckLineForm>;
  touched: FormikTouched<FulfilStockCheckLineForm>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  setLineToFulfil: Dispatch<SetStateAction<number | null>>;
}

const FulfilStockCheckLine = ({
  values,
  setFieldValue,
  errors,
  touched,
  stockConditions,
  setLineToFulfil,
}: FulfilStockCheckLineProps) => {
  return (
    <SecondaryLineWrapper type="withIndex">
      <SecondaryLineDataWrapper>
        <DataPoint withUniqueMargin>
          <Label isRequired htmlFor={fieldNames.action}>
            Action
          </Label>
          <Select
            styles={reactSelectStyling}
            maxMenuHeight={220}
            isSearchable={true}
            options={stockCheckFulfilmentActions}
            id={fieldNames.action}
            value={values.action}
            onChange={e => setFieldValue(fieldNames.action, e)}
            placeholder="Select Action "
          />
          {errors.action && touched.action ? <ErrorMessage>{errors.action}</ErrorMessage> : null}
        </DataPoint>

        {values.action?.label === CHANGE_STOCK_CONDITION && (
          <DataPoint withUniqueMargin>
            <Label isRequired htmlFor={fieldNames.stock_condition}>
              Stock Condition
            </Label>
            <Select
              styles={reactSelectStyling}
              maxMenuHeight={220}
              isSearchable={true}
              options={stockConditions}
              id={fieldNames.stock_condition}
              value={values.stock_condition}
              onChange={e => setFieldValue(fieldNames.stock_condition, e)}
              placeholder="Select Stock Condition"
            />
            {errors.stock_condition && touched.stock_condition ? (
              <ErrorMessage>{errors.stock_condition}</ErrorMessage>
            ) : null}
          </DataPoint>
        )}

        <DataPoint withUniqueMargin>
          <Label isRequired htmlFor={fieldNames.quantity}>
            Quantity
          </Label>
          <InputField
            id={fieldNames.quantity}
            size={"medium"}
            type={"text"}
            value={values.quantity}
            placeholder={"Input quantity"}
            handleChange={e => {
              setFieldValue(fieldNames.quantity, e.target.value);
            }}
          />
          {errors.quantity && touched.quantity ? (
            <ErrorMessage>{errors.quantity}</ErrorMessage>
          ) : null}
        </DataPoint>
      </SecondaryLineDataWrapper>
      <FormButtonPair handleReset={() => setLineToFulfil(null)} />
    </SecondaryLineWrapper>
  );
};

export default FulfilStockCheckLine;
