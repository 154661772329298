import { WMS_API } from "../../../../../../api/baseConfig";
import { FulfilStockCheckLineBody } from "./types";

export const fulfilStockCheckLineAPI = async ({
  stockCheckId,
  stockCheckLineId,
  ...rest
}: FulfilStockCheckLineBody) => {
  try {
    return await WMS_API.post(
      `/stock-checks/${stockCheckId}/stock-check-lines/${stockCheckLineId}/fulfil`,
      { ...rest }
    );
  } catch (err) {
    console.log("Error in Fulfil stock check line API call:", err);
    throw err;
  }
};
