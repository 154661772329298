import moment from "moment";

import { ShipmentUpdateItem } from "../graphql/types/ShipmentUpdateItem";
import { getLastShipmentUpdate } from "./getLastShipmentUpdate";

export const getShipmentExpectedDate = (shipmentUpdates?: ShipmentUpdateItem[]): string => {
  if (!shipmentUpdates || shipmentUpdates.length === 0) return "";

  const lastShipmentUpdate = getLastShipmentUpdate(shipmentUpdates);
  if (!lastShipmentUpdate) return "";

  return lastShipmentUpdate.expected_date
    ? moment(lastShipmentUpdate.expected_date).format("DD-MM-YYYY")
    : "";
};
