import { SOURCING_API } from "../../../../../api/baseConfig";
import { PurchaseOrderPrefillResponse } from "./types";

export const getPurchaseOrderByIdAPI = async (
  purchaseOrderId?: string
): Promise<PurchaseOrderPrefillResponse | undefined> => {
  if (!purchaseOrderId) return undefined;
  try {
    const response = await SOURCING_API.post(`/purchase-orders/${purchaseOrderId}/form-data`);
    if (response) {
      if (Array.isArray(response)) {
        if (response.length > 0) {
          return response[0].data.data as PurchaseOrderPrefillResponse;
        } else {
          console.warn("No purchase order found with the given id.", purchaseOrderId);
          return;
        }
      } else {
        return response.data.data as PurchaseOrderPrefillResponse;
      }
    }
  } catch (err) {
    console.log("Error in get purchase order by id API call", err);
    throw err;
  }
};
