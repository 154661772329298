import { SOURCING_API } from "../../../../../api/baseConfig";

export const getUsersAPI = async (ids: string) => {
  try {
    if (ids === "") return [];
    const response = await SOURCING_API.get(`/iam/users?ids=${ids}`);
    return response?.data?.users || [];
  } catch (err) {
    console.log("Error in getting users API call", err);
    throw err;
  }
};
