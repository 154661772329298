import { StockItem } from "../types";

export const mapStockToCSV = (stock: StockItem[]) => {
  return stock.map(data => {
    return {
      product_stock_id: data.id,
      product_name: data.product.product_name,
      organisation: data.organisation?.organisation_name,
      location: data.location.location_name,
      stock_status: data.stock_status.stock_status_name,
      stock_condition: data.stock_condition.stock_condition_name,
      initial_quantity: data.initial_quantity,
      quantity: data.quantity,
      barcode_uuid: data.barcode_uuid,
      barcode_is_unique: data.barcode_is_unique ? "True" : "False",
    };
  });
};
