import { LOGISTICS_API } from "../../../../../api/baseConfig";

export interface GetClientOrderDetailsRequest {
  clientOrderId: number;
}

export const getClientOrderDetailsAPI = async ({ clientOrderId }: GetClientOrderDetailsRequest) => {
  try {
    return await LOGISTICS_API.get(`/client-order-details/${clientOrderId}`);
  } catch (err) {
    console.log("Error in get client order details API call", err);
    throw err;
  }
};
