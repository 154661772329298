import { ApolloError } from "@apollo/client";

import { useGetClientOrdersQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { ClientOrderListItem } from "../types";

export interface HookResult {
  clientOrders: ClientOrderListItem[];
  clientOrdersError?: ApolloError;
  clientOrdersLoading: boolean;
}

// FYI:
// abstract away generated types and hooks
// so it is not tightly coupled to graphql-codegen
// TODO: next iteration would be to add a mapping between values returned to i.e. .status instead of .client_order_line_status_name
export const useClientOrders = (): HookResult => {
  const { data, error, loading } = useGetClientOrdersQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    clientOrders: data?.client_order_line || [],
    clientOrdersError: error,
    clientOrdersLoading: loading,
  };
};
