import { Formik, FormikState } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  StyledForm,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { handleApolloError } from "../../../../../util/handleApolloError";
import { CourierForm, fieldNames, initialFieldValues } from "../formValues";
import { useAddCourier } from "../graphql/hooks/useAddCourier";
import { useAllCourierNames } from "../graphql/hooks/useAllCourierNames";
import { validationSchema } from "../validation";

const AddCourier = () => {
  let history = useHistory();

  const [initialValues] = useState<CourierForm>(initialFieldValues);
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);

  const { addCourier } = useAddCourier({
    onError: error => {
      handleApolloError(error);
      setMutationLoading(false);
    },
    onCompleted: () => {
      toast.success("Courier created successfully");
      setMutationLoading(false);
      history.goBack();
    },
  });

  const onSubmit = async (
    values: CourierForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<CourierForm>>) => void }
  ) => {
    if (courierNames?.indexOf(values.courier_name) !== -1) {
      toast.error("A courier with this name already exists.");
      return;
    }
    setMutationLoading(true);

    await addCourier({
      courierName: values.courier_name,
    });

    refetchCourierNames();
    resetForm();
  };

  const { courierNames, courierNamesLoading, courierNamesError, refetchCourierNames } =
    useAllCourierNames();

  const isLoading = courierNamesLoading || mutationLoading;

  return (
    <Page error={courierNamesError} isLoading={isLoading} title={"Couriers"}>
      <Panel withWrapper title={"Add Courier"} subtitle={"Add a courier using the inputs below"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, handleReset, handleChange, handleSubmit, errors, touched }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.courier_name}>
                        Courier Name
                      </Label>
                      <InputField
                        id={fieldNames.courier_name}
                        size={"medium"}
                        type={"text"}
                        placeholder={"Enter Courier Name Here"}
                        value={values.courier_name}
                        handleChange={handleChange}
                      />
                      {errors.courier_name && touched.courier_name ? (
                        <ErrorMessage>{errors.courier_name}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  <FormButtonPair handleReset={handleReset} />
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default AddCourier;
