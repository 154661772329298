import { ApolloError } from "@apollo/client";

import { useGetWorkOrderRecipeNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapRecipeNamesToDrodownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  recipeNames: DropdownItem[];
  recipeNamesError?: ApolloError;
  recipeNamesLoading: boolean;
}

export const useRecipeNames = (): HookResult => {
  const { data, error, loading } = useGetWorkOrderRecipeNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    recipeNames:
      data && Array.isArray(data.recipe) ? mapRecipeNamesToDrodownObject(data.recipe) : [],
    recipeNamesError: error,
    recipeNamesLoading: loading,
  };
};
