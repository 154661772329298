export interface CourierForm {
  courier_name: string;
}

export const initialFieldValues: CourierForm = {
  courier_name: "",
};

export const fieldNames = {
  courier_name: "courier_name",
};
