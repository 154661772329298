import { createRef, useMemo, useState } from "react";
import QRCode from "react-qr-code";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import { useReactToPrint } from "react-to-print";

import { Modal } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  InnerPanelWrapper,
  LinkText,
  PanelWrapper,
  QRPrintButtonWrapper,
} from "../../../../../styles/SharedStyles";
import { useProductStock } from "../graphql/hooks/useProductStocks";
import { ProductStockItem } from "../graphql/types";

export default function ViewProductStocks() {
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [QRCodeBarcode, setQRCodeBarcode] = useState("");

  const { productStocks, productStocksLoading, productStocksError } = useProductStock();

  const handleQRModalClose = () => {
    setIsQRModalOpen(false);
    setQRCodeBarcode("");
  };

  const modalRef = createRef<HTMLButtonElement>();

  const qrCodeRef = createRef<any | null>();

  const handlePrint = useReactToPrint({
    content: () => qrCodeRef.current,
    pageStyle:
      "@page { size: auto;  margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.id,
      },
      {
        Header: "Name",
        widthPercent: 0.4,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: ProductStockItem) => listItem.product.product_name,
      },
      {
        Header: "Quantity",
        widthPercent: 0.2,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Condition",
        widthPercent: 0.2,
        accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
      },
      {
        Header: "Status",
        widthPercent: 0.2,
        accessor: (listItem: ProductStockItem) => listItem.stock_status.stock_status_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <StatusBadge type="wms" statusName={row.original.stock_status.stock_status_name} />
        ),
      },
      {
        Header: "QR Code",
        widthPercent: 0.2,
        accessor: (listItem: ProductStockItem) => (
          <SecondaryButton
            isWithinTable
            appearance={"blueButton"}
            onClick={() => {
              setQRCodeBarcode(listItem.barcode_uuid);
              setIsQRModalOpen(true);
            }}
          >
            View/Print
          </SecondaryButton>
        ),
      },
    ],
    []
  );

  return (
    <Page
      error={productStocksError}
      isLoading={productStocksLoading}
      title={"Inventory - Stock Levels"}
    >
      <>
        <Panel
          withWrapper
          title={"Stock Levels"}
          text={"Use the table below to view Sourceful's Stock Levels"}
          allignTitle="left"
        >
          <Table data={productStocks} columns={columns} />
        </Panel>
        <Modal
          id={"QRCodeModal"}
          isOpen={isQRModalOpen}
          handleClose={handleQRModalClose}
          triggerRef={modalRef}
        >
          <PanelWrapper>
            <InnerPanelWrapper>
              <div ref={qrCodeRef}>
                <QRCode value={QRCodeBarcode} />
              </div>
              <QRPrintButtonWrapper>
                <SecondaryButton appearance={"blueButton"} onClick={handlePrint}>
                  Print
                </SecondaryButton>
              </QRPrintButtonWrapper>
            </InnerPanelWrapper>
          </PanelWrapper>
        </Modal>
      </>
    </Page>
  );
}
