import { WMS_API } from "../../../../../api/baseConfig";
import { CreateInboundManifestBody } from "./types";

export const createInboundManifestAPI = async (
  createInboundManifestAPIBody: CreateInboundManifestBody
) => {
  try {
    return await WMS_API.post("/inbound-manifests/create", createInboundManifestAPIBody);
  } catch (err) {
    console.log("Error in create inbound manifest API call:", err);
    throw err;
  }
};
