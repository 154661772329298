import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { Icon } from "../../../../../components/icons/Icon";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import Page from "../../../../../shared/components/templates/Page/Page";
import { daysHoursMinutesSeconds } from "../../../../../util/daysHoursMinutesSeconds";
import { useSuppliers } from "../graphql/hooks/useSuppliers";
import { SupplierDashboardItem } from "../graphql/types";

export default function ViewSuppliersDashboard() {
  let history = useHistory();

  const { suppliers, suppliersLoading, suppliersError } = useSuppliers();

  const items = [
    {
      id: 1,
      handleClick: () => history.push("/suppliers/create-supplier"),
      type: "button",
      title: "Create Supplier",
      iconName: "alert-add-outline",
    },
    {
      id: 2,
      handleClick: () => history.push("/suppliers/create-locale-holiday"),
      type: "button",
      title: "Create Locale Holiday",
      iconName: "alert-add-outline",
    },
  ] as PanelProps["rightItems"];

  const columns = useMemo(
    () => [
      {
        Header: "Supplier",
        widthPercent: 2,
        accessor: (supplier: SupplierDashboardItem) => supplier.organisationName,
      },
      {
        Header: "Locale",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) => supplier.locale,
      },
      {
        Header: "Accepted POs",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) => supplier.acceptedPurchaseOrdersCount,
      },
      {
        Header: "Rejected/Expired POs",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) => supplier.rejectedExpiredPurchaseOrdersCount,
      },
      {
        Header: "Fulfilled POs",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) => supplier.fulfilledPurchaseOrdersCount,
      },
      {
        Header: "Order Value Excl. VAT",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) =>
          supplier.totalMoneyExclVAT.toFixed(2).toString(),
      },
      {
        Header: "Avg Response Time (days:hrs:mins:secs)",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) =>
          daysHoursMinutesSeconds(supplier.averageResponseTime),
      },
      {
        Header: "% Delivered On Time",
        widthPercent: 1,
        accessor: (supplier: SupplierDashboardItem) =>
          supplier.percentDeliveredOnTime.toFixed(1) + "%",
      },
      {
        Header: "Update",
        widthPercent: 0.1,
        Cell: ({ row }: CellProps<SupplierDashboardItem>) =>
          row.original.xeroContactId ? (
            <Link to={`/suppliers/view/${row.original.xeroContactId}`}>
              <Icon name={"arrow-direction-right"} />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  return (
    <Page
      title={"Suppliers Dashboard"}
      isLoading={suppliersLoading}
      error={suppliersError}
      hasBackButton
    >
      <Panel withWrapper title={"Suppliers"} allignTitle="left" rightItems={items}>
        <Table data={suppliers} columns={columns} />
      </Panel>
    </Page>
  );
}
