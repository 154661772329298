import * as Yup from "yup";

export const validationSchema = Yup.object({
  quoteValidFrom: Yup.string().required("Date is required"),
  quoteValidTo: Yup.string().required("Date is required"),
  supplier: Yup.object()
    .shape({
      value: Yup.number().required("Supplier is required"),
    })
    .typeError("Supplier is required"),
  currencyCode: Yup.object()
    .shape({ value: Yup.number().required("Currency code is required") })
    .typeError("Currency code is required"),
  quoteLines: Yup.array()
    .of(
      Yup.object({
        costType: Yup.object()
          .shape({
            value: Yup.number().required("Cost type is required"),
          })
          .typeError("Cost type is required"),
        cost: Yup.string().required("Cost is required"),
      })
    )
    .min(1, "Please provide at least 1 Quotation Cost"),
  moq: Yup.string().required("MOQ is required"),
  leadTimeMaxDays: Yup.string().required("Production lead time is required"),
  supplierReference: Yup.string().nullable(),
  country: Yup.object()
    .shape({
      value: Yup.string().nullable(),
    })
    .nullable(),
});
