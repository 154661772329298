import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";
import { UpdateStockManagementAddressBody } from "./types";

export const updateStockManagementAddress = async (body: UpdateStockManagementAddressBody) => {
  try {
    return await STOCK_MANAGEMENT_API.patch("/addresses", body);
  } catch (err) {
    console.log("Error in update stock management address API call", err);
    throw err;
  }
};
