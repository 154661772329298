import { ApolloError } from "@apollo/client";

import { useGetBaseProductsAccreditationsLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapBaseProductsAccreditationsToCSV } from "../mappers/mapBaseProductsAccreditationsToCSV";

export interface HookResult {
  baseProductsAccreditations: any[];
  baseProductsAccreditationsError?: ApolloError;
  baseProductsAccreditationsLoading: boolean;
  getBaseProductsAccreditations: () => Promise<void>;
}

export const useBaseProductsAccreditations = (): HookResult => {
  const [getBaseProductsAccreditations, { data, error, loading }] =
    useGetBaseProductsAccreditationsLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    baseProductsAccreditations:
      data && Array.isArray(data.base_product)
        ? mapBaseProductsAccreditationsToCSV(data.base_product)
        : [],
    baseProductsAccreditationsError: error,
    baseProductsAccreditationsLoading: loading,
    getBaseProductsAccreditations: async () => {
      getBaseProductsAccreditations();
    },
  };
};
