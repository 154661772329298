import { ApolloError } from "@apollo/client";

import { useGetOutboundManifestByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ManifestItem } from "../types";

export interface HookResult {
  manifest?: ManifestItem;
  manifestError?: ApolloError;
  manifestLoading: boolean;
}

export const useOutboundManifestById = (manifestId: number): HookResult => {
  const { data, error, loading } = useGetOutboundManifestByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      manifestId,
    },
  });

  return {
    manifest: data && Array.isArray(data.outbound_manifest) ? data.outbound_manifest[0] : undefined,
    manifestError: error,
    manifestLoading: loading,
  };
};
