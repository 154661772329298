import { Icon } from "@sourceful/shared-components";

import { IconWrapper } from "../../../../../styles/SharedStyles";

export interface Props {
  approval: string;
}

export const ApprovedIcon = ({ approval }: Props) => {
  if (approval === "Pending") {
    return <IconWrapper withinList>-</IconWrapper>;
  }
  if (approval === "Approved") {
    return (
      <IconWrapper withinList approved>
        <Icon name="alert-tick-fill" />
      </IconWrapper>
    );
  }
  if (approval === "Rejected") {
    return (
      <IconWrapper withinList rejected>
        <Icon name="alert-cross-fill" />
      </IconWrapper>
    );
  }
  return <IconWrapper withinList></IconWrapper>;
};
