import { ProductLineInterface } from "../types";

export const emptyProductLine: ProductLineInterface = {
  product: null,
  quantity: [],
  attachments: [],
  notes: "",
};

export const fieldNames = {
  supplier: "supplier",
  rfq_type: "rfq_type",
  currency: "currency",
  notes: "notes",
  client_name: "client_name",
};

export interface RequestForQuotationForm {
  rfq_type: {
    value: number;
    label: string;
  } | null;
  supplier: {
    value: number;
    label: string;
  } | null;
  currency: {
    value: number;
    label: string;
  } | null;
  product_lines: ProductLineInterface[];
  client_name: string | null;
}

export const initialFieldValues: RequestForQuotationForm = {
  supplier: null,
  rfq_type: null,
  currency: null,
  product_lines: [],
  client_name: null,
};
