import { Formik, FormikState } from "formik";
import moment from "moment";
import * as qs from "query-string";
import { createRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import * as Yup from "yup";

import { ButtonGroup, Heading, Modal, styled } from "@sourceful/shared-components";

import Panel from "../../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleDateInput from "../../../../../../shared/components/forms/SimpleDateInput";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../../shared/components/templates/Page";
import { EnhancedAttachment } from "../../../../../../shared/types/EnhancedAttachment";
import {
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { CreateQuotesBody, CreateSupplierQuoteLinesBody, QuoteContactBody } from "../../api/types";
import { useSupplierQuoteRequest } from "../../graphql/hooks/useSupplierQuoteRequest";
import { ExtendedQuoteRequestLine } from "../../graphql/types";
import {
  createSupplierQuotesAction,
  createSupplierQuotesReset,
} from "../../redux/actions/createSupplierQuotesAction";
import { JWTSupplierQuote } from "../api/JWTSupplierQuote";
import { JWTSupplierQuoteBody, RejectRequestForQuoteBody } from "../api/types";
import { SupplierQuoteLineProductGroup } from "../components/SupplierQuoteLineProductGroup";
import { SupplierQuoteResponderDetails } from "../components/SupplierQuoteResponderDetails";
import { SupplierQuoteSubmitted } from "../components/SupplierQuoteSubmitted";
import { ExternalQuoteForm, fieldNames } from "../formValues";
import { useCommunicationMethods } from "../graphql/hooks/useCommunicationMethods";
import { useContactsByOrganisationId } from "../graphql/hooks/useContactsByOrganisationId";
import { useSupplierQuotationCostTypes } from "../graphql/hooks/useSupplierQuotationCostTypes";
import { ContactItem } from "../graphql/types";
import { groupQuoteLinesByProduct } from "../helpers/groupQuoteLinesByProduct";
import { multipleQuantityQuoteLines } from "../helpers/multipleQuantityQuoteLines";
import { rejectSupplierQuoteRequestAction } from "../redux/actions/rejectSupplierQuoteRequest";
import { Props } from "../types";

export const WhitePrimaryLineWrapper = styled(PrimaryLineWrapper, {
  backgroundColor: "white",
});

const SupplierResponse = (props: Props) => {
  const location = useLocation();
  const [loading, setIsLoading] = useState<boolean>(false);
  const [isValidToken, setIsValidToken] = useState<boolean>(false);
  const [insertContactView, setInsertContactView] = useState<boolean>(false);
  const [attachmentsWithSignedUrls, setAttachmentsWithSignedUrls] = useState<EnhancedAttachment[]>(
    []
  );
  const [quoteLines, setQuoteLines] = useState<ExtendedQuoteRequestLine[]>([]);
  const [groupedQuoteLines, setGroupedQuoteLines] = useState<ExtendedQuoteRequestLine[][]>([]);
  const [selectedContact, setSelectedContact] = useState<ContactItem | null>(null);
  const [openQuoteLine, setOpenQuoteLine] = useState<number | null>(null);
  const [showRejectModal, setShowRejectModal] = useState<boolean>(false);
  const [successfullySubmitted, setSuccessfullySubmitted] = useState<boolean>(false);
  const [linesEdited, setLinesEdited] = useState<number[]>([]);

  const rejectModalTriggerRef = createRef<HTMLButtonElement>();

  const { t } = useTranslation();
  const params = qs.parse(location.search);
  const { quotationCostTypes } = useSupplierQuotationCostTypes();

  const handleSetSelectedContact = (contact: ContactItem | null) => {
    setSelectedContact(contact);
  };

  const handleSetInsertContactView = (contactView: boolean) => {
    setInsertContactView(contactView);
  };

  const handleAddLinesEdited = (index: number) => {
    if (!linesEdited.includes(index)) {
      setLinesEdited([...linesEdited, index]);
    }
  };

  const fullValidationSchema = Yup.object({
    validFrom: Yup.string().required(t("quote_supplier_response_validation_schema_date")),
    validTo: Yup.string().required(t("quote_supplier_response_validation_schema_date")),
    contact: Yup.object({
      contactName: Yup.string().required(
        t("quote_supplier_response_validation_schema_contact_name")
      ),
      contactEmailAddress: Yup.string()
        .email(t("quote_supplier_response_validation_schema_valid_email"))
        .required(t("quote_supplier_response_validation_schema_email")),
      preferredCommunicationMethodId: Yup.number(),
      contactPhoneNumber: Yup.string(),
      contactJobTitle: Yup.string(),
      isExistingContact: Yup.boolean().required(),
    }),
    supplierQuoteLines: Yup.array()
      .of(
        Yup.object({
          productId: Yup.number().required(),
          sampleFee: Yup.number(),
          isSampleFeeRefundable: Yup.boolean().required(
            t("quote_supplier_response_validation_schema_is_sample_fee_refundable_required")
          ),
          domesticLogisticsFeeIncluded: Yup.boolean().required(
            t("quote_supplier_response_validation_schema_domestic_logistics_fee_included_required")
          ),
          packagingOption: Yup.string().required(
            t("quote_supplier_response_validation_schema_packaging_option_required")
          ),
          cartonDetails: Yup.object({
            unitPerCarton: Yup.number().optional(),
            cartonLength: Yup.number()
              .optional()
              .test(
                "noDecimals",
                t("quote_supplier_response_validation_schema_carton_length_full_number"),
                value => Number(value) % 1 === 0 || !value
              ),
            cartonWidth: Yup.number()
              .optional()
              .test(
                "noDecimals",
                t("quote_supplier_response_validation_schema_carton_width_full_number"),
                value => Number(value) % 1 === 0 || !value
              ),
            cartonHeight: Yup.number()
              .optional()
              .test(
                "noDecimals",
                t("quote_supplier_response_validation_schema_carton_height_full_number"),
                value => Number(value) % 1 === 0 || !value
              ),
            cartonWeight: Yup.number()
              .optional()
              .test(
                "noDecimals",
                t("quote_supplier_response_validation_schema_carton_weight_full_number"),
                value => Number(value) % 1 === 0 || !value
              ),
          }),
          quotationCosts: Yup.array().of(
            Yup.object({
              quotationCostTypeId: Yup.number().required(
                t("quote_supplier_response_validation_schema_quotation_cost_type")
              ),
              cost: Yup.number().required(
                t("quote_supplier_response_validation_schema_quotation_cost")
              ),
            })
          ),
          minimumOrderQuantity: Yup.number().required(
            t("quote_supplier_response_validation_schema_minimum_order_quantity_required")
          ),
          supplierQuoteRequestLineId: Yup.number().required(),
          leadTimeMaxDays: Yup.number().required(
            t("quote_supplier_response_validation_schema_lead_time_max_days_required")
          ),
          isSample: Yup.boolean().required(),
          notes: Yup.string(),
          supplierReference: Yup.string(),
        })
      )
      .min(1, t("quote_supplier_response_validation_schema_one_quote_required")),
  });

  const rejectValidationSchema = Yup.object({
    contact: Yup.object({
      contactName: Yup.string().required(
        t("quote_supplier_response_validation_schema_contact_name")
      ),
      contactEmailAddress: Yup.string()
        .email(t("quote_supplier_response_validation_schema_valid_email"))
        .required(t("quote_supplier_response_validation_schema_email")),
      preferredCommunicationMethodId: Yup.number(),
      contactPhoneNumber: Yup.string(),
      contactJobTitle: Yup.string(),
      isExistingContact: Yup.boolean().required(),
    }),
  });

  const [validationSchema, setValidationSchema] = useState<any>(fullValidationSchema);

  const defaultQuotationCosts = quotationCostTypes.filter(
    ({ is_cost_required_default }) => !!is_cost_required_default
  );
  const sampleQuotationCosts = quotationCostTypes.filter(
    ({ is_cost_required_sample }) => !!is_cost_required_sample
  );

  const emptyQuoteLine = {
    leadTimeMaxDays: "",
    isSampleFeeRefundable: false,
    domesticLogisticsFeeIncluded: undefined,
    packagingOption: "",
    cartonDetails: {
      cartonLength: "",
      cartonWidth: "",
      cartonHeight: "",
      cartonWeight: "",
      unitPerCarton: "",
    },
    quotationCosts: defaultQuotationCosts.map(cost => {
      return {
        quotationCostTypeId: cost.id,
        cost: "",
        vat: "",
        costInclVat: "",
      };
    }),
    isSample: false,
    supplierNotes: "",
    notes: "",
    supplierReference: "",
  };

  useEffect(() => {
    if (props.createSupplierQuotesState.success) {
      props.resetSupplierQuotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createSupplierQuotesState.success, props.createSupplierQuotesState.error]);

  useEffect(() => {
    setIsLoading(true);

    if (!params.token || !params.supplierQuoteRequestId) {
      dispatchInvalidToken();
    }

    const verifyJWT = async ({ token, supplierQuoteRequestId }: JWTSupplierQuoteBody) => {
      try {
        const response = await JWTSupplierQuote({
          token: token,
          supplierQuoteRequestId: supplierQuoteRequestId,
        });

        const validToken = response.status === 200;
        if (validToken) {
          if (Array.isArray(response.data?.attachments)) {
            setAttachmentsWithSignedUrls(response.data?.attachments);
          }

          dispatchValidToken();
          return;
        }
        if (!validToken) {
          dispatchInvalidToken();
        }
      } catch (e) {
        dispatchInvalidToken();
      }
    };

    if (
      params.token &&
      typeof params.token === "string" &&
      params.supplierQuoteRequestId &&
      typeof params.supplierQuoteRequestId === "string"
    ) {
      verifyJWT({ token: params.token, supplierQuoteRequestId: params.supplierQuoteRequestId });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const dispatchInvalidToken = () => {
    setIsValidToken(false);
    setIsLoading(false);
  };

  const dispatchValidToken = () => {
    setIsValidToken(true);
    setIsLoading(false);
  };

  const { communicationMethods, communicationMethodsLoading, communicationMethodsError } =
    useCommunicationMethods();

  const { supplierQuoteRequest, supplierQuoteRequestLoading, supplierQuoteRequestError } =
    useSupplierQuoteRequest(
      params.supplierQuoteRequestId ? Number(params.supplierQuoteRequestId) : 0
    );

  const { contacts, contactsLoading, contactsError, getContactsByOrganisationId } =
    useContactsByOrganisationId();

  // Set quote lines using multiple minimum order quantities
  useEffect(() => {
    if (supplierQuoteRequest?.supplier_quote_request_lines) {
      const newQuoteLines = multipleQuantityQuoteLines(
        supplierQuoteRequest?.supplier_quote_request_lines
      );
      setQuoteLines(newQuoteLines);
    }
  }, [supplierQuoteRequest?.supplier_quote_request_lines]);

  // Group quote lines by product
  useEffect(() => {
    if (quoteLines) {
      const groupedLines = groupQuoteLinesByProduct(quoteLines);
      setGroupedQuoteLines(groupedLines);
    }
  }, [quoteLines]);

  const initialFieldValues = useMemo(() => {
    return {
      validFrom: "",
      validTo: "",
      contact: {
        contactName: "",
        contactEmailAddress: "",
        isExistingContact: false,
      },
      supplierQuoteLines:
        quoteLines.map(line => {
          return {
            ...emptyQuoteLine,
            supplierQuoteRequestLineId: line.id,
            productId: line.product.id,
            minimumOrderQuantity: line.minimum_order_quantity
              ? `${line.minimum_order_quantity}`
              : "",
            notes: line.notes || "",
          };
        }) || [],
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultQuotationCosts, quoteLines]);

  useEffect(() => {
    if (supplierQuoteRequest) {
      getContactsByOrganisationId({
        organisationId: supplierQuoteRequest.organisation.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierQuoteRequest]);

  const handleSetOpenQuoteLine = (index: number | null) => {
    setOpenQuoteLine(index);
  };

  const handleRejectResponse =
    (formikProps: any) => async (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      event.preventDefault();
      const { submitForm, validateForm } = formikProps;

      const errors = await validateForm();

      if (Object.keys(errors).length > 0) {
        submitForm();
        return;
      }

      setShowRejectModal(true);
    };

  const handleRejectSubmit = async (
    values: ExternalQuoteForm,
    resetForm: (nextState?: Partial<FormikState<ExternalQuoteForm>>) => void
  ) => {
    const { contact } = values;
    props.rejectRequestForQuote(
      supplierQuoteRequest?.id!,
      {
        job_title: contact.contactJobTitle as string,
        created_by_name: contact.contactName as string,
      },
      params.token! as string
    );
  };

  const onSubmit = (
    values: ExternalQuoteForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<ExternalQuoteForm>>) => void }
  ) => {
    const momentValidFrom = moment(values.validFrom);
    const momentValidTo = moment(values.validTo);

    const validToIsLarger = momentValidTo > momentValidFrom;
    let validVat = true;

    if (!validToIsLarger) {
      toast.error(t("quote_supplier_response_valid_date_error_message"));
      return;
    }

    const quoteLines: CreateSupplierQuoteLinesBody[] = values.supplierQuoteLines.map(line => {
      const supplierQuoteLine: CreateSupplierQuoteLinesBody = {
        minimumOrderQuantity: parseInt(line.minimumOrderQuantity),
        supplierQuoteRequestLineId: Number(line.supplierQuoteRequestLineId!),
        productId: line.productId,
        domesticLogisticsFeeIncluded: line.domesticLogisticsFeeIncluded!,
        packagingOption: line.packagingOption,
        quotationCosts: line.quotationCosts.map(quotationCost => {
          const parsedVat = parseFloat(quotationCost.vat);
          if (!parsedVat || parsedVat <= 0 || parsedVat > 100) {
            validVat = false;
          }
          return {
            quotationCostTypeId: quotationCost.quotationCostTypeId!,
            cost: parseFloat(quotationCost.cost),
            vatPercentage: parseFloat(quotationCost.vat) / 100,
          };
        }),
        isSample: line.isSample,
        supplierReference: line.supplierReference,
        leadTimeMaxDays: parseInt(line.leadTimeMaxDays!),
        cartonDetails: JSON.stringify(line.cartonDetails),
        notes: line.supplierNotes,
        isSampleFeeRefundable: line.isSampleFeeRefundable || false,
      };
      return supplierQuoteLine;
    });

    if (!validVat) {
      toast.error(t("quote_supplier_response_valid_vat_error_message"));
      return;
    }

    const contactBody: QuoteContactBody = {
      contactEmailAddress: values.contact.contactEmailAddress,
      contactName: values.contact.contactName,
      isExistingContact: values.contact.isExistingContact,
    };

    if (values.contact.preferredCommunicationMethod) {
      contactBody.preferredCommunicationMethodId =
        values.contact.preferredCommunicationMethod.value;
    }
    if (values.contact.contactPhoneNumber) {
      contactBody.contactPhoneNumber = values.contact.contactPhoneNumber;
    }
    if (values.contact.contactJobTitle) {
      contactBody.contactJobTitle = values.contact.contactJobTitle;
    }

    const body: CreateQuotesBody = {
      validFrom: values.validFrom,
      validTo: values.validTo,
      currencyCode: supplierQuoteRequest?.currency_code!,
      contact: contactBody,
      supplierOrganisationId: supplierQuoteRequest?.organisation.id!,
      supplierQuoteRequestId: supplierQuoteRequest?.id!,
      supplierQuoteLines: quoteLines,
    };

    props.createSupplierQuotes(body, params.token! as string);
    resetForm();
    setQuoteLines([]);
    setGroupedQuoteLines([]);
  };

  useEffect(() => {
    if (props.createSupplierQuotesState.success) {
      setSuccessfullySubmitted(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createSupplierQuotesState.success]);

  const isLoading =
    loading ||
    supplierQuoteRequestLoading ||
    communicationMethodsLoading ||
    contactsLoading ||
    props.createSupplierQuotesState.loading ||
    props.rejectSupplierQuoteRequestState.loading;
  const isError = supplierQuoteRequestError || contactsError || communicationMethodsError;

  if (successfullySubmitted) {
    return <SupplierQuoteSubmitted />;
  }

  if (supplierQuoteRequest && isValidToken && quoteLines.length > 0) {
    return (
      <Page
        isLoading={isLoading}
        error={isError}
        title={t("quote_supplier_response_page_title")}
        hasBackButton={false}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialFieldValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {formikProps => {
              const { values, setFieldValue, handleSubmit, errors, touched, resetForm } =
                formikProps;

              const handleAddSample = (index: number) => {
                const totalIndex = groupedQuoteLines.reduce((acc, curr) => {
                  return (acc += curr.length);
                }, 0);
                const newGroupedQuoteLines = JSON.parse(JSON.stringify(groupedQuoteLines));
                const newLine = {
                  minimum_order_quantity: undefined,
                  index: totalIndex,
                  product: newGroupedQuoteLines[index][0].product,
                  notes: "",
                  is_sample: true,
                  is_sample_fee_refundable: undefined,
                  id: newGroupedQuoteLines[index][0].id,
                };

                newGroupedQuoteLines[index].push(newLine);

                setGroupedQuoteLines(newGroupedQuoteLines);

                const emptyQuoteLineValues = {
                  ...emptyQuoteLine,
                  supplierQuoteRequestLineId: newGroupedQuoteLines[index][0].id,
                  productId: newGroupedQuoteLines[index][0].product.id,
                  isSample: true,
                  isSampleFeeRefundable: undefined,
                  supplierNotes: "",
                  minimumOrderQuantity: newLine.minimum_order_quantity,
                  domesticLogisticsFeeIncluded: false,
                  packagingOption: "sample",
                  quotationCosts: sampleQuotationCosts.map(cost => {
                    return {
                      quotationCostTypeId: cost.id,
                      cost: "",
                    };
                  }),
                };

                setFieldValue(
                  `supplierQuoteLines`,
                  JSON.parse(JSON.stringify(values.supplierQuoteLines)).concat(emptyQuoteLineValues)
                );
                handleSetOpenQuoteLine(newLine.index);
                setTimeout(() => {
                  document
                    .getElementById(`line_${newLine.index}`)
                    ?.scrollIntoView({ behavior: "smooth" });
                });
              };

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DisplayBox
                      withUniqueMargin
                      value={supplierQuoteRequest.organisation.organisation_name}
                      label={t("quote_supplier_response_manufacturer")}
                    />
                    <DisplayBox
                      withUniqueMargin
                      value={supplierQuoteRequest.currency_code}
                      label={t("quote_supplier_response_requested_currency_code")}
                    />
                  </TopPanelInputsWrapper>
                  <WhitePrimaryLineWrapper>
                    <Title>{t("quote_supplier_response_quote_section_title")}</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin halfWidth>
                        <SimpleDateInput
                          isRequired
                          name={fieldNames.validFrom}
                          label={t("quote_supplier_response_quote_valid_from")}
                          value={values.validFrom}
                          changeHandler={e => setFieldValue(fieldNames.validFrom, e.target.value)}
                          error={errors.validFrom}
                          touched={touched.validFrom}
                        />
                      </DataPoint>
                      <DataPoint withUniqueMargin halfWidth>
                        <SimpleDateInput
                          isRequired
                          name={fieldNames.validTo}
                          label={t("quote_supplier_response_quote_valid_to")}
                          value={values.validTo}
                          changeHandler={e => setFieldValue(fieldNames.validTo, e.target.value)}
                          error={errors.validTo}
                          touched={touched.validFrom}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </WhitePrimaryLineWrapper>

                  {groupedQuoteLines.map((quoteRequestLines, index) => (
                    <SupplierQuoteLineProductGroup
                      key={`quoteRequestLines_${index}_${quoteRequestLines.length}}`}
                      openQuoteLine={openQuoteLine}
                      handleSetOpenQuoteLine={handleSetOpenQuoteLine}
                      attachmentsWithSignedUrls={attachmentsWithSignedUrls}
                      lines={quoteRequestLines}
                      index={index}
                      values={values}
                      errors={errors}
                      touched={touched}
                      setFieldValue={setFieldValue}
                      quotationCostTypes={quotationCostTypes}
                      handleAddSample={handleAddSample}
                      handleAddLinesEdited={handleAddLinesEdited}
                      linesEdited={linesEdited}
                      token={params.token! as string}
                    />
                  ))}

                  <SupplierQuoteResponderDetails
                    insertContactView={insertContactView}
                    values={values}
                    contacts={contacts}
                    setFieldValue={setFieldValue}
                    handleSetInsertContactView={handleSetInsertContactView}
                    handleSetSelectedContact={handleSetSelectedContact}
                    touched={touched}
                    errors={errors}
                    selectedContact={selectedContact}
                    communicationMethods={communicationMethods}
                  />

                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      appearance={"whiteButton"}
                      type="submit"
                      onClick={event => {
                        setValidationSchema(rejectValidationSchema);
                        handleRejectResponse(formikProps)(event);
                      }}
                    >
                      {t("quote_supplier_response_reject_quote_button")}
                    </SecondaryButton>
                    <SecondaryButton
                      type="button"
                      appearance="blueButton"
                      onClick={() => {
                        setValidationSchema(fullValidationSchema);
                        handleSubmit();
                      }}
                    >
                      {t("quote_supplier_response_create_quote_button")}
                    </SecondaryButton>
                  </ButtonGroup>

                  {showRejectModal && (
                    <Modal
                      id="reject-quote-modal"
                      triggerRef={rejectModalTriggerRef}
                      isOpen={showRejectModal}
                      handleClose={() => setShowRejectModal(false)}
                    >
                      <Heading level={3} fontSize={5}>
                        {t("quote_supplier_response_reject_modal_title")}
                      </Heading>
                      <p>{t("quote_supplier_response_reject_modal_message")}</p>

                      <ButtonGroup>
                        <SecondaryButton
                          appearance={"whiteButton"}
                          type="button"
                          onClick={() => setShowRejectModal(false)}
                        >
                          {t("quote_supplier_response_reject_modal_no_button")}
                        </SecondaryButton>
                        <SecondaryButton
                          type="button"
                          appearance="blueButton"
                          onClick={() => {
                            setShowRejectModal(false);
                            handleRejectSubmit(values, resetForm);
                          }}
                        >
                          {t("quote_supplier_response_reject_modal_yes_button")}
                        </SecondaryButton>
                      </ButtonGroup>
                    </Modal>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Page>
    );
  }

  return (
    <Page
      hasBackButton={false}
      isLoading={isLoading}
      title={t("quote_supplier_response_page_title")}
    >
      <Panel
        withWrapper={true}
        title={t("supplier_response_expired_link")}
        subtitle={t("quote_supplier_response_link_expired_page_instructions")}
      >
        <InnerPanelWrapper></InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createSupplierQuotesState: state.createSupplierQuotesReducer,
    rejectSupplierQuoteRequestState: state.rejectSupplierQuoteRequestReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    rejectRequestForQuote: (
      supplierQuoteRequestId: number,
      body: RejectRequestForQuoteBody,
      accessToken: string
    ) => dispatch(rejectSupplierQuoteRequestAction(supplierQuoteRequestId, body, accessToken)),
    createSupplierQuotes: (body: CreateQuotesBody, access_token: string) =>
      dispatch(createSupplierQuotesAction(body, access_token)),
    resetSupplierQuotes: () => dispatch(createSupplierQuotesReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SupplierResponse);
