import { WMS_API } from "../../../../../api/baseConfig";
import { FulfilWOBody } from "./types";

export const fulfilWorkOrderAPI = async (body: FulfilWOBody) => {
  try {
    return await WMS_API.post(`/work-orders/${body.work_order_id}/fulfil`, body);
  } catch (err) {
    console.log("Error in fulfil work Order API call", err);
    throw err;
  }
};
