import moment from "moment";
import { useHistory, useParams } from "react-router-dom";

import { IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useProductStock } from "../graphql/hooks/useProductStock";

const ViewProductStock = () => {
  const { product_stock_id } = useParams<{ product_stock_id: string }>();

  let history = useHistory();

  const { productStock, productStockLoading, productStockError } = useProductStock(
    Number(product_stock_id)
  );

  return (
    <Page error={productStockError} isLoading={productStockLoading} title={"View Product Stock"}>
      <Panel
        withWrapper
        title={`${productStock?.product.product_name} #${productStock?.id}`}
        subtitle={"View product stock details below"}
      >
        {
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DisplayBox label="Product Stock ID" value={productStock?.id} />
              <DisplayBox label="Product Name" value={productStock?.product.product_name} />
              <DisplayBox
                label="Organisation"
                value={productStock?.organisation?.organisation_name}
              />
              <DisplayBox label="Quantity" value={productStock?.quantity} />
              <DisplayBox
                label="Stock Condition"
                value={productStock?.stock_condition.stock_condition_name}
              />
              <DisplayBox
                label="Stock Status"
                value={productStock?.stock_status.stock_status_name}
              />
              <DisplayBox
                label="Last Updated"
                value={moment(productStock?.last_updated).format("DD-MM-YYYY")}
              />
              <DisplayBox
                label="Date of Recieved (by Warehouse)"
                value={moment(productStock?.inbound_manifest_fulfilment_line?.last_updated).format(
                  "DD-MM-YYYY"
                )}
              />
            </TopPanelInputsWrapper>
            <PrimaryLineWrapper>
              <Title>Location</Title>
              <PrimaryLineDataWrapper>
                <DisplayBox
                  label="Warehouse"
                  value={productStock?.location.warehouse.warehouse_name}
                />
                <DisplayBox label="Location" value={productStock?.location.location_name} />
                <DisplayBox
                  label="Location Type"
                  value={productStock?.location.location_type.location_type_name}
                />
              </PrimaryLineDataWrapper>
            </PrimaryLineWrapper>

            <PrimaryLineWrapper>
              <ButtonWrapper style={{ justifyContent: "center" }}>
                <SecondaryButton
                  type="button"
                  appearance="blueButton"
                  onClick={() => {
                    history.push(
                      `/manifests/inbound/review-inbound-manifest/${productStock?.inbound_manifest_fulfilment_line?.inbound_manifest_line.inbound_manifest.id}`
                    );
                  }}
                >
                  <IconText
                    text={"View Source Inbound Manifest"}
                    secondaryIcon={"arrow-tail-tail-right"}
                  />
                </SecondaryButton>
              </ButtonWrapper>
            </PrimaryLineWrapper>
          </InnerPanelWrapper>
        }
      </Panel>
    </Page>
  );
};

export default ViewProductStock;
