export const GRAPHQL_WMS = "wms";
export const GRAPHQL_SOURCING = "sourcing";
export const GRAPHQL_LOGISTICS = "logistics";
export const GRAPHQL_PCAT = "pcat";
export const GRAPHQL_STOCK_MANAGEMENT = "stock-management";

export const SERVICE_WMS: SERVICE_ACCOUNTS = "WMS";
export const SERVICE_LOGISTICS: SERVICE_ACCOUNTS = "Logistics";
export const SERVICE_ORDERS_ACCOUNTS: SERVICE_ACCOUNTS = "Orders";

export const SERVICE_SOURCING: SERVICE_ACCOUNTS = "Sourcing";
export const SERVICE_ADMIN: SERVICE_ACCOUNTS = "Admin";
export const SERVICE_PCAT: SERVICE_ACCOUNTS = "PCAT";
export const SERVICE_ORDERS: SERVICE_ACCOUNTS = "Orders";
export const SERVICE_STOCK_MANAGEMENT: SERVICE_ACCOUNTS = "StockManagement";
export const SERVICE_DEFAULT: SERVICE_ACCOUNTS = "DEFAULT";

export type SERVICE_ACCOUNTS =
  | "Sourcing"
  | "WMS"
  | "Orders"
  | "Logistics"
  | "Admin"
  | "PCAT"
  | "StockManagement"
  | "DEFAULT";

export const STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES = [
  { label: "Email", value: 1 },
  { label: "Phone", value: 2 },
  { label: "Post", value: 3 },
];

export const STOCK_MANAGEMENT_UK_LOCALE = "en-GB";
export const STOCK_MANAGEMENT_US_LOCALE = "en-US";
export const STOCK_MANAGEMENT_LOCALES = [STOCK_MANAGEMENT_UK_LOCALE, STOCK_MANAGEMENT_US_LOCALE];
export const STOCK_MANAGEMENT_CURRENCIES = ["USD", "GBP", "EUR"];
export const STOCK_MANAGEMENT_COUNTRY_CODES = ["US", "GB", "CN"];

export const CN_LOCALE = "zh_CN";
export const UK_LOCALE = "en_GB";
export const US_LOCALE = "en_US";
export const EU_LOCALES = [
  "nl_NL",
  "nl_BE",
  "fr_FR",
  "de_DE",
  "de_AT",
  "es_ES",
  "hr_HR",
  "it_IT",
  "el_GR",
  "el_CY",
  "cs_CZ",
  "da_DK",
  "et_EE",
  "fi_FI",
  "hu_HU",
  "en_IE",
  "lv_LV",
  "lt_LT",
  "fr_LU",
  "en_MT",
  "pl_PL",
  "pt_PT",
  "sk_SK",
  "sl_SI",
  "sv_SE",
  "bg_BG",
  "ro_RO",
];

export const SUPPLIER_LOCALES = EU_LOCALES.concat([CN_LOCALE, UK_LOCALE, US_LOCALE]);

export const SUPPLIER_CURRENCIES = ["EUR", "GBP", "USD", "CNY"];

export const SUPPLIER_CURRENCY_MAP: Record<string, string> = {
  [CN_LOCALE]: "CNY",
  [UK_LOCALE]: "GBP",
  [US_LOCALE]: "USD",
  ...EU_LOCALES.reduce((acc, key) => ({ ...acc, [key]: "EUR" }), {}),
};

export const SUPPLIER_COUNTRY_CODE_MAP: Record<string, string> = {
  [UK_LOCALE]: "UK",
  [CN_LOCALE]: "CN",
  [US_LOCALE]: "US",
  ...EU_LOCALES.reduce((acc, key) => ({ ...acc, [key]: "NL" }), {}),
};

export const PRODUCT_TYPE_ID: { [key: number]: string } = {
  1: "Warehouse",
  2: "Dropship",
  3: "Other",
};

export enum SUPPLIER_QUOTE_REQUEST_STATUS {
  PENDING = 1,
  COMPLETED = 2,
  EXPIRED = 3,
  REJECTED = 4,
}

export enum QUALITY_ASSURANCE_STATUS {
  PENDING = 1,
  PASSED = 2,
  FAILED = 3,
}
