import { ApolloError } from "@apollo/client";

import { useGetCommunicationMethodsQuery } from "../../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { mapCommunicationMethodsToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  communicationMethods: DropdownItem[];
  communicationMethodsError?: ApolloError;
  communicationMethodsLoading: boolean;
}

export const useCommunicationMethods = (): HookResult => {
  const { data, error, loading } = useGetCommunicationMethodsQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    communicationMethods:
      data && Array.isArray(data.communication_method)
        ? mapCommunicationMethodsToDropdownObject(data.communication_method)
        : [],
    communicationMethodsError: error,
    communicationMethodsLoading: loading,
  };
};
