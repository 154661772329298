import { WMS_API } from "../../../../../../api/baseConfig";
import { MarkStockCheckAsCompleteParams } from "./types";

export const markStockCheckAsCompleteAPI = async ({
  stockCheckId,
}: MarkStockCheckAsCompleteParams) => {
  try {
    return await WMS_API.patch(`stock-checks/${stockCheckId}/complete`);
  } catch (err) {
    console.log("Error in Mark stock check as Complete API call:", err);
    throw err;
  }
};
