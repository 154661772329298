import { ApolloError } from "@apollo/client";

import { useGetParentLocationNamesByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapParentLocationNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  parentLocations?: DropdownItem[];
  parentLocationsError?: ApolloError;
  parentLocationsLoading: boolean;
  getParentLocationsByWarehouseId: (options: Options) => Promise<void>;
}

export interface Options {
  warehouseId: number;
}

export const useParentLocationsByWarehouseId = (): HookResult => {
  const [getParentLocationsByWarehouseId, { data, error, loading }] =
    useGetParentLocationNamesByWarehouseIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    parentLocations:
      data && Array.isArray(data.location)
        ? mapParentLocationNamesToDropdownObject(data.location)
        : undefined,
    parentLocationsError: error,
    parentLocationsLoading: loading,
    getParentLocationsByWarehouseId: async ({ warehouseId }: Options) => {
      getParentLocationsByWarehouseId({ variables: { warehouseId } });
    },
  };
};
