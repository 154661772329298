import { FormikErrors, FormikTouched } from "formik";
import { useTranslation } from "react-i18next";

import { ButtonGroup, SecondaryButton } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { ExternalQuoteForm } from "../formValues";
import { fieldNames } from "../formValues";
import { ContactItem } from "../graphql/types";
import { DropdownItem } from "../graphql/types";
import { WhitePrimaryLineWrapper } from "../page/createSupplierResponse";

interface SupplierQuoteResponderDetailsProps {
  insertContactView: boolean;
  values: ExternalQuoteForm;
  contacts: ContactItem[] | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  handleSetSelectedContact: (contact: ContactItem | null) => void;
  touched: FormikTouched<ExternalQuoteForm>;
  errors: FormikErrors<ExternalQuoteForm>;
  selectedContact: ContactItem | null;
  communicationMethods: DropdownItem[];
  handleSetInsertContactView: (contactView: boolean) => void;
}

export const SupplierQuoteResponderDetails = ({
  insertContactView,
  values,
  contacts,
  setFieldValue,
  handleSetSelectedContact,
  touched,
  errors,
  selectedContact,
  communicationMethods,
  handleSetInsertContactView,
}: SupplierQuoteResponderDetailsProps) => {
  const { t } = useTranslation();

  return (
    <>
      <WhitePrimaryLineWrapper>
        <Title>{t("quote_supplier_response_responder_details_title")}</Title>
        <p>{t("quote_supplier_response_responder_details_instructions")}</p>
        <PrimaryLineDataWrapper>
          {!insertContactView && (
            <DataPoint withUniqueMargin>
              <SimpleSelect
                label={t("quote_supplier_response_responder_details_dropdown")}
                error={undefined}
                touched={undefined}
                maxMenuHeight={220}
                name={fieldNames.existingContact}
                value={values.contact.existingContact}
                options={
                  contacts?.map(contact => {
                    return {
                      label: contact.contact_name,
                      value: contact.id,
                    };
                  }) || []
                }
                changeHandler={e => {
                  setFieldValue(`contact.existingContact`, e);
                  const identifiedContact = contacts?.find(contact => contact.id === e?.value!)!;
                  setFieldValue(
                    `contact.contactEmailAddress`,
                    identifiedContact.contact_email_address
                  );
                  setFieldValue(`contact.contactJobTitle`, identifiedContact.contact_job_title);
                  setFieldValue(`contact.contactName`, identifiedContact.contact_name);
                  setFieldValue(
                    `contact.preferredCommunicationMethod`,
                    identifiedContact.preferred_communication_method_id
                  );
                  setFieldValue(`contact.isExistingContact`, true);
                  handleSetSelectedContact(identifiedContact);
                }}
                placeholder={`${t(
                  "quote_supplier_response_responder_details_dropdown_placeholder"
                )}`}
              />
              {touched.contact?.contactName &&
                errors.contact?.contactName &&
                !values.contact?.existingContact && (
                  <ErrorMessage>
                    {t("quote_supplier_response_responder_details_dropdown_error_message")}
                  </ErrorMessage>
                )}
            </DataPoint>
          )}
          {!insertContactView && selectedContact && (
            <>
              <DisplayBox
                withUniqueMargin
                value={selectedContact.contact_email_address}
                label={t("quote_supplier_response_email_address")}
              />
              <DisplayBox
                withUniqueMargin
                value={selectedContact.contact_phone_number}
                label={t("quote_supplier_response_phone_number")}
              />
              <DisplayBox
                withUniqueMargin
                value={selectedContact.contact_job_title}
                label={t("quote_supplier_response_job_title")}
              />
              <DisplayBox
                withUniqueMargin
                value={
                  communicationMethods.find(
                    communicationMethod =>
                      communicationMethod.value ===
                      selectedContact.preferred_communication_method_id
                  )?.label
                }
                label={t("quote_supplier_response_preferred_communication_method")}
              />
            </>
          )}
          {insertContactView && (
            <>
              <DataPoint withUniqueMargin>
                <SimpleInput
                  label={t("quote_supplier_response_full_name")}
                  error={errors.contact?.contactName}
                  touched={touched.contact?.contactName}
                  name={fieldNames.contactName}
                  size={"medium"}
                  type={"text"}
                  value={values.contact.contactName}
                  changeHandler={e => {
                    setFieldValue(`contact.contactName`, e.target.value);
                  }}
                  placeholder={t("quote_supplier_response_full_name_placeholder")}
                />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <SimpleInput
                  label={t("quote_supplier_response_email_address")}
                  error={errors.contact?.contactEmailAddress}
                  touched={touched.contact?.contactEmailAddress}
                  name={fieldNames.contactEmailAddress}
                  size={"medium"}
                  type={"email"}
                  value={values.contact.contactEmailAddress}
                  changeHandler={e => {
                    setFieldValue(`contact.contactEmailAddress`, e.target.value);
                  }}
                  placeholder={t("quote_supplier_response_email_address_placeholder")}
                />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <SimpleInput
                  label={t("quote_supplier_response_job_title")}
                  error={errors.contact?.contactJobTitle}
                  touched={touched.contact?.contactJobTitle}
                  name={fieldNames.contactJobTitle}
                  size={"medium"}
                  type={"email"}
                  value={values.contact.contactJobTitle ? values.contact.contactJobTitle : ""}
                  changeHandler={e => {
                    setFieldValue(`contact.contactJobTitle`, e.target.value);
                  }}
                  placeholder={t("supplier_response_acceptance_job_title_placeholder")}
                />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <SimpleInput
                  label={t("quote_supplier_response_phone_number")}
                  error={errors.contact?.contactPhoneNumber}
                  touched={touched.contact?.contactPhoneNumber}
                  name={fieldNames.contactPhoneNumber}
                  size={"medium"}
                  type={"number"}
                  value={values.contact.contactPhoneNumber ? values.contact.contactPhoneNumber : ""}
                  changeHandler={e => {
                    setFieldValue(`contact.contactPhoneNumber`, e.target.value);
                  }}
                  placeholder={t("quote_supplier_response_phone_number_placeholder")}
                />
              </DataPoint>
              <DataPoint withUniqueMargin>
                <SimpleSelect
                  label={t("quote_supplier_response_preferred_communication_method")}
                  error={errors.contact?.preferredCommunicationMethod}
                  touched={touched.contact?.preferredCommunicationMethod}
                  maxMenuHeight={220}
                  name={fieldNames.preferredCommunicationMethod}
                  value={communicationMethods.find(
                    method => `${method.value}` === `${values.contact.preferredCommunicationMethod}`
                  )}
                  options={communicationMethods}
                  changeHandler={e => {
                    setFieldValue(`contact.preferredCommunicationMethod`, e);
                  }}
                  placeholder={t(
                    "quote_supplier_response_preferred_communication_method_placeholder"
                  )}
                />
              </DataPoint>
            </>
          )}
        </PrimaryLineDataWrapper>
        <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
          {!insertContactView && (
            <SecondaryButton
              type="button"
              appearance="whiteButton"
              onClick={() => {
                handleSetInsertContactView(true);
                setFieldValue(`contact.existingContact`, null);
                setFieldValue(`contact.contactEmailAddress`, "");
                setFieldValue(`contact.contactJobTitle`, "");
                setFieldValue(`contact.contactName`, "");
                setFieldValue(`contact.preferredCommunicationMethod`, null);
                setFieldValue(`contact.isExistingContact`, false);
                handleSetSelectedContact(null);
              }}
            >
              {t("quote_supplier_response_missing_details_button")}
            </SecondaryButton>
          )}
          {insertContactView && (
            <SecondaryButton
              type="button"
              appearance="whiteButton"
              onClick={() => {
                handleSetInsertContactView(false);
                setFieldValue(`contact.contactEmailAddress`, "");
                setFieldValue(`contact.contactJobTitle`, "");
                setFieldValue(`contact.contactName`, "");
                setFieldValue(`contact.preferredCommunicationMethod`, null);
              }}
            >
              {t("quote_supplier_response_select_existing_contact_button")}
            </SecondaryButton>
          )}
        </ButtonGroup>
      </WhitePrimaryLineWrapper>
    </>
  );
};
