import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createInternalQuoteAPI } from "../../api/createInternalQuoteAPI";
import { CreateQuoteBody } from "../../api/types";
import {
  CREATE_INTERNAL_QUOTES_FAILED,
  CREATE_INTERNAL_QUOTES_LOADING,
  CREATE_INTERNAL_QUOTES_RESET,
  CREATE_INTERNAL_QUOTES_SUCCESS,
} from "../types";

export const createInternalQuoteLoading = () => {
  return {
    type: CREATE_INTERNAL_QUOTES_LOADING,
  };
};

export const createInternalQuoteSuccess = () => {
  return {
    type: CREATE_INTERNAL_QUOTES_SUCCESS,
  };
};

export const createInternalQuoteFailed = () => {
  return {
    type: CREATE_INTERNAL_QUOTES_FAILED,
  };
};

export const createInternalQuoteReset = () => {
  return {
    type: CREATE_INTERNAL_QUOTES_RESET,
  };
};

export const createInternalQuoteAction = (body: CreateQuoteBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating quotes", { hideProgressBar: true });
    console.log("Creating Internal Quotes - Action");
    dispatch(createInternalQuoteLoading());
    try {
      await createInternalQuoteAPI(body);
      toast.success("Created quotes successfully.");
      dispatch(createInternalQuoteSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating quotes.");
      dispatch(createInternalQuoteFailed());
    }
  };
};
