import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreatePurchaseOrderBody } from "./types";

export const createPurchaseOrderAPI = async (body: CreatePurchaseOrderBody) => {
  try {
    return await SOURCING_API.post(`/purchase-orders/`, body);
  } catch (err) {
    console.log("Error in create Purchase Order API call", err);
    throw err;
  }
};
