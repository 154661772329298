import dayjs from "dayjs";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { withinTablePadding } from "../../../../../styles/SharedStyles";
import { prettifyProductName } from "../../../shared/mappers";
import { clientNamesMapper, countryCodeFromLocale, productNamesMapper } from "../../shared/mappers";
import { PURCHASE_ORDER_STATUS_ID } from "../../updatePurchaseOrders/types";
import { ApprovedIcon } from "../components/ApprovedIcon";
import { usePurchaseOrders } from "../graphql/hooks/usePurchaseOrders";
import { PurchaseOrderItem } from "../graphql/types";
import { getApprovalStatus, getQAInspectionStatuses } from "../mappers";

export default function ViewPurchaseOrders() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { purchaseOrders, purchaseOrdersError, purchaseOrdersLoading } = usePurchaseOrders();

  const PO_ACTIVE_STATUS = [
    PURCHASE_ORDER_STATUS_ID.PENDING,
    PURCHASE_ORDER_STATUS_ID.APPROVED,
    PURCHASE_ORDER_STATUS_ID.ACCEPTED,
    PURCHASE_ORDER_STATUS_ID.IN_TRANSIT,
  ];

  const columns = useMemo(
    () => [
      {
        Header: "Locale",
        isHidden: true,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          countryCodeFromLocale(purchaseOrder.supplier_organisation?.supplier_details[0].locale),
      },
      {
        Header: "PO#",
        widthPercent: 0.4,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          `${countryCodeFromLocale(
            purchaseOrder.supplier_organisation?.supplier_details[0].locale
          )}-${purchaseOrder.purchase_order_document_uid}`,
      },
      {
        Header: "Supplier",
        widthPercent: 0.1,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          purchaseOrder.supplier_organisation?.organisation_name,
      },
      {
        Header: "Client(s)",
        widthPercent: 0.1,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) => clientNamesMapper(purchaseOrder),
        Cell: ({ row }: CellProps<PurchaseOrderItem>) => clientNamesMapper(row.original).join(", "),
      },
      {
        Header: "Created on",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          dayjs(purchaseOrder.created_at).startOf("date").toDate(),
        Cell: ({ row }: CellProps<PurchaseOrderItem>) =>
          dayjs(row.original.created_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Order Status",
        widthPercent: 0.25,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          purchaseOrder.purchase_order_status.purchase_order_status_name,
        Cell: ({ row }: CellProps<PurchaseOrderItem>) => (
          <StatusBadge
            type="sourcing"
            statusName={row.original.purchase_order_status.purchase_order_status_name}
          />
        ),
      },
      {
        Header: "Delivery Date",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          dayjs(purchaseOrder.updated_delivery_date || purchaseOrder.expected_delivery_date)
            .startOf("date")
            .toDate(),
        Cell: ({ row }: CellProps<PurchaseOrderItem>) =>
          dayjs(row.original.updated_delivery_date || row.original.expected_delivery_date).format(
            "DD-MM-YYYY"
          ),
      },
      {
        Header: "Days To ETA",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) => {
          const isActiveStatus = PO_ACTIVE_STATUS.includes(purchaseOrder.purchase_order_status.id);

          if (!isActiveStatus) return "-";
          const days = dayjs(
            purchaseOrder.updated_delivery_date || purchaseOrder.expected_delivery_date
          )
            .startOf("date")
            .diff(dayjs().startOf("date"), "day");

          return days < 0 ? "Overdue" : days.toString();
        },
        Cell: ({ row }: CellProps<PurchaseOrderItem>) => {
          const delta = dayjs(
            row.original.updated_delivery_date || row.original.expected_delivery_date
          )
            .startOf("date")
            .diff(dayjs().startOf("date"), "day");

          const isActiveStatus = PO_ACTIVE_STATUS.includes(row.original.purchase_order_status.id);

          if (!isActiveStatus) return "-";

          if (delta < 0) {
            return <StatusBadge type="sourcing" statusName={"Overdue"} />;
          }

          return delta;
        },
      },
      {
        Header: "Lines",
        widthPercent: 0.1,
        disableFilters: true,
        accessor: (purchaseOrder: PurchaseOrderItem) => purchaseOrder.purchase_order_lines.length,
      },
      {
        Header: "Product(s)",
        widthPercent: 0.2,
        filter: "arraySome",
        previewLongColumn: true,
        accessor: (purchaseOrder: PurchaseOrderItem) => productNamesMapper(purchaseOrder),
        Cell: ({ row }: CellProps<PurchaseOrderItem>) =>
          prettifyProductName(row.original.purchase_order_lines[0].product.product_name)
            .substring(0, 35)
            .concat("..."),
      },
      {
        Header: "Total Qty",
        widthPercent: 0.2,
        filter: "arraySome",
        disableFilters: true,
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          purchaseOrder.purchase_order_lines.reduce((total, line) => total + line.quantity, 0),
      },
      {
        Header: "Total (excl. VAT)",
        widthPercent: 0.2,
        filter: "arraySome",
        disableFilters: true,
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          `${purchaseOrder.purchase_order_lines[0].supplier_quote_line.currency_code}
           ${purchaseOrder.purchase_order_lines
             .reduce(
               (total, line) => total + line.quantity * (line.calculated_quotation_cost as number),
               0
             )
             .toLocaleString("en-US", {
               minimumFractionDigits: 2,
               maximumFractionDigits: 2,
             })}`,
      },
      {
        Header: "Dielines",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          getApprovalStatus(purchaseOrder.purchase_order_approvals, "Dielines"),
        Cell: ({ row }: CellProps<PurchaseOrderItem>) => {
          const approval = getApprovalStatus(row.original.purchase_order_approvals, "Dielines");
          return <ApprovedIcon approval={approval} />;
        },
      },
      {
        Header: "QC",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (purchaseOrder: PurchaseOrderItem) =>
          getQAInspectionStatuses(purchaseOrder.purchase_order_lines),
        Cell: ({ row }: CellProps<PurchaseOrderItem>) => {
          const qaStatus = getQAInspectionStatuses(row.original.purchase_order_lines);
          return <ApprovedIcon approval={qaStatus} />;
        },
      },
      {
        Header: "Update",
        widthPercent: 0.2,
        disableFilters: true,
        Cell: ({ row }: CellProps<PurchaseOrderItem>) => (
          <Link to={`/purchase-orders/update-purchase-order/${row.original.id}`}>
            <SecondaryButton
              className={withinTablePadding()}
              appearance={"blueButton"}
              isWithinTable
            >
              Update
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const items = SOURCING_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => history.push("/purchase-orders/create-purchase-order"),
          type: "button",
          title: "Create Purchase Order",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];
  return (
    <Page title={"Purchase Orders"} isLoading={purchaseOrdersLoading} error={purchaseOrdersError}>
      <Panel
        withWrapper
        title={"Purchase Orders List"}
        allignTitle="left"
        rightItems={rightItems}
        iconName={"products-box"}
      >
        <Table
          data={purchaseOrders}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
          filterToggles={[]}
        />
      </Panel>
    </Page>
  );
}
