import { Box, DataPoint, Label } from "../../../../styles/SharedStyles";
import { ToolTip } from "../../atoms/labels/ToolTip/ToolTip";

export interface Props {
  label: string;
  value: string | number | undefined | null;
  isGray?: boolean;
  isRequired?: boolean;
  tooltipMessage?: string;
  unknownString?: string;
  withUniqueMargin?: boolean;
  fullWidth?: boolean;
}

export const DisplayBox = ({
  label,
  value,
  tooltipMessage,
  withUniqueMargin,
  isGray,
  isRequired,
  fullWidth,
  unknownString = "Not specified",
}: Props) => {
  return (
    <DataPoint withUniqueMargin={withUniqueMargin} fullWidth={fullWidth}>
      {tooltipMessage ? (
        <ToolTip isGray={isGray} isRequired={isRequired} message={tooltipMessage} title={label} />
      ) : (
        <Label isGray={isGray} isRequired={isRequired}>
          {label}
        </Label>
      )}
      <Box type={fullWidth ? "fullWidth" : undefined}>{value ? value : unknownString}</Box>
    </DataPoint>
  );
};
