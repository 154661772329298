import { ApolloError } from "@apollo/client";

import { useGetStockTransferProductStockByLocationIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStock: ProductStockItem[];
  productStockError?: ApolloError;
  productStockLoading: boolean;
  getProductStock: (options: Options) => Promise<void>;
}

interface Options {
  locationId: number;
}

export const useProductStockByLocationId = (): HookResult => {
  const [getProductStock, { data, error, loading }] =
    useGetStockTransferProductStockByLocationIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    productStock: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    productStockError: error,
    productStockLoading: loading,
    getProductStock: async ({ locationId }: Options) => {
      getProductStock({ variables: { locationId } });
    },
  };
};
