import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { markAsDeliveredPurchaseOrderAPI } from "../../api/markAsDeliveredPurchaseOrder";
import {
  MARK_AS_DELIVERED_PURCHASE_ORDER_FAILED,
  MARK_AS_DELIVERED_PURCHASE_ORDER_LOADING,
  MARK_AS_DELIVERED_PURCHASE_ORDER_RESET,
  MARK_AS_DELIVERED_PURCHASE_ORDER_SUCCESS,
} from "../types";

export const markAsDeliveredPurchaseOrderLoading = () => {
  return {
    type: MARK_AS_DELIVERED_PURCHASE_ORDER_LOADING,
  };
};

export const markAsDeliveredPurchaseOrderSuccess = () => {
  return {
    type: MARK_AS_DELIVERED_PURCHASE_ORDER_SUCCESS,
  };
};

export const markAsDeliveredPurchaseOrderFailed = () => {
  return {
    type: MARK_AS_DELIVERED_PURCHASE_ORDER_FAILED,
  };
};

export const markAsDeliveredPurchaseOrderReset = () => {
  return {
    type: MARK_AS_DELIVERED_PURCHASE_ORDER_RESET,
  };
};

export const markAsDeliveredPurchaseOrderAction = (
  purchase_order_id: number,
  delivery_date: string
) => {
  return async (dispatch: Dispatch) => {
    toast("Marking as Delivered", { hideProgressBar: true });
    dispatch(markAsDeliveredPurchaseOrderLoading());
    try {
      await markAsDeliveredPurchaseOrderAPI(purchase_order_id, delivery_date);
      toast.success("Order marked as 'Delivered' successfully");
      dispatch(markAsDeliveredPurchaseOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with marking this order as delivered.");
      dispatch(markAsDeliveredPurchaseOrderFailed());
    }
  };
};
