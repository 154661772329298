import {
  CREATE_INBOUND_MANIFEST_FAILURE,
  CREATE_INBOUND_MANIFEST_RESET,
  CREATE_INBOUND_MANIFEST_SUCCESS,
  CREATING_INBOUND_MANIFEST,
} from "../types";

export interface CreateInboundManifestReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateInboundManifestReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createInboundManifestReducer(
  state: CreateInboundManifestReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATING_INBOUND_MANIFEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_INBOUND_MANIFEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_INBOUND_MANIFEST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_INBOUND_MANIFEST_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
