import { Formik, FormikErrors, FormikProps } from "formik";
import * as qs from "query-string";
import { createRef, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";

import { ButtonGroup, Heading, IconText, Modal } from "@sourceful/shared-components";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import AttachmentUploader from "../../../../../components/AttachmentUploader/AttachmentUploader";
import Panel from "../../../../../components/panel/panel";
import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { QUALITY_ASSURANCE_STATUS } from "../../../../../shared/constants";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { emptyAttachment } from "../../../shared/constants";
import { createQAInspectionAPI } from "../api/createQAInspection";
import { finaliseQAInspectionAPI } from "../api/finaliseQAInspection";
import { CreateQAInspectionBody, UpdateQAInspectionBody } from "../api/types/index";
import { updateQAInspectionAPI } from "../api/updateQAInspection";
import { QCInspectionLine } from "../components/QCInspectionLine";
import * as copy from "../copy";
import {
  emptyInspectionLine,
  fieldNames,
  getInitialValues,
  initialFieldValues,
} from "../formValues";
import { usePurchaseOrderLineQAInspection } from "../graphql/hooks/usePurchaseOrderLineQAInspection";
import { useQAInspectionStatuses } from "../graphql/hooks/useQAInspectionStatuses";
import { useQAInspectionTypes } from "../graphql/hooks/useQAInspectionTypes";
import { mapAttachmentsToDTO, mapInspectionLinesToDTO } from "../mappers";
import { InspectionLineInterface, QualityAssuranceInspectionInterface } from "../types/index";
import { inspectionValidationSchema } from "../validation/schema";

function CreateQCInspection() {
  const [initialValues, setInitialValues] =
    useState<QualityAssuranceInspectionInterface>(initialFieldValues);
  const [isAddingInspectionLines, setIsAddingInspectionLines] = useState<boolean>(false);
  const [finaliseQcModalOpen, setFinaliseQcModalOpen] = useState<boolean>(false);
  const finaliseQcModalRef = createRef<HTMLButtonElement>();

  const location = useLocation();
  const params = qs.parse(location.search);

  const [updateQAInspectionState, updateQAInspection] = useAPI(updateQAInspectionAPI, true);
  const [createQAInspectionState, createQAInspection] = useAPI(createQAInspectionAPI, true);
  const [finaliseQAInspectionState, finaliseQAInspection] = useAPI(finaliseQAInspectionAPI, true);

  const { qaInspectionTypes, qaInspectionTypesLoading, qaInspectionTypesError } =
    useQAInspectionTypes();

  const { qaInspectionStatuses, qaInspectionStatusesLoading, qaInspectionStatusesError } =
    useQAInspectionStatuses();

  const {
    purchaseOrderLineQAInspection,
    purchaseOrderLineQAInspectionLoading,
    purchaseOrderLineQAInspectionError,
    refetchPurchaseOrderLineQAInspection,
  } = usePurchaseOrderLineQAInspection({
    purchaseOrderLineId: Number(params.purchaseOrderLineId),
  });

  const onSubmit = async (formikProps: FormikProps<QualityAssuranceInspectionInterface>) => {
    const { values, submitForm, validateForm } = formikProps;

    const errors = await validateForm();

    if (Object.keys(errors).length > 0) {
      return;
    }
    submitForm();

    const inspectionLines = mapInspectionLinesToDTO(values.inspection_lines, qaInspectionStatuses);
    const attachments = mapAttachmentsToDTO(values.attachments);

    if (values.qa_inspection_id) {
      const updateQAInspectionPayload: UpdateQAInspectionBody = {
        inspectionLines: inspectionLines,
        attachments: attachments,
      };
      updateQAInspection({
        qaInspectionId: values.qa_inspection_id,
        body: updateQAInspectionPayload,
      });
    } else {
      const createQAInspectionPayload: CreateQAInspectionBody = {
        purchaseOrderLineId: values.purchase_order_line_id,
        qaInspectionNotes: values.qa_inspection_notes,
        attachments: attachments,
        inspectionLines: inspectionLines,
      };
      createQAInspection(createQAInspectionPayload);
    }
  };

  useEffect(() => {
    if (createQAInspectionState === API_REQUEST_STATE.SUCCESS) {
      toast.success("QC Inspection created successfully");
      setInitialValues({ ...initialFieldValues });
      refetchPurchaseOrderLineQAInspection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQAInspectionState]);

  useEffect(() => {
    if (updateQAInspectionState === API_REQUEST_STATE.SUCCESS) {
      toast.success("QC Inspection updated successfully");
      setInitialValues({ ...initialFieldValues });
      refetchPurchaseOrderLineQAInspection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateQAInspectionState]);

  useEffect(() => {
    if (finaliseQAInspectionState === API_REQUEST_STATE.SUCCESS) {
      toast.success("QC Inspection finalised successfully");
      setInitialValues({ ...initialFieldValues });
      refetchPurchaseOrderLineQAInspection();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [finaliseQAInspectionState]);

  useEffect(() => {
    if (purchaseOrderLineQAInspection) {
      const inVal = getInitialValues(purchaseOrderLineQAInspection);
      setInitialValues(inVal);
    }
    setIsAddingInspectionLines(true);
  }, [purchaseOrderLineQAInspection]);

  const error =
    qaInspectionTypesError ||
    qaInspectionStatusesError ||
    purchaseOrderLineQAInspectionError ||
    !params.purchaseOrderLineId;

  const actionLoading =
    createQAInspectionState === API_REQUEST_STATE.PENDING ||
    updateQAInspectionState === API_REQUEST_STATE.PENDING;

  const isLoading =
    qaInspectionTypesLoading ||
    qaInspectionStatusesLoading ||
    purchaseOrderLineQAInspectionLoading ||
    actionLoading;

  const QAInspection = purchaseOrderLineQAInspection?.quality_assurance_inspections[0];
  const Folder = purchaseOrderLineQAInspection?.folder;
  const QAInspectionAttachments =
    purchaseOrderLineQAInspection?.quality_assurance_inspections[0]?.folder?.attachments;

  const finaliseQcInspection = () => {
    if (
      purchaseOrderLineQAInspection &&
      purchaseOrderLineQAInspection.quality_assurance_inspections[0]?.quality_assurance_status
        .id === QUALITY_ASSURANCE_STATUS.PASSED
    ) {
      setFinaliseQcModalOpen(true);
    } else {
      toast.error("QC Checks not passed");
    }
  };

  const handleFinaliseQcInspection = () => {
    if (
      purchaseOrderLineQAInspection &&
      purchaseOrderLineQAInspection.quality_assurance_inspections[0]
    ) {
      finaliseQAInspection({
        qaInspectionId: purchaseOrderLineQAInspection.quality_assurance_inspections[0].id,
        purchaseOrderLineId: Number(params.purchaseOrderLineId),
      });
    }
  };

  const qcInspectionActionsDisabled =
    purchaseOrderLineQAInspection &&
    purchaseOrderLineQAInspection.quality_assurance_inspections.length > 0 &&
    purchaseOrderLineQAInspection.quality_assurance_inspections[0].is_finalised;

  return (
    <Page title={copy.PAGE_TITLE} isLoading={isLoading} error={error}>
      <>
        <Panel
          withWrapper={true}
          title={`Inspect ${purchaseOrderLineQAInspection?.product.product_name} `}
          subtitle={copy.PAGE_SUBTITLE}
        >
          <InnerPanelWrapper>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={inspectionValidationSchema}
              onSubmit={() => {}}
            >
              {formikProps => {
                const { values, setFieldValue, handleSubmit, errors, touched, handleReset } =
                  formikProps;
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <TopPanelInputsWrapper>
                      <DataPoint halfWidth>
                        <ToolTip
                          message={copy.NOTES_TOOLTIP}
                          title={"QC Inspection Notes:"}
                          htmlFor={fieldNames.qa_inspection_notes}
                        />
                        <Box style={{ width: "100%", height: "100px" }}>
                          {QAInspection?.notes || copy.DEFAULT_NOTES}
                        </Box>
                      </DataPoint>
                      <DataPoint halfWidth>
                        <PrimaryLineWrapper>
                          <Title>Product Attachments</Title>
                          {Folder?.attachments.length === 0 && <>{copy.DEFAULT_ATTACHMENTS}</>}
                          {Folder?.attachments.map((attachment, index) => (
                            <SecondaryLineDataWrapper key={attachment.id}>
                              <AttachmentDownloader
                                key={index}
                                originalFilename={attachment.original_filename}
                                attachmentUUID={attachment.attachment_uuid}
                                metadata={attachment.metadata}
                              />
                            </SecondaryLineDataWrapper>
                          ))}
                        </PrimaryLineWrapper>
                      </DataPoint>
                    </TopPanelInputsWrapper>

                    {!isAddingInspectionLines && (
                      <VisibleFor roles={SOURCING_EDIT_ROLES}>
                        <ButtonWrapper>
                          <SecondaryButton
                            disabled={qcInspectionActionsDisabled}
                            type="button"
                            onClick={() => {
                              setInitialValues({
                                ...values,
                                inspection_lines: [emptyInspectionLine],
                              });
                              setIsAddingInspectionLines(true);
                            }}
                            appearance={"whiteButtonBlueText"}
                          >
                            <IconText
                              text={"Add Inspection Line"}
                              primaryIcon={"alert-add-outline"}
                            />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </VisibleFor>
                    )}

                    {isAddingInspectionLines && (
                      <>
                        <PrimaryLineWrapper>
                          <Title>{copy.PRIMARY_LINE_TITLE}</Title>
                          <>
                            {values.inspection_lines.map((value, index) => {
                              return (
                                <QCInspectionLine
                                  key={index}
                                  values={value}
                                  errors={
                                    errors.inspection_lines as FormikErrors<
                                      InspectionLineInterface[]
                                    >
                                  }
                                  touched={touched.inspection_lines}
                                  index={index}
                                  inspectionTypeData={qaInspectionTypes.map(type => ({
                                    value: type.id,
                                    label: type.quality_assurance_inspection_type_name,
                                  }))}
                                  setFieldValue={setFieldValue}
                                  handleRemoveInspectionLine={(index: number) => {
                                    const fitleredInspectionLines = values.inspection_lines.filter(
                                      (_item, i) => i !== index
                                    );
                                    const newValues = { ...values };
                                    newValues.inspection_lines = fitleredInspectionLines;
                                    if (fitleredInspectionLines.length === 0) {
                                      setInitialValues(newValues);
                                      setIsAddingInspectionLines(false);
                                    }
                                    setInitialValues(newValues);
                                  }}
                                />
                              );
                            })}
                          </>

                          <VisibleFor roles={SOURCING_EDIT_ROLES}>
                            <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                              <SecondaryButton
                                disabled={qcInspectionActionsDisabled}
                                type="button"
                                appearance={"whiteButtonBlueText"}
                                onClick={() => {
                                  setInitialValues({
                                    ...values,
                                    inspection_lines: [
                                      ...values.inspection_lines,
                                      emptyInspectionLine,
                                    ],
                                  });
                                }}
                              >
                                <IconText
                                  text={"Add Inspection Line"}
                                  primaryIcon={"alert-add-outline"}
                                />
                              </SecondaryButton>
                            </ButtonWrapper>
                          </VisibleFor>
                        </PrimaryLineWrapper>

                        <PrimaryLineWrapper style={{ flexDirection: "row" }}>
                          <Title>Attachments</Title>
                          <DataPoint halfWidth>
                            {QAInspectionAttachments &&
                              QAInspectionAttachments.map(
                                (inspectionAttachment, index_attachment) => {
                                  return (
                                    <AttachmentDownloader
                                      key={index_attachment}
                                      originalFilename={inspectionAttachment.original_filename}
                                      attachmentUUID={inspectionAttachment.attachment_uuid}
                                      metadata={inspectionAttachment.metadata}
                                    />
                                  );
                                }
                              )}
                          </DataPoint>

                          <DataPoint fullWidth>
                            {values.attachments.map((attachment, index_attachment) => {
                              return (
                                <AttachmentUploader
                                  key={index_attachment}
                                  indexAttachment={index_attachment}
                                  parameterName={`attachments`}
                                  setFieldValue={setFieldValue}
                                  handleRemoveAttachmentLine={(index_attachment: number) => {
                                    const filteredAttachments = values.attachments.filter(
                                      (_item, i) => i !== index_attachment
                                    );
                                    setFieldValue(`attachments`, filteredAttachments);
                                  }}
                                />
                              );
                            })}

                            <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                              <SecondaryButton
                                disabled={qcInspectionActionsDisabled}
                                type="button"
                                onClick={() => {
                                  const attachments: UploadedFile[] = [...values.attachments];
                                  attachments.push({ ...emptyAttachment });
                                  setFieldValue(`attachments`, attachments);
                                }}
                                appearance={"whiteButtonBlueText"}
                              >
                                <IconText
                                  text={"Upload New Attachment"}
                                  primaryIcon={"alert-add-outline"}
                                />
                              </SecondaryButton>
                            </ButtonWrapper>
                          </DataPoint>
                        </PrimaryLineWrapper>

                        <VisibleFor roles={SOURCING_EDIT_ROLES}>
                          <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                            <SecondaryButton
                              appearance={"whiteButton"}
                              onClick={() => {
                                handleReset();
                                setInitialValues({ ...initialFieldValues });
                                setIsAddingInspectionLines(false);
                              }}
                            >
                              Cancel
                            </SecondaryButton>
                            <SecondaryButton
                              disabled={qcInspectionActionsDisabled}
                              appearance={"blueButton"}
                              type="submit"
                              onClick={_e => {
                                onSubmit(formikProps);
                              }}
                            >
                              Update
                            </SecondaryButton>
                          </ButtonGroup>
                        </VisibleFor>
                      </>
                    )}
                  </StyledForm>
                );
              }}
            </Formik>
          </InnerPanelWrapper>
        </Panel>
        {!qcInspectionActionsDisabled && (
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <SecondaryButton onClick={finaliseQcInspection}>Finalise</SecondaryButton>
          </div>
        )}
        <Modal
          id="finalise-qc-inspection-modal"
          isOpen={finaliseQcModalOpen}
          handleClose={() => setFinaliseQcModalOpen(false)}
          triggerRef={finaliseQcModalRef}
        >
          {finaliseQcModalOpen && (
            <div style={{ padding: "20px" }}>
              <Heading level={3} fontSize={4}>
                Finalise QC Inspection
              </Heading>
              <div>
                <p>
                  Are you sure you want to finalise this QC Inspection? This action cannot be
                  undone.
                </p>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <SecondaryButton onClick={() => setFinaliseQcModalOpen(false)}>
                    Cancel
                  </SecondaryButton>
                  <SecondaryButton
                    onClick={() => {
                      handleFinaliseQcInspection();
                      setFinaliseQcModalOpen(false);
                    }}
                    style={{ marginLeft: "10px" }}
                  >
                    Finalise
                  </SecondaryButton>
                </div>
              </div>
            </div>
          )}
        </Modal>
      </>
    </Page>
  );
}

export default CreateQCInspection;
