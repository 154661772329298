import { FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { Icon, IconText } from "@sourceful/shared-components";

import SecondaryButton from "../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../shared/components/atoms/labels/ErrorMessage/ErrorMessage";
import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../shared/components/forms/SimpleTextArea";
import { IndexPill } from "../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  ButtonWrapper,
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  WMSButtonGroup,
} from "../../../../styles/SharedStyles";
import { getProductDetailApi } from "../api/getProductDetails";
import { NO_OPTION_INDEX, YES_OPTION_INDEX, boolOptions, fieldObjects } from "../formValues";
import { CreateOrderForm, ProductCost } from "../types";
import CurrencySimpleInput from "./InputWithCurrency";

export interface ProductCostLineProps {
  values: ProductCost;
  index: number;
  errors: FormikErrors<ProductCost[]> | undefined;
  touched: FormikTouched<ProductCost>[] | undefined;
  handleRemoveQuoteLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  currency: string;
  formValues: CreateOrderForm;
  cnVatRate?: number;
}

const UpdateProductCostLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveQuoteLine,
  errors,
  touched,
  currency,
  formValues,
  cnVatRate,
}: ProductCostLineProps) => {
  const [vat, setVat] = useState(values.vat ? values.vat : boolOptions[NO_OPTION_INDEX]);

  useEffect(() => {
    const total = values.qty * values.unit_cost;
    const default_vat_rate = 1.2;
    let total_with_vat = total;

    if (cnVatRate) {
      total_with_vat = total * cnVatRate;

      if (cnVatRate === 1) {
        setVat(boolOptions[NO_OPTION_INDEX]);
        setFieldValue(
          fieldObjects.product_costs(index).vat.fieldName,
          boolOptions[NO_OPTION_INDEX]
        );
      } else {
        setVat(boolOptions[YES_OPTION_INDEX]);
        setFieldValue(
          fieldObjects.product_costs(index).vat.fieldName,
          boolOptions[YES_OPTION_INDEX]
        );
      }
    } else if (vat.label === "YES") {
      total_with_vat = total * default_vat_rate;
    }

    setFieldValue(fieldObjects.product_costs(index).total.fieldName, total);
    setFieldValue(fieldObjects.product_costs(index).total_with_vat.fieldName, total_with_vat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.qty, values.unit_cost, values.vat, cnVatRate]);

  useEffect(() => {
    // TODO: FIX THIS STATE MANAGEMENT ON sourceful_entity_eu
    // if (formValues.sourceful_entity_eu.tax_type.label === "NLEU") {
    console.log(formValues.billing_address.country_name.label);
    console.log(currency);
    if (formValues.billing_address.country_name.label !== "NL" && currency === "EUR") {
      console.log(formValues.billing_address.country_name.label);
      console.log(currency);
      setVat(boolOptions[NO_OPTION_INDEX]);
      setFieldValue(fieldObjects.product_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);
      return;
    }

    if (currency === "USD" || formValues.sourceful_entity_uk.vat_exempt.label === "YES") {
      setVat(boolOptions[NO_OPTION_INDEX]);
      setFieldValue(fieldObjects.product_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);
      return;
    }

    if (formValues.sourceful_entity_uk.outside_uk.label === "NO") {
      setVat(boolOptions[YES_OPTION_INDEX]);
      setFieldValue(fieldObjects.product_costs(index).vat.fieldName, boolOptions[YES_OPTION_INDEX]);
      return;
    }
    setVat(boolOptions[NO_OPTION_INDEX]);
    setFieldValue(fieldObjects.product_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currency,
    formValues.sourceful_entity_uk.vat_exempt,
    formValues.sourceful_entity_uk.outside_uk,
  ]);

  const handleFetchProductDetails = async (setFieldValue: any, values: ProductCost) => {
    try {
      const res = await getProductDetailApi(values.sku_id);
      setFieldValue(
        fieldObjects.product_costs(index).cost_description.fieldName,
        res.data.data.client_name
      );

      setFieldValue(
        fieldObjects.product_costs(index).base_product_name.fieldName,
        res.data.data.base_product_version.base_product.name
      );

      setFieldValue(
        fieldObjects.product_costs(index).base_product_id.fieldName,
        res.data.data.base_product_version.base_product_id
      );

      setFieldValue(
        fieldObjects.product_costs(index).base_product_version.fieldName,
        res.data.data.base_product_version.version_id
      );

      setFieldValue(
        fieldObjects.product_costs(index).content_attributes.fieldName,
        res.data.data.content_attributes
      );
      setFieldValue(fieldObjects.product_costs(index).artworks.fieldName, res.data.data.artworks);
    } catch (error) {
      toast.error(`error fetching product details(${values.sku_id}): ${error}`);
    }
  };

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
        <SecondaryButton
          disabled={!values.sku_id}
          type="button"
          appearance={"blueButton"}
          onClick={async () => {
            await handleFetchProductDetails(setFieldValue, values);
          }}
        >
          <IconText
            text={"Fetch product details"}
            // primaryIcon={"alert-add-outline"}
          />
        </SecondaryButton>
      </ButtonWrapper>
      {errors && !Array.isArray(errors) ? <ErrorMessage>{errors}</ErrorMessage> : null}
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint halfWidth>
          <SimpleInput
            htmlFor={fieldObjects.product_costs(index).sku_id.fieldName}
            name={fieldObjects.product_costs(index).sku_id.fieldName}
            type={"text"}
            placeholder={fieldObjects.product_costs(index).sku_id.fieldPlaceholder}
            value={values.sku_id}
            error={
              Array.isArray(errors) && errors[index]?.sku_id ? errors[index]!.sku_id : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.sku_id}
            label={fieldObjects.product_costs(index).sku_id.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.product_costs(index).sku_id.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint halfWidth>
          <SimpleTextArea
            name={fieldObjects.product_costs(index).cost_description.fieldName}
            type={"text"}
            placeholder={fieldObjects.product_costs(index).cost_description.fieldPlaceholder}
            value={values.cost_description}
            errors={
              Array.isArray(errors) && errors[index]?.cost_description
                ? [errors[index]!.cost_description]
                : []
            }
            label={fieldObjects.product_costs(index).cost_description.fieldLabel}
            handleChange={e => {
              setFieldValue(
                fieldObjects.product_costs(index).cost_description.fieldName,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            htmlFor={fieldObjects.product_costs(index).qty.fieldName}
            name={fieldObjects.product_costs(index).qty.fieldName}
            type={"number"}
            placeholder={fieldObjects.product_costs(index).qty.fieldPlaceholder}
            value={values.qty}
            error={Array.isArray(errors) && errors[index]?.qty ? errors[index]!.qty : undefined}
            touched={Array.isArray(touched) && touched[index]?.qty}
            label={fieldObjects.product_costs(index).qty.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.product_costs(index).qty.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            htmlFor={fieldObjects.product_costs(index).unit_cost.fieldName}
            name={fieldObjects.product_costs(index).unit_cost.fieldName}
            type={"number"}
            placeholder={fieldObjects.product_costs(index).unit_cost.fieldPlaceholder}
            value={values.unit_cost}
            error={
              Array.isArray(errors) && errors[index]?.unit_cost
                ? errors[index]!.unit_cost
                : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.unit_cost}
            label={`${fieldObjects.product_costs(index).unit_cost.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.product_costs(index).unit_cost.fieldName, e.target.value);
            }}
          />
        </DataPoint>

        <DataPoint>
          <SimpleSelect
            isRequired
            isDisabled
            htmlFor={fieldObjects.product_costs(index).vat.fieldName}
            name={fieldObjects.product_costs(index).vat.fieldName}
            placeholder="VAT"
            options={boolOptions}
            value={values.vat}
            error={
              Array.isArray(errors) && errors[index]?.vat?.label
                ? errors[index]?.vat?.label
                : undefined
            }
            touched={
              Array.isArray(touched) && touched[index]?.vat?.label
                ? touched[index]?.vat?.label
                : undefined
            }
            label="VAT"
            changeHandler={e => setFieldValue(fieldObjects.product_costs(index).vat.fieldName, e)}
          />
        </DataPoint>

        <DataPoint>
          <CurrencySimpleInput
            htmlFor={fieldObjects.product_costs(index).total.fieldName}
            isDisabled
            name={fieldObjects.product_costs(index).total.fieldName}
            type={"text"}
            placeholder={fieldObjects.product_costs(index).total.fieldPlaceholder}
            value={values.total}
            error={Array.isArray(errors) && errors[index]?.total ? errors[index]!.total : undefined}
            touched={Array.isArray(touched) && touched[index]?.total}
            label={`${fieldObjects.product_costs(index).total.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.product_costs(index).total.fieldName, e.target.value);
            }}
            currency={currency}
          />
        </DataPoint>
        <DataPoint>
          <CurrencySimpleInput
            htmlFor={fieldObjects.product_costs(index).total_with_vat.fieldName}
            name={fieldObjects.product_costs(index).total_with_vat.fieldName}
            isDisabled
            type={"text"}
            placeholder={fieldObjects.product_costs(index).total_with_vat.fieldPlaceholder}
            value={values.total_with_vat}
            error={
              Array.isArray(errors) && errors[index]?.total_with_vat
                ? errors[index]!.total_with_vat
                : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.total_with_vat}
            label={`${fieldObjects.product_costs(index).total_with_vat.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.product_costs(index).total_with_vat.fieldName,
                e.target.value
              );
            }}
            currency={currency}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>
      <DataPoint halfWidth containsButton>
        <IconWrapper
          type="button"
          onClick={() => {
            handleRemoveQuoteLine(index);
          }}
        >
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DataPoint>
    </SecondaryLineWrapper>
  );
};

export default UpdateProductCostLine;
