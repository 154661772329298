import {
  CREATE_OUTBOUND_MANIFEST_FAILURE,
  CREATE_OUTBOUND_MANIFEST_RESET,
  CREATE_OUTBOUND_MANIFEST_SUCCESS,
  CREATING_OUTBOUND_MANIFEST,
} from "../types";

export interface CreateOutboundManifestReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateOutboundManifestReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createOutboundManifestReducer(
  state: CreateOutboundManifestReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATING_OUTBOUND_MANIFEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_OUTBOUND_MANIFEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_OUTBOUND_MANIFEST_FAILURE:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_OUTBOUND_MANIFEST_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
