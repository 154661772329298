import * as Yup from "yup";

export const inspectionValidationSchema = Yup.object({
  inspection_lines: Yup.array().of(
    Yup.object({
      qa_inspection_type: Yup.object()
        .shape({ value: Yup.number().required("Inspection type is required") })
        .typeError("Inspection type is required"),
      qa_inspection_line_status: Yup.string()
        .required("Status is required")
        .typeError("Status is required"),
    })
  ),
});
