import { SOURCING_API } from "../../../../../api/baseConfig";

export const markAsDeliveredPurchaseOrderAPI = async (
  purchaseOrderId: number,
  deliveryDate: string
) => {
  try {
    return await SOURCING_API.patch(`/purchase-orders/${purchaseOrderId}/mark-as-delivered`, {
      purchase_order_id: purchaseOrderId,
      delivery_date: deliveryDate,
    });
  } catch (err) {
    console.log("Error in marking as delivered purchase order API call", err);
    throw err;
  }
};
