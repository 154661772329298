import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { cancelOutboundManifestAPI } from "../../api/terminateOutboundManifest/cancelOutboundManifestAPI";
import { completeOutboundManifestAPI } from "../../api/terminateOutboundManifest/completeOutboundManifestAPI";
import { rejectOutboundManifestAPI } from "../../api/terminateOutboundManifest/rejectOutboundManifestAPI";
import {
  CHANGE_OBM_STATUS,
  TERMINATE_OUTBOUND_MANIFEST_FAILED,
  TERMINATE_OUTBOUND_MANIFEST_LOADING,
  TERMINATE_OUTBOUND_MANIFEST_RESET,
  TERMINATE_OUTBOUND_MANIFEST_SUCCESS,
} from "../types";

export const terminatingOutboundManifest = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_LOADING,
  };
};

export const terminateOutboundManifestSuccess = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_SUCCESS,
  };
};

export const terminateOutboundManifestFailed = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_FAILED,
  };
};

export const terminateOutboundManifestReset = () => {
  return {
    type: TERMINATE_OUTBOUND_MANIFEST_RESET,
  };
};

export interface TerminateOutboundManifestActionBody {
  type: CHANGE_OBM_STATUS;
  outbound_manifest_id: number;
}

export const terminateOutboundManifestAction = ({
  outbound_manifest_id,
  type,
}: TerminateOutboundManifestActionBody) => {
  return async (dispatch: Dispatch) => {
    toast(`Terminating Outbound Manifest with type ${type}`, { hideProgressBar: true });
    dispatch(terminatingOutboundManifest());
    try {
      if (type === CHANGE_OBM_STATUS.MARK_AS_DONE) {
        await completeOutboundManifestAPI(outbound_manifest_id);
      }
      if (type === CHANGE_OBM_STATUS.CANCEL) {
        await cancelOutboundManifestAPI(outbound_manifest_id);
      }
      if (type === CHANGE_OBM_STATUS.REJECT) {
        await rejectOutboundManifestAPI(outbound_manifest_id);
      }
      toast.success("Outbound Manifest terminated successfully.");
      dispatch(terminateOutboundManifestSuccess());
    } catch (err) {
      toast.error("Something went wrong with terminating outbound manifest.");
      dispatch(terminateOutboundManifestFailed());
    }
  };
};
