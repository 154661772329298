import _ from "lodash";

import { DeliveryDateItem, SupplierDataItem } from "../types";

export const mapDeliveryDates = (supplierData: SupplierDataItem[]): DeliveryDateItem => {
  const unqueGlobal = _.uniq(supplierData.map(supplier => supplier.global_delivery_date_config));

  return {
    globalConfigDays: unqueGlobal[0],
    supplierConfigs: supplierData.map(supplier => ({
      supplierOrganisationName: supplier.organisation.organisation_name,
      supplierConfigDays: supplier.supplier_delivery_date_config,
    })),
    suppliers: supplierData.map(supplier => ({
      label: supplier.organisation.organisation_name,
      value: supplier.id,
    })),
  };
};
