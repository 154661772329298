import { Key, createRef, useEffect, useState } from "react";

import { Modal } from "@sourceful/shared-components";

import Panel from "../../../../components/panel/panel";
import Page from "../../../../shared/components/templates/Page/Page";
import { InnerPanelWrapper } from "../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../util/api/apiHook";
import { makeKey } from "../../../../util/makeKey";
import { getAttributeTemplates } from "../api/getAttributeTemplates";
import { getAttributeTypes } from "../api/getAttributeTypesTemplates";
import { AttributeTemplate } from "../api/types";
import AttributeTypeRow from "../components/AttributeTypeRow";
import CreateAttributeTypeModal from "../components/CreateAttributeTypeModal";

const ViewAttributesTypes = () => {
  const [getTypesState, getTypes] = useAPI(getAttributeTypes, true);
  const [getTemplatesState, getTemplates] = useAPI(getAttributeTemplates, true);

  const [attributeTypes, setAttributeTypes] = useState<Array<any>>([]);
  const [attributeTemplates, setAttributeTemplates] = useState<Array<AttributeTemplate>>([]);

  const [isOpenNewAttributeTypeModalOpen, setIsOpenNewAttributeTypeModalOpen] = useState(false);
  const modalRef = createRef<HTMLButtonElement>();

  const fetchAndSetAttributeTypes = async () => {
    const attributeTypes = await getTypes({});
    if (Array.isArray(attributeTypes)) {
      setAttributeTypes(attributeTypes[0]);
    }
  };

  const fetchAndSetAttributeTemplates = async () => {
    const attributeTemplates = await getTemplates({});
    if (Array.isArray(attributeTemplates)) {
      setAttributeTemplates(attributeTemplates[0]);
    }
  };

  useEffect(() => {
    fetchAndSetAttributeTypes();
    fetchAndSetAttributeTemplates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOpenNewAttributeTypeModal = () => {
    setIsOpenNewAttributeTypeModalOpen(true);
  };

  const handleCloseNewAttributeTypeModal = () => {
    setIsOpenNewAttributeTypeModalOpen(false);
  };

  const handleCreateAttributeTypeSuccess = () => {
    setIsOpenNewAttributeTypeModalOpen(false);
    fetchAndSetAttributeTypes();
  };

  const isLoading =
    getTypesState === API_REQUEST_STATE.PENDING || getTemplatesState === API_REQUEST_STATE.PENDING;
  const error =
    getTypesState === API_REQUEST_STATE.ERROR || getTemplatesState === API_REQUEST_STATE.ERROR;

  return (
    <Page title={"Attributes Templates"} isLoading={isLoading} error={error}>
      <Panel
        withWrapper
        title={"Attribute Type List"}
        text={"This shows the current list of attribute types"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        rightItems={[
          {
            type: "button",
            id: 0,
            title: "Create New Attribute Type",
            handleClick: handleOpenNewAttributeTypeModal,
            iconName: "alert-add-outline",
          },
        ]}
      >
        <InnerPanelWrapper>
          {attributeTypes.map(
            (
              attributeType: { id: number; attribute_type: string },
              index: Key | null | undefined
            ) => (
              <AttributeTypeRow
                refetchTemplates={() => fetchAndSetAttributeTemplates()}
                key={makeKey("attribute-type")}
                id={attributeType.id}
                attribute_type={attributeType.attribute_type}
                templates={attributeTemplates.filter(
                  ({ attribute_template_type_id }) =>
                    attribute_template_type_id === attributeType.id
                )}
              />
            )
          )}
        </InnerPanelWrapper>
        <Modal
          id="NewBaseProductModal"
          isOpen={isOpenNewAttributeTypeModalOpen}
          handleClose={handleCloseNewAttributeTypeModal}
          triggerRef={modalRef}
        >
          <CreateAttributeTypeModal
            handleCreateAttributeTypeSuccess={handleCreateAttributeTypeSuccess}
          />
        </Modal>
      </Panel>
    </Page>
  );
};

export default ViewAttributesTypes;
