import moment from "moment";

import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  Box,
  DataPoint,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import {
  StockCheckLineFulfilmentItem,
  StockCheckLineFulfilmentProductStockItem,
} from "../graphql/types";

interface StockCheckLineFulfilmentProductStockProps {
  productStock: StockCheckLineFulfilmentProductStockItem;
  stockCheckLineFulfilment: StockCheckLineFulfilmentItem;
  index: number;
}

const StockCheckLineFulfilmentProductStock = ({
  stockCheckLineFulfilment,
  productStock,
  index,
}: StockCheckLineFulfilmentProductStockProps) => (
  <SecondaryLineWrapper type="withIndex">
    <IndexPill index={index + 1} />
    <SecondaryLineDataWrapper>
      <DataPoint>
        <Label isGray>Product Stock ID</Label>
        <Box>{productStock.id}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray>Product</Label>
        <Box>{productStock.product.product_name}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray>Organisation</Label>
        <Box>{productStock.organisation?.organisation_name}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray> Quantity</Label>
        <Box>{stockCheckLineFulfilment.quantity}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray> Condition</Label>
        <Box>{productStock.stock_condition?.stock_condition_name}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray> Status</Label>
        <Box>{productStock.stock_status?.stock_status_name}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray> Location</Label>
        <Box>{productStock.location?.location_name}</Box>
      </DataPoint>
      <DataPoint>
        <Label isGray> Fulfilment Created On</Label>
        <Box>{moment(stockCheckLineFulfilment.last_updated).format("DD/MM/YYYY")}</Box>
      </DataPoint>
    </SecondaryLineDataWrapper>
  </SecondaryLineWrapper>
);

export default StockCheckLineFulfilmentProductStock;
