export interface CustomReportForm {
  organisation: { value: number; label: string } | null;
  warehouse: { value: number; label: string } | null;
  location: { value: number; label: string } | null;
  condition: { value: number; label: string } | null;
  stock: { value: number; label: string } | null;
}

export const initialFieldValues: CustomReportForm = {
  organisation: null,
  warehouse: null,
  location: null,
  condition: null,
  stock: null,
};

export const fieldNames = {
  organisation: "organisation",
  warehouse: "warehouse",
  location: "location",
  condition: "condition",
  stock: "stock",
};
