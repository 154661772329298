export enum ORDER_FLOW_TYPE {
  PC_WITH_BANK_TRANSFER = 1,
  PCC_WITH_BANK_TRANSFER = 2,
  PCC_WITH_PAYMENT_PLAN = 3,
  PC_WITH_CARD_PAYMENT = 4,
  SM_WITH_PAYMENT_PLAN = 5,
  PCC_WITH_DELAYED_PAYMENT_PLAN = 6,
  SM_WITH_CARD_PAYMENT = 7,
  SM_WITH_PAYMENT_PLAN_OFFLINE = 8,
  TEST_ORDER = 9,
  PCC_WITH_BLOCKED_PAYMENT_PLAN = 10,
}
