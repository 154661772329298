import { SidebarItem } from "../SideBar";

export const pcatSidebarItems: SidebarItem[] = [
  {
    heading: "Base Products",
    headingUrl: "product-catalogue",
    icon: "products-mailer-bag",
    links: [
      {
        name: "Base Products",
        URL: "product-catalogue",
      },
    ],
  },
  {
    heading: "Attributes",
    headingUrl: "product-catalogue/attribute-templates",
    icon: "navigation-share",
    links: [
      {
        name: "Attributes",
        URL: "product-catalogue/attribute-templates",
      },
    ],
  },
];
