import { ApolloError } from "@apollo/client";

import { useGetStockTransferByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { StockTransferItem } from "../types";

export interface HookResult {
  stockTransfer?: StockTransferItem;
  stockTransferError?: ApolloError;
  stockTransferLoading: boolean;
}

export const useStockTransferById = (stockTransferId: number): HookResult => {
  const { data, error, loading } = useGetStockTransferByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: { stockTransferId },
  });

  return {
    stockTransfer:
      data && Array.isArray(data.internal_transfer) ? data.internal_transfer[0] : undefined,
    stockTransferError: error,
    stockTransferLoading: loading,
  };
};
