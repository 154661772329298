import { WMS_API } from "../../../../../api/baseConfig";
import { FulfilInboundManifestLineBody } from "./types";

export const fulfilInboundManifestLineAPI = async ({
  inbound_manifest_line_id,
  ...rest
}: FulfilInboundManifestLineBody) => {
  try {
    return await WMS_API.post(`/inbound-manifest-lines/${inbound_manifest_line_id}/fulfil`, rest);
  } catch (err) {
    console.log("Error in fulfil inbound manifest line API call:", err);
    throw err;
  }
};
