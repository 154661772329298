import { Formik } from "formik";
import { Dispatch, SetStateAction } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import * as Yup from "yup";

import { ButtonGroup } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import TextArea from "../../../../../shared/components/forms/SimpleTextArea/SimpleTextArea";
import ErrorScreen from "../../../../../shared/components/templates/ErrorScreen";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  PanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Subtitle,
  Title,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { modalFieldNames, modalInitialFieldValues } from "../formValues/index";
import { useRejectionReasons } from "../graphql/hooks/useRejectionReasons";
import { RejectionResponseInterface, SupplierResponseInterface } from "../types";

interface RejectionModalInterface {
  purchaseOrderNumber: string;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  handleResponse: (
    formValues: SupplierResponseInterface,
    rejectValues: RejectionResponseInterface
  ) => void;
  formValues: SupplierResponseInterface;
}

const RejectionModal = (props: RejectionModalInterface) => {
  const { t } = useTranslation();

  const initialValues: RejectionResponseInterface = modalInitialFieldValues;

  const handleSubmitButton = async (rejectValues: RejectionResponseInterface) => {
    props.setIsModalOpen(false);
    props.handleResponse(props.formValues, rejectValues);
  };

  const validationSchema = Yup.object({
    rejection_reason: Yup.object()
      .nullable()
      .required(`${t("supplier_response_modal_rejection_reason_error")}`),
    rejection_notes: Yup.string()
      .required(`${t("supllier_response_modal_rejection_notes_error")}`)
      .min(10, `${t("supllier_response_modal_rejection_notes_error")}`),
  });

  const { rejectionReasons, rejectionReasonsError, rejectionReasonsLoading } =
    useRejectionReasons();

  if (rejectionReasonsError) {
    return <ErrorScreen>{t("supplier_response_modal_loading_error")}</ErrorScreen>;
  }

  return (
    <Page
      hasBackButton={false}
      title={t("supplier_response_modal_title")}
      isLoading={rejectionReasonsLoading}
    >
      <PanelWrapper>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={values => {
              handleSubmitButton(values);
            }}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <Title style={{ fontSize: "22px" }}>
                      {t("supplier_response_modal_subititle")}
                    </Title>
                    <Subtitle>
                      {t("supplier_response_modal_subititle_instructions", {
                        name: props.purchaseOrderNumber,
                      })}
                    </Subtitle>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <Label htmlFor={modalFieldNames.rejection_reason} isRequired>
                          {t("supplier_response_modal_rejection_reason_title")}
                        </Label>
                        <Select
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          options={rejectionReasons?.map(reason => {
                            return {
                              label: reason.supplier_rejection_reason,
                              value: reason.id,
                            };
                          })}
                          id={modalFieldNames.rejection_reason}
                          value={values.rejection_reason}
                          onChange={e => {
                            setFieldValue(modalFieldNames.rejection_reason, e);
                          }}
                          placeholder={t("supplier_response_modal_rejection_reason_placeholder")}
                        />
                        {errors.rejection_reason && touched.rejection_reason ? (
                          <ErrorMessage>{errors.rejection_reason}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                      <DataPoint halfWidth>
                        <Label htmlFor={modalFieldNames.rejection_notes} isRequired>
                          {t("supllier_response_modal_rejection_notes_title")}
                        </Label>
                        <TextArea
                          id={modalFieldNames.rejection_notes}
                          size={"large"}
                          type={"text"}
                          value={values.rejection_notes}
                          placeholder={t("supllier_response_modal_rejection_notes_placeholder")}
                          handleChange={e => {
                            setFieldValue(modalFieldNames.rejection_notes, e.target.value);
                          }}
                        />
                        {errors.rejection_notes && touched.rejection_notes ? (
                          <ErrorMessage>{errors.rejection_notes}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      appearance={"whiteButton"}
                      type="button"
                      onClick={() => {
                        props.setIsModalOpen(false);
                      }}
                    >
                      {t("supplier_response_modal_cancel_button")}
                    </SecondaryButton>
                    <SecondaryButton appearance={"blueButton"} type="submit">
                      {t("supplier_response_modal_submit_button")}
                    </SecondaryButton>
                  </ButtonGroup>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </PanelWrapper>
    </Page>
  );
};

export default RejectionModal;
