import { Dispatch } from "redux";

import { ActiveServiceReducer } from "../reducers/activeServiceReducer";
import {
  ACTIVE_SERVICE_ADMIN,
  ACTIVE_SERVICE_DEFAULT,
  ACTIVE_SERVICE_LOGISTICS,
  ACTIVE_SERVICE_ORDERS,
  ACTIVE_SERVICE_PCAT,
  ACTIVE_SERVICE_SOURCING,
  ACTIVE_SERVICE_STOCK_MANAGEMENT,
  ACTIVE_SERVICE_WMS,
} from "../types/reduxTypes";

export const activeServiceLogistics = () => {
  return {
    type: ACTIVE_SERVICE_LOGISTICS,
  };
};

export const activeServiceWMS = () => {
  return {
    type: ACTIVE_SERVICE_WMS,
  };
};

export const activeServiceSourcing = () => {
  return {
    type: ACTIVE_SERVICE_SOURCING,
  };
};

export const activeServiceAdmin = () => {
  return {
    type: ACTIVE_SERVICE_ADMIN,
  };
};

export const activeServicePCAT = () => {
  return {
    type: ACTIVE_SERVICE_PCAT,
  };
};

export const activeServiceOrders = () => {
  return {
    type: ACTIVE_SERVICE_ORDERS,
  };
};

export const activeServiceStockManagement = () => {
  return {
    type: ACTIVE_SERVICE_STOCK_MANAGEMENT,
  };
};

export const activeServiceDefault = () => {
  return {
    type: ACTIVE_SERVICE_DEFAULT,
  };
};

export const activeServiceAction = (body: ActiveServiceReducer) => {
  return async (dispatch: Dispatch) => {
    if (body.activeService === "Logistics") {
      console.log("Switch to Logistics ");
      dispatch(activeServiceLogistics());
    }
    if (body.activeService === "WMS") {
      console.log("Switch to WMS ");
      dispatch(activeServiceWMS());
    }
    if (body.activeService === "Sourcing") {
      console.log("Switch to Sourcing ");
      dispatch(activeServiceSourcing());
    }
    if (body.activeService === "Admin") {
      console.log("Switch to Admin ");
      dispatch(activeServiceAdmin());
    }
    if (body.activeService === "PCAT") {
      console.log("Switch to PCAT ");
      dispatch(activeServicePCAT());
    }
    if (body.activeService === "Orders") {
      console.log("Switch to Orders ");
      dispatch(activeServiceOrders());
    }
    if (body.activeService === "StockManagement") {
      console.log("Switch to Stock Management ");
      dispatch(activeServiceStockManagement());
    }
    if (body.activeService === "DEFAULT") {
      console.log("Switch to default ");
      dispatch(activeServiceDefault());
    }
  };
};
