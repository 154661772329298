import { ApolloError } from "@apollo/client";

import { useGetSupplierQuoteLineByIdLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { SupplierQuoteLineItem } from "../types";

export interface HookResult {
  supplierQuoteLine?: SupplierQuoteLineItem;
  supplierQuoteLineLoading: boolean;
  supplierQuoteLineError?: ApolloError;
  getSupplierQuoteLineById: (options: Options) => Promise<void>;
}

export interface Options {
  supplierQuoteLineId: number;
}

export const useSupplierQuoteLine = (): HookResult => {
  const [getSupplierQuoteLineById, { data, loading, error }] = useGetSupplierQuoteLineByIdLazyQuery(
    {
      context: {
        clientName: GRAPHQL_SOURCING,
      },
    }
  );

  return {
    supplierQuoteLine: data?.supplier_quote_line ? data.supplier_quote_line[0] : undefined,
    supplierQuoteLineLoading: loading,
    supplierQuoteLineError: error,
    getSupplierQuoteLineById: async ({ supplierQuoteLineId }: Options) => {
      getSupplierQuoteLineById({ variables: { supplierQuoteLineId } });
    },
  };
};
