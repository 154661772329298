import { WMS_API } from "../../../../../../api/baseConfig";

export const rejectOutboundManifestAPI = async (outbound_manifest_id: number) => {
  try {
    return await WMS_API.patch(`/outbound-manifests/${outbound_manifest_id}/reject`);
  } catch (err) {
    console.log("Error in reject outbound manifest API call:", err);
    throw err;
  }
};
