import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createPurchaseOrderApprovalAPI } from "../../api/createPurchaseOrderApproval";
import { CreatePurchaseOrderApprovalBody } from "../../api/types/index";
import {
  CREATE_PURCHASE_ORDER_APPROVAL_FAILED,
  CREATE_PURCHASE_ORDER_APPROVAL_LOADING,
  CREATE_PURCHASE_ORDER_APPROVAL_RESET,
  CREATE_PURCHASE_ORDER_APPROVAL_SUCCESS,
} from "../types/index";

export const createPurchaseOrderApprovalLoading = () => {
  return {
    type: CREATE_PURCHASE_ORDER_APPROVAL_LOADING,
  };
};

export const createPurchaseOrderApprovalSuccess = () => {
  return {
    type: CREATE_PURCHASE_ORDER_APPROVAL_SUCCESS,
  };
};

export const createPurchaseOrderApprovalFailed = () => {
  return {
    type: CREATE_PURCHASE_ORDER_APPROVAL_FAILED,
  };
};

export const createPurchaseOrderApprovalReset = () => {
  return {
    type: CREATE_PURCHASE_ORDER_APPROVAL_RESET,
  };
};

export const createPurchaseOrderApprovalAction = (
  purchaseOrderId: number,
  body: CreatePurchaseOrderApprovalBody
) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Approval", { hideProgressBar: true });
    dispatch(createPurchaseOrderApprovalLoading());
    try {
      await createPurchaseOrderApprovalAPI(purchaseOrderId, body);
      toast.success("Order status updated");
      dispatch(createPurchaseOrderApprovalSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating approval.");
      dispatch(createPurchaseOrderApprovalFailed());
    }
  };
};
