import { ApolloError } from "@apollo/client";

import { useGetQuotationCostTypesQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QuotationCostTypeItem } from "../types";

export interface HookResult {
  quotationCostTypes: QuotationCostTypeItem[];
  quotationCostTypesLoading: boolean;
  quotationCostTypesError?: ApolloError;
}

export const useQuotationCostTypes = (): HookResult => {
  const { data, loading, error } = useGetQuotationCostTypesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    quotationCostTypes:
      data && Array.isArray(data.quotation_cost_type)
        ? data.quotation_cost_type.map(quotationCostType => {
            return {
              id: quotationCostType.id,
              quotation_cost_type_name: quotationCostType.quotation_cost_type_name,
              quotation_cost_account_type:
                quotationCostType.quotation_cost_account_type.quotation_cost_account_type_name,
              quotation_cost_distribution:
                quotationCostType.quotation_cost_distribution.quotation_cost_distribution_name,
            };
          })
        : [],
    quotationCostTypesLoading: loading,
    quotationCostTypesError: error,
  };
};
