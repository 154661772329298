import { Link } from "react-router-dom";

function HelpHome() {
  return (
    <div>
      <h1>Help and Support</h1>
      <p>
        This pages gives an overview of key questions and potential points of confusion around the
        system suite.
      </p>
      <br />
      <h2> Permissions </h2>
      <p>
        The suite of Operations (Back-Office) Systems operates under strictly defined RBAC. If
        you're experiencing errors, <br /> or cannot execute the action you're hoping to perform,{" "}
        <b>
          please message #tech-support channel with a screenshot <br /> and information about the
          service and action
        </b>{" "}
        and someone should be able to help.
      </p>

      <h3>Summary of Key Roles</h3>
      <ul>
        <li>
          <b>WMS Viewer</b>: Anyone in the business should have access to view WMS pages, as well as
          to generate reports. <br />
          No security groups required for this role as it is assigned to the all-encompassing group
          for the business
        </li>
        <li>
          <b>WMS Operator</b>: Anyone who needs to use any WMS functionality; maily Warehouse teams
          in UK and China, <br />
          as well as some Sourcing representatives for Autostock orders. This role is currently
          assigned only to the <br /> warehouse and logistics team groups. If you think additional
          teams should have access by default, please <br />
          message #tech-support as well.
        </li>
        <li>
          <b>Sourcing Viewer</b>: Anyone in the business should have access to view Sourcing pages,
          as well as to generate <br />
          reports. No security groups required for this role as it is assigned to the
          all-encompassing group for the <br /> business
        </li>
        <li>
          <b>Sourcing Operator</b>: Anyone who needs to be able to fulfil and manage orders in
          Sourcing; largely the <br />
          Sourcing teams. This includes updating logistics statuses (mark as in transit and as
          delivered), recording <br />
          QA/QC outcomes and fulfiling client orders. The role is assigned to users in{" "}
          <b>sg-sourcing-operator </b>.
        </li>
        <li>
          <b>Approvers</b>: Purchase orders are based on a series of approvals (for now at least),
          which require specific <br /> approvals role; wide range of roles within Sourceful,
          including Artwork and Finance teams. If you think you <br />
          should be able to approve something, but the system doesn't allow it, give us a message.
          <br />
          Currently each approver role is assigned using security groups, namely:{" "}
          <b>sg-sourcing-dieline-approval</b>, <br />
          <b>sg-sourcing-order-approval</b>
          <b>sg-sourcing-finance-approval</b>, <b>sg-sourcing-exec-approval</b>. A list of all users
          who <br /> have approver access currently is displayed on the Update Purchase Order page.
        </li>
      </ul>
      <h1>Help and Support</h1>
      <div>
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`help/wms-setup-guide`}
        >
          WMS Setup Guide
        </Link>
      </div>
      <div>
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`help/wms-user-manual`}
        >
          WMS User Manual
        </Link>
      </div>
      <div>
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`help/sourcing-user-manual`}
        >
          Sourcing User Manual
        </Link>
      </div>
    </div>
  );
}

export default HelpHome;
