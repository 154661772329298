import { ApolloError } from "@apollo/client";

import { useGetSuppliersForRfQsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapSuppliersToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  suppliers: DropdownItem[];
  suppliersError?: ApolloError;
  suppliersLoading: boolean;
}

export const useSuppliers = (): HookResult => {
  const { data, error, loading } = useGetSuppliersForRfQsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    suppliers:
      data && Array.isArray(data.organisation)
        ? mapSuppliersToDropdownObject(data.organisation)
        : [],
    suppliersError: error,
    suppliersLoading: loading,
  };
};
