import { StyleSheet } from "@react-pdf/renderer";

export const PDFStyleSheet = StyleSheet.create({
  body: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    display: "flex",
    flexDirection: "column",
  },
  InputRow: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginTop: 5,
  },
  pageNumber: {
    left: 0,
    bottom: 0,
    width: "100%",
    textAlign: "center",
    color: "grey",
  },
  manifestDetailsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    flexDirection: "column",
    marginTop: 10,
  },
  box: {
    border: "1px solid black",
    borderRadius: 10,
    width: 240,
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 10,
    background: "white",
    marginTop: 5,
  },
  dataPoint: {
    minWidth: 150,
    marginLeft: 10,
    marginRight: 10,
    marginTop: 10,
  },
  manifestLineBox: {
    border: "1px solid black",
    borderRadius: 10,
    height: "auto",
    paddingTop: 5,
    paddingBottom: 5,
    paddingHorizontal: 10,
    background: "white",
    marginTop: 5,
  },
  manifestLineDataPoint: {
    width: "45%",
    height: "auto",
    marginRight: 15,
    marginBottom: 15,
  },
  fulfilmentLineDataPoint: {
    minWidth: 150,
    marginRight: 15,
    marginBottom: 15,
  },
  label: {
    fontSize: 15,
    fontWeight: "bold",
  },
  header: {
    fontSize: 12,
    marginBottom: 5,
    textAlign: "center",
    color: "grey",
  },
  ManifestLineWrapper: {
    background: "rgba(204, 222, 249, 0.25)",
    border: "1px solid #C4C4C4",
    borderRadius: 10,
    padding: 20,
    marginTop: 30,
  },
  ManifestLineTitle: {
    fontSize: 17,
    fontWeight: "bold",
  },
  ManifestDataPointWrapper: {
    display: "flex",
    marginTop: 20,
    flexWrap: "wrap",
    justifyContent: "space-between",
  },
  index: {
    color: "#FFFFFF",
    fontSize: 20,
    fontWeight: "bold",
  },
  FulfillmentLineWrapper: {
    background: "#CCDEF9",
    borderRadius: 10,
    border: "1px solid #C4C4C4",
    padding: 20,
    width: "100%",
    marginTop: 20,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flexWrap: "wrap",
    alignItems: "center",
    position: "relative",
  },
  indexHolder: {
    backgroundColor: "#8D8D8D",
    width: 30,
    height: 30,
    textAlign: "center",
    position: "absolute",
    top: -15,
    left: -15,
    borderRadius: "50%",
  },
});
