import { ApolloError } from "@apollo/client";

import { useGetSupplierIdsByParamsLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";

export interface HookResult {
  supplierIds?: number[];
  supplierIdsLoading: boolean;
  supplierIdsError?: ApolloError;
  getSupplierIdsByParams: (options: Options) => Promise<void>;
}

export interface Options {
  supplierName: string;
  externalOrganisationId: string;
}

export const useSupplierIdsByParams = (): HookResult => {
  const [getSupplierIdsByParams, { data, loading, error }] = useGetSupplierIdsByParamsLazyQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    supplierIds: data?.organisation.map(organisation => organisation.id),
    supplierIdsLoading: loading,
    supplierIdsError: error,
    getSupplierIdsByParams: async ({ supplierName, externalOrganisationId }: Options) => {
      getSupplierIdsByParams({ variables: { supplierName, externalOrganisationId } });
    },
  };
};
