import { UNAUTHENTICATED_SOURCING_API } from "../../../../../api/baseConfig";
import { CreateSupplierResponseBody } from "./types";

export const createSupplierResponseAPI = async (
  purchaseOrderId: Number,
  body: CreateSupplierResponseBody,
  accessToken: string
) => {
  try {
    return await UNAUTHENTICATED_SOURCING_API.post(
      `/purchase-orders/${purchaseOrderId}/supplier-responses`,
      { ...body, access_token: accessToken }
    );
  } catch (err) {
    console.log("Error in create Supplier Response API call", err);
    throw err;
  }
};
