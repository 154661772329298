import { ApolloError } from "@apollo/client";

import { useGetProductsForRfQsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { ProductItem } from "../types";

export interface HookResult {
  products: ProductItem[];
  productsError?: ApolloError;
  productsLoading: boolean;
}

export const useProducts = (): HookResult => {
  const { data, error, loading } = useGetProductsForRfQsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    products: data && Array.isArray(data.product) ? data.product : [],
    productsError: error,
    productsLoading: loading,
  };
};
