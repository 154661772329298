import { createRef, useEffect, useState } from "react";

import { ButtonGroup, Heading, Modal } from "@sourceful/shared-components";

import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  InnerPanelWrapper,
  PanelWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { deleteClientOrderAPI } from "../api/deleteClientOrderAPI";

interface DeleteClientOrderProps {
  clientOrderId: number;
  clientOrderStatus: string;
  allPurchaseOrdersInNegativeState: boolean;
  refetchClientOrder: () => Promise<void>;
}

const DeleteClientOrder = ({
  clientOrderId,
  clientOrderStatus,
  allPurchaseOrdersInNegativeState,
  refetchClientOrder,
}: DeleteClientOrderProps) => {
  const [isDeleteClientOrderModalOpen, setIsDeleteClientOrderModalOpen] = useState(false);
  const [canDelete, setCanDelete] = useState<boolean>(false);
  const [deleteClientOrderState, deleteClientOrder] = useAPI(deleteClientOrderAPI, false, {
    pending: "Deleting Client Order",
    success: "The client order has been deleted successfully.",
    error: "The client order could not be deleted. Please try again.",
  });

  useEffect(() => {
    if (deleteClientOrderState === API_REQUEST_STATE.SUCCESS) refetchClientOrder();

    let deleteButtonEnabledBasedOnClientOrderState = true;

    if (clientOrderStatus === "Archived") {
      deleteButtonEnabledBasedOnClientOrderState = false;
    }

    setCanDelete(allPurchaseOrdersInNegativeState && deleteButtonEnabledBasedOnClientOrderState);
  }, [
    deleteClientOrderState,
    allPurchaseOrdersInNegativeState,
    clientOrderStatus,
    refetchClientOrder,
  ]);

  const confirmDeleteModalRef = createRef<HTMLButtonElement>();
  return (
    <>
      <Modal
        id={"deleteClientOrderModal"}
        isOpen={isDeleteClientOrderModalOpen}
        handleClose={() => setIsDeleteClientOrderModalOpen(false)}
        triggerRef={confirmDeleteModalRef}
      >
        <PanelWrapper>
          <InnerPanelWrapper>
            <Heading level={3} fontSize={5} css={{ margin: "0 10px 10px" }}>
              Are you sure you want to delete this order?
            </Heading>
            <ButtonGroup
              className={WMSButtonGroup({ type: "largeMargin" })}
              css={{ marginTop: "auto" }}
            >
              <SecondaryButton
                appearance="whiteButton"
                onClick={() => setIsDeleteClientOrderModalOpen(false)}
              >
                Cancel
              </SecondaryButton>
              <SecondaryButton
                appearance="blueButton"
                type="submit"
                onClick={() => {
                  deleteClientOrder(clientOrderId);
                  setIsDeleteClientOrderModalOpen(false);
                }}
              >
                Confirm
              </SecondaryButton>
            </ButtonGroup>
          </InnerPanelWrapper>
        </PanelWrapper>
      </Modal>
      <VisibleFor roles={SOURCING_EDIT_ROLES}>
        <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
          <SecondaryButton
            appearance={"whiteButtonBlueText"}
            disabled={!canDelete}
            type="button"
            onClick={() => setIsDeleteClientOrderModalOpen(true)}
          >
            Delete Client Order
          </SecondaryButton>
        </ButtonGroup>
      </VisibleFor>
    </>
  );
};

export default DeleteClientOrder;
