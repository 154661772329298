import { ApolloError } from "@apollo/client";
import { AddToast } from "react-toast-notifications";
import { toast } from "react-toastify";

export const handleApolloError = (
  error: ApolloError,
  addToast?: AddToast,
  transformMessage?: (originalMessage: string, code: string) => string
) => {
  return error?.graphQLErrors?.forEach(error => {
    let errorMessage = error.message;

    if (transformMessage) {
      errorMessage = transformMessage(error.message, error?.extensions?.code);
    }
    if (addToast) {
      addToast(errorMessage, { appearance: "error" });
    } else {
      toast.error(errorMessage);
    }
  });
};
