import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { deleteFileCopiesAPI } from "../../api/deleteFileCopiesAPI";
import { DeleteFileCopiesBody } from "../../api/types";
import {
  DELETE_FILE_COPIES_FAILED,
  DELETE_FILE_COPIES_LOADING,
  DELETE_FILE_COPIES_RESET,
  DELETE_FILE_COPIES_SUCCESS,
} from "../types";

export const deleteFileCopiesLoading = () => {
  return {
    type: DELETE_FILE_COPIES_LOADING,
  };
};

export const deleteFileCopiesSuccess = () => {
  return {
    type: DELETE_FILE_COPIES_SUCCESS,
  };
};

export const deleteFileCopiesFailed = () => {
  return {
    type: DELETE_FILE_COPIES_FAILED,
  };
};

export const deleteFileCopiesReset = () => {
  return {
    type: DELETE_FILE_COPIES_RESET,
  };
};

export const deleteFileCopiesAction = (body: DeleteFileCopiesBody) => {
  return async (dispatch: Dispatch) => {
    toast("Deleting File Copies", { hideProgressBar: true });
    dispatch(deleteFileCopiesLoading());
    try {
      await deleteFileCopiesAPI(body);
      toast.success("File Copies deleted successfully");
      dispatch(deleteFileCopiesSuccess());
    } catch (err) {
      toast.error("Something went wrong with deleting file copies.");
      dispatch(deleteFileCopiesFailed());
    }
  };
};
