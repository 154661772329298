import { Formik } from "formik";
import { useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { CreateWarehouseBody } from "../api/types";
import { WarehouseForm, fieldNames, initialFieldValues } from "../formValues";
import { useAllAddressNames } from "../graphql/hooks/useAllAddressNames";
import { useAllOrganisationNames } from "../graphql/hooks/useAllOrganisationNames";
import {
  createWarehouseAction,
  createWarehouseReset,
} from "../redux/actions/createWarehouseAction";
import { CreateWarehouseReducer } from "../redux/reducers/createWarehouseReducer";
import { validationSchema } from "../validation";

interface Props {
  createWarehouseState: CreateWarehouseReducer;
  createWarehouse: (body: CreateWarehouseBody) => void;
  resetCreateWarehouse: () => void;
}

function AddWarehouse(props: Props) {
  const { createWarehouseState } = props;

  const { addressNames, addressNamesLoading, addressNamesError } = useAllAddressNames();
  const { organisationNames, organisationNamesLoading, organisationNamesError } =
    useAllOrganisationNames();

  const onSubmit = async (values: WarehouseForm) => {
    const warehouse = {
      address_id: values.address?.value!,
      warehouse_name: values.warehouse,
      organisation_id: values.organisation?.value!,
    };
    props.createWarehouse(warehouse);
  };

  useEffect(() => {
    if (createWarehouseState.success) {
      props.resetCreateWarehouse();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createWarehouseState.success, createWarehouseState.error]);

  const isLoading = organisationNamesLoading || addressNamesLoading;
  const error = organisationNamesError || addressNamesError;

  return (
    <Page error={error} isLoading={isLoading} title={"Warehouses"}>
      <Panel
        withWrapper
        title={"Add Warehouse"}
        subtitle={"Add a Warehouse using the inputs below"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialFieldValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleReset,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.warehouse}
                      name={fieldNames.warehouse}
                      type={"text"}
                      value={values.warehouse}
                      placeholder="Enter Name Here"
                      error={errors.warehouse}
                      touched={touched.warehouse}
                      label="Warehouse"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleSelect
                      isRequired
                      htmlFor={fieldNames.address}
                      name={fieldNames.address}
                      placeholder="Select Address"
                      options={addressNames}
                      value={values.address}
                      error={errors.address}
                      touched={touched.address}
                      label="Address"
                      changeHandler={e => setFieldValue(fieldNames.address, e)}
                      linkLabelText="+ Add Address"
                      linkLabelURL="/warehouse-management/add-address"
                    />
                  </DataPoint>
                  <DataPoint>
                    <SimpleSelect
                      isRequired
                      htmlFor={fieldNames.organisation}
                      name={fieldNames.organisation}
                      placeholder="Select Organisation"
                      options={organisationNames}
                      value={values.organisation}
                      error={errors.organisation}
                      touched={touched.organisation}
                      label="Organisation"
                      changeHandler={e => setFieldValue(fieldNames.organisation, e)}
                    />
                  </DataPoint>
                </TopPanelInputsWrapper>

                <FormButtonPair handleReset={handleReset} />
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createWarehouseState: state.createWarehouseReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createWarehouse: (body: CreateWarehouseBody) => dispatch(createWarehouseAction(body)),
    resetCreateWarehouse: () => dispatch(createWarehouseReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddWarehouse);
