import { ApolloError } from "@apollo/client";

import { useGetRecipeBaseProductNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapBaseProductNamesToDropdownItem } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  baseProductNames: DropdownItem[];
  baseProductNamesError?: ApolloError;
  baseProductNamesLoading: boolean;
}

export const useBaseProductNames = (): HookResult => {
  const { data, error, loading } = useGetRecipeBaseProductNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    baseProductNames:
      data && Array.isArray(data.base_product)
        ? mapBaseProductNamesToDropdownItem(data.base_product)
        : [],
    baseProductNamesError: error,
    baseProductNamesLoading: loading,
  };
};
