import {
  ADJUSTING_STOCK_AD_HOC_LOADING,
  ADJUST_STOCK_AD_HOC_FAILED,
  ADJUST_STOCK_AD_HOC_RESET,
  ADJUST_STOCK_AD_HOC_SUCCESS,
} from "../types";

export interface AdjustStockAdHocReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  adjustedStock: any;
}

const initialState: AdjustStockAdHocReducer = {
  loading: false,
  success: false,
  error: false,
  adjustedStock: null,
};

export default function adjustStockAdHocReducer(
  state: AdjustStockAdHocReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case ADJUSTING_STOCK_AD_HOC_LOADING:
      return {
        ...state,
        loading: true,
      };
    case ADJUST_STOCK_AD_HOC_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
        adjustedStock: action.adjustedStock,
      };
    case ADJUST_STOCK_AD_HOC_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case ADJUST_STOCK_AD_HOC_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
