import { useAuth0 } from "@auth0/auth0-react";
import axios, { AxiosRequestConfig } from "axios";
import { useEffect, useState } from "react";
import { useToasts } from "react-toast-notifications";

export interface UploadedFile {
  attachment_uuid: string;
  original_filename: string;
}

interface UseFileUploadProps<ResponseData> {
  endpoint?: string;
  onUploadComplete?: (responseData: ResponseData, originalFile: File) => void;
}

const useFileUpload = <ResponseData extends UploadedFile>({
  onUploadComplete,
  endpoint,
}: UseFileUploadProps<ResponseData>) => {
  const { addToast } = useToasts();
  const { getAccessTokenSilently } = useAuth0();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [progress, setProgress] = useState(0);
  const [isUploading, setIsUploading] = useState(false);

  useEffect(() => {
    setProgress(0);
  }, [selectedFile]);

  const onUploadProgress = (progressEvent: ProgressEvent) => {
    const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
    setProgress(percentCompleted);
  };

  const getAxiosClient = (token?: string, overrides?: Partial<AxiosRequestConfig>) => {
    const defaultOptions: AxiosRequestConfig = {
      baseURL: process.env.REACT_APP_FILE_UPLOADER_API_BASE_URL || window.REACT_APP_API_ORIGIN,
      headers: {
        "Content-Type": "application/json",
        authorization: token ? `Bearer ${token}` : "",
      },
      ...overrides,
    };

    return axios.create(defaultOptions);
  };

  const uploadFile = async () => {
    try {
      if (!selectedFile) {
        return addToast("Please choose a file", { appearance: "error" });
      }

      setIsUploading(true);

      const formData = new FormData();
      formData.append("filename", selectedFile.name);
      formData.append("file", selectedFile);

      const token = await getAccessTokenSilently();
      const axiosClient = getAxiosClient(token);
      const response = await axiosClient.post(endpoint || `/gcs-file-uploader/upload`, formData, {
        onUploadProgress,
      });
      const defaultData: UploadedFile = {
        attachment_uuid: response.data.uuid_name,
        original_filename: selectedFile.name,
      };

      onUploadComplete && onUploadComplete(endpoint ? response.data : defaultData, selectedFile);
    } catch (error) {
      // @ts-ignore
      addToast(error.message, { appearance: "error" });
    } finally {
      setIsUploading(false);
    }
  };

  return { selectedFile, setSelectedFile, progress, isUploading, uploadFile };
};

export default useFileUpload;
