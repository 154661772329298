import moment from "moment";

import { InternalTransferItem } from "../types";

export const mapInternalTransfersToCSV = (internalTransfers: InternalTransferItem[]) => {
  return internalTransfers.map(data => {
    return {
      internal_transfer_id: data.id,
      from_location: data.from_location?.location_name,
      from_warehouse: data.from_location?.warehouse.warehouse_name,
      to_location: data.to_location.location_name,
      to_warehouse: data.to_location.warehouse.warehouse_name,
      status: data.internal_transfer_status.internal_transfer_status_name,
      internal_transfer_lines: data.internal_transfer_lines_aggregate.aggregate?.count,
      completed_date: moment(data.last_updated).format("DD-MM-YYYY"),
    };
  });
};
