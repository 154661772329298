import { Formik } from "formik";
import moment from "moment";
import { Dispatch, SetStateAction, useState } from "react";

import { ButtonGroup } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip";
import {
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";

interface DeliveryDateModalInterface {
  purchaseOrderId: number;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  markAsDeliveredPurchaseOrder: (purchase_order_id: number, delivery_date: string) => void;
  setDeliveryDate: Dispatch<SetStateAction<string>>;
  deliveryDate: string;
}

export interface DateForm {
  delivery_date: string;
}
export const initialFieldValues: DateForm = {
  delivery_date: "",
};

const DeliveryDateModal = ({
  purchaseOrderId,
  setIsModalOpen,
  markAsDeliveredPurchaseOrder,
  setDeliveryDate,
  deliveryDate,
}: DeliveryDateModalInterface) => {
  const [initialValues] = useState<DateForm>(initialFieldValues);

  const onSubmit = async () => {
    setIsModalOpen(false);
  };

  const updateStatusToDelivered = () => {
    markAsDeliveredPurchaseOrder(Number(purchaseOrderId), deliveryDate);
  };

  return (
    <Panel withWrapper title={"Set date for when this purchase order was delivered"}>
      <InnerPanelWrapper style={{ padding: 10 }}>
        <Formik initialValues={initialValues} onSubmit={onSubmit}>
          {({ handleSubmit }) => {
            return (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <ToolTip
                      message={"Select delivery date"}
                      title={"Select delivery date"}
                      htmlFor={"delivery_date"}
                    ></ToolTip>
                    <DateInput
                      id={"delivery_date"}
                      type="date"
                      value={deliveryDate}
                      onChange={e => {
                        setDeliveryDate(moment(e.target.valueAsDate).format("YYYY-MM-DD"));
                      }}
                    />
                  </DataPoint>
                </TopPanelInputsWrapper>

                <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
                  <SecondaryButton
                    appearance="blueButton"
                    type="submit"
                    onClick={() => {
                      setIsModalOpen(false);
                      updateStatusToDelivered();
                    }}
                  >
                    Mark as delivered
                  </SecondaryButton>
                </ButtonGroup>
              </StyledForm>
            );
          }}
        </Formik>
      </InnerPanelWrapper>
    </Panel>
  );
};

export default DeliveryDateModal;
