import { ORDER_SERVICE_API } from "../../../../../api/baseConfig";

export const getUserAddressDetailApi = async (userid: string, orgid: string) => {
  try {
    return await ORDER_SERVICE_API.get(`/order-processor/all-order-contacts/${userid}/${orgid}`);
  } catch (err) {
    console.log("Error in get user details api call", err);
    throw err;
  }
};
