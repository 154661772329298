import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { SecondaryButton } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  LOGISTICS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { getShipmentExpectedDate } from "../../../shared/helpers/getShipmentExpectedDate";
import { useShipments } from "../graphql/hooks/useShipments";
import { ShipmentListItem } from "../graphql/types";

export default function ViewShipments() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { shipments, shipmentsError, shipmentsLoading } = useShipments();

  const items = LOGISTICS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => history.push("/shipments/batch-update"),
          type: "button",
          title: "Create Batch Update",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];

  const columns = useMemo(
    () => [
      {
        Header: "CO#",
        widthPercent: 0.3,
        accessor: (shipment: ShipmentListItem) => shipment.client_order.external_client_order_id,
        Cell: ({ row }: CellProps<ShipmentListItem>) =>
          row.original.client_order.external_client_order_id,
      },
      {
        Header: "PO#",
        widthPercent: 0.1,
        accessor: (shipment: ShipmentListItem) => shipment.purchase_order?.purchase_order_uuid,
      },
      {
        Header: "Client Name",
        widthPercent: 0.2,
        accessor: (shipment: ShipmentListItem) =>
          shipment.client_order?.organisation?.organisation_name,
      },
      {
        Header: "Supplier",
        widthPercent: 0.2,
        accessor: (shipment: ShipmentListItem) =>
          shipment?.purchase_order?.organisation?.organisation_name,
      },
      {
        Header: "Expected Date",
        widthPercent: 0.1,
        accessor: (shipment: ShipmentListItem) =>
          getShipmentExpectedDate(shipment.shipment_updates),
        Cell: ({ row }: CellProps<ShipmentListItem>) =>
          row.original.shipment_updates
            ? getShipmentExpectedDate(row.original.shipment_updates)
            : undefined,
      },
      {
        Header: "Update",
        widthPercent: 0.2,
        accessor: (shipment: ShipmentListItem) => shipment.id,
        Cell: ({ row }: CellProps<ShipmentListItem>) => (
          <Link to={`/shipments/client-order/${row.original.client_order_id}`}>
            <SecondaryButton appearance={"blueButton"} isWithinTable>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page title={"Shipments"} isLoading={shipmentsLoading} error={shipmentsError}>
      <Panel
        withWrapper
        title={"Shipments List"}
        text={"Use the table below to explore Sourceful's Shipments"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        rightItems={rightItems}
      >
        <Table data={shipments} columns={columns} renderFilters={(_: FilterSetter<any>) => <></>} />
      </Panel>
    </Page>
  );
}
