import { ApolloError } from "@apollo/client";

import { useGetWarehouseByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { WarehouseItem } from "../types";

export interface HookResult {
  warehouse?: WarehouseItem;
  warehouseError?: ApolloError;
  warehouseLoading: boolean;
}

export const useWarehouseById = (warehouseId: number): HookResult => {
  const { data, error, loading } = useGetWarehouseByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: { warehouseId },
  });

  return {
    warehouse: data && Array.isArray(data.warehouse) ? data.warehouse[0] : undefined,
    warehouseError: error,
    warehouseLoading: loading,
  };
};
