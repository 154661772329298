import { PRODUCT_CATALOGUE_API } from "../../../../../api/baseConfig";

export const getProductDetailApi = async (skuid: string) => {
  try {
    return await PRODUCT_CATALOGUE_API.get(`/sku?id=${skuid}`);
  } catch (err) {
    console.log("Error in get product details api call", err);
    throw err;
  }
};
