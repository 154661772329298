import { ApolloError } from "@apollo/client";

import { useGetAllProductsLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapProductsToCSV } from "../mappers/mapProductsToCSV";

export interface HookResult {
  products: any[];
  productsError?: ApolloError;
  productsLoading: boolean;
  getAllProducts: () => Promise<void>;
}

export const useAllProducts = (): HookResult => {
  const [getAllProducts, { data, error, loading }] = useGetAllProductsLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    products: data && Array.isArray(data.product) ? mapProductsToCSV(data.product) : [],
    productsError: error,
    productsLoading: loading,
    getAllProducts: async () => {
      getAllProducts();
    },
  };
};
