import * as Yup from "yup";

export const createBasicStockCheckValidation = Yup.object({
  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),
  stock_check_type: Yup.object()
    .shape({
      value: Yup.number().required("Stock check type is required"),
    })
    .typeError("stock check type is required"),
  base_product: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .typeError("Base Product is required")
    .when("stock_check_type", {
      is: (
        stock_check_type: {
          value: number;
          label: string;
        } | null
      ) => stock_check_type?.label === "Product",
      then: Yup.object()
        .shape({
          value: Yup.number().required("Base Product is required"),
        })
        .typeError("Base Product is required"),
      otherwise: Yup.object().nullable(),
    }),
  location: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .when("stock_check_type", {
      is: (
        stock_check_type: {
          value: number;
          label: string;
        } | null
      ) => stock_check_type?.label === "Location",
      then: Yup.object()
        .shape({
          value: Yup.number().required("Location is required"),
        })
        .typeError("Location is required"),
      otherwise: Yup.object().nullable(),
    }),
});
