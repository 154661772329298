export const FULFILlING_STOCK_CHECK_LINE = "FULFILlING_STOCK_CHECK_LINE";
export const FULFIL_STOCK_CHECK_LINE_SUCCESS = "FULFIL_STOCK_CHECK_LINE_SUCCESS";
export const FULFIL_STOCK_CHECK_LINE_FAILURE = "FULFIL_STOCK_CHECK_LINE_FAILURE";
export const FULFIL_STOCK_CHECK_LINE_RESET = "FULFIL_STOCK_CHECK_RESET";

export const MARKING_STOCK_CHECK_AS_COMPLETE = "MARKING_STOCK_CHECK_AS_COMPLETE";
export const MARK_STOCK_CHECK_AS_COMPLETE_SUCCESS = "MARK_STOCK_CHECK_AS_COMPLETE_SUCCESS";
export const MARK_STOCK_CHECK_AS_COMPLETE_FAILURE = "MARK_STOCK_CHECK_AS_COMPLETE_FAILURE";
export const MARK_STOCK_CHECK_AS_COMPLETE_RESET = "MARK_STOCK_CHECK_AS_COMPLETE_RESET";

export const MARKING_STOCK_CHECK_LINE_AS_COMPLETE = "MARKING_STOCK_CHECK_LINE_AS_COMPLETE";
export const MARK_STOCK_CHECK_LINE_AS_COMPLETE_SUCCESS =
  "MARK_STOCK_CHECK_LINE_AS_COMPLETE_SUCCESS";
export const MARK_STOCK_CHECK_LINE_AS_COMPLETE_FAILURE =
  "MARK_STOCK_CHECK_LINE_AS_COMPLETE_FAILURE";
export const MARK_STOCK_CHECK_LINE_AS_COMPLETE_RESET = "MARK_STOCK_CHECK_LINE_AS_COMPLETE_RESET";
