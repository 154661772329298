import { ApolloError } from "@apollo/client";

import { useGetInternalTransfersLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapInternalTransfersToCSV } from "../mappers/mapInternalTransfersToCSV";

export interface HookResult {
  internalTransfers: any[];
  internalTransfersError?: ApolloError;
  internalTransfersLoading: boolean;
  getInternalTransfers: () => Promise<void>;
}

export const useInternalTransfers = (): HookResult => {
  const [getInternalTransfers, { data, error, loading }] = useGetInternalTransfersLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    internalTransfers:
      data && Array.isArray(data.internal_transfer)
        ? mapInternalTransfersToCSV(data.internal_transfer)
        : [],
    internalTransfersError: error,
    internalTransfersLoading: loading,
    getInternalTransfers: async () => {
      getInternalTransfers();
    },
  };
};
