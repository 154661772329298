import { CreatePurchaseOrderApprovalBody, UserName } from "../api/types";
import { OrderLineInterface } from "../graphql/types";
import { CreatePurchaseOrderApprovalReducer } from "../redux/reducers/createPurchaseOrderApprovalReducer";
import { MarkAsDeliveredPurchaseOrderReducer } from "../redux/reducers/markAsDeliveredPurchaseOrderReducer";
import { MarkAsInTransitPurchaseOrderReducer } from "../redux/reducers/markAsInTransitPurchaseOrderReducer";

export interface ApprovalsCopyInterface {
  approvalTitle: string;
  approvalDescription: string;
  permissions: string;
}

export enum PURCHASE_ORDER_STATUS {
  PENDING = "Pending",
  INTERNALLY_REJECTED = "Internally Rejected",
  EXTERNALLY_REJECTED = "Externally Rejected",
  APPROVED = "Approved",
  DELIVERED = "Delivered",
  ACCEPTED = "Accepted",
  EXPIRED = "Expired",
  IN_TRANSIT = "In Transit",
  DELETED = "Deleted",
  RECALLED = "Recalled",
}

export const negativePurchaseOrderStatuses = [
  PURCHASE_ORDER_STATUS.INTERNALLY_REJECTED,
  PURCHASE_ORDER_STATUS.EXTERNALLY_REJECTED,
  PURCHASE_ORDER_STATUS.EXPIRED,
  PURCHASE_ORDER_STATUS.DELETED,
  PURCHASE_ORDER_STATUS.RECALLED,
];

export enum PURCHASE_ORDER_STATUS_ID {
  PENDING = 1,
  INTERNALLY_REJECTED = 2,
  EXTERNALLY_REJECTED = 3,
  APPROVED = 4,
  DELIVERED = 5,
  ACCEPTED = 6,
  EXPIRED = 7,
  IN_TRANSIT = 8,
  DELETED = 9,
  RECALLED = 10,
}

export interface ApprovalSectionProps {
  type: "dielines" | "order" | "finance" | "exec";
  approval: any[];
  disabled?: boolean;
  refetchPurchaseOrder: () => Promise<void>;
  createPurchaseOrderApprovalState: CreatePurchaseOrderApprovalReducer;
  createPurchaseOrderApproval: (
    purchaseOrderId: number,
    body: CreatePurchaseOrderApprovalBody
  ) => void;
  getUsersByRole: (role: string) => Promise<UserName[]>;
  resetCreatePurchaseOrderApproval: () => void;
}

export interface LogisticsStatusUpdateProps {
  poStatus: string | undefined;
  refetchPurchaseOrder: () => Promise<void>;
  markAsInTransitPurchaseOrderState: MarkAsInTransitPurchaseOrderReducer;
  markAsInTransitPurchaseOrder: (purchase_order_id: number) => void;
  resetMarkAsInTransitPurchaseOrder: () => void;
  markAsDeliveredPurchaseOrderState: MarkAsDeliveredPurchaseOrderReducer;
  markAsDeliveredPurchaseOrder: (purchase_order_id: number, delivery_date: string) => void;
  resetMarkAsDeliveredPurchaseOrder: () => void;
}

export interface ResendPurchaseOrderProps {
  poStatus: string | undefined;
  refetchPurchaseOrder: () => Promise<void>;
}

export interface RecallPurchaseOrderProps {
  poStatus: string | undefined;
  refetchPurchaseOrder: () => Promise<void>;
}

export interface RegeneratePurchaseOrderProps {
  poStatus: string | undefined;
  poId: string;
}

export interface ProductDisplayLineProps {
  index: number;
  orderLine: OrderLineInterface;
  supplierId: number;
}
