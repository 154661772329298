import { ApolloError } from "@apollo/client";
import { Formik, FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { InnerPanelWrapper, StyledForm } from "../../../../../styles/AddPage";
import {
  ButtonWrapper,
  PrimaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { CreateWorkOrderBody } from "../api/types";
import WorkOrderGeneral from "../components/WorkOrderGeneral";
import WorkOrderInput from "../components/WorkOrderInput";
import { WorkOrderForm, initialFieldValues } from "../formValues";
import { useRecipeById } from "../graphql/hooks/useWorkOrderRecipeById";
import { mapInputLinesToDTO, mapRecipeInputsToRecipeLine } from "../mappers";
import {
  createWorkOrderAction,
  createWorkOrderReset,
} from "../redux/actions/createWorkOrderAction";
import { CreateWorkOrderReducer } from "../redux/reducers/createWorkOrderReducer";
import { validationSchema } from "../validation";

interface Props {
  createWorkOrderState: CreateWorkOrderReducer;
  createWorkOrder: (body: CreateWorkOrderBody) => void;
  resetCreateWorkOrder: () => void;
}

export function CreateWorkOrder(props: Props) {
  const [multiplier, setMultiplier] = useState<number>();
  const [isAddingInputLines, setIsAddingInputLines] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<WorkOrderForm>(initialFieldValues);
  const [componentsError, setComponentsError] = useState<ApolloError | undefined>(undefined);

  const { createWorkOrderState, createWorkOrder, resetCreateWorkOrder } = props;
  const { recipe, recipeLoading, recipeError, getRecipeById } = useRecipeById();

  const onSubmit = (values: WorkOrderForm) => {
    const workOrderBody: CreateWorkOrderBody = {
      warehouse_id: values.warehouse?.value!,
      organisation_id: values.organisation?.value!,
      due_date: values.due_date,
      recipe_id: values.recipe_name?.value!,
      quantity: Number(values.quantity),
      work_order_inputs: mapInputLinesToDTO(values.work_order_and_recipe_inputs),
      external_work_order_id: Number(values.external_work_order_id),
    };

    createWorkOrder(workOrderBody);
  };

  useEffect(() => {
    if (recipe && multiplier && isAddingInputLines) {
      const formattedRecipeData = mapRecipeInputsToRecipeLine(recipe.recipe_inputs, multiplier);
      setInitialValues(currentData => ({
        ...currentData,
        work_order_and_recipe_inputs: formattedRecipeData,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipe, isAddingInputLines]);

  useEffect(() => {
    if (createWorkOrderState.success) {
      resetCreateWorkOrder();
      setInitialValues({ ...initialFieldValues });
      setIsAddingInputLines(false);
    }

    if (createWorkOrderState.error) {
      resetCreateWorkOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createWorkOrderState.success, createWorkOrderState.error]);

  const error = recipeError || componentsError;
  const isLoading = recipeLoading || createWorkOrderState.loading;

  return (
    <Page error={error} isLoading={isLoading} title={"Manifests - Create Work Order"}>
      <Panel
        withWrapper
        title={"Create Work Order"}
        subtitle={"An order used to create a recipe to bundle or create different stocks"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              setTouched,
              validateForm,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <WorkOrderGeneral
                  isAddingInputLines={isAddingInputLines}
                  values={values}
                  setFieldValue={setFieldValue}
                  setMultiplier={setMultiplier}
                  errors={errors as FormikErrors<WorkOrderForm>}
                  touched={touched as FormikTouched<WorkOrderForm>}
                  setError={setComponentsError}
                />

                {!isAddingInputLines && (
                  <ButtonWrapper>
                    <SecondaryButton
                      type="button"
                      appearance={"whiteButtonBlueText"}
                      className={WMSButtonGroup({ type: "smallMargin" })}
                      onClick={async () => {
                        setTouched({
                          warehouse: true,
                          quantity: true,
                          organisation: true,
                          due_date: true,
                          external_work_order_id: true,
                          recipe_name: true,
                        });

                        const formErrors = await validateForm();

                        if (Object.keys(formErrors).length === 0) {
                          getRecipeById({ recipeId: values.recipe_name?.value! });
                          setInitialValues({ ...values });
                          setIsAddingInputLines(true);
                        }
                      }}
                    >
                      <IconText text={"Start Work Order"} primaryIcon={"alert-add-outline"} />
                    </SecondaryButton>
                  </ButtonWrapper>
                )}

                {isAddingInputLines && recipe && recipe.id === values.recipe_name?.value && (
                  <PrimaryLineWrapper>
                    <Title>Work Order Inputs</Title>
                    {values.work_order_and_recipe_inputs.map((line, recipeInputIndex) => (
                      <WorkOrderInput
                        key={recipeInputIndex}
                        line={line}
                        index={recipeInputIndex}
                        values={values}
                        errors={errors as FormikErrors<WorkOrderForm>}
                        touched={touched as FormikTouched<WorkOrderForm>}
                        setFieldValue={setFieldValue}
                        setInitialValues={setInitialValues}
                      />
                    ))}
                  </PrimaryLineWrapper>
                )}

                {isAddingInputLines && recipe && (
                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      type="reset"
                      appearance="whiteButton"
                      onClick={() => {
                        setInitialValues({ ...initialFieldValues });
                        setIsAddingInputLines(false);
                      }}
                    >
                      Cancel
                    </SecondaryButton>

                    <SecondaryButton appearance="blueButton" type="submit">
                      Confirm
                    </SecondaryButton>
                  </ButtonGroup>
                )}
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createWorkOrderState: state.createWorkOrderReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createWorkOrder: (body: CreateWorkOrderBody) => dispatch(createWorkOrderAction(body)),
    resetCreateWorkOrder: () => dispatch(createWorkOrderReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateWorkOrder);
