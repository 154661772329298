import { useTranslation } from "react-i18next";

import { Icon, InputField } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { QuoteLineProps } from "../types";

const SingleQuoteLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveQuoteLine,
  errors,
  touched,
  allQuoteLines,
}: QuoteLineProps) => {
  const { t } = useTranslation();
  const stateIndex = allQuoteLines.findIndex(quoteLine => quoteLine.id === values.id);
  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <Label isRequired htmlFor={`minimum_order_quantity_${index}`}>
            {t("quote_supplier_response_minimum_order_quantity")}
          </Label>
          <InputField
            id={`minimum_order_quantity_${index}`}
            size={"medium"}
            type={"number"}
            value={values.minimum_order_quantity}
            handleChange={e => {
              setFieldValue(`quote_lines[${stateIndex}].minimum_order_quantity`, e.target.value);
            }}
            placeholder={"100"}
          />
          {Array.isArray(errors) &&
          errors[index]?.minimum_order_quantity &&
          Array.isArray(touched) &&
          touched[index]?.minimum_order_quantity ? (
            <ErrorMessage>{errors[index]?.minimum_order_quantity}</ErrorMessage>
          ) : null}
        </DataPoint>

        <DataPoint>
          <ToolTip
            message={t("quote_supplier_response_lead_time_max_days_description")}
            title={t("quote_supplier_response_lead_time_max_days")}
            htmlFor={`lead_time_max_days_${index}`}
          ></ToolTip>

          <InputField
            id={`lead_time_max_days_${index}`}
            size={"medium"}
            type={"number"}
            placeholder={"14"}
            value={values.lead_time_max_days}
            handleChange={e => {
              setFieldValue(`quote_lines[${stateIndex}].lead_time_max_days`, e.target.value);
            }}
          />
          {Array.isArray(errors) &&
          errors[index]?.lead_time_max_days &&
          Array.isArray(touched) &&
          touched[index]?.lead_time_max_days ? (
            <ErrorMessage>{errors[index]?.lead_time_max_days}</ErrorMessage>
          ) : null}
        </DataPoint>
      </SecondaryLineDataWrapper>
      <DataPoint>
        <IconWrapper type="button" onClick={() => handleRemoveQuoteLine(values.id!)}>
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DataPoint>
    </SecondaryLineWrapper>
  );
};

export default SingleQuoteLine;
