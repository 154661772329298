import { STOCK_STATUS } from "../../shared/models";
import { FulfilStockCheckLineBody } from "../api/fulfilStockCheckLine/types";
import { MISSING_STOCK, NEW_STOCK } from "../constants";
import { FulfilStockCheckLineForm } from "../formValues";
import { StockConditionItem } from "../graphql/types";

export const mapStockConditionsToDropdownObject = (stockConditionItems: StockConditionItem[]) => {
  return stockConditionItems.map(stockCondition => {
    return {
      label: stockCondition.stock_condition_name,
      value: stockCondition.id,
    };
  });
};

interface StockCheckLineFulfilmentMapper {
  values: FulfilStockCheckLineForm;
  stockCheckId: number;
  stockCheckLine: number;
}

export const mapStockCheckLineFulfilmentFormValuesToAPIBody = ({
  stockCheckId,
  stockCheckLine,
  values,
}: StockCheckLineFulfilmentMapper): FulfilStockCheckLineBody => {
  const fulfilmentBody: FulfilStockCheckLineBody = {
    stockCheckId: stockCheckId,
    stockCheckLineId: stockCheckLine,
    quantity: Number(values.quantity),
    newStockConditionId: values.stock_condition?.value ? values.stock_condition.value : undefined,
    newStockStatusId:
      values.action?.label === NEW_STOCK
        ? STOCK_STATUS.FOUND_UNEXPECTED
        : values.action?.label === MISSING_STOCK
        ? STOCK_STATUS.MISSING
        : undefined,
  };

  return fulfilmentBody;
};
