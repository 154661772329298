import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { markStockCheckLineAsCompleteAPI } from "../../api/markStockCheckLineAsComplete/markStockCheckLineAsComplete";
import { MarkStockCheckLineAsCompleteParams } from "../../api/markStockCheckLineAsComplete/types";
import {
  MARKING_STOCK_CHECK_LINE_AS_COMPLETE,
  MARK_STOCK_CHECK_LINE_AS_COMPLETE_FAILURE,
  MARK_STOCK_CHECK_LINE_AS_COMPLETE_RESET,
  MARK_STOCK_CHECK_LINE_AS_COMPLETE_SUCCESS,
} from "../types";

export const markingStockCheckLineAsComplete = () => {
  return {
    type: MARKING_STOCK_CHECK_LINE_AS_COMPLETE,
  };
};

export const markStockCheckLineAsCompleteSuccess = () => {
  return {
    type: MARK_STOCK_CHECK_LINE_AS_COMPLETE_SUCCESS,
  };
};

export const markStockCheckLineAsCompleteReset = () => {
  return {
    type: MARK_STOCK_CHECK_LINE_AS_COMPLETE_RESET,
  };
};

export const markStockCheckLineAsCompleteFailure = () => {
  return {
    type: MARK_STOCK_CHECK_LINE_AS_COMPLETE_FAILURE,
  };
};

export const markStockCheckLineAsCompleteAction = (params: MarkStockCheckLineAsCompleteParams) => {
  return async (dispatch: Dispatch) => {
    toast("Marking as complete Stock Check Line", { hideProgressBar: true });
    dispatch(markingStockCheckLineAsComplete());
    try {
      await markStockCheckLineAsCompleteAPI(params);
      toast.success("Stock Check Line marked as complete successfully.");
      dispatch(markStockCheckLineAsCompleteSuccess());
    } catch (err) {
      toast.error("Something went wrong with marking as complete Stock Check Line.");
      dispatch(markStockCheckLineAsCompleteFailure());
    }
  };
};
