import { ApolloError } from "@apollo/client";

import { useGetStockTransfersWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { StockTransferItem } from "../types";

export interface HookResult {
  stockTransfers: StockTransferItem[];
  stockTransfersError?: ApolloError;
  stockTransfersLoading: boolean;
}

export const useStockTransfers = (): HookResult => {
  const { data, error, loading } = useGetStockTransfersWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockTransfers: data && Array.isArray(data.internal_transfer) ? data.internal_transfer : [],
    stockTransfersError: error,
    stockTransfersLoading: loading,
  };
};
