import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { rejectRequestForQuoteAPI } from "../../api/rejectRfq";
import { RejectRequestForQuoteBody } from "../../api/types";
import {
  REJECT_REQUEST_FOR_QUOTE_FAILED,
  REJECT_REQUEST_FOR_QUOTE_LOADING,
  REJECT_REQUEST_FOR_QUOTE_RESET,
  REJECT_REQUEST_FOR_QUOTE_SUCCESS,
} from "../types";

export const rejectRequestForQuoteLoading = () => {
  return {
    type: REJECT_REQUEST_FOR_QUOTE_LOADING,
  };
};

export const rejectRequestForQuoteSuccess = () => {
  return {
    type: REJECT_REQUEST_FOR_QUOTE_SUCCESS,
  };
};

export const rejectRequestForQuoteFailed = () => {
  return {
    type: REJECT_REQUEST_FOR_QUOTE_FAILED,
  };
};

export const rejectRequestForQuoteReset = () => {
  return {
    type: REJECT_REQUEST_FOR_QUOTE_RESET,
  };
};

export const rejectSupplierQuoteRequestAction = (
  supplierQuoteRequestId: number,
  body: RejectRequestForQuoteBody,
  accessToken: string
) => {
  return async (dispatch: Dispatch) => {
    toast("Creating supplier quote response", { hideProgressBar: true });
    dispatch(rejectRequestForQuoteLoading());
    try {
      await rejectRequestForQuoteAPI(supplierQuoteRequestId, body, accessToken);
      toast.success("Supplier quote request rejected successfully");
      dispatch(rejectRequestForQuoteSuccess());
    } catch (err) {
      toast.error("Something went wrong with rejecting the request for quotation.");
      dispatch(rejectRequestForQuoteFailed());
    }
  };
};
