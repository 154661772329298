import { SOURCING_API } from "../../../../../api/baseConfig";
import { UpdateQuoteBody } from "./types";

export const updateInternalQuoteAPI = async (body: UpdateQuoteBody) => {
  try {
    return await SOURCING_API.patch(`/internal-quotes/${body.id}`, body);
  } catch (err) {
    console.log("Error in update Quotes API call", err);
    throw err;
  }
};
