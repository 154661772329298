import { ApolloError } from "@apollo/client";

import { useGetFileCopiesByDateLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { FileCopyItem } from "../types";

export interface HookResult {
  fileCopies: FileCopyItem[];
  fileCopiesError?: ApolloError;
  fileCopiesLoading: boolean;
  getFileCopiesByDate: (options: Options) => Promise<void>;
}

export interface Options {
  fromDate: string;
}

export const useFileCopiesByDate = (): HookResult => {
  const [getFileCopiesByDate, { data, error, loading }] = useGetFileCopiesByDateLazyQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    fileCopies: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    fileCopiesError: error,
    fileCopiesLoading: loading,
    getFileCopiesByDate: async ({ fromDate }: Options) => {
      getFileCopiesByDate({ variables: { fromDate } });
    },
  };
};
