import { ApolloError } from "@apollo/client";

import { useGetPendingClientOrdersQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { ClientOrderListItem } from "../types";

export interface HookResult {
  clientOrders: ClientOrderListItem[];
  clientOrdersError?: ApolloError;
  clientOrdersLoading: boolean;
}

export const usePendingClientOrders = (): HookResult => {
  const { data, error, loading } = useGetPendingClientOrdersQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    clientOrders: data?.client_order_line || [],
    clientOrdersError: error,
    clientOrdersLoading: loading,
  };
};
