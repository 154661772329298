import moment from "moment";
import { CSVLink } from "react-csv";

import { styled } from "@sourceful/shared-components";

export const CardWrapper = styled("div", {
  display: "flex",
  backgroundColor: "white",
  minWidth: "350px",
  minHeight: "400px",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  flexDirection: "column",
});

export const CardTitle = styled("div", {
  width: "100%",
  fontSize: "28px",
  lineHeight: "18px",
  marginTop: "25px",
  display: "flex",
  weight: "700",
  justifyContent: "center",
  fontWeight: 700,
});

const DownloadLink = styled("div", {
  fontSize: "15px",
  lineHeight: "18px",
  textDecoration: "underline",
  color: "#000C32",
  cursor: "pointer",
  "&:hover": {
    textDecoration: "none",
  },
});

export interface ReportsCardProps {
  report: Report;
}

export interface Report {
  data: any[];
  fileTitle: string;
  displayTitle: string;
}

export const ReportsCard = ({ report }: ReportsCardProps) => {
  return (
    <CSVLink
      data={report.data ?? []}
      filename={`${report.fileTitle}_${moment(new Date()).format("YYYY_MM_DD_HH_mm_ss")}.csv`}
    >
      <DownloadLink>{report.displayTitle}</DownloadLink>
    </CSVLink>
  );
};
