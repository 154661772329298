import { LocaleHolidayInterface } from "../types";

export const initialFieldValues: LocaleHolidayInterface = {
  locale: null,
  start_date: "",
  end_date: "",
};

export const fieldNames = {
  locale: "locale",
  start_date: "start_date",
  end_date: "end_date",
};
