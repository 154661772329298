import { ApolloError } from "@apollo/client";

import { useGetAllReportWarehouseNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DropdownItem } from "../types";

export interface HookResult {
  warehouseNames?: DropdownItem[];
  warehouseNamesError?: ApolloError;
  warehouseNamesLoading: boolean;
}

export const useAllReportWarehouseNames = (): HookResult => {
  const { data, error, loading } = useGetAllReportWarehouseNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    warehouseNames:
      data && Array.isArray(data.warehouse)
        ? data.warehouse.map(warehouseName => {
            return {
              label: warehouseName.warehouse_name,
              value: warehouseName.id,
            };
          })
        : [],
    warehouseNamesError: error,
    warehouseNamesLoading: loading,
  };
};
