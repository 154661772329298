import { WMS_API } from "../../../../../../api/baseConfig";

export const cancelInboundManifestAPI = async (inbound_manifest_id: number) => {
  try {
    return await WMS_API.patch(`/inbound-manifests/${inbound_manifest_id}/cancel`);
  } catch (err) {
    console.log("Error in cancel inbound manifest API call:", err);
    throw err;
  }
};
