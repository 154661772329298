import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createSupplierResponseAPI } from "../../api/createSupplierResponse";
import { CreateSupplierResponseBody } from "../../api/types";
import {
  CREATE_SUPPLIER_RESPONSE_FAILED,
  CREATE_SUPPLIER_RESPONSE_LOADING,
  CREATE_SUPPLIER_RESPONSE_RESET,
  CREATE_SUPPLIER_RESPONSE_SUCCESS,
} from "../types";

export const createSupplierResponseLoading = () => {
  return {
    type: CREATE_SUPPLIER_RESPONSE_LOADING,
  };
};

export const createSupplierResponseSuccess = () => {
  return {
    type: CREATE_SUPPLIER_RESPONSE_SUCCESS,
  };
};

export const createSupplierResponseFailed = () => {
  return {
    type: CREATE_SUPPLIER_RESPONSE_FAILED,
  };
};

export const createSupplierResponseReset = () => {
  return {
    type: CREATE_SUPPLIER_RESPONSE_RESET,
  };
};

export const createSupplierResponseAction = (
  purchaseOrderId: number,
  body: CreateSupplierResponseBody,
  accessToken: string
) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Response", { hideProgressBar: true });
    dispatch(createSupplierResponseLoading());
    try {
      await createSupplierResponseAPI(purchaseOrderId, body, accessToken);
      toast.success("Response created successfully.");
      dispatch(createSupplierResponseSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating response.");
      dispatch(createSupplierResponseFailed());
    }
  };
};
