import { Formik, FormikState } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { CreateStockAllocationBody } from "../api/types";
import StockAllocationProductStocks from "../components/StockAllocationProductStocks";
import { StockAllocationForm, fieldNames, initialFieldValues } from "../formValues";
import { useOrganisations } from "../graphql/hooks/useOrganisations";
import { useProductStocks } from "../graphql/hooks/useProductStocks";
import {
  createStockAllocationAction,
  createStockAllocationReset,
} from "../redux/actions/createStockAllocationAction";
import { CreateStockAllocationReducer } from "../redux/reducers/ createStockAllocationReducer";
import { validationSchema } from "../validation";

export interface CreateStockAllocationProps {
  createStockAllocationState: CreateStockAllocationReducer;
  createStockAllocation: (body: CreateStockAllocationBody) => void;
  resetCreateStockAllocation: () => void;
}

export function CreateStockAllocation(props: CreateStockAllocationProps) {
  const [isAllocatingStock, setIsAllocatingStock] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<StockAllocationForm>(initialFieldValues);
  const [selectedProductStockQuantity, setSelectedProductStockQuantity] = useState<
    number | undefined
  >();
  const { createStockAllocation, createStockAllocationState, resetCreateStockAllocation } = props;

  const { organisationNames, organisationsLoading, organisationsError } = useOrganisations();
  const { productStocks, productStocksError, productStocksLoading, refetchProductStocks } =
    useProductStocks();

  const isLoading = organisationsLoading || productStocksLoading || isAllocatingStock;
  const error = organisationsError || productStocksError;

  const onSubmit = (
    values: StockAllocationForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<StockAllocationForm>>) => void }
  ) => {
    setIsAllocatingStock(true);
    const createStockTransferBody: CreateStockAllocationBody = {
      product_stock_id: values.product_stock_id?.value!,
      quantity: values.quantity,
      organisation_id: values.organisation_id?.value!,
    };

    createStockAllocation(createStockTransferBody);
    resetForm();
  };

  useEffect(() => {
    refetchProductStocks();

    if (createStockAllocationState.success) {
      resetCreateStockAllocation();
      setInitialValues({ ...initialFieldValues });
      setIsAllocatingStock(false);
    }

    if (createStockAllocationState.error) {
      props.resetCreateStockAllocation();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStockAllocationState.success, createStockAllocationState.error]);

  return (
    <Page error={error} isLoading={isLoading} title="Operations - Create Stock Allocations">
      <Panel
        withWrapper
        title={"New Stock Allocation"}
        subtitle={"Transfer Stock to another organisation"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, handleReset, touched }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  {!isLoading && (
                    <>
                      <PrimaryLineWrapper style={{ marginBottom: "20px" }}>
                        <Title>Product Stocks</Title>

                        <StockAllocationProductStocks
                          productStocks={productStocks}
                          setFieldValue={setFieldValue}
                          setSelectedProductStockQuantity={(value: number) =>
                            setSelectedProductStockQuantity(value)
                          }
                          selectedId={values.product_stock_id?.value}
                        />
                      </PrimaryLineWrapper>
                      <TopPanelInputsWrapper>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            label="From Organisation"
                            placeholder="Select From Organisation"
                            htmlFor={fieldNames.from_organisation_id}
                            name={fieldNames.from_organisation_id}
                            value={values.from_organisation_id}
                            options={organisationNames}
                            error={errors.from_organisation_id}
                            touched={touched.from_organisation_id}
                            changeHandler={e => {
                              setFieldValue(fieldNames.from_organisation_id, e);
                            }}
                            isDisabled
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            menuPlacement="top"
                            isRequired
                            label="To Organisation"
                            placeholder="Select To Organisation"
                            htmlFor={fieldNames.organisation_id}
                            name={fieldNames.organisation_id}
                            value={values.organisation_id}
                            options={organisationNames}
                            error={errors.organisation_id}
                            touched={touched.organisation_id}
                            changeHandler={e => {
                              setFieldValue(fieldNames.organisation_id, e);
                            }}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleInput
                            isRequired
                            label="Quantity"
                            placeholder="Select Quantity"
                            htmlFor={fieldNames.quantity}
                            name={fieldNames.quantity}
                            type="number"
                            value={`${values.quantity}`}
                            error={errors.quantity}
                            touched={touched.quantity}
                            changeHandler={e => {
                              setFieldValue(fieldNames.quantity, parseInt(e.currentTarget.value));
                            }}
                          />

                          {selectedProductStockQuantity &&
                            values.quantity > selectedProductStockQuantity && (
                              <ErrorMessage>
                                Quantity cannot be more than selected product stock quantity
                              </ErrorMessage>
                            )}
                        </DataPoint>

                        <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                          <SecondaryButton
                            type="reset"
                            appearance="whiteButton"
                            onClick={() => {
                              handleReset();
                              setSelectedProductStockQuantity(undefined);
                            }}
                          >
                            Cancel
                          </SecondaryButton>
                          <SecondaryButton
                            appearance="blueButton"
                            type="submit"
                            disabled={
                              !!selectedProductStockQuantity &&
                              values.quantity > selectedProductStockQuantity
                            }
                          >
                            Confirm
                          </SecondaryButton>
                        </ButtonGroup>
                      </TopPanelInputsWrapper>
                    </>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createStockAllocationState: state.createStockAllocationReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createStockAllocation: (body: CreateStockAllocationBody) =>
      dispatch(createStockAllocationAction(body)),
    resetCreateStockAllocation: () => dispatch(createStockAllocationReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStockAllocation);
