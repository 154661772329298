import { ApolloError } from "@apollo/client";

import { useGetOrganisationByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { OrganisationItem } from "../types";

export interface HookResult {
  organisation?: OrganisationItem;
  organisationError?: ApolloError;
  organisationLoading: boolean;
}

export const useOrganisationById = (organisationId: number): HookResult => {
  const { data, error, loading } = useGetOrganisationByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      organisationId,
    },
  });

  return {
    organisation: data && Array.isArray(data.organisation) ? data.organisation[0] : undefined,
    organisationError: error,
    organisationLoading: loading,
  };
};
