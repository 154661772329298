import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreatePurchaseOrderApprovalBody } from "./types";

export const createPurchaseOrderApprovalAPI = async (
  purchaseOrderId: Number,
  body: CreatePurchaseOrderApprovalBody
) => {
  try {
    return await SOURCING_API.post(`/purchase-orders/${purchaseOrderId}/purchase-order-approvals`, {
      is_approved: body.isApproved,
      purchase_order_approval_type_id: body.purchaseOrderApprovalTypeId,
    });
  } catch (err) {
    console.log("Error in create purchase order approval API call", err);
    throw err;
  }
};
