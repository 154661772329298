import { ApolloError } from "@apollo/client";

import { useGetLocationNamesThatCanHoldStockByWarehouseIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapLocationNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  locationNames: DropdownItem[];
  locationNamesError?: ApolloError;
  locationNamesLoading: boolean;
}

export const useLocationNamesThatCanHoldStockByWarehouseId = (warehouseId: number): HookResult => {
  const { data, error, loading } = useGetLocationNamesThatCanHoldStockByWarehouseIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      warehouseId,
    },
  });

  return {
    locationNames:
      data && Array.isArray(data.location) ? mapLocationNamesToDropdownObject(data.location) : [],
    locationNamesError: error,
    locationNamesLoading: loading,
  };
};
