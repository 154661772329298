import { StockCheckLine } from "../formValues";

export const mapStockCheckLinesToDTO = (stockCheckLines: StockCheckLine[]) => {
  return stockCheckLines.map(line => {
    return {
      productStockId: line.product_stock?.value!,
      expectedQuantity: Number(line.expected_quantity),
      expectedStockConditionId: Number(line.expected_stock_condition_id),
    };
  });
};
