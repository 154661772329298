import { Formik, FormikErrors } from "formik";
import { toast } from "react-toastify";

import { Heading, PrimaryButton, SecondaryButton } from "@sourceful/shared-components";

import { BasicPanel } from "../../../components/panel/panel";
import { ButtonRightContainer } from "../../../shared/components/atoms/containers/ButtonRightContainer";
import { FlexEndContainer } from "../../../shared/components/atoms/containers/FlexEndContainer";
import SimpleInput from "../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../shared/components/forms/SimpleSelect";
import {
  DataPoint,
  InnerPanelWrapper,
  InputContainer,
  PrimaryLineDataWrapper,
} from "../../../styles/SharedStyles";
import { makeKey } from "../../../util/makeKey";
import { createAttributeTemplate } from "./api/createAttributeTemplate";
import {
  AttributeTemplateInterface,
  AttributeTemplateRequiredField,
  attributeTemplateFieldNames,
  attributeTemplateSchema,
  typeOptions,
} from "./types/AttributeTemplateFormTypes";

interface AttributeTemplateFormProps {
  handleGoBack: () => void;
  attributeTypeId: number;
  handleCreateAttributeTemplateSuccess: () => void;
}

const AttributeTemplateForm = ({
  handleGoBack,
  attributeTypeId,
  handleCreateAttributeTemplateSuccess,
}: AttributeTemplateFormProps) => {
  const handleSubmit = async (values: AttributeTemplateInterface) => {
    const formattedValues = {
      attribute_template_type_id: attributeTypeId,
      attribute_template_name: values.name,
      required_fields: values.requiredFields.reduce((acc, field) => {
        return { ...acc, ...{ [field.name]: field.type } };
      }, {}),
    };

    try {
      await createAttributeTemplate({
        ...formattedValues,
      });
      toast.success("Success! Attribute Type created.");
      handleCreateAttributeTemplateSuccess();
    } catch (error) {
      console.error(error);
      toast.error("Error occurred while creating attribute type.");
    }
  };

  const initialAttributeTemplateValues: AttributeTemplateInterface = {
    name: "",
    requiredFields: [
      {
        name: "",
        type: 1,
      },
    ],
  };

  const getError = (
    errors: FormikErrors<AttributeTemplateInterface>,
    index: number
  ): string | undefined => {
    const formikError = errors.requiredFields?.[
      index
    ] as FormikErrors<AttributeTemplateRequiredField>;

    return formikError.name;
  };

  return (
    <>
      <SecondaryButton onClick={handleGoBack}>Back</SecondaryButton>
      <Heading level={6} css={{ margin: "20px 0" }}>
        Add New Attribute Template
      </Heading>
      <Formik
        validationSchema={attributeTemplateSchema}
        initialValues={initialAttributeTemplateValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          const handleAddNewRequiredField = () => {
            const requiredFields = [...values.requiredFields];
            requiredFields.push({
              name: "",
              type: 1,
            });
            setFieldValue(attributeTemplateFieldNames.requiredFields, requiredFields);
          };

          const removeRequiredField = (index: number) => {
            const requiredFields = [...values.requiredFields];
            requiredFields.splice(index, 1);
            setFieldValue(attributeTemplateFieldNames.requiredFields, requiredFields);
          };

          return (
            <>
              <BasicPanel>
                <InnerPanelWrapper>
                  <Heading level={6}>Attribute Properties</Heading>
                  <InputContainer>
                    <SimpleInput
                      type="text"
                      isRequired
                      htmlFor={attributeTemplateFieldNames.name}
                      label="Name"
                      name={attributeTemplateFieldNames.name}
                      error={errors.name}
                      touched={touched.name}
                      value={values.name}
                      changeHandler={e => {
                        setFieldValue(attributeTemplateFieldNames.name, e.target.value);
                      }}
                      placeholder="Enter attribute property name"
                    />
                  </InputContainer>
                </InnerPanelWrapper>
              </BasicPanel>
              <BasicPanel css={{ marginTop: "20px" }}>
                <InnerPanelWrapper>
                  <Heading level={6}>Required Fields</Heading>

                  {values.requiredFields.map((field, index) => {
                    return (
                      <InputContainer key={makeKey("required-field")}>
                        <PrimaryLineDataWrapper>
                          <DataPoint
                            withUniqueMargin
                            css={values.requiredFields.length > 1 ? { width: "36%" } : {}}
                          >
                            <SimpleInput
                              type="text"
                              isRequired
                              htmlFor={`${attributeTemplateFieldNames.requiredFieldName}-${index}`}
                              label="Name"
                              name={`${attributeTemplateFieldNames.requiredFieldName}-${index}`}
                              error={getError(errors, index)}
                              touched={touched.requiredFields?.[index]?.name}
                              value={field.name}
                              changeHandler={e => {
                                setFieldValue(
                                  `${attributeTemplateFieldNames.requiredFields}[${index}].name`,
                                  e.target.value
                                );
                              }}
                              placeholder="Enter required field name"
                            />
                          </DataPoint>
                          <DataPoint
                            withUniqueMargin
                            css={
                              values.requiredFields.length > 1
                                ? { width: "36%" }
                                : { marginRight: "0 !important" }
                            }
                          >
                            <SimpleSelect
                              placeholder="Select attribute type"
                              isRequired
                              htmlFor={`${attributeTemplateFieldNames.requiredFieldType}-${index}`}
                              label="Type"
                              name={`${attributeTemplateFieldNames.requiredFieldType}-${index}`}
                              error={getError(errors, index)}
                              touched={touched.requiredFields?.[index]?.type}
                              value={typeOptions.find(type => type.value === field.type)}
                              changeHandler={e => {
                                if (e) {
                                  setFieldValue(
                                    `${attributeTemplateFieldNames.requiredFields}[${index}].type`,
                                    e.value
                                  );
                                }
                              }}
                              options={typeOptions}
                            />
                          </DataPoint>
                          {values.requiredFields.length > 1 && (
                            <FlexEndContainer withUniqueMargin>
                              {index !== 0 && (
                                <div>
                                  <SecondaryButton onClick={() => removeRequiredField(index)}>
                                    Remove
                                  </SecondaryButton>
                                </div>
                              )}
                            </FlexEndContainer>
                          )}
                        </PrimaryLineDataWrapper>
                      </InputContainer>
                    );
                  })}

                  <ButtonRightContainer>
                    <PrimaryButton
                      borderRadius="square"
                      type="button"
                      onClick={handleAddNewRequiredField}
                    >
                      Add new required field
                    </PrimaryButton>
                  </ButtonRightContainer>
                </InnerPanelWrapper>
              </BasicPanel>
              <ButtonRightContainer>
                <PrimaryButton type="submit" onClick={() => handleSubmit()}>
                  Submit
                </PrimaryButton>
              </ButtonRightContainer>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AttributeTemplateForm;
