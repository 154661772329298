import { ApolloError } from "@apollo/client";

import { useGetQuotationModelExportLineBySupplierQuoteLineIdQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { QuotationModealExportLineRequestObject } from "../types";

export interface HookResult {
  quotationModelExportLine?: QuotationModealExportLineRequestObject;
  quotationModelExportLineError?: ApolloError;
  quotationModelExportLineLoading: boolean;
}

export const useQuotationModelExportLine = (supplierQuoteLineIds: number[]): HookResult => {
  const { data, error, loading } = useGetQuotationModelExportLineBySupplierQuoteLineIdQuery({
    context: { clientName: GRAPHQL_SOURCING },
    variables: { supplierQuoteLineIds },
  });

  return {
    quotationModelExportLine:
      data && Array.isArray(data.quotation_model_export_line)
        ? data.quotation_model_export_line[0]
        : undefined,
    quotationModelExportLineError: error,
    quotationModelExportLineLoading: loading,
  };
};
