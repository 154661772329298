import { FormikErrors, FormikTouched } from "formik";

import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import { DataPoint, SecondaryLineWrapper } from "../../../../../styles/SharedStyles";
import { WorkOrderAndRecipeOutput, WorkOrderOutput } from "../formValues";
import { DropdownItem } from "../graphql/types";

interface WorkOrderLineProps {
  line: WorkOrderAndRecipeOutput;
  multiplier: string;
  locationNames: DropdownItem[];
  values: WorkOrderOutput;
  workOrderLineIndex: number;
  recipeOutputIndex: number;
  errors: FormikErrors<WorkOrderAndRecipeOutput> | undefined;
  touched: FormikTouched<WorkOrderAndRecipeOutput> | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const WorkOrderOutputLine = ({
  values,
  setFieldValue,
  workOrderLineIndex,
  recipeOutputIndex,
  errors,
  touched,
  line,
  multiplier,
  locationNames,
}: WorkOrderLineProps) => (
  <SecondaryLineWrapper type={"withIndex"}>
    <IndexPill index={workOrderLineIndex + 1} />
    <DataPoint>
      <SimpleSelect
        isRequired
        name={`location_${recipeOutputIndex}_${workOrderLineIndex}`}
        htmlFor={`location_${recipeOutputIndex}_${workOrderLineIndex}`}
        label="Location"
        value={values.location}
        options={locationNames}
        placeholder="Select Location"
        changeHandler={event => {
          setFieldValue(
            `work_order_and_recipe_outputs[${recipeOutputIndex}].work_order_outputs[${workOrderLineIndex}].location`,
            event
          );
        }}
        touched={
          Array.isArray(touched?.work_order_outputs)
            ? touched?.work_order_outputs[workOrderLineIndex].location
            : false
        }
        error={
          errors && Array.isArray(errors?.work_order_outputs)
            ? (errors as any).work_order_outputs[workOrderLineIndex].location
            : null
        }
      />
    </DataPoint>

    <DisplayBox
      label={"Quantity"}
      value={line.recipe_output_line.quantity * Number(multiplier)}
      unknownString="Enter Quantity above"
    />
  </SecondaryLineWrapper>
);

export default WorkOrderOutputLine;
