import { PurchaseOrderApprovalItem, PurchaseOrderLineItem } from "../types";

export const getApprovalStatus = (approvals: PurchaseOrderApprovalItem[], type: string) => {
  const filteredApprovals = approvals
    .map(approval => {
      return {
        is_approved: approval.is_approved,
        type: approval.purchase_order_approval_type.purchase_order_approval_type_name,
      };
    })
    .filter(approval => approval.type === type);

  if (filteredApprovals.length === 0) {
    return "Pending";
  } else {
    if (approvals[approvals.length - 1].is_approved) return "Approved";
    return "Rejected";
  }
};

export const getQAInspectionStatuses = (purchaseOrderLines: PurchaseOrderLineItem[]) => {
  const lineStatuses = purchaseOrderLines.map(poLine => {
    if (poLine.quality_assurance_inspections.length === 0) {
      return "Not Required";
    }
    return poLine.quality_assurance_inspections[0].quality_assurance_status
      .quality_assurance_status_name;
  });

  const poStatus = lineStatuses.reduce((agg: any, line) => {
    agg[line] = (agg[line] || 0) + 1;
    return agg;
  }, {});

  if (Object.keys(poStatus).includes("Failed")) {
    return "Rejected";
  }

  if (Object.keys(poStatus).includes("Pending")) {
    return "Pending";
  }

  if (Object.keys(poStatus).includes("Passed")) {
    return "Approved";
  }

  return "Unknown";
};
