import { ApolloError } from "@apollo/client";

import { Report } from "../../../../../../components/ReportsCard/ReportsCard";
import { useGetDashboardProductsReportQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  productsReport?: Report;
  productsReportError?: ApolloError;
  productsReportLoading: boolean;
}

export const useDashboardProductsReport = (): HookResult => {
  const { data, error, loading } = useGetDashboardProductsReportQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    productsReport:
      data && Array.isArray(data.product)
        ? {
            fileTitle: `all_products_report_generated`,
            displayTitle: `Download All Products Report`,
            data:
              data.product.map(data => {
                return {
                  product_id: data.id,
                  product_name: data.product_name,
                  number_of_product_stock: data.product_stocks_aggregate.aggregate?.count,
                  service_account: data.service_account.service_account_name,
                  is_case: data.case_product_id ? "Yes" : "No",
                  case_quantity: data.base_product_quantity,
                  external_product_id: data.external_product_id,
                };
              }) || [],
          }
        : undefined,
    productsReportError: error,
    productsReportLoading: loading,
  };
};
