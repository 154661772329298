import { WMS_API } from "../../../../../api/baseConfig";
import { CreateBaseProductBody } from "./types";

export const createBaseProductAPI = async (body: CreateBaseProductBody) => {
  try {
    return await WMS_API.post("/products/base", body);
  } catch (err) {
    console.log("Error in create base product API call", err);
    throw err;
  }
};
