import {
  RETURN_OUTBOUND_MANIFEST_LINE_FAILED,
  RETURN_OUTBOUND_MANIFEST_LINE_LOADING,
  RETURN_OUTBOUND_MANIFEST_LINE_RESET,
  RETURN_OUTBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export interface ReturnOutboundManifestLineReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: ReturnOutboundManifestLineReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function terminateOutboundManifestReducer(
  state: ReturnOutboundManifestLineReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case RETURN_OUTBOUND_MANIFEST_LINE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RETURN_OUTBOUND_MANIFEST_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case RETURN_OUTBOUND_MANIFEST_LINE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case RETURN_OUTBOUND_MANIFEST_LINE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
