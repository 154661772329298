import { ApolloError } from "@apollo/client";

import { useGetAllReportSupplierCountryCodesQuery } from "../../../../../../generated/graphql_sourcing";
import { StringListDropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";

export interface HookResult {
  supplierCountryCodes?: StringListDropdownItem[];
  supplierCountryCodesError?: ApolloError;
  supplierCountryCodesLoading: boolean;
}

export const useAllReportSupplierCountryCodes = (): HookResult => {
  const { data, error, loading } = useGetAllReportSupplierCountryCodesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    supplierCountryCodes:
      data && Array.isArray(data.vw_order_export_report)
        ? (() => {
            const uniqueSupplierCountryCodes = data.vw_order_export_report.filter(
              (item, index, self) =>
                index ===
                self.findIndex(t => t.supplier_country_code === item.supplier_country_code)
            );

            const labelValuePairs: StringListDropdownItem[] = uniqueSupplierCountryCodes.map(
              item => ({
                label: item.supplier_country_code || "Not set",
                value: [item.supplier_country_code || "Not set"],
              })
            );

            const allValues = uniqueSupplierCountryCodes.map(
              item => item.supplier_country_code || "Not set"
            );
            labelValuePairs.unshift({
              label: "All",
              value: allValues,
            });
            return labelValuePairs;
          })()
        : [{ label: "No Data", value: [] }],
    supplierCountryCodesError: error,
    supplierCountryCodesLoading: loading,
  };
};
