import { breakpoints, styled } from "@sourceful/shared-components";

export const StyledTD = styled("td", {
  whiteSpace: "normal",
  textAlign: "left",
  padding: "10px 15px",
  height: "40px",
  fontSize: "16px",
  color: "#000C32",
  width: "1%",

  ".has-preview-tooltip": {
    position: "relative",
    display: "inline-block",
    cursor: "pointer",
    width: "100%",
  },

  ".has-preview-tooltip:hover": {
    textDecoration: "underline",
    fontWeight: "bold",
  },

  ".has-preview-tooltip span": {
    visibility: "hidden",
    width: "400%",
    backgroundColor: "#FFFFFF",
    border: "solid 1px black",
    opacity: "0.9",
    color: "black",
    textAlign: "center",
    borderRadius: "6px",
    padding: " 20px",
    position: "absolute",
    zIndex: 1,
    left: "90%",
    transform: "translateY(-50%)",
    fontWeight: "normal",
    top: "50%",
  },

  ".has-preview-tooltip:hover span": {
    visibility: "visible",
  },
});

export const StyledTH = styled("th", {
  whiteSpace: "nowrap",
  textAlign: "left",
  height: "50px",
  padding: "10px 15px",
  userSelect: "none",
  fontWeight: "bold",
  color: "#000C32",
  fontSize: "16px",
  width: "1%",
  background: "white",
  borderTop: "1px solid #B9B9BB",
  borderBottom: " 1px solid #B9B9BB",

  ".th-wrapper": {
    display: "flex",
    minHeight: "24px",
    alignItems: "center",
  },
  ".sort-icon": {
    marginLeft: "10px",
  },
});

export const TableStyles = styled("div", {
  /* This is required to make the table full-width */
  display: "block",
  maxWidth: "100%",
  /* This will make the table scrollable when it gets too small */
  ".tableWrap": {
    display: "block",
    maxWidth: "100%",
    overflowX: "auto",
    overflowY: "hidden",
  },

  table: {
    width: "100%",
    minWidth: breakpoints.md + "px",
    borderSpacing: "0px",
    overflow: "hidden",
    borderRadius: "$medium",
    thead: {
      background: "#f6f6f6",
      textAlign: "left",
      borderRadius: "8px",
    },

    tbody: {
      "tr:nth-child(odd)": {
        backgroundColor: "white",
      },
      "tr:nth-child(even)": {
        backgroundColor: "rgba(0, 90, 225, 0.01)",
      },
    },
  },
});
