import { ApolloError } from "@apollo/client";

import { useGetCaseChangeFromCaseProductByParamsLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapProductNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  fromCaseProductNames: DropdownItem[];
  fromCaseProductNamesError?: ApolloError;
  fromCaseProductNamesLoading: boolean;
  getFromCaseProductNames: (options: Options) => Promise<void>;
}

interface Options {
  baseProductId: number | null;
  warehouseId: number | null;
  conditionId: number | null;
}

export const useFromCaseProductByParams = (): HookResult => {
  const [getFromCaseProductNames, { data, error, loading }] =
    useGetCaseChangeFromCaseProductByParamsLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    fromCaseProductNames:
      data && Array.isArray(data.product) ? mapProductNamesToDropdownObject(data.product) : [],
    fromCaseProductNamesLoading: loading,
    fromCaseProductNamesError: error,
    getFromCaseProductNames: async ({ baseProductId, warehouseId, conditionId }: Options) => {
      getFromCaseProductNames({
        variables: { baseProductId, warehouseId, conditionId },
      });
    },
  };
};
