import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

import { SearchField, styled } from "@sourceful/shared-components";

import { StyledTD, StyledTH, TableStyles } from "../../../../../components/Table/tableStyles";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SM_ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { InlineCode } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { getIAMUsers } from "../api/getIAMUsers";

const TableUtilityBarWrapper = styled("div", {
  padding: "0px 25px",
});

const Topbar = styled("div", {
  paddingTop: "10px",
  display: "grid",
  gridGap: "10px",
  "@sm": {
    display: "flex",
    alignItems: "center",
  },
});

export interface UsersResponse {
  uuid: string;
  name: string;
  email: string;
  organisations_users: {
    organisation: {
      uuid: string;
      name: string;
      display_name: string;
    };
  }[];
}

const columnHelper = createColumnHelper<UsersResponse>();

const columns = [
  columnHelper.accessor((user: UsersResponse) => user.uuid, {
    id: "uuid",
    header: () => "UUID",
    cell: info => <InlineCode>{info.getValue()}</InlineCode>,
    footer: info => info.column.id,
  }),
  columnHelper.accessor((user: UsersResponse) => user.name, {
    id: "name",
    header: () => "Name",
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor((user: UsersResponse) => user.email, {
    id: "email",
    header: () => "Email",
    cell: info => info.renderValue(),
    footer: info => info.column.id,
  }),
  columnHelper.accessor((user: UsersResponse) => user.organisations_users, {
    id: "organisations",
    header: () => "Organisations",
    cell: info => {
      return (
        <>
          {info.getValue().map(organisation_user => (
            <InlineCode>{organisation_user.organisation.display_name}</InlineCode>
          ))}
        </>
      );
    },
    footer: info => info.column.id,
  }),
];

const ViewIAMUsers = () => {
  const [data, setUsers] = useState<UsersResponse[]>([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearch = (searchTerm: string) => setSearchTerm(searchTerm);

  const history = useHistory();
  const { roles } = useAuthorisationContext();

  const [getUsersState, getUsers] = useAPI(getIAMUsers, true, {
    pending: "Fetching users...",
    success: "users fetched successfully",
    error: "Error while trying to fetch users",
  });

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await getUsers({
        offset: 0,
        limit: 50,
        filters: [{ key: "username", value: `_regex:${searchTerm}` }], // Assuming your API can filter users based on a query
      });
      const usersData = Array.isArray(response) ? response[0].data.data : response.data.data;
      setUsers(usersData);
    };
    fetchUsers().catch(error => {
      console.error("error getting users:", error);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchTerm]);

  const table = useReactTable<UsersResponse>({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const items = SM_ADMIN_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push(`/admin/iam/user/create`);
          },
          type: "button",
          title: "Create New User",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];

  return (
    <Page title={"IAM Users"} isLoading={false} error={getUsersState === API_REQUEST_STATE.ERROR}>
      <Panel
        withWrapper
        title={"IAM Users"}
        text={`Via this table, you can view all users`}
        allignTitle="left"
        rightItems={rightItems}
        iconName={"toggle-star-fill"}
      >
        <TableStyles>
          <TableUtilityBarWrapper>
            <Topbar>
              <SearchField
                placeholder="Search The Rows Below"
                handleChange={e => handleSearch(e.target.value)}
                value={searchTerm}
                handleClear={() => handleSearch("")}
              />
            </Topbar>
          </TableUtilityBarWrapper>
          <div className="tableWrap">
            <table>
              <thead>
                {table.getHeaderGroups().map(headerGroup => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map(header => (
                      <StyledTH key={header.id}>
                        {header.isPlaceholder
                          ? null
                          : flexRender(header.column.columnDef.header, header.getContext())}
                      </StyledTH>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map(row => (
                  <tr key={row.id}>
                    {row.getVisibleCells().map(cell => (
                      <StyledTD key={cell.id}>
                        {flexRender(cell.column.columnDef.cell, cell.getContext())}
                      </StyledTD>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>

            <div className="h-4" />
          </div>
        </TableStyles>
      </Panel>
    </Page>
  );
};

export default ViewIAMUsers;
