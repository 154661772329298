export interface WorkOrderForm {
  due_date: string;
  organisation: {
    value: number;
    label: string;
  } | null;
  recipe_name: {
    value: number;
    label: string;
  } | null;
  quantity: string;
  external_work_order_id: string;
  warehouse: {
    value: number;
    label: string;
  } | null;
  work_order_and_recipe_inputs: WorkOrderAndRecipeInputs[];
}

export interface WorkOrderAndRecipeInputs {
  recipe_input: {
    product: {
      id: number;
      name: string;
      base_product_id: number;
    };
    quantity: string;
  };
  work_order_inputs: WorkOrderInput[];
}

export interface WorkOrderInput {
  product_stock: {
    label: string;
    value: number;
  } | null;
  quantity: string;
}

export const initialFieldValues: WorkOrderForm = {
  due_date: "",
  organisation: null,
  recipe_name: null,
  quantity: "",
  external_work_order_id: "",
  warehouse: null,
  work_order_and_recipe_inputs: [],
};

export const fieldNames = {
  due_date: "due_date",
  organisation: "organisation",
  recipe_name: "recipe_name",
  quantity: "quantity",
  external_work_order_id: "external_work_order_id",
  warehouse: "warehouse",
};

export const emptyWorkOrderInput: WorkOrderInput = {
  product_stock: null,
  quantity: "",
};
