import { FormikErrors, FormikTouched } from "formik";
import { useHistory } from "react-router-dom";
import Select from "react-select";

import { Icon, InputField } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  Label,
  LinkLabel,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { RecipeLine } from "../formValues";
import { DropdownItem } from "../graphql/types";

interface RecipeLineProps {
  baseProducts: DropdownItem[];
  values: RecipeLine;
  type: "input" | "output";
  index: number;
  errors: FormikErrors<RecipeLine[]> | undefined;
  touched: FormikTouched<RecipeLine[]> | undefined;
  handleRemoveRecipeLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

const RecipeLineComponent = ({
  values,
  type,
  setFieldValue,
  index,
  handleRemoveRecipeLine,
  errors,
  touched,
  baseProducts,
}: RecipeLineProps) => {
  let history = useHistory();

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <Label isRequired>Base Product</Label>
          <LinkLabel onClick={() => history.push("/inventory/base-products/add-base-product")}>
            + Add Base Product
          </LinkLabel>
          <Select
            styles={reactSelectStyling}
            maxMenuHeight={220}
            isSearchable={true}
            id={`base_product${index}`}
            value={values.base_product}
            options={baseProducts}
            onChange={e => {
              setFieldValue(`${type}Lines[${index}].base_product`, e);
            }}
            placeholder="Select Base Product"
          />
          {Array.isArray(errors) &&
          errors[index]?.base_product &&
          Array.isArray(touched) &&
          touched[index]?.base_product ? (
            <ErrorMessage>{errors[index]?.base_product}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint>
          <Label isRequired htmlFor={`quantity_${index}`}>
            Quantity
          </Label>
          <InputField
            id={`quantity_${index}`}
            size={"medium"}
            type={"number"}
            value={values.quantity}
            placeholder={"Select Quantity"}
            handleChange={e => {
              setFieldValue(`${type}Lines[${index}].quantity`, e.target.value);
            }}
          />

          {Array.isArray(errors) &&
          errors[index]?.quantity &&
          Array.isArray(touched) &&
          touched[index]?.quantity ? (
            <ErrorMessage>{errors[index]?.quantity}</ErrorMessage>
          ) : null}
        </DataPoint>
        <IconWrapper type="button" onClick={() => handleRemoveRecipeLine(index)}>
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </SecondaryLineDataWrapper>
    </SecondaryLineWrapper>
  );
};

export default RecipeLineComponent;
