import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";

export interface AddOrUpdateProductStockBody {
  externalProductId: string;
  externalOrganisationId: string;
  quantity: number;
}

export const addOrUpdateProductStock = async (body: AddOrUpdateProductStockBody) => {
  try {
    return STOCK_MANAGEMENT_API.post("/product-stocks", body);
  } catch (err) {
    console.error("Error in add or update product stock API call", err);
    throw err;
  }
};
