import { InventoryItem } from "../types";

export const mapInventoryToCSV = (inventory: InventoryItem[]) => {
  return inventory.map(data => {
    return {
      "Base Product Id": data.base_product_id,
      "Product Id": data.product_id,
      "Product Name": data.product_name,
      "Prepacked Bundle": data.prepacked_bundle,
      "Weekly Usage (90d)":
        data.weekly_usage_90d == null ? null : Math.round(data.weekly_usage_90d * 10) / 10,
      "Weekly Usage (180d)":
        data.weekly_usage_180d == null ? null : Math.round(data.weekly_usage_180d * 10) / 10,
      "Weekly Usage (360d)":
        data.weekly_usage_360d == null ? null : Math.round(data.weekly_usage_360d * 10) / 10,
      Units:
        data.units_total == null || data.units_in_prepacked == null
          ? null
          : data.units_total - data.units_in_prepacked,
      "Units (in prepacked)": data.units_in_prepacked,
      "Units (total)": data.units_total,
      "FSC Cert": data.fsc_cert,
      "GRS Cert": data.grs_cert,
      "FSC Claim": data.fsc_claim,
      "Weeks Remaining (90d)":
        data.base_product_id == null
          ? null
          : data.weeks_remaining_90d == null
          ? "-"
          : Math.round(data.weeks_remaining_90d * 10) / 10,
      "Weeks Remaining (180d)":
        data.base_product_id == null
          ? null
          : data.weeks_remaining_180d == null
          ? "-"
          : Math.round(data.weeks_remaining_180d * 10) / 10,
      "Weeks Remaining (360d)":
        data.base_product_id == null
          ? null
          : data.weeks_remaining_360d == null
          ? "-"
          : Math.round(data.weeks_remaining_360d * 10) / 10,
      Alert: data.alert,
      Location: data.location,
      Quantity: data.quantity,
      "Case Size": data.case_size,
      "Recent Outbound Units": data.recent_outbound_units,
      "Days Since First Stock": data.days_since_first_stock,
    };
  });
};
