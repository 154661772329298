import moment from "moment";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useWorkOrdersWithData } from "../graphql/hooks/useWorkOrdersWithData";
import { WorkOrderItem } from "../graphql/types";

export default function ViewWorkOrders() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();
  const isAuthorised = WMS_EDIT_ROLES.some(role => roles.includes(role));

  const rightItems: PanelProps["rightItems"] = isAuthorised
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/manifests/work-orders/create");
          },
          type: "button",
          title: "Add work order",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const { workOrders, workOrdersLoading, workOrdersError } = useWorkOrdersWithData();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.1,
        filter: "arraySome",
        accessor: (listItem: WorkOrderItem) => listItem.id,
      },
      {
        Header: "Recipe name",
        widthPercent: 0.4,
        filter: "multiRow",
        Cell: ({ _, row }: CellProps<WorkOrderItem>) => (
          <Link to={`/manifests/recipe/view-recipe/${row.original.recipe?.id}`}>
            <LinkText>{row.original.recipe?.recipe_name}</LinkText>
          </Link>
        ),
        accessor: (listItem: WorkOrderItem) => listItem.recipe?.recipe_name,
      },
      {
        Header: "Qty",
        widthPercent: 0.1,
        filter: "multiRow",
        accessor: (listItem: WorkOrderItem) => listItem.quantity,
      },
      {
        Header: "Completed Qty",
        widthPercent: 0.1,
        filter: "multiRow",
        accessor: (listItem: WorkOrderItem) => listItem.completed_quantity,
      },

      {
        Header: "Status",
        widthPercent: 0.1,
        filter: "multiRow",
        accessor: (listItem: WorkOrderItem) => listItem.work_order_status.work_order_status_name,
        Cell: ({ _, row }: CellProps<WorkOrderItem>) => (
          <StatusBadge
            type="wms"
            statusName={row.original.work_order_status.work_order_status_name}
          />
        ),
      },
      {
        Header: "Warehouse name",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (listItem: WorkOrderItem) => listItem.warehouse.warehouse_name,
        Cell: ({ _, row }: CellProps<WorkOrderItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Organisation",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (listItem: WorkOrderItem) => listItem.organisation.organisation_name,
        Cell: ({ _, row }: CellProps<WorkOrderItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation.id}`}>
            <LinkText>{row.original.organisation.organisation_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Due Date",
        widthPercent: 0.1,
        filter: "multiRow",
        accessor: (listItem: WorkOrderItem) => moment(listItem.due_date).format("x"),
        Cell: ({ _, row }: CellProps<WorkOrderItem>) =>
          moment(row.original.due_date).format("DD-MM-YYYY"),
      },
      {
        Header: "Fulfil",
        widthPercent: 0.1,
        Cell: ({ _, row }: CellProps<WorkOrderItem>) => {
          if (isAuthorised && row.original.work_order_status.work_order_status_name !== "Done") {
            return (
              <Link to={`/manifests/work-orders/fulfil/${row.original.id}`}>
                <SecondaryButton isWithinTable appearance={"blueButton"}>
                  Fulfil
                </SecondaryButton>
              </Link>
            );
          }
          return null;
        },
      },
      {
        Header: "View",
        widthPercent: 0.1,
        Cell: ({ _, row }: CellProps<WorkOrderItem>) => (
          <Link to={`/manifests/work-orders/view/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    [isAuthorised]
  );

  return (
    <Page error={workOrdersError} isLoading={workOrdersLoading} title={"Manifests - Work Orders"}>
      <Panel
        withWrapper
        title={"Work Orders"}
        text={"Use the table below to view Sourceful's Work Orders"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        toolTipMessage={"An order to use a recipe to <br/> bundle different stocks"}
      >
        <Table
          data={workOrders}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
}
