import { FormikErrors, FormikTouched } from "formik";
import { useTranslation } from "react-i18next";

import { Heading } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleTextArea from "../../../../../../shared/components/forms/SimpleTextArea";
import { DataPoint, PrimaryLineDataWrapper } from "../../../../../../styles/SharedStyles";
import { CartonDetails, ExternalQuoteForm, SupplierQuoteLine } from "../formValues";

interface SupplierLogisticsDetailsProps {
  index: number;
  values: ExternalQuoteForm;
  errors: FormikErrors<SupplierQuoteLine>;
  touched: FormikTouched<SupplierQuoteLine>;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const SupplierLogisticsDetails = ({
  index,
  values,
  errors,
  touched,
  setFieldValue,
}: SupplierLogisticsDetailsProps) => {
  const { t } = useTranslation();

  const hasErrors = errors && errors.cartonDetails;

  return (
    <>
      <Heading level={3} fontSize={6} css={{ marginTop: "20px" }}>
        {t("quote_supplier_response_logistics_section_title")}
      </Heading>
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin>
          <SimpleInput
            type="number"
            label={t("quote_supplier_response_unit_per_carton")}
            placeholder={t("quote_supplier_response_unit_per_carton")}
            error={hasErrors && (errors.cartonDetails as FormikErrors<CartonDetails>).unitPerCarton}
            touched={
              touched.cartonDetails &&
              (touched.cartonDetails as FormikTouched<CartonDetails>).unitPerCarton
            }
            name={`supplierQuoteLines[${index}].cartonDetails.unitPerCarton`}
            value={values.supplierQuoteLines[index].cartonDetails.unitPerCarton}
            changeHandler={e => {
              setFieldValue(
                `supplierQuoteLines[${index}].cartonDetails.unitPerCarton`,
                e.target.value
              );
            }}
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin>
          <SimpleInput
            type="number"
            label={t("quote_supplier_response_carton_length")}
            placeholder={t("quote_supplier_response_carton_length")}
            error={hasErrors && (errors.cartonDetails as FormikErrors<CartonDetails>).cartonLength}
            touched={
              touched.cartonDetails &&
              (touched.cartonDetails as FormikTouched<CartonDetails>).cartonLength
            }
            name={`supplierQuoteLines[${index}].cartonDetails.cartonLength`}
            value={values.supplierQuoteLines[index].cartonDetails.cartonLength}
            changeHandler={e => {
              setFieldValue(
                `supplierQuoteLines[${index}].cartonDetails.cartonLength`,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint withUniqueMargin>
          <SimpleInput
            type="number"
            label={t("quote_supplier_response_carton_width")}
            placeholder={t("quote_supplier_response_carton_width")}
            error={hasErrors && (errors.cartonDetails as FormikErrors<CartonDetails>).cartonWidth}
            touched={
              touched.cartonDetails &&
              (touched.cartonDetails as FormikTouched<CartonDetails>).cartonWidth
            }
            name={`supplierQuoteLines[${index}].cartonDetails.cartonWidth`}
            value={values.supplierQuoteLines[index].cartonDetails.cartonWidth}
            changeHandler={e => {
              setFieldValue(
                `supplierQuoteLines[${index}].cartonDetails.cartonWidth`,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint withUniqueMargin>
          <SimpleInput
            type="number"
            label={t("quote_supplier_response_carton_height")}
            placeholder={t("quote_supplier_response_carton_height")}
            error={hasErrors && (errors.cartonDetails as FormikErrors<CartonDetails>).cartonHeight}
            touched={
              touched.cartonDetails &&
              (touched.cartonDetails as FormikTouched<CartonDetails>).cartonHeight
            }
            name={`supplierQuoteLines[${index}].cartonDetails.cartonHeight`}
            value={values.supplierQuoteLines[index].cartonDetails.cartonHeight}
            changeHandler={e => {
              setFieldValue(
                `supplierQuoteLines[${index}].cartonDetails.cartonHeight`,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint withUniqueMargin>
          <SimpleInput
            type="number"
            label={t("quote_supplier_response_carton_weight")}
            placeholder={t("quote_supplier_response_carton_weight")}
            error={hasErrors && (errors.cartonDetails as FormikErrors<CartonDetails>).cartonWeight}
            touched={
              touched.cartonDetails &&
              (touched.cartonDetails as FormikTouched<CartonDetails>).cartonWeight
            }
            name={`supplierQuoteLines[${index}].cartonDetails.cartonWeight`}
            value={values.supplierQuoteLines[index].cartonDetails.cartonWeight}
            changeHandler={e => {
              setFieldValue(
                `supplierQuoteLines[${index}].cartonDetails.cartonWeight`,
                e.target.value
              );
            }}
          />
        </DataPoint>
      </PrimaryLineDataWrapper>
      <PrimaryLineDataWrapper>
        <DataPoint withUniqueMargin fullWidth>
          <SimpleTextArea
            size="medium"
            type="text"
            label={t("quote_supplier_response_packaging_option")}
            placeholder={t("quote_supplier_response_packaging_option")}
            name={`supplierQuoteLines[${index}].packagingOption`}
            value={values.supplierQuoteLines[index].packagingOption}
            handleChange={e => {
              setFieldValue(`supplierQuoteLines[${index}].packagingOption`, e.target.value);
            }}
          />
          {errors && errors.packagingOption && touched.packagingOption ? (
            <ErrorMessage>{errors.packagingOption}</ErrorMessage>
          ) : null}
        </DataPoint>
      </PrimaryLineDataWrapper>
    </>
  );
};
