import { ApolloError } from "@apollo/client";

import { useGetOrganisationsWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { OrganisationItem } from "../types";

export interface HookResult {
  organisations: OrganisationItem[];
  organisationsError?: ApolloError;
  organisationsLoading: boolean;
}

export const useOrganisationsWithData = (): HookResult => {
  const { data, error, loading } = useGetOrganisationsWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    organisations: data && Array.isArray(data.organisation) ? data.organisation : [],
    organisationsError: error,
    organisationsLoading: loading,
  };
};
