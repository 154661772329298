import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createLocationAPI } from "../../api/createLocationAPI";
import { CreateLocationBody } from "../../api/types";
import {
  CREATE_LOCATION_FAILED,
  CREATE_LOCATION_LOADING,
  CREATE_LOCATION_RESET,
  CREATE_LOCATION_SUCCESS,
} from "../types";

export const createLocationLoading = () => {
  return {
    type: CREATE_LOCATION_LOADING,
  };
};

export const createLocationSuccess = () => {
  return {
    type: CREATE_LOCATION_SUCCESS,
  };
};

export const createLocationFailed = () => {
  return {
    type: CREATE_LOCATION_FAILED,
  };
};

export const createLocationReset = () => {
  return {
    type: CREATE_LOCATION_RESET,
  };
};

export const createLocationAction = (body: CreateLocationBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Location", { hideProgressBar: true });
    dispatch(createLocationLoading());
    try {
      await createLocationAPI(body);
      toast.success("Location created successfully.");
      dispatch(createLocationSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating location.");
      dispatch(createLocationFailed());
    }
  };
};
