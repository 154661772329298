import * as Yup from "yup";

export const validationSchema = Yup.object({
  locale: Yup.object()
    .shape({
      value: Yup.number().required("Locale is required"),
    })
    .typeError("Locale is required"),
  start_date: Yup.string().required("Date is required"),
  end_date: Yup.string().required("Date is required"),
});
