import { Formik, FormikErrors, FormikState } from "formik";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { CreateStockTransferBody } from "../api/types";
import StockTransferLine from "../components/StockTransferLine";
import {
  StockTransferForm,
  TransferLine,
  emptyTransferLine,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { useLocationNamesByWarehouseId } from "../graphql/hooks/useStockTransferLocationNamesByWarehouseId";
import { useProductStockByLocationId } from "../graphql/hooks/useStockTransferProductStockByLocationId";
import { useWarehouseNames } from "../graphql/hooks/useStockTransferWarehouseNames";
import { mapTransferLineToDTO } from "../mappers";
import {
  createStockTransferAction,
  createStockTransferReset,
} from "../redux/actions/createStockTransferAction";
import { CreateStockTransferReducer } from "../redux/reducers/createStockTransferReducer";
import { validationSchema } from "../validation";

export interface CreateStockTransferProps {
  createStockTransferState: CreateStockTransferReducer;
  createStockTransfer: (body: CreateStockTransferBody) => void;
  resetCreateStockTransfer: () => void;
}

export function CreateStockTransfer(props: CreateStockTransferProps) {
  const [isTransferringStock, setIsTransferringStock] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<StockTransferForm>(initialFieldValues);
  const { createStockTransferState, createStockTransfer, resetCreateStockTransfer } = props;

  const onSubmit = (
    values: StockTransferForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<StockTransferForm>>) => void }
  ) => {
    const uniqueTransferLines = uniqBy(
      values.transfer_lines,
      transfer_line => transfer_line.source_product_stock?.value
    );

    if (uniqueTransferLines.length !== values.transfer_lines.length) {
      toast.error("Please select a different product stock for each transfer line");
      return;
    }

    const createStockTransferBody: CreateStockTransferBody = {
      from_location_id: values.from_location?.value!,
      to_location_id: values.to_location?.value!,
      internal_transfer_lines: mapTransferLineToDTO(values.transfer_lines),
    };

    createStockTransfer(createStockTransferBody);
    resetForm();
  };

  useEffect(() => {
    if (createStockTransferState.success) {
      resetCreateStockTransfer();
      setInitialValues({ ...initialFieldValues });
      setIsTransferringStock(false);
    }

    if (createStockTransferState.error) {
      props.resetCreateStockTransfer();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStockTransferState.success, createStockTransferState.error]);

  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useWarehouseNames();
  const { locationNames, locationNamesLoading, locationNamesError, getLocationNames } =
    useLocationNamesByWarehouseId();
  const { productStock, productStockLoading, productStockError, getProductStock } =
    useProductStockByLocationId();

  const locationCopy = locationNamesLoading ? "Loading Locations" : "Select a location";
  const isLoading = warehouseNamesLoading || createStockTransferState.loading;
  const error = warehouseNamesError || productStockError || locationNamesError;

  return (
    <Page title="Operations - Create Stock Transfer" error={error} isLoading={isLoading}>
      <Panel withWrapper title={"Stock Transfer"} subtitle={"Transfer Stock between locations"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              setTouched,
              handleSubmit,
              errors,
              validateForm,
              touched,
            }) => {
              const fromLocation = values.warehouse ? locationCopy : "Please select a warehouse";
              const toLocation = values.from_location
                ? locationCopy
                : "Please select a From Location";

              return (
                <StyledForm onSubmit={handleSubmit}>
                  {!isTransferringStock ? (
                    <>
                      <TopPanelInputsWrapper>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            label="Warehouse"
                            placeholder="Select Warehouse"
                            htmlFor={fieldNames.warehouse}
                            name={fieldNames.warehouse}
                            value={values.warehouse}
                            options={warehouseNames}
                            error={errors.warehouse}
                            touched={touched.warehouse}
                            changeHandler={e => {
                              setFieldValue(fieldNames.warehouse, e);
                              getLocationNames({
                                warehouseId: e?.value!,
                              });
                            }}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            label="From Location"
                            placeholder={fromLocation}
                            htmlFor={fieldNames.from_location}
                            name={fieldNames.from_location}
                            value={values.from_location}
                            options={locationNames}
                            error={errors.from_location}
                            touched={touched.from_location}
                            changeHandler={e => {
                              setFieldValue(fieldNames.from_location, e);
                              getProductStock({
                                locationId: e?.value!,
                              });
                            }}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            label="To Location"
                            placeholder={toLocation}
                            htmlFor={fieldNames.to_location}
                            name={fieldNames.to_location}
                            value={values.to_location}
                            options={locationNames}
                            error={errors.to_location}
                            touched={touched.to_location}
                            changeHandler={e => {
                              setFieldValue(fieldNames.to_location, e);
                            }}
                          />
                        </DataPoint>
                      </TopPanelInputsWrapper>
                      <ButtonWrapper className={WMSButtonGroup({ type: "largeMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={async () => {
                            setTouched({
                              warehouse: true,
                              from_location: true,
                              to_location: true,
                            });

                            const sameLocation =
                              values.to_location?.value === values.from_location?.value;

                            if (sameLocation && values.to_location) {
                              toast.warning(
                                "'From Location' and 'To Location' cannot be identical. "
                              );
                              return;
                            }

                            const formErrors = await validateForm();

                            if (Object.keys(formErrors).length === 0) {
                              setInitialValues({
                                ...values,
                                transfer_lines: [emptyTransferLine],
                              });
                              setIsTransferringStock(true);
                            }
                          }}
                        >
                          <IconText
                            text={"Add Stock Transfer Line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </>
                  ) : (
                    <TopPanelInputsWrapper>
                      <DisplayBox isGray label="Warehouse" value={values.warehouse?.label} />
                      <DisplayBox
                        isGray
                        tooltipMessage="Location within specified warehouse"
                        label="From Location"
                        value={values.from_location?.label}
                      />
                      <DisplayBox isGray label="To Location" value={values.to_location?.label} />
                    </TopPanelInputsWrapper>
                  )}

                  {isTransferringStock && !productStockLoading && (
                    <>
                      <PrimaryLineWrapper>
                        <Title>Stock Transfer Lines</Title>
                        {values.transfer_lines.map((line, index) => (
                          <StockTransferLine
                            key={line.id}
                            dropdownData={productStock}
                            errors={errors.transfer_lines as FormikErrors<TransferLine[]>}
                            touched={touched.transfer_lines}
                            values={line}
                            index={index}
                            setFieldValue={setFieldValue}
                            handleRemoveTransferLine={(lineIndex: number) => {
                              const fitleredManifestLines = values.transfer_lines.filter(
                                (_, i) => i !== lineIndex
                              );
                              const newValues = { ...values };
                              newValues.transfer_lines = fitleredManifestLines;
                              if (fitleredManifestLines.length === 0) {
                                setIsTransferringStock(false);
                              }
                              setInitialValues(newValues);
                            }}
                          />
                        ))}

                        <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                          <SecondaryButton
                            type="button"
                            appearance="whiteButtonBlueText"
                            onClick={() => {
                              setInitialValues({
                                ...values,
                                transfer_lines: [
                                  ...values.transfer_lines,
                                  { ...emptyTransferLine, id: values.transfer_lines.length },
                                ],
                              });
                            }}
                          >
                            <IconText
                              text={"Add Stock Transfer Line"}
                              primaryIcon={"alert-add-outline"}
                            />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </PrimaryLineWrapper>

                      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                        <SecondaryButton
                          type="reset"
                          appearance={"whiteButton"}
                          onClick={() => {
                            setInitialValues({ ...initialFieldValues });
                            setIsTransferringStock(false);
                          }}
                        >
                          Cancel
                        </SecondaryButton>
                        <SecondaryButton
                          appearance={"blueButton"}
                          type="button"
                          onClick={() => handleSubmit()}
                        >
                          Confirm
                        </SecondaryButton>
                      </ButtonGroup>
                    </>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createStockTransferState: state.createStockTransferReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createStockTransfer: (body: CreateStockTransferBody) =>
      dispatch(createStockTransferAction(body)),
    resetCreateStockTransfer: () => dispatch(createStockTransferReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateStockTransfer);
