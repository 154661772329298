import { AddressItem } from "../graphql/types";

export const mapAddressToString = (address?: AddressItem): string => {
  if (!address) return "";

  return [
    address.address_name !== address.address_line_1 ? address.address_name : null,
    address.address_line_1,
    address.address_line_2,
    address.address_line_3,
    address.city,
    address.region,
    address.country_code,
    address.postcode,
  ]
    .filter(Boolean)
    .join(", ");
};
