import { ApolloError } from "@apollo/client";

import { useGetCurrenciesForRfQsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapCurrencyCodeToDropdownItem } from "../../../../../../shared/mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  currencies: DropdownItem[];
  currenciesError?: ApolloError;
  currenciesLoading: boolean;
}

export const useCurrenciesForPrices = (): HookResult => {
  const { data, error, loading } = useGetCurrenciesForRfQsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    currencies:
      data && Array.isArray(data.currency) ? mapCurrencyCodeToDropdownItem(data.currency) : [],
    currenciesError: error,
    currenciesLoading: loading,
  };
};
