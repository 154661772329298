import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";
import { AddStockManagementContactBody } from "./types";

export const addStockManagementContact = async (body: AddStockManagementContactBody) => {
  try {
    return await STOCK_MANAGEMENT_API.post("/contacts", body);
  } catch (err) {
    console.log("Error in add stock management contact to organisation API call", err);
    throw err;
  }
};
