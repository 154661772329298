import { useState } from "react";

import { CheckBox, IconText, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  ButtonWrapper,
  DataPoint,
  FormattedLineDataWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { booleanReadableLabels } from "../../shared/graphql/mappers";
import { Address } from "../../shared/graphql/types";
import { UpdateAddressDataInterface } from "../../shared/types";
import { FormattedContact } from "../page/UpdateStockManagementOrgansiation";
import UpdateStockManagementAddress from "./UpdateStockManagementAddress";

interface Props {
  address: Address | null | undefined;
  handleSubmitUpdateAddress: (values: UpdateAddressDataInterface, address: Address) => void;
  organisationContacts: FormattedContact[];
}

const StockManagementAddressesData = ({
  address,
  handleSubmitUpdateAddress,
  organisationContacts,
}: Props): JSX.Element | null => {
  const [showUpdateAddressForm, setShowUpdateAddressForm] = useState(false);
  if (!address) return null;

  const filteredOrganisationContacts = organisationContacts
    .map((contact, index) => ({ ...contact, indexId: index }))
    .filter(
      contact =>
        contact.id !== undefined && (contact.addressId === address.id || contact.addressId === null)
    );

  if (!showUpdateAddressForm) {
    return (
      <PrimaryLineWrapper>
        <FormattedLineDataWrapper>
          <Title>{address.name}</Title>
          <PrimaryLineDataWrapper marginSize={"smallMargin"} alignStart>
            <FormattedLineDataWrapper>
              <DisplayBox isGray label="Address Line 1" value={address.addressLine1} />
              <DisplayBox isGray label="Address Line 2" value={address.addressLine2} />
              <DisplayBox isGray label="Address Line 3" value={address.addressLine3} />
              <DisplayBox isGray label="Address Description" value={address.addressDescription} />
              <DisplayBox isGray label="City" value={address.city} />
              <DataPoint>
                <CheckBox
                  label={booleanReadableLabels.isWarehouse}
                  isDisabled={true}
                  value={"readOnlyCheckbox"}
                  id={"readOnlyCheckbox"}
                  isChecked={address.isWarehouse ?? false}
                  handleChange={e => null}
                />
              </DataPoint>
            </FormattedLineDataWrapper>
            <FormattedLineDataWrapper>
              <DisplayBox isGray label="Post Code" value={address.postcode} />
              <DisplayBox isGray label="Region or US State" value={address.region} />
              <DisplayBox isGray label="Country Code" value={address.countryCode} />
              <DisplayBox
                isGray
                label="Delivery timeline"
                value={`${address.deliveryTimeline?.min} to ${address.deliveryTimeline?.max} ${
                  address.deliveryTimeline?.units
                } ${address.deliveryTimeline?.businessDaysOnly ? "business days only" : ""} `}
              />
            </FormattedLineDataWrapper>
          </PrimaryLineDataWrapper>
        </FormattedLineDataWrapper>
        <VisibleFor roles={SM_ADMIN_ROLES}>
          <ButtonWrapper style={{ marginTop: "10px" }}>
            <SecondaryButton
              onClick={() => setShowUpdateAddressForm(true)}
              appearance={"whiteButtonBlueText"}
              isWithinTable
              type="button"
            >
              <IconText text={"Update Address"} primaryIcon={"document-pencil"} />
            </SecondaryButton>
          </ButtonWrapper>
        </VisibleFor>
      </PrimaryLineWrapper>
    );
  } else {
    return (
      <PrimaryLineWrapper>
        <UpdateStockManagementAddress
          address={address}
          onUpdateAddress={(values, address) => handleSubmitUpdateAddress(values, address)}
          setShowUpdateAddressForm={setShowUpdateAddressForm}
          filteredOrganisationContacts={filteredOrganisationContacts}
        />
      </PrimaryLineWrapper>
    );
  }
};

export default StockManagementAddressesData;
