import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { useRequestsForQuotations } from "../graphql/hooks/useRequestsForQuotations";
import { mapRFQToTableRow } from "../mappers";
import { RFQTableRow } from "../types";

export default function RequestForQuotationView() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { requestsForQuotations, requestsForQuotationsError, requestsForQuotationsLoading } =
    useRequestsForQuotations();

  const formattedRFQs: RFQTableRow[] = mapRFQToTableRow(requestsForQuotations);

  const rightItems: PanelProps["rightItems"] = SOURCING_EDIT_ROLES.some(role =>
    roles.includes(role)
  )
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/quotations/request-for-quotation/create-request-for-quotation");
          },
          type: "button",
          title: "New RFQ",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const tableColumns = useMemo(
    () => [
      {
        Header: "RFQ ID",
        widthPercent: 0.05,
        accessor: (rfq: RFQTableRow) => rfq.rfqId,
      },
      {
        Header: "Supplier",
        widthPercent: 0.2,
        accessor: (rfq: RFQTableRow) => rfq.supplierName,
      },
      {
        Header: "Submitted On",
        widthPercent: 0.1,
        accessor: (rfq: RFQTableRow) => rfq.submittedOn,
      },
      {
        Header: "Status",
        widthPercent: 0.1,
        Cell: ({ row }: CellProps<RFQTableRow>) => (
          <StatusBadge type="sourcing" statusName={row.original.status} />
        ),
        accessor: (rfq: RFQTableRow) => rfq.status,
      },
      {
        Header: "Quotation",
        widthPercent: 0.1,
        accessor: (rfq: RFQTableRow) => rfq.rfqId,
        Cell: ({ row }: CellProps<RFQTableRow>) => {
          return row.original.status !== "Pending" ? (
            <div>
              <Icon name="alert-tick-outline"></Icon>
            </div>
          ) : (
            <VisibleFor roles={SOURCING_EDIT_ROLES}>
              <Link
                to={`/quotations/quotation/create-quotation-response?supplierQuoteRequestId=${row.original.rfqId}`}
              >
                <SecondaryButton appearance={"blueButton"} isWithinTable>
                  Add Quotation
                </SecondaryButton>
              </Link>
            </VisibleFor>
          );
        },
      },
      {
        Header: "View",
        widthPercent: 0.1,
        accessor: (rfq: RFQTableRow) => rfq.rfqId,
        Cell: ({ row }: CellProps<RFQTableRow>) => {
          return (
            <Link
              to={`/quotations/request-for-quotation/view-request-for-quotation/${row.original.rfqId}`}
            >
              <SecondaryButton appearance={"blueButton"} isWithinTable>
                View
              </SecondaryButton>
            </Link>
          );
        },
      },
    ],
    []
  );

  return (
    <Page
      title={"Requests for Quotations"}
      isLoading={requestsForQuotationsLoading}
      error={requestsForQuotationsError}
    >
      <Panel
        withWrapper={true}
        title={"RFQs"}
        text={
          "Use the table below to view all submitted requests for quotation. Each RFQ goes to a single supplier but may include multiple requests for products or various minimum order quantitties of the same product. Further information about the RFQs can be found in the relevant linked pages."
        }
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={formattedRFQs} columns={tableColumns} />
      </Panel>
    </Page>
  );
}
