import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { caseChangeAPI } from "../../api/caseChange";
import { CaseChangeBody } from "../../api/types";
import {
  CASE_CHANGE_FAILED,
  CASE_CHANGE_LOADING,
  CASE_CHANGE_RESET,
  CASE_CHANGE_SUCCESS,
} from "../types";

export const caseChangeLoading = () => {
  return {
    type: CASE_CHANGE_LOADING,
  };
};

export const caseChangeSuccess = () => {
  return {
    type: CASE_CHANGE_SUCCESS,
  };
};

export const caseChangeFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CASE_CHANGE_FAILED,
    errors: errors,
  };
};

export const caseChangeReset = () => {
  return {
    type: CASE_CHANGE_RESET,
  };
};

export const caseChangeAction = (body: CaseChangeBody) => {
  return async (dispatch: Dispatch) => {
    toast("Executing Case Change", { hideProgressBar: true });
    dispatch(caseChangeLoading());
    try {
      await caseChangeAPI(body);
      toast.success("Case Change executed successfully.");
      dispatch(caseChangeSuccess());
    } catch (err) {
      toast.error("Something went wrong with executing case change.");
      dispatch(caseChangeFailed({ errors: (err as any).response?.data?.errors }));
    }
  };
};
