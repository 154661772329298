import { ApolloError } from "@apollo/client";

import { useGetStockManagementOrganisationByIdQuery } from "../../../../../../generated/graphql_stock_management";
import { GRAPHQL_STOCK_MANAGEMENT } from "../../../../../../shared/constants";
import { mapOrganisation } from "../mappers";
import { Organisation } from "../types";

export interface HookResult {
  organisation?: Organisation;
  organisationError?: ApolloError;
  organisationLoading: boolean;
  refetchOrganisation: () => Promise<void>;
}

export const useOrganisationById = (organisationId: string): HookResult => {
  const { data, error, loading, refetch } = useGetStockManagementOrganisationByIdQuery({
    context: {
      clientName: GRAPHQL_STOCK_MANAGEMENT,
    },
    variables: {
      organisationId,
    },
  });

  return {
    organisation: data?.organisationById ? mapOrganisation(data.organisationById) : undefined,
    organisationError: error,
    organisationLoading: loading,
    refetchOrganisation: async () => {
      refetch({ organisationId });
    },
  };
};
