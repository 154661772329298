import { useState } from "react";

import { Header, Icon, styled } from "@sourceful/shared-components";

import {
  AttributeType,
  BaseProductVersion,
  BasicEnum,
  ContentAttribute,
  SizeEnum,
  ValueEnum,
} from "../../shared/types";
import { BaseProductStatus } from "./BaseProductStatus";

interface BaseProductVersionRowProps {
  baseProductVersion: BaseProductVersion;
  name: string;
}

interface DisplayContentAttributeProps {
  contentAttribute: ContentAttribute;
}

interface DisplayValueEnumProps {
  valueEnum: ValueEnum;
  type: AttributeType;
}

const DisplayToggleContainer = styled("div", {
  position: "relative",
});

const ToggleIconWrapper = styled("span", {
  position: "absolute",
  left: "90%",
  top: "0%",
  cursor: "pointer",
});

const DisplayValueEnum: React.FC<DisplayValueEnumProps> = ({ valueEnum, type }) => {
  const [viewInfo, setViewInfo] = useState(false);
  const toggleViewInfo = () => {
    setViewInfo(prevViewInfo => !prevViewInfo);
  };
  const isSizeType = type === "size";
  const sizeEnum: SizeEnum | null = isSizeType ? (valueEnum as SizeEnum) : null;
  const basicEnum: BasicEnum | null = !isSizeType ? (valueEnum as BasicEnum) : null;

  const DisplayInfoContainer = styled("div", {
    backgroundColor: "#D3D3D3",
    paddingInline: "5px",
  });

  return (
    <DisplayToggleContainer>
      <p>{valueEnum.id}</p>
      <ToggleIconWrapper onClick={() => toggleViewInfo()}>
        <Icon name={viewInfo ? "arrow-direction-up" : "arrow-direction-down"} />
      </ToggleIconWrapper>
      {viewInfo &&
        (isSizeType && sizeEnum ? (
          <DisplayInfoContainer>
            <p>width: {`${sizeEnum.width} ${sizeEnum.unit}`}</p>
            <p>height: {`${sizeEnum.height} ${sizeEnum.unit}`}</p>
            {sizeEnum.length !== null && <p>length: {`${sizeEnum.length} ${sizeEnum.unit}`}</p>}
          </DisplayInfoContainer>
        ) : (
          basicEnum && (
            <DisplayInfoContainer>
              <p>value: {basicEnum.value}</p>
            </DisplayInfoContainer>
          )
        ))}
    </DisplayToggleContainer>
  );
};

const DisplayContentAttribute: React.FC<DisplayContentAttributeProps> = ({ contentAttribute }) => {
  const [viewInfo, setViewInfo] = useState(false);
  const toggleViewInfo = () => {
    setViewInfo(prevViewInfo => !prevViewInfo);
  };

  const ContentAttributeContainer = styled("div", {
    border: "1px solid black",
    borderRadius: "15px",
  });

  const ViewInfoContainer = styled("div", {
    padding: "5px",
  });

  return (
    <div>
      <DisplayToggleContainer>
        <p>{contentAttribute.title}</p>
        <ToggleIconWrapper onClick={() => toggleViewInfo()}>
          <Icon name={viewInfo ? "arrow-direction-up" : "arrow-direction-down"} />
        </ToggleIconWrapper>
      </DisplayToggleContainer>

      <ContentAttributeContainer>
        {viewInfo && (
          <ViewInfoContainer>
            <p>id: {contentAttribute.id}</p>
            <p>type: {contentAttribute.type}</p>
            {contentAttribute.enum.map(enumValue => (
              <DisplayValueEnum
                key={enumValue.id}
                valueEnum={enumValue}
                type={contentAttribute.type}
              />
            ))}
          </ViewInfoContainer>
        )}
      </ContentAttributeContainer>
    </div>
  );
};

const EditBaseProductVersion: React.FC<BaseProductVersionRowProps> = ({
  baseProductVersion,
  name,
}) => {
  const [viewContentAttributes, setViewContentAttributes] = useState(false);
  const [viewPhysicalAttributes, setViewPhysicalAttributes] = useState(false);

  const toggleViewContentAttributes = () => {
    setViewContentAttributes(prevViewContentAttributes => !prevViewContentAttributes);
  };

  const toggleViewPhysicalAttributes = () => {
    setViewPhysicalAttributes(prevViewPhysicalAttributes => !prevViewPhysicalAttributes);
  };

  return (
    <div style={{ marginTop: "30px", overflowY: "scroll", maxHeight: "90vh" }}>
      <Header>{name}</Header>
      <p>Version: {baseProductVersion.version}</p>
      <p>id: {baseProductVersion.id}</p>
      <BaseProductStatus isCurrentVersion={baseProductVersion.isCurrentVersion} />

      <div>
        <DisplayToggleContainer>
          <h3>Content Attributes</h3>
          <ToggleIconWrapper onClick={() => toggleViewContentAttributes()}>
            <Icon name={viewContentAttributes ? "arrow-direction-up" : "arrow-direction-down"} />
          </ToggleIconWrapper>
        </DisplayToggleContainer>

        {viewContentAttributes &&
          baseProductVersion.contentAttributes.map(contentAttribute => (
            <DisplayContentAttribute
              key={contentAttribute.id}
              contentAttribute={contentAttribute}
            />
          ))}

        <DisplayToggleContainer>
          <h3>Physical Attributes</h3>
          <ToggleIconWrapper onClick={() => toggleViewPhysicalAttributes()}>
            <Icon name={viewPhysicalAttributes ? "arrow-direction-up" : "arrow-direction-down"} />
          </ToggleIconWrapper>
        </DisplayToggleContainer>

        {viewPhysicalAttributes &&
          baseProductVersion.physicalAttributes.map(physicalAttribute => (
            <DisplayContentAttribute
              key={physicalAttribute.id}
              contentAttribute={physicalAttribute}
            />
          ))}
      </div>
    </div>
  );
};

export default EditBaseProductVersion;
