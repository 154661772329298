import { SOURCING_API } from "../../../../../../api/baseConfig";
import { CreateQuotationCostTypeBody } from "./types";

export const createQuotationCostTypeAPI = async (body: CreateQuotationCostTypeBody) => {
  try {
    return await SOURCING_API.post("/quotation-cost-types", body);
  } catch (err) {
    console.log("Error when creating quotation cost type API call", err);
    throw err;
  }
};
