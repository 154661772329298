import { WMS_API } from "../../../../../api/baseConfig";
import { FulfilOutboundManifestLineBody } from "./types";

export const fulfilOutboundManifestLineAPI = async (
  fulfilOutboundManifestLineAPIBody: FulfilOutboundManifestLineBody
) => {
  try {
    return await WMS_API.post(
      `/outbound-manifest-lines/${fulfilOutboundManifestLineAPIBody.outbound_manifest_line_id}/fulfil`,
      fulfilOutboundManifestLineAPIBody
    );
  } catch (err) {
    console.log("Error in fulfil outbound manifest line API call:", err);
    throw err;
  }
};
