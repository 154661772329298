import {
  FULFILLING_OUTBOUND_MANIFEST_LINE,
  FULFIL_OUTBOUND_MANIFEST_LINE_FAILED,
  FULFIL_OUTBOUND_MANIFEST_LINE_RESET,
  FULFIL_OUTBOUND_MANIFEST_LINE_SUCCESS,
} from "../types";

export interface FulfilOutboundManifestLineReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: FulfilOutboundManifestLineReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function fulfilOutboundManifestLineReducer(
  state: FulfilOutboundManifestLineReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case FULFILLING_OUTBOUND_MANIFEST_LINE:
      return {
        ...state,
        loading: true,
      };
    case FULFIL_OUTBOUND_MANIFEST_LINE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case FULFIL_OUTBOUND_MANIFEST_LINE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case FULFIL_OUTBOUND_MANIFEST_LINE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
