import { styled } from "@sourceful/shared-components";

const QuantityTag = styled("div", {
  display: "inline-block",
  background: "#f3f3f3",
  borderRadius: "5px",
  paddingLeft: "5px",
  marginRight: "10px",
  marginBottom: "10px",
});

const QuantityTagContainer = styled("div", {
  marginTop: "10px",
});

const QuantityTagCloseButton = styled("button", {
  border: "none",
  borderLeft: "1px solid #e2e2e2",
  cursor: "pointer",
  marginLeft: "10px",
});

const QuantityTagInner = styled("div", {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
});

interface QuantityTagsProps {
  quantityValues: string[];
  index: number;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void;
}

export const QuantityTags = ({ quantityValues, index, setFieldValue }: QuantityTagsProps) => {
  return (
    <QuantityTagContainer>
      {quantityValues.map((quantity, qtyIndex) => {
        return (
          <QuantityTag key={qtyIndex}>
            <QuantityTagInner style={{ display: "flex" }}>
              <div>{quantity}</div>
              <QuantityTagCloseButton
                onClick={() => {
                  const newQuantity = [...quantityValues];
                  newQuantity.splice(qtyIndex, 1);
                  setFieldValue(`product_lines[${index}].quantity`, newQuantity);
                }}
              >
                x
              </QuantityTagCloseButton>
            </QuantityTagInner>
          </QuantityTag>
        );
      })}
    </QuantityTagContainer>
  );
};
