import { PRODUCT_PRICING_API } from "../../../../../api/baseConfig";

export const expireProductPriceApi = async (pricing_id: number) => {
  try {
    return await PRODUCT_PRICING_API.post(`/expire_product_pricing?pricing_id=${pricing_id}`);
  } catch (err) {
    console.log("Error in expiring product price API call", err);
    throw err;
  }
};
