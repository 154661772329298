import { SetStateAction } from "react";

import { PurchaseOrderPrefillResponse } from "../api/types";
import { PendingDemandItem } from "../graphql/types";
import { PurchaseOrderForm } from "../types";

type PackedPurchaseOrderPrefillResponse =
  | PurchaseOrderPrefillResponse
  | (PurchaseOrderPrefillResponse | undefined)[]
  | undefined;

export interface MapPurchaseOrderToFormValuesProps {
  response: PackedPurchaseOrderPrefillResponse;
  pendingDemand: PendingDemandItem[];
  setSelectedContactIds: (value: SetStateAction<number[]>) => void;
  setSelectedClientOrderAddressIds: (value: SetStateAction<number[]>) => void;
  getSuppliers: (options: { productIds: number[] }) => Promise<void>;
  setIsAddingPOLines: (value: SetStateAction<boolean>) => void;
  getAddressesByOrganisationId: (options: { organisationId: number }) => Promise<void>;
  getXeroContactIdByOrganisationId: (options: { organisationId: number }) => Promise<void>;
}

const unpackPurchaseOrderPrefillResponse = (
  response: PackedPurchaseOrderPrefillResponse
): PurchaseOrderPrefillResponse | undefined => {
  if (Array.isArray(response)) {
    if (response.length > 0) {
      if (!response[0]) {
        return;
      }
      return response[0];
    } else {
      return;
    }
  } else {
    return response;
  }
};

export const mapPurchaseOrderToFormValues = ({
  response,
  pendingDemand,
  setSelectedContactIds,
  setSelectedClientOrderAddressIds,
  getSuppliers,
  setIsAddingPOLines,
  getAddressesByOrganisationId,
  getXeroContactIdByOrganisationId,
}: MapPurchaseOrderToFormValuesProps): PurchaseOrderForm | undefined => {
  const purchaseOrder = unpackPurchaseOrderPrefillResponse(response);

  if (!purchaseOrder) return;

  const mappedPurchaseOrder: PurchaseOrderForm = { ...purchaseOrder };

  const selectedClientOrders = pendingDemand.filter(demand => {
    return mappedPurchaseOrder.client_orders.some(
      ({ value }) => value === demand.client_order_line?.client_order.id
    );
  });

  if (selectedClientOrders) {
    const contactIds = selectedClientOrders.map(clientOrder => {
      return clientOrder.client_order_line?.client_order.contact_id!;
    });
    setSelectedContactIds(contactIds);

    const addressIds = selectedClientOrders.map(clientOrder => {
      return clientOrder.client_order_line?.client_order.address_id!;
    });
    setSelectedClientOrderAddressIds(addressIds);

    const productIds: number[] = selectedClientOrders
      .filter(demand => demand.product)
      .map(demand => demand.product!.id);

    getSuppliers({ productIds });

    mappedPurchaseOrder.purchase_order_lines.forEach(poLine => {
      const matchingClientOrders = selectedClientOrders.filter(
        demand => demand.product?.id === poLine.product?.value
      );
      if (matchingClientOrders && matchingClientOrders.length > 0) {
        poLine.client_order_lines = [...matchingClientOrders];
      } else {
        poLine.client_order_lines = [];
      }
    });
  }

  if (mappedPurchaseOrder.purchase_order_lines.length > 0) {
    setIsAddingPOLines(true);
  }

  if (mappedPurchaseOrder.clients.length === 1) {
    getAddressesByOrganisationId({ organisationId: mappedPurchaseOrder.clients[0].value });
  }

  if (mappedPurchaseOrder.manufacturer) {
    getXeroContactIdByOrganisationId({
      organisationId: mappedPurchaseOrder.manufacturer.value,
    });
  }

  return mappedPurchaseOrder;
};
