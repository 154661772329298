/* eslint-disable react-hooks/exhaustive-deps */
import { Formik } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { CheckBox, IconText } from "@sourceful/shared-components";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import AttachmentUploader from "../../../../../components/AttachmentUploader/AttachmentUploader";
import Panel from "../../../../../components/panel/panel";
import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { UK_LOCALE } from "../../../../../shared/constants";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { emptyAttachment } from "../../../shared/constants";
import { createSupplierAPI } from "../api/createSupplierAPI";
import { CreateSupplierBody } from "../api/types";
import { deliveryType, localeOptions } from "../constants/index";
import { fieldNames, initialFieldValues } from "../formValues";
import { useContactIdsByEmail } from "../graphql/hooks/useContactIdsByEmail";
import { useCountryCodes } from "../graphql/hooks/useCountryCodes";
import { useSupplierIdsByParams } from "../graphql/hooks/useSupplierIdsByParams";
import { AdminSupplierDetailsInterface } from "../types";
import { validationSchema } from "../validation";

const CreateSupplier = () => {
  const [createSupplierState, createSupplier] = useAPI(createSupplierAPI);

  const initialValues: AdminSupplierDetailsInterface = initialFieldValues;
  const [supplierCreated, setSupplierCreated] = useState<boolean>(false);
  const [contactCreated, setContactCreated] = useState<boolean>(false);
  const [supplierExists, setSupplierExists] = useState<boolean>(false);
  const [contactExists, setContactExists] = useState<boolean>(false);

  const { supplierIds, supplierIdsLoading, supplierIdsError, getSupplierIdsByParams } =
    useSupplierIdsByParams();

  const { contactIds, contactIdsLoading, contactIdsError, getContactIdsByEmail } =
    useContactIdsByEmail();

  const { countryCodes, countryCodesLoading, countryCodesError } = useCountryCodes();

  useEffect(() => {
    if (!supplierIdsLoading && supplierIds) {
      if (supplierIds.length === 0) {
        setSupplierCreated(true);
        setSupplierExists(false);
      }
      if (supplierIds.length !== 0) {
        setSupplierExists(true);
      }
    }
  }, [supplierIds, supplierIdsLoading]);

  useEffect(() => {
    if (supplierExists && !supplierIdsLoading) {
      toast.error("A supplier with these parameters already exists.");
    }
  }, [supplierExists]);

  useEffect(() => {
    if (!contactIdsLoading && contactIds) {
      if (contactIds.length === 0) {
        setContactCreated(true);
        setContactExists(false);
      }
      if (contactIds.length !== 0) {
        setContactExists(true);
      }
    }
  }, [contactIds, contactIdsLoading]);

  useEffect(() => {
    if (contactExists && !contactIdsLoading) {
      toast.error("A contact with these parameters already exists.");
    }
  }, [contactExists]);

  useEffect(() => {
    if (createSupplierState === API_REQUEST_STATE.SUCCESS) {
      setSupplierCreated(false);
      setContactCreated(false);
      setSupplierExists(false);
      setContactExists(false);
    }
  }, [createSupplierState]);

  const onSubmit = async (values: AdminSupplierDetailsInterface) => {
    const organisation = {
      organisation_name: values.supplier_organisation_name,
      external_organisation_id: values.external_organisation_id,
    };

    const contact = {
      contact_first_name: values.contact_first_name,
      contact_last_name: values.contact_last_name,
      contact_email: values.contact_email_address,
      contact_phone_number: values.contact_phone_number,
    };

    const address = {
      address_line_1: values.address_line_1,
      address_line_2: values.address_line_2,
      address_line_3: values.address_line_3,
      city: values.city,
      postcode: values.postcode,
      country_code: values.country_code!.label,
    };

    const details = {
      uses_product_references: values.uses_product_references,
      send_client_name_config: values.send_client_name_config,
      order_number_prefix: values.order_number_prefix,
      invoice_type_id: values.invoice_type!.value,
      locale: values.locale!.label,
      delivery_type_id: values.delivery_type!.value,
      supplier_delivery_date_config: Number(values.delivery_date_delta),
      dispatch_hours: values.dispatch_hours > 0 ? Number(values.dispatch_hours) : undefined,
      qa_attachment_files: values.qa_attachment_files.map((attachment: UploadedFile) => ({
        uuid: attachment.attachment_uuid,
        originalFileName: attachment.original_filename,
      })),
    };

    const payload: CreateSupplierBody = { organisation, contact, address, details };

    if (details.locale === UK_LOCALE && !details.dispatch_hours) {
      toast.error("Dispatch hours are required for UK locale.");
      return;
    }

    createSupplier(payload);
  };

  const error = supplierIdsError || contactIdsError || countryCodesError;

  return (
    <Page title={"Create Supplier"} hasBackButton error={error} isLoading={countryCodesLoading}>
      <Panel title={`Enter Supplier Information`} withWrapper={true}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            onSubmit={onSubmit}
            validationSchema={validationSchema}
          >
            {formikProps => {
              const { values, setFieldValue, handleSubmit, errors, touched } = formikProps;
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldNames.supplier_organisation_name}
                          type="text"
                          value={values.supplier_organisation_name}
                          placeholder="Input Supplier Name"
                          label="Supplier Name"
                          changeHandler={e => {
                            setFieldValue(fieldNames.supplier_organisation_name, e.target.value);
                          }}
                          error={errors.supplier_organisation_name}
                          touched={touched.supplier_organisation_name}
                          name={fieldNames.supplier_organisation_name}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          tooltipMessage="If the supplier is setup on Auth0, please enter their Auth0 ID here. <br/> Otherwise, please enter their name in lowercase letters."
                          htmlFor={fieldNames.external_organisation_id}
                          type="text"
                          value={values.external_organisation_id}
                          placeholder="Input External Supplier Id"
                          label="External Organisation ID"
                          changeHandler={e => {
                            setFieldValue(fieldNames.external_organisation_id, e.target.value);
                          }}
                          error={errors.external_organisation_id}
                          touched={touched.external_organisation_id}
                          name={fieldNames.external_organisation_id}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>

                    {!supplierCreated && (
                      <ButtonWrapper className={WMSButtonGroup({ type: "largeMargin" })}>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            getSupplierIdsByParams({
                              supplierName: values.supplier_organisation_name,
                              externalOrganisationId: values.external_organisation_id,
                            });
                          }}
                          appearance={"blueButton"}
                        >
                          <IconText text={"Continue"} primaryIcon={"alert-add-outline"} />
                        </SecondaryButton>
                      </ButtonWrapper>
                    )}
                  </PrimaryLineWrapper>

                  <PrimaryLineWrapper>
                    <Title>Supplier Contact Details</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={!supplierCreated}
                          htmlFor={fieldNames.contact_first_name}
                          type="text"
                          value={values.contact_first_name}
                          placeholder="Input First Name"
                          label="First Name"
                          changeHandler={e => {
                            setFieldValue(fieldNames.contact_first_name, e.target.value);
                          }}
                          error={errors.contact_first_name}
                          touched={touched.contact_first_name}
                          name={fieldNames.contact_first_name}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={!supplierCreated}
                          htmlFor={fieldNames.contact_last_name}
                          type="text"
                          value={values.contact_last_name}
                          placeholder="Input Last Name"
                          label="Last Name"
                          changeHandler={e => {
                            setFieldValue(fieldNames.contact_last_name, e.target.value);
                          }}
                          error={errors.contact_last_name}
                          touched={touched.contact_last_name}
                          name={fieldNames.contact_last_name}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={!supplierCreated}
                          htmlFor={fieldNames.contact_email_address}
                          type="text"
                          value={values.contact_email_address}
                          placeholder="Input Email Address"
                          label="Contact Email Address"
                          changeHandler={e => {
                            setFieldValue(fieldNames.contact_email_address, e.target.value);
                          }}
                          error={errors.contact_email_address}
                          touched={touched.contact_email_address}
                          name={fieldNames.contact_email_address}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isDisabled={!supplierCreated}
                          tooltipMessage="Please enter the phone number without area code."
                          htmlFor={fieldNames.contact_phone_number}
                          type="text"
                          value={values.contact_phone_number}
                          placeholder="Input Phone Number"
                          label="Contact Phone Number"
                          changeHandler={e => {
                            setFieldValue(fieldNames.contact_phone_number, e.target.value);
                          }}
                          error={errors.contact_phone_number}
                          touched={touched.contact_phone_number}
                          name={fieldNames.contact_phone_number}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>

                    {supplierCreated && !contactCreated && (
                      <ButtonWrapper className={WMSButtonGroup({ type: "largeMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"blueButton"}
                          onClick={() =>
                            getContactIdsByEmail({
                              contactEmailAddress: values.contact_email_address,
                            })
                          }
                        >
                          <IconText text={"Continue"} primaryIcon={"alert-add-outline"} />
                        </SecondaryButton>
                      </ButtonWrapper>
                    )}
                  </PrimaryLineWrapper>

                  <PrimaryLineWrapper>
                    <Title>Supplier Details</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleSelect
                          isRequired
                          isDisabled={!contactCreated}
                          htmlFor={fieldNames.locale}
                          value={values.locale}
                          options={localeOptions}
                          placeholder="Select Locale"
                          label="Locale"
                          changeHandler={e => setFieldValue(fieldNames.locale, e)}
                          error={errors.locale}
                          touched={touched.locale}
                          name={fieldNames.locale}
                        />
                      </DataPoint>

                      <SecondaryLineWrapper>
                        <Title>Address</Title>
                        <SecondaryLineDataWrapper>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isRequired
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.address_line_1}
                              type="text"
                              value={values.address_line_1}
                              placeholder="Input Address Line"
                              label="Address Line 1"
                              changeHandler={e => {
                                setFieldValue(fieldNames.address_line_1, e.target.value);
                              }}
                              error={errors.address_line_1}
                              touched={touched.address_line_1}
                              name={fieldNames.address_line_1}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.address_line_2}
                              type="text"
                              value={values.address_line_2}
                              placeholder="Input Address Line"
                              label="Address Line 2"
                              changeHandler={e => {
                                setFieldValue(fieldNames.address_line_2, e.target.value);
                              }}
                              error={errors.address_line_2}
                              touched={touched.address_line_2}
                              name={fieldNames.address_line_2}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.address_line_3}
                              type="text"
                              value={values.address_line_3}
                              placeholder="Input Address Line"
                              label="Address Line 3"
                              changeHandler={e => {
                                setFieldValue(fieldNames.address_line_3, e.target.value);
                              }}
                              error={errors.address_line_3}
                              touched={touched.address_line_3}
                              name={fieldNames.address_line_3}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isRequired
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.city}
                              type="text"
                              value={values.city}
                              placeholder="Input City"
                              label="City"
                              changeHandler={e => setFieldValue(fieldNames.city, e.target.value)}
                              error={errors.city}
                              touched={touched.city}
                              name={fieldNames.city}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleSelect
                              isRequired
                              isDisabled={!contactCreated}
                              tooltipMessage="If the country code does not match the locale, tax will likely be 0%."
                              htmlFor={fieldNames.country_code}
                              value={values.country_code}
                              options={countryCodes}
                              placeholder="Input Country Code"
                              label="Country Code"
                              changeHandler={e => setFieldValue(fieldNames.country_code, e)}
                              error={errors.country_code}
                              touched={touched.country_code}
                              name={fieldNames.country_code}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isRequired
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.postcode}
                              type="text"
                              value={values.postcode}
                              placeholder="Input postcode"
                              label="Postcode"
                              changeHandler={e =>
                                setFieldValue(fieldNames.postcode, e.target.value)
                              }
                              error={errors.postcode}
                              touched={touched.postcode}
                              name={fieldNames.postcode}
                            />
                          </DataPoint>
                        </SecondaryLineDataWrapper>
                      </SecondaryLineWrapper>

                      <SecondaryLineWrapper>
                        <Title>Other</Title>
                        <SecondaryLineDataWrapper>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isRequired
                              tooltipMessage="Usually an abbreviation of the supplier name, e.g Windmill = WM"
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.order_number_prefix}
                              type="text"
                              value={values.order_number_prefix}
                              placeholder="Input Order Number Prefix"
                              label="Order Number Prefix"
                              changeHandler={e => {
                                setFieldValue(fieldNames.order_number_prefix, e.target.value);
                              }}
                              error={errors.order_number_prefix}
                              touched={touched.order_number_prefix}
                              name={fieldNames.order_number_prefix}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.delivery_date_delta}
                              type="number"
                              value={values.delivery_date_delta}
                              placeholder="Input Delivery Date Adjustment (days)"
                              label="Delivery Date Adjustment (days)"
                              changeHandler={e =>
                                setFieldValue(fieldNames.delivery_date_delta, e.target.value)
                              }
                              error={errors.delivery_date_delta}
                              touched={touched.delivery_date_delta}
                              name={fieldNames.delivery_date_delta}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleSelect
                              isRequired
                              tooltipMessage="Currently only taxable invoice is available."
                              isDisabled
                              htmlFor={fieldNames.invoice_type}
                              value={values.invoice_type}
                              options={[]}
                              placeholder="Input Invoice Type"
                              label="Invoice Type"
                              changeHandler={e => setFieldValue(fieldNames.invoice_type, e)}
                              error={undefined}
                              touched={undefined}
                              name={fieldNames.invoice_type}
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleSelect
                              isRequired
                              tooltipMessage="Usually CN is to Sourceful warehouse, while UK is direct to client. If unsure, select both."
                              isDisabled={!contactCreated}
                              htmlFor={fieldNames.delivery_type}
                              value={values.delivery_type}
                              options={deliveryType}
                              placeholder="Input Delivery Type"
                              label="Delivery Type"
                              changeHandler={e => setFieldValue(fieldNames.delivery_type, e)}
                              error={errors.delivery_type}
                              touched={touched.delivery_type}
                              name={fieldNames.delivery_type}
                            />
                          </DataPoint>
                          <DataPoint halfWidth style={{ marginBlock: "20px" }}>
                            <CheckBox
                              isRequired
                              isDisabled={!contactCreated}
                              label="Store Supplier Product References"
                              value={fieldNames.uses_product_references}
                              id={fieldNames.uses_product_references}
                              isChecked={values.uses_product_references ?? false}
                              handleChange={e =>
                                setFieldValue(fieldNames.uses_product_references, e.target.checked)
                              }
                            />
                          </DataPoint>
                          <DataPoint halfWidth style={{ marginBlock: "20px" }}>
                            <CheckBox
                              isRequired
                              label="Send Client Name in PO"
                              isDisabled={!contactCreated}
                              value={fieldNames.send_client_name_config}
                              id={fieldNames.send_client_name_config}
                              isChecked={values.send_client_name_config ?? false}
                              handleChange={e =>
                                setFieldValue(fieldNames.send_client_name_config, e.target.checked)
                              }
                            />
                          </DataPoint>
                          <DataPoint halfWidth>
                            <SimpleInput
                              isDisabled={!contactCreated}
                              tooltipMessage="Suppliers will receive a reminder email for all accepted orders that are due to be dispatched in the next chosen number of hours."
                              label="Dispatch Hours"
                              htmlFor={fieldNames.dispatch_hours}
                              type="text"
                              value={values.dispatch_hours}
                              placeholder="Input dispatch hours"
                              changeHandler={e => {
                                setFieldValue(fieldNames.dispatch_hours, e.target.value);
                              }}
                              error={errors.dispatch_hours}
                              touched={touched.dispatch_hours}
                              name={fieldNames.dispatch_hours}
                            />
                          </DataPoint>
                        </SecondaryLineDataWrapper>
                      </SecondaryLineWrapper>
                      <SecondaryLineWrapper>
                        <Title>QC Files</Title>
                        <SecondaryLineDataWrapper>
                          <DataPoint fullWidth>
                            {values.qa_attachment_files.map((attachment, index_attachment) => {
                              return (
                                <AttachmentUploader
                                  key={`attachment_${index_attachment}`}
                                  indexAttachment={index_attachment}
                                  parameterName={`qa_attachment_files`}
                                  setFieldValue={setFieldValue}
                                  handleRemoveAttachmentLine={(index_attachment: number) => {
                                    const filteredAttachments = values.qa_attachment_files.filter(
                                      (item, i) => i !== index_attachment
                                    );
                                    setFieldValue(`qa_attachment_files`, filteredAttachments);
                                  }}
                                />
                              );
                            })}

                            <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                              <SecondaryButton
                                disabled={!contactCreated}
                                type="button"
                                onClick={() => {
                                  const attachments: UploadedFile[] = [
                                    ...values.qa_attachment_files,
                                  ];
                                  attachments.push({ ...emptyAttachment });
                                  setFieldValue(`qa_attachment_files`, attachments);
                                }}
                                appearance={"whiteButtonBlueText"}
                              >
                                <IconText
                                  text={"Upload New Attachment"}
                                  primaryIcon={"alert-add-outline"}
                                />
                              </SecondaryButton>
                            </ButtonWrapper>
                          </DataPoint>
                        </SecondaryLineDataWrapper>
                      </SecondaryLineWrapper>
                    </PrimaryLineDataWrapper>

                    {contactCreated && (
                      <VisibleFor roles={SOURCING_EDIT_ROLES}>
                        <ButtonWrapper className={WMSButtonGroup({ type: "largeMargin" })}>
                          <SecondaryButton
                            type="button"
                            appearance={"blueButton"}
                            onClick={() => handleSubmit()}
                          >
                            <IconText text={"Submit"} primaryIcon={"alert-add-outline"} />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </VisibleFor>
                    )}
                  </PrimaryLineWrapper>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default CreateSupplier;
