import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, Heading, IconText, Modal } from "@sourceful/shared-components";

import Panel from "../../../../components/panel/panel";
import { StoreTypes } from "../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../shared/components/atoms/buttons/SecondaryButton";
import ToolTip from "../../../../shared/components/atoms/labels/ToolTip";
import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  Label,
  PanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
} from "../../../../styles/SharedStyles";
import { isFeatureFlagEnabled } from "../../../../util/featureFlags";
import { ORDER_FLOW_TYPE } from "../../../shared/constants";
import { createOrderApi } from "../api/createOrder";
import {
  AuxiliaryItem,
  ClientAddress,
  ClientContact,
  ClientOrderContact,
  DiscountItem,
  InsertContact,
  InsertLineItem,
  InsertOrderAndContact,
  InsertOrderWithoutContact,
  ProductItem,
  ProductVariant,
  ShippingItem,
} from "../api/createOrder/types";
import { getUserAddressDetailApi } from "../api/getAddressDetail";
import { getUserDetailApi } from "../api/getUserDetail";
import BillingDetails from "../components/BillingDetails";
import ClientPromisedDeliveryDate from "../components/ClientPromisedDeliveryDate";
import { FinanceDetails } from "../components/FinanceDetails";
import PaymentPlan from "../components/PaymentPlan";
import ShippingDetails from "../components/ShippingDetails";
import UpdateCarbonOffsetCost from "../components/UpdateCarbonOffsetCost";
import UpdateDiscountCostLine from "../components/UpdateDiscountCostLine";
import UpdateFinal from "../components/UpdateFinal";
import UpdateProductCostLine from "../components/UpdateProductCostLine";
import UpdateProductOneOffCostLine from "../components/UpdateProductOneOffCostLine";
import UpdateShippingCostLine from "../components/UpdateShippingCostLine";
import {
  countryCodeOptions,
  currencyCodeOptions,
  eurTaxOptions,
  fieldObjects,
  initialFieldValues,
  initialFieldValuesCarbonOffsetCost,
  initialFieldValuesDiscountCost,
  initialFieldValuesProductCost,
  initialFieldValuesProductOneOffCost,
  initialFieldValuesShippingCost,
} from "../formValues";
import { getCnVatRate } from "../helpers";
import {
  CarbonOffsetCost,
  CreateOrderForm,
  DiscountCost,
  ProductCost,
  ProductOneOffCost,
  SPLIT_PAYMENT_PLAN_TYPE,
  ShippingCost,
} from "../types";
import { cnOrderInvalidTooltipMessage } from "../types/values";
import { filterCNYCurrencyOptions } from "../util/FilterCNOptions";
import { validationSchema } from "../validation";
import AddressSelector from "./AddressSelector";

interface Props {}

function CreateOrder(props: Props) {
  const isOrderUrlModalRef = createRef<HTMLButtonElement>();
  const confirmOrderModalRef = createRef<HTMLButtonElement>();

  const [isOrderUrlModalOpen, setisOrderUrlModalOpen] = useState(false);
  const [isConfirmOrderOpen, setConfirmOrderOpen] = useState(false);
  const [creatingOrder, setCreatingOrder] = useState(false);
  const [OrderUrl, setOrderUrl] = useState("");
  const [initialValues, setInitialValues] = useState<CreateOrderForm>(initialFieldValues);
  const [values, setValues] = useState<CreateOrderForm>(initialFieldValues);
  const [organisation, setOrganisation] = useState<any>();
  const [user, setUser] = useState<any>();
  const [cnOrderValid, setCnOrderValid] = useState<boolean>(false);
  const [addresses, setAddresses] = useState<ClientOrderContact[]>([]);
  const [showAddressSelector, setShowAddressSelector] = useState(false);
  const [isContactSelected, setIsContactSelected] = useState(false);
  const [isShippingSelected, setIsShippingSelected] = useState(false);
  const [isBillingSelected, setIsBillingSelected] = useState(false);

  useEffect(() => {
    if (isContactSelected && isBillingSelected && isShippingSelected) {
      setShowAddressSelector(false);
    }
  }, [isContactSelected, isBillingSelected, isShippingSelected]);

  /* Note: This is not a proper "Formik" validation function because it doesn't return an "errors" object. 
  "cnOrderValid" is a state that is set based on the values of the form. */
  const validateCnOrder = (values: CreateOrderForm) => {
    if (values.currency_code.label === "CNY") {
      if (values.sourceful_entity_cn.client_cn_entity.label === "NO") {
        if (values.sourceful_entity_cn.client_3rd_party_cn_entity.label === "YES") {
          if (values.sourceful_entity_cn.client_3rd_party_cn_entity_agreement.label === "NO") {
            setCnOrderValid(false);
            return;
          } else {
            setCnOrderValid(true);
            return;
          }
        }
        if (values.shipping_address.country_name.label !== "CN") {
          setCnOrderValid(false);
          return;
        }
      }
    }
    setCnOrderValid(true);
  };

  const handleQRModalClose = () => {
    setisOrderUrlModalOpen(false);
    setOrderUrl("");
  };

  const handleConfirmOrderClose = () => {
    setConfirmOrderOpen(false);
  };

  const handleConfirmOrderOpen = (isValid: boolean, values: CreateOrderForm) => {
    setValues(values);
    setInitialValues(values);
    if (!isValid) {
      toast.error("Please fix all validations before creating order");
      return;
    }
    setConfirmOrderOpen(true);
  };

  const isLoading = creatingOrder;
  const error = false;
  const panelTitle = "Create Order";

  const onSubmit = async () => {};

  const copyBillingIntoShipping = (setFieldValue: any, values: CreateOrderForm) => {
    setFieldValue(
      fieldObjects.shipping_address.first_name.fieldName,
      values.billing_address.first_name
    );
    setFieldValue(
      fieldObjects.shipping_address.second_name.fieldName,
      values.billing_address.second_name
    );
    setFieldValue(
      fieldObjects.shipping_address.address_line_1.fieldName,
      values.billing_address.address_line_1
    );
    setFieldValue(
      fieldObjects.shipping_address.address_line_2.fieldName,
      values.billing_address.address_line_2
    );
    setFieldValue(
      fieldObjects.shipping_address.address_line_3.fieldName,
      values.billing_address.address_line_3
    );
    setFieldValue(fieldObjects.shipping_address.city.fieldName, values.billing_address.city);
    setFieldValue(
      fieldObjects.shipping_address.region_or_county_or_state.fieldName,
      values.billing_address.region_or_county_or_state
    );
    setFieldValue(
      fieldObjects.shipping_address.postcode.fieldName,
      values.billing_address.postcode
    );

    setFieldValue(
      fieldObjects.shipping_address.company_name.fieldName,
      values.billing_address.company_name
    );

    setFieldValue(
      fieldObjects.shipping_address.country_name.fieldName,
      values.billing_address.country_name
    );
  };

  const handleFetchUserDetails = async (setFieldValue: any, values: any) => {
    const client_email_address = values.client_email_address;

    if (values.client_email_address) {
      try {
        const res = await getUserDetailApi(client_email_address);
        if (res.data.data.length === 0) {
          toast.error("Unable to fetch user details with email " + client_email_address);
          return;
        } else {
          const organisation = res.data.data[0].organisations_users[0].organisation;
          const client_user = res.data.data[0];
          setOrganisation(organisation);
          setUser(client_user);
          setFieldValue(fieldObjects.contact.user_uuid.fieldName, client_user.user_id);
          setFieldValue(fieldObjects.contact.user_uuid_internal.fieldName, client_user.uuid);
          // TODO: Old Org Id, need to swithc new iam id
          setFieldValue(fieldObjects.contact.org_id.fieldName, organisation.org_id);
          setFieldValue(fieldObjects.contact.org_name.fieldName, organisation.name);
          toast.success(`Fetched user details for ${client_email_address} successfully`);
        }
      } catch (error) {
        toast.error("Error fetching user details: " + error);
      }
    } else {
      toast.error("Please enter client email address");
    }
  };

  const handleFetchUserPreviousAddressDetails = async () => {
    if (organisation && user) {
      try {
        // TODO: Old Org Id, need to swithc new iam id
        const res = await getUserAddressDetailApi(user.user_id, organisation.org_id);

        const data: ClientOrderContact[] = res.data;

        if (data.length === 0) {
          toast.error("There are no previous addresses for this client");
        } else {
          setAddresses(data);
          setIsContactSelected(false);
          setIsShippingSelected(false);
          setIsBillingSelected(false);
          setShowAddressSelector(true);
          toast.success("Client details fetched successfully");
        }
      } catch (error) {
        toast.error("Error fetching client address details: " + error);
      }
    } else {
      toast.error("Please fetch client identity details first");
    }
  };

  const handleContactSelect = (setFieldValue: Function, orderContact: ClientContact) => {
    if (isContactSelected) {
      setIsContactSelected(false);
      setFieldValue(fieldObjects.contact.secondary_email.fieldName, "");
      setFieldValue(fieldObjects.contact.phone_number.fieldName, "");
      setFieldValue(fieldObjects.contact.tax_number.fieldName, "");
    } else {
      setIsContactSelected(true);
      setFieldValue(
        fieldObjects.contact.secondary_email.fieldName,
        orderContact.secondary_email ?? ""
      );
      setFieldValue(fieldObjects.contact.phone_number.fieldName, orderContact.phone_number);
      setFieldValue(fieldObjects.contact.tax_number.fieldName, orderContact.tax_number ?? "");
    }

    console.log("Contact selected");
  };

  const handleShippingSelect = (
    setFieldValue: Function,
    orderContact: ClientAddress,
    delivery_notes: string
  ) => {
    if (isShippingSelected) {
      setIsShippingSelected(false);
      setFieldValue(fieldObjects.shipping_address.company_name.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.first_name.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.second_name.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.address_line_1.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.address_line_2.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.address_line_3.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.city.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.region_or_county_or_state.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.postcode.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.country_name.fieldName, "");
      setFieldValue(fieldObjects.shipping_address.custom_delivery_instruction.fieldName, "");
    } else {
      setIsShippingSelected(true);
      setFieldValue(
        fieldObjects.shipping_address.company_name.fieldName,
        orderContact.company_name
      );

      setFieldValue(fieldObjects.shipping_address.first_name.fieldName, orderContact.first_name);
      setFieldValue(fieldObjects.shipping_address.second_name.fieldName, orderContact.last_name);
      setFieldValue(
        fieldObjects.shipping_address.address_line_1.fieldName,
        orderContact.address_line_1
      );
      setFieldValue(
        fieldObjects.shipping_address.address_line_2.fieldName,
        orderContact.address_line_2 ?? ""
      );
      setFieldValue(
        fieldObjects.shipping_address.address_line_3.fieldName,
        orderContact.address_line_3 ?? ""
      );
      setFieldValue(fieldObjects.shipping_address.city.fieldName, orderContact.city);
      setFieldValue(
        fieldObjects.shipping_address.region_or_county_or_state.fieldName,
        orderContact.region
      );
      setFieldValue(fieldObjects.shipping_address.postcode.fieldName, orderContact.postcode);

      setFieldValue(fieldObjects.shipping_address.country_name.fieldName, "");
      countryCodeOptions.forEach((countryCodeOption: any) => {
        if (countryCodeOption.label === orderContact.country_code) {
          setFieldValue(fieldObjects.shipping_address.country_name.fieldName, countryCodeOption);
        }
      });

      setFieldValue(
        fieldObjects.shipping_address.custom_delivery_instruction.fieldName,
        delivery_notes
      );
    }
  };

  const handleBillingSelect = (setFieldValue: Function, orderContact: ClientAddress) => {
    if (isBillingSelected) {
      setIsBillingSelected(false);
      setFieldValue(fieldObjects.billing_address.company_name.fieldName, "");
      setFieldValue(fieldObjects.billing_address.first_name.fieldName, "");
      setFieldValue(fieldObjects.billing_address.second_name.fieldName, "");
      setFieldValue(fieldObjects.billing_address.address_line_1.fieldName, "");
      setFieldValue(fieldObjects.billing_address.address_line_2.fieldName, "");
      setFieldValue(fieldObjects.billing_address.address_line_3.fieldName, "");
      setFieldValue(fieldObjects.billing_address.city.fieldName, "");
      setFieldValue(fieldObjects.billing_address.region_or_county_or_state.fieldName, "");
      setFieldValue(fieldObjects.billing_address.postcode.fieldName, "");
      setFieldValue(fieldObjects.billing_address.country_name.fieldName, "");
    } else {
      setIsBillingSelected(true);
      setFieldValue(fieldObjects.billing_address.company_name.fieldName, orderContact.company_name);

      setFieldValue(fieldObjects.billing_address.first_name.fieldName, orderContact.first_name);
      setFieldValue(fieldObjects.billing_address.second_name.fieldName, orderContact.last_name);
      setFieldValue(
        fieldObjects.billing_address.address_line_1.fieldName,
        orderContact.address_line_1
      );
      setFieldValue(
        fieldObjects.billing_address.address_line_2.fieldName,
        orderContact.address_line_2 ?? ""
      );
      setFieldValue(
        fieldObjects.billing_address.address_line_3.fieldName,
        orderContact.address_line_3 ?? ""
      );
      setFieldValue(fieldObjects.billing_address.city.fieldName, orderContact.city);
      setFieldValue(
        fieldObjects.billing_address.region_or_county_or_state.fieldName,
        orderContact.region
      );
      setFieldValue(fieldObjects.billing_address.postcode.fieldName, orderContact.postcode);

      setFieldValue(fieldObjects.billing_address.country_name.fieldName, "");
      countryCodeOptions.forEach((countryCodeOption: any) => {
        if (countryCodeOption.label === orderContact.country_code) {
          setFieldValue(fieldObjects.billing_address.country_name.fieldName, countryCodeOption);
        }
      });
    }
  };

  const handleCreateOrder = async (values: CreateOrderForm) => {
    const insertContact: InsertContact = {
      first_name: values.billing_address.first_name,
      last_name: values.billing_address.second_name,
      company_name: values.billing_address.company_name,
      org_id: values.contact.org_id,
      org_name: values.contact.org_name,
      tax_number: values.contact.tax_number,
      main_email: values.client_email_address,
      secondary_email: values.contact.secondary_email,
      phone_number: values.contact.phone_number,
      user_uuid: values.contact.user_uuid,
      user_uuid_internal: values.contact.user_uuid_internal,
      addresses: [
        {
          address_type: "BILLING",
          first_name: values.billing_address.first_name,
          last_name: values.billing_address.second_name,
          company_name: values.billing_address.company_name,
          address_line_1: values.billing_address.address_line_1,
          address_line_2: values.billing_address.address_line_2,
          address_line_3: values.billing_address.address_line_3,
          city: values.billing_address.city,
          region: values.billing_address.region_or_county_or_state,
          postcode: values.billing_address.postcode,
          country_code: values.billing_address.country_name.label,
        },
        {
          address_type: "SHIPPING",
          first_name: values.shipping_address.first_name,
          last_name: values.shipping_address.second_name,
          company_name: values.shipping_address.company_name,
          address_line_1: values.shipping_address.address_line_1,
          address_line_2: values.shipping_address.address_line_2,
          address_line_3: values.shipping_address.address_line_3,
          city: values.shipping_address.city,
          region: values.shipping_address.region_or_county_or_state,
          postcode: values.shipping_address.postcode,
          country_code: values.shipping_address.country_name.label,
          address_description: values.shipping_address.custom_delivery_instruction,
        },
      ],
    };

    const order_line_items: InsertLineItem[] = [];

    values.product_costs.forEach((productCost: ProductCost, index: number) => {
      const product_variant: ProductVariant = {
        artwork_bundle: productCost.artworks,
        base_product_id: productCost.base_product_id,
        base_product_version: productCost.base_product_version,
        base_product_name: productCost.base_product_name,
        product_attributes: productCost.content_attributes,
      };
      const product_line: ProductItem = {
        lead_time_min_days: productCost.min_days,
        lead_time_max_days: productCost.max_days,
        best_quote_id: "",
        product_sku_uuid: productCost.sku_id,
        product_variant: product_variant,
      };

      const order_line_item: InsertLineItem = {
        line_reference: productCost.cost_description,
        line_type_id: 1,
        units: "Quantity",
        price_per_unit: productCost.unit_cost * 100,
        quantity: productCost.qty,
        shipping_line_item: null,
        discount_line_item: null,
        auxiliary_line_item: null,
        product_line_item: product_line,
      };
      order_line_items.push(order_line_item);
    });

    values.product_one_off_costs.forEach((productCost: ProductOneOffCost, index: number) => {
      const auxiliary_line: AuxiliaryItem = {
        auxiliary_type: "Product One Off",
        data: {
          sku_uuid: productCost.sku_id,
        },
      };
      const order_line_item: InsertLineItem = {
        line_reference: productCost.cost_description,
        line_type_id: 3,
        units: "Quantity",
        price_per_unit: productCost.unit_cost * 100,
        quantity: productCost.qty,
        shipping_line_item: null,
        discount_line_item: null,
        auxiliary_line_item: auxiliary_line,
        product_line_item: null,
      };
      order_line_items.push(order_line_item);
    });

    values.shipping_costs.forEach((shippingCost: ShippingCost, index: number) => {
      const shipping_line: ShippingItem = {
        shipping_type: shippingCost.shipping_option.label,
        description: shippingCost.cost_description,
        amount: shippingCost.amount,
        vat: shippingCost.vat.label === "YES",
        destination: {},
        shipping_product_allocations: [],
        shipping_lead_time_min_days: shippingCost.min_days,
        shipping_lead_time_max_days: shippingCost.max_days,
      };
      const order_line_item = {
        line_reference: shippingCost.cost_description,
        line_type_id: 2,
        units: "Quantity",
        price_per_unit: shippingCost.amount * 100,
        quantity: 1,
        shipping_line_item: shipping_line,
        discount_line_item: null,
        auxiliary_line_item: null,
        product_line_item: null,
      };
      order_line_items.push(order_line_item);
    });

    values.carbon_offset_costs.forEach((carbonOffsetCost: CarbonOffsetCost, index: number) => {
      const auxiliary_line: AuxiliaryItem = {
        auxiliary_type: "Carbon Offset",
        data: {},
      };
      const order_line_item = {
        line_reference: "Carbon Offset",
        line_type_id: 3,
        units: "Quantity",
        price_per_unit: carbonOffsetCost.price_per_kg * 100,
        quantity: carbonOffsetCost.kg,
        shipping_line_item: null,
        discount_line_item: null,
        auxiliary_line_item: auxiliary_line,
        product_line_item: null,
      };
      order_line_items.push(order_line_item);
    });

    values.discount_costs.forEach((discountCost: DiscountCost, index: number) => {
      const discount_line: DiscountItem = {
        discount_type: discountCost.discount_option.label,
        amount: discountCost.amount * 100,
        //TODO
        vat: discountCost.vat.label === "YES",
        method: "FLAT",
      };
      const order_line_item = {
        line_reference: discountCost.cost_description,
        line_type_id: 4,
        units: "Quantity",
        discount_type: discountCost.discount_option.label,
        price_per_unit: discountCost.amount * 100,
        quantity: 1,
        vat: "",
        shipping_line_item: null,
        discount_line_item: discount_line,
        auxiliary_line_item: null,
        product_line_item: null,
      };
      order_line_items.push(order_line_item);
    });

    let vat_exemption_type = "NORMAL";
    if (values.currency_code.label === "GBP") {
      if (values.sourceful_entity_uk.vat_exempt.label === "NO") {
        if (values.sourceful_entity_uk.outside_uk.label === "NO") {
          vat_exemption_type = "NORMAL";
        } else {
          vat_exemption_type = "OUTSIDE_UK";
        }
      } else {
        vat_exemption_type = "VAT_EXEMPTED";
      }
    }

    if (values.currency_code.label === "EUR") {
      // TODO: FIX STATE MANAGMENTS
      if (values.billing_address.country_name.label !== "NL") {
        vat_exemption_type = eurTaxOptions[1].label;
      }
      if (values.billing_address.country_name.label === "NL") {
        vat_exemption_type = eurTaxOptions[0].label;
      }
    }

    if (values.currency_code.label === "USD") {
      vat_exemption_type = "USZERO";
    }

    if (values.currency_code.label === "CNY") {
      const cnVatRate = getCnVatRate(values);
      if (cnVatRate) {
        if (cnVatRate === 1) {
          vat_exemption_type = "CN_EXEMPT";
        } else {
          vat_exemption_type = "CN_NORMAL";
        }
      }
    }
    let order: InsertOrderWithoutContact = {
      subtotal: values.final_total.subtotal * 100,
      vat: values.final_total.vat * 100,
      total: values.final_total.total * 100,
      delivery_notes: values.shipping_address.custom_delivery_instruction,
      order_status_type_id: 1,
      currency_code: values.currency_code.label,
      basket_reference: "",
      vat_exemption_type: vat_exemption_type,
      order_line_items: order_line_items,
      client_reference: values.client_reference,
      external_opportunity_id:
        values.external_opportunity_id === "" ? null : values.external_opportunity_id,
      order_flow_type: ORDER_FLOW_TYPE.PCC_WITH_BANK_TRANSFER,
      order_source_type_id: 4,
      due_date_manual: null,
      payment_terms: "",
      client_promised_delivery_from_date:
        isFeatureFlagEnabled("REACT_APP_FEATURE_FLAG_CPDD") &&
        values.client_promised_delivery_date.from_date
          ? moment(values.client_promised_delivery_date.from_date).format()
          : undefined,
      client_promised_delivery_to_date:
        isFeatureFlagEnabled("REACT_APP_FEATURE_FLAG_CPDD") &&
        values.client_promised_delivery_date.to_date
          ? moment(values.client_promised_delivery_date.to_date).format()
          : undefined,
    };

    if (values.payment_term.has_payment_term.label === "YES") {
      const due_date = new Date(values.payment_term.due_date);
      order.due_date_manual = due_date.toISOString();
      order.payment_terms = values.payment_term.payment_term_description;
      if (values.payment_term.payment_plan_type.value === SPLIT_PAYMENT_PLAN_TYPE) {
        order.order_flow_type = ORDER_FLOW_TYPE.PCC_WITH_BLOCKED_PAYMENT_PLAN;
      } else if (values.payment_term.suppress_client_email.label === "YES") {
        order.order_flow_type = ORDER_FLOW_TYPE.PCC_WITH_DELAYED_PAYMENT_PLAN;
      } else {
        order.order_flow_type = ORDER_FLOW_TYPE.PCC_WITH_PAYMENT_PLAN;
      }
    }

    const order_service_payload: InsertOrderAndContact = {
      contact: insertContact,
      order: order,
    };

    if (
      values.currency_code.label === "USD" &&
      values.sourceful_entity_us.vat_exempt.label === "NO"
    ) {
      if (values.sourceful_entity_us.threshold + values.final_total.total > 100000) {
        toast.warn(
          `US threshold amount of ${values.sourceful_entity_us.threshold} + ${values.final_total.total} is more than 100000. Please speak to Finance before processing the order.`
        );
      }
    }

    setConfirmOrderOpen(false);
    setCreatingOrder(true);
    try {
      let response = await createOrderApi(order_service_payload);
      if (response.status === 200) {
        toast.success("Order created successfully with order id " + response.data.order_uuid);

        const self_serve_base_url =
          process.env.REACT_APP_SELF_SERVE_BASE_URL || window.REACT_APP_SELF_SERVE_BASE_URL;
        const order_url = `${self_serve_base_url}/admin/orders/${response.data.order_uuid}`;

        setOrderUrl(order_url);
        setisOrderUrlModalOpen(true);
      } else {
        toast.error("Error creating order " + response.data);
      }
    } catch (error) {
      toast.error("Error creating order " + error);
    }
    setCreatingOrder(false);
  };

  const filteredCurrencyCodeOptions = filterCNYCurrencyOptions(currencyCodeOptions);

  return (
    <Page title={"Order Builder"} isLoading={isLoading} error={error} hasBackButton={false}>
      <Panel withWrapper={true} title={panelTitle}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize={false}
            onSubmit={onSubmit}
            validationSchema={validationSchema}
            validate={validateCnOrder}
          >
            {formikProps => {
              const { values, setFieldValue, handleSubmit, errors, touched, isValid } = formikProps;

              const cnVatRate =
                values.currency_code.label === "CNY" ? getCnVatRate(values) : undefined;

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleSelect
                          isRequired
                          htmlFor={fieldObjects.currency_code.fieldName}
                          name={fieldObjects.currency_code.fieldName}
                          placeholder={fieldObjects.currency_code.fieldPlaceholder}
                          options={
                            isFeatureFlagEnabled("REACT_APP_FEATURE_FLAG_CN_ORDERS")
                              ? currencyCodeOptions
                              : filteredCurrencyCodeOptions
                          }
                          value={values.currency_code}
                          error={errors.currency_code?.label}
                          touched={touched.currency_code?.label}
                          label={fieldObjects.currency_code.fieldLabel}
                          changeHandler={e =>
                            setFieldValue(fieldObjects.currency_code.fieldName, e)
                          }
                          tooltipMessage={
                            "The currency choice drives the Sourceful Entity this order will be linked to."
                          }
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <SimpleInput
                          isRequired
                          tooltipMessage="Client Email Address, the email they used to sign up with us."
                          htmlFor={fieldObjects.clientEmailAddress.fieldName}
                          type="text"
                          value={values.client_email_address}
                          placeholder={fieldObjects.clientEmailAddress.fieldPlaceholder}
                          label={fieldObjects.clientEmailAddress.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(
                              fieldObjects.clientEmailAddress.fieldName,
                              e.target.value
                            );
                          }}
                          error={errors.client_email_address}
                          touched={touched.client_email_address}
                          name={fieldObjects.clientEmailAddress.fieldName}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                    <ButtonWrapper style={{ marginTop: "10px" }}>
                      <SecondaryButton
                        onClick={async () => await handleFetchUserDetails(setFieldValue, values)}
                        appearance={"blueButton"}
                        isWithinTable
                        type="button"
                      >
                        Fetch Client Identity Details
                      </SecondaryButton>
                    </ButtonWrapper>
                    <ButtonWrapper style={{ marginTop: "10px" }}>
                      <SecondaryButton
                        onClick={async () => await handleFetchUserPreviousAddressDetails()}
                        appearance={"blueButton"}
                        isWithinTable
                        type="button"
                      >
                        Fetch Client Previous Address Details
                      </SecondaryButton>
                    </ButtonWrapper>
                    {showAddressSelector && (
                      <AddressSelector
                        addresses={addresses}
                        setFieldValue={setFieldValue}
                        onSelectContact={handleContactSelect}
                        onSelectShipping={handleShippingSelect}
                        onSelectBilling={handleBillingSelect}
                      />
                    )}
                  </PrimaryLineWrapper>
                  <PrimaryLineWrapper>
                    <Title>Contact Information - Main Details</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint fullWidth>
                        <SimpleInput
                          htmlFor={fieldObjects.contact.secondary_email.fieldName}
                          name={fieldObjects.contact.secondary_email.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.secondary_email.fieldPlaceholder}
                          value={values.contact.secondary_email}
                          error={errors.contact?.secondary_email}
                          touched={touched.contact?.secondary_email}
                          label={fieldObjects.contact.secondary_email.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(
                              fieldObjects.contact.secondary_email.fieldName,
                              e.target.value
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint fullWidth>
                        <SimpleInput
                          isRequired
                          htmlFor={fieldObjects.contact.phone_number.fieldName}
                          name={fieldObjects.contact.phone_number.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.phone_number.fieldPlaceholder}
                          value={values.contact.phone_number}
                          error={errors.contact?.phone_number}
                          touched={touched.contact?.phone_number}
                          label={fieldObjects.contact.phone_number.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(
                              fieldObjects.contact.phone_number.fieldName,
                              e.target.value
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint fullWidth>
                        <SimpleInput
                          htmlFor={fieldObjects.contact.tax_number.fieldName}
                          name={fieldObjects.contact.tax_number.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.tax_number.fieldPlaceholder}
                          value={values.contact.tax_number}
                          error={errors.contact?.tax_number}
                          touched={touched.contact?.tax_number}
                          label={fieldObjects.contact.tax_number.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(
                              fieldObjects.contact.tax_number.fieldName,
                              e.target.value
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint fullWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={true}
                          htmlFor={fieldObjects.contact.user_uuid.fieldName}
                          name={fieldObjects.contact.user_uuid.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.user_uuid.fieldPlaceholder}
                          value={values.contact.user_uuid}
                          error={errors.contact?.user_uuid}
                          touched={touched.contact?.user_uuid}
                          label={fieldObjects.contact.user_uuid.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.contact.user_uuid.fieldName, e.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint fullWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={true}
                          htmlFor={fieldObjects.contact.user_uuid_internal.fieldName}
                          name={fieldObjects.contact.user_uuid_internal.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.user_uuid_internal.fieldPlaceholder}
                          value={values.contact.user_uuid_internal}
                          error={errors.contact?.user_uuid_internal}
                          touched={touched.contact?.user_uuid_internal}
                          label={fieldObjects.contact.user_uuid_internal.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(
                              fieldObjects.contact.user_uuid_internal.fieldName,
                              e.target.value
                            );
                          }}
                        />
                      </DataPoint>
                      <DataPoint fullWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={true}
                          htmlFor={fieldObjects.contact.org_id.fieldName}
                          name={fieldObjects.contact.org_id.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.org_id.fieldPlaceholder}
                          value={values.contact.org_id}
                          error={errors.contact?.org_id}
                          touched={touched.contact?.org_id}
                          label={fieldObjects.contact.org_id.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.contact.org_id.fieldName, e.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint fullWidth>
                        <SimpleInput
                          isRequired
                          isDisabled={true}
                          htmlFor={fieldObjects.contact.org_name.fieldName}
                          name={fieldObjects.contact.org_name.fieldName}
                          type="text"
                          placeholder={fieldObjects.contact.org_name.fieldPlaceholder}
                          value={values.contact.org_name}
                          error={errors.contact?.org_name}
                          touched={touched.contact?.org_name}
                          label={fieldObjects.contact.org_name.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.contact.org_name.fieldName, e.target.value);
                          }}
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  {BillingDetails({
                    values: values.billing_address,
                    setFieldValue: setFieldValue,
                    errors: errors.billing_address,
                    touched: touched.billing_address,
                  })}
                  {ShippingDetails({
                    values: values.shipping_address,
                    setFieldValue: setFieldValue,
                    errors: errors.shipping_address,
                    touched: touched.shipping_address,
                    copyBillingIntoShipping: () => copyBillingIntoShipping(setFieldValue, values),
                  })}
                  <FinanceDetails
                    values={values}
                    setFieldValue={setFieldValue}
                    errors={errors}
                    touched={touched}
                  />

                  {values.product_costs.length === 0 && (
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          setFieldValue("product_costs", [
                            ...values.product_costs,
                            { ...initialFieldValuesProductCost },
                          ]);
                        }}
                        appearance={"whiteButtonBlueText"}
                      >
                        <IconText text={"Insert Product Cost"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {values.product_costs.length > 0 && (
                    <PrimaryLineWrapper>
                      <Title>Products Cost</Title>
                      {values.product_costs.map((productCostLine, index) => (
                        <UpdateProductCostLine
                          key={productCostLine.sku_id}
                          index={index}
                          values={productCostLine}
                          errors={errors.product_costs as FormikErrors<ProductCost[]>}
                          touched={touched.product_costs}
                          handleRemoveQuoteLine={(index: number) => {
                            const filteredProductCosts = values.product_costs.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("product_costs", filteredProductCosts);
                          }}
                          setFieldValue={setFieldValue}
                          currency={values.currency_code.label}
                          formValues={values}
                          cnVatRate={cnVatRate}
                        />
                      ))}
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setFieldValue("product_costs", [
                              ...values.product_costs,
                              { ...initialFieldValuesProductCost },
                            ]);
                          }}
                        >
                          <IconText
                            text={"Add new product cost line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {values.product_one_off_costs.length === 0 && (
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          setFieldValue("product_one_off_costs", [
                            ...values.product_one_off_costs,
                            { ...initialFieldValuesProductOneOffCost },
                          ]);
                        }}
                        appearance={"whiteButtonBlueText"}
                      >
                        <IconText
                          text={"Insert Product One Off Cost"}
                          primaryIcon={"alert-add-outline"}
                        />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {values.product_one_off_costs.length > 0 && (
                    <PrimaryLineWrapper>
                      <Title>Products One Off Cost</Title>
                      {values.product_one_off_costs.map((productOneOffCostLine, index) => (
                        <UpdateProductOneOffCostLine
                          key={index}
                          index={index}
                          values={productOneOffCostLine}
                          errors={errors.product_one_off_costs as FormikErrors<ProductOneOffCost[]>}
                          touched={touched.product_one_off_costs}
                          handleRemoveQuoteLine={(index: number) => {
                            const filteredProductOneOffCosts = values.product_one_off_costs.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("product_one_off_costs", filteredProductOneOffCosts);
                          }}
                          setFieldValue={setFieldValue}
                          currency={values.currency_code.label}
                          formValues={values}
                        />
                      ))}
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setFieldValue("product_one_off_costs", [
                              ...values.product_one_off_costs,
                              { ...initialFieldValuesProductOneOffCost },
                            ]);
                          }}
                        >
                          <IconText
                            text={"Add new product one off cost line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {values.shipping_costs.length === 0 && (
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          setFieldValue("shipping_costs", [
                            ...values.shipping_costs,
                            { ...initialFieldValuesShippingCost },
                          ]);
                        }}
                        appearance={"whiteButtonBlueText"}
                      >
                        <IconText text={"Insert Shipping Cost"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {values.shipping_costs.length > 0 && (
                    <PrimaryLineWrapper>
                      <Title>Shipping Cost</Title>
                      {values.shipping_costs.map((shippingCostLine, index) => (
                        <UpdateShippingCostLine
                          key={index}
                          index={index}
                          values={shippingCostLine}
                          errors={errors.shipping_costs as FormikErrors<ShippingCost[]>}
                          touched={touched.shipping_costs}
                          handleRemoveQuoteLine={(index: number) => {
                            const filteredShippingCosts = values.shipping_costs.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("shipping_costs", filteredShippingCosts);
                          }}
                          setFieldValue={setFieldValue}
                          currency={values.currency_code.label}
                          formValues={values}
                        />
                      ))}
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setFieldValue("shipping_costs", [
                              ...values.shipping_costs,
                              { ...initialFieldValuesShippingCost },
                            ]);
                          }}
                        >
                          <IconText
                            text={"Add new shipping cost line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {values.carbon_offset_costs.length === 0 && (
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          setFieldValue("carbon_offset_costs", [
                            ...values.carbon_offset_costs,
                            { ...initialFieldValuesCarbonOffsetCost },
                          ]);
                        }}
                        appearance={"whiteButtonBlueText"}
                      >
                        <IconText
                          text={"Insert Carbon Offset Cost"}
                          primaryIcon={"alert-add-outline"}
                        />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {values.carbon_offset_costs.length > 0 && (
                    <PrimaryLineWrapper>
                      <Title>Carbon Offset - No VAT on this</Title>
                      {values.carbon_offset_costs.map((carbonCostLine, index) => (
                        <UpdateCarbonOffsetCost
                          key={index}
                          index={index}
                          values={carbonCostLine}
                          errors={errors.carbon_offset_costs as FormikErrors<CarbonOffsetCost[]>}
                          touched={touched.carbon_offset_costs}
                          handleRemoveQuoteLine={(index: number) => {
                            const filteredCarbonCosts = values.carbon_offset_costs.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("carbon_offset_costs", filteredCarbonCosts);
                          }}
                          setFieldValue={setFieldValue}
                          currency={values.currency_code.label}
                          formValues={values}
                        />
                      ))}
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setFieldValue("carbon_offset_costs", [
                              ...values.carbon_offset_costs,
                              { ...initialFieldValuesCarbonOffsetCost },
                            ]);
                          }}
                        >
                          <IconText
                            text={"Add new carbon offset cost line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {values.discount_costs.length === 0 && (
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          setFieldValue("discount_costs", [
                            ...values.discount_costs,
                            { ...initialFieldValuesDiscountCost },
                          ]);
                        }}
                        appearance={"whiteButtonBlueText"}
                      >
                        <IconText text={"Insert Discount Cost"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {values.discount_costs.length > 0 && (
                    <PrimaryLineWrapper>
                      <Title>Discount Cost</Title>
                      {values.discount_costs.map((discountCostLine, index) => (
                        <UpdateDiscountCostLine
                          key={index}
                          index={index}
                          values={discountCostLine}
                          errors={errors.discount_costs as FormikErrors<DiscountCost[]>}
                          touched={touched.discount_costs}
                          handleRemoveQuoteLine={(index: number) => {
                            const filteredDiscountCosts = values.discount_costs.filter(
                              (_, i) => i !== index
                            );
                            setFieldValue("discount_costs", filteredDiscountCosts);
                          }}
                          setFieldValue={setFieldValue}
                          currency={values.currency_code.label}
                          formValues={values}
                        />
                      ))}
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setFieldValue("discount_costs", [
                              ...values.discount_costs,
                              { ...initialFieldValuesDiscountCost },
                            ]);
                          }}
                        >
                          <IconText
                            text={"Add new discount cost line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>
                  )}

                  <PrimaryLineWrapper>
                    <Title>Final Total</Title>
                    <PrimaryLineDataWrapper>
                      <UpdateFinal
                        values={values.final_total}
                        setFieldValue={setFieldValue}
                        errors={errors.final_total}
                        touched={touched.final_total}
                        currency={values.currency_code.label}
                      />
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  {isFeatureFlagEnabled("REACT_APP_FEATURE_FLAG_CPDD") && (
                    <PrimaryLineWrapper>
                      <Title>Client Expected Delivery Date</Title>
                      <ClientPromisedDeliveryDate
                        values={values.client_promised_delivery_date}
                        setFieldValue={setFieldValue}
                        errors={errors.client_promised_delivery_date}
                        touched={touched.client_promised_delivery_date}
                      />
                    </PrimaryLineWrapper>
                  )}

                  <PrimaryLineWrapper>
                    <Title>Payment Plans</Title>
                    <PaymentPlan
                      values={values.payment_term}
                      setFieldValue={setFieldValue}
                      errors={errors.payment_term}
                      touched={touched.payment_term}
                      currency={values.currency_code.label}
                    />
                  </PrimaryLineWrapper>
                  <PrimaryLineWrapper>
                    <Title>Client Reference</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint halfWidth>
                        <SimpleInput
                          htmlFor={fieldObjects.clientReference.fieldName}
                          name={fieldObjects.clientReference.fieldName}
                          type="text"
                          placeholder={fieldObjects.clientReference.fieldPlaceholder}
                          value={values.client_reference}
                          error={errors?.client_reference}
                          touched={touched?.client_reference}
                          label={fieldObjects.clientReference.fieldLabel}
                          changeHandler={e => {
                            setFieldValue(fieldObjects.clientReference.fieldName, e.target.value);
                          }}
                        />
                      </DataPoint>
                      <DataPoint halfWidth>
                        <SimpleInput
                          tooltipMessage="If you'd like to add a SalesForce Opportunity ID, please do so here. <br /> This will provide updates in SalesForce automatically."
                          htmlFor={fieldObjects.externalOpportunityId.fieldName}
                          name={fieldObjects.externalOpportunityId.fieldName}
                          type="text"
                          placeholder={fieldObjects.externalOpportunityId.fieldPlaceholder}
                          value={values.external_opportunity_id}
                          error={errors?.external_opportunity_id}
                          touched={touched?.external_opportunity_id}
                          label={fieldObjects.externalOpportunityId.fieldLabel}
                          changeHandler={e =>
                            setFieldValue(
                              fieldObjects.externalOpportunityId.fieldName,
                              e.target.value
                            )
                          }
                        />
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  <ButtonWrapper style={{ marginTop: "10px" }}>
                    <div style={{ display: "flex", flexDirection: "column" }}>
                      {!cnOrderValid && (
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Label style={{ color: "red" }}>CN Order is not valid</Label>
                          <ToolTip message={cnOrderInvalidTooltipMessage} />
                        </div>
                      )}

                      <SecondaryButton
                        onClick={() => handleConfirmOrderOpen(isValid, values)}
                        appearance={"blueButton"}
                        disabled={!cnOrderValid}
                        isWithinTable
                      >
                        Create Order
                      </SecondaryButton>
                    </div>
                  </ButtonWrapper>
                </StyledForm>
              );
            }}
          </Formik>
          <Modal
            id={"OrdeConfirmModal"}
            isOpen={isConfirmOrderOpen}
            handleClose={handleConfirmOrderClose}
            triggerRef={confirmOrderModalRef}
          >
            <PanelWrapper>
              <InnerPanelWrapper>
                <Heading level={3} fontSize={5} css={{ margin: "0 10px 10px" }}>
                  Are you sure you want to create this order?
                </Heading>
                <ButtonGroup
                  className={WMSButtonGroup({ type: "largeMargin" })}
                  css={{ marginTop: "auto" }}
                >
                  <SecondaryButton appearance="whiteButton" onClick={handleConfirmOrderClose}>
                    Cancel
                  </SecondaryButton>
                  <SecondaryButton
                    appearance="blueButton"
                    type="submit"
                    onClick={async () => await handleCreateOrder(values)}
                  >
                    Confirm
                  </SecondaryButton>
                </ButtonGroup>
              </InnerPanelWrapper>
            </PanelWrapper>
          </Modal>

          <Modal
            id={"OrderUrlModal"}
            isOpen={isOrderUrlModalOpen}
            handleClose={handleQRModalClose}
            triggerRef={isOrderUrlModalRef}
          >
            <PanelWrapper>
              <InnerPanelWrapper>
                <Heading level={3} fontSize={5} css={{ margin: "0 10px 10px" }}>
                  Created Order Details
                </Heading>
                <a href={OrderUrl}>Order URL: {OrderUrl}</a>
              </InnerPanelWrapper>
            </PanelWrapper>
          </Modal>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {};
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOrder);
