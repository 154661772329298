import { Formik } from "formik";
import { useState } from "react";

import { ButtonGroup } from "@sourceful/shared-components";

import {
  InTransitUpdateInterface,
  InTransitUpdateProps,
  inTransitFieldNames,
  initialInTransitFieldValues,
} from ".";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import Label from "../../../../../shared/components/atoms/labels/Label";
import SimpleDateInput from "../../../../../shared/components/forms/SimpleDateInput";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../../shared/components/forms/SimpleTextArea";
import Page from "../../../../../shared/components/templates/Page";
import { DataPoint, StyledForm, WMSButtonGroup } from "../../../../../styles/SharedStyles";
import { ShipmentContainer } from "../../../shipments/viewClientOrderShipments/components";
import { inTransitValidationSchema } from "../../../shipments/viewClientOrderShipments/validation";

const InTransitUpdateForm = ({
  handleResponse,
  formValues,
  addresses,
  reset,
}: InTransitUpdateProps) => {
  const [initialValues, setInitialValues] = useState<InTransitUpdateInterface>(formValues);

  const handleSubmitButton = async (inTransitValues: InTransitUpdateInterface) => {
    handleResponse(inTransitValues);
    setInitialValues(initialInTransitFieldValues);
  };

  return (
    <Page hasBackButton={false} title="In Transit Update" isLoading={false}>
      <ShipmentContainer css={{ marginTop: "15px" }}>
        <Formik
          initialValues={initialValues}
          enableReinitialize
          validationSchema={inTransitValidationSchema}
          onSubmit={values => handleSubmitButton(values)}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => (
            <StyledForm onSubmit={handleSubmit}>
              <DataPoint fullWidth withUniqueMargin>
                <SimpleSelect
                  isRequired
                  isDisabled
                  htmlFor={inTransitFieldNames.fromDestination}
                  name={inTransitFieldNames.fromDestination}
                  placeholder=""
                  options={[]}
                  value={values.fromDestination}
                  error={undefined}
                  touched={undefined}
                  label="From"
                  changeHandler={_e => {}}
                />
              </DataPoint>
              <DataPoint fullWidth withUniqueMargin>
                <SimpleSelect
                  isRequired
                  htmlFor={inTransitFieldNames.toDestination}
                  name={inTransitFieldNames.toDestination}
                  placeholder="Select destination of shipment"
                  options={addresses}
                  value={values.toDestination}
                  error={errors.toDestination}
                  touched={touched.toDestination}
                  label="To"
                  changeHandler={e => setFieldValue(inTransitFieldNames.toDestination, e)}
                />
              </DataPoint>
              <DataPoint fullWidth withUniqueMargin>
                <SimpleDateInput
                  isRequired
                  htmlFor={inTransitFieldNames.expectedDeliveryDate}
                  name={inTransitFieldNames.expectedDeliveryDate}
                  value={values.expectedDeliveryDate}
                  error={errors.expectedDeliveryDate}
                  touched={touched.expectedDeliveryDate}
                  label="Expected Delivery Date"
                  changeHandler={e =>
                    setFieldValue(inTransitFieldNames.expectedDeliveryDate, e.target.value)
                  }
                />
              </DataPoint>
              <DataPoint fullWidth withUniqueMargin>
                <SimpleInput
                  name="trackingLink"
                  error={errors.trackingLink}
                  touched={touched.trackingLink}
                  label="Tracking Link"
                  size="large"
                  placeholder="Add tracking link"
                  value={values.trackingLink}
                  changeHandler={e =>
                    setFieldValue(inTransitFieldNames.trackingLink, e.target.value)
                  }
                  type={"text"}
                />
              </DataPoint>
              <DataPoint fullWidth withUniqueMargin>
                <Label>Notes</Label>
                <SimpleTextArea
                  size="large"
                  placeholder="Enter any notes about this update"
                  value={values.notes}
                  handleChange={e => setFieldValue(inTransitFieldNames.notes, e.target.value)}
                  type={"text"}
                />
              </DataPoint>
              <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                <SecondaryButton appearance={"whiteButton"} type="button" onClick={() => reset()}>
                  Cancel
                </SecondaryButton>
                <SecondaryButton appearance={"blueButton"} type="submit">
                  Submit
                </SecondaryButton>
              </ButtonGroup>
            </StyledForm>
          )}
        </Formik>
      </ShipmentContainer>
    </Page>
  );
};

export default InTransitUpdateForm;
