import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { useAddressesWithData } from "../graphql/hooks/useAddressesWithData";
import { AddressItem } from "../graphql/types";
import { joinAddressLines } from "../mappers";

export default function ViewAddresses() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { addresses, addressesLoading, addressesError } = useAddressesWithData();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/warehouse-management/add-address");
          },
          type: "button",
          title: "Add address",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.1,
        accessor: (listItem: AddressItem) => listItem.id,
      },
      {
        Header: "Adress Name",
        widthPercent: 0.5,
        accessor: (listItem: AddressItem) => listItem.address_name,
      },
      {
        Header: "Phone Number",
        widthPercent: 0.1,
        accessor: (listItem: AddressItem) => listItem.phone_number,
      },
      {
        Header: "Full Address",
        widthPercent: 0.4,
        accessor: (listItem: AddressItem) => joinAddressLines(listItem),
      },
      {
        Header: "Postal Code",
        widthPercent: 0.2,
        accessor: (listItem: AddressItem) => listItem.postcode,
      },
      {
        Header: "City",
        widthPercent: 0.2,
        accessor: (listItem: AddressItem) => listItem.city,
      },
      {
        Header: "Country",
        widthPercent: 0.4,
        accessor: (listItem: AddressItem) => listItem.country.country_name,
      },
    ],
    []
  );

  return (
    <Page error={addressesError} isLoading={addressesLoading} title={"Address Book"}>
      <Panel
        withWrapper
        title={"Address List"}
        text={"Use the table below to explore Sourceful's Address Book"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={addresses} columns={columns} />
      </Panel>
    </Page>
  );
}
