import { Formik, FormikErrors } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Yup from "yup";

import { Heading, PrimaryButton, SecondaryButton } from "@sourceful/shared-components";

import { BasicPanel } from "../../../components/panel/panel";
import { ButtonRightContainer } from "../../../shared/components/atoms/containers/ButtonRightContainer";
import SimpleInput from "../../../shared/components/forms/SimpleInput";
import { DeletePill } from "../../../shared/components/molecules/DeletePill/DeletePill";
import { DisplayBox } from "../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  InnerPanelWrapper,
  InputContainer,
  PrimaryLineDataWrapper,
} from "../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../util/api/apiHook";
import { AttributeTemplate } from "../viewAttributesTypes/api/types";
import { createAttributeTemplateOptions } from "./api/createAttributeTemplateOptions";
import { getAttributeTemplateOptions } from "./api/getAttributeTemplateOptions";
import { AttributeTemplateOption } from "./api/types";

interface AttributeTemplateOptionsFormProps {
  template?: AttributeTemplate;
  handleGoBack: () => void;
}

export const attributeTemplateOptionsSchema = Yup.object({});

export interface AttributeTemplateOptionsInterface {
  templates: {
    name: string;
    options: {
      [key: string]: string | number;
    };
  }[];
}

export const attributeTemplateFieldNames = {
  name: "name",
};

const AttributeTemplateOptionsForm = ({
  template,
  handleGoBack,
}: AttributeTemplateOptionsFormProps) => {
  const [getTemplateOptionsState, getTemplateOptions] = useAPI(getAttributeTemplateOptions, true);

  const [attributeTemplateOptions, setAttributeTemplateOptions] = useState<
    Array<AttributeTemplateOption>
  >([]);

  useEffect(() => {
    fetchAndSetAttributeTemplateOptions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!template) return null;

  const fetchAndSetAttributeTemplateOptions = async () => {
    const templateOptions = await getTemplateOptions({
      templateId: template.id,
    });
    if (Array.isArray(templateOptions)) {
      setAttributeTemplateOptions(templateOptions[0]);
    }
  };

  const handleSubmit = async (values: AttributeTemplateOptionsInterface) => {
    if (template) {
      const formattedValues = values.templates.map(currentTemplate => {
        return {
          attribute_template_id: template.id,
          option_value: currentTemplate.options,
          option_name: currentTemplate.name,
          rfq: false,
        };
      });

      try {
        await createAttributeTemplateOptions(formattedValues);
        toast.success("Success! Attribute Type options created.");
        fetchAndSetAttributeTemplateOptions();
      } catch (error) {
        console.error(error);
        toast.error("Error occurred while creating attribute type options.");
      }
    }
  };

  const getYupType = (type: string, name: string) => {
    switch (type) {
      case "string":
        return Yup.string().required(`${name} is required`);
      case "numerical":
        return Yup.number().required(`${name} is required`);
      default:
        console.warn("Unexpected field type");
        return Yup.string().required(`${name} is required`);
    }
  };

  const requiredFieldSchema = Object.entries(JSON.parse(template.required_fields)).map(
    ([key, value]) => ({
      [key]: getYupType(value as string, key),
    })
  );
  const requiredFieldObject: any = requiredFieldSchema.reduce((acc, curr) => {
    return { ...acc, ...curr };
  });
  const requiredOptions = Object.keys(requiredFieldObject);
  const attributeTemplateOptionsSchema = Yup.object({
    templates: Yup.array().of(
      Yup.object({
        name: Yup.string().required("Name is required"),
        options: Yup.object({
          ...requiredFieldObject,
        }),
      })
    ),
  });

  const emptyValuesArray = requiredOptions.map(option => ({
    [option]: "",
  }));
  const emptyValuesObject = emptyValuesArray.reduce((acc, curr) => {
    return { ...acc, ...curr };
  });

  const attributeTemplateOptionsMapped = attributeTemplateOptions.map(option => ({
    name: option.option_name,
    options: option.option_value,
  }));

  const initialAttributeTemplateOptionsValues: AttributeTemplateOptionsInterface = {
    templates:
      attributeTemplateOptionsMapped.length > 0
        ? attributeTemplateOptionsMapped
        : [
            {
              name: "",
              options: {
                ...emptyValuesObject,
              },
            },
          ],
  };

  const isLoading = getTemplateOptionsState === API_REQUEST_STATE.PENDING;
  if (isLoading || !attributeTemplateOptions) return <p>Loading options...</p>;

  return (
    <>
      <SecondaryButton onClick={handleGoBack}>Back</SecondaryButton>
      <Heading level={6} css={{ margin: "20px 0" }}>
        {template.attribute_template_name}
      </Heading>
      <Formik
        enableReinitialize
        validationSchema={attributeTemplateOptionsSchema}
        initialValues={initialAttributeTemplateOptionsValues}
        onSubmit={handleSubmit}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched }) => {
          const handleAddTemplate = () => {
            const templates = [...values.templates];
            templates.push({
              name: "",
              options: {
                ...emptyValuesObject,
              },
            });
            setFieldValue("templates", templates);
          };

          const handleRemoveTemplate = (index: number) => {
            const templates = [...values.templates];
            templates.splice(index, 1);
            setFieldValue("templates", templates);
          };

          return (
            <>
              {values.templates.map((template, i) => (
                <BasicPanel css={{ marginTop: "20px" }}>
                  {values.templates.length > 1 && (
                    <DeletePill onClick={() => handleRemoveTemplate(i)} />
                  )}
                  <InnerPanelWrapper key={i}>
                    <InputContainer>
                      <SimpleInput
                        type="text"
                        placeholder="Option Name"
                        label="Option Name"
                        value={template.name || ""}
                        name={`name-${i}`}
                        changeHandler={(e: any) => {
                          setFieldValue(`templates[${i}].name`, e.currentTarget.value);
                        }}
                        error={
                          errors.templates && errors.templates[i]
                            ? (
                                errors.templates[i] as FormikErrors<{
                                  name: string;
                                }>
                              ).name
                            : undefined
                        }
                        touched={
                          touched.templates && touched.templates[i]
                            ? touched.templates[i].name
                            : undefined
                        }
                      />
                    </InputContainer>
                    {requiredOptions.map((option, ii) => {
                      const optionError =
                        errors.templates &&
                        errors.templates[i] &&
                        (
                          errors.templates[i] as FormikErrors<{
                            options: {
                              [key: string]: string | number;
                            };
                          }>
                        ).options
                          ? (
                              errors.templates[i] as FormikErrors<{
                                options: {
                                  [key: string]: string | number;
                                };
                              }>
                            ).options![option]
                          : undefined;
                      const optionTouched =
                        touched.templates && touched.templates[i] && touched.templates[i].options
                          ? touched.templates[i].options![option]
                          : undefined;

                      return (
                        <PrimaryLineDataWrapper css={{ marginTop: "15px" }}>
                          <DisplayBox label="Name" value={option} withUniqueMargin />
                          <DataPoint halfWidth withUniqueMargin>
                            <SimpleInput
                              type="text"
                              placeholder="Value"
                              name={`option-${i}-${ii}`}
                              label={option}
                              value={template.options[option] || ""}
                              changeHandler={(e: any) => {
                                setFieldValue(
                                  `templates[${i}].options[${option}]`,
                                  e.currentTarget.value
                                );
                              }}
                              error={optionError}
                              touched={optionTouched}
                            />
                          </DataPoint>
                        </PrimaryLineDataWrapper>
                      );
                    })}
                    {i === values.templates.length - 1 && (
                      <ButtonRightContainer>
                        <SecondaryButton onClick={handleAddTemplate}>
                          Add new template
                        </SecondaryButton>
                      </ButtonRightContainer>
                    )}
                  </InnerPanelWrapper>
                </BasicPanel>
              ))}
              <ButtonRightContainer>
                <PrimaryButton onClick={() => handleSubmit()}>Save templates</PrimaryButton>
              </ButtonRightContainer>
            </>
          );
        }}
      </Formik>
    </>
  );
};

export default AttributeTemplateOptionsForm;
