import { Icon, styled } from "@sourceful/shared-components";

import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";

interface ProductLineSimpleProps {
  productLabel: string;
  quantity: number;
  setFieldValue?: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  index?: number;
}

const FlexHalfWidth = styled("div", {
  width: "45%",
  flexFlow: "wrap",
  display: "flex",
});

const ProductLineSimple = ({
  productLabel,
  quantity,
  setFieldValue,
  index,
}: ProductLineSimpleProps) => {
  return (
    <SecondaryLineWrapper type="withIndex">
      <SecondaryLineDataWrapper css={{ marginTop: "0" }}>
        <DataPoint halfWidth>
          <DisplayBox fullWidth label="Product" value={`File Copy - ${productLabel}`} />
        </DataPoint>
        <FlexHalfWidth>
          <DataPoint halfWidth>
            <DisplayBox label="Total Quantity" value={`${quantity} units`} />
          </DataPoint>

          {setFieldValue && index !== undefined && (
            <DataPoint halfWidth containsButton>
              <IconWrapper
                type="button"
                css={{
                  color: "#8D8D8D",
                  "&:hover": { color: "#005AE1" },
                  alignSelf: "bottom",
                }}
                onClick={() => {
                  setFieldValue(`purchase_order_lines[${index}].fileCopyQty`, null);
                }}
              >
                <Icon width={30} height={30} name="navigation-trash" />
              </IconWrapper>
            </DataPoint>
          )}
        </FlexHalfWidth>
      </SecondaryLineDataWrapper>
    </SecondaryLineWrapper>
  );
};
export default ProductLineSimple;
