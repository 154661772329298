import { LocationNameItem, StockConditionNameItem } from "../graphql/types";
import { CHANGE_IBM_LINE_STATUS, CHANGE_IBM_STATUS } from "../redux/types";

export const mapManifestStatusToMessage = (status: string) => {
  switch (status) {
    case CHANGE_IBM_STATUS.CANCEL:
      return "canceled";
    case CHANGE_IBM_STATUS.REJECT:
      return "rejected";
    case CHANGE_IBM_STATUS.MARK_AS_DONE:
      return "done";
  }
};

export const mapManifestLineStatusToMessage = (status: string) => {
  switch (status) {
    case CHANGE_IBM_LINE_STATUS.CANCEL:
      return "canceled";
    case CHANGE_IBM_LINE_STATUS.REJECT:
      return "rejected";
    case CHANGE_IBM_LINE_STATUS.MARK_AS_DONE:
      return "done";
  }
};

export const mapStockConditionNamesToDropdownObject = (
  stockCoditionNames: StockConditionNameItem[]
) => {
  return stockCoditionNames.map(stockCoditionName => {
    return {
      label: stockCoditionName.stock_condition_name,
      value: stockCoditionName.id,
    };
  });
};

export const mapLocationNamesToDropdownObject = (locationNames: LocationNameItem[]) => {
  return locationNames.map(locationName => {
    return {
      label: locationName.location_name,
      value: locationName.id,
    };
  });
};
