import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreateQAInspectionBody } from "./types/index";

export const createQAInspectionAPI = async (body: CreateQAInspectionBody) => {
  try {
    return await SOURCING_API.post("/quality-assurance-inspections", body);
  } catch (err) {
    console.log("Error in create QA Inspection API call", err);
    throw err;
  }
};
