import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { fulfilStockCheckLineAPI } from "../../api/fulfilStockCheckLine/fulfilStockCheckLine";
import { FulfilStockCheckLineBody } from "../../api/fulfilStockCheckLine/types";
import {
  FULFIL_STOCK_CHECK_LINE_FAILURE,
  FULFIL_STOCK_CHECK_LINE_RESET,
  FULFIL_STOCK_CHECK_LINE_SUCCESS,
  FULFILlING_STOCK_CHECK_LINE,
} from "../types";

export const fulfillingStockCheck = () => {
  return {
    type: FULFILlING_STOCK_CHECK_LINE,
  };
};

export const fulfilStockCheckLineSuccess = () => {
  return {
    type: FULFIL_STOCK_CHECK_LINE_SUCCESS,
  };
};

export const fulfilStockCheckLineReset = () => {
  return {
    type: FULFIL_STOCK_CHECK_LINE_RESET,
  };
};

export const fulfilStockCheckLineFailure = () => {
  return {
    type: FULFIL_STOCK_CHECK_LINE_FAILURE,
  };
};

export const fulfilStockCheckLineAction = (body: FulfilStockCheckLineBody) => {
  return async (dispatch: Dispatch) => {
    toast("Fulfilling stock check Line", { hideProgressBar: true });
    dispatch(fulfillingStockCheck());
    try {
      await fulfilStockCheckLineAPI(body);
      toast.success("Stock check line fulfilled successfully.");
      dispatch(fulfilStockCheckLineSuccess());
    } catch (err) {
      toast.error("Something went wrong with fulfilling stock check line.");
      dispatch(fulfilStockCheckLineFailure());
    }
  };
};
