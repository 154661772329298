import { ApolloError } from "@apollo/client";

import { useGetBaseProductDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { BaseProductItem } from "../types";

export interface HookResult {
  baseProducts: BaseProductItem[];
  baseProductsError?: ApolloError;
  baseProductsLoading: boolean;
}

export const useBaseProductData = (): HookResult => {
  const { data, error, loading } = useGetBaseProductDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    baseProducts: data && Array.isArray(data.base_product) ? data.base_product : [],
    baseProductsError: error,
    baseProductsLoading: loading,
  };
};
