import { Formik } from "formik";

import { ButtonGroup, IconText, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor/VisibleFor";
import { STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES } from "../../../../../shared/constants";
import { mapStockManagementAddressesToDropdownItem } from "../../../../../shared/mappers";
import {
  DataPoint,
  FormattedLineDataWrapper,
  StyledForm,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { Address, Contact } from "../../shared/graphql/types";
import {
  UpdateContactDataInterface,
  initialUpdateContactValues,
  updateContactFieldNames,
} from "../../shared/types";
import { updateContactValidationSchema } from "../validation";

interface Props {
  contact: Contact;
  addresses: Address[] | null | undefined;
  handleSubmitUpdateContact: (values: UpdateContactDataInterface, contact: Contact) => void;
  setShowUpdateContactForm: (value: boolean) => void;
  addressName: string;
}

const UpdateStockManagementContact = ({
  contact,
  addresses,
  handleSubmitUpdateContact,
  setShowUpdateContactForm,
  addressName,
}: Props): JSX.Element => {
  return (
    <Formik
      initialValues={initialUpdateContactValues(contact, addressName)}
      enableReinitialize
      onSubmit={(values: UpdateContactDataInterface, { resetForm }) => {
        handleSubmitUpdateContact(values, contact);
        resetForm();
        setShowUpdateContactForm(false);
      }}
      validationSchema={updateContactValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <FormattedLineDataWrapper>
              <DataPoint halfWidth>
                <SimpleInput
                  htmlFor={updateContactFieldNames.firstName}
                  name={updateContactFieldNames.firstName}
                  size={"medium"}
                  error={errors.firstName}
                  touched={touched.firstName}
                  label={"First name"}
                  placeholder={""}
                  type={"text"}
                  value={values.firstName}
                  changeHandler={event => {
                    setFieldValue(updateContactFieldNames.firstName, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleInput
                  htmlFor={updateContactFieldNames.lastName}
                  name={updateContactFieldNames.lastName}
                  size={"medium"}
                  error={errors.lastName}
                  touched={touched.lastName}
                  label={"Last name"}
                  placeholder={""}
                  type={"text"}
                  value={values.lastName}
                  changeHandler={event => {
                    setFieldValue(updateContactFieldNames.lastName, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleInput
                  htmlFor={updateContactFieldNames.emailAddress}
                  name={updateContactFieldNames.emailAddress}
                  size={"medium"}
                  error={errors.emailAddress}
                  touched={touched.emailAddress}
                  label={"Email address"}
                  placeholder={""}
                  type={"text"}
                  value={values.emailAddress}
                  changeHandler={event => {
                    setFieldValue(updateContactFieldNames.emailAddress, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleInput
                  htmlFor={updateContactFieldNames.phoneNumber}
                  name={updateContactFieldNames.phoneNumber}
                  size={"medium"}
                  error={errors.phoneNumber}
                  touched={touched.phoneNumber}
                  label={"Phone number"}
                  placeholder={""}
                  type={"text"}
                  value={values.phoneNumber}
                  changeHandler={event => {
                    setFieldValue(updateContactFieldNames.phoneNumber, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleInput
                  htmlFor={updateContactFieldNames.jobTitle}
                  name={updateContactFieldNames.jobTitle}
                  size={"medium"}
                  error={errors.jobTitle}
                  touched={touched.jobTitle}
                  label={"Job title"}
                  placeholder={""}
                  type={"text"}
                  value={values.jobTitle}
                  changeHandler={event => {
                    setFieldValue(updateContactFieldNames.jobTitle, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleSelect
                  htmlFor={updateContactFieldNames.preferredCommunicationMethodId}
                  name={updateContactFieldNames.preferredCommunicationMethodId}
                  error={errors.preferredCommunicationMethodId}
                  touched={touched.preferredCommunicationMethodId}
                  placeholder="Select communication method"
                  label="Contact's preferred communication method"
                  options={STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES}
                  value={values.preferredCommunicationMethodId}
                  changeHandler={(event: any) => {
                    setFieldValue(updateContactFieldNames.preferredCommunicationMethodId, event);
                  }}
                />
              </DataPoint>

              <DataPoint halfWidth>
                <SimpleSelect
                  tooltipMessage={
                    !!contact.isPrimary
                      ? "To change the this contact's address, you need to update the address directly to have a different primary contact"
                      : ""
                  }
                  isDisabled={!!contact.isPrimary}
                  htmlFor={updateContactFieldNames.addressId}
                  name={updateContactFieldNames.addressId}
                  error={errors.addressId}
                  touched={touched.addressId}
                  placeholder="Select address"
                  label="Which address should this contact be associated with?"
                  options={mapStockManagementAddressesToDropdownItem(addresses)}
                  value={values.addressId}
                  changeHandler={(event: any) => {
                    setFieldValue(updateContactFieldNames.addressId, event);
                  }}
                />
              </DataPoint>
              <VisibleFor roles={SM_ADMIN_ROLES}>
                <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                  <SecondaryButton
                    onClick={() => setShowUpdateContactForm(false)}
                    appearance={"whiteButtonBlueText"}
                    isWithinTable
                    type="button"
                  >
                    <IconText text={"Cancel"} primaryIcon={"alert-cross-outline"} />
                  </SecondaryButton>
                  <SecondaryButton appearance={"blueButton"} isWithinTable type="submit">
                    <IconText text={"Update Contact"} primaryIcon={"toggle-checkmark-check"} />
                  </SecondaryButton>
                </ButtonGroup>
              </VisibleFor>
            </FormattedLineDataWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default UpdateStockManagementContact;
