import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { fulfilWorkOrderAPI } from "../../api/fulfilWorkOrder";
import { FulfilWOBody } from "../../api/types";
import {
  FULFIL_WORK_ORDER_FAILED,
  FULFIL_WORK_ORDER_LOADING,
  FULFIL_WORK_ORDER_RESET,
  FULFIL_WORK_ORDER_SUCCESS,
} from "../types";

export const fulfilingWorkOrder = () => {
  return {
    type: FULFIL_WORK_ORDER_LOADING,
  };
};

export const fulfilWorkOrderSuccess = () => {
  return {
    type: FULFIL_WORK_ORDER_SUCCESS,
  };
};

export const fulfilWorkOrderReset = () => {
  return {
    type: FULFIL_WORK_ORDER_RESET,
  };
};

export const fulfilWorkOrderFailed = () => {
  return {
    type: FULFIL_WORK_ORDER_FAILED,
  };
};

export const fulfilWorkOrderAction = (body: FulfilWOBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Work Order Fulfilment", { hideProgressBar: true });
    dispatch(fulfilingWorkOrder());
    try {
      await fulfilWorkOrderAPI(body);
      toast.success("Work Order Fulfilment created successfully.");
      dispatch(fulfilWorkOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating work order fulfilment.");
      dispatch(fulfilWorkOrderFailed());
    }
  };
};
