import { ChangeEvent } from "react";

import { DateInput, Label } from "../../../../styles/SharedStyles";
import ErrorMessage from "../../atoms/labels/ErrorMessage";
import { ToolTip } from "../../atoms/labels/ToolTip/ToolTip";

export interface Props {
  name: string;
  value: string | undefined | null;
  error: string | null | undefined;
  touched: boolean | undefined;
  label: string;
  changeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  htmlFor?: string;
  isGray?: boolean;
  isRequired?: boolean;
  tooltipMessage?: string;
  isDisabled?: boolean;
}

export const SimpleDateInput = ({
  htmlFor,
  isRequired,
  value,
  name,
  changeHandler,
  error,
  touched,
  isGray,
  label,
  tooltipMessage,
  isDisabled,
}: Props) => {
  return (
    <>
      {tooltipMessage ? (
        <ToolTip
          isGray={isGray}
          isRequired={isRequired}
          message={tooltipMessage}
          title={label}
          htmlFor={htmlFor}
        />
      ) : (
        <Label isGray={isGray} isRequired={isRequired} htmlFor={htmlFor}>
          {label}
        </Label>
      )}

      <DateInput
        css={{ width: "100%" }}
        disabled={isDisabled}
        id={htmlFor}
        name={name}
        type={"date"}
        value={value || ""}
        onChange={event => {
          changeHandler(event);
        }}
      />
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
    </>
  );
};

export default SimpleDateInput;
