import { Dispatch } from "redux";

import { getUsersByRoleAPI } from "../../api/getUserNamesByRole";
import { GET_USERS_FAILED, GET_USERS_LOADING, GET_USERS_RESET, GET_USERS_SUCCESS } from "../types";

export const getUsersLoading = () => {
  return {
    type: GET_USERS_LOADING,
  };
};

export const getUsersSuccess = () => {
  return {
    type: GET_USERS_SUCCESS,
  };
};

export const getUsersFailed = () => {
  return {
    type: GET_USERS_FAILED,
  };
};

export const getUsersReset = () => {
  return {
    type: GET_USERS_RESET,
  };
};

export const getUsersByRoleAction = (role: string) => {
  return async (dispatch: Dispatch) => {
    try {
      const users = await getUsersByRoleAPI(role);
      dispatch(getUsersSuccess());
      return users;
    } catch (error) {
      console.log(error, "Error Getting Users By Roles");
      dispatch(getUsersFailed());
      return [];
    }
  };
};
