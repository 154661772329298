import * as Yup from "yup";

export const globalValidationSchema = Yup.object({
  globalConfigDate: Yup.number()
    .min(0, "Number of days must be greater than or equal to 0")
    .defined("Please specify a number of days")
    .required(),
});

export const supplierValidationSchema = Yup.object({
  supplierConfigs: Yup.array(
    Yup.object({
      supplierConfigDay: Yup.number()
        .min(0, "Number of days must be greater than or equal to 0")
        .defined("Please specify a number of days")
        .required(),
      supplier: Yup.object({
        label: Yup.string().required(),
        value: Yup.number().required(),
      })
        .nullable()
        .defined()
        .required("Please select a supplier"),
    })
  ).min(1, "Please add at least one supplier"),
});
