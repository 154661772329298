import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { mapAddressToDropdownItem } from "../../../../shared/graphql/mappers/mapAddressToDropdownItem";
import { AddressItem } from "../../../../shared/graphql/types/AddressItem";
import { ShipmentUpdateItem } from "../../../../shared/graphql/types/ShipmentUpdateItem";
import { getLastShipmentUpdate } from "../../../../shared/helpers/getLastShipmentUpdate";
import { ADDRESS_TYPE, SHIPMENT_STATUS, SHIPMENT_UPDATE_TYPE } from "../../../../shared/models";
import { ShipmentItem } from "../types";

export const mapShipmentsToAddressType = (
  shipments: ShipmentItem[],
  type: ADDRESS_TYPE
): DropdownItem[] => {
  const statusId =
    type === ADDRESS_TYPE.CURRENT
      ? SHIPMENT_STATUS.IN_TRANSIT
      : SHIPMENT_STATUS.DELIVERY_INTERMEDIATE;

  const updateType =
    type === ADDRESS_TYPE.CURRENT
      ? SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE
      : SHIPMENT_UPDATE_TYPE.IN_TRANSIT;

  const relevantShipmentUpdates = shipments
    .map(shipment => {
      if (shipment.shipment_status_id === statusId) return undefined;
      return getLastShipmentUpdate(shipment.shipment_updates, updateType);
    })
    .filter((item): item is ShipmentUpdateItem => !!item);

  let addresses: (AddressItem | undefined)[] = [];

  switch (type) {
    case ADDRESS_TYPE.FROM:
      addresses = relevantShipmentUpdates.map(
        update => update.addressByFromDestinationId || undefined
      );
      break;
    case ADDRESS_TYPE.TO:
      addresses = relevantShipmentUpdates.map(
        update => update.addressByToDestinationId || undefined
      );
      break;
    case ADDRESS_TYPE.CURRENT:
      addresses = relevantShipmentUpdates.map(update => update.address || undefined);
      break;
    default:
      return [];
  }

  return mapAddressToDropdownItem(addresses.filter((item): item is AddressItem => !!item));
};
