import { SOURCING_API } from "../../../../../api/baseConfig";
import { UpdateGlobalDeliveryDateConfigBody } from "./types";

export const updateGlobalDeliveryDateConfigAPI = async (
  body: UpdateGlobalDeliveryDateConfigBody
) => {
  try {
    return await SOURCING_API.patch("/suppliers/config", body);
  } catch (err) {
    console.log("Error in update global delivery date config API call", err);
    throw err;
  }
};
