import { Link } from "react-router-dom";

function WMSSetupGuide() {
  return (
    <div>
      <h1>Setup Guide</h1>
      <div>
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/help`}
        >
          Back To Help
        </Link>
      </div>
      <p>
        {`
          1) Add an address for the new warehouse in the Warehouse section of the sidebar
      `}{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/warehouse-management/add-address`}
        >
          + Add Address
        </Link>
      </p>
      <p>
        {`2) Add a new warehouse using the Create Warehouse feature using the address in the dropdown that you created earlier
          `}{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/warehouse-management/add-warehouse`}
        >
          + Create Warehouse
        </Link>
      </p>
      <p>
        {`3) Add locations for your newly created warehouse in the Locations screen using the New Location feature
          `}{" "}
        <Link
          style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
          to={`/warehouse-management/add-location`}
        >
          + Add Location
        </Link>
      </p>
      <ul>
        <li>
          {`Parent: location type can house multiple other sub-locations (this may be thought of as a room) → this type CANNOT hold stock`}
        </li>
        <li>
          {`Pickface: location type that refers to a shelf (can have a parent & can hold stock)`}
        </li>
        <li>
          {`Bulk: location type that is generally pallet sized where stock is usually kept unsplit (can have a parent & can hold stock)`}
        </li>
        <li>{`Inbound: location type (can have a parent & can hold stock)`}</li>
        <li>{`Outbound: location type (can have a parent & can hold stock)`}</li>
      </ul>
      <p>
        {`4) (Optional) Add Products (Inventory → Products → Add Product) to the WMS so that you have a catalogue that Manifest creators can use when selecting products to put into and take out of the warehouses. In order to set up products, please ensure you know whether a product is a case of products or a standalone product.`}
      </p>{" "}
      <Link
        style={{ textDecorationLine: "underline", color: "blue", marginLeft: 10 }}
        to={`/inventory/products/add-product`}
      >
        + Add Product
      </Link>
    </div>
  );
}

export default WMSSetupGuide;
