import { ApolloError } from "@apollo/client";

import { useGetSupplierQuoteQuery } from "../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../shared/constants";
import { SupplierQuoteItem } from "../types";

export interface HookResult {
  supplierQuote?: SupplierQuoteItem;
  supplierQuoteError?: ApolloError;
  supplierQuoteLoading: boolean;
}

export const useSupplierQuote = (quoteId: number, quoteLineId: number): HookResult => {
  const { data, error, loading } = useGetSupplierQuoteQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
    variables: {
      quoteId: quoteId,
      quoteLineId: quoteLineId,
    },
  });

  return {
    supplierQuote: data && Array.isArray(data.supplier_quote) ? data.supplier_quote[0] : undefined,
    supplierQuoteError: error,
    supplierQuoteLoading: loading,
  };
};
