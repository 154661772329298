import { FormikErrors, FormikTouched } from "formik";
import { Dispatch, SetStateAction } from "react";

import { IconText } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  ButtonWrapper,
  PrimaryLineDataWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { WorkOrderAndRecipeInputs, WorkOrderForm, emptyWorkOrderInput } from "../formValues";
import WorkOrderInputLine from "./WorkOrderInputLine";

interface WorkOrderInputProps {
  line: WorkOrderAndRecipeInputs;
  index: number;
  values: WorkOrderForm;
  errors: FormikErrors<WorkOrderForm>;
  touched: FormikTouched<WorkOrderForm>;
  setFieldValue: (field: string, value: any) => void;
  setInitialValues: Dispatch<SetStateAction<WorkOrderForm>>;
}

const WorkOrderInput = ({
  line,
  index,
  values,
  errors,
  touched,
  setFieldValue,
  setInitialValues,
}: WorkOrderInputProps) => {
  return (
    <>
      <PrimaryLineDataWrapper>
        <DisplayBox withUniqueMargin label="Product Name" value={line.recipe_input.product.name} />
        <DisplayBox withUniqueMargin label="Quantity" value={line.recipe_input.quantity} />
      </PrimaryLineDataWrapper>

      {line.work_order_inputs.map((workOrderInput, workOrderLineIndex) => (
        <WorkOrderInputLine
          key={workOrderLineIndex}
          line={line}
          organisation_id={values.organisation?.value!}
          warehouse_id={values.warehouse?.value!}
          recipeInputIndex={index}
          workOrderLineIndex={workOrderLineIndex}
          errors={errors.work_order_and_recipe_inputs as FormikErrors<WorkOrderAndRecipeInputs[]>}
          touched={touched.work_order_and_recipe_inputs}
          values={workOrderInput}
          setFieldValue={setFieldValue}
          handleRemoveWorkOrderLine={(orderLineIndices: {
            recipeInputIndex: number;
            workOrderLineIndex: number;
          }) => {
            const fitleredWorkOrderLine = {
              ...values,
            }.work_order_and_recipe_inputs[
              orderLineIndices.recipeInputIndex
            ].work_order_inputs.filter((_, i) => i !== orderLineIndices.workOrderLineIndex);
            const newValues = { ...values };
            newValues.work_order_and_recipe_inputs[
              orderLineIndices.recipeInputIndex
            ].work_order_inputs = fitleredWorkOrderLine;
            setInitialValues(newValues);
          }}
        />
      ))}
      <ButtonWrapper>
        <SecondaryButton
          type="button"
          appearance="whiteButtonBlueText"
          className={WMSButtonGroup({ type: "smallMargin" })}
          onClick={() => {
            const specificWorkOrderLine = { ...values }.work_order_and_recipe_inputs[index]
              .work_order_inputs;

            specificWorkOrderLine.push(emptyWorkOrderInput);
            const newValues = { ...values };
            newValues.work_order_and_recipe_inputs[index].work_order_inputs = specificWorkOrderLine;
            setInitialValues(newValues);
          }}
        >
          <IconText text={"Add Input Line"} primaryIcon={"alert-add-outline"} />
        </SecondaryButton>
      </ButtonWrapper>
    </>
  );
};

export default WorkOrderInput;
