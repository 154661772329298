import { Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { CreateOrganisationBody } from "../api/types";
import { OrganisationForm, fieldNames, initialFieldValues } from "../formValues";
import { useAllOrganisationNames } from "../graphql/hooks/useAllOrganisationNames";
import {
  createOrganisationAction,
  createOrganisationReset,
} from "../redux/actions/createOrganisationAction";
import { CreateOrganisationReducer } from "../redux/reducers/createOrganisationReducer";
import { validationSchema } from "../validation";

interface Props {
  createOrganisationState: CreateOrganisationReducer;
  createOrganisation: (body: CreateOrganisationBody) => void;
  resetCreateOrganisation: () => void;
}

function AddOrganisation(props: Props) {
  const [initialValues] = useState<OrganisationForm>(initialFieldValues);

  const { createOrganisationState } = props;

  const onSubmit = async (values: OrganisationForm) => {
    if (organisationNames?.indexOf(values.organisation_name.trim()) !== -1) {
      toast.error("An organisation with this name already exists.");
      return;
    }

    const organisation: CreateOrganisationBody = {
      organisation_name: values.organisation_name,
    };

    props.createOrganisation(organisation);
  };

  useEffect(() => {
    if (createOrganisationState.success) {
      props.resetCreateOrganisation();
      refetchOrganisationNames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOrganisationState.success, createOrganisationState.error]);

  const {
    organisationNames,
    organisationNamesLoading,
    organisationNamesError,
    refetchOrganisationNames,
  } = useAllOrganisationNames();

  const error = organisationNamesError;

  return (
    <Page error={error} isLoading={organisationNamesLoading} title={"Organisations"}>
      <Panel
        withWrapper
        title={"Add Organisation"}
        subtitle={"Add an Organisation using the inputs below"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              handleReset,
              handleChange,
              handleSubmit,
              setFieldValue,
              errors,
              touched,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <SimpleInput
                      isRequired
                      htmlFor={fieldNames.organisation_name}
                      name={fieldNames.organisation_name}
                      type="text"
                      placeholder="Enter Organisation Name Here"
                      value={values.organisation_name}
                      error={errors.organisation_name}
                      touched={touched.organisation_name}
                      label="Organisation Name"
                      changeHandler={handleChange}
                    />
                  </DataPoint>
                </TopPanelInputsWrapper>

                <FormButtonPair handleReset={handleReset} />
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createOrganisationState: state.createOrganisationReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createOrganisation: (body: CreateOrganisationBody) => dispatch(createOrganisationAction(body)),
    resetCreateOrganisation: () => dispatch(createOrganisationReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddOrganisation);
