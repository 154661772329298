import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createStockCheckAPI } from "../../api/createStockCheck/createStockCheckAPI";
import { CreateStockCheckBody } from "../../api/createStockCheck/types";
import {
  CREATE_STOCK_CHECK_FAILURE,
  CREATE_STOCK_CHECK_RESET,
  CREATE_STOCK_CHECK_SUCCESS,
  CREATING_STOCK_CHECK,
} from "../types/";

export const creatingStockCheck = () => {
  return {
    type: CREATING_STOCK_CHECK,
  };
};

export const createStockCheckSuccess = () => {
  return {
    type: CREATE_STOCK_CHECK_SUCCESS,
  };
};

export const createStockCheckReset = () => {
  return {
    type: CREATE_STOCK_CHECK_RESET,
  };
};

export const createStockCheckFailure = () => {
  return {
    type: CREATE_STOCK_CHECK_FAILURE,
  };
};

export const createStockCheckAction = (body: CreateStockCheckBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating StockCheck", { hideProgressBar: true });
    console.log("Creating StockCheck - In Action");
    dispatch(creatingStockCheck());
    try {
      await createStockCheckAPI(body);
      toast.success("StockCheck created successfully.");
      dispatch(createStockCheckSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating StockCheck.");
      dispatch(createStockCheckFailure());
    }
  };
};
