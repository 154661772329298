export interface ProductForm {
  product_name: string;
  base_product: {
    value: number;
    label: string;
  } | null;
  base_product_quantity: string;
  case_product: {
    value: number;
    label: string;
  } | null;
  weight_grams: string;
  length_mm: string;
  width_mm: string;
  height_mm: string;
}

export const initialFieldValues: ProductForm = {
  base_product_quantity: "",
  product_name: "",
  base_product: null,
  case_product: null,
  weight_grams: "",
  length_mm: "",
  width_mm: "",
  height_mm: "",
};

export const fieldNames = {
  base_product: "base_product",
  product_name: "product_name",
  base_product_quantity: "base_product_quantity",
  case_product: "case_product",
  weight_grams: "weight_grams",
  length_mm: "length_mm",
  width_mm: "width_mm",
  height_mm: "height_mm",
};
