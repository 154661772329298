/* eslint-disable */
import { ApolloError } from "@apollo/client";
import { AxiosResponse } from "axios";
import { Formik } from "formik";
import { useState } from "react";

import { Icon, SecondaryButton, styled } from "@sourceful/shared-components";

import { Organisation } from "../../../../../generated/graphql_stock_management";
import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { mapStockManagementOrganisationsToDropdownItem } from "../../../../../shared/mappers";
import {
  ButtonWrapper,
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
} from "../../../../../styles/SharedStyles";
import { ProductCatalogueSkuBody } from "../../../../sourcing/shared/fetchSku/api/types";
import {
  LinkSkuToOrganisationInterface,
  initialLinkSkuToOrganisationValues,
  linkSkuToOrganisationFieldName,
} from "../../shared/types";
import { LinkSkuToOrganisationBody } from "../api/linkSkuToOrganisation";
import { UnlinkSkuFromOrgParams } from "../api/unlinkSkuFromOrganisation";
import { TitleWrapper } from "../page/UpdateSku";
import { linkSkuToOrgValidationSchema } from "../validation";

interface LinkSkuToOrganisationFormProps {
  sku: ProductCatalogueSkuBody | undefined;
  linkSkuToOrg: (
    data: LinkSkuToOrganisationBody | LinkSkuToOrganisationBody[]
  ) => Promise<AxiosResponse<any> | AxiosResponse<any>[]>;
  unlinkSkuFromOrg: (
    data: UnlinkSkuFromOrgParams | UnlinkSkuFromOrgParams[]
  ) => Promise<AxiosResponse<any> | AxiosResponse<any>[]>;
  getCurrencyWarning?: JSX.Element;
  matchingOrgs?: (Organisation | undefined)[];
  organisationsError?: ApolloError;
  organisations?: Organisation[];
  organisationsLoading: boolean;
}

interface UnlinkButtonProps {
  org: Organisation;
  onClick: () => void;
}

const UnlinkButton = ({ org, onClick }: UnlinkButtonProps) => {
  const [isRemoved, setIsRemoved] = useState<boolean>(false);
  const UnlinkContainer = styled("div", {
    position: "relative",
    paddingRight: "35px",
    borderRadius: "5px",
    border: "1px solid",
    marginRight: "1em",
    justifyContent: "center",
    marginTop: "5px",
    cursor: "pointer",

    "& p": {
      margin: "7px 5px 5px 5px",
      color: "$palette1_colour1",
    },

    "& svg": {
      position: "absolute",
      top: "25%",
      right: 0,
    },
  });

  return (
    <UnlinkContainer
      onClick={() => {
        setIsRemoved(prevIsRemoved => !prevIsRemoved);
        onClick();
      }}
      css={isRemoved ? { backgroundColor: "$palette3_colour1_tint40" } : {}}
    >
      <p>{org!.name}</p>
      <Icon name="alert-cross-default" width={20} height={20} />
    </UnlinkContainer>
  );
};

const LinkSkuToOrganisationForm = ({
  sku,
  linkSkuToOrg,
  unlinkSkuFromOrg,
  getCurrencyWarning,
  matchingOrgs,
  organisationsError,
  organisations,
  organisationsLoading,
}: LinkSkuToOrganisationFormProps) => {
  const [orgIdsToBeRemoved, setOrgIdsToBeRemoved] = useState<string[]>([]);
  const handleSubmitLinkSkuToOrg = async (values: LinkSkuToOrganisationInterface) => {
    const orgIds = values?.orgIds?.map((orgId: any) => orgId.value) || [];
    if (sku) {
      if (values.orgIds) {
        const body: LinkSkuToOrganisationBody = { sku_id: sku.id, org_ids: orgIds };
        await linkSkuToOrg(body).catch(error => {
          console.error("error linking sku to org in UpdateSku:", error);
        });
      }

      if (values.orgIdsToBeRemoved.length > 0) {
        const params = values.orgIdsToBeRemoved.map(orgId => {
          return {
            skuId: sku.id,
            orgId,
          };
        });
        await unlinkSkuFromOrg(params).catch(error => {
          console.error("error unlinking sku from org in UpdateSku:", error);
        });
        setOrgIdsToBeRemoved([]);
        values.orgIdsToBeRemoved = [];
      }
    }
  };

  const isSubmitButtonEnabled = (values: LinkSkuToOrganisationInterface) => {
    if (values.orgIds && values.orgIds.length > 0) {
      return true;
    } else if (orgIdsToBeRemoved.length > 0) {
      return true;
    }
    return false;
  };

  const handleUnlinkButtonClicked = (
    values: LinkSkuToOrganisationInterface,
    org: Organisation,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void
  ) => {
    if (org!.externalOrganisationId) {
      if (values.orgIdsToBeRemoved.includes(org!.externalOrganisationId)) {
        values.orgIdsToBeRemoved.splice(
          values.orgIdsToBeRemoved.indexOf(org!.externalOrganisationId),
          1
        );
      } else {
        values.orgIdsToBeRemoved.push(org!.externalOrganisationId);
      }

      setFieldValue(linkSkuToOrganisationFieldName.orgIdsToBeRemoved, values.orgIdsToBeRemoved);
      setOrgIdsToBeRemoved(values.orgIdsToBeRemoved);
    } else {
      console.warn("external org id is undefined", org);
    }
  };

  return (
    <Formik
      initialValues={initialLinkSkuToOrganisationValues}
      enableReinitialize
      onSubmit={async (values: LinkSkuToOrganisationInterface, { resetForm }) => {
        await handleSubmitLinkSkuToOrg(values);
        resetForm();
      }}
      validationSchema={linkSkuToOrgValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <PrimaryLineWrapper>
              <TitleWrapper>
                <Title>Link SKU with organisation(s)</Title>

                {getCurrencyWarning}
              </TitleWrapper>
              {sku && Array.isArray(sku.org_ids) && sku.org_ids.length > 0 && (
                <div>
                  Currently linked org IDs:
                  <div style={{ display: "flex" }}>
                    {matchingOrgs
                      ?.filter(matchingOrgs => !!matchingOrgs)
                      .map(org => {
                        return (
                          <UnlinkButton
                            key={org!.id}
                            org={org!}
                            onClick={() => handleUnlinkButtonClicked(values, org!, setFieldValue)}
                          />
                        );
                      })}
                  </div>
                </div>
              )}
              <PrimaryLineDataWrapper>
                <DataPoint halfWidth>
                  <SimpleSelect
                    isSearchable
                    isMulti
                    tooltipMessage="you can select one or more organisations"
                    isDisabled={!!organisationsError}
                    htmlFor={linkSkuToOrganisationFieldName.orgIds}
                    value={values.orgIds}
                    options={mapStockManagementOrganisationsToDropdownItem(organisations)}
                    placeholder={organisationsLoading ? "Loading..." : "Select Organisation(s)"}
                    label="Organisation(s) linked to SKU"
                    changeHandler={event => {
                      setFieldValue(linkSkuToOrganisationFieldName.orgIds, event);
                    }}
                    error={errors.orgIds}
                    touched={touched.orgIds}
                    name={linkSkuToOrganisationFieldName.orgIds}
                  />
                </DataPoint>
              </PrimaryLineDataWrapper>
              <VisibleFor roles={SM_ADMIN_ROLES}>
                <ButtonWrapper style={{ marginTop: "10px" }}>
                  <SecondaryButton
                    type="submit"
                    appearance={"blueButton"}
                    isWithinTable
                    disabled={!isSubmitButtonEnabled(values)}
                  >
                    Update SKU Linked Organisation(s)
                  </SecondaryButton>
                </ButtonWrapper>
              </VisibleFor>
            </PrimaryLineWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default LinkSkuToOrganisationForm;
