import * as Yup from "yup";

export const validationSchema = Yup.object({
  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),
  from_location: Yup.object()
    .shape({
      value: Yup.number().required("From Location is required"),
    })
    .typeError("From Location  is required"),
  to_location: Yup.object()
    .shape({
      value: Yup.number().required("To Location is required"),
    })
    .typeError("To Location  is required"),
  transfer_lines: Yup.array().of(
    Yup.object({
      quantity: Yup.string().required("Quantity is required"),
      source_product_stock: Yup.object()
        .shape({
          value: Yup.number().required("Product Stock is required"),
        })
        .typeError("Product Stock is required"),
    })
  ),
});
