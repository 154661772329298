import * as Yup from "yup";

export const validationSchema = Yup.object({
  quantity: Yup.string().required("Quantity is required"),
  product: Yup.object()
    .shape({
      value: Yup.number().required("Product is required"),
    })
    .typeError("Product is required"),
  condition: Yup.object()
    .shape({
      value: Yup.number().required("Condition is required"),
    })
    .typeError("Condition is required"),
  location: Yup.object()
    .shape({
      value: Yup.number().required("Location is required"),
    })
    .typeError("Location is required"),
});
