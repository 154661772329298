import { OrganisationNameItem } from "../graphql/types";

export const mapOrganisationNamesToDropdownObject = (organisationNames: OrganisationNameItem[]) => {
  return organisationNames.map(organisationName => {
    return {
      label: organisationName.organisation_name,
      value: organisationName.id,
    };
  });
};
