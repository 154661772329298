import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";
import { UpdateStockManagementOrganisationBody } from "./types";

export const updateStockManagementOrganisation = async (
  body: UpdateStockManagementOrganisationBody
) => {
  try {
    return await STOCK_MANAGEMENT_API.patch("/organisations", body);
  } catch (err) {
    console.error("Error in update organisation API call", err);
    throw err;
  }
};
