import { ApolloError } from "@apollo/client";

import { useGetBasicStockCheckTypesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DropdownItem } from "../../../shared/graphql/types";
import { mapStockCheckTypesToDropdownObject } from "../../../shared/mappers";

export interface HookResult {
  stockCheckTypes?: DropdownItem[];
  stockCheckTypesError?: ApolloError;
  stockCheckTypesLoading: boolean;
}

export const useBasicStockCheckTypes = (): HookResult => {
  const { data, error, loading } = useGetBasicStockCheckTypesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockCheckTypes:
      data && Array.isArray(data.stock_check_type)
        ? mapStockCheckTypesToDropdownObject(data.stock_check_type)
        : [],
    stockCheckTypesError: error,
    stockCheckTypesLoading: loading,
  };
};
