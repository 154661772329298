import { ApolloError } from "@apollo/client";

import { useGetWorkOrderViewByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { WorkOrderItem } from "../types";

export interface HookResult {
  workOrder?: WorkOrderItem;
  workOrderError?: ApolloError;
  workOrderLoading: boolean;
}

export const useWorkOrderById = (workOrderId: number): HookResult => {
  const { data, error, loading } = useGetWorkOrderViewByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      workOrderId,
    },
  });

  return {
    workOrder: data && Array.isArray(data.work_order) ? data.work_order[0] : undefined,
    workOrderError: error,
    workOrderLoading: loading,
  };
};
