import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

interface InputCreateBaseProduct {
  name: string;
  type: string;
}

export const createBaseProduct = async (input: InputCreateBaseProduct) => {
  try {
    const response = await PRODUCT_CATALOGUE_API.post("/baseproduct", input);
    const data = response.data;
    return {
      data: data,
    };
  } catch (err) {
    console.log("Error in creating product API call", err);
    throw err;
  }
};
