import { styled } from "@sourceful/shared-components";

export const SecondaryButton = styled("button", {
  fontFamily: "Space Grotesk",
  fontStyle: "normal",
  fontSize: "$p2",
  lineHeight: "26px",
  letterSpacing: "-0.025em",
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  paddingInlineStart: "25px",
  paddingInlineEnd: "25px",
  height: "50px",
  flex: "none",
  order: 0,
  flexGrow: 0,
  margin: "4px, 0px",
  alignSelf: "stretch",
  backgroundColor: "transparent",
  color: "$palette3_colour1_shade20",
  borderRadius: "6px",
  borderSizing: "border-box",

  variants: {
    appearance: {
      blueButton: {
        border: "none",
        backgroundColor: "$palette1_colour1",
        color: "$palette3_colour2",
        "&:hover": {
          backgroundColor: "$palette1_colour1_tint20",
          cursor: "pointer",
        },
        "&:focus-visible": {
          backgroundColor: "$palette1_colour1_tint20",
          $$focusBorder: "$colors$palette1_colour1_tint60",
          boxShadow: "0 0 0 4px $$focusBorder",
          outline: "none",
        },
        "&:active": {
          backgroundColor: "$palette1_colour1",
          border: "none",
          color: "$palette3_colour2",
        },
        "&:disabled": {
          backgroundColor: "$palette3_colour1_tint60",
          border: "none",
          color: "$palette3_colour1_tint40",
          cursor: "not-allowed",
        },
      },
      greenButton: {
        background: "$palette2_colour1",
        border: "none",
        color: "$palette3_colour2",
        "&:hover": {
          backgroundColor: "$palette2_colour1_tint20",
          cursor: "pointer",
        },
        "&:focus-visible": {
          backgroundColor: "$palette2_colour1",
          $$focusBorder: "$colors$palette1_colour1_tint60",
          boxShadow: "0 0 0 4px $$focusBorder",
          outline: "none",
        },
        "&:active": {
          backgroundColor: "#7ecf7e",
          border: "none",
          color: "$palette3_colour2",
        },
        "&:disabled": {
          backgroundColor: "$palette3_colour1_tint60",
          border: "none",
          color: "$palette3_colour1_tint40",
        },
      },
      whiteButtonBlueText: {
        border: "1px solid $palette3_colour1_tint20",
        fontWeight: 600,
        color: "$palette1_colour1",
        "&:hover": {
          cursor: "pointer",
          border: "1px solid $palette3_colour1_shade20",
        },
        "&:focus-visible": {
          border: "1px solid $palette3_colour1_shade20",
          $$focusBorder: "$colors$palette3_colour1_tint60",
          boxShadow: "0 0 0 4px $$focusBorder",
          outline: "none",
        },
        "&:active": {
          border: "1px solid $palette3_colour1_tint20",
        },
        "&:disabled": {
          border: "1px solid $palette3_colour1_tint60",
          color: "$palette3_colour1_tint60",
          cursor: "not-allowed",
        },
      },
      whiteButton: {
        border: "1px solid $palette3_colour1_tint20",
        "&:hover": {
          cursor: "pointer",
          border: "1px solid $palette3_colour1_shade20",
        },
        "&:focus-visible": {
          border: "1px solid $palette3_colour1_shade20",
          $$focusBorder: "$colors$palette3_colour1_tint60",
          boxShadow: "0 0 0 4px $$focusBorder",
          outline: "none",
        },
        "&:active": {
          border: "1px solid $palette3_colour1_tint20",
        },
        "&:disabled": {
          border: "1px solid $palette3_colour1_tint60",
          color: "$palette3_colour1_tint60",
        },
      },
    },
    icon: {
      singleIcon: {
        padding: "13px",
      },
    },
    isWithinTable: {
      true: {
        padding: "5px, 10px",
      },
    },
  },
  defaultVariants: {
    appearance: "blueButton",
  },
});

export default SecondaryButton;
