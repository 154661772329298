import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { cancelInboundManifestAPI } from "../../api/terminateInboundManifest/cancelInboundManifestAPI";
import { completeInboundManifestAPI } from "../../api/terminateInboundManifest/completeInboundManifestAPI";
import { rejectInboundManifestAPI } from "../../api/terminateInboundManifest/rejectInboundManifestAPI";
import {
  CHANGE_IBM_STATUS,
  TERMINATE_INBOUND_MANIFEST_FAILED,
  TERMINATE_INBOUND_MANIFEST_LOADING,
  TERMINATE_INBOUND_MANIFEST_RESET,
  TERMINATE_INBOUND_MANIFEST_SUCCESS,
} from "../types/";

export const terminatingInboundManifest = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_LOADING,
  };
};

export const terminateInboundManifestSuccess = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_SUCCESS,
  };
};

export const terminateInboundManifestFailed = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_FAILED,
  };
};

export const terminateInboundManifestReset = () => {
  return {
    type: TERMINATE_INBOUND_MANIFEST_RESET,
  };
};

export interface TerminateInboundManifestActionBody {
  type: CHANGE_IBM_STATUS;
  inbound_manifest_id: number;
}

export const terminateInboundManifestAction = ({
  inbound_manifest_id,
  type,
}: TerminateInboundManifestActionBody) => {
  return async (dispatch: Dispatch) => {
    toast(`Terminating Inbound Manifest with type ${type}`, { hideProgressBar: true });
    dispatch(terminatingInboundManifest());
    try {
      if (type === CHANGE_IBM_STATUS.CANCEL) {
        await cancelInboundManifestAPI(inbound_manifest_id);
      }
      if (type === CHANGE_IBM_STATUS.REJECT) {
        await rejectInboundManifestAPI(inbound_manifest_id);
      }
      if (type === CHANGE_IBM_STATUS.MARK_AS_DONE) {
        await completeInboundManifestAPI(inbound_manifest_id);
      }

      toast.success("Inbound Manifest terminated successfully.");
      dispatch(terminateInboundManifestSuccess());
    } catch (err) {
      toast.error("Something went wrong with terminating inbound manifest.");
      dispatch(terminateInboundManifestFailed());
    }
  };
};
