import { TransferLine } from "../formValues";
import { LocationNameItem, WarehouseNameItem } from "../graphql/types";

export const mapWarehouseNamesToDrodownObject = (warehouseNames: WarehouseNameItem[]) => {
  return warehouseNames.map(warehouseName => {
    return {
      label: warehouseName.warehouse_name,
      value: warehouseName.id,
    };
  });
};

export const mapLocationNamesToDropdownObject = (locationNames: LocationNameItem[]) => {
  return locationNames.map(locationName => {
    return {
      label: locationName.location_name,
      value: locationName.id,
    };
  });
};

export const mapTransferLineToDTO = (transferLines: TransferLine[]) => {
  return transferLines.map(transferLine => {
    return {
      quantity: parseInt(transferLine.quantity),
      source_product_stock_id: transferLine.source_product_stock?.value!,
    };
  });
};
