import Icon from "../../../../../components/icons/Icon";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import {
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { SupplierDateLineProps } from "../types";

export const SupplierDateLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveSupplierLine,
  errors,
  touched,
  suppliers,
}: SupplierDateLineProps) => (
  <SecondaryLineWrapper>
    <SecondaryLineDataWrapper>
      <DataPoint halfWidth>
        <SimpleSelect
          isRequired
          name="Suppliers"
          placeholder="Select supplier"
          options={suppliers}
          value={values.supplier}
          error={errors && errors[index]?.supplier}
          touched={touched && touched[index]?.supplier}
          label="Supplier"
          changeHandler={e => setFieldValue(`supplierConfigs[${index}].supplier`, e)}
        />
      </DataPoint>
      <DataPoint halfWidth>
        <SimpleInput
          isRequired
          name="supplierConfigDate"
          type={"number"}
          placeholder="Enter Delivery Date Adjustment"
          value={values.supplierConfigDay}
          error={errors && errors[index]?.supplierConfigDay}
          touched={touched && touched[index]?.supplierConfigDay}
          label="Delivery Date Adjustment"
          changeHandler={e =>
            setFieldValue(`supplierConfigs[${index}].supplierConfigDay`, e.target.value)
          }
        />
      </DataPoint>
    </SecondaryLineDataWrapper>

    <DataPoint fullWidth centeredText>
      <IconWrapper type="button" onClick={() => handleRemoveSupplierLine(index)}>
        <Icon width={30} height={30} name="navigation-trash" />
      </IconWrapper>
    </DataPoint>
  </SecondaryLineWrapper>
);
