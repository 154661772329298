import { IAM_API } from "../../../../../api/baseConfig";

export interface onboardUserBody {
  orgUuid: string;
  userUuid: string;
}

export const markIAMUserOnboarded = async (params: onboardUserBody) => {
  try {
    return IAM_API.patch(`/v2/user/onboarded`, {
      org_uuid: params.orgUuid,
      user_uuid: params.userUuid,
    });
  } catch (err) {
    console.error("Error in IAM onboard user API call", err);
    throw err;
  }
};
