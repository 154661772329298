import { ApolloError } from "@apollo/client";

import { useGetRequestsForQuotationsQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { RequestForQuotationItem } from "../types";

export interface HookResult {
  requestsForQuotations: RequestForQuotationItem[];
  requestsForQuotationsError?: ApolloError;
  requestsForQuotationsLoading: boolean;
}

export const useRequestsForQuotations = (): HookResult => {
  const { data, error, loading } = useGetRequestsForQuotationsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    requestsForQuotations:
      data && Array.isArray(data.supplier_quote_request) ? data.supplier_quote_request : [],
    requestsForQuotationsError: error,
    requestsForQuotationsLoading: loading,
  };
};
