import * as Yup from "yup";

export const validationSchema = Yup.object({
  quantity: Yup.string().required("Please input a quantity"),
  work_order_and_recipe_outputs: Yup.array().of(
    Yup.object({
      work_order_outputs: Yup.array().of(
        Yup.object({
          location: Yup.object()
            .shape({
              value: Yup.number().required("Location is required"),
            })
            .typeError("Location is required"),
        })
      ),
    })
  ),
});
