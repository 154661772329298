import { SOURCING_API, UNAUTHENTICATED_SOURCING_API } from "../../../../../api/baseConfig";
import { CreateQuotesBody } from "./types";

export const createQuotesAPI = async (body: CreateQuotesBody, access_token?: string) => {
  try {
    if (access_token) {
      return await UNAUTHENTICATED_SOURCING_API.post("/supplier-quotes", {
        ...body,
        access_token,
      });
    } else {
      return await SOURCING_API.post("/supplier-quotes/internal", {
        ...body,
      });
    }
  } catch (err) {
    console.log("Error in create Quotes API call", err);
    throw err;
  }
};
