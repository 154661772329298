import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

export const getAttributeTemplateOptions = async ({ templateId }: { templateId: number }) => {
  try {
    const response = await PRODUCT_CATALOGUE_API.get(`/attribute/options/${templateId}`);
    return response.data.data;
  } catch (err) {
    console.log("Error in getting attribute templates options API call", err);
    throw err;
  }
};
