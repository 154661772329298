import { QuotationCostLocaleItem } from "../graphql/types";

export const mapQuotationCostTypeLocales = (locales: QuotationCostLocaleItem[]) => {
  return locales.map((locale, index) => {
    return {
      label: locale.locale,
      value: index,
    };
  });
};

export const costRequiredOptions = [
  {
    label: "Yes",
    value: 1,
  },
  {
    label: "No",
    value: 0,
  },
];

export const getCostRequiredValue = (val: boolean) => {
  return val
    ? {
        label: "Yes",
        value: 1,
      }
    : {
        label: "No",
        value: 0,
      };
};
