import * as Yup from "yup";

export const validationSchema = Yup.object({
  quotation_cost_description: Yup.string().required("Cost Description is required"),
  quotation_cost_translations: Yup.array().of(
    Yup.object({
      locale: Yup.object()
        .shape({
          value: Yup.number().required("Translation locale is required"),
        })
        .typeError("Translation locale is required"),
      quotation_cost_name: Yup.string().required("Cost Type Name is required"),
      quotation_cost_description: Yup.string().required("Cost Description is required"),
    })
  ),
  is_cost_required_default: Yup.boolean().required("Is Cost Required Default is required"),
  is_cost_required_sample: Yup.boolean().required("Is Cost Required Sample is required"),
});
