export interface DeleteFileCopiesForm {
  from_date: string;
}

export const initialFieldValues: DeleteFileCopiesForm = {
  from_date: "",
};

export const fieldNames = {
  from_date: "from_date",
};
