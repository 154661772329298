import { IconText, SecondaryButton, styled } from "@sourceful/shared-components";
import { IconName } from "@sourceful/shared-types";

import { ButtonWrapper, Title } from "../../styles/SharedStyles";

export const HorizontalCardWrapper = styled("div", {
  width: "auto",
  height: "auto",
  backgroundColor: "white",
  border: "1px solid #C4C4C4",
  borderRadius: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  paddingTop: "25px",
  paddingBottom: "25px",
  alignItems: "center",
});

const defaultContentStyles = {
  width: "400px",
};

export const Text = styled("div", {
  marginTop: "20px",
  fontSize: "15px",
  fontWeight: 400,
  textAlign: "center",
  marginLeft: "10px",
  marginRight: "10px",
});

export interface HorizontalCardProps {
  title: string;
  description?: string;
  primaryButton: ButtonProps;
  secondaryButton?: ButtonProps;
  contentStyles?: any; //TODO: this should be internalCSS
}

export interface ButtonProps {
  iconName?: IconName;
  buttonText: string;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  id: number;
}

export const HorizontalCard = ({
  title,
  description,
  primaryButton,
  secondaryButton,
  contentStyles = defaultContentStyles,
}: HorizontalCardProps) => {
  return (
    <HorizontalCardWrapper css={contentStyles}>
      <Title>{title}</Title>
      {description && <Text>{description}</Text>}
      <ButtonWrapper style={{ marginTop: "10px" }}>
        <SecondaryButton key={primaryButton.id} onClick={primaryButton.handleClick}>
          <IconText text={primaryButton.buttonText} primaryIcon={primaryButton.iconName} />
        </SecondaryButton>
      </ButtonWrapper>
      {secondaryButton && (
        <ButtonWrapper style={{ marginTop: "10px" }}>
          <SecondaryButton key={secondaryButton.id} onClick={secondaryButton.handleClick}>
            <IconText text={secondaryButton.buttonText} primaryIcon={secondaryButton.iconName} />
          </SecondaryButton>
        </ButtonWrapper>
      )}
    </HorizontalCardWrapper>
  );
};
