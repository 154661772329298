import { Formik } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup } from "@sourceful/shared-components";

import Panel from "../../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import { SimpleInput } from "../../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { CreateCaseProductBody } from "../../../../products/addProduct/api/types";
import {
  ProductForm,
  fieldNames,
  initialFieldValues,
} from "../../../../products/addProduct/formValues";
import { useBaseProductData } from "../../../../products/addProduct/graphql/hooks/useBaseProductData";
import { useUnderlyingCaseProductByBaseProductId } from "../../../../products/addProduct/graphql/hooks/useUnderlyingCaseProductByBaseProductId";
import {
  createCaseProductAction,
  createCaseProductReset,
} from "../../../../products/addProduct/redux/actions/createCaseProductAction";
import { CreateCaseProductReducer } from "../../../../products/addProduct/redux/reducers/createCaseProductReducer";
import { validationSchema } from "../../../../products/addProduct/validation";

interface AddProductModalInterface {
  base_product_id: number;
  base_product_name: string;
  product_name: string;
  product_quantity: number;
  setIsNewProductCreated: Dispatch<SetStateAction<boolean>>;
  isNewProductCreated: boolean;
  setIsModalOpen: Dispatch<SetStateAction<boolean>>;
  createCaseProductState: CreateCaseProductReducer;
  createCaseProduct: (body: CreateCaseProductBody) => void;
}

const AddProductModal = (props: AddProductModalInterface) => {
  const updatedFieldValues: ProductForm = {
    ...initialFieldValues,
    base_product_quantity: `${props.product_quantity}`,
    product_name: props.product_name,
    base_product: { label: props.base_product_name, value: props.base_product_id },
  };

  const [initialValues] = useState<ProductForm>(updatedFieldValues);
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);

  const onSubmit = async (values: ProductForm) => {
    setMutationLoading(true);

    const selectedCaseProduct = caseProducts.find(
      caseProduct => caseProduct.id === values.case_product?.value
    );

    const selectedBaseProduct = baseProducts.find(
      baseProduct => baseProduct.id === values.base_product?.value
    );

    const newProductBody: CreateCaseProductBody = {
      product_name: values.product_name,
      fsc_certified: selectedBaseProduct?.fsc_certified!,
      grs_certified: selectedBaseProduct?.grs_certified!,
      base_product_id: selectedBaseProduct?.id!,
      base_product_quantity: Number(values.base_product_quantity),
      case_product_id: selectedCaseProduct?.id!,
      case_product_quantity:
        Number(values.base_product_quantity) / selectedCaseProduct?.base_product_quantity!,
    };

    if (values.width_mm) newProductBody.width_mm = Number(values.width_mm);
    if (values.height_mm) newProductBody.height_mm = Number(values.height_mm);
    if (values.weight_grams) newProductBody.weight_grams = Number(values.weight_grams);
    if (values.length_mm) newProductBody.length_mm = Number(values.length_mm);

    const remainder = newProductBody.base_product_quantity % newProductBody.case_product_quantity;

    if (remainder !== 0) {
      toast.error("Product Quantity is not divisible by Underlying Product Quantity ");
      return;
    }

    props.createCaseProduct(newProductBody);
    props.setIsModalOpen(false);
    props.setIsNewProductCreated(!props.isNewProductCreated);
  };

  const { baseProducts, baseProductsLoading, baseProductsError } = useBaseProductData();

  const {
    caseProducts,
    caseProductsLoading,
    caseProductsError,
    getUnderlyingCaseProductByBaseProductId,
  } = useUnderlyingCaseProductByBaseProductId();

  useEffect(() => {
    getUnderlyingCaseProductByBaseProductId({
      baseProductId: initialValues.base_product?.value!,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isError = baseProductsError || caseProductsError;
  const isLoading = baseProductsLoading || mutationLoading;

  return (
    <Page error={isError} isLoading={isLoading} title={"Add Product"}>
      <Panel withWrapper title={"Add Product"} subtitle={"Add a new Product using the card below"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleReset,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => {
              const caseProductsCopy = caseProductsLoading
                ? "Loading products"
                : "Select Underlying Product";

              const underlyingQtyCopy = values.case_product
                ? caseProducts.find(caseProduct => caseProduct.id === values.case_product?.value)
                    ?.base_product_quantity
                : "Please select an Underlying Product";

              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <SimpleSelect
                        isRequired
                        htmlFor={fieldNames.base_product}
                        name={fieldNames.base_product}
                        placeholder="Select Base Product"
                        options={baseProducts.map(base_product => {
                          return {
                            label: base_product.product_name!,
                            value: base_product.id!,
                          };
                        })}
                        value={values.base_product}
                        error={errors.base_product}
                        touched={touched.base_product}
                        label="Base Product"
                        tooltipMessage="All New Case Products must have a Base Product"
                        changeHandler={e => {
                          setFieldValue(fieldNames.base_product, e);
                          setFieldValue(fieldNames.case_product, null);
                          getUnderlyingCaseProductByBaseProductId({ baseProductId: e?.value! });
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isRequired
                        htmlFor={fieldNames.product_name}
                        name={fieldNames.product_name}
                        type={"text"}
                        placeholder="Enter Product Name"
                        value={values.product_name}
                        error={errors.product_name}
                        touched={touched.product_name}
                        label="Product Name"
                        changeHandler={handleChange}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isRequired
                        htmlFor={fieldNames.base_product_quantity}
                        name={fieldNames.base_product_quantity}
                        type={"text"}
                        placeholder="Enter Product Qty"
                        value={values.base_product_quantity}
                        error={errors.base_product_quantity}
                        touched={touched.base_product_quantity}
                        label="Product Quantity"
                        changeHandler={handleChange}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleSelect
                        isRequired
                        htmlFor={fieldNames.case_product}
                        name={fieldNames.case_product}
                        placeholder={caseProductsCopy}
                        options={caseProducts.map(caseProduct => {
                          return {
                            label: caseProduct.product_name,
                            value: caseProduct.id,
                          };
                        })}
                        value={values.case_product}
                        error={errors.case_product}
                        touched={touched.case_product}
                        label="Underlying Product"
                        tooltipMessage="New Case Products are built from an existing product. This existing product may be a case or base product. Please select the appropriate underlying product here."
                        changeHandler={e => setFieldValue(fieldNames.case_product, e)}
                      />
                    </DataPoint>
                    <DisplayBox isGray label="Underlying Product Qty" value={underlyingQtyCopy} />
                    <DataPoint>
                      <SimpleInput
                        htmlFor={fieldNames.weight_grams}
                        name={fieldNames.weight_grams}
                        type={"number"}
                        placeholder="Enter Weight (g)"
                        value={values.weight_grams}
                        error={errors.weight_grams}
                        touched={touched.weight_grams}
                        label="Weight (grams)"
                        changeHandler={handleChange}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        htmlFor={fieldNames.width_mm}
                        name={fieldNames.width_mm}
                        type={"number"}
                        placeholder="Enter Width (mm)"
                        value={values.width_mm}
                        error={errors.width_mm}
                        touched={touched.width_mm}
                        label="Width (mm)"
                        changeHandler={handleChange}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        htmlFor={fieldNames.height_mm}
                        name={fieldNames.height_mm}
                        type={"number"}
                        placeholder="Enter Height (mm)"
                        value={values.height_mm}
                        error={errors.height_mm}
                        touched={touched.height_mm}
                        label="Height (mm)"
                        changeHandler={handleChange}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        htmlFor={fieldNames.length_mm}
                        name={fieldNames.length_mm}
                        type={"number"}
                        placeholder="Enter Length (mm)"
                        value={values.length_mm}
                        error={errors.length_mm}
                        touched={touched.length_mm}
                        label="Length (mm)"
                        changeHandler={handleChange}
                      />
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
                    <SecondaryButton
                      appearance="whiteButton"
                      type="button"
                      onClick={() => {
                        handleReset();
                        props.setIsModalOpen(false);
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <SecondaryButton
                      appearance="blueButton"
                      type="submit"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Confirm
                    </SecondaryButton>
                  </ButtonGroup>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createCaseProductState: state.createCaseProductReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createCaseProduct: (body: CreateCaseProductBody) => dispatch(createCaseProductAction(body)),
    resetCreateCaseProduct: () => dispatch(createCaseProductReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProductModal);
