import { useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../../components/Table";
import { FilterSetter } from "../../../../../../components/Table/TableUtilityBar";
import Panel from "../../../../../../components/panel/panel";
import StatusBadge from "../../../../../../shared/components/molecules/StatusBadge";
import { LinkText } from "../../../../../../styles/SharedStyles";
import { useStockByLocationType } from "../graphql/hooks/useDashboardStockByLocationType";
import { ProductStockItem } from "../graphql/types";
import { LOCATION_TYPE } from "../types";

export default function InventoryTableByLocationType() {
  const [populatedFields, setPopulatedFields] = useState<ProductStockItem[]>([]);

  const { stockByLocationType, stockByLocationTypeLoading } = useStockByLocationType();

  const inboundStocks =
    stockByLocationType?.filter(
      locationType => locationType.location_type === LOCATION_TYPE.INBOUND
    )[0]?.product_stocks || [];
  const outboundStocks =
    stockByLocationType?.filter(
      locationType => locationType.location_type === LOCATION_TYPE.OUTBOUND
    )[0]?.product_stocks || [];
  const pickfaceStocks =
    stockByLocationType?.filter(
      locationType => locationType.location_type === LOCATION_TYPE.PICKFACE
    )[0]?.product_stocks || [];
  const bulkStocks =
    stockByLocationType?.filter(
      locationType => locationType.location_type === LOCATION_TYPE.BULK
    )[0]?.product_stocks || [];

  useEffect(() => {
    setPopulatedFields(stockByLocationType?.flatMap(locations => locations.product_stocks) || []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockByLocationTypeLoading]);

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (product: ProductStockItem) => product.id,
      },
      {
        Header: "Name",
        widthPercent: 1.5,
        filter: "multiRow",
        accessor: (product: ProductStockItem) => product.product.product_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/view-product-stock/${row.original.id}`}>
            <LinkText>{row.original.product.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Stock Qty",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (product: ProductStockItem) => product.quantity,
      },
      {
        Header: "Case Qty",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (product: ProductStockItem) =>
          product.product.base_product_quantity * product.quantity,
      },
      {
        Header: "Organisation",
        widthPercent: 0.5,
        filter: "multiRow",
        accessor: (product: ProductStockItem) => product.organisation?.organisation_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation?.id}`}>
            <LinkText>{row.original.organisation?.organisation_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Location",
        widthPercent: 0.5,
        filter: "multiRow",
        accessor: (product: ProductStockItem) => product.location.location_name,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.location.id}`}>
            <LinkText>{row.original.location.location_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Status",
        widthPercent: 0.4,
        accessor: (product: ProductStockItem) => product.stock_status.stock_status_name,
        filter: "multiRow",
        Cell: ({ _, row }: CellProps<ProductStockItem>) => (
          <StatusBadge type="wms" statusName={row.original.stock_status.stock_status_name} />
        ),
      },
      {
        Header: "Condition",
        widthPercent: 0.9,
        filter: "multiRow",
        accessor: (product: ProductStockItem) => product.stock_condition.stock_condition_name,
      },
    ],
    []
  );

  return (
    <Panel title={"Inventory"} allignTitle="left">
      <Table
        renderFilters={(_: FilterSetter<any>) => <></>}
        data={populatedFields}
        columns={columns}
        filterToggles={[
          {
            filteredMatches: Number(
              stockByLocationType?.flatMap(locations => locations.product_stocks).length
            ),
            header: "All",
            handleFilter: () => {
              setPopulatedFields(
                stockByLocationType?.flatMap(locations => locations.product_stocks) || []
              );
            },
          },
          {
            filteredMatches: outboundStocks.length,
            header: "Outbound",
            handleFilter: () => {
              setPopulatedFields(outboundStocks);
            },
            toolTipDescription:
              "Location where outbound stock <br/> is usually kept awaiting collection",
          },
          {
            filteredMatches: inboundStocks.length,
            header: "Inbound",
            handleFilter: () => {
              setPopulatedFields(inboundStocks);
            },
            toolTipDescription:
              "Location where inbound stock is usually <br/> kept awaiting to be put away",
          },
          {
            filteredMatches: pickfaceStocks.length,
            header: "Pickface",
            handleFilter: () => {
              setPopulatedFields(pickfaceStocks);
            },
            toolTipDescription: "Shelves of varying sizes <br/> where stock can be stored",
          },
          {
            filteredMatches: bulkStocks.length,
            header: "Bulk",
            handleFilter: () => {
              setPopulatedFields(bulkStocks);
            },
            toolTipDescription: "Pallet sized spaces where <br/> stock is usually stored in cases",
          },
        ]}
      />
    </Panel>
  );
}
