import * as yup from "yup";
import "yup-phone-lite";

let EMAIL_REGX = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$/;
let SOURCEFUL_REGX = /^(?!.*@sourceful).*/;

declare module "yup" {
  export interface StringSchema {
    phone(countryCode?: string | string[], errorMessage?: string): StringSchema;
  }
}

export const createUserFormSchema = yup.object({
  email: yup
    .string()
    .email()
    .matches(SOURCEFUL_REGX, "External users only")
    .matches(EMAIL_REGX, "Invalid email address")
    .required("Email is required"),
  phoneNumber: yup
    .string()
    .phone(["US", "GB", "DE", "BE", "FR", "ES", "IT", "IE"], "Phone number must be valid")
    .required("Phone is required"),
  fullName: yup.string().required("Full name is required"),
  organisationName: yup
    .object()
    .shape({ value: yup.string(), label: yup.string() })
    .required("organisation is required"),
  roles: yup
    .array()
    .of(yup.object().shape({ value: yup.string(), label: yup.string() }))
    .required("Roles are required")
    .min(1, "At least one role is required"),
});
