import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import {
  Box,
  ButtonWrapper,
  CertificationBadge,
  DataPoint,
  FlexHolder,
  FlexTitle,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { OutboundManifestLineStatuses } from "../../../../../util/interfaces";
import { OutboundManifestLineItem } from "../graphql/types";
import { mapManifestLineStatusToMessage } from "../mappers";
import {
  TerminateOutboundManifestLineActionBody,
  terminateOutboundManifestLineAction,
  terminateOutboundManifestLineReset,
} from "../redux/actions/terminateOutboundManifestLineAction";
import { TerminateOutboundManifestLineReducer } from "../redux/reducers/terminateOutboundManifestLineReducer";
import { CHANGE_OBM_LINE_STATUS } from "../redux/types";
import FulfilOutboundManifestLine from "./FulfilOutboundManifestLine";
import ReturnOutboundManifestLine from "./ReturnOutboundManifestLine";

export interface OutboundManifestLineProps {
  index: number;
  organisationId: number;
  warehouseId: number;
  outboundManifestId: number;
  outboundManifestLine: OutboundManifestLineItem;
  refetchOutboundManifest: () => void;
  terminateOBMLineState: TerminateOutboundManifestLineReducer;
  terminateOBMLine: (body: TerminateOutboundManifestLineActionBody) => void;
  terminateOBMLineReset: () => void;
}

const OutboundManifestLine = ({
  index,
  organisationId,
  warehouseId,
  outboundManifestId,
  outboundManifestLine,
  refetchOutboundManifest,
  terminateOBMLineState,
  terminateOBMLine,
  terminateOBMLineReset,
}: OutboundManifestLineProps) => {
  const [obmLineToFulfil, setOBMLineToFulfil] = useState<number | null>(null);
  const [fulfilmentLineToReturn, setFulfilmentLineToReturn] = useState<number | null>(null);

  const terminateManifestLineDialog = (body: TerminateOutboundManifestLineActionBody) => {
    const message = mapManifestLineStatusToMessage(body.type);

    confirmAlert({
      title: "Confirm Change to Manifest Line",
      message: `Are you sure you want to mark this manifest as ${message}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => terminateOBMLine(body),
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  useEffect(() => {
    if (terminateOBMLineState.success) {
      refetchOutboundManifest();
      terminateOBMLineReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminateOBMLineState.success, terminateOBMLineState.error]);

  return (
    <PrimaryLineWrapper>
      <FlexTitle>
        <Title>Manifest Line #{index + 1}</Title>
        <StatusBadge
          type="dot"
          statusName={
            outboundManifestLine.outbound_manifest_line_status.outbound_manifest_line_status_name
          }
        />
      </FlexTitle>

      <FlexHolder>
        {outboundManifestLine.product?.fsc_certified && (
          <CertificationBadge type="FSC"> FSC Certified </CertificationBadge>
        )}
        {outboundManifestLine.product?.grs_certified && (
          <CertificationBadge type="GRS"> GRS Certified </CertificationBadge>
        )}
      </FlexHolder>

      <PrimaryLineDataWrapper>
        <DisplayBox
          withUniqueMargin
          label={"Product"}
          value={outboundManifestLine.product.product_name}
        />
        <DisplayBox withUniqueMargin label={"Quantity"} value={outboundManifestLine.quantity} />
        <DisplayBox
          withUniqueMargin
          label={"Dispatched Quantity"}
          value={outboundManifestLine.dispatched_quantity}
        />
      </PrimaryLineDataWrapper>

      {outboundManifestLine.outbound_manifest_fulfilment_lines.map((ffline, index) => (
        <SecondaryLineWrapper type={"withIndex"} key={`obm_fuflfilment_line_${index}`}>
          <IndexPill index={index + 1} />
          <Title>Fulfilment Line #{index + 1}</Title>

          <SecondaryLineDataWrapper>
            <DisplayBox label={"Fulfilment Line Product"} value={ffline.product.product_name} />
            <DisplayBox label={"Fulfilment Line Quantity"} value={ffline.quantity} />
          </SecondaryLineDataWrapper>

          {ffline.id !== fulfilmentLineToReturn && (
            <ButtonGroup
              className={WMSButtonGroup({
                type: "largeMargin",
                withinSecondaryLine: true,
              })}
            >
              <SecondaryButton
                appearance={"blueButton"}
                onClick={() => {
                  setFulfilmentLineToReturn(ffline.id);
                }}
              >
                Return
              </SecondaryButton>
            </ButtonGroup>
          )}

          {ffline.id === fulfilmentLineToReturn && (
            <ReturnOutboundManifestLine
              key={`return_line_${outboundManifestLine.id}_${ffline.id}`}
              lineId={outboundManifestLine.id}
              fulfilmentLineId={ffline.id}
              setFulfilmentLineToReturn={setFulfilmentLineToReturn}
              refetchOutboundManifest={refetchOutboundManifest}
            />
          )}

          {ffline.outbound_manifest_return_lines.length > 0 && (
            <>
              {ffline.outbound_manifest_return_lines.map((returnLine, index) => {
                return (
                  <>
                    <SecondaryLineWrapper type={"withIndex"}>
                      <IndexPill index={index + 1} />
                      <Title> Return Line {index + 1} </Title>

                      <DataPoint withUniqueMargin>
                        <Label>Returned Quantity</Label>
                        <Box>{returnLine.returned_quantity}</Box>
                      </DataPoint>
                    </SecondaryLineWrapper>
                  </>
                );
              })}
            </>
          )}
        </SecondaryLineWrapper>
      ))}

      {outboundManifestLine && outboundManifestLine.id === obmLineToFulfil && (
        <FulfilOutboundManifestLine
          organisation_id={organisationId}
          warehouse_id={warehouseId}
          outbound_manifest_id={outboundManifestId}
          outbound_manifest_line={outboundManifestLine}
          setOBMLineToFulfil={setOBMLineToFulfil}
          refetchOutboundManifest={refetchOutboundManifest}
        />
      )}

      {(outboundManifestLine.outbound_manifest_line_status.id ===
        OutboundManifestLineStatuses.NEW ||
        outboundManifestLine.outbound_manifest_line_status.id ===
          OutboundManifestLineStatuses.IN_PROGRESS) &&
        outboundManifestLine.id !== obmLineToFulfil && (
          <ButtonWrapper>
            <SecondaryButton
              appearance={"whiteButtonBlueText"}
              className={WMSButtonGroup({ type: "smallMargin" })}
              onClick={() => {
                setOBMLineToFulfil(outboundManifestLine.id);
              }}
            >
              <IconText text={"Add New Fulfillment Line"} primaryIcon={"alert-add-outline"} />
            </SecondaryButton>
          </ButtonWrapper>
        )}

      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
        <SecondaryButton
          appearance={"whiteButton"}
          disabled={
            outboundManifestLine.outbound_manifest_line_status.id !==
            OutboundManifestLineStatuses.NEW
          }
          onClick={() =>
            terminateManifestLineDialog({
              outbound_manifest_line_id: outboundManifestLine.id,
              type: CHANGE_OBM_LINE_STATUS.CANCEL,
              outbound_manifest_id: outboundManifestId,
            })
          }
        >
          Cancel
        </SecondaryButton>
        <SecondaryButton
          appearance={"whiteButton"}
          disabled={
            outboundManifestLine.outbound_manifest_line_status.id !==
            OutboundManifestLineStatuses.NEW
          }
          onClick={() =>
            terminateManifestLineDialog({
              outbound_manifest_line_id: outboundManifestLine.id,
              type: CHANGE_OBM_LINE_STATUS.REJECT,
              outbound_manifest_id: outboundManifestId,
            })
          }
        >
          Reject
        </SecondaryButton>

        <SecondaryButton
          appearance={
            outboundManifestLine.outbound_manifest_line_status.id !==
              OutboundManifestLineStatuses.NEW &&
            outboundManifestLine.outbound_manifest_line_status.id !==
              OutboundManifestLineStatuses.IN_PROGRESS
              ? "whiteButton"
              : "blueButton"
          }
          disabled={
            outboundManifestLine.outbound_manifest_line_status.id !==
              OutboundManifestLineStatuses.NEW &&
            outboundManifestLine.outbound_manifest_line_status.id !==
              OutboundManifestLineStatuses.IN_PROGRESS
          }
          onClick={() => {
            terminateManifestLineDialog({
              outbound_manifest_line_id: outboundManifestLine.id,
              type: CHANGE_OBM_LINE_STATUS.MARK_AS_DONE,
              outbound_manifest_id: outboundManifestId,
            });
          }}
        >
          Mark as Done
        </SecondaryButton>
      </ButtonGroup>
    </PrimaryLineWrapper>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    terminateOBMLineState: state.terminateOutboundManifestLineReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    terminateOBMLine: (body: TerminateOutboundManifestLineActionBody) =>
      dispatch(terminateOutboundManifestLineAction(body)),
    terminateOBMLineReset: () => dispatch(terminateOutboundManifestLineReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(OutboundManifestLine);
