import { Formik, FormikErrors, FormikState, FormikTouched } from "formik";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { mapSupplierCurrenciestoDropdownObject } from "../../../suppliers/shared/mappers";
import { createSupplierQuoteRequestAPI } from "../api/createSupplierQuoteRequest";
import { CreateSupplierQuoteRequestBody } from "../api/types";
import { ProductLine } from "../components/ProductLine";
import {
  RequestForQuotationForm,
  emptyProductLine,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { useProducts } from "../graphql/hooks/useProducts";
import { useSupplierDetails } from "../graphql/hooks/useSupplierDetails";
import { useSuppliers } from "../graphql/hooks/useSuppliers";
import { getDefaultCurrency } from "../helpers/getDefaultCurrency";
import { mapQuoteLinesToDTO } from "../mappers";
import { ProductLineInterface } from "../types";
import { validationSchema } from "../validation";

function CreateRequestForQuotation() {
  const [isAddingProductLines, setIsAddingProductLines] = useState<boolean>(false);
  const [initialValues, setInitialValues] = useState<RequestForQuotationForm>(initialFieldValues);

  const [createSupplierQuoteRequestState, createSupplierQuoteRequest] = useAPI(
    createSupplierQuoteRequestAPI
  );
  const { roles } = useAuthorisationContext();
  const currencyCodes = mapSupplierCurrenciestoDropdownObject();

  const { products, productsLoading, productsError } = useProducts();
  const { suppliers, suppliersLoading, suppliersError } = useSuppliers();
  const { supplierDetails, supplierDetailsLoading, supplierDetailsError } = useSupplierDetails();

  const onSubmit = (
    values: RequestForQuotationForm,
    resetForm: (nextState?: Partial<FormikState<RequestForQuotationForm>> | undefined) => void
  ) => {
    if (!SOURCING_EDIT_ROLES.some(role => roles.includes(role))) {
      toast.error("Incorrect approval permissions");
      return;
    }

    const supplierQuoteRequestLines = mapQuoteLinesToDTO(
      values.product_lines,
      values.currency!.label!
    );

    const primaryContactId = supplierDetails.filter(details => {
      return details.id === values.supplier!.value;
    })[0].supplier_details[0].primary_contact_id;

    if (!primaryContactId) {
      toast.error("Missing Supplier Primary contact id");
      return;
    }

    const createRFQBody: CreateSupplierQuoteRequestBody = {
      supplierOrganisationId: values.supplier!.value!,
      supplierResponseContactId: primaryContactId,
      currencyCode: values.currency!.label!,
      clientName: values.client_name || "",
      supplierQuoteRequestLines: supplierQuoteRequestLines,
    };

    createSupplierQuoteRequest(createRFQBody);
    resetForm();
    setIsAddingProductLines(false);
    setInitialValues(initialFieldValues);
  };

  useEffect(() => {
    if (createSupplierQuoteRequestState === API_REQUEST_STATE.SUCCESS) {
      setInitialValues({ ...initialFieldValues });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSupplierQuoteRequestState]);

  const error = productsError || suppliersError || supplierDetailsError;

  const isLoading = productsLoading || suppliersLoading || supplierDetailsLoading;

  return (
    <Page isLoading={isLoading} error={error} title={"Request For Quotation"}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        enableReinitialize
        onSubmit={(values, { resetForm }) => {
          onSubmit(values, resetForm);
        }}
      >
        {({ values, setFieldValue, handleSubmit, errors, touched, handleReset }) => {
          return (
            <>
              <Panel
                withWrapper
                title={"Quotation Inquiry Sheet"}
                subtitle={
                  "Complete this form to submit a request for quotation to a supplier for one or more products."
                }
              >
                <InnerPanelWrapper>
                  <StyledForm onSubmit={handleSubmit}>
                    <TopPanelInputsWrapper>
                      <DataPoint>
                        <SimpleSelect
                          isRequired
                          htmlFor={fieldNames.supplier}
                          options={suppliers}
                          name={fieldNames.supplier}
                          error={errors.supplier}
                          touched={touched.supplier}
                          label="Supplier"
                          value={values.supplier}
                          changeHandler={e => {
                            setFieldValue(fieldNames.supplier, e);
                            setFieldValue(fieldNames.currency, null);
                          }}
                          placeholder="Select Supplier"
                        />
                      </DataPoint>

                      <DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            htmlFor={fieldNames.currency}
                            label="Currency"
                            name={fieldNames.currency}
                            error={errors.currency}
                            touched={touched.currency}
                            options={currencyCodes}
                            value={values.currency}
                            changeHandler={e => {
                              setFieldValue(fieldNames.currency, e);
                              if (e && values.supplier) {
                                const currencyCode = e.label;

                                const supplierCurrencyCode = getDefaultCurrency(
                                  supplierDetails.filter(
                                    details => details.id === values.supplier!.value
                                  )[0].supplier_details[0].locale
                                );

                                if (currencyCode !== supplierCurrencyCode) {
                                  toast.warn(
                                    `You've selected ${currencyCode}, while the default locale currency is ${supplierCurrencyCode}. It is okay to proceed if intentional, but do double check.`
                                  );
                                }
                              }
                            }}
                            placeholder="Select Currency"
                          />
                        </DataPoint>
                      </DataPoint>

                      <DataPoint>
                        <SimpleInput
                          type="text"
                          htmlFor={fieldNames.client_name}
                          label="Client Name"
                          name={fieldNames.client_name}
                          error={errors.client_name}
                          touched={touched.client_name}
                          value={values.client_name || ""}
                          changeHandler={e => setFieldValue(fieldNames.client_name, e.target.value)}
                          placeholder="Enter Client Name"
                        />
                      </DataPoint>
                    </TopPanelInputsWrapper>

                    {!isAddingProductLines && (
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          onClick={() => {
                            setInitialValues({
                              ...values,
                              product_lines: [{ ...emptyProductLine }],
                            });
                            setIsAddingProductLines(true);
                          }}
                          appearance={"whiteButtonBlueText"}
                        >
                          <IconText
                            text={"Add new product line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    )}
                  </StyledForm>
                </InnerPanelWrapper>
              </Panel>
              {isAddingProductLines && (
                <>
                  {values.product_lines.map((value, index) => {
                    const lineErrors =
                      errors.product_lines && errors.product_lines[index]
                        ? (errors.product_lines[index] as FormikErrors<ProductLineInterface>)
                        : undefined;
                    const lineTouched =
                      touched.product_lines && touched.product_lines[index]
                        ? (touched.product_lines[index] as FormikTouched<ProductLineInterface>)
                        : undefined;

                    return (
                      <ProductLine
                        key={index}
                        productsData={products}
                        values={value}
                        errors={lineErrors}
                        touched={lineTouched}
                        index={index}
                        setFieldValue={setFieldValue}
                        handleRemoveProductLine={(index: number) => {
                          const filteredProductLines = values.product_lines.filter(
                            (_, i) => i !== index
                          );
                          const newValues = { ...values };
                          newValues.product_lines = filteredProductLines;
                          if (filteredProductLines.length === 0) {
                            setInitialValues(newValues);
                            setIsAddingProductLines(false);
                          }
                          setInitialValues(newValues);
                        }}
                      />
                    );
                  })}
                  <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                    <SecondaryButton
                      type="button"
                      appearance={"whiteButtonBlueText"}
                      onClick={() => {
                        setInitialValues({
                          ...values,
                          product_lines: [...values.product_lines, { ...emptyProductLine }],
                        });
                      }}
                    >
                      <IconText text={"Add new product line"} primaryIcon={"alert-add-outline"} />
                    </SecondaryButton>
                  </ButtonWrapper>

                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      type="button"
                      appearance={"whiteButton"}
                      onClick={() => {
                        handleReset();
                        setInitialValues({ ...initialFieldValues });
                        setIsAddingProductLines(false);
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <SecondaryButton
                      appearance={"blueButton"}
                      type="button"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Confirm
                    </SecondaryButton>
                  </ButtonGroup>
                </>
              )}
            </>
          );
        }}
      </Formik>
    </Page>
  );
}

export default CreateRequestForQuotation;
