import * as Yup from "yup";

import { PRODUCT_TYPE_ID } from "../../../../../shared/constants";

export const linkSkuToOrgValidationSchema = Yup.object({
  orgIds: Yup.array()
    .of(Yup.object().shape({ label: Yup.string(), value: Yup.string() }))
    .nullable(),
  orgIdsToBeRemoved: Yup.array().of(Yup.string()),
});
export const updateSkuDataValidationSchema = Yup.object({
  imageURL: Yup.string().url().optional(),
  packSize: Yup.number().min(1, "Pack size must be above 0").required("Pack size is required"),
  productType: Yup.object()
    .shape({
      value: Yup.number(),
    })
    .optional(),
  leadTimeMin: Yup.number().when("productType", {
    is: (productType: any) => PRODUCT_TYPE_ID[productType.value] === "Dropship",
    then: Yup.number().required("Lead time: minimum is required"),
  }),
  leadTimeMax: Yup.number().when("productType", {
    is: (productType: any) => PRODUCT_TYPE_ID[productType.value] === "Dropship",
    then: Yup.number().required("Lead time: maximum is required"),
  }),
  leadTimeUnits: Yup.string().when("productType", {
    is: (productType: any) => PRODUCT_TYPE_ID[productType.value] === "Dropship",
    then: Yup.string().required("Lead time units is required"),
  }),
  businessDaysOnly: Yup.boolean().optional(),
});

export const addNewPriceValidationSchema = Yup.object({
  pricePerUnit: Yup.number().required("Price per unit is required"),
  currencyCode: Yup.string().required("Currency code is required"),
  minOrderQuantity: Yup.number().required("Minimum order quantity is required"),
  validFrom: Yup.date().required("Valid from date is required"),
});
