import * as Yup from "yup";

export const validationSchema = Yup.object({
  organisation: Yup.object()
    .shape({
      value: Yup.number().required("Organisation is required"),
    })
    .typeError("Organisation is required"),

  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("warehouse is required"),
    })
    .typeError("Warehouse is required"),

  expected_dispatch_date: Yup.string().required("Date is required"),

  //TODO CHANGE  THIS TO REQUIRED WHEN LINKING TO SOURCING

  /*  external_order_id: Yup.string().required("Order ID is required"),*/

  courier_service: Yup.object()
    .shape({
      value: Yup.number().required("Courier is required"),
    })
    .typeError("Courier is required"),

  logistics_type: Yup.object()
    .shape({
      value: Yup.number().required("Logistics Type is required"),
    })
    .typeError("Logistics Type is required"),

  manifest_lines: Yup.array().of(
    Yup.object({
      //TODO CHANGE  THIS TO REQUIRED WHEN LINKING TO SOURCING

      /* external_order_line_id: Yup.string().required("Order ID is required"),*/
      product: Yup.object()
        .shape({
          value: Yup.number().required("Product is required"),
        })
        .typeError("Product is required"),

      base_product: Yup.object()
        .shape({
          value: Yup.number().required("Base Product is required"),
        })
        .typeError("Base Product is required"),

      quantity: Yup.string().required("Quantity is required"),
    })
  ),
});
