import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { QuotationCostInterface } from "../../../updateQuotes/types";

export interface QuoteForm {
  quoteValidFrom: string;
  quoteValidTo: string;
  currencyCode: DropdownItem | null;
  moq: string;
  deliveryDate: string | null;
  leadTimeMaxDays: string;
  timeToHandoff: string;
  supplierReference: string | null;
  supplier: DropdownItem | null;
  quoteLines: QuotationCostInterface[];
  productSKU: DropdownItem | null;
  productName: DropdownItem | null;
  country: DropdownItem | null;
}

export const initialFieldValues: QuoteForm = {
  quoteValidFrom: "",
  quoteValidTo: "",
  currencyCode: null,
  supplier: null,
  quoteLines: [],
  productSKU: null,
  productName: null,
  moq: "",
  deliveryDate: "",
  leadTimeMaxDays: "",
  timeToHandoff: "",
  supplierReference: null,
  country: null,
};

export const fieldNames = {
  quoteValidFrom: "quoteValidFrom",
  quoteValidTo: "quoteValidTo",
  currencyCode: "currencyCode",
  supplier: "supplier",
  productSKU: "productSKU",
  productName: "productName",
  moq: "moq",
  deliveryDate: "deliveryDate",
  leadTimeMaxDays: "leadTimeMaxDays",
  timeToHandoff: "timeToHandoff",
  supplierReference: "supplierReference",
  country: "country",
  quoteLine: {
    costType: "costType",
    cost: "cost",
  },
};

export const emptyQuoteLine: QuotationCostInterface = { costType: null, cost: "" };
