import qs from "query-string";

import { WMS_API } from "../../../../../../api/baseConfig";
import { GetFilteredProductStockParams } from "./types";

export const getFilteredProductStockAPI = async (
  getFilteredProductStockAPIBody: GetFilteredProductStockParams
) => {
  try {
    return await WMS_API.get("/product-stocks/", {
      params: getFilteredProductStockAPIBody,
      paramsSerializer: param => {
        return qs.stringify(param);
      },
    });
  } catch (err) {
    console.log("Error in get filtered product stock  API call:", err);
    throw err;
  }
};
