import { QuotationCostAccountTypeItem, QuotationCostDistributionItem } from "../graphql/types";

export const mapQuotationCostTypeAccountTypes = (accountTypes: QuotationCostAccountTypeItem[]) => {
  return accountTypes.map(accountType => {
    return {
      label: accountType.quotation_cost_account_type_name,
      value: accountType.id,
    };
  });
};

export const mapQuotationCostTypeDistributions = (
  costDistributions: QuotationCostDistributionItem[]
) => {
  return costDistributions.map(costDistribution => {
    return {
      label: costDistribution.quotation_cost_distribution_name,
      value: costDistribution.id,
    };
  });
};
