import { WMS_API } from "../../../../../api/baseConfig";
import { CreateAddressBody } from "./types";

export const createAddressAPI = async (body: CreateAddressBody) => {
  try {
    return await WMS_API.post("/addresses", body);
  } catch (err) {
    console.log("Error in case change API call", err);
    throw err;
  }
};
