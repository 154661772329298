import { Formik, FormikState } from "formik";
import { useState } from "react";
import Select from "react-select";
import * as Yup from "yup";

import { ButtonGroup, InputField } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import {
  DataPoint,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { DropdownItem } from "../graphql/types";

/* TODO: Allow for more fulfilment types */
interface ClientOrderLineFulfilmentFormProps {
  setLineToFulfil: (val: number | null) => void;
  orderLineIndex: number;
  clientOrderLineFulfilmentTypes: DropdownItem[];
  lineToFulfil: number;
  productId: number;
  suggestedSupplierQuoteLineId?: number | null;
  handleFulfil: ({
    client_order_line_id,
    product_id,
    quantity,
    suggested_supplier_quote_line_id,
  }: {
    client_order_line_id: number;
    product_id: number;
    quantity: number;
    suggested_supplier_quote_line_id?: number | null;
  }) => void;
}
interface initialValues {
  fulfilmentType: {
    value: number;
    label: string;
  } | null;
  quantity: string;
}

const validationSchema = Yup.object({
  fulfilmentType: Yup.object()
    .shape({
      value: Yup.number().required("Fulfilment Type is required"),
    })
    .typeError("Fulfilment Type is required"),
  quantity: Yup.string().required("Quantity is required"),
});

const fieldNames = {
  quantity: "quantity",
  fulfilmentType: "fulfilmentType",
};

const ClientOrderLineFulfilmentForm = ({
  clientOrderLineFulfilmentTypes,
  setLineToFulfil,
  orderLineIndex,
  handleFulfil,
  lineToFulfil,
  productId,
  suggestedSupplierQuoteLineId,
}: ClientOrderLineFulfilmentFormProps) => {
  const initialValuesData: initialValues = {
    fulfilmentType: null,
    quantity: "",
  };

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [initialValues, setInitialValues] = useState<initialValues>(initialValuesData);

  //TODO: This case only works for creating pre-purchase orders as we are assuming the fulfilment via purchase order
  //For other types of fulfilment this onSubmit will need to be updated
  const onSubmit = (
    values: initialValues,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<initialValues>>) => void }
  ) => {
    handleFulfil({
      client_order_line_id: lineToFulfil,
      product_id: productId,
      quantity: Number(values.quantity),
      suggested_supplier_quote_line_id: suggestedSupplierQuoteLineId,
    });
  };

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      validationSchema={validationSchema}
      onSubmit={onSubmit}
    >
      {({ values, setFieldValue, handleReset, handleChange, handleSubmit, errors, touched }) => {
        return (
          <StyledForm>
            <SecondaryLineWrapper>
              <Title> Fulfil - Order Line {orderLineIndex + 1}</Title>

              <SecondaryLineDataWrapper>
                <DataPoint withUniqueMargin>
                  <Label isRequired htmlFor={fieldNames.fulfilmentType}>
                    Fulfilment Type
                  </Label>
                  <Select
                    styles={reactSelectStyling}
                    maxMenuHeight={220}
                    isSearchable={true}
                    options={clientOrderLineFulfilmentTypes}
                    id={fieldNames.fulfilmentType}
                    value={values.fulfilmentType}
                    onChange={e => {
                      setFieldValue(fieldNames.fulfilmentType, e);
                    }}
                    placeholder="Select Fulfilment Type"
                  />
                  {errors.fulfilmentType && touched.fulfilmentType ? (
                    <ErrorMessage>{errors.fulfilmentType}</ErrorMessage>
                  ) : null}
                </DataPoint>
                <DataPoint withUniqueMargin>
                  <Label isRequired>Quantity </Label>
                  <InputField
                    type="number"
                    size="medium"
                    value={values.quantity}
                    placeholder={"Input quantity"}
                    handleChange={e => setFieldValue(fieldNames.quantity, e.target.value)}
                  />
                  {errors.quantity && touched.quantity && (
                    <ErrorMessage>{errors.quantity}</ErrorMessage>
                  )}
                </DataPoint>
              </SecondaryLineDataWrapper>
              <ButtonGroup
                className={WMSButtonGroup({ type: "largeMargin", withinSecondaryLine: true })}
              >
                <SecondaryButton
                  onClick={() => {
                    handleReset();
                    setLineToFulfil(null);
                  }}
                  type="button"
                  appearance={"whiteButton"}
                >
                  Cancel
                </SecondaryButton>
                <SecondaryButton
                  onClick={() => handleSubmit()}
                  type="button"
                  appearance={"blueButton"}
                >
                  Fulfil
                </SecondaryButton>
              </ButtonGroup>
            </SecondaryLineWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default ClientOrderLineFulfilmentForm;
