import { AggregatedFinanceStockItem, FinanceStockItem } from "../types";

export const mapFinanceStockToCSV = (financeStock: FinanceStockItem[]) => {
  const aggregatedData: { [key: string]: AggregatedFinanceStockItem } = {};

  financeStock.forEach(data => {
    const base_product_id = data.base_product_id ?? 0;
    const base_product = data.base_product ?? "";
    const fsc_certified = data.fsc_certified ?? "";
    const grs_certified = data.grs_certified ?? "";
    const fsc_claim = data.fsc_claim ?? "";
    const warehouse = data.warehouse ?? "";
    const organisation = data.organisation ?? "";
    const current_quantity = data.current_quantity ?? 0;
    const inbound_quantity_on_date = data.inbound_quantity_on_date ?? 0;
    const outbound_quantity_on_date = data.outbound_quantity_on_date ?? 0;
    const total_adjustment_on_date = data.total_adjustment_on_date ?? 0;

    const key = `${data.base_product_id}_${data.warehouse}_${data.organisation}`;

    if (aggregatedData[key]) {
      aggregatedData[key]["Quantity (on 'To Date')"] =
        (aggregatedData[key]["Quantity (on 'To Date')"] || 0) + current_quantity;
      aggregatedData[key]["Inbound Quantity"] =
        (aggregatedData[key]["Inbound Quantity"] || 0) + inbound_quantity_on_date;
      aggregatedData[key]["Outbound Quantity"] =
        (aggregatedData[key]["Outbound Quantity"] || 0) + outbound_quantity_on_date;
      aggregatedData[key]["Other Quantity"] =
        (aggregatedData[key]["Other Quantity"] || 0) +
        total_adjustment_on_date -
        inbound_quantity_on_date -
        outbound_quantity_on_date;
      aggregatedData[key]["Total Change"] =
        (aggregatedData[key]["Total Change"] || 0) + total_adjustment_on_date;
    } else {
      aggregatedData[key] = {
        "Base Product Id": base_product_id,
        "Base Product": base_product,
        "FSC Cert": fsc_certified,
        "GRS Cert": grs_certified,
        "FSC Claim": fsc_claim,
        Warehouse: warehouse,
        Organisation: organisation,
        "Quantity (on 'To Date')": current_quantity,
        "Inbound Quantity": inbound_quantity_on_date,
        "Outbound Quantity": outbound_quantity_on_date,
        "Other Quantity":
          total_adjustment_on_date - inbound_quantity_on_date - outbound_quantity_on_date,
        "Total Change": total_adjustment_on_date,
      };
    }
  });

  const aggregatedDataArray = Object.values(aggregatedData);

  const filteredDataArray = aggregatedDataArray.filter(item => {
    const {
      "Quantity (on 'To Date')": quantityToDate,
      "Inbound Quantity": inboundQuantity,
      "Outbound Quantity": outboundQuantity,
      "Other Quantity": otherQuantity,
      "Total Change": totalChange,
    } = item;

    return (
      quantityToDate !== 0 ||
      inboundQuantity !== 0 ||
      outboundQuantity !== 0 ||
      otherQuantity !== 0 ||
      totalChange !== 0
    );
  });

  return filteredDataArray;
};
