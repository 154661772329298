import { ApolloError } from "@apollo/client";

import { useGetWorkOrdersWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { WorkOrderItem } from "../types";

export interface HookResult {
  workOrders: WorkOrderItem[];
  workOrdersError?: ApolloError;
  workOrdersLoading: boolean;
}

export const useWorkOrdersWithData = (): HookResult => {
  const { data, error, loading } = useGetWorkOrdersWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    workOrders: data && Array.isArray(data.work_order) ? data.work_order : [],
    workOrdersError: error,
    workOrdersLoading: loading,
  };
};
