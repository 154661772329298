import { ApolloError } from "@apollo/client";
import moment from "moment";

import { useGetStockInformationQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { StockInformationItem } from "../types";

export interface HookResult {
  stockInformation?: StockInformationItem;
  stockInformationError?: ApolloError;
  stockInformationLoading: boolean;
}

export const useStockInformation = (): HookResult => {
  const { data, error, loading } = useGetStockInformationQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  const conditions =
    data?.product_stock.map(stock => stock.stock_condition.stock_condition_name) || [];

  return {
    stockInformation: data
      ? {
          stockAvgAge: moment(
            data.product_stock
              .map(stock => stock.last_updated)
              .reduce((avgDate, currentDate) => avgDate + moment(currentDate), 0) /
              data.product_stock.length
          )
            .fromNow()
            .slice(0, -4),
          stockAvgCondition:
            conditions
              .sort(
                (a, b) =>
                  conditions.filter(v => v === a).length - conditions.filter(v => v === b).length
              )
              .pop() || "",
          stockQuantity: data?.product_stock_aggregate.aggregate?.sum?.quantity?.toString() || "",
        }
      : undefined,
    stockInformationError: error,
    stockInformationLoading: loading,
  };
};
