import { ApolloError } from "@apollo/client";

import { useGetInboundManifestDataByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { InboundManifestItem } from "../types";

export interface HookResult {
  manifest?: InboundManifestItem;
  manifestError?: ApolloError;
  manifestLoading: boolean;
  refetchInboundManifest: () => Promise<void>;
}

export const useInboundManifestById = (manifestId: number): HookResult => {
  const { data, error, loading, refetch } = useGetInboundManifestDataByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: { manifestId },
  });

  return {
    manifest: data && Array.isArray(data.inbound_manifest) ? data.inbound_manifest[0] : undefined,
    manifestError: error,
    manifestLoading: loading,
    refetchInboundManifest: async () => {
      refetch({ manifestId });
    },
  };
};
