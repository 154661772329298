import { WMS_API } from "../../../../../../api/baseConfig";
import { TerminateInboundManifestLineBody } from "../types";

export const completeInboundManifestLineAPI = async ({
  inbound_manifest_line_id,
  inbound_manifest_id,
}: TerminateInboundManifestLineBody) => {
  try {
    return await WMS_API.patch(`/inbound-manifest-lines/${inbound_manifest_line_id}/complete`, {
      inbound_manifest_id,
    });
  } catch (err) {
    console.log("Error in complete inbound manifest Line API call:", err);
    throw err;
  }
};
