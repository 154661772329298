import { ExtendedQuoteRequestLine, QuoteRequestLine } from "../../graphql/types";

export const multipleQuantityQuoteLines = (
  quoteLines: QuoteRequestLine[]
): ExtendedQuoteRequestLine[] => {
  const newQuoteLines: ExtendedQuoteRequestLine[] = [];
  quoteLines.forEach(line => {
    if (Array.isArray(line.minimum_order_quantity)) {
      line.minimum_order_quantity.forEach((quantity: number) => {
        newQuoteLines.push({
          ...line,
          index: newQuoteLines.length,
          minimum_order_quantity: quantity,
        });
      });
    }
  });
  return newQuoteLines;
};
