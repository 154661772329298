import { prettifyAddress } from "../../../../shared/mappers";
import { SupplierDataItem, SupplierItem } from "../types";

export const mapSuppliers = (supplierData: SupplierDataItem[]): SupplierItem[] => {
  return supplierData.map(supplier => ({
    locale: supplier.locale,
    purchaseOrdersCount: supplier.organisation.purchase_orders_aggregate.aggregate?.count || 0,
    organisationName: supplier.organisation.organisation_name,
    addresses: supplier.organisation.addresses.map(
      address => `${address.address_name}: ${prettifyAddress(address)}`
    ),
    contact: `${supplier.contact.contact_name} (${supplier.contact.contact_email_address})`,
    createdAt: supplier.created_at,
    xeroContactId: supplier.xero_contact_id,
  }));
};
