import { Formik } from "formik";
import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import Select from "react-select";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { assignMemberRoles } from "../api/assignMemberRoles";

export interface RoleOption {
  label: string;
  value: number;
}

export interface AddRoleForm {
  roles: RoleOption[];
}

export interface UsersResponse {
  name: string;
  email: string;
  uuid: string;
  organisations_users: {
    organisation: {
      uuid: string;
    };
  }[];
}

export const fieldObjects = {
  roles: {
    fieldName: "roles",
    fieldLabel: "roles",
    fieldPlaceholder: "Add roles",
    fieldBox: "Please select roles",
  },
};

export const initialFieldValues: AddRoleForm = {
  roles: [],
};

const AssignMemberRoles = () => {
  const { orgUuid, displayName, userUuid, userName } =
    useParams<{ orgUuid: string; displayName: string; userUuid: string; userName: string }>();
  const [initialValues] = useState<AddRoleForm>(initialFieldValues);
  const history = useHistory();

  const [addRolesState, addRoles] = useAPI(assignMemberRoles, false, {
    pending: "add users membership...",
    success: "users membership added successfully",
    error: "Error while trying to add membership",
  });

  // id the same across the env and the only options right now for external users
  const loadOptions = [
    {
      label: "client_admin",
      value: 2,
    },
    {
      label: "client_user",
      value: 21,
    },
    {
      label: "supplier_admin",
      value: 19,
    },
    {
      label: "supplier_user",
      value: 20,
    },
  ];

  const onSubmit = (values: AddRoleForm) => {
    addRoles({
      role_ids: values.roles.map(role => role.value),
      organisationUUID: orgUuid,
      userUUID: userUuid,
    });
  };

  useEffect(() => {
    if (addRolesState === API_REQUEST_STATE.SUCCESS) {
      history.push(
        `/admin/iam/organisations/${orgUuid}/${displayName}/member/${userUuid}/${userName}`
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addRolesState]);

  const isLoading = addRolesState === API_REQUEST_STATE.PENDING;
  const error = addRolesState === API_REQUEST_STATE.ERROR;

  return (
    <Page title={userName} isLoading={isLoading} error={error}>
      <Panel withWrapper title={"Add Roles"}>
        <InnerPanelWrapper>
          <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              validateForm,
              setTouched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <PrimaryLineWrapper>
                    <Title>Add roles</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin fullWidth>
                        <Label isRequired htmlFor={fieldObjects.roles.fieldName}>
                          {fieldObjects.roles.fieldLabel}
                        </Label>
                        <Select
                          styles={reactSelectStyling}
                          maxMenuHeight={220}
                          isSearchable={true}
                          options={loadOptions}
                          id={fieldObjects.roles.fieldName}
                          isMulti
                          value={values.roles}
                          onChange={e => {
                            setFieldValue(fieldObjects.roles.fieldName, e);
                          }}
                          placeholder={fieldObjects.roles.fieldPlaceholder}
                        />
                        {errors.roles && touched.roles ? (
                          <ErrorMessage>{JSON.stringify(errors.roles)}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>
                  <SecondaryButton
                    type="button"
                    appearance={"blueButton"}
                    onClick={() => handleSubmit()}
                  >
                    Confirm
                  </SecondaryButton>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default AssignMemberRoles;
