import * as Yup from "yup";

export const validationSchema = Yup.object({
  recipe_name: Yup.string().required("Recipe name is required"),
  recipe_type: Yup.object()
    .shape({
      value: Yup.number().required("Recipe type is required"),
    })
    .typeError("Recipe type is required"),
  inputLines: Yup.array()
    .of(
      Yup.object().shape({
        base_product: Yup.object()
          .shape({ value: Yup.number().required("Base Product is required") })
          .typeError("Base Product is required"),
        quantity: Yup.string().required("Quantity is required"),
      })
    )
    .min(1, "An Input Line is required"),
  outputLines: Yup.array()
    .of(
      Yup.object({
        base_product: Yup.object()
          .shape({ value: Yup.number().required("Base Product is required") })
          .typeError("Base Product is required"),
        quantity: Yup.string().required("Quantity is required"),
      })
    )
    .min(1, "An Output Line is required"),
});
