import { ApolloError } from "@apollo/client";

import { useGetAllCourierServiceNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  courierServiceNames: string[];
  courierServiceError?: ApolloError;
  courierServiceLoading: boolean;
  refetchCourierServiceNames: () => Promise<void>;
}

export const useAllCourierServiceNames = (): HookResult => {
  const { data, error, loading, refetch } = useGetAllCourierServiceNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    courierServiceNames:
      data && Array.isArray(data.courier_service)
        ? data.courier_service.map(courierService => courierService.courier_service_name)
        : [],
    courierServiceError: error,
    courierServiceLoading: loading,
    refetchCourierServiceNames: async () => {
      refetch();
    },
  };
};
