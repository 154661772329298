import {
  CarbonOffsetCost,
  CreateOrderForm,
  DEFAULT_PAYMENT_PLAN_TYPE,
  DEFAULT_PAYMENT_PLAN_TYPE_LABEL,
  DiscountCost,
  ProductCost,
  ProductOneOffCost,
  ShippingCost,
} from "../types";

export const initialFieldValues: CreateOrderForm = {
  client_reference: "",
  external_opportunity_id: "",
  currency_code: { value: 1, label: "GBP" },
  client_email_address: "",
  contact: {
    secondary_email: "",
    phone_number: "",
    tax_number: "",
    user_uuid: "",
    user_uuid_internal: "",
    org_id: "",
    org_name: "",
  },
  billing_address: {
    company_name: "",
    first_name: "",
    second_name: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    city: "",
    region_or_county_or_state: "",
    postcode: "",
    country_name: { value: 1, label: "GB" },
    phone_number: "",
  },
  shipping_address: {
    company_name: "",
    first_name: "",
    second_name: "",
    address_line_1: "",
    address_line_2: "",
    address_line_3: "",
    city: "",
    region_or_county_or_state: "",
    postcode: "",
    country_name: { value: 1, label: "GB" },
    phone_number: "",
    custom_delivery_instruction: "",
  },
  sourceful_entity_uk: {
    vat_exempt: { value: 2, label: "NO" },
    outside_uk: { value: 2, label: "NO" },
  },
  sourceful_entity_us: {
    vat_exempt: { value: 2, label: "NO" },
    threshold: 0,
  },
  sourceful_entity_eu: {
    tax_type: { value: 1, label: "NLNL" },
  },
  sourceful_entity_cn: {
    client_cn_entity: {
      value: 2,
      label: "NO",
    },
    client_3rd_party_cn_entity: {
      value: 2,
      label: "NO",
    },
    client_3rd_party_cn_entity_agreement: {
      value: 2,
      label: "NO",
    },
  },
  payment_term: {
    has_payment_term: { value: 2, label: "NO" },
    due_date: "",
    payment_term_description: "",
    suppress_client_email: { value: 2, label: "NO" },
    payment_plan_type: {
      value: DEFAULT_PAYMENT_PLAN_TYPE,
      label: "Default (Pay later w/ automatic approval)",
    },
  },
  client_promised_delivery_date: {
    from_date: "",
    to_date: "",
  },
  product_costs: [],
  product_one_off_costs: [],
  shipping_costs: [],
  carbon_offset_costs: [],
  discount_costs: [],
  final_total: {
    subtotal: 0,
    vat: 0,
    discount: 0,
    total: 0,
  },
};

export const initialFieldValuesProductCost: ProductCost = {
  sku_id: "",
  cost_description: "",
  qty: 0,
  unit_cost: 0,
  min_days: 0,
  max_days: 0,
  vat: { value: 1, label: "YES" },
  total: 0,
  total_with_vat: 0,

  base_product_id: 0,
  base_product_name: "",
  base_product_version: 0,
  content_attributes: {},
  artworks: {},
};

export const initialFieldValuesProductOneOffCost: ProductOneOffCost = {
  sku_id: "",
  cost_description: "",
  qty: 0,
  unit_cost: 0,
  vat: { value: 1, label: "YES" },
  total: 0,
  total_with_vat: 0,
};

export const initialFieldValuesShippingCost: ShippingCost = {
  cost_description: "",
  amount: 0,
  min_days: 0,
  max_days: 0,
  vat: { value: 1, label: "YES" },
  total: 0,
  total_with_vat: 0,
  shipping_option: { value: 1, label: "Sea" },
};

export const initialFieldValuesDiscountCost: DiscountCost = {
  cost_description: "",
  amount: 0,
  vat: {
    value: 1,
    label: "YES",
  },
  total: 0,
  total_with_vat: 0,
  discount_option: {
    value: 1,
    label: "One Off",
  },
};

export const initialFieldValuesCarbonOffsetCost: CarbonOffsetCost = {
  kg: 0,
  price_per_kg: 0.0589,
  vat: { value: 1, label: "YES" },
  total: 0,
  total_with_vat: 0,
};

export const currencyCodeOptions = [
  {
    value: 1,
    label: "GBP",
  },
  {
    value: 2,
    label: "EUR",
  },
  {
    value: 3,
    label: "USD",
  },
  {
    value: 4,
    label: "CNY",
  },
];

export const countryCodeOptions = [
  {
    value: 1,
    label: "GB", // United Kingdom
  },
  {
    value: 2,
    label: "US", // United States (Not European but was in your initial list)
  },
  {
    value: 3,
    label: "CN", // China
  },
  {
    value: 48,
    label: "AL", // Albania
  },
  {
    value: 4,
    label: "AD", // Andorra
  },
  {
    value: 5,
    label: "AT", // Austria
  },
  {
    value: 6,
    label: "BY", // Belarus
  },
  {
    value: 7,
    label: "BE", // Belgium
  },
  {
    value: 8,
    label: "BA", // Bosnia and Herzegovina
  },
  {
    value: 9,
    label: "BG", // Bulgaria
  },
  {
    value: 10,
    label: "HR", // Croatia
  },
  {
    value: 11,
    label: "CY", // Cyprus
  },
  {
    value: 12,
    label: "CZ", // Czech Republic
  },
  {
    value: 13,
    label: "DK", // Denmark
  },
  {
    value: 14,
    label: "EE", // Estonia
  },
  {
    value: 15,
    label: "FI", // Finland
  },
  {
    value: 16,
    label: "FR", // France
  },
  {
    value: 17,
    label: "DE", // Germany
  },
  {
    value: 18,
    label: "GR", // Greece
  },
  {
    value: 19,
    label: "HU", // Hungary
  },
  {
    value: 20,
    label: "IS", // Iceland
  },
  {
    value: 21,
    label: "IE", // Ireland
  },
  {
    value: 22,
    label: "IT", // Italy
  },
  {
    value: 23,
    label: "XK", // Kosovo
  },
  {
    value: 24,
    label: "LV", // Latvia
  },
  {
    value: 25,
    label: "LI", // Liechtenstein
  },
  {
    value: 26,
    label: "LT", // Lithuania
  },
  {
    value: 27,
    label: "LU", // Luxembourg
  },
  {
    value: 28,
    label: "MK", // North Macedonia
  },
  {
    value: 29,
    label: "MT", // Malta
  },
  {
    value: 30,
    label: "MD", // Moldova
  },
  {
    value: 31,
    label: "MC", // Monaco
  },
  {
    value: 32,
    label: "ME", // Montenegro
  },
  {
    value: 33,
    label: "NL", // Netherlands
  },
  {
    value: 34,
    label: "NO", // Norway
  },
  {
    value: 35,
    label: "PL", // Poland
  },
  {
    value: 36,
    label: "PT", // Portugal
  },
  {
    value: 37,
    label: "RO", // Romania
  },
  {
    value: 38,
    label: "RU", // Russia
  },
  {
    value: 39,
    label: "SM", // San Marino
  },
  {
    value: 40,
    label: "RS", // Serbia
  },
  {
    value: 41,
    label: "SK", // Slovakia
  },
  {
    value: 42,
    label: "SI", // Slovenia
  },
  {
    value: 43,
    label: "ES", // Spain
  },
  {
    value: 44,
    label: "SE", // Sweden
  },
  {
    value: 45,
    label: "CH", // Switzerland
  },
  {
    value: 46,
    label: "UA", // Ukraine
  },
  {
    value: 47,
    label: "VA", // Vatican City
  },
];

export const YES_OPTION_INDEX = 0;
export const NO_OPTION_INDEX = 1;

export const boolOptions = [
  {
    value: 1,
    label: "YES",
  },
  {
    value: 2,
    label: "NO",
  },
];

export const eurTaxOptions = [
  {
    value: 1,
    label: "NLNL",
  },
  {
    value: 2,
    label: "NLEU",
  },
];

export const discountOptions = [
  {
    value: 1,
    label: "One Off",
  },
  {
    value: 2,
    label: "Special Offer",
  },
];

export const shippingTypeOptions = [
  {
    value: 1,
    label: "Sea",
  },
  {
    value: 2,
    label: "Air",
  },
  {
    value: 3,
    label: "Air-Courier",
  },
  {
    value: 4,
    label: "Domestic",
  },
  {
    value: 5,
    label: "Other",
  },
];

export const fieldObjects = {
  currency_code: {
    fieldName: "currency_code",
    fieldLabel: "Currency Code",
    fieldPlaceholder: "Select Currency Code",
  },
  clientReference: {
    fieldName: "client_reference",
    fieldLabel: "Client Reference (Optional)",
    fieldPlaceholder: "Enter Client Reference",
  },
  externalOpportunityId: {
    fieldName: "external_opportunity_id",
    fieldLabel: "SalesForce Opportunity ID (Optional)",
    fieldPlaceholder: "Enter SalesForce Opportunity ID",
  },
  clientEmailAddress: {
    fieldName: "client_email_address",
    fieldLabel: "Client Email Address",
    fieldPlaceholder: "Enter Client Email Address",
  },
  contact: {
    secondary_email: {
      fieldName: "contact.secondary_email",
      fieldLabel: "Secondary Email (Optional)",
      fieldPlaceholder: "Enter Secondary Email (Optional)",
    },
    phone_number: {
      fieldName: "contact.phone_number",
      fieldLabel: "Phone Number",
      fieldPlaceholder: "Enter Phone Number",
    },
    tax_number: {
      fieldName: "contact.tax_number",
      fieldLabel: "Tax Number (Optional)",
      fieldPlaceholder: "Enter Tax Number (Optional)",
    },
    user_uuid: {
      fieldName: "contact.user_uuid",
      fieldLabel: "User UUID",
      fieldPlaceholder: "Enter User UUID",
    },
    user_uuid_internal: {
      fieldName: "contact.user_uuid_internal",
      fieldLabel: "User UUID Internal",
      fieldPlaceholder: "Enter User UUID Internal",
    },
    org_id: {
      fieldName: "contact.org_id",
      fieldLabel: "Org ID",
      fieldPlaceholder: "Enter Org ID",
    },
    org_name: {
      fieldName: "contact.org_name",
      fieldLabel: "Org Name",
      fieldPlaceholder: "Enter Org Name",
    },
  },
  billing_address: {
    company_name: {
      fieldName: "billing_address.company_name",
      fieldLabel: "Company Name",
      fieldPlaceholder: "Enter Company Name",
    },
    first_name: {
      fieldName: "billing_address.first_name",
      fieldLabel: "First Name",
      fieldPlaceholder: "Enter First Name",
    },
    second_name: {
      fieldName: "billing_address.second_name",
      fieldLabel: "Second Name",
      fieldPlaceholder: "Enter Second Name",
    },
    address_line_1: {
      fieldName: "billing_address.address_line_1",
      fieldLabel: "Address Line 1",
      fieldPlaceholder: "Enter Address Line 1 Here",
    },
    address_line_2: {
      fieldName: "billing_address.address_line_2",
      fieldLabel: "Address Line 2",
      fieldPlaceholder: "Enter Address Line 2 Here",
    },
    address_line_3: {
      fieldName: "billing_address.address_line_3",
      fieldLabel: "Address Line 3",
      fieldPlaceholder: "Enter Address Line 3 Here",
    },
    city: {
      fieldName: "billing_address.city",
      fieldLabel: "City",
      fieldPlaceholder: "Enter City Here",
    },
    region_or_county_or_state: {
      fieldName: "billing_address.region_or_county_or_state",
      fieldLabel: "Region/County/State",
      fieldPlaceholder: "Enter Region/County/State Here",
    },
    postcode: {
      fieldName: "billing_address.postcode",
      fieldLabel: "Postcode",
      fieldPlaceholder: "Enter Postcode Here",
    },
    country_name: {
      fieldName: "billing_address.country_name",
      fieldLabel: "Country",
      fieldPlaceholder: "Select Country Here",
    },
  },
  shipping_address: {
    company_name: {
      fieldName: "shipping_address.company_name",
      fieldLabel: "Company Name",
      fieldPlaceholder:
        "Company that we are shipping to (does not have to be the client company name)",
    },
    first_name: {
      fieldName: "shipping_address.first_name",
      fieldLabel: "First Name",
      fieldPlaceholder: "Enter First Name",
    },
    second_name: {
      fieldName: "shipping_address.second_name",
      fieldLabel: "Second Name",
      fieldPlaceholder: "Enter Second Name",
    },
    address_line_1: {
      fieldName: "shipping_address.address_line_1",
      fieldLabel: "Address Line 1",
      fieldPlaceholder: "Enter Address Line 1 Here",
    },
    address_line_2: {
      fieldName: "shipping_address.address_line_2",
      fieldLabel: "Address Line 2",
      fieldPlaceholder: "Enter Address Line 2 Here",
    },
    address_line_3: {
      fieldName: "shipping_address.address_line_3",
      fieldLabel: "Address Line 3",
      fieldPlaceholder: "Enter Address Line 3 Here",
    },
    city: {
      fieldName: "shipping_address.city",
      fieldLabel: "City",
      fieldPlaceholder: "Enter City Here",
    },
    region_or_county_or_state: {
      fieldName: "shipping_address.region_or_county_or_state",
      fieldLabel: "Region/County/State",
      fieldPlaceholder: "Enter Region/County/State Here",
    },
    postcode: {
      fieldName: "shipping_address.postcode",
      fieldLabel: "Postcode",
      fieldPlaceholder: "Enter Postcode Here",
    },
    country_name: {
      fieldName: "shipping_address.country_name",
      fieldLabel: "Country",
      fieldPlaceholder: "Select Country Here",
    },
    custom_delivery_instruction: {
      fieldName: "shipping_address.custom_delivery_instruction",
      fieldLabel: "Custom Delivery Instruction",
      fieldPlaceholder: "Enter Custom Delivery Instruction Here",
    },
  },
  sourceful_entity_uk: {
    vat_exempt: {
      fieldName: "sourceful_entity_uk.vat_exempt",
      fieldLabel: "VAT Exempt",
      fieldPlaceholder: "Select VAT Exempt",
    },
    outside_uk: {
      fieldName: "sourceful_entity_uk.outside_uk",
      fieldLabel: "Outside UK",
      fieldPlaceholder: "Select Outside UK",
    },
  },
  sourceful_entity_eu: {
    tax_type: {
      fieldName: "sourceful_entity_eu.tax_type",
      fieldLabel: "Tax Type",
      fieldPlaceholder: "Select Tax Type",
    },
  },
  sourceful_entity_us: {
    vat_exempt: {
      fieldName: "sourceful_entity_us.vat_exempt",
      fieldLabel: "VAT Exempt",
      fieldPlaceholder: "Select VAT Exempt",
    },
    threshold: {
      fieldName: "sourceful_entity_us.threshold",
      fieldLabel: "Threshold",
      fieldPlaceholder: "Enter Threshold Here",
    },
  },
  sourceful_entity_cn: {
    client_cn_entity: {
      fieldName: "sourceful_entity_cn.client_cn_entity",
      fieldLabel: "Does client have CN entity?",
      fieldPlaceholder: "Yes/No",
    },
    client_3rd_party_cn_entity: {
      fieldName: "sourceful_entity_cn.client_3rd_party_cn_entity",
      fieldLabel: "Does client have 3rd party CN entity?",
      fieldPlaceholder: "Yes/No",
    },
    client_3rd_party_cn_entity_agreement: {
      fieldName: "sourceful_entity_cn.client_3rd_party_cn_entity_agreement",
      fieldLabel: "Does the client's 3rd party agree to be billed?",
      fieldPlaceholder: "Yes/No",
    },
  },
  payment_term: {
    has_payment_term: {
      fieldName: "payment_term.has_payment_term",
      fieldLabel: "Has Payment Term",
      fieldPlaceholder: "Select Has Payment Term",
    },
    due_date: {
      fieldName: "payment_term.due_date",
      fieldLabel: "Payment Due Date",
      fieldPlaceholder: "Enter Payment Due Date Here",
    },
    payment_term_description: {
      fieldName: "payment_term.payment_term_description",
      fieldLabel: "Payment Term Description",
      fieldPlaceholder: "e.g. 30 days from invoice",
    },
    suppress_client_email: {
      fieldName: "payment_term.suppress_client_email",
      fieldLabel: "Suppress Client Email",
      fieldPlaceholder: "Select Suppress Client Email",
    },
    payment_plan_type: {
      fieldName: "payment_term.payment_plan_type",
      fieldLabel: "Payment Plan Type",
      fieldPlaceholder: DEFAULT_PAYMENT_PLAN_TYPE_LABEL,
    },
  },
  client_promised_delivery_date: {
    from_date: {
      fieldName: "client_promised_delivery_date.from_date",
      fieldLabel: "From Date",
      fieldPlaceholder: "Enter From Date Here",
    },
    to_date: {
      fieldName: "client_promised_delivery_date.to_date",
      fieldLabel: "To Date",
      fieldPlaceholder: "Enter To Date Here",
    },
  },
  product_costs: (idx: number) => {
    return {
      sku_id: {
        fieldName: `product_costs.${idx}.sku_id`,
        fieldLabel: "SKU ID",
        fieldPlaceholder: "Enter SKU ID",
      },
      cost_description: {
        fieldName: `product_costs.${idx}.cost_description`,
        fieldLabel: "Cost Description (auto filled)",
        fieldPlaceholder: "Cost Description (auto filled)",
      },
      qty: {
        fieldName: `product_costs.${idx}.qty`,
        fieldLabel: "Quantity",
        fieldPlaceholder: "Enter Quantity",
      },
      min_days: {
        fieldName: `product_costs.${idx}.min_days`,
        fieldLabel: "Min Days",
        fieldPlaceholder: "Enter Min Days",
      },
      max_days: {
        fieldName: `product_costs.${idx}.max_days`,
        fieldLabel: "Max Days",
        fieldPlaceholder: "Enter Max Days",
      },
      unit_cost: {
        fieldName: `product_costs.${idx}.unit_cost`,
        fieldLabel: "Unit Cost",
        fieldPlaceholder: "Enter Unit Cost",
      },
      vat: {
        fieldName: `product_costs.${idx}.vat`,
        fieldLabel: "VAT",
        fieldPlaceholder: "VAT",
      },
      total: {
        fieldName: `product_costs.${idx}.total`,
        fieldLabel: "Total",
        fieldPlaceholder: "Total",
      },
      total_with_vat: {
        fieldName: `product_costs.${idx}.total_with_vat`,
        fieldLabel: "Total with VAT",
        fieldPlaceholder: "Total with VAT",
      },

      // hidden fields
      base_product_id: {
        fieldName: `product_costs.${idx}.base_product_id`,
      },
      base_product_name: {
        fieldName: `product_costs.${idx}.base_product_name`,
      },
      base_product_version: {
        fieldName: `product_costs.${idx}.base_product_version`,
      },
      content_attributes: {
        fieldName: `product_costs.${idx}.content_attributes`,
      },
      artworks: {
        fieldName: `product_costs.${idx}.artworks`,
      },
    };
  },
  product_one_off_costs: (idx: number) => {
    return {
      sku_id: {
        fieldName: `product_one_off_costs.${idx}.sku_id`,
        fieldLabel: "SKU ID (Optional)",
        fieldPlaceholder: "SKU ID (Optional)",
      },
      cost_description: {
        fieldName: `product_one_off_costs.${idx}.cost_description`,
        fieldLabel: "Cost Description",
        fieldPlaceholder: "Enter Cost Description",
      },
      qty: {
        fieldName: `product_one_off_costs.${idx}.qty`,
        fieldLabel: "Quantity",
        fieldPlaceholder: "Enter Quantity",
      },
      unit_cost: {
        fieldName: `product_one_off_costs.${idx}.unit_cost`,
        fieldLabel: "Unit Cost",
        fieldPlaceholder: "Enter Unit Cost",
      },
      vat: {
        fieldName: `product_one_off_costs.${idx}.vat`,
        fieldLabel: "VAT",
        fieldPlaceholder: "VAT",
      },
      total: {
        fieldName: `product_one_off_costs.${idx}.total`,
        fieldLabel: "Total",
        fieldPlaceholder: "Total",
      },
      total_with_vat: {
        fieldName: `product_one_off_costs.${idx}.total_with_vat`,
        fieldLabel: "Total with VAT",
        fieldPlaceholder: "Total with VAT",
      },
    };
  },
  shipping_costs: (idx: number) => {
    return {
      cost_description: {
        fieldName: `shipping_costs.${idx}.cost_description`,
        fieldLabel: "Cost Description",
        fieldPlaceholder: "Cost Description",
      },
      shipping_option: {
        fieldName: `shipping_costs.${idx}.shipping_option`,
        fieldLabel: "Shipping Option",
        fieldPlaceholder: "Select Shipping Option",
      },
      min_days: {
        fieldName: `shipping_costs.${idx}.min_days`,
        fieldLabel: "Min Days",
        fieldPlaceholder: "Min Days",
      },
      max_days: {
        fieldName: `shipping_costs.${idx}.max_days`,
        fieldLabel: "Max Days",
        fieldPlaceholder: "Max Days",
      },
      amount: {
        fieldName: `shipping_costs.${idx}.amount`,
        fieldLabel: "Amount",
        fieldPlaceholder: "Amount",
      },
      vat: {
        fieldName: `shipping_costs.${idx}.vat`,
        fieldLabel: "VAT",
        fieldPlaceholder: "VAT",
      },
      total: {
        fieldName: `shipping_costs.${idx}.total`,
        fieldLabel: "Total",
        fieldPlaceholder: "Total",
      },
      total_with_vat: {
        fieldName: `shipping_costs.${idx}.total_with_vat`,
        fieldLabel: "Total with VAT",
        fieldPlaceholder: "Total with VAT",
      },
    };
  },
  carbon_offset_costs: (idx: number) => {
    return {
      cost_description: {
        fieldName: `carbon_offset_costs.${idx}.cost_description`,
        fieldLabel: "Cost Description",
        fieldPlaceholder: "Cost Description",
      },
      kg: {
        fieldName: `carbon_offset_costs.${idx}.kg`,
        fieldLabel: "Kg",
        fieldPlaceholder: "Kg",
      },
      price_per_kg: {
        fieldName: `carbon_offset_costs.${idx}.price_per_kg`,
        fieldLabel: "Price Per Kg",
        fieldPlaceholder: "Price Per Kg",
      },
      vat: {
        fieldName: `carbon_offset_costs.${idx}.vat`,
        fieldLabel: "VAT",
        fieldPlaceholder: "VAT",
      },
      total: {
        fieldName: `carbon_offset_costs.${idx}.total`,
        fieldLabel: "Total",
        fieldPlaceholder: "Total",
      },
      total_with_vat: {
        fieldName: `carbon_offset_costs.${idx}.total_with_vat`,
        fieldLabel: "Total with VAT",
        fieldPlaceholder: "Total with VAT",
      },
    };
  },
  discount_costs: (idx: number) => {
    return {
      cost_description: {
        fieldName: `discount_costs.${idx}.cost_description`,
        fieldLabel: "Cost Description",
        fieldPlaceholder: "Cost Description",
      },
      discount_option: {
        fieldName: `discount_costs.${idx}.discount_option`,
        fieldLabel: "Discount Option",
        fieldPlaceholder: "Select Discount Option",
      },
      amount: {
        fieldName: `discount_costs.${idx}.amount`,
        fieldLabel: "Amount",
        fieldPlaceholder: "Amount",
      },
      vat: {
        fieldName: `discount_costs.${idx}.vat`,
        fieldLabel: "VAT",
        fieldPlaceholder: "VAT",
      },
      total: {
        fieldName: `discount_costs.${idx}.total`,
        fieldLabel: "Total",
        fieldPlaceholder: "Total",
      },
      total_with_vat: {
        fieldName: `discount_costs.${idx}.total_with_vat`,
        fieldLabel: "Total with VAT",
        fieldPlaceholder: "Total with VAT",
      },
    };
  },
  final_total: {
    subtotal: {
      fieldName: "final_total.subtotal",
      fieldLabel: "Subtotal",
      fieldPlaceholder: "Subtotal",
    },
    discount: {
      fieldName: "final_total.discount",
      fieldLabel: "Discount",
      fieldPlaceholder: "Discount",
    },
    vat: {
      fieldName: "final_total.vat",
      fieldLabel: "VAT",
      fieldPlaceholder: "VAT",
    },
    total: {
      fieldName: "final_total.total",
      fieldLabel: "Total",
      fieldPlaceholder: "Total",
    },
  },
};
