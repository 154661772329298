import { Formik } from "formik";

import { ButtonGroup, CheckBox, IconText, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor/VisibleFor";
import { STOCK_MANAGEMENT_COUNTRY_CODES } from "../../../../../shared/constants";
import {
  DataPoint,
  FormattedLineDataWrapper,
  PrimaryLineDataWrapper,
  StyledForm,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { Address } from "../../shared/graphql/types";
import {
  UpdateAddressDataInterface,
  initialUpdateAddressValues,
  updateAddressFieldNames,
} from "../../shared/types";
import { FormattedContact } from "../page/UpdateStockManagementOrgansiation";
import { updateAddressValidationSchema } from "../validation";

interface Props {
  address: Address;
  onUpdateAddress: (values: UpdateAddressDataInterface, address: Address) => void;
  setShowUpdateAddressForm: (value: boolean) => void;
  filteredOrganisationContacts: FormattedContact[];
}

const UpdateStockManagementAddress = ({
  address,
  onUpdateAddress,
  setShowUpdateAddressForm,
  filteredOrganisationContacts,
}: Props): JSX.Element => {
  return (
    <Formik
      initialValues={initialUpdateAddressValues(address, filteredOrganisationContacts)}
      enableReinitialize
      onSubmit={(values: UpdateAddressDataInterface, { resetForm }) => {
        onUpdateAddress(values, address);
        resetForm();
        setShowUpdateAddressForm(false);
      }}
      validationSchema={updateAddressValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <PrimaryLineDataWrapper marginSize={"smallMargin"} alignStart>
              <FormattedLineDataWrapper>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.addressName}
                    name={updateAddressFieldNames.addressName}
                    size={"medium"}
                    error={errors.addressName}
                    touched={touched.addressName}
                    label={"Address name"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressName}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.addressName, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.addressLine1}
                    name={updateAddressFieldNames.addressLine1}
                    size={"medium"}
                    error={errors.addressLine1}
                    touched={touched.addressLine1}
                    label={"Address line 1"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressLine1}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.addressLine1, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.addressLine2}
                    name={updateAddressFieldNames.addressLine2}
                    size={"medium"}
                    error={errors.addressLine2}
                    touched={touched.addressLine2}
                    label={"Address line 2"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressLine2}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.addressLine2, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.addressLine3}
                    name={updateAddressFieldNames.addressLine3}
                    size={"medium"}
                    error={errors.addressLine3}
                    touched={touched.addressLine3}
                    label={"Address line 3"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressLine3}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.addressLine3, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.addressDescription}
                    name={updateAddressFieldNames.addressDescription}
                    size={"medium"}
                    error={errors.addressDescription}
                    touched={touched.addressDescription}
                    label={"Address description"}
                    placeholder={""}
                    type={"text"}
                    value={values.addressDescription}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.addressDescription, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.city}
                    name={updateAddressFieldNames.city}
                    size={"medium"}
                    error={errors.city}
                    touched={touched.city}
                    label={"City"}
                    placeholder={""}
                    type={"text"}
                    value={values.city}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.city, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <CheckBox
                    label={"This is a warehouse address"}
                    value={updateAddressFieldNames.isWarehouse}
                    id={updateAddressFieldNames.isWarehouse}
                    isChecked={values.isWarehouse ?? false}
                    handleChange={event => {
                      setFieldValue(updateAddressFieldNames.isWarehouse, event.target.checked);
                    }}
                  />
                </DataPoint>
              </FormattedLineDataWrapper>
              <FormattedLineDataWrapper>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.postcode}
                    name={updateAddressFieldNames.postcode}
                    size={"medium"}
                    error={errors.postcode}
                    touched={touched.postcode}
                    label={"Postcode"}
                    placeholder={""}
                    type={"text"}
                    value={values.postcode}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.postcode, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    htmlFor={updateAddressFieldNames.region}
                    name={updateAddressFieldNames.region}
                    size={"medium"}
                    error={errors.region}
                    touched={touched.region}
                    label={"Region or US State"}
                    placeholder={""}
                    type={"text"}
                    value={values.region}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.region, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleSelect
                    htmlFor={updateAddressFieldNames.countryCode}
                    name={updateAddressFieldNames.countryCode}
                    error={errors.countryCode}
                    touched={touched.countryCode}
                    placeholder="Select country code"
                    label="Country Code"
                    options={STOCK_MANAGEMENT_COUNTRY_CODES.map((countryCode, index) => ({
                      label: countryCode,
                      value: index,
                    }))}
                    value={values.countryCode}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.countryCode, event);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleSelect
                    isRequired
                    htmlFor={updateAddressFieldNames.primaryContactId}
                    name={updateAddressFieldNames.primaryContactId}
                    error={errors.primaryContactId}
                    touched={touched.primaryContactId}
                    placeholder="Select a primary contact"
                    label="Primary contact"
                    options={filteredOrganisationContacts.map(contact => ({
                      label: `${contact.firstName} ${contact.lastName}`,
                      value: contact.indexId,
                    }))}
                    value={values.primaryContactId}
                    changeHandler={event => {
                      setFieldValue(updateAddressFieldNames.primaryContactId, event);
                    }}
                  />
                </DataPoint>
              </FormattedLineDataWrapper>
            </PrimaryLineDataWrapper>
            <PrimaryLineDataWrapper marginSize={"smallMargin"} alignStart>
              <FormattedLineDataWrapper>
                <DataPoint>
                  <SimpleInput
                    tooltipMessage="number of days (or of the type of unit specified below)"
                    htmlFor={updateAddressFieldNames.deliveryTimelineMin}
                    name={updateAddressFieldNames.deliveryTimelineMin}
                    size={"medium"}
                    error={errors.deliveryTimelineMin}
                    touched={touched.deliveryTimelineMin}
                    label={"Delivery timeline: minimum"}
                    min={0}
                    placeholder={""}
                    type={"number"}
                    value={values.deliveryTimelineMin}
                    changeHandler={event => {
                      setFieldValue(
                        updateAddressFieldNames.deliveryTimelineMin,
                        event.target.value
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <SimpleInput
                    tooltipMessage="number of days (or of the type of unit specified below)"
                    htmlFor={updateAddressFieldNames.deliveryTimelineMax}
                    name={updateAddressFieldNames.deliveryTimelineMax}
                    size={"medium"}
                    error={errors.deliveryTimelineMax}
                    touched={touched.deliveryTimelineMax}
                    label={"Delivery timeline: maximum"}
                    min={values.deliveryTimelineMin || 0}
                    placeholder={"0"}
                    type={"number"}
                    value={values.deliveryTimelineMax}
                    changeHandler={event => {
                      setFieldValue(
                        updateAddressFieldNames.deliveryTimelineMax,
                        event.target.value
                      );
                    }}
                  />{" "}
                  <SimpleInput
                    tooltipMessage="days are currently supported"
                    htmlFor={updateAddressFieldNames.deliveryTimelineUnits}
                    name={updateAddressFieldNames.deliveryTimelineUnits}
                    size={"medium"}
                    error={errors.deliveryTimelineUnits}
                    touched={touched.deliveryTimelineUnits}
                    label={"Delivery timeline: units"}
                    placeholder={""}
                    type={"text"}
                    value={values.deliveryTimelineUnits}
                    changeHandler={event => {
                      setFieldValue(
                        updateAddressFieldNames.deliveryTimelineUnits,
                        event.target.value
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint></DataPoint>
                <DataPoint>
                  <CheckBox
                    label={"Delivery timeline includes business days only"}
                    value={updateAddressFieldNames.deliveryTimelineBusinessDaysOnly}
                    id={updateAddressFieldNames.deliveryTimelineBusinessDaysOnly}
                    isChecked={values.deliveryTimelineBusinessDaysOnly ?? false}
                    handleChange={event => {
                      setFieldValue(
                        updateAddressFieldNames.deliveryTimelineBusinessDaysOnly,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
              </FormattedLineDataWrapper>
            </PrimaryLineDataWrapper>
            <VisibleFor roles={SM_ADMIN_ROLES}>
              <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                <SecondaryButton
                  onClick={() => setShowUpdateAddressForm(false)}
                  appearance={"whiteButtonBlueText"}
                  isWithinTable
                  type="button"
                >
                  <IconText text={"Cancel"} primaryIcon={"alert-cross-outline"} />
                </SecondaryButton>
                <SecondaryButton appearance={"blueButton"} isWithinTable type="submit">
                  <IconText text={"Update Address"} primaryIcon={"toggle-checkmark-check"} />
                </SecondaryButton>
              </ButtonGroup>
            </VisibleFor>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default UpdateStockManagementAddress;
