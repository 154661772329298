import * as Yup from "yup";

export const validationSchema = Yup.object({
  base_product: Yup.object()
    .shape({
      value: Yup.number().required("Base Product is required"),
    })
    .typeError("Base Product is required"),
  organisation: Yup.object()
    .shape({
      value: Yup.number().required("Organisation is required"),
    })
    .typeError("Base Product is required"),
  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),
  condition: Yup.object()
    .shape({
      value: Yup.number().required("Condition is required"),
    })
    .typeError("Condition is required"),
  selected_product_stock_lines: Yup.array().of(
    Yup.object().shape({
      selected_quantity: Yup.string().required("Quantity is required"),
    })
  ),
});
