import { ApolloError } from "@apollo/client";

import { useGetStockAllocationsQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { StockAllocation } from "../types";

export interface HookResult {
  stockAllocations: StockAllocation[];
  stockAllocationsError?: ApolloError;
  stockAllocationsLoading: boolean;
}

export const useStockAllocations = (): HookResult => {
  const { data, error, loading } = useGetStockAllocationsQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    stockAllocations: data && Array.isArray(data.stock_allocation) ? data.stock_allocation : [],
    stockAllocationsError: error,
    stockAllocationsLoading: loading,
  };
};
