import moment from "moment";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { Icon } from "../../../../../components/icons/Icon";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import Page from "../../../../../shared/components/templates/Page/Page";
import { useSuppliers } from "../graphql/hooks/useSuppliers";
import { SupplierItem } from "../graphql/types";

export default function ViewSuppliers() {
  let history = useHistory();

  const { suppliers, suppliersLoading, suppliersError } = useSuppliers();

  const items = [
    {
      id: 1,
      handleClick: () => history.push("/suppliers/create-supplier"),
      type: "button",
      title: "Create Supplier",
      iconName: "alert-add-outline",
    },
    {
      id: 2,
      handleClick: () => history.push("/suppliers/create-locale-holiday"),
      type: "button",
      title: "Create Locale Holiday",
      iconName: "alert-add-outline",
    },
  ] as PanelProps["rightItems"];

  const columns = useMemo(
    () => [
      {
        Header: "Supplier",
        widthPercent: 2,
        accessor: (supplier: SupplierItem) => supplier.organisationName,
      },
      {
        Header: "Locale",
        widthPercent: 1,
        accessor: (supplier: SupplierItem) => supplier.locale,
      },
      {
        Header: "Contact",
        widthPercent: 3,
        accessor: (supplier: SupplierItem) => supplier.contact,
      },
      {
        Header: "Address",
        widthPercent: 5,
        accessor: (supplier: SupplierItem) => supplier.addresses,
      },
      {
        Header: "Orders",
        widthPercent: 1,
        accessor: (supplier: SupplierItem) => supplier.purchaseOrdersCount,
      },
      {
        Header: "Onboarded On",
        widthPercent: 1,
        accessor: (supplier: SupplierItem) => moment(supplier.createdAt).format("x"),
        Cell: ({ row }: CellProps<SupplierItem>) =>
          moment(row.original.createdAt).format("DD-MM-YYYY"),
      },
      {
        Header: "Update",
        widthPercent: 0.1,
        Cell: ({ row }: CellProps<SupplierItem>) =>
          row.original.xeroContactId ? (
            <Link to={`/suppliers/view/${row.original.xeroContactId}`}>
              <Icon name={"arrow-direction-right"} />
            </Link>
          ) : (
            <></>
          ),
      },
    ],
    []
  );

  return (
    <Page title={"Suppliers"} isLoading={suppliersLoading} error={suppliersError} hasBackButton>
      <Panel withWrapper title={"Suppliers"} allignTitle="left" rightItems={items}>
        <Table data={suppliers} columns={columns} />
      </Panel>
    </Page>
  );
}
