import { SOURCING_API } from "../../../../../api/baseConfig";

export const updateSupplierQCFilesAPI = async (supplierId: number, body: any) => {
  try {
    return await SOURCING_API.patch(`/suppliers/${supplierId}/files`, body);
  } catch (err) {
    console.log("Error in update supplier QC files API call", err);
    throw err;
  }
};
