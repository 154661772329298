export enum STOCK_CHECK_TYPE {
  FULL = 1,
  WEEKLY = 5,
  ADHOC = 2,
  LOCATION = 3,
  PRODUCT = 4,
}

export enum STOCK_STATUS {
  AVAILABLE = 0,
  RESERVED = 1,
  DUE_IN = 2,
  MISSING = 3,
  FOUND_UNEXPECTED = 4,
}

export enum STOCK_CHECK_LINE_STATUS {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}

export enum STOCK_CHECK_STATUS {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}
