import { ApolloError } from "@apollo/client";

import { useGetCaseChangeProductStockByParamsLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStock: ProductStockItem[];
  productStockError?: ApolloError;
  productStockLoading: boolean;
  getProductStock: (options: GetProductStockOptions) => Promise<void>;
}

export interface GetProductStockOptions {
  baseProductId: number | null;
  baseProductQuantity: number | null;
  warehouseId: number | null;
  stockConditionId: number | null;
  organisationId: number | null;
}

export const useProductStockByParams = (): HookResult => {
  const [getProductStock, { data, error, loading }] = useGetCaseChangeProductStockByParamsLazyQuery(
    {
      context: {
        clientName: GRAPHQL_WMS,
      },
    }
  );

  return {
    productStock: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    productStockError: error,
    productStockLoading: loading,
    getProductStock: async ({
      warehouseId,
      organisationId,
      baseProductId,
      stockConditionId,
      baseProductQuantity,
    }: GetProductStockOptions) => {
      getProductStock({
        variables: {
          warehouseId,
          baseProductId,
          stockConditionId,
          baseProductQuantity,
          organisationId,
        },
      });
    },
  };
};
