import { FormikErrors, FormikTouched } from "formik";

import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";

export interface SupplierConfigInterface {
  supplier: { label: string; value: number } | null;
  supplierConfigDay: number | undefined;
}

export interface SupplierDateLineProps {
  values: SupplierConfigInterface;
  suppliers: DropdownItem[];
  index: number;
  errors: FormikErrors<SupplierConfigInterface[]> | undefined;
  touched: FormikTouched<SupplierConfigInterface>[] | undefined;
  handleRemoveSupplierLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const initialSupplierConfig: SupplierConfigInterface = {
  supplier: null,
  supplierConfigDay: undefined,
};
