import { ApolloError } from "@apollo/client";

import { useGetInboundCaseProductsQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { CaseProductItem } from "../types";

export interface HookResult {
  caseProducts: CaseProductItem[];
  caseProductsError?: ApolloError;
  caseProductsLoading: boolean;
}

export const useInboundCaseProductsByBaseProductId = (baseProductId: number): HookResult => {
  const { data, error, loading } = useGetInboundCaseProductsQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      baseProductId,
    },
  });

  return {
    caseProducts: data && Array.isArray(data.product) ? data.product : [],
    caseProductsError: error,
    caseProductsLoading: loading,
  };
};
