import moment from "moment";

import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  Box,
  DataPoint,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { ExtendedProductStock } from "../api/getFilteredProductStock/types";

interface StockCheckLineProps {
  line: ExtendedProductStock;
  index: number;
}

const StockCheckLine = ({ line, index }: StockCheckLineProps) => {
  return (
    <SecondaryLineWrapper type="withIndex">
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <Label isGray={true}>Product Stock ID</Label>
          <Box>{line.id}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Product Name</Label>
          <Box>{line.product_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Expected Quantity</Label>
          <Box>{line.quantity}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Expected Condition</Label>
          <Box>{line.stock_condition_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Expected Status</Label>
          <Box>{line.stock_status_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Expected Location</Label>
          <Box>{line.location_name}</Box>
        </DataPoint>
        <DataPoint>
          <Label isGray={true}>Last Interaction With this Stock</Label>
          <Box>{moment(line.last_updated).format("DD/MM/YYYY")}</Box>
        </DataPoint>
      </SecondaryLineDataWrapper>
    </SecondaryLineWrapper>
  );
};

export default StockCheckLine;
