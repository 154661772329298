import { WMS_API } from "../../../../../api/baseConfig";
import { CaseChangeBody } from "./types";

export const caseChangeAPI = async (body: CaseChangeBody) => {
  try {
    return await WMS_API.post("/case-change", body);
  } catch (err) {
    console.log("Error in case change API call", err);
    throw err;
  }
};
