import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { useOrganisationsWithData } from "../graphql/hooks/useOrganisationsWithData";
import { OrganisationItem } from "../graphql/types";

export default function ViewOrganisations() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { organisations, organisationsLoading, organisationsError } = useOrganisationsWithData();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/organisation-management/add-organisation");
          },
          type: "button",
          title: "Add Organisation",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "Organisation Name",
        widthPercent: 0.4,
        accessor: (listItem: OrganisationItem) => listItem.organisation_name,
      },

      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<OrganisationItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page
      error={organisationsError}
      isLoading={organisationsLoading}
      title={"Inventory - Organisations"}
    >
      <Panel
        withWrapper
        title={"Organisations"}
        text={"Use the table below to view Sourceful's Organisations"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        rightItems={rightItems}
      >
        <Table data={organisations} columns={columns} />
      </Panel>
    </Page>
  );
}
