import { LOGISTICS_API } from "../../../../../api/baseConfig";

export const splitShipmentAPI = async (shipmentId: number) => {
  try {
    return await LOGISTICS_API.patch(`/shipments/${shipmentId}/split`);
  } catch (err) {
    console.log("Error in splitting shipment API call", err);
    throw err;
  }
};
