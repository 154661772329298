import { AdminSupplierDetailsInterface } from "../types";

export const initialFieldValues: AdminSupplierDetailsInterface = {
  supplier_organisation_name: "",
  external_organisation_id: "",
  contact_first_name: "",
  contact_last_name: "",
  contact_email_address: "",
  contact_phone_number: "",
  dispatch_hours: 0,
  uses_product_references: false,
  send_client_name_config: false,
  order_number_prefix: "",
  invoice_type: { value: 2, label: "Taxable" },
  locale: null,
  delivery_type: { value: 3, label: "Both client address and Sourceful warehouse" },
  address_line_1: "",
  address_line_2: "",
  address_line_3: "",
  city: "",
  postcode: "",
  country_code: null,
  delivery_date_delta: 0,
  qa_attachment_files: [],
};

export const fieldNames = {
  supplier_organisation_name: "supplier_organisation_name",
  external_organisation_id: "external_organisation_id",
  contact_first_name: "contact_first_name",
  contact_last_name: "contact_last_name",
  contact_email_address: "contact_email_address",
  contact_phone_number: "contact_phone_number",
  uses_product_references: "uses_product_references",
  send_client_name_config: "send_client_name_config",
  order_number_prefix: "order_number_prefix",
  invoice_type: "invoice_type",
  locale: "locale",
  delivery_type: "delivery_type",
  address_line_1: "address_line_1",
  address_line_2: "address_line_2",
  address_line_3: "address_line_3",
  city: "city",
  postcode: "postcode",
  country_code: "country_code",
  dispatch_hours: "dispatch_hours",
  delivery_date_delta: "delivery_date_delta",
};
