import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import {
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_FAILED,
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_LOADING,
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_RESET,
  MARK_AS_IN_TRANSIT_PURCHASE_ORDER_SUCCESS,
} from "../types/";

export interface MarkAsInTransitPurchaseOrderReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: MarkAsInTransitPurchaseOrderReducer = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function markAsInTransitPurchaseOrderReducer(
  state: MarkAsInTransitPurchaseOrderReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case MARK_AS_IN_TRANSIT_PURCHASE_ORDER_LOADING:
      return {
        ...state,
        loading: true,
      };
    case MARK_AS_IN_TRANSIT_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case MARK_AS_IN_TRANSIT_PURCHASE_ORDER_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case MARK_AS_IN_TRANSIT_PURCHASE_ORDER_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
