import { SOURCING_API } from "../../../../../api/baseConfig";
import { AttachInvoicesToPurchaseOrderBody } from "./types";

export const attachInvoicesToPurchaseOrderAPI = async (
  purchaseOrderId: number,
  body: AttachInvoicesToPurchaseOrderBody
) => {
  try {
    return await SOURCING_API.patch(`/purchase-orders/${purchaseOrderId}/attach-invoices`, body);
  } catch (err) {
    console.log("Error in attaching invoices to purchase order API call", err);
    throw err;
  }
};
