import * as Yup from "yup";

export const validationSchema = Yup.object({
  warehouse: Yup.string().required("Warehouse Name is required"),
  address: Yup.object()
    .shape({
      value: Yup.number().required("Address is required"),
    })
    .typeError("Address is required"),
  organisation: Yup.object()
    .shape({
      value: Yup.number().required("Organisation is required"),
    })
    .typeError("Organisation is required"),
});
