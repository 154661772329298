import React from "react";

import { styled } from "@sourceful/shared-components";

interface BaseProductStatusProps {
  isCurrentVersion: boolean;
}

export const BaseProductStatus: React.FC<BaseProductStatusProps> = ({ isCurrentVersion }) => {
  const BaseProductStatusContainer = styled("div", {
    backgroundColor: isCurrentVersion ? "#99FF66" : "#D3D3D3",
    paddingInline: "15px",
    borderRadius: "10px",
  });

  const BaseProductStatusText = styled("span", {
    marginInline: "10px",
  });

  return (
    <BaseProductStatusContainer>
      <BaseProductStatusText>{isCurrentVersion ? "Active" : "Inactive"}</BaseProductStatusText>
    </BaseProductStatusContainer>
  );
};
