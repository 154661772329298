import { useState } from "react";
import Select from "react-select";

import { ButtonGroup, Icon } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import TextArea from "../../../../../shared/components/forms/SimpleTextArea";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { ProductLineProps } from "../formValues";

export const QCInspectionLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveInspectionLine,
  errors,
  touched,
  inspectionTypeData,
}: ProductLineProps) => {
  const [selectedStatus, setSelectedStatus] = useState(values.qa_inspection_line_status);

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <Label htmlFor={`qa_inspection_type_${index}`}>Inspection Type</Label>
          <Select
            styles={reactSelectStyling}
            maxMenuHeight={220}
            isSearchable={true}
            id={`qa_inspection_type_${index}`}
            value={values.qa_inspection_type}
            options={inspectionTypeData}
            isDisabled={values.qa_inspection_line_state ? true : false}
            onChange={e => {
              setFieldValue(`inspection_lines[${index}].qa_inspection_type`, e);
            }}
            placeholder="Select Product"
          />
          {errors &&
          touched &&
          errors[index]?.qa_inspection_type &&
          touched[index]?.qa_inspection_type ? (
            <ErrorMessage>{errors[index]?.qa_inspection_type}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint>
          {!values.qa_inspection_line_state ? (
            <>
              <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                <SecondaryButton
                  appearance={selectedStatus === "passed" ? "blueButton" : "whiteButton"}
                  onClick={() => {
                    setSelectedStatus("passed");
                    setFieldValue(`inspection_lines[${index}].qa_inspection_line_status`, "passed");
                  }}
                >
                  Passed
                </SecondaryButton>
                <SecondaryButton
                  appearance={selectedStatus === "failed" ? "blueButton" : "whiteButton"}
                  onClick={() => {
                    setSelectedStatus("failed");
                    setFieldValue(`inspection_lines[${index}].qa_inspection_line_status`, "failed");
                  }}
                >
                  Failed
                </SecondaryButton>
              </ButtonGroup>
              {errors &&
              touched &&
              errors[index]?.qa_inspection_line_status &&
              touched[index]?.qa_inspection_line_status ? (
                <ErrorMessage>{errors[index]?.qa_inspection_line_status}</ErrorMessage>
              ) : null}
            </>
          ) : (
            <ButtonGroup className={WMSButtonGroup()}>
              {values.qa_inspection_line_status === "passed" && (
                <SecondaryButton
                  appearance={"blueButton"}
                  onClick={() => {
                    setSelectedStatus("passed");
                    setFieldValue(`inspection_lines[${index}].qa_inspection_line_status`, "passed");
                  }}
                >
                  Passed
                </SecondaryButton>
              )}
              {values.qa_inspection_line_status === "failed" && (
                <SecondaryButton
                  appearance={"blueButton"}
                  onClick={() => {
                    setSelectedStatus("failed");
                    setFieldValue(`inspection_lines[${index}].qa_inspection_line_status`, "failed");
                  }}
                >
                  Failed
                </SecondaryButton>
              )}
            </ButtonGroup>
          )}
        </DataPoint>
      </SecondaryLineDataWrapper>

      <SecondaryLineDataWrapper>
        <DataPoint fullWidth>
          <Label htmlFor={`qa_inspection_line_notes_${index}`}>{"Notes"}</Label>
          <TextArea
            id={`qa_inspection_line_notes_${index}`}
            size={"large"}
            type={"text"}
            isDisabled={values.qa_inspection_line_state ? true : false}
            placeholder={"Enter material and finishing"}
            value={values.qa_inspection_line_notes}
            handleChange={e => {
              setFieldValue(`inspection_lines[${index}].qa_inspection_line_notes`, e.target.value);
            }}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>

      {!values.qa_inspection_line_state && (
        <DataPoint fullWidth centeredText>
          <IconWrapper type="button" onClick={() => handleRemoveInspectionLine(index)}>
            <Icon width={30} height={30} name="navigation-trash" />
          </IconWrapper>
        </DataPoint>
      )}
    </SecondaryLineWrapper>
  );
};
