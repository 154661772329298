import * as Yup from "yup";

export const validationSchema = Yup.object({
  quantity: Yup.string().required("Quantity is required"),
  organisation: Yup.object()
    .shape({
      value: Yup.number().required("Organisation is required"),
    })
    .typeError("Organisation is required"),
  warehouse: Yup.object()
    .shape({
      value: Yup.number().required("Warehouse is required"),
    })
    .typeError("Warehouse is required"),
  recipe_name: Yup.object()
    .shape({
      value: Yup.number().required("Recipe Name is required"),
    })
    .typeError("Recipe Name is required"),
  due_date: Yup.string().required("Due Date is required"),
  work_order_and_recipe_inputs: Yup.array().of(
    Yup.object({
      work_order_inputs: Yup.array().of(
        Yup.object({
          product_stock: Yup.object()
            .shape({
              value: Yup.number().required("Product stock is required"),
            })
            .typeError("Product stock is required"),
          quantity: Yup.string().required("Quantity is required"),
        })
      ),
    })
  ),
});
