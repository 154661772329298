import * as qs from "query-string";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

import Panel from "../../../../../components/panel/panel";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  InnerPanelWrapper,
  PrimaryLineWrapper,
  Subtitle,
  Title,
} from "../../../../../styles/SharedStyles";

const ACCEPTED_ORDER = {
  pageTitle: "accept_page_title",
  wrapperTitle: "accept_wrapper_title",
  wrapperSubtitle: "accept_wrapper_subtitle",
};

const REJECTED_ORDER = {
  pageTitle: "reject_page_title",
  wrapperTitle: "reject_wrapper_title",
  wrapperSubtitle: "reject_wrapper_subtitle",
};

function StatusConfirmation() {
  const location = useLocation();
  const params = qs.parse(location.search);
  const { t } = useTranslation();

  const purchaseOrderId = params.purchaseOrderId?.toString() || "";
  const type = params.type;

  let translationKeys;
  if (type === "reject") {
    translationKeys = REJECTED_ORDER;
  }
  if (type === "accept") {
    translationKeys = ACCEPTED_ORDER;
  }

  return (
    <Page hasBackButton={false} title={t(translationKeys?.pageTitle || "")} isLoading={false}>
      <Panel title={purchaseOrderId} withWrapper={true}>
        <InnerPanelWrapper>
          <PrimaryLineWrapper>
            <Title style={{ fontSize: "22px" }}>{t(translationKeys?.wrapperTitle || "")}</Title>
            <Subtitle>
              {t(translationKeys?.wrapperSubtitle || "", { name: purchaseOrderId })}
            </Subtitle>
          </PrimaryLineWrapper>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

export default StatusConfirmation;
