import { ApolloError } from "@apollo/client";

import { useGetInboundManifestByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ManifestItem } from "../types";

export interface HookResult {
  manifest?: ManifestItem;
  manifestError?: ApolloError;
  manifestLoading: boolean;
}

export const useInboundManifestById = (manifestId: number): HookResult => {
  const { data, error, loading } = useGetInboundManifestByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: {
      manifestId,
    },
  });

  return {
    manifest: data && Array.isArray(data.inbound_manifest) ? data.inbound_manifest[0] : undefined,
    manifestError: error,
    manifestLoading: loading,
  };
};
