import { FormikErrors, FormikTouched } from "formik";

import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";

export interface AdminQuotationCostTypeTranslationInterface {
  locale: {
    value: number;
    label: string;
  } | null;
  quotation_cost_name: string;
  quotation_cost_description: string;
}

export interface TranslationLineProps {
  values: AdminQuotationCostTypeTranslationInterface;
  locales: DropdownItem[];
  index: number;
  errors: FormikErrors<AdminQuotationCostTypeTranslationInterface[]> | undefined;
  touched: FormikTouched<AdminQuotationCostTypeTranslationInterface>[] | undefined;
  handleRemoveTranslationLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const emptyQuotationCostTranslation: AdminQuotationCostTypeTranslationInterface = {
  locale: null,
  quotation_cost_name: "",
  quotation_cost_description: "",
};
