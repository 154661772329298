import { ApolloError } from "@apollo/client";

import { useGetFileCopiesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { FileCopyItem } from "../types";

export interface HookResult {
  fileCopies: FileCopyItem[];
  fileCopiesError?: ApolloError;
  fileCopiesLoading: boolean;
}

export const useFileCopies = (): HookResult => {
  const { data, error, loading } = useGetFileCopiesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    fileCopies: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    fileCopiesError: error,
    fileCopiesLoading: loading,
  };
};
