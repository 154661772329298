import { StageInForm } from "../types";

export interface CaseChangeForm {
  from_case: {
    value: number;
    label: string;
  } | null;
  to_case: {
    value: number;
    label: string;
  } | null;
  from_quantity: string;
  to_quantity: string;
  new_location: {
    value: number;
    label: string;
  } | null;
  case_change_method: string | null;
  to_case_base_product_quantity: number | null;
  organisation: {
    value: number;
    label: string;
  } | null;
  base_product: {
    value: number;
    label: string;
  } | null;
  warehouse: {
    value: number;
    label: string;
  } | null;
  condition: {
    value: number;
    label: string;
  } | null;
  selected_product_stock_lines: SelectedProductStockLinesInterface[];
}

export const fieldNames = {
  organisation: "organisation",
  base_product: "base_product",
  warehouse: "warehouse",
  location: "location",
  new_location: "new_location",
  condition: "condition",
  from_case: "from_case",
  to_case: "to_case",
  from_quantity: "from_quantity",
  to_quantity: "to_quantity",
};

export const initialCaseChangeFormData: CaseChangeForm = {
  from_case: null,
  to_case: null,
  from_quantity: "",
  to_quantity: "",
  new_location: null,
  case_change_method: null,
  to_case_base_product_quantity: null,
  organisation: null,
  warehouse: null,
  base_product: null,
  condition: {
    label: "Good",
    value: 0,
  },
  selected_product_stock_lines: [],
};

export interface SelectedProductStockLinesInterface {
  id: string;
  product_name: string;
  base_product_quantity: string;
  location: string;
  condition: string;
  quantity: string;
  total_base_product_quantity: string;
  selected_quantity: string;
}

export const initialStageInForm: StageInForm = {
  filteringComplete: false,
  caseSelectionConfirmed: false,
  productStockSelectionConfirmed: false,
};
