export const errorIncorrectPermissions = "Incorrect creation permissions";
export const errorDuplicateProduct =
  "You have used the same product in more than 1 purchase order line, please ensure a unique product is used per purchase order line ";
export const errorMissingSupplierContactId = `Cannot Obtain Xero Contact Id for this manufacturer`;
export const pageTitle = "Purchase Order";
export const panelTitle = "Create Purchase Order";
export const panelSubtitle = "Create A New Purchase Order";
export const errorMissingSupplierQuote =
  "Please generate and select a quote for each purchase order line";
export const errorDifferentleadTimes =
  "The selected quotes have a different lead time, which is not supported yet. Please make sure the lead times match or create separate purchase orders.";
