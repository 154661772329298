import { ApolloError } from "@apollo/client";

import { useGetQuotationCostDistributionsQuery } from "../../../../../../../generated/graphql_sourcing";
import { DropdownItem } from "../../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { mapQuotationCostTypeDistributions } from "../../mappers";

export interface HookResult {
  quotationCostDistributions: DropdownItem[];
  quotationCostDistributionsLoading: boolean;
  quotationCostDistributionsError?: ApolloError;
}

export const useQuotationCostDistributions = (): HookResult => {
  const { data, loading, error } = useGetQuotationCostDistributionsQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    quotationCostDistributions:
      (data && mapQuotationCostTypeDistributions(data.quotation_cost_distribution)) || [],
    quotationCostDistributionsLoading: loading,
    quotationCostDistributionsError: error,
  };
};
