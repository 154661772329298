import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { styled } from "@sourceful/shared-components";

import Panel, { RightItemButton } from "../../../../components/panel/panel";
import Page from "../../../../shared/components/templates/Page/Page";
import { API_REQUEST_STATE, useAPI } from "../../../../util/api/apiHook";
import { defaultImageUrl } from "../../shared/constants";
import { BaseProductVersion } from "../../shared/types";
import { getBaseProducts } from "../../viewBaseProducts/api/getBaseProducts";
import { BaseProduct } from "../../viewBaseProducts/api/types";
import { getBaseProductVersionsByBaseProduct } from "../api/getBaseProductVersionsByBaseProduct";
import BaseProductVersionRow from "../components/BaseProductVersionRow";

interface RouteParams {
  productId: string;
}

const newProductVersionButton: RightItemButton = {
  type: "button",
  id: 1,
  title: "New Product Version",
  iconName: "navigation-plus",
  handleClick: () => {
    console.log("New Product Version Button Clicked");
  },
};

const defaultProductName = "Name Not Set";
const defaultProductType = "Type Not Set";

const ProductContainer = styled("div", {
  display: "flex",
  flexDirection: "row",
  marginLeft: "20px",
  marginRight: "10px",
  marginBottom: "20px",
  alignItems: "left",
});

const ProductImageContainer = styled("div", {
  flex: 1,
  marginRight: "10px",
});

const ProductImage = styled("img", {
  borderRadius: "20px",
  width: "500px",
  height: "500px",
  display: "block",
  textAlign: "center",
});

const ProductVersionHistoryContainer = styled("div", {
  flex: 1,
  display: "flex",
  flexDirection: "column",
  gap: "10px",
  marginRight: "30px",
});

const ContainerProductVersionHistoryLabels = styled("div", {
  display: "flex",
  flexDirection: "row",
  gap: "10px",
  fontWeight: "bold",
});

const ViewBaseProductOverview = () => {
  const [getBaseProductVersionsState] = useAPI(getBaseProductVersionsByBaseProduct, true);
  const [getProductsState, getProducts] = useAPI(getBaseProducts, true);
  const [product, setProduct] = useState<BaseProduct>();

  const { productId } = useParams<RouteParams>();

  const [baseProductVersions, setBaseProductVersions] = useState<BaseProductVersion[]>([]);

  const fetchAndSetProducts = async (page = 1, id = productId, sort = "id") => {
    const getProductsResponse = await getProducts({
      page,
      page_size: 20,
      show_images: true,
      sort: `asc:${sort}`, // Use the passed sort option here
      id: id ? `eq:${id}` : undefined,
    });

    if (getProductsResponse === undefined) return;

    if (Array.isArray(getProductsResponse) && getProductsResponse.length > 0) {
      setProduct(getProductsResponse[0].data[0]);
    }

    if (!Array.isArray(getProductsResponse) && getProductsResponse.data.length > 0) {
      setProduct(getProductsResponse.data[0]);
    }
  };

  const getImgSrc = (product: BaseProduct | undefined) => {
    if (product?.image_url) {
      return product.image_url;
    }
    return defaultImageUrl;
  };

  const getName = (product: BaseProduct | undefined) => {
    if (product?.name) {
      return product.name;
    }
    return defaultProductName;
  };

  const getType = (product: BaseProduct | undefined) => {
    if (product?.type) {
      return product.type;
    }
    return defaultProductType;
  };

  useEffect(() => {
    fetchAndSetProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const fetchBaseProductVersions = async () => {
      const data = await getBaseProductVersionsByBaseProduct(productId);
      // sort data by version_id descending
      data.sort((a, b) => (a.version > b.version ? -1 : 1));
      setBaseProductVersions(data);
    };

    fetchBaseProductVersions();
  }, [productId]);

  useEffect(() => {
    if (getProductsState === API_REQUEST_STATE.ERROR) {
      toast.error("Error while trying to get base products");
    }
  }, [getProductsState]);

  const isLoading =
    API_REQUEST_STATE.PENDING === getBaseProductVersionsState ||
    API_REQUEST_STATE.PENDING === getProductsState;
  const error =
    API_REQUEST_STATE.ERROR === getBaseProductVersionsState ||
    API_REQUEST_STATE.ERROR === getProductsState;

  return (
    <Page title={"Base Products"} isLoading={isLoading} error={error}>
      <Panel
        withWrapper
        title={getName(product)}
        text={getType(product)}
        allignTitle="left"
        rightItems={[newProductVersionButton]}
      >
        <ProductContainer>
          <ProductImageContainer>
            <ProductImage src={getImgSrc(product)} alt="Product" />
          </ProductImageContainer>
          <ProductVersionHistoryContainer>
            <div>
              <h3>Product Version History</h3>
              <ContainerProductVersionHistoryLabels>
                <span>Version</span>
                <span style={{ flexGrow: 2, paddingLeft: "40%" }}>Description</span>
                <span>Actions</span>
              </ContainerProductVersionHistoryLabels>
            </div>
            {baseProductVersions.map(item => (
              <BaseProductVersionRow
                key={item.id}
                baseProductVersion={item}
                baseProductName={getName(product)}
              />
            ))}
          </ProductVersionHistoryContainer>
        </ProductContainer>
      </Panel>
    </Page>
  );
};

export default ViewBaseProductOverview;
