import { ApolloError } from "@apollo/client";

import { useGetSupplierQuotationCostTypesQuery } from "../../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { QuotationCostTypeItem } from "../types";

export interface HookResult {
  quotationCostTypes: QuotationCostTypeItem[];
  quotationCostTypesError?: ApolloError;
  quotationCostTypesLoading: boolean;
}

export const useSupplierQuotationCostTypes = (): HookResult => {
  const { data, error, loading } = useGetSupplierQuotationCostTypesQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    quotationCostTypes:
      data && Array.isArray(data.quotation_cost_type) ? data.quotation_cost_type : [],
    quotationCostTypesError: error,
    quotationCostTypesLoading: loading,
  };
};
