import { createRef, useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, Modal } from "@sourceful/shared-components";

import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { WMSButtonGroup } from "../../../../../styles/SharedStyles";
import {
  markAsDeliveredPurchaseOrderAction,
  markAsDeliveredPurchaseOrderReset,
} from "../redux/actions/markAsDeliveredPurchaseOrderAction";
import {
  markAsInTransitPurchaseOrderAction,
  markAsInTransitPurchaseOrderReset,
} from "../redux/actions/markAsInTransitPurchaseOrderAction";
import { LogisticsStatusUpdateProps } from "../types";
import DeliveryDateModal from "./DeliveryDateModal";

const LogisticsStatusUpdate = ({
  poStatus,
  refetchPurchaseOrder,
  markAsInTransitPurchaseOrderState,
  markAsDeliveredPurchaseOrderState,
  ...props
}: LogisticsStatusUpdateProps) => {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const modalRef = createRef<HTMLButtonElement>();
  const [deliveryDate, setDeliveryDate] = useState(new Date().toISOString().split("T")[0]);

  const handleModalClose = () => {
    setIsModalOpen(false);
  };
  useEffect(() => {
    if (markAsInTransitPurchaseOrderState.success) {
      refetchPurchaseOrder();
      props.resetMarkAsInTransitPurchaseOrder();
    }
    if (markAsInTransitPurchaseOrderState.error) {
      props.resetMarkAsInTransitPurchaseOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAsInTransitPurchaseOrderState]);

  useEffect(() => {
    if (markAsDeliveredPurchaseOrderState.success) {
      refetchPurchaseOrder();
      props.resetMarkAsDeliveredPurchaseOrder();
    }
    if (markAsDeliveredPurchaseOrderState.error) {
      props.resetMarkAsDeliveredPurchaseOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markAsDeliveredPurchaseOrderState]);

  const markAsInTransit = () => {
    props.markAsInTransitPurchaseOrder(Number(purchaseOrderId));
  };

  return (
    <VisibleFor roles={SOURCING_EDIT_ROLES}>
      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
        {poStatus === "Accepted" && (
          <SecondaryButton
            type="button"
            appearance={"blueButton"}
            onClick={() => {
              markAsInTransit();
            }}
          >
            Mark Purchase Order As In Transit
          </SecondaryButton>
        )}

        {poStatus === "In Transit" && (
          <SecondaryButton
            type="button"
            appearance={"blueButton"}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            Mark Purchase Order As Delivered
          </SecondaryButton>
        )}
        <Modal
          id={"CreateProdModal"}
          isOpen={isModalOpen}
          handleClose={handleModalClose}
          triggerRef={modalRef}
        >
          <DeliveryDateModal
            setIsModalOpen={setIsModalOpen}
            purchaseOrderId={Number(purchaseOrderId)}
            markAsDeliveredPurchaseOrder={props.markAsDeliveredPurchaseOrder}
            setDeliveryDate={setDeliveryDate}
            deliveryDate={deliveryDate}
          ></DeliveryDateModal>
        </Modal>
      </ButtonGroup>
    </VisibleFor>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    markAsInTransitPurchaseOrderState: state.markAsInTransitPurchaseOrderReducer,
    markAsDeliveredPurchaseOrderState: state.markAsDeliveredPurchaseOrderReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    markAsInTransitPurchaseOrder: (purchaseOrderId: number) =>
      dispatch(markAsInTransitPurchaseOrderAction(purchaseOrderId)),
    resetMarkAsDeliveredPurchaseOrder: () => dispatch(markAsDeliveredPurchaseOrderReset()),
    markAsDeliveredPurchaseOrder: (purchaseOrderId: number, deliveryDate: string) =>
      dispatch(markAsDeliveredPurchaseOrderAction(purchaseOrderId, deliveryDate)),
    resetMarkAsInTransitPurchaseOrder: () => dispatch(markAsInTransitPurchaseOrderReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(LogisticsStatusUpdate);
