import { ApolloError } from "@apollo/client";

import { useGetOrderExportLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapOrderExportToCSV } from "../mappers/mapOrderExportToCSV";

export interface HookResult {
  orderExport: any[];
  orderExportError?: ApolloError;
  orderExportLoading: boolean;
  getOrderExport: (options: Options) => Promise<void>;
}

export interface Options {
  supplier_country_code: string | string[];
  fromDate: string;
  toDate: string;
}

export const useOrderExport = (): HookResult => {
  const [getOrderExport, { data, error, loading }] = useGetOrderExportLazyQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    orderExport:
      data && Array.isArray(data.vw_order_export_report)
        ? mapOrderExportToCSV(data.vw_order_export_report)
        : [],
    orderExportError: error,
    orderExportLoading: loading,
    getOrderExport: async ({ supplier_country_code, fromDate, toDate }: Options) => {
      getOrderExport({ variables: { supplier_country_code, fromDate, toDate } });
    },
  };
};
