import { Formik } from "formik";
import moment from "moment";
import { toast } from "react-toastify";

import { ButtonGroup } from "@sourceful/shared-components";

import {
  DeliveredUpdateInterface,
  DeliveryDateUpdateInterface,
  DeliveryUpdateProps,
  deliveredFieldNames,
  deliveryDateFieldNames,
} from ".";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import Label from "../../../../../shared/components/atoms/labels/Label";
import SimpleDateInput from "../../../../../shared/components/forms/SimpleDateInput";
import SimpleTextArea from "../../../../../shared/components/forms/SimpleTextArea";
import Page from "../../../../../shared/components/templates/Page";
import { DataPoint, StyledForm, WMSButtonGroup } from "../../../../../styles/SharedStyles";
import { ShipmentContainer } from "../../../shipments/viewClientOrderShipments/components";
import {
  deliveredValidationSchema,
  deliveryDateValidationSchema,
} from "../../../shipments/viewClientOrderShipments/validation";

const DeliveryUpdateForm = ({
  handleDeliveredResponse,
  handleDeliveryDateResponse,
  deliveredValues,
  deliveryDateValues,
}: DeliveryUpdateProps) => {
  const handleDeliveredSubmitButton = async (deliveredValues: DeliveredUpdateInterface) => {
    const isFuture = moment(deliveredValues.actualDeliveryDate) > moment(Date.now());
    if (isFuture) {
      toast.error("Delivery date cannot be in the future");
      return;
    }
    handleDeliveredResponse(deliveredValues);
  };

  const handleDeliveryDateSubmitButton = async (
    deliveryDateValues: DeliveryDateUpdateInterface
  ) => {
    const isPast = moment(Date.now()) > moment(deliveryDateValues.expectedDeliveryDate);
    if (isPast) {
      toast.error("ETA date cannot be in the past");
      return;
    }
    handleDeliveryDateResponse(deliveryDateValues);
  };

  return (
    <Page hasBackButton={false} title="Delivery Update" isLoading={false}>
      <ShipmentContainer css={{ marginTop: "15px" }}>
        <Formik
          initialValues={deliveredValues}
          enableReinitialize
          validationSchema={deliveredValidationSchema}
          onSubmit={values => handleDeliveredSubmitButton(values)}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => (
            <StyledForm onSubmit={handleSubmit}>
              <DataPoint fullWidth withUniqueMargin>
                <SimpleDateInput
                  isRequired
                  htmlFor={deliveredFieldNames.actualDeliveryDate}
                  name={deliveredFieldNames.actualDeliveryDate}
                  value={values.actualDeliveryDate}
                  error={errors.actualDeliveryDate}
                  touched={touched.actualDeliveryDate}
                  label="Delivery Date"
                  changeHandler={e =>
                    setFieldValue(deliveredFieldNames.actualDeliveryDate, e.target.value)
                  }
                />
              </DataPoint>
              <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                <SecondaryButton appearance={"blueButton"} type="submit">
                  Deliver Shipment
                </SecondaryButton>
              </ButtonGroup>
            </StyledForm>
          )}
        </Formik>

        <Formik
          initialValues={deliveryDateValues}
          enableReinitialize
          validationSchema={deliveryDateValidationSchema}
          onSubmit={values => handleDeliveryDateSubmitButton(values)}
        >
          {({ values, setFieldValue, handleSubmit, errors, touched }) => (
            <StyledForm onSubmit={handleSubmit}>
              <DataPoint fullWidth withUniqueMargin>
                <SimpleDateInput
                  isRequired
                  htmlFor={deliveryDateFieldNames.expectedDeliveryDate}
                  name={deliveryDateFieldNames.expectedDeliveryDate}
                  value={values.expectedDeliveryDate}
                  error={errors.expectedDeliveryDate}
                  touched={touched.expectedDeliveryDate}
                  label="Expected Delivery Date"
                  changeHandler={e =>
                    setFieldValue(deliveryDateFieldNames.expectedDeliveryDate, e.target.value)
                  }
                />
              </DataPoint>
              <DataPoint fullWidth withUniqueMargin>
                <Label>Notes</Label>
                <SimpleTextArea
                  size="large"
                  placeholder="Enter any notes about this update"
                  value={values.notes}
                  handleChange={e => setFieldValue(deliveryDateFieldNames.notes, e.target.value)}
                  type={"text"}
                />
              </DataPoint>
              <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                <SecondaryButton appearance={"blueButton"} type="submit">
                  Update Expected Date
                </SecondaryButton>
              </ButtonGroup>
            </StyledForm>
          )}
        </Formik>
      </ShipmentContainer>
    </Page>
  );
};

export default DeliveryUpdateForm;
