import { useHistory } from "react-router-dom";

import { ButtonGroup } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  Box,
  DataPoint,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { prettifyProductName } from "../../../shared/mappers";
import { mapAttachmentsToSet } from "../../shared/mappers";
import { PURCHASE_ORDER_STATUS, ProductDisplayLineProps } from "../types";

const ProductDisplayLine = ({ index, orderLine }: ProductDisplayLineProps) => {
  const redirect = useHistory();

  return (
    <SecondaryLineWrapper key={orderLine.id} type="withIndex">
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DisplayBox
          fullWidth
          label="Product Name"
          isGray
          value={prettifyProductName(orderLine.product.product_name)}
        />
        <DataPoint withUniqueMargin>
          <DisplayBox label="Quantity (Units)" isGray value={orderLine.quantity} />
        </DataPoint>
        <DataPoint withUniqueMargin>
          <DisplayBox
            label="Quote (Unit Cost)"
            isGray
            value={`${orderLine.calculated_quotation_cost} ${orderLine.supplier_quote_line.currency_code} with
            MOQ ${orderLine.supplier_quote_line.minimum_order_quantity}`}
          />
        </DataPoint>
        <DataPoint withUniqueMargin>
          <DisplayBox
            label="Quote Reference"
            isGray
            value={orderLine.supplier_quote_line.supplier_reference}
          />
        </DataPoint>

        <SecondaryLineWrapper>
          <Title>Product Attachments</Title>
          {orderLine.folder.attachments.length !== 0 ? (
            <>
              {mapAttachmentsToSet(orderLine.folder.attachments).map(attachment => (
                <SecondaryLineDataWrapper key={attachment.id}>
                  <AttachmentDownloader
                    key={attachment.id}
                    originalFilename={attachment.original_filename}
                    attachmentUUID={attachment.attachment_uuid}
                    metadata={attachment.metadata}
                  />
                </SecondaryLineDataWrapper>
              ))}
            </>
          ) : (
            <Box> None </Box>
          )}
        </SecondaryLineWrapper>
      </SecondaryLineDataWrapper>
      {orderLine.purchase_order_status.purchase_order_status_name !==
        PURCHASE_ORDER_STATUS.PENDING &&
        orderLine.purchase_order_status.purchase_order_status_name !==
          PURCHASE_ORDER_STATUS.APPROVED && (
          <ButtonGroup
            css={{ display: "flex", width: "100%" }}
            className={WMSButtonGroup({ type: "largeMargin" })}
          >
            <SecondaryButton
              appearance={"blueButton"}
              type="button"
              onClick={() =>
                redirect.push(`/qc-orders/create-qc?purchaseOrderLineId=${orderLine.id}`)
              }
            >
              {orderLine.is_qa_inspection_required &&
              orderLine.quality_assurance_inspections.length > 0 ? (
                <>View or Update QC Outcome</>
              ) : (
                <>Add QC Outcome</>
              )}
            </SecondaryButton>
          </ButtonGroup>
        )}
    </SecondaryLineWrapper>
  );
};

export default ProductDisplayLine;
