import { useEffect } from "react";

import { ButtonGroup } from "@sourceful/shared-components";

import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Title } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { expireProductPriceApi } from "../api/expireProductPrice";

const ExpirePrice = ({
  id,
  handleClose,
  refetchPrices,
}: {
  id: number;
  handleClose: () => void;
  refetchPrices: () => Promise<void>;
}) => {
  const [expirePriceState, expirePrice] = useAPI(expireProductPriceApi, true);

  const handleExpireProductPrice = async (id: number) => {
    await expirePrice(id);
  };

  useEffect(() => {
    if (expirePriceState === API_REQUEST_STATE.SUCCESS) {
      refetchPrices();
      handleClose();
    } else if (expirePriceState === API_REQUEST_STATE.ERROR) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expirePriceState]);

  return (
    <div>
      <Title>Expiring price</Title>
      <p>Are you sure you want to expire this price?</p>

      <ButtonGroup>
        <SecondaryButton onClick={() => handleClose()} appearance="whiteButton">
          Cancel
        </SecondaryButton>
        <SecondaryButton onClick={() => handleExpireProductPrice(id)}>Expire</SecondaryButton>
      </ButtonGroup>
    </div>
  );
};

export default ExpirePrice;
