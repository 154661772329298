import { SizeValue } from "@sourceful/shared-types";

import { SupplierQuoteRequestLineObject } from "../graphql/types";

export const groupedQuoteLinesToIdList = (
  groupedLines?: SupplierQuoteRequestLineObject[]
): number[] => {
  if (!groupedLines) return [];
  return groupedLines.reduce((prev, curr) => {
    return [...curr.lines.map(line => line.id), ...prev];
  }, [] as number[]);
};

export const renderSizeValue = (value: SizeValue) => {
  const sizes = [];
  if (value.height) sizes.push(value.height);
  if (value.width) sizes.push(value.width);
  if (value.length) sizes.push(value.length);
  let sizeString = "";

  sizes.forEach((size, i) => {
    sizeString += `${size}${i === sizes.length - 1 ? value.unit : "x"}`;
  });

  return sizeString;
};
