import { ApolloError } from "@apollo/client";

import { useGetDashboardInboundManifestsQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DashboardItem } from "../types";

export interface HookResult {
  inboundManifests: DashboardItem[];
  inboundManifestsError?: ApolloError;
  inboundManifestsLoading: boolean;
}

export const useDashboardInboundManifests = (): HookResult => {
  const { data, error, loading } = useGetDashboardInboundManifestsQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    inboundManifests:
      data && Array.isArray(data.inbound_manifest)
        ? data.inbound_manifest.map(inboundManifest => {
            return {
              orderId: inboundManifest.id,
              organisationName: inboundManifest.organisation.organisation_name,
              iconName: "document-clipboard",
              date: inboundManifest.expected_arrival,
            };
          })
        : [],
    inboundManifestsError: error,
    inboundManifestsLoading: loading,
  };
};
