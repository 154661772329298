import { styled } from "@sourceful/shared-components";

export const ShipmentContainer = styled("div", {
  padding: "0 25px 25px",
});

export const AlignedLabel = styled("p", {
  width: "130px",
  display: "inline-block",
  fontWeight: "bold",
  margin: "0",
});

export const TimelineContainer = styled("div", {
  marginTop: "10px",
});

export const ClientOrderContainer = styled("div", {
  marginTop: "10px",
});
