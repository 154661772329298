import { DropdownItem } from "../graphql/types";
import { REPORT_TYPE } from "../types";

export const fieldNames = {
  report: "report",
  warehouse: "warehouse",
  fromDate: "fromDate",
  toDate: "toDate",
};

export interface ReportForm {
  report: DropdownItem | null;
  warehouse?: DropdownItem | null;
  fromDate?: string | null;
  toDate?: string | null;
}

export const reportOptions: DropdownItem[] = [
  { label: "Inventory Report", value: REPORT_TYPE.INVENTORY_REPORT },
  {
    label: "Empty Locations",
    value: REPORT_TYPE.EMPTY_LOCATIONS,
  },
  { label: "Manifests", value: REPORT_TYPE.MANIFESTS },
  { label: "All Products", value: REPORT_TYPE.ALL_PRODUCTS },
  { label: "Product Stock", value: REPORT_TYPE.PRODUCT_STOCK },
  { label: "Internal Transfers", value: REPORT_TYPE.INTERNAL_TRANSFERS },
  { label: "Accreditation", value: REPORT_TYPE.BASE_PRODUCTS_ACCREDITATIONS },
  { label: "Finance Stock Report", value: REPORT_TYPE.FINANCE_STOCK_REPORT },
];
