import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { createPurchaseOrderAPI } from "../../api/createPurchaseOrder";
import { CreatePurchaseOrderBody } from "../../api/types";
import {
  CREATE_PURCHASE_ORDER_FAILED,
  CREATE_PURCHASE_ORDER_LOADING,
  CREATE_PURCHASE_ORDER_RESET,
  CREATE_PURCHASE_ORDER_SUCCESS,
} from "../types/createPurchaseOrder";

export const createPurchaseOrderLoading = () => {
  return {
    type: CREATE_PURCHASE_ORDER_LOADING,
  };
};

export const createPurchaseOrderSuccess = () => {
  return {
    type: CREATE_PURCHASE_ORDER_SUCCESS,
  };
};

export const createPurchaseOrderFailed = () => {
  return {
    type: CREATE_PURCHASE_ORDER_FAILED,
  };
};

export const createPurchaseOrderReset = () => {
  return {
    type: CREATE_PURCHASE_ORDER_RESET,
  };
};

export const createPurchaseOrderAction = (body: CreatePurchaseOrderBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Purchase Order", { hideProgressBar: true });
    dispatch(createPurchaseOrderLoading());
    try {
      await createPurchaseOrderAPI(body);
      toast.success("Created Purchase Order successfully");
      dispatch(createPurchaseOrderSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating purchase order.");
      dispatch(createPurchaseOrderFailed());
    }
  };
};
