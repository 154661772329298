import { CellProps } from "react-table";

import { ShipmentItem } from "../graphql/types";
import { BatchShipmentUpdateInterface } from "../types";

export const initialBatchShipmentUpdateFieldValues: BatchShipmentUpdateInterface = {
  updateType: null,
  currentAddress: null,
  fromAddress: null,
  toAddress: null,
  clientOrganisations: [],
  purchaseOrderNumbers: [],
  clientOrderNumbers: [],
};

export const batchShipmentUpdateFieldNames = {
  updateType: "updateType",
  currentAddress: "currentAddress",
  fromAddress: "fromAddress",
  toAddress: "toAddress",
  clientOrganisations: "clientOrganisations",
  purchaseOrderNumbers: "purchaseOrderNumbers",
  clientOrderNumbers: "clientOrderNumbers",
};

export const tableCols = [
  {
    Header: "CO#",
    widthPercent: 0.1,
    accessor: (shipment: ShipmentItem) => shipment.client_order.external_client_order_id,
    Cell: ({ row }: CellProps<ShipmentItem>) => row.original.client_order.external_client_order_id,
  },
  {
    Header: "PO#",
    widthPercent: 0.1,
    accessor: (shipment: ShipmentItem) => shipment.purchase_order?.purchase_order_uuid,
  },
  {
    Header: "Client Name",
    widthPercent: 0.1,
    accessor: (shipment: ShipmentItem) => shipment.client_order?.organisation?.organisation_name,
  },
  {
    Header: "Supplier",
    widthPercent: 0.1,
    accessor: (shipment: ShipmentItem) => shipment?.purchase_order?.organisation?.organisation_name,
  },
  {
    Header: "Status",
    widthPercent: 0.1,
    accessor: (shipment: ShipmentItem) => shipment.shipment_status?.shipment_status_name,
  },
];
