import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { TranslationLineItem } from "../graphql/types";

export const mapQuotationCostTypeTranslations = (translation: any) => {
  return Object.keys(translation).map(key => {
    return {
      locale: key,
      quotation_cost_name: translation[key].name,
      quotation_cost_description: translation[key].description,
    };
  });
};

export const mapTranslationToDropdownObject = (
  translations: TranslationLineItem[],
  locales: DropdownItem[]
) => {
  return translations
    .map(translation => {
      const value = locales.find(({ label }) => label === translation.locale)?.value;

      return {
        locale: {
          label: translation.locale,
          value: value ?? -1,
        },
        quotation_cost_name: translation.quotation_cost_name,
        quotation_cost_description: translation.quotation_cost_description,
      };
    })
    .filter(translation => translation.locale.value !== -1);
};
