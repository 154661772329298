import { useAuth0 } from "@auth0/auth0-react";
import React, { createContext, useContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { activeServiceAction } from "../redux/actions/activeServiceAction";
import { ActiveServiceReducer } from "../redux/reducers/activeServiceReducer";
import { StoreTypes } from "../redux/store/storeTypes";

export interface Options {
  children: React.ReactNode;
  activeServiceState: ActiveServiceReducer;
  changeActiveService: (body: ActiveServiceReducer) => void;
  defaultContext?: AuthorisationContextInterface;
}

// TODO: move to contants
export enum ROLE {
  PUBLIC = "public",
  ADMIN = "admin",
  DEVELOPER = "sourceful_developer",
  STOCK_MANAGEMENT_OPERATOR = "ops_stock_management_operator",
  SOURCING_COORDINATOR = "ops_sourcing_operator",
  SOURCING_USER = "ops_sourcing_viewer",
  DIELINES_APPROVER = "ops_sourcing_approval_dielines",
  EXEC_APPROVER = "ops_sourcing_approval_exec",
  FINANCE_APPROVER = "ops_sourcing_approval_finance",
  ORDER_APPROVER = "ops_sourcing_approval_order",
  WMS_OPERATOR = "ops_wms_operator",
  WMS_USER = "ops_wms_viewer",
  LOGISTICS_OPERATOR = "ops_logistics_operator",
  LOGISTICS_USER = "ops_logistics_viewer",
}

export const ADMIN_ROLES = [ROLE.ADMIN, ROLE.DEVELOPER];

export const SM_ADMIN_ROLES = [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.STOCK_MANAGEMENT_OPERATOR];
export const WMS_ROLES = [ROLE.WMS_OPERATOR, ROLE.WMS_USER];
export const SOURCING_ROLES = [ROLE.SOURCING_COORDINATOR, ROLE.SOURCING_USER];
export const LOGISTIC_ROLES = [ROLE.LOGISTICS_USER, ROLE.LOGISTICS_OPERATOR];
export const SOURCING_EDIT_ROLES = [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.SOURCING_COORDINATOR];
export const WMS_EDIT_ROLES = [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.WMS_OPERATOR];
export const LOGISTICS_EDIT_ROLES = [ROLE.ADMIN, ROLE.DEVELOPER, ROLE.LOGISTICS_OPERATOR];
export const PCAT_ROLES = [ROLE.DEVELOPER, ROLE.ADMIN];
export const STOCK_MANAGEMENT_ROLES = [ROLE.DEVELOPER, ROLE.ADMIN];

export interface AuthorisationContextInterface {
  activeRole: string;
  roles: string[];
}

const DEFAULT_ROLES = [ROLE.PUBLIC];

const DEFAULT_CONTEXT = {
  activeRole: ROLE.PUBLIC,
  roles: DEFAULT_ROLES,
};

const HASURA_CLAIMS_NAMESPACE = "https://hasura.io/jwt/claims"; // TODO: extract to .env
const HASURA_ALLOWED_ROLES_CLAIM = "x-hasura-allowed-roles"; // TODO: extract to .env

export const AuthorisationContext = createContext<AuthorisationContextInterface>(DEFAULT_CONTEXT);

export const useAuthorisationContext = () => useContext(AuthorisationContext);

export const AuthorisationProvider = ({ children, ...props }: Options) => {
  const { user, isAuthenticated } = useAuth0();
  const [authorisationContext, setAuthorisationContext] = useState(
    !!props.defaultContext ? props.defaultContext : DEFAULT_CONTEXT
  );
  const { activeServiceState } = props;

  useEffect(() => {
    if (isAuthenticated) {
      if (Array.isArray(user?.[HASURA_CLAIMS_NAMESPACE]?.[HASURA_ALLOWED_ROLES_CLAIM])) {
        const HASURA_ROLES = user?.[HASURA_CLAIMS_NAMESPACE]?.[HASURA_ALLOWED_ROLES_CLAIM];
        let activeRole: ROLE;
        if (HASURA_ROLES.includes(ROLE.ADMIN)) {
          activeRole = ROLE.ADMIN;
        } else {
          switch (activeServiceState.activeService) {
            case "WMS":
              activeRole = HASURA_ROLES.find((r: ROLE) => WMS_ROLES.includes(r));
              break;
            case "Sourcing":
              activeRole = HASURA_ROLES.find((r: ROLE) => SOURCING_ROLES.includes(r));
              break;
            case "Logistics":
              activeRole = HASURA_ROLES.find((r: ROLE) => LOGISTIC_ROLES.includes(r));
              break;
            case "PCAT":
              activeRole = HASURA_ROLES.find((r: ROLE) => PCAT_ROLES.includes(r));
              break;
            case "StockManagement":
              activeRole = HASURA_ROLES.find((r: ROLE) => STOCK_MANAGEMENT_ROLES.includes(r));
              break;
            case "Admin":
              activeRole = HASURA_ROLES.find(
                (r: ROLE) =>
                  r === ROLE.ADMIN || r === ROLE.DEVELOPER || r === ROLE.STOCK_MANAGEMENT_OPERATOR
              );
              break;
            default:
              activeRole = ROLE.PUBLIC;
          }
        }
        setAuthorisationContext(previousContext => ({
          ...previousContext,
          activeRole: activeRole,
          roles: HASURA_ROLES,
        }));
      }
    }
  }, [isAuthenticated, user, activeServiceState]);

  return (
    <AuthorisationContext.Provider value={authorisationContext} {...props}>
      {children}
    </AuthorisationContext.Provider>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    activeServiceState: state.activeServiceReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    changeActiveService: (body: ActiveServiceReducer) => dispatch(activeServiceAction(body)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AuthorisationProvider);
