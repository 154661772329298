export interface WarehouseForm {
  warehouse: string;
  address: {
    value: number;
    label: string;
  } | null;
  organisation: {
    value: number;
    label: string;
  } | null;
}

export const initialFieldValues: WarehouseForm = {
  warehouse: "",
  address: null,
  organisation: null,
};

export const fieldNames = {
  warehouse: "warehouse",
  address: "address",
  organisation: "organisation",
};
