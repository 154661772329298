import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { createAddressAPI } from "../../api/createAddress";
import { CreateAddressBody } from "../../api/types";
import {
  CREATE_ADDRESS_FAILED,
  CREATE_ADDRESS_LOADING,
  CREATE_ADDRESS_RESET,
  CREATE_ADDRESS_SUCCESS,
} from "../types";

export const createAddressLoading = () => {
  return {
    type: CREATE_ADDRESS_LOADING,
  };
};

export const createAddressSuccess = () => {
  return {
    type: CREATE_ADDRESS_SUCCESS,
  };
};

export const createAddressFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CREATE_ADDRESS_FAILED,
    errors: errors,
  };
};

export const createAddressReset = () => {
  return {
    type: CREATE_ADDRESS_RESET,
  };
};

export const createAddressAction = (body: CreateAddressBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating address", { hideProgressBar: true });
    dispatch(createAddressLoading());
    try {
      await createAddressAPI(body);
      toast.success("Address created successfully.");
      dispatch(createAddressSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating address.");
      dispatch(createAddressFailed({ errors: (err as any).response?.data?.errors }));
    }
  };
};
