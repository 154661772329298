import { QuoteLine } from "../../types";

export interface QuoteForm {
  quote_valid_from: string;
  quote_valid_to: string;
  quote_lines: QuoteLine[];
}

export const initialFieldValues: QuoteForm = {
  quote_valid_from: "",
  quote_valid_to: "",
  quote_lines: [],
};

export const fieldNames = {
  quote_valid_from: "quote_valid_from",
  quote_valid_to: "quote_valid_to",
};
