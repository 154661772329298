import { Dispatch, SetStateAction, useMemo } from "react";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import {
  Box,
  CenterTextWrapper,
  DataPoint,
  HighlightedText,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { CaseChangeForm } from "../formValues";
import { BaseProductQuantityItem, ProductStockItem } from "../graphql/types";
import { StageInForm } from "../types";

interface Props {
  productStock: ProductStockItem[];
  productStockLoading: boolean;
  fromCaseBaseProductQuantity: BaseProductQuantityItem[];
  initialValues: CaseChangeForm;
  setInitialValues: Dispatch<SetStateAction<CaseChangeForm>>;
  values: CaseChangeForm;
  stageInForm: StageInForm;
  queryLoading: boolean;
}

const ProductStockSelection = ({
  fromCaseBaseProductQuantity,
  initialValues,
  setInitialValues,
  values,
  productStock,
  stageInForm,
  queryLoading,
}: Props) => {
  const isLoadingCopy = queryLoading ? "Loading Product Stock..." : "No Product Stock To See";

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.id,
      },
      {
        Header: "Name",
        widthPercent: 0.7,
        accessor: (listItem: ProductStockItem) => listItem.product.product_name,
      },
      {
        Header: "Organisation",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.organisation?.organisation_name,
      },
      {
        Header: "Location",
        widthPercent: 0.7,
        accessor: (listItem: ProductStockItem) => listItem.location.location_name,
      },
      {
        Header: "Condition",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.stock_condition.stock_condition_name,
      },
      {
        Header: "Base Qty",
        widthPercent: 0.7,
        accessor: (listItem: ProductStockItem) => listItem.product.base_product_quantity,
      },
      {
        Header: "Total base quantity",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) =>
          listItem.quantity * listItem.product.base_product_quantity,
      },
      {
        Header: "Available Qty",
        widthPercent: 0.1,
        accessor: (listItem: ProductStockItem) => listItem.quantity,
      },
      {
        Header: "Select",
        widthPercent: 0.4,
        Cell: ({ _, row }: CellProps<ProductStockItem>) => {
          if (
            values.selected_product_stock_lines.some(line => Number(line.id) === row.original.id)
          ) {
            return (
              <SecondaryButton
                type="button"
                appearance="greenButton"
                onClick={() => {
                  const indexOfProductStock = values.selected_product_stock_lines.findIndex(
                    line => {
                      return Number(line.id) === row.original.id;
                    }
                  );
                  values.selected_product_stock_lines.splice(indexOfProductStock, 1);
                  setInitialValues({
                    ...initialValues,
                    selected_product_stock_lines: [...initialValues.selected_product_stock_lines],
                  });
                }}
              >
                Selected
              </SecondaryButton>
            );
          }
          if (
            !values.selected_product_stock_lines.some(line => Number(line.id) === row.original.id)
          ) {
            return (
              <SecondaryButton
                type="button"
                appearance="blueButton"
                onClick={() => {
                  setInitialValues({
                    ...initialValues,
                    selected_product_stock_lines: [
                      ...initialValues.selected_product_stock_lines,
                      {
                        id: `${row.original.id}`,
                        product_name: row.original.product.product_name,
                        condition: row.original.stock_condition.stock_condition_name,
                        location: row.original.location.location_name,
                        base_product_quantity: `${row.original.product.base_product_quantity}`,
                        total_base_product_quantity: `${
                          row.original.quantity * row.original.product.base_product_quantity
                        }`,
                        quantity: `${row.original.quantity}`,
                        selected_quantity: "",
                      },
                    ],
                  });
                }}
              >
                Select
              </SecondaryButton>
            );
          }
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [productStock, values.selected_product_stock_lines]
  );

  return (
    <>
      {stageInForm.caseSelectionConfirmed && productStock.length === 0 && (
        <PrimaryLineWrapper>
          <PrimaryLineDataWrapper style={{ justifyContent: "center", marginTop: "0px" }}>
            {isLoadingCopy}
          </PrimaryLineDataWrapper>
        </PrimaryLineWrapper>
      )}

      {stageInForm.caseSelectionConfirmed &&
        !stageInForm.productStockSelectionConfirmed &&
        productStock &&
        productStock?.length > 0 && (
          <>
            <TopPanelInputsWrapper>
              <PrimaryLineDataWrapper>
                <DataPoint>
                  <Label isGray>From Case</Label>
                  <Box>{values.from_case?.label}</Box>
                </DataPoint>
                <DataPoint>
                  <Label isGray>From Quantity</Label>
                  <Box>{values.from_quantity}</Box>
                </DataPoint>
                <DataPoint>
                  <Label isGray>To Case</Label>
                  <Box>{values.to_case?.label}</Box>
                </DataPoint>
                <DataPoint>
                  <Label isGray>To Quantity</Label>
                  <Box>{values.to_quantity}</Box>
                </DataPoint>
                <DataPoint>
                  <Label isGray>New Location</Label>
                  <Box>{values.new_location?.label}</Box>
                </DataPoint>
              </PrimaryLineDataWrapper>
            </TopPanelInputsWrapper>
            <PrimaryLineWrapper>
              <PrimaryLineDataWrapper style={{ justifyContent: "center" }}>
                <CenterTextWrapper>
                  Please Select:
                  <HighlightedText>
                    {`${values.from_quantity} cases of ${fromCaseBaseProductQuantity[0].base_product_quantity}x ${values.base_product?.label}`}
                  </HighlightedText>
                </CenterTextWrapper>
              </PrimaryLineDataWrapper>

              <Table data={productStock ?? []} columns={columns} />
            </PrimaryLineWrapper>
          </>
        )}
    </>
  );
};

export default ProductStockSelection;
