import { QuotationCostInterface } from "../types";

export interface CartonDetails {
  unitPerCarton?: string;
  cartonLength?: string;
  cartonWidth?: string;
  cartonHeight?: string;
  cartonWeight?: string;
}

export interface QuoteForm {
  quoteValidFrom: string;
  quoteValidTo: string;
  currencyCode: string;
  moq: string;
  deliveryDate: string | null;
  leadTimeMaxDays: string;
  timeToHandoff: string;
  supplierReference: string | null;
  supplier: {
    label: string;
    value: number;
  } | null;
  quotationCosts: QuotationCostInterface[];
  productSKU: {
    label: string;
    value: number;
  } | null;
  productName: {
    label: string;
    value: number;
  } | null;
  country: {
    label: string;
    value: number;
  } | null;
  unitPerCarton?: string;
  cartonLength?: string;
  cartonWidth?: string;
  cartonHeight?: string;
  cartonWeight?: string;
  packagingOption?: string;
  domesticLogisticsFeeIncluded?: boolean;
  notes?: string;
}

export const initialFieldValues: QuoteForm = {
  quoteValidFrom: "",
  quoteValidTo: "",
  currencyCode: "",
  supplier: null,
  quotationCosts: [],
  productSKU: null,
  productName: null,
  moq: "",
  deliveryDate: "",
  leadTimeMaxDays: "",
  timeToHandoff: "",
  supplierReference: null,
  country: null,
  unitPerCarton: "",
  cartonLength: "",
  cartonWidth: "",
  cartonHeight: "",
  cartonWeight: "",
  packagingOption: "",
  domesticLogisticsFeeIncluded: false,
  notes: "",
};

export const fieldNames = {
  quoteValidFrom: "quoteValidFrom",
  quoteValidTo: "quoteValidTo",
  currencyCode: "currencyCode",
  moq: "moq",
  deliveryDate: "deliveryDate",
  leadTimeMaxDays: "leadTimeMaxDays",
  timeToHandoff: "timeToHandoff",
  supplierReference: "supplierReference",
  country: "country",
  quotationCost: {
    costType: "costType",
    cost: "cost",
  },
  unitPerCarton: "unitPerCarton",
  cartonLength: "cartonLength",
  cartonWidth: "cartonWidth",
  cartonHeight: "cartonHeight",
  cartonWeight: "cartonWeight",
  packagingOption: "packagingOption",
  domesticLogisticsFeeIncluded: "domesticLogisticsFeeIncluded",
  notes: "notes",
};

export const emptyQuotationCost: QuotationCostInterface = { costType: null, cost: "" };
