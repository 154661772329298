import { ApolloError } from "@apollo/client";

import { useGetCourierServicesWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { CourierServiceItem } from "../types";

export interface HookResult {
  courierServices: CourierServiceItem[];
  courierServicesError?: ApolloError;
  courierServicesLoading: boolean;
}

export const useCourierServicesWithData = (): HookResult => {
  const { data, error, loading } = useGetCourierServicesWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    courierServices: data && Array.isArray(data.courier_service) ? data.courier_service : [],
    courierServicesError: error,
    courierServicesLoading: loading,
  };
};
