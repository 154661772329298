import { SidebarItem } from "../SideBar";

export const adminSidebarItems: SidebarItem[] = [
  {
    heading: "My Dashboard",
    headingUrl: "admin",
    icon: "navigation-workspaces",
    links: [
      {
        name: "My Dashboard",
        URL: "admin",
      },
    ],
  },
  {
    heading: "SKUs",
    headingUrl: "admin/skus",
    icon: "products-mailer-bag",
    links: [
      {
        name: "SKUs",
        URL: "admin/skus",
      },
    ],
  },
  {
    heading: "IAM",
    headingUrl: "admin",
    icon: "toggle-star-fill",
    links: [
      {
        name: "Organisations",
        URL: "iam/organisations",
      },
      {
        name: "Users",
        URL: "iam/users",
      },
    ],
  },
  {
    heading: `Stock \n Management`,
    headingUrl: "admin/organisations",
    icon: "toggle-star-fill",
    links: [
      {
        name: "SM Organisations",
        URL: "admin/organisations",
      },
    ],
  },
];
