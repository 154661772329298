import { ChangeEvent } from "react";

import { InputField } from "@sourceful/shared-components";

import { Label } from "../../../../styles/SharedStyles";
import ErrorMessage from "../../atoms/labels/ErrorMessage";
import { ToolTip } from "../../atoms/labels/ToolTip/ToolTip";

export interface Props {
  name: string;
  type: "number" | "text" | "email";
  placeholder: string;
  value: string | number | undefined | null;
  error: string | null | undefined;
  touched: boolean | undefined;
  label?: string;
  changeHandler: (event: ChangeEvent<HTMLInputElement>) => void;
  htmlFor?: string;
  isGray?: boolean;
  size?: "small" | "medium" | "large";
  isRequired?: boolean;
  tooltipMessage?: string;
  isDisabled?: boolean;
  min?: number;
  max?: number;
}

export const SimpleInput = ({
  htmlFor,
  isRequired,
  value,
  name,
  placeholder,
  changeHandler,
  error,
  touched,
  isGray,
  label,
  tooltipMessage,
  isDisabled,
  type,
  size = "medium",
  min,
  max,
}: Props) => {
  return (
    <>
      {tooltipMessage ? (
        <ToolTip
          isGray={isGray}
          isRequired={isRequired}
          message={tooltipMessage}
          title={label}
          htmlFor={htmlFor}
        />
      ) : (
        <>
          {label && (
            <Label isGray={isGray} isRequired={isRequired} htmlFor={htmlFor}>
              {label}
            </Label>
          )}
        </>
      )}
      <InputField
        min={min ? `${min}` : undefined}
        max={max ? `${max}` : undefined}
        id={htmlFor}
        isDisabled={isDisabled}
        name={name}
        size={size}
        type={type}
        value={`${value}` || ""}
        placeholder={placeholder}
        handleChange={event => {
          changeHandler(event);
        }}
      />
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
    </>
  );
};

export default SimpleInput;
