import { PRODUCT_PRICING_API } from "../../../../../api/baseConfig";

export interface AddProductPriceBody {
  sku: string;
  price_per_unit: number;
  currency_code: string;
  min_order_quantity: number;
  valid_from: string | null;
}

export const addProductPriceApi = async (body: AddProductPriceBody) => {
  try {
    return await PRODUCT_PRICING_API.post("/add_product_pricing", body);
  } catch (err) {
    console.log("Error in adding product price API call", err);
    throw err;
  }
};
