import { IAM_API } from "../../../../../api/baseConfig";

export interface createIAMOrganisationBody {
  displayName: string;
  metadata: string;
}

export const createIAMOrganisation = async (params: createIAMOrganisationBody) => {
  try {
    return IAM_API.post(`/v2/organisation`, {
      display_name: params.displayName,
      metadata: JSON.parse(params.metadata),
    });
  } catch (err) {
    console.error("Error in IAM create user API call", err);
    throw err;
  }
};
