import { ApolloError } from "@apollo/client";

import { useGetClientOrderQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { ClientOrder, mapGQLClientOrderToDomain } from "../types";

export interface HookResult {
  clientOrder?: ClientOrder;
  clientOrderLoading: boolean;
  clientOrderError?: ApolloError;
  refetchClientOrder: () => Promise<void>;
}

export interface Options {
  clientOrderId: number;
}

export const useClientOrder = ({ clientOrderId }: Options): HookResult => {
  const { data, loading, error, refetch } = useGetClientOrderQuery({
    variables: { clientOrderId: clientOrderId },
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    clientOrder: Array.isArray(data?.client_order)
      ? mapGQLClientOrderToDomain(data?.client_order[0])
      : undefined,
    clientOrderLoading: loading,
    clientOrderError: error,
    refetchClientOrder: async () => {
      refetch({ clientOrderId: clientOrderId });
    },
  };
};
