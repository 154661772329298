import { UNAUTHENTICATED_SOURCING_API } from "../../../../../../api/baseConfig";
import { JWTSupplierQuoteBody } from "./types";

export const JWTSupplierQuote = async ({ supplierQuoteRequestId, token }: JWTSupplierQuoteBody) => {
  try {
    return await UNAUTHENTICATED_SOURCING_API.post(
      `/supplier-quote-requests/${supplierQuoteRequestId}/access`,
      { access_token: token }
    );
  } catch (err) {
    console.log("Error in Supplier Quote JWT call", err);
    throw err;
  }
};
