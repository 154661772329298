import { ApolloError } from "@apollo/client";

import { useGetProductInformationQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductInformationItem } from "../types";

export interface HookResult {
  productInformation?: ProductInformationItem;
  productInformationError?: ApolloError;
  productInformationLoading: boolean;
}

export const useProductInformation = (): HookResult => {
  const { data, error, loading } = useGetProductInformationQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    productInformation: data
      ? {
          productCount: data.product_aggregate.aggregate?.count.toString() || "",
          mostStockedProductName: data?.product.reduce((a, b) => {
            const aProduct = a.product_stocks_aggregate.aggregate?.sum?.quantity ?? 0;
            const bProduct = b.product_stocks_aggregate.aggregate?.sum?.quantity ?? 0;
            return aProduct > bProduct ? a : b;
          })?.product_name,
        }
      : undefined,
    productInformationError: error,
    productInformationLoading: loading,
  };
};
