import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";
import { v4 as uuidv4 } from "uuid";

import { IconText, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { CreateOutboundManifestBody } from "../api/types";
import ManifestLine from "../components/OutboundManifestLine";
import {
  ManifestLineInterface,
  OutboundManifestForm,
  emptyManifestLine,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { useOutboundBaseProducts } from "../graphql/hooks/useOutboundBaseProducts";
import { useOutboundCourierServiceNames } from "../graphql/hooks/useOutboundCourierServiceNames";
import { useOutboundLogisticsTypeNames } from "../graphql/hooks/useOutboundLogisticsTypeNames";
import { useOutboundOrganisationNames } from "../graphql/hooks/useOutboundOrganisationNames";
import { useOutboundWarehouseNames } from "../graphql/hooks/useOutboundWarehouseNames";
import { mapManifestLinesToDTO } from "../mappers";
import {
  createOutboundManifestAction,
  createOutboundManifestReset,
} from "../redux/actions/createOutboundManifestActions";
import { Props } from "../types";
import { validationSchema } from "../validation";

function CreateOutboundManifest(props: Props) {
  const [initialValues, setInitialValues] = useState<OutboundManifestForm>(initialFieldValues);
  const [isAddingManifestLines, setIsAddingManifestLines] = useState<boolean>(false);

  const { createOutboundManifestState } = props;

  const { logisticsTypeNames, logisticsTypeNamesLoading, logisticsTypeNamesError } =
    useOutboundLogisticsTypeNames();
  const { courierServiceNames, courierServiceNamesLoading, courierServiceNamesError } =
    useOutboundCourierServiceNames();
  const { organisationNames, organisationNamesLoading, organisationNamesError } =
    useOutboundOrganisationNames();
  const { baseProducts, baseProductsLoading, baseProductsError } = useOutboundBaseProducts();
  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } =
    useOutboundWarehouseNames();

  const onSubmit = (values: OutboundManifestForm) => {
    const manifestLines = mapManifestLinesToDTO(values.manifest_lines);

    const random5Chars = uuidv4().substring(0, 5);
    const outboundManifestBody: CreateOutboundManifestBody = {
      organisation_id: values.organisation?.value ?? 0,
      //TODO CHANGE  THIS TO REQUIRED WHEN LINKING TO SOURCING
      /* external_order_id: parseInt(values.external_order_id, 10),*/
      external_order_id: -1,
      logistics_type_id: values.logistics_type?.value ?? 0,
      warehouse_id: values.warehouse?.value ?? 0,
      expected_dispatch: new Date(values.expected_dispatch_date).toISOString(),
      outbound_manifest_lines: [...manifestLines],
      courier_service_id: values.courier_service?.value ?? 0,
      notes: values.notes ?? "",
      reference: `${values.organisation?.label}-${moment(values.expected_dispatch_date).format(
        "L"
      )}-${random5Chars}`,
    };

    props.createOutboundManifest(outboundManifestBody);
  };

  useEffect(() => {
    if (createOutboundManifestState.success) {
      props.resetCreateOutboundManifest();
      setInitialValues({ ...initialFieldValues });
      setIsAddingManifestLines(false);
    }

    if (createOutboundManifestState.error) {
      props.resetCreateOutboundManifest();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createOutboundManifestState.success, createOutboundManifestState.error]);

  const isLoading =
    organisationNamesLoading ||
    warehouseNamesLoading ||
    logisticsTypeNamesLoading ||
    createOutboundManifestState.loading ||
    courierServiceNamesLoading ||
    baseProductsLoading;

  const error =
    organisationNamesError ||
    warehouseNamesError ||
    baseProductsError ||
    logisticsTypeNamesError ||
    courierServiceNamesError;

  return (
    <Page error={error} isLoading={isLoading} title={"Create Outbound Manifest"}>
      <Panel
        withWrapper
        title={"Create Outbound Manifest "}
        subtitle={"Use the form below to create an outbound manifest"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleChange,
              handleSubmit,
              errors,
              touched,
              handleReset,
              setTouched,
              validateForm,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.organisation}>
                        Organisation
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        options={organisationNames}
                        id={fieldNames.organisation}
                        value={values.organisation}
                        onChange={e => setFieldValue(fieldNames.organisation, e)}
                        placeholder="Select Organisation"
                      />
                      {errors.organisation && touched.organisation ? (
                        <ErrorMessage>{errors.organisation}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.courier_service}>
                        Courier
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        options={courierServiceNames}
                        id={fieldNames.courier_service}
                        value={values.courier_service}
                        onChange={e => {
                          setFieldValue(fieldNames.courier_service, e);
                        }}
                        placeholder="Select Courier"
                      />
                      {errors.courier_service && touched.courier_service ? (
                        <ErrorMessage>{errors.courier_service}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.warehouse}>
                        Warehouse
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        id={fieldNames.warehouse}
                        value={values.warehouse}
                        options={warehouseNames}
                        onChange={e => setFieldValue(fieldNames.warehouse, e)}
                        placeholder="Select Warehouse "
                      />
                      {errors.warehouse && touched.warehouse ? (
                        <ErrorMessage>{errors.warehouse}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <ToolTip
                        message={"Select how the stock will <br/> arrive into the warehouse"}
                        title={"Logistics Type *"}
                        htmlFor={fieldNames.logistics_type}
                      ></ToolTip>
                      <Select
                        styles={reactSelectStyling}
                        maxMenuHeight={220}
                        isSearchable={true}
                        id={fieldNames.logistics_type}
                        value={values.logistics_type}
                        options={logisticsTypeNames}
                        onChange={e => setFieldValue(fieldNames.logistics_type, e)}
                        placeholder="Select Logistics Type "
                      />
                      {errors.logistics_type && touched.logistics_type ? (
                        <ErrorMessage>{errors.logistics_type}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.expected_dispatch_date}>
                        Expected Collection Date
                      </Label>
                      <DateInput
                        id={fieldNames.expected_dispatch_date}
                        type="date"
                        value={values.expected_dispatch_date}
                        onChange={handleChange}
                      />
                      {errors.expected_dispatch_date && touched.expected_dispatch_date ? (
                        <ErrorMessage>{errors.expected_dispatch_date}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  {!isAddingManifestLines && (
                    <ButtonWrapper>
                      <SecondaryButton
                        type="button"
                        appearance={"whiteButtonBlueText"}
                        onClick={async () => {
                          setTouched({
                            warehouse: true,
                            organisation: true,
                            expected_dispatch_date: true,
                            external_order_id: true,
                            logistics_type: true,
                            courier_service: true,
                          });

                          const formErrors = await validateForm();

                          if (Object.keys(formErrors).length === 0) {
                            setInitialValues({ ...values, manifest_lines: [emptyManifestLine] });
                            setIsAddingManifestLines(true);
                          }
                        }}
                        className={WMSButtonGroup({ type: "smallMargin" })}
                      >
                        <IconText text={"Add Manifest Lines"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}
                  {isAddingManifestLines && (
                    <>
                      <PrimaryLineWrapper>
                        <Title>Manifest Lines</Title>
                        {values.manifest_lines.map((value, index) => (
                          <ManifestLine
                            baseProductData={baseProducts}
                            values={value}
                            errors={errors.manifest_lines as FormikErrors<ManifestLineInterface[]>}
                            touched={touched.manifest_lines}
                            index={index}
                            setFieldValue={setFieldValue}
                            handleRemoveManifestLine={(index: number) => {
                              const fitleredManifestLines = values.manifest_lines.filter(
                                (item, i) => i !== index
                              );
                              const newValues = { ...values };
                              newValues.manifest_lines = fitleredManifestLines;
                              if (fitleredManifestLines.length === 0) {
                                setInitialValues(newValues);
                                setIsAddingManifestLines(false);
                              }
                              setInitialValues(newValues);
                            }}
                          />
                        ))}
                        <ButtonWrapper>
                          <SecondaryButton
                            type="button"
                            appearance={"whiteButtonBlueText"}
                            onClick={() => {
                              setInitialValues({
                                ...values,
                                manifest_lines: [...values.manifest_lines, emptyManifestLine],
                              });
                              setIsAddingManifestLines(true);
                            }}
                            className={WMSButtonGroup({ type: "smallMargin" })}
                          >
                            <IconText
                              text={"Add Manifest Lines"}
                              primaryIcon={"alert-add-outline"}
                            />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </PrimaryLineWrapper>

                      <DataPoint fullWidth={true}>
                        <Label htmlFor={fieldNames.notes}>Notes</Label>
                        <InputField
                          id={fieldNames.notes}
                          size={"medium"}
                          type={"text"}
                          value={values.notes}
                          placeholder={"Add a note here"}
                          handleChange={e => setFieldValue(fieldNames.notes, e.target.value)}
                        />
                      </DataPoint>

                      <FormButtonPair
                        handleReset={() => {
                          handleReset();
                          setInitialValues({ ...initialFieldValues });
                          setIsAddingManifestLines(false);
                        }}
                      />
                    </>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}
function mapStateToProps(state: StoreTypes) {
  return {
    createOutboundManifestState: state.createOutboundManifestReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createOutboundManifest: (body: CreateOutboundManifestBody) =>
      dispatch(createOutboundManifestAction(body)),
    resetCreateOutboundManifest: () => dispatch(createOutboundManifestReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateOutboundManifest);
