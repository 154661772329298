import { HeaderGroup } from "react-table";

import { Icon } from "@sourceful/shared-components";

interface SortIconProps<T extends object> {
  column: HeaderGroup<T>;
}

const SortIcon = <T extends object>({ column }: SortIconProps<T>) => {
  if (column.disableSortBy) return null;

  if (column.isSorted)
    <Icon
      className="sort-icon"
      name={column.isSortedDesc ? "arrow-direction-down" : "arrow-direction-up"}
    />;

  return <Icon className="sort-icon" name={"arrow-direction-dropdown"} />;
};

export default SortIcon;
