import { useEffect } from "react";
import { useParams } from "react-router-dom";

import { ButtonGroup } from "@sourceful/shared-components";

import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { WMSButtonGroup } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { resendPurchaseOrderAPI } from "../api/resendPurchaseOrder";
import { PURCHASE_ORDER_STATUS, ResendPurchaseOrderProps } from "../types";

const ResendPurchaseOrder = ({ poStatus, refetchPurchaseOrder }: ResendPurchaseOrderProps) => {
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const [resendPurchaseOrderState, resendPurchaseOrder] = useAPI(resendPurchaseOrderAPI, false, {
    pending: "Resending Purchase Order",
    success: "The purchase order has been resent successfully.",
    error: "The purchase order could not be resent. Please try again.",
  });

  useEffect(() => {
    if (resendPurchaseOrderState === API_REQUEST_STATE.SUCCESS) refetchPurchaseOrder();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resendPurchaseOrderState]);

  return (
    <VisibleFor roles={SOURCING_EDIT_ROLES}>
      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
        {poStatus === PURCHASE_ORDER_STATUS.EXPIRED && (
          <SecondaryButton type="button" onClick={() => resendPurchaseOrder(purchaseOrderId)}>
            Resend Purchase Order to Supplier
          </SecondaryButton>
        )}
      </ButtonGroup>
    </VisibleFor>
  );
};

export default ResendPurchaseOrder;
