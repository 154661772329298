import { ApolloError } from "@apollo/client";

import { useGetActiveAndFutureLocaleHolidaysQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { LocaleHolidayItem } from "../types";

export interface HookResult {
  localeHolidays: LocaleHolidayItem[];
  localeHolidaysLoading: boolean;
  localeHolidaysError?: ApolloError;
  refetchLocaleHolidays: () => Promise<void>;
}

const today = new Date().toISOString();

export const useActiveAndFutureLocaleHolidays = (): HookResult => {
  const { data, loading, error, refetch } = useGetActiveAndFutureLocaleHolidaysQuery({
    context: { clientName: GRAPHQL_SOURCING },
    variables: { today },
  });

  return {
    localeHolidays: data && Array.isArray(data.locale_holidays) ? data.locale_holidays : [],
    localeHolidaysLoading: loading,
    localeHolidaysError: error,
    refetchLocaleHolidays: async () => {
      refetch({ today });
    },
  };
};
