import moment from "moment";
import { createRef, useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import QRCode from "react-qr-code";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, Modal } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  InnerPanelWrapper,
  PanelWrapper,
  QRPrintButtonWrapper,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { InboundManifestStatuses } from "../../../../../util/interfaces";
import InboundManifestLine from "../components/InboundManifestLine";
import { useInboundManifestById } from "../graphql/hooks/useInboundManifestById";
import { mapManifestStatusToMessage } from "../mappers";
import {
  TerminateInboundManifestActionBody,
  terminateInboundManifestAction,
  terminateInboundManifestReset,
} from "../redux/actions/terminateInboundManifestAction";
import { TerminateInboundManifestReducer } from "../redux/reducers/terminateInboundManifestReducer";
import { CHANGE_IBM_STATUS } from "../redux/types";

interface Props {
  terminateIBMState: TerminateInboundManifestReducer;
  terminateIBM: (body: TerminateInboundManifestActionBody) => void;
  terminateIBMReset: () => void;
}

const FulfillInboundManifest = (props: Props) => {
  const [isQRModalOpen, setIsQRModalOpen] = useState(false);
  const [QRCodeBarcode, setQRCodeBarcode] = useState("");
  const [initialLoading, setInitialLoading] = useState<boolean>(true);

  const { terminateIBM, terminateIBMState } = props;

  const qrCodeRef = createRef<any | null>();
  const modalRef = createRef<HTMLButtonElement>();

  const handlePrint = useReactToPrint({
    content: () => qrCodeRef.current,
    pageStyle:
      "@page { size: auto;  margin: 20mm; } @media print { body { -webkit-print-color-adjust: exact; } }",
  });

  const { inbound_manifest_id } = useParams<{ inbound_manifest_id: string }>();

  const { manifest, manifestLoading, manifestError, refetchInboundManifest } =
    useInboundManifestById(Number(inbound_manifest_id));

  useEffect(() => {
    if (terminateIBMState.success) {
      refetchInboundManifest();
      props.terminateIBMReset();
    }
    if (terminateIBMState.error) {
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminateIBMState.success, terminateIBMState.error]);

  const isLoading = (manifestLoading && initialLoading) || terminateIBMState.loading;

  const manifestCanBeRejectedOrCancelled = () => {
    let hasFulfilmentLines = false;

    manifest?.inbound_manifest_lines.forEach(manifestLine => {
      const fulfilmentLinesLength = manifestLine.inbound_manifest_fulfilment_lines.length;
      if (fulfilmentLinesLength > 0) {
        hasFulfilmentLines = true;
      }
    });

    const isNew = manifest?.inbound_manifest_status.id === InboundManifestStatuses.NEW;

    if (isNew && hasFulfilmentLines === false) return true;
    return false;
  };

  const manifestCanBeMarkedAsDone = () => {
    const isNewOrInProgress =
      manifest?.inbound_manifest_status.id === InboundManifestStatuses.NEW ||
      manifest?.inbound_manifest_status.id === InboundManifestStatuses.IN_PROGRESS;

    if (isNewOrInProgress) return true;
    return false;
  };

  const terminateManifestDialog = (body: TerminateInboundManifestActionBody) => {
    const message = mapManifestStatusToMessage(body.type);

    confirmAlert({
      title: "Confirm Change to Manifest",
      message: `Are you sure you want to mark this manifest as ${message}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => terminateIBM(body),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const refetchIbm = () => {
    setInitialLoading(false);
    refetchInboundManifest();
  };

  return (
    <Page error={manifestError} isLoading={isLoading} title={"Fulfil Inbound Manifest"}>
      <>
        <Panel
          withWrapper
          title={"Fulfil Inbound Manifest Card"}
          subtitle={"Mark off what is actually recieved into the warehouse against the expectation"}
          manifestStatusName={manifest?.inbound_manifest_status.inbound_manifest_status_name}
        >
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DisplayBox label={"Manifest ID"} value={`#${manifest?.id}`} />
              <DisplayBox label={"Organisation"} value={manifest?.organisation.organisation_name} />
              <DisplayBox label={"Warehouse"} value={manifest?.warehouse.warehouse_name} />
              <DisplayBox
                label={"Logistics Type"}
                tooltipMessage={"Select how stock will <br/> arrive at the warehouse"}
                value={manifest?.logistics_type.logistics_type_name}
              />
              <DisplayBox
                label={"Expected Arrival Date"}
                value={moment(manifest?.expected_arrival).format("DD-MM-YYYY")}
              />
              <DisplayBox
                label={"Courier Service"}
                value={manifest?.courier_service?.courier_service_name}
              />
              <DisplayBox label={"Reference"} value={manifest?.reference} />
              <DisplayBox label={"Notes"} value={manifest?.notes} unknownString={"No Notes"} />
            </TopPanelInputsWrapper>

            {manifest?.inbound_manifest_lines.map((ibmLine, index) => (
              <InboundManifestLine
                inbound_manifest_id={manifest.id}
                key={index}
                inboundManifestLine={ibmLine}
                refetchInboundManifest={refetchIbm}
                index={index}
                warehouseId={manifest?.warehouse.id!}
                setIsQRModalOpen={setIsQRModalOpen}
                setQRCodeBarcode={setQRCodeBarcode}
              />
            ))}

            <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
              <SecondaryButton
                appearance={"whiteButton"}
                disabled={!manifestCanBeRejectedOrCancelled()}
                onClick={() =>
                  terminateManifestDialog({
                    type: CHANGE_IBM_STATUS.CANCEL,
                    inbound_manifest_id: Number(inbound_manifest_id),
                  })
                }
              >
                Cancel
              </SecondaryButton>
              <SecondaryButton
                appearance={"whiteButton"}
                disabled={!manifestCanBeRejectedOrCancelled()}
                onClick={() =>
                  terminateManifestDialog({
                    type: CHANGE_IBM_STATUS.REJECT,
                    inbound_manifest_id: Number(inbound_manifest_id),
                  })
                }
              >
                Reject
              </SecondaryButton>
              <SecondaryButton
                appearance={manifestCanBeMarkedAsDone() ? "blueButton" : "whiteButton"}
                onClick={() =>
                  terminateManifestDialog({
                    type: CHANGE_IBM_STATUS.MARK_AS_DONE,
                    inbound_manifest_id: Number(inbound_manifest_id),
                  })
                }
                disabled={!manifestCanBeMarkedAsDone()}
              >
                Mark as Done
              </SecondaryButton>
            </ButtonGroup>
          </InnerPanelWrapper>
        </Panel>

        <Modal
          id={"QRCodeModal"}
          isOpen={isQRModalOpen}
          handleClose={() => {
            setIsQRModalOpen(false);
            setQRCodeBarcode("");
          }}
          triggerRef={modalRef}
        >
          <PanelWrapper>
            <InnerPanelWrapper>
              <div ref={qrCodeRef}>
                <QRCode value={QRCodeBarcode} />
              </div>
              <QRPrintButtonWrapper>
                <SecondaryButton appearance={"blueButton"} onClick={handlePrint}>
                  Print
                </SecondaryButton>
              </QRPrintButtonWrapper>
            </InnerPanelWrapper>
          </PanelWrapper>
        </Modal>
      </>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    terminateIBMState: state.terminateInboundManifestReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    terminateIBM: (body: TerminateInboundManifestActionBody) =>
      dispatch(terminateInboundManifestAction(body)),
    terminateIBMReset: () => dispatch(terminateInboundManifestReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfillInboundManifest);
