import { styled } from "@sourceful/shared-components";

export const NotificationBadgeWrapper = styled("button", {
  border: 0,
  fontSize: "10px",
  fontWeight: "700",
  lineHeight: "15px",
  fontFamily: "Space Grotesk",
  fontStyle: "normal",
  borderRadius: "50%",
  width: "28px",
  height: "28px",
  backgroundColor: "#000000",
  color: "#FFFFFF",
  variants: {
    isActive: {
      true: {
        backgroundColor: "#005AE1",
      },
      false: {
        backgroundColor: "#B9B9BB",
      },
    },
  },
});

const Text = styled("p", {
  margin: "0px",
  padding: "0px",
  textAlign: "center",
  color: "#FFFFFF",
});

export interface NotificationBadgeProps {
  text: string;
  isActive: boolean;
}

export const NotificationBadge = ({ text, isActive }: NotificationBadgeProps) => {
  return (
    <NotificationBadgeWrapper isActive={isActive}>
      {text.length >= 3 ? <Text>99+</Text> : <Text>{text}</Text>}
    </NotificationBadgeWrapper>
  );
};
