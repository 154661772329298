import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { SplitShipmentAction } from "../actions/splitShipmentAction";
import {
  SPLIT_SHIPMENT_FAILED,
  SPLIT_SHIPMENT_LOADING,
  SPLIT_SHIPMENT_RESET,
  SPLIT_SHIPMENT_SUCCESS,
} from "../types";

export interface SplitShipmentState {
  loading: boolean;
  success: boolean;
  error: boolean;
  errors: ErrorType[];
}

const initialState: SplitShipmentState = {
  loading: false,
  success: false,
  error: false,
  errors: [],
};

export default function updateShipmentDeliveryDateReducer(
  state: SplitShipmentState = initialState,
  action: SplitShipmentAction
) {
  switch (action.type) {
    case SPLIT_SHIPMENT_LOADING:
      return { ...state, loading: true };
    case SPLIT_SHIPMENT_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case SPLIT_SHIPMENT_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
        errors: action.errors,
      };
    case SPLIT_SHIPMENT_RESET:
      return { ...state, ...initialState };
    default:
      return state;
  }
}
