export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never;

export interface ReactSelectOption {
  value: number;
  label: string;
}

export enum ServiceAccounts {
  WMS = 1,
  SOURCING = 2,
  LOGISTICS = 3,
}

export enum InboundManifestStatuses {
  NEW = 0,
  IN_PROGRESS = 1,
  DONE = 2,
  REJECTED = 3,
  CANCELLED = 4,
}

export enum InboundManifestLineStatuses {
  NEW = 0,
  IN_PROGRESS = 1,
  DONE = 2,
  REJECTED = 3,
  CANCELLED = 4,
}

export enum InternalTransferStatuses {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}

export enum InternalTransferLineStatuses {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}

export enum OutboundManifestStatuses {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
  IN_PROGRESS = 4,
  REJECTED = 5,
}

export enum OutboundManifestLineStatuses {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
  REJECTED = 4,
  IN_PROGRESS = 5,
}

export enum OutboundManifestFulfilmentLineStatuses {
  NEW = 0,
  PACKED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}

export enum OutboundManifestFulfilmentStatuses {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}

export enum StockCheckStatuses {
  NEW = 0,
  RESERVED = 1,
  COMPLETE = 2,
  CANCELLED = 3,
}

export enum WorkOrderStatuses {
  NEW = 0,
  IN_PROGRESS = 1,
  DONE = 2,
  CANCELLED = 3,
}

export enum AllStatusColours {
  NEW = "#005AE1",
  IN_PROGRESS = "#FFB500",
  DONE_OR_COMPLETE = "#78D178",
  REJECTED = "#D02257",
  CANCELLED = "#747477",
  RESERVED = "#f5f51b",
}
