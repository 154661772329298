import { styled } from "@sourceful/shared-components";

const MessageWrapper = styled("div", {
  margin: "0px",
  padding: "8px 12px",
  borderRadius: "5px",
  backgroundColor: "#fff5f5",
  borderColor: "#fc8181",
  borderStyle: "solid",
  borderWidth: "1px",
  marginTop: "8px",
  display: "flex",
  alignItems: "center",
  width: "auto",
  color: "#c53030",
});

const ErrorMessage = ({ children, css }: { children: React.ReactNode; css?: any }) => {
  return <MessageWrapper css={css}> {children}</MessageWrapper>;
};

export default ErrorMessage;
