import { FormikErrors, FormikTouched } from "formik";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { toast } from "react-toastify";

import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import {
  Box,
  ButtonPositioner,
  DataPoint,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { CaseChangeForm, SelectedProductStockLinesInterface } from "../formValues";
import { ProductStockItem } from "../graphql/types";
import { StageInForm } from "../types";
import { CaseQuantityLine } from "./CaseQuantityLine";

interface Props {
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  stageInForm: StageInForm;
  setStageInForm: Dispatch<SetStateAction<StageInForm>>;
  values: CaseChangeForm;
  errors: any;
  touched: FormikTouched<CaseChangeForm>;
  setInitialValues: Dispatch<SetStateAction<CaseChangeForm>>;
  productStock: ProductStockItem[];
}

const CaseQuantitySelection = ({
  setFieldValue,
  productStock,
  stageInForm,
  setStageInForm,
  values,
  touched,
  errors,
  setInitialValues,
}: Props) => {
  const [remainingQuantity, setRemainingQuantity] = useState<number>(Number(values.from_quantity));

  const incompatibleQuantityInputs = values.selected_product_stock_lines.some(
    line =>
      line.selected_quantity !== "" &&
      (Number(line.selected_quantity) <= 0 ||
        !Number.isInteger(Number(line.selected_quantity)) ||
        Number(line.selected_quantity) > Number(line.quantity))
  );

  const RemoveProductStock = (index: number) => {
    values.selected_product_stock_lines.splice(index, 1);
    setInitialValues({
      ...values,
      selected_product_stock_lines: [...values.selected_product_stock_lines],
    });
  };

  useEffect(() => {
    let quantity: number = 0;

    values.selected_product_stock_lines.forEach(line => {
      if (Number(line.selected_quantity) >= 0) {
        quantity = quantity + Number(line.selected_quantity);
      }
    });

    setRemainingQuantity(Number(values.from_quantity) - quantity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.selected_product_stock_lines]);

  return (
    <>
      {stageInForm.caseSelectionConfirmed &&
        !stageInForm.productStockSelectionConfirmed &&
        productStock &&
        productStock?.length > 0 &&
        values.selected_product_stock_lines.length > 0 && (
          <PrimaryLineWrapper>
            <Title style={{ marginBottom: "40px" }}>
              Select the quantity of the cases/product stock you want to use
            </Title>
            <PrimaryLineDataWrapper style={{ justifyContent: "flex-end" }}>
              <DataPoint>
                <Label success={remainingQuantity === 0} warning={remainingQuantity < 0}>
                  Remaining Quantity To Select
                </Label>
                <Box success={remainingQuantity === 0} warning={remainingQuantity < 0}>
                  {remainingQuantity}
                </Box>
              </DataPoint>
            </PrimaryLineDataWrapper>
            {values.selected_product_stock_lines.map((line, index) => {
              return (
                <CaseQuantityLine
                  RemoveProductStock={RemoveProductStock}
                  values={line}
                  index={index}
                  setFieldValue={setFieldValue}
                  touched={touched.selected_product_stock_lines}
                  errors={
                    errors.selected_product_stock_lines as FormikErrors<
                      SelectedProductStockLinesInterface[]
                    >
                  }
                ></CaseQuantityLine>
              );
            })}
            <ButtonPositioner>
              <SecondaryButton
                appearance="blueButton"
                type="button"
                onClick={() => {
                  if (remainingQuantity < 0) {
                    toast.warning(
                      `You have selected too much product stock. Please only select a quantity of ${values.from_quantity}`
                    );
                  }

                  if (
                    remainingQuantity === 0 &&
                    values.selected_product_stock_lines.some(line => {
                      return line.selected_quantity === "";
                    })
                  ) {
                    toast.warning("Please remove empty product stock lines");
                  }

                  if (incompatibleQuantityInputs) {
                    toast.warning("Please ensure all quantities are completed correctly");
                  }

                  if (remainingQuantity > 0) {
                    toast.warning("Please select more product stock");
                  }
                  if (
                    !incompatibleQuantityInputs &&
                    remainingQuantity === 0 &&
                    !values.selected_product_stock_lines.some(line => {
                      return line.selected_quantity === "";
                    })
                  ) {
                    setStageInForm({
                      ...stageInForm,
                      productStockSelectionConfirmed: true,
                    });
                    setInitialValues({ ...values });
                  }
                }}
              >
                Confirm
              </SecondaryButton>
            </ButtonPositioner>
          </PrimaryLineWrapper>
        )}
    </>
  );
};

export default CaseQuantitySelection;
