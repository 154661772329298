import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { useWarehousesWithData } from "../graphql/hooks/useWarehousesWithData";
import { WarehouseItem } from "../graphql/types";
import { joinAddressLines } from "../mappers";

const ViewWarehouses = () => {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { warehouses, warehousesLoading, warehousesError } = useWarehousesWithData();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/warehouse-management/add-warehouse");
          },
          type: "button",
          title: "Add warehouse",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.1,
        accessor: (listItem: WarehouseItem) => listItem.id,
      },
      {
        Header: "Warehouse",
        widthPercent: 0.2,
        accessor: (listItem: WarehouseItem) => listItem.warehouse_name,
      },
      {
        Header: "Full Address",
        widthPercent: 0.4,
        accessor: (listItem: WarehouseItem) => joinAddressLines(listItem.address),
      },
      {
        Header: "Postal Code",
        widthPercent: 0.2,
        accessor: (listItem: WarehouseItem) => listItem.address.postcode,
      },
      {
        Header: "City",
        widthPercent: 0.2,
        accessor: (listItem: WarehouseItem) => listItem.address.city,
      },
      {
        Header: "Country",
        widthPercent: 0.4,
        accessor: (listItem: WarehouseItem) => listItem.address.country.country_name,
      },
      {
        Header: "View",
        widthPercent: 0.05,
        Cell: ({ _, row }: CellProps<WarehouseItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.id}`}>
            <SecondaryButton appearance={"blueButton"} isWithinTable>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page error={warehousesError} isLoading={warehousesLoading} title={"Warehouses"}>
      <Panel
        withWrapper
        title={"Warehouse List"}
        text={"Use the table below to view the details of Sourceful's Warehouses."}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={warehouses} columns={columns} />
      </Panel>
    </Page>
  );
};

export default ViewWarehouses;
