import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { createBaseProductAPI } from "../../api/createBaseProduct";
import { CreateBaseProductBody } from "../../api/types";
import {
  CREATE_BASE_PRODUCT_FAILED,
  CREATE_BASE_PRODUCT_LOADING,
  CREATE_BASE_PRODUCT_RESET,
  CREATE_BASE_PRODUCT_SUCCESS,
} from "../types";

export const createBaseProductLoading = () => {
  return {
    type: CREATE_BASE_PRODUCT_LOADING,
  };
};

export const createBaseProductSuccess = () => {
  return {
    type: CREATE_BASE_PRODUCT_SUCCESS,
  };
};

export const createBaseProductFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CREATE_BASE_PRODUCT_FAILED,
    errors: errors,
  };
};

export const createBaseProductReset = () => {
  return {
    type: CREATE_BASE_PRODUCT_RESET,
  };
};

export const createBaseProductAction = (body: CreateBaseProductBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating base product", { hideProgressBar: true });
    dispatch(createBaseProductLoading());
    try {
      await createBaseProductAPI(body);
      toast.success("Base product created successfully.");
      dispatch(createBaseProductSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating base product.");
      dispatch(createBaseProductFailed({ errors: (err as any).response?.data?.errors }));
    }
  };
};
