import { ApolloError } from "@apollo/client";

import { useGetBaseProductNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  baseProductNames: string[];
  baseProductNamesError?: ApolloError;
  baseProductNamesLoading: boolean;
  refetchBaseProductNames: () => Promise<void>;
}

export const useBaseProductNames = (): HookResult => {
  const { data, error, loading, refetch } = useGetBaseProductNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    baseProductNames:
      data && Array.isArray(data.base_product)
        ? data.base_product
            .map(baseProduct => baseProduct.product_name || "")
            .filter(baseProductName => baseProductName !== "")
        : [],
    baseProductNamesError: error,
    baseProductNamesLoading: loading,
    refetchBaseProductNames: async () => {
      refetch();
    },
  };
};
