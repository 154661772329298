import { useEffect } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { Icon } from "../../../../../components/icons/Icon";
import Panel from "../../../../../components/panel/panel";
import { ROLE } from "../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import Page from "../../../../../shared/components/templates/Page/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import { InnerPanelWrapper, TopPanelInputsWrapper } from "../../../../../styles/SharedStyles";
import { stockSyncAction, stockSyncReset } from "../redux/actions/stockSyncAction";
import { StockSyncReducer } from "../redux/reducers/stockSyncReducer";

interface Props {
  stockSyncState: StockSyncReducer;
  stockSync: () => void;
  resetStockSync: () => void;
}

function ViewWMSAdminPanel(props: Props) {
  const { stockSyncState } = props;

  useEffect(() => {
    if (stockSyncState.success) {
      props.resetStockSync();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stockSyncState.success, stockSyncState.error]);

  const submitStockSync = async () => {
    props.stockSync();
  };

  return (
    <Page title={"WMS Admin Panel"} isLoading={false} hasBackButton>
      <Panel
        withWrapper
        title={"Admin Panel"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <VisibleFor roles={[ROLE.DEVELOPER]}>
              <SecondaryButton appearance="blueButton" icon="singleIcon" onClick={submitStockSync}>
                <Icon name="alert-exclamation-fill" />
                &nbsp; Stock Sync
              </SecondaryButton>
            </VisibleFor>
          </TopPanelInputsWrapper>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    stockSyncState: state.stockSyncReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    stockSync: () => dispatch(stockSyncAction()),
    resetStockSync: () => dispatch(stockSyncReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewWMSAdminPanel);
