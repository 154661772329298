import {
  CREATE_ORGANISATION_FAILED,
  CREATE_ORGANISATION_LOADING,
  CREATE_ORGANISATION_RESET,
  CREATE_ORGANISATION_SUCCESS,
} from "../types";

export interface CreateOrganisationReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateOrganisationReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createOrganisationReducer(
  state: CreateOrganisationReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_ORGANISATION_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_ORGANISATION_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_ORGANISATION_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_ORGANISATION_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
