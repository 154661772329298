import { BaseProductItem, ProductStockItem, QueryResult } from "../graphql/types";

export const mapQueryResultToProductAndStockItem = (
  queryResults: QueryResult[],
  productId: number
) => {
  let baseProductquery = queryResults.filter(
    queryResult => queryResult.id === queryResult.base_product_id
  )[0];

  const baseProduct: BaseProductItem = {
    id: baseProductquery.id,
    product_name: baseProductquery.product_name || "",
    fsc_certified: baseProductquery.fsc_certified,
    grs_certified: baseProductquery.grs_certified,
    length_mm: baseProductquery.length_mm,
    weight_grams: baseProductquery.weight_grams,
    width_mm: baseProductquery.width_mm,
    height_mm: baseProductquery.height_mm,
  };

  let selectedProductQuery = queryResults.filter(queryResult => queryResult.id === productId)[0];

  const selectedProduct: BaseProductItem = {
    id: selectedProductQuery.id,
    product_name: selectedProductQuery.product_name || "",
    fsc_certified: selectedProductQuery.fsc_certified,
    grs_certified: selectedProductQuery.grs_certified,
    length_mm: selectedProductQuery.length_mm,
    weight_grams: selectedProductQuery.weight_grams,
    width_mm: selectedProductQuery.width_mm,
    height_mm: selectedProductQuery.height_mm,
  };

  const productStocks = queryResults.map(queryResult => {
    return queryResult.product_stocks.map(stock => {
      const quantity = queryResult.base_product_quantity * stock.quantity;

      return {
        id: stock.id,
        productName: queryResult.product_name,
        quantity: stock.quantity,
        location: {
          id: stock.location.id,
          name: stock.location.location_name,
        },
        stockStatus: stock.stock_status.stock_status_name,
        organisation: {
          id: stock.organisation?.id!,
          name: stock.organisation?.organisation_name!,
        },
        totalQuantity: stock.stock_status.stock_status_name !== "Missing" ? quantity : 0,
      } as ProductStockItem;
    });
  });

  return {
    selectedProduct: selectedProduct,
    baseProduct: baseProduct,
    productStock: productStocks.flat(),
  };
};
