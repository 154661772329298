import {
  BaseProductItem,
  LocationItem,
  StockCheckTypeItem,
  WarehouseNameItem,
} from "../graphql/types";

export const mapWarehouseNamesToDropdownObject = (warehouseNames: WarehouseNameItem[]) => {
  return warehouseNames.map(warehouseName => {
    return {
      label: warehouseName.warehouse_name,
      value: warehouseName.id,
    };
  });
};

export const mapBaseProductNamesToDropdownObject = (baseProductNames: BaseProductItem[]) => {
  return baseProductNames.map(baseProductName => {
    return {
      label: baseProductName.product_name!,
      value: baseProductName.id!,
    };
  });
};

export const mapStockCheckTypesToDropdownObject = (stockCheckTypes: StockCheckTypeItem[]) => {
  return stockCheckTypes.map(stockCheckType => {
    return {
      label: stockCheckType.stock_check_type_name,
      value: stockCheckType.id,
    };
  });
};

export const mapLocationNamesToDropdownObject = (locationNames: LocationItem[]) => {
  return locationNames.map(location => {
    return {
      label: location.location_name,
      value: location.id,
    };
  });
};
