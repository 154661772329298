import { FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";

import { Icon } from "@sourceful/shared-components";

import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import { IndexPill } from "../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../styles/SharedStyles";
import { NO_OPTION_INDEX, boolOptions, fieldObjects } from "../formValues";
import { CarbonOffsetCost, CreateOrderForm } from "../types";
import CurrencySimpleInput from "./InputWithCurrency";

export interface CarbonOffsetLineProps {
  values: CarbonOffsetCost;
  index: number;
  errors: FormikErrors<CarbonOffsetCost[]> | undefined;
  touched: FormikTouched<CarbonOffsetCost>[] | undefined;
  handleRemoveQuoteLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  currency: string;
  formValues: CreateOrderForm;
}

const UpdateCarbonOffsetCost = ({
  values,
  setFieldValue,
  index,
  handleRemoveQuoteLine,
  errors,
  touched,
  currency,
  formValues,
}: CarbonOffsetLineProps) => {
  const [vat] = useState(boolOptions[NO_OPTION_INDEX]);

  useEffect(() => {
    const total = values.kg * values.price_per_kg;
    const total_with_vat = values.kg * values.price_per_kg;
    setFieldValue(fieldObjects.carbon_offset_costs(index).total.fieldName, total);
    setFieldValue(fieldObjects.carbon_offset_costs(index).total_with_vat.fieldName, total_with_vat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.kg, values.price_per_kg, values.vat]);

  useEffect(() => {
    setFieldValue(fieldObjects.carbon_offset_costs(index).vat.fieldName, vat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <SimpleInput
            htmlFor={fieldObjects.carbon_offset_costs(index).kg.fieldName}
            name={fieldObjects.carbon_offset_costs(index).kg.fieldName}
            type={"text"}
            placeholder={fieldObjects.carbon_offset_costs(index).kg.fieldPlaceholder}
            value={values.kg}
            error={Array.isArray(errors) && errors[index]?.kg ? errors[index]!.kg : undefined}
            touched={Array.isArray(touched) && touched[index]?.kg}
            label={fieldObjects.carbon_offset_costs(index).kg.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.carbon_offset_costs(index).kg.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            isDisabled={true}
            htmlFor={fieldObjects.carbon_offset_costs(index).price_per_kg.fieldName}
            name={fieldObjects.carbon_offset_costs(index).price_per_kg.fieldName}
            type={"text"}
            placeholder={fieldObjects.carbon_offset_costs(index).price_per_kg.fieldPlaceholder}
            value={values.price_per_kg}
            error={
              Array.isArray(errors) && errors[index]?.price_per_kg
                ? errors[index]!.price_per_kg
                : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.price_per_kg}
            label={fieldObjects.carbon_offset_costs(index).price_per_kg.fieldLabel}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.carbon_offset_costs(index).price_per_kg.fieldName,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            isDisabled
            htmlFor={fieldObjects.product_costs(index).vat.fieldName}
            name={fieldObjects.product_costs(index).vat.fieldName}
            placeholder="VAT"
            options={boolOptions}
            value={values.vat}
            error={
              Array.isArray(errors) && errors[index]?.vat?.label
                ? errors[index]?.vat?.label
                : undefined
            }
            touched={
              Array.isArray(touched) && touched[index]?.vat?.label
                ? touched[index]?.vat?.label
                : undefined
            }
            label="VAT"
            changeHandler={e => setFieldValue(fieldObjects.product_costs(index).vat.fieldName, e)}
          />
        </DataPoint>
        <DataPoint>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.carbon_offset_costs(index).total.fieldName}
            name={fieldObjects.carbon_offset_costs(index).total.fieldName}
            type={"text"}
            placeholder={fieldObjects.carbon_offset_costs(index).total.fieldPlaceholder}
            value={values.total}
            error={Array.isArray(errors) && errors[index]?.total ? errors[index]!.total : undefined}
            touched={Array.isArray(touched) && touched[index]?.total}
            label={`${fieldObjects.carbon_offset_costs(index).total.fieldLabel} (${currency})`}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.carbon_offset_costs(index).total.fieldName,
                e.target.value
              );
            }}
            currency={currency}
          />
        </DataPoint>
        <DataPoint>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.carbon_offset_costs(index).total_with_vat.fieldName}
            name={fieldObjects.carbon_offset_costs(index).total_with_vat.fieldName}
            type={"text"}
            placeholder={fieldObjects.carbon_offset_costs(index).total_with_vat.fieldPlaceholder}
            value={values.total_with_vat}
            error={
              Array.isArray(errors) && errors[index]?.total_with_vat
                ? errors[index]!.total_with_vat
                : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.total_with_vat}
            label={`${
              fieldObjects.carbon_offset_costs(index).total_with_vat.fieldLabel
            } (${currency})`}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.carbon_offset_costs(index).total_with_vat.fieldName,
                e.target.value
              );
            }}
            currency={currency}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>
      <DataPoint halfWidth containsButton>
        <IconWrapper
          type="button"
          onClick={() => {
            handleRemoveQuoteLine(index);
          }}
        >
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DataPoint>
    </SecondaryLineWrapper>
  );
};

export default UpdateCarbonOffsetCost;
