import { ApolloError } from "@apollo/client";

import { useGetAllOrganisationNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";

export interface HookResult {
  organisationNames: string[];
  organisationNamesError?: ApolloError;
  organisationNamesLoading: boolean;
  refetchOrganisationNames: () => Promise<void>;
}

export const useAllOrganisationNames = (): HookResult => {
  const { data, error, loading, refetch } = useGetAllOrganisationNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    organisationNames:
      data && Array.isArray(data.organisation)
        ? data.organisation.map(organisation => organisation.organisation_name)
        : [],
    organisationNamesError: error,
    organisationNamesLoading: loading,
    refetchOrganisationNames: async () => {
      refetch();
    },
  };
};
