import { Formik } from "formik";

import { ButtonGroup, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor/VisibleFor";
import { STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES } from "../../../../../shared/constants";
import { mapStockManagementAddressesToDropdownItem } from "../../../../../shared/mappers";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
} from "../../../../../styles/SharedStyles";
import { Address } from "../../shared/graphql/types";
import {
  AddContactToOrganisationInterface,
  addContactFieldNames,
  initialAddContactToOrganisationValues,
} from "../../shared/types";
import { addContactValidationSchema } from "../validation";

interface Props {
  addresses: Address[] | null | undefined;
  onClose: () => void;
  onAddContact: (values: AddContactToOrganisationInterface) => void;
}

const AddStockManagementContact = ({ addresses, onClose, onAddContact }: Props): JSX.Element => {
  return (
    <Formik
      initialValues={initialAddContactToOrganisationValues}
      enableReinitialize
      onSubmit={(values: AddContactToOrganisationInterface, { resetForm }) => {
        onAddContact(values);
        resetForm();
      }}
      validationSchema={addContactValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <SecondaryLineWrapper
              type={"darkBackground"}
              css={{
                alignItems: "normal",
              }}
            >
              <PrimaryLineDataWrapper
                css={{
                  alignItems: "normal",
                }}
              >
                <DataPoint fullWidth>
                  <Title>Add new contact</Title>
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addContactFieldNames.firstName}
                    name={addContactFieldNames.firstName}
                    size={"medium"}
                    error={errors.firstName}
                    touched={touched.firstName}
                    label={"First name"}
                    placeholder={""}
                    type={"text"}
                    value={values.firstName}
                    changeHandler={event => {
                      setFieldValue(addContactFieldNames.firstName, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addContactFieldNames.lastName}
                    name={addContactFieldNames.lastName}
                    size={"medium"}
                    error={errors.lastName}
                    touched={touched.lastName}
                    label={"Last name"}
                    placeholder={""}
                    type={"text"}
                    value={values.lastName}
                    changeHandler={event => {
                      setFieldValue(addContactFieldNames.lastName, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addContactFieldNames.emailAddress}
                    name={addContactFieldNames.emailAddress}
                    size={"medium"}
                    error={errors.emailAddress}
                    touched={touched.emailAddress}
                    label={"Email address"}
                    placeholder={""}
                    type={"text"}
                    value={values.emailAddress}
                    changeHandler={event => {
                      setFieldValue(addContactFieldNames.emailAddress, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addContactFieldNames.phoneNumber}
                    name={addContactFieldNames.phoneNumber}
                    size={"medium"}
                    error={errors.phoneNumber}
                    touched={touched.phoneNumber}
                    label={"Phone number"}
                    placeholder={""}
                    type={"text"}
                    value={values.phoneNumber}
                    changeHandler={event => {
                      setFieldValue(addContactFieldNames.phoneNumber, event.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    isRequired
                    htmlFor={addContactFieldNames.jobTitle}
                    name={addContactFieldNames.jobTitle}
                    size={"medium"}
                    error={errors.jobTitle}
                    touched={touched.jobTitle}
                    label={"Job title"}
                    placeholder={""}
                    type={"text"}
                    value={values.jobTitle}
                    changeHandler={event => {
                      setFieldValue(addContactFieldNames.jobTitle, event.target.value);
                    }}
                  />
                </DataPoint>
              </PrimaryLineDataWrapper>

              <DataPoint halfWidth>
                <SimpleSelect
                  isRequired
                  htmlFor={addContactFieldNames.preferredCommunicationMethodId}
                  name={addContactFieldNames.preferredCommunicationMethodId}
                  maxMenuHeight={220}
                  error={errors.preferredCommunicationMethodId}
                  touched={touched.preferredCommunicationMethodId}
                  placeholder="Select communication method"
                  label="Contact's preferred communication method"
                  options={STOCK_MANAGEMENT_PREFERRED_COMMUNICATION_TYPES}
                  value={values.preferredCommunicationMethodId}
                  changeHandler={(event: any) => {
                    setFieldValue(addContactFieldNames.preferredCommunicationMethodId, event);
                  }}
                />
              </DataPoint>

              <DataPoint halfWidth>
                <SimpleSelect
                  htmlFor={addContactFieldNames.addressId}
                  name={addContactFieldNames.addressId}
                  maxMenuHeight={220}
                  error={errors.addressId}
                  touched={touched.addressId}
                  placeholder="Select address"
                  label="Which address should this contact be associated with?"
                  options={mapStockManagementAddressesToDropdownItem(addresses)}
                  value={values.addressId}
                  changeHandler={(event: any) => {
                    setFieldValue(addContactFieldNames.addressId, event);
                  }}
                />
              </DataPoint>
              <VisibleFor roles={SM_ADMIN_ROLES}>
                <ButtonGroup css={{ marginTop: "20px" }}>
                  <SecondaryButton type="button" appearance="outline" onClick={() => onClose()}>
                    Cancel
                  </SecondaryButton>
                  <SecondaryButton type="submit" appearance={"blueButton"} isWithinTable>
                    Save contact
                  </SecondaryButton>
                </ButtonGroup>
              </VisibleFor>
            </SecondaryLineWrapper>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default AddStockManagementContact;
