import ReactTooltip from "react-tooltip";

import { Icon, styled } from "@sourceful/shared-components";

const ToolTipWrapper = styled("div", {
  color: "#005AE1",
  paddingTop: "0px",
  marginLeft: "5px",
  marginTop: "0px",
});

export const LabelToolTipWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
});

const Label = styled("label", {
  fontSize: "15px",
  fontWeight: "bold",
  color: "#000000",
  variants: {
    isGray: {
      true: {
        color: "#8D8D8D",
      },
    },
    isRequired: {
      true: {
        "&::after": {
          content: "*",
        },
      },
    },
    title: {
      true: { fontSize: "28px", fontWeight: 700 },
    },
    smallTitle: {
      true: {
        fontSize: "17px",
        fontWeight: "bold",
        margin: "0px",
      },
    },
  },
});

interface ToolTipProps {
  message: string;
  index?: number;
  style?: any;
  title?: string;
  htmlFor?: string;
  smallTitleVariant?: boolean;
  isGray?: boolean;
  isRequired?: boolean;
}

export const ToolTip = ({
  message,
  index,
  style,
  title,
  htmlFor,
  smallTitleVariant,
  isGray,
  isRequired,
}: ToolTipProps) => {
  return (
    <LabelToolTipWrapper>
      {title && (
        <Label
          isGray={isGray}
          isRequired={isRequired}
          smallTitle={smallTitleVariant}
          htmlFor={htmlFor}
        >
          {title}
        </Label>
      )}
      <ToolTipWrapper style={style} data-tip={message} data-for={`tooltip_${index}`}>
        <Icon css={{ marginBottom: "10px" }} height={20} name="alert-information-outline"></Icon>
      </ToolTipWrapper>
      <ReactTooltip
        id={`tooltip_${index}`}
        place="top"
        effect="solid"
        type="light"
        border={true}
        borderColor="#000C32"
        html={true}
      />
    </LabelToolTipWrapper>
  );
};

export default ToolTip;
