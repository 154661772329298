import { Formik } from "formik";

import { ButtonGroup, CheckBox, IconText, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  STOCK_MANAGEMENT_CURRENCIES,
  STOCK_MANAGEMENT_LOCALES,
} from "../../../../../shared/constants";
import {
  mapCurrencyCodeToDropdownItem,
  mapLocaleToDropdownItem,
  mapPaymentDueDateTypeToDropdownItem,
} from "../../../../../shared/mappers";
import {
  DataPoint,
  FormattedLineDataWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { booleanReadableLabels, orderFlowTypes } from "../../shared/graphql/mappers";
import { Organisation } from "../../shared/graphql/types";
import {
  UpdateOrganisationDataInterface,
  initialUpdateOrganisationValues,
  updateOrganisationFieldNames,
} from "../../shared/types";
import { updateOrganisationValidationSchema } from "../validation";

interface Props {
  organisation: Organisation | null | undefined;
  setShowUpdateOrgDataForm: (value: boolean) => void;
  handleSubmitUpdateOrganisation: (
    values: UpdateOrganisationDataInterface,
    organisationId: string
  ) => Promise<void>;
}

const UpdateStockManagementOrganisationData = ({
  organisation,
  setShowUpdateOrgDataForm,
  handleSubmitUpdateOrganisation,
}: Props): JSX.Element => {
  if (!organisation) return <></>;

  return (
    <Formik
      initialValues={initialUpdateOrganisationValues(organisation)}
      enableReinitialize
      onSubmit={async (values: UpdateOrganisationDataInterface, { resetForm }) => {
        const organisationId = organisation.id as string;
        await handleSubmitUpdateOrganisation(values, organisationId);
        resetForm();
      }}
      validationSchema={updateOrganisationValidationSchema}
    >
      {({ values, setFieldValue, handleSubmit, errors, touched }) => {
        return (
          <StyledForm onSubmit={handleSubmit}>
            <TopPanelInputsWrapper>
              <DataPoint halfWidth>
                <DisplayBox
                  isGray
                  fullWidth
                  label="Stock Management Organisation ID"
                  tooltipMessage="Stock Management Organisation ID cannot be changed"
                  value={organisation?.id}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <DisplayBox
                  isGray
                  fullWidth
                  label="External Organisation ID"
                  tooltipMessage="External Organisation ID cannot be changed"
                  value={organisation?.externalOrganisationId}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleInput
                  tooltipMessage="Organisation name must match Auth0/IAM name"
                  htmlFor={updateOrganisationFieldNames.name}
                  name={updateOrganisationFieldNames.name}
                  size={"medium"}
                  error={errors.name}
                  touched={touched.name}
                  label={"Organisation name"}
                  type={"text"}
                  placeholder={""}
                  value={values.name}
                  changeHandler={event => {
                    setFieldValue(updateOrganisationFieldNames.name, event.target.value);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleSelect
                  htmlFor={updateOrganisationFieldNames.defaultCurrency}
                  name={updateOrganisationFieldNames.defaultCurrency}
                  maxMenuHeight={220}
                  error={errors.defaultCurrency}
                  touched={touched.defaultCurrency}
                  placeholder="Select currency code"
                  label="Default Currency Code"
                  options={mapCurrencyCodeToDropdownItem(
                    STOCK_MANAGEMENT_CURRENCIES.map(currency => {
                      return {
                        currency_code: currency,
                      };
                    })
                  )}
                  value={values.defaultCurrency}
                  changeHandler={event => {
                    setFieldValue(updateOrganisationFieldNames.defaultCurrency, event);
                  }}
                />
              </DataPoint>
              <DataPoint halfWidth>
                <SimpleSelect
                  htmlFor={updateOrganisationFieldNames.locale}
                  name={updateOrganisationFieldNames.locale}
                  maxMenuHeight={220}
                  error={errors.locale}
                  touched={touched.locale}
                  placeholder="Select locale"
                  label="Organisation Locale"
                  options={mapLocaleToDropdownItem(STOCK_MANAGEMENT_LOCALES)}
                  value={values.locale}
                  changeHandler={event => {
                    console.log("EVENT", event);
                    setFieldValue(updateOrganisationFieldNames.locale, event);
                  }}
                />
              </DataPoint>
            </TopPanelInputsWrapper>
            <FormattedLineDataWrapper>
              <Title>{organisation?.name} Config</Title>
            </FormattedLineDataWrapper>
            <PrimaryLineWrapper>
              <PrimaryLineDataWrapper>
                <DataPoint halfWidth>
                  <SimpleInput
                    tooltipMessage="example: '30 days after invoice', clients will see this on the invoice email"
                    htmlFor={updateOrganisationFieldNames.paymentTerms}
                    name={updateOrganisationFieldNames.paymentTerms}
                    size={"medium"}
                    error={errors.config?.paymentTerms}
                    touched={touched.config?.paymentTerms}
                    label={"Payment terms"}
                    type={"text"}
                    placeholder={""}
                    value={values.config?.paymentTerms}
                    changeHandler={event => {
                      setFieldValue(updateOrganisationFieldNames.paymentTerms, event.target.value);
                    }}
                  />
                </DataPoint>

                <DataPoint halfWidth>
                  <SimpleSelect
                    htmlFor={updateOrganisationFieldNames.paymentDueDateType}
                    name={updateOrganisationFieldNames.paymentDueDateType}
                    maxMenuHeight={220}
                    error={errors.config?.paymentDueDate?.type}
                    touched={touched.config?.paymentDueDate?.type}
                    placeholder="Select type"
                    label="Payment due date type"
                    options={mapPaymentDueDateTypeToDropdownItem([
                      {
                        value: "last_day_of_month",
                        readable: "Last day of the month",
                      },
                      {
                        value: "days_after_invoice",
                        readable: "(x) days after invoice",
                      },
                    ])}
                    value={values.config.paymentDueDate.type}
                    changeHandler={event => {
                      if (event?.value === "last_day_of_month") {
                        setFieldValue(updateOrganisationFieldNames.paymentDueDateValue, 0);
                      }
                      setFieldValue(updateOrganisationFieldNames.paymentDueDateType, event);
                    }}
                  />
                  {values.config.paymentDueDate.type?.label === "(x) days after invoice" && (
                    <SimpleInput
                      htmlFor={updateOrganisationFieldNames.paymentDueDateValue}
                      name={updateOrganisationFieldNames.paymentDueDateValue}
                      size={"medium"}
                      error={errors.config?.paymentDueDate?.value}
                      touched={touched.config?.paymentDueDate?.value}
                      label={"Payment due date: # of days"}
                      placeholder={"1"}
                      min={1}
                      type={"number"}
                      value={values.config.paymentDueDate.value}
                      changeHandler={event => {
                        setFieldValue(
                          updateOrganisationFieldNames.paymentDueDateValue,
                          Number(event.target.value)
                        );
                      }}
                    />
                  )}
                </DataPoint>

                <DataPoint halfWidth>
                  <SimpleInput
                    tooltipMessage="Name on Xero must match client name on Xero"
                    htmlFor={updateOrganisationFieldNames.xeroClientName}
                    name={updateOrganisationFieldNames.xeroClientName}
                    size={"medium"}
                    error={errors.config?.xeroClientName}
                    touched={touched.config?.xeroClientName}
                    label={"Name on Xero"}
                    type={"text"}
                    placeholder={""}
                    value={values.config.xeroClientName}
                    changeHandler={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.xeroClientName,
                        event.target.value
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleSelect
                    htmlFor={updateOrganisationFieldNames.orderFlowTypeId}
                    name={updateOrganisationFieldNames.orderFlowTypeId}
                    maxMenuHeight={220}
                    error={errors.config?.orderFlowTypeId}
                    touched={touched.config?.orderFlowTypeId}
                    placeholder="Select type"
                    label="Order flow type"
                    options={orderFlowTypes}
                    value={values.config.orderFlowTypeId}
                    changeHandler={event => {
                      setFieldValue(updateOrganisationFieldNames.orderFlowTypeId, event);
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <SimpleInput
                    tooltipMessage="This will be shown on the Stock Management 'view basket' page for clients to read."
                    htmlFor={updateOrganisationFieldNames.paymentDisclaimer}
                    name={updateOrganisationFieldNames.paymentDisclaimer}
                    size={"medium"}
                    error={errors.config?.paymentDisclaimer}
                    touched={touched.config?.paymentDisclaimer}
                    label={"Payment disclaimer"}
                    type={"text"}
                    placeholder={""}
                    value={values.config.paymentDisclaimer}
                    changeHandler={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.paymentDisclaimer,
                        event.target.value
                      );
                    }}
                  />
                </DataPoint>
              </PrimaryLineDataWrapper>
              <PrimaryLineDataWrapper>
                <DataPoint halfWidth>
                  <CheckBox
                    label={booleanReadableLabels.deliveryInvoicedSeparately}
                    value={updateOrganisationFieldNames.deliveryInvoicedSeparately}
                    id={updateOrganisationFieldNames.deliveryInvoicedSeparately}
                    isChecked={values.config.deliveryInvoicedSeparately ?? false}
                    handleChange={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.deliveryInvoicedSeparately,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <CheckBox
                    label={booleanReadableLabels.computeDeliveryCostPerProduct}
                    value={updateOrganisationFieldNames.computeDeliveryCostPerProduct}
                    id={updateOrganisationFieldNames.computeDeliveryCostPerProduct}
                    isChecked={values.config.computeDeliveryCostPerProduct ?? false}
                    handleChange={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.computeDeliveryCostPerProduct,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <CheckBox
                    label={booleanReadableLabels.enableClientReferenceCollection}
                    value={updateOrganisationFieldNames.enableClientReferenceCollection}
                    id={updateOrganisationFieldNames.enableClientReferenceCollection}
                    isChecked={values.config.enableClientReferenceCollection ?? false}
                    handleChange={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.enableClientReferenceCollection,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <CheckBox
                    label={booleanReadableLabels.prepaidStock}
                    value={updateOrganisationFieldNames.prepaidStock}
                    id={updateOrganisationFieldNames.prepaidStock}
                    isChecked={values.config.prepaidStock ?? false}
                    handleChange={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.prepaidStock,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
                <DataPoint halfWidth>
                  <CheckBox
                    label={booleanReadableLabels.displayProductUnitCost}
                    value={updateOrganisationFieldNames.displayProductUnitCost}
                    id={updateOrganisationFieldNames.displayProductUnitCost}
                    isChecked={values.config.displayProductUnitCost ?? false}
                    handleChange={event => {
                      setFieldValue(
                        updateOrganisationFieldNames.displayProductUnitCost,
                        event.target.checked
                      );
                    }}
                  />
                </DataPoint>
              </PrimaryLineDataWrapper>
            </PrimaryLineWrapper>
            <VisibleFor roles={SM_ADMIN_ROLES}>
              <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                <SecondaryButton
                  onClick={() => setShowUpdateOrgDataForm(false)}
                  appearance={"whiteButtonBlueText"}
                  isWithinTable
                  type="button"
                >
                  <IconText text={"Cancel"} primaryIcon={"alert-cross-outline"} />
                </SecondaryButton>
                <SecondaryButton type="submit" appearance={"blueButton"} isWithinTable>
                  <IconText text={"Update Organisation"} primaryIcon={"toggle-checkmark-check"} />
                </SecondaryButton>
              </ButtonGroup>
            </VisibleFor>
          </StyledForm>
        );
      }}
    </Formik>
  );
};

export default UpdateStockManagementOrganisationData;
