import {
  AvgResponseTimeItem,
  DeliveredOnTimeItem,
  SupplierDashboardDataItem,
  SupplierDashboardItem,
  TotalMoneyExclVATItem,
} from "../types";

const sumTotalMoneyExclVAT = (totalMoneyExclVAT: Array<TotalMoneyExclVATItem>): number => {
  return totalMoneyExclVAT.reduce((acc, item) => {
    if (item.purchase_order_lines) {
      const subTotal = item.purchase_order_lines.reduce((subAcc, line) => {
        if (line.calculated_quotation_cost !== undefined && line.quantity !== undefined) {
          return subAcc + line.calculated_quotation_cost * line.quantity;
        }
        return subAcc;
      }, 0);
      return acc + subTotal;
    }
    return acc;
  }, 0);
};

const calcDeliveredOnTimePercentage = (deliveredOnTimeData: Array<DeliveredOnTimeItem>): number => {
  let onTime = 0;
  let late = 0;

  for (const item of deliveredOnTimeData) {
    if (item.purchase_order_status_id === 5) {
      if (
        item.delivery_date &&
        item.expected_delivery_date &&
        item.delivery_date <= item.expected_delivery_date
      ) {
        onTime++;
      } else if (
        item.delivery_date &&
        item.expected_delivery_date &&
        item.delivery_date > item.expected_delivery_date
      ) {
        late++;
      }
    } else if (
      (item.purchase_order_status_id === 6 || item.purchase_order_status_id === 8) &&
      !item.delivery_date &&
      item.expected_delivery_date < new Date().toISOString()
    ) {
      late++;
    }
  }

  const totalOrders = onTime + late;

  if (totalOrders === 0) {
    return 0;
  }

  return (onTime / totalOrders) * 100;
};

const calcAverageResponseTime = (avgResponseTimeData: Array<AvgResponseTimeItem>): number => {
  let totalResponseTime = 0;
  let count = 0;

  for (const item of avgResponseTimeData) {
    const earliestApprovalTime =
      item.purchase_order_approvals_aggregate?.aggregate?.min?.created_at;
    const supplierResponseTime = item.purchase_order_supplier_response?.created_at;

    if (earliestApprovalTime && supplierResponseTime) {
      const responseTime =
        new Date(supplierResponseTime).getTime() - new Date(earliestApprovalTime).getTime();
      totalResponseTime += responseTime;
      count++;
    }
  }

  if (count === 0) return 0;

  return totalResponseTime / count;
};

export const mapSuppliersDashboard = (
  supplierData: SupplierDashboardDataItem[]
): SupplierDashboardItem[] => {
  return supplierData.map(supplier => ({
    xeroContactId: supplier.xero_contact_id,
    locale: supplier.locale,
    organisationName: supplier.organisation.organisation_name,
    totalMoneyExclVAT: sumTotalMoneyExclVAT(supplier.organisation.totalMoneyExclVAT),
    acceptedPurchaseOrdersCount:
      supplier.organisation.acceptedPurchaseOrdersCount.aggregate?.count || 0,
    rejectedExpiredPurchaseOrdersCount:
      supplier.organisation.rejectedExpiredPurchaseOrdersCount.aggregate?.count || 0,
    fulfilledPurchaseOrdersCount:
      supplier.organisation.fulfilledPurchaseOrdersCount.aggregate?.count || 0,
    averageResponseTime: calcAverageResponseTime(supplier.organisation.averageResponseTimeData),
    percentDeliveredOnTime: calcDeliveredOnTimePercentage(
      supplier.organisation.deliveredOnTimeData
    ),
  }));
};
