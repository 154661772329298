import { Formik, FormikErrors, FormikState } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { IconText } from "@sourceful/shared-components";

import Panel from "../../../../../../components/panel/panel";
import {
  ADMIN_ROLES,
  SOURCING_EDIT_ROLES,
} from "../../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../../redux/store/storeTypes";
import { SecondaryButton } from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleInput from "../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../../../shared/components/forms/SimpleTextArea";
import { FormButtonPair } from "../../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../../shared/components/wrappers/VisibleFor";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { QuotationCostTypeTranslationLine } from "../../shared/components/QuotationCostTypeTranslationLine/QuotationCostTypeTranslationLine";
import { useQuotationCostLocales } from "../../shared/graphql/hooks/useQuotationCostLocales";
import { costRequiredOptions, getCostRequiredValue } from "../../shared/mappers";
import {
  AdminQuotationCostTypeTranslationInterface,
  emptyQuotationCostTranslation,
} from "../../shared/types";
import { CreateQuotationCostTypeBody } from "../api/types";
import { fieldNames, initialFieldValues } from "../formValues";
import { useQuotationCostAccountTypes } from "../graphql/hooks/useQuotationCostAccountTypes";
import { useQuotationCostDistributions } from "../graphql/hooks/useQuotationCostDistributions";
import {
  createQuotationCostTypeAction,
  createQuotationCostTypeReset,
} from "../redux/actions/createQuotationCostTypeAction";
import { CreateQuotationCostTypeReducer } from "../redux/reducers/createQuotationCostTypeReducer";
import { AdminQuotationCostTypeInterface } from "../types";
import { validationSchema } from "../validation";

interface Props {
  createQuotationCostTypeState: CreateQuotationCostTypeReducer;
  createQuotationCostType: (body: CreateQuotationCostTypeBody) => void;
  resetCreateQuotationCostType: () => void;
}

const CreateQuotationCostType = (props: Props) => {
  const [initialValues, setInitialValues] =
    useState<AdminQuotationCostTypeInterface>(initialFieldValues);

  const { createQuotationCostTypeState } = props;

  const {
    quotationCostAccountTypes,
    quotationCostAccountTypesLoading,
    quotationCostAccountTypesError,
  } = useQuotationCostAccountTypes();

  const {
    quotationCostDistributions,
    quotationCostDistributionsLoading,
    quotationCostDistributionsError,
  } = useQuotationCostDistributions();

  const { locales, localesLoading, localesError } = useQuotationCostLocales();

  const onSubmit = async (
    values: AdminQuotationCostTypeInterface,
    {
      resetForm,
    }: { resetForm: (nextState?: Partial<FormikState<AdminQuotationCostTypeInterface>>) => void }
  ) => {
    const translationLocales = values.quotation_cost_translations.map(
      translationLine => translationLine.locale!.label
    );

    if (translationLocales.length !== _.uniq(translationLocales).length) {
      toast.error("Please make sure each locale has at most one translation.");
      return;
    }

    const translation = Object.assign(
      {
        en_GB: {
          name: values.quotation_cost_name,
          description: values.quotation_cost_description,
        },
      },
      ...values.quotation_cost_translations.map(translationLine => ({
        [translationLine.locale!.label]: {
          name: translationLine.quotation_cost_name,
          description: translationLine.quotation_cost_description,
        },
      }))
    );

    const quotationCostType: CreateQuotationCostTypeBody = {
      quotation_cost_type_name: values.quotation_cost_name,
      quotation_cost_distribution_id: values.quotaiton_cost_distribution!.value,
      cost_description: values.quotation_cost_description,
      quotation_cost_account_type_id: values.quotaiton_cost_account_type!.value,
      translation: JSON.stringify(translation),
      is_cost_required_default: values.is_cost_required_default,
      is_cost_required_sample: values.is_cost_required_sample,
    };

    props.createQuotationCostType(quotationCostType);
    resetForm();
  };

  useEffect(() => {
    if (createQuotationCostTypeState.success) {
      props.resetCreateQuotationCostType();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createQuotationCostTypeState.success, createQuotationCostTypeState.error]);

  const error = quotationCostAccountTypesError || quotationCostDistributionsError || localesError;
  const isLoading =
    quotationCostAccountTypesLoading || quotationCostDistributionsLoading || localesLoading;

  return (
    <VisibleFor roles={ADMIN_ROLES}>
      <Page title={"Create Quotation Cost Type"} hasBackButton error={error} isLoading={isLoading}>
        <Panel title={`Create Quotation Cost Type`} withWrapper={true}>
          <InnerPanelWrapper>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {formikProps => {
                const { values, setFieldValue, handleSubmit, handleReset, errors, touched } =
                  formikProps;
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <PrimaryLineWrapper>
                      <PrimaryLineDataWrapper>
                        <DataPoint>
                          <SimpleInput
                            isRequired
                            name={fieldNames.quotation_cost_name}
                            type={"text"}
                            placeholder={"Input name for cost type"}
                            value={values.quotation_cost_name}
                            error={errors.quotation_cost_name}
                            touched={touched.quotation_cost_name}
                            label="Cost Type Name"
                            changeHandler={e =>
                              setFieldValue(fieldNames.quotation_cost_name, e.target.value)
                            }
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            name={fieldNames.quotaiton_cost_distribution}
                            placeholder={"Select cost distribution"}
                            options={quotationCostDistributions}
                            value={values.quotaiton_cost_distribution}
                            error={errors.quotaiton_cost_distribution}
                            touched={touched.quotaiton_cost_distribution}
                            label={"Cost Distribution"}
                            changeHandler={e =>
                              setFieldValue(fieldNames.quotaiton_cost_distribution, e)
                            }
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleSelect
                            isRequired={true}
                            name={fieldNames.quotaiton_cost_account_type}
                            placeholder={"Select account type"}
                            options={quotationCostAccountTypes}
                            value={values.quotaiton_cost_account_type}
                            error={errors.quotaiton_cost_account_type}
                            touched={touched.quotaiton_cost_account_type}
                            label={"Account Type"}
                            changeHandler={e =>
                              setFieldValue(fieldNames.quotaiton_cost_account_type, e)
                            }
                          />
                        </DataPoint>
                        <DataPoint fullWidth css={{ marginTop: "20px" }}>
                          <SimpleTextArea
                            label="Cost Description"
                            type={"text"}
                            value={values.quotation_cost_description}
                            placeholder="Please provide a description of what the cost includes/represents, which will be meaningful for both suppliers and internal teams."
                            handleChange={e => {
                              setFieldValue(fieldNames.quotation_cost_description, e.target.value);
                            }}
                          />
                          {errors.quotation_cost_description &&
                          touched.quotation_cost_description ? (
                            <ErrorMessage>{errors.quotation_cost_description}</ErrorMessage>
                          ) : null}
                        </DataPoint>
                      </PrimaryLineDataWrapper>

                      <PrimaryLineDataWrapper>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            tooltipMessage="Setting this value to true will make this cost type required by default for all suppliers when completing an RFQ."
                            htmlFor={"is_cost_required_default"}
                            name={"is_cost_required_default"}
                            value={getCostRequiredValue(values.is_cost_required_default)}
                            error={errors.is_cost_required_default}
                            touched={touched.is_cost_required_default}
                            label="Is Cost Required Default"
                            changeHandler={e => {
                              setFieldValue("is_cost_required_default", e ? e.value : false);
                            }}
                            placeholder="Is Cost Required Default"
                            options={costRequiredOptions}
                          />
                        </DataPoint>
                        <DataPoint halfWidth>
                          <SimpleSelect
                            isRequired
                            tooltipMessage="Setting this value to true will make this cost type required in samples for all suppliers when completing an RFQ."
                            htmlFor={"is_cost_required_sample"}
                            name={"is_cost_required_sample"}
                            value={getCostRequiredValue(values.is_cost_required_sample)}
                            error={errors.is_cost_required_sample}
                            touched={touched.is_cost_required_sample}
                            label="Is Cost Required Sample"
                            changeHandler={e => {
                              setFieldValue("is_cost_required_sample", e ? e.value : false);
                            }}
                            placeholder="Is Cost Required Sample"
                            options={costRequiredOptions}
                          />
                        </DataPoint>
                      </PrimaryLineDataWrapper>

                      {values.quotation_cost_translations.length > 0 && (
                        <PrimaryLineWrapper>
                          {values.quotation_cost_translations.map((translationLine, index) => (
                            <QuotationCostTypeTranslationLine
                              key={`translation_line_${index}`}
                              values={translationLine}
                              locales={locales}
                              index={index}
                              errors={
                                errors.quotation_cost_translations as FormikErrors<
                                  AdminQuotationCostTypeTranslationInterface[]
                                >
                              }
                              touched={touched.quotation_cost_translations}
                              handleRemoveTranslationLine={(lineIndex: number) => {
                                const newValues = { ...values };
                                newValues.quotation_cost_translations =
                                  values.quotation_cost_translations.filter(
                                    (_item, i) => i !== lineIndex
                                  );
                                setInitialValues(newValues);
                              }}
                              setFieldValue={setFieldValue}
                            />
                          ))}
                        </PrimaryLineWrapper>
                      )}
                    </PrimaryLineWrapper>

                    <VisibleFor roles={SOURCING_EDIT_ROLES}>
                      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                        <SecondaryButton
                          type="button"
                          appearance={"whiteButtonBlueText"}
                          onClick={() => {
                            setInitialValues({
                              ...values,
                              quotation_cost_translations: [
                                ...values.quotation_cost_translations,
                                emptyQuotationCostTranslation,
                              ],
                            });
                          }}
                        >
                          <IconText
                            text={"Add Translation Line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </VisibleFor>

                    <FormButtonPair handleReset={handleReset} />
                  </StyledForm>
                );
              }}
            </Formik>
          </InnerPanelWrapper>
        </Panel>
      </Page>
    </VisibleFor>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createQuotationCostTypeState: state.createQuotationCostTypeReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createQuotationCostType: (body: CreateQuotationCostTypeBody) =>
      dispatch(createQuotationCostTypeAction(body)),
    resetCreateQuotationCostType: () => dispatch(createQuotationCostTypeReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateQuotationCostType);
