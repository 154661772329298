import { AddressNameItem, OrganisationNameItem } from "../graphql/types";

export const mapAddressNamesToDrodownObject = (addressNames: AddressNameItem[]) => {
  return addressNames.map(addressName => {
    return {
      label: addressName.address_name,
      value: addressName.id,
    };
  });
};

export const mapOrganisationNamesToDrodownObject = (organisationNames: OrganisationNameItem[]) => {
  return organisationNames.map(organisationName => {
    return {
      label: organisationName.organisation_name,
      value: organisationName.id,
    };
  });
};
