import { ApolloError } from "@apollo/client";

import { useGetAllCourierNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapCourierNamesToDrodownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  courierNames?: DropdownItem[];
  courierNamesError?: ApolloError;
  courierNamesLoading: boolean;
}

export const useAllCourierNames = (): HookResult => {
  const { data, error, loading } = useGetAllCourierNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    courierNames:
      data && Array.isArray(data.courier) ? mapCourierNamesToDrodownObject(data.courier) : [],
    courierNamesError: error,
    courierNamesLoading: loading,
  };
};
