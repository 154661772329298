import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";
import { BaseProductVersion } from "../../shared/types";
import { mapResponseToBaseProductVersion } from "./mappers";

export const getBaseProductVersionsByBaseProduct = async (
  baseProductId: string
): Promise<BaseProductVersion[]> => {
  try {
    const response = await PRODUCT_CATALOGUE_API.get(
      `/baseproductversions?base_product_id=eq:${baseProductId}`
    );

    return Array.isArray(response.data.data)
      ? mapResponseToBaseProductVersion(response.data.data)
      : [];
  } catch (err) {
    console.log("Error in getting products API call", err);
    throw err;
  }
};
