import { ApolloError } from "@apollo/client";

import { useGetSupplierQuoteRequestByIdQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapToSupplierQuoteRequestObject } from "../mappers";
import { SupplierQuoteRequestObject } from "../types";

export interface HookResult {
  supplierQuoteRequest?: SupplierQuoteRequestObject;
  supplierQuoteRequestError?: ApolloError;
  supplierQuoteRequestLoading: boolean;
}

export const useSupplierQuoteRequest = (rfqId: number): HookResult => {
  const { data, error, loading } = useGetSupplierQuoteRequestByIdQuery({
    context: { clientName: GRAPHQL_SOURCING },
    variables: { rfqId },
  });

  return {
    supplierQuoteRequest:
      data && Array.isArray(data.supplier_quote_request)
        ? mapToSupplierQuoteRequestObject(data.supplier_quote_request[0])
        : undefined,
    supplierQuoteRequestError: error,
    supplierQuoteRequestLoading: loading,
  };
};
