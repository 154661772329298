import { ApolloError } from "@apollo/client";

import { useGetLocationByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { LocationItem } from "../types";

export interface HookResult {
  location?: LocationItem;
  locationError?: ApolloError;
  locationLoading: boolean;
}

export const useLocationById = (locationId: number): HookResult => {
  const { data, error, loading } = useGetLocationByIdQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
    variables: { locationId },
  });

  return {
    location: data && Array.isArray(data.location) ? data.location[0] : undefined,
    locationError: error,
    locationLoading: loading,
  };
};
