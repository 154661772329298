import { StockCheckItem, StockCheckLineItem } from "../graphql/types";

interface ActiveStockCheckLine {
  selectedLineId: number | null;
  lineId: number;
}

export const isActiveStockCheckLine = ({
  lineId,
  selectedLineId,
}: ActiveStockCheckLine): boolean => {
  if (lineId === selectedLineId) {
    return true;
  }
  return false;
};

export const calculateStockCheckPercentageAccuracy = ({
  stockCheck,
}: {
  stockCheck: StockCheckItem;
}): number => {
  const totalExpectedQuantities = stockCheck.stock_check_lines.reduce((accumulator, line) => {
    return accumulator + line.expected_quantity;
  }, 0);

  const stockCheckLineFulfilmentQuantities = stockCheck.stock_check_lines.map(line => {
    return line.stock_check_line_fulfilments.reduce((accumulator, fulfilment) => {
      return accumulator + fulfilment.quantity;
    }, 0);
  });

  const totalFulfilmentQuantities = stockCheckLineFulfilmentQuantities.reduce(
    (accumulator, quantity) => accumulator + quantity,
    0
  );

  const inaccuracy = totalFulfilmentQuantities / totalExpectedQuantities;

  return 100 - inaccuracy * 100;
};

export const calculateStockCheckLinePercentageAccuracy = ({
  stockCheckLine,
}: {
  stockCheckLine: StockCheckLineItem;
}): number => {
  const totalFulfilmentQuantity = stockCheckLine.stock_check_line_fulfilments.reduce(
    (accumulator, fulfilment) => {
      return accumulator + fulfilment.quantity;
    },
    0
  );

  const inaccuracy = totalFulfilmentQuantity / stockCheckLine.expected_quantity;
  return 100 - inaccuracy * 100;
};

export const accuracyClassifier = ({ percentageAccuracy }: { percentageAccuracy: number }) => {
  return percentageAccuracy < 100 ? "bad" : "good";
};
