import { Formik, FormikErrors, FormikState } from "formik";
import { uniqBy } from "lodash";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { SimpleDateInput } from "../../../../../shared/components/forms/SimpleDateInput";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { CreateStockCheckBody } from "../../shared/api/createStockCheck/types";
import { useWarehouseNames } from "../../shared/graphql/hooks/useWarehouseNames";
import {
  createStockCheckAction,
  createStockCheckReset,
} from "../../shared/redux/actions/createStockCheckAction";
import { CreateStockCheckReducer } from "../../shared/redux/reducers/createStockCheckReducer";
import AdhocStockCheckLine from "../components/AdhocStockCheckLine";
import {
  AdhocStockCheckForm,
  StockCheckLine,
  emptyStockCheckLine,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { useProductStockByWarehouseId } from "../graphql/hooks/useProductStockByWarehouseId";
import { mapStockCheckLinesToDTO } from "../mappers";
import { validationSchema } from "../validation";

interface Props {
  createStockCheckState: CreateStockCheckReducer;
  createStockCheck: (body: CreateStockCheckBody) => void;
  resetCreateStockCheck: () => void;
}

function CreateAdhocStockCheck(props: Props) {
  const [initialValues, setInitialValues] = useState<AdhocStockCheckForm>(initialFieldValues);
  const [isAddingProductStockLines, setIsAddingProductStockLines] = useState<boolean>(false);

  const { createStockCheckState } = props;

  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useWarehouseNames();
  const { productStock, productStockLoading, productStockError, getProductStockByWarehouseId } =
    useProductStockByWarehouseId();

  const onSubmit = (
    values: AdhocStockCheckForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<AdhocStockCheckForm>>) => void }
  ) => {
    const stockCheckLines = mapStockCheckLinesToDTO(values.stock_check_lines);

    const createStockCheckBody: CreateStockCheckBody = {
      stockCheckTypeId: 2,
      stockCheckLines: [...stockCheckLines],
      notes: values.notes,
    };

    if (values.due_date !== "") {
      createStockCheckBody.dueDate = new Date(values.due_date);
    }

    const uniqueLines = uniqBy(
      stockCheckLines,
      stock_check_line => stock_check_line.productStockId
    );

    if (uniqueLines.length !== stockCheckLines.length) {
      toast.warning(
        `Duplicate Detected. You have used a duplicate product stock, please ensure no duplicate product stocks`
      );
      return;
    }

    props.createStockCheck(createStockCheckBody);
    resetForm();
  };

  useEffect(() => {
    if (createStockCheckState.success) {
      props.resetCreateStockCheck();
      setInitialValues({ ...initialFieldValues });
      setIsAddingProductStockLines(false);
    }

    if (createStockCheckState.error) {
      props.resetCreateStockCheck();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createStockCheckState.success, createStockCheckState.error]);

  const error = warehouseNamesError || productStockError;
  const isLoading = warehouseNamesLoading || createStockCheckState.loading || productStockLoading;

  return (
    <Page title={"Create Stock Check"} error={error} isLoading={isLoading}>
      <Panel withWrapper title={"Create Adhoc Stock Check "} subtitle={"Create Adhoc Stock Check"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              handleReset,
              validateForm,
              setTouched,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                {isAddingProductStockLines ? (
                  <TopPanelInputsWrapper>
                    <DisplayBox isGray label="Warehouse" value={values.warehouse!.label} />
                    <DisplayBox isGray label="Due Date" value={values.due_date} />
                    <DisplayBox isGray label="Notes" value={values.notes} />
                  </TopPanelInputsWrapper>
                ) : (
                  <>
                    <TopPanelInputsWrapper>
                      <DataPoint>
                        <SimpleSelect
                          htmlFor={fieldNames.warehouse}
                          touched={touched.warehouse}
                          error={errors.warehouse}
                          options={warehouseNames}
                          name={fieldNames.warehouse}
                          value={values.warehouse}
                          changeHandler={e => {
                            setFieldValue(fieldNames.warehouse, e);
                          }}
                          placeholder="Select Warehouse"
                          label="Warehouse"
                          isRequired
                        />
                      </DataPoint>
                      <DataPoint>
                        <SimpleDateInput
                          htmlFor={fieldNames.due_date}
                          label="Due Date"
                          value={values.due_date}
                          changeHandler={e => {
                            setFieldValue(fieldNames.due_date, e.target.value);
                          }}
                          error={errors.due_date}
                          touched={touched.due_date}
                          name={fieldNames.due_date}
                        />
                      </DataPoint>
                      <DataPoint>
                        <SimpleInput
                          htmlFor={fieldNames.notes}
                          label="Notes"
                          type="text"
                          value={values.notes}
                          placeholder={"Input notes"}
                          changeHandler={e => {
                            setFieldValue(fieldNames.notes, e.target.value);
                          }}
                          error={errors.notes}
                          touched={touched.notes}
                          name={fieldNames.notes}
                        />
                      </DataPoint>
                    </TopPanelInputsWrapper>
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        appearance="whiteButtonBlueText"
                        type="button"
                        onClick={async () => {
                          setTouched({
                            warehouse: true,
                            due_date: true,
                          });

                          const formErrors = await validateForm();
                          if (Object.keys(formErrors).length === 0) {
                            setInitialValues({
                              ...values,
                              stock_check_lines: [emptyStockCheckLine],
                            });
                            setIsAddingProductStockLines(true);
                            getProductStockByWarehouseId({
                              warehouseId: values.warehouse?.value!,
                            });
                          }
                        }}
                      >
                        <IconText text={"Start Stock Check"} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  </>
                )}

                {isAddingProductStockLines && (
                  <>
                    <PrimaryLineWrapper>
                      <ToolTip
                        message={"Select stock lines to check <br/> on an ad-hoc basis"}
                        smallTitleVariant
                        title={"Stock Check Lines"}
                      />
                      {values.stock_check_lines.map((line, index) => (
                        <AdhocStockCheckLine
                          dropdownData={productStock}
                          errors={errors.stock_check_lines as FormikErrors<StockCheckLine[]>}
                          touched={touched.stock_check_lines}
                          values={line}
                          index={index}
                          setFieldValue={setFieldValue}
                          handleRemoveCheckLine={(removeIndex: number) => {
                            const fitleredManifestLines = values.stock_check_lines.filter(
                              (_, i) => i !== removeIndex
                            );
                            const newValues = { ...values };
                            newValues.stock_check_lines = fitleredManifestLines;
                            setInitialValues(newValues);
                            if (fitleredManifestLines.length === 0) {
                              setIsAddingProductStockLines(false);
                            }
                          }}
                        />
                      ))}

                      <ButtonWrapper>
                        <SecondaryButton
                          type="button"
                          appearance="whiteButtonBlueText"
                          className={WMSButtonGroup({ type: "largeMargin" })}
                          onClick={() => {
                            setInitialValues({
                              ...values,
                              stock_check_lines: [...values.stock_check_lines, emptyStockCheckLine],
                            });
                          }}
                        >
                          <IconText
                            text={"Add Stock Check Line"}
                            primaryIcon={"alert-add-outline"}
                          />
                        </SecondaryButton>
                      </ButtonWrapper>
                    </PrimaryLineWrapper>

                    <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                      <SecondaryButton
                        type="button"
                        appearance="whiteButton"
                        onClick={() => {
                          setIsAddingProductStockLines(false);
                          setInitialValues({ ...initialFieldValues });
                          handleReset();
                        }}
                      >
                        Cancel
                      </SecondaryButton>
                      <SecondaryButton appearance="blueButton" type="submit">
                        Confirm
                      </SecondaryButton>
                    </ButtonGroup>
                  </>
                )}
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createStockCheckState: state.createStockCheckReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createStockCheck: (body: CreateStockCheckBody) => dispatch(createStockCheckAction(body)),
    resetCreateStockCheck: () => dispatch(createStockCheckReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdhocStockCheck);
