import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { getQuotationCalculationAPI } from "../../api/getQuotationCalculation";
import GetQuotationCalculationBody from "../../api/types/getQuotationCalculation";
import {
  GET_QUOTATION_CALCULATION_FAILED,
  GET_QUOTATION_CALCULATION_LOADING,
  GET_QUOTATION_CALCULATION_RESET,
  GET_QUOTATION_CALCULATION_SUCCESS,
} from "../types/getQuotationCalculation";

export const getQuotationCalculationLoading = () => {
  return {
    type: GET_QUOTATION_CALCULATION_LOADING,
  };
};

export const getQuotationCalculationSuccess = () => {
  return {
    type: GET_QUOTATION_CALCULATION_SUCCESS,
  };
};

export const getQuotationCalculationFailed = () => {
  return {
    type: GET_QUOTATION_CALCULATION_FAILED,
  };
};

export const getQuotationCalculationReset = () => {
  return {
    type: GET_QUOTATION_CALCULATION_RESET,
  };
};

export const getQuotationCalculationAction = (body: GetQuotationCalculationBody) => {
  return async (dispatch: Dispatch) => {
    dispatch(getQuotationCalculationLoading());
    try {
      const quotationCalculationResult = await getQuotationCalculationAPI(body);
      dispatch(getQuotationCalculationSuccess());
      return quotationCalculationResult;
    } catch (err) {
      toast.error("Something went wrong with getting quotation calculation.");
      dispatch(getQuotationCalculationFailed());
      return [];
    }
  };
};
