import { CHANGE_STOCK_CONDITION, MISSING_STOCK, NEW_STOCK } from "../constants";

export interface FulfilStockCheckLineForm {
  action: {
    value: number;
    label: string;
  } | null;
  quantity: string;
  stock_condition: {
    value: number;
    label: string;
  } | null;
}

export const fieldNames = {
  action: "action",
  quantity: "quantity",
  stock_condition: "stock_condition",
};

export const initialFieldValues: FulfilStockCheckLineForm = {
  action: null,
  quantity: "",
  stock_condition: null,
};

export const stockCheckFulfilmentActions = [
  {
    label: MISSING_STOCK,
    value: 0,
  },
  {
    label: NEW_STOCK,
    value: 1,
  },
  {
    label: CHANGE_STOCK_CONDITION,
    value: 2,
  },
];
