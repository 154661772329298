import * as Yup from "yup";

import {
  STOCK_MANAGEMENT_CURRENCIES,
  STOCK_MANAGEMENT_UK_LOCALE,
  STOCK_MANAGEMENT_US_LOCALE,
} from "../../../../../shared/constants";

export const organisationConfigValidationSchema = Yup.object({
  paymentTerms: Yup.string().required("Payment terms is required"),
  paymentDueDate: Yup.object()
    .shape({
      type: Yup.object()
        .shape({
          value: Yup.string().oneOf(["days_after_invoice", "last_day_of_month"]),
        })
        .required("Payment due date type is required")
        .typeError("Payment due date type is required"),
      value: Yup.number(),
    })
    .required("Payment due date is required"),
  xeroClientName: Yup.string().required("Xero client name is required"),
  orderFlowTypeId: Yup.object()
    .shape({ value: Yup.number(), label: Yup.string() })
    .required("Order flow type is required")
    .typeError("Order flow type is required"),
  autostockEnabled: Yup.boolean().required("Autostock is required"),
  mainProviderType: Yup.string().required("Main provider type is required"),
  paymentDisclaimer: Yup.string().required("Payment disclaimer is required"),
  productsSourceType: Yup.string().required("Products source type is required"),
  selfserviceEnabled: Yup.boolean().required("Selfservice is required"),
  orderProcessingType: Yup.string().required("Order processing type is required"),
  productPricingSourceType: Yup.string().required("Product pricing source type is required"),
  deliveryInvoicedSeparately: Yup.boolean().required().default(false),
  computeDeliveryCostPerProduct: Yup.boolean().required().default(false),
  enableClientReferenceCollection: Yup.boolean().required().default(false),
  prepaidStock: Yup.boolean().required().default(false),
  displayProductUnitCost: Yup.boolean().required().default(false),
});

export const createOrganisationDataValidationSchema = Yup.object({
  name: Yup.string().required("Organisation name is required"),
  locale: Yup.object()
    .shape({
      label: Yup.string().oneOf([STOCK_MANAGEMENT_UK_LOCALE, STOCK_MANAGEMENT_US_LOCALE]),
      value: Yup.number(),
    })
    .required("Locale is required")
    .typeError("Locale is required"),
  defaultCurrency: Yup.object()
    .shape({
      label: Yup.string().oneOf(STOCK_MANAGEMENT_CURRENCIES),
      value: Yup.number(),
    })
    .required("Default currency is required")
    .typeError("Default currency is required"),
  externalOrganisationId: Yup.object()
    .shape({
      value: Yup.string(),
    })
    .required("IAM Organisation is required")
    .typeError("IAM Organisation is required"),
  config: organisationConfigValidationSchema,
});
