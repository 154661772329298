import { ButtonGroup } from "@sourceful/shared-components";

import { ShipmentContainer } from ".";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import Page from "../../../../../shared/components/templates/Page";
import { WMSButtonGroup } from "../../../../../styles/SharedStyles";
import { SplitShipmentProps } from "../types";

const SplitShipmentForm = ({
  handleSplitShipmentResponse,
  shipmentId,
  reset,
}: SplitShipmentProps) => {
  return (
    <Page hasBackButton={false} title="Split Shipment" isLoading={false}>
      <ShipmentContainer css={{ marginTop: "30px" }}>
        Are you sure you'd like to continue splitting this shipment in <b> two</b>? This action is
        irreversible with the following consequences:
        <ul>
          <li> You'll have to generate updates for each shipment. </li>
          <li>
            The client will be notified about the split and will expect two separate shipments.
          </li>
        </ul>
        <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
          <SecondaryButton appearance={"whiteButton"} onClick={() => reset()}>
            Cancel
          </SecondaryButton>
          <SecondaryButton
            appearance={"blueButton"}
            onClick={() => handleSplitShipmentResponse(shipmentId)}
          >
            Continue
          </SecondaryButton>
        </ButtonGroup>
      </ShipmentContainer>
    </Page>
  );
};

export default SplitShipmentForm;
