import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useStockAllocations } from "../graphql/hooks/useStockAllocations";
import { StockAllocation } from "../graphql/types";

const ViewStockAllocations = () => {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { stockAllocations, stockAllocationsLoading, stockAllocationsError } =
    useStockAllocations();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.1,
        accessor: (stockAllocation: StockAllocation) => stockAllocation.id,
      },
      {
        Header: "Warehouse",
        widthPercent: 1.5,
        accessor: (stockAllocation: StockAllocation) => stockAllocation.warehouse.warehouse_name,
        Cell: ({ _, row }: CellProps<StockAllocation>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
      },

      {
        Header: "From Org",
        widthPercent: 0.6,
        accessor: (stockAllocation: StockAllocation) =>
          stockAllocation.organisation.organisation_name,
      },

      {
        Header: "To Org",
        widthPercent: 0.5,
        accessor: (stockAllocation: StockAllocation) =>
          stockAllocation.organisationByToOrganisationId.organisation_name,
      },
      {
        Header: "Quantity",
        widthPercent: 0.5,
        accessor: (stockAllocation: StockAllocation) => stockAllocation.quantity,
      },
    ],
    []
  );

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/operations/stock-allocation/create-stock-allocation");
          },
          type: "button",
          title: "Create Stock Allocation",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  return (
    <Page
      title="Stock Allocations"
      error={stockAllocationsError}
      isLoading={stockAllocationsLoading}
    >
      <Panel
        withWrapper
        title={"Stock Allocation List"}
        text={"Use the table below to explore stock allocations"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table
          data={stockAllocations}
          columns={columns}
          renderFilters={(_: FilterSetter<any>) => <></>}
        />
      </Panel>
    </Page>
  );
};

export default ViewStockAllocations;
