import { ApolloError } from "@apollo/client";

import { useGetPurchaseOrderForSupplierResponseQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { PurchaseOrderItem } from "../types";

export interface HookResult {
  purchaseOrder?: PurchaseOrderItem;
  purchaseOrderError?: ApolloError;
  purchaseOrderLoading: boolean;
}

export interface Options {
  purchaseOrderId: number;
  supplierOrgId: number;
}

export const usePurchaseOrderForSupplierResponse = ({
  purchaseOrderId,
  supplierOrgId,
}: Options): HookResult => {
  const { data, error, loading } = useGetPurchaseOrderForSupplierResponseQuery({
    variables: { purchaseOrderId, supplierOrgId },
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    purchaseOrder: data?.purchase_order[0],
    purchaseOrderError: error,
    purchaseOrderLoading: loading,
  };
};
