import moment from "moment";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ButtonGroup } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  InfoBox,
  InnerPanelWrapper,
  Label,
  PrimaryLineWrapper,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import StockCheckLineFulfilmentProductStock from "../../shared/components/StockCheckLineFulfilmentProductStock";
import StockCheckLineOriginalStock from "../../shared/components/StockCheckLineOriginalStock";
import StockCheckLineToFulfil from "../../shared/components/StockCheckLineToFulfil";
import { useStockCheckById } from "../../shared/graphql/hooks/useStockCheckById";
import {
  accuracyClassifier,
  calculateStockCheckPercentageAccuracy,
  isActiveStockCheckLine,
} from "../../shared/helpers";

function ViewStockCheck() {
  const { stock_check_id } = useParams<{ stock_check_id: string }>();
  const [lineToDisplayFulfilments, setLineToDisplayFulfilments] = useState<number | null>(null);
  const [lineToDisplayOriginalStock, setLineToDisplayOriginalStock] = useState<number | null>(null);
  const [percentageAccuracy, setPercentageAccuracy] = useState<number | null>(null);

  const { stockCheckLoading, stockCheck, stockCheckError } = useStockCheckById(
    Number(stock_check_id)
  );

  const isError = stockCheckError;
  const isLoading = stockCheckLoading;

  useEffect(() => {
    if (stockCheck) {
      const accuracy = calculateStockCheckPercentageAccuracy({ stockCheck: stockCheck });
      setPercentageAccuracy(accuracy);
    }
  }, [stockCheck]);

  return (
    <>
      <Page error={isError} isLoading={isLoading} title={"Operations - View Stock Check"}>
        <Panel
          withWrapper
          title={"View Stock Check"}
          subtitle={"Use the form below to View a  stock check"}
          sourcingStatusName={stockCheck?.stock_check_status.stock_check_status_name}
        >
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DataPoint>
                <Label isGray>Stock Check ID</Label>
                <Box>#{stockCheck?.id}</Box>
              </DataPoint>
              <DataPoint>
                <Label isGray>Stock Check Type</Label>
                <Box>{stockCheck?.stock_check_type.stock_check_type_name}</Box>
              </DataPoint>
              <DataPoint>
                <Label isGray>Location</Label>

                <Box>{stockCheck?.location?.location_name ?? "None Provided"}</Box>
              </DataPoint>
              <DataPoint>
                <Label isGray>Status</Label>
                <Box>{stockCheck?.stock_check_status?.stock_check_status_name}</Box>
              </DataPoint>
              <DataPoint>
                <Label isGray>Due Date</Label>
                <Box>
                  {stockCheck?.due_date
                    ? moment(stockCheck?.due_date).format("DD/MM/YYYY")
                    : "None Provided"}
                </Box>
              </DataPoint>
              <DataPoint>
                <Label isGray>Notes</Label>
                <Box>{stockCheck?.notes ? stockCheck?.notes : "None Provided"}</Box>
              </DataPoint>
              <DataPoint>
                <Label isGray> Accuracy</Label>
                {percentageAccuracy && (
                  <InfoBox type={accuracyClassifier({ percentageAccuracy: percentageAccuracy })}>
                    {percentageAccuracy.toFixed(2)} %
                  </InfoBox>
                )}
              </DataPoint>
            </TopPanelInputsWrapper>

            {stockCheck?.stock_check_lines.map((stockCheckLine, index) => {
              const isActiveDisplayFulfillmentsLine = isActiveStockCheckLine({
                lineId: stockCheckLine.id,
                selectedLineId: lineToDisplayFulfilments,
              });

              const isActiveDisplayOriginalStockLine = isActiveStockCheckLine({
                lineId: stockCheckLine.id,
                selectedLineId: lineToDisplayOriginalStock,
              });

              return (
                <PrimaryLineWrapper key={stockCheckLine.id}>
                  <StockCheckLineToFulfil stockCheckLine={stockCheckLine} index={index} />

                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    {stockCheckLine.stock_check_line_fulfilments.length > 0 && (
                      <SecondaryButton
                        type="button"
                        onClick={() => {
                          if (isActiveDisplayFulfillmentsLine) {
                            setLineToDisplayFulfilments(null);
                          } else {
                            setLineToDisplayFulfilments(stockCheckLine.id);
                          }
                        }}
                        appearance={"whiteButton"}
                      >
                        {isActiveDisplayFulfillmentsLine
                          ? "Hide Fulfilments"
                          : "Display Fulfilments"}
                      </SecondaryButton>
                    )}

                    <SecondaryButton
                      type="button"
                      onClick={() => {
                        if (isActiveDisplayOriginalStockLine) {
                          setLineToDisplayOriginalStock(null);
                        } else {
                          setLineToDisplayOriginalStock(stockCheckLine.id);
                        }
                      }}
                      appearance={"whiteButton"}
                    >
                      {isActiveDisplayOriginalStockLine
                        ? "Hide Original Stock"
                        : "Display Original Stock"}
                    </SecondaryButton>
                  </ButtonGroup>

                  {isActiveDisplayFulfillmentsLine &&
                    stockCheckLine.stock_check_line_fulfilments.map(fulfilment => {
                      return fulfilment.product_stocks.map((productStock, stockIndex) => {
                        return (
                          <StockCheckLineFulfilmentProductStock
                            key={productStock.id}
                            productStock={productStock}
                            stockCheckLineFulfilment={fulfilment}
                            index={stockIndex}
                          />
                        );
                      });
                    })}

                  {isActiveDisplayOriginalStockLine && (
                    <StockCheckLineOriginalStock productStock={stockCheckLine.product_stock} />
                  )}
                </PrimaryLineWrapper>
              );
            })}
          </InnerPanelWrapper>
        </Panel>
      </Page>
    </>
  );
}

export default ViewStockCheck;
