import { WorkOrderOutputLineItem } from "../graphql/types";

export interface WordOrderFulfilmentForm {
  work_order_and_recipe_outputs: WorkOrderAndRecipeOutput[];
  quantity: string;
}

export interface WorkOrderAndRecipeOutput {
  recipe_output_line: {
    product: {
      product_name: string;
      id: number;
    };
    quantity: number;
  };
  existing_work_order_output_lines: WorkOrderOutputLineItem[];
  work_order_outputs: WorkOrderOutput[];
}

export interface WorkOrderOutput {
  location: {
    label: string;
    value: number;
  } | null;
}

export const initialValuesData: WordOrderFulfilmentForm = {
  work_order_and_recipe_outputs: [],
  quantity: "",
};

export const emptyWorkOrderOutput: WorkOrderOutput = {
  location: null,
};
