import { ApolloError } from "@apollo/client";

import { useGetValidQuotesQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapQuoteToTableRow } from "../../mappers";
import { QuoteRowItem } from "../types";

export interface HookResult {
  quotes: QuoteRowItem[];
  quotesError?: ApolloError;
  quotesLoading: boolean;
}

export const useValidQuotes = (): HookResult => {
  const { data, error, loading } = useGetValidQuotesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    quotes:
      data && Array.isArray(data.supplier_quote_line)
        ? mapQuoteToTableRow(data.supplier_quote_line)
        : [],
    quotesError: error,
    quotesLoading: loading,
  };
};
