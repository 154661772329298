import { UploadedFile } from "../../../../../attachments/fileUpload";
import { AttachmentBody } from "../../../../../shared/types/AttachmentBody";
import { SupplierQuoteRequestLineBody } from "../api/types";
import { ProductItem, SupplierItem } from "../graphql/types";
import { ProductLineInterface } from "../types";

export const mapSuppliersToDropdownObject = (suppliers: SupplierItem[]) => {
  return suppliers.map(supplier => {
    return {
      label: supplier.organisation_name,
      value: supplier.id,
    };
  });
};

export const mapProductsToDropdownObject = (products: ProductItem[]) => {
  return products.map(product => {
    return {
      label: product.product_name,
      value: product.id,
    };
  });
};

export const mapAttachmentsToDTO = (attachments: UploadedFile[]) => {
  return attachments.map((item: UploadedFile) => {
    let attachment: AttachmentBody = {
      originalFilename: item.original_filename,
      uuid: item.attachment_uuid,
      notes: "",
      metadata: JSON.stringify({ fileType: "RFQ File" }),
    };
    return attachment;
  });
};

export const mapQuoteLinesToDTO = (quoteLines: ProductLineInterface[], currency: string) => {
  return quoteLines.map(line => {
    const lineBody: SupplierQuoteRequestLineBody = {
      productId: line.product!.value!,
      minimumOrderQuantity: line.quantity.map(quantity => parseInt(quantity)),
      currencyCode: currency,
      attachments: mapAttachmentsToDTO(line.attachments),
      notes: line.notes,
    };
    return lineBody;
  });
};
