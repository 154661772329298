import * as yup from "yup";

export const attributeTemplateSchema = yup.object({
  name: yup.string().required("Attribute name is required"),
  requiredFields: yup.array().of(
    yup.object({
      name: yup.string().required("Required field name is required"),
      type: yup.string().required("Required field type is required"),
    })
  ),
});

export type AttributeTemplateRequiredField = {
  name: string;
  type: number;
};

export interface AttributeTemplateInterface {
  name: string;
  requiredFields: AttributeTemplateRequiredField[];
}

export const attributeTemplateFieldNames = {
  name: "name",
  requiredFields: "requiredFields",
  requiredFieldName: "requiredFieldName",
  requiredFieldType: "requiredFieldType",
};

export const typeOptions = [
  {
    value: 1,
    label: "Text",
  },
  {
    value: 2,
    label: "Number",
  },
];
