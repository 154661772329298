export interface ManifestLineInterface {
  product: {
    value: number;
    label: string;
  } | null;
  base_product: {
    value: number;
    label: string;
  } | null;
  quantity: string;
}

export interface InboundManifestForm {
  organisation: {
    value: number;
    label: string;
  } | null;
  warehouse: {
    value: number;
    label: string;
  } | null;
  logistics_type: {
    value: number;
    label: string;
  } | null;
  courier_service: {
    value: number;
    label: string;
  } | null;
  expected_arrival_date: string;
  manifest_lines: ManifestLineInterface[];
  notes: string;
}

export const initialFieldValues: InboundManifestForm = {
  organisation: null,
  warehouse: null,
  logistics_type: null,
  expected_arrival_date: "",
  manifest_lines: [],
  notes: "",
  courier_service: null,
};

export const emptyManifestLine: ManifestLineInterface = {
  product: null,
  quantity: "",
  base_product: null,
};

export const fieldNames = {
  organisation: "organisation",
  warehouse: "warehouse",
  expected_arrival_date: "expected_arrival_date",
  status_name: "status_name",
  location: "location",
  logistics_type: "logistics_type",
  notes: "notes",
  courier_service: "courier_service",
};
