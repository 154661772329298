import { ApolloError } from "@apollo/client";

import { useGetWarehousesWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { WarehouseItem } from "../types";

export interface HookResult {
  warehouses: WarehouseItem[];
  warehousesError?: ApolloError;
  warehousesLoading: boolean;
}

export const useWarehousesWithData = (): HookResult => {
  const { data, error, loading } = useGetWarehousesWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    warehouses: data && Array.isArray(data.warehouse) ? data.warehouse : [],
    warehousesError: error,
    warehousesLoading: loading,
  };
};
