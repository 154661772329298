import moment from "moment";
import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  InnerPanelWrapper,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { OutboundManifestStatuses } from "../../../../../util/interfaces";
import OutboundManifestLine from "../components/OutboundManifestLine";
import { useOutboundManifestById } from "../graphql/hooks/useOutboundManifestById";
import {
  TerminateOutboundManifestActionBody,
  terminateOutboundManifestAction,
  terminateOutboundManifestReset,
} from "../redux/actions/terminateOutboundManifestAction";
import { TerminateOutboundManifestReducer } from "../redux/reducers/terminateOutboundManifestReducer";
import { CHANGE_OBM_STATUS } from "../redux/types";

interface Props {
  terminateOBMState: TerminateOutboundManifestReducer;
  terminateOBM: (body: TerminateOutboundManifestActionBody) => void;
  terminateOBMReset: () => void;
}

const FulfilOutboundManifest = (props: Props) => {
  const { terminateOBMState } = props;
  const [initialLoading, setInitialLoading] = useState<boolean>(true);
  const { outbound_manifest_id } = useParams<{ outbound_manifest_id: string }>();

  const { manifest, manifestLoading, manifestError, refetchOutboundManifest } =
    useOutboundManifestById(Number(outbound_manifest_id));

  const refetchObm = () => {
    setInitialLoading(false);
    refetchOutboundManifest();
  };

  useEffect(() => {
    if (terminateOBMState.success) {
      refetchOutboundManifest();
      props.terminateOBMReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminateOBMState.success, terminateOBMState.error]);

  const manifestCanBeRejectedOrCancelled = () => {
    let hasFulfilmentLines = false;

    manifest?.outbound_manifest_lines.forEach(manifestLine => {
      const fulfilmentLinesLength = manifestLine.outbound_manifest_fulfilment_lines.length;
      if (fulfilmentLinesLength > 0) {
        hasFulfilmentLines = true;
      }
    });

    const isNew = manifest?.outbound_manifest_status.id === OutboundManifestStatuses.NEW;
    return isNew && hasFulfilmentLines === false;
  };

  const manifestCanBeMarkedAsDone = () => {
    return (
      manifest?.outbound_manifest_status.id === OutboundManifestStatuses.NEW ||
      manifest?.outbound_manifest_status.id === OutboundManifestStatuses.IN_PROGRESS
    );
  };

  const terminateManifestDialog = (body: TerminateOutboundManifestActionBody) => {
    let message = null;

    switch (body.type) {
      case CHANGE_OBM_STATUS.CANCEL:
        message = "canceled";
        break;
      case CHANGE_OBM_STATUS.REJECT:
        message = "rejected";
        break;
      case CHANGE_OBM_STATUS.MARK_AS_DONE:
        message = "done";
        break;
    }

    confirmAlert({
      title: "Confirm Change to Manifest",
      message: `Are you sure you want to mark this manifest as ${message}`,
      buttons: [
        {
          label: "Yes",
          onClick: () => props.terminateOBM(body),
        },
        {
          label: "No",
          onClick: () => {
            return;
          },
        },
      ],
    });
  };

  const isLoading = (manifestLoading && initialLoading) || terminateOBMState.loading;

  return (
    <Page error={manifestError} isLoading={isLoading} title={"Fulfil Outbound Manifest"}>
      <Panel
        withWrapper
        title={"Fulfil Outbound Manifest"}
        subtitle={"Mark off what is actually recieved into the warehouse against the expectation"}
        manifestStatusName={manifest?.outbound_manifest_status.outbound_manifest_status_name}
      >
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <DisplayBox isGray label="Manifest ID" value={`#${manifest?.id}`} />
            <DisplayBox
              isGray
              label="Organisation"
              value={manifest?.organisation.organisation_name}
            />
            <DisplayBox isGray label="Warehouse" value={manifest?.warehouse.warehouse_name} />
            <DisplayBox
              isGray
              label="Logistics Type"
              value={manifest?.logistics_type.logistics_type_name}
              tooltipMessage="How stock will arrive <br/> into the warehouse"
            />
            <DisplayBox
              isGray
              label="Expected Collection Date"
              value={moment(manifest?.expected_dispatch).format("DD-MM-YYYY")}
            />
            <DisplayBox
              isGray
              label="Courier Service"
              value={manifest?.courier_service.courier_service_name}
            />
            <DisplayBox isGray label="Reference" value={manifest?.reference} />
            <DisplayBox isGray label="Notes" value={manifest?.notes} />
          </TopPanelInputsWrapper>

          {manifest?.outbound_manifest_lines.map((OBMLine, index) => (
            <OutboundManifestLine
              key={`obm_line_${index}`}
              index={index}
              organisationId={manifest?.organisation_id}
              warehouseId={manifest?.warehouse.id}
              outboundManifestId={manifest?.id}
              outboundManifestLine={OBMLine}
              refetchOutboundManifest={refetchObm}
            />
          ))}
          <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
            <SecondaryButton
              appearance={"whiteButton"}
              disabled={!manifestCanBeRejectedOrCancelled()}
              onClick={() =>
                terminateManifestDialog({
                  type: CHANGE_OBM_STATUS.CANCEL,
                  outbound_manifest_id: Number(outbound_manifest_id),
                })
              }
            >
              Cancel
            </SecondaryButton>
            <SecondaryButton
              appearance={manifestCanBeRejectedOrCancelled() ? "blueButton" : "whiteButton"}
              disabled={!manifestCanBeRejectedOrCancelled()}
              onClick={() =>
                terminateManifestDialog({
                  type: CHANGE_OBM_STATUS.REJECT,
                  outbound_manifest_id: Number(outbound_manifest_id),
                })
              }
            >
              Reject
            </SecondaryButton>
            <SecondaryButton
              appearance={manifestCanBeMarkedAsDone() ? "blueButton" : "whiteButton"}
              onClick={() =>
                terminateManifestDialog({
                  type: CHANGE_OBM_STATUS.MARK_AS_DONE,
                  outbound_manifest_id: Number(outbound_manifest_id),
                })
              }
              disabled={!manifestCanBeMarkedAsDone()}
            >
              Mark as Done
            </SecondaryButton>
          </ButtonGroup>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    terminateOBMState: state.terminateOutboundManifestReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    terminateOBM: (body: TerminateOutboundManifestActionBody) =>
      dispatch(terminateOutboundManifestAction(body)),
    terminateOBMReset: () => dispatch(terminateOutboundManifestReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(FulfilOutboundManifest);
