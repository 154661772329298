import { Address } from "../../../../../generated/graphql_stock_management";
import { FormattedContact } from "../../updateStockManagementOrganisation/page/UpdateStockManagementOrgansiation";

interface GetInitialPrimaryContactValueProps {
  organisationContacts: FormattedContact[];
  address: Address;
}

export const getInitialPrimaryContactValue = ({
  organisationContacts,
  address,
}: GetInitialPrimaryContactValueProps) => {
  const foundPrimaryContact = organisationContacts.find(
    contact => contact.addressId === address.id && contact.isPrimary
  );

  return foundPrimaryContact
    ? {
        label: `${foundPrimaryContact.firstName} ${foundPrimaryContact.lastName}`,
        value: foundPrimaryContact.indexId,
      }
    : null;
};
