import { ClientOrder } from "../graphql/types";

export const flattenedClientOrderAttachments = (clientOrder: ClientOrder) => {
  const clientOrderLineAttachments = clientOrder.client_order_lines.flatMap(coLine => {
    return coLine.folder.attachments.flatMap(attachment => {
      return attachment;
    });
  });
  const clientOrderAttachments = clientOrder.folder.attachments.map(attachment => {
    return attachment;
  });
  return clientOrderLineAttachments.concat(clientOrderAttachments);
};
