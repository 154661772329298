import { useEffect } from "react";

import { ButtonGroup } from "@sourceful/shared-components";

import { SecondaryButton } from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Title } from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { resetProductPriceApi } from "../api/resetProductPrice";

const ResetPrices = ({
  skuId,
  handleClose,
  refetchPrices,
}: {
  skuId: string;
  handleClose: () => void;
  refetchPrices: () => Promise<void>;
}) => {
  const [resetPriceState, resetPrice] = useAPI(resetProductPriceApi, true);

  const handleResetProductPrices = async () => {
    await resetPrice(skuId);
  };

  useEffect(() => {
    if (resetPriceState === API_REQUEST_STATE.SUCCESS) {
      refetchPrices();
      handleClose();
    } else if (resetPriceState === API_REQUEST_STATE.ERROR) {
      handleClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetPriceState]);

  return (
    <div>
      <Title>Resetting Prices</Title>
      <p>Are you sure you want to reset all prices?</p>

      <ButtonGroup>
        <SecondaryButton onClick={() => handleClose()} appearance="whiteButton">
          Cancel
        </SecondaryButton>
        <SecondaryButton onClick={() => handleResetProductPrices()}>Reset</SecondaryButton>
      </ButtonGroup>
    </div>
  );
};

export default ResetPrices;
