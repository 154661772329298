import { styled } from "@sourceful/shared-components";

export const GlobalWrapper = styled("div", {
  paddingLeft: "10px",
  paddingRight: "10px",
  paddingBottom: "20px",
  paddingTop: "10px",
});

export const CardsWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  width: "100%",
  marginTop: "30px",
  marginBottom: "30px",
  justifyContent: "space-between",
});

export const HorizontalCardWrapper = styled("div", {
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  height: "473px",
  marginTop: "10px",
});
