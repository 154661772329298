import { ApolloError } from "@apollo/client";

import { useGetSupplierByXeroIdQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapSupplier } from "../mappers";
import { SupplierItem } from "../types";

export interface HookResult {
  supplier?: SupplierItem;
  supplierLoading: boolean;
  supplierError?: ApolloError;
}

export const useSupplierByXeroId = (xeroContactId: string): HookResult => {
  const { data, loading, error } = useGetSupplierByXeroIdQuery({
    context: { clientName: GRAPHQL_SOURCING },
    variables: { xeroContactId },
  });

  const isArray = data && Array.isArray(data.supplier_details) && data.supplier_details.length > 0;

  return {
    supplier: isArray ? mapSupplier(data.supplier_details[0]) : undefined,
    supplierLoading: loading,
    supplierError: error,
  };
};
