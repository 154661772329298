import { SOURCING_API } from "../../../../../api/baseConfig";

export const recallPurchaseOrderAPI = async (purchaseOrderId: string) => {
  try {
    return await SOURCING_API.post(`/purchase-orders/${purchaseOrderId}/recall`);
  } catch (err) {
    console.log("Error in recall purchase order API call", err);
    throw err;
  }
};
