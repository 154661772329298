import { useMemo } from "react";
import { useHistory } from "react-router-dom";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { useCourierServicesWithData } from "../graphql/hooks/useCourierServicesWithData";
import { CourierServiceItem } from "../graphql/types";

export default function ViewCourierServices() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { courierServices, courierServicesLoading, courierServicesError } =
    useCourierServicesWithData();

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/courier-management/add-courier-service");
          },
          type: "button",
          title: "Add Courier Service",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const columns = useMemo(
    () => [
      {
        Header: "Courier Service Name",
        widthPercent: 0.4,
        accessor: (listItem: CourierServiceItem) => listItem.courier_service_name,
      },
      {
        Header: "Courier Name",
        widthPercent: 0.4,
        accessor: (listItem: CourierServiceItem) => listItem.courier.courier_name,
      },
    ],
    []
  );

  return (
    <Page
      error={courierServicesError}
      isLoading={courierServicesLoading}
      title={"Inventory - Couriers"}
    >
      <Panel
        withWrapper
        title={"Couriers"}
        text={"Use the table below to view available courier services"}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        rightItems={rightItems}
      >
        <Table data={courierServices} columns={columns} />
      </Panel>
    </Page>
  );
}
