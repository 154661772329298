import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useStockTransfers } from "../graphql/hooks/useStockTransfersWithData";
import { StockTransferItem } from "../graphql/types";

export default function ViewStockTransfers() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const { stockTransfers, stockTransfersLoading, stockTransfersError } = useStockTransfers();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.1,
        accessor: (stockTransfer: StockTransferItem) => stockTransfer.id,
      },
      {
        Header: "Status",
        widthPercent: 0.6,
        accessor: (stockTransfer: StockTransferItem) =>
          stockTransfer.internal_transfer_status.internal_transfer_status_name,
        Cell: ({ _, row }: CellProps<StockTransferItem>) => (
          <StatusBadge
            type="wms"
            statusName={row.original.internal_transfer_status.internal_transfer_status_name}
          />
        ),
      },
      {
        Header: "Warehouse",
        widthPercent: 1.5,
        accessor: (stockTransfer: StockTransferItem) =>
          stockTransfer.from_location?.warehouse.warehouse_name,
        Cell: ({ _, row }: CellProps<StockTransferItem>) => (
          <Link
            to={`/warehouse-management/view-warehouse/${row.original.from_location?.warehouse.id}`}
          >
            <LinkText>{row.original.from_location?.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
      },

      {
        Header: "From Location",
        widthPercent: 0.6,
        accessor: (stockTransfer: StockTransferItem) => stockTransfer.from_location?.location_name,
        Cell: ({ _, row }: CellProps<StockTransferItem>) => (
          <Link
            to={`/warehouse-management/location/view-location/${row.original.from_location?.id}`}
          >
            <LinkText>{row.original.from_location?.location_name}</LinkText>
          </Link>
        ),
      },

      {
        Header: "To Location",
        widthPercent: 0.5,
        accessor: (stockTransfer: StockTransferItem) => stockTransfer.to_location.location_name,
        Cell: ({ _, row }: CellProps<StockTransferItem>) => (
          <Link to={`/warehouse-management/location/view-location/${row.original.to_location.id}`}>
            <LinkText>{row.original.to_location.location_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Lines",
        widthPercent: 0.5,
        accessor: (stockTransfer: StockTransferItem) =>
          stockTransfer.internal_transfer_lines_aggregate.aggregate?.count,
      },

      {
        Header: "Review",
        widthPercent: 0.9,
        Cell: ({ _, row }: CellProps<StockTransferItem>) => (
          <Link to={`/operations/stock-transfer/review-stock-transfer/${row.original.id}`}>
            <SecondaryButton appearance="blueButton" isWithinTable>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  const rightItems: PanelProps["rightItems"] = WMS_EDIT_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/operations/stock-transfer/create-stock-transfer");
          },
          type: "button",
          title: "Create Stock Transfer",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  return (
    <Page title="Stock Transfer" error={stockTransfersError} isLoading={stockTransfersLoading}>
      <Panel
        withWrapper
        title={"Stock Transfer List"}
        text={"Use the table below to explore stock transfers"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <Table data={stockTransfers} columns={columns} />
      </Panel>
    </Page>
  );
}
