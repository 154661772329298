import { toast } from "react-toastify";
import { Dispatch } from "redux";

import { ErrorType } from "../../../../../../shared/api/errors/errorTypes";
import { createCaseProductAPI } from "../../api/createCaseProduct";
import { CreateCaseProductBody } from "../../api/types";
import {
  CREATE_CASE_PRODUCT_FAILED,
  CREATE_CASE_PRODUCT_LOADING,
  CREATE_CASE_PRODUCT_RESET,
  CREATE_CASE_PRODUCT_SUCCESS,
} from "../types";

export const createCaseProductLoading = () => {
  return {
    type: CREATE_CASE_PRODUCT_LOADING,
  };
};

export const createCaseProductSuccess = () => {
  return {
    type: CREATE_CASE_PRODUCT_SUCCESS,
  };
};

export const createCaseProductFailed = ({ errors }: { errors: ErrorType[] }) => {
  return {
    type: CREATE_CASE_PRODUCT_FAILED,
    errors: errors,
  };
};

export const createCaseProductReset = () => {
  return {
    type: CREATE_CASE_PRODUCT_RESET,
  };
};

export const createCaseProductAction = (body: CreateCaseProductBody) => {
  return async (dispatch: Dispatch) => {
    toast("Creating Case product", { hideProgressBar: true });
    dispatch(createCaseProductLoading());
    try {
      await createCaseProductAPI(body);
      toast.success("Case product created successfully.");
      dispatch(createCaseProductSuccess());
    } catch (err) {
      toast.error("Something went wrong with creating case product.");
      dispatch(createCaseProductFailed({ errors: (err as any).response?.data?.errors }));
    }
  };
};
