import React, { useState } from "react";

import { styled } from "@sourceful/shared-components";

import SideBar from "../../../../components/SideBar/SideBar";
import Nav from "../../organisms/Nav";

interface LayoutProps {
  children?: React.ReactNode;
  publicPage?: boolean;
}

const SideBarWrapper = styled("div", {
  width: "auto",
});

const MainPageWrapper = styled("div", {
  width: "100%",
  marginLeft: "20px",
  flexGrow: "1",
  overflow: "hidden",
  padding: "20px",
});

const GlobalWrapper = styled("div", {
  display: "flex",
  paddingRight: "40px",
  minHeight: "calc(100vh - 50px)",
});

const ExternalWrapper = styled("div", { maxWidth: "1440px", margin: "0 auto" });

const Layout = ({ children, publicPage }: LayoutProps) => {
  const [sidebarShowing, setSidebarShowing] = useState(true);
  const handleToggleSidebar = () => {
    setSidebarShowing(!sidebarShowing);
  };

  if (publicPage) {
    return (
      <>
        <Nav />
        <GlobalWrapper>
          <ExternalWrapper>
            <MainPageWrapper>{children}</MainPageWrapper>
          </ExternalWrapper>
        </GlobalWrapper>
      </>
    );
  }
  return (
    <>
      <Nav handleToggleSidebar={handleToggleSidebar} />
      <GlobalWrapper>
        <SideBarWrapper>
          <SideBar showing={sidebarShowing} />
        </SideBarWrapper>
        <MainPageWrapper>{children}</MainPageWrapper>
      </GlobalWrapper>
    </>
  );
};

export default Layout;
