import { Formik, FormikState } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  InnerPanelWrapper,
  Label,
  StyledForm,
  TopPanelInputsWrapper,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { CreateCaseProductBody } from "../api/types";
import { ProductForm, fieldNames, initialFieldValues } from "../formValues";
import { useBaseProductData } from "../graphql/hooks/useBaseProductData";
import { useUnderlyingCaseProductByBaseProductId } from "../graphql/hooks/useUnderlyingCaseProductByBaseProductId";
import {
  createCaseProductAction,
  createCaseProductReset,
} from "../redux/actions/createCaseProductAction";
import { CreateCaseProductReducer } from "../redux/reducers/createCaseProductReducer";
import { validationSchema } from "../validation";

interface Props {
  createCaseProductState: CreateCaseProductReducer;
  createCaseProduct: (body: CreateCaseProductBody) => void;
  resetCreateCaseProduct: () => void;
}
function AddProduct(props: Props) {
  const [initialValues] = useState<ProductForm>(initialFieldValues);
  const { createCaseProductState } = props;

  const onSubmit = async (
    values: ProductForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<ProductForm>>) => void }
  ) => {
    const selectedCaseProduct = caseProducts?.find(
      caseProduct => caseProduct.id === values.case_product?.value
    );

    const selectedBaseProduct = baseProducts?.find(
      baseProduct => baseProduct.id === values.base_product?.value
    );

    const newProductBody: CreateCaseProductBody = {
      product_name: values.product_name,
      fsc_certified: selectedBaseProduct?.fsc_certified!,
      grs_certified: selectedBaseProduct?.grs_certified!,
      base_product_id: selectedBaseProduct?.id!,
      base_product_quantity: Number(values.base_product_quantity),
      case_product_id: selectedCaseProduct?.id!,
      case_product_quantity:
        Number(values.base_product_quantity) / selectedCaseProduct?.base_product_quantity!,
    };
    if (values.width_mm) {
      newProductBody.width_mm = Number(values.width_mm);
    }
    if (values.height_mm) {
      newProductBody.height_mm = Number(values.height_mm);
    }
    if (values.weight_grams) {
      newProductBody.weight_grams = Number(values.weight_grams);
    }
    if (values.length_mm) {
      newProductBody.length_mm = Number(values.length_mm);
    }

    if (newProductBody.base_product_quantity % newProductBody.case_product_quantity !== 0) {
      toast.error("Product Quantity is not divisible by Underlying Product Quantity ");
      return;
    }
    props.createCaseProduct(newProductBody);

    resetForm();
  };
  useEffect(() => {
    if (createCaseProductState.success) {
      props.resetCreateCaseProduct();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createCaseProductState.success, createCaseProductState.error]);

  const { baseProducts, baseProductsLoading, baseProductsError } = useBaseProductData();
  const {
    caseProducts,
    caseProductsError,
    caseProductsLoading,
    getUnderlyingCaseProductByBaseProductId,
  } = useUnderlyingCaseProductByBaseProductId();

  const error = baseProductsError || caseProductsError;
  const isLoading = baseProductsLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Add Product"}>
      <Panel withWrapper title={"Add Product"} subtitle={"Add a new Product using the card below"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleReset,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <ToolTip
                        message={"All New Case Products must have a Base Product"}
                        title={"Base Product*"}
                        htmlFor={fieldNames.base_product}
                      />
                      <Select
                        styles={reactSelectStyling}
                        value={values.base_product}
                        options={baseProducts?.map(base_product => {
                          return {
                            label: base_product.product_name!,
                            value: base_product.id!,
                          };
                        })}
                        onChange={e => {
                          setFieldValue(fieldNames.base_product, e);
                          setFieldValue(fieldNames.case_product, null);
                          getUnderlyingCaseProductByBaseProductId({
                            baseProductId: e?.value!,
                          });
                        }}
                        isSearchable={true}
                        maxMenuHeight={220}
                        id={fieldNames.base_product}
                        placeholder={"Select Base Product"}
                      />
                      {errors.base_product && touched.base_product ? (
                        <ErrorMessage>{errors.base_product}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <ToolTip
                        message={
                          "New Case Products are built from an existing product. This existing product may be a case or base product. Please select the appropriate underlying product here."
                        }
                        title={"Underlying Product*"}
                        htmlFor={fieldNames.case_product}
                      />
                      {values.base_product && caseProducts?.length > 0 ? (
                        <Select
                          styles={reactSelectStyling}
                          value={values.case_product}
                          options={caseProducts.map(case_product => {
                            return {
                              label: case_product.product_name,
                              value: case_product.id,
                            };
                          })}
                          onChange={e => setFieldValue(fieldNames.case_product, e)}
                          isSearchable={true}
                          maxMenuHeight={220}
                          placeholder={"Select Underlying Product"}
                          id={fieldNames.case_product}
                        />
                      ) : (
                        <Box>
                          {caseProductsLoading
                            ? "Products Loading"
                            : "Please select a Base Product"}
                        </Box>
                      )}
                      {errors.case_product && touched.case_product ? (
                        <ErrorMessage>{errors.case_product}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.product_name}>
                        Product Name
                      </Label>
                      <InputField
                        id={fieldNames.product_name}
                        size={"medium"}
                        type={"text"}
                        value={values.product_name}
                        handleChange={handleChange}
                        placeholder={"Enter Product Name"}
                      />
                      {errors.product_name && touched.product_name ? (
                        <ErrorMessage>{errors.product_name}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.base_product_quantity}>
                        Product Quantity
                      </Label>
                      <InputField
                        id={fieldNames.base_product_quantity}
                        size={"medium"}
                        type={"text"}
                        value={values.base_product_quantity}
                        handleChange={handleChange}
                        placeholder={"Enter Product Qty"}
                      />
                      {errors.base_product_quantity && touched.base_product_quantity ? (
                        <ErrorMessage>{errors.base_product_quantity}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label isGray>Underlying Product Quantity</Label>
                      {values.case_product && caseProducts.length > 0 ? (
                        <Box>
                          {
                            caseProducts.find(
                              caseProduct => caseProduct.id === values.case_product?.value
                            )?.base_product_quantity
                          }
                        </Box>
                      ) : (
                        <Box> Please select an Underlying Product </Box>
                      )}
                      {errors.case_product && touched.case_product ? (
                        <ErrorMessage>{errors.case_product}</ErrorMessage>
                      ) : null}
                    </DataPoint>

                    <DataPoint>
                      <Label htmlFor={fieldNames.weight_grams}>Weight (grams)</Label>
                      <InputField
                        id={fieldNames.weight_grams}
                        size={"medium"}
                        type={"number"}
                        value={values.weight_grams}
                        handleChange={handleChange}
                        placeholder={"Enter Weight (g)"}
                      />
                      {errors.weight_grams && touched.weight_grams ? (
                        <ErrorMessage>{errors.weight_grams}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label htmlFor={fieldNames.width_mm}>Width (mm)</Label>
                      <InputField
                        id={fieldNames.width_mm}
                        size={"medium"}
                        type={"number"}
                        value={values.width_mm}
                        handleChange={handleChange}
                        placeholder={"Enter Width (mm)"}
                      />
                      {errors.width_mm && touched.width_mm ? (
                        <ErrorMessage>{errors.width_mm}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label htmlFor={fieldNames.height_mm}>Height (mm)</Label>
                      <InputField
                        id={fieldNames.height_mm}
                        size={"medium"}
                        type={"number"}
                        value={values.height_mm}
                        handleChange={handleChange}
                        placeholder={"Enter Height (mm)"}
                      />
                      {errors.height_mm && touched.height_mm ? (
                        <ErrorMessage>{errors.height_mm}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label htmlFor={fieldNames.length_mm}>Length (mm)</Label>
                      <InputField
                        id={fieldNames.length_mm}
                        size={"medium"}
                        type={"number"}
                        value={values.length_mm}
                        handleChange={handleChange}
                        placeholder={"Enter Length (mm)"}
                      />
                      {errors.length_mm && touched.length_mm ? (
                        <ErrorMessage>{errors.length_mm}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  <FormButtonPair handleReset={handleReset} />
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createCaseProductState: state.createCaseProductReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createCaseProduct: (body: CreateCaseProductBody) => dispatch(createCaseProductAction(body)),
    resetCreateCaseProduct: () => dispatch(createCaseProductReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddProduct);
