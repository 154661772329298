import { useHistory } from "react-router-dom";
import Select from "react-select";

import { Icon, InputField } from "@sourceful/shared-components";

import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  Box,
  CertificationBadge,
  DataPoint,
  FlexHolder,
  IconWrapper,
  Label,
  LinkLabel,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { useOutboundCaseProductsByBaseProductId } from "../graphql/hooks/useOutboundCaseProductsByBaseProductId";
import { ManifestLineProps } from "../types";

const OutboundManifestLine = ({
  values,
  index,
  errors,
  touched,
  baseProductData,
  setFieldValue,
  handleRemoveManifestLine,
}: ManifestLineProps) => {
  let history = useHistory();

  const { getOutboundCaseProductsByBaseProductId, caseProducts } =
    useOutboundCaseProductsByBaseProductId();

  let caseQuantity =
    caseProducts?.find(product => product.id === values.product?.value)?.base_product_quantity ?? 1;

  let selectedBaseProduct = baseProductData?.find(
    baseProduct => baseProduct.id === values.base_product?.value
  );

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <FlexHolder>
        {selectedBaseProduct?.fsc_certified && (
          <CertificationBadge type="FSC"> FSC Certified </CertificationBadge>
        )}
        {selectedBaseProduct?.grs_certified && (
          <CertificationBadge type="GRS"> GRS Certified </CertificationBadge>
        )}
      </FlexHolder>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint>
          <Label isRequired htmlFor={`base_product${index}`}>
            Base Product
          </Label>
          <Select
            styles={reactSelectStyling}
            maxMenuHeight={220}
            isSearchable={true}
            id={`base_product${index}`}
            value={values.base_product}
            options={baseProductData?.map(base_product => {
              return {
                label: base_product.product_name!,
                value: base_product.id!,
              };
            })}
            onChange={e => {
              setFieldValue(`manifest_lines[${index}].base_product`, e);
              setFieldValue(`manifest_lines[${index}].product`, null);
              getOutboundCaseProductsByBaseProductId({ baseProductId: e?.value! });
            }}
            placeholder="Select Base Product"
          />
          {Array.isArray(errors) &&
          errors[index]?.base_product &&
          Array.isArray(touched) &&
          touched[index]?.base_product ? (
            <ErrorMessage>{errors[index]?.base_product}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint>
          <Label isRequired htmlFor={`product_${index}`}>
            Product
          </Label>
          <LinkLabel onClick={() => history.push("/inventory/products/add-product")}>
            + Add Product
          </LinkLabel>
          {values.base_product ? (
            <Select
              styles={reactSelectStyling}
              maxMenuHeight={220}
              isSearchable={true}
              id={`product_${index}`}
              value={values.product}
              options={caseProducts?.map(product => {
                return {
                  label: product.product_name,
                  value: product.id,
                };
              })}
              onChange={e => {
                setFieldValue(`manifest_lines[${index}].product`, e);
              }}
              placeholder="Select Product"
            />
          ) : (
            <Box> Please select a Base Product </Box>
          )}
          {Array.isArray(errors) &&
          errors[index]?.product &&
          Array.isArray(touched) &&
          touched[index]?.product ? (
            <ErrorMessage>{errors[index]?.product}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DataPoint>
          <Label isRequired htmlFor={`quantity_${index}`}>
            Quantity
          </Label>
          <InputField
            id={`quantity_${index}`}
            size={"medium"}
            type={"number"}
            placeholder={"0.00"}
            value={values.quantity}
            handleChange={e => {
              setFieldValue(`manifest_lines[${index}].quantity`, e.target.value);
            }}
          />
          {Array.isArray(errors) &&
          errors[index]?.quantity &&
          Array.isArray(touched) &&
          touched[index]?.quantity ? (
            <ErrorMessage>{errors[index]?.quantity}</ErrorMessage>
          ) : null}
        </DataPoint>
        <DisplayBox
          label={"Total Quantity"}
          value={
            values.product && values.quantity ? caseQuantity * Number(values.quantity) : undefined
          }
          unknownString={"Select a Product & Quantity"}
        />
      </SecondaryLineDataWrapper>
      <DataPoint>
        <IconWrapper type="button" onClick={() => handleRemoveManifestLine(index)}>
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DataPoint>
    </SecondaryLineWrapper>
  );
};

export default OutboundManifestLine;
