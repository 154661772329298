import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import {
  ButtonWrapper,
  CertificationBadge,
  FlexHolder,
  FlexTitle,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineWrapper,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { SecondaryLineDataWrapper } from "../../../../../styles/SharedStyles";
import { InboundManifestLineStatuses } from "../../../../../util/interfaces";
import { InboundManifestLineItem } from "../graphql/types";
import { mapManifestLineStatusToMessage } from "../mappers";
import {
  TerminateInboundManifestLineActionBody,
  terminateInboundManifestLineAction,
  terminateInboundManifestLineReset,
} from "../redux/actions/terminateInboundManifestLineAction";
import { TerminateInboundManifestLineReducer } from "../redux/reducers/terminateInboundManifestLineReducer";
import { CHANGE_IBM_LINE_STATUS } from "../redux/types";
import FulfilInboundManifestLine from "./FulfilInboundManifestLine";

export interface InboundManifestLineProps {
  index: number;
  warehouseId: number;
  inbound_manifest_id: number;
  inboundManifestLine: InboundManifestLineItem;
  setQRCodeBarcode: (QRCodeBarcode: string) => void;
  setIsQRModalOpen: (isQRModalOpen: boolean) => void;
  refetchInboundManifest: () => void;
  terminateIBMLineState: TerminateInboundManifestLineReducer;
  terminateIBMLine: (body: TerminateInboundManifestLineActionBody) => void;
  terminateIBMLineReset: () => void;
}

const InboundManifestLine = ({
  index,
  warehouseId,
  inboundManifestLine,
  refetchInboundManifest,
  terminateIBMLineState,
  terminateIBMLine,
  inbound_manifest_id,
  terminateIBMLineReset,
}: InboundManifestLineProps) => {
  const [ibmLineToFulfil, setIbmLineToFulfil] = useState<number | null>(null);
  let history = useHistory();

  const terminateManifestLineDialog = (body: TerminateInboundManifestLineActionBody) => {
    const message = mapManifestLineStatusToMessage(body.type);

    confirmAlert({
      title: "Confirm Change to Manifest Line",
      message: `Are you sure you want to mark this manifest line as ${message}?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => terminateIBMLine(body),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  useEffect(() => {
    if (terminateIBMLineState.success) {
      refetchInboundManifest();
      terminateIBMLineReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [terminateIBMLineState.success, terminateIBMLineState.error]);

  return (
    <PrimaryLineWrapper>
      <FlexTitle>
        <Title>Manifest Line #{index + 1}</Title>
        <StatusBadge
          type="dot"
          statusName={
            inboundManifestLine.inbound_manifest_line_status.inbound_manifest_line_status_name
          }
        />
      </FlexTitle>

      <FlexHolder>
        {inboundManifestLine.product.fsc_certified && (
          <CertificationBadge type="FSC"> FSC Certified </CertificationBadge>
        )}
        {inboundManifestLine.product.grs_certified && (
          <CertificationBadge type="GRS"> GRS Certified </CertificationBadge>
        )}
      </FlexHolder>

      <PrimaryLineDataWrapper>
        <DisplayBox
          withUniqueMargin
          label={"Product"}
          value={inboundManifestLine.product.product_name}
        />
        <DisplayBox withUniqueMargin label={"Quantity"} value={inboundManifestLine.quantity} />
        <DisplayBox
          withUniqueMargin
          label={"Received Quantity"}
          value={`${inboundManifestLine.received_quantity}`}
        />
      </PrimaryLineDataWrapper>

      {inboundManifestLine.inbound_manifest_fulfilment_lines.map((ffline, index) => (
        <SecondaryLineWrapper type={"withIndex"}>
          <IndexPill index={index + 1} />
          <Title>Fulfilment Line #{index + 1}</Title>
          <FlexHolder>
            {ffline.is_file_copy && <StatusBadge type="wms" statusName={"File Copy"} />}
          </FlexHolder>

          <SecondaryLineDataWrapper>
            <DisplayBox label={"Fulfilment Line Product"} value={ffline.product.product_name} />
            <DisplayBox label={"Fulfilment Line Quantity"} value={ffline.quantity} />
            <DisplayBox
              label={"Fulfilment Line Location"}
              value={ffline.product_stocks[0].location.location_name}
            />
          </SecondaryLineDataWrapper>
          <SecondaryLineDataWrapper>
            <DisplayBox
              label={"Fulfilment Line Notes"}
              value={ffline.notes}
              fullWidth={ffline.notes !== ""}
            />
          </SecondaryLineDataWrapper>
          <ButtonGroup
            className={WMSButtonGroup({
              type: "largeMargin",
              withinSecondaryLine: true,
            })}
          >
            <SecondaryButton
              onClick={() => {
                history.push(`/inventory/view-product-stock/${ffline.product_stocks[0].id}`);
              }}
              appearance="blueButton"
            >
              Go to Stock
            </SecondaryButton>
          </ButtonGroup>
        </SecondaryLineWrapper>
      ))}

      {inboundManifestLine && inboundManifestLine.id === ibmLineToFulfil && (
        <FulfilInboundManifestLine
          inbound_manifest_id={inbound_manifest_id}
          inbound_manifest_line={inboundManifestLine}
          total_base_product_quantity={
            (inboundManifestLine.quantity - inboundManifestLine.received_quantity) *
            inboundManifestLine.product.base_product_quantity
          }
          refetchInboundManifest={refetchInboundManifest}
          setIBMLineToFulfil={setIbmLineToFulfil}
          warehouse_id={warehouseId}
        />
      )}

      {(inboundManifestLine.inbound_manifest_line_status.id === InboundManifestLineStatuses.NEW ||
        inboundManifestLine.inbound_manifest_line_status.id ===
          InboundManifestLineStatuses.IN_PROGRESS) &&
        inboundManifestLine.id !== ibmLineToFulfil && (
          <ButtonWrapper>
            <SecondaryButton
              appearance={"whiteButtonBlueText"}
              className={WMSButtonGroup({ type: "smallMargin" })}
              onClick={() => {
                setIbmLineToFulfil(inboundManifestLine.id);
              }}
            >
              <IconText text={"Add New Fulfillment Line"} primaryIcon={"alert-add-outline"} />
            </SecondaryButton>
          </ButtonWrapper>
        )}

      <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
        <SecondaryButton
          appearance={"whiteButton"}
          disabled={
            inboundManifestLine.inbound_manifest_line_status.id !== InboundManifestLineStatuses.NEW
          }
          onClick={() =>
            terminateManifestLineDialog({
              inbound_manifest_id: inbound_manifest_id,
              inbound_manifest_line_id: inboundManifestLine.id,
              type: CHANGE_IBM_LINE_STATUS.CANCEL,
            })
          }
        >
          Cancel
        </SecondaryButton>
        <SecondaryButton
          appearance={"whiteButton"}
          disabled={
            inboundManifestLine.inbound_manifest_line_status.id !== InboundManifestLineStatuses.NEW
          }
          onClick={() =>
            terminateManifestLineDialog({
              inbound_manifest_id: inbound_manifest_id,
              inbound_manifest_line_id: inboundManifestLine.id,
              type: CHANGE_IBM_LINE_STATUS.REJECT,
            })
          }
        >
          Reject
        </SecondaryButton>

        <SecondaryButton
          appearance={
            inboundManifestLine.inbound_manifest_line_status.id !==
              InboundManifestLineStatuses.NEW &&
            inboundManifestLine.inbound_manifest_line_status.id !==
              InboundManifestLineStatuses.IN_PROGRESS
              ? "whiteButton"
              : "blueButton"
          }
          disabled={
            inboundManifestLine.inbound_manifest_line_status.id !==
              InboundManifestLineStatuses.NEW &&
            inboundManifestLine.inbound_manifest_line_status.id !==
              InboundManifestLineStatuses.IN_PROGRESS
          }
          onClick={() => {
            terminateManifestLineDialog({
              inbound_manifest_id: inbound_manifest_id,
              inbound_manifest_line_id: inboundManifestLine.id,
              type: CHANGE_IBM_LINE_STATUS.MARK_AS_DONE,
            });
          }}
        >
          Mark as Done
        </SecondaryButton>
      </ButtonGroup>
    </PrimaryLineWrapper>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    terminateIBMLineState: state.terminateInboundManifestLineReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    terminateIBMLine: (body: TerminateInboundManifestLineActionBody) =>
      dispatch(terminateInboundManifestLineAction(body)),
    terminateIBMLineReset: () => dispatch(terminateInboundManifestLineReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(InboundManifestLine);
