import {
  Box,
  DataPoint,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { ArrayElement } from "../../../../../util/interfaces";
import { ClientOrder } from "../graphql/types";

// TODO: Rename from Client Order Line Fulfilment to Pre Purchase Order
interface ClientOrderLineFulfilmentProps {
  client_order_line_fulfilment: ArrayElement<
    ArrayElement<ClientOrder["client_order_lines"]>["pre_purchase_orders"]
  >;
  fulfilmentIndex: number;
}

const ClientOrderLineFulfilment = ({
  client_order_line_fulfilment,
  fulfilmentIndex,
}: ClientOrderLineFulfilmentProps) => {
  return (
    <SecondaryLineWrapper>
      <Title>Pre-Purchase Order Line #{fulfilmentIndex + 1}</Title>
      <SecondaryLineDataWrapper>
        <DataPoint withUniqueMargin={true}>
          <Label isGray> Quantity </Label>
          <Box> {client_order_line_fulfilment.quantity} </Box>
        </DataPoint>
        <DataPoint withUniqueMargin={true}>
          <Label isGray> Allocated Quantity </Label>
          <Box> {client_order_line_fulfilment.allocated_quantity} </Box>
        </DataPoint>
      </SecondaryLineDataWrapper>
    </SecondaryLineWrapper>
  );
};

export default ClientOrderLineFulfilment;
