import { ApolloError } from "@apollo/client";

import { useGetPurchaseOrdersQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { PurchaseOrderItem } from "../types";

export interface HookResult {
  purchaseOrders: PurchaseOrderItem[];
  purchaseOrdersError?: ApolloError;
  purchaseOrdersLoading: boolean;
}

export const usePurchaseOrders = (): HookResult => {
  const { data, error, loading } = useGetPurchaseOrdersQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    purchaseOrders: data && Array.isArray(data.purchase_order) ? data.purchase_order : [],
    purchaseOrdersError: error,
    purchaseOrdersLoading: loading,
  };
};
