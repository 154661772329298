import { styled } from "@sourceful/shared-components";

export const ModalContainer = styled("div", {
  width: "500px",
  maxWidth: "90vw",
  paddingTop: "10px",
});

export const FlexEndContainer = styled("div", {
  display: "flex",
  justifyContent: "flex-end",
});
