import { LOGISTICS_API } from "../../../../../api/baseConfig";
import { CreateShipmentInTransitUpdateBody } from "./types";

export interface CreateShipmentInTransitUpdateRequest {
  shipmentId: number;
  body: CreateShipmentInTransitUpdateBody;
}

export const createShipmentInTransitUpdateAPI = async ({
  shipmentId,
  body,
}: CreateShipmentInTransitUpdateRequest) => {
  try {
    return await LOGISTICS_API.patch(`/shipments/${shipmentId}/in-transit`, body);
  } catch (err) {
    console.log("Error in create in-transit update for shipment API call", err);
    throw err;
  }
};
