import { DropdownItem } from "../graphql/types";

export interface StockTransferForm {
  warehouse: DropdownItem | null;
  from_location: DropdownItem | null;
  to_location: DropdownItem | null;
  transfer_lines: TransferLine[];
}

export interface TransferLine {
  id: null | number;
  source_product_stock: DropdownItem | null;
  quantity: string;
}

export const initialFieldValues: StockTransferForm = {
  from_location: null,
  warehouse: null,
  to_location: null,
  transfer_lines: [],
};

export const fieldNames = {
  warehouse: "warehouse",
  from_location: "from_location",
  to_warehouse: "to_warehouse",
  to_location: "to_location",
};

export const emptyTransferLine: TransferLine = {
  id: null,
  quantity: "",
  source_product_stock: null,
};
