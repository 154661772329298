import { Formik, FormikState } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { DeleteFileCopiesBody, FileCopies } from "../api/types";
import { DeleteFileCopiesForm, fieldNames, initialFieldValues } from "../formValues";
import { useFileCopiesByDate } from "../graphql/hooks/useFileCopiesByDate";
import {
  deleteFileCopiesAction,
  deleteFileCopiesReset,
} from "../redux/actions/deleteFileCopiesAction";
import { DeleteFileCopiesReducer } from "../redux/reducers/deleteFileCopiesReducer";
import { validationSchema } from "../validation";

interface Props {
  deleteFileCopiesState: DeleteFileCopiesReducer;
  deleteFileCopies: (body: DeleteFileCopiesBody) => void;
  resetDeleteFileCopies: () => void;
}

const DeletFileCopies = (props: Props) => {
  const { deleteFileCopiesState } = props;

  const [initialValues, setInitialValues] = useState<DeleteFileCopiesForm>(initialFieldValues);
  const [isDisplayingFileCopies, setIsDisplayingFileCopies] = useState<boolean>(false);

  const onSubmit = async (
    values: DeleteFileCopiesForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<DeleteFileCopiesForm>>) => void }
  ) => {
    const fileCopiesToDelete = fileCopies.map(file_copy => {
      const fileCopiesBody: FileCopies = {
        product_stock_id: file_copy.id!,
      };

      return fileCopiesBody;
    })!;

    if (fileCopiesToDelete.length === 0) {
      toast.error("No File Copies Found");
      return;
    }

    const deleteFileCopiesBody: DeleteFileCopiesBody = {
      file_copies: fileCopiesToDelete,
    };

    props.deleteFileCopies(deleteFileCopiesBody);
  };

  const { fileCopies, fileCopiesLoading, fileCopiesError, getFileCopiesByDate } =
    useFileCopiesByDate();

  useEffect(() => {
    if (deleteFileCopiesState.success) {
      props.resetDeleteFileCopies();
      setInitialValues({ ...initialFieldValues });
      setIsDisplayingFileCopies(false);
    }

    if (deleteFileCopiesState.error) {
      props.resetDeleteFileCopies();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteFileCopiesState.success, deleteFileCopiesState.error]);

  return (
    <Page error={fileCopiesError} isLoading={false} title={"Delete File Copies"}>
      <Panel
        title={"Delete File Copies"}
        subtitle={"Select the dates for which you want to delete file copies"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setTouched,
              validateForm,
              handleChange,
              handleSubmit,
              handleReset,
              errors,
              touched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label
                        isGray={isDisplayingFileCopies ? true : false}
                        isRequired
                        htmlFor={fieldNames.from_date}
                      >
                        From Date
                      </Label>
                      {isDisplayingFileCopies ? (
                        <Box>{values.from_date}</Box>
                      ) : (
                        <DateInput
                          id={fieldNames.from_date}
                          type={"date"}
                          value={values.from_date}
                          onChange={handleChange}
                        />
                      )}
                      {errors.from_date && touched.from_date ? (
                        <ErrorMessage>{errors.from_date}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  {!isDisplayingFileCopies && (
                    <ButtonWrapper
                      css={{
                        display: "flex",
                        width: "100%",

                        justifyContent: "right",
                      }}
                    >
                      <SecondaryButton
                        css={{ marginTop: 20 }}
                        type="button"
                        appearance={"whiteButtonBlueText"}
                        onClick={async () => {
                          setTouched({
                            from_date: true,
                          });

                          const formErrors = await validateForm();

                          if (Object.keys(formErrors).length === 0) {
                            getFileCopiesByDate({ fromDate: values.from_date });
                            setInitialValues({ ...values });
                            setIsDisplayingFileCopies(true);
                          }
                        }}
                      >
                        <IconText text={"Obtain File Copies "} primaryIcon={"alert-add-outline"} />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}

                  {isDisplayingFileCopies && (
                    <PrimaryLineWrapper>
                      <Title>Obtained File Copies</Title>
                      <PrimaryLineDataWrapper>
                        <DataPoint withUniqueMargin>
                          <Label>Number of File Copies Obtained</Label>
                          <Box>{fileCopiesLoading ? "Loading" : fileCopies?.length}</Box>
                        </DataPoint>
                      </PrimaryLineDataWrapper>
                    </PrimaryLineWrapper>
                  )}

                  {isDisplayingFileCopies && (
                    <ButtonGroup className={WMSButtonGroup({ type: "extraLargeMargin" })}>
                      <SecondaryButton
                        appearance={"whiteButton"}
                        type="button"
                        onClick={() => {
                          handleReset();
                          setInitialValues(initialFieldValues);
                          setIsDisplayingFileCopies(false);
                        }}
                      >
                        Cancel
                      </SecondaryButton>
                      <SecondaryButton appearance={"blueButton"} type="submit">
                        Confirm
                      </SecondaryButton>
                    </ButtonGroup>
                  )}
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    deleteFileCopiesState: state.deleteFileCopiesReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    deleteFileCopies: (body: DeleteFileCopiesBody) => dispatch(deleteFileCopiesAction(body)),
    resetDeleteFileCopies: () => dispatch(deleteFileCopiesReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeletFileCopies);
