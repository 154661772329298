import moment from "moment";
import { useParams } from "react-router-dom";

import Panel from "../../../../../components/panel/panel";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  FlexTitle,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useWorkOrderById } from "../graphql/hooks/useWorkOrderViewById";

const ViewWorkOrder = () => {
  const { work_order_id } = useParams<{ work_order_id: string }>();

  const { workOrder, workOrderLoading, workOrderError } = useWorkOrderById(Number(work_order_id));

  return (
    <Page error={workOrderError} isLoading={workOrderLoading} title={"Review Work Order"}>
      <Panel
        withWrapper
        title={"Review Work Order "}
        subtitle={"Review of current work order"}
        manifestStatusName={workOrder?.work_order_status.work_order_status_name}
      >
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <DisplayBox label="Warehouse" value={workOrder?.warehouse.warehouse_name} />
            <DisplayBox label="Due Date" value={moment(workOrder?.due_date).format("DD-MM-YYYY")} />
            <DisplayBox label="External Work Order ID" value={workOrder?.external_work_order_id} />
            <DisplayBox label="Quantity" value={workOrder?.quantity} />
            <DisplayBox label="Completed Quantity" value={workOrder?.completed_quantity} />
            <DisplayBox label="Cancelled Quantity" value={workOrder?.cancelled_quantity} />
            <DisplayBox label="Recipe Name" value={workOrder?.recipe?.recipe_name} />
            <DisplayBox label="Organisation" value={workOrder?.organisation.organisation_name} />
          </TopPanelInputsWrapper>

          {workOrder?.work_order_input_lines.map(inputLine => (
            <PrimaryLineWrapper key={inputLine.id}>
              <Title>{`Work Order Input Line #${inputLine.id}`}</Title>
              <PrimaryLineDataWrapper>
                <DisplayBox
                  label="Product Stock Name"
                  value={inputLine.product_stock.product.product_name}
                />
                <DisplayBox label="Quantity" value={inputLine.quantity} />
              </PrimaryLineDataWrapper>
            </PrimaryLineWrapper>
          ))}

          {workOrder?.work_order_output_lines.map(outputLine => (
            <PrimaryLineWrapper key={outputLine.id}>
              <FlexTitle>
                <Title>{`Work Order Output Line #${outputLine.id}`}</Title>
              </FlexTitle>
              <PrimaryLineDataWrapper>
                <DisplayBox label="Quantity" value={outputLine.quantity} />

                {outputLine.product_stocks.map((product_stock, index) => (
                  <SecondaryLineWrapper type="withIndex" key={index}>
                    <IndexPill index={index + 1} />
                    <Title>{`Product Stock #${index + 1}`}</Title>
                    <SecondaryLineDataWrapper>
                      <DisplayBox
                        label="Product Stock Name"
                        value={product_stock.product.product_name}
                      />
                      <DisplayBox label="Quantity" value={product_stock.initial_quantity} />
                      <DisplayBox label="Location" value={product_stock.location.location_name} />
                    </SecondaryLineDataWrapper>
                  </SecondaryLineWrapper>
                ))}
              </PrimaryLineDataWrapper>
            </PrimaryLineWrapper>
          ))}
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default ViewWorkOrder;
