import { STOCK_MANAGEMENT_API } from "../../../../../api/baseConfig";

export interface CreateStockManagementOrganisationBody {
  defaultCurrency: string;
  externalOrganisationId: string;
  organisationTypeId: number;
  config: OrganisationConfig;
  name: string;
  locale: string;
}
export interface PaymentDueDate {
  type: string;
  value: number;
}
export interface OrganisationConfig {
  paymentTerms: string;
  paymentDueDate: PaymentDueDate;
  xeroClientName: string;
  orderFlowTypeId: number;
  autostockEnabled: boolean;
  mainProviderType: string;
  paymentDisclaimer: string;
  productsSourceType: string;
  selfserviceEnabled: boolean;
  orderProcessingType: string;
  productPricingSourceType: string;
  deliveryInvoicedSeparately: boolean;
  computeDeliveryCostPerProduct: boolean;
  enableClientReferenceCollection: boolean;
  prepaidStock: boolean;
  displayProductUnitCost: boolean;
}

export const createStockManagementOrganisation = async (
  body: CreateStockManagementOrganisationBody
) => {
  try {
    return await STOCK_MANAGEMENT_API.post("/organisations", body);
  } catch (err) {
    console.error("Error in create organisation API call", err);
    throw err;
  }
};
