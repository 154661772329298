import {
  ACTIVE_SERVICE_ADMIN,
  ACTIVE_SERVICE_DEFAULT,
  ACTIVE_SERVICE_LOGISTICS,
  ACTIVE_SERVICE_ORDERS,
  ACTIVE_SERVICE_PCAT,
  ACTIVE_SERVICE_SOURCING,
  ACTIVE_SERVICE_STOCK_MANAGEMENT,
  ACTIVE_SERVICE_WMS,
} from "../types/reduxTypes";

export interface ActiveServiceReducer {
  activeService:
    | "Sourcing"
    | "WMS"
    | "Logistics"
    | "Admin"
    | "Orders"
    | "PCAT"
    | "StockManagement"
    | "DEFAULT";
}

const initialState: ActiveServiceReducer = {
  activeService: "DEFAULT",
};

export default function activeServiceReducer(
  state: ActiveServiceReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case ACTIVE_SERVICE_LOGISTICS:
      return {
        ...state,
        activeService: "Logistics",
      };
    case ACTIVE_SERVICE_WMS:
      return {
        ...state,
        activeService: "WMS",
      };
    case ACTIVE_SERVICE_SOURCING:
      return {
        ...state,
        activeService: "Sourcing",
      };
    case ACTIVE_SERVICE_ADMIN:
      return {
        ...state,
        activeService: "Admin",
      };
    case ACTIVE_SERVICE_PCAT:
      return {
        ...state,
        activeService: "PCAT",
      };
    case ACTIVE_SERVICE_ORDERS:
      return {
        ...state,
        activeService: "Orders",
      };
    case ACTIVE_SERVICE_STOCK_MANAGEMENT:
      return {
        ...state,
        activeService: "StockManagement",
      };
    case ACTIVE_SERVICE_DEFAULT:
      return {
        ...state,
        activeService: "DEFAULT",
      };
    default:
      return state;
  }
}
