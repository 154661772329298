import { UploadedFile } from "../../../../../attachments/fileUpload";
import { QAInspectionStatusItem } from "../graphql/types";
import { InspectionLineInterface } from "../types";

export const mapInspectionLinesToDTO = (
  inspectionLines: InspectionLineInterface[],
  statuses: QAInspectionStatusItem[]
) => {
  return inspectionLines
    .filter(line => line.qa_inspection_line_state !== "existing")
    .map(line => {
      const status_id = statuses.filter(
        status =>
          status.quality_assurance_status_name.toLowerCase() === line.qa_inspection_line_status
      )[0]?.id;

      return {
        qaInspectionTypeId: line.qa_inspection_type?.value || 1,
        qaInspectionLineStatusId: status_id,
        qaInspectionLineNotes: line.qa_inspection_line_notes,
      };
    });
};

export const mapAttachmentsToDTO = (attachments: UploadedFile[]) => {
  return attachments.map(attachment => ({
    originalFilename: attachment.original_filename,
    uuid: attachment.attachment_uuid,
  }));
};
