import { ApolloError } from "@apollo/client";

import { useGetInternalAddressesQuery } from "../../../../../../generated/graphql_logistics";
import { DropdownItem } from "../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_LOGISTICS } from "../../../../../../shared/constants";
import { mapAddressToDropdownItem } from "../../../../shared/graphql/mappers/mapAddressToDropdownItem";

export interface HookResult {
  internalAddresses: DropdownItem[];
  internalAddressesError?: ApolloError;
  internalAddressesLoading: boolean;
}

export const useInternalAddresses = (): HookResult => {
  const { data, error, loading } = useGetInternalAddressesQuery({
    context: { clientName: GRAPHQL_LOGISTICS },
  });

  return {
    internalAddresses:
      data && Array.isArray(data.address) ? mapAddressToDropdownItem(data.address) : [],
    internalAddressesError: error,
    internalAddressesLoading: loading,
  };
};
