import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { useAuth0 } from "@auth0/auth0-react";
import { FC } from "react";

import {
  GRAPHQL_LOGISTICS,
  GRAPHQL_PCAT,
  GRAPHQL_SOURCING,
  GRAPHQL_STOCK_MANAGEMENT,
  GRAPHQL_WMS,
} from "../shared/constants";
import { useAuthorisationContext } from "./AuthorisationProvider";

const HASURA_DEFAULT_ROLE_HEADER = "x-hasura-role";

const ApolloWrapper: FC = ({ children }) => {
  const { isLoading, getAccessTokenSilently, isAuthenticated } = useAuth0();
  const { activeRole: role } = useAuthorisationContext();

  const wmsHttpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_WMS_ENDPOINT
      ? `${process.env.REACT_APP_HASURA_WMS_ENDPOINT}/v1/graphql`
      : `${window.REACT_APP_HASURA_WMS_ENDPOINT}/v1/graphql`,
  });

  const sourcingHttpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_SOURCING_ENDPOINT
      ? `${process.env.REACT_APP_HASURA_SOURCING_ENDPOINT}/v1/graphql`
      : `${window.REACT_APP_HASURA_SOURCING_ENDPOINT}/v1/graphql`,
  });

  const logisticsHttpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_LOGISTICS_ENDPOINT
      ? `${process.env.REACT_APP_HASURA_LOGISTICS_ENDPOINT}/v1/graphql`
      : `${window.REACT_APP_HASURA_LOGISTICS_ENDPOINT}/v1/graphql`,
  });

  const pcatHttpLink = createHttpLink({
    uri: process.env.REACT_APP_HASURA_PCAT_ENDPOINT
      ? `${process.env.REACT_APP_HASURA_PCAT_ENDPOINT}/v1/graphql`
      : `${window.REACT_APP_HASURA_PCAT_ENDPOINT}/v1/graphql`,
  });

  const stockManagementHttpLink = createHttpLink({
    uri: process.env.REACT_APP_STOCK_MANAGEMENT_API_BASE_URL
      ? `${process.env.REACT_APP_STOCK_MANAGEMENT_API_BASE_URL}/stock-management-api/api/graphql`
      : `${window.REACT_APP_STOCK_MANAGEMENT_API_BASE_URL}/stock-management-api/api/graphql`,
  });

  const authLink = setContext(async (_, { headers }) => {
    if (isAuthenticated) {
      const token = await getAccessTokenSilently();
      if (token) {
        return {
          headers: {
            ...headers,
            [HASURA_DEFAULT_ROLE_HEADER]: role,
            authorization: `Bearer ${token}`,
          },
        };
      }
    }

    return {
      headers: {
        ...headers,
      },
    };
  });

  const dynamicLink = (links: { [key: string]: ApolloLink }) =>
    new ApolloLink((operation, forward) => {
      const { clientName } = operation.getContext();
      const link = links[clientName]; // Get the link based on clientName

      if (!link) {
        throw new Error(
          `No link found for clientName when setting up Apollo Client: ${clientName}`
        );
      }
      return link.request(operation, forward);
    });

  const dynamicClientLink = dynamicLink({
    [GRAPHQL_SOURCING]: sourcingHttpLink,
    [GRAPHQL_LOGISTICS]: logisticsHttpLink,
    [GRAPHQL_STOCK_MANAGEMENT]: stockManagementHttpLink,
    [GRAPHQL_WMS]: wmsHttpLink,
    [GRAPHQL_PCAT]: pcatHttpLink,
  });

  const client = new ApolloClient({
    link: authLink.split(
      operation =>
        [GRAPHQL_SOURCING, GRAPHQL_LOGISTICS, GRAPHQL_PCAT, GRAPHQL_STOCK_MANAGEMENT].includes(
          operation.getContext().clientName
        ),
      dynamicClientLink,
      wmsHttpLink // WMS is default
    ),
    cache: new InMemoryCache(),
    defaultOptions: {
      watchQuery: {
        fetchPolicy: "cache-and-network",
      },
    },
  });

  if (isLoading === null || !client) {
    return null;
  }

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};

export default ApolloWrapper;
