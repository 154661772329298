import { ApolloError } from "@apollo/client";

import InnerHeader, { TopLevelButton } from "../../../../components/innerHeader/innerHeader";
import LoadingScreen from "../../../../components/loadingScreen/LoadingScreen";
import ErrorScreen from "../ErrorScreen";

export interface Props {
  children: React.ReactChildren | React.ReactElement;
  topLevelButtons?: TopLevelButton[];
  title: string;
  isLoading: boolean;
  hasBackButton?: boolean;
  error?: ApolloError | boolean;
  //TODO: review if appropriate to have different titleLevel sizes for pages
}

export const Page = ({
  children,
  title,
  error,
  isLoading,
  topLevelButtons = [],
  hasBackButton = true,
}: Props) => {
  let content = children;

  if (isLoading) {
    content = <LoadingScreen />;
  }
  if (error) {
    content = <ErrorScreen>Error while loading the data</ErrorScreen>;
  }

  return (
    <>
      <InnerHeader
        titleLevel={3}
        title={title}
        hasBackButton={hasBackButton}
        topLevelButtons={topLevelButtons}
      />
      {content}
    </>
  );
};

export default Page;
