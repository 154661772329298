export interface LocationForm {
  warehouse: {
    value: number;
    label: string;
  } | null;
  location_type: {
    value: number;
    label: string;
  } | null;
  location_name: string;
  parent_location: {
    value: number;
    label: string;
  } | null;
  can_hold_stock: {
    value: number;
    label: string;
  } | null;
}

export const initialFieldValues: LocationForm = {
  location_name: "",
  location_type: null,
  warehouse: null,
  parent_location: null,
  can_hold_stock: null,
};

export const fieldNames = {
  location_type: "location_type",
  warehouse: "warehouse",
  location_name: "location_name",
  parent_location: "parent_location",
  can_hold_stock: "can_hold_stock",
};
