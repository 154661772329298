import { Formik, FormikErrors } from "formik";
import { useEffect, useMemo } from "react";

import Table from "../../../../../components/Table";
import Icon from "../../../../../components/icons/Icon";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import Page from "../../../../../shared/components/templates/Page/Page";
import {
  DataPoint,
  IconWrapper,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { updateGlobalDeliveryDateConfigAPI } from "../api/updateGlobalDeliveryDateConfigAPI";
import { updateSupplierDeliveryDateConfigAPI } from "../api/updateSupplierDeliveryDateConfigAPI";
import { SupplierDateLine } from "../components/SupplierDateLine";
import { useDeliveryDates } from "../graphql/hooks/useDeliveryDates";
import { SupplierDeliveryDateItem } from "../graphql/types";
import { SupplierConfigInterface, initialSupplierConfig } from "../types";
import { globalValidationSchema, supplierValidationSchema } from "../validation";

export default function ViewDeliveryDates() {
  const [updateGlobalConfigState, updateGlobalConfig] = useAPI(updateGlobalDeliveryDateConfigAPI);
  const [updateSupplierConfigState, updateSupplierConfig] = useAPI(
    updateSupplierDeliveryDateConfigAPI
  );

  const { deliveryDates, deliveryDatesLoading, deliveryDatesError, refetchDeliveryDates } =
    useDeliveryDates();

  useEffect(() => {
    if (
      updateGlobalConfigState === API_REQUEST_STATE.SUCCESS ||
      updateSupplierConfigState === API_REQUEST_STATE.SUCCESS
    )
      refetchDeliveryDates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateGlobalConfigState, updateSupplierConfigState]);

  const columns = useMemo(
    () => [
      {
        Header: "Supplier",
        widthPercent: 2,
        accessor: (supplier: SupplierDeliveryDateItem) => supplier.supplierOrganisationName,
      },
      {
        Header: "Config Days",
        widthPercent: 1,
        accessor: (supplier: SupplierDeliveryDateItem) => supplier.supplierConfigDays,
      },
    ],
    []
  );

  return (
    <Page
      title="Suppliers"
      isLoading={deliveryDatesLoading}
      error={deliveryDatesError}
      hasBackButton
    >
      <Panel withWrapper title={"Delivery Date Configs"} allignTitle="left">
        <InnerPanelWrapper>
          <Formik
            key="globalConfigDate"
            initialValues={{ globalConfigDate: undefined }}
            enableReinitialize
            onSubmit={async values =>
              values.globalConfigDate &&
              (await updateGlobalConfig({ configValue: values.globalConfigDate }))
            }
            validationSchema={globalValidationSchema}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched }) => (
              <StyledForm onSubmit={handleSubmit} style={{ marginBottom: "20px" }}>
                <PrimaryLineWrapper>
                  <Title>Global Delivery Date Config</Title>
                  <PrimaryLineDataWrapper>
                    <DataPoint>
                      <DisplayBox
                        label={"Current Global Delivery Date Config"}
                        value={deliveryDates?.globalConfigDays.toString()}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        name="globalConfigDate"
                        type={"number"}
                        placeholder="Update Global Delivery Date Config"
                        value={values.globalConfigDate}
                        error={errors.globalConfigDate}
                        touched={touched.globalConfigDate}
                        label="Update Global Delivery Date Config"
                        changeHandler={e => setFieldValue("globalConfigDate", e.target.value)}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SecondaryButton appearance="blueButton" type="submit">
                        Update Global Config
                      </SecondaryButton>
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>
              </StyledForm>
            )}
          </Formik>
          <Table data={deliveryDates?.supplierConfigs ?? []} columns={columns} />
          <Formik
            key="supplierConfigsDate"
            initialValues={{ supplierConfigs: [initialSupplierConfig] }}
            enableReinitialize
            validationSchema={supplierValidationSchema}
            onSubmit={async values => {
              const supplierConfigBody = values.supplierConfigs
                .filter(
                  supplierConfig => !!supplierConfig.supplier && !!supplierConfig.supplierConfigDay
                )
                .map(supplierConfig => {
                  return {
                    supplierId: supplierConfig.supplier!.value,
                    configValue: Number(supplierConfig.supplierConfigDay!),
                  };
                });

              await updateSupplierConfig({ supplierConfigs: supplierConfigBody });
            }}
          >
            {({ values, setFieldValue: setAFieldValue, handleSubmit, errors, touched }) => (
              <StyledForm onSubmit={handleSubmit}>
                {values.supplierConfigs.length > 0 && (
                  <>
                    <PrimaryLineWrapper>
                      <Title>Supplier Delivery Date Config</Title>
                      {values.supplierConfigs.map((supplierConfig, index) => (
                        <SupplierDateLine
                          key={`supplier_date_line${index}`}
                          values={supplierConfig}
                          suppliers={deliveryDates?.suppliers ?? []}
                          index={index}
                          errors={errors.supplierConfigs as FormikErrors<SupplierConfigInterface[]>}
                          touched={touched.supplierConfigs}
                          handleRemoveSupplierLine={(lineIndex: number) => {
                            const newValues = { ...values };
                            newValues.supplierConfigs = values.supplierConfigs.filter(
                              (_item, i) => i !== lineIndex
                            );
                            if (newValues.supplierConfigs.length === 0) {
                              newValues.supplierConfigs = [initialSupplierConfig];
                            }

                            setAFieldValue("supplierConfigs", newValues.supplierConfigs);
                          }}
                          setFieldValue={setAFieldValue}
                        />
                      ))}
                      <DataPoint fullWidth centeredText>
                        <IconWrapper
                          type="button"
                          onClick={() => {
                            const newValues = { ...values };
                            newValues.supplierConfigs = [
                              ...values.supplierConfigs,
                              initialSupplierConfig,
                            ];
                            setAFieldValue("supplierConfigs", newValues.supplierConfigs);
                          }}
                        >
                          <Icon width={30} height={30} name="navigation-plus" />
                        </IconWrapper>
                      </DataPoint>
                    </PrimaryLineWrapper>
                    <SecondaryButton
                      appearance="blueButton"
                      type="submit"
                      className={WMSButtonGroup({ type: "largeMargin" })}
                    >
                      Update Supplier Config
                    </SecondaryButton>
                  </>
                )}
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}
