import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, InputField } from "@sourceful/shared-components";

import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import {
  DataPoint,
  DateInput,
  Label,
  SecondaryLineWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { CreateOutboundReturnBody } from "../api/types";
import {
  returnOutboundManifestLineAction,
  returnOutboundManifestLineReset,
} from "../redux/actions/returnOutboundManifestLineAction";
import { ReturnOutboundManifestLineReducer } from "../redux/reducers/returnOutboundManifestLineReducer";

export interface OBMFulfillerProps {
  setFulfilmentLineToReturn: (val: number | null) => void;
  fulfilmentLineId: number;
  lineId: number;
  returnOBMFulfilmentLineState: ReturnOutboundManifestLineReducer;
  returnOBMFulfilmentLine: (body: CreateOutboundReturnBody) => void;
  returnOBMFulfilmentLineReset: () => void;
  refetchOutboundManifest: () => void;
}

const ReturnOutboundManifestLine = ({
  fulfilmentLineId,
  setFulfilmentLineToReturn,
  lineId,
  returnOBMFulfilmentLineState,
  returnOBMFulfilmentLine,
  returnOBMFulfilmentLineReset,
  refetchOutboundManifest,
}: OBMFulfillerProps) => {
  const { outbound_manifest_id } = useParams<{ outbound_manifest_id: string }>();

  const [returnQuantity, setReturnQuantity] = useState<string>("");
  const [returnDate, setReturnDate] = useState<string>("");

  const initiateOBMFulfilmentLineReturn = ({ ffline_id }: { ffline_id: number }) => {
    if (returnQuantity === "" || returnDate === "") {
      toast.error("Missing inputs - make sure that a return date and return quantity is supplied");
      return;
    }

    const returnOBMFulfilmentLineBody: CreateOutboundReturnBody = {
      outbound_manifest_id: Number(outbound_manifest_id),
      return_received_date: returnDate,
      returned_quantity: Number(returnQuantity),
      outbound_manifest_fulfilment_line_id: ffline_id,
      outbound_manifest_line_id: lineId,
    };

    returnOBMFulfilmentLine(returnOBMFulfilmentLineBody);
  };

  useEffect(() => {
    if (returnOBMFulfilmentLineState.success) {
      setFulfilmentLineToReturn(null);
      setReturnDate("");
      setReturnQuantity("");
      refetchOutboundManifest();
      returnOBMFulfilmentLineReset();
    }
    if (returnOBMFulfilmentLineState.error) {
      returnOBMFulfilmentLineReset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [returnOBMFulfilmentLineState.success, returnOBMFulfilmentLineState.error]);

  return (
    <SecondaryLineWrapper>
      <DataPoint>
        <Label>Quantity to Return</Label>
        <InputField
          id={`return_quanity_${fulfilmentLineId}`}
          size={"medium"}
          type={"number"}
          value={returnQuantity}
          handleChange={e => {
            setReturnQuantity(e.target.value);
          }}
          placeholder={"Input Quantity"}
        />
      </DataPoint>
      <DataPoint>
        <Label>Returned Date</Label>
        <DateInput
          id={`returned_date_${fulfilmentLineId}`}
          type="date"
          value={returnDate}
          onChange={e => {
            setReturnDate(e.target.value);
          }}
        />
      </DataPoint>

      <ButtonGroup
        className={WMSButtonGroup({
          type: "largeMargin",
          withinSecondaryLine: true,
        })}
      >
        <SecondaryButton
          appearance={"whiteButton"}
          onClick={() => {
            setFulfilmentLineToReturn(null);
            setReturnQuantity("");
            setReturnDate("");
          }}
        >
          Cancel Return
        </SecondaryButton>
        <SecondaryButton
          appearance={"blueButton"}
          onClick={() =>
            initiateOBMFulfilmentLineReturn({
              ffline_id: fulfilmentLineId,
            })
          }
        >
          Confirm Return
        </SecondaryButton>
      </ButtonGroup>
    </SecondaryLineWrapper>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    returnOBMFulfilmentLineState: state.returnOutboundManifestLineReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    returnOBMFulfilmentLine: (body: CreateOutboundReturnBody) =>
      dispatch(returnOutboundManifestLineAction(body)),
    returnOBMFulfilmentLineReset: () => dispatch(returnOutboundManifestLineReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReturnOutboundManifestLine);
