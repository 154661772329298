import { ApolloError } from "@apollo/client";

import { useGetProductStocksQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStocks: ProductStockItem[];
  productStocksError?: ApolloError;
  productStocksLoading: boolean;
}

export const useProductStock = (): HookResult => {
  const { data, error, loading } = useGetProductStocksQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    productStocks: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    productStocksError: error,
    productStocksLoading: loading,
  };
};
