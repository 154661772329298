import { CommunicationMethodItem } from "../graphql/types";

export const mapCommunicationMethodsToDropdownObject = (
  communicationMethods: CommunicationMethodItem[]
) => {
  return communicationMethods.map(communicationMethod => {
    return {
      label: communicationMethod.communication_method_name,
      value: communicationMethod.id,
    };
  });
};
