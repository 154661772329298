import { PRODUCT_CATALOGUE_API } from "../../../../../api/baseConfig";

export interface LinkSkuToOrganisationBody {
  sku_id: string;
  org_ids: string[];
}

export const linkSkuToOrganisation = async (body: LinkSkuToOrganisationBody) => {
  try {
    return await PRODUCT_CATALOGUE_API.post("/sku-orgs", body);
  } catch (err) {
    console.log("Error in link sku to organisation API call", err);
    throw err;
  }
};
