import { ApolloError } from "@apollo/client";

import { useGetAllStockManagementOrganisationsQuery } from "../../../../../../generated/graphql_stock_management";
import { GRAPHQL_STOCK_MANAGEMENT } from "../../../../../../shared/constants";
import { mapOrganisation } from "../mappers";
import { Organisation } from "../types";

export interface HookResult {
  organisations?: Organisation[];
  organisationsError?: ApolloError;
  organisationsLoading: boolean;
}

export const useAllOrganisations = (): HookResult => {
  const { data, error, loading } = useGetAllStockManagementOrganisationsQuery({
    context: {
      clientName: GRAPHQL_STOCK_MANAGEMENT,
    },
  });

  return {
    organisations: data ? data.allOrganisations.map(mapOrganisation) : [],
    organisationsError: error,
    organisationsLoading: loading,
  };
};
