import {
  DropdownItem,
  StringListDropdownItem,
} from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { REPORT_TYPE } from "../types";

export const fieldNames = {
  report: "report",
  supplierCountryCode: "supplierCountryCode",
  fromDate: "fromDate",
  toDate: "toDate",
};

export interface ReportForm {
  report: DropdownItem | null;
  supplierCountryCode?: StringListDropdownItem | null;
  fromDate?: string | null;
  toDate?: string | null;
}

export const reportOptions: DropdownItem[] = [
  { label: "Order Export", value: REPORT_TYPE.ORDER_EXPORT },
];
