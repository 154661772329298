import { ApolloError } from "@apollo/client";

import { useGetQuotationCostLocalesQuery } from "../../../../../../../generated/graphql_sourcing";
import { DropdownItem } from "../../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { mapQuotationCostTypeLocales } from "../../mappers";

export interface HookResult {
  locales: DropdownItem[];
  localesLoading: boolean;
  localesError?: ApolloError;
}

export const useQuotationCostLocales = (): HookResult => {
  const { data, loading, error } = useGetQuotationCostLocalesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    locales: (data && mapQuotationCostTypeLocales(data.locale)) || [],
    localesLoading: loading,
    localesError: error,
  };
};
