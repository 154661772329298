import { LocationNameItem, LocationTypeNameItem, WarehouseNameItem } from "../graphql/types";

export const mapLocationTypeNamesToDrodownObject = (locationTypeNames: LocationTypeNameItem[]) => {
  return locationTypeNames.map(locationTypeName => {
    return {
      label: locationTypeName.location_type_name,
      value: locationTypeName.id,
    };
  });
};

export const mapWarehouseNamesToDrodownObject = (warehouseNames: WarehouseNameItem[]) => {
  return warehouseNames.map(warehouseName => {
    return {
      label: warehouseName.warehouse_name,
      value: warehouseName.id,
    };
  });
};

export const mapParentLocationNamesToDropdownObject = (locationNames: LocationNameItem[]) => {
  return locationNames.map(locationName => {
    return {
      label: locationName.location_name,
      value: locationName.id,
    };
  });
};
