import { styled } from "@sourceful/shared-components";

export const Label = styled("label", {
  fontSize: "15px",
  fontWeight: "bold",
  color: "#000000",
  variants: {
    isGray: {
      true: {
        color: "#8D8D8D",
      },
    },
    isRequired: {
      true: {
        "&::after": {
          content: "*",
        },
      },
    },
    isInlineBlock: {
      true: {
        display: "inline-block",
        marginInlineEnd: "20px",
      },
    },
    warning: {
      true: {
        color: "#D02257",
      },
    },
  },
});

export default Label;
