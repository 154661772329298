import qs from "query-string";

import { SOURCING_API } from "../../../../../api/baseConfig";
import { QuoteLineIdAndUnitCostPairs } from "../../createPurchaseOrder/types";
import GetQuotationCalculationBody from "./types/getQuotationCalculation";

export const getQuotationCalculationAPI = async (body: GetQuotationCalculationBody) => {
  try {
    const response = await SOURCING_API.get(`/quotation-calculations/`, {
      params: body,
      paramsSerializer: param => {
        return qs.stringify(param);
      },
    });

    const quotationCalculationPairs: QuoteLineIdAndUnitCostPairs[] =
      response?.data?.quotationCalculation || [];
    return quotationCalculationPairs;
  } catch (err) {
    console.log("Error in getting Quotation Calculation API call", err);
    throw err;
  }
};
