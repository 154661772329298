import { useMemo } from "react";

import { VerticalCard } from "../../../../../components/card/VerticalCard";
import { Page } from "../../../../../shared/components/templates/Page";
import { CardsWrapper } from "../../../../../styles/AdminPage";
import { DashboardItem } from "../../../../wms/dashboards/viewManifestDashboard/graphql/types";
import { usePendingDemand } from "../../../clientOrders/viewPendingDemand/graphql/hooks/usePendingDemand";
import { usePendingClientOrders } from "../graphql/hooks/usePendingClientOrders";
import { usePendingPurchaseOrders } from "../graphql/hooks/usePendingPurchaseOrders";

export default function ViewSourcingDashboard() {
  const { clientOrders, clientOrdersError, clientOrdersLoading } = usePendingClientOrders();
  const { purchaseOrders, purchaseOrdersError, purchaseOrdersLoading } = usePendingPurchaseOrders();
  const {
    data: pendingDemand,
    error: pendingDemandError,
    isLoading: pendingDemandLoading,
  } = usePendingDemand();

  const error = !!clientOrdersError || !!pendingDemandError || !!purchaseOrdersError;
  const isLoading = !!clientOrdersLoading || !!pendingDemandLoading || !!purchaseOrdersLoading;

  const dashboardClientOrders: DashboardItem[] = useMemo(() => {
    return clientOrders && Array.isArray(clientOrders)
      ? clientOrders.map(clientOrder => {
          return {
            orderId: clientOrder.id,
            organisationName: clientOrder.client_order.organisation.organisation_name,
            iconName: "default-calendar",
            date: clientOrder.created_at,
            link: `${clientOrder.client_order.id}`,
          };
        })
      : [];
  }, [clientOrders]);

  const dashboardPurchaseOrders: DashboardItem[] = useMemo(() => {
    return purchaseOrders && Array.isArray(purchaseOrders)
      ? purchaseOrders.map(purchaseOrder => {
          return {
            orderId: purchaseOrder.id,
            organisationName: purchaseOrder.supplier_organisation?.organisation_name || "",
            iconName: "default-calendar",
            date: purchaseOrder.created_at,
          };
        })
      : [];
  }, [purchaseOrders]);

  const dashboardPendingDemand: DashboardItem[] = useMemo(() => {
    return pendingDemand && Array.isArray(pendingDemand)
      ? pendingDemand
          .filter(demand => !!demand.product?.id)
          .map(demand => {
            return {
              orderId: demand.product?.id!,
              organisationName: demand.client_order?.organisation.organisation_name || "",
              iconName: "default-calendar",
              link: "",
            };
          })
      : [];
  }, [pendingDemand]);

  return (
    <>
      <Page error={error} isLoading={isLoading} title={"Sourcing Dashboard"} hasBackButton={false}>
        <CardsWrapper>
          <VerticalCard
            sortBy
            cardTitle={"Client Orders"}
            linkText="View client order"
            link="/client-orders/update-client-order/"
            tooltip="Pending Client Orders"
            scrollBarContent={dashboardClientOrders}
          />

          <VerticalCard
            sortBy
            cardTitle={"Purchase Orders"}
            linkText="View purchase order"
            link="/purchase-orders/update-purchase-order/"
            tooltip="Pending Purchase Orders"
            scrollBarContent={dashboardPurchaseOrders}
          />

          <VerticalCard
            sortBy
            cardTitle={"Pending Demand"}
            linkText="View pending demands"
            link="/client-orders/pending-demand/"
            tooltip="Pending Demand"
            scrollBarContent={dashboardPendingDemand}
          />
        </CardsWrapper>
      </Page>
    </>
  );
}
