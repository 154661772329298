import moment from "moment";

import {
  SupplierQuoteRequestItem,
  SupplierQuoteRequestLineItem,
  SupplierQuoteRequestObject,
} from "../../graphql/types";

export const mapToSupplierQuoteRequestObject = (
  supplierQuoteRequest: SupplierQuoteRequestItem
): SupplierQuoteRequestObject => {
  const groupedQuoteLines: SupplierQuoteRequestLineItem[][] = [];

  supplierQuoteRequest.supplier_quote_request_lines.forEach(line => {
    const existingGroup = groupedQuoteLines.find(group => group[0].product.id === line.product.id);
    if (existingGroup) {
      existingGroup.push(line);
    } else {
      groupedQuoteLines.push([line]);
    }
  });

  return {
    supplierQuotes: supplierQuoteRequest.supplier_quotes.map(quote => ({
      id: quote.id,
      validFrom: moment(quote.valid_from).format("DD-MM-YYYY"),
      validTo: moment(quote.valid_to).format("DD-MM-YYYY"),
    })),
    id: supplierQuoteRequest.id,
    currencyCode: supplierQuoteRequest.currency_code,
    status: supplierQuoteRequest.supplier_quote_request_status.supplier_quote_request_status_name,
    statusId: supplierQuoteRequest.supplier_quote_request_status.id,
    clientName: supplierQuoteRequest.client_name,
    validFrom: supplierQuoteRequest.supplier_quotes[0]
      ? moment(supplierQuoteRequest.supplier_quotes[0].valid_from).format("DD-MM-YYYY")
      : undefined,
    validTo: supplierQuoteRequest.supplier_quotes[0]
      ? moment(supplierQuoteRequest.supplier_quotes[0].valid_to).format("DD-MM-YYYY")
      : undefined,
    groupedLines: groupedQuoteLines.map(group => ({
      folder: group[0].folder,
      product: group[0].product,
      notes: group[0].notes,
      moqs: group.map(line => line.minimum_order_quantity).join(", "),
      lines: group.flatMap(line => line.supplier_quote_lines),
    })),
    contact: supplierQuoteRequest.contact,
    organisation: supplierQuoteRequest.organisation.organisation_name,
  };
};
