import { Heading, styled } from "@sourceful/shared-components";

import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import SimpleTextArea from "../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import {
  DataPoint,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
} from "../../../../../styles/SharedStyles";
import { SupplierQuoteLineItem } from "../graphql/types";

const SupplierQuoteLineCloser = styled("div", {
  marginTop: "20px",
  maxHeight: "0",
  overflow: "hidden",

  variants: {
    isLineOpen: {
      true: {
        maxHeight: "unset",
        overflow: "visible",
      },
    },
  },
});

interface SupplierQuoteLineProps {
  line: SupplierQuoteLineItem;
  handleSetOpenQuoteLine: (index: number | null) => void;
  openQuoteLine: number | null;
}

export const SupplierQuoteLine = ({
  line,
  handleSetOpenQuoteLine,
  openQuoteLine,
}: SupplierQuoteLineProps) => {
  const getDomesticLogisticFeeValue = () => {
    if (!line.domestic_logistics_fee_included) return undefined;
    return line.domestic_logistics_fee_included ? "Included" : "Not Included";
  };

  const getIsSampleFeeRefundableValue = () => {
    if (!line.is_sample_fee_refundable) return null;
    return line.is_sample_fee_refundable ? "Refundable" : "Not Refundable";
  };

  const isOpen = openQuoteLine === line.id;
  const carton = line.carton_details;

  const isLineSample = line.is_sample;
  const fillInDetailsString = isLineSample ? "Sample Details" : "Details";

  return (
    <PrimaryLineWrapper key={`line_${line.id}`} id={`line_${line.id}`}>
      <PrimaryLineDataWrapper>
        <DataPoint halfWidth withUniqueMargin>
          <DisplayBox
            fullWidth
            withUniqueMargin
            label="Quantity"
            value={line.minimum_order_quantity}
          />
        </DataPoint>

        <SecondaryButton
          css={{ marginTop: "15px" }}
          appearance="blueButton"
          type="button"
          onClick={() => {
            if (isOpen) handleSetOpenQuoteLine(null);
            else {
              handleSetOpenQuoteLine(line.id);
              // Scroll the current quote line into view using the "above div"
              setTimeout(() => {
                document.getElementById(`line_${line.id}`)?.scrollIntoView({ behavior: "smooth" });
              });
            }
          }}
        >
          {isOpen ? "Close" : fillInDetailsString}
        </SecondaryButton>
      </PrimaryLineDataWrapper>

      <SupplierQuoteLineCloser isLineOpen={isOpen}>
        <hr />
        <Heading level={3} fontSize={6} css={{ marginTop: "20px", marginBottom: "20px" }}>
          {isLineSample ? "Sample Pricing Details" : " Pricing Details"}
        </Heading>

        {line.quotation_costs.map(quotationCost => (
          <PrimaryLineDataWrapper
            key={`costType_${quotationCost.quotation_cost_type.id}_for_${line.id}`}
            marginSize="smallMargin"
          >
            <DataPoint halfWidth withUniqueMargin>
              <DisplayBox
                fullWidth
                withUniqueMargin
                label="Cost Type"
                value={quotationCost.quotation_cost_type.quotation_cost_type_name}
              />
            </DataPoint>
            <DataPoint halfWidth withUniqueMargin>
              <DisplayBox
                fullWidth
                withUniqueMargin
                label="Cost Excl. VAT"
                value={`${quotationCost.cost_excl_vat} ${quotationCost.currency_code}`}
              />
            </DataPoint>
            {quotationCost.vat_percentage && (
              <>
                <DataPoint halfWidth withUniqueMargin>
                  <DisplayBox
                    fullWidth
                    withUniqueMargin
                    label="VAT"
                    value={`${quotationCost.vat_percentage * 100}%`}
                  />
                </DataPoint>
                <DataPoint halfWidth withUniqueMargin>
                  <DisplayBox
                    fullWidth
                    withUniqueMargin
                    label="Total Cost"
                    value={`${(1 + quotationCost.vat_percentage) * quotationCost.cost_excl_vat}`}
                  />
                </DataPoint>
              </>
            )}
          </PrimaryLineDataWrapper>
        ))}

        {!isLineSample && (
          <>
            <hr />
            <Heading level={3} fontSize={6} css={{ marginTop: "20px" }}>
              Logistics Details
            </Heading>
            <PrimaryLineDataWrapper>
              <DataPoint withUniqueMargin>
                <DisplayBox withUniqueMargin label="Unit per Carton" value={carton.unitPerCarton} />
              </DataPoint>
            </PrimaryLineDataWrapper>
            <PrimaryLineDataWrapper>
              <DisplayBox label="Carton Length (mm)" value={carton.cartonLength} withUniqueMargin />
              <DisplayBox label="Carton Height (mm)" value={carton.cartonHeight} withUniqueMargin />
              <DisplayBox label="Carton Width (mm)" value={carton.cartonWidth} withUniqueMargin />
              <DisplayBox label="Carton Weight (g)" value={carton.cartonWeight} withUniqueMargin />
            </PrimaryLineDataWrapper>
            <PrimaryLineDataWrapper>
              <DataPoint withUniqueMargin fullWidth>
                <SimpleTextArea
                  size="medium"
                  type="text"
                  label="Packaging Options"
                  value={line.packaging_option || "No packaging options added."}
                  handleChange={_e => {}}
                />
              </DataPoint>
            </PrimaryLineDataWrapper>
          </>
        )}

        <PrimaryLineDataWrapper>
          <DataPoint withUniqueMargin halfWidth>
            <DisplayBox
              fullWidth
              withUniqueMargin
              label="Production Turnaround (Max)"
              value={line.lead_time_max_days}
            />
          </DataPoint>

          {isLineSample ? (
            <DataPoint withUniqueMargin halfWidth>
              <DisplayBox
                fullWidth
                withUniqueMargin
                label="Refundable Sample Fee?"
                value={getIsSampleFeeRefundableValue()}
              />
            </DataPoint>
          ) : (
            <DataPoint withUniqueMargin halfWidth>
              <DisplayBox
                fullWidth
                withUniqueMargin
                label="Domestic Logistics Fee"
                value={getDomesticLogisticFeeValue()}
              />
            </DataPoint>
          )}
        </PrimaryLineDataWrapper>
        {line.notes && (
          <PrimaryLineDataWrapper>
            <DataPoint withUniqueMargin fullWidth>
              <SimpleTextArea
                size="medium"
                type="text"
                label="Supplier Notes"
                value={line.notes || "No notes were added."}
                handleChange={_e => {}}
              />
            </DataPoint>
          </PrimaryLineDataWrapper>
        )}
      </SupplierQuoteLineCloser>
    </PrimaryLineWrapper>
  );
};
