import {
  RECIPE_REQUEST_FAILED,
  RECIPE_REQUEST_LOADING,
  RECIPE_REQUEST_RESET,
  RECIPE_REQUEST_SUCCESS,
} from "../types";

export interface RecipeRequestReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: RecipeRequestReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function recipeRequestReducer(
  state: RecipeRequestReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case RECIPE_REQUEST_LOADING:
      return {
        ...state,
        loading: true,
      };
    case RECIPE_REQUEST_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case RECIPE_REQUEST_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case RECIPE_REQUEST_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
