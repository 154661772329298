import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { Icon } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SM_ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import { InlineCode } from "../../../../../styles/SharedStyles";
import { useAllOrganisations } from "../../shared/graphql/hooks/useAllOrganisations";
import { Organisation } from "../../shared/graphql/types";

const OrganisationIdCell = ({ row }: CellProps<Organisation>) => (
  <InlineCode>{row.original.id}</InlineCode>
);

const ViewAndUpdateCell = ({ row }: CellProps<Organisation>) => (
  <Link to={`/admin/organisations/${row.original.id}`}>
    <Icon name={"arrow-direction-right"} />
  </Link>
);

const ViewStockManagementOrganisations = () => {
  const { organisations, organisationsError, organisationsLoading } = useAllOrganisations();
  const history = useHistory();
  const { roles } = useAuthorisationContext();

  const columns = useMemo(
    () => [
      {
        Header: "Organisation ID",
        widthPercent: 1,
        accessor: (organisation: Organisation) => organisation.id,
        Cell: OrganisationIdCell,
        filter: "multiRow",
      },
      {
        Header: "Name",
        widthPercent: 1,
        accessor: (organisation: Organisation) => organisation.name,
        filter: "multiRow",
      },
      {
        Header: "Locale",
        widthPercent: 1,
        accessor: (organisation: Organisation) => organisation.locale,
        filter: "multiRow",
      },
      {
        Header: "Currency",
        widthPercent: 1,
        accessor: (organisation: Organisation) => organisation.defaultCurrency,
        filter: "multiRow",
      },
      {
        Header: "External Organisation ID",
        widthPercent: 1,
        accessor: (organisation: Organisation) => organisation.externalOrganisationId,
        filter: "multiRow",
      },
      {
        Header: "View & Update",
        widthPercent: 1,
        Cell: ViewAndUpdateCell,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [organisations]
  );

  const items = SM_ADMIN_ROLES.some(role => roles.includes(role))
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("organisations/create-organisation");
          },
          type: "button",
          title: "Create Organisation",
          iconName: "alert-add-outline",
        },
      ]
    : [];
  const rightItems: PanelProps["rightItems"] = items as PanelProps["rightItems"];
  return (
    <Page
      title={"Stock Management Organisations"}
      isLoading={organisationsLoading}
      error={organisationsError}
    >
      <Panel
        withWrapper
        title={"Organisation List"}
        text={
          "Via this table you can view all organisations that have been onboarded to the Stock Management System"
        }
        allignTitle="left"
        rightItems={rightItems}
        iconName={"toggle-star-fill"}
      >
        {
          // prettier-ignore
          // @ts-ignore
          <Table data={organisations ?? []}
            columns={columns}
            renderFilters={(_: FilterSetter<any>) => <></>}
          />
        }
      </Panel>
    </Page>
  );
};

export default ViewStockManagementOrganisations;
