import { Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  StyledForm,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import {
  CaseChangeBody,
  CaseChangeInputLineInsertBody,
  CaseChangeOutputLineInsertBody,
} from "../api/types";
import CaseChangeSummary from "../components/CaseChangeSummary";
import CaseQuantitySelection from "../components/CaseQuantitySelection";
import CaseSelection from "../components/CaseSelection";
import ProductStockSelection from "../components/ProductStockSelection";
import {
  CaseChangeForm,
  fieldNames,
  initialCaseChangeFormData,
  initialStageInForm,
} from "../formValues";
import { useAggregatedProductStockByParams } from "../graphql/hooks/useCaseChangeAggregatedProductStockByParams";
import { useFromCaseBaseProdQuantityById } from "../graphql/hooks/useCaseChangeFromCaseBaseProdQuantityById";
import { useFromCaseProductByParams } from "../graphql/hooks/useCaseChangeFromCaseProductByParams";
import { useLocationByWarehouseId } from "../graphql/hooks/useCaseChangeLocationByWarehouseId";
import { useProductStockByParams } from "../graphql/hooks/useCaseChangeProductStockByParams";
import { useToCaseProductByBaseProdId } from "../graphql/hooks/useCaseChangeToCaseProductByBaseProdId";
import { useTopDropdownsData } from "../graphql/hooks/useCaseChangeTopDropdownsData";
import { caseChangeAction, caseChangeReset } from "../redux/actions/caseChangeAction";
import { CaseChangeReducer } from "../redux/reducers/caseChangeReducer";
import { StageInForm } from "../types";
import { validationSchema } from "../validation";

interface Props {
  caseChangeState: CaseChangeReducer;
  caseChange: (body: CaseChangeBody) => void;
  resetCaseChange: () => void;
}

function CreateCaseChange(props: Props) {
  const [initialValues, setInitialValues] = useState<CaseChangeForm>(initialCaseChangeFormData);
  const [stageInForm, setStageInForm] = useState<StageInForm>(initialStageInForm);

  const { caseChangeState } = props;
  const date = new Date();

  const { productStock, productStockLoading, productStockError, getProductStock } =
    useProductStockByParams();

  const { locationNames, locationNamesError, getLocationNames } = useLocationByWarehouseId();

  const {
    fromCaseBaseProductQuantity,
    fromCaseBaseProductQuantityLoading,
    fromCaseBaseProductQuantityError,
    getFromCaseBaseProductQuantity,
  } = useFromCaseBaseProdQuantityById();

  const {
    aggregatedProductStock,
    aggregatedProductStockLoading,
    aggregatedProductStockError,
    getAggregatedProductStock,
  } = useAggregatedProductStockByParams();

  const {
    organisationNames,
    baseProductNames,
    warehouseNames,
    conditionNames,
    topDropdownsDataLoading,
    topDropdownsDataError,
  } = useTopDropdownsData();

  const {
    fromCaseProductNames,
    fromCaseProductNamesLoading,
    fromCaseProductNamesError,
    getFromCaseProductNames,
  } = useFromCaseProductByParams();

  const { toCaseProductNames, toCaseProductNamesError, getToCaseProductNames } =
    useToCaseProductByBaseProdId();

  const ResetStates = () => {
    setInitialValues(initialCaseChangeFormData);

    setStageInForm(initialStageInForm);
  };

  const onSubmit = (values: CaseChangeForm) => {
    const caseChangeInputs = values.selected_product_stock_lines.map(line => {
      const inputLineBody: CaseChangeInputLineInsertBody = {
        source_product_stock_id: parseInt(line.id),
        from_quantity: parseInt(line.selected_quantity),
      };
      return inputLineBody;
    });

    const caseChangeOutput: CaseChangeOutputLineInsertBody = {
      location_id: values.new_location?.value!,
      to_quantity: parseInt(values.to_quantity),
    };

    const caseChangeBody: CaseChangeBody = {
      case_change_input_lines: [...caseChangeInputs],
      case_change_output_line: caseChangeOutput,
      warehouse_id: values.warehouse?.value!,
      organisation_id: values.organisation?.value!,
      due_date: date.toISOString(),
      from_product_id: values.from_case?.value!,
      from_quantity: parseInt(values.from_quantity),
      to_product_id: values.to_case?.value!,
      to_quantity: parseInt(values.to_quantity),
    };

    props.caseChange(caseChangeBody);

    ResetStates();
  };

  useEffect(() => {
    if (caseChangeState.success) {
      props.resetCaseChange();
      setInitialValues({ ...initialCaseChangeFormData });
      ResetStates();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [caseChangeState.success, caseChangeState.error]);

  const formikRef = useRef<any>(null);

  const isLoading = topDropdownsDataLoading;

  const error =
    productStockError ||
    fromCaseProductNamesError ||
    toCaseProductNamesError ||
    locationNamesError ||
    topDropdownsDataError ||
    fromCaseBaseProductQuantityError ||
    aggregatedProductStockError;

  return (
    <Page error={error} isLoading={isLoading} title={"Case Change"}>
      <Panel
        withWrapper
        title={"New Case Change"}
        subtitle={"Use the form below to join and split cases"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
            innerRef={formikRef}
          >
            {({
              values,
              setFieldValue,
              handleSubmit,
              errors,
              touched,
              handleReset,
              setTouched,
              validateForm,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                {!stageInForm.caseSelectionConfirmed && (
                  <>
                    {stageInForm.filteringComplete ? (
                      <TopPanelInputsWrapper>
                        <DisplayBox
                          isGray
                          label="Organisation"
                          value={values.organisation!.label}
                        />
                        <DisplayBox
                          isGray
                          label="Base Product"
                          value={values.base_product!.label}
                        />
                        <DisplayBox isGray label="Warehouse" value={values.warehouse!.label} />
                        <DisplayBox isGray label="Condition" value={values.condition!.label} />
                      </TopPanelInputsWrapper>
                    ) : (
                      <>
                        <TopPanelInputsWrapper>
                          <DataPoint>
                            <SimpleSelect
                              isRequired
                              htmlFor={fieldNames.organisation}
                              name={fieldNames.organisation}
                              placeholder="Select Organisation"
                              options={organisationNames}
                              value={values.organisation}
                              error={errors.organisation}
                              touched={touched.organisation}
                              label="Organisation"
                              changeHandler={e => {
                                setFieldValue(fieldNames.organisation, e);
                                setInitialValues({
                                  ...values,
                                  organisation: { value: e?.value, label: e?.label },
                                });
                              }}
                            />
                          </DataPoint>
                          <DataPoint>
                            <SimpleSelect
                              isRequired
                              htmlFor={fieldNames.base_product}
                              name={fieldNames.base_product}
                              placeholder="Select Base Product"
                              options={baseProductNames}
                              value={values.base_product}
                              error={errors.base_product}
                              touched={touched.base_product}
                              label="Base Product"
                              changeHandler={e => {
                                setFieldValue(fieldNames.base_product, e);
                                setInitialValues({
                                  ...values,
                                  base_product: { value: e?.value, label: e?.label },
                                });
                              }}
                            />
                          </DataPoint>
                          <DataPoint>
                            <SimpleSelect
                              isRequired
                              htmlFor={fieldNames.warehouse}
                              name={fieldNames.warehouse}
                              placeholder="Select Warehouse"
                              options={warehouseNames}
                              value={values.warehouse}
                              error={errors.warehouse}
                              touched={touched.warehouse}
                              label="Warehouse"
                              changeHandler={e => {
                                setFieldValue(fieldNames.warehouse, e);
                                setInitialValues({
                                  ...values,
                                  warehouse: { value: e?.value, label: e?.label },
                                });
                              }}
                            />
                          </DataPoint>
                          <DataPoint>
                            <SimpleSelect
                              isRequired
                              htmlFor={fieldNames.condition}
                              name={fieldNames.condition}
                              placeholder="Select Condiiton"
                              options={conditionNames}
                              value={values.condition}
                              error={errors.condition}
                              touched={touched.condition}
                              label="Condition"
                              changeHandler={e => {
                                setFieldValue(fieldNames.condition, e);
                                setInitialValues({
                                  ...values,
                                  condition: { value: e?.value, label: e?.label },
                                });
                              }}
                            />
                          </DataPoint>
                        </TopPanelInputsWrapper>

                        <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                          <SecondaryButton
                            type="button"
                            appearance="whiteButtonBlueText"
                            onClick={async () => {
                              setTouched({
                                base_product: true,
                                warehouse: true,
                                organisation: true,
                                condition: true,
                              });
                              const formErrors = await validateForm();
                              if (Object.keys(formErrors).length === 0) {
                                setInitialValues({
                                  ...values,
                                });
                                setStageInForm({ ...stageInForm, filteringComplete: true });
                                getFromCaseProductNames({
                                  baseProductId: values.base_product?.value!,
                                  warehouseId: values.warehouse?.value!,
                                  conditionId: values.condition?.value!,
                                });
                                getLocationNames({
                                  warehouseId: values.warehouse?.value!,
                                });
                                getToCaseProductNames({
                                  baseProductId: values.base_product?.value!,
                                });
                                getAggregatedProductStock({
                                  warehouseId: values.warehouse?.value!,
                                  baseProductId: values.base_product?.value!,
                                  stockConditionId: values.condition?.value!,
                                  organisationId: values.organisation?.value!,
                                });
                              }
                            }}
                          >
                            <IconText text={"View Cases"} primaryIcon={"alert-add-outline"} />
                          </SecondaryButton>
                        </ButtonWrapper>
                      </>
                    )}
                  </>
                )}

                <CaseSelection
                  aggregatedProductStock={aggregatedProductStock}
                  aggregatedProductStockLoading={aggregatedProductStockLoading}
                  fromCaseBaseProductQuantity={fromCaseBaseProductQuantity}
                  fromCaseBaseProductQuantityLoading={fromCaseBaseProductQuantityLoading}
                  locationNames={locationNames}
                  fromCaseProductNames={fromCaseProductNames}
                  toCaseProductNames={toCaseProductNames}
                  getFromCaseBaseProductQuantity={getFromCaseBaseProductQuantity}
                  fromCaseProductNamesLoading={fromCaseProductNamesLoading}
                  setFieldValue={setFieldValue}
                  stageInForm={stageInForm}
                  setStageInForm={setStageInForm}
                  values={values}
                  setInitialValues={setInitialValues}
                  getProductStock={getProductStock}
                />
                <ProductStockSelection
                  fromCaseBaseProductQuantity={fromCaseBaseProductQuantity}
                  queryLoading={productStockLoading}
                  initialValues={initialValues}
                  setInitialValues={setInitialValues}
                  values={values}
                  stageInForm={stageInForm}
                  productStock={productStock}
                  productStockLoading={productStockLoading}
                />
                <CaseQuantitySelection
                  setFieldValue={setFieldValue}
                  productStock={productStock}
                  stageInForm={stageInForm}
                  setStageInForm={setStageInForm}
                  values={values}
                  touched={touched}
                  errors={errors}
                  setInitialValues={setInitialValues}
                />
                <CaseChangeSummary stageInForm={stageInForm} values={values} />

                {stageInForm.productStockSelectionConfirmed && (
                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      type="reset"
                      appearance="whiteButton"
                      onClick={() => {
                        handleReset();
                        ResetStates();
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <SecondaryButton appearance="blueButton" type="submit">
                      Confirm
                    </SecondaryButton>
                  </ButtonGroup>
                )}
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    caseChangeState: state.caseChangeReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    caseChange: (body: CaseChangeBody) => dispatch(caseChangeAction(body)),
    resetCaseChange: () => dispatch(caseChangeReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateCaseChange);
