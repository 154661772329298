import { FormikErrors, FormikTouched } from "formik";
import { useHistory } from "react-router";
import Select, { InputActionMeta, OptionTypeBase } from "react-select";

import { Label, LinkLabel, reactSelectStyling } from "../../../../styles/SharedStyles";
import ErrorMessage from "../../atoms/labels/ErrorMessage";
import { ToolTip } from "../../atoms/labels/ToolTip/ToolTip";

export interface DropdownItem {
  label: string;
  value: number;
}

export interface StringDropdownItem {
  label: string;
  value: string;
}

export interface StringListDropdownItem {
  label: string;
  value: string[];
}

export interface Props {
  name: string;
  placeholder: string;
  options: DropdownItem[];
  value: DropdownItem | undefined | null | DropdownItem[];
  error: string | null | undefined | FormikErrors<DropdownItem>[] | string[];
  touched: boolean | undefined | FormikTouched<DropdownItem>[];
  label: string;
  changeHandler: (event: OptionTypeBase | null) => void;
  linkLabelURL?: string;
  linkLabelText?: string;
  htmlFor?: string;
  isGray?: boolean;
  isRequired?: boolean;
  isSearchable?: boolean;
  tooltipMessage?: string;
  maxMenuHeight?: number;
  isDisabled?: boolean;
  isMulti?: boolean;
  menuPlacement?: "top" | "bottom" | "auto";
  onInputChange?: (newValue: string, actionMeta: InputActionMeta) => void;
  menuIsOpen?: boolean;
}

export const SimpleSelect = ({
  options,
  htmlFor,
  isRequired,
  value,
  name,
  placeholder,
  changeHandler,
  error,
  touched,
  isGray,
  label,
  tooltipMessage,
  isDisabled,
  linkLabelURL,
  linkLabelText,
  isMulti = false,
  maxMenuHeight = 220,
  isSearchable = true,
  menuPlacement = "auto",
  onInputChange,
  menuIsOpen,
}: Props) => {
  let history = useHistory();
  const isRequiredAttribute = linkLabelURL ? false : isRequired;

  return (
    <>
      {tooltipMessage ? (
        <ToolTip
          isGray={isGray}
          isRequired={isRequired}
          message={tooltipMessage}
          title={label}
          htmlFor={htmlFor}
        />
      ) : (
        <Label isGray={isGray} isRequired={isRequiredAttribute} htmlFor={htmlFor}>
          {label}
          {linkLabelURL && isRequired && <>*</>}
          {linkLabelURL && linkLabelText && (
            <LinkLabel onClick={() => history.push(linkLabelURL)}>{linkLabelText}</LinkLabel>
          )}
        </Label>
      )}
      <Select
        isMulti={isMulti}
        menuPlacement={menuPlacement}
        isDisabled={isDisabled}
        id={htmlFor}
        name={name}
        options={options}
        value={value}
        onChange={event => {
          changeHandler(event);
        }}
        maxMenuHeight={maxMenuHeight}
        isSearchable={isSearchable}
        placeholder={placeholder}
        styles={reactSelectStyling}
        onInputChange={onInputChange}
        menuIsOpen={menuIsOpen}
      />
      {error && touched ? <ErrorMessage>{error}</ErrorMessage> : null}
    </>
  );
};

export default SimpleSelect;
