import React from "react";

import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import type { Props as SimpleInputProps } from "../../../../shared/components/forms/SimpleInput";

interface Props extends SimpleInputProps {
  currency: string;
}

const CurrencySimpleInput: React.FC<Props> = ({ currency, value, changeHandler, ...props }) => {
  const formatValue = (value: string | number | undefined | null) => {
    if (typeof value === "number") {
      return new Intl.NumberFormat("en-UK", {
        style: "currency",
        currency: currency,
      }).format(value);
    }
    return value;
  };

  const parseValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value.replace(/[^0-9.]/g, "");
    changeHandler({
      ...event,
      target: {
        ...event.target,
        value,
      },
    });
  };
  return <SimpleInput {...props} value={formatValue(value)} changeHandler={parseValue} />;
};

export default CurrencySimpleInput;
