import { Formik, FormikState } from "formik";
import { useState } from "react";
import { useHistory } from "react-router-dom";
import Select from "react-select";
import { toast } from "react-toastify";

import { InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  LinkLabel,
  StyledForm,
  TopPanelInputsWrapper,
  reactSelectStyling,
} from "../../../../../styles/SharedStyles";
import { handleApolloError } from "../../../../../util/handleApolloError";
import { CourierServiceForm, fieldNames, initialFieldValues } from "../formValues";
import { useAddCourierService } from "../graphql/hooks/useAddCourierService";
import { useAllCourierNames } from "../graphql/hooks/useAllCourierNames";
import { useAllCourierServiceNames } from "../graphql/hooks/useAllCourierServiceNames";
import { validationSchema } from "../validation";

const AddCourierService = () => {
  let history = useHistory();

  const [initialValues] = useState<CourierServiceForm>(initialFieldValues);
  const [mutationLoading, setMutationLoading] = useState<boolean>(false);

  const { addCourierService } = useAddCourierService({
    onError: error => {
      handleApolloError(error);
      setMutationLoading(false);
    },
    onCompleted: () => {
      toast.success("Courier service created successfully");
      setMutationLoading(false);
    },
  });

  const onSubmit = async (
    values: CourierServiceForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<CourierServiceForm>>) => void }
  ) => {
    if (courierServiceNames?.indexOf(values.courier_service_name) !== -1) {
      toast.error("A courier service with this name already exists.");
      return;
    }
    setMutationLoading(true);

    await addCourierService({
      courierId: values.courier_id?.value!,
      courierServiceName: values.courier_service_name,
    });

    refetchCourierServiceNames();
    resetForm();
  };

  const { courierNames, courierNamesLoading, courierNamesError } = useAllCourierNames();
  const {
    courierServiceNames,
    courierServiceLoading,
    courierServiceError,
    refetchCourierServiceNames,
  } = useAllCourierServiceNames();

  const error = courierNamesError || courierServiceError;
  const isLoading = courierNamesLoading || courierServiceLoading || mutationLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Couriers"}>
      <Panel
        withWrapper
        title={"Add Courier Service"}
        subtitle={"Add a courier service using the inputs below"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleReset,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label htmlFor={fieldNames.courier_id}>
                        Couier*
                        <LinkLabel onClick={() => history.push("/courier-management/add-courier")}>
                          + Add Courier
                        </LinkLabel>
                      </Label>
                      <Select
                        styles={reactSelectStyling}
                        value={values.courier_id}
                        options={courierNames}
                        onChange={e => setFieldValue(fieldNames.courier_id, e)}
                        isSearchable={true}
                        maxMenuHeight={220}
                        placeholder={"Select Courier Here"}
                        id={fieldNames.courier_id}
                      />
                      {errors.courier_id && touched.courier_id ? (
                        <ErrorMessage>{errors.courier_id}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                    <DataPoint>
                      <Label isRequired htmlFor={fieldNames.courier_service_name}>
                        Courier Service Name
                      </Label>
                      <InputField
                        id={fieldNames.courier_service_name}
                        size={"medium"}
                        type={"text"}
                        placeholder={"Enter Courier Service Name Here"}
                        value={values.courier_service_name}
                        handleChange={handleChange}
                      />
                      {errors.courier_service_name && touched.courier_service_name ? (
                        <ErrorMessage>{errors.courier_service_name}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </TopPanelInputsWrapper>

                  <FormButtonPair handleReset={handleReset} />
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default AddCourierService;
