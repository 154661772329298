import Icon from "../../../../../../../components/icons/Icon";
import ErrorMessage from "../../../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleInput from "../../../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../../../../shared/components/forms/SimpleTextArea";
import { IndexPill } from "../../../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../../../../styles/SharedStyles";
import { TranslationLineProps } from "../../types";

export const QuotationCostTypeTranslationLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveTranslationLine,
  errors,
  touched,
  locales,
}: TranslationLineProps) => (
  <SecondaryLineWrapper type={"withIndex"}>
    <IndexPill index={index + 1} />
    <SecondaryLineDataWrapper>
      <DataPoint halfWidth>
        <SimpleSelect
          isRequired
          name="Locales"
          placeholder="Select translation locale"
          options={locales}
          value={values.locale}
          error={errors && errors[index]?.locale}
          touched={touched && touched[index]?.locale}
          label="Locale"
          changeHandler={e => {
            setFieldValue(`quotation_cost_translations[${index}].locale`, e);
          }}
        />
      </DataPoint>
      <DataPoint halfWidth>
        <SimpleInput
          isRequired
          name="Cost Name Translation"
          type={"text"}
          placeholder="Enter translation for cost name"
          value={values.quotation_cost_name}
          error={errors && errors[index]?.quotation_cost_name}
          touched={touched && touched[index]?.quotation_cost_name}
          label="Cost Name Translation"
          changeHandler={e => {
            setFieldValue(
              `quotation_cost_translations[${index}].quotation_cost_name`,
              e.target.value
            );
          }}
        />
      </DataPoint>
      <DataPoint fullWidth css={{ marginTop: "20px" }}>
        <SimpleTextArea
          label="Cost Description Translation"
          type={"text"}
          value={values.quotation_cost_description}
          placeholder="Please provide a description of what the cost includes/represents, which will be meaningful for both suppliers and internal teams."
          handleChange={e => {
            setFieldValue(
              `quotation_cost_translations[${index}].quotation_cost_description`,
              e.target.value
            );
          }}
        />
        {errors &&
        touched &&
        errors[index]?.quotation_cost_description &&
        touched[index]?.quotation_cost_description ? (
          <ErrorMessage>{errors[index]?.quotation_cost_description}</ErrorMessage>
        ) : null}
      </DataPoint>
    </SecondaryLineDataWrapper>

    <DataPoint fullWidth centeredText>
      <IconWrapper type="button" onClick={() => handleRemoveTranslationLine(index)}>
        <Icon width={30} height={30} name="navigation-trash" />
      </IconWrapper>
    </DataPoint>
  </SecondaryLineWrapper>
);
