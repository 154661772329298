import { Document, Page as PDFPAge, Text, View } from "@react-pdf/renderer";
import moment from "moment";

import { PDFStyleSheet } from "../../../../../styles/PdfStyles";
import { ManifestItem } from "../graphql/types";

// TODO: This page requires proper styling
export const PDF = ({ manifestData }: { manifestData: ManifestItem | undefined }) => (
  <Document>
    <PDFPAge size={"A4"} style={PDFStyleSheet.body} wrap={true}>
      <Text style={PDFStyleSheet.header} fixed>
        Inbound Manifest ID: {manifestData?.id}
      </Text>
      <Text style={PDFStyleSheet.header} fixed>
        Date: {moment(new Date()).format("DD-MM-YYYY")}
      </Text>

      <View style={PDFStyleSheet.manifestDetailsWrapper}>
        <View style={PDFStyleSheet.InputRow}>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Manifest ID</Text>
            <Text style={PDFStyleSheet.box}>#{manifestData?.id}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Organisation</Text>
            <Text style={PDFStyleSheet.box}>{manifestData?.organisation.organisation_name}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Warehouse</Text>
            <Text style={PDFStyleSheet.box}>{manifestData?.warehouse.warehouse_name}</Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Status </Text>
            <Text style={PDFStyleSheet.box}>
              {manifestData?.inbound_manifest_status.inbound_manifest_status_name}
            </Text>
          </View>
        </View>
        <View style={PDFStyleSheet.InputRow}>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Logistics Type</Text>
            <Text style={PDFStyleSheet.box}>
              {manifestData?.logistics_type.logistics_type_name}
            </Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}>Expected Arrival Date</Text>
            <Text style={PDFStyleSheet.box}>
              {moment(manifestData?.expected_arrival).format("DD-MM-YYYY")}
            </Text>
          </View>
          <View style={PDFStyleSheet.dataPoint}>
            <Text style={PDFStyleSheet.label}> Number of Manifest Lines </Text>
            <Text style={PDFStyleSheet.box}>{manifestData?.inbound_manifest_lines.length}</Text>
          </View>
        </View>
      </View>
      <View>
        {manifestData?.inbound_manifest_lines.map(ibmLine => {
          return (
            <View style={PDFStyleSheet.ManifestLineWrapper} key={ibmLine.id}>
              <Text style={PDFStyleSheet.ManifestLineTitle}> Manifest line ID: {ibmLine.id} </Text>
              <View style={PDFStyleSheet.ManifestDataPointWrapper}>
                <View style={PDFStyleSheet.InputRow}>
                  <View style={PDFStyleSheet.manifestLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Product Name </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>
                      {ibmLine.product.product_name}
                    </Text>
                  </View>
                  <View style={PDFStyleSheet.manifestLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Quantity </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>{ibmLine.quantity}</Text>
                  </View>
                  <View style={PDFStyleSheet.manifestLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Received Quantity </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>{ibmLine.received_quantity}</Text>
                  </View>
                  <View style={PDFStyleSheet.manifestLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Line Status </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>
                      {ibmLine.inbound_manifest_line_status.inbound_manifest_line_status_name}
                    </Text>
                  </View>
                  <View style={PDFStyleSheet.manifestLineDataPoint}>
                    <Text style={PDFStyleSheet.label}> Number of Fulfilment Lines </Text>
                    <Text style={PDFStyleSheet.manifestLineBox}>
                      {ibmLine.inbound_manifest_fulfilment_lines.length}
                    </Text>
                  </View>
                </View>
              </View>
              {ibmLine.inbound_manifest_fulfilment_lines.map((ffline, index) => {
                return (
                  <View style={PDFStyleSheet.FulfillmentLineWrapper}>
                    <View style={PDFStyleSheet.indexHolder}>
                      <Text style={PDFStyleSheet.index}>{index + 1}</Text>
                    </View>
                    <Text style={PDFStyleSheet.ManifestLineTitle}>
                      {" "}
                      Fulfillment Line ID: {ffline.id}{" "}
                    </Text>
                    <View style={PDFStyleSheet.ManifestDataPointWrapper}>
                      <View style={PDFStyleSheet.fulfilmentLineDataPoint}>
                        <Text style={PDFStyleSheet.label}> Fulfillment Line Product name </Text>
                        <Text style={PDFStyleSheet.manifestLineBox}>
                          {ffline.product.product_name}
                        </Text>
                      </View>
                      <View style={PDFStyleSheet.fulfilmentLineDataPoint}>
                        <Text style={PDFStyleSheet.label}> Fulfilment Line Quantity </Text>
                        <Text style={PDFStyleSheet.manifestLineBox}>{ffline.quantity}</Text>
                      </View>
                    </View>
                  </View>
                );
              })}
            </View>
          );
        })}
        <Text
          style={PDFStyleSheet.pageNumber}
          render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
          fixed={true}
        />
      </View>
    </PDFPAge>
  </Document>
);
