import { FormikErrors, FormikTouched } from "formik";
import { useEffect, useState } from "react";

import { Icon } from "@sourceful/shared-components";

import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import { IndexPill } from "../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  IconWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
} from "../../../../styles/SharedStyles";
import { NO_OPTION_INDEX, YES_OPTION_INDEX, boolOptions, discountOptions } from "../formValues";
import { fieldObjects } from "../formValues";
import { CreateOrderForm, DiscountCost } from "../types";
import CurrencySimpleInput from "./InputWithCurrency";

export interface DiscountCostLineProps {
  values: DiscountCost;
  index: number;
  errors: FormikErrors<DiscountCost[]> | undefined;
  touched: FormikTouched<DiscountCost>[] | undefined;
  handleRemoveQuoteLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  currency: string;
  formValues: CreateOrderForm;
}

const UpdateDiscountCostLine = ({
  values,
  setFieldValue,
  index,
  handleRemoveQuoteLine,
  errors,
  touched,
  currency,
  formValues,
}: DiscountCostLineProps) => {
  const [vat, setVat] = useState(boolOptions[NO_OPTION_INDEX]);

  useEffect(() => {
    // TODO: FIX THIS STATE MANAGEMENT ON sourceful_entity_eu
    // if (formValues.sourceful_entity_eu.tax_type.label === "NLEU") {
    console.log(formValues.billing_address.country_name.label);
    console.log(currency);
    if (formValues.billing_address.country_name.label !== "NL" && currency === "EUR") {
      console.log(formValues.billing_address.country_name.label);
      console.log(currency);
      setVat(boolOptions[NO_OPTION_INDEX]);
      setFieldValue(fieldObjects.discount_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);
      return;
    }

    if (currency === "USD") {
      setVat(boolOptions[NO_OPTION_INDEX]);
      setFieldValue(fieldObjects.discount_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);
      return;
    }
    if (formValues.sourceful_entity_uk.vat_exempt.label === "YES") {
      setVat(boolOptions[NO_OPTION_INDEX]);
      setFieldValue(fieldObjects.discount_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);
      return;
    }
    if (formValues.sourceful_entity_uk.outside_uk.label === "NO") {
      setVat(boolOptions[YES_OPTION_INDEX]);
      setFieldValue(
        fieldObjects.discount_costs(index).vat.fieldName,
        boolOptions[YES_OPTION_INDEX]
      );
      return;
    }
    setVat(boolOptions[NO_OPTION_INDEX]);
    setFieldValue(fieldObjects.discount_costs(index).vat.fieldName, boolOptions[NO_OPTION_INDEX]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    currency,
    formValues.sourceful_entity_uk.vat_exempt,
    formValues.sourceful_entity_uk.outside_uk,
  ]);

  useEffect(() => {
    if (vat.label === "YES") {
      const total = values.amount * -1.0;
      const total_with_vat = values.amount * -1.2;
      setFieldValue(fieldObjects.discount_costs(index).total.fieldName, total);
      setFieldValue(fieldObjects.discount_costs(index).total_with_vat.fieldName, total_with_vat);
      return;
    }
    const total = values.amount * -1.0;
    const total_with_vat = values.amount * -1.0;
    setFieldValue(fieldObjects.discount_costs(index).total.fieldName, total);
    setFieldValue(fieldObjects.discount_costs(index).total_with_vat.fieldName, total_with_vat);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.amount, values.vat]);

  return (
    <SecondaryLineWrapper type={"withIndex"}>
      <IndexPill index={index + 1} />
      <SecondaryLineDataWrapper>
        <DataPoint halfWidth>
          <SimpleInput
            htmlFor={fieldObjects.discount_costs(index).cost_description.fieldName}
            name={fieldObjects.discount_costs(index).cost_description.fieldName}
            type={"text"}
            placeholder={fieldObjects.discount_costs(index).cost_description.fieldPlaceholder}
            value={values.cost_description}
            error={
              Array.isArray(errors) && errors[index]?.cost_description
                ? errors[index]!.cost_description
                : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.cost_description}
            label={fieldObjects.discount_costs(index).cost_description.fieldLabel}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.discount_costs(index).cost_description.fieldName,
                e.target.value
              );
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleInput
            htmlFor={fieldObjects.discount_costs(index).amount.fieldName}
            name={fieldObjects.discount_costs(index).amount.fieldName}
            type={"text"}
            placeholder={fieldObjects.discount_costs(index).amount.fieldPlaceholder}
            value={values.amount}
            error={
              Array.isArray(errors) && errors[index]?.amount ? errors[index]!.amount : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.amount}
            label={fieldObjects.discount_costs(index).amount.fieldLabel}
            changeHandler={e => {
              setFieldValue(fieldObjects.discount_costs(index).amount.fieldName, e.target.value);
            }}
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            htmlFor={fieldObjects.discount_costs(index).discount_option.fieldName}
            name={fieldObjects.discount_costs(index).discount_option.fieldName}
            placeholder="Select Discount Option Here"
            options={discountOptions}
            value={values.discount_option}
            error={
              Array.isArray(errors) && errors[index]?.discount_option?.label
                ? errors[index]!.discount_option?.label
                : undefined
            }
            touched={
              Array.isArray(touched) && touched[index]?.discount_option?.label
                ? touched[index]!.discount_option?.label
                : undefined
            }
            label="Discount Option"
            changeHandler={e =>
              setFieldValue(fieldObjects.discount_costs(index).discount_option.fieldName, e)
            }
          />
        </DataPoint>
        <DataPoint>
          <SimpleSelect
            isRequired
            isDisabled
            htmlFor={fieldObjects.discount_costs(index).vat.fieldName}
            name={fieldObjects.discount_costs(index).vat.fieldName}
            placeholder="VAT"
            options={boolOptions}
            value={values.vat}
            error={
              Array.isArray(errors) && errors[index]?.vat?.label
                ? errors[index]?.vat?.label
                : undefined
            }
            touched={
              Array.isArray(touched) && touched[index]?.vat?.label
                ? touched[index]?.vat?.label
                : undefined
            }
            label="VAT"
            changeHandler={e => setFieldValue(fieldObjects.discount_costs(index).vat.fieldName, e)}
          />
        </DataPoint>
        <DataPoint>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.discount_costs(index).total.fieldName}
            name={fieldObjects.discount_costs(index).total.fieldName}
            type={"text"}
            placeholder={fieldObjects.discount_costs(index).total.fieldPlaceholder}
            value={values.total}
            error={Array.isArray(errors) && errors[index]?.total ? errors[index]!.total : undefined}
            touched={Array.isArray(touched) && touched[index]?.total}
            label={`${fieldObjects.discount_costs(index).total.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(fieldObjects.discount_costs(index).total.fieldName, e.target.value);
            }}
            currency={currency}
          />
        </DataPoint>
        <DataPoint>
          <CurrencySimpleInput
            isDisabled
            htmlFor={fieldObjects.discount_costs(index).total_with_vat.fieldName}
            name={fieldObjects.discount_costs(index).total_with_vat.fieldName}
            type={"text"}
            placeholder={fieldObjects.discount_costs(index).total_with_vat.fieldPlaceholder}
            value={values.total_with_vat}
            error={
              Array.isArray(errors) && errors[index]?.total_with_vat
                ? errors[index]!.total_with_vat
                : undefined
            }
            touched={Array.isArray(touched) && touched[index]?.total_with_vat}
            label={`${fieldObjects.discount_costs(index).total_with_vat.fieldLabel}  (${currency})`}
            changeHandler={e => {
              setFieldValue(
                fieldObjects.discount_costs(index).total_with_vat.fieldName,
                e.target.value
              );
            }}
            currency={currency}
          />
        </DataPoint>
      </SecondaryLineDataWrapper>
      <DataPoint halfWidth containsButton>
        <IconWrapper
          type="button"
          onClick={() => {
            handleRemoveQuoteLine(index);
          }}
        >
          <Icon width={30} height={30} name="alert-cross-fill" />
        </IconWrapper>
      </DataPoint>
    </SecondaryLineWrapper>
  );
};

export default UpdateDiscountCostLine;
