import { ApolloError } from "@apollo/client";

import { useGetAllAddressNamesQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapAddressNamesToDrodownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  addressNames: DropdownItem[];
  addressNamesError?: ApolloError;
  addressNamesLoading: boolean;
}

export const useAllAddressNames = (): HookResult => {
  const { data, error, loading } = useGetAllAddressNamesQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    addressNames:
      data && Array.isArray(data.address) ? mapAddressNamesToDrodownObject(data.address) : [],
    addressNamesError: error,
    addressNamesLoading: loading,
  };
};
