import { ApolloError } from "@apollo/client";

import { useGetLocalesWithSuppliersQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { mapLocalesToLocaleItem } from "../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  locales: DropdownItem[];
  localesLoading: boolean;
  localesError?: ApolloError;
}

export const useLocalesWithSuppliers = (): HookResult => {
  const { data, loading, error } = useGetLocalesWithSuppliersQuery({
    context: { clientName: GRAPHQL_SOURCING },
  });

  return {
    locales: data
      ? mapLocalesToLocaleItem(data.supplier_details.map(supplier => supplier.locale))
      : [],
    localesLoading: loading,
    localesError: error,
  };
};
