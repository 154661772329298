import { ApolloError } from "@apollo/client";

import { useGetProductsForQuotesQuery } from "../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../shared/constants";
import { mapProductNamesToDropdownObject, mapProductSKUsToDropdownObject } from "../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  productNames: DropdownItem[];
  productSKUs: DropdownItem[];
  productsError?: ApolloError;
  productsLoading: boolean;
}

export const useProducts = (): HookResult => {
  const { data, error, loading } = useGetProductsForQuotesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    productNames:
      data && Array.isArray(data.product) ? mapProductNamesToDropdownObject(data.product) : [],
    productSKUs:
      data && Array.isArray(data.product) ? mapProductSKUsToDropdownObject(data.product) : [],
    productsError: error,
    productsLoading: loading,
  };
};
