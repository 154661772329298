import { useAuth0 } from "@auth0/auth0-react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { IconButton, SourcefulWordmark, styled } from "@sourceful/shared-components";

import Icon from "../../../../components/icons/Icon";
import {
  AdminIcon,
  LogisticsIcon,
  OrderIcon,
  PCatIcon,
  SourcingIcon,
  WMSIcon,
} from "../../../../components/icons/ServiceIcons";
import { ROLE, useAuthorisationContext } from "../../../../providers/AuthorisationProvider";
import { activeServiceAction } from "../../../../redux/actions/activeServiceAction";
import { ActiveServiceReducer } from "../../../../redux/reducers/activeServiceReducer";
import { StoreTypes } from "../../../../redux/store/storeTypes";
import { PublicSourcefulLogoSpacer, RowSpacer } from "../../../../styles/SourcingStyles";
import { getFeatureFlagPcatEnabled } from "../../../../util/featureFlags";

const NavLabel = styled("div", {
  marginLeft: "6px",
});

const StyledNav = styled("nav", {
  width: "100%",
  display: "flex",
  height: "50px",
  alignItems: "center",
  background: "#FFFFFF",
  justifyContent: "space-between",
  borderBottom: "1px solid #C4C4C4",
  padding: "0px 10px",
});

const UnorderedList = styled("ul", {
  display: "flex",
  alignItems: "center",
});

const ListItem = styled("li", {
  padding: "0px 10px",
  cursor: "pointer",
  color: "#005AE1",
  display: "flex",
  flexDirection: "row",
  fontFamily: "Space Grotesk",
  fontSize: "18px",
  lineHeight: "35px",

  variants: {
    isText: { true: { cursor: "default" } },
    isGray: { true: { color: "#8D8D8D" } },
    header: {
      true: {
        marginRight: "10px",
        "&:hover": {
          textDecoration: "underline",
        },
      },
    },
    isActive: { true: { fontWeight: "bold" } },
  },
});

interface NavProps {
  activeServiceState: ActiveServiceReducer;
  changeActiveService: (body: ActiveServiceReducer) => void;
  handleToggleSidebar?: () => void;
}

const Nav = (props: NavProps) => {
  let history = useHistory();
  const { user, logout } = useAuth0();
  const { changeActiveService, activeServiceState, handleToggleSidebar } = props;
  const { roles } = useAuthorisationContext();

  const auth0LogoutRedirectLink = process.env.REACT_APP_AUTH0_LOGOUT_BASE_URL
    ? process.env.REACT_APP_AUTH0_LOGOUT_BASE_URL
    : window.REACT_APP_AUTH0_LOGOUT_BASE_URL;

  if (roles.includes(ROLE.PUBLIC)) {
    return (
      <StyledNav>
        <UnorderedList>
          <ListItem>
            <RowSpacer>
              <PublicSourcefulLogoSpacer>
                <Icon name={"sourceful-logo"} width={35} height={35} />
              </PublicSourcefulLogoSpacer>
              <SourcefulWordmark />
            </RowSpacer>
          </ListItem>
        </UnorderedList>
      </StyledNav>
    );
  } else {
    return (
      <StyledNav>
        <UnorderedList>
          {!!handleToggleSidebar && activeServiceState.activeService !== "DEFAULT" && (
            <IconButton
              handleClick={handleToggleSidebar}
              iconName="navigation-menu"
              buttonDescription="navigation button"
              size="small"
            />
          )}
          <ListItem onClick={() => history.push("/dashboard")}>
            <SourcefulWordmark />
          </ListItem>

          <ListItem
            onClick={() => {
              changeActiveService({ activeService: "Sourcing" });
              history.push("/sourcing");
            }}
            header
            isActive={activeServiceState.activeService === "Sourcing"}
          >
            <SourcingIcon />
            Sourcing
          </ListItem>

          <ListItem
            onClick={() => {
              changeActiveService({ activeService: "WMS" });
              history.push("/dashboard");
            }}
            isActive={activeServiceState.activeService === "WMS"}
            header
          >
            <WMSIcon />
            <NavLabel>WMS</NavLabel>
          </ListItem>

          <ListItem
            onClick={_e => {
              changeActiveService({ activeService: "Logistics" });
              history.push("/logistics/dashboard");
            }}
            isActive={activeServiceState.activeService === "Logistics"}
            header
          >
            <LogisticsIcon />
            <NavLabel>Logistics</NavLabel>
          </ListItem>

          {getFeatureFlagPcatEnabled() && (
            <ListItem
              onClick={_e => {
                changeActiveService({ activeService: "PCAT" });
                history.push("/product-catalogue");
              }}
              isActive={activeServiceState.activeService === "PCAT"}
              header
            >
              <PCatIcon />
              <NavLabel>PCat</NavLabel>
            </ListItem>
          )}
          <ListItem
            onClick={_e => {
              changeActiveService({ activeService: "Orders" });
              history.push("/orders/dashboard");
            }}
            isActive={activeServiceState.activeService === "Orders"}
            header
          >
            <OrderIcon />
            <NavLabel>Order Builder</NavLabel>
          </ListItem>

          <ListItem
            onClick={_e => {
              changeActiveService({ activeService: "Admin" });
              history.push("/admin");
            }}
            isActive={activeServiceState.activeService === "Admin"}
            header
          >
            <AdminIcon />
            <NavLabel>Admin</NavLabel>
          </ListItem>
        </UnorderedList>

        <UnorderedList>
          <ListItem isGray isText>
            {user?.name ?? "John Doe"}
          </ListItem>
          <ListItem
            isGray
            onClick={() => logout({ returnTo: auth0LogoutRedirectLink, federated: true })}
            css={{ alignItems: "center" }}
          >
            <Icon name="navigation-exit" width={24} height={24} />
          </ListItem>
        </UnorderedList>
      </StyledNav>
    );
  }
};

function mapStateToProps(state: StoreTypes) {
  return {
    activeServiceState: state.activeServiceReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    changeActiveService: (body: ActiveServiceReducer) => dispatch(activeServiceAction(body)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Nav);
