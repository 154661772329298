import moment from "moment";
import { useMemo } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import { FilterSetter } from "../../../../../components/Table/TableUtilityBar";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  WMS_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import StatusBadge from "../../../../../shared/components/molecules/StatusBadge";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { InboundManifestStatuses } from "../../../../../util/interfaces";
import { useInboundManifestsAsc } from "../graphql/hooks/useInboundManifestsAsc";
import { ManifestItem } from "../graphql/types";

export default function ViewInboundManifests() {
  let history = useHistory();
  const { roles } = useAuthorisationContext();

  const isAuthorised = WMS_EDIT_ROLES.some(role => roles.includes(role));

  const rightItems: PanelProps["rightItems"] = isAuthorised
    ? [
        {
          id: 1,
          handleClick: () => {
            history.push("/manifests/inbound/create-inbound-manifest");
          },
          type: "button",
          title: "Add Manifest",
          iconName: "alert-add-outline",
        },
        {
          id: 2,
          handleClick: () => {
            history.push("/inventory/products/add-product");
          },
          type: "button",
          title: "Create Product",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const { manifests, manifestsLoading, manifestsError } = useInboundManifestsAsc();

  const columns = useMemo(
    () => [
      {
        Header: "ID",
        widthPercent: 0.2,
        filter: "arraySome",
        accessor: (manifest: ManifestItem) => manifest.id,
      },
      {
        Header: "Reference",
        widthPercent: 0.5,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => manifest.reference,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/manifests/inbound/fulfil-inbound-manifest/${row.original.id}`}>
            {row.original.reference}
          </Link>
        ),
      },
      {
        Header: "Organisation",
        widthPercent: 0.3,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => manifest.organisation.organisation_name,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/inventory/organisations/view-organisation/${row.original.organisation.id}`}>
            <LinkText>{row.original.organisation?.organisation_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Status",
        widthPercent: 0.05,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) =>
          manifest.inbound_manifest_status.inbound_manifest_status_name,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <StatusBadge
            type="wms"
            statusName={row.original.inbound_manifest_status.inbound_manifest_status_name}
          />
        ),
      },
      {
        Header: "Expected Date",
        widthPercent: 0.2,
        filter: "multiRow",
        accessor: (manifest: ManifestItem) => moment(manifest.expected_arrival).format("x"),
        Cell: ({ _, row }: CellProps<ManifestItem>) =>
          moment(row.original.expected_arrival).format("DD-MM-YYYY"),
      },
      {
        Header: "Warehouse",
        widthPercent: 0.2,
        filter: "multiRow",
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/warehouse-management/view-warehouse/${row.original.warehouse.id}`}>
            <LinkText>{row.original.warehouse.warehouse_name}</LinkText>
          </Link>
        ),
        accessor: (manifest: ManifestItem) => manifest.warehouse.warehouse_name,
      },
      {
        Header: "Lines",
        widthPercent: 0.1,
        filter: "arraySome",
        accessor: (manifest: ManifestItem) =>
          manifest.inbound_manifest_lines_aggregate.aggregate?.count,
      },
      {
        Header: "Fulfil",
        widthPercent: 0.15,
        Cell: ({ _, row }: CellProps<ManifestItem>) =>
          isAuthorised &&
          (row.original.inbound_manifest_status.id === InboundManifestStatuses.NEW ||
            row.original.inbound_manifest_status.id === InboundManifestStatuses.IN_PROGRESS) && (
            <Link to={`/manifests/inbound/fulfil-inbound-manifest/${row.original.id}`}>
              <SecondaryButton isWithinTable appearance={"blueButton"}>
                Fulfil
              </SecondaryButton>
            </Link>
          ),
      },
      {
        Header: "View",
        widthPercent: 0.15,
        Cell: ({ _, row }: CellProps<ManifestItem>) => (
          <Link to={`/manifests/inbound/review-inbound-manifest/${row.original.id}`}>
            <SecondaryButton isWithinTable appearance={"blueButton"}>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    [isAuthorised]
  );

  return (
    <Page error={manifestsError} isLoading={manifestsLoading} title={"Manifests - Inbound"}>
      <Panel
        withWrapper
        title={"Inbound Manifests List"}
        text={"Use the table below to view Sourceful's Inbound Manifests"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        toolTipMessage={"List of items that a warehouse <br/> should be expecting to recieve"}
      >
        <Table data={manifests} columns={columns} renderFilters={(_: FilterSetter<any>) => <></>} />
      </Panel>
    </Page>
  );
}
