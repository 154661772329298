import { uniqBy } from "lodash";

import {
  AggProductStockItem,
  AggregatedProductStockItem,
  BaseProductNameItem,
  ConditionNameItem,
  LocationNameItem,
  OrganisationNameItem,
  ProductNameItem,
  StatusNameItem,
  WarehouseNameItem,
} from "../graphql/types";

export const mapLocationNamesToDropdownObject = (locationNames: LocationNameItem[]) => {
  return locationNames.map(locationName => {
    return {
      label: locationName.location_name,
      value: locationName.id,
    };
  });
};

export const mapConditionNamesToDropdownObject = (conditionNames: ConditionNameItem[]) => {
  return conditionNames.map(conditionName => {
    return {
      label: conditionName.stock_condition_name,
      value: conditionName.id,
    };
  });
};

export const mapStatusNamesToDropdownObject = (statusNames: StatusNameItem[]) => {
  return statusNames.map(statusName => {
    return {
      label: statusName.stock_status_name,
      value: statusName.id,
    };
  });
};

export const mapOrganisationNamesToDropdownObject = (organisationNames: OrganisationNameItem[]) => {
  return organisationNames.map(organisationName => {
    return {
      label: organisationName.organisation_name,
      value: organisationName.id,
    };
  });
};

export const mapBaseProductNamesToDropdownObject = (baseProductNames: BaseProductNameItem[]) => {
  return baseProductNames
    .filter(baseProductName => baseProductName.id && baseProductName.product_name)
    .map(baseProductName => {
      return {
        label: baseProductName.product_name!,
        value: baseProductName.id!,
      };
    });
};

export const mapWarehouseNamesToDropdownObject = (warehouseNames: WarehouseNameItem[]) => {
  return warehouseNames.map(warehouseName => {
    return {
      label: warehouseName.warehouse_name,
      value: warehouseName.id,
    };
  });
};

export const mapProductNamesToDropdownObject = (productNames: ProductNameItem[]) => {
  return productNames.map(productName => {
    return {
      label: productName.product_name,
      value: productName.id,
    };
  });
};

export const mapAggregatedProductStock = (productStocks: AggregatedProductStockItem[]) => {
  const groupedQty = productStocks.reduce(
    (agg: any, line) => ({
      ...agg,
      [line.product.product_name]: {
        ...agg[line.product.product_name],
        [line.location.location_name]:
          agg[line.product.product_name]?.[line.location.location_name] + line.quantity ||
          0 + line.quantity,
      },
    }),
    {}
  );

  const uniquePairs = uniqBy(productStocks, id =>
    [id.product.product_name, id.location.location_name].join("-")
  );

  const aggregatedProductStock: AggProductStockItem[] = uniquePairs.map(pair => {
    return {
      productName: pair.product.product_name,
      locationName: pair.location.location_name,
      quantity: groupedQty[pair.product.product_name][pair.location.location_name],
      organisationName: pair.organisation?.organisation_name,
      conditionName: pair.stock_condition.stock_condition_name,
      warehouseName: pair.location.warehouse.warehouse_name,
    };
  });

  return aggregatedProductStock;
};
