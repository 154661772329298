import { UNAUTHENTICATED_SOURCING_API } from "../../../../../api/baseConfig";
import { JWTPurchaseOrderBody } from "./types";

export const JWTPurchaseOrder = async ({ purchaseOrderId, token }: JWTPurchaseOrderBody) => {
  try {
    return await UNAUTHENTICATED_SOURCING_API.post(`/purchase-orders/${purchaseOrderId}/access`, {
      access_token: token,
    });
  } catch (err) {
    console.log("Error in Purchase Order JWT call", err);
    throw err;
  }
};
