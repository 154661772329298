import { WMS_API } from "../../../../../api/baseConfig";
import { ServiceAccounts } from "../../../../../util/interfaces";
import { CreateOrganisationBody } from "./types";

export const createOrganisationAPI = async (body: CreateOrganisationBody) => {
  try {
    return await WMS_API.post("/organisations", {
      ...body,
      service_account_id: ServiceAccounts.WMS,
    });
  } catch (err) {
    console.log("Error in case change API call", err);
    throw err;
  }
};
