import moment from "moment";
import { useHistory } from "react-router-dom";

import { VerticalCardCategories } from "../../../../../components/card/VerticalCardCategories";
import { Page } from "../../../../../shared/components/templates/Page";
import { CardsWrapper } from "../../../../../styles/AdminPage";
import InventoryTableByLocationType from "../../shared/inventoryTableByLocationType/page/InventoryTableByLocationType";
import { useDashboardProductsReport } from "../graphql/hooks/useDashboardProductsReport";
import { useProductInformation } from "../graphql/hooks/useProductInformation";
import { useStockInformation } from "../graphql/hooks/useStockInformation";

export default function ViewInventoryDashboard() {
  let history = useHistory();

  const { productInformation, productInformationLoading, productInformationError } =
    useProductInformation();
  const { stockInformation, stockInformationLoading, stockInformationError } =
    useStockInformation();

  const { productsReport, productsReportLoading, productsReportError } =
    useDashboardProductsReport();

  const isLoading = productInformationLoading || stockInformationLoading || productsReportLoading;

  const error = productInformationError || stockInformationError || productsReportError;

  return (
    <Page isLoading={isLoading} error={error} title={"Inventory"}>
      <>
        <CardsWrapper>
          <VerticalCardCategories
            cardTitle={"Product Information"}
            linkText="View products"
            link="/manifests/inbound/fulfil-inbound-manifest/"
            scrollBarContent={[
              {
                title: "Number of products",
                iconName: "environment-climate",
                value: productInformation?.productCount,
              },
              {
                title: "Most stocked cases",
                iconName: "environment-climate",
                value: productInformation?.mostStockedProductName,
              },
            ]}
            button={{
              title: "See all products",
              handleClick: () => {
                history.push("/inventory/products");
              },
            }}
          />
          <VerticalCardCategories
            cardTitle={"Stock information"}
            linkText="View outbound manifest"
            scrollBarContent={[
              {
                title: "Quantity of stocks",
                iconName: "environment-climate",
                value: stockInformation?.stockQuantity,
              },
              {
                title: "Average age of stock",
                iconName: "environment-climate",
                value: stockInformation?.stockAvgAge,
              },
              {
                title: "Average stock condition",
                iconName: "environment-climate",
                value: stockInformation?.stockAvgCondition,
              },
            ]}
            button={{
              title: "See all stock",
              handleClick: () => {
                history.push("/inventory/stock-levels");
              },
            }}
          />
          <VerticalCardCategories
            cardTitle={"Quick reports"}
            linkText="View outbound manifest"
            scrollBarContent={[
              {
                title: "All current stock records report",
                iconName: "tools-align-vertical-bottom",
                linkProps: {
                  data: productsReport?.data ?? [],
                  displayTitle: "Download report",
                  filename:
                    productsReport?.fileTitle
                      .concat(moment(new Date()).format("DD_MM_YYYY_HH_mm_ss"))
                      .concat(".csv") || "Report Not Available",
                },
              },
              {
                title: "Create custom reports",
                iconName: "environment-climate",
                isTitleClickable: true,
                linkPath: "/inventory/create-reports",
              },
            ]}
            button={{
              title: "See all reports",
              handleClick: () => {
                history.push("/inventory/reports");
              },
            }}
          />
        </CardsWrapper>

        <InventoryTableByLocationType />
      </>
    </Page>
  );
}
