import { DropdownItem } from "../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { getLastShipmentUpdate } from "../../../shared/helpers/getLastShipmentUpdate";
import { SHIPMENT_UPDATE_TYPE } from "../../../shared/models";
import { ShipmentItem } from "../graphql/types";

export const filterByPurchaseOrderNumber = (values: DropdownItem[]) => (shipment: ShipmentItem) => {
  if (values.length === 0) return true;
  return values.some(purchaseOrderNumber =>
    shipment.purchase_order ? purchaseOrderNumber.value === shipment.purchase_order.id : true
  );
};

export const filterByClientOrderNumber = (values: DropdownItem[]) => (shipment: ShipmentItem) => {
  if (values.length === 0) return true;
  return values.some(clientOrderNumber => clientOrderNumber.value === shipment.client_order.id);
};

export const filterByClientOrganisation = (values: DropdownItem[]) => (shipment: ShipmentItem) => {
  if (values.length === 0) return true;
  return values.some(clientOrg => clientOrg.value === shipment.client_order.organisation.id);
};

export const filterByFromAddress = (address: DropdownItem | null) => (shipment: ShipmentItem) => {
  if (!address) return true;
  const last = getLastShipmentUpdate(shipment.shipment_updates, SHIPMENT_UPDATE_TYPE.IN_TRANSIT);
  return address.value === last?.addressByFromDestinationId?.id;
};

export const filterByToAddress = (address: DropdownItem | null) => (shipment: ShipmentItem) => {
  if (!address) return true;
  const last = getLastShipmentUpdate(shipment.shipment_updates, SHIPMENT_UPDATE_TYPE.IN_TRANSIT);
  return address.value === last?.addressByToDestinationId?.id;
};

export const filterByCurrentAddress =
  (address: DropdownItem | null) => (shipment: ShipmentItem) => {
    if (!address) return true;
    const last = getLastShipmentUpdate(
      shipment.shipment_updates,
      SHIPMENT_UPDATE_TYPE.DELIVERED_INTERMEDIATE
    );
    return address.value === last?.address?.id;
  };
