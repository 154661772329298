import { ApolloError } from "@apollo/client";

import { useGetCaseChangeLocationsByWarehouseIdLazyQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { mapLocationNamesToDropdownObject } from "../../mappers";
import { DropdownItem } from "../types";

export interface HookResult {
  locationNames: DropdownItem[];
  locationNamesError?: ApolloError;
  locationNamesLoading: boolean;
  getLocationNames: (options: Options) => Promise<void>;
}

interface Options {
  warehouseId: number | null;
}

export const useLocationByWarehouseId = (): HookResult => {
  const [getLocationNames, { data, error, loading }] =
    useGetCaseChangeLocationsByWarehouseIdLazyQuery({
      context: {
        clientName: GRAPHQL_WMS,
      },
    });

  return {
    locationNames:
      data && Array.isArray(data.location) ? mapLocationNamesToDropdownObject(data.location) : [],
    locationNamesLoading: loading,
    locationNamesError: error,
    getLocationNames: async ({ warehouseId }: Options) => {
      getLocationNames({
        variables: { warehouseId },
      });
    },
  };
};
