import { Formik, FormikErrors, FormikState } from "formik";
import moment from "moment";
import * as qs from "query-string";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import AttachmentDownloader from "../../../../../../components/AttachmentDownloader/AttachmentDownloader";
import Panel from "../../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../../shared/components/templates/Page";
import {
  Box,
  ButtonWrapper,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../../styles/SharedStyles";
import { prettifyProductName } from "../../../../shared/mappers";
import { CreateQuoteLinesBody, CreateQuotesBody } from "../../api/types";
import SingleQuoteLine from "../../components/QuoteLine";
import { useSupplierQuoteRequest } from "../../graphql/hooks/useSupplierQuoteRequest";
import {
  createSupplierQuotesAction,
  createSupplierQuotesReset,
} from "../../redux/actions/createSupplierQuotesAction";
import { CreateSupplierQuotesReducer } from "../../redux/reducers/createSupplierQuotesReducer";
import { QuoteLine, emptyQuoteLine } from "../../types";
import { QuoteForm, fieldNames, initialFieldValues } from "../formValues";
import { validationSchema } from "../validation";

interface Props {
  createSupplierQuotesState: CreateSupplierQuotesReducer;
  createSupplierQuotes: (body: CreateQuotesBody, access_token: string) => void;
  resetSupplierQuotes: () => void;
}

const CreateInternalResponse = (props: Props) => {
  const params = qs.parse(useLocation().search);
  const [initialValues, setInitialValues] = useState<QuoteForm>(initialFieldValues);

  useEffect(() => {
    if (props.createSupplierQuotesState.success) {
      setInitialValues({ ...initialFieldValues });
      props.resetSupplierQuotes();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.createSupplierQuotesState.success]);

  const { supplierQuoteRequest, supplierQuoteRequestLoading, supplierQuoteRequestError } =
    useSupplierQuoteRequest(
      params.supplierQuoteRequestId ? Number(params.supplierQuoteRequestId) : 0
    );

  const onSubmit = (
    values: QuoteForm,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<QuoteForm>>) => void }
  ) => {
    const momentValidFrom = moment(values.quote_valid_from);
    const momentValidTo = moment(values.quote_valid_to);

    const validToIsLarger = momentValidTo > momentValidFrom;
    if (!validToIsLarger) {
      toast.error("Please ensure your valid to date is later than your valid from date");
      return;
    }

    const quoteLines: CreateQuoteLinesBody[] = values.quote_lines.map(line => {
      const rfqLine = Number(line.rfq_line_id!);
      const supplierQuoteLine: CreateQuoteLinesBody = {
        minimumOrderQuantity: Number(line.minimum_order_quantity),
        supplierQuoteRequestLineId: rfqLine,
        productId: supplierQuoteRequest?.supplier_quote_request_lines!.find(quoteRequestLine => {
          return quoteRequestLine.id === rfqLine;
        })!.product.id!,
      };
      if (line.lead_time_max_days) {
        supplierQuoteLine.leadTimeMaxDays = Number(line.lead_time_max_days);
      }
      return supplierQuoteLine;
    });

    const body: CreateQuotesBody = {
      validFrom: values.quote_valid_from,
      validTo: values.quote_valid_to,
      currencyCode: supplierQuoteRequest?.currency_code!,
      supplierOrganisationId: supplierQuoteRequest?.organisation.id!,
      supplierQuoteRequestId: supplierQuoteRequest?.id!,
      supplierQuoteLines: quoteLines,
    };

    props.createSupplierQuotes(body, params.token! as string);
  };

  const isLoading = supplierQuoteRequestLoading || props.createSupplierQuotesState.loading;

  return (
    <Page error={supplierQuoteRequestError} isLoading={isLoading} title={"Request For Quotation"}>
      <Panel
        withWrapper={true}
        title={"Create a Quotation"}
        subtitle={
          "Use this page to create a quotation internally, either on behalf of a supplier or as part of day-to-day activities when additional data becomes available."
        }
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched, handleReset }) => {
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label isGray>Manufacturer</Label>
                      <Box>{supplierQuoteRequest?.organisation.organisation_name}</Box>
                    </DataPoint>
                    <DataPoint>
                      <Label isGray>Requested Currency Code</Label>
                      <Box>{supplierQuoteRequest?.currency_code}</Box>
                    </DataPoint>
                  </TopPanelInputsWrapper>
                  <PrimaryLineWrapper>
                    <Title>Quote Validity</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin>
                        <Label htmlFor={fieldNames.quote_valid_from} isRequired>
                          Quote Valid From
                        </Label>
                        <DateInput
                          id={fieldNames.quote_valid_from}
                          type="date"
                          value={values.quote_valid_from}
                          onChange={e => {
                            setFieldValue(fieldNames.quote_valid_from, e.target.value);
                          }}
                        />
                        {errors.quote_valid_from && touched.quote_valid_from ? (
                          <ErrorMessage>{errors.quote_valid_from}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                      <DataPoint withUniqueMargin>
                        <Label htmlFor={fieldNames.quote_valid_to} isRequired>
                          Quote Valid To
                        </Label>
                        <DateInput
                          id={fieldNames.quote_valid_to}
                          type="date"
                          value={values.quote_valid_to}
                          onChange={e => {
                            setFieldValue(fieldNames.quote_valid_to, e.target.value);
                          }}
                        />
                        {errors.quote_valid_to && touched.quote_valid_to ? (
                          <ErrorMessage>{errors.quote_valid_to}</ErrorMessage>
                        ) : null}
                      </DataPoint>
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  {supplierQuoteRequest?.supplier_quote_request_lines.map(
                    (quote_request_line, index) => {
                      return (
                        <>
                          <PrimaryLineWrapper key={quote_request_line.id}>
                            <Title>RFQ Line #{index + 1}</Title>
                            <PrimaryLineDataWrapper>
                              <DataPoint withUniqueMargin>
                                <Label isGray> Product</Label>
                                <Box>
                                  {prettifyProductName(quote_request_line.product.product_name)}
                                </Box>
                              </DataPoint>
                              <DataPoint withUniqueMargin={true}>
                                <Label isGray> Quantity </Label>
                                <Box> {quote_request_line.minimum_order_quantity} </Box>
                              </DataPoint>

                              {quote_request_line.product.fsc_certification && (
                                <DataPoint withUniqueMargin={true}>
                                  <Label isGray> FSC Certification</Label>
                                  <Box>
                                    {
                                      quote_request_line.product.fsc_certification
                                        .fsc_certification_name
                                    }
                                  </Box>
                                </DataPoint>
                              )}
                              <SecondaryLineWrapper>
                                <Title>Attachments</Title>
                                {!quote_request_line.folder ||
                                quote_request_line.folder.attachments.length === 0 ? (
                                  <SecondaryLineDataWrapper>
                                    No attachments have been added to this product line.
                                  </SecondaryLineDataWrapper>
                                ) : (
                                  <>
                                    {quote_request_line.folder.attachments.map(
                                      (attachment, index) => {
                                        return (
                                          <SecondaryLineDataWrapper key={attachment.id}>
                                            <AttachmentDownloader
                                              key={index}
                                              originalFilename={attachment.original_filename}
                                              attachmentUUID={attachment.attachment_uuid}
                                              metadata={attachment.metadata}
                                            />
                                          </SecondaryLineDataWrapper>
                                        );
                                      }
                                    )}
                                  </>
                                )}
                              </SecondaryLineWrapper>
                            </PrimaryLineDataWrapper>

                            {values.quote_lines &&
                              values.quote_lines
                                .filter(
                                  quote_line => quote_line.rfq_line_id === quote_request_line.id
                                )
                                .map((quote_line, QuoteLineIndex) => {
                                  return (
                                    <SingleQuoteLine
                                      values={quote_line}
                                      allQuoteLines={values.quote_lines}
                                      errors={errors.quote_lines as FormikErrors<QuoteLine[]>}
                                      touched={touched.quote_lines}
                                      index={QuoteLineIndex}
                                      setFieldValue={setFieldValue}
                                      handleRemoveQuoteLine={(id: number) => {
                                        const filteredQuoteLines = values.quote_lines.filter(
                                          (item, i) => item.id !== id
                                        );
                                        const newValues = { ...values };
                                        newValues.quote_lines = filteredQuoteLines;
                                        if (filteredQuoteLines.length === 0) {
                                          setInitialValues(newValues);
                                        }
                                        setInitialValues(newValues);
                                      }}
                                    />
                                  );
                                })}
                            <ButtonWrapper className={WMSButtonGroup({ type: "largeMargin" })}>
                              <SecondaryButton
                                type="button"
                                appearance="whiteButtonBlueText"
                                onClick={() => {
                                  setInitialValues({
                                    ...values,
                                    quote_lines: [
                                      ...values.quote_lines,
                                      {
                                        ...emptyQuoteLine,
                                        rfq_line_id: quote_request_line.id,
                                        id: values.quote_lines.length + 1,
                                      },
                                    ],
                                  });
                                }}
                              >
                                <IconText text={"Add Quote"} primaryIcon={"alert-add-outline"} />
                              </SecondaryButton>
                            </ButtonWrapper>
                          </PrimaryLineWrapper>
                        </>
                      );
                    }
                  )}

                  {typeof errors.quote_lines === "string" &&
                    errors.quote_lines &&
                    touched.quote_lines && <ErrorMessage>{errors.quote_lines}</ErrorMessage>}

                  <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                    <SecondaryButton
                      type="button"
                      appearance="whiteButton"
                      onClick={() => {
                        handleReset();
                        setInitialValues({ ...initialFieldValues });
                      }}
                    >
                      Cancel
                    </SecondaryButton>
                    <SecondaryButton
                      type="button"
                      appearance="blueButton"
                      onClick={() => {
                        handleSubmit();
                      }}
                    >
                      Create Quote
                    </SecondaryButton>
                  </ButtonGroup>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

function mapStateToProps(state: StoreTypes) {
  return {
    createSupplierQuotesState: state.createSupplierQuotesReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createSupplierQuotes: (body: CreateQuotesBody) => dispatch(createSupplierQuotesAction(body)),
    resetSupplierQuotes: () => dispatch(createSupplierQuotesReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateInternalResponse);
