import { FormikErrors, FormikTouched } from "formik";

import { QAInspectionItem } from "../graphql/types";
import {
  InspectionLineInterface,
  InspectionTypeData,
  QualityAssuranceInspectionInterface,
} from "../types";

export interface ProductLineProps {
  values: InspectionLineInterface;
  inspectionTypeData: InspectionTypeData[];
  index: number;
  errors: FormikErrors<InspectionLineInterface[]> | undefined;
  touched: FormikTouched<InspectionLineInterface>[] | undefined;
  handleRemoveInspectionLine: (index: number) => void;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
}

export const emptyInspectionLine: InspectionLineInterface = {
  qa_inspection_type: null,
  qa_inspection_line_status: "",
  qa_inspection_line_notes: "",
  qa_inspection_line_state: null,
};

export const fieldNames = {
  qa_inspection_notes: "qa_inspection_notes",
  product_attachments: "product_attachments",
  inspection_lines: "inspection_lines",
  attachments: "attachments",
};

export const initialFieldValues: QualityAssuranceInspectionInterface = {
  qa_inspection_id: null,
  purchase_order_line_id: 0,
  qa_inspection_notes: "",
  attachments: [],
  inspection_lines: [],
};

export const getInitialValues = (purchaseOrderLineQAInspection: QAInspectionItem) => {
  return {
    ...initialFieldValues,
    purchase_order_line_id: purchaseOrderLineQAInspection.id,
    qa_inspection_notes:
      purchaseOrderLineQAInspection.quality_assurance_inspections.length === 0
        ? ""
        : purchaseOrderLineQAInspection.quality_assurance_inspections[0].notes || "",
    attachments: [],
    qa_inspection_id:
      purchaseOrderLineQAInspection.quality_assurance_inspections.length === 0
        ? null
        : purchaseOrderLineQAInspection.quality_assurance_inspections[0].id,

    inspection_lines:
      purchaseOrderLineQAInspection.quality_assurance_inspections.length === 0
        ? []
        : purchaseOrderLineQAInspection.quality_assurance_inspections[0].quality_assurance_inspection_lines.map(
            line => {
              return {
                qa_inspection_type: {
                  label:
                    line.quality_assurance_inspection_type.quality_assurance_inspection_type_name,
                  value: line.quality_assurance_inspection_type.id,
                },
                qa_inspection_line_status:
                  line.quality_assurance_status.quality_assurance_status_name.toLowerCase(),
                qa_inspection_line_notes: line.notes!,
                qa_inspection_line_state: "existing",
              } as InspectionLineInterface;
            }
          ) || [],
  };
};
