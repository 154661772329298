import { ApolloError } from "@apollo/client";

import { useGetContactsByOrganisationNameLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";
import { ContactItem } from "../types";

export interface HookResult {
  contacts?: ContactItem[];
  contactsLoading: boolean;
  contactsError?: ApolloError;
  getContactsByOrganisationName: (options: Options) => Promise<void>;
}

export interface Options {
  organisationName: string;
}

export const useContactsByOrganisationName = (): HookResult => {
  const [getContactsByOrganisationName, { data, loading, error }] =
    useGetContactsByOrganisationNameLazyQuery({
      context: {
        clientName: GRAPHQL_SOURCING,
      },
    });

  return {
    contacts: data?.contact,
    contactsLoading: loading,
    contactsError: error,
    getContactsByOrganisationName: async ({ organisationName }: Options) => {
      getContactsByOrganisationName({ variables: { organisationName } });
    },
  };
};
