import { FormikErrors, FormikTouched } from "formik";

import { SimpleDateInput } from "../../../../shared/components/forms/SimpleDateInput";
import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import { DropdownItem } from "../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { DataPoint, PrimaryLineDataWrapper } from "../../../../styles/SharedStyles";
import { boolOptions, fieldObjects } from "../formValues";
import {
  DEFAULT_PAYMENT_PLAN_TYPE,
  DEFAULT_PAYMENT_PLAN_TYPE_LABEL,
  PaymentTerm,
  SPLIT_PAYMENT_PLAN_TYPE,
  SPLIT_PAYMENT_PLAN_TYPE_LABEL,
} from "../types";

export interface PaymentTermProps {
  values: PaymentTerm;
  errors: FormikErrors<PaymentTerm> | undefined;
  touched: FormikTouched<PaymentTerm> | undefined;
  setFieldValue: (field: string, value: any, shouldValidate?: boolean | undefined) => void;
  currency: string;
}

const paymentPlanTypeOptions: DropdownItem[] = [
  { label: DEFAULT_PAYMENT_PLAN_TYPE_LABEL, value: DEFAULT_PAYMENT_PLAN_TYPE },
  { label: SPLIT_PAYMENT_PLAN_TYPE_LABEL, value: SPLIT_PAYMENT_PLAN_TYPE },
];

const PaymentPlan = ({ values, errors, touched, setFieldValue, currency }: PaymentTermProps) => {
  return (
    <PrimaryLineDataWrapper>
      <DataPoint>
        <SimpleSelect
          isRequired
          htmlFor={fieldObjects.payment_term.has_payment_term.fieldName}
          name={fieldObjects.payment_term.has_payment_term.fieldName}
          placeholder="Select whether or not the order has payment terms"
          options={boolOptions}
          value={values.has_payment_term}
          error={errors?.has_payment_term?.label}
          touched={touched?.has_payment_term?.label}
          label={fieldObjects.payment_term.has_payment_term.fieldLabel}
          changeHandler={e =>
            setFieldValue(fieldObjects.payment_term.has_payment_term.fieldName, e)
          }
        />
      </DataPoint>

      {values.has_payment_term.label === "YES" && (
        <>
          <DataPoint>
            <SimpleSelect
              isRequired
              htmlFor={fieldObjects.payment_term.payment_plan_type.fieldName}
              name={fieldObjects.payment_term.payment_plan_type.fieldName}
              placeholder="Select whether or not to block order processing"
              options={paymentPlanTypeOptions}
              value={values.payment_plan_type}
              error={errors?.payment_plan_type?.label}
              touched={touched?.payment_plan_type?.label}
              label={fieldObjects.payment_term.payment_plan_type.fieldLabel}
              changeHandler={e =>
                setFieldValue(fieldObjects.payment_term.payment_plan_type.fieldName, e)
              }
            />
          </DataPoint>
          <DataPoint>
            <SimpleSelect
              isRequired
              htmlFor={fieldObjects.payment_term.suppress_client_email.fieldName}
              name={fieldObjects.payment_term.suppress_client_email.fieldName}
              placeholder="Select whether or not to suppress client email"
              options={boolOptions}
              value={values.suppress_client_email}
              error={errors?.suppress_client_email?.label}
              touched={touched?.suppress_client_email?.label}
              label={fieldObjects.payment_term.suppress_client_email.fieldLabel}
              changeHandler={e =>
                setFieldValue(fieldObjects.payment_term.suppress_client_email.fieldName, e)
              }
            />
          </DataPoint>
          <DataPoint>
            <SimpleDateInput
              isRequired
              htmlFor={fieldObjects.payment_term.due_date.fieldName}
              name={fieldObjects.payment_term.due_date.fieldName}
              value={values.due_date}
              error={errors?.due_date}
              touched={touched?.due_date}
              label={fieldObjects.payment_term.due_date.fieldLabel}
              changeHandler={e => {
                setFieldValue(fieldObjects.payment_term.due_date.fieldName, e.target.value);
              }}
            />
          </DataPoint>
          <DataPoint>
            <SimpleInput
              isRequired
              htmlFor={fieldObjects.payment_term.payment_term_description.fieldName}
              name={fieldObjects.payment_term.payment_term_description.fieldName}
              type="text"
              placeholder={fieldObjects.payment_term.due_date.fieldPlaceholder}
              value={values.payment_term_description}
              error={errors?.payment_term_description}
              touched={touched?.payment_term_description}
              label={fieldObjects.payment_term.payment_term_description.fieldLabel}
              changeHandler={e => {
                setFieldValue(
                  fieldObjects.payment_term.payment_term_description.fieldName,
                  e.target.value
                );
              }}
            />
          </DataPoint>
        </>
      )}
    </PrimaryLineDataWrapper>
  );
};

export default PaymentPlan;
