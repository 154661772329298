import { useMemo } from "react";
import { Link, useParams } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText } from "../../../../../styles/SharedStyles";
import { useCaseProductsByBaseProductId } from "../graphql/hooks/useCaseProductsByBaseProductId";
import { CaseProductItem } from "../graphql/types";

const ViewCaseSizesByBaseProduct = () => {
  const { base_product_id } = useParams<{ base_product_id: string }>();

  const { caseProducts, caseProductsLoading, caseProductsError } = useCaseProductsByBaseProductId(
    Number(base_product_id)
  );

  const baseProductName = caseProducts.find(
    caseProduct => caseProduct.id === caseProduct.base_product_id
  )?.product_name;

  const columns = useMemo(
    () => [
      {
        Header: "Name",
        widthPercent: 0.4,
        accessor: (listItem: CaseProductItem) => listItem.product_name,
        Cell: ({ _, row }: CellProps<CaseProductItem>) => (
          <Link
            to={`/inventory/products/view-product/${row.original.id}/${row.original.base_product_id}`}
          >
            <LinkText>{row.original.product_name}</LinkText>
          </Link>
        ),
      },
      {
        Header: "Case Quantity",
        widthPercent: 0.4,
        accessor: (listItem: CaseProductItem) => listItem.base_product_quantity,
      },

      {
        Header: "View",
        widthPercent: 0.2,
        Cell: ({ _, row }: CellProps<CaseProductItem>) => (
          <Link
            to={`/inventory/products/view-product/${row.original.id}/${row.original.base_product_id}`}
          >
            <SecondaryButton appearance="blueButton" isWithinTable>
              View
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page
      error={caseProductsError}
      isLoading={caseProductsLoading}
      title={"Base Products - Case Sizes"}
    >
      <Panel
        withWrapper
        title={`${baseProductName}`}
        subtitle={"View Case Sizes of this product below"}
      >
        <Table data={caseProducts} columns={columns} />
      </Panel>
    </Page>
  );
};
export default ViewCaseSizesByBaseProduct;
