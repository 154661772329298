import { useMemo } from "react";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { Page } from "../../../../../shared/components/templates/Page";
import { LinkText, RectangleStatusBadge } from "../../../../../styles/SharedStyles";
import { countryCodeFromLocale } from "../../../purchaseOrders/shared/mappers";
import { PURCHASE_ORDER_STATUS_ID } from "../../../purchaseOrders/updatePurchaseOrders/types";
import { useQAInspections } from "../graphql/hooks/useQAInspections";
import { mapQAInspectionToTableRow } from "../mappers";
import { QCInspectionTableRow } from "../types/index";

export default function ViewQCInspections() {
  const { qaInspections, qaInspectionsError, qaInspectionsLoading } = useQAInspections();

  const formattedQAData: QCInspectionTableRow[] = mapQAInspectionToTableRow(qaInspections);
  const tableColumns = useMemo(
    () => [
      {
        Header: "#",
        widthPercent: 0.15,
        accessor: (qa: QCInspectionTableRow) => qa.qa_id,
      },
      {
        Header: "PO #",
        widthPercent: 0.4,
        Cell: ({ row }: CellProps<QCInspectionTableRow>) => {
          return (
            <Link to={`/purchase-orders/update-purchase-order/${row.original.purchase_order_id}`}>
              <LinkText>
                {countryCodeFromLocale(row.original.locale)}-{row.original.purchase_order_uid}
              </LinkText>
            </Link>
          );
        },
        accessor: (qa: QCInspectionTableRow) => qa.purchase_order_id,
      },
      {
        Header: "Manufacturer",
        widthPercent: 0.4,
        accessor: (qa: QCInspectionTableRow) => qa.manufacturer_name,
      },
      {
        Header: "Client Name",
        widthPercent: 0.05,
        accessor: (qa: QCInspectionTableRow) => qa.client_name,
      },
      {
        Header: "QC Status",
        widthPercent: 0.05,
        accessor: (qa: QCInspectionTableRow) => qa.qa_status,
        Cell: ({ row }: CellProps<QCInspectionTableRow>) => (
          <RectangleStatusBadge status={row.original.qa_status.replace(/\s/g, "") as any}>
            {row.original.qa_status}
          </RectangleStatusBadge>
        ),
      },
      {
        Header: "View",
        widthPercent: 0.2,

        Cell: ({ row }: CellProps<QCInspectionTableRow>) => {
          if (
            row.original.purchase_order_status_id !== PURCHASE_ORDER_STATUS_ID.APPROVED &&
            row.original.purchase_order_status_id !== PURCHASE_ORDER_STATUS_ID.PENDING
          ) {
            return (
              <Link
                to={`/qc-orders/create-qc?purchaseOrderLineId=${row.original.purchase_order_line_id}`}
              >
                <SecondaryButton appearance={"blueButton"} isWithinTable>
                  Update
                </SecondaryButton>
              </Link>
            );
          } else return null;
        },
      },
    ],
    []
  );

  return (
    <Page title={"Quality Control"} error={qaInspectionsError} isLoading={qaInspectionsLoading}>
      <Panel
        title={"QC Orders"}
        text={"Use the table below to view Sourceful's QC Orders."}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
        withWrapper={true}
      >
        <Table data={formattedQAData} columns={tableColumns} />
      </Panel>
    </Page>
  );
}
