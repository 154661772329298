import {
  CREATE_SUPPLIER_RESPONSE_FAILED,
  CREATE_SUPPLIER_RESPONSE_LOADING,
  CREATE_SUPPLIER_RESPONSE_RESET,
  CREATE_SUPPLIER_RESPONSE_SUCCESS,
} from "../types";

export interface CreateSupplierResponseReducer {
  loading: boolean;
  success: boolean;
  error: boolean;
}

const initialState: CreateSupplierResponseReducer = {
  loading: false,
  success: false,
  error: false,
};

export default function createSupplierResponseReducer(
  state: CreateSupplierResponseReducer = initialState,
  action: any = {}
) {
  switch (action.type) {
    case CREATE_SUPPLIER_RESPONSE_LOADING:
      return {
        ...state,
        loading: true,
      };
    case CREATE_SUPPLIER_RESPONSE_SUCCESS:
      return {
        ...state,
        loading: false,
        success: true,
        error: false,
      };
    case CREATE_SUPPLIER_RESPONSE_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: true,
      };
    case CREATE_SUPPLIER_RESPONSE_RESET:
      return {
        ...state,
        ...initialState,
      };
    default:
      return state;
  }
}
