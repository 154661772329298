import { Formik, FormikState } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { CellProps } from "react-table";

import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import { SOURCING_EDIT_ROLES } from "../../../../../providers/AuthorisationProvider";
import SimpleDateInput from "../../../../../shared/components/forms/SimpleDateInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { createLocaleHolidayAPI } from "../api/createLocaleHolidayAPI";
import { CreateLocaleHolidayBody } from "../api/types";
import { fieldNames, initialFieldValues } from "../formValues";
import { useActiveAndFutureLocaleHolidays } from "../graphql/hooks/useActiveAndFutureLocaleHolidays";
import { useLocalesWithSuppliers } from "../graphql/hooks/useLocalesWithSuppliers";
import { LocaleHolidayItem } from "../graphql/types";
import { LocaleHolidayInterface } from "../types";
import { validationSchema } from "../validation";

const CreateLocaleHoliday = () => {
  const [initialValues] = useState<LocaleHolidayInterface>(initialFieldValues);

  const [createLocaleHolidayState, createLocaleHoliday] = useAPI(createLocaleHolidayAPI);

  const { locales, localesLoading, localesError } = useLocalesWithSuppliers();
  const { localeHolidays, localeHolidaysLoading, localeHolidaysError, refetchLocaleHolidays } =
    useActiveAndFutureLocaleHolidays();

  const columns = useMemo(
    () => [
      {
        Header: "Locale",
        widthPercent: 0.1,
        accessor: (localeHoliday: LocaleHolidayItem) => localeHoliday.locale,
      },
      {
        Header: "Start Date",
        widthPercent: 0.1,
        accessor: (localeHoliday: LocaleHolidayItem) =>
          moment(localeHoliday.start_date).format("x"),
        Cell: ({ row }: CellProps<LocaleHolidayItem>) =>
          moment(row.original.start_date).format("DD-MM-YYYY"),
      },
      {
        Header: "End Date",
        widthPercent: 0.1,
        accessor: (localeHoliday: LocaleHolidayItem) => moment(localeHoliday.end_date).format("x"),
        Cell: ({ row }: CellProps<LocaleHolidayItem>) =>
          moment(row.original.end_date).format("DD-MM-YYYY"),
      },
    ],
    []
  );

  const onSubmit = async (
    values: LocaleHolidayInterface,
    { resetForm }: { resetForm: (nextState?: Partial<FormikState<LocaleHolidayInterface>>) => void }
  ) => {
    const localeHoliday: CreateLocaleHolidayBody = {
      locale: values.locale!.label,
      start_date: values.start_date.toString(),
      end_date: values.end_date.toString(),
    };

    createLocaleHoliday(localeHoliday);
    resetForm();
  };

  useEffect(() => {
    if (createLocaleHolidayState === API_REQUEST_STATE.SUCCESS) {
      refetchLocaleHolidays();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createLocaleHolidayState]);

  const error = localesError || localeHolidaysError;
  const isLoading = localesLoading || localeHolidaysLoading;

  return (
    <Page title={"Create Locale Holiday"} hasBackButton error={error} isLoading={isLoading}>
      <Panel title={`Create Locale Holiday`} withWrapper={true}>
        <VisibleFor roles={SOURCING_EDIT_ROLES}>
          <InnerPanelWrapper>
            <Formik
              initialValues={initialValues}
              enableReinitialize
              validationSchema={validationSchema}
              onSubmit={onSubmit}
            >
              {formikProps => {
                const { values, setFieldValue, handleSubmit, handleReset, errors, touched } =
                  formikProps;
                return (
                  <StyledForm onSubmit={handleSubmit}>
                    <PrimaryLineWrapper>
                      <PrimaryLineDataWrapper>
                        <DataPoint>
                          <SimpleSelect
                            isRequired
                            name={fieldNames.locale}
                            placeholder={"Select locale"}
                            options={locales}
                            value={values.locale}
                            error={errors.locale}
                            touched={touched.locale}
                            label={"Supplier Locale"}
                            changeHandler={e => setFieldValue(fieldNames.locale, e)}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleDateInput
                            isRequired
                            htmlFor={fieldNames.start_date}
                            name={fieldNames.start_date}
                            value={values.start_date}
                            error={errors.start_date}
                            touched={touched.start_date}
                            label={"Holiday Start Date"}
                            changeHandler={e => {
                              setFieldValue(fieldNames.start_date, e.target.value);
                            }}
                          />
                        </DataPoint>
                        <DataPoint>
                          <SimpleDateInput
                            isRequired
                            htmlFor={fieldNames.end_date}
                            name={fieldNames.end_date}
                            value={values.end_date}
                            error={errors.end_date}
                            touched={touched.end_date}
                            label={"Holiday End Date"}
                            changeHandler={e => {
                              setFieldValue(fieldNames.end_date, e.target.value);
                            }}
                          />
                        </DataPoint>
                      </PrimaryLineDataWrapper>
                    </PrimaryLineWrapper>

                    <FormButtonPair handleReset={handleReset} />
                  </StyledForm>
                );
              }}
            </Formik>
          </InnerPanelWrapper>
        </VisibleFor>

        <Table data={localeHolidays} columns={columns} />
      </Panel>
    </Page>
  );
};

export default CreateLocaleHoliday;
