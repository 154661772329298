import * as Yup from "yup";

export const inTransitValidationSchema = Yup.object({
  expectedDeliveryDate: Yup.string().required("ETA is required"),
  toDestination: Yup.object()
    .shape({
      value: Yup.number().required("Destination is required"),
    })
    .typeError("Destination is required"),
});

export const deliveredValidationSchema = Yup.object({
  actualDeliveryDate: Yup.string().required("Delivery Date is required"),
});

export const deliveryDateValidationSchema = Yup.object({
  expectedDeliveryDate: Yup.string().required("ETA is required"),
});
