import { ApolloError } from "@apollo/client";

import { useInsertCourierMutation } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { AddCourierMutationOptions } from "../types";

export interface HookResult {
  addCourier: (options: AddCourierMutationOptions) => Promise<number | undefined>;
}

export interface Options {
  onError: (error: ApolloError) => void;
  onCompleted: () => void;
}

export const useAddCourier = ({ onError, onCompleted }: Options): HookResult => {
  const [AddCourier] = useInsertCourierMutation({
    context: {
      clientName: GRAPHQL_WMS,
    },
    onError: error => onError(error),
    onCompleted: () => onCompleted(),
  });

  return {
    addCourier: async ({ courierName }: AddCourierMutationOptions) => {
      const insertedWarehouse = await AddCourier({
        variables: {
          courierName,
        },
      });

      return insertedWarehouse.data?.insert_courier_one?.id;
    },
  };
};
