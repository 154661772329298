import { useEffect, useState } from "react";
import { useParams } from "react-router";

import { IconText, SecondaryButton } from "@sourceful/shared-components";

import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SM_ADMIN_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { Page } from "../../../../../shared/components/templates/Page";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor/VisibleFor";
import {
  ButtonWrapper,
  FormattedLineDataWrapper,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { useOrganisationById } from "../../shared/graphql/hooks/useOrganisationById";
import { Address, Contact } from "../../shared/graphql/types";
import { getInitialPrimaryContactValue } from "../../shared/helpers";
import {
  AddAddressToOrganisationInterface,
  AddContactToOrganisationInterface,
  UpdateAddressDataInterface,
  UpdateContactDataInterface,
  UpdateOrganisationDataInterface,
} from "../../shared/types";
import { addStockManagementAddress } from "../api/addStockManagementAddress";
import { addStockManagementContact } from "../api/addStockManagementContact";
import {
  AddStockManagementAddressBody,
  AddStockManagementContactBody,
  UpdateStockManagementAddressBody,
  UpdateStockManagementContactBody,
  UpdateStockManagementOrganisationBody,
} from "../api/types";
import { updateStockManagementAddress } from "../api/updateStockManagementAddress";
import { updateStockManagementContact } from "../api/updateStockManagementContact";
import { updateStockManagementOrganisation } from "../api/updateStockManagementOrganisation";
import AddStockManagementAddress from "../components/AddStockManagementAddress";
import AddStockManagementContact from "../components/AddStockManagementContact";
import StockManagementAddressesData from "../components/StockManagementAddressesData";
import StockManagementContactsData from "../components/StockManagementContactsData";
import StockManagementOrganisationData from "../components/StockManagementOrganisationData";
import UpdateStockManagementOrganisationData from "../components/UpdateStockManagementOrganisationData";

export type FormattedContact = Contact & { indexId: number };

const UpdateStockManagementOrganisation = () => {
  const { orgId } = useParams<{ orgId: string }>();
  const toastMessages = (isAdding: boolean, type: string) => {
    return {
      pending: `${isAdding ? "Adding" : "Updating"} ${type}`,
      success: `${type.charAt(0).toUpperCase()}${type.slice(1)} successfully ${
        isAdding ? "added" : "updated"
      }`,
      error: `Error while trying to ${isAdding ? "add" : "update"} ${type}`,
    };
  };
  const [addStockManagementAddressState, addAddress] = useAPI(
    addStockManagementAddress,
    false,
    toastMessages(true, "address")
  );
  const [updateStockManagementAddressState, updateAddress] = useAPI(
    updateStockManagementAddress,
    false,
    toastMessages(false, "address")
  );
  const [addStockManagementContactState, addContact] = useAPI(
    addStockManagementContact,
    false,
    toastMessages(true, "contact")
  );
  const [updateStockManagementContactState, updateContact] = useAPI(
    updateStockManagementContact,
    false,
    toastMessages(false, "contact")
  );
  const [updateOrganisationState, updateOrganisation] = useAPI(
    updateStockManagementOrganisation,
    false,
    toastMessages(false, "organisation")
  );
  const { organisation, organisationError, organisationLoading, refetchOrganisation } =
    useOrganisationById(orgId);
  const organisationAddresses = organisation?.addresses ?? [];
  const organisationContacts = organisation?.contacts ?? [];
  const [showAddAddressForm, setShowAddAddressForm] = useState(false);
  const [showAddContactForm, setShowAddContactForm] = useState(false);
  const [showUpdateOrgDataForm, setShowUpdateOrgDataForm] = useState(false);
  const { roles } = useAuthorisationContext();

  useEffect(() => {
    if (addStockManagementAddressState === API_REQUEST_STATE.SUCCESS) {
      refetchOrganisation();
      setShowAddAddressForm(false);
    }
    // eslint-disable-next-line
  }, [addStockManagementAddressState]);

  useEffect(() => {
    if (updateStockManagementAddressState === API_REQUEST_STATE.SUCCESS) {
      refetchOrganisation();
    }
    // eslint-disable-next-line
  }, [updateStockManagementAddressState]);

  useEffect(() => {
    if (addStockManagementContactState === API_REQUEST_STATE.SUCCESS) {
      refetchOrganisation();
      setShowAddContactForm(false);
    }
    // eslint-disable-next-line
  }, [addStockManagementContactState]);

  useEffect(() => {
    if (updateStockManagementContactState === API_REQUEST_STATE.SUCCESS) {
      refetchOrganisation();
    }
    // eslint-disable-next-line
  }, [updateStockManagementContactState]);

  useEffect(() => {
    if (updateOrganisationState === API_REQUEST_STATE.SUCCESS) {
      refetchOrganisation();
      setShowUpdateOrgDataForm(false);
    }
    // eslint-disable-next-line
  }, [updateOrganisationState]);

  const handleSubmitUpdateOrganisation = async (
    values: UpdateOrganisationDataInterface,
    organisationId: string
  ) => {
    const orderFlowTypeId = values.config.orderFlowTypeId?.value as number;
    const paymentDueDateType: string = values.config.paymentDueDate.type?.value;
    const paymentDueDateValue: number = values.config.paymentDueDate.value;
    const defaultCurrency: string = values.defaultCurrency?.label ?? "";

    // Create an object with only the properties that have changed
    const body: UpdateStockManagementOrganisationBody = {
      organisationId,
      ...(values.name !== organisation?.name && { name: values.name }),
      config: {
        ...(values.config.paymentTerms !== organisation?.config?.paymentTerms && {
          paymentTerms: values.config.paymentTerms,
        }),
        ...((paymentDueDateType !== organisation?.config?.paymentDueDate?.type ||
          paymentDueDateValue !== organisation?.config?.paymentDueDate?.value) && {
          paymentDueDate: { type: paymentDueDateType, value: values.config.paymentDueDate.value },
        }),
        ...(values.config.xeroClientName !== organisation?.config?.xeroClientName && {
          xeroClientName: values.config.xeroClientName,
        }),
        ...(orderFlowTypeId !== organisation?.config?.orderFlowTypeId && {
          orderFlowTypeId: orderFlowTypeId,
        }),
        ...(values.config.paymentDisclaimer !== organisation?.config?.paymentDisclaimer && {
          paymentDisclaimer: values.config.paymentDisclaimer,
        }),
        ...(values.config.deliveryInvoicedSeparately !==
          organisation?.config?.deliveryInvoicedSeparately && {
          deliveryInvoicedSeparately: values.config.deliveryInvoicedSeparately,
        }),
        ...(values.config.computeDeliveryCostPerProduct !==
          organisation?.config?.computeDeliveryCostPerProduct && {
          computeDeliveryCostPerProduct: values.config.computeDeliveryCostPerProduct,
        }),
        ...(values.config.enableClientReferenceCollection !==
          organisation?.config?.enableClientReferenceCollection && {
          enableClientReferenceCollection: values.config.enableClientReferenceCollection,
        }),
        ...(values.config.prepaidStock !== organisation?.config?.prepaidStock && {
          prepaidStock: values.config.prepaidStock,
        }),
        ...(values.config.displayProductUnitCost !==
          organisation?.config?.displayProductUnitCost && {
          displayProductUnitCost: values.config.displayProductUnitCost,
        }),
      },
      ...(values.locale?.label !== organisation?.locale && {
        locale: values.locale?.label,
      }),
      ...(defaultCurrency !== organisation?.defaultCurrency && {
        defaultCurrency: defaultCurrency,
      }),
    };

    // Only send a PATCH request if there are changes
    if (Object.keys(body).length > 1) {
      await updateOrganisation(body);
    }
  };

  const handleSubmitAddAddress = async (values: AddAddressToOrganisationInterface) => {
    const primaryContactId = formattedOrganisationContacts.find(
      ({ indexId }) => indexId === values.primaryContactId?.value
    )?.id;

    if (!primaryContactId) return;

    const body: AddStockManagementAddressBody = {
      organisationId: orgId,
      isWarehouse: values.isWarehouse,
      isInternal: false,
      addressName: values.addressName,
      addressDescription: values.addressDescription,
      addressLine1: values.addressLine1,
      addressLine2: values.addressLine2,
      addressLine3: values.addressLine3,
      city: values.city,
      postcode: values.postcode,
      countryCode: values.countryCode?.label as string,
      region: values.region,
      deliveryTimeline: {
        max: Number(values.deliveryTimelineMax),
        min: Number(values.deliveryTimelineMin),
        units: values.deliveryTimelineUnits,
        businessDaysOnly: values.deliveryTimelineBusinessDaysOnly,
      },
      paymentAttributes: {},
      primaryContactId: primaryContactId,
    };
    await addAddress(body);
  };

  const handleSubmitUpdateAddress = async (
    values: UpdateAddressDataInterface,
    address: Address
  ) => {
    const initialPrimaryContact = getInitialPrimaryContactValue({
      organisationContacts: formattedOrganisationContacts,
      address,
    });

    const body: UpdateStockManagementAddressBody = {
      addressId: address?.id ?? " ",
      ...(values.isWarehouse !== address?.isWarehouse && {
        isWarehouse: values.isWarehouse,
      }),
      ...(values.addressName !== address?.name && {
        addressName: values.addressName,
      }),
      ...(values.addressDescription !== address?.addressDescription && {
        addressDescription: values.addressDescription,
      }),
      ...(values.addressLine1 !== address?.addressLine1 && {
        addressLine1: values.addressLine1,
      }),
      ...(values.addressLine2 !== address?.addressLine2 && {
        addressLine2: values.addressLine2,
      }),
      ...(values.addressLine3 !== address?.addressLine3 && {
        addressLine3: values.addressLine3,
      }),
      ...(values.city !== address?.city && {
        city: values.city,
      }),
      ...(values.postcode !== address?.postcode && {
        postcode: values.postcode,
      }),
      ...(values.countryCode?.label !== address?.countryCode && {
        countryCode: values?.countryCode?.label,
      }),
      ...(values.region !== address?.region && {
        region: values.region,
      }),
      ...((values.deliveryTimelineMax !== address?.deliveryTimeline?.max ||
        values.deliveryTimelineMin !== address?.deliveryTimeline?.min ||
        values.deliveryTimelineUnits !== address?.deliveryTimeline?.units ||
        values.deliveryTimelineBusinessDaysOnly !==
          address?.deliveryTimeline?.businessDaysOnly) && {
        deliveryTimeline: {
          max: Number(values.deliveryTimelineMax),
          min: Number(values.deliveryTimelineMin),
          units: values.deliveryTimelineUnits,
          businessDaysOnly: values.deliveryTimelineBusinessDaysOnly,
        },
      }),
      ...(initialPrimaryContact?.value !== values.primaryContactId?.value && {
        primaryContactId: organisationContacts[values.primaryContactId!.value].id,
      }),
    };
    // Only send a PATCH request if there are changes
    if (Object.keys(body).length > 1) {
      await updateAddress(body);
    }
  };

  const handleSubmitAddContact = async (values: AddContactToOrganisationInterface) => {
    const body: AddStockManagementContactBody = {
      organisationId: orgId,
      firstName: values.firstName,
      lastName: values.lastName,
      jobTitle: values.jobTitle,
      emailAddress: values.emailAddress,
      phoneNumber: values.phoneNumber,
      preferredCommunicationMethodId: values.preferredCommunicationMethodId?.value,
      addressId: values.addressId?.value,
    };
    await addContact(body);
    setShowAddContactForm(false);
  };

  const handleSubmitUpdateContact = async (
    values: UpdateContactDataInterface,
    contact: Contact
  ) => {
    const body: UpdateStockManagementContactBody = {
      contactId: contact?.id ?? " ",
      ...(values.firstName !== contact?.firstName && {
        firstName: values.firstName,
      }),
      ...(values.lastName !== contact?.lastName && {
        lastName: values.lastName,
      }),
      ...(values.jobTitle !== contact?.contactJobTitle && {
        jobTitle: values.jobTitle,
      }),
      ...(values.emailAddress !== contact?.contactEmailAddress && {
        emailAddress: values.emailAddress,
      }),
      ...(values.phoneNumber !== contact?.contactPhoneNumber && {
        phoneNumber: values.phoneNumber,
      }),
      ...(values.preferredCommunicationMethodId?.value !==
        contact?.preferredCommunicationMethodId && {
        preferredCommunicationMethodId: values.preferredCommunicationMethodId?.value,
      }),
      ...(values.addressId?.value !== contact?.addressId && {
        addressId: values.addressId?.value,
      }),
    };
    // Only send a PATCH request if there are changes
    if (Object.keys(body).length > 1) {
      await updateContact(body);
    }
  };

  const addressRightItems: PanelProps["rightItems"] = SM_ADMIN_ROLES.some(role =>
    roles.includes(role)
  )
    ? [
        {
          id: 1,
          handleClick: () => setShowAddAddressForm(true),
          type: "button",
          title: "Add New Address",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const contactRightItems: PanelProps["rightItems"] = SM_ADMIN_ROLES.some(role =>
    roles.includes(role)
  )
    ? [
        {
          id: 1,
          handleClick: () => setShowAddContactForm(true),
          type: "button",
          title: "Add New Contact",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const formattedOrganisationContacts: FormattedContact[] = organisationContacts.map(
    (contact, index) => {
      return {
        ...contact,
        indexId: index,
      };
    }
  );

  return (
    <Page
      title={"View & Update Organisation"}
      isLoading={organisationLoading}
      error={organisationError}
    >
      <>
        <Panel withWrapper={true} title={organisation?.name ?? "Stock Management Organisation"}>
          <InnerPanelWrapper>
            {showUpdateOrgDataForm ? (
              <UpdateStockManagementOrganisationData
                organisation={organisation}
                setShowUpdateOrgDataForm={setShowUpdateOrgDataForm}
                handleSubmitUpdateOrganisation={(values, organisationId) =>
                  handleSubmitUpdateOrganisation(values, organisationId)
                }
              />
            ) : (
              <StockManagementOrganisationData organisation={organisation} />
            )}
            {!showUpdateOrgDataForm && (
              <VisibleFor roles={SM_ADMIN_ROLES}>
                <ButtonWrapper style={{ marginTop: "10px" }}>
                  <SecondaryButton
                    onClick={() => setShowUpdateOrgDataForm(true)}
                    appearance={"whiteButtonBlueText"}
                    isWithinTable
                    type="button"
                  >
                    <IconText text={"Update Organisation"} primaryIcon={"document-pencil"} />
                  </SecondaryButton>
                </ButtonWrapper>
              </VisibleFor>
            )}
          </InnerPanelWrapper>
        </Panel>

        <Panel
          withWrapper={true}
          title={`${organisation?.name} Contacts`}
          rightItems={contactRightItems}
        >
          <InnerPanelWrapper>
            {showAddContactForm && (
              <AddStockManagementContact
                onClose={() => setShowAddContactForm(false)}
                onAddContact={values => handleSubmitAddContact(values)}
                addresses={organisationAddresses}
              />
            )}
            {organisationContacts.length === 0 && (
              <PrimaryLineDataWrapper>
                A contact hasn't been added to this organisation yet.
                {organisationAddresses?.length === 0 &&
                  " An address must be added to the organisation before you can add contacts."}
              </PrimaryLineDataWrapper>
            )}
            {organisationContacts.map(contact => (
              <div key={contact.id}>
                <StockManagementContactsData
                  contact={contact}
                  addresses={organisationAddresses}
                  handleSubmitUpdateContact={(values, contact) =>
                    handleSubmitUpdateContact(values, contact)
                  }
                />
              </div>
            ))}
          </InnerPanelWrapper>
        </Panel>
        <Panel
          withWrapper={true}
          title={`${organisation?.name} Addresses`}
          rightItems={addressRightItems}
        >
          <InnerPanelWrapper>
            <FormattedLineDataWrapper>
              {showAddAddressForm && (
                <AddStockManagementAddress
                  organisationContacts={formattedOrganisationContacts}
                  onClose={() => setShowAddAddressForm(false)}
                  onAddAddress={values => handleSubmitAddAddress(values)}
                />
              )}
            </FormattedLineDataWrapper>
            {organisationAddresses.length === 0 && (
              <PrimaryLineDataWrapper>
                An address hasn't been added to this organisation yet.
              </PrimaryLineDataWrapper>
            )}
            {organisationAddresses.map(address => (
              <div key={address.id}>
                <StockManagementAddressesData
                  organisationContacts={formattedOrganisationContacts}
                  address={address}
                  handleSubmitUpdateAddress={(values, address) =>
                    handleSubmitUpdateAddress(values, address)
                  }
                />
              </div>
            ))}
          </InnerPanelWrapper>
        </Panel>
      </>
    </Page>
  );
};

export default UpdateStockManagementOrganisation;
