import { PRODUCT_PRICING_API } from "../../../../../api/baseConfig";

export interface GetProductPricesResponse {
  sku: string;
  price_per_unit: number;
  valid_to: string;
  currency_code: string;
  id: number;
  min_order_quantity: number;
  valid_from: string;
  updated_at: string;
}

export interface GetProductPricesBody {
  skus: string[];
  include_history?: boolean;
  include_future?: boolean;
}

export const getProductPricesApi = async (body: GetProductPricesBody) => {
  try {
    return await PRODUCT_PRICING_API.post("/get_products_pricing", body);
  } catch (err) {
    console.log("Error in getting product prices API call", err);
    throw err;
  }
};
