import { ApolloError } from "@apollo/client";
import { FormikErrors, FormikTouched } from "formik";
import { Dispatch, SetStateAction, useEffect } from "react";

import { SimpleDateInput } from "../../../../../shared/components/forms/SimpleDateInput";
import { SimpleInput } from "../../../../../shared/components/forms/SimpleInput";
import { SimpleSelect } from "../../../../../shared/components/forms/SimpleSelect";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { DataPoint, TopPanelInputsWrapper } from "../../../../../styles/SharedStyles";
import { WorkOrderForm, fieldNames } from "../formValues";
import { useOrganisationNames } from "../graphql/hooks/useWorkOrderOrganisationNames";
import { useRecipeNames } from "../graphql/hooks/useWorkOrderRecipeNames";
import { useWarehouseNames } from "../graphql/hooks/useWorkOrderWarehouseNames";

interface WorkOrderGeneralProps {
  values: WorkOrderForm;
  isAddingInputLines: boolean;
  errors: FormikErrors<WorkOrderForm>;
  touched: FormikTouched<WorkOrderForm>;
  setFieldValue: (field: string, value: any) => void;
  setError: Dispatch<SetStateAction<ApolloError | undefined>>;
  setMultiplier: Dispatch<SetStateAction<number | undefined>>;
}

const WorkOrderGeneral = ({
  isAddingInputLines,
  values,
  setFieldValue,
  setMultiplier,
  errors,
  touched,
  setError,
}: WorkOrderGeneralProps) => {
  const { recipeNames, recipeNamesLoading, recipeNamesError } = useRecipeNames();
  const { organisationNames, organisationNamesLoading, organisationNamesError } =
    useOrganisationNames();
  const { warehouseNames, warehouseNamesLoading, warehouseNamesError } = useWarehouseNames();

  const organisationCopy = organisationNamesLoading ? "Loading values" : "Select organisation";
  const recipeCopy = recipeNamesLoading ? "Loading values" : "Select recipe name";
  const warehouseCopy = warehouseNamesLoading ? "Loading values" : "Select warehouse";

  useEffect(() => {
    setError(recipeNamesError || organisationNamesError || warehouseNamesError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recipeNamesError, organisationNamesError, warehouseNamesError]);

  return (
    <TopPanelInputsWrapper>
      {isAddingInputLines ? (
        <>
          <DisplayBox isGray isRequired label="Warehouse" value={values.warehouse?.label} />
          <DisplayBox isGray isRequired label="Organisation" value={values.organisation?.label} />
          <DisplayBox isGray isRequired label="Recipe Name" value={values.recipe_name?.label} />
          <DisplayBox
            isGray
            isRequired
            label="External Work Order ID"
            value={values.external_work_order_id}
          />
          <DisplayBox isGray isRequired label="Due Date" value={values.due_date} />
          <DisplayBox isGray isRequired label="Work Order Quantity" value={values.quantity} />
        </>
      ) : (
        <>
          <DataPoint>
            <SimpleSelect
              isRequired
              htmlFor={fieldNames.warehouse}
              label="Warehouse"
              options={warehouseNames}
              name={fieldNames.warehouse}
              placeholder={warehouseCopy}
              value={values.warehouse}
              error={errors.warehouse}
              touched={touched.warehouse}
              changeHandler={event => setFieldValue(fieldNames.warehouse, event)}
            />
          </DataPoint>
          <DataPoint>
            <SimpleSelect
              isRequired
              htmlFor={fieldNames.organisation}
              label="Organisation"
              options={organisationNames}
              name={fieldNames.organisation}
              placeholder={organisationCopy}
              value={values.organisation}
              error={errors.organisation}
              touched={touched.organisation}
              changeHandler={event => setFieldValue(fieldNames.organisation, event)}
            />
          </DataPoint>
          <DataPoint>
            <SimpleSelect
              isRequired
              htmlFor={fieldNames.recipe_name}
              label="Recipe Name"
              options={recipeNames}
              name={fieldNames.recipe_name}
              placeholder={recipeCopy}
              value={values.recipe_name}
              error={errors.recipe_name}
              touched={touched.recipe_name}
              changeHandler={event => setFieldValue(fieldNames.recipe_name, event)}
            />
          </DataPoint>
          <DataPoint>
            <SimpleInput
              name={fieldNames.external_work_order_id}
              htmlFor={fieldNames.external_work_order_id}
              type="number"
              label="External Work Order ID"
              value={values.external_work_order_id}
              placeholder="Select External Work Order ID"
              error={errors.external_work_order_id}
              touched={touched.external_work_order_id}
              changeHandler={event => {
                setFieldValue(fieldNames.external_work_order_id, event.target.value);
              }}
            />
          </DataPoint>
          <DataPoint>
            <SimpleDateInput
              isRequired
              name={fieldNames.due_date}
              htmlFor={fieldNames.due_date}
              label="Due Date"
              value={values.due_date}
              error={errors.due_date}
              touched={touched.due_date}
              changeHandler={event => {
                setFieldValue(fieldNames.due_date, event.target.value);
              }}
            />
          </DataPoint>
          <DataPoint>
            <SimpleInput
              isRequired
              name={fieldNames.quantity}
              htmlFor={fieldNames.quantity}
              type="number"
              label="Work Order Quantity"
              value={values.quantity}
              placeholder="Select Quantity"
              error={errors.quantity}
              touched={touched.quantity}
              changeHandler={event => {
                setFieldValue(fieldNames.quantity, event.target.value);
                setMultiplier(Number(event.target.value));
              }}
            />
          </DataPoint>
        </>
      )}
    </TopPanelInputsWrapper>
  );
};

export default WorkOrderGeneral;
