import { PRODUCT_CATALOGUE_API } from "../../../../api/baseConfig";

interface CreateAttributeTemplateBody {
  attribute_template_type_id: number;
  attribute_template_name: string;
  required_fields: {
    [key: string]: string;
  };
}

export const createAttributeTemplate = async (body: CreateAttributeTemplateBody) => {
  try {
    const response = await PRODUCT_CATALOGUE_API.post("/attribute/templates", body);
    const data = response.data;
    return {
      data: data,
    };
  } catch (err) {
    console.log("Error in creating attribute template API call", err);
    throw err;
  }
};
