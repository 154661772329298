import { useTranslation } from "react-i18next";

import { InputField } from "@sourceful/shared-components";

import { AttachmentDownloader } from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import LineData from "../../../../../components/lineData/LineData";
import { IndexPill } from "../../../../../shared/components/molecules/IndexPill/IndexPill";
import {
  DataPoint,
  FormattedLineDataWrapper,
  Label,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { ExternalAttachmentsWrapper } from "../../../../../styles/SourcingStyles";
import { prettifyProductName } from "../../../shared/mappers";
import { mapAttachmentsToSet } from "../../shared/mappers";
import { SupplierResponseProductLineProps } from "../formValues";

const CHAR_SEQ = "%-%";

const getProductName = (productName: string, isFileCopy: boolean) => {
  if (isFileCopy) {
    return prettifyProductName(productName) + " (File Copy)";
  }
  return prettifyProductName(productName);
};

export const ProductLine = ({
  values,
  setFieldValue,
  handleAttachmentDownload,
  requiredRefs,
  orderLine,
  index,
  isFileCopyLine = false,
}: SupplierResponseProductLineProps) => {
  const { t } = useTranslation();

  const product = orderLine.product;
  const repeatOrder = product.supplier_products.length !== 0;
  const refs = (
    product.supplier_products.length === 0 ? "" : product.supplier_products[0].supplier_reference
  ).split(CHAR_SEQ);
  const orderReference = refs.length === 2 ? refs[0] : "";
  const productIdReference = refs.length === 2 ? refs[1] : refs[0];

  return (
    <SecondaryLineWrapper key={index} type="withIndex">
      <IndexPill index={index + 1} />
      <FormattedLineDataWrapper>
        <LineData
          label={t("supplier_response_product_name")}
          value={getProductName(product.product_name, isFileCopyLine)}
          important={true}
        />
        <LineData
          label={t("supplier_response_product_quantity")}
          value={`${orderLine.quantity} ${t("supplier_response_product_units")}`}
        />
        <>
          {!!orderLine?.supplier_quote_line &&
            !!orderLine?.supplier_quote_line?.supplier_reference && (
              <LineData
                label={t("supplier_response_quotation_reference")}
                value={orderLine.supplier_quote_line.supplier_reference}
                important={true}
              />
            )}
        </>

        {requiredRefs && !isFileCopyLine && (
          <>
            {repeatOrder ? (
              <>
                <LineData
                  label={t("supplier_response_reference_order_number")}
                  value={orderReference}
                />
                <LineData
                  label={t("supplier_response_reference_product_id")}
                  value={productIdReference}
                />
              </>
            ) : (
              <>
                <DataPoint>
                  <Label>{t("supplier_response_reference_order_number")}</Label>
                  <InputField
                    size={"medium"}
                    type={"text"}
                    value={values.purchase_order_lines_data[index].supplier_order_number || ""}
                    name={`purchase_order_lines_data[${index}].supplier_order_number`}
                    placeholder={t("supplier_response_reference_order_number_placeholder")}
                    handleChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </DataPoint>
                <DataPoint>
                  <Label isRequired>{t("supplier_response_reference_product_id")}</Label>
                  <InputField
                    size={"medium"}
                    type={"text"}
                    value={values.purchase_order_lines_data[index].supplier_product_id || ""}
                    name={`purchase_order_lines_data[${index}].supplier_product_id`}
                    placeholder={t("supplier_response_reference_product_id_placeholder")}
                    handleChange={e => {
                      setFieldValue(e.target.name, e.target.value);
                    }}
                  />
                </DataPoint>
              </>
            )}
          </>
        )}
      </FormattedLineDataWrapper>

      <ExternalAttachmentsWrapper>
        <Title>{t("supplier_response_attachments_title")}</Title>
        {!orderLine.folder || orderLine.folder.attachments.length === 0 ? (
          <SecondaryLineDataWrapper>
            {t("supplier_response_attachments_not_found")}
          </SecondaryLineDataWrapper>
        ) : (
          <>
            {mapAttachmentsToSet(orderLine.folder.attachments).map((attachment, index) => (
              <SecondaryLineDataWrapper key={index}>
                <AttachmentDownloader
                  key={index}
                  originalFilename={attachment.original_filename}
                  attachmentUUID={attachment.attachment_uuid}
                  onDownload={attachmentUUID => {
                    handleAttachmentDownload(attachmentUUID);
                  }}
                  metadata={attachment.metadata}
                />
              </SecondaryLineDataWrapper>
            ))}
          </>
        )}
      </ExternalAttachmentsWrapper>
    </SecondaryLineWrapper>
  );
};
