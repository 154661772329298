import { IAM_API } from "../../../../../api/baseConfig";

export interface addMembersBody {
  members: string[];
  organisationId: string;
}

export const addIAMMembers = async (params: addMembersBody) => {
  try {
    return await IAM_API.post(`/v2/organisation/members`, {
      org_uuid: params.organisationId,
      user_uuids: params.members,
    });
  } catch (err) {
    console.error("Error in IAM add user API call", err);
    throw err;
  }
};
