import { useState } from "react";

import { IconText, SecondaryButton } from "@sourceful/shared-components";

import { SM_ADMIN_ROLES } from "../../../../../providers/AuthorisationProvider";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import VisibleFor from "../../../../../shared/components/wrappers/VisibleFor";
import {
  ButtonWrapper,
  FormattedLineDataWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
} from "../../../../../styles/SharedStyles";
import { Warning } from "../../../skus/updateSku/page/UpdateSku";
import { preferredCommunicationMethodTypes } from "../../shared/graphql/mappers";
import { Address, Contact } from "../../shared/graphql/types";
import { UpdateContactDataInterface } from "../../shared/types";
import UpdateStockManagementContact from "./UpdateStockManagementContact";

interface Props {
  contact: Contact | null | undefined;
  addresses: Address[] | null | undefined;
  handleSubmitUpdateContact: (values: UpdateContactDataInterface, contact: Contact) => void;
}

const StockManagementContactsData = ({
  contact,
  addresses,
  handleSubmitUpdateContact,
}: Props): JSX.Element | null => {
  const [showUpdateContactForm, setShowUpdateContactForm] = useState(false);
  if (!contact) return null;
  const contactAddressName = () => {
    const contactAddressId = contact?.addressId;
    if (!contactAddressId) {
      return "";
    }
    const address = addresses?.find(address => address.id === contactAddressId);
    if (!address) {
      return "";
    }
    return address?.name;
  };
  if (!showUpdateContactForm) {
    return (
      <PrimaryLineWrapper>
        <FormattedLineDataWrapper>
          <Title css={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {contact?.firstName} {contact?.lastName}
            {contact?.isPrimary && <Warning>Primary Contact</Warning>}
          </Title>
          <PrimaryLineDataWrapper marginSize={"smallMargin"} alignStart>
            <FormattedLineDataWrapper>
              <DisplayBox isGray label="Job Title" value={contact?.contactJobTitle} />
              <DisplayBox isGray label="Email address" value={contact?.contactEmailAddress} />
              <DisplayBox isGray label="Phone number" value={contact?.contactPhoneNumber} />
              <DisplayBox isGray label="Associated address" value={contactAddressName()} />
              <DisplayBox
                isGray
                label="Preferred communication method"
                value={
                  contact?.preferredCommunicationMethodId
                    ? preferredCommunicationMethodTypes[contact?.preferredCommunicationMethodId]
                    : ""
                }
              />
            </FormattedLineDataWrapper>
          </PrimaryLineDataWrapper>
        </FormattedLineDataWrapper>
        <VisibleFor roles={SM_ADMIN_ROLES}>
          <ButtonWrapper style={{ marginTop: "10px" }}>
            <SecondaryButton
              onClick={() => setShowUpdateContactForm(true)}
              appearance={"whiteButtonBlueText"}
              isWithinTable
              type="button"
            >
              <IconText text={"Update Contact"} primaryIcon={"document-pencil"} />
            </SecondaryButton>
          </ButtonWrapper>
        </VisibleFor>
      </PrimaryLineWrapper>
    );
  } else {
    return (
      <PrimaryLineWrapper>
        <UpdateStockManagementContact
          contact={contact}
          addresses={addresses}
          handleSubmitUpdateContact={(values, contact) =>
            handleSubmitUpdateContact(values, contact)
          }
          setShowUpdateContactForm={setShowUpdateContactForm}
          addressName={contactAddressName() ?? ""}
        />
      </PrimaryLineWrapper>
    );
  }
};

export default StockManagementContactsData;
