// TODO: Remove this file after the FF is removed

interface DropdownOption {
  value: number;
  label: string;
}

export const filterCNCountryCodeOptions = (countryCodeOptions: DropdownOption[]) => {
  return countryCodeOptions.filter(country => country.label !== "CN");
};

export const filterCNYCurrencyOptions = (currencyOptions: DropdownOption[]) => {
  return currencyOptions.filter(currency => currency.label !== "CNY");
};
