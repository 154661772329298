import { ApolloError } from "@apollo/client";

import { useGetAllSkusQuery } from "../../../../../generated/graphql_pcat";
import { GRAPHQL_PCAT } from "../../../../../shared/constants";
import { mapSkus } from "../mappers/mapSkus";
import { MappedSkuItem } from "../types";

export interface HookResult {
  skus?: MappedSkuItem[];
  skusError?: ApolloError;
  skusLoading: boolean;
}

export const useAllSkus = (): HookResult => {
  const { data, error, loading } = useGetAllSkusQuery({
    context: {
      clientName: GRAPHQL_PCAT,
    },
  });

  return {
    skus: data ? mapSkus(data.product_service_sku) : [],
    skusError: error,
    skusLoading: loading,
  };
};
