import { SizeValue } from "@sourceful/shared-types";

export const renderSizeValue = (value: SizeValue, emptyValueString: string) => {
  const sizes = [];
  if (value.height) sizes.push(value.height);
  if (value.width) sizes.push(value.width);
  if (value.length) sizes.push(value.length);

  if (sizes.length === 0) {
    return <>{emptyValueString}</>;
  }

  return (
    <>
      {sizes.map((size, i) => {
        return size + `${i === sizes.length - 1 ? value.unit : "x"}`;
      })}
    </>
  );
};
