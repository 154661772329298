import { ApolloError } from "@apollo/client";

import { useGetDashboardOutboundManifestsQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { DashboardItem } from "../types";

export interface HookResult {
  outboundManifests: DashboardItem[];
  outboundManifestsError?: ApolloError;
  outboundManifestsLoading: boolean;
}

export const useDashboardOutboundManifests = (): HookResult => {
  const { data, error, loading } = useGetDashboardOutboundManifestsQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    outboundManifests:
      data && Array.isArray(data.outbound_manifest)
        ? data.outbound_manifest.map(outboundManifest => {
            return {
              orderId: outboundManifest.id,
              organisationName: outboundManifest.organisation.organisation_name,
              iconName: "document-clipboard",
              date: outboundManifest.expected_dispatch,
            };
          })
        : [],
    outboundManifestsError: error,
    outboundManifestsLoading: loading,
  };
};
