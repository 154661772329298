import { IAM_API } from "../../../../../api/baseConfig";

export interface AssignMemberRolesBody {
  roleIDs: number[];
  organisationUUID: string;
  userUUID: string;
}

export const assignMemberRoles = async (params: AssignMemberRolesBody) => {
  try {
    return IAM_API.post(`/v2/organisation/member/roles`, {
      role_ids: params.roleIDs,
      org_uuid: params.organisationUUID,
      user_uuid: params.userUUID,
    });
  } catch (err) {
    console.error("Error in IAM assign roles API call", err);
    throw err;
  }
};
