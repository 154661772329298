import { extractProductName } from "../../../shared/mappers";
import { QuoteItem } from "../graphql/types";

export const mapQuoteToTableRow = (quotes: QuoteItem[]) => {
  return quotes.map(quote => {
    const moq = quote.minimum_order_quantity;

    const fixedCosts = quote.quotation_costs.filter(
      quotationCost => quotationCost.quotation_cost_type.quotation_cost_distribution_id !== 3
    );

    const firstTimeCosts = quote.quotation_costs
      .filter(
        quotationCost => quotationCost.quotation_cost_type.quotation_cost_distribution_id === 3
      )
      .map(quotationCost => Number(quotationCost.cost_excl_vat));

    const scaledQuotationCosts = fixedCosts.map(quotationCost => {
      if (quotationCost.quotation_cost_type.quotation_cost_distribution_id === 1) {
        return moq !== 0
          ? Number(quotationCost.cost_excl_vat) * moq
          : Number(quotationCost.cost_excl_vat);
      }
      return Number(quotationCost.cost_excl_vat);
    });

    const accumulatedCost = scaledQuotationCosts.reduce(
      (cost: number, quotationCost) => cost + quotationCost,
      0
    );

    const firstTimeCost = firstTimeCosts.reduce(
      (cost: number, quotationCost) => cost + quotationCost,
      accumulatedCost
    );

    return {
      id: quote.id,
      quotation_id: quote.supplier_quote_id,
      product_uid: quote.product.external_product_id || quote.product.line_reference,
      product_name: extractProductName(quote.product.product_name),
      supplier_name: quote.supplier_quote.organisation.organisation_name,
      valid_to: quote.supplier_quote.valid_to,
      moq: moq,
      repeat_unit_price: moq !== 0 ? accumulatedCost / moq : accumulatedCost,
      first_unit_price: moq !== 0 ? firstTimeCost / moq : firstTimeCost,
      currency: quote.currency_code,
    };
  });
};
