import { ApolloError } from "@apollo/client";

import { useGetProductStockWithDataQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { ProductStockItem } from "../types";

export interface HookResult {
  productStock: ProductStockItem[];
  productStockError?: ApolloError;
  productStockLoading: boolean;
}

export const useProductStock = (): HookResult => {
  const { data, error, loading } = useGetProductStockWithDataQuery({
    context: {
      clientName: GRAPHQL_WMS,
    },
  });

  return {
    productStock: data && Array.isArray(data.product_stock) ? data.product_stock : [],
    productStockError: error,
    productStockLoading: loading,
  };
};
