import { styled } from "@sourceful/shared-components";

import { Label } from "../../styles/SharedStyles";

const LineWrapper = styled("div", {
  margin: "0px",
  padding: "8px 12px",
  display: "flex",
  alignItems: "center",
  width: "auto",
});

const LabelWrapper = styled("div", {
  width: "250px",
});

const Data = styled("div", {});

const ImportantData = styled("div", {
  fontWeight: 700,
});

const LineData = ({
  label,
  value,
  important,
}: {
  label: string;
  value: any;
  important?: boolean;
}) => {
  return (
    <LineWrapper>
      <LabelWrapper>
        <Label>{label}:</Label>
      </LabelWrapper>
      {important ? <ImportantData>{value}</ImportantData> : <Data>{value}</Data>}
    </LineWrapper>
  );
};

export default LineData;
