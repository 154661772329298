import { ApolloError } from "@apollo/client";

import { useGetStockCheckByIdQuery } from "../../../../../../generated/graphql_wms";
import { GRAPHQL_WMS } from "../../../../../../shared/constants";
import { StockCheckItem } from "../types";

export interface HookResult {
  stockCheck?: StockCheckItem;
  stockCheckError?: ApolloError;
  stockCheckLoading: boolean;
  refetchStockCheck: () => Promise<void>;
}

export const useStockCheckById = (stockCheckId: number): HookResult => {
  const { data, error, loading, refetch } = useGetStockCheckByIdQuery({
    context: { clientName: GRAPHQL_WMS },
    variables: { stockCheckId },
  });

  return {
    stockCheck: data && Array.isArray(data.stock_check) ? data.stock_check[0] : undefined,
    stockCheckError: error,
    stockCheckLoading: loading,
    refetchStockCheck: async () => {
      refetch({ stockCheckId });
    },
  };
};
