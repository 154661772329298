import { AdminQuotationCostTypeInterface } from "../types";

export const initialFieldValues: AdminQuotationCostTypeInterface = {
  quotation_cost_description: "",
  quotation_cost_translations: [],
  is_cost_required_default: false,
  is_cost_required_sample: false,
};

export const fieldNames = {
  quotation_cost_description: "quotation_cost_description",
  quotation_cost_translations: "quotation_cost_translations",
};
