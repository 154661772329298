export const SPLIT_SHIPMENT_FAILED = "SPLIT_SHIPMENT_FAILED";
export const SPLIT_SHIPMENT_RESET = "SPLIT_SHIPMENT_RESET";
export const SPLIT_SHIPMENT_LOADING = "SPLIT_SHIPMENT_LOADING";
export const SPLIT_SHIPMENT_SUCCESS = "SPLIT_SHIPMENT_SUCCESS";

export type SplitShipmentActionType =
  | typeof SPLIT_SHIPMENT_FAILED
  | typeof SPLIT_SHIPMENT_LOADING
  | typeof SPLIT_SHIPMENT_RESET
  | typeof SPLIT_SHIPMENT_SUCCESS;
