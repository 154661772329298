import { countryCodeFromLocale } from "../../../../purchaseOrders/shared/mappers";
import {
  AvgResponseTimeItem,
  PurchaseOrderLineItem,
  SupplierDataItem,
  SupplierItem,
  ValueQtyItem,
} from "../types";

export const mapSupplier = (supplier: SupplierDataItem): SupplierItem => {
  const averageResponseTimeData: AvgResponseTimeItem[] =
    supplier.organisation.averageResponseTimeData;

  const hasResponse = averageResponseTimeData.filter(data =>
    Boolean(data.purchase_order_supplier_response)
  );

  const hasResponseAndApprovals = hasResponse.filter(
    data => data.purchase_order_approvals.length > 0
  );

  const responseTimes = hasResponseAndApprovals.map(
    (averageResponseTimeDataItem: AvgResponseTimeItem) => {
      const responseReceivedAt = new Date(
        averageResponseTimeDataItem.purchase_order_supplier_response!.created_at
      );

      const orderSent = new Date(
        averageResponseTimeDataItem.purchase_order_approvals[0].created_at
      );

      return responseReceivedAt.valueOf() - orderSent.valueOf();
    }
  );

  const averageResponseTime =
    responseTimes.length > 0
      ? responseTimes.reduce((a: number, b: number) => a + b, 0) / responseTimes.length
      : 0;

  const purchaseOrderLineItems: PurchaseOrderLineItem[] = [];

  supplier.organisation.incompletePurchaseOrders.forEach(purchaseOrder => {
    purchaseOrder.purchase_order_lines.forEach(line => {
      const purchaseOrderLineAllocations = line.purchase_order_line_allocations;

      let clientName = "";
      if (purchaseOrderLineAllocations.length === 0) {
        clientName = "";
      } else if (purchaseOrderLineAllocations.length === 1) {
        clientName = purchaseOrderLineAllocations[0].client_order.organisation.organisation_name;
      } else {
        const allocationNames = purchaseOrderLineAllocations.map(allocation => {
          return `${allocation.client_order.organisation.organisation_name} (${allocation.quantity})`;
        });
        clientName = allocationNames.join(", ");
      }

      const purchaseOrderLineItem: PurchaseOrderLineItem = {
        purchaseOrderId: purchaseOrder.id,
        purchaseOrderNumber: `${countryCodeFromLocale(supplier.locale)}-${
          purchaseOrder.purchase_order_document_uid
        }
          `,
        expectedDeliveryDate:
          purchaseOrder.updated_delivery_date ?? purchaseOrder.expected_delivery_date,
        deliveryDate: purchaseOrder.delivery_date,
        createdOn: purchaseOrder.created_at,
        purchaseOrderStatus: purchaseOrder.purchase_order_status.purchase_order_status_name,
        quantity: line.quantity,
        productName: line.product.product_name,
        clientName: clientName,
        calculatedQuotationCost: line.calculated_quotation_cost,
      };

      purchaseOrderLineItems.push(purchaseOrderLineItem);
    });
  });

  const poLines = supplier.organisation.totalMoneyExclVAT
    .flatMap(poLine => poLine.purchase_order_lines)
    .filter(line => !!Boolean(line.calculated_quotation_cost));

  const totalValueExclVat = poLines.reduce(
    (sum: number, poLine: ValueQtyItem) =>
      sum + poLine.calculated_quotation_cost! * poLine.quantity,
    0
  );

  return {
    id: supplier.id,
    attachments: supplier?.folder?.attachments ?? [],
    locale: supplier.locale,
    createdAt: supplier.created_at,
    xeroContactId: supplier.xero_contact_id,
    organisationName: supplier.organisation.organisation_name,
    acceptedPurchaseOrdersCount:
      supplier.organisation.acceptedPurchaseOrdersCount.aggregate?.count ?? 0,
    fulfilledPurchaseOrdersCount:
      supplier.organisation.fulfilledPurchaseOrdersCount.aggregate?.count ?? 0,
    expiredAndRejectedPurchaseOrdersCount:
      supplier.organisation.expiredAndRejectedPurchaseOrdersCount.aggregate?.count ?? 0,
    averageResponseTimeData: averageResponseTime,
    purchaseOrderLineItems: purchaseOrderLineItems,
    totalValueExclVat,
  };
};
