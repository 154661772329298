import React from "react";
import { useHistory } from "react-router-dom";

import {
  ButtonGroup,
  Heading,
  Icon,
  IconText,
  SecondaryButton,
  styled,
} from "@sourceful/shared-components";
import { HeadingLevel } from "@sourceful/shared-components/build/src/components/atoms/typography/heading/Heading";
import { IconName } from "@sourceful/shared-types";

export interface InnerHeaderProps {
  topLevelButtons: TopLevelButton[];
  title: string;
  titleLevel: HeadingLevel;
  hasBackButton?: boolean;
}

export interface TopLevelButton {
  id: number;
  title: string;
  iconName: IconName;
  handleClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  style?: any;
}

const HeaderWrapper = styled("header", {
  display: "flex",
  justifyContent: "space-between",
  width: "100%",
  alignItems: "center",
  color: "#000C32",
});

const IconWrapper = styled("button", {
  padding: "0px",
  margin: "0px",
  border: "none",
  cursor: "pointer",
  color: "#005AE1",
});

const HeaderArrowWrapper = styled("div", {
  display: "flex",
  flexDirection: "row",
});

const InnerHeader = ({ hasBackButton, title, titleLevel, topLevelButtons }: InnerHeaderProps) => {
  let history = useHistory();
  return (
    <HeaderWrapper>
      <HeaderArrowWrapper>
        {hasBackButton && (
          <IconWrapper onClick={() => history.goBack()}>
            <Icon width={50} height={50} name="arrow-direction-left" />
          </IconWrapper>
        )}
        <Heading level={titleLevel}>{title}</Heading>
      </HeaderArrowWrapper>
      <ButtonGroup>
        {topLevelButtons.map(({ id, title, iconName, handleClick, style }) => (
          <SecondaryButton style={style} key={id} onClick={e => handleClick(e)}>
            <IconText text={title} primaryIcon={iconName} />
          </SecondaryButton>
        ))}
      </ButtonGroup>
    </HeaderWrapper>
  );
};

export default InnerHeader;
