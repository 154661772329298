import fileDownload from "js-file-download";
import { useState } from "react";
import { GridLoader } from "react-spinners";
import { useToasts } from "react-toast-notifications";

import { downloadAttachmentAPI } from "../../api/gcs/downloadAttachmentAPI";
import { Label } from "../../styles/AddPage";
import { DiplayAttachment, URLHolder } from "../../styles/SourcingStyles";

export type AttachmentMetadata = { display_filename?: string; fileType?: string };

interface AttachmentProps {
  originalFilename: string;
  attachmentUUID: string;
  onDownload?: (attachmentUuid: string) => Promise<void> | void;
  metadata?: AttachmentMetadata;
}

const getFilename = (metadata: any, originalFilename: string) => {
  if (metadata?.display_filename) {
    return metadata.display_filename;
  }
  return originalFilename;
};

export const AttachmentDownloader = ({
  originalFilename,
  attachmentUUID,
  onDownload,
  metadata,
}: AttachmentProps) => {
  const { addToast } = useToasts();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const filename = getFilename(metadata, originalFilename);

  const downloadAttachment = async () => {
    setIsLoading(true);
    try {
      const response = await downloadAttachmentAPI({
        uuid_name: attachmentUUID,
        filename: originalFilename,
        metadata: metadata,
      });
      const blob = response.data;
      fileDownload(blob, filename);

      setIsLoading(false);
    } catch (e) {
      addToast("Error downloading Attachment.", { appearance: "error" });
      setIsLoading(false);
    }
  };

  const handleDownload = async () => {
    const handler = typeof onDownload === "function" ? onDownload : downloadAttachment;

    await handler(attachmentUUID);
  };

  return (
    <DiplayAttachment>
      {isLoading ? (
        <GridLoader color={"#005AE1"} size={10} />
      ) : (
        <>
          {metadata?.fileType && (
            <div>
              <Label>{`${metadata.fileType}:`}</Label>
            </div>
          )}
          <URLHolder type={"bigMargin"} onClick={handleDownload}>
            {filename}
          </URLHolder>
        </>
      )}
    </DiplayAttachment>
  );
};

export default AttachmentDownloader;
