import { Formik, FormikErrors } from "formik";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import SimpleDateInput from "../../../../../shared/components/forms/SimpleDateInput";
import SimpleInput from "../../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../../shared/components/forms/SimpleSelect";
import SimpleTextArea from "../../../../../shared/components/forms/SimpleTextArea";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  ButtonWrapper,
  DataPoint,
  InnerPanelWrapper,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { useAPI } from "../../../../../util/api/apiHook";
import { PURCHASE_ORDER_STATUS_ID } from "../../../purchaseOrders/updatePurchaseOrders/types";
import { extractProductName } from "../../../shared/mappers";
import { useCountryNames } from "../../graphql/hooks/useCountryNames";
import { usePurchaseOrderStatus } from "../../graphql/hooks/usePurchaseOrderStatus";
import { useQuotationCostTypes } from "../../graphql/hooks/useQuotationCostTypes";
import { useSupplierQuote } from "../../graphql/hooks/useSupplierQuote";
import { mapCountryNamesToDropdownObject } from "../../graphql/mappers";
import { syncDeliveryDateWithLeadTime } from "../../util/syncDeliveryDateWithLeadTime";
import { UpdateQuotationCostLinesBody, UpdateQuoteBody, UpdateQuoteLinesBody } from "../api/types";
import { updateInternalQuoteAPI } from "../api/updateInternalQuoteAPI";
import UpdatedQuotationCost from "../components/UpdatedQuotationCost";
import {
  CartonDetails,
  QuoteForm,
  emptyQuotationCost,
  fieldNames,
  initialFieldValues,
} from "../formValues";
import { QuotationCostInterface } from "../types";
import { validationSchema } from "../validation";

const MINUTE = 1000 * 60;
const HOUR = MINUTE * 60;
const DAY = HOUR * 24;

const UpdateInternalQuotes = () => {
  const { quote_id: quoteId, quote_line_id: quoteLineId } =
    useParams<{ quote_id: string; quote_line_id: string }>();
  const [initialValues, setInitialValues] = useState<QuoteForm>(initialFieldValues);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [updateInternalQuoteState, updateInternalQuote] = useAPI(updateInternalQuoteAPI, false, {
    pending: "Updating quote...",
    success: "Updated quote successfully",
    error: "Something went wrong with updating quotes",
  });

  const { roles } = useAuthorisationContext();

  const { supplierQuote, supplierQuoteLoading, supplierQuoteError } = useSupplierQuote(
    Number(quoteId),
    Number(quoteLineId)
  );

  const { purchaseOrderStatusLoading, purchaseOrderStatus, purchaseOrderStatusError } =
    usePurchaseOrderStatus(Number(quoteId));

  const purchaseOrderStatusIds = purchaseOrderStatus
    ? purchaseOrderStatus?.map(status => status.purchase_order_status.id)
    : undefined;

  const hasNotBeenUsed =
    !purchaseOrderStatusIds ||
    !purchaseOrderStatusIds.length ||
    purchaseOrderStatusIds.every(id => {
      return [
        PURCHASE_ORDER_STATUS_ID.INTERNALLY_REJECTED,
        PURCHASE_ORDER_STATUS_ID.EXTERNALLY_REJECTED,
        PURCHASE_ORDER_STATUS_ID.EXPIRED,
      ].includes(id);
    });

  const { countryNames, countryNamesLoading, countryNamesError } = useCountryNames();
  const { quotationCostTypes, quotationCostTypesLoading, quotationCostTypesError } =
    useQuotationCostTypes();

  useEffect(() => {
    if (supplierQuote) {
      const currencies =
        supplierQuote?.supplier_quote_lines.map(quoteLine => quoteLine.currency_code) || [];
      const currency = currencies.length === 1 ? currencies[0] : "Various";

      const countryIndex = countryNames.findIndex(
        country =>
          country.country_code === supplierQuote.supplier_quote_lines[0].country?.country_code
      );

      const selectedCosts = supplierQuote.supplier_quote_lines[0].quotation_costs.map(
        quotationCost => {
          const costType = quotationCostTypes.find(
            quotationCostType =>
              quotationCostType.quotation_cost_type_name ===
              quotationCost.quotation_cost_type.quotation_cost_type_name
          );
          return {
            id: quotationCost.id,
            cost: quotationCost.cost_excl_vat,
            costType: costType
              ? {
                  label: costType.quotation_cost_type_name,
                  value: costType.id,
                }
              : null,
          };
        }
      );

      const lineCartonDetails = supplierQuote.supplier_quote_lines[0].carton_details;
      const getCartonDetails = (): CartonDetails => {
        if (lineCartonDetails) {
          if (typeof lineCartonDetails === "string") {
            return JSON.parse(lineCartonDetails);
          }
          return lineCartonDetails;
        }
        return {
          cartonLength: "",
          cartonWidth: "",
          cartonHeight: "",
          cartonWeight: "",
          unitPerCarton: "",
        };
      };
      const cartonDetails = getCartonDetails();

      setInitialValues(values => ({
        ...values,
        supplier: {
          value: supplierQuote.organisation.id,
          label: supplierQuote.organisation.organisation_name,
        },
        quoteValidFrom: `${moment(supplierQuote.valid_from).format("YYYY-MM-DD")}`,
        quoteValidTo: `${moment(supplierQuote.valid_to).format("YYYY-MM-DD")}`,
        currencyCode: currency,
        quotationCosts: selectedCosts,
        productSKU: {
          value: supplierQuote.supplier_quote_lines[0].product.id,
          label: supplierQuote.supplier_quote_lines[0].product.line_reference,
        },
        productName: {
          value: supplierQuote.supplier_quote_lines[0].product.id,
          label: extractProductName(supplierQuote.supplier_quote_lines[0].product.product_name),
        },
        moq: `${supplierQuote.supplier_quote_lines[0].minimum_order_quantity}`,
        deliveryDate: supplierQuote.supplier_quote_lines[0].lead_time_max_days
          ? getSelectedDateFromLeadTime(
              `${supplierQuote.supplier_quote_lines[0].lead_time_max_days}`
            )
          : null,
        leadTimeMaxDays: `${supplierQuote.supplier_quote_lines[0].lead_time_max_days}`,
        supplierReference: supplierQuote.supplier_quote_lines[0].supplier_reference
          ? `${supplierQuote.supplier_quote_lines[0].supplier_reference}`
          : "",
        country: supplierQuote.supplier_quote_lines[0].country
          ? {
              value: countryIndex,
              label: `${supplierQuote.supplier_quote_lines[0].country?.country_name}`,
            }
          : values.country,
        unitPerCarton: cartonDetails.unitPerCarton,
        cartonLength: cartonDetails.cartonLength,
        cartonWidth: cartonDetails.cartonWidth,
        cartonHeight: cartonDetails.cartonHeight,
        cartonWeight: cartonDetails.cartonWeight,
        packagingOption: supplierQuote.supplier_quote_lines[0].packaging_option || undefined,
        domesticLogisticsFeeIncluded:
          supplierQuote.supplier_quote_lines[0].domestic_logistics_fee_included || undefined,
        notes: supplierQuote.supplier_quote_lines[0].notes || undefined,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supplierQuote, quotationCostTypes]);

  const countryNamesOptions = useMemo(
    () => mapCountryNamesToDropdownObject(countryNames || []),
    [countryNames]
  );

  const getSelectedDateFromLeadTime = (leadTime: string): string => {
    const today = new Date();

    // Normalize to 00:00 for consistency with getLeadTimeFromSelectedDate()
    today.setTime(Date.parse(today.toDateString()));

    const leadTimeInMS = parseInt(leadTime) * DAY;
    today.setTime(today.getTime() + leadTimeInMS);

    return today.toISOString().split("T")[0];
  };

  const onSubmit = (values: QuoteForm) => {
    if (supplierQuote && supplierQuote.supplier_quote_lines[0]) {
      if (!SOURCING_EDIT_ROLES.some(role => roles.includes(role))) {
        toast.error("Incorrect approval permissions");
        return;
      }

      if (!values.productSKU) {
        toast.error("Please select a product");
        return;
      }

      const momentValidFrom = moment(values.quoteValidFrom);
      const momentValidTo = moment(values.quoteValidTo);

      const validToIsLarger = momentValidTo > momentValidFrom;
      if (!validToIsLarger) {
        toast.error("Please ensure your valid to date is later than your valid from date");
        return;
      }

      const leadTimeMaxDays = Number(values.leadTimeMaxDays);
      const timeToHandoff = values.timeToHandoff !== "" ? Number(values.timeToHandoff) : undefined;

      if (timeToHandoff && leadTimeMaxDays < timeToHandoff) {
        toast.error(
          "Lead time has to be at least equal to time to handoff as it may include delivery."
        );
        return;
      }

      const unitCostQuotationType = quotationCostTypes.find(
        quotationCostType => quotationCostType.quotation_cost_type_name === "Product Unit Cost"
      );

      if (!unitCostQuotationType) {
        toast.error(
          "Something went wrong with finding the correct type of quotation cost. Please refresh the page"
        );
        return;
      }

      const quotationCostLines: UpdateQuotationCostLinesBody[] = values.quotationCosts.map(
        quotationCost => {
          return {
            id: quotationCost.id,
            cost_excl_vat: Number(quotationCost.cost),
            currency_code: values.currencyCode,
            quotation_cost_type_id: quotationCost.costType!.value,
          };
        }
      );

      const productUnitCosts = quotationCostLines.filter(
        quotationCostLine => quotationCostLine.quotation_cost_type_id === unitCostQuotationType.id
      );

      if (productUnitCosts.length === 0) {
        toast.error("Please insert at least one line with type `Product Unit Cost`");
        return;
      }

      const countryCode = !!countryNames?.filter(
        country => values.country?.label === country.country_name
      ).length
        ? countryNames?.filter(country => values.country?.label === country.country_name)[0]
            .country_code
        : undefined;

      const quotationLine: UpdateQuoteLinesBody = {
        id: supplierQuote.supplier_quote_lines[0].id,
        lead_time_max_days: timeToHandoff ?? leadTimeMaxDays,
        minimum_order_quantity: Number(values.moq),
        currency_code: values.currencyCode,
        quotation_cost_lines: quotationCostLines,
        supplier_reference: values.supplierReference || undefined,
        country_code: countryCode,
        notes: values.notes,
        carton_details: {
          unitPerCarton: values.unitPerCarton,
          cartonHeight: values.cartonHeight,
          cartonLength: values.cartonLength,
          cartonWidth: values.cartonWidth,
          cartonWeight: values.cartonWeight,
        },
        packaging_option: values.packagingOption,
      };

      const quotation: UpdateQuoteBody = {
        id: Number(quoteId),
        supplier_organisation_id: Number(values.supplier!.value),
        valid_from: values.quoteValidFrom,
        valid_to: values.quoteValidTo,
        quote_line: quotationLine,
      };

      updateInternalQuote(quotation);
    }
  };

  const isRFQ = !!supplierQuote?.supplier_quote_lines[0].supplier_quote_request_line_id;
  const rfqFieldsDisabled = !hasNotBeenUsed || isRFQ;

  const error =
    quotationCostTypesError || countryNamesError || supplierQuoteError || purchaseOrderStatusError;
  const isLoading =
    quotationCostTypesLoading ||
    countryNamesLoading ||
    supplierQuoteLoading ||
    purchaseOrderStatusLoading;

  return (
    <Page error={error} isLoading={isLoading} title={"Quotation Form"}>
      <Panel withWrapper title={"Update Quote"}>
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({ values, setFieldValue, handleSubmit, errors, touched, handleReset }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DisplayBox isRequired isGray label="Supplier" value={values.supplier?.label} />

                  <DisplayBox isRequired isGray label="Currency Code" value={values.currencyCode} />
                </TopPanelInputsWrapper>
                <PrimaryLineWrapper>
                  <Title>Quote Validity</Title>

                  <PrimaryLineDataWrapper>
                    <DataPoint halfWidth withUniqueMargin>
                      <DisplayBox
                        fullWidth
                        label="Quote Valid From"
                        value={moment(values.quoteValidFrom).format("DD/MM/YYYY")}
                      />
                    </DataPoint>
                    <DataPoint halfWidth>
                      <SimpleDateInput
                        isRequired
                        htmlFor={fieldNames.quoteValidTo}
                        name={fieldNames.quoteValidTo}
                        value={values.quoteValidTo}
                        error={errors.quoteValidTo}
                        touched={touched.quoteValidTo}
                        label="Quote Valid To"
                        changeHandler={e => {
                          setFieldValue(fieldNames.quoteValidTo, e.target.value);
                        }}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>
                <PrimaryLineWrapper>
                  <Title>Quotation Details</Title>

                  <PrimaryLineDataWrapper>
                    <DataPoint halfWidth withUniqueMargin>
                      <DisplayBox fullWidth label="Product SKU" value={values.productSKU?.label} />
                    </DataPoint>
                    <DataPoint halfWidth withUniqueMargin>
                      <DisplayBox
                        fullWidth
                        label="Product Name"
                        value={values.productName?.label}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>

                  <PrimaryLineDataWrapper>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        isRequired
                        htmlFor={fieldNames.moq}
                        name={fieldNames.moq}
                        placeholder="100"
                        type="text"
                        value={values.moq}
                        error={errors.moq}
                        touched={touched.moq}
                        label="Minimum Order Quantity"
                        changeHandler={e => setFieldValue(fieldNames.moq, e.target.value)}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleDateInput
                        isGray={rfqFieldsDisabled}
                        isDisabled={!hasNotBeenUsed}
                        htmlFor={fieldNames.deliveryDate}
                        name={fieldNames.deliveryDate}
                        value={values.deliveryDate}
                        error={errors.deliveryDate}
                        touched={touched.deliveryDate}
                        label="Delivery Date"
                        changeHandler={e => {
                          setFieldValue(fieldNames.deliveryDate, e.target.value);
                          syncDeliveryDateWithLeadTime(
                            fieldNames.deliveryDate,
                            e.target.value,
                            setFieldValue
                          );
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        isRequired
                        tooltipMessage="Lead time as agreed with client for them to receive the order."
                        htmlFor={fieldNames.leadTimeMaxDays}
                        name={fieldNames.leadTimeMaxDays}
                        placeholder="14"
                        type="text"
                        value={values.leadTimeMaxDays}
                        error={errors.leadTimeMaxDays}
                        touched={touched.leadTimeMaxDays}
                        label="Lead Time to Client"
                        changeHandler={e => {
                          setFieldValue(fieldNames.leadTimeMaxDays, e.target.value);
                          syncDeliveryDateWithLeadTime(
                            fieldNames.leadTimeMaxDays,
                            e.target.value,
                            setFieldValue
                          );
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        tooltipMessage="Time for production and delivery to Sourceful."
                        htmlFor={fieldNames.timeToHandoff}
                        name={fieldNames.timeToHandoff}
                        placeholder="14"
                        type="text"
                        value={values.timeToHandoff}
                        error={errors.timeToHandoff}
                        touched={touched.timeToHandoff}
                        label="Time to Handoff"
                        changeHandler={e => {
                          setFieldValue(fieldNames.timeToHandoff, e.target.value);
                        }}
                      />
                    </DataPoint>
                    {values.domesticLogisticsFeeIncluded !== undefined && (
                      <DisplayBox
                        value={values.domesticLogisticsFeeIncluded ? "Yes" : "No"}
                        label={"Domestic Logistics Fee Included"}
                      />
                    )}
                  </PrimaryLineDataWrapper>
                  <PrimaryLineDataWrapper>
                    <DataPoint fullWidth>
                      <SimpleTextArea
                        isDisabled={rfqFieldsDisabled}
                        name={fieldNames.notes}
                        placeholder="Notes"
                        type="text"
                        value={values.notes || ""}
                        label="Notes"
                        handleChange={e => {
                          setFieldValue(fieldNames.notes, e.target.value);
                        }}
                      />
                      {errors && errors.notes && touched.notes ? (
                        <ErrorMessage>{errors.notes}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>
                <PrimaryLineWrapper>
                  <Title>Supplier Reference Details</Title>
                  <PrimaryLineDataWrapper>
                    <DataPoint halfWidth>
                      <SimpleSelect
                        isDisabled={rfqFieldsDisabled}
                        name={fieldNames.country}
                        placeholder="Select Country"
                        options={countryNamesOptions}
                        value={values.country}
                        error={errors.country}
                        touched={touched.country}
                        label="Country Name"
                        changeHandler={e => {
                          setFieldValue(fieldNames.country, e);
                        }}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                  <PrimaryLineDataWrapper>
                    <DataPoint halfWidth>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        tooltipMessage="If a reference is provided, it would be displayed in the magic link page and the Purchase Order document going out to the supplier when an order with this quote is created."
                        htmlFor={fieldNames.supplierReference}
                        name={fieldNames.supplierReference}
                        type="text"
                        placeholder="Enter Supplier Reference"
                        value={values.supplierReference}
                        error={errors.supplierReference}
                        touched={touched.supplierReference}
                        label="Quotation Supplier Reference"
                        changeHandler={e =>
                          setFieldValue(fieldNames.supplierReference, e.target.value)
                        }
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>

                <PrimaryLineWrapper>
                  <Title>Logistics Details</Title>

                  <PrimaryLineDataWrapper>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        htmlFor={fieldNames.unitPerCarton}
                        name={fieldNames.unitPerCarton}
                        placeholder="Units"
                        type="number"
                        value={values.unitPerCarton}
                        error={errors?.unitPerCarton}
                        touched={touched?.unitPerCarton}
                        label="Unit per carton"
                        changeHandler={e => {
                          console.log(e.target.value);
                          setFieldValue(fieldNames.unitPerCarton, e.target.value);
                        }}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>

                  <PrimaryLineDataWrapper>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        htmlFor={fieldNames.cartonHeight}
                        name={fieldNames.cartonHeight}
                        placeholder="Height"
                        type="number"
                        value={values.cartonHeight}
                        error={errors?.cartonHeight}
                        touched={touched?.cartonHeight}
                        label="Carton Height (mm)"
                        changeHandler={e => {
                          setFieldValue(fieldNames.cartonHeight, e.target.value);
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        htmlFor={fieldNames.cartonWidth}
                        name={fieldNames.cartonWidth}
                        placeholder="Width"
                        type="number"
                        value={values.cartonWidth}
                        error={errors?.cartonWidth}
                        touched={touched?.cartonWidth}
                        label="Carton Width (mm)"
                        changeHandler={e => {
                          setFieldValue(fieldNames.cartonWidth, e.target.value);
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        htmlFor={fieldNames.cartonLength}
                        name={fieldNames.cartonLength}
                        placeholder="Length"
                        type="number"
                        value={values.cartonLength}
                        error={errors?.cartonLength}
                        touched={touched?.cartonLength}
                        label="Carton Length (mm)"
                        changeHandler={e => {
                          setFieldValue(fieldNames.cartonLength, e.target.value);
                        }}
                      />
                    </DataPoint>
                    <DataPoint>
                      <SimpleInput
                        isDisabled={rfqFieldsDisabled}
                        htmlFor={fieldNames.cartonWeight}
                        name={fieldNames.cartonWeight}
                        placeholder="Weight"
                        type="number"
                        value={values.cartonWeight}
                        error={errors?.cartonWeight}
                        touched={touched?.cartonWeight}
                        label="Carton Weight"
                        changeHandler={e => {
                          setFieldValue(fieldNames.cartonWeight, e.target.value);
                        }}
                      />
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                  <PrimaryLineDataWrapper>
                    <DataPoint fullWidth>
                      <SimpleTextArea
                        isDisabled={rfqFieldsDisabled}
                        name={fieldNames.packagingOption}
                        placeholder="Weight"
                        type="text"
                        value={values.packagingOption || ""}
                        label="Packaging Option"
                        handleChange={e => {
                          setFieldValue(fieldNames.packagingOption, e.target.value);
                        }}
                      />
                      {errors && errors.packagingOption && touched.packagingOption ? (
                        <ErrorMessage>{errors.packagingOption}</ErrorMessage>
                      ) : null}
                    </DataPoint>
                  </PrimaryLineDataWrapper>
                </PrimaryLineWrapper>

                <PrimaryLineWrapper>
                  <Title>Quotation Cost Breakdown</Title>

                  <IconText
                    text={
                      "Please ensure that at least one of the quotation costs is Product Unit Cost"
                    }
                    primaryIcon="alert-exclamation-outline"
                  />

                  {values.quotationCosts.map((quotationCost, index) => (
                    <UpdatedQuotationCost
                      isDisabled={rfqFieldsDisabled}
                      key={index}
                      index={index}
                      values={quotationCost}
                      errors={errors.quotationCosts as FormikErrors<QuotationCostInterface[]>}
                      touched={touched.quotationCosts}
                      handleRemoveQuotationCost={(index: number) => {
                        const filteredQuotationCosts = values.quotationCosts.filter(
                          (_, i) => i !== index
                        );
                        const newValues = { ...values };
                        newValues.quotationCosts = filteredQuotationCosts;
                        if (filteredQuotationCosts.length === 0) {
                          setInitialValues(newValues);
                        }
                        setInitialValues(newValues);
                      }}
                      setFieldValue={setFieldValue}
                      quotationCostTypes={quotationCostTypes}
                    />
                  ))}

                  {hasNotBeenUsed && !isRFQ && (
                    <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        type="button"
                        appearance={"whiteButtonBlueText"}
                        onClick={() => {
                          setInitialValues({
                            ...values,
                            quotationCosts: [...values.quotationCosts, { ...emptyQuotationCost }],
                          });
                        }}
                      >
                        <IconText
                          text={"Add new quotation cost line"}
                          primaryIcon={"alert-add-outline"}
                        />
                      </SecondaryButton>
                    </ButtonWrapper>
                  )}
                </PrimaryLineWrapper>
                {typeof errors.quotationCosts === "string" &&
                  errors.quotationCosts &&
                  touched.quotationCosts && <ErrorMessage>{errors.quotationCosts}</ErrorMessage>}
                <ButtonGroup className={WMSButtonGroup({ type: "largeMargin" })}>
                  <SecondaryButton
                    type="button"
                    appearance="whiteButton"
                    onClick={() => {
                      handleReset();
                      setInitialValues({ ...initialFieldValues });
                    }}
                  >
                    Cancel
                  </SecondaryButton>
                  <SecondaryButton
                    disabled={isRFQ}
                    type="button"
                    appearance="blueButton"
                    onClick={() => handleSubmit()}
                  >
                    Update Quote
                  </SecondaryButton>
                </ButtonGroup>
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default UpdateInternalQuotes;
