import moment from "moment";
import { useMemo, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { CellProps } from "react-table";

import { PrimaryButton } from "@sourceful/shared-components";

import Table from "../../../../../components/Table";
import Panel, { PanelProps } from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton/SecondaryButton";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { Page } from "../../../../../shared/components/templates/Page";
import { useValidQuotes } from "../graphql/hooks/useValidQuotes";
import { QuoteRowItem } from "../graphql/types";

export default function QuotationsView() {
  const [showExpiredQuotes, setShowExpiredQuotes] = useState(false);
  let history = useHistory();

  const { roles } = useAuthorisationContext();

  const rightItems: PanelProps["rightItems"] = SOURCING_EDIT_ROLES.some(role =>
    roles.includes(role)
  )
    ? [
        {
          id: 1,
          handleClick: () => history.push("/quotations/quotation/create-quotation"),
          type: "button",
          title: "New Quote",
          iconName: "alert-add-outline",
        },
        {
          id: 2,
          handleClick: () =>
            history.push("/quotations/request-for-quotation/create-request-for-quotation"),
          type: "button",
          title: "New RFQ",
          iconName: "alert-add-outline",
        },
      ]
    : [];

  const { quotes, quotesLoading, quotesError } = useValidQuotes();
  const quotesAfterToday = useMemo(
    () => quotes.filter(quote => moment(quote.valid_to).isSameOrAfter(new Date())),
    [quotes]
  );

  const tableColumns = useMemo(
    () => [
      {
        Header: "SKU",
        widthPercent: 0.7,
        accessor: (quote: QuoteRowItem) => quote.product_uid,
      },
      {
        Header: "Product Name",
        widthPercent: 0.4,
        accessor: (quote: QuoteRowItem) => quote.product_name,
      },
      {
        Header: "Supplier",
        widthPercent: 0.2,
        accessor: (quote: QuoteRowItem) => quote.supplier_name,
      },
      {
        Header: "Currency",
        widthPercent: 0.05,
        accessor: (quote: QuoteRowItem) => quote.currency,
      },
      {
        Header: "Max First Unit Price",
        widthPercent: 0.1,
        accessor: (quote: QuoteRowItem) => quote.first_unit_price.toFixed(4),
      },
      {
        Header: "Max Repeat Unit Price",
        widthPercent: 0.1,
        accessor: (quote: QuoteRowItem) => quote.repeat_unit_price.toFixed(4),
      },
      {
        Header: "MOQ",
        widthPercent: 0.05,
        accessor: (quote: QuoteRowItem) => quote.moq,
      },
      {
        Header: "Actions",
        widthPercent: 0.15,
        Cell: ({ _, row }: CellProps<QuoteRowItem>) => (
          <Link
            to={`/quotations/quotation/update-quotation/${row.original.quotation_id}/${row.original.id}`}
          >
            <SecondaryButton isWithinTable appearance={"blueButton"} css={{ width: "110px" }}>
              Update
            </SecondaryButton>
          </Link>
        ),
      },
    ],
    []
  );

  return (
    <Page error={quotesError} isLoading={quotesLoading} title={"Quotations"}>
      <Panel
        withWrapper={true}
        title={"Valid Quotations"}
        rightItems={rightItems}
        allignTitle="left"
        iconName={"alert-exclamation-outline"}
      >
        <ErrorMessage css={{ marginLeft: "20px", marginRight: "20px" }}>
          The unit cost displayed is accumulated as maximum unit cost based on MOQ. Increasing the
          quantity will reduce unit cost due to delivery and set up charge distribution.
          <br /> <br />
          Set up costs are only charged on first order, so pricing may be lower on repeat purchase.{" "}
          <br />
          <br /> All costs exclude VAT.
        </ErrorMessage>
        <Table
          data={showExpiredQuotes ? quotes : quotesAfterToday}
          columns={tableColumns}
          renderFilters={() => <></>}
        >
          <PrimaryButton
            borderRadius="square"
            onClick={() => setShowExpiredQuotes(filter => !filter)}
          >
            {!showExpiredQuotes ? "Show expired quotes" : "Hide expired quotes"}
          </PrimaryButton>
        </Table>
      </Panel>
    </Page>
  );
}
