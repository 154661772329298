import { SOURCING_API } from "../../../../../api/baseConfig";
import { CreateLocaleHolidayBody } from "./types";

export const createLocaleHolidayAPI = async (body: CreateLocaleHolidayBody) => {
  try {
    return await SOURCING_API.post("/locale-holidays", body);
  } catch (err) {
    console.log("Error in create locale holiday API call", err);
    throw err;
  }
};
