import { createRef, useEffect, useState } from "react";
import { OptionTypeBase } from "react-select";
import { toast } from "react-toastify";

import {
  ButtonGroup,
  Modal,
  PrimaryButton,
  SecondaryButton,
  styled,
} from "@sourceful/shared-components";

import Panel from "../../../../components/panel/panel";
import SimpleInput from "../../../../shared/components/forms/SimpleInput";
import SimpleSelect from "../../../../shared/components/forms/SimpleSelect";
import Page from "../../../../shared/components/templates/Page/Page";
import { InnerPanelWrapper, PrimaryLineDataWrapper } from "../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../util/api/apiHook";
import { makeKey } from "../../../../util/makeKey";
import { getBaseProducts } from "../api/getBaseProducts";
import BaseProductPanel from "../components/BaseProductPanel";
import CreateBaseProductModal from "../components/CreateBaseProductModal";

const ProductPagination = styled("div", {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  paddingBottom: "20px",
});

const InputContainer = styled("div", {
  width: "200px",
  marginRight: "10px",
  display: "flex",
  flexDirection: "column",
  justifyContent: "end",
});

const InputsContainer = styled("div", {
  display: "flex",
  marginBottom: "40px",
});

const sortOptionValues: { [key: string]: any } = {
  0: "name",
  1: "id",
};

const sortOptions = [
  { value: 0, label: "Name" },
  { value: 1, label: "ID" },
];

const ViewBaseProducts = () => {
  const [getBaseProductsState, getProducts] = useAPI(getBaseProducts, true);
  const [products, setProducts] = useState<Array<any>>([]);

  const [pagination, setPagination] = useState<any>({});
  const [isBaseProductModalOpen, setIsBaseProductModalOpen] = useState(false);
  const [selectedBaseProduct, setSelectedBaseProduct] = useState<string>();
  const [filterId, setFilterId] = useState("");
  const [inputId, setInputId] = useState("");
  const [isNewBaseProductModalOpen, setIsNewBaseProductModalOpen] = useState(false);

  const [selectedSortOption, setSelectedSortOption] = useState(0);

  const modalRef = createRef<HTMLButtonElement>();

  const fetchAndSetProducts = async (page = 1, id = filterId, sort = selectedSortOption) => {
    const sortOption = sortOptionValues[sort];
    const products = await getProducts({
      page,
      page_size: 20,
      show_images: true,
      sort: `asc:${sortOption}`,
      id: id ? `eq:${id}` : undefined,
    });
    if (Array.isArray(products)) {
      setProducts(products[0].data);
      setPagination(products[0].pagination);
    } else setProducts([]);
  };

  useEffect(() => {
    fetchAndSetProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (getBaseProductsState === API_REQUEST_STATE.ERROR) {
      toast.error("Error while trying to get base products");
    }
  }, [getBaseProductsState]);

  const handleNextPage = () => {
    if (pagination.page_number < pagination.num_pages) {
      fetchAndSetProducts(pagination.page_number + 1);
    }
  };

  const handlePreviousPage = () => {
    if (pagination.page_number > 1) {
      fetchAndSetProducts(pagination.page_number - 1);
    }
  };

  const applyFilter = () => {
    setFilterId(inputId);
    fetchAndSetProducts(1, inputId);
  };

  const handleSortOptionChange = (event: OptionTypeBase) => {
    setSelectedSortOption(event.value);

    fetchAndSetProducts(1, filterId, event.value);
  };

  const isLoading = getBaseProductsState === API_REQUEST_STATE.PENDING;
  const error = getBaseProductsState === API_REQUEST_STATE.ERROR;

  const handleOpenBaseProduct = (productId: string) => {
    setSelectedBaseProduct(productId);
    setIsBaseProductModalOpen(true);
  };

  const handleOpenNewBaseProductModal = () => {
    setIsNewBaseProductModalOpen(true);
  };

  const handleCloseNewBaseProductModal = () => {
    setIsNewBaseProductModalOpen(false);
  };

  const handleCreateBaseProductSuccess = () => {
    setIsNewBaseProductModalOpen(false);
    fetchAndSetProducts();
  };

  return (
    <Page title={"Base Products"} isLoading={isLoading} error={error}>
      <>
        <Panel
          withWrapper
          title={"Base Products from Product Catalogue"}
          text={
            "Here are all the base products from the product catalogue. Click on a product to view its details and edit it."
          }
          allignTitle="left"
          rightItems={[
            {
              id: 1,
              handleClick: () => {
                handleOpenNewBaseProductModal();
              },
              type: "button",
              title: "Create New Base Product",
              iconName: "alert-add-outline",
            },
          ]}
          iconName={"alert-exclamation-outline"}
        >
          <InnerPanelWrapper>
            <InputsContainer>
              <InputContainer>
                <SimpleSelect
                  name="sortBy"
                  error={undefined}
                  touched={undefined}
                  placeholder="Select sort option"
                  label="Sort By"
                  options={[
                    { value: 0, label: "Name" },
                    { value: 1, label: "ID" },
                  ]}
                  value={sortOptions.find((option: any) => option.value === selectedSortOption)}
                  changeHandler={event => {
                    if (event) {
                      handleSortOptionChange(event);
                    }
                  }}
                />
              </InputContainer>
              <InputContainer>
                <SimpleInput
                  name="idSearch"
                  size={"medium"}
                  error={undefined}
                  touched={undefined}
                  label={"Search by ID"}
                  placeholder={"ID"}
                  type={"number"}
                  value={inputId}
                  changeHandler={event => {
                    setInputId(event.target.value);
                  }}
                />
              </InputContainer>

              <InputContainer>
                <PrimaryButton onClick={applyFilter}>Search</PrimaryButton>
              </InputContainer>
            </InputsContainer>
            <PrimaryLineDataWrapper marginSize="smallMargin">
              {products.map((product: any, index) => (
                <BaseProductPanel
                  name={product.name}
                  productId={product.id}
                  productType={product.type}
                  imgSrc={product.image_url}
                  key={makeKey("base-product")}
                  viewProductHandler={handleOpenBaseProduct}
                />
              ))}
            </PrimaryLineDataWrapper>
            <ProductPagination>
              <p>
                Showing {products.length} products in page {pagination.page_number} of{" "}
                {pagination.num_pages}
              </p>
              <ButtonGroup>
                <SecondaryButton
                  onClick={handlePreviousPage}
                  disabled={pagination.page_number === 1}
                >
                  Previous
                </SecondaryButton>
                <SecondaryButton
                  onClick={handleNextPage}
                  disabled={pagination.page_number === pagination.num_pages}
                >
                  Next
                </SecondaryButton>
              </ButtonGroup>
            </ProductPagination>
          </InnerPanelWrapper>
        </Panel>
        <Modal
          id="BaseProductModal"
          isOpen={isBaseProductModalOpen}
          handleClose={() => setIsBaseProductModalOpen(false)}
          triggerRef={modalRef}
        >
          {selectedBaseProduct}
        </Modal>
        <Modal
          id="NewBaseProductModal"
          isOpen={isNewBaseProductModalOpen}
          handleClose={handleCloseNewBaseProductModal}
          triggerRef={modalRef}
        >
          <CreateBaseProductModal handleCreateBaseProductSuccess={handleCreateBaseProductSuccess} />
        </Modal>
      </>
    </Page>
  );
};

export default ViewBaseProducts;
