import { Formik } from "formik";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { CheckBox, InputField } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import ErrorMessage from "../../../../../shared/components/atoms/labels/ErrorMessage";
import { FormButtonPair } from "../../../../../shared/components/templates/FormButtonPair";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  DataPoint,
  InnerPanelWrapper,
  Label,
  StyledForm,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { CreateBaseProductBody } from "../api/types";
import { BaseProductForm, fieldNames, initialFieldValues } from "../formValues";
import { useBaseProductNames } from "../graphql/hooks/useBaseProductsNames";
import {
  createBaseProductAction,
  createBaseProductReset,
} from "../redux/actions/createBaseProductAction";
import { CreateBaseProductReducer } from "../redux/reducers/createBaseProductReducer";
import { validationSchema } from "../validation";

interface Props {
  createBaseProductState: CreateBaseProductReducer;
  createBaseProduct: (body: CreateBaseProductBody) => void;
  resetCreateBaseProduct: () => void;
}

function AddBaseProduct(props: Props) {
  const [initialValues] = useState<BaseProductForm>(initialFieldValues);

  const { createBaseProductState } = props;

  const {
    baseProductNames,
    baseProductNamesLoading,
    baseProductNamesError,
    refetchBaseProductNames,
  } = useBaseProductNames();

  useEffect(() => {
    if (createBaseProductState.success) {
      props.resetCreateBaseProduct();
      refetchBaseProductNames();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createBaseProductState.success, createBaseProductState.error]);

  const onSubmit = async (values: BaseProductForm) => {
    if (baseProductNames.indexOf(values.product_name) !== -1) {
      toast.error("Base product with this name already exists.");
      return;
    }

    const baseProductBody: CreateBaseProductBody = {
      fsc_certified: values.fsc_certified,
      grs_certified: values.grs_certified,
      product_name: values.product_name,
    };

    if (values.width_mm) {
      baseProductBody.width_mm = Number(values.width_mm);
    }
    if (values.height_mm) {
      baseProductBody.height_mm = Number(values.height_mm);
    }
    if (values.weight_grams) {
      baseProductBody.weight_grams = Number(values.weight_grams);
    }
    if (values.length_mm) {
      baseProductBody.length_mm = Number(values.length_mm);
    }

    props.createBaseProduct(baseProductBody);
  };

  return (
    <Page
      error={baseProductNamesError}
      isLoading={baseProductNamesLoading}
      title={"Add Base Product"}
    >
      <Panel
        withWrapper
        title={"Add Base Product"}
        subtitle={"Add a Product using the card below, All Base products have a quantity of 1"}
      >
        <InnerPanelWrapper>
          <Formik
            initialValues={initialValues}
            enableReinitialize
            validationSchema={validationSchema}
            onSubmit={onSubmit}
          >
            {({
              values,
              setFieldValue,
              handleReset,
              handleChange,
              handleSubmit,
              errors,
              touched,
            }) => (
              <StyledForm onSubmit={handleSubmit}>
                <TopPanelInputsWrapper>
                  <DataPoint>
                    <Label isRequired htmlFor={fieldNames.product_name}>
                      Product Name
                    </Label>
                    <InputField
                      id={fieldNames.product_name}
                      size={"medium"}
                      type={"text"}
                      value={values.product_name}
                      handleChange={handleChange}
                      placeholder={"Enter Product Name..."}
                    />
                    {errors.product_name && touched.product_name ? (
                      <ErrorMessage>{errors.product_name}</ErrorMessage>
                    ) : null}
                  </DataPoint>

                  <DataPoint>
                    <Label htmlFor={fieldNames.fsc_certified}>FSC Certification</Label>
                    <CheckBox
                      handleChange={e => setFieldValue(fieldNames.fsc_certified, e.target.checked)}
                      value={fieldNames.fsc_certified}
                      id={fieldNames.fsc_certified}
                      label={"Required"}
                      isChecked={values.fsc_certified}
                    />
                    {errors.fsc_certified && touched.fsc_certified ? (
                      <ErrorMessage>{errors.fsc_certified}</ErrorMessage>
                    ) : null}
                  </DataPoint>

                  <DataPoint>
                    <Label htmlFor={fieldNames.grs_certified}>GRS Certification</Label>
                    <CheckBox
                      handleChange={e => setFieldValue(fieldNames.grs_certified, e.target.checked)}
                      value={fieldNames.grs_certified}
                      id={fieldNames.grs_certified}
                      label={"Required"}
                      isChecked={values.grs_certified}
                    />
                    {errors.grs_certified && touched.grs_certified ? (
                      <ErrorMessage>{errors.grs_certified}</ErrorMessage>
                    ) : null}
                  </DataPoint>

                  <DataPoint>
                    <Label htmlFor={fieldNames.weight_grams}>Weight (grams)</Label>
                    <InputField
                      id={fieldNames.weight_grams}
                      size={"medium"}
                      type={"number"}
                      value={values.weight_grams}
                      handleChange={handleChange}
                      placeholder={"Enter Weight (g)"}
                    />
                    {errors.weight_grams && touched.weight_grams ? (
                      <ErrorMessage>{errors.weight_grams}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                  <DataPoint>
                    <Label htmlFor={fieldNames.width_mm}>Width (mm)</Label>
                    <InputField
                      id={fieldNames.width_mm}
                      size={"medium"}
                      type={"number"}
                      value={values.width_mm}
                      handleChange={handleChange}
                      placeholder={"Enter Width (mm)"}
                    />
                    {errors.width_mm && touched.width_mm ? (
                      <ErrorMessage>{errors.width_mm}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                  <DataPoint>
                    <Label htmlFor={fieldNames.height_mm}>Height (mm)</Label>
                    <InputField
                      id={fieldNames.height_mm}
                      size={"medium"}
                      type={"number"}
                      value={values.height_mm}
                      handleChange={handleChange}
                      placeholder={"Enter Height (mm)"}
                    />
                    {errors.height_mm && touched.height_mm ? (
                      <ErrorMessage>{errors.height_mm}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                  <DataPoint>
                    <Label htmlFor={fieldNames.length_mm}>Length (mm)</Label>
                    <InputField
                      id={fieldNames.length_mm}
                      size={"medium"}
                      type={"number"}
                      value={values.length_mm}
                      handleChange={handleChange}
                      placeholder={"Enter Length (mm)"}
                    />
                    {errors.length_mm && touched.length_mm ? (
                      <ErrorMessage>{errors.length_mm}</ErrorMessage>
                    ) : null}
                  </DataPoint>
                </TopPanelInputsWrapper>

                <FormButtonPair handleReset={handleReset} />
              </StyledForm>
            )}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    createBaseProductState: state.createBaseProductReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    createBaseProduct: (body: CreateBaseProductBody) => dispatch(createBaseProductAction(body)),
    resetCreateBaseProduct: () => dispatch(createBaseProductReset()),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddBaseProduct);
