import { ApolloError } from "@apollo/client";

import { useGetContactIdsByEmailLazyQuery } from "../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../shared/constants";

export interface HookResult {
  contactIds?: number[];
  contactIdsLoading: boolean;
  contactIdsError?: ApolloError;
  getContactIdsByEmail: (options: Options) => Promise<void>;
}

export interface Options {
  contactEmailAddress: string;
}

export const useContactIdsByEmail = (): HookResult => {
  const [getContactIdsByEmail, { data, loading, error }] = useGetContactIdsByEmailLazyQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    contactIds: data?.contact.map(contact => contact.id),
    contactIdsLoading: loading,
    contactIdsError: error,
    getContactIdsByEmail: async ({ contactEmailAddress }: Options) => {
      getContactIdsByEmail({ variables: { contactEmailAddress } });
    },
  };
};
