import { useCallback, useEffect, useState } from "react";
import { useParams } from "react-router";
import { toast } from "react-toastify";

import { styled } from "@sourceful/shared-components";

import Panel from "../../../../../components/panel/panel";
import { DisplayBox } from "../../../../../shared/components/molecules/DisplayBox";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  InlineCode,
  InnerPanelWrapper,
  Label,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { API_REQUEST_STATE, useAPI } from "../../../../../util/api/apiHook";
import { fetchSkuAPI } from "../../../../sourcing/shared/fetchSku/api/fetchSku";
import { ProductCatalogueSkuBody } from "../../../../sourcing/shared/fetchSku/api/types";
import { useAllOrganisations } from "../../../organisations/shared/graphql/hooks/useAllOrganisations";
import { GetProductPricesResponse, getProductPricesApi } from "../api/getProductPrices";
import { getProductStocks as getProductStocksApi } from "../api/getProductStocks";
import { linkSkuToOrganisation } from "../api/linkSkuToOrganisation";
import { UnlinkSkuFromOrg } from "../api/unlinkSkuFromOrganisation";
import { updateSkuMetadata } from "../api/updateSkuMetadata";
import LinkSkuToOrganisationForm from "../components/LinkSkuToOrganisationForm";
import ProductStock from "../components/ProductStock";
import SkuPrices from "../components/SkuPrices";
import UpdateSkuForm from "../components/UpdateSkuForm";

export const Warning = styled("div", {
  borderRadius: "5px",
  color: "#FFFFFF",
  display: "flex",
  justifyContent: "center",
  alignContent: "center",
  textAlign: "center",
  width: "200px",
  padding: "5px 12px",
  background: "#FFB500",
});

export const TitleWrapper = styled("div", {
  display: "flex",
  justifyContent: "space-between",
});

const UpdateSku = () => {
  const { organisations, organisationsLoading, organisationsError } = useAllOrganisations();
  const { skuId } = useParams<{ skuId: string }>();
  const [fetchSkuState, fetchSkus] = useAPI(fetchSkuAPI, true);
  const [linkSkuToOrgState, linkSkuToOrg] = useAPI(linkSkuToOrganisation, true);
  const [unlinkSkuFromOrgState, unlinkSkuFromOrg] = useAPI(UnlinkSkuFromOrg, true);
  const [updateSkuState, updateSku] = useAPI(updateSkuMetadata, true);
  const [getPricesState, getPrices] = useAPI(getProductPricesApi, true);
  const [sku, setSku] = useState<ProductCatalogueSkuBody>();
  const [skuPrices, setSkuPrices] = useState<GetProductPricesResponse[]>([]);
  const [productStocks, setProductStocks] = useState<any[]>([]);
  const [getProductStocksState, getProductStocks] = useAPI(getProductStocksApi, true);

  //function to refetch prices after adding a new price
  const fetchPrices = async () => {
    const prices = await getPrices([
      { skus: [skuId], include_future: true, include_history: false },
    ]);
    const pricesData: GetProductPricesResponse[] = Array.isArray(prices)
      ? prices[0].data
      : prices.data;
    setSkuPrices(pricesData);
  };

  const fetchProductStocks = async () => {
    const getStock = async () => {
      const response = await getProductStocks({ skuId });
      const productStocks = Array.isArray(response) ? response[0].data : response.data;
      setProductStocks(productStocks);
    };

    getStock().catch(error => {
      console.error("error getting product stocks in ViewSkus:", error);
    });
  };

  useEffect(() => {
    if (unlinkSkuFromOrgState === API_REQUEST_STATE.SUCCESS) {
      toast.success("SKU successfully unlinked");
    } else if (unlinkSkuFromOrgState === API_REQUEST_STATE.ERROR) {
      toast.error("Error while trying to unlink SKU");
    }
  }, [unlinkSkuFromOrgState]);

  useEffect(() => {
    if (updateSkuState === API_REQUEST_STATE.SUCCESS) {
      toast.success("SKU successfully updated");
    } else if (updateSkuState === API_REQUEST_STATE.ERROR) {
      toast.error("Error while trying to update SKU");
    }
  }, [updateSkuState]);

  useEffect(() => {
    if (linkSkuToOrgState === API_REQUEST_STATE.SUCCESS) {
      toast.success("SKU successfully linked to organisation");
    } else if (linkSkuToOrgState === API_REQUEST_STATE.ERROR) {
      toast.error("Error while trying to link SKU to organisation");
    }
  }, [linkSkuToOrgState]);

  useEffect(() => {
    fetchPrices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // fetch sku
    const getSku = async () => {
      const product = await fetchSkus({ skuId: skuId });
      // @ts-ignore
      setSku(product[0]);
    };
    getSku().catch(error => {
      console.error("error getting skus in UpdateSku:", error);
    });
    fetchProductStocks();

    // eslint-disable-next-line
  }, [skuId, linkSkuToOrgState, unlinkSkuFromOrgState]);

  const matchingOrgs = sku?.org_ids.map(orgId => {
    return organisations?.find(org => org.externalOrganisationId === orgId);
  });

  const allMatchingOrgsPrePaid =
    matchingOrgs &&
    matchingOrgs.length > 0 &&
    matchingOrgs.every(org => org?.config?.prepaidStock === true);

  const getCurrencyWarning = useCallback(() => {
    // show warning if there are no prices with the default currency of the linked orgs
    const missingCurrencys = matchingOrgs
      ?.filter(org => {
        return skuPrices.every(price => {
          return price.currency_code !== org?.defaultCurrency;
        });
      })
      .map(org => org?.defaultCurrency);

    if (!missingCurrencys?.length || allMatchingOrgsPrePaid) return;

    const uniqueMissingCurrencys = [...new Set(missingCurrencys)];

    return (
      <Warning>
        Add a price with each organisations default currency
        {" ("}
        {uniqueMissingCurrencys?.map((currency, index) => {
          return `${currency}${index < uniqueMissingCurrencys.length - 1 ? ", " : ""}`;
        })}
        {")"}
      </Warning>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [matchingOrgs, skuPrices]);

  const errorState =
    fetchSkuState === API_REQUEST_STATE.ERROR ||
    updateSkuState === API_REQUEST_STATE.ERROR ||
    getPricesState === API_REQUEST_STATE.ERROR ||
    organisationsError ||
    getProductStocksState === API_REQUEST_STATE.ERROR;
  const loadingState =
    organisationsLoading ||
    fetchSkuState === API_REQUEST_STATE.PENDING ||
    updateSkuState === API_REQUEST_STATE.PENDING ||
    getPricesState === API_REQUEST_STATE.PENDING ||
    getProductStocksState === API_REQUEST_STATE.PENDING ||
    !sku;

  return (
    <Page title={"Update SKU"} isLoading={loadingState} error={errorState}>
      <Panel withWrapper={true} title={"Update SKU details"}>
        <InnerPanelWrapper>
          <TopPanelInputsWrapper>
            <DataPoint halfWidth>
              <Label isGray>Sku ID</Label>
              <Box type={"fitToContent"}>
                <InlineCode>{sku?.id}</InlineCode>
              </Box>
            </DataPoint>
            <DataPoint halfWidth>
              <DisplayBox isGray fullWidth label="Sku Name" value={sku?.client_name} />
            </DataPoint>
          </TopPanelInputsWrapper>

          <UpdateSkuForm sku={sku} skuId={skuId} updateSku={updateSku} />

          <LinkSkuToOrganisationForm
            sku={sku}
            linkSkuToOrg={linkSkuToOrg}
            unlinkSkuFromOrg={unlinkSkuFromOrg}
            getCurrencyWarning={getCurrencyWarning()}
            matchingOrgs={matchingOrgs}
            organisationsError={organisationsError}
            organisations={organisations}
            organisationsLoading={organisationsLoading}
          />

          {sku && (
            <SkuPrices
              skuPrices={skuPrices}
              skuId={sku?.id}
              refetchPrices={fetchPrices}
              allMatchingOrgsPrePaid={allMatchingOrgsPrePaid}
            />
          )}
          {sku && (
            <ProductStock
              skuId={skuId}
              productStocks={productStocks}
              organisations={organisations}
              refetchProductStocks={fetchProductStocks}
            />
          )}
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
};

export default UpdateSku;
