import { AdminQuotationCostTypeInterface } from "../types";

export const initialFieldValues: AdminQuotationCostTypeInterface = {
  quotation_cost_name: "",
  quotation_cost_description: "",
  quotation_cost_translations: [],
  quotaiton_cost_distribution: null,
  quotaiton_cost_account_type: null,
  is_cost_required_default: false,
  is_cost_required_sample: false,
};

export const fieldNames = {
  quotation_cost_name: "quotation_cost_name",
  quotation_cost_description: "quotation_cost_description",
  quotation_cost_translations: "quotation_cost_translations",
  quotaiton_cost_distribution: "quotaiton_cost_distribution",
  quotaiton_cost_account_type: "quotaiton_cost_account_type",
};
