import { useParams } from "react-router-dom";

import Panel from "../../../../../components/panel/panel";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  DataPoint,
  InnerPanelWrapper,
  Label,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  Title,
  TopPanelInputsWrapper,
} from "../../../../../styles/SharedStyles";
import { useWarehouseById } from "../graphql/hooks/useWarehouseById";

const ViewWarehouse = () => {
  const { warehouse_id } = useParams<{ warehouse_id: string }>();

  const { warehouse, warehouseLoading, warehouseError } = useWarehouseById(Number(warehouse_id));

  return (
    <Page error={warehouseError} isLoading={warehouseLoading} title={"View Warehouse"}>
      <Panel
        withWrapper
        title={`Warehouse #${warehouse?.id}`}
        subtitle={"View warehouse details below"}
      >
        {
          <InnerPanelWrapper>
            <TopPanelInputsWrapper>
              <DataPoint>
                <Label>Warehouse Name</Label>
                <Box>{warehouse?.warehouse_name}</Box>
              </DataPoint>
              <DataPoint>
                <Label>Organisation</Label>
                <Box>{warehouse?.organisation.organisation_name}</Box>
              </DataPoint>
            </TopPanelInputsWrapper>
            <PrimaryLineWrapper>
              <Title style={{ width: "100%", display: "flex", justifyContent: "center" }}>
                Address
              </Title>
              <PrimaryLineDataWrapper>
                <DataPoint>
                  <Label>Organisation</Label>
                  <Box>{warehouse?.address.organisation.organisation_name}</Box>
                </DataPoint>
                <DataPoint>
                  <Label>Address Name</Label>
                  <Box>{warehouse?.address.address_name}</Box>
                </DataPoint>
                <DataPoint>
                  <Label>Address Description</Label>
                  <Box>{warehouse?.address.address_description}</Box>
                </DataPoint>
                <DataPoint>
                  <Label>Phone Number</Label>
                  <Box>
                    {warehouse?.address.phone_number ? warehouse.address.phone_number : "N/A"}
                  </Box>
                </DataPoint>
                <DataPoint>
                  <Label>Address Line 1</Label>
                  <Box>{warehouse?.address.address_line_1}</Box>
                </DataPoint>
                <DataPoint>
                  <Label>Address Line 2</Label>
                  <Box>
                    {warehouse?.address.address_line_2 ? warehouse.address.address_line_2 : "N/A"}
                  </Box>
                </DataPoint>
                <DataPoint>
                  <Label>Address Line 3</Label>
                  <Box>
                    {warehouse?.address.address_line_3 ? warehouse?.address.address_line_3 : "N/A"}
                  </Box>
                </DataPoint>
                <DataPoint>
                  <Label>City</Label>
                  <Box>{warehouse?.address.city}</Box>
                </DataPoint>
                <DataPoint>
                  <Label>Postcode</Label>
                  <Box>{warehouse?.address.postcode}</Box>
                </DataPoint>
                <DataPoint>
                  <Label>Country</Label>
                  <Box>{warehouse?.address.country.country_name}</Box>
                </DataPoint>
              </PrimaryLineDataWrapper>
            </PrimaryLineWrapper>
          </InnerPanelWrapper>
        }
      </Panel>
    </Page>
  );
};

export default ViewWarehouse;
