import {
  Address as GraphqlAddress,
  Contact as GraphqlContact,
  Organisation as GraphqlOrganisation,
  OrganisationConfig as GraphqlOrganisationConfig,
} from "../../../../../../generated/graphql_stock_management";
import { Address, Config, Contact, Organisation } from "../types";

const mapOrganisationConfig = (config: GraphqlOrganisationConfig | null | undefined): Config => {
  if (!config) return {} as Config;
  return {
    xeroClientName: config.xeroClientName,
    autostockEnabled: config.autostockEnabled,
    selfserviceEnabled: config.selfserviceEnabled,
    mainProviderType: config.mainProviderType,
    orderProcessingType: config.orderProcessingType,
    deliveryInvoicedSeparately: config.deliveryInvoicedSeparately,
    prepaidStock: config.prepaidStock,
    displayProductUnitCost: config.displayProductUnitCost,
    computeDeliveryCostPerProduct: config.computeDeliveryCostPerProduct,
    enableClientReferenceCollection: config.enableClientReferenceCollection,
    paymentDisclaimer: config.paymentDisclaimer,
    productsSourceType: config.productsSourceType,
    productPricingSourceType: config.productPricingSourceType,
    paymentTerms: config.paymentTerms,
    orderFlowTypeId: config.orderFlowTypeId,
    paymentDueDate: { type: config.paymentDueDate?.type, value: config.paymentDueDate?.value },
  };
};

const mapAddresses = (
  addresses: (GraphqlAddress | null | undefined)[] | null | undefined
): Address[] | [] => {
  if (!addresses) return [] as Address[];
  return addresses?.map((address: GraphqlAddress | null | undefined): Address => {
    return {
      id: address?.id,
      externalAddressId: address?.externalAddressId,
      organisationId: address?.organisationId,
      isWarehouse: address?.isWarehouse,
      isInternal: address?.isInternal,
      name: address?.name,
      addressDescription: address?.addressDescription,
      addressLine1: address?.addressLine1,
      addressLine2: address?.addressLine2,
      addressLine3: address?.addressLine3,
      city: address?.city,
      postcode: address?.postcode,
      countryCode: address?.countryCode,
      taxRates: address?.taxRates?.map(taxRate => {
        return {
          id: taxRate?.id,
          taxRateTypeId: taxRate?.taxRateTypeId,
          taxValue: taxRate?.taxValue,
          taxPercentage: taxRate?.taxPercentage,
          countryCode: taxRate?.countryCode,
          taxRateType: taxRate?.taxRateType,
        };
      }),
      deliveryTimeline: {
        min: address?.deliveryTimeline?.min,
        max: address?.deliveryTimeline?.max,
        units: address?.deliveryTimeline?.units,
        businessDaysOnly: address?.deliveryTimeline?.businessDaysOnly,
      },
      region: address?.region,
    };
  });
};

const mapContacts = (
  contacts: (GraphqlContact | null | undefined)[] | null | undefined
): Contact[] => {
  if (!contacts) return [] as Contact[];
  return contacts?.map((contact: GraphqlContact | null | undefined): Contact => {
    return {
      id: contact?.id,
      firstName: contact?.firstName,
      lastName: contact?.lastName,
      contactEmailAddress: contact?.contactEmailAddress,
      contactPhoneNumber: contact?.contactPhoneNumber,
      organisationId: contact?.organisationId,
      addressId: contact?.addressId,
      isPrimary: contact?.isPrimary,
      contactJobTitle: contact?.contactJobTitle,
      preferredCommunicationMethodId: contact?.preferredCommunicationMethodId,
      taxNumber: contact?.taxNumber,
    };
  });
};

export const mapOrganisation = (organisation: GraphqlOrganisation | null): Organisation => {
  return {
    id: organisation?.id,
    name: organisation?.name,
    externalOrganisationId: organisation?.externalOrganisationId,
    locale: organisation?.locale,
    defaultCurrency: organisation?.defaultCurrency,
    addresses: mapAddresses(organisation?.addresses),
    contacts: mapContacts(organisation?.contacts),
    config: mapOrganisationConfig(organisation?.config),
  };
};

export const orderFlowTypes: { value: number; label: string }[] = [
  { value: 5, label: "Order flow with payment plan" },
  { value: 7, label: "Order flow with card (stripe) payment" },
  { value: 8, label: "Order flow offline" },
  { value: 9, label: "Order flow test" },
];

export const preferredCommunicationMethodTypes: { [key: number]: string } = {
  1: "Email",
  2: "Phone",
  3: "Post",
};

export const booleanReadableLabels = {
  autostockEnabled: "Autostock enabled",
  selfserviceEnabled: "Selfservice enabled",
  deliveryInvoicedSeparately: "Delivery invoiced separately",
  prepaidStock: "Prepaid stock",
  computeDeliveryCostPerProduct: "Compute delivery cost per product",
  enableClientReferenceCollection:
    "Collect reference & enable PO upload on 'view basket' page. This will also show the order invoice on the order view.",
  isWarehouse: "Is a warehouse address",
  displayProductUnitCost: "Display product unit cost",
};
