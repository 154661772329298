import { ApolloError } from "@apollo/client";

import { useGetQuotationCostAccountTypesQuery } from "../../../../../../../generated/graphql_sourcing";
import { DropdownItem } from "../../../../../../../shared/components/forms/SimpleSelect/SimpleSelect";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { mapQuotationCostTypeAccountTypes } from "../../mappers";

export interface HookResult {
  quotationCostAccountTypes: DropdownItem[];
  quotationCostAccountTypesLoading: boolean;
  quotationCostAccountTypesError?: ApolloError;
}

export const useQuotationCostAccountTypes = (): HookResult => {
  const { data, loading, error } = useGetQuotationCostAccountTypesQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
  });

  return {
    quotationCostAccountTypes:
      (data && mapQuotationCostTypeAccountTypes(data.quotation_cost_account_type)) || [],
    quotationCostAccountTypesLoading: loading,
    quotationCostAccountTypesError: error,
  };
};
