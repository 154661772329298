import { ApolloError } from "@apollo/client";

import { useGetQuotationCostTypeByIdQuery } from "../../../../../../../generated/graphql_sourcing";
import { GRAPHQL_SOURCING } from "../../../../../../../shared/constants";
import { mapQuotationCostTypeTranslations } from "../../mappers";
import { QuotationCostTypeItem } from "../types";

export interface HookResult {
  quotationCostType?: QuotationCostTypeItem;
  quotationCostTypeLoading: boolean;
  quotationCostTypeError?: ApolloError;
  refetchQuotationCostType: () => Promise<void>;
}

export const useQuotationCostTypeById = (costTypeId: number): HookResult => {
  const { data, loading, error, refetch } = useGetQuotationCostTypeByIdQuery({
    context: {
      clientName: GRAPHQL_SOURCING,
    },
    variables: {
      costTypeId,
    },
  });

  return {
    quotationCostType:
      data && Array.isArray(data.quotation_cost_type)
        ? {
            ...data.quotation_cost_type[0],
            quotation_cost_distribution:
              data.quotation_cost_type[0].quotation_cost_distribution
                .quotation_cost_distribution_name,
            quotation_cost_account_type:
              data.quotation_cost_type[0].quotation_cost_account_type
                .quotation_cost_account_type_name,
            translation: mapQuotationCostTypeTranslations(data.quotation_cost_type[0].translation),
          }
        : undefined,
    quotationCostTypeLoading: loading,
    quotationCostTypeError: error,
    refetchQuotationCostType: async () => {
      refetch({ costTypeId });
    },
  };
};
