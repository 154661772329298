import { RecipeLine } from "../formValues";
import { BaseProductItem, ProductItem, RecipeTypeItem } from "../graphql/types";

export const mapProductNamesToDropdownItem = (productNames: ProductItem[]) => {
  return productNames.map(productName => {
    return {
      label: productName.product_name,
      value: productName.id,
    };
  });
};

export const mapRecipeTypeNamesToDropdownItem = (recipeTypeNames: RecipeTypeItem[]) => {
  return recipeTypeNames.map(recipeTypeName => {
    return {
      label: recipeTypeName.recipe_type_name,
      value: recipeTypeName.id,
    };
  });
};

export const mapBaseProductNamesToDropdownItem = (baseProductNames: BaseProductItem[]) => {
  return baseProductNames.map(baseProductName => {
    return {
      label: baseProductName.product_name!,
      value: baseProductName.id!,
    };
  });
};

export const mapRecipeLinesToDTO = (recipeLines: RecipeLine[]) => {
  return recipeLines.map(recipeLine => {
    return {
      quantity: Number(recipeLine.quantity),
      product_id: recipeLine.base_product?.value!,
    };
  });
};
