import { WMS_API } from "../../../../../api/baseConfig";

export const stockSyncAPI = async () => {
  try {
    return await WMS_API.post("/product-stocks/sync");
  } catch (err) {
    console.log("Error in stock sync API call", err);
    throw err;
  }
};
