import { useState } from "react";
import { toast } from "react-toastify";

import { IconText, PrimaryButton, SecondaryButton } from "@sourceful/shared-components";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import AttachmentUploader, {
  PrefilledAttachment,
} from "../../../../../components/AttachmentUploader/AttachmentUploader";
import { ButtonWrapper, WMSButtonGroup } from "../../../../../styles/SharedStyles";
import { emptyAttachment } from "../../../shared/constants";
import { updateSupplierQCFilesAPI } from "../api/updateSupplierQCFiles";
import { SupplierItem } from "../graphql/types";

interface EditSupplierQCFilesProps {
  supplier: SupplierItem;
}

export const EditSupplierQCFiles = ({ supplier }: EditSupplierQCFilesProps) => {
  const [qcFiles, setQcFiles] = useState<UploadedFile[]>(supplier.attachments || []);

  const setPrefilledAttachment = (uploadedFile: UploadedFile): PrefilledAttachment | undefined => {
    if (uploadedFile.attachment_uuid === "" && uploadedFile.original_filename === "") {
      return;
    }
    return {
      attachmentUUID: uploadedFile.attachment_uuid,
      originalFilename: uploadedFile.original_filename,
    };
  };

  const onSubmit = async () => {
    try {
      updateSupplierQCFilesAPI(supplier.id, {
        qa_attachment_files: qcFiles.map(qcFile => ({
          originalFileName: qcFile.original_filename,
          uuid: qcFile.attachment_uuid,
        })),
      });
    } catch (err) {
      toast.error("Something went wrong with creating purchase order.");
      console.error("Error in update supplier QC files API call", err);
    } finally {
      toast.success("Updated Supplier QC Files successfully");
    }
  };

  const haveFilesChanged = qcFiles !== supplier.attachments;

  return (
    <>
      {qcFiles.map((file, index_attachment) => {
        return (
          <AttachmentUploader
            key={index_attachment}
            indexAttachment={index_attachment}
            parameterName={`attachments`}
            prefilledAttachment={setPrefilledAttachment(file)}
            setFieldValue={(_, value) => {
              const attachments = [...qcFiles];
              attachments[index_attachment] = value;
              setQcFiles(attachments);
            }}
            handleRemoveAttachmentLine={(index_attachment: number) => {
              const filteredAttachments = qcFiles.filter((_, i) => i !== index_attachment);
              setQcFiles(filteredAttachments);
            }}
          />
        );
      })}
      <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
        <SecondaryButton
          type="button"
          onClick={() => {
            const attachments: UploadedFile[] = [...qcFiles];
            attachments.push({ ...emptyAttachment });
            setQcFiles(attachments);
          }}
          appearance={"whiteButtonBlueText"}
        >
          <IconText text={"Upload New Attachment"} primaryIcon={"alert-add-outline"} />
        </SecondaryButton>
        <PrimaryButton borderRadius="square" onClick={onSubmit} disabled={!haveFilesChanged}>
          <IconText text={"Save"} primaryIcon={"alert-tick-fill"} />
        </PrimaryButton>
      </ButtonWrapper>
    </>
  );
};
