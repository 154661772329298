import { Formik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useEffect, useMemo, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { connect } from "react-redux";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import { CellProps } from "react-table";
import { toast } from "react-toastify";
import { Action } from "redux";
import { ThunkDispatch } from "redux-thunk";

import { ButtonGroup, IconText } from "@sourceful/shared-components";

import { UploadedFile } from "../../../../../attachments/fileUpload";
import AttachmentDownloader from "../../../../../components/AttachmentDownloader/AttachmentDownloader";
import AttachmentUploader from "../../../../../components/AttachmentUploader/AttachmentUploader";
import Table from "../../../../../components/Table";
import Panel from "../../../../../components/panel/panel";
import {
  SOURCING_EDIT_ROLES,
  useAuthorisationContext,
} from "../../../../../providers/AuthorisationProvider";
import { StoreTypes } from "../../../../../redux/store/storeTypes";
import SecondaryButton from "../../../../../shared/components/atoms/buttons/SecondaryButton";
import { ToolTip } from "../../../../../shared/components/atoms/labels/ToolTip/ToolTip";
import { Page } from "../../../../../shared/components/templates/Page";
import {
  Box,
  ButtonPositioner,
  ButtonWrapper,
  DataPoint,
  DateInput,
  InnerPanelWrapper,
  Label,
  LinkText,
  PrimaryLineDataWrapper,
  PrimaryLineWrapper,
  SecondaryLineDataWrapper,
  SecondaryLineWrapper,
  StyledForm,
  Title,
  TopPanelInputsWrapper,
  WMSButtonGroup,
} from "../../../../../styles/SharedStyles";
import { SourcingStatusBadge, TitleBadgeSpacer } from "../../../../../styles/SourcingStyles";
import { WithFeatureFlag } from "../../../../../util/featureFlags";
import { emptyAttachment } from "../../../shared/constants";
import ProductLineSimple from "../../createPurchaseOrder/components/ProductLineSimple";
import { clientNamesMapper, countryCodeFromLocale } from "../../shared/mappers";
import {
  AttachInvoicesToPurchaseOrderBody,
  UpdatePurchaseOrderDeliveryDateBody,
} from "../api/types/index";
import ApprovalSection from "../components/ApprovalSection";
import LogisticsStatusUpdate from "../components/LogisticsStatusUpdate";
import ProductDisplayLine from "../components/ProductDisplayLine";
import RecallPurchaseOrder from "../components/RecallPurchaseOrder";
import RegeneratePurchaseOrder from "../components/RegeneratePurchaseOrder";
import ResendPurchaseOrder from "../components/ResendPurchaseOrder";
import { FINANCE_ATTACHMENTS } from "../copy";
import { FinanceAttachmentsInterface, fieldNames, initialFieldValues } from "../formValues";
import { ApprovalInfo } from "../formValues/index";
import { usePurchaseOrderById } from "../graphql/hooks/usePurchaseOrderById";
import { AttachmentItem, ClientOrderTableInterface } from "../graphql/types";
import { getUniquePoLines, updatePoLinesWithFileCopyQty } from "../helpers";
import {
  mapApprovalToDateSent,
  mapFinanceAttachmentsToDTO,
  mapPurchaseOrderApprovalsToObject,
  mapStatusToType,
  mapSupplierResponseStatus,
} from "../mappers";
import {
  attachInvoicesToPurchaseOrderAction,
  attachInvoicesToPurchaseOrderReset,
} from "../redux/actions/attachInvoicesToPurchaseOrderAction";
import { getUsersAction } from "../redux/actions/getUserNamesAction";
import {
  updatePurchaseOrderDeliveryDateAction,
  updatePurchaseOrderDeliveryDateReset,
} from "../redux/actions/updatePurchaseOrderDeliveryDateAction";
import { AttachInvoicesToPurchaseOrderReducer } from "../redux/reducers/attachInvoicesToPurchaseOrderReducer";
import { UpdatePurchaseOrderDeliveryDateReducer } from "../redux/reducers/updatePurchaseOrderDeliveryDateReducer";
import { PURCHASE_ORDER_STATUS } from "../types";

interface Props {
  updatePurchaseOrderDeliveryDateState: UpdatePurchaseOrderDeliveryDateReducer;
  updatePurchaseOrderDeliveryDate: (
    body: UpdatePurchaseOrderDeliveryDateBody,
    purchaseOrderId: number
  ) => void;
  resetUpdatePurchaseOrderDeliveryDate: () => void;
  attachInvoicesToPurchaseOrderState: AttachInvoicesToPurchaseOrderReducer;
  attachInvoicesToPurchaseOrder: (
    purchaseOrderId: number,
    body: AttachInvoicesToPurchaseOrderBody
  ) => void;
  resetAttachInvoicesToPurchaseOrder: () => void;
  getUsers: (ids: string) => void;
}

function UpdatePurchaseOrder(props: Props) {
  const { roles } = useAuthorisationContext();
  const { updatePurchaseOrderDeliveryDateState, attachInvoicesToPurchaseOrderState } = props;
  const [updatedDeliveryDate, setUpdatedDeliveryDate] = useState("");
  const [initialValues] = useState<FinanceAttachmentsInterface>(initialFieldValues);
  const [isFullAddressOpen, setIsFullAddressOpen] = useState<boolean>(false);
  const [approvalsWithUserDetails, setApprovalsWithUserDetails] = useState<ApprovalInfo[]>([]);
  const [isClientOrderTableOpen, setIsClientOrderTableOpen] = useState<boolean>(false);
  const { purchaseOrderId } = useParams<{ purchaseOrderId: string }>();

  const { purchaseOrder, purchaseOrderError, purchaseOrderLoading, refetchPurchaseOrder } =
    usePurchaseOrderById({
      purchaseOrderId: Number(purchaseOrderId),
    });

  const getClientOrdersTableData = (): ClientOrderTableInterface[] => {
    const clientOrders =
      purchaseOrder?.purchase_order_lines.flatMap(poLine =>
        poLine.purchase_order_line_allocations
          .filter(line => !line.is_excess)
          .flatMap(line => {
            return { ...line };
          })
      ) ?? [];

    return _.uniqBy(clientOrders, "client_order.id");
  };

  const columns = useMemo(
    () => [
      {
        Header: "CO#",
        widthPercent: 0.1,
        accessor: (clientOrder: ClientOrderTableInterface) =>
          clientOrder.client_order.external_client_order_id,
        Cell: ({ row }: CellProps<ClientOrderTableInterface>) => (
          <Link to={`/client-orders/update-client-order/${row.original.client_order.id}`}>
            <LinkText>
              {row.original.client_order.external_client_order_id.substring(0, 8)}
            </LinkText>
          </Link>
        ),
      },

      {
        Header: "Client",
        widthPercent: 0.1,
        accessor: (clientOrder: ClientOrderTableInterface) =>
          clientOrder.client_order.organisation.organisation_name,
      },
      {
        Header: "Client Expected Delivery Date",
        widthPercent: 0.1,
        accessor: (clientOrder: ClientOrderTableInterface) =>
          clientOrder.client_order.client_promised_delivery_from_date ||
          clientOrder.client_order.client_promised_delivery_to_date,
        Cell: ({ row }: CellProps<ClientOrderTableInterface>) => {
          if (row.original.client_order.client_promised_delivery_from_date) {
            return `from ${moment(
              row.original.client_order.client_promised_delivery_from_date
            ).format("DD-MM-YYYY")} to ${moment(
              row.original.client_order.client_promised_delivery_to_date
            ).format("DD-MM-YYYY")}`;
          } else if (row.original.client_order.client_promised_delivery_to_date) {
            return moment(row.original.client_order.client_promised_delivery_to_date).format(
              "DD-MM-YYYY"
            );
          } else {
            return "No date set";
          }
        },
      },
      {
        Header: "Created At",
        widthPercent: 0.1,
        accessor: (clientOrder: ClientOrderTableInterface) =>
          moment(clientOrder.client_order.created_at).format("x"),
        Cell: ({ row }: CellProps<ClientOrderTableInterface>) =>
          moment(row.original.client_order.created_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Updated At",
        widthPercent: 0.1,
        accessor: (clientOrder: ClientOrderTableInterface) =>
          moment(clientOrder.client_order.updated_at).format("x"),
        Cell: ({ row }: CellProps<ClientOrderTableInterface>) =>
          moment(row.original.client_order.updated_at).format("DD-MM-YYYY"),
      },
      {
        Header: "Service",
        widthPercent: 0.1,
        accessor: (clientOrder: ClientOrderTableInterface) =>
          clientOrder.client_order.service_account.service_account_name,
      },
    ],
    []
  );

  const handleDateUpdate = () => {
    if (!SOURCING_EDIT_ROLES.some(role => roles.includes(role))) {
      toast.error("Incorrect permissions for updating date.");
      return;
    }

    props.updatePurchaseOrderDeliveryDate({ updatedDeliveryDate }, Number(purchaseOrderId));
  };

  useEffect(() => {
    if (updatePurchaseOrderDeliveryDateState.success) {
      setUpdatedDeliveryDate("");
      refetchPurchaseOrder();
      props.resetUpdatePurchaseOrderDeliveryDate();
    }
    if (updatePurchaseOrderDeliveryDateState.error) {
      props.resetUpdatePurchaseOrderDeliveryDate();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updatePurchaseOrderDeliveryDateState]);

  useEffect(() => {
    if (attachInvoicesToPurchaseOrderState.success) {
      refetchPurchaseOrder();
      props.resetAttachInvoicesToPurchaseOrder();
    }
    if (attachInvoicesToPurchaseOrderState.error) {
      props.resetAttachInvoicesToPurchaseOrder();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [attachInvoicesToPurchaseOrderState]);

  const handleUploadFinanceAttachments = (values: FinanceAttachmentsInterface) => {
    const invoiceAttachments = mapFinanceAttachmentsToDTO(values.finance_attachments);
    props.attachInvoicesToPurchaseOrder(Number(purchaseOrderId), { invoiceAttachments });
  };

  const approvals = mapPurchaseOrderApprovalsToObject(
    purchaseOrder?.purchase_order_approvals || []
  );
  const dielinesApproval = approvalsWithUserDetails.filter(
    approval => approval.type === "Dielines"
  );
  const orderApproval = approvalsWithUserDetails.filter(approval => approval.type === "Order");
  const poStatus = purchaseOrder?.purchase_order_status.purchase_order_status_name!;

  useEffect(() => {
    const mapUserIds = async () => {
      const userIds = approvals.map(({ userId }) => userId);
      const userIdsStringified = userIds.join(",");
      const allUsers: any = await props.getUsers(userIdsStringified);

      const allApprovalsWithUserIds = approvals.map(approval => {
        const foundUser = allUsers?.find((user: any) => user.userId === approval.userId);
        return {
          ...approval,
          userName: foundUser?.name || "Unknown user",
        };
      });

      setApprovalsWithUserDetails(allApprovalsWithUserIds);
    };
    mapUserIds();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [purchaseOrder]);

  const supplierResponse = purchaseOrder?.purchase_order_supplier_response;
  const address = [
    purchaseOrder?.address.address_name !== purchaseOrder?.address.address_line_1
      ? purchaseOrder?.address.address_name
      : null,
    purchaseOrder?.address.address_line_1,
    purchaseOrder?.address.address_line_2,
    purchaseOrder?.address.address_line_3,
    purchaseOrder?.address.city,
    purchaseOrder?.address.region,
    purchaseOrder?.address.postcode,
  ]
    .filter(Boolean)
    .join(", ");

  const invoices = purchaseOrder?.folder.attachments.filter(
    attachment => attachment.metadata.fileType === "finance"
  );

  const clientOrderInvoices = purchaseOrder?.purchase_order_lines
    .flatMap(poLine => {
      const attachments = poLine.purchase_order_line_allocations.flatMap(allocation => {
        const invoiceAttachments = allocation.client_order.folder.attachments.filter(
          attachment => attachment.metadata.fileType === "Client Order Invoice"
        );
        return invoiceAttachments?.filter(attachment => !!attachment);
      });
      return attachments.filter(invoiceAttachment => !!invoiceAttachment);
    })
    .filter(attachment => !!attachment) as AttachmentItem[];

  const poAttachments = purchaseOrder?.folder.attachments.filter(
    attachment => attachment.metadata.fileType !== "finance"
  );

  const updateDeliveryDate = () => {
    confirmAlert({
      title: "Update Purchase Order Estimated Delivery Date",
      message: `Are you sure you want to update the estimated delivery date of this purchase order?`,
      buttons: [
        {
          label: "Yes",
          onClick: () => handleDateUpdate(),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const uniquePoLines = getUniquePoLines(purchaseOrder?.purchase_order_lines || []);

  const sourcefulUrl = process.env.REACT_APP_SELF_SERVE_BASE_URL
    ? process.env.REACT_APP_SELF_SERVE_BASE_URL
    : window.REACT_APP_SELF_SERVE_BASE_URL;

  return (
    <Page
      title={"Update Purchase Order"}
      isLoading={purchaseOrderLoading}
      error={purchaseOrderError}
    >
      <Panel
        withWrapper={true}
        title={"Update Purchase Order"}
        subtitle={"Update a purchase order for a number of products"}
        sourcingStatusName={purchaseOrder?.purchase_order_status.purchase_order_status_name}
      >
        <InnerPanelWrapper>
          <Formik initialValues={initialValues} enableReinitialize onSubmit={() => {}}>
            {formikProps => {
              const { values, setFieldValue, handleSubmit } = formikProps;
              return (
                <StyledForm onSubmit={handleSubmit}>
                  <TopPanelInputsWrapper>
                    <DataPoint>
                      <Label isGray> Purchase Order Number</Label>
                      <Box>
                        #
                        {countryCodeFromLocale(
                          purchaseOrder?.supplier_organisation?.supplier_details[0].locale
                        )}
                        -{purchaseOrder?.purchase_order_document_uid}
                      </Box>
                    </DataPoint>
                    <DataPoint>
                      <Label isGray> Order Type</Label>
                      <Box>{purchaseOrder?.is_production ? "Production" : "Sample"}</Box>
                    </DataPoint>
                    <DataPoint>
                      <Label isGray>Order Creation Date</Label>
                      <Box>{moment(purchaseOrder?.created_at).format("DD-MM-YYYY")}</Box>
                    </DataPoint>
                    {purchaseOrder?.notes && (
                      <DataPoint fullWidth>
                        <Label isGray>Notes</Label>
                        <Box type={"fullWidth"}>{purchaseOrder?.notes}</Box>
                      </DataPoint>
                    )}
                  </TopPanelInputsWrapper>

                  <PrimaryLineWrapper>
                    <Title>Manufacturer</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin>
                        <Label isGray> Manufacturer Name</Label>
                        <Box>{purchaseOrder?.supplier_organisation?.organisation_name}</Box>
                      </DataPoint>
                      {poStatus !== "Pending" && poStatus !== "Internally Rejected" && (
                        <DataPoint withUniqueMargin>
                          <Label isGray>Date Sent </Label>
                          <Box>
                            {mapApprovalToDateSent(purchaseOrder?.purchase_order_approvals || [])}
                          </Box>
                        </DataPoint>
                      )}
                      {poStatus !== "Internally Rejected" && (
                        <DataPoint withUniqueMargin>
                          <Label isGray>Supplier Response</Label>
                          {poStatus in Object.values(PURCHASE_ORDER_STATUS) && (
                            <SourcingStatusBadge type={mapStatusToType(poStatus)}>
                              {mapSupplierResponseStatus(poStatus!)}
                            </SourcingStatusBadge>
                          )}
                        </DataPoint>
                      )}
                      {supplierResponse && (
                        <DataPoint withUniqueMargin>
                          <Label isGray>Date of Supplier Response</Label>
                          <Box>{moment(supplierResponse.created_at).format("DD-MM-YYYY")}</Box>
                        </DataPoint>
                      )}
                      {supplierResponse && supplierResponse.initial_notes && (
                        <DataPoint withUniqueMargin>
                          <Label isGray>Response Notes</Label>
                          <Box>{supplierResponse.initial_notes}</Box>
                        </DataPoint>
                      )}
                      {supplierResponse &&
                        supplierResponse.rejection_notes &&
                        supplierResponse.purchase_order_supplier_rejection_reason && (
                          <>
                            <DataPoint withUniqueMargin>
                              <Label isGray>Rejection Reason</Label>
                              <Box>
                                {
                                  supplierResponse.purchase_order_supplier_rejection_reason
                                    ?.supplier_rejection_reason!
                                }
                              </Box>
                            </DataPoint>
                            <DataPoint withUniqueMargin>
                              <Label isGray>Rejection Notes</Label>
                              <Box>{supplierResponse.rejection_notes}</Box>
                            </DataPoint>
                          </>
                        )}
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  <PrimaryLineWrapper>
                    <Title>Client</Title>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin halfWidth>
                        <Label>Client Name(s)</Label>
                        <Box type="fullWidth">
                          {purchaseOrder ? clientNamesMapper(purchaseOrder).join(", ") : ""}
                        </Box>
                      </DataPoint>
                      <ButtonPositioner>
                        <SecondaryButton
                          appearance="whiteButtonBlueText"
                          type="button"
                          onClick={() => {
                            setIsClientOrderTableOpen(!isClientOrderTableOpen);
                          }}
                        >
                          <IconText
                            text={"View Associated Client Orders"}
                            primaryIcon={
                              isClientOrderTableOpen ? "arrow-direction-up" : "arrow-direction-down"
                            }
                          />
                        </SecondaryButton>
                      </ButtonPositioner>
                    </PrimaryLineDataWrapper>
                    {isClientOrderTableOpen && (
                      <SecondaryLineWrapper>
                        <DataPoint fullWidth>
                          <Title>View the details of the associated client orders below</Title>
                        </DataPoint>
                        <DataPoint fullWidth>
                          <Table
                            hideUtilityBar
                            data={getClientOrdersTableData()}
                            columns={columns}
                          />
                        </DataPoint>
                      </SecondaryLineWrapper>
                    )}
                  </PrimaryLineWrapper>
                  <PrimaryLineWrapper>
                    <TitleBadgeSpacer>
                      <Title>Delivery</Title>
                      {purchaseOrder?.address.is_internal ? (
                        <SourcingStatusBadge type={"SourcefulWarehouse"}>
                          Sourceful Warehouse
                        </SourcingStatusBadge>
                      ) : (
                        <SourcingStatusBadge type={"ClientAddress"}>
                          Client Address
                        </SourcingStatusBadge>
                      )}
                    </TitleBadgeSpacer>
                    <PrimaryLineDataWrapper>
                      <DataPoint withUniqueMargin>
                        <Label isGray> Original Estimated Delivery Date </Label>
                        <Box>
                          {moment(purchaseOrder?.expected_delivery_date).format("DD-MM-YYYY")}
                        </Box>
                      </DataPoint>

                      <DataPoint withUniqueMargin>
                        <Label isGray> Delivery Address </Label>
                        <Box>{purchaseOrder?.address.address_name}</Box>
                      </DataPoint>
                      <DataPoint withUniqueMargin>
                        <Label isGray> Updated Estimated Delivery Date </Label>
                        <Box>
                          {purchaseOrder?.updated_delivery_date
                            ? moment(purchaseOrder?.updated_delivery_date).format("DD-MM-YYYY")
                            : "No Update - refer to original"}
                        </Box>
                      </DataPoint>
                      {(poStatus === "Accepted" || poStatus === "In Transit") && (
                        <DataPoint withUniqueMargin>
                          <ToolTip
                            title={"Update Estimated Delivery Date"}
                            message={
                              "The delivery date update should show the end date of the delivery window provided by the manufacturer."
                            }
                            htmlFor={fieldNames.estimated_delivery_date}
                          ></ToolTip>
                          <DateInput
                            id={fieldNames.estimated_delivery_date}
                            type="date"
                            value={updatedDeliveryDate}
                            onChange={e => {
                              setUpdatedDeliveryDate(
                                moment(e.target.valueAsDate).format("YYYY-MM-DD")
                              );
                            }}
                          />
                        </DataPoint>
                      )}
                    </PrimaryLineDataWrapper>
                    {uniquePoLines.length > 0 && (
                      <PrimaryLineDataWrapper>
                        <Title>Delivery Instructions</Title>

                        {uniquePoLines.map(poLine => (
                          <DataPoint withUniqueMargin fullWidth>
                            <Label isGray>
                              Client order{" "}
                              <Link
                                style={{ display: "inline-block" }}
                                to={{
                                  pathname: `${sourcefulUrl}/admin/orders/${poLine.purchase_order_line_allocations[0].client_order.external_client_order_id}`,
                                }}
                                target="_blank"
                              >
                                <LinkText>
                                  #
                                  {
                                    poLine.purchase_order_line_allocations[0].client_order
                                      .external_client_order_id
                                  }
                                </LinkText>
                              </Link>
                              :
                            </Label>

                            <Box type={"fullWidth"}>
                              {
                                poLine.purchase_order_line_allocations[0].client_order
                                  .delivery_instructions
                              }
                            </Box>
                          </DataPoint>
                        ))}
                      </PrimaryLineDataWrapper>
                    )}
                    <ButtonGroup className={WMSButtonGroup({ type: "smallMargin" })}>
                      <SecondaryButton
                        appearance={"whiteButtonBlueText"}
                        type="button"
                        onClick={() => {
                          setIsFullAddressOpen(!isFullAddressOpen);
                        }}
                      >
                        <IconText
                          text={"Full Address"}
                          secondaryIcon={
                            isFullAddressOpen ? "arrow-direction-up" : "arrow-direction-down"
                          }
                        />
                      </SecondaryButton>
                      {(poStatus === "Accepted" || poStatus === "In Transit") && (
                        <SecondaryButton
                          appearance={"blueButton"}
                          type="button"
                          onClick={() => {
                            if (updatedDeliveryDate === "") {
                              toast.warning("Please input a value ");
                              return;
                            }
                            if (updatedDeliveryDate <= purchaseOrder?.created_at) {
                              toast.warning(
                                "Your updated estimated delivery date cannot be the same or earlier than the order creation date "
                              );
                              return;
                            }
                            if (updatedDeliveryDate !== "") {
                              updateDeliveryDate();
                            }
                          }}
                        >
                          Update
                        </SecondaryButton>
                      )}
                    </ButtonGroup>
                    {isFullAddressOpen && (
                      <SecondaryLineWrapper>
                        <Title>Full Address</Title>
                        <SecondaryLineDataWrapper>
                          <DataPoint>
                            <Label isGray>Address Name</Label>
                            <Box type={"fullWidth"}>{purchaseOrder?.address.address_name}</Box>
                          </DataPoint>
                          {purchaseOrder?.address.address_description && (
                            <DataPoint>
                              <Label isGray>Address Description</Label>
                              <Box type={"fullWidth"}>
                                {purchaseOrder?.address.address_description}
                              </Box>
                            </DataPoint>
                          )}
                          <DataPoint>
                            <Label isGray>Country Code</Label>
                            <Box type={"fullWidth"}>{purchaseOrder?.address.country_code}</Box>
                          </DataPoint>

                          <DataPoint fullWidth>
                            <Label isGray>Full Address</Label>
                            <Box type={"fullWidth"}>{`${address}`}</Box>
                          </DataPoint>
                        </SecondaryLineDataWrapper>
                      </SecondaryLineWrapper>
                    )}
                  </PrimaryLineWrapper>

                  {purchaseOrder && (
                    <PrimaryLineWrapper>
                      <Title>Product</Title>
                      {updatePoLinesWithFileCopyQty(purchaseOrder!.purchase_order_lines)?.map(
                        (orderLine, index) => {
                          return (
                            <>
                              <ProductDisplayLine
                                key={orderLine.id}
                                index={index}
                                orderLine={orderLine}
                                supplierId={purchaseOrder.supplier_organisation_id}
                              />
                              {orderLine.fileCopyQty && (
                                <ProductLineSimple
                                  index={index}
                                  productLabel={orderLine.product.product_name}
                                  quantity={orderLine.fileCopyQty}
                                />
                              )}
                            </>
                          );
                        }
                      )}
                    </PrimaryLineWrapper>
                  )}

                  <ApprovalSection
                    type="dielines"
                    approval={dielinesApproval}
                    refetchPurchaseOrder={refetchPurchaseOrder}
                  />

                  <ApprovalSection
                    type="order"
                    disabled={
                      dielinesApproval.length === 0 ||
                      !dielinesApproval[dielinesApproval.length - 1].isApproved
                    }
                    approval={orderApproval}
                    refetchPurchaseOrder={refetchPurchaseOrder}
                  />

                  <PrimaryLineWrapper>
                    <Title>{FINANCE_ATTACHMENTS.attachmentsTitle}</Title>
                    <PrimaryLineDataWrapper>
                      <p>{FINANCE_ATTACHMENTS.attachmentsDescription}</p>

                      <DataPoint fullWidth withUniqueMargin>
                        <Title>{FINANCE_ATTACHMENTS.invoicesTitle}</Title>
                        {invoices && invoices?.length !== 0 && (
                          <>
                            {invoices.map((invoiceAttachment, index_attachment) => (
                              <AttachmentDownloader
                                key={index_attachment}
                                originalFilename={invoiceAttachment.original_filename}
                                attachmentUUID={invoiceAttachment.attachment_uuid}
                                metadata={invoiceAttachment.metadata}
                              />
                            ))}
                          </>
                        )}
                        {clientOrderInvoices && clientOrderInvoices?.length !== 0 && (
                          <>
                            {_.uniqBy(clientOrderInvoices, "attachment_uuid").map(
                              (invoiceAttachment, index_attachment) => (
                                <AttachmentDownloader
                                  key={index_attachment}
                                  originalFilename={invoiceAttachment.original_filename}
                                  attachmentUUID={invoiceAttachment.attachment_uuid}
                                  metadata={invoiceAttachment.metadata}
                                />
                              )
                            )}
                          </>
                        )}
                        {!clientOrderInvoices && !invoices && <p>No Exisiting Invoices</p>}
                      </DataPoint>

                      <SecondaryLineWrapper style={{ flexDirection: "row" }}>
                        <DataPoint fullWidth>
                          {values.finance_attachments.map((attachment, index_attachment) => {
                            return (
                              <AttachmentUploader
                                key={index_attachment}
                                indexAttachment={index_attachment}
                                parameterName={`finance_attachments`}
                                setFieldValue={setFieldValue}
                                handleRemoveAttachmentLine={(index_attachment: number) => {
                                  const filteredAttachments = values.finance_attachments.filter(
                                    (item, i) => i !== index_attachment
                                  );
                                  setFieldValue(`finance_attachments`, filteredAttachments);
                                }}
                              />
                            );
                          })}

                          <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                            <SecondaryButton
                              type="button"
                              onClick={() => {
                                const attachments: UploadedFile[] = [...values.finance_attachments];
                                attachments.push({ ...emptyAttachment });
                                setFieldValue(`finance_attachments`, attachments);
                              }}
                              appearance={"whiteButtonBlueText"}
                            >
                              <IconText
                                text={"Upload New Attachment"}
                                primaryIcon={"alert-add-outline"}
                              />
                            </SecondaryButton>
                          </ButtonWrapper>
                        </DataPoint>
                      </SecondaryLineWrapper>
                      {values.finance_attachments.length !== 0 && (
                        <ButtonWrapper className={WMSButtonGroup({ type: "smallMargin" })}>
                          <SecondaryButton
                            type="button"
                            onClick={() => handleUploadFinanceAttachments(values)}
                            appearance={"blueButton"}
                          >
                            <IconText text={"Upload Attachments to PO"} />
                          </SecondaryButton>
                        </ButtonWrapper>
                      )}
                    </PrimaryLineDataWrapper>
                  </PrimaryLineWrapper>

                  <PrimaryLineWrapper>
                    <Title>Attachments</Title>
                    {poAttachments?.map((poAttachment, index_attachment) => (
                      <AttachmentDownloader
                        key={index_attachment}
                        originalFilename={poAttachment.original_filename}
                        attachmentUUID={poAttachment.attachment_uuid}
                        metadata={poAttachment.metadata}
                      />
                    ))}
                  </PrimaryLineWrapper>

                  <LogisticsStatusUpdate
                    poStatus={poStatus}
                    refetchPurchaseOrder={refetchPurchaseOrder}
                  />

                  <ResendPurchaseOrder
                    poStatus={poStatus}
                    refetchPurchaseOrder={refetchPurchaseOrder}
                  />

                  <RecallPurchaseOrder
                    poStatus={poStatus}
                    refetchPurchaseOrder={refetchPurchaseOrder}
                  />

                  <WithFeatureFlag featureFlag="REACT_APP_FEATURE_FLAG_REGENERATE_PO">
                    <RegeneratePurchaseOrder poStatus={poStatus} poId={purchaseOrderId} />
                  </WithFeatureFlag>
                </StyledForm>
              );
            }}
          </Formik>
        </InnerPanelWrapper>
      </Panel>
    </Page>
  );
}

function mapStateToProps(state: StoreTypes) {
  return {
    updatePurchaseOrderDeliveryDateState: state.updatePurchaseOrderDeliveryDateReducer,
    attachInvoicesToPurchaseOrderState: state.attachInvoicesToPurchaseOrderReducer,
  };
}

function mapDispatchToProps(dispatch: ThunkDispatch<StoreTypes, void, Action>) {
  return {
    updatePurchaseOrderDeliveryDate: (
      body: UpdatePurchaseOrderDeliveryDateBody,
      purchaseOrderId: number
    ) => dispatch(updatePurchaseOrderDeliveryDateAction(purchaseOrderId, body)),
    resetUpdatePurchaseOrderDeliveryDate: () => dispatch(updatePurchaseOrderDeliveryDateReset()),
    resetAttachInvoicesToPurchaseOrder: () => dispatch(attachInvoicesToPurchaseOrderReset()),
    attachInvoicesToPurchaseOrder: (
      purchaseOrderId: number,
      body: AttachInvoicesToPurchaseOrderBody
    ) => dispatch(attachInvoicesToPurchaseOrderAction(purchaseOrderId, body)),
    getUsers: (ids: string) => dispatch(getUsersAction(ids)),
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UpdatePurchaseOrder);
